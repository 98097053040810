import React, { useEffect, useState } from 'react'
import Button from '../../../../components/common/Button/Button'
import { useTranslation } from 'react-i18next';
import ViewSummaryPopup from '../../../../components/newMotorPolicy/veiwSummaryPopup/viewSummaryPopup';
import useSummary from '../../../../hooks/useSummary';
import { formatToThreeDigits } from '../../../../utils';
import Loader from '../../../../components/common/Loader/Loader';
import { useSelector } from "react-redux";
import { getQuoteResponse } from '../../../../redux/features/motor/getQuoteSlice';
import { getPolicyPremiumResponse } from '../../../../redux/features/motor/renewalPremiumSlice';
import useLanguageTranslate from '../../../../hooks/useLanguageTranslate';

const LifeInsuranceBottomStickyBtnLayout = ({
    children,
    btn_label,
    btn_onClick,
    btn_disabled,
    show_summary,
    totalAmount,
    isLoading,
    showSummaryHandler
}) => {
    const { t } = useTranslation();
    const translate = useLanguageTranslate()

    return (
        <>
            {children}
            <div className="footer bg-white border-t-[#E5E9EB] border-t border-solid mt-2 w-full py-2 flex justify-end fixed pr-[3%] bottom-0 max767:w-full max767:p-4 max767:inset-x-0 ipad:items-center max767:inline-block max767:z-[9999]">
                {show_summary && !isLoading && <div onClick={showSummaryHandler} className="cursor-pointer view_summary  mr-[2%] mt-3 max767:w-full max767:flex max767:mb-2 max767:justify-between ipad:mt-0  rtl:ml-3 rtl:mr-0">
                    <span className='text-[#00384D] text-center text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline mr-3'>{t("motorInsurance.bottomStickybtnlayout.summary")}</span>  <strong className='text-[#1A2024] text-xl not-italic font-extrabold leading-6'>  {translate("BHD","دينار بحريني")} {formatToThreeDigits(totalAmount || 0)}</strong>
                </div>}
                <div className="flex justify-end pr-[3%] rtl:pl-[3%] max767:w-full max767:pr-[0%]">
                    <Button
                        isLoading={isLoading}
                        disabled={btn_disabled}
                        onSubmit={btn_onClick ? btn_onClick : () => { }}
                        type="submit"
                        label={btn_label || t(
                            "motorInsurance.newMotorInsuranceFlow.basicInfo.continueButton"
                        )}
                        className="mb-0 stickybutton flex w-[200px] flex-col items-center gap-2.5 shrink-0 p-4 bg-[#55C6EF] rounded-[10px] text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px] max767:w-full max767:h-[40px]"
                    />
                </div>
            </div>
        </>
    )
}

export default LifeInsuranceBottomStickyBtnLayout