import React from 'react'
import Button from '../../../../../components/common/Button/Button'
import tickicon from "../../../../../assets/images/check.svg";
import useLanguageTranslate from '../../../../../hooks/useLanguageTranslate';

const CycleCard = ({
    label,
    selected,
    isCurrentPlan,
    onClick,
    plan
}) => {
    const tranlate = useLanguageTranslate()
    return (
        <div onClick={() => onClick(plan)} className={`${selected?"bg-[#E3F8FF] rounded-[10px] border-1 border-[#00719A] ":""} justify-center items-start gap-4 flex-[1_0_0] border p-6 rounded-[10px] w-[264px] max-w-[100%] max767:w-full max767:flex max767:justify-between max767:p-4 max767:items-center`}>
      
            <p className='text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] mb-3 max767:mb-0'>{label}</p>
            {!isCurrentPlan &&
                (selected ?
                    <Button
                        pre_icon={tickicon}
                        label={tranlate("Selected",'تم اختياره')}
                        className="bg-[#55C6EF] w-full text-[#1A2024] rounded-md text-center text-sm not-italic justify-center font-semibold leading-6 tracking-[-0.084px] flex h-8 items-center gap-2.5 shrink-0 self-stretch px-3 py-1 max767:w-[130px]"
                    />
                    : <Button
                        label={tranlate("Select",'يختار')}
                        className="w-full border bg-white rounded-md border-solid border-[#DDE2E4] justify-center text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] flex h-8 items-center gap-2.5 shrink-0 self-stretch px-3 py-1 max767:w-[130px]"

                    />)
            }
            {
                isCurrentPlan && <Button
                    disabled
                    label={tranlate("Current Plan","الخطة الحالية")}
                    className="w-full flex-col items-center gap-2.5 self-stretch border opacity-50 bg-white px-3 py-1 rounded-md border-solid border-[#DDE2E4] max767:w-[130px]"
                />
            }
        </div>
        
    )
}

export default CycleCard