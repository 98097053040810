import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../axiosInstance";
import { apiEndPoints, apiEndpoints } from "../../api";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const fetchProfileInfo = createAsyncThunk(
  "fetchProfileInfo",
  (queryString, { rejectWithValue }) => {
    return Axios.get(`${apiEndpoints.profile}`).then((res) => res.data);
  }
);

export const profileSlice = createSlice({
  name: "profileSlice",
  initialState,
  reducers: {
    clearProfileError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // FETCH  UTILS API
    builder.addCase(fetchProfileInfo.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchProfileInfo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchProfileInfo.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });
  },
});

// SELECTORS
export const selectProfileDatatWhole = (store) => store?.profile;
export const selectProfileData = (store) => store?.profile?.data;
export const selectProfilePolicyTransferStatus = (store) => store?.profile?.data?.TransferStatus;
export const selectProfileDataLoader = (store) => store?.profile?.isLoading;

// ACTIONS TO DISPATCH
export const { clearProfileError } = profileSlice.actions;

export default profileSlice.reducer;
