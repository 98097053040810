import React from 'react'

import erroricon from '../../../../../assets/images/error.svg';
import useLanguageTranslate from '../../../../../hooks/useLanguageTranslate';

const InfoCard = ({
    title,
    title_icon,
    className,
    infoData,
}) => {
  const translate = useLanguageTranslate()

    return (
        <div className={`infoCard flex flex-col items-start gap-2 self-stretch bg-[#F6F8F9] p-4 rounded-2xl mb-4 ${className}`}>
            <div className="flex align-middle gap-2">
                
                <h1 className='text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px] flex gap-2'><img src={title_icon} alt="title_icon" /> {title}</h1>
            </div>
            <ul className='pl-12 rtl:pr-12 max767:pl-[24px]'>
                {
                    infoData?.map(each => <li className=" gap-2 mb-1">
                        <span className="inline-flexflex align-middle text-[#48535B] text-sm not-italic font-normal leading-5 tracking-[-0.14px]">{translate(each?.en_label, each?.ar_label)}</span>
                        {each?.en_hint_msg && <img title={translate(each?.en_hint_msg,each?.ar_hint_msg)} src={erroricon} alt="info_icon" className='inline-flex ml-2 rtl:mr-2'/>}
                    </li>)
                }
            </ul>

        </div>
    )
}

export default InfoCard