// src/pages/ReportRedirect.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Loader } from 'storybook/internal/components';
import { DEV_BASE_URL, STAGING_BASE_URL } from '../../api/baseURL';

const ReportRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");

    if (token) {
      // Construct the URL for redirection
      const apiURL = `${STAGING_BASE_URL}/policy/report?token=${token}`;
      window.location.href = apiURL;
    } else {
      // Redirect back to home or an error page if token is missing
      navigate('/');
    }
  }, [location, navigate]);

  return (
    <div>
      <Loader/>
      <p>Redirecting...</p>
    </div>
  );
};

export default ReportRedirect;
