import { createSlice } from "@reduxjs/toolkit";
import { CHANGE_MOBILE_NUMBER_FLOW } from "../../pages/signInRegisterPages/changeMobileNumberFlow/data";

const initialState = {
  flowType: CHANGE_MOBILE_NUMBER_FLOW,
  currentStepIndex: 0,
  currentSubstepIndex: 0,
};

const changeMobileNumberSlice = createSlice({
  name: "changeMobileNumberFlow",
  initialState,
  reducers: {
    setChangeMobileNumberFlowType: (state, action) => {
      const data = action.payload;
      state.flowType = data.type;
      state.currentStepIndex = data.currentStepIndex || 0;
      state.currentSubstepIndex = data.currentSubstepIndex || 0;
    },
    setCurrentStepIndex: (state, action) => {
      state.currentStepIndex = action.payload;
      state.currentSubstepIndex = 0;
    },
    setCurrentSubstepIndex: (state, action) => {
      state.currentSubstepIndex = action.payload;
    },
    resetChangeMobileNumberFlow: (state) => {
      state.currentStepIndex = 0;
      state.currentSubstepIndex = 0;
      state.flowType = CHANGE_MOBILE_NUMBER_FLOW;
    },
  },
});

export const selectChangeMobileNumberFlow = (store) =>
  store.changeMobileNumberFlow;
export const selectChangeMobileNumberFlowType = (store) =>
  store?.changeMobileNumberFlow?.flowType;
export const selectCurrentStepIndex = (store) =>
  store?.changeMobileNumberFlow?.currentStepIndex;
export const selectCurrentSubstepIndex = (store) =>
  store.changeMobileNumberFlow?.currentSubstepIndex;

export const {
  setChangeMobileNumberFlowType,
  setCurrentStepIndex,
  setCurrentSubstepIndex,
  resetChangeMobileNumberFlow
} = changeMobileNumberSlice.actions;
export default changeMobileNumberSlice.reducer;
