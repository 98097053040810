import React from "react";
import ChevronRight from "../../assets/images/chevron_right-gray.svg";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";

const ActiveClaimCard = ({ claim }) => {
  const navigate = useNavigate();
  const valueToEncrypt = claim.CaseNumber;
  const translate = useLanguageTranslate();
  // Encryption logic
  const secretKey = "i-hate-meej";
  const encryptedValue = CryptoJS.AES.encrypt(
    valueToEncrypt,
    secretKey
  ).toString();

  // Convert to URL-safe format
  const urlSafeEncryptedValue = encryptedValue
    .split("+")
    .join("-") // Replace '+' with '-'
    .split("/")
    .join("_") // Replace '/' with '_'
    .split("=")
    .join(""); // Remove '=' padding

  let claimStatus = "";
  switch (claim.Status) {
    case "Claim Intimation":
      claimStatus = translate("Intimation","الإبلاغ");
      break;
    case "Intimation Acknowledgement":
      claimStatus = translate("Intimation","الإبلاغ");
      break;
    case "Incomplete Intimation":
      claimStatus = translate("Intimation","الإبلاغ");
      break;
    case "Rejected Claim":
      claimStatus = translate("Intimation","الإبلاغ");
      break;
    case "Claim Registration":
      claimStatus = translate("Registration","التسجيل");
      break;
    case "Survey Completion":
      claimStatus = translate("Survey","الاستبيان");
      break;
    case "Under Procurement":
      claimStatus = translate("Settlement","التسوية");
      break;
    case "Under Repair":
      claimStatus = translate("Settlement","التسوية");
      break;
    case "Total Loss":
      claimStatus = translate("Settlement","التسوية");
      break;
    case "Cash Loss":
      claimStatus = translate("Settlement","التسوية");
      break;
    case "Pending For Loss Adjustor":
      claimStatus = translate("Settlement","التسوية");
      break;
    case "Pending for Estimate":
      claimStatus = translate("Survey","الاستبيان");
      break;
    case "Pending for Deductible":
      claimStatus = translate("Registration","التسجيل");
      break;
    case "Pending for Depreciation":
      claimStatus = translate("Survey","الاستبيان");
      break;
    case "Vehicle Collection":
      claimStatus = translate("Settlement","التسوية");
      break;
    case "Close Claim":
      claimStatus = translate("Closed", "مغلق");
      break;
    default:
      claimStatus = translate("Unknown status.","حالة غير معروفة");
  }

  return (
    <div 
    // onClick={() =>
    //   navigate("/motor-insurance/claim-details/" + claim.CaseNumber, {
    //     state: { key1: claimStatus, key2: claim.IntimationDateStr },
    //   })
    // } 
    onClick={() =>
      navigate(`/motor-insurance/claim-details/${urlSafeEncryptedValue}`)
    }
    className="flex items-center gap-4 shrink-0 border bg-[#F6F8F9] p-4 rounded-2xl border-solid border-[#DDE2E4] max767:w-full cursor-pointer max767:mb-4 justify-between">
      <div className="flex flex-col gap-2">
        <p
          className={`claim-item text-white text-center text-xs not-italic font-semibold leading-4 tracking-[0.12px] uppercase h-5 justify-end items-center gap-4 px-2 py-0 rounded-[10px] text-left ${claimStatus}`}
        >
          {claimStatus}
        </p>
        <h1 className="text-[#1A2024] text-xl not-italic font-semibold leading-[normal] max767:text-2xl">
          {claim.CaseNumber}
        </h1>
        <div className="flex items-center gap-1">
          <p className="text-[#1A2024] text-sm not-italic font-normal leading-[normal]">
            {claim.IntimationDateStr}
          </p>
        </div>
      </div>
      <img
        src={ChevronRight}
        alt="ChevronRight"
        className="cursor-pointer rtl:rotate-180"
   
      />
    </div>

    // <div className="flex w-60 flex-col items-start gap-4 shrink-0 border bg-[#F6F8F9] p-4 rounded-2xl border-solid border-[#DDE2E4] max767:w-full max767:mb-4">
    //   <p className={`claim-item ${claim.status}`}> {claim.status}</p>
    //   <div className="flex flex-col items-start gap-1 self-stretch">
    //     <p className="text-[#1A2024] text-xs not-italic font-semibold leading-[normal]">
    //       {claim.carMakeModel}
    //     </p>

    //     <h1 className="text-[#1A2024] text-xl not-italic font-semibold leading-[normal] max767:text-2xl">
    //       {claim.plateNumber}
    //     </h1>
    //   </div>
    //   <div className="flex flex-col items-start gap-1 self-stretch">
    //     <p className="text-[#1A2024] text-sm not-italic font-normal leading-[normal]">
    //       {" "}
    //       {claim.policyNumber}
    //     </p>
    //     <p className="text-[#1A2024] text-xs not-italic font-normal leading-[normal]">
    //       {claim.claimDate}
    //     </p>
    //   </div>
    // </div>
  );
};

export default ActiveClaimCard;
