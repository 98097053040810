import React from 'react'
import './documentsReadyInfo.css'
import InfoCard from './infoCard'
import { PageState } from '../../NewMotorPolicy'
import { useTranslation } from 'react-i18next'
import BottomStickyBtnLayout from '../../Layouts/BottomStickyBtnLayout'
import aboutcaricon from '../../../../../assets/images/directions_car.svg';
import documentsicon from '../../../../../assets/images/description.svg';
import NewMotorJourneyLayout from '../../Layouts/NewMotorJourneyLayout'
const DocumentsReadyInfo = ({
  continueClickHandler,
  hide_back_btn,
  show_close_btn,
}) => {
  const { t } = useTranslation();
  return (
    <div className="documentsReadyInfo w-full">
      <NewMotorJourneyLayout
        show_close_btn={show_close_btn}
        is_hide_back_btn={hide_back_btn}
      >
        <BottomStickyBtnLayout btn_onClick={continueClickHandler}>
          <div className="content w-[32%] max767:w-full mx-auto ipad:min-w-[66%] ">
            <h1 className="text-[#1A2024] text-center text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] max767:text-left">
              {t(
                "motorInsurance.newMotorInsuranceFlow.documentsReadyInfo.title"
              )}
            </h1>
            <p className="text-[#1A2024] text-center text-base not-italic font-normal leading-6 tracking-[-0.16px] mb-6 max767:text-left">
              {t(
                "motorInsurance.newMotorInsuranceFlow.documentsReadyInfo.description"
              )}
            </p>
            <div className="infoCards mb-6">
              <InfoCard
                title={t(
                  "motorInsurance.newMotorInsuranceFlow.documentsReadyInfo.aboutCar.title"
                )}
                title_icon={aboutcaricon}
                infoData={[
                  {
                    en_label: "Plate Number and Type",
                    ar_label: "رقم اللوحة ونوعها",
                  },
                  {
                    en_label: "Car Make and Model",
                    ar_label: "ماركة السيارة والطراز",
                  },
                  {
                    en_label: "Car Body Type and Number of Seats",
                    ar_label: "نوع هيكل السيارة وعدد المقاعد",
                  },
                  {
                    en_label: "Engine Capacity and Chassis Number (VIN)",
                    ar_label: "سعة المحرك ورقم الشاسيه (VIN)",
                  },
                ]}
              />
              <InfoCard
                title={t(
                  "motorInsurance.newMotorInsuranceFlow.documentsReadyInfo.documents.title"
                )}
                title_icon={documentsicon}
                infoData={[
                  {
                    en_label: "CPR Bahraini National ID",
                    ar_label: "الرقم الشخصي",
                  },
                  {
                    en_label: "Vehicle Ownership Certificate",
                    ar_label: "شهادة ملكية السيارة",
                  },
                  {
                    en_label: "Car Invoice",
                    ar_label: "فاتورة السيارة",
                  },
                  {
                    en_label: "Bank Information and Letter",
                    ar_label: "معلومات البنك وخطاب",
                  },
                ]}
              />
            </div>
          </div>
        </BottomStickyBtnLayout>
      </NewMotorJourneyLayout>
    </div>
  );
};

export default DocumentsReadyInfo

