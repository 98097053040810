import React from "react";
import pictureaspdf from '../../../../../../../assets/images/picture_as_pdf.svg'
import Loader from "../../../../../../../components/common/Loader/Loader";
import useLanguageTranslate from "../../../../../../../hooks/useLanguageTranslate";
const FundInfoCard = ({
  fundName,
  ISIN,
  assetClass,
  category,
  description,
  pdfLink,
  htmlToImageConvert,
  pdfLoading
}) => {

  const translate = useLanguageTranslate()
  return (

    <div className="flex justify-between p-8 border bg-white rounded-[10px] border-solid border-[#DDE2E4] max767:flex-col max767:p-4">
      <div className="title w-[85%] max-w-[100%] ipad:w-[70%] max767:w-[100%]">
        <div className="title w-[55%] max-w-[100%] ipad:w-[90%] max767:w-[100%]">
          <h5 className="text-[#6E7C87] text-xs not-italic font-semibold leading-[normal] mb-2">{translate("Fund Name","اسم الصندوق")}</h5>
          <h2 className="text-[#1A2024] text-base not-italic font-semibold leading-[normal] tracking-[-0.16px]">{fundName}</h2>
          <p className="mt-4 text-[#48535B] text-sm not-italic font-normal leading-5">{description}</p>
        </div>
      </div>
      <div className="title w-[15%] max-w-[100%] ipad:w-[30%] max767:w-[100%]">
        <div className="text-sm ">
          <p className="text-[#6E7C87] text-xs not-italic font-semibold leading-[normal] tracking-[-0.072px] mb-1">{translate("Fund Code ISIN","رمز الصندوق ISIN")}</p>
          <p className="text-[#1A2024] text-base not-italic font-semibold leading-[normal] tracking-[-0.16px] mb-3"> {ISIN}</p>
          <p className="text-[#6E7C87] text-xs not-italic font-semibold leading-[normal] tracking-[-0.072px] mb-1">{translate("Asset Class","فئة الأصول")}</p>
          <p className="text-[#1A2024] text-base not-italic font-semibold leading-[normal] tracking-[-0.16px] mb-3"> {assetClass}</p>
          <p className="text-[#6E7C87] text-xs not-italic font-semibold leading-[normal] tracking-[-0.072px] mb-1">{translate("Category","الفئة")}</p>
          <p className="text-[#1A2024] text-base not-italic font-semibold leading-[normal] tracking-[-0.16px] mb-3">{category}</p>
          {/* <div onClick={htmlToImageConvert}
            className="flex w-max transition-all items-center cursor-pointer relative gap-2 border bg-white text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] px-4 py-2 rounded-md border-solid border-[#DDE2E4]"
          >
            <img src={pictureaspdf} alt="" />
            {
              pdfLoading  ? <Loader className="h-[20px] w-[20px] relative ml-[-20px] mr-6" /> : "Download PDF"
            }
          </div> */}

        {!pdfLoading  && (
            <div
              onClick={htmlToImageConvert}
              className="flex w-max transition-all items-center cursor-pointer relative gap-2 border bg-white text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] px-4 py-2 rounded-md border-solid border-[#DDE2E4]"
            >
              <img src={pictureaspdf} alt="" />
              {translate("Download PDF","تحميل PDF")}
            </div>
          )}
        </div>
      </div>
    </div>

  );
};

export default FundInfoCard;
