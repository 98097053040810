import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import RenewMotorLayout from "../../renewalMotorPolicy/renewMotorLayout";
import PlanCard from "../../newMotorPolicy/planPages/planCard";
import { CURRENT_PLAN, plans } from "./data";
import ViewFullListBenefitsModal from "../../newMotorPolicy/planPages/viewFullListBenefitsModal";
import listblackicon from "../../../../assets/images/list-black.svg";
import useChangePlanFlow, {
  CHANGE_PLAN_DOWNGRADE,
  CHANGE_PLAN_UPGRADE,
} from "../../../../hooks/useChangePlanFlow";
import useMotorForm from "../../../../hooks/useMotorFormData";
import { useDispatch, useSelector } from "react-redux";
import {
  getPolicyViewLoading,
  getPolicyViewResponse,
  policyView,
} from "../../../../redux/features/policyDetailsSlice";
import { useParams } from "react-router-dom";
import {
  cancelPolicy,
  getCancellationResponse,
  getQuoteWithRefund,
  getQuoteWithRefundError,
  getQuoteWithRefundLoading,
  getQuoteWithRefundResponse,
  rejectCancelPolicy,
} from "../../../../redux/features/endorsements/cancellationSlice";
import { getPolicyPremiumLoading } from "../../../../redux/features/motor/renewalPremiumSlice";
import SkeletonLoader from "../../../../components/common/Loader/SkeletonLoader";
import { Loader } from "storybook/internal/components";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";

export const CHANGE_PLAN_SELECT_PLAN = "CHANGE_PLAN_SELECT_PLAN";

const SelectNewPlan = () => {
  /* Setting up dispatch to call redux */
  const dispatch = useDispatch();

  /* Setting up variables with appropriate requests to send and response to receive, reading from redux/functions */
  const { policy_id } = useParams();
  const [Channel, setChannel] = useState("online.solidarity");
  const [PolicyNumber, setPolicyNumber] = useState(policy_id);

  const polReq = {
    PolicyNumber,
    Channel,
  };

  // Reading param from url, incase refresh to call the policy view again
  const policyViewResponseDetails = useSelector(getPolicyViewResponse);
  const isPolicyViewLoading = useSelector(getPolicyViewLoading);

  const getQuoteRefundResponse = useSelector(getQuoteWithRefundResponse);
  const getQuoteRefundLoading = useSelector(getQuoteWithRefundLoading);
  const getQuoteRefundError = useSelector(getQuoteWithRefundError);
  const cancellationResponse = useSelector(getCancellationResponse);

  console.log("Policy number is: ", PolicyNumber);
  console.log("Policy View Response is: ", policyViewResponseDetails);
  console.log(
    "Policy view response plan name from outside: ",
    policyViewResponseDetails?.PolicyHeader?.PlanName
  );

  const [selectedPlan, setSelectedPlan] = useState();
  const [viewPlanList, setViewPlanList] = useState(false);
  const [currentPlan, setCurrentPlan] = useState("");
  // const currentPlan = CURRENT_PLAN;

  /* Functions and Variables that are updated with functions/state */
  const { t } = useTranslation();

  const { handleNextSubstep, handleFlowChange } = useChangePlanFlow();
  const { saveMotorDataInRedux } = useMotorForm(
    CHANGE_PLAN_SELECT_PLAN,
    setSelectedPlan
  );

  /* Use Effects, monitor changes to call on [var] change or one time call */
  useEffect(() => {
    if (PolicyNumber) {
      // Calling API /Policy/View
      if (policyViewResponseDetails == null) {
        // Calling API /policy/view
        dispatch(policyView(polReq));
      } else if (
        policyViewResponseDetails?.PolicyHeader?.PolicyNumber != PolicyNumber
      ) {
        // Calling API /policy/view
        dispatch(policyView(polReq));
      }
    }
  }, [policy_id]);

  useEffect(() => {
    console.log(
      "Policy view response plan name: ",
      policyViewResponseDetails?.PolicyHeader?.PlanName
    );
    // Set current plan
    setCurrentPlan(policyViewResponseDetails?.PolicyHeader?.PlanName);

    // console.log('Cancellation status: ', cancellationResponse);
  }, [policyViewResponseDetails]);

  /* Plan selection */
  const planOnClickHandler = async (plan) => {
    console.log("selected plan is: ", plan.code);
    setSelectedPlan(plan);

    try {
      const getQuoteWithRefundRequest = {
        PolicyNumber: policyViewResponseDetails?.PolicyHeader?.PolicyNumber,
        Reason:
          "Plan to be changed by cancelling previous policy and issuing new quote.",
        NewPlan: plan.code,
      };

      // Calling API /Meej/GetQuoteWithRefund
      const quoteRefundAction = dispatch(
        getQuoteWithRefund(getQuoteWithRefundRequest)
      );
      const quoteRefundResult = await unwrapResult(quoteRefundAction);
      console.log("quote refund result", quoteRefundResult);

      if (quoteRefundResult) {
        saveMotorDataInRedux(selectedPlan);
      }
    } catch (error) {
      // Handle any errors from the API call
      // alert("Error retrieving vehicle data:", error);
      toast.error("Error while changing plan:", error);
    }
  };

  /* Next */
  const continueClickHanlder = () => {
    //Cancel the policy (Making it cancellation in progress)
    const cancellationRequest = {
      PolicyNumber: policyViewResponseDetails?.PolicyHeader?.PolicyNumber,
      Reason: "Change plan is requested for this plan.",
    };
    // Calling API /Meej/CancelActivePolicy
    // dispatch(cancelPolicy(cancellationRequest));

    if (currentPlan?.weightage > selectedPlan.weightage) {
      handleFlowChange(CHANGE_PLAN_DOWNGRADE, 1);
    } else {
      handleFlowChange(CHANGE_PLAN_UPGRADE, 1);
    }
  };

  return (
    <RenewMotorLayout
      is_hide_back_btn={true}
      show_close_btn
      btn_disabled={!selectedPlan}
      show_summary={selectedPlan}
      btn_onClick={continueClickHanlder}
    >
      <ViewFullListBenefitsModal
        viewPlanList={viewPlanList}
        setViewPlanList={setViewPlanList}
      />
      <div className="changeyourplan p-4 pt-0 w-[33%] max-350:w-[100%] max767:w-[100%] ipad:w-[55%] mx-auto max767:p-5">
        <TitleDescription
          title={t("motorInsurance.changePlanMotorPolicy.selectNewPlan.title")}
          description={t(
            "motorInsurance.changePlanMotorPolicy.selectNewPlan.description"
          )}
        />
      </div>
      <div className="selectyourcard">
        <div className="max767:w-full max767:overflow-x-scroll max767:pt-0 ipad:overflow-x-hidden ipad:w-[70%] mx-auto">
          <div className="flex gap-4 mb-4 max767:w-[1024px] max767:pl-5 max767:pr-0 max767:py-0 ipad:w-[536px] ipad:justify-end ipad:items-center ipad:content-center ipad:max-auto ipad:my-0 ipad:flex-wrap justify-center">
            {isPolicyViewLoading ? (
              <Loader /> // Show Loader when isPolicyViewLoading is true
            ) : (
              plans.map((plan) => (
                <PlanCard
                  key={plan.code} // Add a unique key to each PlanCard
                  planOnClickHandler={
                    getQuoteRefundLoading ? () => {} : planOnClickHandler
                  }
                  selectedPlan={selectedPlan}
                  plan={plan}
                  isCurrentPlan={plan.code == currentPlan}
                  showSelectButton
                />
              ))
            )}
          </div>
          <button
            className="flex items-center mx-auto gap-2 border bg-white px-4 py-2 rounded-[10px] border-solid border-[#DDE2E4] text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] ipad:mt-4 max767:hidden"
            onClick={() => setViewPlanList(!viewPlanList)}
          >
            <img src={listblackicon} alt="altText" />
            {t(
              "motorInsurance.newMotorInsuranceFlow.planSelector.viewFullList"
            )}
          </button>
        </div>
        <button
          className="flex items-center mx-auto gap-2 border bg-white px-4 py-2 rounded-[10px] border-solid border-[#DDE2E4] text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] ipad:mt-4 hidden max767:block max767:flex"
          onClick={() => setViewPlanList(!viewPlanList)}
        >
          <img src={listblackicon} alt="altText" />
          {t("motorInsurance.newMotorInsuranceFlow.planSelector.viewFullList")}
        </button>
      </div>
    </RenewMotorLayout>
  );
};

export default SelectNewPlan;
