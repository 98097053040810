import { createSlice } from "@reduxjs/toolkit";
import { FLOW_TYPE_EXPRESS_SHIELD } from "../../pages/buyOnline/expressShield/data";
const initialState = {
  flowType: FLOW_TYPE_EXPRESS_SHIELD,
  currentStepIndex: 0,
  currentSubstepIndex: 0,
};

const expressShieldFlowSlice = createSlice({
  name: "expressShieldFlow",
  initialState,
  reducers: {
    setFlowType: (state, action) => {
      const data = action.payload;
      state.flowType = data.type;
      state.currentStepIndex = data.currentStepIndex || 0;
      state.currentSubstepIndex = data.currentSubstepIndex || 0;
    },
    setCurrentStepIndex: (state, action) => {
      state.currentStepIndex = action.payload;
      state.currentSubstepIndex = 0;
    },
    setCurrentSubstepIndex: (state, action) => {
      state.currentSubstepIndex = action.payload;
    },
  },
});

export const selectExpressShieldFlow = (store) => store.expressShieldFlow;
export const selectFlowType = (store) => store?.expressShieldFlow?.flowType;
export const selectCurrentStepIndex = (store) =>
  store?.expressShieldFlow?.currentStepIndex;
export const selectCurrentSubstepIndex = (store) =>
  store.expressShieldFlow?.currentSubstepIndex;

export const { setFlowType, setCurrentStepIndex, setCurrentSubstepIndex } =
  expressShieldFlowSlice.actions;
export default expressShieldFlowSlice.reducer;
