import React, { useState } from "react";
import ExpressShieldPlanLayout from "../expressShieldPlanLayout";
import FormTabs from "../../../../components/common/tabs/formTabs";
import useValidation from "../../../../hooks/useValidation";
import { useTranslation } from "react-i18next";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import Input from "../../../../components/common/Input/Input";
import SelectInput from "../../../../components/common/selectInput/selectInput";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import ReviewYourApplication from "./reviewApplication";
import useLifeFormData from "../../../../hooks/useLifeFormData";
import { COUNTRY_SELECT_DATA, LEAD_INFO } from "./data";
import useUpdateLead from "../../../../hooks/BuyOnlineExpressShield/useUpdateLead";

export const genderTabs = [
  { label_en: "Male", label_ar: "ذكر", type: "M" },
  { label_en: "Female", label_ar: "أنثى", type: "F" },
];

export const EXPRESS_SHEILD_OTHER_DETAILS = "EXPRESS_SHEILD_OTHER_DETAILS"

const OtherDetails = ({ onSuccess }) => {
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const [formData, setFormData] = useState({ height: "", weight: "", });
  const { errors, validateFields } = useValidation();
  const [bmiError, setBmiError] = useState("");
  const [invalidBMIPopup, setInvalidBMIPopup] = useState(false);
  const {updateLeadHandler,leadLoader} = useUpdateLead()
  const { saveLifeDataInRedux } = useLifeFormData(
    EXPRESS_SHEILD_OTHER_DETAILS,
    setFormData
  );
  const toggleInvalidBMIPopup = () => {
    setInvalidBMIPopup(!invalidBMIPopup);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onTabClick = (value, name) => {
    let update = { ...formData, [name]: value };
    setFormData(update);
    if (errors?.[name]) {
      validateForm(update);
    }
  };

  const validateForm = (data) => {
    let isValid = validateFields(
      [
        {
          name: "gender",
          validations: ["required"],
        },
        {
          name: "height",
          validations: ["required"],
        },
        {
          name: "nationality",
          validations: ["select"],
        },
        {
          name: "weight",
          validations: ["required"],
        },
      ],
      data ? data : formData,
    );
    return isValid;
  };

  // Function to calculate BMI
  const calculateBMI = (weight, height) => {
    if (!weight || !height) return 0;
    const heightInMeters = height / 100; // Convert height from cm to meters
    return (weight / heightInMeters ** 2).toFixed(2); // Formula: weight (kg) / height (m)^2
  };

  const nextClickHandler = async () => {
    const { height, weight } = formData;
    if (validateForm()) {
      let payload = {
        Info: `${LEAD_INFO.otherDetails} ${formData?.nationality}`,
      }
      updateLeadHandler(payload)
      const bmi = calculateBMI(weight, height);
      saveLifeDataInRedux(formData)
      if (bmi >= 30 || bmi <= 18.5) {
        setInvalidBMIPopup(true);
      } else if (bmi >= 18.5 && bmi <= 30) {
        setBmiError(""); // Reset error message
        
        onSuccess();

      } else {
        setBmiError(`Your BMI is ${bmi}. Please enter valid weight and height.`);
      }
    }
  };

  return (
    <ExpressShieldPlanLayout
      is_bottom_sticky_layout = {!invalidBMIPopup}
      btn_onClick={nextClickHandler}
    >
      <div className="w-[32%] max767:w-full mx-auto ipad:w-[60%]">
        <TitleDescription
          title={t("buyOnline.expressShieldFlow.otherDetails.title")}
          description={t(
            "buyOnline.expressShieldFlow.otherDetails.description"
          )}
        />
        <SelectInput
          name="nationality"
          formData={formData}
          label={t("buyOnline.expressShieldFlow.otherDetails.nationality")}
          options_data={COUNTRY_SELECT_DATA}
          error={errors.nationality}
          handleChange={handleChange} className="nationlaityselect"
        />
        <FormTabs
          label={t("buyOnline.expressShieldFlow.otherDetails.gender")}
          tabs={genderTabs}
          onTabClick={(type) => onTabClick(type, "gender")}
          activeTab={formData.gender}
          error={errors?.gender}
        />
        <div className="flex items-start gap-4 flex-[1_0_0] buyonlineinputs">
          <div className="flex flex-col items-start gap-1 flex-[1_0_0]">
          <Input
            label={t("buyOnline.expressShieldFlow.otherDetails.height")}
            type="number"
            name="height"
            className="border bg-white border-solid border-[#B0BABF] w-[240px]"
            value={formData.height}
            error={errors.height}
            handleChange={handleChange}
          />
          </div>
          <div className="flex items-start gap-1 flex-[1_0_0]">
          <Input
            label={t("buyOnline.expressShieldFlow.otherDetails.weight")}
            type="number"
            name="weight"
            className="border bg-white border-solid border-[#B0BABF] w-[240px] max767:w-[172px] max767:max-w-[100%]"
            value={formData.weight}
            error={errors.weight}
            handleChange={handleChange}
          />
          </div>
          {bmiError && <p className="text-red-500 mt-2">{bmiError}</p>}
        </div>
      </div>
      <ReviewYourApplication
        show={invalidBMIPopup}
        onClose={toggleInvalidBMIPopup}
      />
    </ExpressShieldPlanLayout>
  );
};

export default OtherDetails;
