import React from "react";
import AlertComponent from "../../../pages/motorInsurancePages/transferPolicyFlow/alertComponent";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { currencyFormatterWithCountryCode } from "../../../utils";

const MobileFundsPremiumTableAlert = ({
  tableType,
  total,
  errorMsg,
  fundsData = [],
  allocationTotal,
}) => {
  const translate = useLanguageTranslate();
  return (
    <div>
      {fundsData?.length > 0 && (
        <div className="flex justify-between">
          <span className="text-[#252C32] text-base not-italic font-semibold leading-6 tracking-[-0.096px]">
            {translate("Total ", "المجمو")}{" "}
          </span>
          {tableType == "redirecting" && (
            <span
              className={`${
                total != 100 ? "invalid" : "valid"
              }text-[#252C32] text-base not-italic font-semibold leading-6 tracking-[-0.096px]`}
            >
              {translate(`${total || 0}% `, `${total || 0}%`)}
            </span>
          )}
          {tableType == "allocation" && (
            <span
              className={`text-[#252C32] text-base not-italic font-semibold leading-6 tracking-[-0.096px]`}
            >
              {translate(
                currencyFormatterWithCountryCode(
                  `BHD ${total || 0}`,
                  `دينار بحريني ${total || 0}`
                )
              )}
            </span>
          )}
        </div>
      )}
      {tableType == "redirecting" && fundsData?.length > 0 && (
        <div className="flex items-center mt-4">
          <div className="ml-auto text-red-500 text-sm max767:gap-3 max767:mb-2 max767:grid max767:ml-0 max767:w-full">
            {fundsData?.length > 10 && (
              <AlertComponent
                className="mb-2"
                title={`${translate(
                  "Maximum Funds is 10 per policy, please remove",
                  "الحد الأقصى للصناديق هو 10 لكل بوليصة، يرجى إزالة"
                )} ${fundsData.length - 10} ${translate("funds", "صناديق")}`}
              />
            )}
            {total > 100 && (
              <AlertComponent
                title={translate(
                  "Total allocation is more than 100%",
                  "إجمالي التخصيص يزيد عن 100٪"
                )}
              />
            )}
            {fundsData?.length > 0 && total < 100 && (
              <AlertComponent
                title={translate(
                  "Total allocation is less than 100% ",
                  "إجمالي التخصيص أقل من 100٪"
                )}
              />
            )}
            {errorMsg && <AlertComponent title={errorMsg} />}
          </div>
        </div>
      )}
      {tableType == "allocation" && fundsData?.length > 0 && errorMsg && (
        <div className="mt-4 text-red-500 text-sm">
          <AlertComponent
            title={errorMsg}
            label={translate(
              "You can remove a fund if you want to adjust your allocation.",
              "يمكنك حذف هذا الصندوق إذا كنت ترغب في تعديل تخصيصك."
            )}
          />
        </div>
      )}
      {tableType == "allocation" && total != allocationTotal && (
        <div className="mt-4 text-red-500 text-sm">
          <AlertComponent
            title={`${translate(
              "Total allocation should be",
              "يجب أن يكون إجمالي التخصيص"
            )}  ${currencyFormatterWithCountryCode(
              `${translate("BHD", "دينار بحريني")} ${allocationTotal}`
            )}`}
          />
        </div>
      )}
    </div>
  );
};
export default MobileFundsPremiumTableAlert;
