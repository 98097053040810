import React, { useState } from "react";

import {
  createColumnHelper,
  useReactTable,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import "./selectFundsTable.css";
import FundsPreviewPopup from "./fundsPreviewPopup/fundsPreviewPopup";
import useLanguageTranslate from "../../../../../../hooks/useLanguageTranslate";
import searchimg from "../../../../../../assets/images/graySearch.svg";
import {
  assetClassesOptions,
  categoriesOptions,
  currenciesOptions,
  geographicFocusOptions,
} from "./data";
import CustomCheckbox from "../../../../../../components/common/customCheckbox/customCheckbox";

// Static data

const SelectFundsTable = ({ rowSelection, setRowSelection, data }) => {
  const translate = useLanguageTranslate();
  const [columnFilters, setColumnFilters] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.display({
      id: "select",
      meta: { sticky: true },
      header: ({ table }) => (
        <CustomCheckbox
          className="mb-[0] mt-[0] px-[0] py-[0]"
          isChecked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => (
        <CustomCheckbox
          className="mb-[0] mt-[0] px-[0] py-[0]"
          isChecked={row.getIsSelected()}
          isDisabled={!row.getCanSelect()}
          onChange={row.getToggleSelectedHandler()}
        />
      ),
    }),
    columnHelper.accessor("fund_name", {
      header: translate("Fund Name", "اسم الصندوق"),
      meta: { sticky: true, name: "fund_name" },
    }),
    columnHelper.accessor("isin", {
      header: translate("id/ISIN", "معرّف/رقم ISIN"),
    }),
    columnHelper.accessor("category", {
      header: translate("Category", "الفئة"),
    }),
    columnHelper.accessor("currency", {
      header: translate("Currency", "العملة"),
    }),
    columnHelper.accessor("managementApproach", {
      header: translate("Management Approach", "نهج الإدارة"),
    }),
    columnHelper.accessor("geographicFocus", {
      header: translate("Geographic Focus", "التركيز الجغرافي"),
    }),
    columnHelper.accessor("assetClass", {
      header: translate("Asset Class", "فئة الأصول"),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      columnFilters,
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true,
  });

  const uniqueCategories = categoriesOptions;
  const uniqueCurrencies = currenciesOptions;
  const uniqueGeographicFocus = geographicFocusOptions;
  const uniqueAssetClass = assetClassesOptions;

  const [showFundPreview, setShowFundPreview] = useState(null);
  const fundClickHandler = (fund) => {
    setShowFundPreview(fund?.original);
  };

  return (
    <>
      {showFundPreview && (
        <FundsPreviewPopup
          show={showFundPreview}
          setShow={setShowFundPreview}
        />
      )}
      <div className="w-auto ">
        <div className="ipad:flex-col ipad:w-full mb-4 hidden ipad:block ipad:p-5 ipad:mb-0">
          <input
            type="text"
            placeholder={translate(
              "Search for fund name",
              "ابحث عن اسم الصندوق"
            )}
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
              setColumnFilters((old) => [
                { id: "fund_name", value: e.target.value },
              ]);
            }}
            className="flex h-8 w-full items-start border bg-white max-w-full pl-1 pr-3 py-1 rounded-md border-solid border-[#DDE2E4] text-[#9AA6AC] text-sm not-italic font-normal placeholder:text-[#9AA6AC] placeholder:text-sm placeholder:not-italic "
          />
        </div>
        <div className="mb-2 flex gap-4 justify-between ipad:pl-4 ipad:pr-4 ipad:gap-1">
          <div className="relative">
            <input
              type="text"
              placeholder={translate(
                "Search for fund name",
                "ابحث عن اسم الصندوق"
              )}
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                setColumnFilters((old) => [
                  { id: "fund_name", value: e.target.value },
                ]);
              }}
              className="pl-10 ipad:hidden flex h-8 items-start border bg-white w-[428px] max-w-full pl-1 pr-3 py-1 rounded-md border-solid border-[#DDE2E4] text-[#9AA6AC] text-sm not-italic font-normal placeholder:text-[#9AA6AC] placeholder:text-sm placeholder:not-italic "
            />
            <img
              src={searchimg}
              alt="search icon"
              className="absolute left-2 top-1"
            />
          </div>
          <div className="flex items-center text-[#9AA6AC] text-sm not-italic font-normal leading-6 border bg-white rounded-md border-solid border-[#DDE2E4] pl-3 pr-1 h-[32px]">
            <p>{translate("Categories", "الفئات")}</p>
            <select
              onChange={(e) => {
                const value = e.target.value;
                setColumnFilters((old) =>
                  value
                    ? [{ id: "category", value }]
                    : old.filter((f) => f.id != "category")
                );
              }}
              className="h-[30px] w-[76px] pl-[15px] text-sm not-italic font-normal text-[#252C32]"
            >
              <option value="">{translate(" All", "الكل")}</option>
              {uniqueCategories.map((category) => (
                <option key={category.type} value={category.type}>
                  {translate(category.text_en, category.text_ar)}
                </option>
              ))}
            </select>
          </div>

          {/* Currency Filter Dropdown */}
          <div className="flex items-center text-[#9AA6AC] text-sm not-italic font-normal leading-6 border bg-white rounded-md border-solid border-[#DDE2E4] pl-3 pr-1 h-[32px]">
            <p>{translate("Currency", "العملة")}</p>
            <select
              onChange={(e) => {
                const value = e.target.value;
                setColumnFilters((old) =>
                  value
                    ? [{ id: "currency", value }]
                    : old.filter((f) => f.id != "currency")
                );
              }}
              className="h-[30px] w-[76px] pl-[15px] text-sm not-italic font-normal text-[#252C32]"
            >
              <option value="">{translate(" All", "الكل")}</option>
              {uniqueCurrencies.map((currency) => (
                <option key={currency.type} value={currency.type}>
                  {translate(currency.text_en, currency.text_ar)}
                </option>
              ))}
            </select>
          </div>

          {/* Geographic Focus Filter Dropdown */}
          <div className="flex items-center text-[#9AA6AC] text-sm not-italic font-normal leading-6 border bg-white rounded-md border-solid border-[#DDE2E4] pl-3 pr-1 h-[32px]">
            <p>{translate("Geographic Focus", "التركيز الجغرافي")}</p>
            <select
              onChange={(e) => {
                const value = e.target.value;
                setColumnFilters((old) =>
                  value
                    ? [{ id: "geographicFocus", value }]
                    : old.filter((f) => f.id != "geographicFocus")
                );
              }}
              className="h-[30px] w-[76px] pl-[15px] text-sm not-italic font-normal text-[#252C32]"
            >
              <option value="">{translate(" All", "الكل")}</option>
              {uniqueGeographicFocus.map((focus) => (
                <option key={focus.type} value={focus.type}>
                  {translate(focus.text_en, focus.text_ar)}
                </option>
              ))}
            </select>
          </div>

          {/* Asset Class Filter Dropdown */}
          <div className="flex items-center text-[#9AA6AC] text-sm not-italic font-normal leading-6 border bg-white rounded-md border-solid border-[#DDE2E4] pl-3 pr-1 h-[32px]">
            <p>{translate("Asset Class", "فئة الأصول")}</p>
            <select
              onChange={(e) => {
                const value = e.target.value;
                setColumnFilters((old) =>
                  value
                    ? [{ id: "assetClass", value }]
                    : old.filter((f) => f.id != "assetClass")
                );
              }}
              className="h-[30px] w-[76px] pl-[15px] text-sm not-italic font-normal text-[#252C32]"
            >
              <option value="">{translate(" All", "الكل")}</option>
              {uniqueAssetClass.map((asset) => (
                <option key={asset.type} value={asset.type}>
                  {translate(asset.text_en, asset.text_ar)}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          className="overflow-x-auto w-[100%] addfunds
          "
        >
          <table className="w-[100%] border-separate border border-solid border-[#DDE2E4] rounded-[10px] border-spacing-0 ipad:rounded-none">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr
                  key={headerGroup.id}
                  className="bg-[#F6F8F9] selectfundtble"
                >
                  {headerGroup.headers.map((header) => (
                    <th
                      className={
                        header.column.columnDef.meta?.sticky
                          ? "sticky-column justify-center items-center px-4 py-2.5 text-left border-b border-solid text-[#6E7C87] text-xs not-italic font-semibold leading-6 tracking-[-0.072px]"
                          : "justify-center items-center px-4 py-2.5 border-b border-solid border-[#DDE2E4] border-spacing-0 text-left text-[#6E7C87] text-xs not-italic font-semibold leading-6 tracking-[-0.072px]"
                      }
                      key={header.id}
                    >
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "cursor-pointer"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {header.column.getIsSorted() == "asc"
                          ? " 🔼"
                          : header.column.getIsSorted() == "desc"
                          ? " 🔽"
                          : ""}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="border-separate border-b border-solid border-[#DDE2E4] border-spacing-0 hover:bg-[#F6F8F9] hover:text-[#00719A]"
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      onClick={
                        cell.column.columnDef.meta?.name == "fund_name"
                          ? () => fundClickHandler(row)
                          : () => {}
                      }
                      className={
                        cell.column.columnDef.meta?.sticky
                          ? "sticky-column justify-center items-center px-4 py-2.5 left-[4px] text-left hover:bg-[#F6F8F9] hover:text-[#00719A] text-sm not-italic font-normal "
                          : "border-b border-solid border-separate border-spacing-0 justify-center items-center px-4 py-2.5 text-left hover:bg-[#F6F8F9] text-sm not-italic font-normal"
                      }
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default SelectFundsTable;
