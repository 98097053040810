import React from "react";
import { useTranslation } from "react-i18next";

const EditableField = ({
  value,
  onChange,
  isEditing,
  onSave,
  onEdit,
  editIcon,
  isMobile,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      {isEditing ? (
        <div className="flex items-center max767:flex-col mt-3">
          <input
            type="text"
            value={value}
            onChange={onChange}
            className="editinput bg-white w-[286px] h-8 text-2xl not-italic font-semibold rounded-md border-2 border-solid border-[#5B6871] block p-2.5 mr-2"
          />
          <button
            onClick={onSave}
            className="flex flex-col editbtnblue items-center gap-2.5 rounded border bg-white text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] px-2 py-0 border-solid border-[#DDE2E4]"
          >
            {t("motorInsurance.policyOverview.save")}
          </button>
        </div>
      ) : (
        <div className="flex items-center ">
          <span className="text-[#252C32] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mt-2.5 mb-0 mx-0 max767:mt-0 flex">
            {value}
            {/* {!isMobile && (
            <button
              onClick={onEdit}
              className="mx-2 text-gray-500 cursor-pointer"
            >
              <img src={editIcon} alt="edit" className=" ml-3" />
            </button>
          )} */}
          </span>
         
        </div>
      )}
    </div>
  );
};

export default EditableField;
