import React from 'react'
import './Loader.css'
import loader from '../../../assets/images/Spinner.svg';
const Loader = ({className}) => {
  return (
    <div className={`loader w-[40px] h-[40px] z-[99999] absolute max767:-translate-x-2/4 max767:-translate-y-2/4 max767:m-0 left-2/4 max767:top-[30%] ${className}`}><img src={loader} alt="loader"/>

    </div>
  )
}

export default Loader