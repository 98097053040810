import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import plusicon from "../../../assets/images/grayplus.svg";
import minusicon from "../../../assets/images/graycross.svg";

function FaqBox({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleAnswer = () => {
    setIsOpen(!isOpen);
  };

  // Close the FAQ box whenever the page changes
  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  return (
    <li
      className="faq-box border bg-white rounded-[10px] border-solid border-[#B0BABF] list-none px-6 py-4 mb-2 "
    >
      <h4
        className="faq-question flex justify-between items-center cursor-pointer"
        onClick={toggleAnswer}
        data-testid="faq-question"
      >
        <span className="text-black text-base not-italic font-medium leading-[150%]">{question}</span>
        <span className="text-xl">
          {isOpen ? (
            <span className="minus">
              <img src={minusicon} alt="Plus Icon" className="icon max767:max-w-[32px] max767:h-[32px]" />
            </span>
          ) : (
            <span className="plus">
              <img src={plusicon} alt="Minus Icon" className="icon max767:max-w-[32px] max767:h-[32px]" />
            </span>
          )}
        </span>
      </h4>
      {isOpen && (
        <div className="faq-answer mt-2 text-black text-base not-italic font-normal leading-[150%]" data-testid="faq-answer">
          {answer?.split('<br>')?.map(each=> <p className="min-h-3">{each}</p> )}
        </div>
      )}
    </li>
  );
}

export default FaqBox;
