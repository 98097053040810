import React, { useState } from "react";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import Button from "../../../../components/common/Button/Button";
import { useTranslation } from "react-i18next";
import FormTabs from "../../../../components/common/tabs/formTabs";
import useScreenResolution from "../../../../hooks/useScreenResolution";
import { useNavigate, useParams } from "react-router-dom";
import Back_Btndark from "../../../../assets/images/greenbackarrow.svg";
import backarrow from "../../../../assets/images/back-arrow-left.svg";
import artWrork from "../../../../assets/images/artwork.svg";
import CryptoJS from "crypto-js";

const ReasonforReection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mobile } = useScreenResolution();
  const [activeTab, setActiveTab] = useState("Incorrect Offer");
  const [counterOffer, setCounterOffer] = useState("");
  const [error, setError] = useState("");
  const { claim_id } = useParams();
  const valueToEncrypt = claim_id;

  // Encryption logic
  const secretKey = "i-hate-meej";
  const encryptedValue = CryptoJS.AES.encrypt(
    valueToEncrypt,
    secretKey
  ).toString();

  // Convert to URL-safe format
  const urlSafeEncryptedValue = encryptedValue
    .split("+")
    .join("-") // Replace '+' with '-'
    .split("/")
    .join("_") // Replace '/' with '_'
    .split("=")
    .join(""); // Remove '=' padding
  const tabs = [
    {
      type: "Incorrect Offer",
      label_en: "Incorrect Offer",
      label_ar: "عرض غير صحيح",
    },
    {
      type: "Required Repairs",
      label_en: "Required Repairs",
      label_ar: "الإصلاحات المطلوبة",
    },
  ];
  const handleTabClick = (tabType) => {
    setActiveTab(tabType);
    setCounterOffer("");
    setError("");
  };

  const handleCounterOfferChange = (e) => {
    setCounterOffer(e.target.value);
  };

  const handleSubmit = () => {
    if (activeTab =="Incorrect Offer" && !counterOffer) {
      setError(t("validation.required"));
      return;
    }

    navigate(`/motor-insurance/claim-details/${urlSafeEncryptedValue}`);
  };
  return (
    <div className="mx-auto bg-white max767:p-5 w-[500px] max-w-[100%] mt-[5%] ipad:mt-[11%] max767:mt-[10%]">
      {!mobile ? (
        <button
          className="backButton bg-navy-blue py-2 rounded mt-20 flex absolute left-[26px] top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:bg-[#F6F8F9] max767:w-10 max767:h-10 max767:text-center max767:justify-center max767:rounded-3xl max767:mt-12 max767:ml-[-11px] rtl:right-[26px]"
          onClick={() =>
            navigate(`/motor-insurance/claim-details/${urlSafeEncryptedValue}`)
          }
        >
          <img src={backarrow} alt="Back arrow" className="1 rtl:rotate-180" />
          <span className="back-text inline max767:hidden rtl:mr-1">
            {t("motorInsurance.trackClaimFlow.garageSelection.backButton")}
          </span>
        </button>
      ) : (
        <img
          src={Back_Btndark}
          alt="Back"
          className="max767:visible w-10 h-10 max767:mb-5"
          onClick={() => navigate(`/motor-insurance/claim-details/${urlSafeEncryptedValue}`)}
        />
      )}
      <div className="max767:hidden">
        <TitleDescription
          title={t("motorInsurance.trackClaimFlow.resonForRejection.title")}
          description={t(
            "motorInsurance.trackClaimFlow.resonForRejection.description"
          )}
        />
      </div>
      <div className="max767:block hidden max767:mb-6 ">
        <TitleDescription
          title={t("motorInsurance.trackClaimFlow.resonForRejection.title")}
          description={t(
            "motorInsurance.trackClaimFlow.resonForRejection.description"
          )}
        />
      </div>
      <FormTabs
        tabs={tabs}
        activeTab={activeTab}
        onTabClick={handleTabClick}
        label={t("motorInsurance.trackClaimFlow.resonForRejection.title")}
        // error={error}
        className="max767:pt-6"
      />
      {activeTab =="Incorrect Offer" && (
        <div className="mb-4">
          <label className="block text-[#48535B] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] mb-1">
            {t(
              "motorInsurance.trackClaimFlow.resonForRejection.counterOfferLable"
            )}
          </label>
          <div className="flex items-center border border-gray-300 rounded-[10px] p-2 ">
            <input
              type="text"
              value={counterOffer}
              onChange={handleCounterOfferChange}
              placeholder=""
              className="flex-1 outline-none text-2xl h-[74px] "
            />
            <span className="  flex gap-2  max767:ml-[-20px]">
              <img src={artWrork} alt={""} />
              BHD
            </span>
          </div>
          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        </div>
      )}
      <div className="max767:fixed max767:bottom-2 max767:w-[96%] max767:mx-auto max767:my-0 max767:p-5 max767:border-t-[#DDE2E4] max767:border-t max767:border-solid max767:inset-x-0">
        <Button
          label={t("motorInsurance.trackClaimFlow.resonForRejection.submit")}
          className="flex flex-col items-center gap-2.5 self-stretch bg-[#55C6EF] p-4 rounded-[10px] w-full mt-6 fontlg max767:mt-0"
          onSubmit={handleSubmit}
        />
      </div>
    </div>
  );
};

export default ReasonforReection;
