import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import { essentialCareEndPoints } from "../../../api";

const initialState = {
    data: null,
    isLoading: false,
    error: null,
};

export const getEssentiaCareQuote = createAsyncThunk(
    "getEssentiaCareQuote",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await Axios.post(`${essentialCareEndPoints.essentialCareQuote}`, payload);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const essentialCareQuoteSlice = createSlice({
    name: "Express Shield Slice",
    initialState,
    reducers: {
        clearEssentialCareError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        //  EXPRESS SHIELD SAVE API
        builder.addCase(getEssentiaCareQuote.pending, (state) => {
                state.isLoading = true;
        });
        builder.addCase(getEssentiaCareQuote.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action?.payload;
            state.error = null;
        });
        builder.addCase(getEssentiaCareQuote.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error?.message;
        });
    },
});

// SELECTORS
export const selectEssentialCareWholeData = (store) => store?.essentialCare;
export const selectEssentialCareLoader = (store) => store?.essentialCare.isLoading;
export const selectEssentialCareData = (store) => store?.essentialCare.data;

// ACTIONS TO DISPATCH
export const { clearEssentialCareError } = essentialCareQuoteSlice.actions;

export default essentialCareQuoteSlice.reducer;
