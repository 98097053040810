/* :Page:MotorRenewal: Adjust Sum Insured page */
import React, { useState } from "react";
import useRenewalFlow from "../../../../hooks/useRenewalFlow";
import RenewMotorLayout from "../renewMotorLayout";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useTranslation } from "react-i18next";
import AdjustCarValuePopup from "../../newMotorPolicy/carDetails/carInfo/adjustCarValuePopup";
import InputWithButton from "../../../../components/common/Input/inputWithButton";
import { INCREASE_VALUE } from "../../newMotorPolicy/carDetails/carInfo/data";
import { useDispatch, useSelector } from "react-redux";
import { getPolicyPremiumResponse } from "../../../../redux/features/motor/renewalPremiumSlice";
import { selectNewMotorForm } from "../../../../redux/features/newMotorFormSlice";
import { RENEW_POLICY_SELECTED_POLICY } from "../selectPoliciesPage/selectPoliciesPage";
import { currencyFormatter } from "../../../../utils";
import { RENEW_POLICY_COVERAGE_ENHANCEMENT } from "../enhanceYourCoveragePage/enhanceYourCoveragePage";
import useMotorForm from "../../../../hooks/useMotorFormData";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";

// export const RENEW_POLICY_SELECTED_POLICY = "RENEW_POLICY_SELECTED_POLICY";

const AdjustCarValuePage = () => {
  /* Setting up dispatch to call redux */
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({});

  const { removeMotorFormData } = useMotorForm(
    RENEW_POLICY_COVERAGE_ENHANCEMENT,
    setFormState
  );

  const policyPremiumResponse = useSelector(getPolicyPremiumResponse);

  const translate = useLanguageTranslate();
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    amount: policyPremiumResponse.SumInsured,
    // popup data
    tab_type: INCREASE_VALUE,
    increase_tab_type: null,
    decrease_tab_type: null,
  });
  const [selectedTab, setSelectedTab] = useState({
    amount: (policyPremiumResponse.SumInsured = null
      ? policyPremiumResponse.SumInsured
      : 0),
  });
  const [showAdjustPopup, setShowAdjustPopup] = useState(false);

  const selectedPolicyRenewalInfo =
    useSelector(selectNewMotorForm)?.[RENEW_POLICY_SELECTED_POLICY];

  const { handleNextSubstep } = useRenewalFlow();
  const continueClickHanlder = () => {
    handleNextSubstep();
  };

  const confirmClickHandler = () => {
    setFormData({ ...formData, amount: selectedTab.amount });
  };
  const popupToggle = () => {
    setShowAdjustPopup((state) => !state);
  };

  // Clear final covers list from redux
  useState(() => {
    dispatch(removeMotorFormData);
  }, []);

  return (
    <RenewMotorLayout show_summary btn_onClick={continueClickHanlder}>
      <div className="adjustcarpolicy p-4 w-[33%] max-350:w-[100%] max767:w-[100%] ipad:w-[55%] mx-auto">
        <TitleDescription
          title={t("motorInsurance.renewalPolicyFlow.adjustCarValuePage.title")}
          description={t(
            "motorInsurance.renewalPolicyFlow.adjustCarValuePage.description"
          )}
        />

        <InputWithButton
          label={t(
            "motorInsurance.newMotorInsuranceFlow.carInfoFinancing.carValueLabel"
          )}
          inputValue={`${translate("BHD", "المحدودة")} ${
            currencyFormatter(formData?.amount) || 0
          }`}
          onButtonClick={popupToggle}
          buttonText={t(
            "motorInsurance.newMotorInsuranceFlow.carInfoFinancing.buttonText"
          )}
          isTP={
            selectedPolicyRenewalInfo.PlanName == "TPL" ||
            selectedPolicyRenewalInfo.PlanName == "TPPLUS"
              ? true
              : false
          }
        />
        <div className="AdjustCarValuePopup">
          <AdjustCarValuePopup
            show={showAdjustPopup}
            onClose={popupToggle}
            formData={formData}
            setFormData={setFormData}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            confirmClickHandler={confirmClickHandler}
          />
        </div>
      </div>
    </RenewMotorLayout>
  );
};

export default AdjustCarValuePage;
