import React, { lazy, Suspense, useEffect, useRef } from "react";
import ServiceGrid from "../../components/common/serviceCards/serviceCards";
import faPlusCircleimg from "../../../src/assets/images/newpolicyicon.svg";
import faSyncAltimg from "../../../src/assets/images/Renewal.svg";
import faFileAltimg from "../../../src/assets/images/FileaClaim.svg";
import EmergencyServicesimg from "../../../src/assets/images/EmergencyServices.svg";
import faPlusCircleimgdark from "../../../src/assets/images/newpolicydark.svg";
import faFileAltimgdark from "../../../src/assets/images/FileaClaimdark.svg";
import faSyncAltimgdark from "../../../src/assets/images/Renewaldark.svg";
import EmergencyServicesimgdardk from "../../../src/assets/images/EmergencyServicesdark.svg";
import { Link } from "react-router-dom";
import righticon from "../../assets/images/Chevronright.svg";
import Button from "../../components/common/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOffers,
  selectOffersData,
} from "../../redux/features/offersSlice";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader/Loader";
import MyPolicies from "../dashboard/myPolicies";

const services = [
  {
    icon: faPlusCircleimg,
    micon: faPlusCircleimgdark,
    text: "New Policy",
    text_ar: "سياسة جديدة",
    type: "new_policy",
  },
  {
    icon: faSyncAltimg,
    micon: faFileAltimgdark,
    text: "Renewal",
    text_ar: "تجديد",
    type: "Renewal",
  },
  {
    icon: faFileAltimg,
    micon: faSyncAltimgdark,
    text: "File a Claim",
    text_ar: "تقديم مطالبة",
    type: "Claim",
  },
  {
    icon: EmergencyServicesimg,
    micon: EmergencyServicesimgdardk,
    text: "Emergency Services",
    text_ar: "خدمات الطوارئ",
    type: "Emergency",
    hideInMobile: true
  },
];

const MyPoliciesPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { state } = location;
  const translate = useLanguageTranslate();
  const { i18n, t } = useTranslation();
  const sliderRef1 = useRef(null);
  useEffect(() => {
    dispatch(fetchOffers());
  }, []);

  const navigate = useNavigate();
  const serviceClickHandler = (service) => {
    if (service =="new_policy") {
      navigate("/new-policy");
    } else if (service =="Renewal") {
      navigate("/motor-insurance/renew-motor-policy");
    } else if (service =="Claim") {
      navigate("/file-a-claim");
    } else if (service =="Emergency") {
      navigate("/emergency-services");
    }
  };

  return (
    <Suspense fallback={<Loader />}>
      <div>
        <div className="min-h-scree">
          <div className="myPoliciesContainer p-6">
            <div className="newPolicy flex align-middle justify-between max767:pl-4 max767:pr-4 mb-4 ">
              <div className="breadcrump flex align-middle gap-10">
                <ul className="inline-flex gap-2">
                  <li className="inline-flex">
                    {" "}
                    <Link
                      to="/my-policy"
                      className="text-xs not-italic font-normal leading-4 text-[#6E7C87]"
                    >
                      {t("newPolicy.online_services_label")}
                    </Link>{" "}
                    <span className="ml-2 rtl:ml-0 mr-2">
                      <img src={righticon} alt="right icon" className="rtl:rotate-180"/>
                    </span>
                  </li>
                  <li className="bread-active text-xs not-italic font-normal leading-4 inline-flex text-black">
                    {" "}
                    {t("newPolicy.new_insurance_policies_label")}
                  </li>
                </ul>
              </div>
              {/* <p className="hidden last-login text-xs not-italic font-normal leading-4 text-gray-700">
                {t("commonLabels.last_login_label")}: 03 November 2024 at 05:20
              </p> */}
            </div>

            <div className="flex justify-between alpbaseign-center mt-8 ipad:mb-2 max767:p-4 pb-2 max767:mt-12 max767:pl-0 max767:pr-0">
              <h1 className="text-2xl not-italic font-semibold max767:text-black max767:text-xl max767:not-italic max767:font-semibold max767:leading-[120%]">
                {t("commonLabels.my_policies_label")}
              </h1>
              <button
                className="flex flex-col text-sm items-center gap-2.5 border font-semibold bg-white rounded-[10px] leading-normal px-3 py-1 max767:text-[#00384D] max767:text-sm max767:not-italic max767:font-medium max767:leading-[120%] max767:underline max767:border-0"
                onClick={() => navigate("/expired-policies")}
              >
                {t("motorInsurance.viewExpiredPolicy.view")}
              </button>
            </div>
            <div className="policiesGrid flex w-full items-start content-start gap-4 flex-wrap ipad:grid-cols-2 ipad:grid  max767:p-0">
              <MyPolicies />
            </div>
          </div>
          <div className="flex flex-col lifeinsurencepolicycard p-6 max767:pt-0 max767:p-5">
            <h1 className="text-black text-2xl not-italic font-semibold leading-8 tracking-[-0.24x]">
              {t("dashboard.quick_actions_label")}
            </h1>
            <ServiceGrid
              services={services}
              serviceClickHandler={serviceClickHandler}
            />
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default MyPoliciesPage;
