import React, { useState } from "react";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import useFileClaimFlow from "../../../hooks/useFileClaimFlow";
import FileClaimMotorLayout from "./fileClaimMotorLayout";
import TelInput from "../../../components/common/telInput/telInput";
import useValidation from "../../../hooks/useValidation";
import Input from "../../../components/common/Input/Input";
import Button from "../../../components/common/Button/Button";
import { useTranslation } from "react-i18next";
import useMotorForm from "../../../hooks/useMotorFormData";
export const ClaimInfoPage = "ClaimInfoPage";
function YourInfoPage() {
  const [formData, setFormData] = useState({});
  const { t } = useTranslation()
  const { handleNextSubstep } = useFileClaimFlow();
  const { saveMotorDataInRedux } = useMotorForm(
    ClaimInfoPage,
    setFormData
  );
  const { errors, validateFields } = useValidation();



  const validateForm = () => {
    let validationConfig = [
      {
        name: "driverCprNumber",
        validations: ["length"],
        exact_length: 9,
      },
      {
        name: "ownerCprNumber",
        validations: ["length",],
        exact_length: 9,
      },
      {
        name: "plateNumber",
        validations: ["required"],
      },
      {
        name: "email",
        validations: ["required", "email"],
      },
      {
        name: "mobile",
        validations: ["length"],
        exact_length: 8,
      },
    ];

    let isValid = validateFields(validationConfig, formData);
    console.log(isValid, formData, "valid");
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let update = { ...formData, [name]: value };
    setFormData(update);
    if (errors?.[name]) {
      validateForm(update);
    }
  };
  const continueClickHanlder = () => {
    if (validateForm()) {
      saveMotorDataInRedux(formData);
      handleNextSubstep();
    }
  };
  return (
    <FileClaimMotorLayout btn_onClick={continueClickHanlder}>
      <div className="mx-auto bg-white max767:p-5 w-[500px] max-w-[100%]">
        <div className="max767:hidden">
          <TitleDescription title={t("motorInsurance.fileAClaimFlow.yourInfoPage.title")} description={t("motorInsurance.fileAClaimFlow.yourInfoPage.description")} />
        </div>
        <div className="max767:block hidden max767:mb-6 max767:mt-20">
          <TitleDescription title={t("motorInsurance.fileAClaimFlow.yourInfoPage.title")} description={t("motorInsurance.fileAClaimFlow.yourInfoPage.mobile_description")} />
        </div>
        <div>
          <Input
            label={t("motorInsurance.fileAClaimFlow.yourInfoPage.driver_cpr_number_label")}
            type="number"
            handleChange={handleChange}
            name="driverCprNumber"
            value={formData.driverCprNumber}
            error={errors.driverCprNumber}
            data-testid="driverCprNumber"
          />
          <Input
            label={t("motorInsurance.fileAClaimFlow.yourInfoPage.vehicle_cpr_number_label")}
            type="number"
            handleChange={handleChange}
            name="ownerCprNumber"
            value={formData.ownerCprNumber}
            error={errors.ownerCprNumber}
            data-testid="ownerCprNumber"
          />
          <Input
            label={t("motorInsurance.fileAClaimFlow.yourInfoPage.plate_number_label")}
            type="number"
            handleChange={handleChange}
            name="plateNumber"
            value={formData.plateNumber}
            error={errors.plateNumber}
            data-testid="plateNumber"
          />
          <Input
            label={t("motorInsurance.fileAClaimFlow.yourInfoPage.email_address_label")}
            type="text"
            handleChange={handleChange}
            name="email"
            value={formData.email}
            error={errors.email}
            data-testid="email"
          />

          <TelInput
            name="mobile"
            value={formData.mobile}
            error={errors.mobile}
            onChange={handleChange}
            label={t("motorInsurance.fileAClaimFlow.yourInfoPage.mobile_number_label")}
            className="mobilenumber px-4 py-3 max767:w-[50%] w-[97%] border-r-0 border-0 rounded-[9px] h-[46px] focus:outline-none"
            data-testid="mobile"
          />

        </div>
      </div>
    </FileClaimMotorLayout>
  );
}

export default YourInfoPage;
