import React from "react";

import MorePageLayout from "./morePageLayout";
import ChangePassword from "../profileSettings/changePassword";
import { useTranslation } from "react-i18next";

const MobileChangePasswordPage = () => {
  const { t } = useTranslation();

  return (
    <MorePageLayout is_hide_back_btn show_close_btn>
      <div className="max767:p-5 chngepassword">
      <h2 className="text-[#1A2024] text-left rtl:text-right text-lg not-italic font-semibold leading-6 tracking-[-0.18px] mt-4 mb-2 max767:mb-0 max767:text-xl max767:not-italic max767:font-semibold"> {t("securityPage.changePassword")}</h2>

      <ChangePassword />
      </div>
    </MorePageLayout>
  );
};

export default MobileChangePasswordPage;
