import axios from "axios";
import { BASE_URL, FILES_BASE_URL, STRAPI_API_TOKEN, STRAPI_BASE_URL } from "../api/baseURL";
import { clearLocalStorageForUnauthorized } from "../utils";

export const LOCAL_STORAGE_NAME = "CUSTOMER_PORTAL"


const Axios = axios.create({
  baseURL: `${BASE_URL}`,
});

Axios.interceptors.request.use(
  (config) => {
    if (config.url =="/token") {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded"
    }
    else {
      config.headers["Content-Type"] = "application/json"
    }
    const token = JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME))?.access_token || "";
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status ==401) {
      clearLocalStorageForUnauthorized()
    }
    return Promise.reject(error);
  }
);

export const AxiosStrapi = axios.create({
  baseURL: `${STRAPI_BASE_URL}`,
});

AxiosStrapi.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `bearer ${STRAPI_API_TOKEN}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const AxiosFiles = axios.create({
  baseURL: `${FILES_BASE_URL}`,
});
AxiosFiles.interceptors.request.use(
  (config) => {
    config.headers["Content-Type"] = "application/json"

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);




export default Axios;
