import React, { startTransition, useState } from "react";
import SignupPage from "./SignupPage/SignupPage";
import VerifyOtpPage from "./VerifyOtpPage/VerifyOtpPage";
import CreatePasswordPage from "./CreatePasswordPage/CreatePasswordPage";
import GetVerifiedPage from "./GetVerifiedPage/GetVerifiedPage";
import RegisterSuccessPage from "./RegisterSuccessPage/RegisterSuccessPage";
import Modal from "../../components/common/modal/modal";
import Header from "../../components/common/Header/Header";
import { useNavigate } from "react-router-dom";
import { updateLoggedInUserDataInLocalStorage } from "../../config/config";
import ConfirmCitizenShip from "./confirmCitizenShip/confirmCitizenship";

// Enumeration for page states
const PageState = {
  SIGNUP: "SIGNUP",
  OTP_VERIFIED: "OTP_VERIFIED",
  PASSWORD_CREATED: "PASSWORD_CREATED",
  DOCS_VERIFIED: "DOCS_VERIFIED",
  SUCCESS: "SUCCESS",
  US_CITIZENSHIP: "US_CITIZENSHIP",
};

const MainSignupPage = () => {
  updateLoggedInUserDataInLocalStorage({ isLoggedIn: false });

  const navigate = useNavigate();
  const [CPRExists, setCPRExists] = useState(false);
  const [currentPage, setCurrentPage] = useState(PageState.SIGNUP);
  const [showModal, setShowModal] = useState(true);

  // Handler for advancing to the next page
  const handleSuccess = (nextPage, isCPRExists = CPRExists) => {
    setCPRExists(isCPRExists);
    setCurrentPage(nextPage);
  };

  // Handler for going back to the previous page
  const handleBackClicked = () => {
    startTransition(() => {
      switch (currentPage) {
        case PageState.OTP_VERIFIED:
          setCurrentPage(PageState.SIGNUP);
          break;
        case PageState.PASSWORD_CREATED:
          setCurrentPage(CPRExists ? PageState.SIGNUP : PageState.OTP_VERIFIED);
          break;
        case PageState.DOCS_VERIFIED:
          setCurrentPage(PageState.PASSWORD_CREATED);
          break;
        case PageState.SUCCESS:
          setCurrentPage(
            CPRExists ? PageState.PASSWORD_CREATED : PageState.DOCS_VERIFIED
          );
          break;
        default:
          break;
      }
    });
  };

  // Component to render based on current page state
  const PageRenderer = () => {
    switch (currentPage) {
      case PageState.SIGNUP:
        return (
          <SignupPage
            onSuccess={(isCPRExists) =>
              handleSuccess(
                isCPRExists
                  ? PageState.PASSWORD_CREATED
                  : PageState.OTP_VERIFIED,
                isCPRExists
              )
            }
          />
        );
      case PageState.OTP_VERIFIED:
        return (
          <VerifyOtpPage
            onBackClick={handleBackClicked}
            onSuccess={() => handleSuccess(PageState.PASSWORD_CREATED)}
          />
        );
      case PageState.PASSWORD_CREATED:
        return (
          <CreatePasswordPage
            onBackClick={handleBackClicked}
            onSuccess={() =>
              CPRExists
                ? handleSuccess(PageState.SUCCESS)
                : handleSuccess(PageState.DOCS_VERIFIED)
            }
          />
        );
      case PageState.DOCS_VERIFIED:
        return (
          <GetVerifiedPage
            onBackClick={handleBackClicked}
            // onSuccess={() => handleSuccess(PageState.US_CITIZENSHIP)}
            onSuccess={() => handleSuccess(PageState.SUCCESS)}
          />
        );
      case PageState.US_CITIZENSHIP:
        return (
          <ConfirmCitizenShip
            onSuccess={() => handleSuccess(PageState.SUCCESS)}
          />
        );
      case PageState.SUCCESS:
        return <RegisterSuccessPage />;
      default:
        return null;
    }
  };

  return (
    <Modal show={showModal}>
      <Header
        isPopup
        onCloseModal={() => {
          startTransition(() => {
            setShowModal(false);
            navigate("/");
          });
        }}
        showBackBtn={
          currentPage != PageState.SIGNUP && currentPage != PageState.SUCCESS
        }
        backClickHandler={handleBackClicked}
      />
      <div className="mainSignupModal">
        <PageRenderer />
      </div>
    </Modal>
  );
};

export default MainSignupPage;
