import React, { useEffect, useState } from "react";
import backarrow from "../../../assets/images/back-arrow-left.svg";
import editicon from "../../../assets/images/editicon.svg";
import downloadicon from "../../../assets/images/downloadblack.svg";
import { useNavigate } from "react-router-dom";
import EditableField from "../../../components/motorPolicyCard/editableField";
import BackdropModal from "../../../components/common/modal/backdropModal";
import useScreenResolution from "../../../hooks/useScreenResolution";
import ellipsis from "../../../../src/assets/images/eclips.svg";
import PopoverComponent from "../../../components/common/popover/PopoverComponent";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { useTranslation } from "react-i18next";
import { currencyFormatterWithCountryCode, dateFormatter } from "../../../utils";
const menuData = [
  {
    icon: editicon,
    text_en: "Edit policy Name",
    text_ar: "تعديل اسم السياسة",
    type: "edit",
  },
  {
    icon: downloadicon,
    text_en: "Download Documents",
    text_ar: "تنزيل المستندات",
    type: "download",
  },
];

const PolicyHeroCard = ({ info, content, handleDownloadMenuClicked }) => {
  const translate = useLanguageTranslate();
  const policyList = content?.LifeDetails?.policyList?.[0];
  const policyDetails = content?.PolicyDetails;
  const [isEditing, setIsEditing] = useState(false);
  const [isMobileEditing, setIsMobileEditing] = useState(false);
  const [text, setText] = useState();
  const [showEllipsisMenu, setShowEllipsisMenu] = useState(false);
  const screenResolution = useScreenResolution();
  
  const { i18n, t } = useTranslation();
  const handleEditClick = () => {
    setIsEditing(true);
  };
  const handleSaveClick = () => {
    setIsEditing(false);
  };

  const navigate = useNavigate();
  const backClickHandler = () => {
    // navigate(-1);
    navigate("/my-policy")
  };

  const handleMobileSaveClick = () => {
    toggleMobileEditing();
  };

  const toggleEllipsisMenu = () => {
    setShowEllipsisMenu(!showEllipsisMenu);
  };
  const toggleMobileEditing = () => {
    setIsMobileEditing(!isMobileEditing);
  };

  const handleMenuClick = (type) => {
    if (type =="edit") {
      setIsMobileEditing(true);
    } else if (type =="download") {
      handleDownloadMenuClicked(true);
    }
    setShowEllipsisMenu(false);
  };

  useEffect(() => {
  setText(info?.CPR||"")
  }, [i18n?.resolvedLanguage]);

  const mobileEditableField = (
    <>
      <EditableField
        value={text}
        isEditing={false}
        isMobile={screenResolution.mobile}
      />
      <div className="editpolicymodal">
        <BackdropModal
          show={isMobileEditing}
          onClose={toggleMobileEditing}
          title="Edit Policy Name"
        >
          <EditableField
            value={text}
            onChange={(e) => setText(e.target.value)}
            isEditing={isMobileEditing}
            onSave={handleMobileSaveClick}
            isMobile={screenResolution.mobile}
            downloadIcon={downloadicon}
          />
        </BackdropModal>
      </div>
    </>
  );
  return (
    <>
      <div className="visible flex   md:hidden  items-start mb-4  max767:w-full max767:flex-row max767:sticky max767:top-0 max767:mt-0 max767:bg-[#E8F3E8] max767:p-4 stickytitle max767:items-center max767:mb-0 max767:z-10">
        <button
          onClick={backClickHandler}
          className="flex text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:bg-[#F6F8F9] max767:p-2 max767:rounded-3xl"
        >
          <img src={backarrow} alt="back arrow" className="rtl:rotate-180" />
          <span className="max767:hidden ml-2">
            {t("lifeInsurance.policyOverview.back")}
          </span>
        </button>
        <div className="flex flex-col items-start mb-4  max767:w-full max767:flex-row max767:justify-center max767:text-center max767:flex max767:mb-0">
          {screenResolution.mobile ? (
            mobileEditableField //sticky top
          ) : (
            <EditableField
              value={text}
              onChange={(e) => setText(e.target.value)}
              isEditing={isEditing}
              onSave={handleSaveClick}
              onEdit={handleEditClick}
              editIcon={editicon}
              isMobile={screenResolution.mobile}
            />
          )}
        </div>

        {screenResolution.mobile && (
          <PopoverComponent
            className="z-10"
            headerMenu={
              <button
                onClick={toggleEllipsisMenu}
                className="flex text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
              >
                <img alt="ellipsis" src={ellipsis} />
              </button>
            }
            positions={["bottom"]}
            isPopoverOpen={showEllipsisMenu}
            closer={toggleEllipsisMenu}
            popupContent={
              <div className="flex mt-[26px] w-[228px] flex-col items-start bg-white shadow-[0px_0px_32px_0px_rgba(0,0,0,0.20)] rounded-xl ">
                {menuData.map((data) => (
                  <button
                    key={data.type}
                    className="flex h-11 justify-between items-center self-stretch px-4 py-0 border-b-[#DDE2E4] border-b border-solid"
                    onClick={() => handleMenuClick(data.type)}
                  >
                    {translate(data.text_en, data.text_ar)}
                    <img src={data.icon} alt="" />
                  </button>
                ))}
              </div>
            }
          />
        )}
      </div>
      <div className="p-5 bg-[#E8F3E8] rounded-2xl flex justify-between items-start self-stretch px-5 py-6 mt-6 mb-4 max767:flex-col max767:mt-0 max767:rounded-none">
        <div className=" flex flex-col  items-start mb-4 max767:hidden max767:w-full max767:flex-row max767:sticky max767:top-0 max767:mt-0 max767:bg-[#E8F3E8] max767:p-4 stickytitle max767:items-center max767:mb-0 max767:z-10">
          <button
            onClick={backClickHandler}
            className="flex text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:bg-[#F6F8F9] max767:p-2 max767:rounded-3xl"
          >
            <img src={backarrow} alt="back arrow" className="rtl:rotate-180" />
            <span className="max767:hidden ml-2">
              {t("lifeInsurance.policyOverview.back")}
            </span>
          </button>
          <div className="flex flex-col items-start mb-4  max767:w-full max767:flex-row max767:justify-center max767:text-center max767:flex max767:mb-0">
            {screenResolution.mobile ? (
              mobileEditableField //sticky top
            ) : (
              <EditableField
                value={text}
                onChange={(e) => setText(e.target.value)}
                isEditing={isEditing}
                onSave={handleSaveClick}
                onEdit={handleEditClick}
                editIcon={editicon}
                isMobile={screenResolution.mobile}
              />
            )}
          </div>
        </div>

        <div className="flex flex-col justify-between items-start ">
          <span className="flex items-center mb-4 gap-1 bg-[#F6F8F9] px-2 py-1 rounded-lg text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:mb-1">
            {info?.status}
          </span>
          {screenResolution.mobile && mobileEditableField}
          <div>
            <h2 className="text-[#5B6871] text-sm not-italic font-semibold leading-[normal] mb-1 max767:mt-4 max767:mb-1 max767:text-base max767:not-italic max767:font-semibold">
              {policyDetails?.ProductName}
            </h2>
            <p className="text-[#5B6871] text-sm not-italic font-semibold leading-[normal] mb-4 max767:text-base max767:not-italic max767:font-normal">
              {translate("Policy Number:", "رقم الوثيقة:")} {info?.policy}
            </p>
            {!screenResolution.mobile && policyList?.currentValue && (
              <>
                <span className="text-[#1A2024] text-base not-italic font-medium leading-[120%] mr-1">
                  {policyList?.currentValue?.split(" ")[0]}{" "}
                </span>
                <span className="text-[#1A2024] text-[28px] not-italic font-black leading-[120%] mr-1">
                  {currencyFormatterWithCountryCode(
                    policyList?.currentValue?.split(" ")[1]
                  )}
                </span>
              </>
            )}
            {!screenResolution.mobile && (
              <span className="text-[#5B6871] text-[10px] not-italic font-normal leading-[normal]">
                {translate(
                  "Current Value as of ",
                  " القيمة الحالية اعتبارًا من"
                )}{" "}
                {dateFormatter(
                  policyList?.lastPaymentDate,
                  "d MMMM yyyy",
                  translate("en", "ar")
                )}
              </span>
            )}
            {screenResolution.mobile && (
              <div className="flex flex-col gap-2 max767:mt-8">
                <span className="text-[#1A2024] text-[16px] not-italic leading-[120%] max767:text-base max767:not-italic max767:text-[#48535B]">
                  {translate("Current Value", "القيمة الحالية")}
                </span>
                {policyList?.currentValue && (
                  <>
                    <div className="">
                      <span className="text-[#1A2024] text-[28px] not-italic font-black leading-[120%] max767:text-base max767:not-italic max767:font-medium">
                        {policyList?.currentValue.split(" ")[0]}{" "}
                      </span>
                      <span className="text-[#1A2024] text-[28px] not-italic font-black leading-[120%]">
                        {currencyFormatterWithCountryCode(
                          policyList?.currentValue.split(" ")[1]
                        )}
                      </span>
                    </div>
                  </>
                )}

                <span className="text-[#48535B] text-[16px] not-italic font-normal leading-[normal]">
                  {translate("As of ", " القيمة الحالية اعتبارًا من")}
                  {dateFormatter(
                    policyList?.lastPaymentDate,
                    "d MMMM yyyy",
                    translate("en", "ar")
                  )}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PolicyHeroCard;
