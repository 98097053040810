import React from "react";
import { useNavigate } from "react-router-dom";
import { FILE_CLAIM_FLOW, FILE_CLAIM_NON_MOTOR_POLICY_HOLDER } from "./data";
import useFileClaimFlow from "../../../hooks/useFileClaimFlow";
import { useSelector } from "react-redux";
import { selectNewMotorForm } from "../../../redux/features/newMotorFormSlice";
import { CaseNo } from "./reviewAndConfirmPage";
import CryptoJS from "crypto-js";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";

function CreatedClaimPage() {
  const navigate = useNavigate();
  const { handleFlowChange, flowType } = useFileClaimFlow();
  const CaseNumber = useSelector(selectNewMotorForm)?.[CaseNo];
  const valueToEncrypt = CaseNumber;
  const translate = useLanguageTranslate();
  // Encryption logic
  const secretKey = "i-hate-meej";
  const encryptedValue = CryptoJS.AES.encrypt(
    valueToEncrypt,
    secretKey
  ).toString();

  // Convert to URL-safe format
  const urlSafeEncryptedValue = encryptedValue
    .split("+")
    .join("-") // Replace '+' with '-'
    .split("/")
    .join("_") // Replace '/' with '_'
    .split("=")
    .join(""); // Remove '=' padding

  const handleSuccess = () => {
    handleFlowChange(FILE_CLAIM_FLOW, 0);
    navigate(`/motor-insurance/claim-details/${urlSafeEncryptedValue}`);
  };
  return (
    <div className="items-center justify-center mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 max767:mt-[20%] w-[500px] max767:max-w-[100%] ipad:mt-[20%]">
      {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
      <div className="mx-auto max-w-3xl bg-gray-50 rounded-lg p-4">
        <div className="py-10 max767:pb-2">
          <svg
            className="mx-auto"
            width="54"
            height="54"
            viewBox="0 0 54 54"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M26.9997 0.332031C12.2797 0.332031 0.333008 12.2787 0.333008 26.9987C0.333008 41.7187 12.2797 53.6654 26.9997 53.6654C41.7197 53.6654 53.6663 41.7187 53.6663 26.9987C53.6663 12.2787 41.7197 0.332031 26.9997 0.332031ZM21.6663 40.332L8.33301 26.9987L12.093 23.2387L21.6663 32.7854L41.9063 12.5454L45.6663 16.332L21.6663 40.332Z"
              fill="#119C2B"
            />
          </svg>

          {/* <p className="text-xl pt-5 max-w-[452px] text-center mx-auto">
            { flowType != FILE_CLAIM_NON_MOTOR_POLICY_HOLDER ? translate("Your claim has been successfully submitted.Please use the case number","تم تقديم مطالبتك بنجاح. يرجى استخدام رقم الحالة") : translate("Thank you!","شكراً لك!")}
            <br /> <strong>{CaseNumber}</strong> { flowType != FILE_CLAIM_NON_MOTOR_POLICY_HOLDER ? translate("to track the status and updates of your claim.","لتتبع حالة وتحديثات مطالبتك.") : 
            translate("Your claim has been successfully submitted. Our Claims Department will review your case and contact you shortly.","لقد تم تقديم مطالبتك بنجاح. سيقوم قسم المطالبات لدينا بمراجعة طلبك والتواصل معك قريباً.") }
          </p> */}

          {flowType != FILE_CLAIM_NON_MOTOR_POLICY_HOLDER ? (
            <p className="text-xl pt-5 max-w-[452px] text-center mx-auto">
              {translate(
                "Your claim has been successfully submitted.Please use the case number",
                "تم تقديم مطالبتك بنجاح. يرجى استخدام رقم الحالة"
              )}
              <br /> <strong>{CaseNumber}</strong>{" "}
              {translate(
                "to track the status and updates of your claim.",
                "لتتبع حالة وتحديثات مطالبتك."
              )}
            </p>
          ) : (
            <p className="text-xl pt-5 max-w-[452px] text-center mx-auto">
              {translate("Thank you!", "شكراً لك!")} <br />
              {translate(
                "Your claim has been successfully submitted. Our Claims Department will review your case and contact you shortly.",
                "لقد تم تقديم مطالبتك بنجاح. سيقوم قسم المطالبات لدينا بمراجعة طلبك والتواصل معك قريباً."
              )}
            </p>
          )}
          <div className="mx-auto text-center p-4 m-5 bg-[#55C6EF] h-[56px] rounded-lg max767:block hidden">
            <button
              onClick={handleSuccess}
              className="mx-auto text-center font-semibold"
            >
              {translate("Back to Claim", "العودة إلى المطالبة")}
            </button>
          </div>
        </div>
      </div>
      <div className="mx-auto text-center p-4 m-5 bg-[#55C6EF] rounded-lg h-[56px] max767:hidden">
        <button
          onClick={handleSuccess}
          className="mx-auto text-center font-semibold"
        >
          {translate("Back to Claim", "العودة إلى المطالبة")}
        </button>
      </div>
    </div>
  );
}

export default CreatedClaimPage;
