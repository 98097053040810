import fundPerfomancePage1 from "../../../assets/images/fundPerfomancePage1.svg";
import health360 from "../../../assets/images/health360.svg";

export const policyInfo = {
  status: "Active",
  ar_status: "نشيط",
  planName: "Retirement Saving Plan",
  policyNumber: "P/0001/4/20/B003/0004341-05",
  amount: "5,677.93",
  date: "01 November 2023",
};

export const tabs = [
  {
    label_en: "Policy Overview",
    label_ar: "نظرة عامة على السياسة",
    type: "PolicyOverview",
  },
];

export const overviewTabData = {
  cards: [
    {
      type: "policyTerm",
      icon: fundPerfomancePage1,
      label_en: "Policy Term",
      value: "20 years",
      label_ar: "مصطلح السياسة",
      value_ar: "20 سنة",
    },
  ],

  // table: [
  //   {
  //     data: [
  //       { id: 1, column1: "Owner", column2: "Name" },
  //       { id: 2, column1: "Tab", column2: "Retirement savings plan" },
  //       { id: 3, column1: "Life Cover", column2: "selected", icon: selected },
  //       {
  //         id: 4,
  //         column1: "Lie cover",
  //         column2: "Not selected",
  //         icon: notselected,
  //       },
  //       { id: 5, column1: "Life cover", column2: "selected", icon: selected },
  //       { id: 6, column1: "Life cover", column2: "selected", icon: selected },
  //     ],
  //   },
  // ],
};

export const coverageAndBenefits = {
  includeBenefits: [
    {
      id: 1,
      description: "Outpatient medical treatments",
      description_ar: "العلاجات الطبية للمرضى الخارجيين",
    },
    {
      id: 2,
      description: "Generic Medicines and Basic Investigations",
      description_ar: "الأدوية العامة والفحوصات الأساسية",
    },
    {
      id: 3,
      description: "Physiotherapy",
      description_ar: "العلاج الطبيعي",
    },
    {
      id: 4,
      description:
        "Pre-existing & Chronic Conditions - up to the limit mentioned in the schedule of benefits and subject to 3 months waiting period",
      description_ar:
        "الحالات المزمنة والموجودة مسبقًا - تصل إلى الحد المذكور في جدول المزايا وتخضع لفترة انتظار مدتها 3 أشهر",
    },
    {
      id: 5,
      description:
        "Outpatient Maternity treatments (For females primary insured or spouses only) - limited to those specified in the schedule of benefits and subject to 3 months waiting period",
      description_ar:
        "علاجات الأمومة للمرضى الخارجيين (للمؤمّن الرئيسي الإناث أو الزوجات فقط) - محدودة لتلك المحددة في جدول المزايا وتخضع لفترة انتظار مدتها 3 أشهر",
    },
    {
      id: 6,
      description:
        "Dental treatments - limited to those specified in the schedule of benefits and subject to 3 months waiting period",
      description_ar:
        "علاجات الأسنان - محدودة لتلك المذكورة في جدول المزايا وتخضع لفترة انتظار مدتها 3 أشهر",
    },
  ],

  downloadCard: {
    label_en: "Your policy schedule document",
    label_ar: "وثيقة جدول السياسة الخاصة بك",
    download_btn: true,
    filesData: [
      {
        fileName: "Policy Schedule",
        fileName_ar: "جدول السياسة",
        fileData: {
          size: "165.7 KB",
        },
      },
      {
        fileName: "Policy Schedule",
        fileName_ar: "جدول السياسة",
        fileData: {
          size: "165.7 KB",
        },
      },
      {
        fileName: "Policy Schedule",
        fileName_ar: "جدول السياسة",
        fileData: {
          size: "165.7 KB",
        },
      },
      {
        fileName: "Policy Schedule",
        fileName_ar: "جدول السياسة",
        fileData: {
          size: "165.7 KB",
        },
      },
      {
        fileName: "Policy Schedule",
        fileName_ar: "جدول السياسة",
        fileData: {
          size: "165.7 KB",
        },
      },
    ],
  },
};

export const health360Data = {
  title_en: "Download Health 360 to get your eCard",
  title_ar: "قم بتنزيل هلث 360 للحصول على بطاقتك الإلكترونية",
  items: {
    label_en: "Health 360",
    label_ar: "هلث 360",
    gPayLink:
      "https://play.google.com/store/apps/details?id=com.health360.app&hl=en&gl=US",
    iosLink:
      "https://apps.apple.com/bh/app/health-360-ancillary-services/id1273593493",
    icon: health360,
  },
};
