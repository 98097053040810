export const CHANGE_MOBILE_NUMBER_FLOW = "CHANGE_MOBILE_NUMBER_FLOW";
export const ACCOUNT_PASSWORD_PAGE = "ACCOUNT_PASSWORD_PAGE";
export const UPDATED_MOBILE_NUMBER_PAGE = "UPDATED_MOBILE_NUMBER_PAGE";
export const VERIFY_OTP_PAGE = "VERIFY_OTP_PAGE";
export const REGISTER_SUCCESS_PAGE = "REGISTER_SUCCESS_PAGE";
export const CHANGE_EMAIL_FLOW = "CHANGE_EMAIL_FLOW";
export const UPDATED_EMAIL_PAGE = "UPDATED_EMAIL_PAGE";
export const change_mobile_number_or_email_flow = {
  [CHANGE_MOBILE_NUMBER_FLOW]: [
    {
      name: ACCOUNT_PASSWORD_PAGE,
      substeps: [ACCOUNT_PASSWORD_PAGE],
    },
    {
      name: UPDATED_MOBILE_NUMBER_PAGE,
      substeps: [UPDATED_MOBILE_NUMBER_PAGE],
    },
    {
      name: VERIFY_OTP_PAGE,
      substeps: [VERIFY_OTP_PAGE],
    },
    {
      name: REGISTER_SUCCESS_PAGE,
      substeps: [REGISTER_SUCCESS_PAGE],
    },
  ],
  [CHANGE_EMAIL_FLOW]: [
    {
      name: ACCOUNT_PASSWORD_PAGE,
      substeps: [ACCOUNT_PASSWORD_PAGE],
    },
    {
      name: UPDATED_EMAIL_PAGE,
      substeps: [UPDATED_EMAIL_PAGE],
    },
    {
      name: REGISTER_SUCCESS_PAGE,
      substeps: [REGISTER_SUCCESS_PAGE],
    },
  ],
};
