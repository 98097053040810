/* :Page:NewMotor: Review & Confirm page */

import React, { useState } from "react";
import Summary from "../../../../components/newMotorPolicy/veiwSummaryPopup/summary";
import PromoCode from "../../../../components/newMotorPolicy/veiwSummaryPopup/promoCode";
import { useNavigate } from "react-router-dom";
import backarrow from "../../../../../src/assets/images/back-arrow-left.svg";
import Exclamation from "../../../../../src/assets/images/Exclamation.svg";
import forwardarrow from "../../../../../src/assets/images/arrow_back.svg";
import { coverOptions, motorData } from "./data";
import useMotorFlow from "../../../../hooks/useMotorFlow";
import NewMotorJourneyLayout from "../Layouts/NewMotorJourneyLayout";
import useMotorForm from "../../../../hooks/useMotorFormData";
import { PROMO_CODE } from "../../../../components/newMotorPolicy/veiwSummaryPopup/viewSummaryPopup";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getQuoteResponse } from "../../../../redux/features/motor/getQuoteSlice";
import {
  getPolicyPayLoading,
  policyPay,
} from "../../../../redux/features/payment/policyPaySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getPolicyPremiumLoading } from "../../../../redux/features/motor/renewalPremiumSlice";
import { Loader } from "storybook/internal/components";
import Button from '../../../../components/common/Button/Button';
import { selectNewMotorForm } from "../../../../redux/features/newMotorFormSlice";
import { COVERAGE_ENHANCEMENT } from "../addons/coverageEnhancement";
import { toast } from "react-toastify";
import { currencyFormatter } from "../../../../utils";
import { applyPromoCode } from "../../../../redux/features/promoCode/promoCodeSlice";
import BottomStickyBtnLayout from "../Layouts/BottomStickyBtnLayout";
import CustomCheckbox from "../../../../components/common/customCheckbox/customCheckbox";

// review and confirm page
const PaymentSummury = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [coverOptionsState, setCoverOptionsState] = useState(coverOptions);
  const [discountPercent, setDiscountPercent] = useState(10);
  const [formState, setFormState] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const { handlePrevSubstep } = useMotorFlow();

  // Get car info
  const carData = useSelector(getQuoteResponse);
  const carDetails = carData.QuoteDetails;

  const promoCodeDetails = useSelector(selectNewMotorForm)?.[PROMO_CODE];
  // console.log("Car data: ", carData);
  // console.log("Car details: ", carDetails);

  // Setting up policy pay
  const quoteNumber = carData.PolicyNumber;
  const totalPremium = carData.TotalPremium;
  const quotePremium = carData.Premium;

  console.log(
    "Quote to pay is: ",
    quoteNumber,
    " with premium: ",
    totalPremium
  );
  const policyPayLoading = useSelector(getPolicyPayLoading);

  let coverageEnhanceData =
    useSelector(selectNewMotorForm)?.[COVERAGE_ENHANCEMENT];

  const handlePayment = async () => {
    // navigate("/motor-insurance/payment")
    if (quoteNumber && totalPremium) {
      const payload = {
        PolicyNumber: quoteNumber,
        Premium: totalPremium,
        Channel: "online.solidarity",
      };

      try {
        // Calling API policy/pay
        const linkAction = await dispatch(policyPay(payload));
        const link = unwrapResult(linkAction);
        // console.log("Link is: ", link)
        if (link) {
          window.location.href = link;
        }
      } catch (error) {
        // alert("Error in getting payment link: ", error)
        toast.error("Error in getting payment link: ", error);
      }
    }
  };

  const { saveMotorDataInRedux, removeMotorFormData } = useMotorForm(
    PROMO_CODE,
    setFormState
  );

  const applyPromo = async () => {
    try {
      console.log('Applying promo code: ', formState.promoCode);
      
      let promoCodeRequest = { 
        QuoteNumber: quoteNumber, 
        Channel: "online.solidarity", 
        PromoCode: formState.promoCode 
      };
  
      // Use await if this is an async action
      const promoCodeAction = await dispatch(applyPromoCode(promoCodeRequest));
      const promoCodeResult = unwrapResult(promoCodeAction);
      
      // Check if promo code was successfully applied
      if (promoCodeResult.ErrorCode == 0) {
        saveMotorDataInRedux({
          promoCode: formState.promoCode, 
          promoCodeApplied: true,
          promoCodeTotalPremium: promoCodeResult.TotalPremium,
          promoCodePremium: promoCodeResult.Premium,
          promoCodeTax: promoCodeResult.Tax,
          promoCodeDifference: quotePremium - promoCodeResult.Premium,
        });
      } else {
        setFormState({
          promoCode: formState.promoCode, // Retain promoCode
          promoCodeApplied: false, // Reset promoCodeApplied to false
        });
        saveMotorDataInRedux({
          promoCode: formState.promoCode,
          promoCodeApplied: false,
        });

      }
    } catch (error) {
      setFormState({
        promoCode: formState.promoCode, // Retain promoCode
        promoCodeApplied: false, // Reset promoCodeApplied to false
      });
      saveMotorDataInRedux({
        promoCode: formState.promoCode,
        promoCodeApplied: false,
      });

      console.error('Promo code application failed:', error);
    }
  };

  const calculateTotal = () => {
    const premium = 147.6;
    const baseTotal = coverOptionsState.reduce((acc, option) => {
      return option.included ? acc + option.price : acc;
    }, premium);
    const discount = (baseTotal * discountPercent) / 100;
    const vat = (baseTotal - discount) * 0.1;
    const finalTotal = baseTotal - discount + vat;
    return { premium, baseTotal, discount, vat, finalTotal };
  };

  const { premium, baseTotal, discount, vat, finalTotal } = calculateTotal();
  const translate = useLanguageTranslate();
  const { t } = useTranslation();
  const declaration = t(
    "motorInsurance.newMotorInsuranceFlow.paymentSummary.declaration",
    { returnObjects: true }
  );

  return (
    <NewMotorJourneyLayout>
      {/* <BottomStickyBtnLayout
        hideContinueBtn
        // AdditionalComponent={
        //   <Button
        //     isLoading={policyPayLoading}
        //     className={`w-full text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] flex justify-center p-3 rounded-[10px] ${
        //       isChecked
        //         ? "bg-[#55C6EF] rounded-[10px] cursor-pointer"
        //         : "bg-gray-300 text-gray-500 cursor-not-allowed"
        //     }`}
        //     disabled={!isChecked}
        //     // onClick={handlePayment}
        //     onSubmit={handlePayment}
        //     label={t(
        //       "motorInsurance.newMotorInsuranceFlow.paymentSummary.proceed_payment"
        //     )}
        //     post_icon={backarrow}
        //     post_icon_class={
        //       "w-6 h-6 ml-[5px] rtl:mr-[5px] rotate-180 rtl:rotate-0"
        //     }
        //   />
        // }
      > */}
      <div className="flex flex-col items-center mx-auto max-w-lg">
        <div className="flex justify-center items-center w-full">
          <div className=" p-0  w-full">
            <h2 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mb-2 text-center max767:text-left max767:text-xl max767:pl-6 max767:mt-7">
              {t(
                "motorInsurance.newMotorInsuranceFlow.paymentSummary.review_confirm"
              )}
            </h2>
            <p className="text-[#1A2024] text-base not-italic font-normal leading-6 tracking-[-0.16px] text-center mb-5 max767:text-left max767:pl-6">
              {t(
                "motorInsurance.newMotorInsuranceFlow.paymentSummary.review_proceed"
              )}
            </p>
            <div className="bg-[#F6F8F9] rounded-[26px] p-4 mb-4 max767:bg-transparent max767:p-6 max767:pt-4 max767:mb-0">
              <h3 className="text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px]">
                {t(
                  "motorInsurance.newMotorInsuranceFlow.paymentSummary.motor_insurance"
                )}
              </h3>
              <p className="text-[#1A2024] text-sm not-italic font-normal leading-5 tracking-[-0.14px] mb-3">
                {t(
                  "motorInsurance.newMotorInsuranceFlow.paymentSummary.confirm_payment"
                )}
              </p>
              <div className="max767:overflow-x-scroll">
                <table className="min-w-full border-separate bg-white border border-solid border-[#DDE2E4] rounded-[10px] border-spacing-0">
                  <tbody>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b text-[#5B6871] text-sm not-italic font-medium leading-6 border-r-[#DDE2E4] border-r border-solid rtl:border-l-[#DDE2E4] rtl:border-l rtl:border-solid">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.plate_number"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
                        {carDetails?.RegistrationNumber}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b text-[#5B6871] text-sm not-italic font-medium leading-6 border-r-[#DDE2E4] border-r border-solid rtl:border-l-[#DDE2E4] rtl:border-l rtl:border-solid">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.plate_type"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
                        {translate(motorData.plateType, motorData.plateType_ar)}
                        {/* Private */}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b text-[#5B6871] text-sm not-italic font-medium leading-6 border-r-[#DDE2E4] border-r border-solid rtl:border-l-[#DDE2E4] rtl:border-l rtl:border-solid">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.chassis_number"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6 ">
                        {carDetails?.ChasissNumber
                          ? `${carDetails.ChasissNumber.slice(
                              0,
                              2
                            )}XXXXXXXXXXXX${carDetails.ChasissNumber.slice(-3)}`
                          : ""}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b text-[#5B6871] text-sm not-italic font-medium leading-6 border-r-[#DDE2E4] border-r border-solid rtl:border-l-[#DDE2E4] rtl:border-l rtl:border-solid">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.car_make"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b  text-[#1A2024] text-sm not-italic font-medium leading-6">
                        {/* {translate(motorData.carMake,motorData.carMake_ar)} */}
                        {carDetails?.Make}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b text-[#5B6871] text-sm not-italic font-medium leading-6 border-r-[#DDE2E4] border-r border-solid rtl:border-l-[#DDE2E4] rtl:border-l rtl:border-solid">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.car_model"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
                        {/* {translate(motorData.carModel,motorData.carModel_ar)} */}
                        {carDetails?.Model}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b text-[#5B6871] text-sm not-italic font-medium leading-6 border-r-[#DDE2E4] border-r border-solid rtl:border-l-[#DDE2E4] rtl:border-l rtl:border-solid">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.manufacturing_year"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
                        {carDetails?.ModelYear}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b text-[#5B6871] text-sm not-italic font-medium leading-6 border-r-[#DDE2E4] border-r border-solid rtl:border-l-[#DDE2E4] rtl:border-l rtl:border-solid">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.sum_insured"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
                        {currencyFormatter(carDetails?.SumInsured)}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b text-[#5B6871] text-sm not-italic font-medium leading-6 border-r-[#DDE2E4] border-r border-solid rtl:border-l-[#DDE2E4] rtl:border-l rtl:border-solid">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.startDate"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
                        {carDetails?.FromDateStr}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b text-[#5B6871] text-sm not-italic font-medium leading-6 border-r-[#DDE2E4] border-r border-solid rtl:border-l-[#DDE2E4] rtl:border-l rtl:border-solid">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.plan"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
                        {carDetails?.PlanName == "OFL"
                          ? "Online Flexi"
                          : carDetails?.PlanName == "OPL"
                          ? "Online Platinum"
                          : carDetails?.PlanName == "TPPLUS"
                          ? "Third Party Plus"
                          : carDetails?.PlanName == "TPL"
                          ? "Third Party"
                          : carDetails?.PlanName == "PL"
                          ? "Platinum"
                          : carDetails?.PlanName == "GO"
                          ? "Gold"
                          : carDetails?.PlanName == "FL"
                          ? "Flexi"
                          : carDetails?.PlanName}
                      </td>
                    </tr>
                    <tr className="border-b-last">
                      <td className="py-2 px-4 border-b text-[#5B6871] text-sm not-italic font-medium leading-6 border-r-[#DDE2E4] border-r border-solid align-top rtl:border-l-[#DDE2E4] rtl:border-l rtl:border-solid">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.add_ons"
                        )}
                      </td>
                      <td className=" text-[#1A2024] text-sm not-italic font-medium leading-6">
                        {coverageEnhanceData?.coverOptionsState
                          ?.filter((option) => option.included)
                          ?.map((addOn, index) => (
                            <div
                              key={index}
                              className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6"
                            >
                              {addOn?.name}
                            </div>
                          ))}
                        {/* {translate(
                          motorData.addOns.map((addOn, index) => (
                            <div
                              key={index}
                              className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6"
                            >
                              {addOn}
                            </div>
                          )),
                          motorData.addOns_ar.map((addOn, index) => (
                            <div
                              key={index}
                              className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6"
                            >
                              {addOn}
                            </div>
                          ))
                        )} */}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#F6F8F9] rounded-[26px] p-4 mb-4 w-full summary-box max767:bg-transparent max767:p-6 max767:pt-0 max767:mb-0">
          <PromoCode
            promoCode={formState.promoCode}
            setPromoCode={(code) =>
              setFormState((prevState) => ({ ...prevState, promoCode: code }))
            }
            applyPromoCode={applyPromo}
            clearPromoCode={() => {
              setFormState({});
              removeMotorFormData({});
            }}
          />

          <Summary
            premium={premium}
            finalTotal={finalTotal}
            total={baseTotal}
            discount={discount}
            vat={vat}
            coveredOptions={coverOptionsState}
            passedFrom={"MotorNewReviewAndConfirm"}
          />
        </div>
        <div className="w-full max767:py-0 max767:p-6">
          {/* Warning Message */}
          <div className="alertBox yellow flex items-start gap-4 self-stretch border bg-[#FDE9D2] p-4 rounded-[10px] border-solid border-[#FBC98E]">
            <img src={Exclamation} alt="danger img" />
            <span className="text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
              {t(
                "motorInsurance.newMotorInsuranceFlow.paymentSummary.disclaimer"
              )}
            </span>
          </div>

          {/* Checkbox and Text */}
          <div className="flex">
            <CustomCheckbox
              Label={
                <div className="flex">
                  <label
                    htmlFor="agreement"
                    className="text-[#252C32] text-sm not-italic font-normal leading-6"
                  >
                    {t(
                      "motorInsurance.newMotorInsuranceFlow.paymentSummary.agreement"
                    )}
                    <a
                      href="#"
                      className="text-[#00384D] text-sm not-italic font-semibold leading-6 underline"
                    >
                      {t(
                        "motorInsurance.newMotorInsuranceFlow.paymentSummary.policy"
                      )}
                    </a>{" "}
                    {t(
                      "motorInsurance.newMotorInsuranceFlow.paymentSummary.insurance"
                    )}
                    {declaration?.map((paragraph, index) => (
                      <p className="mt-2" key={index}>
                        {paragraph}
                      </p>
                    ))}
                  </label>
                </div>
              }
              isChecked={isChecked}
              setIsChecked={setIsChecked}
            />
          </div>

          {/* Proceed Button */}
          <Button
            isLoading={policyPayLoading}
            className={`w-full text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] flex justify-center p-3 rounded-[10px] ${
              isChecked
                ? "bg-[#55C6EF] rounded-[10px] cursor-pointer"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
            disabled={!isChecked}
            // onClick={handlePayment}
            onSubmit={handlePayment}
            label={t(
              "motorInsurance.newMotorInsuranceFlow.paymentSummary.proceed_payment"
            )}
            post_icon={backarrow}
            post_icon_class={
              "w-6 h-6 ml-[5px] rtl:mr-[5px] rotate-180 rtl:rotate-0"
            }
          />
        </div>
      </div>
      {/* </BottomStickyBtnLayout> */}
    </NewMotorJourneyLayout>
  );
};

export default PaymentSummury;
