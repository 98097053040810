import React, { useEffect, useMemo, useState } from "react";
import RenewMotorLayout from "../../../../../motorInsurancePages/renewalMotorPolicy/renewMotorLayout";
import TitleDescription from "../../../../../../components/newMotorPolicy/titleDescription/titleDescription";
import useLanguageTranslate from "../../../../../../hooks/useLanguageTranslate";
import SelectFundsTable from "../selectFundsTable/selectFundsTable";
import { SELECT_FUNDS_TABLE_DATA } from "../selectFundsTable/data";
import { useTranslation } from "react-i18next";
import SelectFundsTableMobile from "../selectFundsTable/selectFundsTableMobile";
import useScreenResolution from "../../../../../../hooks/useScreenResolution";
import { useDispatch, useSelector } from "react-redux";
import { fetchStrapiFunds, fetchTcsCatalogFunds, selectMappedFunds, selectMappedLoader, selectTcsCatalogFormattedFunds } from "../../../../../../redux/features/allfunds/allFundsSlice";
import Loader from "../../../../../../components/common/Loader/Loader";

const AddFundsPage = ({
    switchToggle,
    setUserFunds,
    userFunds,
    newSelectedIds,
    setNewSelectedIds,
}) => {
    const { t } = useTranslation();
    const screenResolution = useScreenResolution();

    const addFundsClickHandler = () => {
        let selectedFundsWithData = tableData?.filter(
            (_, i) => newSelectedIds[i]
        );
        selectedFundsWithData = selectedFundsWithData.map((each, i) => ({
            ...each,
            isNew: true,
        }));
        //setSelectedNewFunds(selectedFundsWithData)
        //filterUserFundsWithoutDuplicateSelectedFunds
        let filterUserFunds = userFunds?.filter((each) => !each.isNew);
        let update = [...filterUserFunds, ...selectedFundsWithData];
        setUserFunds(update);
        switchToggle();
    };

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchTcsCatalogFunds());
        dispatch(fetchStrapiFunds())
    }, [])

    //   const tableData = useSelector(selectTcsCatalogFormattedFunds);


    const tableData = useSelector(selectMappedFunds)
    const isLoading = useSelector(selectMappedLoader)
    //   console.log(mappedFunds,'some mapped funds')
    //   console.log(tableData,'some table data')

    return (
      <div className="hghg">
      <RenewMotorLayout
        btn_onClick={addFundsClickHandler}
        backClickHandler={switchToggle}
        is_bottom_sticky_layout={!screenResolution.mobile}
      >
        {isLoading && !tableData?.length && (
          <Loader className="w-10 h-10 fixed left-[50%] top-[50%]" />
        )}
        <div className="w-[1200px] max-w-[100%] mx-auto overflow-x-auto ">
          <div className="changeplatenumber w-[500px] max-w-[100%] mx-auto bg-white max767:p-5">
            <TitleDescription
              title={t(
                "lifeInsurance.investmentStrategyFlow.fundsRedirectingFlow.addFundsPage.title"
              )}
              description={t(
                "lifeInsurance.investmentStrategyFlow.fundsRedirectingFlow.addFundsPage.description"
              )}
            />
          </div>
          <div className="">
            {screenResolution.mobile ? (
              <SelectFundsTableMobile
                data={tableData || []}
                switchToggle={switchToggle}
                setUserFunds={setUserFunds}
                userFunds={userFunds}
              />
            ) : (
              <SelectFundsTable
                rowSelection={newSelectedIds}
                setRowSelection={setNewSelectedIds}
                data={tableData || []}
              />
            )}
          </div>
        </div>
      </RenewMotorLayout>
      </div>
    );
};

export default AddFundsPage;
