import React, { useState } from 'react'
import NewOwnerDetails from './newOwnerDetails';
import TransferSuccess from './transferSuccess';

const TransferPolicyFlow = ({ className }) => {
  const [toggle, setToggle] = useState(false)
  const [newOwnerNumber, setNewOwnerNumber] = useState("");

  const switchToggle = () => {
    setToggle(state => !state);
  }

  const updateOwnerNumber = (newNumber) => {
    setNewOwnerNumber(newNumber); // Update state with the value from NewOwnerDetails
  };

  return (
    <div className={`mainCarDetailsModal pt-[80px] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll max767:pt-[20px] ${className}`}>
      {
        !toggle ? <NewOwnerDetails switchToggle={switchToggle} updateOwnerNumber={updateOwnerNumber} /> : <TransferSuccess number={newOwnerNumber}/>
      }
    </div>
  )
}

export default TransferPolicyFlow