
/* :Data:MotorRenewal: plans upgrade info */ 
import ThirdParty from "../../../../assets/images/thirdparty.svg";
import ThirdPartyplus from "../../../../assets/images/thirdparty+.svg";
import Flexi from "../../../../assets/images/Flexi.svg";
import OnlineFlexi from "../../../../assets/images/OnlineFlexi.svg";
import Gold from "../../../../assets/images/Gold.svg";
import Platinium from "../../../../assets/images/Platinium.svg";





export const CURRENT_PLAN = (selectedPolicy) => {
  let planCodeName = "", planImage = "", weightage = 0;
  if (selectedPolicy) {
    switch (selectedPolicy.PlanName) {
      case "OPL":
        planCodeName = "Online Platinium";
        planImage = Platinium;
        weightage = 4;

        break;
      case "PL":
        planCodeName = "Platinium";
        planImage = Platinium;
        weightage = 4;

        break;
      case "GO":
        planCodeName = "Gold";
        planImage = Gold;
        weightage = 3;

        break;
      case "OFL":
        planCodeName = "Online Flexi";
        planImage = OnlineFlexi;
        weightage = 3;

        break;
      case "FL":
        planCodeName = "Flexi";
        planImage = Flexi;
        weightage = 3;

        break;
      case "TPPlus":
        planCodeName = "Third Party +";
        planImage = ThirdPartyplus;
        weightage = 2;

        break;
      case "TPL":
        planCodeName = "Third Party";
        planImage = ThirdParty;
        weightage = 1;

        break;
      default:
        planCodeName = "NaN";
        planImage = ""

        break;
    }
  } 

  return {
    id: weightage,
    imgSrc: planImage,
    name: planCodeName,
    name_ar: "الطرف الثالث +",
    // price: "BHD 50.4",
    // price_ar: "50.4 دينار بحريني",
    // amount: 50.4,
    // features: [
    //   {
    //     en: "Car Replacement up to 8 Days",
    //     ar: "استبدال السيارة لمدة تصل إلى 8 أيام"
    //   },
    //   {
    //     en: "24/7 Roadside Assistance in Bahrain",
    //     ar: "خدمة المساعدة على الطريق على مدار الساعة طوال أيام الأسبوع في البحرين"
    //   },
    //   {
    //     en: "Up to BD 10,000 Coverage for Uninsured Vehicle Incidents",
    //     ar: "تغطية تصل إلى 10,000 دينار بحريني لحوادث المركبات غير المؤمنة"
    //   },
    //   {
    //     en: "Third-Party Liability Coverage",
    //     ar: "تغطية المسؤولية تجاه الطرف الثالث"
    //   },
    // ],
    selected: false,
    isRecommended: false,
    weightage: weightage,
  }

}



export const plans = [
  {
    imgSrc: ThirdParty,
    code: "TPL",
    name: "Third Party",
    name_ar:"طرف ثالث",
    // price: "BHD 50.4",
    price_ar:"50.4 دينار بحريني",
    amount : 50.4,
    features: [
      {
        en: "Third party Bodley Injury unlimited",
        ar: "إصابة طرف ثالث Bodley غير محدودة"
      },
      {
        en: "Third party property damage up to BD 500,000",
        ar: "أضرار الممتلكات الخاصة بالطرف الثالث تصل إلى 500,000 دينار بحريني"
      },
    ],
    selected: false,
    isRecommended: false,
    weightage : 1,
  },
  {
    imgSrc: ThirdPartyplus,
    code: "TPPlus",
    name: "Third Party +",
    name_ar:"الطرف الثالث +",
    // price: "BHD 50.4",
    price_ar:"50.4 دينار بحريني",
    // amount : 50.4,
    features: [
      {
        en: "Car Replacement up to 8 Days",
        ar: "استبدال السيارة لمدة تصل إلى 8 أيام"
      },
      {
        en: "24/7 Roadside Assistance in Bahrain",
        ar: "خدمة المساعدة على الطريق على مدار الساعة طوال أيام الأسبوع في البحرين"
      },
      {
        en: "Up to BD 10,000 Coverage for Uninsured Vehicle Incidents",
        ar: "تغطية تصل إلى 10,000 دينار بحريني لحوادث المركبات غير المؤمنة"
      },
      {
        en: "Third-Party Liability Coverage",
        ar: "تغطية المسؤولية تجاه الطرف الثالث"
      },
    ],
    selected: false,
    isRecommended: false,
    weightage : 2,
  },
  {
    imgSrc: OnlineFlexi,
    code: "OFL",
    name: "Online Flexi",
    name_ar:"ذهب",
    // price: "BHD 147.6",
    price_ar:"147.6 دينار بحريني",
    // amount : 147.6,
    features: [
      {
        en: "5 Years Agency Repair",
        ar: "5 سنوات من إصلاح الوكالة"
      },
      {
        en: "BD 500,000 Third-Party Property Damage",
        ar: "500,000 دينار بحريني تعويض عن أضرار الممتلكات التي يلحقها طرف ثالث"
      },
      {
        en: "BD 500 Personal Accident Coverage",
        ar: "تغطية الحوادث الشخصية بمبلغ 500 دينار بحريني"
      },
      {
        en: "Optional Roadside Assistance",
        ar: "المساعدة على الطريق الاختيارية"
      },
    ],
    selected: false,
    isRecommended: false,
    weightage : 3,

  },
  {
    imgSrc: Platinium,
    code: "OPL",
    name: "Online Platinum",
    name_ar:"البلاتين",
    // price: "BHD 317.9",
    price_ar:"317.9 دينار بحريني",
    // amount :317.9,
    features: [
      {
        en: "Lifetime Agency Repair",
        ar: "إصلاح الوكالة مدى الحياة"
      },
      {
        en: "Unlimited Third-Party Bodily Injury Coverage",
        ar: "تغطية غير محدودة لإصابات الطرف الثالث الجسدية"
      },
      {
        en: "BD 5000 Personal Accident Coverage",
        ar: "تغطية الحوادث الشخصية بمبلغ 5000 دينار بحريني"
      },
      {
        en: "30-Day GCC Coverage",
        ar: "تغطية دول مجلس التعاون الخليجي لمدة 30 يومًا"
      }
    ],
    selected: true,
    isRecommended: true,
    weightage : 4,

  },
];