import React from "react";

const Modal = ({ children, show }) => {
  return (
    <>
      {show && (
        <div className=" inset-0">
          <div className="bg-white rounded-lg max-h-full max-w-full ">
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
