import React, { useState } from "react";
import TitleDescription from "../../../../../components/newMotorPolicy/titleDescription/titleDescription";
import BottomLayout from "./bottomLayout";
import PolicyCancellationMotorLayout from "./policyCancellationMotorLayout";
import usePolicyCancellationFlow from "../../../../../hooks/usePolicyCancellationFlow";
import { useTranslation } from "react-i18next";
import { cancellationReasons } from "./data";
import useValidation from "../../../../../hooks/useValidation";
import useLanguageTranslate from "../../../../../hooks/useLanguageTranslate";

const PolicyCancellationReasonPage = ({ handleNavigationToPolicyOverview, setSelectedReasonOut }) => {
  const { handleNextSubstep, handlePrevStep } = usePolicyCancellationFlow();
  const translate = useLanguageTranslate()

  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const { errors, validateFields } = useValidation();

  const validationConfig = [
    { name: "selectedReason", validations: ["required"] },
    {
      name: "otherReason",
      validations: selectedReason =="Others" ? ["required"] : [],
    },
  ];

  const handleRadioChange = (reason) => {
    setSelectedReason(reason);
    setSelectedReasonOut(reason);
    if (reason != "Others") {
      setOtherReason("");
    }
  };

  const { t } = useTranslation();

  const continueClickHandler = (e) => {
    console.log('Reason for cancellation: ', selectedReason, otherReason);
    
    e.preventDefault();

    const isFormValid = validateFields(validationConfig, { selectedReason,  otherReason  });
    if (!isFormValid) {
      return;
    }
    handleNextSubstep();
  };

  return (
    <PolicyCancellationMotorLayout
      btn_onClick={continueClickHandler}
      backClickHandler={handlePrevStep}
      CancellationAdditionalComponent={
        <BottomLayout
          btn_onClick={continueClickHandler}
          handleNavigationToPolicyOverview={handleNavigationToPolicyOverview}
        />
      }
    >
      <div className="flex flex-col items-center mx-auto max-w-lg max767:p-5 max767:mt-10">
        <div className="flex justify-center items-center w-full">
          <div className="p-0 w-full">
            <TitleDescription
              title={t("motorInsurance.policyCancellationFlow.cancellationReson.title")}
              description={t("motorInsurance.policyCancellationFlow.cancellationReson.description")}
            />

            <form className="space-y-4">
              {cancellationReasons?.map((reason, index) => (
                <div key={index} className="flex justify-start items-start gap-4 self-stretch border bg-white px-4 py-3 rounded-[10px] border-solid border-[#DDE2E4]">
                  <input
                    type="radio"
                    name="cancellationReason"
                    id={`reason-${index}`}
                    value={reason?.label}
                    onChange={() => handleRadioChange(translate(reason?.label))}
                    className="mt-1 mr-2 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300"
                  />
                  <div className="flex flex-col w-full">
                    <label
                      htmlFor={`reason-${index}`}
                      className="text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] mb-1"
                    >
                      {translate(reason?.label, reason?.label_ar)}
                    </label>

                    {selectedReason =="Others" && reason.label =="Others" ? (
                      <div className="mt-4 w-full">
                        <input
                          type="text"
                          placeholder={translate("Other reasons (please specify)","أسباب أخرى (يرجى التحديد)")}
                          value={otherReason}
                          onChange={(e) => setOtherReason(e.target.value)}
                          className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 placeholder:text-black placeholder:text-lg placeholder:not-italic placeholder:font-normal placeholder:leading-[26px] placeholder:tracking-[-0.18px] placeholder:opacity-50"
                        />
                        {errors.otherReason && (
                          <p className="text-red-500 text-sm">{translate("Please specify other reason.","يرجى تحديد سبب آخر")}</p>
                        )}
                      </div>
                    ) : (
                      <p className="text-[#1A2024] text-base not-italic font-normal leading-6 tracking-[-0.16px]">
                        {translate(reason?.description,reason?.description_ar)}
                      </p>
                    )}
                  </div>
                </div>
              ))}
              {errors.selectedReason && (
                <p className="text-red-500 text-sm">{translate("Please select any reason to proceed","يرجى اختيار أي سبب للمتابعة")}</p>
              )}
            </form>
          </div>
        </div>
      </div>
    </PolicyCancellationMotorLayout>
  );
};

export default PolicyCancellationReasonPage;
