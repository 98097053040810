import React from 'react'
import Button from '../../../../components/common/Button/Button'
import tickicon from "../../../../assets/images/done.svg";
import radioActive from "../../../../assets/images/radio_active.svg";
import radioUnactive from "../../../../assets/images/radio_unactive.svg";
import useLanguageTranslate from '../../../../hooks/useLanguageTranslate';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectNewMotorForm } from '../../../../redux/features/newMotorFormSlice';
export const RENEW_POLICY_SELECTED_PLAN = "RENEW_POLICY_SELECTED_PLAN";


const PlanCard = ({ planOnClickHandler, plan, showSelectButton, isCurrentPlan, selectedPlan }) => {

  const selectedPlanFromRedux = useSelector(selectNewMotorForm)?.[RENEW_POLICY_SELECTED_PLAN]; 

  const translate = useLanguageTranslate()
  const { t } = useTranslation()

  // let selected = selectedPlan?.code ==plan.code;
  let selected = plan.code 
  ? (selectedPlan?.code ==plan.code || selectedPlanFromRedux ==plan.code) 
  : (selectedPlan?.name ==plan.name);

  return (
    <div
      key={plan.name}
      className={`flex relative plancard w-[260px] h-auto flex-col items-start border p-6 rounded-[10px] border-solid  cursor-pointer box-border max767:w-[260px] ${(selected && !isCurrentPlan)
        ? "border-2 border-solid border-[#00719A] bg-[#E3F8FF]"
        : "border-transparent border-2 solid"
        }`}
      onClick={() => planOnClickHandler(plan)}
    >
      {plan.isRecommended && (
        <span className="absolute top-[-10px] left-[16px] bg-[#F76659] rounded-md items-center gap-1 px-2 py-0 text-[#FFEFEB] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
          {translate("Recommended", "موصى به")}
        </span>
      )}
      <div className="inline-flex justify-between w-full items-start mb-4">
        <img src={plan.imgSrc} alt="altText" className='min-h-[48px]'/>
        {!showSelectButton && <div className="relative">
          <input
            type="radio"
            name="plan"
            id={`plan-${plan.name}`}
            className="hidden"
            checked={selectedPlan?.name ==plan.name}
            onChange={() => planOnClickHandler(plan)}
          />
          <img
            src={selected ? radioActive : radioUnactive}
            alt={selected ? "Selected" : "Not Selected"}
            className="w-6 h-6"
          />
        </div>}
      </div>

      <div className="flex justify-between items-center mb-2">
        <span className="text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px]">
          {translate(plan.name, plan.name_ar)}
        </span>
      </div>
      <h3 className="text-[#1A2024] text-base not-italic font-normal leading-6 tracking-[-0.16px] mb-4">
        {translate(plan.price, plan.price_ar)}
      </h3>
      <div className="featureCont flex flex-col">
        {plan.features.map((feature) => (
          <div key={feature} className="feature flex gap-2 items-start">
            <img src={tickicon} alt="tick icon" className="mt-1 " />
            <p className="text-black text-xs not-italic font-normal leading-[150%] mb-1">
              {translate(feature.en, feature.ar)}
            </p>
          </div>
        ))}
      </div>
      <p className="text-[#1A2024] text-xs not-italic font-normal leading-4">
        {plan.description}
      </p>
      {!isCurrentPlan &&
        showSelectButton &&
        (selected ? (
          <Button
            pre_icon={tickicon}
            label={t("motorInsurance.renewalPolicyFlow.policyCard.selected")}
            className="bg-[#55C6EF] text-[#1A2024] rounded-md text-center text-sm not-italic justify-center font-semibold leading-6 tracking-[-0.084px] flex h-8 items-center gap-2.5 shrink-0 self-stretch px-3 py-1"
          />
        ) : (
          <Button
            label={t("motorInsurance.renewalPolicyFlow.policyCard.select")}
            className="border bg-white rounded-md border-solid border-[#DDE2E4] justify-center text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] flex h-8 items-center gap-2.5 shrink-0 self-stretch px-3 py-1"
          />
        ))}
      {isCurrentPlan && (
        <Button
          disabled
          label={translate("Current Plan", "الخطة الحالية")}
          className="border bg-white rounded-md border-solid border-[#DDE2E4] justify-center text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] flex h-8 items-center gap-2.5 shrink-0 self-stretch px-3 py-1"
        />
      )}
    </div>
  );
}

export default PlanCard