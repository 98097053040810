import React from "react";
import Button from "../../../../../components/common/Button/Button";
import { screenResolution } from "../../../../../utils/screenResolution";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useScreenResolution from "../../../../../hooks/useScreenResolution";

const BottomLayout = ({
  btn_disabled = false,
  btn_onClick,
  btn_label,
  handleNavigationToPolicyOverview,
}) => {
  const { t } = useTranslation();
  const { mobile } = useScreenResolution();
  const navigate = useNavigate();

  const handleRedirection = (type) => {
    switch (type) {
      case "keepPolicy":
        handleNavigationToPolicyOverview();
        break;
      case "termsCondition":
        window.open("https://www.solidarity.com.bh/termsofuse", "_blank");
        break;
      default:
        break;
    }
  };

  return (
    <>
      {mobile ? (
        <div className="flex flex-col space-y-4 max767:w-full">
          <Button
            label={t(
              "motorInsurance.policyCancellationFlow.bottomLayout.keepPolicyBtn"
            )}
            className="text-[#00384D] text-base not-italic font-extrabold leading-6 max767:text-center max767:bg-transparent max767:justify-center"
            onSubmit={() => handleRedirection("keepPolicy")}
          />
          <Button
            disabled={btn_disabled}
            onSubmit={btn_onClick ? btn_onClick : () => {}}
            type="submit"
            label={
              btn_label ||
              t("motorInsurance.newMotorInsuranceFlow.basicInfo.continueButton")
            }
            className="mb-0 stickybutton flex w-[200px] flex-col items-center gap-2.5 shrink-0 p-4 bg-[#55C6EF] rounded-[10px] text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px] max767:w-full max767:h-[40px]"
          />
          <Button
            label={t(
              "motorInsurance.policyCancellationFlow.bottomLayout.viewTermsAndConditionBtn"
            )}
            className="text-[#00384D] text-center text-sm not-italic font-normal leading-[26px] tracking-[-0.14px] underline bg-transparent hover:bg-[#fff] items-center max767:justify-center"
            onSubmit={() => handleRedirection("termsCondition")}
          />
        </div>
      ) : (
        <div className="flex space-x-4 pr-4">
          <Button
            label={t(
              "motorInsurance.policyCancellationFlow.bottomLayout.viewTermsAndConditionBtn"
            )}
            className="text-[#00384D] text-center text-sm not-italic font-normal leading-[26px] tracking-[-0.14px] underline bg-transparent hover:bg-[#fff] items-center"
            onSubmit={() => handleRedirection("termsCondition")}
          />
          <Button
            label={t(
              "motorInsurance.policyCancellationFlow.bottomLayout.keepPolicyBtn"
            )}
            className="text-[#00384D] text-base not-italic font-extrabold leading-6 bg-transparent hover:bg-[#fff] items-center"
            onSubmit={() => handleRedirection("keepPolicy")}
          />
        </div>
      )}
    </>
  );
};

export default BottomLayout;
