import React, { useEffect, useState } from "react";
import googleplay from "../../assets/images/Google.svg";
import apple from "../../assets/images/Apple.svg";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import { emergencyServicesData } from "../dashboard/data";
import Breadcrumb from "../lifeInsurancePages/PolicyOverview/Breadcrumb";
import useScreenResolution from "../../hooks/useScreenResolution";
import callIcon from "../../assets/images/phonegray.svg";
import whatsappIcon from "../../../src/assets/images/whatsappgray.svg"
import { useTranslation } from "react-i18next";
const AssistanceCard = ({ items, data }) => {
  const translate = useLanguageTranslate();

  return (
 
    <div className="w-[90%] max-w-[100%] ipad:w-[100%] max767:w-[100%]">
      {data &&<div className="flex items-center space-x-2 ml-2 mb-4 mt-5">
        <img
          src={data?.icon}
          alt={translate(data?.altText_en, data?.altText_ar)}
          className="w-8 h-8 mr-0 rtl:ml-2"
        />
        <h3 className="text-black text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] rtl:mr-2 rtl:pl-0">{translate(data?.title_en, data?.title_ar)}</h3>
      </div>}
      <div className="space-y-4">
        {items.map((item, index) => (
          <div
            key={index}
            className="flex items-center gap-4 self-stretch bg-[#F6F8F9] p-6 rounded-2xl min-h-[116px] border border-solid border-[#E5E9EB]"
          >
            {item.icon && (
              <img src={item.icon} alt={translate(item.label_en, item.label_ar)} className="w-[64px] h-[64px] mr-0" />
            )}
            <div>
              <p className="text-[#48535B] text-left text-lg not-italic font-medium leading-[120%] mb-3 rtl:text-right">{translate(item.label_en, item.label_ar)}</p>
              {item.phone && (
                <div className="flex items-center space-x-2 ml-0 mt-3">
                  <img src={callIcon} alt="call" className="w-5 h-5 rtl:ml-2" />
                  <p className="text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
                    <span className="">{translate("Call:","اتصال")}</span> {item.phone}
                  </p>
                </div>
              )}
              {item.link && (
                <div className="flex items-center space-x-2 ml-0">
                  <img src={whatsappIcon} alt="whatsapp" className="w-5 h-5 rtl:ml-2" />
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className=""
                  >
                    {translate(item.linkTextEn,item.linkTextAr)}
                  </a>
                </div>
              )}
              {(item.iosLink || item.gPayLink) && (
                <div className="flex items-center space-x-2 ml-0 max767:justify-start">
                  {item.iosLink && (
                    <a
                      href={item.iosLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={apple} alt="App Store" className="h-10" />
                    </a>
                  )}
                  {item.gPayLink && (
                    <a
                      href={item.gPayLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={googleplay}
                        alt="Google Play"
                        className="h-10"
                      />
                    </a>
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
   
  );
};


const EmergencyServices = ({ data, type }) => {
    const [breadCrumbData, setBreadCrumbData] = useState([]);
    const screenResolution = useScreenResolution();
  const translate = useLanguageTranslate();
  const {i18n} = useTranslation()

  const serviceData = data ? data : emergencyServicesData;
  
  useEffect(() => {
    setBreadCrumbData([
      {
        label: translate("Online Services","الخدمات عبر الإنترنت"),
        link: `/`,
        clickable: true,
      },
      {
        label: translate("Emergency Services","خدمات الطوارئ"),
        clickable: false,
        isActive: true,
      },
    ]);
  }, [i18n?.resolvedLanguage]);
    return (
      <div className={`container mx-auto p-6 flex-col flex-wrap max767:p-5 emegencymodal`}>
        <div className="flex justify-between items-center">
          {!screenResolution.mobile && <Breadcrumb items={breadCrumbData} />}
          {/* {!screenResolution.mobile && (
            <span className="hidden last-login text-xs not-italic font-normal leading-4 text-gray-700">
              Last Login: 03 November 2024 at 05:20{" "}
            </span>
          )} */}
        </div>
        <div className="grid grid-cols-2 gap-6 w-[1000px] max-w-[100%] max767:grid-cols-1 mt-6 max767:mt-2">
        {serviceData.map((section, index) => (
         
          <AssistanceCard
            key={index}
            items={section?.items}
            data={!type && section?.data}
          />
         
        ))}
        </div>
      </div>
    );

};

export default EmergencyServices;
