import React, { useEffect, useMemo, useState } from "react";
import Button from "../../../../components/common/Button/Button";
import { useTranslation } from "react-i18next";
import ViewSummaryPopup from "../../../../components/newMotorPolicy/veiwSummaryPopup/viewSummaryPopup";
import useSummary from "../../../../hooks/useSummary";
import {
  currencyFormatter,
  currencyFormatterWithCountryCode,
  formatToThreeDigits,
} from "../../../../utils";
import Loader from "../../../../components/common/Loader/Loader";
import { useSelector } from "react-redux";
import { selectCarsLoader } from "../../../../redux/features/constants/carsListSlice";
import { selectBanksLoader } from "../../../../redux/features/constants/banksListSlice";
import { getSumInsuredLoader } from "../../../../redux/features/motor/getSumInsuredSlice";
import {
  getQuoteError,
  getQuoteLoading,
  getQuoteResponse,
} from "../../../../redux/features/motor/getQuoteSlice";
import { gdtRetrieveVehicleLoading } from "../../../../redux/features/motor/gdtSlice";
import {
  tokenLoading,
  uploadLoading,
} from "../../../../redux/features/upload/fileUploadSlice";
import { getScanOwnershipLoading } from "../../../../redux/features/ai/scannerSlice";
import { getTokenOtpLoading } from "../../../../redux/features/account/accountOtpSlice";
import {
  myPoliciesWholeLoader,
  selectMyPoliciesLoader,
} from "../../../../redux/features/myPolicySlice";
import {
  getPolicyPremiumError,
  getPolicyPremiumLoading,
  getPolicyPremiumResponse,
} from "../../../../redux/features/motor/renewalPremiumSlice";
import {
  getQuoteWithRefundError,
  getQuoteWithRefundLoading,
  getQuoteWithRefundResponse,
} from "../../../../redux/features/endorsements/cancellationSlice";
import { getRequestPolicyTransferLoading } from "../../../../redux/features/endorsements/transferPolicySlice";
import { selectNewMotorForm } from "../../../../redux/features/newMotorFormSlice";
import { getCalculatedPremiumLoader } from "../../../../redux/features/motor/calculatePremiumSlice";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import { ChangePlateNoLoading } from "../../../../redux/features/motor/ChangePlateNumberSlice";
export const COVERAGE_ENHANCEMENT = "COVERAGE_ENHANCEMENT";

const BottomStickyBtnLayout = ({
  children,
  btn_label,
  btn_onClick,
  btn_disabled,
  show_summary,
  price,
  included,
  carReplacement,
  AdditionalComponent,
  hideContinueBtn = false,
  passedFrom,
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [formState, setFormState] = useState({});
  const toggleShow = () => {
    setShow((state) => !state);
  };
  const {
    discount_amount,
    coverage_enhance_data,
    promocode,
    discount_percent,
    total_amount,
    // premium,
    discount,
    discount_percentage,
    vat_additional_amount,
  } = useSummary();

  const translate = useLanguageTranslate();
  // Loading indicator from redux. Would be better to have a global loading indicator for each API call, if needed
  const isCarListLoader = useSelector(selectCarsLoader);
  const isBanksListLoader = useSelector(selectBanksLoader);
  const isSumInsuredLoader = useSelector(getSumInsuredLoader);
  const isQuoteLoader = useSelector(getQuoteLoading);
  const isGdtLoader = useSelector(gdtRetrieveVehicleLoading);
  const isFilesLoading = useSelector(uploadLoading);
  const isTokenLoading = useSelector(tokenLoading);
  const isScannerLoading = useSelector(getScanOwnershipLoading);
  const isTokenOtpLoading = useSelector(getTokenOtpLoading);
  const isFetchPolicyLoading = useSelector(myPoliciesWholeLoader);
  const isPolicyPremiumLoading = useSelector(getPolicyPremiumLoading);
  const isCoverPriceLoading = useSelector(getCalculatedPremiumLoader);
  const isBankLoading = useSelector((state) => state.ScanBankLetter.status);
  const isGetPoliciesLoading = useSelector(selectMyPoliciesLoader);
  const isQuoteWithRefundLoading = useSelector(getQuoteWithRefundLoading);
  const isRequestTransferLoading = useSelector(getRequestPolicyTransferLoading);

  const isChangePlateLoading = useSelector(ChangePlateNoLoading);
  // Quote details for price
  const quoteResponse = useSelector(getQuoteResponse);
  // Premium details for price
  const policyPremiumResponse = useSelector(getPolicyPremiumResponse);
  // Quote with refund
  const quoteWithRefundResponse = useSelector(getQuoteWithRefundResponse);

  // Errors to disable button
  const quoteResponseError = useSelector(getQuoteError);
  const policyPremiumError = useSelector(getPolicyPremiumError);
  const quoteWithRefundError = useSelector(getQuoteWithRefundError);
  const coverageEnhanceData =
    useSelector(selectNewMotorForm)?.[COVERAGE_ENHANCEMENT];

  // const [totalPremium, setTotalPremium] = useState(
  //     // Check if quoteResponse is an object and has a valid TotalPremium
  //     typeof quoteResponse == 'object' && !Array.isArray(quoteResponse) && quoteResponse.TotalPremium != undefined
  //         ? quoteResponse.TotalPremium
  //         : // Check if policyPremiumResponse is an object and has a valid TotalPremium
  //         typeof policyPremiumResponse == 'object' && !Array.isArray(policyPremiumResponse) && policyPremiumResponse.TotalPremium != undefined
  //             ? policyPremiumResponse.TotalPremium
  //             : // Check if quoteRefundResponse is an object and has a valid TotalPremium
  //             typeof quoteWithRefundResponse == 'object' && !Array.isArray(quoteWithRefundResponse) && quoteWithRefundResponse.TotalPremium != undefined
  //                 ? quoteWithRefundResponse.TotalPremium
  //                 : ""
  // );

  // useEffect(() => {
  //     console.log("Adding price in bottomSticky");
  //     if (included == true) {
  //         setTotalPremium(totalPremium + price + price * 0.1);
  //     }
  //     if (included == false) {
  //         setTotalPremium(totalPremium - price - price * 0.1);
  //     }
  //     if (included == 'CMOT28') {
  //         setPreviousPrice(totalPremium);
  //         if (previousPrice){
  //             setTotalPremium(previousPrice + price + price * 0.1);
  //         }
  //         else {
  //             setTotalPremium(totalPremium + price + price * 0.1);
  //         }
  //     }
  // }, [price, included]);
  // useEffect(() => {
  //     console.log("Updating total premium");

  //     if (included == true) {
  //         setTotalPremium(totalPremium + price + price * 0.1);
  //     } else if (included == false) {
  //         setTotalPremium(totalPremium - price - price * 0.1);
  //     } else if (carReplacement == true) {
  //         // Store the current total as previous before adding CMOT28 price
  //         setPreviousPrice((prev) => prev || totalPremium);

  //         // Add to previousPrice, ignoring any further updates to current totalPremium
  //         setTotalPremium(previousPrice + price + price * 0.1);
  //     }
  // }, [price, included]);

  // useEffect(() => {
  //     setTotalPremium(
  //         // Check if quoteResponse is an object and has a valid TotalPremium
  //         typeof quoteResponse == "object" &&
  //             !Array.isArray(quoteResponse) &&
  //             quoteResponse.TotalPremium != undefined
  //             ? quoteResponse.TotalPremium
  //             : // Check if policyPremiumResponse is an object and has a valid TotalPremium
  //             typeof policyPremiumResponse == "object" &&
  //                 !Array.isArray(policyPremiumResponse) &&
  //                 policyPremiumResponse.TotalPremium != undefined
  //                 ? policyPremiumResponse.TotalPremium
  //                 : // Check if quoteRefundResponse is an object and has a valid TotalPremium
  //                 typeof quoteWithRefundResponse == "object" &&
  //                     !Array.isArray(quoteWithRefundResponse) &&
  //                     quoteWithRefundResponse.TotalPremium != undefined
  //                     ? quoteWithRefundResponse.TotalPremium
  //                     : ""
  //     );
  // }, [quoteResponse, policyPremiumResponse, quoteWithRefundResponse]);
  let premium = "";
  let tax = "";
  let totalPremium = "";
  if (passedFrom == "MotorNew") {
    premium =
      quoteResponse != ""
        ? quoteResponse.Premium
        : policyPremiumResponse.Premium;
    tax = quoteResponse != "" ? quoteResponse.Tax : policyPremiumResponse.Tax;
    totalPremium =
      quoteResponse != ""
        ? quoteResponse.TotalPremium
        : policyPremiumResponse.TotalPremium;

    // Add prices from coverage_enhance_data?.coverOptionsState if not empty
    if (coverage_enhance_data?.coverOptionsState?.length > 0) {
      const additionalPremium = coverage_enhance_data.coverOptionsState
        .filter(
          (option) =>
            option.included &&
            Number(option.price) > 0 &&
            !option.defaultIncludedInPlan
        ) // Ensure price is a number
        .reduce((sum, option) => sum + Number(option.price), 0); // Convert price to number before summing

      totalPremium += additionalPremium + additionalPremium * 0.1;
      tax = (premium + additionalPremium) * 0.1;
    }
  } else if (passedFrom == "MotorRenewal") {
    premium =
      policyPremiumResponse != "" ? policyPremiumResponse.Premium : "-";
    tax = policyPremiumResponse != "" ? policyPremiumResponse.Tax : "-";
    totalPremium =
      policyPremiumResponse != "" ? policyPremiumResponse.TotalPremium : "-";

    // Add prices from coverage_enhance_data?.coverOptionsState if not empty
    if (coverage_enhance_data?.coverOptionsState?.length > 0) {
      const additionalPremium = coverage_enhance_data.coverOptionsState
        .filter(
          (option) =>
            option.included &&
            Number(option.price) > 0 &&
            !option.defaultIncludedInPlan
        ) // Ensure price is a number
        .reduce((sum, option) => sum + Number(option.price), 0); // Convert price to number before summing

      totalPremium += additionalPremium + additionalPremium * 0.1;
      tax = (premium + additionalPremium) * 0.1;
    }
  }

  const [previousPrice, setPreviousPrice] = useState(totalPremium);

  console.log("Total Premium is from bottomSticky: ", totalPremium);
  console.log("Price in bottomSticky is: ", price);

  return (
    <>
      <div className="summarypopup">
        <ViewSummaryPopup
          show={show}
          setShow={setShow}
          formState={formState}
          setFormState={setFormState}
        />
      </div>

      {children}
      <div className="footer bg-white border-t-[#E5E9EB] border-t border-solid mt-2 w-full py-2 flex justify-end fixed pr-[3%] bottom-0 max767:w-full max767:p-4 max767:inset-x-0 ipad:items-center max767:inline-block max767:z-[9]">
        {show_summary &&
          totalPremium > 0 &&
          !isQuoteWithRefundLoading &&
          !isPolicyPremiumLoading &&
          !isQuoteLoader &&
          !quoteResponseError &&
          !policyPremiumError &&
          !quoteWithRefundError && (
            <div
              onClick={toggleShow}
              className="view_summary  mr-[2%] mt-3 max767:w-full max767:flex max767:mb-2 max767:justify-between ipad:mt-0  rtl:ml-3 rtl:mr-0"
            >
              <span className="text-[#00384D] text-center text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline mr-3 rtl:ml-3 rtl:mr-0">
                {t("motorInsurance.bottomStickybtnlayout.summary")}
              </span>
              <strong className="text-[#1A2024] text-xl not-italic font-extrabold leading-6">
                {translate("BHD", "المحدودة")} {currencyFormatter(totalPremium)}
              </strong>
            </div>
          )}
        <div className="flex justify-end pr-[3%] rtl:pl-[3%] max767:w-full max767:pr-[0%]">
          <div className="flex max767:w-full max767:flex-col max767:gap-2">
            {AdditionalComponent && AdditionalComponent}
            {!hideContinueBtn && (
              <Button
                isLoading={
                  isCarListLoader ||
                  isBanksListLoader ||
                  isSumInsuredLoader ||
                  isQuoteLoader ||
                  isGdtLoader ||
                  isFilesLoading ||
                  isTokenLoading ||
                  isScannerLoading ||
                  isTokenOtpLoading ||
                  isFetchPolicyLoading ||
                  isBankLoading == "loading" ||
                  isPolicyPremiumLoading ||
                  isQuoteWithRefundLoading ||
                  isGetPoliciesLoading ||
                  isCoverPriceLoading ||
                  isRequestTransferLoading ||
                  isChangePlateLoading
                }
                disabled={btn_disabled || quoteWithRefundError}
                onSubmit={btn_onClick ? btn_onClick : () => {}}
                type="submit"
                label={
                  btn_label ||
                  t(
                    "motorInsurance.newMotorInsuranceFlow.basicInfo.continueButton"
                  )
                }
                className="mb-0 stickybutton flex w-[200px] flex-col items-center gap-2.5 shrink-0 p-4 bg-[#55C6EF] rounded-[10px] text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px] max767:w-full max767:h-[40px]"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomStickyBtnLayout;
