import faSyncAlt from "../../../assets/images/darkrefresh.svg";
import faFileAlt from "../../../assets/images/fileclaimicon.svg";
import faPhone from "../../../assets/images/phone.svg";
import changefileicon from "../../../assets/images/changefileicon.svg";
import addmore from "../../../assets/images/Addmore-icon.svg";
import adjustcar from "../../../assets/images/adjustcarvalue.svg";
import changeplate from "../../../assets/images/changeplate.svg";
import transferpolicy from "../../../assets/images/transferpolicy.svg";
import removebank from "../../../assets/images/removebank.svg";
import cancelPolicy from "../../../assets/images/cancelPolicy.svg";
import CertificateDetails from "../../../assets/images/CertificateDetails.svg"


export const policyOverview = {
  policyInfo: {
    policy_label: "Your Vehicle",
    policy_label_ar: "دودج دورانجو - 516914",
    status: "Active",
    status_ar: "نشيط",
    planName: "Motor Insurance Policy - Flexi Plan",
    planName_ar: "وثيقة تأمين السيارات - خطة فليكسي",
    policyNumber: "P/0001/4/20/B003/0004341-05",
    date: {
      startDate: "01/02/2024",
      endDate: "31/01/2025",
    },
    btn: {
      icon: faSyncAlt,
      btn_lable_en: "Renew Policy",
      btn_lable_ar: "تجديد الوثيقة",
    },
  },


    
     cards : [
        // {
        //   icon: faFileAlt,
        //   value: "File a Claim",
        //   value_ar:"تقديم مطالبة",
        // },
        {
          icon:"" ,
          value: "Road Assistance",
          value_ar:"مساعدة على الطريق",
          call_option: {
            icon:faPhone,
            lable_en:"Call +973 17223351",
            label_ar: "اتصل على 973+ 17223351",
          }
        },
        {
          icon: "",
          value: "Home Assistance",
          value_ar:"مساعدة منزلية",
          call_option: {
            icon:faPhone,
            lable_en:"Call +973 17223351",
            label_ar: "اتصل على 973+ 17223351",
          }
        },
        {
          icon: "",
          value: "Airport Delivery Services",
          value_ar:"توصيل إلى المطار",
          call_option: {
            icon:faPhone,
            lable_en:"Call +973 17223351",
            label_ar: "اتصل على 973+ 17223351",
          }
        },
        // {
        //   icon: "",
        //   value: "Personal Cyber",
        //   value_ar:"السيبرانية الشخصية",
        //   call_option: {
        //     icon:faPhone,
        //     lable_en:"Call +973 17223351",
        //       label_ar: "اتصل على +973 17223351",
        //   }
        // },
      ]
}

export const vehicleCertificate = {
  tableData: [{
  data:[
    { "id": 1, "column1": "Owner", "column1_ar": "مالك", "column2": "SAYED JAWAD SAID_JAWAD SADEQ", "btn": { "btn_lable_en": "Transfer", "btn_lable_ar": "تحويل" } },
  { "id": 2, "column1": "Policy Number", "column1_ar": "رقم الوثيقة", "column2": "P/003/14/20/8003/0004341-05" },
  { "id": 3, "column1": "Start Date", "column1_ar": "تاريخ البدء", "column2": "01/02/2024" },
  { "id": 4, "column1": "End Date", "column1_ar": "تاريخ النهاية", "column2": "31/01/2025" },
  { "id": 5, "column1": "Plate Number", "column1_ar": "رقم اللوحة", "column2": "516914", "btn": { "btn_lable_en": "Change", "btn_lable_ar": "يتغير" } },
  { "id": 6, "column1": "Chassis Number", "column1_ar": "رقم الهيكل", "column2": "ERTY7896541235555" },
  { "id": 7, "column1": "Make", "column1_ar": "يصنع", "column2": "ACURA" },
  { "id": 8, "column1": "Manufacturing Year", "column1_ar": "سنة التصنيع", "column2": "2024" },
  { "id": 9, "column1": "Body Type", "column1_ar": "نوع الجسم", "column2": "SEDAN" },
  { "id": 10, "column1": "Engine Capacity", "column1_ar": "سعة المحرك", "column2": "1200 CC" },
  { "id": 11, "column1": "Seating Capacity", "column1_ar": "سعة المقاعد", "column2": "4 Seater" },
  { "id": 12, "column1": "Vehicle Colour", "column1_ar": "لون السيارة", "column2": "White" },
  { "id": 13, "column1": "Type of Cover", "column1_ar": "نوع الغطاء", "column2": "Motor - SEDAN" },
  { "id": 14, "column1": "Car Value", "column1_ar": "قيمة السيارة (مبلغ التأمين)", "column2": "BHD 7,000/-", "btn": { "btn_lable_en": "Adjust", "btn_lable_ar": "يُعدِّل" } },
  { "id": 15, "column1": "Excess", "column1_ar": "إفراط", "column2": "BHD 50/-" },
  { "id": 16, "column1": "Unknown Claim Excess", "column1_ar": "مطالبة غير معروفة", "column2": "BHD 100/-" }
]}],
 downloadCard: {
  label_en: "Your insurance certificate policy documents",
  label_ar: "مستندات وثيقة شهادة التأمين الخاصة بك",
  download_btn: true,
  filesData: [
    {
      fileName: "Vehicle Insurance Certificate",
      fileName_ar:"شهادة تأمين المركبة",
      fileData: {
        size: "165.7 KB",
      },
    },
  ],
},

}

export const CHANGE_PLAN = "CHANGE_PLAN";
export const ADD_MORE_COVERS = "ADD_MORE_COVERS";
export const ADJUST_SUM_INSURED = "ADJUST_SUM_INSURED";
export const TRANSFER_POLICY = "TRANSFER_POLICY"
export const REMOVE_BANK_NAME = "REMOVE_BANK_NAME";
export const CHANGE_PLATE_NUMBER="CHANGE_PLATE_NUMBER"
export const CANCEL_POLICY = "CANCEL_POLICY"
export const manageMyPolicy = {
  cards : [
    {
      icon: CertificateDetails,
      value: "Certificate Details",
      value_ar:"تغيير الخطة",
      type : CHANGE_PLAN
      
    },
    {
      icon: changefileicon,
      value: "Change plan",
      value_ar:"تغيير الخطة",
      type : CHANGE_PLAN
      
    },
    {
      icon: addmore ,
      value: "Add more covers",
      value_ar:"إضافة المزيد من الأغطية",
      type : ADD_MORE_COVERS
      
    },
    {
      icon: adjustcar,
      value: "Adjust car value (Sum Insured)",
      value_ar:"تعديل قيمة السيارة (مبلغ التأمين)",
      type: ADJUST_SUM_INSURED
    },
    {
      icon: changeplate,
      value: "Change plate number",
      value_ar:"تغيير رقم اللوحة",
      type: CHANGE_PLATE_NUMBER
    },
    {
      icon: removebank,
      value: "Remove bank name",
      value_ar:"إزالة اسم البنك",
      type: REMOVE_BANK_NAME
    },
    {
      icon: transferpolicy,
      value: "Transfer policy ownership",
      value_ar:"نقل ملكية السياسة",
      type: TRANSFER_POLICY
     
    },
    {
      icon: cancelPolicy,
      value: "Cancel Policy",
      value_ar: "إلغاء السياسة",
      type: CANCEL_POLICY
    }
  ]
}



export const coverageAndBenefits = {
  includeBenefits :[
      {
        "id": 1,
        "description": "3 Years Agency Repair",
         "description_ar":"إصلاح الوكالة لمدة 3 سنوات"
      },
      {
        "id": 2,
        "description": "BHD 500,000 Loss or Damage of Vehicle",
         "description_ar":"500,000 دينار بحريني خسارة أو تلف المركبة"
      },
      {
        "id": 3,
        "description": "Unlimited Third Party Bodily Injury",
         "description_ar":"إصابة جسدية غير محدودة لطرف ثالث"
      },
      {
        "id": 4,
        "description": "BHD 500 Emergency Treatment Cover",
         "description_ar":"تغطية العلاج الطارئ بقيمة 500 دينار بحريني"
      },
      {
        "id": 5,
        "description": "Towing Cover",
         "description_ar":"غطاء القطر"
      },
      {
        "id": 6,
        "description": "3 Years NIL Depreciation on Parts",
         "description_ar":"3 سنوات من عدم الاستهلاك على الأجزاء"
      },
      {
        "id": 7,
        "description": "BDS0 Windscreen",
         "description_ar":"BD50 الزجاج الأمامي"
      }
    ],

  optionalCoverage: [
    {
      "id": 1,
      "description": "Personal Accident Benefit to Driver",
      "description_ar":"إعانة الحوادث الشخصية للسائق"
    },
    {
      "id": 2,
      "description": "Car Replacement",
         "description_ar":"استبدال السيارة"
    },
    {
      "id": 3,
      "description": "Geographical Extension",
         "description_ar":"الامتداد الجغرافي"
    },
    {
      "id": 4,
      "description": "Roadside Assistance",
         "description_ar":"المساعدة على الطريق"
    },
    {
      "id": 5,
      "description": "Act of God",
         "description_ar":"فعل الله"
    },
    {
      "id": 6,
      "description": "VIP Service",
         "description_ar":"خدمة كبار الشخصيات"
    },
    {
      "id": 7,
      "description": "Riot, Strike and Malicious Damage",
         "description_ar":"الشغب والإضراب والتخريب المتعمد"
    },
    {
      "id": 8,
      "description": "Self Repair Authority",
         "description_ar":"سلطة الإصلاح الذاتي"
    },
    {
      "id": 9,
      "description": "Life Insurance",
         "description_ar":"تأمين على الحياة"
    }
  ],
  downloadCard: {
    label_en: "Your policy schedule document",
    label_ar: "وثيقة جدول السياسة الخاصة بك",
    download_btn: true,
    filesData: [
      {
        fileName: "Policy Schedule",
        fileName_ar:"جدول السياسة",
        fileData: {
          size: "165.7 KB",
        },
      },
    ],
  },
}

export const PolicyDetailsGlobal = {
  PolicyNumber: null,
  PolicyStatus: null
}