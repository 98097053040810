/* :Page:NewMotor: Car Plan Selector page */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PlanListTable from "./planListTable";
import SignInModal from "./signInModal";
import listblackicon from "../../../../assets/images/list-black.svg";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import { calculatePremiumRequest, coverPriceRequest, getMotorPlans, getQuoteRequest } from "./data";
import MobilePlanListTable from "./mobilePlanListTable";
import BackdropModal from "../../../../components/common/modal/backdropModal";
import TabPlanListTable from "./tabPlanListTable";
import NewMotorJourneyLayout from "../Layouts/NewMotorJourneyLayout";
import BottomStickyBtnLayout from "../Layouts/BottomStickyBtnLayout";
import useMotorForm from "../../../../hooks/useMotorFormData";
import useMotorFlow from "../../../../hooks/useMotorFlow";
import {
  FLOW_TYPE_NEW_CAR,
  FLOW_TYPE_USED_CAR_THIRD_PARTY_PLAN,
  FLOW_TYPE_USED_CAR_WITH_OC,
  FLOW_TYPE_USED_CAR_WITHOUT_OC,
} from "../../../../hooks/data";
import { screenResolution } from "../../../../utils/screenResolution";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import { useSelector, useDispatch } from "react-redux";
import { selectLoginTokenInfo } from "../../../../redux/features/loginRegisterSlice";
import useSummary from "../../../../hooks/useSummary";
import { selectNewMotorForm } from "../../../../redux/features/newMotorFormSlice";
import { CAR_INFO_BRAND_NEW, CAR_INFO_NEW_CAR_DETAILS, CAR_INFO_FINANCING, CAR_INFO_PLATE_NUMBER } from "../carDetails/carInfo/data";
import PlanCard from "./planCard";
import ViewFullListBenefitsModal from "./viewFullListBenefitsModal";
import { calculatePremium, getCalculatedPremiumResponse } from "../../../../redux/features/motor/calculatePremiumSlice";
import { getQuoteResponse, getQuoteCoversResponse, getQuote, getQuoteLoading, getQuoteSuccess, getQuoteError, getCoverPrices} from "../../../../redux/features/motor/getQuoteSlice"
import { unwrapResult } from "@reduxjs/toolkit";
import { gdtRetrieveVehicleResponse } from "../../../../redux/features/motor/gdtSlice";
import { getScanOwnershipResponse } from "../../../../redux/features/ai/scannerSlice";
import { selectProfileData } from "../../../../redux/features/profileSlice";
import { toast } from 'react-toastify';
import { clearApplyPromoState } from "../../../../redux/features/promoCode/promoCodeSlice";
import { PROMO_CODE } from "../../../../components/newMotorPolicy/veiwSummaryPopup/viewSummaryPopup";

export const PLAN_SELECTOR_TYPE = "PLAN_SELECTOR_TYPE";
export const THIRD_PARTY_KEY = "Third Party";
export const COVERAGE_ENHANCEMENT = "COVERAGE_ENHANCEMENT";

const PlanSelector = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const { handleNextSubstep, handleFlowChange, flowType } = useMotorFlow();
  const loginTokenInfo = useSelector(selectLoginTokenInfo);
  const userProfileInfo = useSelector(selectProfileData);

  const [selectedPlan, setSelectedPlan] = useState("Platinum");
  const [selectedPlanCode, setSelectedPlanCode] = useState("PL");
  const [showSignIn, setShowSignIn] = useState(false);
  const [viewPlanList, setViewPlanList] = useState(false);
  const {
    saveMotorDataInRedux: savePlanDataInRedux,
  } = useMotorForm(PLAN_SELECTOR_TYPE, setSelectedPlan);
  
  const [formState, setFormState] = useState(null);
  const {
    saveMotorDataInRedux: saveCoverageDataInRedux,
  } = useMotorForm(COVERAGE_ENHANCEMENT, setFormState);

  const { removeMotorFormData } = useMotorForm(
    PROMO_CODE,
    setFormState
  );

  // Get quote info
  const newCarInfoData = useSelector(selectNewMotorForm)?.[CAR_INFO_BRAND_NEW];
  const isQuoteLoading = useSelector(getQuoteLoading);
  const isQuoteSuccess = useSelector(getQuoteSuccess);
  const isQuoteError = useSelector(getQuoteError);


  // Global motor form data
  const motorFormData = useSelector(selectNewMotorForm);
  const carBrandNew = useSelector(selectNewMotorForm)?.[CAR_INFO_BRAND_NEW];
  const carDetails =
    useSelector(selectNewMotorForm)?.[CAR_INFO_NEW_CAR_DETAILS];
  const carFinancing = useSelector(selectNewMotorForm)?.[CAR_INFO_FINANCING];
  const plateNumber = useSelector(selectNewMotorForm)?.[CAR_INFO_PLATE_NUMBER]?.plateNumber;
  const newGdtEndDate = useSelector(selectNewMotorForm)?.[CAR_INFO_PLATE_NUMBER]?.newEndDate
  const newGdtFromDate = useSelector(selectNewMotorForm)?.[CAR_INFO_PLATE_NUMBER]?.newFromDate
  const plateType = useSelector(selectNewMotorForm)?.[CAR_INFO_PLATE_NUMBER]?.vehiclePlateType
  const carPlan = useSelector(selectNewMotorForm)?.[PLAN_SELECTOR_TYPE];
  const carGdtInfo = useSelector(gdtRetrieveVehicleResponse);
  const carOwnershipInfo = useSelector(getScanOwnershipResponse);

  const currentYear = new Date().getFullYear();
  const carAge = currentYear - carDetails.modelYear;

  console.log('Car age is: ', carAge);

  // Set calculate premium request. Passing the data from the forms saved in redux newMotorFormData state
  const calculatePremiumPayload = calculatePremiumRequest(
    carBrandNew,
    carDetails,
    carFinancing,
    carGdtInfo,
    carOwnershipInfo
  );
  // Get the response from the state.
  const calculatePremiumResponse = useSelector(getCalculatedPremiumResponse);

  // Calling API /Meej/calculatePremium
  useEffect(() => {
    console.log("Motor Form Data: ", motorFormData);
    console.log("Request should be: ", calculatePremiumPayload);

    dispatch(calculatePremium(calculatePremiumPayload));
  }, [motorFormData]);

  const [plans, setPlans] = useState([]);

  // Whenver the calculatePremiumResponse is changed, update the plans
  useEffect(() => {
    // Setting up plans from the calculate premium response. Based on selectedPlans we pass in the API. TPL, TPPlus, OFL, OPL
    if (calculatePremiumResponse.length > 0) {
      const tplPlan = calculatePremiumResponse.filter(
        (item) => item.PolicyPlan == "TPL"
      );
      const flPlan = calculatePremiumResponse.filter(
        (item) => item.PolicyPlan == "FL"
      );
      const tpPlusPlan = calculatePremiumResponse.filter(
        (item) => item.PolicyPlan == "TPPLUS"
      );
      const goPlan = calculatePremiumResponse.filter(
        (item) => item.PolicyPlan == "GO"
      );
      const plPlan = calculatePremiumResponse.filter(
        (item) => item.PolicyPlan == "PL"
      );
      const onlineFlPlan = calculatePremiumResponse.filter(
        (item) => item.PolicyPlan == "OFL"
      );
      const onlinePlPlan = calculatePremiumResponse.filter(
        (item) => item.PolicyPlan == "OPL"
      );

      setPlans(getMotorPlans(tplPlan, tpPlusPlan, onlineFlPlan, onlinePlPlan, carAge > 1));
    }
  }, [calculatePremiumResponse]);

  const onPlanSelectedContinueClicked = async () => {
    if (!loginTokenInfo) {
      togglePopupClickHandler();
      return;
    }

    // console.log("Calling quote with : ", carPlan.code);
    // console.log('User logged in: ', userProfileInfo);
    // console.log('User party is: ', userProfileInfo.Code);

    //Set Get Quote request
    const getQuotePayload = getQuoteRequest(
      carBrandNew,
      carDetails,
      carFinancing,
      carPlan.code,
      null,
      carGdtInfo,
      carOwnershipInfo,
      plateNumber,
      userProfileInfo ? userProfileInfo.Code : "FALCON",
      newGdtEndDate,
      newGdtFromDate,
      plateType
    );

    // Calling API /motor/quote
    if (carPlan.code != "") {
      try {
        // Clear promo code state
        await dispatch(clearApplyPromoState());
        await removeMotorFormData();

        const resultAction = await dispatch(getQuote(getQuotePayload));
        const quoteResult = unwrapResult(resultAction);

        // Clear finalCovers to reset covers on next page
        saveCoverageDataInRedux(null);
        // At this point, the quote has been successfully fetched
        // and the state should be updated

        if (quoteResult) {
          // Cover prices
          const coverPricesPayload = coverPriceRequest(
            quoteResult.PolicyNumber,
            carPlan.code,
            "",
            ""
          );
          await dispatch(getCoverPrices(coverPricesPayload));
          // You might want to check a specific property in quoteResult
          // IF conditions when moving to covers page.
          if (selectedPlan?.name == THIRD_PARTY_KEY) {
            handleFlowChange(FLOW_TYPE_USED_CAR_THIRD_PARTY_PLAN, 3, 0);
          } else if (newCarInfoData.is_brand_new == "Yes") {
            handleFlowChange(FLOW_TYPE_NEW_CAR, 3, 0);
          } else if (newCarInfoData.is_have_oc == "Yes") {
            handleFlowChange(FLOW_TYPE_USED_CAR_WITH_OC, 3, 0);
          } else {
            handleFlowChange(FLOW_TYPE_USED_CAR_WITHOUT_OC, 3, 0);
          }
        }
      } catch (error) {
        // alert("Error in getting quote.", error);
        console.log('getting quote error: ', isQuoteError);
      }
    }
  };
  const showSummaryClickHandler = () => {
    alert("show Summary");
  };
  const togglePopupClickHandler = () => {
    setShowSignIn((state) => !state);
  };

  const planOnClickHandler = (plan) => {
    console.log("Plan selected: ", plan);
    savePlanDataInRedux(plan);
    setSelectedPlan(plan);
  };

  const { total_amount } = useSummary();

  // Hiding plans
  const filteredPlans = plans.filter(plan => {
    if (carAge > 10) {
      // Only show Third Party (TPL and TPPLUS) plans
      return plan.code == 'TPL' || plan.code == 'TPPLUS';
    }
    else if (carFinancing.is_finance == "Yes") {
      // Only show OPL and OFL plans
      return plan.code == 'OFL' || plan.code == 'OPL';
    }
    else if (plateType != "01" && carBrandNew.is_brand_new == "No") {
      if (plateType != "10") {
        return plan.code == 'TPL' || plan.code == 'TPPLUS' || plan.code == 'OFL'; 
      }
      else {
        return plan.code == 'TPL'; 
      }
    }
    // else if (carFinancing.is_finance == "Yes" && carAge <= 1) {
    //   // Only show OPL and OFL plans
    //   return plan.code == 'OFL' || plan.code == 'OPL';
    // }
    // else if (carAge > 1) {
    //   // Exclude OPL plans
    //   return plan.code != 'OPL';
    // }
    // If no specific filtering is needed, return all plans
    return true;
  });
  

  return (
    <NewMotorJourneyLayout>
      <BottomStickyBtnLayout
        // btn_disabled={!total_amount}
        show_summary={false}
        btn_onClick={onPlanSelectedContinueClicked}
        passedFrom={"NewMotor"}
      >
        <div className="planSelector flex flex-col items-center p-6 max767:p-0 mx-auto pt-0">
          <SignInModal show={false} />
          <div className="planselectortitle max767:ml-[0px] max767:mt-0 max767:w-full max767:pb-0 max767:p-[22px] max767:pt-0">
            <TitleDescription
              title={t(
                "motorInsurance.newMotorInsuranceFlow.planSelector.title"
              )}
              description={t(
                "motorInsurance.newMotorInsuranceFlow.planSelector.description"
              )}
            />
          </div>

          <div className="max767:w-full max767:overflow-x-scroll max767:pt-5 ipad:overflow-x-hidden ipad:w-[77%]">
            <div className="flex gap-4 mb-4 max767:w-max max767:pl-4 max767:pr-0 max767:py-0 ipad:w-[536px] ipad:justify-end ipad:items-center ipad:content-center ipad:flex-wrap">
              {filteredPlans.map((plan) => (
                <PlanCard
                  planOnClickHandler={isQuoteLoading ? () => {} : planOnClickHandler}
                  plan={plan}
                  selectedPlan={selectedPlan}
                />
              ))}
            </div>
          </div>
          <button
            className="flex items-center gap-2 border bg-white px-4 py-2 rounded-[10px] border-solid border-[#DDE2E4] text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] ipad:mt-4"
            onClick={() => setViewPlanList(!viewPlanList)}
          >
            <img src={listblackicon} alt="altText" />
            {t(
              "motorInsurance.newMotorInsuranceFlow.planSelector.viewFullList"
            )}
          </button>
        </div>
      </BottomStickyBtnLayout>

      <ViewFullListBenefitsModal
        viewPlanList={viewPlanList}
        setViewPlanList={setViewPlanList}
      />
      <div className="choose-your-plan">
        <SignInModal
          show={showSignIn}
          setShow={setShowSignIn}
          cbSuccess={onPlanSelectedContinueClicked}
        />
      </div>
    </NewMotorJourneyLayout>
  );
};

export default PlanSelector;
