import React from "react";
import CarInfoBranNew from "./carInfoBranNew";
import CarInfoPlateNumber from "./carInfoPlateNumber";
import CarInfoConfirm from "./carInfoConfirm";
import CarInfoFinancing from "./carInfoFinancing";
import NewCarDetailsForm from "./newCarDetailsForm";
import useMotorFlow from "../../../../../hooks/useMotorFlow";

const CarInfo = () => {
  const { currentSubstep, handleNextSubstep } = useMotorFlow()
  const onSuccess = () => {
    handleNextSubstep();
  }
  return (
    <>
      {currentSubstep =="about_car" && <CarInfoBranNew onSuccess={onSuccess} />}
      {currentSubstep =='car_info_number_plate' && <CarInfoPlateNumber />}
      {currentSubstep =="car_financing" && <CarInfoFinancing onSuccess={onSuccess} />}
      {currentSubstep =="car_info_confirm" && <CarInfoConfirm />}
      {currentSubstep =="new_car_detail" && <NewCarDetailsForm onSuccess={onSuccess} />}
    </>
  );
};

export default CarInfo;
