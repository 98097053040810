import React, { useMemo, useState } from "react";
import TitleDescription from "../../../../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import RenewMotorLayout from "../../../../../motorInsurancePages/renewalMotorPolicy/renewMotorLayout";
import useFundsSwitchingFlow from "../../../../../../hooks/fundsSwitchingFlow";
import MobileFundsPremiumTableAlert from "../../../../../../components/common/investments/mobileFundsPremiumTableAlert";
import useScreenResolution from "../../../../../../hooks/useScreenResolution";
import useCreateLifeRequests, {
  LIFE_REQUEST_TYPE,
} from "../../../../../../hooks/life/useCreateLifeRequests";
import { decodeQueryString, intFromBhdCurrency } from "../../../../../../utils";
import AllocationTable from "../../../../../../components/common/investments/AllocationTable";
import CustomCheckbox from "../../../../../../components/common/customCheckbox/customCheckbox";

const FundsAllocationPage = ({
  data,
  fundsToRemove,
  setSelectedNewFunds,
  isUserChangedFunds,
  setIsUserChangedFunds,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { search } = useLocation();
  const [total, setTotal] = useState(100);
  const [errorMsg, setErrorMsg] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const screenResolution = useScreenResolution();

  const { isLoading: requestLoading, createLifeRequest } =
    useCreateLifeRequests();

  const params = decodeQueryString();
  const continueClickHandler = async () => {
    let payload = {
      PolicyNumber: params?.policy,
      RequestType: LIFE_REQUEST_TYPE.fundsSwitch,
      SwitchFrom: fundsToRemove?.map((each) => ({
        ISIN: each?.original?.isin,
        NumberOfUnits: each?.original?.units_held || 0,
        FundValue: each?.original?.total_value || 0,
        FundName: each?.original?.fund_name || "",
      })),
      SwitchTo: data?.map((each) => ({
        ISIN: each?.isin,
        NumberOfUnits: each?.units_held || 0,
        FundValue: each?.total_value || 0,
        FundName: each?.fund_name || "",
      })),
    };
    const response = await createLifeRequest(payload);
    navigate(`/life-insurance/policy-overview${search}`);
  };
  const { handlePrevSubstep } = useFundsSwitchingFlow();
  const allocationTotal = useMemo(() => {
    const total = fundsToRemove?.reduce(
      (acc, each) =>
        acc + (parseFloat(intFromBhdCurrency(each.original.fund_value)) || 0),
      0
    );
    return total;
  }, []);
  return (
    <RenewMotorLayout
      AdditionalComponent={
        screenResolution.mobile && (
          <MobileFundsPremiumTableAlert
            total={total}
            errorMsg={errorMsg}
            allocationTotal={allocationTotal}
            fundsData={data}
            tableType="allocation"
          />
        )
      }
      btn_onClick={continueClickHandler}
      backClickHandler={handlePrevSubstep}
      btn_disabled={
        !isChecked || total != allocationTotal || requestLoading || errorMsg
      }
      btn_label={"Confirm and Submit"}
    >
      <div className="w-[820px] max-w-[100%] mx-auto overflow-x-auto">
        <TitleDescription
          className="max767:p-5 max767:pb-0"
          title={t(
            "lifeInsurance.investmentStrategyFlow.fundSwitchingFlow.fundAllocationPage.title"
          )}
          description={t(
            "lifeInsurance.investmentStrategyFlow.fundSwitchingFlow.fundAllocationPage.description"
          )}
        />
        <div className="table-allocation">
          <AllocationTable
            fundsData={data || []}
            tableType="allocation"
            fundsToRemove={fundsToRemove}
            total={total}
            setTotal={setTotal}
            allocationTotal={allocationTotal}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            setFundsData={setSelectedNewFunds}
            isUserChangedFunds={isUserChangedFunds}
            setIsUserChangedFunds={setIsUserChangedFunds}
          />
          <div className="px-0 flex mt-[30px] max767:px-5">
            <CustomCheckbox
              Label={
                <label
                  htmlFor="agreement"
                  className="text-[#252C32] text-sm not-italic font-normal leading-6"
                >
                  I accept{" "}
                  <a
                    href=" https://www.solidarity.com.bh/termsofuse"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#00384D] text-sm not-italic font-semibold leading-6 underline"
                  >
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://www.solidarity.com.bh/privacyandsecurity"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#00384D] text-sm not-italic font-semibold leading-6 underline"
                  >
                    Privacy policy.
                  </a>
                </label>
              }
              isChecked={isChecked}
              setIsChecked={setIsChecked}
            />
          </div>
        </div>
      </div>
    </RenewMotorLayout>
  );
};

export default FundsAllocationPage;
