import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import {  motorApiEndpoints } from "../../../api";

const initialState = {
  VehicleDetails: [],
  isLoading: false,
  success: null,
  error: null,
};


export const ChangePlateNo = createAsyncThunk(
    "ChangePlateNumber",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling Endorsement/updateRegNumber...")
            const response = await Axios.post(`${motorApiEndpoints.ChangePlate}`, payload);
            console.log("Endorsement/updateRegNumber response is: ", response.data)

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const ChangePlateNoSlice = createSlice({
  name: "ChangePlateNoSlice",
  initialState,
  reducers: {
    clearChangePlateNoError: (state) => {
      state.error = null;
    },
    clearChangePlateState: () => initialState
  },
  extraReducers: (builder) => {
    // Update Reg API
    builder.addCase(ChangePlateNo.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(ChangePlateNo.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
      state.VehicleDetails = action?.payload.VehicleDetails;
    });
    builder.addCase(ChangePlateNo.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      console.log("ChangePlateNo error: ", action.payload)
    });
  },
});

// SELECTORS
export const ChangePlateNoResponseReg = (store) => store?.ChangePlateNo.RegistrationNumber;
export const ChangePlateNoResponsePol = (store) => store?.ChangePlateNo.PolicyNumber;
export const ChangePlateNoLoading = (store) => store?.ChangePlateNo.isLoading;
export const ChangePlateNoError = (store) => store?.ChangePlateNo.error;
export const ChangePlateNoSuccess = (store) => store?.ChangePlateNo.success;


// ACTIONS TO DISPATCH
export const { clearChangePlateNoError, clearChangePlateState } = ChangePlateNoSlice.actions;

export default ChangePlateNoSlice.reducer;
