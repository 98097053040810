import React, { useEffect } from "react";
import useChangeMobileNumberFlow from "../../../hooks/useChangeMobileNumberFlow";
import AccountPasswordPage from "./accountPasswordPage";
import UpdateSucccessPage from "./updateSuccessPage";
import ChangeEmailPage from "./changeEmailPage";
import { ACCOUNT_PASSWORD_PAGE, CHANGE_EMAIL_FLOW, REGISTER_SUCCESS_PAGE, UPDATED_EMAIL_PAGE } from "../changeMobileNumberFlow/data";

const ChangeEmailFlow = () => {
  const { currentStep, handleFlowChange } = useChangeMobileNumberFlow();

  useEffect(() => {
    handleFlowChange(CHANGE_EMAIL_FLOW);
  }, []);
  console.log("Change Mobile Number Flow=" + currentStep?.name);
  const PageRenderer = () => {
    switch (currentStep?.name) {
      case ACCOUNT_PASSWORD_PAGE:
        return <AccountPasswordPage />;
      case UPDATED_EMAIL_PAGE:
        return <ChangeEmailPage />;
      case REGISTER_SUCCESS_PAGE:
        return <UpdateSucccessPage />;
      default:
        return null;
    }
  };
  return (
    <div className="changeMobileNumberModal pt-[5%] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll ">
      <PageRenderer />
    </div>
  );
};

export default ChangeEmailFlow;
