import React, { useState } from 'react'
import TitleDescription from '../../../../components/newMotorPolicy/titleDescription/titleDescription';
import { useTranslation } from 'react-i18next';
import useMotorForm from '../../../../hooks/useMotorFormData';
import { PROMO_CODE } from '../../../../components/newMotorPolicy/veiwSummaryPopup/viewSummaryPopup';
import { useNavigate } from 'react-router-dom';
import ReviewMotorTable from '../../renewalMotorPolicy/reviewAndConfirmPage/reviewMotorTable';
import PromoCode from '../../../../components/newMotorPolicy/veiwSummaryPopup/promoCode';
import Summary from '../../../../components/newMotorPolicy/veiwSummaryPopup/summary';
import forwardarrow from "../../../../assets/images/arrow_forwardb.svg";
import AdjustSumInsuredMotorLayout from '../adjustSumInsuredMotorLayout';
import Input from '../../../../components/common/Input/Input';
import useLanguageTranslate from '../../../../hooks/useLanguageTranslate';
import { useDispatch, useSelector } from 'react-redux';
import { getQuoteWithRefundResponse } from '../../../../redux/features/endorsements/cancellationSlice';
import { getPolicyPayLoading, policyPay } from '../../../../redux/features/payment/policyPaySlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Loader } from 'storybook/internal/components';
import { getPolicyViewResponse } from '../../../../redux/features/policyDetailsSlice';
import Button from '../../../../components/common/Button/Button';

const ReviewAndConfirmPage = () => {
  /* Setting up dispatch to call redux */
    const dispatch = useDispatch();

  /* Setting up variables with appropriate requests to send and response to receive, reading from redux/functions */

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formState, setFormState] = useState({});
  const translate = useLanguageTranslate()

  const { saveMotorDataInRedux, removeMotorFormData } = useMotorForm(
    PROMO_CODE,
    setFormState
  );

  const applyPromoCode = () => {
    saveMotorDataInRedux(formState);
  };

  const quoteWithRefundResponse = useSelector(getQuoteWithRefundResponse);
  const policyViewResponseDetails = useSelector(getPolicyViewResponse);
  const policyPayLoading = useSelector(getPolicyPayLoading);
  
  // Setting up policy pay
  const quoteNumber = quoteWithRefundResponse.PolicyNumber
  const totalPremium = quoteWithRefundResponse.TotalPremium;
  /* Next / Payment */
  const handlePayment = async () => {
    if (quoteNumber && totalPremium) {
      const payload = {
        PolicyNumber: quoteNumber,
        Premium: totalPremium,
        Channel: "online.solidarity",
        IsChangedPlan: true,
        PreviousPolicyNumber: policyViewResponseDetails.PolicyHeader?.PolicyNumber 
      };

      try {
        // Calling API policy/pay
        const linkAction = await dispatch(policyPay(payload));
        const link = unwrapResult(linkAction)
        // console.log("Link is: ", link)
        if (link) {
          window.location.href = link;
        }

      }
      catch (error) {
        // alert("Error in getting payment link: ", error)
        toast.error("Error in getting payment link: ", error);
      }
      
    }
  };


  return (
    <AdjustSumInsuredMotorLayout is_bottom_sticky_layout={false}>
      <div className="adjustsumvalue p-4 w-[632px] max-w-full mx-auto mb-0">
        <TitleDescription
          title={t(
            "motorInsurance.adjustSumInsuredFlow.reviewAndConfirmPage.title"
          )}
          description={t(
            "motorInsurance.adjustSumInsuredFlow.reviewAndConfirmPage.description"
          )}
        />
        <div className="flex flex-col items-center mx-auto max-w-lg">
          <div className="mt-2 bg-[#F6F8F9] p-5 rounded-2xl w-full max767:bg-[#FFF] max767:p-0">
            <p className="text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px] mb-3">
              {t(
                "motorInsurance.adjustSumInsuredFlow.reviewAndConfirmPage.you_have_increased_label"
              )}
            </p>
            <div className="amountInput reviwsumvalue mb-4">
              <Input
                className="initialAmount w-full border border-solid border-[#B0BABF] flex justify-center items-center gap-2.5 self-stretch pl-12 px-4 py-3 bg-[#E5E9EB] text-[#6E7C87] text-lg not-italic font-normal leading-[26px] tracking-[-0.18px] rounded-[10px] border-solid border-[#B0BABF] max767:mb-0"
                type="number"
                name="amount"
                value={policyViewResponseDetails?.PolicyHeader?.OldSumInsured}
                startAdornment={
                  <p className="text-[#6E7C87] text-lg not-italic font-normal leading-[26px] tracking-[-0.18px">
                    {" "}
                    {translate("BHD", "د.ب")}
                  </p>
                }
              />
              <div className="w-0.5 h-8 shrink-0 bg-[#E5E9EB] ml-[25px]"></div>
              <Input
                className="increasedAmount w-full flex border border-solid border-[#B0BABF] justify-center items-center gap-2.5 self-stretch pl-12 px-4 py-3 bg-[#FFF] text-[#1A2024] text-lg not-italic font-semibold leading-[26px] tracking-[-0.18px] rounded-[10px] border-solid border-[#B0BABF] max767:mb-6"
                type="number"
                name="amount"
                value={quoteWithRefundResponse.SumInsured}
                startAdornment={
                  <p className="text-[#1A2024] text-lg not-italic font-semibold leading-[26px] tracking-[-0.18px">
                    {" "}
                    {translate("BHD", "د.ب")}
                  </p>
                }
              />
            </div>
            <ReviewMotorTable passedFrom={"MotorChangeActivePlan"} />
            <div className="bg-[#F6F8F9] rounded-[26px] p-4 mb-4 w-full summary-box">
              <PromoCode
                promoCode={formState.promoCode}
                setPromoCode={(code) =>
                  setFormState((prevState) => ({
                    ...prevState,
                    promoCode: code,
                  }))
                }
                applyPromoCode={applyPromoCode}
                clearPromoCode={() => {
                  setFormState({});
                  removeMotorFormData({});
                }}
              />

              <Summary passedFrom={"MotorChangeActivePlan"} />

              {/* Proceed Button */}
              <Button
                isLoading={policyPayLoading}
                className={`bg-[#55C6EF] mt-4 w-full text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] flex justify-center p-3 rounded-[10px]`}
                onSubmit={handlePayment}
                label={t(
                  "motorInsurance.adjustSumInsuredFlow.reviewAndConfirmPage.proceed_to_payment"
                )}
                post_icon={forwardarrow}
                post_icon_class={"w-6 h-6 ml-[5px] rtl:rotate-180 rtl:mr-2"}
              />
            </div>
          </div>
        </div>
      </div>
    </AdjustSumInsuredMotorLayout>
  );
}

export default ReviewAndConfirmPage