import React from "react";
import useExpressShieldFlow from "../../../../hooks/BuyOnlineExpressShield/useExpressShieldFlow";
import BasicInfo from "./basicInfo";
import OtherDetails from "./otherDetails";

const Details = () => {
  const { currentSubstep, handleNextSubstep } = useExpressShieldFlow();
  const onSuccess = () => {
    handleNextSubstep();
  };
  return (
    <>
      {currentSubstep =="basic_details" && <BasicInfo onSuccess={onSuccess} />}
      {currentSubstep =="other_details" && (
        <OtherDetails onSuccess={onSuccess} />
      )}
    </>
  );
};

export default Details;
