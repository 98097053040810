/* :Page:NewMotor: Covers Pages */
/* 
coverOptions (init) -> formState (included covers) -> finalCovers
Brief about the below, 
we basically have three main variables for covers.
1. coverOptions 
2. formState
3. FinalCovers

and one function that sets up the variables and data. From this we change the selections, defaults and prices based on plan and cover response from API
- setCoverOptions

first, what is shown is the coverOptions with the default of the function. Then after the cover/prices API is called, we change the var of coverOptions 
by passing the new result of cover prices to the setCoverOptions

FormState is a copy of coverOptions, BUT, it has the included covers. For example, when a user selects a cover, it changes in formState as included = true

Finally, formState is then copied into finalCovers to be passed into the final getQuote. Having the covers with included = true

Selecting a cover calls updateOptionValues which updates the formState.

Special cases were made for car replacemen, we call the cover/prices API with each change of days and size as price is dependent on them. 

*/
import React, { useEffect, useState } from "react";
import CoverOption from "./CoverOption";
import useMotorForm from "../../../../hooks/useMotorFormData";
import NewMotorJourneyLayout from "../Layouts/NewMotorJourneyLayout";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useTranslation } from "react-i18next";
import BottomStickyBtnLayout from "../Layouts/BottomStickyBtnLayout";
import { setCoverOptions, carReplacementPrice, geoExtensionPrice } from "./data";
import useMotorFlow from "../../../../hooks/useMotorFlow";
import { useSelector, useDispatch } from "react-redux";
import { getCoverPrices, getQuoteResponse, getQuoteCoversResponse, getQuote } from "../../../../redux/features/motor/getQuoteSlice";
import { coverPriceRequest, getQuoteRequest } from "../planPages/data";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import { selectNewMotorForm } from "../../../../redux/features/newMotorFormSlice";
import { CAR_INFO_BRAND_NEW, CAR_INFO_FINANCING, CAR_INFO_NEW_CAR_DETAILS, CAR_INFO_PLATE_NUMBER } from "../carDetails/carInfo/data";
import { PLAN_SELECTOR_TYPE } from "../planPages/planSelectorPage";
import { gdtRetrieveVehicleResponse } from "../../../../redux/features/motor/gdtSlice";
import { getScanOwnershipResponse } from "../../../../redux/features/ai/scannerSlice";
import { RENEW_POLICY_COVERAGE_ENHANCEMENT } from "../../renewalMotorPolicy/enhanceYourCoveragePage/enhanceYourCoveragePage";
import { selectProfileData } from "../../../../redux/features/profileSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { clearApplyPromoState } from "../../../../redux/features/promoCode/promoCodeSlice";
import { PROMO_CODE } from "../../../../components/newMotorPolicy/veiwSummaryPopup/viewSummaryPopup";
export const COVERAGE_ENHANCEMENT = "COVERAGE_ENHANCEMENT";

const CoverageEnhancement = () => {
  /* Setting up dispatch to call redux */
  const dispatch = useDispatch();

  /* Setting up variables with appropriate requests to send and response to receive, reading from redux/functions */
  const quoteResponse = useSelector(getQuoteResponse);
  
  const userProfileInfo = useSelector(selectProfileData);
  const coverPricesResponse = useSelector(getQuoteCoversResponse);
  const carGdtInfo = useSelector(gdtRetrieveVehicleResponse);
  const carOwnershipInfo = useSelector(getScanOwnershipResponse);
  const finalCovers = useSelector(selectNewMotorForm)?.[COVERAGE_ENHANCEMENT];
  const carBrandNew = useSelector(selectNewMotorForm)?.[CAR_INFO_BRAND_NEW];
  const carDetails = useSelector(selectNewMotorForm)?.[CAR_INFO_NEW_CAR_DETAILS];
  const carFinancing = useSelector(selectNewMotorForm)?.[CAR_INFO_FINANCING];
  const carPlan = useSelector(selectNewMotorForm)?.[PLAN_SELECTOR_TYPE];
  const plateNumber = useSelector(selectNewMotorForm)?.[CAR_INFO_PLATE_NUMBER]?.plateNumber;
  const newGdtEndDate = useSelector(selectNewMotorForm)?.[CAR_INFO_PLATE_NUMBER]?.newEndDate
  const newGdtFromDate = useSelector(selectNewMotorForm)?.[CAR_INFO_PLATE_NUMBER]?.newFromDate
  const plateType = useSelector(selectNewMotorForm)?.[CAR_INFO_PLATE_NUMBER]?.vehiclePlateType

  const currentSelectedCarDays = finalCovers ? finalCovers?.coverOptionsState?.find(car => car.code =="CMOT28")?.selectedDays : "8";
  const currentSelectedCarSize = finalCovers? finalCovers?.coverOptionsState?.find(car => car.code =="CMOT28")?.selectedCarType : "SMALL";
  const currenetSelectedGeoOption = finalCovers? finalCovers?.coverOptionsState?.find(car => car.code =="CMOT24")?.selectedGeoOption : "KSA";



  const coverPricesPayload = coverPriceRequest(
    quoteResponse.PolicyNumber,
    carPlan.code,
    "",
    ""
  );
  /* Functions and Variables that are updated with functions/state */

  // coverOptions is the actual list show. We use the function setCoverOptions to manipulate the list by passing the coverPriceResponse and plan name. If we want to change the coverOptions later we use setCoverOptionState
  const [coverOptions, setCoverOptionsState] = useState(
    // setCoverOptions(coverPricesResponse, quoteResponse.PlanName, finalCovers)
    setCoverOptions(coverPricesResponse, carPlan.code, finalCovers)
  );

  
  // Single cover state with properties (name, code, price, etc...). Used when enabling/disabling toggle of a cover. Initializing it as coverOptions
  const [formState, setFormState] = useState({
    coverOptionsState: finalCovers ==null ? coverOptions : finalCovers
  });


  console.log('on init, form state is: ', formState);

  const { t } = useTranslation();
  const { handleNextSubstep } = useMotorFlow();

  // Sets up saving form data (Covers) in redux COVERAGE_ENHANCEMENT. Which we then filter based on field included = true
  const { saveMotorDataInRedux } = useMotorForm(
    COVERAGE_ENHANCEMENT,
    setFormState
  );

  const { removeMotorFormData } = useMotorForm(
    PROMO_CODE,
    setFormState
  );

  // Temp price to add the cover price to the total in the bottom layout
  const [tempPrice, setTempPrice] = useState(0);

  // Updates selected cover
  const updateOptionValues = async (name, updates, price) => {
    
    // If agency repair is selected, disable the dep. on parts CMOT99
    // setTempCarReplacement(false);
    console.log("updating cover.", name, updates);
    // If Platinum plan, setting default based on selected. Setting minimum for SMALL30 and MEDIUM15
    if (quoteResponse.PlanName =="OPL") {
      if (updates.selectedCarType =="SMALL") {
        updates.selectedDays = "30";
        updates.noOfDays = [
          {
            label_en: "30 Days",
            label_ar: "30 إدارة سياستي",
            type: "30",
          },
        ] 
      } else if (updates.selectedCarType =="MEDIUM") {
        updates.selectedDays = "15";
        updates.noOfDays = [
          {
            label_en: "15 Days",
            label_ar: "15 التغطيات والمزايا",
            type: "15",
          },
          {
            label_en: "30 Days",
            label_ar: "30 إدارة سياستي",
            type: "30",
          },
        ] 
      }
      else if (updates.selectedCarType =="LUXURY"){
        updates.selectedDays = "8";
        updates.noOfDays = [
          {
            label_en: "8 Days",
            label_ar: "8 عاةلى السياسة",
            type: "8",
          },
          {
            label_en: "10 Days",
            label_ar: "10 شهادة المركبة",
            type: "10",
          },
          {
            label_en: "15 Days",
            label_ar: "15 التغطيات والمزايا",
            type: "15",
          },
          {
            label_en: "30 Days",
            label_ar: "30 إدارة سياستي",
            type: "30",
          },
        ]
      }
    }
    // Setting car replacement options prices in card view 
    if (name =="CMOT28") {   
      // Setting if cover is included by default for OFL
        if (quoteResponse.PlanName =="OFL" && (updates.selectedDays =="8" && currentSelectedCarSize =="SMALL") || (currentSelectedCarDays =="8" && updates.selectedCarType =="SMALL")) {
          updates.defaultIncludedInPlan = true;
        }
        else if (quoteResponse.PlanName =="OPL" && (updates.selectedDays =="30" && currentSelectedCarSize =="SMALL") || (currentSelectedCarDays =="30" && updates.selectedCarType =="SMALL")) {
          updates.defaultIncludedInPlan = true;
        }
        else if (carPlan.code =="TPPLUS" && (updates.selectedDays =="8" && currentSelectedCarSize =="SMALL") || (currentSelectedCarDays =="8" && updates.selectedCarType =="SMALL")) {
          updates.defaultIncludedInPlan = true;
        }    
        else {
          updates.defaultIncludedInPlan = false;
        }
        // Await the carReplacementPrice function.
        price = await carReplacementPrice(
            updates.selectedDays || currentSelectedCarDays,
            updates.selectedCarType || currentSelectedCarSize
        );        
        updates.price = price; // Set the updated price.
        // updates.included = "CMOT28"; // Uncomment if needed
    }

    // Setting geo options options prices in card view 
    if (name =="CMOT24") {
      // if (quoteResponse.PlanName =="OFL" && (updates.selectedGeoOption =="KSA") ) {
      //     updates.defaultIncludedInPlan = true;
      // }
      // else {
      //   updates.defaultIncludedInPlan = false;
      // }
      // if (updates.selectedGeoOption =="GCC" || currenetSelectedGeoOption =="GCC") {
        const tempCoverPriceRequest = {
          PolicyNumber: quoteResponse.PolicyNumber,
          GeoCoverKSA: updates.selectedGeoOption =="GCC" ? false : true
        }
        const coverPriceAction = await dispatch(getCoverPrices(tempCoverPriceRequest));
        const coverPriceResult = unwrapResult(coverPriceAction);
        
        price = await geoExtensionPrice(updates.selectedOption =="GCC", coverPriceResult);
        updates.price = price; // Set the updated price.
      // }
    }

    console.log("PLAN: ", quoteResponse.PlanName);
    // Set a list of covers as a var and then makes selected cover as included true. Then passes it as the original form (cover options)
    console.log('before update form state is: ', formState);

    // update is defined as a shallow copy of formstate, then formState.coverOptions is mapped. For each option, we check whether option.name matches name, then we keep options (other fields price, name code, etc) and add updates (included field)
    // Basically, updates the formState included field to true for the selected cover 
    // let update = {
    //   ...formState,

    //   coverOptionsState: formState.coverOptionsState.map((option) =>
    //     option.code ==name ? { ...option, ...updates } : option
    //   ),
    // };

    let update = {
      ...formState,
    
      coverOptionsState: formState.coverOptionsState.map((option) => {
        // Update for `name` (CMOT2 logic)
        if (option.code ==name) {
          return { ...option, ...updates };
        }
        // Update for CMOT99 if the name is CMOT2 and updates.included is true
        if (name =="CMOT2" && updates.included && option.code =="CMOT99") {
          return { 
            ...option, 
            included: true, 
            defaultIncludedInPlan: true 
          };
        }
        else if (name =="CMOT2" && !updates.included && option.code =="CMOT99") {
          return { 
            ...option, 
            included: false, 
            defaultIncludedInPlan: false 
          };
        }
        // Return other options unchanged
        return option;
      }),
    };

    console.log('Setting update: ', update);


    // Set form state, which is basically the updated covers and their properties
    setFormState(update);
    // Saves the updated covers in redux COVERAGE_ENHANCEMENT to use globally
    await saveMotorDataInRedux(update);

  };

  /* Use Effects, monitor changes to call on [var] change or one time call */

  // If quote response is changed, call get cover prices with new quote
  useEffect(() => {
    console.log("Quote details: ", quoteResponse);
    console.log("Quote no. is: ", quoteResponse.PolicyNumber);
    console.log("Quote plan is: ", quoteResponse.PlanName);

    // Calling API /Meej/CoverPrices
    if (finalCovers ==null) {
      dispatch(getCoverPrices(coverPricesPayload));
    }

    // Get the quote resposne from the redux state
  }, []);




  /* Next page */
  const NextHandler = async () => {
    console.log('Cover Options are: ', coverOptions);
    console.log('Form State is: ', formState);
    console.log("Calling last get quote, with final covers: ", finalCovers);

    try {

      const getQuotePayload = getQuoteRequest(
        carBrandNew,
        carDetails,
        carFinancing,
        carPlan.code,
        finalCovers,
        carGdtInfo,
        carOwnershipInfo,
        plateNumber,
        userProfileInfo ? userProfileInfo.Code : "NULL",
        newGdtEndDate,
        newGdtFromDate,
        plateType
      );
  
      console.log("Payload to be sent with covers: ", getQuotePayload);
  
      // Clear promo code state
      await dispatch(clearApplyPromoState());
      await removeMotorFormData();
      // Calling API /motor/quote
      const finalQuoteAction = await dispatch(getQuote(getQuotePayload));
      const finalQuoteResult = unwrapResult(finalQuoteAction);
      if (finalQuoteResult) {
        handleNextSubstep();
      }
    }
    catch (error) {
      console.log("Error in getting quote: ", error);
    }

  };

  return (
    <NewMotorJourneyLayout>
      <BottomStickyBtnLayout show_summary btn_onClick={NextHandler} price={tempPrice} passedFrom={"MotorNew"}>
        <div className="coverageEnhancement flex flex-col items-center p-6 mx-auto  max767:pt-0 max767:p-5">
          <TitleDescription
            title={t(
              "motorInsurance.newMotorInsuranceFlow.coverageEnhancement.title"
            )}
            description={t(
              "motorInsurance.newMotorInsuranceFlow.coverageEnhancement.description"
            )}
          />
          <div className=" w-[33%] max-350:w-[100%] max767:w-[100%] ipad:w-[55%]">
          {Array.isArray(formState.coverOptionsState) && formState.coverOptionsState.map((option) => (
            <CoverOption
              key={option.code}
              option={option}
              updateOptionValues={updateOptionValues}
            />
          ))}


          </div>
        </div>
      </BottomStickyBtnLayout>
    </NewMotorJourneyLayout>
  );
};

export default CoverageEnhancement;
