import React, { useEffect, useState } from "react";
import {
  createColumnHelper,
  useReactTable,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
} from "@tanstack/react-table";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import useScreenResolution from "../../../hooks/useScreenResolution";
import { currencyFormatterWithCountryCode } from "../../../utils";
import CustomCheckbox from "../customCheckbox/customCheckbox";

const FundSwitchingTable = ({
  rowSelection,
  setRowSelection,
  setFundsToRemove,
  isContinueClicked,
  data,
}) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const columnHelper = createColumnHelper();
  const translate = useLanguageTranslate();
  const screenResolution = useScreenResolution();
  let fundsDataToRemove = data
    ?.filter((each) => rowSelection?.[each?.id])
    .map((each) => ({
      original: each,
    }));
  console.log("Removing", fundsDataToRemove);
  const toggleRow = (e, fundId) => {
    setExpandedRow(expandedRow == fundId ? null : fundId);
  };

  const handleCheckboxClick = (e, id) => {
    // Prevent checkbox click from toggling the row collapse/expand
    e.stopPropagation();
    setRowSelection((prev) => ({
      ...prev,
      [id]: !rowSelection[id],
    }));
  };

  const columns = [
    columnHelper.display({
      id: "select",
      meta: { sticky: true },
      header: ({ table }) => (
        <CustomCheckbox
          className="mb-[0] mt-[0] px-[0] py-[0]"
          inputClassName=""
          isChecked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      ),
      cell: ({ row }) => (
        <CustomCheckbox
          className="mb-[0] mt-[0] px-[0] py-[0]"
          inputClassName=""
          isChecked={row.getIsSelected()}
          isDisabled={!row.getCanSelect()}
          onChange={row.getToggleSelectedHandler()}
        />
      ),
    }),
    columnHelper.accessor("fund_name", {
      header: translate("Fund Name", "اسم الصندوق"),
    }),
    columnHelper.accessor("no_of_unit", {
      header: translate("Number Of Unit", "عدد الوحدات"),
    }),
    columnHelper.accessor("unit_price", {
      header: translate("Unit Price", "سعر الوحدة"),
    }),
    columnHelper.accessor("fund_value", {
      header: translate("Fund Value", "قيمة الصندوق"),
      meta: { isCurrency: true },
    }),
    columnHelper.accessor("allocation", {
      header: translate("Allocation", "التخصيص"),
      meta: { isPercentage: true, name: "allocation" },
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    state: {
      rowSelection,
    },
    onRowSelectionChange: setRowSelection,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true,
  });

  useEffect(() => {
    if (isContinueClicked)
      if (screenResolution.mobile) {
        setFundsToRemove(fundsDataToRemove);
      } else {
        setFundsToRemove(table.getSelectedRowModel().flatRows);
      }
  }, [isContinueClicked]);

  return (
    <div className="p-0 max767:p-0 overflow-x-auto">
      <div className="md:hidden">
        {data.map((fund) => (
          <div key={fund.id}>
            <div className="flex justify-between items-center cursor-pointer py-2 gap-2">
              <div className="flex items-center max767:justify-start max767:items-start">
                <CustomCheckbox
                  className="mb-[0] mt-[0] px-[0] py-[0]"
                  isChecked={rowSelection[fund.id] ?? false}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    e.stopPropagation();
                    handleCheckboxClick(e, fund.id);
                  }}
                  inputClassName="mr-2 rtl:ml-2 rtl:mr-0"
                  Label={
                    <span
                      onClick={(e) => toggleRow(e, fund.id)}
                      className="text-[#1A2024] text-sm not-italic font-normal leading-6"
                    >
                      {fund.fund_name}
                    </span>
                  }
                />
              </div>

              <div onClick={(e) => toggleRow(e, fund.id)} className="flex">
                {/* <FontAwesomeIcon
                icon={expandedRow == fund.id ? faChevronUp : faChevronDown}
                className="flex text-[#252C32] text-xs mt-[-5px] mr-4 ipad:mr-0 transform rotate-[-88deg]"
              /> */}
                <FontAwesomeIcon
                  icon={expandedRow == fund.id ? faChevronUp : faChevronDown}
                  className={`flex text-[#252C32] text-xs mt-[-5px] mr-4 ipad:mr-0 transform   ${
                    expandedRow == fund.id
                      ? "your-custom-class-for-up"
                      : "rotate-[-88deg]"
                  }`}
                />
              </div>
            </div>

            {/* Expanded details */}
            {expandedRow == fund.id && (
              <div className="bg-[#F6F8F9] mb-0 pt-0 w-full mt-4 p-4">
                <div className="mb-2 flex justify-between">
                  <div>
                    <strong className="text-[#6E7C87] text-sm not-italic font-medium leading-[normal]">
                      {translate("Number of Unit", "ar Number of Unit")}:{" "}
                    </strong>
                  </div>
                  <div className="text-black text-right text-base not-italic font-normal leading-[normal]">
                    {fund.no_of_unit}
                  </div>
                </div>
                <div className="mb-2 flex justify-between">
                  <strong className="text-[#6E7C87] text-sm not-italic font-medium leading-[normal]">
                    {translate("Unit Price", "ar Unit Price")}:{" "}
                  </strong>
                  <div className="text-black text-right text-base not-italic font-normal leading-[normal]">
                    {fund.unit_price}
                  </div>
                </div>
                <div className="mb-2 flex justify-between">
                  <strong className="text-[#6E7C87] text-sm not-italic font-medium leading-[normal]">
                    {translate("Fund Value", "ar Fund Value")}:{" "}
                  </strong>
                  <div className="text-black text-right text-base not-italic font-normal leading-[normal]">
                    {currencyFormatterWithCountryCode(fund.fund_value)}
                  </div>
                </div>
                <div className="mb-2 flex justify-between">
                  <strong className="text-[#6E7C87] text-sm not-italic font-medium leading-[normal]">
                    {translate("Allocation", "ar Allocation")}:{" "}
                  </strong>
                  <div className="text-black text-right text-base not-italic font-normal leading-[normal]">
                    {fund.allocation}%
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="hidden md:block">
        <table className="w-[100%] border-separate border border-solid border-[#DDE2E4] rounded-[10px] border-spacing-0 ipad:rounded-none fundswtble">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    className={
                      header.column.columnDef.meta?.sticky
                        ? "justify-center items-center px-4 py-2.5 text-left border-b border-solid text-[#6E7C87] text-xs not-italic font-semibold leading-6 tracking-[-0.072px] w-[50px]"
                        : "justify-center items-center px-4 py-2.5 border-b border-solid border-[#DDE2E4] border-spacing-0 text-left text-[#6E7C87] text-xs not-italic font-semibold leading-6 tracking-[-0.072px] "
                    }
                    key={header.id}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="border-b">
                {row.getVisibleCells().map((cell) => (
                  <td
                    className={
                      cell.column.columnDef.meta?.sticky
                        ? "justify-center items-center px-4 py-2.5 text-left border-b border-solid text-[#1A2024] text-xs not-italic font-semibold leading-6 tracking-[-0.072px]"
                        : "justify-center items-center px-4 py-2.5 border-b border-solid border-[#DDE2E4] border-spacing-0 text-left text-[#1A2024] text-xs not-italic font-semibold leading-6 tracking-[-0.072px]"
                    }
                    key={cell.id}
                  >
                    {cell.column.columnDef.meta?.isCurrency
                      ? currencyFormatterWithCountryCode(cell.getValue())
                      : flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                    {cell.getValue() &&
                      cell.column.columnDef.meta?.isPercentage &&
                      "%"}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FundSwitchingTable;
