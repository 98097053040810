import { useTranslation } from "react-i18next";
import Button from "../../../components/common/Button/Button";
import BackdropModal from "../../../components/common/modal/backdropModal";
import { useNavigate } from "react-router-dom";

const CprExistsPopup = ({ show, onClose, btn_label, onSuccess }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const redirectToSignIn = () => navigate("/sign-in");
  return (
    <div className="cpr-exists-modal">
      <BackdropModal
        show={show}
        onClose={onClose}
        title={t("signUpFlow.cpr_exists_popup.title")}
      >
        <div className="cpr-exists-popup">
          <p className="text-[#1A2024] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] mb-4">
          {t("signUpFlow.cpr_exists_popup.description_1")}
          </p>
          <p className="text-[#1A2024] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] mb-4">
          {t("signUpFlow.cpr_exists_popup.description_2")}
          </p>
          <Button
            onSubmit={redirectToSignIn}
            type="submit"
            label={
              btn_label ||
              t("signUpFlow.cpr_exists_popup.btn_label")
            }
            className="mb-0 stickybutton flex w-full flex-col items-center gap-2.5 shrink-0 p-4 bg-[#55C6EF] rounded-[10px] text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px] max767:w-full"
          />
        </div>
      </BackdropModal>
    </div>
  );
};

export default CprExistsPopup;
