import React, { useState } from 'react'
import Axios from '../../redux/axiosInstance'
import { lifeApiEndPoints } from '../../api'
import { toast } from 'react-toastify'
import { selectProfileData } from '../../redux/features/profileSlice';
import { useSelector } from 'react-redux';


export const LIFE_REQUEST_TYPE = {
    changePaymentCycleToMonthly: "Change Payment Cycle to Monthly",
    changePaymentCycleToQuarterly: "Change Payment Cycle to Quarterly",
    changePaymentCycleToSemiAnnual: "Change Payment Cycle to Semi-Annual",
    changePaymentCycleToAnnual: "Change Payment Cycle to Annual",
    withdrawal: "Withdrawal",
    decreasePremium: "Decrease Premium",
    increasePremium: "Increase Premium",
    surrenderPolicy : "Surrender",
    addBeneficiary : "Add Beneficiary",
    editBeneficiary : "Edit Beneficiary",
    removeBeneficiary : "Remove Beneficiary",
    editSharePercentage : "Edit Percentage Beneficiary",
    fundRedirectionAdd : "Fund Redirection - Add",
    fundsSwitch : "Fund Switch"
    
};

const useCreateLifeRequests = () => {
    const [isLoading, setIsLoading] = useState(false)

    const profileData = useSelector(selectProfileData)


    const createLifeRequest = async (payload = {}) => {
        let apiPayload = {
            CPR: profileData?.CPR,
            ...payload
        }
        setIsLoading(true);
        return Axios.post(lifeApiEndPoints.createRequests, apiPayload).then(res => {
            setIsLoading(false)
            toast.success(res?.data?.Message || "Request submitted successfully. Please wait for approval.")
            return res.data
        }).catch(e => {
            setIsLoading(false)
            toast.error(e?.response?.data?.Message || "Something went wrong. Please try again later. Error")
            return e?.response?.data
        })
    }
    return (
        {
            createLifeRequest,
            isLoading
        }
    )
}

export default useCreateLifeRequests