import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Input from "../../../../../components/common/Input/Input";
import SelectInput from "../../../../../components/common/selectInput/selectInput";
import TitleDescription from "../../../../../components/newMotorPolicy/titleDescription/titleDescription";
import useValidation from "../../../../../hooks/useValidation";
import BottomStickyBtnLayout from "../../Layouts/BottomStickyBtnLayout";
import useMotorForm from "../../../../../hooks/useMotorFormData";
import {
  bodyType,
  CAR_INFO_BRAND_NEW,
  CAR_INFO_NEW_CAR_DETAILS,
  CAR_INFO_PLATE_NUMBER,
  engineSizes,
  modelYears,
  noOfSeats,
} from "./data";
import NewMotorJourneyLayout from "../../Layouts/NewMotorJourneyLayout";
import {
  fetchCarsList,
  selectCars,
} from "../../../../../redux/features/constants/carsListSlice";
import { useSelector, useDispatch } from "react-redux";
import {
  clearGetSumInsuredError,
  fetchSumInsuredDetails,
  getSumInsuredDetails,
  getSumInsuredError,
  getSumInsuredLoader,
  getSumInsuredSuccess,
  resetSumInsuredDetails,
} from "../../../../../redux/features/motor/getSumInsuredSlice";
import { fetchBanksList } from "../../../../../redux/features/constants/banksListSlice";
import { selectNewMotorForm } from "../../../../../redux/features/newMotorFormSlice";
import { gdtRetrieveVehicleResponse } from "../../../../../redux/features/motor/gdtSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const NewCarDetailsForm = ({ onSuccess }) => {
  // Define dispatch to use redux reducers
  const dispatch = useDispatch();

  // Variables for the cars list fetched, from redux
  const carsList = useSelector((state) => state.carsList);
  const calculateSumInsuredError = useSelector(getSumInsuredError);

  // Variables to set the selector makes and model options from the cars list fetched
  const [carOptions, setCarOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);

  const [selectedCarMakeCode, setSelectedCarMakeCode] = useState("");

  const isMappedWithGDT =
    useSelector(selectNewMotorForm)?.[CAR_INFO_PLATE_NUMBER]?.isMappedWithGDT;

  // Setting the variable of carOptions to the fetched car list with make code as the value. carsList as dependency means function will be called if carsList is updated
  useEffect(() => {
    if (carsList.cars) {
      const options = carsList.cars.map((car) => ({
        value: car.Code,
        label: car.Make,
      }));
      setCarOptions(options);
    }
  }, [carsList]);

  const location = useLocation();
  useEffect(() => {
    console.log("setting form data");
    if (!isMappedWithGDT) {
      setFormData((prevFormData) => ({
        ...prevFormData, // Preserve all existing fields
        modelMake: "", // Reset modelMake
        carModel: "", // Reset carModel
      }));
    }
    console.log("for mdata ", formData);
  }, [location]);

  const [formData, setFormData] = useState({});
  const { errors, validateField } = useValidation();
  const { t } = useTranslation();
  const { saveMotorDataInRedux } = useMotorForm(
    CAR_INFO_NEW_CAR_DETAILS,
    setFormData
  );
  const handleChange = async (e) => {
    let { name, value } = e.target;
    if (name === "chasisNumber") {
      value = value?.toUpperCase().replace(/\s+/g, ""); // Convert to uppercase and remove spaces
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    await dispatch(resetSumInsuredDetails());

    switch (name) {
      case "chasisNumber":
        // validateField(value, name, "required");
        validateField(value, name, "length", { exact_length: 17 });
        break;
      case "modelMake":
        validateField(value, name, "select");
        console.log("vehicle make selection: ", value, name);
        setSelectedCarMakeCode(value);
        // Filter and set model options based on the selected make
        const selectedCar = carsList.cars.find((car) => car.Code == value);
        console.log("selected car: ", selectedCar);
        if (selectedCar || (formData.modelMake && selectedCar)) {
          const modelOptions = selectedCar.Models.map((model) => ({
            value: model.Code,
            label: model.Model,
          }));
          setModelOptions(modelOptions);
        } else {
          setModelOptions([]); // Clear model options if no matching make is found
        }
        break;
      case "carModel":
        validateField(value, name, "select");
        break;
      case "modelYear":
        validateField(value, name, "select");
        break;
        // case "carBodyType":
        //     validateField(value, name, "select");
        break;
      case "seats":
        validateField(value, name, "select");
        break;
      case "engineCapacity":
        validateField(value, name, "select");
        break;
      default:
        break;
    }
  };

  // Variables for the cars list fetched, from redux
  const banksList = useSelector((state) => state.banksList);
  const sumInsuredDetails = useSelector(getSumInsuredDetails);
  const sumInsuredLoading = useSelector(getSumInsuredLoader);
  const sumInsuredSuccess = useSelector(getSumInsuredSuccess);
  const newCarInfoData = useSelector(selectNewMotorForm)?.[CAR_INFO_BRAND_NEW];
  const carGdtInfo = useSelector(gdtRetrieveVehicleResponse);

  useEffect(() => {
    if (newCarInfoData.is_brand_new != "Yes") {
      console.log("Car info GDT: ", carGdtInfo);
      if (carGdtInfo) {
        console.log("Setting form data: ", formData);
        setFormData((prevFormData) => ({
          ...prevFormData,
          chasisNumber: carGdtInfo.ChassisNumber,
          modelYear: carGdtInfo.YearOfManufacture,
          modelMake:
            carGdtInfo.TCSVehicleModelCode != ""
              ? carGdtInfo.TCSVehicleMakeCode
              : "",
          carModel:
            carGdtInfo.TCSVehicleModelCode != ""
              ? carGdtInfo.TCSVehicleModelCode
              : "",
          tcsMakeCode:
            carGdtInfo.TCSVehicleModelCode != ""
              ? carGdtInfo.TCSVehicleMakeCode
              : "",
          tcsModelCode:
            carGdtInfo.TCSVehicleModelCode != ""
              ? carGdtInfo.TCSVehicleModelCode
              : "",
        }));
        console.log("Set form data: ", formData);
      }
    }
  }, [newCarInfoData.is_brand_new]);

  useEffect(() => {
    const initializeData = async () => {
      // Dispatch actions to clear state and fetch necessary data
      try {
        // Clear sum insured redux
        await dispatch(resetSumInsuredDetails());
      } catch (error) {
        console.error(
          "Error clearing sum insured details or initializing data",
          error
        );
      }
    };

    // Call the async function
    initializeData();

    // Optional cleanup function (if needed)
    return () => {
      // Cleanup logic if necessary
    };
  }, [formData]);

  const NextHandler = async () => {
    // Calling API /constants/banks
    if (!banksList.banks) {
      await dispatch(fetchBanksList());
    }

    console.log("Next from details page. Data: ", formData);
    saveMotorDataInRedux(formData);
    let isFormValid = true;

    const isChasisNumberValid = validateField(
      formData.chasisNumber,
      "chasisNumber",
      "length",
      { exact_length: 17 }
    );
    const isModelMakeValid = validateField(
      formData.modelMake,
      "modelMake",
      "select"
    );
    const isCarModelValid = validateField(
      formData.carModel,
      "carModel",
      "select"
    );
    const isModelYearValid = validateField(
      formData.modelYear,
      "modelYear",
      "select"
    );
    // const isCarBodyTypeValid = validateField(
    //     formData.carBodyType,
    //     "carBodyType",
    //     "select"
    // );
    const isSeatsValid = validateField(formData.seats, "seats", "select");
    const isEngineCapacityValid = validateField(
      formData.engineCapacity,
      "engineCapacity",
      "select"
    );

    isFormValid =
      isChasisNumberValid &&
      isModelMakeValid &&
      isCarModelValid &&
      isModelYearValid &&
      // isCarBodyTypeValid &&
      isSeatsValid &&
      isEngineCapacityValid;

    // console.log("Is form valid? ", isFormValid);
    // console.log('Chassis', isChasisNumberValid);
    // console.log('Make', isModelMakeValid);
    // console.log('Model', isCarModelValid);

    if (isFormValid) {
      // console.log(newCarInfoData.is_brand_new);
      if (newCarInfoData.is_brand_new != "Yes") {
        console.log("sum insured details is null?", sumInsuredDetails == "");

        if (!sumInsuredDetails || sumInsuredDetails.length == 0) {
          let sumInsuredPayload = {
            CarMakeCode: formData.modelMake,
            CarModelCode: formData.carModel,
            CarModelYear: formData.modelYear,
            CarEngineSize: formData.engineCapacity,
          };
          try {
            // Calling API /Meej/calculateSumInsured
            const sumInsuredAction = await dispatch(
              fetchSumInsuredDetails(sumInsuredPayload)
            );
            const sumInsuredResult = unwrapResult(sumInsuredAction);

            if (sumInsuredResult) {
              onSuccess();
            }
          } catch (error) {
            // alert("Error in calculating sum insured: ", error)
            // toast.error("Error in calculating sum insured: ", calculateSumInsuredError);
            console.log(
              "Error in calculating sum insured: ",
              calculateSumInsuredError
            );
          }
        } else {
          onSuccess();
        }
      } else {
        onSuccess();
      }
    }
  };

  return (
    <NewMotorJourneyLayout>
      <BottomStickyBtnLayout btn_onClick={NextHandler}>
        <div className="max-w-lg mx-auto bg-white rounded-md max767:p-5 max767:mt-[0px] max767:pt-0">
          <TitleDescription
            title={t(
              "motorInsurance.newMotorInsuranceFlow.carInfo.newCarForm.title"
            )}
            description={t(
              "motorInsurance.newMotorInsuranceFlow.carInfo.newCarForm.description"
            )}
          />

          {newCarInfoData.is_brand_new == "Yes" && (
            <Input
              label={t(
                "motorInsurance.newMotorInsuranceFlow.carInfo.newCarForm.chasisNumberLabel"
              )}
              type="text"
              handleChange={handleChange}
              name="chasisNumber"
              value={formData.chasisNumber}
              error={errors?.chasisNumber}
              data-testid="chasisNumber"
            />
          )}
          <div className="">
            <div className="flex yournewcardetails flex-wrap gap-5 max767:gap-2">
              {!isMappedWithGDT && (
                <>
                  <SelectInput
                    name="modelMake"
                    default_placeholder=""
                    formData={formData}
                    options_data={carOptions}
                    label={t(
                      "motorInsurance.newMotorInsuranceFlow.carInfo.newCarForm.modelMakeLabel"
                    )}
                    error={errors?.modelMake}
                    handleChange={handleChange}
                  />
                  <SelectInput
                    name="carModel"
                    default_placeholder=""
                    formData={formData}
                    options_data={modelOptions}
                    label={t(
                      "motorInsurance.newMotorInsuranceFlow.carInfo.newCarForm.carModelLabel"
                    )}
                    error={errors?.carModel}
                    handleChange={handleChange}
                  />
                </>
              )}
              {newCarInfoData.is_brand_new == "Yes" && (
                <SelectInput
                  name="modelYear"
                  default_placeholder=""
                  formData={formData}
                  options_data={modelYears}
                  label={t(
                    "motorInsurance.newMotorInsuranceFlow.carInfo.newCarForm.modelYearLabel"
                  )}
                  error={errors?.modelYear}
                  handleChange={handleChange}
                />
              )}
              {/* <SelectInput
                                name="carBodyType"
                                default_placeholder=""
                                formData={formData}
                                options_data={bodyType}
                                label={t(
                                    "motorInsurance.newMotorInsuranceFlow.carInfo.newCarForm.carBodyTypeLabel"
                                )}
                                error={errors?.carBodyType}
                                handleChange={handleChange}
                            /> */}
              <SelectInput
                name="seats"
                formData={formData}
                default_placeholder=""
                options_data={[
                  { value: "4", label: "4 Seater" },
                  { value: "5", label: "5 Seater" },
                  { value: "7", label: "7 Seater" },
                ]}
                label={t(
                  "motorInsurance.newMotorInsuranceFlow.carInfo.newCarForm.noOfSeatsLabel"
                )}
                error={errors?.seats}
                handleChange={handleChange}
              />
              <SelectInput
                className="forceltr_select rtl:text-right"
                name="engineCapacity"
                default_placeholder=""
                formData={formData}
                options_data={engineSizes}
                label={t(
                  "motorInsurance.newMotorInsuranceFlow.carInfo.newCarForm.engineCapacityLabel"
                )}
                error={errors?.engineCapacity}
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
      </BottomStickyBtnLayout>
    </NewMotorJourneyLayout>
  );
};

export default NewCarDetailsForm;
