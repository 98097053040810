import faPlusCircleimg from "../../../src/assets/images/newpolicyicon.svg";
import faSyncAltimg from "../../../src/assets/images/Renewal.svg";
import FileaClaimdark from "../../assets/images/FileaClaimdark.svg"
import faSyncAltdark from "../../assets/images/trackclaim.svg"
export const FILE_A_CLAIM = "FILE_A_CLAIM";
export const TRACK_A_CLAIM = "TRACK_A_CLAIM";

export const quickActions = [
    {
        icon: faPlusCircleimg,
        micon: FileaClaimdark,
        text: "File a New Claim",
        text_ar: "تقديم مطالبة جديدة",
        type: FILE_A_CLAIM
    },
    {
        icon: faSyncAltimg,
        micon: faSyncAltdark,
        text: "Track a Claim",
        text_ar: "تتبع المطالبة",
        type: TRACK_A_CLAIM
    },
];

export const CLAIM_STATUS = {
    registered: 'Registered',
    processed: 'Processed',
    settled: 'Settled',
    closed: 'Closed',
    inProgress: "IN PROGRESS",
    rejected: "REJECTED",
    completed: "COMPLETED",
}


export const activeClaimsData = [
    {
        id: 1,
        status: CLAIM_STATUS.settled,
        carMakeModel: "Ford - Mustang",
        plateNumber: "427520",
        policyNumber: "C/003//21/8002/00101423",
        claimDate: "16 October 2024",
    },
    {
        id: 1,
        status: CLAIM_STATUS.processed,
        carMakeModel: "Toyota - Corolla",
        plateNumber: "217470",
        policyNumber: "C/003//21/8002/00093423",
        claimDate: "16 October 2024",
    },
]

export const fileClaimFaqsData = [
    {
        question_en: "How can I file a motor accident claim with Solidarity Bahrain?",
        question_ar: "كيف يمكنني تقديم مطالبة بحادث سيارة إلى التضامن؟",
        answer_en: "Filing a motor accident claim with Solidarity Bahrain involves several steps to ensure that your claim is processed efficiently. Follow these instructions to file your claim",
        answer_ar: "يف يمكنني تقديم مطالبة بحادث سيارة إلى التضامن يف يمكنني تقديم مطالبة بحادث سيارة إلى التضامن يف يمكنني تقديم مطالبة بحادث سيارة إلى التضامن يف يمكنني تقديم مطالبة بحادث سيارة إلى التضامن",
    },
    {
        question_en: "What documents do I need to submit when filing a claim?",
        question_ar: "ما هي الخطوة الأولى التي يجب أن أتخذها قبل تقديم المطالبة؟",
        answer_en: <><p>You need to submit or attach the following documents:</p>
            <ul>
                <li>Traffic Report</li>
                <li>Valid Driving License</li>
                <li>Vehicle Ownership Certificate</li>
                <li>Insurance Certificate</li>
                <li>Clear photos of the vehicles/properties involved in the accident (vehicle photos must include registration number)</li>
            </ul></>,
        answer_ar: <><p>جب عليك تقديم أو إرفاق المستندات التالية:</p>
            <ul>
                <li>تقرير المرور</li>
                <li>رخصة قيادة سارية المفعول</li>
                <li>شهادة ملكية السيارة</li>
                <li>شهادة التأمين</li>
                <li>صور واضحة للمركبات / الممتلكات المشاركة في الحادث (يجب أن تتضمن صور السيارة رقم )</li>
            </ul></>,
    },
]

export const allClaims = [
    {
        id: 1,
        status: CLAIM_STATUS.inProgress,
        claimID: "CRM-CLAIM-01009",
        claimDate: "Mon, 01 Jul 2024",
        time: "8:37pm"

    },
    {
        id: 1,
        status: CLAIM_STATUS.inProgress,
        claimID: "CRM-CLAIM-01018",
        claimDate: "Mon, 01 Jul 2024",
        time: "8:37pm"

    },
    {
        id: 1,
        status: CLAIM_STATUS.inProgress,
        claimID: "CRM-CLAIM-01019",
        claimDate: "Mon, 01 Jul 2024",
        time: "8:37pm"

    },
    {
        id: 2,
        status: CLAIM_STATUS.rejected,
        claimID: "CRM-CLAIM-01005",
        claimDate: "Mon, 01 Jul 2024",
        time: "8:37pm"

    },
    {
        id: 3,
        status: CLAIM_STATUS.completed,
        claimID: "CRM-CLAIM-01028",
        claimDate: "Mon, 01 Jul 2024",
        time: "8:37pm"
    },
]

