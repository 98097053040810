import React from 'react';
import { QRCodeSVG } from 'qrcode.react'; // Import QRCodeSVG for SVG rendering

const QRCodeGenerator = ({ value }) => {
  return (
    <div className="">
      <QRCodeSVG
        value={value} // The data the QR code will represent
        className="h-full w-full"   // Adjust the size as needed
        bgColor={"#F6F8F9"} // Background color
        fgColor={"#000000"} // Foreground (QR code) color
        includeMargin={true} // Optionally include margin
      />
    </div>
  );
};

export default QRCodeGenerator;
