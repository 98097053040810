import React from "react";
import useMotorFlow from "../../../../hooks/useMotorFlow";
import UploadPhotosOfCar from "./uploadPhotosOfCar";
import UploadDocsPage from "./uploadDocsPage";
import { screenResolution } from "../../../../utils/screenResolution";
import MobileUploadCarPhotoStepperLayout from "./mobileUploadPages/MobileUploadCarPhotoStepperLayout";

const UploadsMainPage = () => {
  const { currentSubstep } = useMotorFlow();

  return (
    <>
      {screenResolution.mobile ? (
        <>
          {currentSubstep == "upload_photos_of_car" && (
            <MobileUploadCarPhotoStepperLayout />
          )}
          {currentSubstep == "upload_req_docs" && <UploadDocsPage />}
        </>
      ) : (
        <>
          {currentSubstep == "upload_photos_of_car" && <UploadPhotosOfCar />}
          {currentSubstep == "upload_req_docs" && <UploadDocsPage />}
        </>
      )}
    </>
  );
};

export default UploadsMainPage;
