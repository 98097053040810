import { createSlice } from "@reduxjs/toolkit";
import { POLICY_CANCELLATION_FLOW } from "../../pages/motorInsurancePages/policyOverview/managePolicy/policyCancellationFlow/data";

const initialState = {
  flowType: POLICY_CANCELLATION_FLOW,
  currentStepIndex: 0,
  currentSubstepIndex: 0,
};

const motorInsurancePolicyCancellationFlowSlice = createSlice({
  name: "motorInsurancePolicyCancellationFlow",
  initialState,
  reducers: {
    setPolicyCancellationFlowType: (state, action) => {
      const data = action.payload;
      state.flowType = data.type;
      state.currentStepIndex = data.currentStepIndex || 0;
      state.currentSubstepIndex = data.currentSubstepIndex || 0;
    },
    setCurrentStepIndex: (state, action) => {
      state.currentStepIndex = action.payload;
      state.currentSubstepIndex = 0;
    },
    setCurrentSubstepIndex: (state, action) => {
      state.currentSubstepIndex = action.payload;
    },
    resetPolicyCancellationFlow: (state) => {
      state.currentStepIndex = 0;
      state.currentSubstepIndex = 0;
      state.flowType = POLICY_CANCELLATION_FLOW;
    },
  },
});

export const selectMotorInsurancePolicyCancellationFlow = (store) =>
  store.motorInsurancePolicyCancellationFlow;
export const selectPolicyCancellationFlowType = (store) =>
  store?.motorInsurancePolicyCancellationFlow?.flowType;
export const selectCurrentStepIndex = (store) =>
  store?.motorInsurancePolicyCancellationFlow?.currentStepIndex;
export const selectCurrentSubstepIndex = (store) =>
  store.motorInsurancePolicyCancellationFlow?.currentSubstepIndex;

export const {
  setPolicyCancellationFlowType,
  setCurrentStepIndex,
  setCurrentSubstepIndex,
  resetPolicyCancellationFlow
} = motorInsurancePolicyCancellationFlowSlice.actions;
export default motorInsurancePolicyCancellationFlowSlice.reducer;
