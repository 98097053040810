import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { setLanguageInLocalStorage } from "../../../hooks/useLanguagePercistant";
import { STRAPI_BASE_URL } from "../../../api/baseURL";
import {
  clearLoggedInUserDataInLocalStorage,
  isLoggedIn,
} from "../../../config/config";
import LinkSelector from "../linkSelector/linkSelector";
import { useDispatch, useSelector } from "react-redux";
import {
  clearLoginTokenInfo,
  selectLoginTokenInfo,
} from "../../../redux/features/loginRegisterSlice";
import { resetRedux } from "../../../redux/features/resetSlice";
import posthog from "posthog-js";

const Dropdown = ({ items, closeDropdown, onItemClick }) => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const loginInfo = useSelector(selectLoginTokenInfo);

  const logoutHandler = () => {
    dispatch(clearLoginTokenInfo());
    clearLoggedInUserDataInLocalStorage();
    dispatch(resetRedux());
    posthog.reset(true);
    posthog.reloadFeatureFlags();
    navigate("/");
  };

  const translate = useLanguageTranslate();
  const handleLanguageChange = () => {
    let switch_lang = i18n.resolvedLanguage === "ar" ? "en" : "ar";
    i18n.changeLanguage(switch_lang);
    setLanguageInLocalStorage(switch_lang);
  };

  const onDropdownItemClick = (item, event) => {
    if (item.type == "logout") {
      logoutHandler();
      return;
    } else if (item.handleLangChng) {
      handleLanguageChange();
      return

    }

    if (item.link) {
      if (item?.is_internal) {
        navigate(item.link);
        return
      } else {
        event.preventDefault();

        window.open(item.link, "_blank");
      }
    }

    closeDropdown();
  };

  return (
    <div className="absolute right-0 mt-2 settings-box bg-white shadow-[0px_0px_1px_0px_rgba(26,32,36,0.32),0px_12px_24px_0px_rgba(91,104,113,0.24)] min-w-[272px] p-4 rounded-[10px] rtl:left-[0px] rtl:right-[unset]">
      <ul className="py-1">
        {items.map((item, index) => {
          const imgSrc =
            typeof item.image == "object"
              ? translate(
                  item?.image?.img?.data?.attributes?.url,
                  item?.image?.arabic_img?.data?.attributes?.url ||
                    item?.image?.img?.data?.attributes?.url
                )
              : "";
          const finalImgUrl = `${STRAPI_BASE_URL}${imgSrc}`;

          const newItem = {
            ...item,
            icon: {
              data: typeof item.image == "object" ? finalImgUrl : item.icon,
              img_alt_en:
                typeof item.image == "object" ? item?.image?.img_alt_en : "",
              img_alt_ar:
                typeof item.image == "object" ? item?.image?.img_alt_ar : "",
            },
            label_en: item.text_en,
            label_ar: item.text_ar,
          };

          return (
            <li
              key={index}
              className={`px-4 py-3 hover:bg-gray-100 flex items-center text-[#48535B] text-sm not-italic font-normal leading-6 cursor-pointer relative p-2 ${
                item.showIfIsLoggedIn == true &&
                (loginInfo == null || loginInfo == "")
                  ? "hidden"
                  : ""
              } ${item.hide ? "hidden": ""}`}
              onClick={(event) =>
                onItemClick ? onItemClick(item, event) : onDropdownItemClick(item, event)
              }
            >
              <LinkSelector
                item={newItem}
                handleChange={closeDropdown}
                ClassName={"flex items-center"}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Dropdown;
