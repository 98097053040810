import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getClaimDetails,
  selectClaimDetails,
  selectCrmClaimsLoading,
  selectCrmClaimsError,
  clearClaimDetails,
} from "../../../redux/features/crm/crmClaimSlice";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import useValidation from "../../../hooks/useValidation";
import RenewMotorLayout from "../renewalMotorPolicy/renewMotorLayout";
import Input from "../../../components/common/Input/Input";
import { Loader } from "storybook/internal/components";
import CryptoJS from "crypto-js";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";

const BasicInfoPage = () => {
  const [formData, setFormData] = useState({ claimNumber: "" });
  const { errors, validateFields } = useValidation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoading = useSelector(selectCrmClaimsLoading);
  const error = useSelector(selectCrmClaimsError);

  const translate = useLanguageTranslate()
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const validateForm = () => {
    const validationConfig = [
      {
        name: "claimNumber",
        validations: ["required"],
      },
    ];
    return validateFields(validationConfig, formData);
  };

  const continueClickHandler = async () => {
    if (!validateForm()) return;

    try {
      const response = await dispatch(
        getClaimDetails({ caseNumber: formData.claimNumber })
      ).unwrap();

      let claimStatus = "";
      switch (response.Claim.ClaimStatus) {
        case "Claim Intimation":
          claimStatus = "Intimation";
          break;
        case "Intimation Acknowledgement":
          claimStatus = "Intimation";
          break;
        case "Incomplete Intimation":
          claimStatus = "Intimation";
          break;
        case "Rejected Claim":
          claimStatus = "Intimation";
          break;
        case "Claim Registration":
          claimStatus = "Registration";
          break;
        case "Survey Completion":
          claimStatus = "Survey";
          break;
        case "Under Procurement":
          claimStatus = "Settlement";
          break;
        case "Under Repair":
          claimStatus = "Settlement";
          break;
        case "Total Loss":
          claimStatus = "Settlement";
          break;
        case "Cash Loss":
          claimStatus = "Settlement";
          break;
        case "Pending For Loss Adjustor":
          claimStatus = "Settlement";
          break;
        case "Pending for Estimate":
          claimStatus = "Survey";
          break;
        case "Pending for Deductible":
          claimStatus = "Registration";
          break;
        case "Pending for Depreciation":
          claimStatus = "Survey";
          break;
        case "Vehicle Collection":
          claimStatus = "Settlement";
          break;
        case "Close Claim":
          claimStatus = "Closed";
          break;
        default:
          claimStatus = "Unknown status.";
      }

      const valueToEncrypt = response.Claim.CaseNumber;

      // Encryption logic
      const secretKey = "i-hate-meej";
      const encryptedValue = CryptoJS.AES.encrypt(
        valueToEncrypt,
        secretKey
      ).toString();

      // Convert to URL-safe format
      const urlSafeEncryptedValue = encryptedValue
        .split("+")
        .join("-") // Replace '+' with '-'
        .split("/")
        .join("_") // Replace '/' with '_'
        .split("=")
        .join(""); // Remove '=' padding
        
      // Navigate with the encrypted number
      navigate(`/motor-insurance/claim-details/${urlSafeEncryptedValue}`);
    } catch (err) {
      console.error("Failed to fetch claim details:", err);
    }
  };

  return (
    <RenewMotorLayout is_hide_back_btn btn_onClick={continueClickHandler}>
      <div className="max-w-lg mx-auto bg-white max767:p-5">
        <TitleDescription
          title={translate("Track Claim","تتبع المطالبة")}
          description={translate("To locate your claim, please provide the following information.","لتحديد مطالبتك، يرجى تقديم المعلومات التالية.")}
        />
        <Input
          name="claimNumber"
          type="text"
          label={translate("Claim Number","رقم المطالبة")}
          value={formData?.claimNumber}
          error={errors?.claimNumber}
          handleChange={handleChange}
        />
        {isLoading && <Loader />}
        {error && <p className="text-red-500">{error}</p>}
      </div>
    </RenewMotorLayout>
  );
};

export default BasicInfoPage;
