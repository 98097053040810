import React, { useState } from "react";
import backarrow from "../../../assets/images/back-arrow-left.svg";
import closeicon from "../../../assets/images/close.svg";
import { useNavigate } from "react-router-dom";
import useExpressShieldFlow from "../../../hooks/BuyOnlineExpressShield/useExpressShieldFlow";
import useScreenResolution from "../../../hooks/useScreenResolution";
import LifeInsuranceBottomStickyBtnLayout from "../../motorInsurancePages/newMotorPolicy/Layouts/LifeInsuranceBottomStickyLayout";
import LifeViewSummaryPopup from "../../../components/newMotorPolicy/veiwSummaryPopup/lifeViewSummaryPopup";
import { useSelector } from "react-redux";
import { selectExpressShieldData } from "../../../redux/features/expressSheild/expressSheildSlice";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";

const ExpressShieldPlanLayout = ({
  children,
  is_hide_back_btn,
  show_close_btn,
  btn_label,
  btn_onClick,
  btn_disabled,
  show_summary,
  is_bottom_sticky_layout = true,
  isLoading

}) => {
  const navigate = useNavigate();
  const { handlePrevSubstep } = useExpressShieldFlow();
  const screenResolution = useScreenResolution();
  const translate = useLanguageTranslate();
  const [show, setShow] = useState(false)
  const showSummaryHandler = () => {
    setShow(true)
  }

  const expressSheildData = useSelector(selectExpressShieldData);
  let totalAmount = expressSheildData?.TotalPremium ||0

  return (
    <div className=" ">
      <div className="summarypopup">
        <LifeViewSummaryPopup
          TotalPremium={expressSheildData?.TotalPremium || 0}
          Tax={expressSheildData?.Tax}
          Premium={expressSheildData?.Premium || 0}
          QuoteNumber={expressSheildData?.QuoteDetails?.QuoteNumber || ""}
          show={show}
          setShow={setShow}
        />
      </div>
      {!is_hide_back_btn && (
        <button
          className="backButton bg-navy-blue py-2 rounded mt-20 flex absolute left-[26px] rtl:right-[26px] top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:bg-[#F6F8F9] max767:w-10 max767:h-10 max767:text-center max767:justify-center max767:rounded-3xl max767:mt-8 max767:ml-[-11px] max767:relative max767:left-[13px]"
          onClick={handlePrevSubstep}
        >
          <img
            src={backarrow}
            alt="Back arrow"
            className="1 rtl:ml-[5px] rtl:rotate-[180deg]"
          />
          <span className="back-text inline max767:hidden">
            {translate("Back", "رجوع")}
          </span>
        </button>
      )}
      {show_close_btn && screenResolution.mobile && (
        <button
          className="bpy-2 rounded mt-4 flex absolute top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:ml-0 max767:mt-8"
          onClick={() => navigate("/new-policy")}
        >
          <img src={closeicon} alt="Close Icon" />
        </button>
      )}
      {is_bottom_sticky_layout ? (
        <LifeInsuranceBottomStickyBtnLayout
          show_summary={show_summary}
          btn_onClick={btn_onClick}
          btn_disabled={btn_disabled}
          btn_label={btn_label}
          isLoading={isLoading}
          showSummaryHandler={showSummaryHandler}
          totalAmount={totalAmount}
        >
          {children}
        </LifeInsuranceBottomStickyBtnLayout>
      ) : (
        children
      )}{" "}
    </div>
  );
};

export default ExpressShieldPlanLayout;
