import { useEffect } from "react";

const useZoomVideoClient = (elementId, entryId, mobile, onClose) => {
  useEffect(() => {
    const setVideoClient = () => {
      const dom = document.getElementById(elementId);

      if (!dom) {
        console.warn(`Element with id "${elementId}" not found.`);
        return;
      }

      const handleClick = async () => {
        try {
          const videoClient = await new window.VideoClient();
          console.log(videoClient,mobile, "Video client initialized");

          await videoClient.init({
            entryId: entryId,
            name: mobile,
          });

          videoClient.startVideo();
            onClose();
          videoClient.on("video-end", () => {
            console.log("Video ended");
          });
        } catch (error) {
          console.error("Error in initializing video client: ", error);
        }
      };

       dom.addEventListener("click", handleClick);

      // Cleanup event listener on unmount
      return () => {
        dom.removeEventListener("click", handleClick);
      };
    };

    const cleanup = setVideoClient();

    return () => {
      if (cleanup) cleanup();
    };
  }, [mobile]);
};

export default useZoomVideoClient;
