export const FUNDS_SWITCHING_FLOW = "FUNDS_SWITCHING_FLOW";
export const FUNDS_SWITCHING_FLOW_SWITCH_FUNDS = "FUNDS_SWITCHING_FLOW_SWITCH_FUNDS";
export const FUNDS_SWITCHING_FLOW_ADD_FUNDS = "FUNDS_SWITCHING_FLOW_ADD_FUNDS";
export const FUNDS_SWITCHING_FLOW_FUNDS_ALLOCATION =
  "FUNDS_SWITCHING_FLOW_FUNDS_ALLOCATION";

export const funds_switching_flow = {
  [FUNDS_SWITCHING_FLOW]: [
    {
      name: FUNDS_SWITCHING_FLOW_SWITCH_FUNDS,
      substeps: [FUNDS_SWITCHING_FLOW_SWITCH_FUNDS],
    },
    {
      name: FUNDS_SWITCHING_FLOW_ADD_FUNDS,
      substeps: [FUNDS_SWITCHING_FLOW_ADD_FUNDS],
    },
    {
      name: FUNDS_SWITCHING_FLOW_FUNDS_ALLOCATION,
      substeps: [FUNDS_SWITCHING_FLOW_FUNDS_ALLOCATION],
    },
  ],
};
