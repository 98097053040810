import React from "react";
import pdficon from "../../../assets/images/Fileicon.svg";
import downloadicon from "../../../assets/images/file_download.svg";
import Button from "../Button/Button";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";

const DownloadBtn = ({ filesData = [], className }) => {
  const translate = useLanguageTranslate()

  return (
    filesData?.length > 0 &&
    filesData?.map((btnData) => (
      <div key={btnData.ID} className={`${className} downloadbtn`}>
        <Button className="bg-transparent hover:bg-white flex items-center justify-between self-stretch border bg-white p-4 rounded-lg border-solid border-[#E5E9EB]"
          key={btnData.ID}
          pre_icon={pdficon}
          post_icon={downloadicon}
          onSubmit={() => window.open(btnData?.filesData?.url||btnData?.File, "_blank")}
          label={btnData?.fileName ? translate(btnData?.fileName, btnData?.fileName_ar) : btnData?.Name}
        />
      </div>
    ))
  );
};

export default DownloadBtn;





//keeping for reference as it is used in other files as well,
// Once everything is resolved will remove this code
// src/DynamicDownloadButton.js
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import React from "react";
// // import {  faDownload, faFileDownload, faFilePdf } from "@fortawesome/free-solid-svg-icons";
// import pdficon from "../../../assets/images/Fileicon.svg";
// import downloadicon from "../../../assets/images/file_download.svg";
// import useLanguageTranslate from "../../../hooks/useLanguageTranslate";

// const DownloadBtn = ({ filesData = [], className }) => {
//   const handleDownload = (fileName, fileUrl) => {
//     if (filesData) {
//    // Create an anchor element
//    const link = document.createElement("a");

//    // Set the href to the file URL
//    link.href = fileUrl;

//    // Set the target to _blank to open in a new tab
//    link.target = "_blank";

//    // Append the link to the body (required for Firefox)
//    document.body.appendChild(link);

//    // Programmatically click the link to open the file in a new tab
//    link.click();

//    // Remove the link from the document
//    document.body.removeChild(link);
//     }
//   };
// const translate=useLanguageTranslate()
//   return (
//     filesData?.length > 0 &&
//     filesData?.map((btnData) => (
//       <div className={`${className} downloadbtn`}>
//         <button
//           onClick={() => handleDownload(btnData?.fileName, btnData?.filesData?.url)}
//           className="border bg-white w-full p-4 rounded-lg inline-flex justify-between"
//         >
//           {/* <FontAwesomeIcon icon={faFilePdf} /> */}
//           <div className="flex gap-4 text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] ipad:items-center ipad:gap-2 max767:items-center">
//             <img src={pdficon} alt="Beneficiary" />
//             <div className="flex flex-col items-start">
//               <span className="text-[#252C32] text-sm not-italic md:text-left font-semibold leading-6 tracking-[-0.084px]">
//                 {translate(btnData?.fileName,btnData?.fileName_ar)}
//               </span>
//               <span className="text-[#84919A] text-xs not-italic font-normal leading-4 max-390:clear-both">
//                 {btnData?.fileData?.size}
//               </span>
//             </div>
//           </div>
//           <img src={downloadicon} alt="Beneficiary" className="" />
//         </button>
//       </div>
//     ))
//   );
// };

// export default DownloadBtn;

