import React, { useState } from 'react'
import RenewMotorLayout from '../../../../motorInsurancePages/renewalMotorPolicy/renewMotorLayout';
import TitleDescription from '../../../../../components/newMotorPolicy/titleDescription/titleDescription';
import { currencyFormatterWithCountryCode, decodeQueryString, formatToThreeDigits, generateQueryString } from '../../../../../utils';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useLanguageTranslate from '../../../../../hooks/useLanguageTranslate';
import useCreateLifeRequests, { LIFE_REQUEST_TYPE } from '../../../../../hooks/life/useCreateLifeRequests';
import { Loader } from 'storybook/internal/components';
import CustomCheckbox from '../../../../../components/common/customCheckbox/customCheckbox';

const ReviewAndConfirm = ({ switchToggle, formData }) => {
    const [isChecked, setIsChecked] = useState(false);
    const { isLoading, createLifeRequest } = useCreateLifeRequests()


    const { t } = useTranslation()
    const translate = useLanguageTranslate()
    const continueClickHanlder = () => {
        switchToggle();
    };

   const {search} = useLocation()
   const params = decodeQueryString()

    const navigate = useNavigate()
    const confirmClickHandler = async () => {
        let payload = {
            PolicyNumber: params?.policy,
            RequestType: LIFE_REQUEST_TYPE.withdrawal,
            IBAN: formData?.iban_number,
            Amount: formData?.amount
        }
        let response = await createLifeRequest(payload)
        navigate(`/life-insurance/policy-overview?${search}`)
    }
    console.log(formData, 'form data')
    return (
      <RenewMotorLayout
        btn_disabled={isLoading}
        backClickHandler={switchToggle}
        is_bottom_sticky_layout={false}
        btn_onClick={continueClickHanlder}
      >
        {isLoading && (
          <Loader className="w-10 h-10 fixed left-[50%] top-[50%]" />
        )}

        <div className="changeplatenumber w-[500px] max-w-[100%] mx-auto bg-white max767:p-5">
          <TitleDescription
            title={t("lifeInsurance.withdrawal.reviewAndConfirm.title")}
            description={t(
              "lifeInsurance.withdrawal.reviewAndConfirm.description"
            )}
          />
          <div className="bg-[#F6F8F9] rounded-[26px] p-4 mb-4 w-full summary-box">
            <div className="rounded-lg summary-conntent">
              <div className="flex justify-between mb-2 px-0 py-2.5">
                <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
                  {t(
                    "lifeInsurance.withdrawal.reviewAndConfirm.withdrawalAmountLabel"
                  )}
                </span>
                <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
                  {translate("BHD", "دينار بحريني")}{" "}
                  {currencyFormatterWithCountryCode(formData?.amount || 0)}
                </span>
              </div>
              {/* <div className="flex justify-between mb-2 px-0 py-2.5">
                            <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
                            {t("lifeInsurance.withdrawal.reviewAndConfirm.totalFeeLabel")}
                            </span>
                            <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
                            {translate("BHD","دينار بحريني")} 5.000
                            </span>
                        </div> */}
              <div className="flex justify-between mb-2 px-0 py-2.5">
                <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
                  {t("lifeInsurance.withdrawal.reviewAndConfirm.sendToLabel")}
                </span>
                <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
                  {formData?.iban_number || ""}
                </span>
              </div>

              {/* <div className="flex justify-between px-0 py-2.5 border-t-[#DDE2E4] border-t border-solid">
                            <span className="text-[#1A2024] text-sm not-italic font-extrabold leading-6">
                            {t("lifeInsurance.withdrawal.reviewAndConfirm.receivedLabel")}
                            </span>
                            <span className="text-[#1A2024] text-sm not-italic font-extrabold leading-6">
                            {translate("BHD","دينار بحريني")} {formatToThreeDigits(995)}
                            </span>
                        </div> */}
            </div>
          </div>

          <div className="flex">
            <CustomCheckbox
              Label={
                <div className="flex">
                  <label
                    htmlFor="agreement"
                    className="text-[#252C32] text-sm not-italic font-normal leading-6"
                  >
                    {t(
                      "lifeInsurance.withdrawal.reviewAndConfirm.iAcceptTheLabel"
                    )}{" "}
                    <a
                      href=" https://www.solidarity.com.bh/termsofuse"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#00384D] text-sm not-italic font-semibold leading-6 underline"
                    >
                      {t(
                        "lifeInsurance.withdrawal.reviewAndConfirm.termsOfLinkLabel"
                      )}
                    </a>{" "}
                    {t("lifeInsurance.withdrawal.reviewAndConfirm.andLabel")}{" "}
                    <a
                      href="https://www.solidarity.com.bh/privacyandsecurity"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-[#00384D] text-sm not-italic font-semibold leading-6 underline"
                    >
                      {t(
                        "lifeInsurance.withdrawal.reviewAndConfirm.privacyLinkLabel"
                      )}
                    </a>
                  </label>
                </div>
              }
              isChecked={isChecked}
              setIsChecked={setIsChecked}
            />
          </div>
          <button
            className={`w-full text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] flex justify-center p-3 rounded-[10px] ${
              isChecked
                ? "bg-[#55C6EF] rounded-[10px] cursor-pointer"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
            disabled={!isChecked}
            onClick={confirmClickHandler}
          >
            {translate("Confirm and Submit", "تأكيد وإرسال")}
          </button>
        </div>
      </RenewMotorLayout>
    );
}

export default ReviewAndConfirm