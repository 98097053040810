import React, { useEffect, useMemo, useState } from "react";
import Button from "../../../../../components/common/Button/Button";
import BackdropModal from "../../../../../components/common/modal/backdropModal";
import useValidation from "../../../../../hooks/useValidation";
import AddNewBeneficiary from "../../../../../assets/images/AddNewBeneficiary.svg";
import Input from "../../../../../components/common/Input/Input";
import TelInput from "../../../../../components/common/telInput/telInput";
import SelectInput from "../../../../../components/common/selectInput/selectInput";
import {
  daysOptions,
  monthsOptions,
  yearsOptions,
} from "../../../../motorInsurancePages/newMotorPolicy/start/basicInfo/data";
import { useTranslation } from "react-i18next";
import {
  ADD_BENEFICIARY_VALIDATIONS,
  ageCalculator,
  FORM_VALIDATION_WITH_MOBILE_VALIDATION,
  RELATIONSHIP_OPTIONS,
} from "./data";
import Axios from "../../../../../redux/axiosInstance";
import { expressShieldEndPoints } from "../../../../../api";
import FormTabs from "../../../../../components/common/tabs/formTabs";
import { genderTabs } from "../../../../buyOnline/expressShield/details/otherDetails";
import { COUNTRY_SELECT_DATA } from "../../../../buyOnline/expressShield/details/data";
import useScreenResolution from "../../../../../hooks/useScreenResolution";
import BottomStickyBtnLayout from "../../../../motorInsurancePages/newMotorPolicy/Layouts/BottomStickyBtnLayout";
import backarrow from "../../../../../assets/images/back-arrow-left.svg";
import useLanguageTranslate from "../../../../../hooks/useLanguageTranslate";

const AddNewBeneficiaryPopup = ({
  show,
  setShow,
  toggleTrustieDetails,
  AddBenficiary,
  isLoading,
  editData,
  isEditBenefi,
  setIsEditBenefi,
  deleteBenef,
  UpdateBenficiary,
  minorAge = 21,
}) => {
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const [values, setValues] = useState(editData || {});
  const { errors, validateFields } = useValidation();
  const { mobile } = useScreenResolution();
  const [cprError, setCprError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const validateForm = (data) => {
    let form_data = data ? data : values;
    let validationSchema = isAgeLessThanRequired
      ? ADD_BENEFICIARY_VALIDATIONS
      : FORM_VALIDATION_WITH_MOBILE_VALIDATION;
    let isValid = validateFields(validationSchema, form_data);
    return isValid;
  };
  const onClose = () => {
    setShow((state) => !state);
    setValues({});
  };
  useEffect(() => {
    setValues(editData);
  }, [editData]);

  const isAgeLessThanRequired = () => {
    if (!values) return false;
    return ageCalculator(values) < minorAge;
  };

  const addHandler = () => {
    if (!validateForm()) return;
    if (cprError) return;
    if (mobileError) return;
    if (values.cpr.length != 9) {
      setCprError(t("validation.cpr_number"));
      return;
    }
    if (values.mobile.length != 8) {
      setMobileError(t("validation.invalidMobile"));
      return;
    }

    if (isAgeLessThanRequired()) {
      toggleTrustieDetails(values);
    } else {
      isEditBenefi ? UpdateBenficiary(values) : AddBenficiary(values);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "share_percentage" && value > 100) return;
    let newValue = value;
    if (name == "mobile") {
      newValue = value.replace(/\D/g, ""); // Remove non-digit characters
      if (newValue.length > 8) {
        newValue = newValue.substring(0, 8); // Limit to 8 digits
      }
      if (newValue.length != 8) {
        setMobileError(t("validation.invalidMobile"));
      } else {
        setMobileError("");
      }
    }

    if (name == "cpr") {
      newValue = value.replace(/\D/g, "");
      if (newValue.length > 9) {
        newValue = newValue.substring(0, 9);
      }

      if (newValue.length != 9) {
        setCprError(t("validation.cpr_number"));
      } else {
        setCprError("");
      }
    }

    let update = { ...values, [name]: newValue };
    setValues(update);
    if (errors?.[name]) {
      validateForm(update);
    }
  };

  const onTabClick = (value, name) => {
    let update = { ...values, [name]: value };
    setValues(update);
    if (errors?.[name]) {
      validateForm(update);
    }
  };

  const backClickHandler = () => {
    onClose();
  };
  return (
    <div className="addnewbenificiary">
      <BackdropModal
        onClose={onClose}
        show={show}
        show_summary={false}
        hideClosebtn={mobile}
      >
        {!mobile && (
          <p className="flex mb-5 w-[600px] max-w-[100%] max767:mt-[-13px]">
            <img
              src={AddNewBeneficiary}
              alt="exit_to_app"
              className="exit_to_app  mt-[-40px] "
            />
          </p>
        )}
        {mobile && (
          <p onClick={backClickHandler} className="flex gap-2">
            {" "}
            <img
              src={backarrow}
              alt="back"
              className="bg-[#F6F8F9] p-2 rounded-3xl rtl:rotate-180"
            />
          </p>
        )}
        <div className="content mb-4 max767:mt-10">
          <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mb-3 max767:text-xl">
            {isEditBenefi
              ? translate("Edit Beneficiary Details", "تعديل بيانات المستفيد")
              : translate("Add New Beneficiary", "الدخل الثابت")}
          </h1>
          {mobile && (
            <p className="text-[#1A2024] text-base not-italic font-normal leading-6 tracking-[-0.16px] max767:mb-8">
              {translate(
                "Please review your information and submit request.",
                "يرجى مراجعة معلوماتك وتقديم الطلب."
              )}
            </p>
          )}
          <div className=" max767:w-full mx-auto ipad:w-[100%]">
            <Input
              label={t(
                "motorInsurance.newMotorInsuranceFlow.basicInfo.fullName"
              )}
              type="text"
              name="fullName"
              value={values?.fullName}
              error={errors?.fullName}
              handleChange={handleChange}
            />

            <Input
              label={translate("Email Address", "عنوان البريد الإلكتروني")}
              type="email"
              name="email"
              value={values?.email}
              error={errors?.email}
              handleChange={handleChange}
            />
            <TelInput
              name="mobile"
              value={values?.mobile}
              error={errors?.mobile || mobileError}
              onChange={handleChange}
              label={t(
                "motorInsurance.newMotorInsuranceFlow.basicInfo.mobileNumber"
              )}
              className="mobilenumber px-4 py-3 max767:w-[50%] w-[97%] border-r-0 border-0 rounded-[9px] h-[46px] focus:outline-none"
            />

            <Input
              label={translate("CPR Number", "الرقم الشخصي")}
              type="number"
              name="cpr"
              value={values?.cpr}
              error={errors?.cpr || cprError}
              handleChange={handleChange}
            />
            <div className="date_of_birth">
              <label className="block mb-[5px] text-gray-700 not-italic font-normal leading-6 text-base max-350:text-[13px]">
                {t(
                  "motorInsurance.newMotorInsuranceFlow.basicInfo.dateOfBirth"
                )}
              </label>
              <div className="flex gap-6 self-stretch">
                <SelectInput
                  name="day"
                  options_data={daysOptions}
                  formData={values}
                  default_placeholder={t(
                    "motorInsurance.newMotorInsuranceFlow.basicInfo.day"
                  )}
                  error={errors?.day}
                  handleChange={handleChange}
                />

                <SelectInput
                  name="month"
                  formData={values}
                  default_placeholder={t(
                    "motorInsurance.newMotorInsuranceFlow.basicInfo.month"
                  )}
                  options_data={monthsOptions}
                  error={errors?.month}
                  handleChange={handleChange}
                />

                <SelectInput
                  name="year"
                  formData={values}
                  default_placeholder={t(
                    "motorInsurance.newMotorInsuranceFlow.basicInfo.year"
                  )}
                  options_data={yearsOptions}
                  error={errors?.year}
                  handleChange={handleChange}
                />
              </div>
            </div>
            <FormTabs
              label={t("buyOnline.expressShieldFlow.otherDetails.gender")}
              tabs={genderTabs}
              onTabClick={(type) => onTabClick(type, "gender")}
              activeTab={values?.gender}
              error={errors?.gender}
            />

            <SelectInput
              label={translate("Relationship", "العلاقة")}
              className="selectinput"
              type="text"
              name="relationship"
              error={errors?.relationship}
              handleChange={handleChange}
              options_data={RELATIONSHIP_OPTIONS}
              formData={values}
            />

            <SelectInput
              name="nationality"
              className="selectinput"
              formData={values}
              label={translate("Nationality", "الجنسية")}
              options_data={COUNTRY_SELECT_DATA}
              error={errors?.nationality}
              handleChange={handleChange}
            />

            <Input
              label={translate("Share Percentage", "نسبة الحصة")}
              type="number"
              name="share_percentage"
              value={values?.share_percentage}
              error={errors?.share_percentage}
              handleChange={handleChange}
              endAdornment={
                <span className="flex items-center gap-2.5 bg-[#F6F8F9] px-4 py-[13px] rounded-tr-[10px] rounded-br-[10px] right-0.5 top-px mr-[-10px] -mt-3.5">
                  %
                </span>
              }
            />
          </div>

          {!mobile &&
            (isEditBenefi ? (
              <div className="mt-2 flex items-start gap-3 self-stretch addbbottombutton">
                <Button
                  onSubmit={() => deleteBenef(values)}
                  pre_icon={""}
                  label={translate("Remove Beneficiary", "حذف المستفيد")}
                  className="h-full  gap-2.5 flex-[1_0_0] px-4 py-2 border rounded-[10px] border-solid border-[#DDE2E4] bg-white text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
                />
                <Button
                  disabled={isLoading}
                  onSubmit={addHandler}
                  label={translate("Update", "تحديث")}
                  className="flex  gap-2.5 flex-[1_0_0] px-4 py-2  rounded-[10px]   bg-[#55C6EF] text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
                />
              </div>
            ) : (
              <div className="mt-2 flex items-start gap-3 self-stretch addbbottombutton h-[40px]">
                <Button
                  onSubmit={onClose}
                  label={translate("Cancel", "إلغاء")}
                  className=" gap-2.5 flex-[1_0_0] px-4 h-full border rounded-[10px] border-solid border-[#DDE2E4] bg-white text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] hover:bg-[#DDE2E4]"
                />
                <Button
                  disabled={isLoading}
                  onSubmit={addHandler}
                  label={
                    isAgeLessThanRequired
                      ? translate("Next", "التالي")
                      : translate("Add", "إضافة")
                  }
                  className=" items-center gap-2.5 flex-[1_0_0] px-4 h-full  rounded-[10px] bg-[#55C6EF] text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
                />
              </div>
            ))}
          {mobile && (
            <BottomStickyBtnLayout
              btn_label={
                isEditBenefi
                  ? translate("Update", "تحديث")
                  : isAgeLessThanRequired
                  ? translate("Next", "التالي")
                  : translate("Add", "إضافة")
              }
              btn_onClick={addHandler}
              btn_disabled={isLoading}
            ></BottomStickyBtnLayout>
          )}
        </div>
      </BackdropModal>
    </div>
  );
};

export default AddNewBeneficiaryPopup;
