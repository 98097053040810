import React, { useState } from "react";
import MorePageLayout from "./morePageLayout";
import ToggleSwitch from "../../components/common/toggleSwitch/toggleSwitch";
import righticon from "../../assets/images/chevron_right.svg";
import FaceIDSymbol from "../../assets/images/FaceIDSymbol.svg";
import lockicon from "../../assets/images/lockblack.svg";
import sms from "../../assets/images/email.svg";
import Changepassword from "../../assets/images/lock_open.svg";
import phonelinklock from "../../assets/images/phonelink_lock.svg"
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const MobileSecurityPage = () => {
  const { t } = useTranslation();

  const [formState, setFormState] = useState({
    useFaceID: true,
    autoLock: true,
    twoFactorAuth: false,
  });
  const navigate = useNavigate();

  const updateToggle = (field) => {
    setFormState((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  return (
    <MorePageLayout>
      <div className="mt-24 p-5 w-full h-screen bg-white">
        <h2 className="text-black text-xl not-italic font-semibold leading-[120%] mb-4">
        {t("securityPage.security")}
        </h2>

        <div className="flex items-center justify-between py-4">
          <div className="flex items-center space-x-3">
            <span className="flex items-start bg-[#E5E9EB] p-3 rounded-3xl mr-4 rtl:ml-4 rtl:mr-0">
              <img src={FaceIDSymbol} alt="Face ID Icon" />
            </span>
            <span className="text-black text-base not-italic font-medium leading-6 tracking-[-0.16px]">
            {t("securityPage.useFaceID")}
            </span>
          </div>
          <ToggleSwitch
            isOn={formState.useFaceID}
            onChange={() => updateToggle("useFaceID")}
          />
        </div>

        <div className="flex items-center justify-between py-3">
          <div className="flex items-center space-x-3">
            <span className="flex items-start bg-[#E5E9EB] p-3 rounded-3xl mr-4 rtl:ml-4 rtl:mr-0">
              <img src={lockicon} alt="Lock Icon" />
            </span>
            <div className="">
              <span className="text-black text-base not-italic font-medium leading-6 tracking-[-0.16px] pb-2 ">
              {t("securityPage.autoLockSecurity")}
              </span>
              <p className="text-[#5B6871] text-sm not-italic font-normal leading-[120%] pt-2">
              {t("securityPage.autoLockDescription")}
              </p>
            </div>
          </div>
          <ToggleSwitch
            isOn={formState.autoLock}
            onChange={() => updateToggle("autoLock")}
          />
        </div>
{/* 
        <div
          className="flex items-center justify-between py-3"
          onClick={() => navigate("/change-email")}
        >
          <div className="flex items-center space-x-3">
            <span className="flex items-start bg-[#E5E9EB] p-3 rounded-3xl mr-4">
              <img src={sms} alt="Lock Icon" />
            </span>
            <div>
              <span className="text-black text-base not-italic font-medium leading-6 tracking-[-0.16px] mb-1">
                Change your email address
              </span>
              <p className="text-[#5B6871] text-sm not-italic font-normal leading-[120%] pt-2">
                ahmed.jassim@gmail.com
              </p>
            </div>
          </div>
          <img src={righticon} alt="Right Icon" />
        </div> */}

        <div className="flex items-center justify-between py-3" onClick={() => navigate("/change-password")}>
          <div className="flex items-center space-x-3">
            <span className="flex items-start bg-[#E5E9EB] p-3 rounded-3xl mr-4 rtl:ml-4 rtl:mr-0">
              <img src={Changepassword} alt="Change password" />
            </span>
            <div>
              <span className="text-black text-base not-italic font-medium leading-6 tracking-[-0.16px] mb-1">
              {t("securityPage.changePassword")}
              </span>
              <p className="text-[#5B6871] text-sm not-italic font-normal leading-[120%] pt-2">
                ************
              </p>
            </div>
          </div>
          <img src={righticon} alt="Right Icon"  className="rtl:rotate-180"/>
        </div>

        <div className="flex items-center justify-between py-3">
          <div className="flex items-center space-x-3">
            <span className="flex items-start bg-[#E5E9EB] p-3 rounded-3xl mr-4 rtl:ml-4 rtl:mr-0">
              <img src={phonelinklock} alt="Two-factor" />
            </span>
            <span className="text-black text-base not-italic font-medium leading-6 tracking-[-0.16px] mb-1">
            {t("securityPage.twoFactorAuth")}
            </span>
          </div>
          <ToggleSwitch
            isOn={formState.twoFactorAuth}
            onChange={() => updateToggle("twoFactorAuth")}
          />
        </div>
      </div>
    </MorePageLayout>
  );
};

export default MobileSecurityPage;
