import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCurrentStepIndex,
    selectCurrentSubstepIndex,
    selectChangePlanFlowType,
    setCurrentStepIndex,
    setCurrentSubstepIndex,
    setChangePlanFlowType
} from '../redux/features/motorInsuranceChangePlanFlowSlice';
import { CHANGE_PLAN_REVIEW_AND_CONFIRM_PAGE, CHANGE_PLAN_SELECT_NEW_PLAN, CHANGE_PLAN_UPLOAD_PHOTOS_OF_CAR } from '../pages/motorInsurancePages/changePlanMotorPolicy/data';



const useChangePlanFlow = () => {
    const dispatch = useDispatch();
    const flowType = useSelector(selectChangePlanFlowType);
    const currentStepIndex = useSelector((state) => selectCurrentStepIndex(state));
    const currentSubstepIndex = useSelector((state) => selectCurrentSubstepIndex(state));

    const steps = new_motor_insurance_renewal_flow[flowType] || [];
    const currentStep = steps[currentStepIndex] || {};
    const currentSubstep = currentStep.substeps ? currentStep.substeps[currentSubstepIndex] : '';

    const handleFlowChange = (type, currentStepIndex, currentSubstepIndex) => {
        dispatch(setChangePlanFlowType({ type, currentStepIndex, currentSubstepIndex }));
    };

    const handleNextSubstep = () => {
        if (currentStep.substeps && currentSubstepIndex < currentStep.substeps.length - 1) {
            dispatch(setCurrentSubstepIndex(currentSubstepIndex + 1));
        }
        else if (currentSubstepIndex ==currentStep.substeps.length - 1) {
            handleNextStep()
        }
    };

    const handlePrevSubstep = () => {
        if (currentSubstepIndex > 0) {
            dispatch(setCurrentSubstepIndex(currentSubstepIndex - 1));
        }
        else {
            handlePrevStep()
        }
    };

    const handleNextStep = () => {
        if (currentStepIndex < steps.length - 1) {
            dispatch(setCurrentStepIndex(currentStepIndex + 1));
        }
    };

    const handlePrevStep = () => {
        if (currentStepIndex > 0) {
            const currentStep = steps[currentStepIndex - 1] || {};
            const substepIndex = currentStep?.substeps?.length - 1;
            dispatch(setCurrentStepIndex(currentStepIndex - 1));
            dispatch(setCurrentSubstepIndex(substepIndex));
        }
    };

    return (
        {
            handleFlowChange,
            handleNextSubstep,
            handlePrevSubstep,
            handleNextStep,
            handlePrevStep,
            flowType,
            steps,
            currentStep,
            currentStepIndex,
            currentSubstep,
            currentSubstepIndex,
        }
    );
};

export default useChangePlanFlow;



export const CHANGE_PLAN_UPGRADE = "CHANGE_PLAN_UPGRADE";
export const CHANGE_PLAN_DOWNGRADE = "CHANGE_PLAN_DOWNGRADE"


export const new_motor_insurance_renewal_flow = {
    [CHANGE_PLAN_UPGRADE]: [
        {
            name: CHANGE_PLAN_SELECT_NEW_PLAN,
            substeps: [
                CHANGE_PLAN_SELECT_NEW_PLAN,
            ]
        },
        {
            name: CHANGE_PLAN_UPLOAD_PHOTOS_OF_CAR,
            substeps: [
                CHANGE_PLAN_UPLOAD_PHOTOS_OF_CAR,
            ]
        },
        {
            name: CHANGE_PLAN_REVIEW_AND_CONFIRM_PAGE,
            substeps: [
                CHANGE_PLAN_REVIEW_AND_CONFIRM_PAGE,
            ]
        },
    ],
    [CHANGE_PLAN_DOWNGRADE]: [
        {
            name: CHANGE_PLAN_SELECT_NEW_PLAN,
            substeps: [
                CHANGE_PLAN_SELECT_NEW_PLAN,
            ]
        },
        {
            name: CHANGE_PLAN_REVIEW_AND_CONFIRM_PAGE,
            substeps: [
                CHANGE_PLAN_REVIEW_AND_CONFIRM_PAGE,
            ]
        },
    ],
};
