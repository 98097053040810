import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import TitleDescription from '../../../../components/newMotorPolicy/titleDescription/titleDescription';
import UploadSuccess from './uploadSuccess';
import useMotorFlow from '../../../../hooks/useMotorFlow';
import uploadfile from "../../../../assets/images/upload_file.svg";
import qrcode from "../../../../assets/images/QRCode.svg";

const UploadScan = () => {
    const { t } = useTranslation();
    const [docsuploadedSuccess, setDocsuploadedSuccess] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setDocsuploadedSuccess(true);
        }, 2000);
    }, [])
    const { handleNextSubstep } = useMotorFlow()

    const NextHandler = () => {
        handleNextSubstep()
    };
    return (
        <div className="max-w-lg mx-auto bg-white rounded-md max767:w-[90%]">
            <div className="upload3rdPartyDocsPage">
                <TitleDescription
                    title={t(
                        "motorInsurance.newMotorInsuranceFlow.upload3rdPartyDocsPage.title"
                    )}
                    description={t(
                        "motorInsurance.newMotorInsuranceFlow.upload3rdPartyDocsPage.description"
                    )}
                />
                {docsuploadedSuccess && <UploadSuccess nexthandler={NextHandler} />}
                <div className="bg-[#E3F8FF] rounded-3xl p-6 mb-6">
                    <div className="flex items-center mb-4">
                        <div className="text-white rounded-full mr-2">
                            <img src={uploadfile} alt="Back arrow" className="" />
                        </div>
                        <h2 className="text-lg font-semibold">
                            {t(
                                "motorInsurance.newMotorInsuranceFlow.upload3rdPartyDocsPage.stepsToUpload.label"
                            )}
                        </h2>
                    </div>
                    <p className="text-gray-700 mb-4  px-8 py-0">
                        {t(
                            "motorInsurance.newMotorInsuranceFlow.upload3rdPartyDocsPage.stepsToUpload.description"
                        )}{" "}
                    </p>
                    <div className="flex">
                        <img
                            src={qrcode}
                            alt="QR Code"
                            className="w-40 h-40 ml-7"
                        />
                    </div>
                </div>

            </div>

            <div>
                <h2 className="text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px] mb-2">
                    {t(
                        "motorInsurance.newMotorInsuranceFlow.upload3rdPartyDocsPage.instructions.label"
                    )}
                </h2>
                <p className="text-[#48535B] text-sm not-italic font-normal leading-5 tracking-[-0.14px] mb-4">
                    {t(
                        "motorInsurance.newMotorInsuranceFlow.upload3rdPartyDocsPage.instructions.description"
                    )}
                </p>

                <p className="text-[#48535B] text-sm not-italic font-normal leading-5 tracking-[-0.14px] mb-4">
                    {t(
                        "motorInsurance.newMotorInsuranceFlow.upload3rdPartyDocsPage.needAssistance"
                    )}{" "}
                    <a href="/faqs" className="text-[#00384D] text-sm not-italic font-semibold leading-5 tracking-[-0.14px] underline">
                        {t(
                            "motorInsurance.newMotorInsuranceFlow.upload3rdPartyDocsPage.faq"
                        )}
                    </a>{" "}
                    {t(
                        "motorInsurance.newMotorInsuranceFlow.upload3rdPartyDocsPage.sectionOr"
                    )}{" "}
                    <a href="/contact" className="text-[#00384D] text-sm not-italic font-semibold leading-5 tracking-[-0.14px] underline">
                        {t(
                            "motorInsurance.newMotorInsuranceFlow.upload3rdPartyDocsPage.contactCustomerSupport"
                        )}{" "}
                    </a>
                    .
                </p>
            </div>
        </div>
    )
}

export default UploadScan