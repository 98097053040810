import React from "react";
import backarrow from "../../../../../assets/images/back-arrow-left.svg";
import closeicon from "../../../../../assets/images/close.svg";
import { screenResolution } from "../../../../../utils/screenResolution";
import BottomStickyBtnLayout from "../../../newMotorPolicy/Layouts/BottomStickyBtnLayout";
import useRenewalFlow from "../../../../../hooks/useRenewalFlow";
import useScreenResolution from "../../../../../hooks/useScreenResolution";
import useLanguageTranslate from "../../../../../hooks/useLanguageTranslate";

const PolicyCancellationMotorLayout = ({
    children,
    is_hide_back_btn,
    show_close_btn,
    handleCloseBtnClick,
    btn_label,
    btn_onClick,
    btn_disabled,
    show_summary,
    is_bottom_sticky_layout = true,
    backClickHandler,
    tempPrice,
    tempIncluded,
    CancellationAdditionalComponent,

}) => {
    const { handlePrevSubstep } = useRenewalFlow()
    const  { mobile } = useScreenResolution()
    const translate = useLanguageTranslate()


    return (
        <div className="">
            {!is_hide_back_btn && (
                <button
                    className="backButton bg-navy-blue py-2 rounded mt-20 flex absolute left-[26px] top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:bg-[#F6F8F9] max767:w-10 max767:h-10 max767:text-center max767:justify-center max767:rounded-3xl max767:mt-12 max767:ml-[-11px] rtl:right-[26px]"
                    onClick={backClickHandler ? backClickHandler : handlePrevSubstep}
                >
                    <img src={backarrow} alt="Back arrow" className="1 rtl:rotate-180" />
                    <span className="back-text inline max767:hidden rtl:mr-1">
                    {translate("Back", "عودة")}
                    </span>
                </button>
            )}
            {show_close_btn && screenResolution.mobile && (
                <button
                    className="bpy-2 rounded mt-4 flex absolute top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:ml-[16px] max767:mb-[10px]"
                    onClick={handleCloseBtnClick}
                >
                    <img src={closeicon} alt="Close Icon" />
                </button>
            )}
            {is_bottom_sticky_layout ?
                <BottomStickyBtnLayout hideContinueBtn={mobile} show_summary={show_summary} btn_onClick={btn_onClick} btn_disabled={btn_disabled} btn_label={btn_label} price={tempPrice} included={tempIncluded} AdditionalComponent={CancellationAdditionalComponent}>
                    {children}
                </BottomStickyBtnLayout> : children }

        </div>
    );
};

export default PolicyCancellationMotorLayout;
