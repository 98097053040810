import React from "react";
import DownloadBtn from "../../../components/common/downloadBtn/downloadBtn";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import useScreenResolution from "../../../hooks/useScreenResolution";

import { useNavigate, useParams } from "react-router-dom";
import { decodeQueryString } from "../../../utils";
import Loader from "../../../components/common/Loader/Loader";
import greendonecheck from "../../../assets/images/greendone.svg";
import { useTranslation } from "react-i18next";
import { health360Data } from "./data";
import googleplay from "../../../assets/images/Google.svg";
import aiHilalHealthCareIcon from "../../../assets/images/alhilalhostpital.svg";
import apple from "../../../assets/images/Apple.svg";
import websiteIcon from "../../../assets/images/siteIcon.svg";
import call from "../../../assets/images/phone.svg";
import Button from "../../../components/common/Button/Button";
import BackdropModal from "../../../components/common/modal/backdropModal";

const PolicyOverviewTab = ({ data, showDownloadPopup, handleDownloadMenuClicked }) => {
  const translate = useLanguageTranslate();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { id } = useParams();
  const params = decodeQueryString();
  const screenResolution = useScreenResolution();

  return (
    <div className="flex w-full gap-[16px]">
      {/* hospital card */}
      <div className="flex flex-col w-[800px] max-w-[100%] p-6 border bg-white rounded-[10px] border-solid border-[#DDE2E4] self-start pb-0">
      <div className="flex items-center justify-between p-6 w-full bg-[#F6F8F9] rounded-[10px]">
        <div className="flex items-center w-[244px] h-10 ">
          <img
            src={aiHilalHealthCareIcon}
            alt="Al-Hilal Hospital Logo"
            className="h-[100%]"
          />
        </div>

        <div className="flex items-center space-x-6">
          <a
            href="https://www.alhilalhealthcare.com"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-sm text-blue-600 hover:underline rtl:ml-4"
          >
            <img
              src={websiteIcon}
              alt="siteIcon"
              className="w-6 h-6"
            />
            <span className="ml-2 text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] underline decoration-solid decoration-auto underline-offset-auto rtl:mr-2 rtl:ml-0">www.alhilalhealthcare.com</span>
          </a>
          <div className="flex items-center text-sm text-gray-600">
            <img
              src={call}
              alt="phone"
              className="w-6 h-6"
            />
            <span className="ml-2 text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] rtl:mr-2 rtl:ml-0">{translate("Call:","اتصال")} 80408080</span>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap gap-4 ipad:gap-2 max767:grid max767:grid-cols-2 max767:p-5 policyoverviewtab mt-6">
        {/* Include Benefit section */}
        <div className="includeSection w-3/5 max-600:w-full ipad:w-[50%]">
          <h3 className="text-[#1A2024] text-lg not-italic font-medium leading-[normal] mb-4">
            {t("motorInsurance.covergeAndBenefit.benefit")}
          </h3>
          <ul className="space-y-2 mb-6">
            {data?.includeBenefits?.map((benefit) => (
              <li className="flex items-center text-black text-sm not-italic font-normal leading-[150%]">
                <img
                  src={greendonecheck}
                  className="mr-2 rtl:ml-2 rtl:mr-0"
                  alt="green check mark"
                />
                {translate(benefit.description, benefit.description_ar)}
              </li>
            ))}
          </ul>
        </div>
      </div>
      </div>
      <div className="flex flex-col w-[336px] max-w-[100%] gap-4">
        {/* Health care card */}
        <div className="w-[100%] ipad:w-[100%] max767:w-[100%]">
        
          <div className="space-y-4">
            {health360Data?.items && (
              <div className="flex flex-col health360Data?.itemss-center gap-4 self-stretch bg-[#F6F8F9] p-6 rounded-[10px] min-h-[116px]">
                 {health360Data && (
            <div className="text-[#1A2024] text-sm not-italic font-medium leading-[normal] mb-1">
              {translate(health360Data?.title_en, health360Data?.title_ar)}
            </div>
          )}
          <div className="flex gap-4">
          <div>
                {health360Data?.items.icon && (
                  <img
                    src={health360Data?.items.icon}
                    alt={translate(
                      health360Data?.items.label_en,
                      health360Data?.items.label_ar
                    )}
                    className="w-[64px] h-[64px] mr-0"
                  />
                )}
                </div>
                <div>
                  <p className="text-[#48535B] text-left text-lg not-italic font-medium leading-[120%] mb-3 rtl:text-right">
                    {translate(
                      health360Data?.items.label_en,
                      health360Data?.items.label_ar
                    )}
                  </p>

                  {(health360Data?.items.iosLink ||
                    health360Data?.itemss.gPayLink) && (
                    <div className="flex health360Data?.itemss-center space-x-2 ml-0 max767:justify-start">
                      {health360Data?.items.iosLink && (
                        <a
                          href={health360Data?.items.iosLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={apple} alt="App Store" className="h-10" />
                        </a>
                      )}
                      {health360Data?.items.gPayLink && (
                        <a
                          href={health360Data?.items.gPayLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={googleplay}
                            alt="Google Play"
                            className="h-10"
                          />
                        </a>
                      )}
                    </div>
                  )}
                </div>
              </div>
              </div>
            )}
          </div>
        </div>

        {/* Documments */}
        {data?.downloadCard && (
          <div className="w-full items-center max-600:w-full max-600:p-0 max-600:mb-4 ipad:p-0 ipad:w-[50%] max767:hidden pr-0">
            <div className="flex flex-col justify-center items-start gap-2 self-stretch bg-[#F6F8F9] p-6 rounded-[10px]">
              <div className="flex flex-col w-full">
                <div className="text-[#1A2024] text-sm not-italic font-medium leading-[normal] mb-1 flex justify-between w-full gap-2 justify-around items-center">
                  {translate(
                    data?.downloadCard?.label_en,
                    data?.downloadCard?.label_ar
                  )}
                  <Button
                    className="bg-transparent text-[#00384D] text-sm not-italic font-semibold leading-4 tracking-[-0.14px] underline hover:bg-transparent viewallbtn"
                    label={translate("View All", "عرض الكل")}
                    onSubmit={handleDownloadMenuClicked}
                  />
                </div>
              </div>
              {data?.downloadCard?.download_btn && (
                <DownloadBtn
                  filesData={data?.downloadCard.filesData.slice(0, 4)}
                  className={"w-full"}
                />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="downloaddocumentsmodal">
          <BackdropModal
            show={showDownloadPopup}
            onClose={handleDownloadMenuClicked}
            title={translate("Download Documents","تحميل المستندات")}
          >
            <div className="flex-row">
              <DownloadBtn filesData={data?.downloadCard?.filesData} className={"mb-2"} />
            </div>
          </BackdropModal>
        </div>
    </div>
  );
};

export default PolicyOverviewTab;
