import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyBbJzeSA-Pd6uIbBWMZDkBsOs4JU8yvaFw",
    authDomain: "essential-haiku-254805.firebaseapp.com",
    databaseURL: "https://essential-haiku-254805.firebaseio.com",
    projectId: "essential-haiku-254805",
    storageBucket: "essential-haiku-254805.appspot.com",
    messagingSenderId: "824596323368",
    appId: "1:824596323368:web:2972d69e55e0c37a72291d",
    measurementId: "G-DF8PV3T1SE"
    };


const app = initializeApp(firebaseConfig);


const db = getFirestore(app);

export { db };
