import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearLifeFormData, selectNewLifeForm, setLifeFormData } from "../redux/features/newLifeFormSlice";

const useLifeFormData = (formName, setFormData) => {
    const dispatch = useDispatch();
    const newLifeFormData = useSelector(selectNewLifeForm);
    const redux_form_data = newLifeFormData?.[formName];

    useEffect(() => {
        if (redux_form_data) {
            setFormData(redux_form_data);
        }
    }, [redux_form_data, setFormData]);

    const saveLifeDataInRedux = (formData) => {
        dispatch(setLifeFormData({ form_name: formName, data: formData }));
    }

    const removeLifeFormData = () => {
        dispatch(clearLifeFormData({ form_name: formName }));
    };

    return { saveLifeDataInRedux, removeLifeFormData, redux_form_data };
};

export default useLifeFormData;
