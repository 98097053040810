export const FLOW_TYPE_EXPRESS_SHIELD = "FLOW_TYPE_EXPRESS_SHIELD";

export const DETAILS = "details";
export const PLAN = "plan";
export const PAYMENT = "payment";

export const express_shield_flow = {
  [FLOW_TYPE_EXPRESS_SHIELD]: [
    {
      name: DETAILS,
      substeps: ["basic_details", "other_details"],
    },
    {
      name: PLAN,
      substeps: ["declaration", "choose_plan", "beeficiary_details"],
    },
    {
      name: PAYMENT,
      substeps: [
        "review_and_confirm",
        "payment_method",
        "payment_congratulations",
      ],
    },
  ],
};

export const headerStepperData = [
  {
    key: DETAILS,
    title_en: "Details",
    title_ar: "يبدأ",
  },
  {
    key: PLAN,
    title_en: "Plan",
    title_ar: "يخطط",
  },
  {
    key: PAYMENT,
    title_en: "Payment",
    title_ar: "يدفع",
  },
];


let individualBeneficiary = {
  data: [
    { id: 0, column1: "", column2: "" },
    { id: 1, column1: "Name", column2: "Mohammed Sadeq" },
    { id: 2, column1: "Share Percentage", column2: "64" },
    { id: 3, column1: "CPR", column2: "880079867" },
    { id: 3, column1: "Relationship", column2: "Cousin" },
    { id: 3, column1: "Mobile Number", column2: "+97337796977" },
  ],
}

export const genderLabels = {
  M: {
    label_en: "Male",
    label_ar: "ذكر",
  },
  F: {
    label_en: "Female",
    label_ar: "أنثى",
  },
};