import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import forwardarrow from "../../../assets/images/arrow_forwardb.svg";
import Exclamation from "../../../assets/images/Exclamation.svg";
import PromoCode from "../../../components/newMotorPolicy/veiwSummaryPopup/promoCode";
import { useTranslation } from "react-i18next";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useSelector } from "react-redux";
import { selectNewLifeForm } from "../../../redux/features/newLifeFormSlice";
import {
  ESSENTIAL_CARE_BASIC_INFO,
  EXPRESS_SHEILD_BASIC_INFO,
} from "../details/basicInfo";
// import { ageCalculator } from "../../../lifeInsurancePages/PolicyOverview/manageMyPolicy/manageBeneficiaries/data";
import { selectExpressShieldData } from "../../../redux/features/expressSheild/expressSheildSlice";
import LifeInsuranceSummary from "../../../components/newMotorPolicy/veiwSummaryPopup/lifeInsuranceSummary";
import { selectProfileData } from "../../../redux/features/profileSlice";
import useLifeFormData from "../../../hooks/useLifeFormData";
import usePaymentApi from "../../../hooks/BuyOnlineExpressShield/usePaymentApi";
import Loader from "../../../components/common/Loader/Loader";
import { genderLabels } from "../data";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import EssentialCareLayout from "../essentialCareLayout";
import useEssentialCareFlow from "../../../hooks/essentialCare/useEssentialCare";
import { ESSENTIAL_CARE_FAMILY_MEMBER_DETAILS } from "../plan/familyMmeberDetails";
import LifeDynamicTable from "../../../components/common/table/lifeTable";
import CustomCheckbox from "../../../components/common/customCheckbox/customCheckbox";
import { selectEssentialCareData } from "../../../redux/features/essentialCare/essentialCareQuoteSlice";
import Button from "../../../components/common/Button/Button";
const PROMO_CODE = "PROMO_CODE";
const ReviewAndConfirm = () => {
  const { handleNextSubstep } = useEssentialCareFlow();
  const { generatePaymentLink, isLoading } = usePaymentApi();
  const translate = useLanguageTranslate();
  const onSuccess = () => {
    handleNextSubstep();
  };

  const [formState, setFormState] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();
  const { saveLifeDataInRedux, removeLifeFormData } = useLifeFormData(
    PROMO_CODE,
    setFormState
  );

  const applyPromoCode = () => {
    saveLifeDataInRedux(formState);
  };

  const basicDetails =
    useSelector(selectNewLifeForm)?.[ESSENTIAL_CARE_BASIC_INFO];
  const familyMembersDetails =
    useSelector(selectNewLifeForm)?.[ESSENTIAL_CARE_FAMILY_MEMBER_DETAILS];

  const essentialCareQuoteData = useSelector(selectEssentialCareData);

  const declaration = t(
    "essentialCareFlow.reviewAndConfirm.agreement.iDeclare",
    { returnObjects: true }
  );

  const navigate = useNavigate();
  const handlePayment = async () => {
    //navigate("/motor-insurance/payment");
    let payload = {
      PolicyNumber: essentialCareQuoteData?.QuoteDetails?.QuoteNumber || "",
      Premium: essentialCareQuoteData?.TotalPremium || 0,
    };
    let response = await generatePaymentLink(payload);
    console.log(response);

    if (response?.length) {
      window.open(response[0], "_self");
    }
  };

  return (
    <EssentialCareLayout
      is_bottom_sticky_layout={false}
      btn_onClick={onSuccess}
    >
      <div className="w-[500px] max-w-[100%] mx-auto">
        <TitleDescription
          title={t("essentialCareFlow.reviewAndConfirm.title")}
          description={t("essentialCareFlow.reviewAndConfirm.description")}
        />
        <div className="w-full">
          {/* Participant Details Section */}
          <div className="bg-[#F6F8F9] rounded-[26px] mb-6 p-5 max767:bg-transparent max767:p-0">
            <h2 className="text-base not-italic font-semibold mb-1">
              {translate(
                "New Essential Care Insurance Policy",
                "سياسة تأمين العناية الأساسية الجديدة"
              )}
            </h2>
            <span className="text-sm not-italic font-normal mb-4 block">
              {translate(
                "Review the details below and confirm the payment",
                "راجع التفاصيل أدناه وقم بتأكيد الدفع"
              )}
            </span>

            {familyMembersDetails?.length > 0 && (
              <div className="beneficiaryDetails bg-[#F6F8F9] rounded-[26px] max767:p-0">
                <LifeDynamicTable
                  data={familyMembersDetails?.map((each, i) => {
                    let update = JSON.parse(JSON.stringify(each));
                    update.data = update.data.filter((item) => item.id != 6);
                    if (i == 0) {
                      update.data = update.data.filter((item) => item.id != 5);
                      update = {
                        ...update,
                        tableTitle: "Primary (You)",
                        tableTitle_ar: "تفاصيل المستفيد",
                      };
                    } else {
                      update = {
                        ...update,
                        tableTitle: `Family Member ${i}`,
                        tableTitle_ar: `فرد العائلة ${i}`,
                      };
                    }
                    return update;
                  })}
                />
              </div>
            )}
          </div>
        </div>
        <div className="bg-[#F6F8F9] rounded-[26px] p-5 mb-4 w-full summary-box max767:bg-transparent max767:p-0 max767:mb-6">
          {/* <PromoCode
            promoCode={formState.promoCode}
            setPromoCode={(code) =>
              setFormState((prevState) => ({
                ...prevState,
                promoCode: code,
              }))
            }
            applyPromoCode={applyPromoCode}
            clearPromoCode={() => {
              setFormState({});
              removeLifeFormData({});
            }}
          /> */}

          <LifeInsuranceSummary
            premiumText={{ en: "Total Contribution", ar: "ar Transfer Fees" }}
            totalPremium={essentialCareQuoteData?.TotalPremium || 0}
            tax={essentialCareQuoteData?.Tax}
            premium={essentialCareQuoteData?.Premium || 0}
            quotationNumber={
              essentialCareQuoteData?.QuoteDetails?.QuoteNumber || ""
            }
          />
        </div>
        <div className="w-full max767:py-0 max767:p-0">
          {/* Warning Message */}
          <div className="alertBox yellow flex items-start gap-4 self-stretch border bg-[#FDE9D2] p-4 rounded-[10px] border-solid border-[#FBC98E]">
            <img src={Exclamation} alt="danger img" />
            <span className="text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
              {t("essentialCareFlow.reviewAndConfirm.note")}
            </span>
          </div>

          <CustomCheckbox
            className=""
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            Label={
              <div className="flex flex-col">
                <label
                  htmlFor="agreement"
                  className="text-[#252C32] text-sm not-italic font-normal leading-6 max767:break-all"
                >
                  {declaration?.map((paragraph, index) => (
                    <p className="mb-2" key={index}>
                      {paragraph}
                    </p>
                  ))}
                </label>
              </div>
            }
          />

          <Button
            className={`w-full text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] flex justify-center p-3 rounded-[10px] ${
              isChecked && !isLoading
                ? "bg-[#55C6EF] rounded-[10px] cursor-pointer"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
            isLoading={isLoading}
            label={t(
              "motorInsurance.newMotorInsuranceFlow.paymentSummary.proceed_payment"
            )}
            disabled={!isChecked}
            onSubmit={isLoading ? () => {} : handlePayment}
            post_icon={forwardarrow}
            post_icon_class={"w-6 h-6 ml-[5px] rtl:rotate-180 rtl:mr-2"}
          />
        </div>{" "}
      </div>
    </EssentialCareLayout>
  );
};
export default ReviewAndConfirm;
