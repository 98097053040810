import React, { useMemo, useState } from 'react'
import Axios, { AxiosFiles } from '../redux/axiosInstance'
import { apiEndpoints } from '../api'
import { toast } from 'react-toastify'
import useLanguageTranslate from './useLanguageTranslate'
import useFileDownload from './useFileDownload'
// useAppleWalletApi.js



const useAppleWalletApi = (policyNumber) => {
    const [isLoading, setIsLoading] = useState(false);
    const translate = useLanguageTranslate();

    const generateAppleWalletFileId = async (payload = {}) => {
        setIsLoading(true);
        try {
            let response = await Axios.post(apiEndpoints.apppleWalletGenerateFileId, payload);
            return response?.data
        }
        catch (e) {
            setIsLoading(false)
            toast.error(translate(
                e.response.data.ErrorMessageEn,
                e.response.data.ErrorMessageAr
            ))
            return e?.response
        }
    };

    const fetchPkPassLink = async (file_id) => {
        setIsLoading(true);
        let apiPayload = {
            ip: "",
            validity: 7200,
            agent: "Postman",
            reference: "nothing",
            file_id
        };
        try {
            let response = await AxiosFiles.post(apiEndpoints.apppleWalletGeneratePass, apiPayload);
            return response?.data;
        } catch (e) {
            toast.error(translate("Failed to generate Wallet pass", "فشل في إنشاء بطاقة المحفظة"));
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownload = async (url, filename) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const blob = await response.blob();

            // Ensure filename ends with `.pkpass`
            const sanitizedFilename = filename.endsWith('.pkpass') ? filename : `${filename}.pkpass`;

            // Create a temporary URL for the Blob
            const blobUrl = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = sanitizedFilename;
            document.body.appendChild(link);
            link.click();

            // Clean up the temporary URL
            URL.revokeObjectURL(blobUrl);
            document.body.removeChild(link);
        } catch (error) {
            console.error("Failed to download the file:", error);
        }
    };

    const AppleWalletApiTrigger = async () => {
        let payload = {
            "policyNumber": policyNumber
        }
        let data = await generateAppleWalletFileId(payload)
        if (data?.status ==400 || !data) return
        console.log(data, 'some wallet file id generate response')
        let pkpassResponse = await fetchPkPassLink(data?.file_id)
        const pkpassLink = pkpassResponse?.data?.link;
        if (!pkpassLink) {
            toast.error(translate("Failed to retrieve Wallet pass link", "فشل في استرداد رابط البطاقة"));
            return;
        }

        handleDownload(pkpassLink, 'Wallet');
    };

    const isAppleDevice = () => {
        return /iPhone|iPad|Macintosh|Mac OS X/i.test(navigator.userAgent);
    };


    return {
        isLoading,
        AppleWalletApiTrigger,
        isAppleDevice
    };
};

export default useAppleWalletApi;