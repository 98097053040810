import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import {  motorApiEndpoints } from "../../../api";
import { newMotorFormSlice } from "../newMotorFormSlice";
import { useSelector } from "react-redux";
import { toast } from 'react-toastify';

const initialState = {
  calculatePremiumResponse: [],
  isLoading: false,
  success: null,
  error: null,
};

// const motorFormData = useSelector(selectNewMotorForm)
// const brandNewData = useSelector(selectNewMotorForm)?.[CAR_INFO_BRAND_NEW];


export const calculatePremium = createAsyncThunk(
    "calculatePremium",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling calculate premium...")
            const response = await Axios.post(`${motorApiEndpoints.calculatePremium}`, payload, {
              timeout: 60000, // Set timeout to 5 seconds (5000 ms)
            });
            console.log("Calculate Premium response is: ", response.data)

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const calculatePremiumSlice = createSlice({
  name: "Calculate Premium Slice",
  initialState,
  reducers: {
    clearCalculatePremiumError: (state) => {
      state.error = null;
    },
    clearCalculatePremiumState: () => initialState // This resets the state to its initial value
  },
  extraReducers: (builder) => {
    // FETCH  UTILS API
    builder.addCase(calculatePremium.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(calculatePremium.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.calculatePremiumResponse = action?.payload?.PolicyPlanlist;
    });
    builder.addCase(calculatePremium.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.error?.message;
    });
  },
});

// SELECTORS
export const getCalculatedPremiumResponse = (store) => store?.calculatePremium.calculatePremiumResponse;
export const getCalculatedPremiumLoader = (store) => store?.calculatePremium.isLoading;
export const getCalculatedPremiumSuccess = (store) => store?.calculatePremium.success;


// ACTIONS TO DISPATCH
export const { clearCalculatePremiumError, clearCalculatePremiumState } = calculatePremiumSlice.actions;

export default calculatePremiumSlice.reducer;
