import React, { useEffect, useMemo, useState } from "react";
import "./Header.css";
import NotificationWindow from "../../../pages/Notifications/Notifications";
import faCog from "../../../assets/images/settings.svg";
import faBell from "../../../assets/images/notifications_none.svg";
import faGlobe from "../../../assets/images/language.svg";
import faBook from "../../../assets/images/dynamic_feed.svg";
import faExclamationTriangle from "../../../assets/images/report_problem.svg";
import closeicon from "../../../assets/images/close.svg";
import logo from "../../../assets/images/Logo.svg";
import smLogo from "../../../assets/images/sm-logo.svg";
import sideBrOpen from "../../../assets/images/sidebarclose.svg";
import sideBrClose from "../../../assets/images/sidebar-open.svg";
import headerbackarrow from "../../../assets/images/back-arrow-left.svg";
import "./Header.css";
import { selectCommonApiContent } from "../../../redux/features/commonApiDataSlice";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "../dropdown/dropdown";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Stepper from "../stepper/stepper";
import { selectLoginTokenInfo } from "../../../redux/features/loginRegisterSlice";
import faprofile from "../../../assets/images/account_circlegray.svg";
import logout from "../../../assets/images/logout.svg";
import userin from "../../../assets/images/account_circle.svg"
import {
  selectUtilsWhole,
  toggleSidenav,
} from "../../../redux/features/toolsSlice";
import useScreenResolution from "../../../hooks/useScreenResolution";
import { selectUnreadNotifications } from "../../../redux/features/notificationsSlice";
import { selectProfileData } from "../../../redux/features/profileSlice";
import SignIn from "../../../assets/images/account_circlegray.svg";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { claimPaths, emailOrMobileChangePaths, lifeInsuranceOverviewPaths, motorInsurancePaths, navigateToHomePaths, policyOverviewPaths } from "./data";

const settingsMenuItems = [
  {
    showIfIsLoggedIn: true,
    icon: faprofile,
    text_en: "Profie Settings",
    text_ar: "إعدادات الملف الشخصي",
    link: "/profile-settings",
    is_internal: true,
  },
  {
    icon: faGlobe,
    text_en: "Switch to Arabic (العربية)",
    text_ar: "Switch to English",
    link: "",
    handleLangChng: true,
  },
  { icon: faBook, text_en: "Learning Center", text_ar: "مركز التعلم", hide: true },
  {
    icon: faExclamationTriangle,
    text_en: "Report a bug",
    text_ar: "الإبلاغ عن مشكلة",
    is_internal: false,
    link: "https://forms.clickup.com/9007063400/p/f/8cdtrb8-18238/FS7Y77PAP59R7KDLBD/help-us-improve",
  },
];

const Header = ({
  className,
  invalidPage=false,
  isPopup = false,
  onCloseModal,
  showBackBtn,
  backClickHandler,
  showStepper,
  activeStep,
  steps,
}) => {
  const navigate = useNavigate();
  const screenResolution = useScreenResolution();
  const translate = useLanguageTranslate()

  const [showSettingsMenu, setShowSettingsMenu] = useState(false);
  const [showNotificationsMenu, setShowNotificationsMenu] = useState(false);

  const headerReducerData = useSelector(selectCommonApiContent);
  const headerData = useMemo(() => {
    if (!headerReducerData) return settingsMenuItems;
    else {
      return [
        ...settingsMenuItems,
        [
          "settings_t_and_conditions",
          "settings_privacy_and_security",
          "settings_fees_and_charges",
        ].map((key) => {
          return {
            image: headerReducerData.attributes[key].icon,
            text_en: headerReducerData.attributes[key].link.label_en,
            text_ar: headerReducerData.attributes[key].link.label_ar,
            is_internal: headerReducerData.attributes[key].link.is_internal,
            link: headerReducerData.attributes[key].link.link,
            hide: headerReducerData.attributes[key].link.hide
          };
        }),
        {
          showIfIsLoggedIn: true,
          icon: logout,
          text_en: "Logout",
          text_ar: "تسجيل الخروج",
          type: "logout",
        },
      ];
    }
  }, [headerReducerData]);

  const toggleSettingsMenu = () => {
    setShowSettingsMenu(!showSettingsMenu);
    if (!showSettingsMenu) setShowNotificationsMenu(false);
  };


  const toggleNotificationsMenu = () => {
    setShowNotificationsMenu(!showNotificationsMenu);
    if (!showNotificationsMenu) setShowSettingsMenu(false);
  };

  const loginTokenInfo = useSelector(selectLoginTokenInfo);

  const { pathname,search } = useLocation();

  const optionalcloseClickHanlder = () => {
    if (pathname?.includes("/new-motor-policy")) {
      navigate("/new-policy");
    }
    else if (
      navigateToHomePaths.some((path) =>
        pathname?.includes(path)
      )
    ) {
      navigate("/");
    } else if (emailOrMobileChangePaths.some((path) => pathname?.includes(path))) {
      navigate("/profile-settings");
    } else if (policyOverviewPaths.some((path) => pathname?.includes(path))) {
      //navigate(`/motor-insurance/policy-overview/${policy_id}`);
      navigate(-1);
    } else if (claimPaths.some((path) => pathname?.includes(path))) {
      navigate("/file-a-claim");
    }
    else if (lifeInsuranceOverviewPaths.some((path) => pathname?.includes(path))) {
      navigate(`/life-insurance/policy-overview${search}&tabType=ManagePolicy`);
    }
    else if (motorInsurancePaths.some((path) => pathname?.includes(path))) {
      // navigate("/motor-insurance/file-a-claim");
      navigate(-1);
    }
    else if ( pathname?.includes("/express-shield")){
       navigate("/new-policy")
    }
    else {
      goToHome();
    }

  };

  const goToHome = () => {
    navigate("/");
  };

  const dispatch = useDispatch();
  const sideBarToggle = () => {
    dispatch(toggleSidenav());
  };
  const sidenavOpen = useSelector(selectUtilsWhole).sidenav_open;
  const unreadNotifications = useSelector(selectUnreadNotifications);
  const profileData = useSelector(selectProfileData);
  return (
    <>
      <nav
        className={`fixed top-0 h-[48px] ${className} inset-x-0 bg-white py-0.5 px-0 text-black font-medium flex justify-between items-center z-10 max767:pt-[22px] max767:pb-[0px] max767:px-0  max767:min-h-0 max767:h-auto border-b-[#E5E9EB] border-b border-solid max767:border-0 max767:shadow-none`}
      >
        <div className=" rtl:mr-[30px] flex justify-center items-center bg-white ipad:w-[115px] ipad:h-7 ipad:pl-2 ipad:ml-2 max767:hidden ipad:w-auto">
          {showBackBtn && (
            <button
              className="bg-navy-blue py-2 rounded mt-32 left-6 rtl:right-4 absolute flex "
              onClick={backClickHandler}
            >
              <img
                src={headerbackarrow}
                alt="back arrow"
                className="mr-2.5 1 rtl:ml-[5px] rtl:rotate-[180deg]"
              />
              <span className="back-text inline max767:hidden">
                {translate("Back", "عودة")}
              </span>
            </button>
          )}
          {!screenResolution.mobile && (
            <img
              onClick={goToHome}
              src={sidenavOpen ? logo : smLogo}
              alt="logo"
              className={
                sidenavOpen
                  ? "logofull w-[135px] h-9 ml-[30px] mt-[-5px]"
                  : "smLogo ml-3"
              }
            />
          )}
          {screenResolution.tablet && !invalidPage && (
            <p onClick={sideBarToggle}>
              {sidenavOpen ? (
                <img src={sideBrOpen} alt="close" className="min-w-6 ml-5" />
              ) : (
                <img src={sideBrClose} alt="open" className="min-w-6 ml-5" />
              )}
            </p>
          )}
        </div>
        {!isPopup ? (
          <div className="flex items-center space-x-4 mr-6 relative max767:w-full max767:justify-between max767:m-0 max767:pl-0 max767:pr-2.5 max767:pb-4  max767:shadow-none max767:border-0">
            {!loginTokenInfo && (
              <button
                className={`${
                  screenResolution.mobile
                    ? "isMobile ml-6 text-xl not-italic font-medium"
                    : "notIsMobile"
                } btn-sign-in bg-[#55C6EF] mt-[-1px] rounded-md inline-flex flex items-center gap-2.5 text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] px-3 py-1 max767:text-sm`}
                onClick={() => navigate("/sign-in")}
              >
                <img src={userin} alt="" className="max767:block hidden" />
                {translate("Sign in", "تسجيل الدخول")}
              </button>
            )}
            {loginTokenInfo && screenResolution.mobile && (
              <h4
                className={`${
                  screenResolution.mobile
                    ? "isMobile ml-6 text-xl not-italic font-medium"
                    : "notIsMobile"
                }`}
              >
                {translate("Welcome", "مرحبًا")}{" "}
                {profileData?.FirstName?.split(' ')?.length
                  ? `${profileData?.FirstName?.split(' ')[0] || ""} `
                  : "User"}
              </h4>
            )}
            <div className="flex gap-2 items-center">
            {/* <div className="relative pt-2"> */}
              {/* <button
                className="focus:outline-none notification-button "
                onClick={toggleNotificationsMenu}
              > */}
                {/* <FontAwesomeIcon icon={faBell} size="lg" /> */}
                {/* <img src={faBell} alt="" className="mr-2" />
                <span className="unread-count bg-[#F76659] text-[#FFEFEB] top-[-11px] absolute inline-flex items-center text-xs not-italic font-semibold leading-4 px-[5px] py-0 rounded-[10px] border-2 border-solid border-white left-[7px]">
                  {unreadNotifications?.length || 0}
                </span>
              </button>
              {showNotificationsMenu && (
                <NotificationWindow onClose={toggleNotificationsMenu} />
              )} */}
            {/* </div> */}
            {!screenResolution.mobile && (
              <div className="relative pt-3">
                <button
                  className="focus:outline-none"
                  onClick={toggleSettingsMenu}
                >
                  {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                  <img
                    src={!profileData ? faCog : SignIn}
                    alt=""
                    className="mr-2 mb-1 rtl:mr-0 rtl:ml-2"
                  />
                </button>
                {showSettingsMenu && (
                  <Dropdown
                    items={headerData.flat()}
                    closeDropdown={toggleSettingsMenu}
                  />
                )}
              </div>
            )}
            </div>
          </div>
        ) : (
          <>
            {showStepper && <Stepper activeStep={activeStep} steps={steps} />}
              <div className={`flex items-center justify-center ${invalidPage ? 'hideclose': '' }`}>
                <button
                  className="bpy-2 mr-10 px-0 py-[15px] rtl:ml-10 rounded flex right-[26px] top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:hidden"
                  onClick={
                    onCloseModal ? onCloseModal : optionalcloseClickHanlder
                  }
                >
                  <img src={closeicon} alt="Close Icon" className="mr-2" />
                  {translate("Close", "إغلاق")}
                </button>
              </div>
            
          </>
        )}
      </nav>
      <div className="placeholder h-[0px] max"></div>
    </>
  );
};

export default Header;
