import React, { useState } from "react";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import FileClaimMotorLayout from "./fileClaimMotorLayout";
import useFileClaimFlow from "../../../hooks/useFileClaimFlow";
import MotorPolicyOptionsCard from "../../../components/motorPolicyOptionsCard/motorPolicyOptionsCard";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { PROPERTY_DAMAGE } from "./NonSolidarityUser/data";
import { useTranslation } from "react-i18next";

let cashOption = {
  key: "cash",
  title_en: "Receive Cash",
  title_ar: "تلقي النقود",
  description_en: "I want to receive cash and I will repair my vehicle",
  description_ar: "أريد الحصول على نقود وسأصلح سيارتي",
};
const planOptions = [
  {
    key: "repair",
    title_en: "Repair my vehicle",
    title_ar: "إصلاح سيارتي",
    description_en: "I want solidarity to repair my car",
    description_ar: "أريد التضامن لإصلاح سيارتي",
  },
  cashOption,
];

const propertyDamagePlanOptions = [
  {
    key: "repair",
    title_en: "Repair my property",
    title_ar: "إصلاح ممتلكاتي",
    description_en: "I want solidarity to repair my property",
    description_ar: "أريد التضامن لإصلاح ممتلكاتي",
  },
  {
    key: "cash",
    title_en: "Receive Cash",
    title_ar: "تلقي النقود",
    description_en: "I want to receive cash and I will repair my property",
    description_ar: "أريد الحصول على نقود وسأصلح ممتلكاتي",
  }
];

function RepairOrCashPage({
  selectedOption,
  setSelectedOption,
  selectedInsuredType,
}) {
  const { t } = useTranslation();

  //const [selectedOption, setSelectedOption] = useState(null);
  const translate = useLanguageTranslate();
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  const { handleNextSubstep } = useFileClaimFlow();
  const continueClickHanlder = () => {
    handleNextSubstep();
  };
  return (
    <FileClaimMotorLayout
      btn_disabled={!selectedOption}
      btn_onClick={continueClickHanlder}
    >
      <div className="mx-auto bg-white max767:p-5 w-[500px] max-w-[100%]">
        <div className="max767:hidden">
          <TitleDescription
            title={t(
              "motorInsurance.fileAClaimFlow.soilidarityUserRepairOrCashPage.title"
            )}
            description={t(
              "motorInsurance.fileAClaimFlow.soilidarityUserRepairOrCashPage.description"
            )}
          />
        </div>
        <div className="max767:block hidden max767:mb-6 max767:mt-20">
          <TitleDescription
            title="Resolve Your Claim"
            description={"How would you like to proceed with your claim?"}
          />
        </div>
        <div>
          {(selectedInsuredType == PROPERTY_DAMAGE
            ? propertyDamagePlanOptions
            : planOptions
          ).map((plan) => (
            <MotorPolicyOptionsCard
              key={plan.key}
              title={translate(plan.title_en, plan.title_ar)}
              description={translate(plan.description_en, plan.description_ar)}
              isSelected={selectedOption == plan.key}
              onSelect={() => handleOptionSelect(plan.key)}
            />
          ))}
        </div>
      </div>
    </FileClaimMotorLayout>
  );
}

export default RepairOrCashPage;
