import React, { useEffect, useState } from "react";
import logo from "../../../../assets/images/Logo.svg";
import { useTranslation } from "react-i18next";
import PaymentSuccess from "./paymentSuccess";
import benefitlogo from "../../../../assets/images/BenefitLogo1.svg";
import Creditlogo from "../../../../assets/images/visalogo.svg";
import Applepay from "../../../../assets/images/pay.svg";
import Flooss from "../../../../assets/images/floos.svg";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";


const PaymentMethods = () => {
  const { t } = useTranslation();
  const translate = useLanguageTranslate()
  const [isResult, setIsResult] = useState(false)

  const paymentData = {
    name: "SAYED JAWAD SADIQ JAWAD SADEQ",
    name_ar:"سيد جواد صادق جواد صادق",
    quotationNumber: "Q241501010107001-01",
    planName: "New Motor Insurance",
    planName_ar:"خطة مرنة جديدة",
    description: "Flexi Plan for 516914 Car plate number",
    description_ar:"للتأمين على السيارات لرقم لوحة السيارة 516914",
    paymentMethods: [
      { name: "Debit Card",name_ar:"بطاقة الخصم", icon: benefitlogo },
      { name: "Credit Card",name_ar:"بطاقة إئتمان", icon: Creditlogo },
      { name: "Apple Pay",name_ar:"آبل باي", icon: Applepay },
      { name: "Flooss",name_ar:"تدفق", icon: Flooss },
    ],
  };

  useEffect(()=>{
    setTimeout(()=>{
      setIsResult(true)
    },[3000])
  },[])

  return (
    <div className="flex justify-center items-center ">
      {!isResult ? (
      <div className="p-12 w-[760px] max-w-[100%] mt-[7%] mx-auto bg-[#F6F8F9] rounded-[26px] max767:pt-8 max767:mt-0 max767:p-5 max767:w-[100%] max767:rounded-none ipad:mt-[14%]">
        <div className="flex justify-between items-center mt-0 mb-12 max767:grid max767:mb-[24px]">
          <h2 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] max767:w-full  max767:order-2 ">{t(
                "motorInsurance.newMotorInsuranceFlow.paymentMethods.title"
              )}</h2>
          <img src={logo} alt="Solidarity Logo" className="h-10 max767:flex max767:order-1 max767:mb-[16px]" />
        </div>

        <div className="mb-4 flex flex-col items-start self-stretch border bg-white rounded-[10px] ">
          <h3 className="text-[#1A2024] text-base not-italic font-medium leading-6 border-b px-6 py-4 w-full">{translate(paymentData.name,paymentData.name_ar)}</h3>
          <div className="border-b w-full px-6 py-4">
            <p className=" text-[#5B6871] text-base not-italic font-normal leading-6">{t(
                "motorInsurance.newMotorInsuranceFlow.paymentMethods.quotationNumber"
              )}</p>
            <p className="text-[#1A2024] text-base not-italic font-medium leading-6">{paymentData.quotationNumber}</p>
          </div>
          <div className="border-b w-full px-6 py-4">
            <p className="text-[#5B6871] text-base not-italic font-normal leading-6">{t(
                "motorInsurance.newMotorInsuranceFlow.paymentMethods.description"
              )}</p>
            <p className="text-[#1A2024] text-base not-italic font-medium leading-6">{translate(paymentData.planName,paymentData.planName_ar)}</p>
            <p className="text-[#1A2024] text-base not-italic font-medium leading-6">{translate(paymentData.description,paymentData.description_ar)}</p>
          </div>
        </div>

        <div className="flex gap-2 mb-12 ipad:items-center max767:flex max767:flex-col max767:items-center max767:gap-2 max767:self-stretch ipad:gap-2 ipadself-stretch max767:mb-[24px]">
          {paymentData.paymentMethods.map((method, index) => (
            <div
              key={index}
              onClick={() => setIsResult(true)}
              className="flex w-40 min-h-[128px] flex-col justify-center items-start gap-4 border bg-white p-6 rounded-[10px] border-solid border-[#DDE2E4] hover:shadow-lg transition-shadow max767:w-[100%] cursor-pointer max767:min-h-[84px]"
            >
              <div className="max767:flex max767:w-full max767:justify-between max767:items-center">
              <img
                src={method.icon}
                alt={`${method.name} Icon`}
                className="w-max  mb-2 max767:order-2"
              />
              <span className="text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] max767:order-1">{translate(method.name,method.name_ar)}</span>
            </div>
            </div>
          ))}
        </div>

        <footer className=" ">
          <p className="text-[#5B6871] text-xs not-italic font-normal leading-[150%]">{t(
                "motorInsurance.newMotorInsuranceFlow.paymentMethods.footerText1"
              )} </p>
          <p className="text-[#5B6871] text-xs not-italic font-normal leading-[150%]">
          {t(
                "motorInsurance.newMotorInsuranceFlow.paymentMethods.footerText2"
              )}
          </p>
        </footer>
      </div>
       ): (
        <PaymentSuccess />
      )}
    </div>
  );
};

export default PaymentMethods;
