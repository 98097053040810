
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BackdropModal from "../../../../../components/common/modal/backdropModal";
import Button from "../../../../../components/common/Button/Button";
import updatecardlogo from "../../../../../assets/images/updatecardgray.svg"
import paymentmethod from "../../../../../assets/images/Paymentmethodicon.svg"
import { toast } from "react-toastify";
import PaymentCardDetailForm from "../changePaymentMethod/paymentCardDetailForm";
import useValidation from "../../../../../hooks/useValidation";
import { PAYMENT_FORM_VALIDATIONS } from "../changePaymentMethod/data";
import useLanguageTranslate from "../../../../../hooks/useLanguageTranslate";
const UpdatePaymentMethodModal = ({ show, setShow }) => {
    const { errors, validateFields } = useValidation();

    const validateForm = (data) => {
        let isValid = validateFields(PAYMENT_FORM_VALIDATIONS, data ? data : formData)
        return isValid
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let update = { ...formData, [name]: value }
        setFormData(update);
        if (errors?.[name]) {
            validateForm(update)
        }
    };

    const [formData, setFormData] = useState({})
    const { t } = useTranslation();
    const translate = useLanguageTranslate()
    const onClose = () => {
        setShow((state) => !state);
        setFormData({})
    } 
    const changeHandler = () => {
        if(!validateForm()) return;
        onClose()
        toast.success(translate("Your payment method successfully updated","تم تحديث طريقة الدفع الخاصة بك بنجاح"))
    }
    return (
        <BackdropModal onClose={onClose} show={show}>
            <p className="flex mb-5 max767:mt-[-13px]"><img src={updatecardlogo} alt="exit_to_app" className="exit_to_app  mt-[-40px] " /></p>
            <div className="content mb-4 max767:mt-[0px]">
                <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mb-3 max767:text-xl">
                   {t("lifeInsurance.updatePaymentMethod.popup_title")}
                </h1>
     
                <PaymentCardDetailForm errors={errors||{}} handleChange={handleChange} setFormData={setFormData} formData={formData} />
                <div className="mt-8 flex items-start gap-3 self-stretch">
                    <Button onSubmit={onClose} label= {t("lifeInsurance.updatePaymentMethod.cancel_btn_label")} className="flex flex-col items-center gap-2.5 flex-[1_0_0] px-4 py-2 border rounded-[10px] border-solid border-[#DDE2E4] bg-white text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]" />
                    <Button onSubmit={changeHandler} label={t("lifeInsurance.updatePaymentMethod.change_btn_label")} className="flex flex-col items-center gap-2.5 flex-[1_0_0] px-4 py-2 border rounded-[10px] border-solid border-[#DDE2E4] bg-[#55C6EF] text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]" />

                </div>
            </div>

        </BackdropModal>
    );
};

export default UpdatePaymentMethodModal;
