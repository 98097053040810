
import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import PaymentCardDetailForm from "./paymentCardDetailForm";
import BackdropModal from "../../../../../components/common/modal/backdropModal";
import Button from "../../../../../components/common/Button/Button";
import exit_to_app from "../../../../../assets/images/surrendergray.svg"
import paymentmethod from "../../../../../assets/images/Paymentmethodicon.svg"
import { toast } from "react-toastify";
import useValidation from "../../../../../hooks/useValidation";
import { PAYMENT_FORM_VALIDATIONS } from "./data";
import useLanguageTranslate from "../../../../../hooks/useLanguageTranslate";
const ChangePaymentMethodModal = ({ show, setShow }) => {
    const { errors, validateFields } = useValidation();
    const translate = useLanguageTranslate();

    const validateForm = (data) => {
        let isValid = validateFields(PAYMENT_FORM_VALIDATIONS, data ? data : formData)
        return isValid
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let update = { ...formData, [name]: value }
        setFormData(update);
        if (errors?.[name]) {
            validateForm(update)
        }
    };

    const [formData, setFormData] = useState({})
    const { t } = useTranslation();
    const onClose = () => {
        setShow((state) => !state);
        setFormData({})
    }
    const changeHandler = () => {
        if (!validateForm()) return;
        onClose()
        toast.success(translate("Payment method successfully changed","تم تغيير طريقة الدفع بنجاح"))
    }

    return (
        <BackdropModal onClose={onClose} show={show} className="changepayment-popup w-[700px] max-w-[100%]">
            <p className="flex mb-5 max767:mt-[-13px]"><img src={exit_to_app} alt="exit_to_app" className="exit_to_app  mt-[-40px] " /></p>
            <div className="content mb-4 max767:mt-[0px]">
                <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mb-3 max767:text-xl">
                    {t("lifeInsurance.changePaymentMethod.popup_title")}
                </h1>
                <div className="currentCardInfo">
                    <p className="text-[#1A2024] text-base not-italic font-medium leading-[26px] tracking-[-0.16px]">
                        {t("lifeInsurance.changePaymentMethod.currentPaymentMethod")}
                    </p>
                    <div className=" flex justify-start items-start gap-4 self-stretch bg-[#F6F8F9] p-4 rounded-[10px] mb-8 w-[600px] max-w-[100%]">
                        <img src={paymentmethod} alt="card icon" className="cardIcon" />
                        <div>
                            <p className="text-[#252C32] text-center text-base not-italic font-normal leading-6 tracking-[-0.16px]">
                                {t("lifeInsurance.changePaymentMethod.masterCardEndLabel")} 1234
                            </p>
                            <small className="text-[#5B6871] text-center text-sm not-italic font-normal leading-5">
                                {t("lifeInsurance.changePaymentMethod.expiryLabel")} 06/2024
                            </small>
                        </div>
                    </div>
                </div>
                <p className="text-[#1A2024] text-base not-italic font-medium leading-[26px] tracking-[-0.16px]">
                    {t("lifeInsurance.changePaymentMethod.newPaymentMethodLabel")}
                </p>
                <PaymentCardDetailForm errors={errors || {}} handleChange={handleChange} setFormData={setFormData} formData={formData} />
                <div className="mt-8 flex items-start gap-3 self-stretch">
                    <Button onSubmit={onClose} label={t("lifeInsurance.changePaymentMethod.cancel_btn_label")} className="flex flex-col items-center gap-2.5 flex-[1_0_0] px-4 py-2 border rounded-[10px] border-solid border-[#DDE2E4] bg-white text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]" />
                    <Button onSubmit={changeHandler} label={t("lifeInsurance.changePaymentMethod.Change_btn_label")} className="flex flex-col items-center gap-2.5 flex-[1_0_0] px-4 py-2 border rounded-[10px] border-solid border-[#DDE2E4] bg-[#55C6EF] text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]" />
                </div>
            </div>

        </BackdropModal>
    );
};

export default ChangePaymentMethodModal;
