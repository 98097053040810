import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import { signUpApiEndpoints } from "../../../api";

const initialState = {
  isLoading: false,
  error: null,
  verificationData: null,
};

export const verifySession = createAsyncThunk(
  "verifySession",
  (queryString, { rejectWithValue }) => {
    return Axios.post(`${signUpApiEndpoints?.verifySession}`, {
      cpr: queryString,
    })
      .then((response) => response.data)
      .catch((error) => {
        return rejectWithValue(error.response?.data || "Something went wrong");
      });
  }
);

const verifySessionSlice = createSlice({
  name: "verifySession",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifySession.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.verificationData = null;
      })
      .addCase(verifySession.fulfilled, (state, action) => {
        state.isLoading = false;
        state.verificationData = action.payload.verification;
      })
      .addCase(verifySession.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.verificationData = null;
      });
  },
});

export default verifySessionSlice.reducer;
