import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosStrapi } from "../../axiosInstance";
import { strapiApiEndPoints } from "../../../api";
import { mockApiCall } from "../../../utils";
import { MockbuyOnlineExpressFlow } from "../../../mocks/buyOnlineExpressFlow";
import { toast } from "react-toastify";

const initialState = {
    questionnaire: null,
    plans : null,
    isLoading: false,
    error: null,
};

export const fetchCmsQuestionniare = createAsyncThunk(
    "fetchCmsQuestionniare",
    (queryString, { rejectWithValue }) => {
        return AxiosStrapi.get(`${strapiApiEndPoints.buyOnlineQuestionniare}`).then((res) => res.data);
       // return mockApiCall(MockbuyOnlineExpressFlow.questionnaire)
    }
);
export const fetchCmsExpressOnlinePlans = createAsyncThunk(
    "fetchCmsExpressOnlinePlans",
    (queryString, { rejectWithValue }) => {
        return AxiosStrapi.get(`${strapiApiEndPoints.buyOnlinePlans}`).then((res) => res.data);
      //  return mockApiCall(MockbuyOnlineExpressFlow.plans)
    }
);

export const expressShieldFlowStrapiCmsSlice = createSlice({
    name: "expressShieldFlowStrapiCmsSlice",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        // FETCH  fetchCmsQuestionniare API
        builder.addCase(fetchCmsQuestionniare.pending, (state, action) => {
            if (!state.data) {
                state.isLoading = true;
            }
        });
        builder.addCase(fetchCmsQuestionniare.fulfilled, (state, action) => {
            state.isLoading = false;
            state.questionnaire = action.payload?.data?.attributes;
        });
        builder.addCase(fetchCmsQuestionniare.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error?.message;
            toast.error("Failed to load Questionnaire")
        });
        // FETCH  fetchCmsExpressOnlinePlans API
        builder.addCase(fetchCmsExpressOnlinePlans.pending, (state, action) => {
            if (!state.data) {
                state.isLoading = true;
            }
        });
        builder.addCase(fetchCmsExpressOnlinePlans.fulfilled, (state, action) => {
            state.isLoading = false;
            state.plans = action.payload?.data?.attributes;
        });
        builder.addCase(fetchCmsExpressOnlinePlans.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error?.message;
            toast.error("Failed to load Plans")

        });
    },
});

export const selectQuestionnairePageCms = (store) => store?.expressShieldFlowStrapiCms?.questionnaire;
export const selectPlansPageCms = (store) => store?.expressShieldFlowStrapiCms?.plans;
export const selectExpressFlowCmsLoadingState = (store) => store?.expressShieldFlowStrapiCms?.isLoading;

export const selectNotificationsDataLoader = (store) => store?.notifications?.isLoading;


export default expressShieldFlowStrapiCmsSlice.reducer;
