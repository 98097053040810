export const PAYMENT_FORM_VALIDATIONS = [
    {
        name: "card_number",
        validations: ['required']
    },
    {
        name: "month_year",
        validations: ['required']
    },
    {
        name: "cvv",
        validations: ['required']
    },
    {
        name: "holder_name",
        validations: ['required']
    },
]
