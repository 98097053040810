import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import { signUpApiEndpoints } from "../../../api";

const initialState = {
  customerDetails: {
    email: "",
    username: "",
    cpr: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
  },
  isLoading: false,
  error: null,
};

export const createCustomerAccount = createAsyncThunk(
  "createCustomerAccount",
(queryString, { rejectWithValue }) => {
  return Axios.post(`${signUpApiEndpoints?.createAccount}`, queryString)
    .then((res) => res.data)
    .catch((error) => {
      return rejectWithValue(error.response?.data || "Something went wrong");
    });
}
);

const createCustomerAccountSlice = createSlice({
  name: "createCustomerAccount",
  initialState,
  reducers: {
    setCustomerDetails: (state, action) => {
      state.customerDetails = { ...state.customerDetails, ...action.payload };
    },
    resetCustomerDetails: (state) => {
      state.customerDetails = initialState.customerDetails;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCustomerAccount.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCustomerAccount.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createCustomerAccount.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});


export const { setCustomerDetails, resetCustomerDetails } =
  createCustomerAccountSlice.actions;

export const selectIsLoading = (state) => state.createCustomerAccount.isLoading;


export default createCustomerAccountSlice.reducer;
