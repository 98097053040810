import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Input from "../../../components/common/Input/Input";
import Button from "../../../components/common/Button/Button";
import "./SignupPage.css";
import { signupData } from "./data";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import TelInput from "../../../components/common/telInput/telInput";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginRegister } from "../../../redux/features/loginRegisterSlice";
import { useTranslation } from "react-i18next";
import CprExistsPopup from "../cprExistsFlow/cprExistsPopup";
import {
  sendOtp,
  selectOtpLoading,
  selectOtpError,
} from "../../../redux/features/sendOtpSlice";
import { toast } from "react-toastify";
import useScreenResolution from "../../../hooks/useScreenResolution";
import BackBtnWithLabel from "../../../components/common/backBtnWithLabel/backBtnWithLabel";
import {
  checkCprExists,
  selectCheckCPRExistsLoading,
  setSignupDetails,
} from "../../../redux/features/signUp/signUpSlice";
import Loader from "../../../components/common/Loader/Loader";
// import Loader from "../../../components/common/Loader/Loader";

const SignupPage = ({ onSuccess }) => {
  const { mobile } = useScreenResolution();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const cprLoading = useSelector(selectCheckCPRExistsLoading);
  const otpLoading = useSelector(selectOtpLoading);

  const isLoading = cprLoading || otpLoading;

  // const success = useSelector(selectOtpSuccess);
  const [showCPRExistsPopup, setShowCPRExistsPopup] = useState(false);
  const [formData, setFormData] = useState({
    cprNumber: "",
    email: "",
    mobile: "",
  });
  const [errors, setErrors] = useState({});
  const formSubmitHandler = async (e) => {
    e?.preventDefault();
    if (validateForm()) {
      const { cprNumber } = formData;

      const cprResponse = await dispatch(checkCprExists(cprNumber));
      dispatch(
        setSignupDetails({
          cprNumber: formData.cprNumber,
          mobile: formData.mobile,
          email: formData.email,
        })
      );

      if (cprResponse?.payload?.userExist) {
        toggleCPRexistsPopup();
      } else {
        const response = await dispatch(
          sendOtp(`${formData?.countryCode || 973}${formData?.mobile}`)
        );
        if (response?.payload?.smsStatus =="MESSAGE_SENT") {
          onSuccess();
        } else if (response?.payload?.smsStatus =="MESSAGE_NOT_SENT") {
          toast.error(
            translate(
              "OTP not sent. Please try again",
              "لم يتم إرسال OTP. الرجاء المحاولة مرة أخرى"
            )
          );
        } else {
          toast.error(
            translate("Please try again", "الرجاء المحاولة مرة أخرى")
          );
        }
      }
    }
  };

  const response = signupData;
  const translate = useLanguageTranslate();

  const toggleCPRexistsPopup = () => {
    setShowCPRExistsPopup(!showCPRExistsPopup);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    console.log(value);
    if (name =="mobile") {
      newValue = value.replace(/\D/g, ""); // Remove non-digit characters
      if (newValue.length > 8) {
        newValue = newValue.substring(0, 8); // Limit to 8 digits
      }
    }

    setFormData((prev) => ({ ...prev, [name]: newValue }));
    validateField(name, newValue);
    // dispatch(setSignupDetails({ [name]: value }));
  };

  const validateField = (fieldName, value) => {
    let newError = "";

    switch (fieldName) {
      case "cprNumber":
        if (!value) {
          newError = t("validation.required");
        }
        break;
      case "email":
        if (!value) {
          newError = t("validation.required");
        } else if (!/\S+@\S+\.\S+/.test(value)) {
          newError = t("validation.invalidEmail");
        }
        break;
      case "mobile":
        if (!value) {
          newError = t("validation.required");
        } else if (!/^\d{8}$/.test(value)) {
          newError = t("validation.invalidMobile");
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: newError }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.cprNumber && formData.cprNumber?.length != 9) {
      newErrors.cprNumber = t("validation.cpr_number");
    }
    if (!formData.email) {
      newErrors.email = t("validation.required");
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = t("validation.invalidEmail");
    }
    if (!formData.mobile) {
      newErrors.mobile = t("validation.required");
    } else if (!/^\d{8}$/.test(formData.mobile)) {
      newErrors.mobile = t("validation.invalidMobile");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length ==0;
  };

  const loginRegisterData = useSelector(selectLoginRegister);
  console.log(formData, "some form data");
  return (
    <>
      {isLoading && (
        <Loader className=" w-10 h-10 fixed left-[50%] top-[50%]" />
      )}
      <div className="sign_in_page flex align-center h_100vh justify-center items-center">
        <div className="right_cont signup-right-content h_100 block justify-center items-center w-[500px] max-w-[100%] max767:p-4 max767:mt-28 ipad:mt-28">
          <div className="form-division">
            {mobile ? (
              <div className="content">
                <BackBtnWithLabel label={translate("Sign up", "التسجيل")} />
                <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] max767:text-[28px] max767:font-semibold">
                  {translate("Let's Get Started", "لنبدأ")}
                </h1>
                <p className="max767:mb-6 max767:text-black max767:text-sm max767:not-italic max767:font-normal">
                  {" "}
                  {translate(
                    "Enter your CPR or CR number",
                    "أدخل الرقم الشخصي (CPR) أو رقم السجل التجاري (CR)"
                  )}
                </p>
              </div>
            ) : (
              <div className="content">
                <h1 className="text-center max767:text-left text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px]">
                  {translate(
                    loginRegisterData?.create_account_title?.title_en,
                    loginRegisterData?.create_account_title?.title_ar
                  )}
                </h1>
                <div className="flex text-center w-full">
                  <p className="text-center mb-6 max767:text-left w-full">
                    {translate(
                      "Already have an account ?",
                      "هل لديك حساب مسبق؟"
                    )}{" "}
                    <Link
                      to="/sign-in"
                      className="text-[#00384D] text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline ml-[5px]"
                    >
                      {" "}
                      {t("signUpFlow.signUpPage.sign_in_label")}
                    </Link>
                  </p>
                </div>
              </div>
            )}

            <form onSubmit={formSubmitHandler}>
              <Input
                label={t("signUpFlow.signUpPage.cpr_input_label")}
                type="number"
                handleChange={handleChange}
                name="cprNumber"
                value={formData.cprNumber}
                error={errors.cprNumber}
                data-testid="cprNumber"
              />
              <Input
                label={t("signUpFlow.signUpPage.email_input_label")}
                type="text"
                handleChange={handleChange}
                name="email"
                value={formData.email}
                error={errors.email}
                data-testid="email"
              />

              <TelInput
                name="mobile"
                value={formData.mobile}
                error={errors.mobile}
                onChange={handleChange}
                label={t("signUpFlow.signUpPage.mobile_input_label")}
                className="mobilenumber px-4 py-3 max767:w-[50%] w-[97%] border-r-0 border-0 rounded-[9px] h-[46px] "
                data-testid="mobile"
              />
              <div className="terms_cont flex align-middle max767:block hidden">
                <p className="terms_cont-title mt-0 text-[#1A2024] rtl:text-right rtl:hidden text-center text-base not-italic font-normal leading-6 tracking-[-0.16px]">
                  {translate(
                    loginRegisterData?.accept_terms_label?.title_en,
                    loginRegisterData?.accept_terms_label?.title_ar
                  )}
                  <a
                    href="https://www.solidarity.com.bh/termsofuse"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#00384D] text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline ml-[5px]"
                  >
                    {" "}
                    {translate(
                      loginRegisterData?.terms_conditions_link?.label_en,
                      loginRegisterData?.terms_conditions_link?.label_ar
                    )}{" "}
                  </a>{" "}
                  {translate("and", "و")}
                  <a
                    href="https://www.solidarity.com.bh/privacyandsecurity"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-[#00384D] text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline ml-[5px]"
                  >
                    {" "}
                    {translate(
                      loginRegisterData?.privacy_policy_link?.label_en,
                      loginRegisterData?.privacy_policy_link?.label_ar
                    )}
                  </a>
                </p>
              </div>
              <Button
                type="submit"
                className="primary-button mt-6"
                label={translate("Next", "التالي")}
              />
            </form>

            <div className="terms_cont flex align-middle max767:hidden">
              <p className="terms_cont-title mt-0 text-[#1A2024] rtl:text-right text-center text-base not-italic font-normal leading-6 tracking-[-0.16px]">
                {translate(
                  loginRegisterData?.accept_terms_label?.title_en,
                  loginRegisterData?.accept_terms_label?.title_ar
                )}
                <a
                  href=" https://www.solidarity.com.bh/termsofuse"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#00384D] text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline ml-[5px]"
                >
                  {" "}
                  {translate(
                    loginRegisterData?.terms_conditions_link?.label_en,
                    loginRegisterData?.terms_conditions_link?.label_ar
                  )}{" "}
                </a>{" "}
                {translate("and", "و")}
                <a
                  href="https://www.solidarity.com.bh/privacyandsecurity"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#00384D] text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline ml-[5px]"
                >
                  {" "}
                  {translate(
                    loginRegisterData?.privacy_policy_link?.label_en,
                    loginRegisterData?.privacy_policy_link?.label_ar
                  )}
                </a>
              </p>
            </div>
          </div>
        </div>
        {
          <CprExistsPopup
            show={showCPRExistsPopup}
            onClose={toggleCPRexistsPopup}
            onSuccess={onSuccess}
          />
        }
      </div>
    </>
  );
};

export default SignupPage;
