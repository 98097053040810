import React from 'react';
import FormTabs from '../../../../components/common/tabs/formTabs';

const OwnDamage = ({ option, updateOptionValues }) => {
  return (
    <div className="mb-4">
      <div className="flex space-x-2 mb-2">
      <FormTabs
          tabs={option.sumInsuredType}
          activeTab={option.selectedSumInsuredType}
          onTabClick={(tabType) =>
            updateOptionValues(option.code, { selectedSumInsuredType: tabType }, true)
          }
        />
      </div>
    </div>
  );
};
export default OwnDamage;