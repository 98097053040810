import React, { useState, useRef } from "react";
import removefile from "../../../../../assets/images/Xclose.svg";
import multiplefile from "../../../../../assets/images/add_to_photos.svg";
import Button from "../../../../../components/common/Button/Button";

const FileUpload = () => {
  const [files, setFiles] = useState([]);
  const [inputVisible, setInputVisible] = useState(true);
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    const newFiles = event.target.files;
    setFiles((prevFiles) => [...prevFiles, ...Array.from(newFiles)]);
    if (newFiles.length > 0) {
      setInputVisible(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleRemoveFile = (index) => {
    setFiles((prevFiles) => {
      const updatedFiles = prevFiles.filter((_, i) => i != index);
      if (updatedFiles.length ==0) {
        setInputVisible(true);
      }
      return updatedFiles;
    });
  };

  const handleTakeAgain = () => {
    fileInputRef.current.click();
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="">
          <>
            <div className="flex mb-6 relative  bg-[#F6F8F9]">
              <div className={`fileInput w-full h-[164px] ${!inputVisible ? "hidden" : ""}`}>
                <input
                  type="file"
                  className="opacity-0 flex h-[164px] justify-center items-center shrink-0 self-stretch w-full rounded-md z-[99] absolute"
                  multiple
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
                <div
                  className="flex flex-col items-center gap-1 relative z-0 mx-auto my-0 top-[42px] inset-x-0"
                >
                  <p>
                    <img src={multiplefile} alt="Select multiple files" />{" "}
                  </p>
                  <p>
                    <label>Add file or photo</label>
                  </p>{" "}
                </div>
              </div>
            </div>
          </>
      </form>

      <ul>
        {files.map((file, index) => (
          <div className="mt-4">
            <li
              className="flex justify-between flex-1 gap-2 bg-[#F6F8F9] pl-4 pr-2 py-2 rounded-md text-[#252C32] text-sm not-italic font-normal leading-6 mb-2 items-center"
              key={index}
            >
              <span>{file.name}</span>
              <button
                onClick={() => handleRemoveFile(index)}
                style={{ marginLeft: "10px" }}
              >
                <img src={removefile} alt="Remove file" />
              </button>
            </li>
          </div>
        ))}
      </ul>

      {!inputVisible && (
         <> 
         <div className="uploadbuttons flex">
          <button
            type="button"
            className="inline-grid flex-col items-center mr-2 gap-2.5 flex-[1_0_0] border bg-white px-4 py-2 rounded-[10px] border-solid border-[#DDE2E4] text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] w-[172px]"
            onClick={handleTakeAgain}
          >
            Take Again
          </button>
          <Button label="Confirm" className="confirm-btn inline mr-1.5 flex-col items-center gap-2.5 flex-[1_0_0] px-4 py-2 rounded-[10px] text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] w-[172px]" />
          </div>
        </>
        
      )}
    </>
  );
};

export default FileUpload;
