import React, { useState } from "react";
import googleplay from "../../../../../assets/images/Google.svg";
import apple from "../../../../../assets/images/Apple.svg";
import { useTranslation } from "react-i18next";
import TitleDescription from "../../../../../components/newMotorPolicy/titleDescription/titleDescription";
import FileUpload from "./fileUpload";
import Button from "../../../../../components/common/Button/Button";
import Congratulations from "./congratulations";
import { screenResolution } from "../../../../../utils/screenResolution";

const MobileUploadCarPhotos = () => {
  const { t } = useTranslation();
  const [showSuccess, setShowSuccess] = useState(false);
  // TODO: add  Confirm BTN disable when no files uploaded, once file data is stored in redux
  const toggleSuccess = () => setShowSuccess(!showSuccess);
  let instructions =
    "Please upload clear photos of all four sides of your car to reflect its current condition accurately. Start by capturing the front, then the rear, followed by each side. This ensures all aspects are clearly presented.";

  return (
    <>
      {!showSuccess ? (
        <div className="min-h-screen flex flex-col p-4 w-[33%] mx-auto my-0 max767:w-full ipad:w-[55%] max767:p-5 max767:pt-0">
          <TitleDescription
            className="uplodad-car-div text-left"
            title="Upload Photos of Your Car"
            description={instructions}
          />
          <div className="w-full">
            <FileUpload />
            {/* {!screenResolution.mobile ? (
              <>
                <Button label="Confirm" className="confirm-btn inline mr-1.5 mt-2.5 flex-col items-center gap-2.5 flex-[1_0_0] px-4 py-2 rounded-[10px] text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] w-[172px]" onSubmit={toggleSuccess} />

                <div className="flex space-x-4 mt-6 mb-6">
                  <p className="text-[#5B6871] text-sm not-italic font-normal leading-5">
                    {t(
                      "motorInsurance.newMotorInsuranceFlow.paymentSuccess.for_inquiries"
                    )}{" "}
                    <a
                      href="#"
                      className="text-[#5B6871] text-sm not-italic font-medium leading-[150%] underline"
                    >
                      {t(
                        "motorInsurance.newMotorInsuranceFlow.paymentSuccess.whatsapp"
                      )}
                    </a>{" "}
                    {t(
                      "motorInsurance.newMotorInsuranceFlow.paymentSuccess.or_call"
                    )}{" "}
                    <a
                      href="#"
                      className="text-[#5B6871] text-sm not-italic font-medium leading-[150%] underline"
                    >
                      17130000
                    </a>
                  </p>
                </div>
                <div className="flex justify-start space-x-4 ml-0">
                  <button className="flex gap-1">
                    <img src={apple} alt="App Store" className="h-10" />
                    <img src={googleplay} alt="Google Play" className="h-10" />
                  </button>
                </div>
              </>
            ) : (
              <>
                
               
              </>
            )} */}
          </div>
        </div>
      ) : (
        <Congratulations />
      )}
    </>
  );
};

export default MobileUploadCarPhotos;
