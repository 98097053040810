import React, { useState } from "react";
import BackdropModal from "../../common/modal/backdropModal";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import LifeInsuranceSummary from "./lifeInsuranceSummary";

export const PROMO_CODE = "PROMO_CODE";

const LifeViewSummaryPopup = ({
    show,
    setShow,
    TotalPremium,
    Tax,
    Premium,
    QuoteNumber,

}) => {
    const onClose = () => setShow((state) => !state);

    const translate = useLanguageTranslate()

    return (
        <div className="summarymodal">
            <BackdropModal onClose={onClose} show={show}>
                <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px]">
                    {translate("Summary", "ملخص")}
                </h1>
                <LifeInsuranceSummary
                    totalPremium={TotalPremium || 0}
                    tax={Tax}
                    premium={Premium || 0}
                    quotationNumber={QuoteNumber || ""}

                />
            </BackdropModal>
        </div>
    );
};

export default LifeViewSummaryPopup;
