import ReviewAndConfirm from "./reviewAndConfirm";
import closeicon from "../../../../../assets/images/close.svg"
import { useLocation, useNavigate } from "react-router-dom";
import useScreenResolution from "../../../../../hooks/useScreenResolution";

const SurrenderPolicyFlow = () => {
  const { mobile } = useScreenResolution();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();


  return (
    <div className="mainCarDetailsModal pt-[80px] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll max767:pt-[0]">
      {mobile && (
        <button
          className="bpy-2 rounded mt-4 flex absolute top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:ml-[16px] max767:mb-[10px]"
          onClick={() => navigate(`/life-insurance/policy-overview${search}&tabType=ManagePolicy`)}
        >
          <img src={closeicon} alt="Close Icon" />
        </button>
      )}
      <ReviewAndConfirm />
    </div>
  );
};

export default SurrenderPolicyFlow;
