import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, {  AxiosStrapi } from "../axiosInstance";
import {  apiEndpoints, strapiApiEndPoints } from "../../api";
import { clearLoggedInUserDataInLocalStorage, setLoggedInUserDataInLocalStorage } from "../../config/config";

const initialState = {
  // strapi cms labels
  data: null,
  isLoading: false,
  error: null,
  backupData : null,

  // login user state
  isLoadingLogin: false,
  tokenInfo: null,
  errorLogin: null,
};

export const fetchLoginRegisterContent = createAsyncThunk(
  "fetchLoginRegisterContent",
  (queryString, { rejectWithValue }) => {
    return AxiosStrapi.get(
      `${strapiApiEndPoints.signInRegister}`
    ).then((res) => res.data);
  }
);

export const loginUser = createAsyncThunk(
  "loginUser",
  (data, { rejectWithValue }) => {
    let {payload,cb} = data
    return Axios.post(
      `${apiEndpoints.signIn}`, payload
    ).then((res) => {
      cb(true)
      return res.data
    }).catch((err) => {
      console.log(err)

      return rejectWithValue(err.response.data);
    })
  }
);

export const loginRegisterSlice = createSlice({
  name: "loginRegister slice",
  initialState,
  reducers: {
    clearLoginRegisterError: (state) => {
      state.error = null;
    },
    clearLoginUserError: (state) => {
      state.errorLogin = null;
    },
    setLoginTokenInfo: (state,action) => {
      state.tokenInfo = action.payload;
    },
    clearLoginTokenInfo : (state) => { 
      state.tokenInfo = null;
      clearLoggedInUserDataInLocalStorage()
    },
    loginWithBackupTokenInfo : (state) => { 
      state.tokenInfo = state.backupData;
      setLoggedInUserDataInLocalStorage(state.backupData);
    }
  },
  extraReducers: (builder) => {
    // FETCH  UTILS API
    builder.addCase(fetchLoginRegisterContent.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchLoginRegisterContent.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data?.attributes;
    });
    builder.addCase(fetchLoginRegisterContent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });


    // LOGIN USER
    builder.addCase(loginUser.pending, (state, action) => {
      state.isLoadingLogin = true;
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.isLoadingLogin = false;
      state.tokenInfo = action.payload;
      state.backupData = action.payload;
      setLoggedInUserDataInLocalStorage(action.payload);
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.isLoadingLogin = false;
      state.errorLogin = action.payload;
    });
  },
});

// SELECTORS
export const selectLoginRegisterWhole = (store) => store?.login_register;
export const selectLoginRegister = (store) => store?.login_register?.data;
export const selectLoginRegisterLoader = (store) => store?.login_register?.isLoading;

export const selectLoginPageLabels = (store) => store?.login_register?.data?.sign_in_header

// LOGIN CRM API
export const selectLoginTokenInfo = (store) => store?.login_register?.tokenInfo
export const selectLoginErrorInfo = (store) => store?.login_register?.errorLogin
export const selectLoginLoader = (store) => store?.login_register?.isLoadingLogin


// ACTIONS TO DISPATCH
export const { clearLoginRegisterError, clearLoginUserError,setLoginTokenInfo ,clearLoginTokenInfo,loginWithBackupTokenInfo } = loginRegisterSlice.actions;

export default loginRegisterSlice.reducer;
