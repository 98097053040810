import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/common/Button/Button";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { useNavigate } from "react-router-dom";
import useScreenResolution from "../../../hooks/useScreenResolution";

const UpdateSucccessPage = ({ }) => {
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const navigate = useNavigate();
  const {mobile} = useScreenResolution();

  const handleNavigation = () => {
    if (mobile) {
      navigate("/profile-details");
    } else {
      navigate("/profile-settings");
    }
  };
  return (
    <div className="max-w-lg mx-auto bg-white rounded-md mt-10 mb-8 text-center max767:p-4 max767:mt-32 ipad:mt-44">
      <div className="bg-gray-100 p-6 rounded-lg mb-4">
        <div className="flex justify-around mb-4">
          <div className="bg-green-500 text-white p-3 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        </div>
        <h2 className="text-[#1A2024] text-center text-2xl not-italic font-normal leading-[34px] tracking-[-0.24px] mb-2">
          {translate(
            "Your contact number has been successfully updated.",
            "تم تحديث رقم الاتصال الخاص بك بنجاح."
          )}
        </h2>
      </div>
      <Button
        type="submit"
        className="primary-button"
        onSubmit={handleNavigation}
        label={translate("Back to Profile", "العودة إلى الملف الشخصي")}
      />
    </div>
  );
};

export default UpdateSucccessPage;
