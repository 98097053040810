import React, { useState } from 'react'
import PopoverComponent from '../../../../../../components/common/popover/PopoverComponent'
import useLanguageTranslate from '../../../../../../hooks/useLanguageTranslate';
import iconmenu from '../../../../../../assets/images/Iconbutton.svg'
import editicon from '../../../../../../assets/images/edit.svg'
import deleteicon from '../../../../../../assets/images/delete.svg'
export const EDIT_ACTION = "EDIT_ACTION";
export const DELETE_ACTION = "DELETE_ACTION";

const TableRow = ({
    headers,
    showActions,
    row,
    rowIndex,
    actionMenuClickHandler,
    showEdit = true,
    showDelete = true
}) => {
    const [showPopover, setShowPopover] = useState(false)
    const hidePopover = () => {
        setShowPopover(false)
    }
    const openPopover = () => {
        setShowPopover(state => !state)
    }
    console.log(showPopover, 'show popover')
    const translate = useLanguageTranslate()
    return (
        <tr className={rowIndex % 2 ==0 ? 'bg-white' : 'bg-white'}>
            {headers.map((header, colIndex) => (
                <td key={colIndex} className=" p-2 text-[#1A2024] text-sm not-italic font-normal leading-6 border-b px-6 py-2.5">
                    {row[header.key]}
                </td>
            ))}
            {
                showActions && <td className=" p-2 border-b">
                    <div className='editpopover'>
                    <PopoverComponent
                        headerMenu={
                            <p onClick={openPopover}><img src={iconmenu} alt="icon menu"/>{translate("","")}</p>
                        }
                        positions={["bottom"]}
                        isPopoverOpen={showPopover}
                        closer={hidePopover}
                        popupContent={
                            <div className='inline-flex flex-col w-64 items-start gap-2 bg-white shadow-[0px_0px_1px_0px_rgba(26,32,36,0.32),0px_12px_24px_0px_rgba(91,104,113,0.24)] p-2 rounded-[10px] -left-40 top-2.5'>
                                {showEdit && <button className='flex gap-2 py-2 w-full hover:bg-[#F6F8F9] rounded-md' onClick={() => actionMenuClickHandler(row, EDIT_ACTION)}>
                                    <img src={editicon} alt="" />
                                   <span className='text-[#48535B] text-sm not-italic font-normal leading-6'> {translate("Edit Details","تعديل التفاصيل")}</span>
                                </button>}
                                {showDelete && <button className='flex gap-2 py-2 w-full hover:bg-[#F6F8F9] rounded-md' onClick={() => actionMenuClickHandler(row, DELETE_ACTION)}>
                                    <img src={deleteicon} alt="" />
                                  <span className='text-[#CC0905] text-sm not-italic font-normal leading-6'> {translate("Remove beneficiary","حذف المستفيد")}</span>
                                </button>}
                            </div>
                        }
                    />
                    </div>
                </td>
            }
        </tr>
    )
}

export default TableRow