import React from 'react'
import useLanguageTranslate from '../../../../../../hooks/useLanguageTranslate'

const CarInfoTable = ({table_data,className}) => {
    const translate= useLanguageTranslate()
    return (
        <table className={`bg-white border-separate border border-solid border-[#DDE2E4] rounded-[10px] border-spacing-0 min-w-full divide-y ${className}`}>
            <tbody>
                {table_data.map((row, index) => (
                    <tr key={index} className="rounded-[10px] border-gray-200">
                        <td className="text-[#5B6871] border-b border-r text-sm not-italic font-medium leading-6  items-center gap-2.5 flex-[1_0_0] px-6 py-2.5 rtl:border-l-[#DDE2E4] rtl:border-l rtl:border-solid">{translate(row.column1,row.column1_ar)}</td>
                        <td className="text-[#1A2024] border-b text-sm not-italic font-medium leading-6 items-center gap-2.5  px-6 py-2.5">{row.column2}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

export default CarInfoTable