import React from "react";
import logo from "../../../../assets/images/Logo.svg";
import pdficon from "../../../../assets/images/Fileicon.svg";
import downloadicon from "../../../../assets/images/file_download.svg";
import { useTranslation } from "react-i18next";
import scanimage from "../../../../assets/images/QRCode.svg";
import homeblackicon from "../../../../assets/images/home-black.svg";
import googleplay from "../../../../assets/images/Google.svg";
import apple from "../../../../assets/images/Apple.svg";
import NewMotorJourneyLayout from "../Layouts/NewMotorJourneyLayout";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import { decodeQueryString, generateQueryString } from "../../../../utils";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentSuccess = () => {
  const { t } = useTranslation();
  const handleDownload = (fileName, fileData) => {
    if (fileData) {
      const blob = new Blob([fileData], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const translate = useLanguageTranslate();
  const navigate = useNavigate()
  const { pathname ,search } = useLocation()
  const goToPolicyDetails = () => {
    if(pathname?.includes('motor-insurance')){
      navigate(`/motor-insurance/policy-overview${search}`)
    }
    else {
      // here navigate to life insurance
    }
  }
  return (
    <div className="flex justify-center items-center ">
      <div className="p-8 mt-[5%] mx-auto bg-[#F6F8F9] rounded-[26px] max767:pt-8 max767:mt-0 max767:p-5 max767:w-[100%] max767:rounded-none ipad:w-[95%] ipad:mt-[10%]">
        <div className="">
          <div className="flex justify-between">
            <div className="flex justify-between w-full max767:grid">
              <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] max767:w-full  max767:order-2">
                {t(
                  "motorInsurance.newMotorInsuranceFlow.paymentSuccess.congratulations"
                )}
              </h1>
              <img src={logo} alt="Solidarity Logo" className="h-10 max767:flex max767:order-1 max767:mb-2" />
            </div>
          </div>
          <p className="mt-10 flex-col text-[#5B6871] text-base not-italic font-normal leading-6 tracking-[-0.16px] w-[65%] max767:w-full max767:mt-4">
            {t(
              "motorInsurance.newMotorInsuranceFlow.paymentSuccess.notification_received"
            )}
          </p>
        </div>
        <div className="space-y-4">
          <p className="mt-10 text-[#5B6871] text-base not-italic font-normal leading-6 tracking-[-0.16px]  max767:mt-4">
            {t("motorInsurance.newMotorInsuranceFlow.paymentSuccess.view_download")}
          </p>
          {documents.map((doc, index) => (
            <div key={index} className="flex items-center justify-between">
              <div className="flex items-center space-x-4 w-full">
                <div className=" flex items-center justify-center h-20 w-20">
                  <img
                    src={scanimage}
                    alt="QR Code"
                    className="h-full w-full"
                  />
                </div>
                <div className=" flex downloadbtn w-[86%]">
                  <button
                    onClick={() => handleDownload(doc?.fileName, doc?.fileData)}
                    className="border bg-white w-full p-4 rounded-lg inline-flex justify-between"
                  >
                    <div className="flex gap-4 text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
                      <img src={pdficon} alt="Beneficiary" />
                      <div className="flex flex-col items-start">
                        <span className="text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]  max767:text-left">
                          {doc?.fileName}
                        </span>
                        <span className="text-[#84919A] text-xs not-italic font-normal leading-4">
                          {doc?.fileData?.size}
                        </span>
                      </div>
                    </div>
                    <img src={downloadicon} alt="Beneficiary" className="" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="flex space-x-4 mt-9">
          <p className="text-[#5B6871] text-sm not-italic font-normal leading-5 ">
            {t(
              "motorInsurance.newMotorInsuranceFlow.paymentSuccess.for_inquiries"
            )}{" "}
            <a href="#" className="text-[#5B6871] text-sm not-italic font-medium leading-[150%] underline">
              {t("motorInsurance.newMotorInsuranceFlow.paymentSuccess.whatsapp")}
            </a>{" "}
            {t("motorInsurance.newMotorInsuranceFlow.paymentSuccess.or_call")}{" "}
            <a href="#" className="text-[#5B6871] text-sm not-italic font-medium leading-[150%] underline">17130000</a>{" "}
          </p>
        </div>
        <div className="mt-8 text-center items-center flex gap-2 max767:inline-block max767:w-full">
          <button onClick={goToPolicyDetails} className="flex w-[30%] px-[9px] bg-[#55C6EF] mr-2 rounded-[10px] py-2 items-center gap-2 mb-0 text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:w-full ipad:w-[35%]">
            <p className="items-center text-sm justify-around w-full flex">{t(
              "motorInsurance.newMotorInsuranceFlow.paymentSuccess.view_policy_details"
            )}
              <img src={homeblackicon} alt="home Icon" className="h-8 flex" />
            </p>
          </button>
          <span className="max767:invisible">
            {t("motorInsurance.newMotorInsuranceFlow.paymentSuccess.or")}
          </span>
          <div className="flex justify-center space-x-4 ml-2">
            <button className="flex gap-1">
              <img
                src={apple}
                alt="app store"
                className="h-10"
              />
              <img
                src={googleplay}
                alt="Google Play"
                className="h-10"
              />
            </button>
          </div>
        </div>
      </div>
    </div>


  );
};

export default PaymentSuccess;


const documents = [
  {
    fileName: "Vehicle Insurance Certificate",
    fileName_ar: "شهادة تأمين المركبة",
    fileData: {
      size: "16.7 KB",
    },
  },
  {
    fileName: "Policy Schedule",
    fileData: {
      size: "16.7 KB",
    },
  },
  {
    fileName: "Tax Invoice",
    fileData: {
      size: "16.7 KB",
    },
  },
  {
    fileName: "Welcome Kit",
    fileData: {
      size: "16.7 KB",
    },
  },
];
