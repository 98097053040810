import React, { useState } from "react";
import NewMotorJourneyLayout from "../Layouts/NewMotorJourneyLayout";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import FileUpload from "../../../../components/common/fileUploadInput/fileUploadInput";
import { useTranslation } from "react-i18next";
import useMotorForm from "../../../../hooks/useMotorFormData";
import BottomStickyBtnLayout from "../Layouts/BottomStickyBtnLayout";
import useMotorFlow from "../../../../hooks/useMotorFlow";
import { useSelector } from "react-redux";
import { selectNewMotorForm } from "../../../../redux/features/newMotorFormSlice";

const MOTOR_UPLOAD_FILES = "MOTOR_UPLOAD_FILES";
const CAR_INFO_FINANCING = "CAR_INFO_FINANCING";

const UploadDocsPage = ({ backClickHandler }) => {
  const { t } = useTranslation();
  const { handleNextSubstep } = useMotorFlow();

  const [formData, setFormData] = useState({
    is_no_claim_certificate_uploaded: false,
    is_bank_letter_uploaded: false,
  });

  const { saveMotorDataInRedux } = useMotorForm(
    MOTOR_UPLOAD_FILES,
    setFormData
  );

  const NextHandler = () => {
    if (isFinanced.is_finance == "Yes") {
      if (
        formData.is_no_claim_certificate_uploaded == true &&
        formData.is_bank_letter_uploaded == true
      ) {
        saveMotorDataInRedux(formData);
        handleNextSubstep();
      }
    } else {
      if (formData.is_no_claim_certificate_uploaded == true) {
        saveMotorDataInRedux(formData);
        handleNextSubstep();
      }
    }
  };
  const onFileUploaded = (value, name) => {
    console.log("Uploading value: ", value);
    console.log("Uploading name: ", name);

    setFormData({ ...formData, [name]: value });
  };

  const isFinanced = useSelector(selectNewMotorForm)?.[CAR_INFO_FINANCING];

  return (
    <NewMotorJourneyLayout backClickHandler={backClickHandler}>
      <BottomStickyBtnLayout
        show_summary={true}
        btn_disabled={
          isFinanced.is_finance == "Yes"
            ? formData.is_no_claim_certificate_uploaded == false ||
              formData.is_bank_letter_uploaded == false
            : formData.is_no_claim_certificate_uploaded == false
        }
        btn_onClick={NextHandler}
        passedFrom={"NewMotor"}
      >
        <div className="items-center flex flex-col gap-6">
          <div className="uploadDocsPage min-w-[32%] max767:p-6 ipad:min-w-[55%] max767:pt-0 max767:w-[100%]">
            <TitleDescription
              title={t(
                "motorInsurance.newMotorInsuranceFlow.uploadDocsPage.title"
              )}
              description={t(
                "motorInsurance.newMotorInsuranceFlow.uploadDocsPage.description"
              )}
            />
            <div className="noClaimCertuficate mb-5">
              <p className="mb-2 text-[#48535B] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] max767:mb-1">
                {t(
                  "motorInsurance.newMotorInsuranceFlow.uploadDocsPage.noClaimLabel"
                )}
              </p>
              <FileUpload
                name="is_no_claim_certificate_uploaded"
                toggleFileUpload={onFileUploaded}
              />
            </div>
            {isFinanced.is_finance == "Yes" ? (
              <div className="bankLetter mb-6">
                <p className="mb-2 text-[#48535B] text-base not-italic font-normal leading-[26px] tracking-[-0.16px]">
                  {t(
                    "motorInsurance.newMotorInsuranceFlow.uploadDocsPage.bankLetterLabel"
                  )}
                </p>
                <FileUpload
                  name="is_bank_letter_uploaded"
                  toggleFileUpload={onFileUploaded}
                />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </BottomStickyBtnLayout>
    </NewMotorJourneyLayout>
  );
};

export default UploadDocsPage;
