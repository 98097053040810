/* :Page:NewMotor:MotorRenewal: Adjust sum insured pop up page */

import React, { useState } from "react";
import BackdropModal from "../../../../../components/common/modal/backdropModal";
import TitleDescription from "../../../../../components/newMotorPolicy/titleDescription/titleDescription";
import Tabs from "../../../../../components/common/tabs/tabs";
import {
  DECREASE_VALUE,
  getDecreaseTabOptions,
  getIncreaseTabOptions,
  INCREASE_VALUE,
  increaseDecreaseTabOptions,
} from "./data";
import Button from "../../../../../components/common/Button/Button";
import FormTabs from "../../../../../components/common/tabs/formTabs";
import Input from "../../../../../components/common/Input/Input";
import Exclamation from "../../../../../assets/images/Exclamation.svg";
import { useSelector } from "react-redux";
import { getSumInsuredDetails } from "../../../../../redux/features/motor/getSumInsuredSlice";
import { useTranslation } from "react-i18next";
import useLanguageTranslate from "../../../../../hooks/useLanguageTranslate";

const AdjustCarValuePopup = ({
  confirmClickHandler,
  show,
  onClose,
  className,
  formData,
  setFormData,
  selectedTab,
  setSelectedTab,
  type,
}) => {
  const translate = useLanguageTranslate();
  const onTabClick = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };
  const { t } = useTranslation();

  const sumInsuredDetails = useSelector(getSumInsuredDetails);

  console.log("Sum insured details: ", sumInsuredDetails);
  console.log("Selected tab amount: ", selectedTab.amount);

  const increaseTabOptions = getIncreaseTabOptions(
    sumInsuredDetails != "" ? sumInsuredDetails : null,
    sumInsuredDetails != "" ? null : selectedTab.amount
  );
  const decreaseTabOptions = getDecreaseTabOptions(
    sumInsuredDetails != "" ? sumInsuredDetails : null,
    sumInsuredDetails != "" ? null : selectedTab.amount
  );

  console.log(selectedTab);

  return (
    <BackdropModal show={show} className={className} onClose={onClose}>
      <TitleDescription
        title={t("motorInsurance.adjustCarvaluePopUp.title")}
        description={
          t("motorInsurance.adjustCarvaluePopUp.description") +
            " " +
            selectedTab?.amount || 0
        }
      />
      <div className="alertBox yellow flex items-start gap-4 self-stretch border bg-[#FDE9D2] p-4 rounded-[10px] border-solid border-[#FBC98E]">
        <img src={Exclamation} alt="danger img" />
        <p className="text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
          {t("motorInsurance.adjustCarvaluePopUp.disclaimer")}
        </p>
      </div>
      <div className="adjustmodaltabs">
        <Tabs
          tabs={increaseDecreaseTabOptions}
          onTabClick={(type) => onTabClick(type, "tab_type")}
          activeTab={formData.tab_type}
        />
      </div>
      {formData.tab_type == INCREASE_VALUE && (
        <div className="increse-box-tab flex flex-col items-start gap-2 self-stretch bg-[#F6F8F9] px-4 py-6 rounded-[0px_0px_10px_10px]">
          <p className="text-[#48535B] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] max767:text-sm">
            {" "}
            {t("motorInsurance.adjustCarvaluePopUp.by_how_much_increase")}
          </p>
          <FormTabs
            tabs={increaseTabOptions}
            onTabClick={(type, tab) => {
              setSelectedTab(tab);
              onTabClick(type, "increase_tab_type");
            }}
            activeTab={formData.increase_tab_type}
          />
        </div>
      )}
      {formData.tab_type == DECREASE_VALUE && (
        <div className="increse-box-tab flex flex-col items-start gap-2 self-stretch bg-[#F6F8F9] px-4 py-6 rounded-[0px_0px_10px_10px]">
          <p className="text-[#48535B] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] max767:text-sm">
            {t("motorInsurance.adjustCarvaluePopUp.by_how_much_decrease")}
          </p>

          {/* Render amounts only if they meet the condition */}
          {/* {decreaseTabOptions.map((option, index) => 
                            (option.amount >= 3000) && (
                                <p key={index}> {option.amount} </p>
                            )
                        )} */}

          <FormTabs
            tabs={decreaseTabOptions.filter((option) => option.amount >= 1000)}
            onTabClick={(type, tab) => {
              setSelectedTab(tab);
              onTabClick(type, "increase_tab_type");
            }}
            activeTab={formData.increase_tab_type}
          />
        </div>
      )}

      <Input
        label={t("motorInsurance.adjustCarvaluePopUp.label")}
        type="text"
        name="updatedValue"
        className="border bg-[#E5E9EB] px-4 py-[5px] rounded-[10px] border-solid border-[#B0BABF] w-full h-12 text-[#6E7C87]"
        value={`${translate("BHD", "د.ب")} ${selectedTab?.amount || 0}`}
        disabled
      />

      <div className="flex gap-2 align-middle w-full adjust-buttons">
        <Button
          type="submit"
          className="secondary-button text-center text-[#1A2024] h-10 justify-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] w-full items-center gap-2.5 border bg-white px-4 py-2 rounded-[10px] border-solid border-[#DDE2E4]"
          onSubmit={onClose}
          label={t("motorInsurance.adjustCarvaluePopUp.labelCancel")}
        />
        <Button
          type="submit"
          className="primary-button h-10 text-center justify-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
          onSubmit={() => {
            onClose();
            confirmClickHandler();
          }}
          label={t("motorInsurance.adjustCarvaluePopUp.labelConfirm")}
        />
      </div>
    </BackdropModal>
  );
};

export default AdjustCarValuePopup;
