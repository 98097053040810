import Changepaymentcycle from "../../../../assets/images/Changepaymentcycle.svg";
import DecreaseIncrease from "../../../../assets/images/DecreaseIncrease.svg";
import Changepayment from "../../../../assets/images/Changepaymentmethod.svg";
import Updatecard from "../../../../assets/images/updatecard.svg";
import beneficiaries from "../../../../assets/images/Managebeneficiaries.svg";
import strategy from "../../../../assets/images/Managedinvestmentstrategy.svg";
import Redirect from "../../../../assets/images/RedirectFunds.svg";
import Switchfunds from "../../../../assets/images/Switchfunds.svg";
import Withdrawal from "../../../../assets/images/Withdrawal.svg";
import Surrender from "../../../../assets/images/SurrenderPolicy.svg";
import { BUY_ONLINE_PLANS, PROTECTION_PLANS, SAVING_PROTECTION } from "../data";

export const STATUS_WAITING_FOR_APPROVAL = "STATUS_WAITING_FOR_APPROVAL";
export const STATUS_APPROVED = "STATUS_APPROVED";
export const STATUS_REJECTED = "STATUS_REJECTED";



export const CHANGE_PAYMENT_CYCLE = "CHANGE_PAYMENT_CYCLE";
export const DECREASE_INCRESE_PREMIUM = "DECREASE_INCRESE_PREMIUM";
export const CHANGE_PAYMENT_METHOD = "CHANGE_PAYMENT_METHOD";
export const UPDATE_CARD_DETAILS = "UPDATE_CARD_DETAILS"
export const MANAGE_BENEFICIARY = "MANAGE_BENEFICIARY";
export const FUND_MANAGEMENT = "FUND_MANAGEMENT"
export const CHANGE_TO_INVESTMENT_STRATEGY = "CHANGE_TO_INVESTMENT_STRATEGY"
export const SWITCH_FUNDS = "SWITCH_FUNDS"
export const WITHDRAWL = "WITHDRAWL"
export const SURRENDER_POLICY = "SURRENDER_POLICY"

export const manageMyPolicy = {
  cards: [
    {
      icon: Changepaymentcycle,
      label_en: "Change payment cycle",
      label_ar: "تغيير الخطة",
      type: CHANGE_PAYMENT_CYCLE,
      showPlanType : [PROTECTION_PLANS,SAVING_PROTECTION]
    },
    {
      icon: DecreaseIncrease,
      label_en: "Decrease or Increase contribution",
      label_ar: "إضافة المزيد من الأغطية",
      type: DECREASE_INCRESE_PREMIUM,
      showPlanType : [PROTECTION_PLANS,SAVING_PROTECTION]

    },
    {
      isHide: true,
      icon: Changepayment,
      label_en: "Change payment method",
      label_ar: "تعديل قيمة السيارة (مبلغ التأمين)",
      type: CHANGE_PAYMENT_METHOD,
    },
    {
      isHide: true,
      icon: Updatecard,
      label_en: "Update card details",
      label_ar: "تغيير رقم اللوحة",
      type: UPDATE_CARD_DETAILS,
    },
    {
      icon: beneficiaries,
      label_en: "Manage beneficiaries",
      label_ar: "إزالة اسم البنك",
      type: MANAGE_BENEFICIARY,
      showPlanType : [PROTECTION_PLANS,SAVING_PROTECTION,BUY_ONLINE_PLANS]
    },
    {
      icon: strategy,
      label_en: "Fund Management",
      label_ar: "نقل ملكية السياسة",
      type: FUND_MANAGEMENT,
      showPlanType : [SAVING_PROTECTION]

    },
    {
      icon: Redirect,
      label_en: "Change to Investment Strategy",
      label_ar: "نقل ملكية السياسة",
      type: CHANGE_TO_INVESTMENT_STRATEGY,
      showPlanType : [SAVING_PROTECTION]
    },
    {
      icon: Withdrawal,
      label_en: "Withdrawal",
      label_ar: "نقل ملكية السياسة",
      type: WITHDRAWL,
      showPlanType : [SAVING_PROTECTION]
    },
    {
      icon: Surrender,
      label_en: "Surrender Policy",
      label_ar: "نقل ملكية السياسة",
      type: SURRENDER_POLICY,
      showPlanType : [SAVING_PROTECTION]
    },
  ],
};