import React from "react";
import backarrow from "../../assets/images/back-arrow-left.svg";
import closeicon from "../../assets/images/close.svg";
import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Button/Button";
import useScreenResolution from "../../hooks/useScreenResolution";
import chatIcon from "../../assets/images/chat.svg";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
const MorePageLayout = ({
  children,
  is_hide_back_btn,
  back_btn_on_click,
  show_close_btn,
  close_btn_on_click,
  show_chat_btn,
  chat_btn_on_click,
}) => {
  const navigate = useNavigate();
  const screenResolution = useScreenResolution();
  const translate = useLanguageTranslate();
  const navigateToMore = () => {
    navigate("/more");
  }
  return (
    <div className="morePageLayout">
      <div className="max767:flex max767:w-full max767:pl-5">
      {!is_hide_back_btn && (
        <button
          className="backButton bg-navy-blue py-2 rounded mt-20 flex absolute left-[26px] top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:bg-[#F6F8F9] max767:w-10 max767:h-10 max767:text-center max767:justify-center max767:rounded-3xl max767:mt-12 max767:ml-[-11px] rtl:right-[26px]"
          onClick={back_btn_on_click ? back_btn_on_click : navigateToMore}
        >
          <img src={backarrow} alt="Back arrow" className="1 rtl:rotate-180" />
        </button>
      )}
      {show_close_btn && screenResolution.mobile && (
        <button
          className="bpy-2 rounded mt-4 flex absolute top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:ml-0"
          onClick={() =>
            close_btn_on_click ? close_btn_on_click() : navigateToMore()
          }
        >
          <img src={closeicon} alt="Close Icon" />
        </button>
      )}
      {show_chat_btn && screenResolution.mobile && (
        <Button
          label={translate("Chat","الدردشة")}
          className="inline-flex items-center gap-2 bg-[#55C6EF] px-4 py-2 rounded-md w-[93px] absolute right-4 top-[50px]"
          pre_icon={chatIcon}
          onSubmit={chat_btn_on_click}
        />
      )}
      </div>
      {children}
      
    </div>
  );
};

export default MorePageLayout;
