import React, { useState } from "react";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import useFileClaimFlow from "../../../hooks/useFileClaimFlow";
import FileClaimMotorLayout from "./fileClaimMotorLayout";
import MotorPolicyOptionsCard from "../../../components/motorPolicyOptionsCard/motorPolicyOptionsCard";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { useTranslation } from "react-i18next";
import { FILE_CLAIM_FLOW, FILE_CLAIM_NON_MOTOR_POLICY_HOLDER } from "./data";

const policyOptions = [
  {
    key: "motorPolicy",
    label_en: "Motor Policy",
    label_ar: "وثيقة تأمين المركبات",
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: ""
  },
  {
    key: "lifePolicy",
    label_en: "Life Policy",
    label_ar: "وثيقة تأمين على الحياة",
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: ""
  },
  {
    key: "homePolicy",
    label_en: "Home Policy",
    label_ar: "وثيقة تأمين المنزل",
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: ""
  },
  {
    key: "travelPolicy",
    label_en: "Travel Policy",
    label_ar: "وثيقة تأمين السفر",
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: ""
  },
  {
    key: "domesticHelperPolicy",
    label_en: "Domestic Helper Policy",
    label_ar: "سياسة المساعد المنزلي",
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: "",
  },
  {
    key: "personalAccidentPolicy",
    label_en: "Personal Accident Policy",
    label_ar: "سياسة الحوادث الشخصية",
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: "",
  },
  {
    key: "medicalPolicy",
    label_en: "Medical Policy",
    label_ar: "سياسة التأمين الطبي",
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: "",
  },
  {
    key: "expatMedicalVisaPolicy",
    label_en: "Expat Medical Visa Policy",
    label_ar: "سياسة تأشيرة الطبية للمغتربين",
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: "",
  },
  {
    key: "personalCyberPolicy",
    label_en: "Personal Cyber Policy",
    label_ar: "سياسة الأمن السيبراني الشخصي",
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: "",
  },
  {
    key: "smartDevicePolicy",
    label_en: "Smart Device Policy",
    label_ar: "سياسة الأجهزة الذكية",
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: "",
  },
  {
    key: "bupaPolicy",
    label_en: "BUPA Policy",
    label_ar: "سياسة بوبا",
    title_en: "",
    title_ar: "",
    description_en: "",
    description_ar: "",
  },
];

function PolicyTypePage() {
  const [selectedOption, setSelectedOption] = useState(null);
  const translate = useLanguageTranslate();
  const { t } = useTranslation()
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  const { handleNextSubstep , handleFlowChange } = useFileClaimFlow();
  const continueClickHanlder = () => {
    if(selectedOption =="motorPolicy"){
      handleFlowChange(FILE_CLAIM_FLOW, 2);    }
    else if(selectedOption =="lifePolicy"){
      window.location.href = "https://life.solidarity.com.bh/file-a-claim"
    }
    else {
      handleFlowChange(FILE_CLAIM_NON_MOTOR_POLICY_HOLDER,2)
    }
    
  };
  return (
    <FileClaimMotorLayout
      btn_onClick={continueClickHanlder}
      btn_disabled={!selectedOption}
      is_hide_back_btn={false}
    >
      <div className="flex flex-col items-center mx-auto w-[500px] max-w-[100%] max767:mt-8 PolicyTypePage max767:p-4">
        <TitleDescription title={t("motorInsurance.fileAClaimFlow.policyTypePage.title")} description={t("motorInsurance.fileAClaimFlow.policyTypePage.description")} className="max767:text-left w-full max767:mb-3" />{" "}
        <div className="PolicyTypePagecard w-full">
          {policyOptions.map((policy) => (
            <MotorPolicyOptionsCard
              key={policy.key}
              label={translate(policy.label_en, policy.label_ar)}
              title={translate(policy.title_en, policy.title_ar)}
              description={translate(policy.description_en, policy.description_ar)}
              isSelected={selectedOption ==policy.key}
              onSelect={() => handleOptionSelect(policy.key)}
            />
          ))}
        </div>
      </div>
    </FileClaimMotorLayout>
  );
}

export default PolicyTypePage;
