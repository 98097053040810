import React, { useEffect, useState } from "react";
import logo from "../../../../assets/images/Logo.svg";
import pdficon from "../../../../assets/images/Fileicon.svg";
import downloadicon from "../../../../assets/images/file_download.svg";
import { useTranslation } from "react-i18next";
import scanimage from "../../../../assets/images/QRCode.svg";
import homeblackicon from "../../../../assets/images/home-black.svg";
import googleplay from "../../../../assets/images/Google.svg";
import apple from "../../../../assets/images/Apple.svg";
import NewMotorJourneyLayout from "../Layouts/NewMotorJourneyLayout";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPolicyVerifyLoading,
  getPolicyVerifyResponse,
  PolicyVerify,
  ViewIssuedQuoteDocs,
  ViewIssuedQuoteDocsLoading,
} from "../../../../redux/features/motor/policyDocsViewSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import SkeletonLoader from "../../../../components/common/Loader/SkeletonLoader";
import CustomLoader from "../../../../components/common/Loader/CustomLoader";
import QRCodeGenerator from "../../../../components/QrCode/QrCodeGenerator";

const PolicyDocs = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const policy = searchParams.get("policy");
  const token = searchParams.get("token");
  const [decodedValue, setDecodedValue] = useState("");
  const [fileName, setFileNameValue] = useState("");
  const [link, setLinkValue] = useState("");
  const navigate = useNavigate();

  const [documents, setDocuments] = useState([]);

  const PolicyDocs = useSelector((state) => state?.viewDocs?.PolicyDocs);
  const PolicyDocsStatus = useSelector((state) => state?.viewDocs?.status);
  const PolicyDocsError = useSelector((state) => state?.viewDocs?.error);

  const policyVerifyResponse = useSelector(getPolicyVerifyResponse);
  const isPolicyVerifyLoading = useSelector(getPolicyVerifyLoading);

  //const IsLoading = useSelector(ViewIssuedQuoteDocsLoading);
  const GetPolicyDocs = async () => {
    console.log("decoded value to be passed, ", decodedValue);
    const req = {
      quote: decodedValue,
    };
    var res = await dispatch(ViewIssuedQuoteDocs(req));
    if (res.error?.message != "Rejected") {
      const response = await unwrapResult(res);
      //  const fileData = getFileByName(response, "ENDORSEMENT DOCUMENTS");
      //  setFileNameValue(fileData.ID);
      //  setLinkValue(fileData.File);
      const endorsementDocument = response?.find(
        (report) => report.ID == "ENDORSEMENT DOCUMENTS"
      );
      console.log("end doc: ", endorsementDocument);

      setDocuments([
        {
          fileName: endorsementDocument.ID,
          fileName_ar: getArabicName(endorsementDocument.ID), // Function to map IDs to Arabic names
          fileData: {
            size: "", // Adjust if necessary or make dynamic
            url: endorsementDocument.File,
          },
        },
      ]);
    }
  };

  const VerifyPolicyDocs = async () => {
    console.log("decoded value to be passed, ", decodedValue);
    const req = {
      Token: decodedValue,
    };
    var res = await dispatch(PolicyVerify(req));
    if (res.error?.message != "Rejected") {
      const response = await unwrapResult(res);
      console.log("policy verify response: ", response);

      // Assuming response.Reports contains the array you're showing
      const reports = response?.Reports || [];
      const filteredReports = reports.filter(report => report.ID !== "SELF BILLED TAX INVOICE");
      // Map the API reports into your documents array structure
      const mappedDocuments = filteredReports.map((report) => ({
        fileName: report.Name,
        fileName_ar: getArabicName(report.ID), // Function to map IDs to Arabic names
        fileData: {
          size: "", // Adjust if necessary or make dynamic
          url: report.File,
        },
      }));

      console.log("Mapped documents: ", documents);
      setDocuments(mappedDocuments);
      // You can now store `documents` in state or perform other actions
    }
  };

  // Example function to map report IDs to their corresponding Arabic names
  const getArabicName = (ID) => {
    const arabicNames = {
      "Tax Invoice": "فاتورة الضريبة",
      "Policy Schedule": "جدول الوثيقة",
      "Vehicle Certificate": "شهادة تأمين المركبة",
      "ENDORSEMENT DOCUMENTS": "شهادة التعديل",
    };
    return arabicNames[ID] || ID; // Default to ID if no match is found
  };

  // Setting up API call based on the param value in the url
  useEffect(() => {
    if (policy) {
      console.log("should get policy value:", policy);
      setDecodedValue(decodeURIComponent(policy));
    } else if (token) {
      console.log("should get token value:", token);
      setDecodedValue(decodeURIComponent(token));
    }
  }, [policy, token]);

  useEffect(() => {
    if (decodedValue) {
      if (policy) {
        GetPolicyDocs();
      } else if (token) {
        VerifyPolicyDocs();
      }
    }
  }, [decodedValue]); // This ensures the API is called only when decodedValue is updated

  useEffect(() => {
    console.log("documents updated: ", documents);
  }, [documents]); // This ensures the API is called only when decodedValue is updated

  const getFileByName = (docs, fileName) => {
    const uniqueFiles = [];
    const namesSet = new Set();

    for (let doc of docs) {
      if (!namesSet.has(doc.ID) && doc.ID == fileName) {
        namesSet.add(doc.ID);
        uniqueFiles.push(doc);
      }
    }

    return uniqueFiles.length > 0 ? uniqueFiles[0] : null; // Return the first matching file or null if not found
  };

  // for(var document of documents){
  //   documents.filesData.push({
  //     fileName: document.ID,
  //     fileName_ar: document.ID,
  //     filesData:{
  //       url: document.File,
  //       size: "165.7 KB",
  //     }
  //   });
  // }

  const handleDownload = (fileName, fileUrl) => {
    if (documents[0].fileData) {
      // Create an anchor element
      const link = document.createElement("a");

      // Set the href to the file URL
      link.href = fileUrl;

      // Set the target to _blank to open in a new tab
      link.target = "_blank";

      // Append the link to the body (required for Firefox)
      document.body.appendChild(link);

      // Programmatically click the link to open the file in a new tab
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    }
  };
  const translate = useLanguageTranslate();

  // documents = [
  //     {
  //     fileName: fileName,
  //     fileName_ar:"شهادة تأمين المركبة",
  //     fileData: {
  //       size: "16.69 KB",
  //       url: link
  //     },
  //   }
  // ];

  const handleNavigate = () => {
    navigate("/my-policy"); // This will navigate to /my-policy
  };

  return (
    <div className="flex justify-center items-center ">
      <div className="p-8 mt-[5%] mx-auto bg-[#F6F8F9] rounded-[26px] max767:pt-8 max767:mt-0 max767:p-5 max767:w-[100%] max767:rounded-none ipad:w-[95%] ipad:mt-[10%]">
        <div className="">
          <div className="flex justify-between">
            <div className="flex justify-between w-full max767:grid">
              <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] max767:w-full  max767:order-2">
                {t(
                  "motorInsurance.newMotorInsuranceFlow.paymentSuccess.congratulations"
                )}
              </h1>
              <img
                src={logo}
                alt="Solidarity Logo"
                className="h-10 max767:flex max767:order-1 max767:mb-2"
              />
            </div>
          </div>
          <p className="mt-10 flex-col text-[#5B6871] text-base not-italic font-normal leading-6 tracking-[-0.16px] w-[65%] max767:w-full max767:mt-4">
            {t(
              "motorInsurance.newMotorInsuranceFlow.paymentSuccess.notification_received"
            )}
          </p>
        </div>
        <div className="space-y-4">
          {PolicyDocsStatus == "loading" ||
          isPolicyVerifyLoading ||
          documents.length < 1 ? (
            <CustomLoader type="endo" />
          ) : (
            <div>
              <p className="mt-10 text-[#5B6871] text-base not-italic font-normal leading-6 tracking-[-0.16px] max767:mt-4">
                {t(
                  "motorInsurance.newMotorInsuranceFlow.paymentSuccess.view_download"
                )}
              </p>
              {documents.map((doc, index) => (
                <div key={index} className="flex items-center justify-between">
                  <div className="flex items-center space-x-4 w-full">
                    <div className="flex items-center justify-center h-20 w-20">
                      <QRCodeGenerator value={doc?.fileData?.url} />
                    </div>
                    <div className="flex downloadbtn w-[86%]">
                      <button
                        onClick={() =>
                          handleDownload(doc?.fileName, doc?.fileData?.url)
                        }
                        className="border bg-white w-full p-4 rounded-lg inline-flex justify-between"
                      >
                        <div className="flex gap-4 text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
                          <img src={pdficon} alt="Beneficiary" />
                          <div className="flex flex-col items-start">
                            <span className="text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:text-left">
                              {doc?.fileName}
                            </span>
                            <span className="text-[#84919A] text-xs not-italic font-normal leading-4">
                              {doc?.fileData?.size}
                            </span>
                          </div>
                        </div>
                        <img src={downloadicon} alt="Download" className="" />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="flex space-x-4 mt-9">
          <p className="text-[#5B6871] text-sm not-italic font-normal leading-5 ">
            {t(
              "motorInsurance.newMotorInsuranceFlow.paymentSuccess.for_inquiries"
            )}{" "}
            <a
              href="#"
              className="text-[#5B6871] text-sm not-italic font-medium leading-[150%] underline"
            >
              {t(
                "motorInsurance.newMotorInsuranceFlow.paymentSuccess.whatsapp"
              )}
            </a>{" "}
            {t("motorInsurance.newMotorInsuranceFlow.paymentSuccess.or_call")}{" "}
            <a
              href="#"
              className="text-[#5B6871] text-sm not-italic font-medium leading-[150%] underline"
            >
              17130000
            </a>{" "}
          </p>
        </div>
        <div className="mt-8 text-center items-center flex gap-2 max767:inline-block max767:w-full">
          <button
            className="flex w-[30%] px-[9px] bg-[#55C6EF] mr-2 rounded-[10px] py-2 items-center gap-2 mb-0 text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:w-full ipad:w-[35%]"
            onClick={handleNavigate}
          >
            <p className="items-center text-sm justify-around w-full flex">
              {t(
                "motorInsurance.newMotorInsuranceFlow.paymentSuccess.view_policy_details"
              )}
              <img src={homeblackicon} alt="home Icon" className="h-8 flex" />
            </p>
          </button>
          <span className="max767:invisible">
            {t("motorInsurance.newMotorInsuranceFlow.paymentSuccess.or")}
          </span>
          <div className="flex justify-center space-x-4 ml-2">
            <button className="flex gap-1">
              <img src={apple} alt="app store" className="h-10" />
              <img src={googleplay} alt="Google Play" className="h-10" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicyDocs;

let documents = [
  // {
  //   fileName: "Vehicle Insurance Certificate",
  //   fileName_ar:"شهادة تأمين المركبة",
  //   fileData: {
  //     size: "16.7 KB",
  //   },
  // },
  // {
  //   fileName: "Policy Schedule",
  //   fileData: {
  //     size: "16.7 KB",
  //   },
  // },
  // {
  //   fileName: "Tax Invoice",
  //   fileData: {
  //     size: "16.7 KB",
  //   },
  // },
  // {
  //   fileName: "Welcome Kit",
  //   fileData: {
  //     size: "16.7 KB",
  //   },
  // },
];
