import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import {  policyApiEndpoints } from "../../../api";

const initialState = {
  PremiumDetails: [],
  isLoading: false,
  success: null,
  error: null,
};


export const policyPremium = createAsyncThunk(
    "policyPremium",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling policyPremium ...")
            const response = await Axios.post(`${policyApiEndpoints.premium}`, payload);
            console.log("policyPremium response is: ", response.data)

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);


export const policyPremiumSlice = createSlice({
  name: "policyPremiumSlice",
  initialState,
  reducers: {
    clearpPolicyPremiumSliceError: (state) => {
      state.error = null;
    },
    clearPolicyPremium: () => initialState
  },
  extraReducers: (builder) => {
    // Get Quote API
    builder.addCase(policyPremium.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(policyPremium.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
      state.PremiumDetails = action?.payload;
    //   state.NewQuote = action?.payload.PolicyNumber;

    });
    builder.addCase(policyPremium.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.ErrorMessageEn;
      state.success = false;
      console.log("policyPremium error: ", action.payload.ErrorMessageEn )
    });
  },
});

// SELECTORS
export const getPolicyPremiumResponse = (store) => store?.policyPremium.PremiumDetails;
export const getPolicyPremiumResponseQuoteDetails = (store) => store?.policyPremium.PremiumDetails.QuoteDetails;
export const getPolicyPremiumLoading = (store) => store?.policyPremium.isLoading;
export const getPolicyPremiumError = (store) => store?.policyPremium.error;
export const getPolicyPremiumSuccess = (store) => store?.policyPremium.success;


// export const getSumInsuredLoader = (store) => store?.getSumInsured.isLoading;


// ACTIONS TO DISPATCH
export const { clearpPolicyPremiumSliceError, clearPolicyPremium } = policyPremiumSlice.actions;

export default policyPremiumSlice.reducer;
