import { createSlice } from "@reduxjs/toolkit";
import { FLOW_TYPE_ESSENTIAL_CARE } from "../../../pages/essentialCare/data";
const initialState = {
  flowType: FLOW_TYPE_ESSENTIAL_CARE,
  currentStepIndex: 0,
  currentSubstepIndex: 0,
};

const essentialCareFlowSlice = createSlice({
  name: "essentialCareFlow",
  initialState,
  reducers: {
    setFlowType: (state, action) => {
      const data = action.payload;
      state.flowType = data.type;
      state.currentStepIndex = data.currentStepIndex || 0;
      state.currentSubstepIndex = data.currentSubstepIndex || 0;
    },
    setCurrentStepIndex: (state, action) => {
      state.currentStepIndex = action.payload;
      state.currentSubstepIndex = 0;
    },
    setCurrentSubstepIndex: (state, action) => {
      state.currentSubstepIndex = action.payload;
    },
  },
});

export const selectEssentialCareFlow = (store) => store.essentialCareFlow;
export const selectFlowType = (store) => store?.essentialCareFlow?.flowType;
export const selectCurrentStepIndex = (store) =>
  store?.essentialCareFlow?.currentStepIndex;
export const selectCurrentSubstepIndex = (store) =>
  store.essentialCareFlow?.currentSubstepIndex;

export const { setFlowType, setCurrentStepIndex, setCurrentSubstepIndex } =
  essentialCareFlowSlice.actions;
export default essentialCareFlowSlice.reducer;
