import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useFileClaimFlow from "../../../../hooks/useFileClaimFlow";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import FileUpload from "../../../../components/common/fileUploadInput/fileUploadInput";
import DragDropFileUpload from "../../../../components/common/dragDropFileUpload";
import Button from "../../../../components/common/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import closeicon from "../../../../assets/images/close.svg";
import useScreenResolution from "../../../../hooks/useScreenResolution";
import CryptoJS from "crypto-js";

const UploadDocuments = () => {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const { mobile } = useScreenResolution();
  const { claim_id } = useParams();

  const { t } = useTranslation();
  const [fileUploadError, setFileUploadError] = useState(null);
  const valueToEncrypt = claim_id;

  // Encryption logic
  const secretKey = "i-hate-meej";
  const encryptedValue = CryptoJS.AES.encrypt(
    valueToEncrypt,
    secretKey
  ).toString();

  // Convert to URL-safe format
  const urlSafeEncryptedValue = encryptedValue
    .split("+")
    .join("-") // Replace '+' with '-'
    .split("/")
    .join("_") // Replace '/' with '_'
    .split("=")
    .join(""); // Remove '=' padding
  const handleUploadComplete = (file) => {
    console.log(`Upload complete..... for ${file.name}`);
  };
  const onFileUploaded = (value, name) => {
    setFormData({ ...formData, [name]: value });
    if (value) {
      setFileUploadError(null);
    } else {
      setFileUploadError(t("validation.required"));
    }
  };

  const handleSubmit = () => {
    if (formData?.upload_documents) {
      navigate(`/motor-insurance/claim-details/${urlSafeEncryptedValue}`);
    } else {
      setFileUploadError(t("validation.required"));
    }
  };
  const handleCloseBtnClick = () => {
    navigate(`/motor-insurance/claim-details/${urlSafeEncryptedValue}`);
  };

  return (
    <>
      {mobile && (
        <button
          className="bpy-2 rounded mt-4 flex absolute top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:mt-12"
          onClick={handleCloseBtnClick}
        >
          <img src={closeicon} alt="Close Icon" />
        </button>
      )}
      <div className="mx-auto bg-white max767:p-5 w-[500px] max-w-[100%] mt-[5%] ipad:mt-[11%] max767:mt-0">
        <div className="max767:hidden">
          <TitleDescription
            title={t("motorInsurance.fileAClaimFlow.repairOrCashPage.title")}
            description={t(
              "motorInsurance.fileAClaimFlow.repairOrCashPage.description"
            )}
          />
        </div>
        <div className="max767:block hidden max767:mb-6 max767:mt-0">
          <TitleDescription
            title={t(
              "motorInsurance.fileAClaimFlow.repairOrCashPage.mobile_title"
            )}
            description={t(
              "motorInsurance.fileAClaimFlow.repairOrCashPage.mobile_description"
            )}
          />
        </div>
        <div>
          <DragDropFileUpload
            className="flex-col"
            label={t(
              "motorInsurance.trackClaimFlow.uploadDocumentPage.uploadLabel"
            )}
            onUploadComplete={handleUploadComplete}
            name="upload_documents"
            multiple
            toggleFileUpload={onFileUploaded}
          />
          {fileUploadError && (
            <p className="text-red-500 text-sm">{fileUploadError}</p>
          )}
        </div>
        <Button
          label={t("motorInsurance.trackClaimFlow.uploadDocumentPage.submit")}
          className="flex flex-col items-center gap-2.5 self-stretch bg-[#55C6EF] p-4 rounded-[10px] w-full mt-4 text-lg"
          onSubmit={handleSubmit}
        />
      </div>
    </>
  );
};

export default UploadDocuments;
