import React from "react";
import googleplay from "../../../../../assets/images/Google.svg";
import apple from "../../../../../assets/images/Apple.svg"; //
import checkcircle from "../../../../../assets/images/check_circlelarge.svg";

import { useTranslation } from "react-i18next";
const Congratulations = () => {
      const { t } = useTranslation();

  return (
    <div className="min-h-screen flex flex-col items-start mt-32 justify-start p-4 w-[33%] mx-auto my-0 max767:w-full max767:items-start max767:mt-0">
      <div className="mb-4">
       <img src={checkcircle} alt="check circle"/>
      </div>
      <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] text-left">
        Congratulations on Successfully Uploading Your Photos!
      </h1>
      <p className=" mb-4 text-left text-[#1A2024] text-base not-italic font-normal leading-[150%]">
        You have successfully uploaded photos of your car from all required
        angles. Head back to your application to continue.
      </p>

      <div className="flex space-x-4 mt-1 mb-4">
        <p className="text-[#5B6871] text-sm not-italic font-normal leading-5 ">
          {t(
            "motorInsurance.newMotorInsuranceFlow.paymentSuccess.for_inquiries"
          )}{" "}
          <a
            href="#"
            className="text-[#5B6871] text-sm not-italic font-medium leading-[150%] underline"
          >
            {t("motorInsurance.newMotorInsuranceFlow.paymentSuccess.whatsapp")}
          </a>{" "}
          {t("motorInsurance.newMotorInsuranceFlow.paymentSuccess.or_call")}{" "}
          <a
            href="#"
            className="text-[#5B6871] text-sm not-italic font-medium leading-[150%] underline"
          >
            17130000
          </a>{" "}
        </p>
      </div>
      <div className="flex justify-start space-x-4 w-full">
        <button className="flex gap-1">
          <img src={apple} alt="app store" className="h-10" />
          <img src={googleplay} alt="Google Play" className="h-10" />
        </button>
      </div>
    </div>
  );
};

export default Congratulations
