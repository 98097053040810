import React from "react";
import Button from "../Button/Button";
import useScreenResolution from "../../../hooks/useScreenResolution";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";

const LifeDynamicTable = ({ data }) => {
  const screenResolution = useScreenResolution();
  const translate = useLanguageTranslate();
    console.log(data,'some data')
  return (
    <div className="max767:p-5 buyonlintable1 max767:pt-0">
      {/* Loop through the array of table datasets */}
      {data?.map((tableData, tableIndex) => (
        <div key={tableIndex} className="mb-4">
          {" "}
          {console.log(tableData, "tableData")}
          {/* Adding margin between tables */}
          <div className="flex justify-between w-full align-middle items-center mt-6 mb-2 BeneficiaryDetailsbox min-h-[40px]">
          <h3 className=" text-base not-italic font-semibold">
            {tableData?.tableTitle &&
              translate(tableData.tableTitle, tableData.tableTitle_ar)}
          </h3>
            {tableData?.deleteBtn &&
              <h3 className="text-base not-italic font-semibold">
                {tableData?.deleteBtn}
              </h3>}
          </div>
          <table className="min-w-full bg-white border border-gray-200 border-separate border-spacing-0 rounded-[10px] owner-table border-l-[#DDE2E4] border-solid max767:border-0 rtl:border-l-[#DDE2E4] rtl:border-solid rtl:border-l ">
            <tbody>
              {tableData?.data?.map((row, rowIndex) => (
                <tr
                  key={row.id || rowIndex}
                  className=" md:border-b"
                >
                  <td className=" py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6 max767:text-xs max767:not-italic max767:font-medium max767:leading-6 rtl:border-r-0">
                    {translate(row?.column1, row?.column1_ar)}
                  </td>
                  <td
                    key={row?.key}
                    className=" py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6 justify-between items-center  max767:border-r-0 rtl:border-r"
                  >
                    <span className="flex">
                      {row.icon && !screenResolution.mobile && (
                        <img
                          src={row.icon}
                          alt={row.column2}
                          className="mr-2 rtl:ml-2"
                        />
                      )}
                      {row?.column2}
                    </span>
                    {row?.btn && (
                      <Button
                        label={translate(
                          row?.btn?.btn_label_en,
                          row?.btn?.btn_label_ar
                        )}
                        className="text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] flex flex-col items-center gap-2.5 rounded border bg-[#F6F8F9] px-2 py-0 border-solid border-[#DDE2E4] "
                        onSubmit={row?.btn?.onSubmit}
                      />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default LifeDynamicTable;
