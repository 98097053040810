import React, { useEffect, useState } from "react";
import "./GetVerifiedPage.css";
import Button from "../../../components/common/Button/Button";
import Loader from "../../../components/common/Loader/Loader";
import { useTranslation } from "react-i18next";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import useScreenResolution from "../../../hooks/useScreenResolution";
import BackBtnWithLabel from "../../../components/common/backBtnWithLabel/backBtnWithLabel";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { verifySession } from "../../../redux/features/signUp/createVeriffSessionSlice";
import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk";
import VerificationFail from "../../../assets/images/verificationFail.svg";
import {
  selectCprFromKycVerification,
  verifyKyc,
} from "../../../redux/features/signUp/kycVerificationStatusSlice";
import { db } from "../../../firebase";
import useFirestoreDoc from "../../../hooks/useFirestoreDoc";

const GetVerifiedPage = ({ onSuccess }) => {
  const { mobile } = useScreenResolution();
  const translate = useLanguageTranslate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [sessionStatus, setSessionStatus] = useState(null);

  const { signupDetails } = useSelector((state) => state.signup);
  const cprFromVerifKyc = useSelector(selectCprFromKycVerification);
  const cprNumber = signupDetails?.cprNumber || cprFromVerifKyc;

  const { data, loading } = useFirestoreDoc(db, "veriff-decisions", cprNumber);

  useEffect(() => {
    if (data?.status == "approved") {
      // toast.success("Your Kyc Verification has been approved")
      setIsLoading(false);
      onSuccess();
    }
  }, [data]);

  const [isLoading, setIsLoading] = useState(true);

  const initiateVerification = async () => {
    const payload = signupDetails?.cprNumber || cprFromVerifKyc;
    setIsLoading(true);
    const response = await dispatch(verifySession(payload));
    setIsLoading(false);
    if (response?.payload?.verification?.url) {
      const redirectUrl = response?.payload?.verification?.url;
      if (redirectUrl) {
        const veriffFrame = createVeriffFrame({
          url: redirectUrl,
          onEvent: async (msg) => {
            switch (msg) {
              case MESSAGES.CANCELED:
                setSessionStatus("CANCELED");
                break;

              case MESSAGES.FINISHED:
                //await handleKycVerificationStatus();
                setIsLoading(true);
                break;
              default:
                console.log("Unhandled event: ", msg);
                break;
            }
          },
        });
      } else {
        toast.error(
          translate(
            "Unable to get the verification URL. Please try again.",
            "غير قادر على الحصول على رابط التحقق. الرجاء المحاولة مرة أخرى."
          )
        );
      }
    } else {
      toast.error(
        translate(
          response?.payload?.ErrorMessageEn,
          response?.payload?.ErrorMessageAr
        )
      );
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      initiateVerification();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  const retryVerification = () => {
    setSessionStatus(null);
    initiateVerification();
  };

  console.log(data, "some data");
  return (
    <div className="verify_otp_page mt-20 flex h_100 p-0 justify-center items-center mb-3 max767:p-5 max767:mt-20 w-[500px] max-w-[100%] mx-auto">
      {/* <Loader /> */}
      <div className="get_verified_page verify-content">
        <div className="content flex flex-col align-middle justify-center">
          {mobile && (
            <BackBtnWithLabel label={translate("Sign up", "التسجيل")} />
          )}
          <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 mb-3 tracking-[-0.24px] text-center max767:text-left max767:text-[28px] max767:font-semibold max767:w-full">
            {t("signUpFlow.getVerifiedPage.page_title")}
          </h1>
          <p className="text-[#1A2024] text-center text-base not-italic font-normal leading-6 mb-3  tracking-[-0.16px] max767:mb-8 max767:text-black max767:text-sm max767:not-italic max767:font-normal">
            {t("signUpFlow.getVerifiedPage.page_description")}
          </p>
        </div>

        {/* {data?.status != "approved" && <p className="text-[#5B6871] text-center text-sm not-italic font-normal leading-5">
          {translate(data?.messageEn, data?.messageAr)}
        </p>} */}

        <div className="verify_loader flex flex-col justify-center items-center gap-4 self-stretch bg-[#F6F8F9] p-6 rounded-2xl mb-6">
          {sessionStatus == "CANCELED" || data?.status == "declined" ? (
            <>
              <img src={VerificationFail} alt="Verification Canceled" />
              <p>
                <b className="text-[#252C32] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px]">
                  {data?.messageEn && data?.messageAr
                    ? translate(data.messageEn, data.messageAr)
                    : t("signUpFlow.getVerifiedPage.verification_failed_title")}
                </b>
              </p>
              <p className="text-[#5B6871] text-center text-sm not-italic font-normal leading-5">
                {data?.subMsgEn && data?.subMsgAr
                  ? translate(data.subMsgEn, data.subMsgAr)
                  : t(
                      "signUpFlow.getVerifiedPage.verification_failed_description"
                    )}
              </p>
            </>
          ) : (
            <>
              {(isLoading || loading) && (
                <div className="h-[50px] w-[50px] flex relative">
                  <Loader className="left-[-5px]" />
                </div>
              )}

              <p>
                <b className="text-[#252C32] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px]">
                  {t("signUpFlow.getVerifiedPage.verify_your_account_label")}
                </b>
              </p>
              <p className="text-[#5B6871] text-center text-sm not-italic font-normal leading-5">
                {t("signUpFlow.getVerifiedPage.dont_close_label")}
              </p>
            </>
          )}
        </div>
        {(sessionStatus == "CANCELED" ||
          (data?.status == "declined" && data?.attempt < 4)) && (
          <Button
            label={t("signUpFlow.getVerifiedPage.btn_label")}
            className="sign-in-btn primary-button"
            onSubmit={retryVerification}
          />
        )}

        {/* <Button
          label={"Start Verification"}
          className="sign-in-btn primary-button"
          onSubmit={retryVerification}
        /> */}
      </div>
    </div>
  );
};

export default GetVerifiedPage;
