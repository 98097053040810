import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFundManagementDocs, fetchLifePolicyDetails, selectLifePolicyDataWhole } from '../../redux/features/life/lifePolicyDetailSlice'

const useLifePolicyDetails = (PolicyNumber) => {
    const { data, isLoading ,fundManagementLoading , fundManagementData } = useSelector(selectLifePolicyDataWhole)
    const dispatch = useDispatch()
    const fetchPolicyDetailsHandler = async () => {
        let payload = {
            PolicyNumber
        }
        await dispatch(fetchLifePolicyDetails(payload))
    }
    useEffect(() => {
        if(!isLoading && data?.PolicyDetails?.PolicyNumber !=PolicyNumber ){
            fetchPolicyDetailsHandler();
        }
        if(!fundManagementLoading&&!fundManagementData){
            dispatch(fetchFundManagementDocs())
        }
    }, [])
   
    return { data, isLoading ,fundManagementLoading ,fundManagementData }
}

export default useLifePolicyDetails