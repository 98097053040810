import React, { useEffect, useState } from 'react'
import Input from '../../../../../components/common/Input/Input'
import useLanguageTranslate from '../../../../../hooks/useLanguageTranslate'

const SharePercentageTable = ({ data , values,setValues , total }) => {
    const translate = useLanguageTranslate()

    useEffect(() => {
        let update = {};
        data?.forEach((each,i) => {
            update[i] = each.share_percentage;
        })
        setValues(update);
    }, [data])

    const inputChangeHandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if(parseInt(value)>100){return}
        setValues({ ...values, [name]: value })
    }
    return (
        <table className="min-w-full border-separate border-spacing-0 border rounded-[10px] border-b-0 max767:border-t-0 max767:border-l-0 max767:border-r-0 max767:rounded-none max767:border-b-0">
            <thead>
                <tr className="max767:hidden">
                    <th className="border-b p-2 text-left text-[#6E7C87] text-xs not-italic font-semibold leading-6 tracking-[-0.072px] max767:pl-0">
                       {translate("Beneficiary Name","اسم المستفيد")}
                    </th>
                    <th className="border-b p-2 text-left text-[#6E7C87] text-xs not-italic font-semibold leading-6 tracking-[-0.072px] max767:pl-0">
                        {translate("Share Percentage","نسبة الحصة")}
                    </th>
                </tr>
            </thead>
            <tbody>
                {data?.map((each, rowIndex) => (
                    <tr className={`max767:flex`}>
                        <td className="max767:flex p-2 text-[#1A2024] text-sm not-italic font-normal leading-6 border-b max767:p-3 max767:pl-0 max767:w-[70%]">
                            {each?.beneficiary_name}
                        </td>
                        <td className="max767:flex edit-share p-2 text-[#1A2024] text-sm not-italic font-normal leading-6 tableinput border-b max767:justify-end max767:p-3 max767:pl-0 max767:w-[30%]">
                            <Input 
                                type="number"
                                value={values[rowIndex]}
                                handleChange={inputChangeHandler}
                                name={rowIndex + ""}
                                endAdornment={ <div className='bg-gray-100'>%</div> }
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
            <tfoot className=''>
                <tr className='max767:flex'>
                    <td className="p-2 text-[#1A2024] text-sm not-italic font-semibold leading-6 border-b max767:p-3 max767:pl-0 max767:border-b-0 max767:w-[70%]">{translate("Total","المجموع")}</td>
                    <td className=" p-2 text-[#1A2024] text-sm not-italic font-semibold leading-6 border-b max767:p-3 max767:pl-0 max767:flex  max767:border-b-0 max767:w-[30%]">{total||0}%</td>
                </tr>
            </tfoot>
        </table>
    )
}

export default SharePercentageTable

