import React from "react";
import useExpressShieldFlow from "../../../../hooks/BuyOnlineExpressShield/useExpressShieldFlow";
import Declaration from "./declaration";
import ChoosePlan from "./choosePlan";
import BeneficiaryDetails from "./beneficiaryDetails";

const Plan = () => {
  const { currentSubstep, handleNextSubstep } = useExpressShieldFlow();
  const onSuccess = () => {
    handleNextSubstep();
  };
  return (
    <>
      {currentSubstep =="declaration" && <Declaration onSuccess={onSuccess} />}
      {currentSubstep =="choose_plan" && <ChoosePlan onSuccess={onSuccess} />}
      {currentSubstep =="beeficiary_details" && (
        <BeneficiaryDetails onSuccess={onSuccess} />
      )}
    </>
  );
};

export default Plan;
