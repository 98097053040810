import React, { useEffect, useState } from "react";
import NewMotorJourneyLayout from "../Layouts/NewMotorJourneyLayout";
import BottomStickyBtnLayout from "../Layouts/BottomStickyBtnLayout";
import useMotorFlow from "../../../../hooks/useMotorFlow";
import UploadScan from "./uploadScan";

const UploadPhotosOfCar = ({ backClickHandler, onSuccess }) => {
  const { handleNextSubstep  } = useMotorFlow()
  const NextHandler = () => {
    handleNextSubstep()
  };


  return (
    <NewMotorJourneyLayout backClickHandler={backClickHandler}>
      <BottomStickyBtnLayout
        btn_onClick={NextHandler}
        show_summary
      >
       <UploadScan />
      </BottomStickyBtnLayout>
    </NewMotorJourneyLayout>
  );
};

export default UploadPhotosOfCar;
