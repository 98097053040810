import React from "react";
import RenewMotorLayout from "../../renewalMotorPolicy/renewMotorLayout";
import UploadScan from "../../newMotorPolicy/uploads/uploadScan";
import useChangePlanFlow from "../../../../hooks/useChangePlanFlow";

const UploadPhotosOfCar = () => {
  const { handleNextSubstep, handlePrevSubstep  } = useChangePlanFlow()
  const NextHandler = () => {
    handleNextSubstep()
  };


  return (
    <RenewMotorLayout backClickHandler={handlePrevSubstep} show_summary btn_onClick={NextHandler}>
       <UploadScan />
    </RenewMotorLayout>
  );
};

export default UploadPhotosOfCar;