import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { AxiosStrapi } from "../../axiosInstance";
import { lifeApiEndPoints, strapiApiEndPoints } from "../../../api";
import { TYPE_BALANCED, TYPE_CONSERVATIVE, TYPE_GROWTH } from "../../../pages/lifeInsurancePages/PolicyOverview/fundManagementTab/investmentStrategyChangeFlow/data";

const initialState = {
    data: null,
    isLoading: false,
    error: null,
    fundManagementLoading : false,
    fundManagementData : null
};

export const fetchLifePolicyDetails = createAsyncThunk(
    "fetchLifePolicyDetails",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await Axios.post(lifeApiEndPoints.lifePolicyDetails, payload);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);
export const fetchFundManagementDocs = createAsyncThunk(
    "fetchFundManagementDocs",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await AxiosStrapi.get(strapiApiEndPoints.fundManagement, payload);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const lifePolicyDetailSlice = createSlice({
    name: "Life Policy Detail Slice",
    initialState,
    reducers: {
        clearPolicyError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLifePolicyDetails.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchLifePolicyDetails.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchLifePolicyDetails.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error?.message;
        });

        ///////////
        builder.addCase(fetchFundManagementDocs.pending, (state) => {
            state.fundManagementLoading = true;
        });
        builder.addCase(fetchFundManagementDocs.fulfilled, (state, action) => {
            state.fundManagementLoading = false;
            state.fundManagementData = action.payload?.data?.attributes;
        });
        builder.addCase(fetchFundManagementDocs.rejected, (state, action) => {
            state.fundManagementLoading = false;
            state.error = action.error?.message;
        });

    },
});

// Selectors
export const selectLifePolicyDataWhole = (store) => store?.lifePolicyDetail;
export const selectLifePolicyData = (store) => store?.lifePolicyDetail.data;
export const selectFundManagementDoc = (store,type) => {
    let docs = store?.lifePolicyDetail.fundManagementData;

    if(type===TYPE_CONSERVATIVE){
       return docs.conservative?.data?.attributes?.url
    }
    else if (type===TYPE_BALANCED){
        return docs.balanced?.data?.attributes?.url
    }
    else if (type===TYPE_GROWTH){
        return docs.growth?.data?.attributes?.url
    }
    return docs
};
export const selectLifePolicyLoader = (store) => store?.lifePolicyDetail.isLoading;

// Actions
export const { clearPolicyError } = lifePolicyDetailSlice.actions;

export default lifePolicyDetailSlice.reducer;
