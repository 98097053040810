import React, { useState } from 'react'
import TitleDescription from '../../../../components/newMotorPolicy/titleDescription/titleDescription';
import { useTranslation } from 'react-i18next';
import useMotorForm from '../../../../hooks/useMotorFormData';
import { PROMO_CODE } from '../../../../components/newMotorPolicy/veiwSummaryPopup/viewSummaryPopup';
import { useNavigate } from 'react-router-dom';
import RenewMotorLayout from '../../renewalMotorPolicy/renewMotorLayout';
import ReviewMotorTable from '../../renewalMotorPolicy/reviewAndConfirmPage/reviewMotorTable';
import TPL from "../../../../assets/images/thirdparty.svg";
import FL from "../../../../assets/images/Flexi.svg";
import GO from "../../../../assets/images/Gold.svg";
import PL from "../../../../assets/images/Platinium.svg";
import OFL from "../../../../assets/images/OnlineFlexi.svg";


import useChangePlanFlow, { CHANGE_PLAN_UPGRADE } from '../../../../hooks/useChangePlanFlow';
import PromoCode from '../../../../components/newMotorPolicy/veiwSummaryPopup/promoCode';
import Summary from '../../../../components/newMotorPolicy/veiwSummaryPopup/summary';
import forwardarrow from "../../../../assets/images/arrow_forwardb.svg";
import RefundSummary from '../../../../components/newMotorPolicy/veiwSummaryPopup/refundSummary';
import { getPolicyViewResponse } from '../../../../redux/features/policyDetailsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getQuoteWithRefundResponse } from '../../../../redux/features/endorsements/cancellationSlice';
import { getPolicyPayLoading, policyPay } from '../../../../redux/features/payment/policyPaySlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getPolicyPremiumLoading } from '../../../../redux/features/motor/renewalPremiumSlice';
import { Loader } from 'storybook/internal/components';
import useLanguageTranslate from '../../../../hooks/useLanguageTranslate';
import Button from '../../../../components/common/Button/Button';
import CustomCheckbox from '../../../../components/common/customCheckbox/customCheckbox';

// Create a mapping of plan code to image and name
const planMap = {
  "TPL": {
    image: TPL,
    text: "Third Party",
    text_ar:"طرف ثالث"
  },
  "FL": {
    image: FL,
    text: "Flexi",
    text_ar:"المرن"
  },
  "GO": {
    image: GO,
    text: "Gold",
    text_ar:"ذهبي"
  },
  "PL": {
    image: PL,
    text: "Platinum",
    text_ar:"بلاتيني"
  },
  "OFL": {
    image: OFL,
    text: "Online Flexi",
    text_ar:"المرن اونلاين"

  },
  "OPL": {
    image: PL,
    text: "Platinum",
    text_ar:"بلاتيني"
  },
  // add other plan names as needed
};

const ReviewAndConfirmPage = () => {
  /* Setting up dispatch to call redux */
  const dispatch = useDispatch();

  /* Setting up variables with appropriate requests to send and response to receive, reading from redux/functions */
  const navigate = useNavigate();
  const translate = useLanguageTranslate()
  
  const { t } = useTranslation();
  const { handlePrevSubstep } = useChangePlanFlow();

  const [formState, setFormState] = useState({});
  
  const [isChecked, setIsChecked] = useState(false);
  const declaration = t(
    "motorInsurance.changePlanMotorPolicy.reviewAndConfirmPage.declaration",
    { returnObjects: true }
  );
  const { saveMotorDataInRedux, removeMotorFormData } = useMotorForm(
    PROMO_CODE,
    setFormState
  );
  
  const policyViewResponseDetails = useSelector(getPolicyViewResponse);
  const quoteWithRefundResponse = useSelector(getQuoteWithRefundResponse);
  const policyPayLoading = useSelector(getPolicyPayLoading);

  // Setting up policy pay
  const quoteNumber = quoteWithRefundResponse.PolicyNumber
  const totalPremium = quoteWithRefundResponse.TotalPremium;

  const { flowType } = useChangePlanFlow();
  console.log(flowType);
  const isUpgrade = flowType ==CHANGE_PLAN_UPGRADE;

  // Create a mapping of PlanName to image
  const planName = policyViewResponseDetails?.PolicyHeader?.PlanName;

  const applyPromoCode = () => {
    saveMotorDataInRedux(formState);
  };

  


  
  
  /* Next / Payment */
  const handlePayment = async () => {
    if (quoteNumber && totalPremium) {
      const payload = {
        PolicyNumber: quoteNumber,
        Premium: totalPremium,
        Channel: "online.solidarity",
        IsChangedPlan: true,
        PreviousPolicyNumber: policyViewResponseDetails.PolicyHeader?.PolicyNumber 
      };

      try {
        // Calling API policy/pay
        const linkAction = await dispatch(policyPay(payload));
        const link = unwrapResult(linkAction)
        // console.log("Link is: ", link)
        if (link) {
          window.location.href = link;
        }

      }
      catch (error) {
        // alert("Error in getting payment link: ", error)
        toast.error("Error in getting payment link: ", error);
      }
      
    }
  };
  
  // const handleCongratulations = () => {
  //   navigate("/motor-insurance/payment-success");
  // };


  return (
    <RenewMotorLayout
      backClickHandler={handlePrevSubstep}
      is_bottom_sticky_layout={false}
    >
      <div className="flex flex-col items-center mx-auto max-w-lg">
        <div className="flex justify-center items-center w-full">
          <div className="p-0 w-full max767:p-4">
            <div className="max767:mt-20">
              {isUpgrade ? (
                <TitleDescription
                  title={t(
                    "motorInsurance.changePlanMotorPolicy.reviewAndConfirmPage.upgrade_title"
                  )}
                  description={t(
                    "motorInsurance.changePlanMotorPolicy.reviewAndConfirmPage.upgrade_description"
                  )}
                />
              ) : (
                <TitleDescription
                  title={t(
                    "motorInsurance.changePlanMotorPolicy.reviewAndConfirmPage.downgrade_title"
                  )}
                  description={t(
                    "motorInsurance.changePlanMotorPolicy.reviewAndConfirmPage.downgrade_description"
                  )}
                />
              )}
            </div>
            {isUpgrade ? (
              <div className="mt-10">
                <div className="bg-[#F6F8F9] mb-6 p-4 rounded-2xl">
                  <h4 className="text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px] mb-4">
                    {t(
                      "motorInsurance.changePlanMotorPolicy.reviewAndConfirmPage.upgrade_plan"
                    )}
                  </h4>

                  {/* Previous Plan */}
                  <div className="plan flex align-middle items-center gap-5">
                    <img
                      src={
                        planMap[
                          policyViewResponseDetails?.PolicyHeader?.PlanName
                        ]?.image
                      }
                      className="bg-[#E5E9EB] items-center p-2 rounded-[10px]"
                      alt=""
                    />
                    <p className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px]">
                      {" "}
                      {translate(
                        planMap[
                          policyViewResponseDetails?.PolicyHeader?.PlanName
                        ]?.text,
                        planMap[
                          policyViewResponseDetails?.PolicyHeader?.PlanName
                        ]?.text_ar
                      ) || "-"}
                    </p>
                  </div>
                  <div className="line h-6 border-l-2 ml-8"></div>

                  {/* New Plan */}
                  <div className="plan flex align-middle gap-5 mb-5 items-center">
                    <img
                      src={planMap[quoteWithRefundResponse?.PlanName]?.image}
                      className="bg-[#FFF] items-center p-2 rounded-[10px]"
                      alt=""
                    />
                    {/* <p className='text-[#48535B] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px]'>{t("motorInsurance.changePlanMotorPolicy.reviewAndConfirmPage.gold")}</p> */}
                    <p className="text-[#48535B] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px]">
                      {" "}
                      {translate(
                        planMap[quoteWithRefundResponse?.PlanName]?.text,
                        planMap[quoteWithRefundResponse?.PlanName]?.text_ar
                      ) || "-"}
                    </p>
                  </div>
                  <div className="reviwetable">
                    <ReviewMotorTable passedFrom={"MotorChangeActivePlan"} />
                  </div>
                </div>
                <div className="bg-[#F6F8F9] rounded-[26px] p-4 mb-4 w-full summary-box">
                  <PromoCode
                    promoCode={formState.promoCode}
                    setPromoCode={(code) =>
                      setFormState((prevState) => ({
                        ...prevState,
                        promoCode: code,
                      }))
                    }
                    applyPromoCode={applyPromoCode}
                    clearPromoCode={() => {
                      setFormState({});
                      removeMotorFormData({});
                    }}
                  />

                  <Summary passedFrom={"MotorChangeActivePlan"} />

                  <div className="flex">
                    <CustomCheckbox
                      Label={
                        <div className="flex">
                          <label
                            htmlFor="agreement"
                            className="text-[#252C32] text-sm not-italic font-normal leading-6"
                          >
                            {declaration?.map((paragraph, index) => (
                              <p className="mb-2" key={index}>
                                {paragraph}
                              </p>
                            ))}
                          </label>
                        </div>
                      }
                      isChecked={isChecked}
                      setIsChecked={setIsChecked}
                    />
                  </div>

                  {/* Proceed Button */}
                  <Button
                    isLoading={policyPayLoading}
                    className={`bg-[#55C6EF] mt-4 w-full text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] flex justify-center p-3 rounded-[10px]`}
                    onClick={handlePayment}
                    label={t(
                      "motorInsurance.adjustSumInsuredFlow.reviewAndConfirmPage.proceed_to_payment"
                    )}
                    post_icon={forwardarrow}
                    post_icon_class={"w-6 h-6 ml-[5px] rtl:rotate-180 rtl:mr-2"}
                  />
                </div>
              </div>
            ) : (
              // Not upgrade?
              <div className="mt-10">
                <div className="bg-[#F6F8F9] mb-6 p-4 rounded-2xl">
                  <h4 className="text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px] mb-4">
                    {t(
                      "motorInsurance.changePlanMotorPolicy.reviewAndConfirmPage.downgrade_plan"
                    )}
                  </h4>
                  {/* <div className="plan flex align-middle items-center gap-5">
                      <img src={policyViewResponseDetails?.PolicyHeader?.PlanName} className='bg-[#E5E9EB] items-center p-2 rounded-[10px]' alt="" />
                      <p className='text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px]'>{t("motorInsurance.changePlanMotorPolicy.reviewAndConfirmPage.gold")}</p>
                    </div>
                    <div className="line h-6 border-l-2 ml-8"></div>
                    <div className="plan flex align-middle gap-5 mb-5 items-center">
                      <img src={policyViewResponseDetails?.PolicyHeader?.PlanName} className='bg-[#FFF] items-center p-2 rounded-[10px]' alt="" />
                      <p className='text-[#48535B] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px]'>{t("motorInsurance.changePlanMotorPolicy.reviewAndConfirmPage.third_party")}</p>
                    </div> */}
                  <div className="reviwetable">
                    <ReviewMotorTable passedFrom={"MotorChangeActivePlan"} />
                  </div>
                </div>
                <div className="bg-[#F6F8F9] rounded-[26px] p-4 mb-4 w-full summary-box">
                  <RefundSummary />
                </div>
                <button
                  className={`bg-[#55C6EF] mt-4 w-full text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] flex justify-center p-3 rounded-[10px]`}
                  // onClick={handleCongratulations}
                >
                  <span>
                    {t(
                      "motorInsurance.changePlanMotorPolicy.reviewAndConfirmPage.confirm"
                    )}
                  </span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </RenewMotorLayout>
  );
}

export default ReviewAndConfirmPage