import React, { useEffect, useState } from "react";
import NewPlateNumber from "./newPlateNumber";
import UploadLetterOfDocument from "./uploadLetterOfDocument";
import { useNavigate, useSearchParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { retrieveVehicleFromGDT } from "../../../redux/features/motor/gdtSlice";
import { useDispatch } from "react-redux";
import ChangePlateNumberPopup from "./changePlateNumberPopup";

const ChangePlateNumberFlow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const policyNum = searchParams.get("policy");
  const RegNo = searchParams.get("regNo");
  const chassis = searchParams.get("chassis");
  const [GDTPlateNo, setGDTPlateNOValue] = useState("");

  const [ChangedPlateNO, SetChangedPlateNOValue] = useState("");
  const [PlateType, SetPlateTypeValue] = useState("");

  //console.log("REGNO" + RegNo);
  const gdtPayload = {
    PlateOrChassisNo: chassis,
    PlateType: "01",
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [toggle, setToggle] = useState(false);

  const checkGDT = async () => {
    const resultAction = await dispatch(retrieveVehicleFromGDT(gdtPayload));
    if (resultAction.error?.message != "Rejected") {
      const vehicleData = await unwrapResult(resultAction);

      if (
        vehicleData.VehicleDetails.ChassisNumber == chassis &&
        RegNo == vehicleData.VehicleDetails.PlateNumber
      ) {
        openModal();
      } else if (
        vehicleData.VehicleDetails.ChassisNumber == chassis &&
        RegNo != vehicleData.VehicleDetails.PlateNumber
      ) {
        // GDTPlateNo = vehicleData.VehicleDetails.PlateNumber;
        setGDTPlateNOValue(vehicleData.VehicleDetails.PlateNumber);
      }
    }
  };

  const checkGDTinComp = async (gdtPayload) => {
    const resultAction = await dispatch(retrieveVehicleFromGDT(gdtPayload));
    if (resultAction.error?.message != "Rejected") {
      const vehicleData = await unwrapResult(resultAction);
      return vehicleData;
    } else {
      return "Error";
    }
  };
  useEffect(() => {
    checkGDT();
  });

  const switchToggle = (plateNo, PlateType) => {
    SetChangedPlateNOValue(plateNo);
    SetPlateTypeValue(PlateType);
    setToggle((state) => !state);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate(-1);
  };

  return (
    <div className="mainCarDetailsModal pt-[80px] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll max767:pt-[0]">
      {isModalOpen && (
        <ChangePlateNumberPopup
          onClose={closeModal}
          show="true"
          btn_label={"Home"}
          message1={"Your plate number is already updated in the system"}
          message2={" "}
          title={"Plate number is already updated"}
        />
        // <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
        //   <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
        //     <h2 className="text-lg font-semibold mb-2">Plate Number already updated</h2>
        //     <p className="mb-4">Your plate number is already update in the system</p>
        //     <button
        //       onClick={closeModal}
        //       className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        //     >
        //       Close
        //     </button>
        //   </div>
        // </div>
      )}

      {!toggle ? (
        <NewPlateNumber
          switchToggle={switchToggle}
          Policy={policyNum}
          PlateNumber={RegNo}
          Chassis={chassis}
          checkGDT={checkGDTinComp}
          GDTPlateNo={GDTPlateNo}
        />
      ) : (
        <UploadLetterOfDocument
          switchToggle={switchToggle}
          NewPlateNo={ChangedPlateNO}
          PlateType={PlateType}
          Policy={policyNum}
        />
      )}
    </div>
  );
};

export default ChangePlateNumberFlow;
