import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import {
  fetchProfileInfo,
  selectProfileData,
  selectProfileDataLoader,
} from "../../redux/features/profileSlice";
import { useNavigate } from "react-router-dom";
import useScreenResolution from "../../hooks/useScreenResolution";
import Input from "../../components/common/Input/Input";
import TelInput from "../../components/common/telInput/telInput";
import Button from "../../components/common/Button/Button";
import errorImg from "../../assets/images/confirmEmail.svg"
import { Tooltip } from "react-tooltip";


const ProfileDetails = () => {
  const { t } = useTranslation();
  const screenResolution = useScreenResolution();
  const [formData, setFormData] = useState({
    fullname: "",
    cpr: "",
    mobile: "",
    email: "",
  });
  const translate = useLanguageTranslate();
  const navigate = useNavigate();
  const isLoading = useSelector(selectProfileDataLoader);
  const profileData = useSelector(selectProfileData);
  const [showTooltip, setShowTooltip] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProfileInfo());
  }, []);
  useEffect(() => {
    if (profileData) {
      setFormData({
        fullname: `${profileData.FirstName || ""} ${
          profileData?.LastName || ""
        }`,
        cpr: profileData.CPR,
        mobile: profileData.PhoneNumber,
        email: profileData.Email,
      });
    }
  }, [profileData]);
 
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  return (
    <div className="yourProfile mb-4  px-[20px]">
      <Tooltip id="profile-tooltip" place="bottom" />
      {screenResolution.mobile && (
        <h2 className="text-black text-xl not-italic font-semibold leading-[120%]">
          {translate("Your Profile", "ملفك الشخصي")}
        </h2>
      )}
      <div className="inputGrid px-0 py-6 flex flex-col md:flex-row md:space-x-4 max767:w-full max767:pb-0 rtl:gap-3 rtl:md:space-x-0">
        <Input
          label={t("profileSettings.full_name")}
          type="text"
          disabled
          name="fullname"
          className="text-[#5B6871]"
          value={formData?.fullname || ""}
          setFormData={setFormData}
          handleChange={handleChange}
        />
        <Input
          label={t("profileSettings.cpr_cr_number")}
          type="text"
          disabled
          name="cpr"
          className="text-[#5B6871]"
          setFormData={setFormData}
          value={formData?.cpr || ""}
          handleChange={handleChange}
        />
      </div>
      <div className="flex ipad:flex max767:flex-col rtl:md:space-x-0  rtl:gap-3">
        <div className="input-columns  w-[45%] w-full md:w-1/2 profile-seting-multitel rtl:pr-0 relative text-[#5B6871]">
          <TelInput
            value={formData?.mobile || ""}
            onChange={(val) => {
              console.log(val);
            }}
            label={t("profileSettings.mobile_number")}
            className="mobilenumber text-[#5B6871]"
            handleChange={handleChange}
            disabled
          />

          <Button
            label={t("profileSettings.change_mobile_number")}
            onSubmit={() => navigate("/change-mobile-number")}
            className="text-[#00384D] text-sm not-italic font-semibold leading-[26px] tracking-[-0.14px] underline absolute left-0 bottom-[0px] max767:relative bg-transparent hover:bg-transparent max767:mb-8"
          />
        </div>
        <div className="input-columns  pl-2 w-[45%] w-full md:w-1/2 profile-seting-multitel max767:pl-0 rtl:pl-0 relative ">
          <Input
            label={t("profileSettings.email_address")}
            type="email"
            disabled
            value={formData?.email || ""}
            className="text-[#5B6871]"
            handleChange={handleChange}
            // endAdornment={(showTooltip && !screenResolution.mobile) ?(<img
            //   src={errorImg}
            //   alt="Confirm email"
            //   data-tooltip-id="profile-tooltip"
            //   data-tooltip-content="Confirm your email"

            // />): ""}
          />
          {/* <div className="confirm-email flex max767:justify-between">
          <Button
            className="text-[#00384D] text-sm not-italic font-semibold leading-[26px] tracking-[-0.14px] underline max767:mb-4 absolute left-2 bottom-[0px] max767:relative bg-transparent hover:bg-transparent"
            label={t("profileSettings.verify_email")}
            onSubmit={() => navigate("/change-email")}
          />
          {screenResolution.mobile && <button 
            type="submit"
            className="flex text-[#A56213] text-xs not-italic font-semibold leading-6"
          >
            <img src={errorImg} alt="" />
           {"Confirm your email"}
          </button>}
          </div> */}
        </div>
      </div>
      {screenResolution.mobile && (
        <Button
          className="flex flex-col text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px] items-center gap-2.5 bg-[#55C6EF] p-4 rounded-[10px] fixed w-[91%] mx-auto my-0 bottom-5 inset-x-0"
          label={translate("Save", "حفظ")}
          onSubmit={() => alert("save profile data")}
        />
      )}
    </div>
  );
};

export default ProfileDetails;
