import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosStrapi } from "../axiosInstance";
import { strapiApiEndPoints } from "../../api";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const fetchFaqs = createAsyncThunk(
  "fetchFaqs",
  (queryString, { rejectWithValue }) => {
    return AxiosStrapi.get(`${strapiApiEndPoints.faqs}`)
      .then((res) => res.data)
      .catch((error) => rejectWithValue(error.message));
  }
);

export const faqsSlice = createSlice({
  name: "faqs slice",
  initialState,
  reducers: {
    clearFaqsError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // FETCH FAQS API
    builder.addCase(fetchFaqs.pending, (state) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchFaqs.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchFaqs.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload || action.error?.message;
    });
  },
});

// SELECTORS
export const selectFaqsDataWhole = (store) => store?.faqs;
export const selectFaqsData = (store) => store?.faqs?.data;
export const selectFaqsDataLoader = (store) => store?.faqs?.isLoading;

// ACTIONS TO DISPATCH
export const { clearFaqsError } = faqsSlice.actions;

export default faqsSlice.reducer;
