import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import { signUpApiEndpoints } from "../../../api";

const initialState = {
  signupDetails: {
    cprNumber: "",
    email: "",
    mobile: "",
  },
  data: null,
  isLoading: false,
  error: null,
  userExist: null,
};

export const checkCprExists = createAsyncThunk(
  "signUp",
  (queryString, { rejectWithValue }) => {
    return Axios.post(`${signUpApiEndpoints?.userExist}`, {
      CPR: queryString,
    }).then((res) => res.data);
  }

);

const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setSignupDetails: (state, action) => {
      state.signupDetails = { ...state.signupDetails, ...action.payload };
    },
    resetSignupDetails: (state) => {
      state.signupDetails = initialState.signupDetails;
      state.userExist = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(checkCprExists.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(checkCprExists.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.userExist = action.payload.userExist;
      })
      .addCase(checkCprExists.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to check CPR";
      });
  },
});

export const { setSignupDetails, resetSignupDetails } = signupSlice.actions;

export const selectCheckCPRExistsLoading = (state) => state?.signup?.isLoading;
export const selectCustomerDetails = (state) => state?.signup?.data;


export default signupSlice.reducer;
