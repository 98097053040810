import { createSlice } from "@reduxjs/toolkit";
import { FUNDS_SWITCHING_FLOW } from "../../pages/lifeInsurancePages/PolicyOverview/fundManagementTab/investementStrategyFlow/fundSwitchingFlow/data";

const initialState = {
  flowType: FUNDS_SWITCHING_FLOW,
  currentStepIndex: 0,
  currentSubstepIndex: 0,
};

const fundsSwitchingFlowSlice = createSlice({
  name: "fundsSwitchingFlow",
  initialState,
  reducers: {
    setFundsSwitchingFlowType: (state, action) => {
      const data = action.payload;
      state.flowType = data.type;
      state.currentStepIndex = data.currentStepIndex || 0;
      state.currentSubstepIndex = data.currentSubstepIndex || 0;
    },
    setCurrentStepIndex: (state, action) => {
      state.currentStepIndex = action.payload;
      state.currentSubstepIndex = 0;
    },
    setCurrentSubstepIndex: (state, action) => {
      state.currentSubstepIndex = action.payload;
    },
  },
});

export const selectFundsSwitchingFlow = (store) =>
  store.fundsSwitchingFlow;
export const selectFundsSwitchingFlowType = (store) =>
  store?.fundsSwitchingFlow?.flowType;
export const selectCurrentStepIndex = (store) =>
  store?.fundsSwitchingFlow?.currentStepIndex;
export const selectCurrentSubstepIndex = (store) =>
  store.fundsSwitchingFlow?.currentSubstepIndex;

export const {
  setFundsSwitchingFlowType,
  setCurrentStepIndex,
  setCurrentSubstepIndex,
} = fundsSwitchingFlowSlice.actions;
export default fundsSwitchingFlowSlice.reducer;
