import React, { startTransition, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./SigninPage.css";
import { useDispatch, useSelector } from "react-redux";
import {
  clearLoginTokenInfo,
  loginWithBackupTokenInfo,
  selectLoginRegister,
} from "../../../redux/features/loginRegisterSlice";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { STRAPI_BASE_URL } from "../../../api/baseURL";
import Modal from "../../../components/common/modal/modal";
import Header from "../../../components/common/Header/Header";
import SignInForm from "./signInForm";
import useScreenResolution from "../../../hooks/useScreenResolution";
import BackBtnWithLabel from "../../../components/common/backBtnWithLabel/backBtnWithLabel";
import GetVerifiedPage from "../GetVerifiedPage/GetVerifiedPage";
import ConfirmCitizenShip from "../confirmCitizenShip/confirmCitizenship";

export const SIGN_IN_PAGE = "signInPage";
export const GET_VERIFIED_PAGE = "GetVerifiedPage";
export const CITIZENSHIP_PAGE = "CITIZENSHIP_PAGE";

const SignInPage = () => {
  const { mobile } = useScreenResolution();
  const [showModal, setShowModal] = useState(true);
  const [currentPage, setCurrentPage] = useState(SIGN_IN_PAGE);

  const { t } = useTranslation();
  const translate = useLanguageTranslate();

  const loginRegisterData = useSelector(selectLoginRegister);
  const dispatch = useDispatch();
  const cbVerifyStatus = (data) => {
    if (data.Verified == false) {
      setCurrentPage(GET_VERIFIED_PAGE);
      dispatch(clearLoginTokenInfo());
      // } else if (data.BenefitVerified === false) {
      //   setCurrentPage(CITIZENSHIP_PAGE);
      //   dispatch(clearLoginTokenInfo());
    } else {
      navigate("/");
    }
  };
  const navigate = useNavigate();

  const verifedOnSuccess = () => {
    setCurrentPage(CITIZENSHIP_PAGE);
  };

  const citizenshipOnSuccess = () => {
    navigate("/");
    dispatch(loginWithBackupTokenInfo());
  };

  const togglePages = () => {
    if (currentPage == GET_VERIFIED_PAGE) {
      return <GetVerifiedPage onSuccess={citizenshipOnSuccess} />;
    } else if (currentPage == SIGN_IN_PAGE) {
      return (
        <div className="sign_in_page flex align-center h_100vh justify-center">
          <div className="left_cont bg-[#00A9E7] w-1/3 h_100 max-w-[29.5%] max767:hidden"></div>
          <div className="signinright_cont h_100 p-20 w-3/4 justify-center items-center flex pt-[5%] max767:p-0 max767:w-full max767:pt-[1%] max767:items-start">
            <div className="form-division w-[53%] max767:w-full max767:p-5">
              {mobile ? (
                <div className="content">
                  <BackBtnWithLabel
                    label={translate("Login", "تسجيل الدخول")}
                  />
                  <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] max767:mb-5 max767:text-[28px] max767:font-semibold">
                    {translate(
                      "Welcome back to your financial gateway",
                      "مرحباً بعودتك إلى بوابتك المالية"
                    )}
                  </h1>
                </div>
              ) : (
                <div className="content">
                  <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mb-1">
                    {translate(
                      loginRegisterData?.sign_in_header?.title_en,
                      loginRegisterData?.sign_in_header?.title_ar
                    )}
                  </h1>
                  <div className="flex align-center mb-2">
                    <p className="mb-3">{t("siginPage.new_to_label")} </p>
                    <Link
                      to="/sign-up"
                      className="text-[#00384D] text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline ml-1"
                    >
                      {" "}
                      {t("siginPage.signup_link_label")}
                    </Link>
                  </div>
                </div>
              )}
              <SignInForm cbVerifyStatus={cbVerifyStatus} />

              {!mobile && (
                <div className="downlaod_app_cont mt-10 w-full">
                  <p className="text-[#5B6871] text-sm not-italic font-normal leading-[150%] mx-0 my-4 flex">
                    {translate(
                      loginRegisterData?.download_app_title?.title_en,
                      loginRegisterData?.download_app_title?.title_ar
                    )}
                  </p>
                  <div className="store_icons flex gap-2">
                    <a
                      href="https://apps.apple.com/bh/app/solidarity/id1585138622"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`${STRAPI_BASE_URL}${loginRegisterData?.apple_store_icon?.img?.data?.attributes?.url}`}
                        alt={translate(
                          loginRegisterData?.apple_store_icon?.img?.img_alt_en,
                          loginRegisterData?.apple_store_icon?.img?.img_alt_ar
                        )}
                        className="play_store cursor-pointer"
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=bh.solidarity.app&hl=en&gl=US"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={`${STRAPI_BASE_URL}${loginRegisterData?.google_play_icon?.img?.data?.attributes?.url}`}
                        alt={translate(
                          loginRegisterData?.google_play_icon?.img?.img_alt_en,
                          loginRegisterData?.google_play_icon?.img?.img_alt_ar
                        )}
                        className="app_store cursor-pointer"
                      />
                    </a>
                  </div>
                </div>
              )}
              {mobile && (
                <div className="flex align-center mb-2">
                  <p className="mb-3">{t("siginPage.new_to_label")} </p>
                  <Link
                    to="/sign-up"
                    className="text-[#00384D] text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline ml-1"
                  >
                    {" "}
                    {t("siginPage.signup_link_label")}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else if (currentPage == CITIZENSHIP_PAGE) {
      return <ConfirmCitizenShip onSuccess={citizenshipOnSuccess} />;
    }
  };

  return (
    <Modal show={showModal}>
      <Header
        isPopup
        onCloseModal={() => {
          startTransition(() => {
            setShowModal(false);
            navigate("/");
          });
        }}
      />

      {togglePages()}
    </Modal>
  );
};

export default SignInPage;
