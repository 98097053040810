/* :Page:NewMotor:Renewal Bottom Button for Continue */

import React, { useContext, useEffect, useMemo, useState } from "react";
import Button from "../../../../components/common/Button/Button";
import { useTranslation } from "react-i18next";
import ViewSummaryPopup from "../../../../components/newMotorPolicy/veiwSummaryPopup/viewSummaryPopup";
import useSummary from "../../../../hooks/useSummary";
import { formatToThreeDigits } from "../../../../utils";
import Loader from "../../../../components/common/Loader/Loader";
import { useSelector } from "react-redux";
import { selectCarsLoader } from "../../../../redux/features/constants/carsListSlice";
import { selectBanksLoader } from "../../../../redux/features/constants/banksListSlice";
import { getSumInsuredLoader } from "../../../../redux/features/motor/getSumInsuredSlice";
import {
  getQuoteLoading,
  getQuoteResponse,
} from "../../../../redux/features/motor/getQuoteSlice";
import { gdtRetrieveVehicleLoading } from "../../../../redux/features/motor/gdtSlice";
import {
  tokenLoading,
  uploadLoading,
} from "../../../../redux/features/upload/fileUploadSlice";
import { getScanOwnershipLoading } from "../../../../redux/features/ai/scannerSlice";
import { getTokenOtpLoading } from "../../../../redux/features/account/accountOtpSlice";
import { myPoliciesWholeLoader } from "../../../../redux/features/myPolicySlice";
import {
  getPolicyPremiumLoading,
  getPolicyPremiumResponse,
} from "../../../../redux/features/motor/renewalPremiumSlice";
import { PriceContext, PriceProvider } from "./PriceContext";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
const BottomPriceLayout = ({
  children,
  btn_label,
  btn_onClick,
  btn_disabled,
  show_summary,
  price,
  included,
  carReplacement,
}) => {
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const [show, setShow] = useState(false);
  const [formState, setFormState] = useState({});
  const toggleShow = () => {
    setShow((state) => !state);
  };
  const {
    discount_amount,
    coverage_enhance_data,
    promocode,
    discount_percent,
    total_amount,
    premium,
    discount,
    discount_percentage,
    vat_additional_amount,
  } = useSummary();

  const [totalPremium, setTotalPremium] = useState(0);
  const [previousPrice, setPreviousPrice] = useState(totalPremium);
  const [carReplacementPrice, setCarReplacementPrice] = useState(0);

  console.log("BottomPriceLayout - children:", children);

  useEffect(() => {
    console.log("Updating total premium");
    if (included == true && carReplacement == false) {
      const coverPriceVAT = price + price * 0.1;
      setTotalPremium(totalPremium + coverPriceVAT);
    } else if (included == false && carReplacement == false) {
      const coverPriceVAT = price + price * 0.1;
      setTotalPremium(totalPremium - coverPriceVAT);
    } else if (included == false && carReplacement == true) {
      setCarReplacementPrice(0);
    } else if (carReplacement == true) {
      // Store the current total as previous before adding CMOT28 price
      // setPreviousPrice((prev) => prev || totalPremium);

      // Add to previousPrice, ignoring any further updates to current totalPremium
      const coverPriceVAT = price + price * 0.1;
      setCarReplacementPrice(coverPriceVAT);
    }
    // else if (carReplacement == true && included == false) {
    //     // Store the current total as previous before adding CMOT28 price
    //     setPreviousPrice((prev) => prev || totalPremium);

    //     // Add to previousPrice, ignoring any further updates to current totalPremium
    //     const coverPriceVAT = price + (price * 0.1);
    //     setTotalPremium(previousPrice - coverPriceVAT);
    // }
  }, [price, included]);

  useEffect(() => {
    if (totalPremium < 0) {
      setTotalPremium(0);
    }
  }, [totalPremium]);

  return (
    <>
      <div className="summarypopup">
        <ViewSummaryPopup
          show={show}
          setShow={setShow}
          formState={formState}
          setFormState={setFormState}
        />
      </div>

      {children}

      <div className="footer bg-white border-t-[#E5E9EB] border-t border-solid mt-2 w-full py-2 flex max767:flex-col justify-end fixed pr-[3%] bottom-0 max767:w-full max767:p-4 max767:inset-x-0 ipad:items-center max767:inline-block">
        {show_summary && (
          <div
            onClick={toggleShow}
            className="view_summary  mr-[2%] mt-3 max767:w-full max767:flex max767:mb-2 max767:justify-between ipad:mt-0 rtl:ml-3 rtl:mr-0 "
          >
            <span className="text-[#00384D] text-center text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline mr-3 rtl:ml-3 rtl:mr-0">
              {t("motorInsurance.bottomStickybtnlayout.summary")}
            </span>{" "}
            <strong className="text-[#1A2024] text-xl not-italic font-extrabold leading-6">
              {translate("BHD", "د.ب")} {totalPremium + carReplacementPrice}
            </strong>
          </div>
        )}

        <div className="flex justify-end pr-[3%] rtl:pl-[3%] max767:w-full max767:pr-[0%]">
          <Button
            disabled={btn_disabled}
            onSubmit={btn_onClick ? btn_onClick : () => {}}
            type="submit"
            label={
              btn_label ||
              t("motorInsurance.newMotorInsuranceFlow.basicInfo.continueButton")
            }
            className="mb-0 stickybutton flex w-[200px] flex-col items-center gap-2.5 shrink-0 p-4 bg-[#55C6EF] rounded-[10px] text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px] max767:w-full max767:h-[40px]"
          />
        </div>
      </div>
    </>
  );
};

export default BottomPriceLayout;
