import React, { useMemo, useRef, useState } from "react";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import FileClaimMotorLayout from "./fileClaimMotorLayout";
import useFileClaimFlow from "../../../hooks/useFileClaimFlow";
import DragDropFileUpload from "../../../components/common/dragDropFileUpload";
import FileUpload from "../../../components/common/fileUploadInput/fileUploadInput";
import { useTranslation } from "react-i18next";
import { BASE_URL, FILES_BASE_URL } from "../../../api/baseURL";
import { uploadFile } from "../../../redux/features/upload/fileUploadSlice";
import FileUploadClaim from "../../../components/common/fileUploadInput/fileUploadInputForClaims";
import useMotorForm from "../../../hooks/useMotorFormData";
import {
  FILE_CLAIM_FLOW,
  FILE_CLAIM_NON_MOTOR_POLICY_HOLDER,
  REQUIRED_DOCS_INFO_DATA,
  UPLOAD_DOCS_INPUT,
} from "./data";
import {
  BODILY_INJURY,
  PROPERTY_DAMAGE,
  VEHICLE_DAMAGE,
} from "./NonSolidarityUser/data";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import useValidation from "../../../hooks/useValidation";
import TelInput from "../../../components/common/telInput/telInput";
import { Loader } from "storybook/internal/components";
import CustomCheckbox from "../../../components/common/customCheckbox/customCheckbox";

export const DriverLicencesDocs = "DriverLicence";
export const AccidentDocs = "AccidentDocs";
export const OwnershipDocs = "OwnershipDocs";
export const IncidentInput = "IncidentInput";
export const InsuranceCert = "InsuranceCert";
export const PropertyOwner = "PropertyOwner";
export const ProofAddress = "ProofAddress";
export const ImpactedPersonCprDocs = "ImpactedPersonCprDocs";
export const JudicialReportDocs = "JudicialReportDocs";
export const OtherDocs = "OtherDocs";
export const PropertyAccidentPhotosDocs = "propertyAccidentPhotosDocs";

export const IBAN_DOC = "ibanDoc";
export const MOBLIE_DATA = "MOBLIE_DATA";
export const OWNER_SHIP_DOCS = "ownerShipCardDocs";
export const PHOTOS_OF_DAMAGE = "photosOfDamage";
export const POLICE_REPORTS = "policeReports";

function UploadDocsPage({ selectedInsuredType }) {
  const [formData, setFormData] = useState({});
  const [values, setValues] = useState({});
  const { errors, validateFields } = useValidation();
  var isLoading = false;
  const translate = useLanguageTranslate();
  const { t } = useTranslation();
  const { handleNextSubstep, flowType } = useFileClaimFlow();
  const continueClickHandler = async () => {
    isLoading = true;
    await saveDriverLicenceDocs(drivingLicenseDocs);
    await saveAccidentDocs(accidentPhotosDocs);
    await saveOwnershipDocs(vehicleOwnershipCertificateDocs);
    await saveInsuranceCertDocs(insuranceCertificateDocs);
    await savePropertyOwnerCprDocs(proofOfPropertyOwnershipDocs);
    await saveProofOfPropertyOwnershipDocs(proofOfPropertyOwnershipDocs);
    await savePropertyAccidentPhotosDocs(propertyAccidentPhotosDocs);
    await saveImpactedPersonCprDocs(impactedPersonCprDocs);
    await saveJudicialReportDocs(judicialReportDocs);
    await saveOtherDocs(otherDocs);

    // non motor docs
    await saveOwnerShipCardDocs(ownerShipCardDocs);
    await savePhotosOfDamage(photosOfDamage);
    await savePoliceReports(policeReports);
    await saveIbanDocument(ibanCertificate);
    await saveIncidentCertificate(incidentDocs);
    await saveMobileData(values);

    if (validateForm()) {
      if (flowType != FILE_CLAIM_NON_MOTOR_POLICY_HOLDER) {
        const insuranceCertificates = insuranceCertificateDocs.map(
          (doc) => doc.preview
        );
        const ownerShipsNonMotors = ownerShipCardDocs.map((doc) => doc.preview);
        const damagesDocs = photosOfDamage.map((doc) => doc.preview);
        const policeReportsNonMotor = policeReports.map((doc) => doc.preview);
        const incidentReports = incidentDocs.map((doc) => doc.preview);

        const combined = [
          ...(insuranceCertificates && insuranceCertificates.length
            ? insuranceCertificates
            : []),
          ...(ownerShipsNonMotors && ownerShipsNonMotors.length
            ? ownerShipsNonMotors
            : []),
          ...(damagesDocs && damagesDocs.length ? damagesDocs : []),
          ...(policeReportsNonMotor && policeReportsNonMotor.length
            ? policeReportsNonMotor
            : []),
          ...(incidentReports && incidentReports.length ? incidentReports : []),
        ];
        if (ibanCertificate.file) {
          combined.push(ibanCertificate.file);
        }

        // api
        await SubmitToNonMotorClaim(combined);
      } else {
        const insuranceCertificates = insuranceCertificateDocs.map(
          (doc) => doc.preview
        );
        const ownerShipsNonMotors = ownerShipCardDocs.map((doc) => doc.preview);
        const damagesDocs = photosOfDamage.map((doc) => doc.preview);
        const policeReportsNonMotor = policeReports.map((doc) => doc.preview);

        const combined = [
          ...(insuranceCertificates && insuranceCertificates.length
            ? insuranceCertificates
            : []),
          ...(ownerShipsNonMotors && ownerShipsNonMotors.length
            ? ownerShipsNonMotors
            : []),
          ...(damagesDocs && damagesDocs.length ? damagesDocs : []),
          ...(policeReportsNonMotor && policeReportsNonMotor.length
            ? policeReportsNonMotor
            : []),
        ];
        if (ibanCertificate.file) {
          combined.push(ibanCertificate.file);
        }

        // api
        await SubmitToNonMotorClaim(combined);
      }

      isLoading = false;
      handleNextSubstep();
    }
  };

  const SubmitToNonMotorClaim = async (links) => {
    const url = BASE_URL + "/MailToCompany/emailToCompany";

    const data = {
      // Your data to send in the request body
      Channel: "online.solidarity",
      ToTitle: "Non Motor Claim",
      ToMessage:
        "We have received a Non Motor Claim from the online portal. Kindly find the details below.",
      ToDepartment: "",
      attachmentLink: links,
      FromEmail: "ayman.mayoof@solidarity.com.bh",
      ToEmail: "ayman.mayoof@solidarity.com.bh",
      subject: "Non-Motor Claim",
      message: "Msg",
      formType: "Plain",
      MobileNumber: values.mobile ?? "",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set headers for JSON
        },
        body: JSON.stringify(data), // Convert data to JSON string
      });

      if (!response.ok) {
        isLoading = false;
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);
      isLoading = false;
      return result;
    } catch (error) {
      isLoading = false;
      console.error("Error:", error);
    }
  };

  // SOLIDARITY USER using same docs inVehicle Damage

  const [ibanCertificate, setIbanCertificate] = useState({
    fileName: "",
    fileSize: "",
    file: null,
    base64file: "",
    ownershipData: "",
    error: "",
  });

  const { saveMotorDataInRedux: saveIbanDocument } = useMotorForm(
    IBAN_DOC,
    setIbanCertificate
  );

  const { saveMotorDataInRedux: saveMobileData } = useMotorForm(
    MOBLIE_DATA,
    setValues
  );

  // Non-Solidarity Vehicle Damage
  const [drivingLicenseDocs, setDrivingLicenseDocs] = useState([]);
  const { saveMotorDataInRedux: saveDriverLicenceDocs } = useMotorForm(
    DriverLicencesDocs,
    setDrivingLicenseDocs
  );

  const [insuranceCertificateDocs, setInsuranceCertificateDocs] = useState([]);
  const { saveMotorDataInRedux: saveInsuranceCertDocs } = useMotorForm(
    InsuranceCert,
    setInsuranceCertificateDocs
  );

  const [vehicleOwnershipCertificateDocs, setVehicleOwnershipCertificateDocs] =
    useState([]);
  const { saveMotorDataInRedux: saveOwnershipDocs } = useMotorForm(
    OwnershipDocs,
    setVehicleOwnershipCertificateDocs
  );

  const [accidentPhotosDocs, setAccidentPhotosDocs] = useState([]);
  const { saveMotorDataInRedux: saveAccidentDocs } = useMotorForm(
    AccidentDocs,
    setAccidentPhotosDocs
  );

  const [incidentDocs, setIncidentInput] = useState([]);
  const { saveMotorDataInRedux: saveIncidentCertificate } = useMotorForm(
    IncidentInput,
    setIncidentInput
  );

  // Non-Solidarity Property Damage
  const [propertyOwnerCprDocs, setPropertyOwnerCprDocs] = useState([]);
  const { saveMotorDataInRedux: savePropertyOwnerCprDocs } = useMotorForm(
    PropertyOwner,
    setPropertyOwnerCprDocs
  );

  const [proofOfPropertyOwnershipDocs, setProofOfPropertyOwnershipDocs] =
    useState([]);
  const { saveMotorDataInRedux: saveProofOfPropertyOwnershipDocs } =
    useMotorForm(ProofAddress, setProofOfPropertyOwnershipDocs);

  const [propertyAccidentPhotosDocs, setPropertyAccidentPhotosDocs] = useState(
    []
  );
  const { saveMotorDataInRedux: savePropertyAccidentPhotosDocs } = useMotorForm(
    PropertyAccidentPhotosDocs,
    setPropertyAccidentPhotosDocs
  );

  // Non-Solidarity Bodily Injury
  const [impactedPersonCprDocs, setImpactedPersonCprDocs] = useState([]);
  const { saveMotorDataInRedux: saveImpactedPersonCprDocs } = useMotorForm(
    ImpactedPersonCprDocs,
    setImpactedPersonCprDocs
  );

  const [judicialReportDocs, setJudicialReportDocs] = useState([]);
  const { saveMotorDataInRedux: saveJudicialReportDocs } = useMotorForm(
    judicialReportDocs,
    setJudicialReportDocs
  );

  const [otherDocs, setOtherDocs] = useState([]);
  const { saveMotorDataInRedux: saveOtherDocs } = useMotorForm(
    OtherDocs,
    setOtherDocs
  );

  // Non Motor Solidarity Flow

  const [ownerShipCardDocs, setOwnerShipCardDocs] = useState([]);
  const { saveMotorDataInRedux: saveOwnerShipCardDocs } = useMotorForm(
    OWNER_SHIP_DOCS,
    setOwnerShipCardDocs
  );

  const [photosOfDamage, setPhotosOfDamage] = useState([]);
  const { saveMotorDataInRedux: savePhotosOfDamage } = useMotorForm(
    PHOTOS_OF_DAMAGE,
    setPhotosOfDamage
  );

  const [policeReports, setPoliceReports] = useState([]);
  const { saveMotorDataInRedux: savePoliceReports } = useMotorForm(
    POLICE_REPORTS,
    setPoliceReports
  );

  const handleUploadComplete = (file) => {
    //RequestTokenAPI(file.name +"_"+ generateUniqueIdentifier());
    console.log(`Upload complete for ${file}`);
  };
  const [isChecked, setIsChecked] = useState(false);
  const UploadDoc = (file, type) => {
    return new Promise((resolve, reject) => {
      let generatedLink = "";
      const reader = new FileReader();

      reader.onloadend = async () => {
        try {
          const base64String = reader.result.split(",")[1];
          const tokenResult = await RequestTokenAPI(
            file.name + "_" + generateUniqueIdentifier()
          );
          const uploaded = await UploadAPI(
            tokenResult.data.token,
            file.name,
            base64String
          );
          const fileId = uploaded.data.file_id;
          const requestedLink = await RequestLinkAPI(fileId);

          generatedLink = requestedLink.data.link;
          console.log("My Link: " + generatedLink);
          resolve(generatedLink);
        } catch (error) {
          console.error("Error during upload:", error);
          reject(error);
        }
      };

      reader.onerror = (error) => {
        console.error("Error converting file to Base64:", error);
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  };

  function generateUniqueIdentifier() {
    // Get current timestamp for uniqueness
    const timestamp = Date.now().toString(36); // Converts timestamp to base-36 (0-9, a-z)

    // Generate a random number and convert it to base-36 as well
    const randomNumber = Math.floor(Math.random() * 1_000_000_000).toString(36);

    // Concatenate the timestamp and random number
    return `${timestamp}-${randomNumber}`;
  }
  const onFileUploaded = (value, name) => {
    setFormData({ ...formData, [name]: value });

    console.log(`Upload complete for ${formData}`);
  };

  // Sample function to make a POST API call
  const RequestTokenAPI = async (id) => {
    const url = FILES_BASE_URL + "/tokens/request";
    const data = {
      // Your data to send in the request body
      secret: "Claim123",
      agent: "online.solidarity",
      item_type: "claim_docs",
      item_id: id,
      source: "online.solidarity",
      description: "Files token request from customer portal in claims.",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set headers for JSON
        },
        body: JSON.stringify(data), // Convert data to JSON string
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);
      return result;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const UploadAPI = async (token, name, base64) => {
    const url = FILES_BASE_URL + "/files/upload";
    const data = {
      // Your data to send in the request body
      token: token,
      source: "online.solidarity",
      file_name: name,
      file_base64: base64,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set headers for JSON
        },
        body: JSON.stringify(data), // Convert data to JSON string
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);
      return result;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const RequestLinkAPI = async (fileId) => {
    const url = FILES_BASE_URL + "/links/request";
    const data = {
      // Your data to send in the request body
      file_id: fileId,
      ip: "",
      validity: 7200,
      reference: "customer portal",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set headers for JSON
        },
        body: JSON.stringify(data), // Convert data to JSON string
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);
      return result;
    } catch (error) {
      console.error("Error:", error);
    }
  };
  console.log(flowType, "flowType");

  const { docs } = useMemo(() => {
    if (flowType == FILE_CLAIM_FLOW) {
      console.log("solidarity_user");
      return UPLOAD_DOCS_INPUT.solidarity_user;
    } else if (flowType == FILE_CLAIM_NON_MOTOR_POLICY_HOLDER) {
      console.log("solidarity_user_non_motor");
      return UPLOAD_DOCS_INPUT.solidarity_user_non_motor;
    } else if (selectedInsuredType == VEHICLE_DAMAGE) {
      console.log("non_solidarity_vehicle_damage");
      return UPLOAD_DOCS_INPUT.non_solidarity_vehicle_damage;
    } else if (selectedInsuredType == PROPERTY_DAMAGE) {
      console.log("non_solidarity_property_damage");
      return UPLOAD_DOCS_INPUT.non_solidarity_property_damage;
    } else if (selectedInsuredType == BODILY_INJURY) {
      console.log("non_solidarity_bodily_injury");
      return UPLOAD_DOCS_INPUT.non_solidarity_bodily_injury;
    } else {
      return [];
    }
  }, [flowType, selectedInsuredType]);
  console.log(docs.flowType, "some docs");
  console.log(docs, "some docs");

  console.log(selectedInsuredType, "selectedInsuredType");
  // console.log(allDocs, 'some all docs')

  const validateForm = (data) => {
    let config = [];
    docs?.forEach((each) => {
      let data = {
        name: each?.name,
        validations: ["file"],
      };
      if (!each.isOptional) config.push(data);
    });
    if (flowType == FILE_CLAIM_NON_MOTOR_POLICY_HOLDER) {
      config.push({
        name: "mobile",
        validations: ["number", "length"],
        exact_length: 8,
      });
    }
    console.log(config, "some config");
    let isValid = validateFields(config, {
      // Solidarity User
      driver_license: drivingLicenseDocs,
      vehicle_ownership_certificate: vehicleOwnershipCertificateDocs,
      photos_of_accident: accidentPhotosDocs,

      // Non-Solidarity Vehicle Damage
      insurance_certificate: insuranceCertificateDocs,
      vd_clear_photos_of_the_accident: accidentPhotosDocs,

      // Non-Solidarity Property Damage
      property_owner_cpr_number: propertyOwnerCprDocs,
      proof_of_property_ownership: proofOfPropertyOwnershipDocs,
      clear_photos_of_the_accident: propertyAccidentPhotosDocs,

      // Non-Solidarity Bodily Injury
      cpr_number_of_the_impacted_person: impactedPersonCprDocs,
      judicial_report: judicialReportDocs,
      other_docs: otherDocs,
      incident_report: incidentDocs,

      // non motor flow
      mobile: data ? data?.mobile : values.mobile,
      ownership_card: ownerShipCardDocs,
      photos_of_damages: photosOfDamage,
      police_report: policeReports,
      iban_letter_of_owner: ibanCertificate,
    });

    console.log("Check " + data);
    console.log("Check ", isValid);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const update = { ...values, [name]: value };
    setValues(update);
    if (errors?.[name]) validateForm(update);
  };

  console.log(flowType, " flow type");
  console.log(selectedInsuredType, " selectedInsuredType");

  console.log(flowType, "some flow type");

  console.log(errors, "some errors");
  // console.log(validationConfig, 'some errors')
  console.log(accidentPhotosDocs, "accident photos doc");
  console.log("drivingLicenseDocs", drivingLicenseDocs);
  console.log("insuranceCertificates", insuranceCertificateDocs);
  console.log(
    "vehicleOwnershipCertificateDocs",
    vehicleOwnershipCertificateDocs
  );
  console.log("incidentReports", incidentDocs);

  return (
    <FileClaimMotorLayout
      btn_disabled={
        flowType == FILE_CLAIM_NON_MOTOR_POLICY_HOLDER && !isChecked
      }
      btn_onClick={continueClickHandler}
    >
      <div className="mx-auto bg-white max767:p-5 w-[500px] max-w-[100%]">
        <div className="max767:hidden">
          <TitleDescription
            title={t("motorInsurance.fileAClaimFlow.repairOrCashPage.title")}
            description={t(
              "motorInsurance.fileAClaimFlow.repairOrCashPage.description"
            )}
          />
        </div>
        <div className="max767:block hidden max767:mb-6 max767:mt-20">
          <TitleDescription
            title={t(
              "motorInsurance.fileAClaimFlow.repairOrCashPage.mobile_title"
            )}
            description={t(
              "motorInsurance.fileAClaimFlow.repairOrCashPage.mobile_description"
            )}
          />
        </div>

        {flowType == FILE_CLAIM_FLOW && (
          <div>
            <div>
              <div>
                <DragDropFileUpload
                  label={translate(
                    UPLOAD_DOCS_INPUT?.solidarity_user?.docs?.[0]
                      ?.input_label_en,
                    UPLOAD_DOCS_INPUT?.solidarity_user?.docs?.[0]
                      ?.input_label_ar
                  )}
                  onUploadComplete={handleUploadComplete}
                  name={UPLOAD_DOCS_INPUT?.solidarity_user?.docs?.[0]?.name}
                  multiple
                  toggleFileUpload={onFileUploaded}
                  uploadDocFunc={UploadDoc}
                  uploadedFiles={drivingLicenseDocs || []}
                  maxFiles={2}
                  setUploadedFiles={setDrivingLicenseDocs}
                />
                {errors?.[
                  UPLOAD_DOCS_INPUT?.solidarity_user?.docs?.[0]?.name
                ] && (
                  <p className="text-red-500 text-sm">
                    {t("validation.required")}
                  </p>
                )}
              </div>
              <div>
                <DragDropFileUpload
                  label={translate(
                    UPLOAD_DOCS_INPUT?.solidarity_user?.docs?.[1]
                      ?.input_label_en,
                    UPLOAD_DOCS_INPUT?.solidarity_user?.docs?.[1]
                      ?.input_label_ar
                  )}
                  onUploadComplete={handleUploadComplete}
                  name={UPLOAD_DOCS_INPUT?.solidarity_user?.docs?.[1]?.name}
                  multiple
                  toggleFileUpload={onFileUploaded}
                  uploadDocFunc={UploadDoc}
                  maxFiles={2}
                  uploadedFiles={vehicleOwnershipCertificateDocs || []}
                  setUploadedFiles={setVehicleOwnershipCertificateDocs}
                />
                {errors?.[
                  UPLOAD_DOCS_INPUT?.solidarity_user?.docs?.[1]?.name
                ] && (
                  <p className="text-red-500 text-sm">
                    {t("validation.required")}
                  </p>
                )}
              </div>
              <div>
                <DragDropFileUpload
                  label={translate(
                    UPLOAD_DOCS_INPUT?.solidarity_user?.docs?.[2]
                      ?.input_label_en,
                    UPLOAD_DOCS_INPUT?.solidarity_user?.docs?.[2]
                      ?.input_label_ar
                  )}
                  onUploadComplete={handleUploadComplete}
                  name={UPLOAD_DOCS_INPUT?.solidarity_user?.docs?.[2]?.name}
                  multiple
                  toggleFileUpload={onFileUploaded}
                  uploadDocFunc={UploadDoc}
                  uploadedFiles={accidentPhotosDocs || []}
                  maxFiles={10}
                  setUploadedFiles={setAccidentPhotosDocs}
                />
                {errors?.[
                  UPLOAD_DOCS_INPUT?.solidarity_user?.docs?.[2]?.name
                ] && (
                  <p className="text-red-500 text-sm">
                    {t("validation.required")}
                  </p>
                )}
              </div>
            </div>
            <div></div>
          </div>
        )}

        {selectedInsuredType == PROPERTY_DAMAGE && (
          <div>
            <div>
              <DragDropFileUpload
                label={translate(
                  UPLOAD_DOCS_INPUT?.non_solidarity_property_damage?.docs?.[0]
                    ?.input_label_en,
                  UPLOAD_DOCS_INPUT?.non_solidarity_property_damage?.docs?.[0]
                    ?.input_label_ar
                )}
                onUploadComplete={handleUploadComplete}
                name={
                  UPLOAD_DOCS_INPUT?.non_solidarity_property_damage?.docs?.[0]
                    ?.name
                }
                multiple
                toggleFileUpload={onFileUploaded}
                uploadDocFunc={UploadDoc}
                uploadedFiles={propertyOwnerCprDocs || []}
                setUploadedFiles={setPropertyOwnerCprDocs}
              />
              {errors?.[
                UPLOAD_DOCS_INPUT?.non_solidarity_property_damage?.docs?.[0]
                  ?.name
              ] && (
                <p className="text-red-500 text-sm">
                  {t("validation.required")}
                </p>
              )}
            </div>
            <div>
              <DragDropFileUpload
                label={translate(
                  UPLOAD_DOCS_INPUT?.non_solidarity_property_damage?.docs?.[1]
                    ?.input_label_en,
                  UPLOAD_DOCS_INPUT?.non_solidarity_property_damage?.docs?.[1]
                    ?.input_label_ar
                )}
                onUploadComplete={handleUploadComplete}
                name={
                  UPLOAD_DOCS_INPUT?.non_solidarity_property_damage?.docs?.[1]
                    ?.name
                }
                multiple
                toggleFileUpload={onFileUploaded}
                uploadDocFunc={UploadDoc}
                uploadedFiles={proofOfPropertyOwnershipDocs || []}
                setUploadedFiles={setProofOfPropertyOwnershipDocs}
              />
              {errors?.[
                UPLOAD_DOCS_INPUT?.non_solidarity_property_damage?.docs?.[1]
                  ?.name
              ] && (
                <p className="text-red-500 text-sm">
                  {t("validation.required")}
                </p>
              )}
            </div>
            <div>
              <DragDropFileUpload
                label={translate(
                  UPLOAD_DOCS_INPUT?.non_solidarity_property_damage?.docs?.[2]
                    ?.input_label_en,
                  UPLOAD_DOCS_INPUT?.non_solidarity_property_damage?.docs?.[2]
                    ?.input_label_ar
                )}
                onUploadComplete={handleUploadComplete}
                name={
                  UPLOAD_DOCS_INPUT?.non_solidarity_property_damage?.docs?.[2]
                    ?.name
                }
                multiple
                toggleFileUpload={onFileUploaded}
                uploadDocFunc={UploadDoc}
                uploadedFiles={propertyAccidentPhotosDocs || []}
                setUploadedFiles={setPropertyAccidentPhotosDocs}
              />
              {errors?.[
                UPLOAD_DOCS_INPUT?.non_solidarity_property_damage?.docs?.[2]
                  ?.name
              ] && (
                <p className="text-red-500 text-sm">
                  {t("validation.required")}
                </p>
              )}
            </div>
          </div>
        )}

        {selectedInsuredType == BODILY_INJURY && (
          <div>
            <div>
              <DragDropFileUpload
                label={translate(
                  UPLOAD_DOCS_INPUT?.non_solidarity_bodily_injury?.docs?.[0]
                    ?.input_label_en,
                  UPLOAD_DOCS_INPUT?.non_solidarity_bodily_injury?.docs?.[0]
                    ?.input_label_ar
                )}
                onUploadComplete={handleUploadComplete}
                name={
                  UPLOAD_DOCS_INPUT?.non_solidarity_bodily_injury?.docs?.[0]
                    ?.name
                }
                multiple
                toggleFileUpload={onFileUploaded}
                uploadDocFunc={UploadDoc}
                uploadedFiles={impactedPersonCprDocs || []}
                setUploadedFiles={setImpactedPersonCprDocs}
              />
              {errors?.[
                UPLOAD_DOCS_INPUT?.non_solidarity_bodily_injury?.docs?.[0]?.name
              ] && (
                <p className="text-red-500 text-sm">
                  {t("validation.required")}
                </p>
              )}
            </div>
            <div>
              <DragDropFileUpload
                label={translate(
                  UPLOAD_DOCS_INPUT?.non_solidarity_bodily_injury?.docs?.[1]
                    ?.input_label_en,
                  UPLOAD_DOCS_INPUT?.non_solidarity_bodily_injury?.docs?.[1]
                    ?.input_label_ar
                )}
                onUploadComplete={handleUploadComplete}
                name={
                  UPLOAD_DOCS_INPUT?.non_solidarity_bodily_injury?.docs?.[1]
                    ?.name
                }
                multiple
                toggleFileUpload={onFileUploaded}
                uploadDocFunc={UploadDoc}
                uploadedFiles={judicialReportDocs || []}
                setUploadedFiles={setJudicialReportDocs}
              />
              {errors?.[
                UPLOAD_DOCS_INPUT?.non_solidarity_bodily_injury?.docs?.[1]?.name
              ] && (
                <p className="text-red-500 text-sm">
                  {t("validation.required")}
                </p>
              )}
            </div>
            <div>
              <DragDropFileUpload
                label={translate(
                  UPLOAD_DOCS_INPUT?.non_solidarity_bodily_injury?.docs?.[2]
                    ?.input_label_en,
                  UPLOAD_DOCS_INPUT?.non_solidarity_bodily_injury?.docs?.[2]
                    ?.input_label_ar
                )}
                onUploadComplete={handleUploadComplete}
                name={
                  UPLOAD_DOCS_INPUT?.non_solidarity_bodily_injury?.docs?.[2]
                    ?.name
                }
                multiple
                toggleFileUpload={onFileUploaded}
                uploadDocFunc={UploadDoc}
                uploadedFiles={otherDocs || []}
                setUploadedFiles={setOtherDocs}
              />
              {errors?.[
                UPLOAD_DOCS_INPUT?.non_solidarity_bodily_injury?.docs?.[2]?.name
              ] && (
                <p className="text-red-500 text-sm">
                  {t("validation.required")}
                </p>
              )}
            </div>
          </div>
        )}

        {flowType == FILE_CLAIM_NON_MOTOR_POLICY_HOLDER &&
          (!isLoading ? (
            <div>
              <TelInput
                name="mobile"
                value={values.mobile}
                error={errors.mobile}
                onChange={handleChange}
                label={translate("Mobile Number", "رقم الجوال")}
                className="mobilenumber px-4 py-3 max767:w-[50%] w-[97%] border-r-0 border-0 rounded-[9px] h-[46px] focus:outline-none"
              />
              <div className="mt-[-30px]">
                <DragDropFileUpload
                  label={translate(
                    UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[0]
                      ?.input_label_en,
                    UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[0]
                      ?.input_label_ar
                  )}
                  onUploadComplete={handleUploadComplete}
                  name={
                    UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[0]
                      ?.name
                  }
                  multiple
                  toggleFileUpload={onFileUploaded}
                  uploadDocFunc={UploadDoc}
                  uploadedFiles={insuranceCertificateDocs || []}
                  setUploadedFiles={setInsuranceCertificateDocs}
                />
                {errors?.[
                  UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[0]?.name
                ] && (
                  <p className="text-red-500 text-sm">
                    {t("validation.required")}
                  </p>
                )}
              </div>
              <div>
                <DragDropFileUpload
                  label={translate(
                    UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[1]
                      ?.input_label_en,
                    UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[1]
                      ?.input_label_ar
                  )}
                  onUploadComplete={handleUploadComplete}
                  name={
                    UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[1]
                      ?.name
                  }
                  multiple
                  toggleFileUpload={onFileUploaded}
                  maxFiles={2}
                  uploadDocFunc={UploadDoc}
                  uploadedFiles={ownerShipCardDocs || []}
                  setUploadedFiles={setOwnerShipCardDocs}
                />
                {errors?.[
                  UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[1]?.name
                ] && (
                  <p className="text-red-500 text-sm">
                    {t("validation.required")}
                  </p>
                )}
              </div>
              <div>
                <DragDropFileUpload
                  label={translate(
                    UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[2]
                      ?.input_label_en,
                    UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[2]
                      ?.input_label_ar
                  )}
                  onUploadComplete={handleUploadComplete}
                  name={
                    UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[2]
                      ?.name
                  }
                  multiple
                  toggleFileUpload={onFileUploaded}
                  uploadDocFunc={UploadDoc}
                  maxFiles={10}
                  uploadedFiles={photosOfDamage || []}
                  setUploadedFiles={setPhotosOfDamage}
                />
                {errors?.[
                  UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[2]?.name
                ] && (
                  <p className="text-red-500 text-sm">
                    {t("validation.required")}
                  </p>
                )}
              </div>
              <div>
                <DragDropFileUpload
                  label={translate(
                    UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[3]
                      ?.input_label_en,
                    UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[3]
                      ?.input_label_ar
                  )}
                  onUploadComplete={handleUploadComplete}
                  name={
                    UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[3]
                      ?.name
                  }
                  multiple
                  toggleFileUpload={onFileUploaded}
                  uploadDocFunc={UploadDoc}
                  uploadedFiles={policeReports || []}
                  setUploadedFiles={setPoliceReports}
                />
                {errors?.[
                  UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[3]?.name
                ] && (
                  <p className="text-red-500 text-sm">
                    {t("validation.required")}
                  </p>
                )}
              </div>
              <div className="uploaddocs">
                <label className="mt-6 flex text-[#48535B] text-base not-italic font-normal leading-[26px] tracking-[-0.16px]">
                  {translate(
                    UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[4]
                      ?.input_label_en,
                    UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[4]
                      ?.input_label_ar
                  )}
                </label>
                <FileUploadClaim
                  className="platetypedocuinput uploaddisable"
                  name={
                    UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[4]
                      ?.name
                  }
                  toggleFileUpload={onFileUploaded}
                  formState={ibanCertificate}
                  setFormState={setIbanCertificate}
                />
                {errors?.[
                  UPLOAD_DOCS_INPUT?.solidarity_user_non_motor?.docs?.[4]?.name
                ] && (
                  <p className="text-red-500 text-sm">
                    {t("validation.required")}
                  </p>
                )}
              </div>
            </div>
          ) : (
            <Loader />
          ))}

        {selectedInsuredType == VEHICLE_DAMAGE && (
          <div>
            <div>
              <DragDropFileUpload
                label={translate(
                  UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[0]
                    ?.input_label_en,
                  UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[0]
                    ?.input_label_ar
                )}
                onUploadComplete={handleUploadComplete}
                name={
                  UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[0]
                    ?.name
                }
                multiple
                maxFiles={2}
                toggleFileUpload={onFileUploaded}
                uploadDocFunc={UploadDoc}
                uploadedFiles={drivingLicenseDocs || []}
                setUploadedFiles={setDrivingLicenseDocs}
              />
              {errors?.[
                UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[0]
                  ?.name
              ] && (
                <p className="text-red-500 text-sm">
                  {t("validation.required")}
                </p>
              )}
            </div>
            <div>
              <DragDropFileUpload
                label={translate(
                  UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[1]
                    ?.input_label_en,
                  UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[1]
                    ?.input_label_ar
                )}
                onUploadComplete={handleUploadComplete}
                name={
                  UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[1]
                    ?.name
                }
                multiple
                toggleFileUpload={onFileUploaded}
                uploadDocFunc={UploadDoc}
                uploadedFiles={insuranceCertificateDocs || []}
                setUploadedFiles={setInsuranceCertificateDocs}
              />
              {errors?.[
                UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[1]
                  ?.name
              ] && (
                <p className="text-red-500 text-sm">
                  {t("validation.required")}
                </p>
              )}
            </div>
            <div>
              <DragDropFileUpload
                label={translate(
                  UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[2]
                    ?.input_label_en,
                  UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[2]
                    ?.input_label_ar
                )}
                onUploadComplete={handleUploadComplete}
                name={
                  UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[2]
                    ?.name
                }
                multiple
                toggleFileUpload={onFileUploaded}
                uploadDocFunc={UploadDoc}
                maxFiles={2}
                uploadedFiles={vehicleOwnershipCertificateDocs || []}
                setUploadedFiles={setVehicleOwnershipCertificateDocs}
              />
              {errors?.[
                UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[2]
                  ?.name
              ] && (
                <p className="text-red-500 text-sm">
                  {t("validation.required")}
                </p>
              )}
            </div>
            <div>
              <DragDropFileUpload
                label={translate(
                  UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[3]
                    ?.input_label_en,
                  UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[3]
                    ?.input_label_ar
                )}
                onUploadComplete={handleUploadComplete}
                name={
                  UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[3]
                    ?.name
                }
                multiple
                toggleFileUpload={onFileUploaded}
                uploadDocFunc={UploadDoc}
                uploadedFiles={accidentPhotosDocs || []}
                setUploadedFiles={setAccidentPhotosDocs}
              />
              {errors?.[
                UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[3]
                  ?.name
              ] && (
                <p className="text-red-500 text-sm">
                  {t("validation.required")}
                </p>
              )}
            </div>
            {/* <div>
              <DragDropFileUpload
                label={translate(
                  UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[4]
                    ?.input_label_en,
                  UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[4]
                    ?.input_label_ar
                )}
                onUploadComplete={handleUploadComplete}
                name={
                  UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[4]
                    ?.name
                }
                multiple
                toggleFileUpload={onFileUploaded}
                uploadDocFunc={UploadDoc}
                uploadedFiles={incidentDocs || []}
                setUploadedFiles={setIncidentInput}
              />
              {errors?.[
                UPLOAD_DOCS_INPUT?.non_solidarity_vehicle_damage?.docs?.[4]
                  ?.name
              ] && (
                <p className="text-red-500 text-sm">
                  {t("validation.required")}
                </p>
              )}
            </div> */}
          </div>
        )}

        {flowType != FILE_CLAIM_NON_MOTOR_POLICY_HOLDER && (
          <div>
            <div>
              <DragDropFileUpload
                label={t(
                  "motorInsurance.fileAClaimFlow.repairOrCashPage.report_file_label"
                )}
                onUploadComplete={handleUploadComplete}
                name={"incident_report"}
                multiple
                toggleFileUpload={onFileUploaded}
                uploadDocFunc={UploadDoc}
                uploadedFiles={incidentDocs || []}
                setUploadedFiles={setIncidentInput}
              />
              {errors?.["incident_report"] && (
                <p className="text-red-500 text-sm">
                  {t("validation.required")}
                </p>
              )}
            </div>
          </div>
        )}

        {flowType == FILE_CLAIM_NON_MOTOR_POLICY_HOLDER && (
          <div className="flex items-start gap-2 self-stretch px-0 py-1 mb-4 mt-4">
            <CustomCheckbox
              Label={
                <div className="flex flex-col">
                  <label
                    htmlFor="agreement"
                    className="text-[#252C32] text-sm not-italic font-normal leading-6 max767:break-all"
                  >
                    <p>
                      {translate(
                        "By submitting this form, you agree to be contacted regarding your request and you are confirming you agree to our Terms of Use and Privacy Policy.",
                        "من خلال تقديم هذا النموذج، فإنك توافق على أن يتم التواصل معك بشأن طلبك وتؤكد موافقتك على شروط الاستخدام وسياسة الخصوصية الخاصة بنا."
                      )}
                    </p>
                  </label>
                </div>
              }
              isChecked={isChecked}
              setIsChecked={setIsChecked}
            />
          </div>
        )}
      </div>
    </FileClaimMotorLayout>
  );
}

export default UploadDocsPage;
