import React, { useState } from 'react'
import Button from '../../../../../components/common/Button/Button'
import BackdropModal from '../../../../../components/common/modal/backdropModal';
import useValidation from '../../../../../hooks/useValidation';
import backarrow from "../../../../../assets/images/back-arrow-left.svg"
import Input from '../../../../../components/common/Input/Input';
import TelInput from '../../../../../components/common/telInput/telInput';
import SelectInput from '../../../../../components/common/selectInput/selectInput';
import { daysOptions, monthsOptions, yearsOptions } from '../../../../motorInsurancePages/newMotorPolicy/start/basicInfo/data';
import { useTranslation } from 'react-i18next';
import AlertComponent from '../../../../motorInsurancePages/transferPolicyFlow/alertComponent';
import FormTabs from '../../../../../components/common/tabs/formTabs';
import { RELATIONSHIP_OPTIONS, TRUSTEE_DETAILS_VALIDATION, trusteeTypeTabTypes } from './data';
import { genderTabs } from '../../../../buyOnline/expressShield/details/otherDetails';
import { COUNTRY_SELECT_DATA } from '../../../../buyOnline/expressShield/details/data';
import useScreenResolution from '../../../../../hooks/useScreenResolution';
import BottomStickyBtnLayout from '../../../../motorInsurancePages/newMotorPolicy/Layouts/BottomStickyBtnLayout';
import useLanguageTranslate from '../../../../../hooks/useLanguageTranslate';

const TrusteeDetailPopup = ({ show, setShow, AddBenficiary, toggleAddNewBeneficiary }) => {
    const { t } = useTranslation();
    const translate = useLanguageTranslate()
    const [values, setValues] = useState({});
    const { errors, validateFields } = useValidation();
    const {mobile} = useScreenResolution()
    const validateForm = (data) => {
        let isValid = validateFields(TRUSTEE_DETAILS_VALIDATION, data ? data : values)
        return isValid
    };
    const onClose = () => {
        setShow((state) => !state);
        setValues({})
    }
    const addHandler = () => {
        if (!validateForm()) return;
        //onClose()
        // this replace with api call 
        AddBenficiary(values)
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        let update = { ...values, [name]: value }
        setValues(update);
        if (errors?.[name]) {
            validateForm(update);
        }
    }

    const onTabClick = (value, name) => {
        let update = { ...values, [name]: value }
        setValues(update);
        if (errors?.[name]) {
            validateForm(update);
        }

    };
    const backClickHandler = () => {
        toggleAddNewBeneficiary();
        onClose()
    }
    return (
      <div className="TrusteesDetails">
        <BackdropModal onClose={onClose} show={show}  hideClosebtn={mobile}>
          <div className="content mb-4 max767:mt-[0px] ">
            <div className="w-[60%] mt-[-53px] flex items-center px-0 py-2.5 justify-between max767:w-[100%] max767:mt-3 max767:flex-col max767:justify-start">
              <p onClick={backClickHandler} className="flex gap-2 max767:w-full justify-center items-center">
                {" "}
<img src={backarrow} alt="" className='rtl:rotate-180'/>
                <span className="back-text inline max767:hidden">{translate("Back","خلف")}</span>
              </p>
              <h1 className="text-[#1A2024] text-md not-italic font-semibold leading-8 tracking-[-0.24px] max767:text-xl max767:mt-6 max767:text-left max767:w-full">
                {translate("Trustees Details","تفاصيل الأمناء")}
              </h1>
              {mobile && <p>{translate("Please review your information and submit request.","يرجى مراجعة معلوماتك وتقديم الطلب.")}</p>}
            </div>
            <div className="mt-6">
              <AlertComponent
                title={
                  translate("Notice: The beneficiary is under the age of 21. Please provide the trustee's contact information to add beneficiary.","ملاحظة: هذا المستفيد أقل من 21 عامًا. يرجى تقديم معلومات الاتصال الخاصة بالأمناء لإضافة المستفيد.")
                }
              />

              <div className=" mt-4 max767:w-full mx-auto ipad:w-full">
                <FormTabs
                  label={translate("Trustees Type","نوع الأمناء")}
                  tabs={trusteeTypeTabTypes}
                  onTabClick={(type) => onTabClick(type, "is_finance")}
                  activeTab={values.is_finance}
                  error={errors?.is_finance}
                />
                <Input
                  label={t(
                    "motorInsurance.newMotorInsuranceFlow.basicInfo.fullName"
                  )}
                  type="text"
                  name="fullName"
                  value={values.fullName}
                  error={errors.fullName}
                  handleChange={handleChange}
                />
                <Input
                  label={translate("Email Address","عنوان البريد الإلكتروني")}
                  type="email"
                  name="email"
                  value={values?.email}
                  error={errors?.email}
                  handleChange={handleChange}
                />
                <TelInput
                  name="mobile"
                  value={values?.mobile}
                  error={errors?.mobile}
                  onChange={handleChange}
                  label={t(
                    "motorInsurance.newMotorInsuranceFlow.basicInfo.mobileNumber"
                  )}
                  className="mobilenumber px-4 py-3 max767:w-[50%] w-[97%] border-r-0 border-0 rounded-[9px] h-[46px] focus:outline-none"
                />
                <Input
                  label={translate("CPR Number","الرقم الشخصي")}
                  type="text"
                  name="cpr"
                  value={values?.cpr}
                  error={errors?.cpr}
                  handleChange={handleChange}
                />

                <div className="date_of_birth">
                  <label className="block mb-[5px] text-gray-700 not-italic font-normal leading-6 text-base max-350:text-[13px]">
                    {t(
                      "motorInsurance.newMotorInsuranceFlow.basicInfo.dateOfBirth"
                    )}
                  </label>
                  <div className="flex gap-6 self-stretch">
                    <SelectInput
                      name="day"
                      options_data={daysOptions}
                      formData={values}
                      default_placeholder={t(
                        "motorInsurance.newMotorInsuranceFlow.basicInfo.day"
                      )}
                      error={errors?.day}
                      handleChange={handleChange}
                    />

                    <SelectInput
                      name="month"
                      formData={values}
                      default_placeholder={t(
                        "motorInsurance.newMotorInsuranceFlow.basicInfo.month"
                      )}
                      options_data={monthsOptions}
                      error={errors?.month}
                      handleChange={handleChange}
                    />

                    <SelectInput
                      name="year"
                      formData={values}
                      default_placeholder={t(
                        "motorInsurance.newMotorInsuranceFlow.basicInfo.year"
                      )}
                      options_data={yearsOptions}
                      error={errors?.year}
                      handleChange={handleChange}
                    />
                  </div>
                </div>

                <FormTabs
                  label={t("buyOnline.expressShieldFlow.otherDetails.gender")}
                  tabs={genderTabs}
                  onTabClick={(type) => onTabClick(type, "gender")}
                  activeTab={values?.gender}
                  error={errors?.gender}
                />

                <SelectInput
                  label={translate("Beneficiary Relationship","علاقة المستفيد")}
                  type="text"
                  name="relationship"
                  error={errors?.relationship}
                  handleChange={handleChange}
                  options_data={RELATIONSHIP_OPTIONS}
                  formData={values}
                />

                <SelectInput
                  name="nationality"
                  formData={values}
                  label={translate("Nationality","الجنسية")}
                  options_data={COUNTRY_SELECT_DATA}
                  error={errors?.nationality}
                  handleChange={handleChange}
                />
              </div>

              {!mobile && (<div className="mt-8 flex items-start gap-3 self-stretch">
                <Button
                  onSubmit={backClickHandler}
                  label={translate("Cancel","إلغاء")}
                  className="flex flex-col items-center gap-2.5 flex-[1_0_0] px-4 py-2 border rounded-[10px] border-solid border-[#DDE2E4] bg-white text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
                />
                <Button
                  onSubmit={addHandler}
                  label={translate("Add","إضافة")}
                  className="flex flex-col items-center gap-2.5 flex-[1_0_0] px-4 py-2 border rounded-[10px] border-solid border-[#DDE2E4] bg-[#55C6EF] text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
                />
              </div>)}
              {mobile && (
            <BottomStickyBtnLayout
              btn_label={translate("Add","إضافة")}
              btn_onClick={addHandler}
            ></BottomStickyBtnLayout>
          )}
            </div>
          </div>
        </BackdropModal>
      </div>
    );
}

export default TrusteeDetailPopup