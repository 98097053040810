import React from "react";
import backarrow from "../../../assets/images/back-arrow-left.svg";
import closeicon from "../../../assets/images/close.svg";
import { useNavigate } from "react-router-dom";
import { screenResolution } from "../../../utils/screenResolution";
import BottomStickyBtnLayout from "../newMotorPolicy/Layouts/BottomStickyBtnLayout";
import useAddMoreCoversFlow from "../../../hooks/useAddMoreCoversFlow";
import BottomPriceLayout from "../newMotorPolicy/Layouts/BottomPriceLayout";
import { PriceProvider } from "../newMotorPolicy/Layouts/PriceContext";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";

const AddMoreCoversMotorLayout = ({
    children,
    is_hide_back_btn,
    show_close_btn,

}) => {
    const navigate = useNavigate();
    const { handlePrevSubstep } = useAddMoreCoversFlow();
    const translate = useLanguageTranslate();

    return (
        <div className="">
            {!is_hide_back_btn && (
                <button
                    className="backButton bg-navy-blue py-2 rounded mt-20 flex absolute left-[26px] top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:bg-[#F6F8F9] max767:w-10 max767:h-10 max767:text-center max767:justify-center max767:rounded-3xl max767:mt-12 max767:ml-[-11px] rtl:right-[26px]"
                    onClick={handlePrevSubstep}
                >
                    <img src={backarrow} alt="Back arrow" className="1 rtl:rotate-180" />
                    <span className="back-text inline max767:hidden">{translate("Back", "رجوع")}</span>
                </button>
            )}
            {show_close_btn && screenResolution.mobile && (
                <button
                    className="bpy-2 rounded mt-4 flex absolute top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:ml-0"
                    onClick={() => navigate(-1)}
                >
                    <img src={closeicon} alt="Close Icon" />
                </button>
            )}
            {children}

        </div>
    );
};

export default AddMoreCoversMotorLayout;
