import React from 'react';
import useLanguageTranslate from '../../../hooks/useLanguageTranslate';

const FormTabs = ({ tabs, activeTab, onTabClick, className, label, error }) => {
  const translate = useLanguageTranslate();

  return (
    <>
      {label && (
        <p className="label text-[#48535B] text-base not-italic font-normal leading-[26px] tracking-[-0.16px]">
          {label}
        </p>
      )}
      <div
        className={`flex pt-[5px] border-b-[#DDE2E4] mb-6 yesno-tab-box w-full ${className}`}
        role="tablist"
      >
        {tabs.map((tab) => (
          <button
            key={tab.type}
            onClick={() => onTabClick(tab.type, tab)}
            className={`${
              activeTab == tab.type
                ? "bg-[#E3F8FF] text-[#00719A] text-sm not-italic font-semibold flex w-[50%] box-border flex-col items-center gap-2.5 shrink-0 px-4 py-[13px] border-2 border-solid border-[#00719A] max767:mb-2 h-[50px] justify-center"
                : "flex w-[50%] h-[50px] flex-col items-center shrink-0 border bg-white px-4 py-[13px] border-solid border-[#DDE2E4] max767:border-r max767:mb-2 box-border justify-center"
            }`}
            role="tab"
            aria-selected={activeTab == tab.type}
          >
            <span className="top_title text-center text-base not-italic font-normal leading-6 max767:text-left max767:text-[14px] rtl:text-sm">
              {translate(tab.label_en, tab.label_ar)}
            </span>
            {tab.subtitle_en && (
              <span className="top_title text-center text-base not-italic font-normal leading-6  max767:w-6/12 max767:inline-block">
                {translate(tab.subtitle_en, tab.subtitle_ar)}
              </span>
            )}
          </button>
        ))}
      </div>
      {error && <p className="field-err text-red-500 text-sm">{error}</p>}
    </>
  );
};

export default FormTabs;
