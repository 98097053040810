import React, { useEffect, useState } from "react";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useTranslation } from "react-i18next";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import Button from "../../../components/common/Button/Button";
import { toast } from "react-toastify";
import useLifeFormData from "../../../hooks/useLifeFormData";
import LifeDynamicTable from "../../../components/common/table/lifeTable";
import EssentialCareLayout from "../essentialCareLayout";
import AddNewFamilyMemberPopup from "./addNewFamilyMemberPopup";
import { differenceInYears, format, parseISO } from "date-fns";
import faPlusCircleimg from "../../../assets/images/add.svg";
import listblackicon from "../../../assets/images/list-black.svg";
import ViewEssentialCareBenefitsDetailsPopup from "../viewEssentialCareBenefitsDetailsPopup";
import { selectProfileData } from "../../../redux/features/profileSlice";
import { useSelector } from "react-redux";
import { selectNewLifeForm } from "../../../redux/features/newLifeFormSlice";
import { dateFormatter } from "../../../utils";
import useUpdateLead from "../../../hooks/BuyOnlineExpressShield/useUpdateLead";
import { LEAD_INFO } from "../details/data";
import useEssentialCareQuote from "../../../hooks/essentialCare/useEssentialCareQuote";
import { getRelationShipOptionFromValue } from "../data";
import { ESSENTIAL_CARE_DECLERATION } from "./declaration";
import { ESSENTIAL_CARE_PERSONAL_DETAILS } from "./personalDetails";

export const ESSENTIAL_CARE_FAMILY_MEMBER_DETAILS =
  "ESSENTIAL_CARE_FAMILY_MEMBER_DETAILS";
export const ESSENTIAL_CARE_FAMILY_MEMBER_DETAILS_VALUES =
  "ESSENTIAL_CARE_FAMILY_MEMBER_DETAILS_VALUES";
const FamilyMembersDetails = ({ onSuccess }) => {
  const [beneficairyDetailsInfo, setBeneficiaryDetailsInfo] = useState([]);
  const [viewPlanList, setViewPlanList] = useState(false);
  const [showAddNewBenefi, setShowAddNewBenefi] = useState(false);

  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const { saveLifeDataInRedux } =
    useLifeFormData(ESSENTIAL_CARE_FAMILY_MEMBER_DETAILS, setBeneficiaryDetailsInfo);

  const familyMembersDetails = useSelector(selectNewLifeForm)?.[ESSENTIAL_CARE_FAMILY_MEMBER_DETAILS];
  const essentialCarePersonalDetails = useSelector(selectNewLifeForm)?.[ESSENTIAL_CARE_PERSONAL_DETAILS];
  const profileData = useSelector(selectProfileData);


  const { essentialCareQuoteHandler, isLoading } = useEssentialCareQuote();




  const beneficiaryTableFormatter = (formValues, response, hide_gender) => {
    return {
      original_data: { ...formValues, response },
      data: [
        {
          id: 1,
          column1: "Name",
          column1_ar: "الاسم",
          column2: formValues?.fullName,
        },
        {
          id: 2,
          column1: "CPR No.",
          column1_ar: "رقم CPR",
          column2: formValues?.cpr,
        },
        ...(!hide_gender ?
          [{
            id: 3,
            column1: "Gender",
            column1_ar: "الجنس",
            column2: formValues?.gender,
          }] : []),
        {
          id: 4,
          column1: "Date of Birth",
          column1_ar: "تاريخ الميلاد",
          column2: dateFormatter(
            new Date(formValues?.year, formValues?.month - 1, formValues?.day),
            "dd MMMM yyyy",
            translate("en", "ar")
          ),
        },
        {
          id: 5,
          column1: "Relationship",
          column1_ar: "العلاقة",
          column2: translate(getRelationShipOptionFromValue(formValues?.relationship)?.label, getRelationShipOptionFromValue(formValues?.relationship)?.label_ar),
        },
        {
          id: 6,
          column1: "Premium",
          column1_ar: "القسط",
          column2: translate("BHD 84", "٨٤ دينار بحريني"),
        },
      ]

    };
  };

  console.log(essentialCarePersonalDetails, "dec")

  useEffect(() => {
    if (!profileData || familyMembersDetails?.length || beneficairyDetailsInfo?.length) return
    const dob = parseISO(profileData?.DateOfBirth || "");
    let values = {
      fullName: profileData?.FirstName || "",
      gender: essentialCarePersonalDetails?.answers?.Gender === "Female" ? "F" : "M",
      month: format(dob, "MM") || "",
      day: format(dob, "dd") || "",
      year: format(dob, "yyyy") || "",
      cpr: profileData?.CPR || "",
      relationship: "12",
      Martialstatus: essentialCarePersonalDetails?.answers?.Martialstatus === "Single" ? "SINGLE" : "MARRIED"
    };

    let formatedData = beneficiaryTableFormatter(values, "", true);
    let update = [...beneficairyDetailsInfo, formatedData];
    setBeneficiaryDetailsInfo(update);
    essentialCareQuoteHandler(update)
    // saveLifeDataInRedux(update);
  }, [profileData]);



  const AddBenficiary = async (values) => {
    // Extract values
    const { cpr, day, month, year, relationship } = values;

    const cprExists = beneficairyDetailsInfo.some(
      (beneficiary) => beneficiary?.original_data?.cpr == cpr
    );

    if (cprExists) {
      toast.error("CPR already exists.");
      return;
    }

    // Construct the beneficiary's date of birth
    const dob = new Date(year, month, day); // Month is zero-indexed in JavaScript

    // Calculate age
    const age = differenceInYears(new Date(), dob);

    if (relationship == "13") {
      const spouseExists = beneficairyDetailsInfo.some(
        (beneficiary) => beneficiary.relationship == "13"
      );
      if (spouseExists) {
        toast.error("A spouse is already added.");
        return; // Stop further processing
      } else if (age < 18) {

        toast.error("Spouse must be 18 years or older");
        return; // Stop further processing
      }
    } else if (relationship == "14") {
      if (age >= 18) {

        toast.error("Children must be less than 18 years old");
        return; // Stop further processing
      }
    }
    let format = beneficiaryTableFormatter(values);
    let update = [...beneficairyDetailsInfo, format];
    setBeneficiaryDetailsInfo(update);
    essentialCareQuoteHandler(update)

    saveLifeDataInRedux(update);
    // Proceed with adding the beneficiary
    // toast.success(translate("Family member added successfully.", "تم إضافة عضو الأسرة بنجاح."), values);
  };

  const { updateLeadHandler, leadLoader } = useUpdateLead()


  const toggleAddNewBeneficiary = () => {
    setShowAddNewBenefi((state) => !state);
  };


  const nextClickHandler = async () => {
    updateLeadHandler({
      Info: LEAD_INFO.addFamilyMember,
    })
    saveLifeDataInRedux(beneficairyDetailsInfo);

    onSuccess();
  };
  const removeBeneficiary = (data, index) => {
    let update = beneficairyDetailsInfo?.filter((_, i) => i != index);
    setBeneficiaryDetailsInfo(update);
    essentialCareQuoteHandler(update)
    saveLifeDataInRedux(update);
  };

  const personalDetails =
    useSelector(selectNewLifeForm)?.[ESSENTIAL_CARE_PERSONAL_DETAILS];
  const isSingle = personalDetails?.answers?.Martialstatus === "Single"



  return (
    <EssentialCareLayout
      is_bottom_sticky_layout
      btn_onClick={nextClickHandler}
      isLoading={isLoading}
      show_summary
    >
      <div className="w-[500px] max-w-[100%] mx-auto">
        <TitleDescription
          title={t("essentialCareFlow.beneficiaryInfo.title")}
          description={t("essentialCareFlow.beneficiaryInfo.description")}
        />
        <div></div>
        <AddNewFamilyMemberPopup
          show={showAddNewBenefi}
          setShow={setShowAddNewBenefi}
          AddBenficiary={AddBenficiary}
        />
        {beneficairyDetailsInfo.length > 0 && (
          <div className="beneficiaryDetails bg-[#F6F8F9] rounded-[26px] p-5 max767:p-0">
            <LifeDynamicTable
              data={beneficairyDetailsInfo?.map((each, i) => {
                let update = JSON.parse(JSON.stringify(each));
                if (i == 0) {
                  update.data = update.data.filter((item) => item.id != 5);
                  update = {
                    ...update,
                    tableTitle: "Primary (You)",
                    tableTitle_ar: "تفاصيل المستفيد",
                  };
                } else {
                  update = {
                    ...update,
                    tableTitle: `Family Member ${i}`,
                    tableTitle_ar: `فرد العائلة ${i}`,
                    deleteBtn: (
                      <Button
                        label={t(
                          "essentialCareFlow.beneficiaryInfo.removeBeneficiary"
                        )}
                        onSubmit={() => removeBeneficiary(each, i)}
                        className="removeBeneficiary flex flex-col items-center gap-2.5 border bg-white px-4 py-2 rounded-md text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
                      />
                    ),
                  };
                }
                return update;
              })}
            />
          </div>
        )}

        <div className="mb-4">
          <div className="mx-auto flex justify-center toggleAddNewBeneficiary mt-6 mb-6">
            {!isSingle && <Button
              className="flex flex-col items-center gap-2 bg-[#55C6EF] px-4 py-2 rounded-[10px]"
              label={t("essentialCareFlow.beneficiaryInfo.addMember")}
              onSubmit={toggleAddNewBeneficiary}
              pre_icon={faPlusCircleimg}
            />}
          </div>
          <div className="mx-auto flex justify-center EssentialCare">
            <Button
              className="flex items-center gap-2 border bg-white px-4 py-2 rounded-[10px] border-solid border-[#DDE2E4] text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] ipad:mt-4"
              onSubmit={() => setViewPlanList(true)}
              pre_icon={listblackicon}
              label={translate(
                "Essential Care benefits & covers",
                "مزايا وتغطيات الرعاية الأساسية"
              )}
            />
          </div>
          <div className="ViewEssentialCareBenefitsDetailsPopup">
            <ViewEssentialCareBenefitsDetailsPopup
              viewPlanList={viewPlanList}
              closePopup={() => setViewPlanList(false)}
            />
          </div>
        </div>
      </div>
    </EssentialCareLayout>
  );
};
export default FamilyMembersDetails;
