import React, { useState } from "react";
import Button from "../../../components/common/Button/Button";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import BackBtnWithLabel from "../../../components/common/backBtnWithLabel/backBtnWithLabel";
import Loader from "../../../components/common/Loader/Loader";
import useScreenResolution from "../../../hooks/useScreenResolution";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import FormTabs from "../../../components/common/tabs/formTabs";
import { yesNoTabs } from "../../buyOnline/expressShield/plan/declaration";
import Axios from "../../../redux/axiosInstance";
import { signUpApiEndpoints } from "../../../api";
import { useSelector } from "react-redux";
import { selectCprFromKycVerification } from "../../../redux/features/signUp/kycVerificationStatusSlice";
import { toast } from "react-toastify";
import IAgreePopup from "../../../components/common/IAgreePopup";

const ConfirmCitizenShip = ({ onSuccess }) => {
  const translate = useLanguageTranslate();
  const { mobile } = useScreenResolution();

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });

  const onTabClick = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  const [loading, setLoading] = useState(false);
  const { signupDetails } = useSelector((state) => state.signup);
  const cprFromVerifKyc = useSelector(selectCprFromKycVerification);
  const cprNumber = signupDetails?.cprNumber || cprFromVerifKyc;

  const continueClickHandler = () => {
    setShowIAgreePopup(false);
    setLoading(true);
    console.log(formData.us_citizen_ship);
    const payload = {
      cpr: cprNumber,
      FATCADeclarationId: formData.us_citizen_ship == "Yes" ? "Y" : "N",
      USCitizen: formData.us_citizen_ship == "Yes" ? "Y" : "N",
      bornInUs: formData.us_citizen_ship == "Yes" ? "Y" : "N",
      USTaxResident: formData.us_citizen_ship == "Yes" ? "Y" : "N",
      crsDeclarationId: "NO",
      salary: "< BD 300",
      natureOfBusinessActivity: "Insurance Service",
      sourceOfFunds: "Salary",
      sourceOfWealth: "Debt issued",
      reasonForOpeningAccount: "Insurance",
      remarks: "Solidarity",
      dueDiligenceLevel: "COMMON",
    };

    Axios.post(signUpApiEndpoints.confirmUsCitizenship, payload)
      .then((res) => {
        console.log(res, "some res");
        if (res?.status == 200) {
          onSuccess();
        }
      })
      .catch((err) => {
        toast.error(
          translate(
            err.response.data.ErrorMessageEn,
            err.response.data.ErrorMessageAr
          )
        );
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const [showIAgreePopup, setShowIAgreePopup] = useState(false);
  const toggleshowAgreePopup = (e) => {
    e.preventDefault();
    setShowIAgreePopup(!showIAgreePopup);
  };
  return (
    <>
      <IAgreePopup show={showIAgreePopup} onAgree={continueClickHandler} />

      {loading && <Loader className="w-10 h-10 fixed left-[50%] top-[50%]" />}
      <div className="sign_in_page flex align-center h_100vh justify-center items-center mt-20 max767:mt-20 ipad:mt-20">
        <div className="no_padding_top right_cont signup-right-content h_100 create_pass_page justify-center items-center block min-w-[500px]  pb-0 px-0 max767:min-w-[90%] max767:p-5">
          <div className="content">
            {mobile && (
              <BackBtnWithLabel label={translate("Sign up", "التسجيل")} />
            )}
            <TitleDescription
              className="max767:mt-[-5px] max767:mb-6"
              title={translate(
                "Confirm U.S.A Citizenship",
                "تأكيد الجنسية الأمريكية"
              )}
              description={translate(
                "Please confirm if you are a USA citizen for compliance purposes.",
                "يرجى تأكيد ما إذا كنت مواطنًا أمريكيًا لمتطلبات الامتثال."
              )}
            />
          </div>
          <div className="mb-6">
            <FormTabs
              label={""}
              tabs={yesNoTabs}
              onTabClick={(type) => onTabClick(type, "us_citizen_ship")}
              activeTab={formData.us_citizen_ship}
            />
          </div>
          <Button
            type="submit"
            label={translate("Continue", "تابع")}
            className="primary-button"
            disabled={!formData.us_citizen_ship || loading}
            onSubmit={toggleshowAgreePopup}
          />
        </div>
      </div>
    </>
  );
};

export default ConfirmCitizenShip;
