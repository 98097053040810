export const sampleFundData = {
  sectorDistribution: [
    { name: "Industrials", value: "32.87%" },
    { name: "Consumer Discretionary", value: "20.79%" },
    { name: "Communication Services", value: "11.33%" },
    { name: "Energy", value: "7.11%" },
    { name: "Consumer Staples", value: "7.08%" },
    { name: "Financials", value: "5.58%" },
    { name: "Information Technology", value: "4.56%" },
    { name: "Real Estate", value: "2.36%" },
    { name: "Materials", value: "0.87%" },
    { name: "Cash", value: "0.36%" },
    { name: "Not Applicable", value: "0.29%" },
    { name: "Government", value: "0.09%" },
  ],
  ratingDistribution: [
    { name: "BBB", value: "20.20%" },
    { name: "BB+", value: "15.65%" },
    { name: "BB", value: "14.43%" },
    { name: "BB-", value: "13.37%" },
    { name: "B+", value: "12.47%" },
    { name: "B", value: "8.64%" },
    { name: "CCC", value: "3.59%" },
    { name: "CC", value: "0.78%" },
    { name: "OTHER", value: "0.57%" },
    { name: "BBB-", value: "0.43%" },
    { name: "NR", value: "0.23%" },
    { name: "D", value: "0.23%" },
    { name: "C", value: "0.23%" },
    { name: "WR", value: "0.16%" },
  ],
  topHoldings: [
    { name: "COG Holdings LLC 144A", value: "1.09%" },
    { name: "OneMain Finance Corp", value: "0.88%" },
    { name: "CSC Holdings LLC 144A", value: "0.84%" },
    { name: "Teva Pharmaceutical Finance 144A", value: "0.82%" },
    { name: "Venture Global LNG Inc 144A", value: "0.78%" },
    { name: "CHS/Community Health Systems", value: "0.76%" },
    { name: "Sirius XM Radio Inc 144A", value: "0.76%" },
    { name: "TransDigm Inc 144A", value: "0.75%" },
    { name: "Dish DBS Corp 144A", value: "0.64%" },
    { name: "Tenet Healthcare Corporation", value: "0.55%" },
  ],
  countryExposure: [
    { name: "USA", value: "83.90%" },
    { name: "Canada", value: "4.80%" },
    { name: "United Kingdom", value: "1.87%" },
    { name: "Monaco", value: "1.65%" },
    { name: "Luxembourg", value: "1.04%" },
    { name: "France", value: "0.88%" },
    { name: "Italy", value: "0.79%" },
    { name: "Ireland", value: "0.75%" },
    { name: "Germany", value: "0.71%" },
    { name: "Netherlands", value: "0.71%" },
    { name: "Australia", value: "0.67%" },
    { name: "Japan", value: "0.56%" },
    { name: "Switzerland", value: "0.47%" },
    { name: "Austria", value: "0.22%" },
    { name: "Spain", value: "0.14%" },
    { name: "Jersey", value: "0.14%" },
    { name: "Belgium", value: "0.14%" },
    { name: "Bermuda", value: "0.14%" },
    { name: "Puerto Rico", value: "0.07%" },
    { name: "Malaysia", value: "0.07%" },
    { name: "Others", value: "0.00%" },
  ],
  productFees: [
    { name: "Fund Fees", value: "0.10%" },
    { name: "Ongoing Charges", value: "0.30%" },
    { name: "Management Fee", value: "0.00%" },
  ],
  assetAllocation: [
    { name: "Fixed Income", value: "99.84%" },
    { name: "Cash", value: "0.16%" },
  ],
  pricePerformance: [
    { name: "Total NAV", value: "47.07 USD" },
    { name: "Day Change", value: "-0.17%" },
    { name: "One Month", value: "0.21%" },
    { name: "Three Months", value: "0.45%" },
    { name: "Six Months", value: "2.35%" },
    { name: "YTD", value: "5.44%" },
    { name: "One Year", value: "6.58%" },
    { name: "Three Years", value: "N/A" },
    { name: "Five Years", value: "N/A" },
    { name: "Ten Years", value: "N/A" },
  ],
  currencyExposure: [
    { name: "USD", value: "100%" },

  ]
};


export const fundDetails = {
  fundName: "0-5 YEAR HIGH YIELD CORPORATE BOND ETF INC",
  ISIN: "LU1244530892",
  assetClass: "Fixed Income",
  category: "Growth",
  description:
    "The Sub-Fund seeks to achieve medium to long-term capital growth and a reasonable level of income by investing primarily in equities listed on recognized Gulf Cooperation Council (GCC) stock exchanges The Sub-Fund seeks to achieve medium to long-term capital growth and a reasonable level of income by investing primarily in equities listed on recognized Gulf Cooperation Council (GCC) stock exchanges, The Sub-Fund seeks to achieve medium to long-term capital growth and a reasonable level of income by investing primarily in equities listed on recognized Gulf Cooperation Council (GCC) stock exchanges, The Sub-Fund seeks to achieve medium to long-term capital growth and a reasonable level of income by investing primarily in equities listed on recognized Gulf Cooperation Council (GCC) stock exchanges...",
  pdfLink: "/download.pdf",
};

export const rollingChartData = {
  data: {
    fundPerformance: [0, 3, 4, 6, 4, 7, 5, 6, 8, 3, 6, 7, 9, 4, 6, 5, 4],
    benchmarkPerformance: [1, 2, 3, 7, 8, 6, 4, 5, 3, 4, 5, 6, 4, 3, 5, 4, 3],
  },

  labels: [
    "May 21",
    "Jul 21",
    "Sep 21",
    "Dec 21",
    "Feb 22",
    "Apr 22",
    "Jun 22",
    "Aug 22",
    "Oct 22",
    "Dec 22",
    "Feb 23",
    "Apr 23",
    "Jun 23",
    "Aug 23",
    "Oct 23",
    "Dec 23",
    "Feb 24",
  ],
};


export const countryFullData = {
  "AFG": "Afghanistan",
  "ALB": "Albania",
  "DZA": "Algeria",
  "AND": "Andorra",
  "AGO": "Angola",
  "ATG": "Antigua and Barbuda",
  "ARG": "Argentina",
  "ARM": "Armenia",
  "AUS": "Australia",
  "AUT": "Austria",
  "AZE": "Azerbaijan",
  "BHS": "Bahamas",
  "BHR": "Bahrain",
  "BGD": "Bangladesh",
  "BRB": "Barbados",
  "BLR": "Belarus",
  "BEL": "Belgium",
  "BLZ": "Belize",
  "BEN": "Benin",
  "BTN": "Bhutan",
  "BOL": "Bolivia",
  "BIH": "Bosnia and Herzegovina",
  "BWA": "Botswana",
  "BRA": "Brazil",
  "BRN": "Brunei",
  "BGR": "Bulgaria",
  "BFA": "Burkina Faso",
  "BDI": "Burundi",
  "CPV": "Cabo Verde",
  "KHM": "Cambodia",
  "CMR": "Cameroon",
  "CAN": "Canada",
  "CAF": "Central African Republic",
  "TCD": "Chad",
  "CHL": "Chile",
  "CHN": "China",
  "COL": "Colombia",
  "COM": "Comoros",
  "COD": "Congo (DRC)",
  "COG": "Congo (Republic)",
  "CRI": "Costa Rica",
  "CIV": "Côte d'Ivoire",
  "CYM": "Cayman Islands",
  "HRV": "Croatia",
  "CUB": "Cuba",
  "CYP": "Cyprus",
  "CZE": "Czech Republic",
  "DNK": "Denmark",
  "DJI": "Djibouti",
  "DMA": "Dominica",
  "DOM": "Dominican Republic",
  "ECU": "Ecuador",
  "EGY": "Egypt",
  "SLV": "El Salvador",
  "GNQ": "Equatorial Guinea",
  "ERI": "Eritrea",
  "EST": "Estonia",
  "SWZ": "Eswatini",
  "ETH": "Ethiopia",
  "FJI": "Fiji",
  "FIN": "Finland",
  "FRA": "France",
  "GAB": "Gabon",
  "GMB": "Gambia",
  "GEO": "Georgia",
  "DEU": "Germany",
  "GHA": "Ghana",
  "GRC": "Greece",
  "GRD": "Grenada",
  "GTM": "Guatemala",
  "GIN": "Guinea",
  "GNB": "Guinea-Bissau",
  "GUY": "Guyana",
  "HTI": "Haiti",
  "HND": "Honduras",
  "HUN": "Hungary",
  "ISL": "Iceland",
  "IND": "India",
  "IDN": "Indonesia",
  "IRN": "Iran",
  "IRQ": "Iraq",
  "IRL": "Ireland",
  "ITA": "Italy",
  "JAM": "Jamaica",
  "JPN": "Japan",
  "JOR": "Jordan",
  "JEY" : "Jersey",
  "KAZ": "Kazakhstan",
  "KEN": "Kenya",
  "KIR": "Kiribati",
  "KOR": "Korea (South)",
  "KWT": "Kuwait",
  "KGZ": "Kyrgyzstan",
  "LAO": "Laos",
  "LVA": "Latvia",
  "LBN": "Lebanon",
  "LSO": "Lesotho",
  "LBR": "Liberia",
  "LBY": "Libya",
  "LIE": "Liechtenstein",
  "LTU": "Lithuania",
  "LUX": "Luxembourg",
  "MDG": "Madagascar",
  "MWI": "Malawi",
  "MYS": "Malaysia",
  "MDV": "Maldives",
  "MLI": "Mali",
  "MLT": "Malta",
  "MHL": "Marshall Islands",
  "MRT": "Mauritania",
  "MUS": "Mauritius",
  "MEX": "Mexico",
  "FSM": "Micronesia",
  "MDA": "Moldova",
  "MCO": "Monaco",
  "MNG": "Mongolia",
  "MNE": "Montenegro",
  "MAR": "Morocco",
  "MOZ": "Mozambique",
  "MMR": "Myanmar",
  "NAM": "Namibia",
  "NRU": "Nauru",
  "NPL": "Nepal",
  "NLD": "Netherlands",
  "NZL": "New Zealand",
  "NIC": "Nicaragua",
  "NER": "Niger",
  "NGA": "Nigeria",
  "MKD": "North Macedonia",
  "NOR": "Norway",
  "OMN": "Oman",
  "PAK": "Pakistan",
  "PLW": "Palau",
  "PAN": "Panama",
  "PNG": "Papua New Guinea",
  "PRY": "Paraguay",
  "PER": "Peru",
  "PHL": "Philippines",
  "POL": "Poland",
  "PRT": "Portugal",
  "QAT": "Qatar",
  "ROU": "Romania",
  "RUS": "Russia",
  "RWA": "Rwanda",
  "KNA": "Saint Kitts and Nevis",
  "LCA": "Saint Lucia",
  "VCT": "Saint Vincent and the Grenadines",
  "WSM": "Samoa",
  "SMR": "San Marino",
  "STP": "Sao Tome and Principe",
  "SAU": "Saudi Arabia",
  "SEN": "Senegal",
  "SRB": "Serbia",
  "SYC": "Seychelles",
  "SLE": "Sierra Leone",
  "SGP": "Singapore",
  "SVK": "Slovakia",
  "SVN": "Slovenia",
  "SLB": "Solomon Islands",
  "SOM": "Somalia",
  "ZAF": "South Africa",
  "ESP": "Spain",
  "LKA": "Sri Lanka",
  "SDN": "Sudan",
  "SUR": "Suriname",
  "SWE": "Sweden",
  "CHE": "Switzerland",
  "SYR": "Syria",
  "TWN": "Taiwan",
  "TJK": "Tajikistan",
  "TZA": "Tanzania",
  "THA": "Thailand",
  "TLS": "Timor-Leste",
  "TGO": "Togo",
  "TON": "Tonga",
  "TTO": "Trinidad and Tobago",
  "TUN": "Tunisia",
  "TUR": "Turkey",
  "TKM": "Turkmenistan",
  "TUV": "Tuvalu",
  "UGA": "Uganda",
  "UKR": "Ukraine",
  "ARE": "United Arab Emirates",
  "GBR": "United Kingdom",
  "USA": "United States",
  "URY": "Uruguay",
  "UZB": "Uzbekistan",
  "VUT": "Vanuatu",
  "VEN": "Venezuela",
  "VNM": "Vietnam",
  "YEM": "Yemen",
  "ZMB": "Zambia",
  "ZWE": "Zimbabwe"
};