import React, { useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import uploadedfiles from "../../assets/images/Upload.svg";
import xclose from "../../assets/images/Xclose.svg";

import multiplefile from "../../assets/images/add_to_photos.svg";
import useScreenResolution from "../../hooks/useScreenResolution";
import { useTranslation } from "react-i18next";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";

const DragDropFileUpload = ({
  uploadedFiles = [],
  setUploadedFiles = () => {},
  name,
  label,
  onUploadComplete,
  multiple = true,
  toggleFileUpload,
  uploadDocFunc,
  maxFiles = 5, // New prop for max uploads
}) => {
  const translate = useLanguageTranslate();
  const [progress, setProgress] = useState({});
  const fileInputRef = useRef(null);
  const { t } = useTranslation();
  const screenResolution = useScreenResolution();

  const processFiles = (input) => {
    const newFiles = Array.isArray(input) ? input : Array.from(input.files);
    const totalFiles = uploadedFiles.length + newFiles.length;

    if (totalFiles > maxFiles) {
      alert(`You can only upload up to ${maxFiles} files.`);
      return;
    }

    const filesWithPreview = newFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setUploadedFiles([...uploadedFiles, ...filesWithPreview]);
    toggleFileUpload(true, name);

    filesWithPreview.forEach((file) => {
      handleFileUpload(file);
    });
  };

  const onDrop = (acceptedFiles) => {
    processFiles(acceptedFiles);
  };

  const handleFileUpload = async (file) => {
    let uploadProgress = 0;
    let link = null;

    const startUploadInterval = () => {
      return setInterval(async () => {
        uploadProgress += 10;

        setProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: uploadProgress,
        }));

        if (uploadProgress == 10 && !link) {
          clearInterval(uploadInterval);
          link = await uploadDocFunc(file, name);
          console.log("Link generated at 10% progress:", link);
          file.preview = link;
          uploadInterval = startUploadInterval();
        }

        if (uploadProgress >= 100) {
          clearInterval(uploadInterval);
          onUploadComplete && onUploadComplete(file, name);
          if (link) {
            console.log("Final Link:", link);
          }
        }
      }, 300);
    };

    let uploadInterval = startUploadInterval();
  };

  const handleDelete = (fileToDelete) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.filter((file) => file.name != fileToDelete.name)
    );
    toggleFileUpload(false, name);

    setProgress((prevProgress) => {
      const updatedProgress = { ...prevProgress };
      delete updatedProgress[fileToDelete.name];
      return updatedProgress;
    });
  };

  const handleFileChange = (event) => {
    processFiles(event.target);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple });

  return (
    <div className="file-upload">
      <h3 className="mb-2 text-[#48535B] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] mt-6">
        {label}
      </h3>
      {!screenResolution.mobile ? (
        <div
          {...getRootProps({
            className:
              "dropzone h-[164px] justify-center items-center bg-[#F6F8F9] px-4 py-8 rounded-md text-center",
            disabled: uploadedFiles.length >= maxFiles, // Disable if maxFiles reached
          })}
        >
          <input
            {...getInputProps()}
            disabled={uploadedFiles.length >= maxFiles}
          />
          <p className="justify-center flex">
            <img src={uploadedfiles} className="items-center" alt="upload" />
          </p>
          <p className="text-[#252C32] text-center text-sm not-italic font-normal leading-6 mb-2">
            {translate(
              "Drag and drop files here",
              "قم بسحب الملفات وإفلاتها هنا"
            )}
          </p>
          <button
            type="button"
            className="items-center gap-2.5 border bg-white px-4 py-2 rounded-md text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
            disabled={uploadedFiles.length >= maxFiles} // Disable if maxFiles reached
          >
            {translate("Browse", "تصفح")}
          </button>
        </div>
      ) : (
        <div className="flex flex-col space-y-2">
          <div className="max767:block hidden relative">
            <div
              className={`flex fileInput w-full h-[164px] mb-4 bg-[#F6F8F9]`}
            >
              <input
                type="file"
                className="opacity-0 flex h-[164px] justify-center items-center shrink-0 self-stretch w-full rounded-md z-[99] absolute"
                multiple
                onChange={handleFileChange}
                ref={fileInputRef}
                disabled={uploadedFiles.length >= maxFiles} // Disable if maxFiles reached
              />
              <div className="flex flex-col items-center gap-1 relative z-0 mx-auto my-0 top-[60px] inset-x-0">
                <p>
                  <img src={multiplefile} alt="Select multiple files" />
                </p>
                <p>
                  <label>Add file or photo</label>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {uploadedFiles.length > 0 && (
        <div className="uploaded-files mt-4 flex items-start gap-2 self-stretch py-2 rounded-md max767:mt-0 max767:grid flex-col">
          {uploadedFiles.map((file) => (
            <div
              key={file.name}
              className="flex file-item w-full bg-[#F6F8F9] pl-4 pr-2 py-2 mb-1 rounded-md"
            >
              <div className="flex justify-between items-center w-full">
                <span className="text-[#252C32] text-sm not-italic font-normal leading-6">
                  {file.name}
                </span>

                {progress[file.name] == 100 && (
                  <button
                    className="ml-2 px-2 py-1 text-white rounded"
                    onClick={() => handleDelete(file)}
                  >
                    <img src={xclose} alt="close icon" />
                  </button>
                )}
              </div>

              {progress[file.name] < 100 && (
                <div className="relative mt-2 h-2 w-full bg-[#AAE2F7] rounded">
                  <div
                    className="absolute top-0 h-full bg-[#00A9E7] rounded"
                    style={{ width: `${progress[file.name]}%` }}
                  ></div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DragDropFileUpload;
