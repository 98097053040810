import 'react-international-phone/style.css';
import React, { useState, useEffect, useRef } from 'react';
import {
  defaultCountries,
  FlagImage,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import useLanguageTranslate from '../../../hooks/useLanguageTranslate';
 
const TelInput = ({label, value, onChange,error, ...restProps }) => {
  const includedCountries = ['bh', 'kw'];
 
  // const parsedCountries = defaultCountries.filter((iso) =>
  //   includedCountries.includes(iso[1])
  // );
  const parsedCountries = defaultCountries
 
  const { inputRef, country, setCountry } = usePhoneInput({
    defaultCountry: 'bh',
    countries: parsedCountries,
  });
 
  const translate = useLanguageTranslate();
  const [isOpen, setIsOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const dropdownRef = useRef(null);
 
  const handleSelectChange = (iso2,x) => {
    setCountry(iso2);
    onChange({ target: { value: x ,name : "countryCode"} });
    setIsOpen(false);
  };
 
  const handleInputFocus = () => {
    setIsFocused(true);
  };
 
  const handleInputBlur = () => {
    setIsFocused(false);
  };
 
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
 
  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
 
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);
 
  return (
    <>
    <div className="formField form-input min-h-[105px]">
      <label className="mb-1 text-gray-700 not-italic font-normal leading-6 block text-base">
       {label}
      </label>
      <div
        ref={dropdownRef}
        className={`relative flex items-center w-full multi-tel bg-white border m-0 rounded-[10px] border-solid min-h-[50px] border-[#B0BABF] h-[50px] ${
          isFocused
            ? "border-2 border-active rounded border-[black] h-[50px]"
            : "border-1 border-default rounded hover:border-[#84919A] transition duration-300 h-[50px]"
        } `}
        dir={translate("ltr", "rtl")}
      >
        <div
          className={`relative flex items-center bg-white country-selector py-2  rtl:mr-1 rtl:pr-[11px] ${translate(
            "pr-4",
            "pr-2 pl-4"
          )} cursor-pointer`}
          onClick={() => setIsOpen(!isOpen)}
          data-testid="country-selector"
        >
          <FlagImage
            iso2={country.iso2}
            className={translate("ml-2", "ml-3")}
            data-testid="flag-image"
          />
          <span
            dir="ltr"
            className={translate("ml-2", "ml-2")}
            data-testid="dial-code"
          >
            +{country.dialCode}
          </span>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={`w-4 ${translate(
              "mr-2",
              "ml-2"
            )} h-4 text-gray-500 ml-2 transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          />
        </div>
        {isOpen && (
          <ul
            dir={translate("ltr", "rtl")}
            className={`absolute top-full mt-1 w-56 bg-white shadow-lg max-h-60 overflow-auto z-10 country-dropdown`}
            data-testid="country-list"
          >
            {parsedCountries.map((c) => {
              const country = parseCountry(c);
              return (
                <li
                  key={country.iso2}
                  className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100"
                  onClick={(e) => handleSelectChange(country.iso2,country.dialCode)}
                  data-testid={`country-option-${country.iso2}`}
                >
                  <FlagImage
                    iso2={country.iso2}
                    style={{ marginRight: "8px" }}
                    className="ml-1 h-8"
                  />
                  <span className="ml-1">+{country.dialCode}</span>
                </li>
              );
            })}
          </ul>
        )}
        <input
          dir={translate("ltr", "rtl")}
          type="number"
          className="w-full p-2 focus:outline-none focus:border-none mgr-btm-0 no-border px-4 py-3"
          value={value}
          onChange={(e)=>onChange(e,country)}
          onFocus={handleInputFocus}
          ref={inputRef}
          onBlur={handleInputBlur}
          {...restProps}
          data-testid="mobile"
        />
      </div>
      {error && <p className="field-err text-red-500 text-sm mt-1">{error}</p>}
      </div>
    </>
  );
};
 
 
export default TelInput