import React, { useEffect } from 'react';
import useScreenResolution from './useScreenResolution';
import { useNavigate } from 'react-router-dom';

const useOnboarding = () => {
    const { mobile, tablet } = useScreenResolution();
    const navigate = useNavigate();


    useEffect(() => {
        const handleAppInstalled = (evt) => {
            localStorage.removeItem('onboardingCompleted');
        };
        window?.addEventListener('appinstalled', handleAppInstalled);
        return () => {
            window?.removeEventListener('appinstalled', handleAppInstalled);
        };
    }, []);

    useEffect(() => {
        const isAlready = JSON.parse(localStorage.getItem('onboardingCompleted')) || false;

        if ((mobile || tablet) && !isAlready) {
            localStorage.setItem('onboardingCompleted', JSON.stringify(true));
            navigate('/onboarding-flow');
        }
    }, [mobile, tablet, navigate]);

    return null;
};

export default useOnboarding;
