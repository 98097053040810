import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import { forgotPasswordApiEndpoints } from "../../../api";

const initialState = {
  forgotPasswordDetails: {
    cprNumber: "",
    mobile: "",
  },
  data: null,
  isLoading: false,
  error: null,
};

export const forgotPassword = createAsyncThunk(
  "forgotPassword",
  (queryString, { rejectWithValue }) => {
    return Axios.post(`${forgotPasswordApiEndpoints?.forgotpassword}`, {
      CPR: queryString,
    })
      .then((res) => res.data)
      .catch((error) => rejectWithValue(error.response.data));
  }
);

const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    setForgotPasswordDetails: (state, action) => {
      state.forgotPasswordDetails = { ...state.forgotPasswordDetails, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to process forgot password request";
      });
  },
});

export const { setForgotPasswordDetails } = forgotPasswordSlice.actions;

export const selectIsLoading = (state) => state?.forgotPassword?.isLoading;
export const selectForgotPasswordDetails = (state) => state?.forgotPassword?.data;

export default forgotPasswordSlice.reducer;
