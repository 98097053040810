import { useState, useEffect } from "react";
import checkedImage from "./Checkbox.svg";
import uncheckedImage from "./Checkbox (2).svg";

const CustomCheckbox = ({
  Label,
  className = "",
  isChecked,
  setIsChecked = () => {},
  inputClassName = "",
  onChange,
  indeterminate,
  isDisabled,
  onClick = () => {},
}) => {

  const handleCheckboxChange = (event) => {
    setIsChecked(!isChecked);
    onChange?.(event);
  };

  return (
    <div
      className={`${className} relative flex gap-2.5 justify-start items-start mt-2.5 mb-4`}
    >
        <input
          type="checkbox"
          aria-label={Label}
          checked={isChecked}
          onChange={handleCheckboxChange}
          className={`hidden ${inputClassName}`}
          disabled={isDisabled || false}
          onClick={onClick}
          indeterminate={indeterminate}
        />
        <img
          src={isChecked ? checkedImage : uncheckedImage}
          alt={isChecked ? "Selected" : "Not Selected"}
          className="w-[20px] cursor-pointer mt-1 checkboximg"
          onClick={handleCheckboxChange}
        />
      {<div>{Label}</div>}
      </div>

  );
};

export default CustomCheckbox;
