import React, { useState } from "react";
import Header from "../../../../components/common/Header/Header";
import Modal from "../../../../components/common/modal/modal";
import ClaimDetailCard from "./claimDetailCard";
import Button from "../../../../components/common/Button/Button";
import closeicon from "../../../../assets/images/close.svg";
import useScreenResolution from "../../../../hooks/useScreenResolution";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ClaimDetailModal = () => {
  const { mobile } = useScreenResolution();
  const { t } = useTranslation();
  const navigate = useNavigate()

  
  const navigateToChangeGarage = () => {
    navigate("/motor-insurance/change-garage/123");
  };

  return (
    <div className="relative z-20 mx-auto bg-white max767:p-5 w-[500px] max-w-[100%] mt-[5%] ipad:mt-[8%] pt-0">
    {mobile && (
      <button
        className="ml-0 mb-0 rounded flex absolute top-0 left-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:bg-[#F6F8F9] max767:relative max767:mb-[30px] max767:p-2 max767:rounded-3xl"
       
      >
        <img src={closeicon} alt="Close Icon"   onClick={() =>  navigate("/motor-insurance/claim-details/123")}/>
      </button>
    )}
  
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row justify-between py-4 md:space-y-0 space-y-4">
        <div>
          <h1 className="text-[#1A2024] text-xl font-semibold leading-normal max767:text-2xl">
            CRM-CLAIM-01009
          </h1>
          <div className="flex items-center gap-1">
            <p className="text-[#1A2024] text-xs font-normal leading-normal">
              Mon, 01 Jul 2024
            </p>
            <p className="text-[#1A2024] text-xs font-normal leading-normal">
              8:37pm
            </p>
          </div>
        </div>
        <Button
          label={t("motorInsurance.trackClaimFlow.garageSelection.title")}
          className="flex flex-col justify-center items-center gap-2.5 border bg-white px-3 py-1 rounded-md border-solid border-[#DDE2E4]"
          onSubmit={navigateToChangeGarage}
        />
      </div>
      <ClaimDetailCard />
    </div>
  </div>
  
  );
};

export default ClaimDetailModal;
