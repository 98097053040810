import React from 'react';
import FormTabs from '../../../../components/common/tabs/formTabs';

const GeoExtension = ({ option, updateOptionValues }) => {
  return (
    <div className="mb-4">
      <div className="flex space-x-2 mb-2">
      <FormTabs
          tabs={option.options}
          activeTab={option.selectedGeoOption}
          onTabClick={(tabType) =>
            updateOptionValues(option.code, { selectedGeoOption: tabType }, true)
          }
        />
      </div>
    </div>
  );
};
export default GeoExtension;