import React, { useEffect, useState } from 'react'
import useRenewalFlow from '../../../../hooks/useRenewalFlow'
import RenewMotorLayout from '../renewMotorLayout';
import { useTranslation } from 'react-i18next';
import TitleDescription from '../../../../components/newMotorPolicy/titleDescription/titleDescription';
import VerifyOtpComponent from './verifyOtpComponent';
import { useDispatch } from "react-redux";
import { selectNewMotorForm } from "../../../../redux/features/newMotorFormSlice";
import { useSelector } from "react-redux";
import { RENEW_POLICY_CPR_NUMBER } from "../cprNumberPage/cprNumberPage"
import { sendToken, verifyToken } from "../../../../redux/features/account/accountOtpSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { fetchUserPolicies } from '../../../../redux/features/myPolicySlice';
import { toast } from 'react-toastify';

const VerifyOtpPage = () => {

  /* Setting up dispatch to call redux */
  const dispatch = useDispatch();
  
  /* Setting up variables with appropriate requests to send and response to receive, reading from redux/functions */
  const otpInfo = useSelector(selectNewMotorForm)?.[RENEW_POLICY_CPR_NUMBER];
  // var otpPinId = otpInfo.pinId;
  const [otpPinId, setOtpPinId] = useState(otpInfo ? otpInfo.pinId : ""); 

  const [otp, setOtp] = useState();
  const { t } = useTranslation();

  const { handleNextSubstep } = useRenewalFlow();

  // Resending OTP
  const notRecivedCodeHandler = async () => {
    const tokenOtpPayload = {
      CPR: otpInfo?.cprNumber,
      // CPR: "000309265",
    };
    // Calling API /account/sendtoken with CPR
    const tokenOtpAction = await dispatch(sendToken(tokenOtpPayload));
    const tokenOtpResult = unwrapResult(tokenOtpAction);

    if (tokenOtpResult) {
    }
    
    if (tokenOtpResult && tokenOtpResult.smsStatus =="MESSAGE_SENT") {
      toast.success("Message resent");
      
      // Set new pin id
      // otpPinId = tokenOtpResult.pinId;
      setOtpPinId(tokenOtpResult.pinId);

      // Save the masked phone number and pinId in the Redux state
      // saveMotorDataInRedux(updatedFormData)

    }
    else if (tokenOtpResult.requestError.serviceException.messageId =="THROTTLE_EXCEPTION") {
      // alert(tokenOtpResult.requestError.serviceException.text);
      toast.error(tokenOtpResult.requestError.serviceException.text);
      // handleNextSubstep();

    }
  };

  /* Use Effects, monitor changes to call on [var] change or one time call */
  useEffect( () => {
    setOtpPinId(otpInfo ? otpInfo.pinId : "");
  }, [otpInfo]) 

  /* Next */ 
  const continueClickHanlder = async () => {
    // Calling API verify token
    try {
      const tokenOtpPayload = {
        // CPR: formData.cprNumber,
        pinId: otpPinId,
        pin: otp,
      };

      // Calling API /account/sendtoken with CPR
      const verifyOtpAction = await dispatch(verifyToken(tokenOtpPayload));
      const verifyOtpResult = unwrapResult(verifyOtpAction);
      console.log("Verify result: ", verifyOtpResult);
      
      console.log('OTP is: ', otp);
      // If the response is successful, process the phone number
      if (verifyOtpResult && verifyOtpResult.verified ==true) {
        console.log("OTP True");
        
        // Calling API /GetPolicies for renewal
        let payload =
        {
            "Cpr": otpInfo?.cprNumber,
            "Channel": "online.solidarity"
        }
        // Calling API /policy/getPolicies 
        const fetchPolicyAction = await dispatch(fetchUserPolicies(payload));
        const fetchPolicyResult = unwrapResult(fetchPolicyAction);        
        
        if (fetchPolicyResult) {
          // Move to the next step
          console.log('Moving to next step:', fetchPolicyResult);
          handleNextSubstep();
        }
      }
      // handleNextSubstep();

    } catch (error) {
      console.error("Failed to verify token:", error);
    }

    // OTP successfull
    console.log("OTP INFO: ", otpInfo);
    
  }
  return (
    <RenewMotorLayout
      btn_disabled={!(otp?.length ==4)}
      btn_onClick={continueClickHanlder}
    >
      <div className='reniewlotp p-5'>
        <TitleDescription
          title={t("motorInsurance.renewalPolicyFlow.verifyOtpPage.title")}
          description={t(
            "motorInsurance.renewalPolicyFlow.verifyOtpPage.description"
          )}
        />
        <VerifyOtpComponent
          notRecivedCodeHandler={notRecivedCodeHandler}
          otp={otp}
          setOtp={setOtp}
          mobile= {otpInfo ? otpInfo.phone : ""}
          navigationPath={"/sign-up"}
        />

      </div>
    </RenewMotorLayout>
  );
}

export default VerifyOtpPage