export const POLICY_CANCELLATION_FLOW = "POLICY_CANCELLATION_FLOW";
export const POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_REVIEW =
  "POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_REVIEW";
export const POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_REASON =
  "POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_REASON";
export const POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_CONFIRMATION =
  "POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_CONFIRMATION";

export const policyCancellationTableData = {
  owner: "SAYED JAWAD SADIQ JAWAD SADEQ",
  policyNumber: "P/003/14/20/8003/0004341-05",
  startDate: "01/02/2024",
  endDate: "31/01/2025",
  plateNumber: "516914",
  chassisNumber: "ERTY7896541235555",
  make: "Dodge",
  carModel: "Durango",
  manufacturingYear: "2024",
  bodyType: "SUV",
  engineCapacity: "1200 CC",
  seatingCapacity: "7 Seater",
  vehicleColour: "Black",
  premiumPaid: "BHD 147.5",
};

export const cancellationReasons = [
  {
    label: "Financial Reasons",
    description: "I can no longer afford the premiums",
    label_ar: "الأسباب المالية",
    description_ar: "لم أعد قادرًا على تحمل الأقساط",
  },
  {
    label: "Dissatisfaction with Service",
    description: "I am not satisfied with the customer service",
    label_ar: "الخدمة غير مرضية",
    description_ar: "لست راضيًا عن خدمة العملاء",
  },
  {
    label: "Found a Better Offer",
    description: "I found a better offer with another company",
    label_ar: "وجدت عرضًا أفضل",
    description_ar: "وجدت عرضًا أفضل مع شركة أخرى",
  },
  {
    label: "Change in Vehicle Ownership",
    description: "I sold or transferred ownership of the vehicle",
    label_ar: "تغيير ملكية المركبة",
    description_ar: "بعت أو نقلت ملكية المركبة",
  },
  {
    label: "No Longer Needed",
    description: "I no longer need insurance",
    label_ar: "لم يعد هناك حاجة",
    description_ar: "لم أعد بحاجة إلى التأمين",
  },
  {
    label: "Coverage Concerns",
    description: "I am not satisfied with the coverage provided",
    label_ar: "التغطية غير واضحة",
    description_ar: "لست راضيًا عن التغطية المقدمة",
  },
  {
    label: "Others",
    description: "Other reasons (please specify)",
    label_ar: "أسباب أخرى",
    description_ar: "أسباب أخرى (يرجى التحديد)",
  },
];


export const termsAndConditions = [
  "You must be at least 18 years old to use this service.",
  "The service is provided on an 'as is' basis.",
  "We are not responsible for any data loss.",
  "By using this service, you agree to these terms.",
  "The terms may be updated at any time.",
];