import { createSlice } from "@reduxjs/toolkit";
import { ADD_MORE_COVERS_FLOW } from "../../pages/motorInsurancePages/addMOreCoversFlow/data";

const initialState = {
  flowType: ADD_MORE_COVERS_FLOW,
  currentStepIndex: 0,
  currentSubstepIndex: 0,
};

const motorInsuranceAddMoreCoversFlowSlice = createSlice({
  name: "motorInsuranceAddMoreCoversFlow",
  initialState,
  reducers: {
    setAddMoreCoversFlowType: (state, action) => {
      const data = action.payload;
      state.flowType = data.type;
      state.currentStepIndex = data.currentStepIndex || 0;
      state.currentSubstepIndex = data.currentSubstepIndex || 0;
    },
    setCurrentStepIndex: (state, action) => {
      state.currentStepIndex = action.payload;
      state.currentSubstepIndex = 0;
    },
    setCurrentSubstepIndex: (state, action) => {
      state.currentSubstepIndex = action.payload;
    },
  },
});

export const selectMotorInsuranceAddMoreCoversFlow = (store) =>
  store.motorInsuranceAddMoreCoversFlow;
export const selectAddMoreCoversFlowType = (store) =>
  store?.motorInsuranceAddMoreCoversFlow?.flowType;
export const selectCurrentStepIndex = (store) =>
  store?.motorInsuranceAddMoreCoversFlow?.currentStepIndex;
export const selectCurrentSubstepIndex = (store) =>
  store.motorInsuranceAddMoreCoversFlow?.currentSubstepIndex;

export const {
  setAddMoreCoversFlowType,
  setCurrentStepIndex,
  setCurrentSubstepIndex,
} = motorInsuranceAddMoreCoversFlowSlice.actions;
export default motorInsuranceAddMoreCoversFlowSlice.reducer;
