import React, { useState } from "react";
import ExpressShieldPlanLayout from "../expressShieldPlanLayout";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import FormTabs from "../../../../components/common/tabs/formTabs";
import { useTranslation } from "react-i18next";
import useValidation from "../../../../hooks/useValidation";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import Button from "../../../../components/common/Button/Button";
import AddNewBeneficiaryPopup from "../../../lifeInsurancePages/PolicyOverview/manageMyPolicy/manageBeneficiaries/addNewBenfeficiaryPopup";
import { toast } from "react-toastify";
import TrusteeDetailPopup from "../../../lifeInsurancePages/PolicyOverview/manageMyPolicy/manageBeneficiaries/trusteeDetailPopup";
import DynamicTable from "../../../../components/common/table/table";
import useExpressShieldParty from "../../../../hooks/BuyOnlineExpressShield/useExpressShieldParty";
import useLifeFormData from "../../../../hooks/useLifeFormData";
import useExpressSheildSave from "../../../../hooks/BuyOnlineExpressShield/useExpressSheildSave";
import LifeDynamicTable from "../../../../components/common/table/lifeTable";

export const yesNoTabs = [
  { label_en: "Yes", label_ar: "نعم", type: "Yes" },
  { label_en: "No", label_ar: "لا", type: "No" },
];

export const EXPRESS_SHEILD_BENEFICIARY_DETAILS =
  "EXPRESS_SHEILD_BENEFICIARY_DETAILS";
export const EXPRESS_SHEILD_BENEFICIARY_DETAILS_VALUES =
  "EXPRESS_SHEILD_BENEFICIARY_DETAILS_VALUES";
const BeneficiaryDetails = ({ onSuccess }) => {
  const [beneficairyDetailsInfo, setBeneficiaryDetailsInfo] = useState([]);
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const [values, setValues] = useState({});
  const { errors, validateFields } = useValidation();
  const [showAddNewBenefi, setShowAddNewBenefi] = useState(false);
  const [showTrustieDetails, setShowTrustieDetails] = useState(false);

  const { saveLifeDataInRedux } = useLifeFormData(
    EXPRESS_SHEILD_BENEFICIARY_DETAILS,
    setBeneficiaryDetailsInfo
  );

  const { saveLifeDataInRedux: saveValuesInRedux } = useLifeFormData(
    EXPRESS_SHEILD_BENEFICIARY_DETAILS_VALUES,
    setValues
  );

  const { expressSheildSaveHandler, isLoading } = useExpressSheildSave();

  const toggleTrustieDetails = () => {
    setShowTrustieDetails((state) => !state);
  };

  const {
    checkPartyLoading,
    createPartyLoading,
    checkPartyExistApi,
    createPartyApi,
  } = useExpressShieldParty();

  const beneficiaryTableFormatter = (formValues, response) => {
    return {
      original_data: { ...formValues, response },
      data: [
        { id: 0, column1: "", column2: "" },
        {
          id: 1,
          column1: t("buyOnline.expressShieldFlow.beneficiaryInfo.table.name"),
          column2: formValues?.fullName,
        },
        {
          id: 2,
          column1: t(
            "buyOnline.expressShieldFlow.beneficiaryInfo.table.sharePercentage"
          ),
          column2: formValues?.share_percentage,
        },
        {
          id: 3,
          column1: t("buyOnline.expressShieldFlow.beneficiaryInfo.table.cpr"),
          column2: formValues?.cpr,
        },
        {
          id: 3,
          column1: t(
            "buyOnline.expressShieldFlow.beneficiaryInfo.table.relationship"
          ),
          column2: formValues?.relationship,
        },
        {
          id: 3,
          column1: t(
            "buyOnline.expressShieldFlow.beneficiaryInfo.table.mobileNumber"
          ),
          column2: formValues?.mobile,
        },
      ],
    };
  };

  const AddBenficiary = async (values) => {
    let payload = {
      CPR: values?.cpr,
    };
    let response = await checkPartyExistApi(payload);

    if (!response?.data?.PartyExists) {
      const [firstName, ...lastNameParts] = values.fullName.split(" ");
      const lastName = lastNameParts.join(" ");

      const dateOfBirth =
        new Date(`${values.year}-${values.month}-${values.day}`).getTime() /
        1000;

      let createPartyPayload = {
        FirstName: firstName,
        LastName: lastName,
        CPR: values.cpr,
        PhoneNumber: values?.mobile,
        Email: values?.email,
        AddressLine1: ".",
        AddressLine2: ".",
        Gender: values?.gender,
        DateOfBirth: dateOfBirth.toString(),
        Channel: "online.solidarity",
      };
      let response = await createPartyApi(createPartyPayload);
      if (response?.data?.Success) {
        toast.success(
          t("buyOnline.expressShieldFlow.beneficiaryInfo.beneficiarySuccess")
        );
        let format = beneficiaryTableFormatter(values, response?.data);
        let update = [...beneficairyDetailsInfo, format];
        setBeneficiaryDetailsInfo(update);
        saveLifeDataInRedux(update);
        setShowAddNewBenefi(false);
      } else {
        toast.error(
          t("buyOnline.expressShieldFlow.beneficiaryInfo.beneficiaryFailed")
        );
      }
    } else {
      let format = beneficiaryTableFormatter(values, response?.data);
      let update = [...beneficairyDetailsInfo, format];
      setBeneficiaryDetailsInfo(update);
      saveLifeDataInRedux(update);
      setShowAddNewBenefi(false);
    }
  };
  const toggleAddNewBeneficiary = () => {
    setShowAddNewBenefi((state) => !state);
  };
  const validateForm = (data) => {
    let isValid = validateFields(
      [
        {
          name: "is_appoint_benficiary",
          validations: ["required"],
        },
      ],
      data ? data : values
    );
    return isValid;
  };

  const onTabClick = (value, name) => {
    console.log(value, "somevalue");
    console.log(name, "somevalue");
    let update = { ...values, [name]: value };
    if (name == "is_appoint_benficiary" && value == "No") {
      setBeneficiaryDetailsInfo([]);
      saveLifeDataInRedux([]);
    }
    setValues(update);
    if (errors?.[name]) {
      validateForm(update);
    }
  };
  const nextClickHandler = async () => {
    if (validateForm()) {
      saveValuesInRedux(values);
      let percentage = 0;
      beneficairyDetailsInfo?.forEach((each) => {
        let per = each.original_data?.share_percentage;
        percentage = percentage + parseInt(per || 0);
      });
      if (values.is_appoint_benficiary == "Yes") {
        if (!beneficairyDetailsInfo?.length) {
          toast.error(
            translate(
              "Please add atleast one Beneficiary",
              "يرجى إضافة مستفيد واحد على الأقل"
            )
          );
          return;
        } else if (percentage != 100) {
          toast.error(
            translate(
              `Accumulate Percentage should be 100 currently its ${percentage}`,
              `يجب أن تكون النسبة المئوية الإجمالية 100٪ حاليًا هي ${percentage}`
            )
          );
          return;
        }
      }

      let response = await expressSheildSaveHandler();
      if (response?.type == "expressSheildSave/fulfilled") {
        onSuccess();
      } else {
        toast.error(
          t("buyOnline.expressShieldFlow.beneficiaryInfo.somethingWentWrong")
        );
      }
    }
  };
  const removeBeneficiary = (data, index) => {
    let update = beneficairyDetailsInfo?.filter((_, i) => i != index);
    setBeneficiaryDetailsInfo(update);
    saveLifeDataInRedux(update);
  };
  return (
    <ExpressShieldPlanLayout
      is_bottom_sticky_layout
      btn_onClick={nextClickHandler}
      isLoading={isLoading}
      show_summary
    >
      <div className="w-[500px] max-w-[100%] mx-auto">
        <TitleDescription
          title={t("buyOnline.expressShieldFlow.beneficiaryInfo.title")}
          description={t(
            "buyOnline.expressShieldFlow.beneficiaryInfo.description"
          )}
        />
        <div>
          <FormTabs
            label={t(
              "buyOnline.expressShieldFlow.beneficiaryInfo.appointBeneficiary"
            )}
            tabs={yesNoTabs}
            onTabClick={(type) => onTabClick(type, "is_appoint_benficiary")}
            activeTab={values.is_appoint_benficiary}
            error={errors?.is_appoint_benficiary}
          />
          {values.is_appoint_benficiary == "Yes" && (
            <div className="mb-4">
              <Button
                className="flex flex-col items-center gap-2.5 sdsds flex-[1_0_0] border bg-white p-4 rounded-md border-solid border-[#DDE2E4] w-full text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px] hover:bg-[#fff]"
                label={t(
                  "buyOnline.expressShieldFlow.beneficiaryInfo.addBeneficiary"
                )}
                onSubmit={toggleAddNewBeneficiary}
              />
            </div>
          )}
        </div>
        <AddNewBeneficiaryPopup
          show={showAddNewBenefi}
          setShow={setShowAddNewBenefi}
          toggleTrustieDetails={() => {
            toast.error(t("buyOnline.expressShieldFlow.beneficiaryInfo.age21"));
            // toggleAddNewBeneficiary()
          }}
          AddBenficiary={AddBenficiary}
          isLoading={checkPartyLoading || createPartyLoading}
        />
        <TrusteeDetailPopup
          show={showTrustieDetails}
          setShow={setShowTrustieDetails}
          AddBenficiary={AddBenficiary}
          toggleAddNewBeneficiary={toggleAddNewBeneficiary}
        />
        {beneficairyDetailsInfo.length > 0 && (
          <div className="beneficiaryDetails bg-[#F6F8F9] rounded-[26px] p-6">
            <LifeDynamicTable
              data={beneficairyDetailsInfo?.map((each, i) => {
                let update = JSON.parse(JSON.stringify(each));
                if (i == 0) {
                  update = {
                    ...update,
                    tableTitle: "Beneficiary Details",
                    tableTitle_ar: "تفاصيل المستفيد",
                  };
                }
                update.data[0].column2 = (
                  <Button
                    label={t(
                      "buyOnline.expressShieldFlow.beneficiaryInfo.removeBeneficiary"
                    )}
                    onSubmit={() => removeBeneficiary(each, i)}
                    className="flex flex-col items-center gap-2.5 border bg-white px-4 py-2 rounded-md text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
                  />
                );
                return update;
              })}
            />
          </div>
        )}
      </div>
    </ExpressShieldPlanLayout>
  );
};
export default BeneficiaryDetails;
