import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import { crmApiEndPoints } from "../../../api";

const initialState = {
    claims: [], // Store claims directly
    claimDetails: null, // Store individual claim details
    isLoading: false,
    error: null,
    status: "idle", // Tracks status: idle | loading | succeeded | failed
};

export const getCrmClaims = createAsyncThunk(
    "crmClaim/getCrmClaims",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`${crmApiEndPoints.crmClaims}`, payload, {
                timeout: 30000, // Set timeout to 30 seconds
            });
            return response.data.Claims || []; // Return an empty array if no claims
        } catch (err) {
            console.error("Error fetching CRM claims:", err.response?.data || err.message);
            return rejectWithValue(err.response?.data || "Failed to fetch claims");
        }
    }
);

export const getClaimDetails = createAsyncThunk(
    "crmClaim/getClaimDetails",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await Axios.post(`${crmApiEndPoints.crmClaimDetails}`, payload, {
                timeout: 30000, // Set timeout to 30 seconds
            });
            return response.data || []; // Return an empty array if no claims
        } catch (err) {
            console.error("Error fetching CRM Details:", err.response?.data || err.message);
            return rejectWithValue(err.response?.data || "Failed to fetch claim details");
        }
    }
);

const crmClaimSlice = createSlice({
    name: "crmClaim",
    initialState,
    reducers: {
        clearClaimListError: (state) => {
            state.error = null;
        },
        clearClaimDetailsError: (state) => {
            state.selectCrmClaimsError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // CRM Claims
            .addCase(getCrmClaims.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.status = "loading";
            })
            .addCase(getCrmClaims.fulfilled, (state, action) => {
                state.isLoading = false;
                state.claims = action.payload;
                state.status = "succeeded";
            })
            .addCase(getCrmClaims.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.status = "failed";
            })
            // Claim Details
            .addCase(getClaimDetails.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.status = "loading";
            })
            .addCase(getClaimDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.claimDetails = action.payload;
                state.status = "succeeded";
            })
            .addCase(getClaimDetails.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
                state.status = "failed";
            });
    },
});

// SELECTORS
export const selectCrmClaims = (state) => state.crmClaim.claims;
export const selectClaimDetails = (state) => state.crmClaim.claimDetails;
export const selectCrmClaimsLoading = (state) => state.crmClaim.isLoading;
export const selectCrmClaimsError = (state) => state.crmClaim.error;

export const { clearClaimListError, clearClaimDetailsError } = crmClaimSlice.actions;

export default crmClaimSlice.reducer;
