import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  getLoggedInUserDataFromLocalStorage } from "../config/config";
import { useLocation, useNavigate } from "react-router-dom";
import { selectLoginTokenInfo, setLoginTokenInfo } from "../redux/features/loginRegisterSlice";


const UserRefreshLogin = () => {
    const loginTokenInfo = useSelector(selectLoginTokenInfo);
    let isLoggedin = loginTokenInfo;
    const dispatch = useDispatch();

    const loginUserDetails = getLoggedInUserDataFromLocalStorage()
    if (loginUserDetails) {
        if (!isLoggedin) {
            isLoggedin = true;
            dispatch(setLoginTokenInfo(loginUserDetails));
        }
    }

    const { pathname } = useLocation();
    const navigate = useNavigate()
    useEffect(() => {
        if (
            (isLoggedin && pathname?.includes("sign-in")) ||
            (isLoggedin && pathname?.includes("sign-up"))
        ) {
           // navigate('/')
        }
    }, [isLoggedin]);
    useEffect(() => {
        if (
            (isLoggedin && pathname?.includes("sign-in")) ||
            (isLoggedin && pathname?.includes("sign-up"))
        ) {
         navigate('/')
        }
    }, [pathname]);
    

    return isLoggedin;
};

export default UserRefreshLogin;
