import React, { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { decodeQueryString } from "../../../utils";
import useLifePolicyDetails from "../../../hooks/life/useLifePolicyDetails";
import { useSearchParams } from "react-router-dom";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import useScreenResolution from "../../../hooks/useScreenResolution";
import { BREADCRUMP_ITEMS } from "../../lifeInsurancePages/PolicyOverview/data";
import PolicyHeroCard from "../../lifeInsurancePages/PolicyOverview/PolicyHeroCard";
import Tabs from "../../../components/common/tabs/tabs";
import MobileFooter from "../../../components/common/sideNavbar/mobileFooter";
import Loader from "../../../components/common/Loader/Loader";
import { coverageAndBenefits, tabs } from "./data";
import Breadcrumb from "../../lifeInsurancePages/PolicyOverview/Breadcrumb";
import PolicyOverviewTab from "./policyOverviewTab";

const PolicyOverviewMainTab = () => {
  const decodedQueryString = decodeQueryString();
  const { data, isLoading } = useLifePolicyDetails(decodedQueryString?.policy);
  const [searchParams] = useSearchParams();
  const policyStatus = searchParams.get("status");
  const translate = useLanguageTranslate();

  const [breadCrumbData, setBreadCrumbData] = useState(BREADCRUMP_ITEMS);
  const [activeTab, setActiveTab] = useState("PolicyOverview");
  const [downloadMenuClicked, setDownloadMenuClicked] = useState(false);
  const screenResolution = useScreenResolution();
  const { i18n } = useTranslation();
  const toggleDownloadMenuClicked = () => {
    setDownloadMenuClicked(!downloadMenuClicked);
  };
  const renderActiveTabContent = () => {
    switch (activeTab) {
      case "PolicyOverview":
        return (
          <PolicyOverviewTab
            data={coverageAndBenefits}
            handleDownloadMenuClicked={toggleDownloadMenuClicked}
            showDownloadPopup={downloadMenuClicked}
          />
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    setBreadCrumbData([
      {
        label: translate("My Policie", "وثائقي"),
        link: "/my-policy",
        clickable: true,
        name: "my_policy",
      },
      {
        label: translate("Essential Care Policy","وثيقة الرعاية الأساسية"),
        clickable: false,
        name: "current_plan",
        isActive: true,
      },
    ]);
  }, [i18n?.resolvedLanguage]);

  const params = decodeQueryString();

  useEffect(() => {
    if (params.tabType) {
      setActiveTab(params.tabType);
    }
  }, [params?.tabType]);

  return (
    <div className="p-6 max767:p-0 max767:m-0">
      {isLoading && <Loader />}
      <div className="flex justify-between items-center">
        {!screenResolution.mobile && <Breadcrumb items={breadCrumbData} />}
        {/* {!screenResolution.mobile && (
          <span className="hidden last-login text-xs not-italic font-normal leading-4 text-gray-700">
            Last Login: 03 November 2024 at 05:20{" "}
          </span>
        )} */}
      </div>

      <PolicyHeroCard
        info={decodedQueryString}
        content={data}
        handleDownloadMenuClicked={toggleDownloadMenuClicked}
      />
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} />
      {renderActiveTabContent()}
      {screenResolution.mobile && <MobileFooter />}
    </div>
  );
};

export default PolicyOverviewMainTab;
