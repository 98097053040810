import React, { useEffect, useState } from "react";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import useValidation from "../../../hooks/useValidation";
import { useTranslation } from "react-i18next";
import RenewMotorLayout from "../renewalMotorPolicy/renewMotorLayout";
import Input from "../../../components/common/Input/Input";
import { Loader } from "storybook/internal/components";

const RemoveBankPage = ({ switchToggle, status, lender }) => {
  const [formData, setFormData] = useState({ currentBank : lender});
  const { errors } = useValidation();
  const { t } = useTranslation();
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const continueClickHanlder = () => {
    switchToggle();
  };
  useEffect(() => {
    setFormData({ currentBank: lender });
  }, [lender]);
  return (
     <RenewMotorLayout is_hide_back_btn btn_onClick={continueClickHanlder}>
      <div className="max-w-[100%] w-[500px] mx-auto bg-white max767:p-5">
        <TitleDescription
          title={t("motorInsurance.removeBankNameFlow.removeBankPage.title")}
          description={t("motorInsurance.removeBankNameFlow.removeBankPage.description")}
        />
        {status =='loading' ? ( // Show loading indicator if status is 'loading'
          <Loader/>
        ) : (
          <Input
            disabled
            name="currentBank"
            type="text"
            label={t("motorInsurance.removeBankNameFlow.removeBankPage.input_label")}
            value={formData?.currentBank}
            error={errors?.currentBank}
            handleChange={handleChange}
          />
        )}
      </div>
    </RenewMotorLayout>
  );
};

export default RemoveBankPage;
