import React from "react";
import PolicyCard from "../../components/policyCard/policyCard";
import { transferPolicyData } from "./data";
import Button from "../../components/common/Button/Button";
import { useNavigate } from "react-router-dom";
import faPlusCircleimg from "../../../src/assets/images/newpolicyicon.svg";
import faSyncAltimg from "../../../src/assets/images/Renewal.svg";
import faFileAltimg from "../../../src/assets/images/FileaClaim.svg";
import EmergencyServicesimg from "../../../src/assets/images/EmergencyServices.svg";

import faPlusCircleimgdark from "../../../src/assets/images/newpolicydark.svg";
import faSyncAltimgdark from "../../../src/assets/images/FileaClaimdark.svg";
import faFileAltimgdark from "../../../src/assets/images/Renewaldark.svg";
import EmergencyServicesimgdardk from "../../../src/assets/images/EmergencyServicesdark.svg";

import ServiceGrid from "../../components/common/serviceCards/serviceCards";

import faPlusCircleimgqa from "../../../src/assets/images/Roaddark.svg";
import faSyncAltimgdarkqa from "../../../src/assets/images/home-black.svg";
import faFileAltimgdarkqa from "../../../src/assets/images/Traveldark.svg"
import EmergencyServicesimgqa from "../../../src/assets/images/Alldark.svg"

import { useTranslation } from "react-i18next";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
const services = [
  {
    icon: faPlusCircleimg,
    micon: faPlusCircleimgdark,
    text: "New Policy",
    text_ar: " خطة جديدة",
    type: "new_policy",
  },
  { icon: faSyncAltimg, micon: faSyncAltimgdark, text: "Renewal", text_ar: "تجديد", type: "Renewal" },
  {
    icon: faFileAltimg,
    micon: faFileAltimgdark,
    text: "File a Claim",
    text_ar: "تقديم مطالبة",
    type: "Claim",
  },
  {
    icon: EmergencyServicesimg,
    micon: EmergencyServicesimgdardk,
    text: "Emergency Services",
    text_ar: "خدمات الطوارئ",
    type: "Emergency",
  },
];
const assistanceServices = [
  {
    icon: faPlusCircleimg,
    micon: faPlusCircleimgqa,
    text: "Road",
    text_ar: "سياسة جديدة",
    type: "road",
  },
  { icon: faSyncAltimg, micon: faSyncAltimgdarkqa, text: "Home", text_ar: "تجديد", type: "home" },
  {
    icon: faFileAltimg,
    micon: faFileAltimgdarkqa,
    text: "Travel",
    text_ar: "تقديم مطالبة",
    type: "travel",
  },
  {
    icon: EmergencyServicesimg,
    micon: EmergencyServicesimgqa,
    text: "All",
    text_ar: "خدمات الطوارئ",
    type: "all",
  },
];
const MobilePolicyTransferRequest = ({ }) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const translate = useLanguageTranslate();
  const onSubmit = () => {
    navigate("/motor-insurance/confirm-transfer-details");
  };
  const serviceClickHandler = (service) => {
    if (service =="new_policy") {
      navigate("/new-policy");
    } else if (service =="Renewal") {
      navigate("/motor-insurance/renew-motor-policy");
    } else if (service =="Claim") {
      navigate("/file-a-claim");
    }
  };
  const assistanceServiceClickHandler = (service) => {
    if (service =="road") {
      navigate("/");
    } else if (service =="home") {
      navigate("/");
    } else if (service =="travel") {
      navigate("/");
    } else if (service =="all") {
      navigate("/");
    }
  };
  return (
    <div className="Policy-Transfer-Request">
      <div className="p-4 mt-2 flex-col bg-[#FDE9D2] border-y-[#FBC98E] border-t border-solid border-b">
      <div className="content mb-4">
        <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mb-2 max767:text-xl">
          Policy Transfer Request
        </h1>
        <p className="text-[#48535B] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] text-left">
          <span className="font-semibold">Sayed Jawad Sadeq </span> with mobile
          number <span className="font-semibold">+973 37796977</span> wants to
          transfer this policy to you.
        </p>
      </div>
      <div className="flex justify-center mb-4">
        <PolicyCard
          policy_name={transferPolicyData.policy_name}
          policy_label={transferPolicyData.policy_label}
          policy_number={transferPolicyData.policy_number}
          from_date={transferPolicyData.from_date}
          to_date={transferPolicyData.to_date}
          policy_bg_image={transferPolicyData.policy_bg_image}
          expire_days={transferPolicyData.expire_days}
          is_text_dark={transferPolicyData.is_text_dark}
          mapping_key={transferPolicyData.id}
          data={transferPolicyData}
          disableCardClick
        />
      </div>
      <div className="flex justify-center">
        <Button
          onSubmit={onSubmit}
          type="submit"
          label={"Start Transfer"}
          className="mb-0 stickybutton flex w-full flex-col items-center gap-2.5 shrink-0 p-3 bg-[#55C6EF] rounded-[10px] text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px] max767:w-full"
        />
      </div>
      </div>

      <div className="flex flex-col lifeinsurencepolicycard">
        <h1 className="text-black text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] pl-6 mt-4">
          {t("dashboard.quick_actions_label")}
        </h1>
        <ServiceGrid
          services={services}
          serviceClickHandler={serviceClickHandler}
        />
      </div>

      <div className="flex flex-col Assistance">
        <h1 className="text-black text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] pl-6 mt-4">
          {translate("Assistance Services","خدمات المساعدة")}
        </h1>
        <ServiceGrid
          services={assistanceServices}
          serviceClickHandler={assistanceServiceClickHandler}
        />
      </div>
    </div>
  );
};

export default MobilePolicyTransferRequest;
