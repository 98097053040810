import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../axiosInstance";
import { apiEndpoints } from "../../api";

const initialState = {
    data: null,
    isLoading: false,
    error: null,
};

export const fetchNotifications = createAsyncThunk(
    "fetchNotifications",
    (queryString, { rejectWithValue }) => {
        return Axios.get(`${apiEndpoints.notifications}`).then((res) => res.data);
    }
);

export const notificationsSlice = createSlice({
    name: "notificationsSlice",
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        // FETCH  UTILS API
        builder.addCase(fetchNotifications.pending, (state, action) => {
            if (!state.data) {
                state.isLoading = true;
            }
        });
        builder.addCase(fetchNotifications.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchNotifications.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error?.message;
        });
    },
});

// SELECTORS
export const selectNotificationsDatatWhole = (store) => store?.notifications;
export const selectNotificationsData = (store) => store?.notifications?.data;
export const selectUnreadNotifications = (store) => {
   // return store?.notifications?.data?.Notifications?.filter((notification) =>!notification.read);
   return store?.notifications?.data?.Notifications
}
export const selectNotificationsDataLoader = (store) => store?.notifications?.isLoading;

// ACTIONS TO DISPATCH
export const { clearNotificationsError } = notificationsSlice.actions;

export default notificationsSlice.reducer;
