import React, { useState } from "react";
import { moreMenuData } from "./sidebarLinks";
import { Link, useNavigate } from "react-router-dom";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import righticon from "../../../assets/images/chevron_right.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectProfileData } from "../../../redux/features/profileSlice";
import Button from "../Button/Button";
import {
  clearLoginTokenInfo,
  selectLoginTokenInfo,
} from "../../../redux/features/loginRegisterSlice";
import { clearLoggedInUserDataInLocalStorage } from "../../../config/config";
import { resetRedux } from "../../../redux/features/resetSlice";
import posthog from "posthog-js";
import { useTranslation } from "react-i18next";
import { setLanguageInLocalStorage } from "../../../hooks/useLanguagePercistant";
import VideoCallModal from "./videoCallModal";

const MorePage = () => {
  const translate = useLanguageTranslate();
  const profileData = useSelector(selectProfileData);
  const loginInfo = useSelector(selectLoginTokenInfo);
  const { i18n } = useTranslation();
  const [showVideoPopup, setShowVideoPopup] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutHandler = () => {
    dispatch(clearLoginTokenInfo());
    clearLoggedInUserDataInLocalStorage();
    dispatch(resetRedux());
    posthog.reset(true);
    posthog.reloadFeatureFlags();
    navigate("/");
  };
  const handleLanguageChange = () => {
    let switch_lang = i18n.resolvedLanguage == "ar" ? "en" : "ar";
    i18n.changeLanguage(switch_lang);
    setLanguageInLocalStorage(switch_lang);
  };
  const onItemClick = (link, event) => {
    if (link.type == "logout") {
      logoutHandler();
      return;
    }
    if (link.type == "handleLangChng") {
      handleLanguageChange();
      return;
    }

    if (link.type == "signin" && loginInfo) {
      navigate("/profile-details");
      return;
    }
    if (link.type == "VideoCall") {
      setShowVideoPopup(true);
    }
    if (link.link) {
      if (link?.is_internal == false) {
        event.preventDefault();
        window.open(link.link, "_blank");
      } else {
        navigate(link.link);
      }
    }
  };

  return (
    <div>
      <div className="overflow-x-hidden flex-grow overflow-y-scroll h-screen pb-8">
        <ul className="py-4 space-y-1 w-full inline-flex flex-col items-start gap-12 mb-6">
          {moreMenuData.map((section, index) => (
            <div
              key={index}
              className="grid flex-col items-start gap-4 w-full max767:mt-20"
            >
              <li className="px-5">
                <div className="flex flex-row items-center mb-0">
                  <div className="menu-subtitle text-black text-xl not-italic font-semibold leading-[120%]">
                    {translate(section.title_en, section.title_ar)}
                  </div>
                </div>
              </li>
              {section.links.map((link, linkIndex) => (
                <li
                  key={linkIndex}
                  className={`flex justify-between align-middle items-center w-full ${
                    link.showIfIsLoggedIn && !loginInfo ? "hidden" : ""
                  } ${link.hide ? "hidden" : ""}`}
                  onClick={(event) => onItemClick(link, event)}
                >
                  <Link
                    to={link.link}
                    state={{ navigatedFromLink: link.text }}
                    className={`relative flex flex-row items-center h-14 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent pr-5 w-[97%] rtl:justify-between`}
                  >
                    <div className="flex items-center">
                      <span className="inline-flex justify-center items-center ml-4 mr-4 rtl:mr-0">
                        <img
                          className="flex items-start bg-[#E5E9EB] p-3 rounded-3xl"
                          src={link.icon}
                          alt={translate(link.text_en, link.text_ar)}
                        />
                      </span>
                      {
                        <span className="ml-2 text-black text-base not-italic font-medium leading-6 tracking-[-0.16px] truncate">
                          {loginInfo && link.type == "signin"
                            ? profileData?.FirstName
                            : translate(link.text_en, link.text_ar)}
                        </span>
                      }
                    </div>
                    <img
                      src={righticon}
                      alt=">"
                      className="ml-auto rtl:rotate-180 rtl:ml-[unset]"
                    />
                  </Link>
                </li>
              ))}
            </div>
          ))}
        </ul>
        {loginInfo && (
          <Button
            label={translate("Delete Account", "حذف الحساب")}
            className="text-[#4D0004] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] flex flex-col items-center gap-2.5 self-stretch w-[92%] mx-auto my-0 bg-[#FA8D7F] px-4 py-2 rounded-[10px]"
            onSubmit={() =>
              alert("Are you sure you want to delete your account?")
            }
          />
        )}
        {
          <VideoCallModal
            show={showVideoPopup}
            onClose={() => setShowVideoPopup(false)}
          />
        }
      </div>
    </div>
  );
};

export default MorePage;
