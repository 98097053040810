import React from 'react';

const InputWithButton = ({
    onButtonClick,
    className = '',
    inputValue = '',
    isDisabled = true,
    buttonText,
    label,
    isTP
}) => {
    return (
      <div className="adjustinputbutton">
        {label && (
          <label className="text-[#48535B] text-base not-italic font-normal leading-[26px] tracking-[-0.16px]">
            {label}
          </label>
        )}
        <div
          className={`flex mb-6 items-center justify-between h-[50px]  gap-2.5 self-stretch border bg-[#E5E9EB] px-4 py-[5px] rounded-[10px] border-solid border-[#B0BABF] ${className}`}
        >
          <input
            type="text"
            value={inputValue}
            disabled={isDisabled}
            className="text-[#6E7C87] text-lg not-italic font-medium leading-[26px] tracking-[-0.18px] max767:w-[40%]"
          />

          {!isTP && (
            <button
              type="button"
              onClick={onButtonClick}
              className="flex flex-col items-center gap-2.5 border bg-white text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] px-3 py-1 rounded-md border-solid border-[#DDE2E4]"
            >
              {buttonText}
            </button>
          )}
        </div>
      </div>
    );
};

export default InputWithButton;
