import React, { useState } from "react";
import Input from "../../../components/common/Input/Input";
import Button from "../../../components/common/Button/Button";
import "./CreatePasswordPage.css";
import ValidationItem from "../../../components/common/ValidationItem/ValidationItem";
import { useTranslation } from "react-i18next";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import useScreenResolution from "../../../hooks/useScreenResolution";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import BackBtnWithLabel from "../../../components/common/backBtnWithLabel/backBtnWithLabel";
import { useDispatch, useSelector } from "react-redux";
import {
  createCustomerAccount,
  selectIsLoading,
} from "../../../redux/features/signUp/createCustomerAccountSlice";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  resetPassword,
  selectResetIsLoading,
} from "../../../redux/features/forgotPasswordFlow/resetPasswordSlice";
import {
  getOtpData,
  selectVerifyOtpData,
} from "../../../redux/features/verifyOtpSlice";
import Loader from "../../../components/common/Loader/Loader";
import IAgreePopup from "../../../components/common/IAgreePopup";

const CreatePasswordPage = ({ onSuccess, pageTitle }) => {
  const { mobile } = useScreenResolution();
  const translate = useLanguageTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { signupDetails } = useSelector((state) => state.signup);
  const location = useLocation();
  //Forgot Password Detais
  const forgotPasswordDetails = useSelector(
    (state) => state.forgotPassword.forgotPasswordDetails
  );
  const forgotPasswordData = useSelector((state) => state.forgotPassword.data);

  const otp = useSelector(getOtpData);

  const isResetPasswordLoading = useSelector(selectResetIsLoading);
  const isCreateCustomerAccountLoading = useSelector(selectIsLoading);

  const isLoading = isResetPasswordLoading || isCreateCustomerAccountLoading;
  const isForgotPassword = location.pathname.includes("forgot-password");

  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errors, setErrors] = useState({});
  const [validationData, setValidationData] = useState([
    {
      is_valid: false,
      msg: { en: "At least 8 characters", ar: "على الأقل 8 حرفًا" }},
    { is_valid: false, msg: { en: "1 Uppercase Letter", ar: "حرف كبير واحد" } },
    { is_valid: false, msg: { en: "1 Lowercase Letter", ar: "حرف صغير واحد" } },
    { is_valid: false, msg: { en: "1 Number", ar: "رقم واحد" } },
    { is_valid: false, msg: { en: "1 Symbol", ar: "رمز واحد" } },
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let newError = "";

    switch (fieldName) {
      case "confirmPassword":
        if (!value) {
          newError = t("validation.required");
        } else if (value != formData.password) {
          newError = t("validation.passwordDoesNotMatch");
        }
        break;
      case "password":
        if (!value) {
          newError = t("validation.required");
        }
        validatePassword(value);
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: newError }));
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setValidationData([
      {
        is_valid: password.length >= minLength,
        msg: { en: "At least 8 characters", ar: "على الأقل 8 حرفًا" },
      },
      {
        is_valid: hasUppercase,
        msg: { en: "1 Uppercase Letter", ar: "حرف كبير واحد" },
      },
      {
        is_valid: hasLowercase,
        msg: { en: "1 Lowercase Letter", ar: "حرف صغير واحد" },
      },
      { is_valid: hasNumber, msg: { en: "1 Number", ar: "رقم واحد" } },
      { is_valid: hasSymbol, msg: { en: "1 Symbol", ar: "رمز واحد" } },
    ]);
  };

  const validateForm = () => {
    const newErrors = {};
    const password = formData.password;
    const confirmPassword = formData.confirmPassword;

    if (!password) {
      newErrors.password = t("validation.required");
    }
    if (!confirmPassword) {
      newErrors.confirmPassword = t("validation.required");
    }
    if (password != confirmPassword) {
      newErrors.confirmPassword = t("validation.passwordDoesNotMatch");
    }

    setErrors(newErrors);
    validatePassword(password);

    return Object.keys(newErrors).length ==0;
  };

  const formSubmitHandler = async (e) => {
    e?.preventDefault();
    if (validateForm()) {
      if (isForgotPassword) {
        const resetPasswordPayload = {
          CPR: forgotPasswordDetails?.cprNumber,
          Token: forgotPasswordData?.Token,
          PinId: forgotPasswordData?.PinId,
          Password: formData.password,
          Pin: otp,
        };

        const response = await dispatch(resetPassword(resetPasswordPayload));
        if (response?.type =="resetPassword/fulfilled") {
          toast.success(translate("Password updated successfully","تم تحديث كلمة المرور بنجاح"));
          navigate("/sign-in");
        } else {
          toast.error(
            translate(
              response?.payload?.ErrorMessageEn,
              response?.payload?.ErrorMessageAr
            )
          );
        }
      } else {
        const payload = {
          email: signupDetails?.email,
          username: signupDetails?.cprNumber,
          cpr: signupDetails?.cprNumber,
          password: formData.password,
          confirmPassword: formData.confirmPassword,
          phoneNumber: signupDetails?.mobile,
        };
        const response = await dispatch(createCustomerAccount(payload));
        if (response?.payload?.result =="Succeeded") {
          NextHandler();
        } else {
          toast.error(
            translate(
              response?.payload?.ErrorMessageEn,
              response?.payload?.ErrorMessageAr
            )
          );
        }
      }
    }
  };

  const NextHandler = () => {
    onSuccess();
  };

  const isFormValid = validationData.every((item) => item.is_valid);


  return (
    <>
      {isLoading && (
        <Loader className=" w-10 h-10 fixed left-[50%] top-[50%]" />
      )}
      <div className="sign_in_page flex align-center h_100vh justify-center items-center mt-20 max767:mt-20 ipad:mt-20">
        <div className="no_padding_top right_cont signup-right-content h_100 create_pass_page justify-center items-center block min-w-[500px]  pb-0 px-0 max767:min-w-[90%] max767:p-5">
          <div className="content">
            {mobile && (
              <BackBtnWithLabel label={translate("Sign up", "التسجيل")} />
            )}
            <TitleDescription
              className="max767:mt-[-5px] max767:mb-6"
              title={
                pageTitle
                  ? pageTitle
                  : translate(
                      "Create your password",
                      "قم بإنشاء كلمة المرور الخاصة بك"
                    )
              }
              description={translate(
                " Choose a secure password that is at least 12 characters long",
                "اختر كلمة مرور آمنة تتكون من 12 حرفًا على الأقل"
              )}
            />
          </div>
          <form onSubmit={formSubmitHandler}>
            <Input
              label={t("signUpFlow.createPassPage.password_input_label")}
              type="password"
              handleChange={handleChange}
              name="password"
              value={formData.password}
              error={errors.password}
              data-testid="password"
            />
            <Input
              label={t(
                "signUpFlow.createPassPage.confirm_password_input_label"
              )}
              type="password"
              handleChange={handleChange}
              name="confirmPassword"
              value={formData.confirmPassword}
              error={errors.confirmPassword}
              data-testid="confirmPassword"
            />
            <div className="pass_valid_cont flex flex-col items-start gap-2 self-stretch bg-[#F6F8F9] mx-0 mb-6 p-4 rounded-[10px]">
              <h4>
                {translate(
                  "Your password must have:",
                  "يجب أن تحتوي كلمة المرور على:"
                )}
              </h4>
              {validationData.map((each, index) => (
                <ValidationItem
                  key={index}
                  is_validated={each.is_valid}
                  msg={each.msg}
                />
              ))}
            </div>

            <Button
              type="submit"
              label={"Continue"}
              className="primary-button"
              disabled={!isFormValid}
            />
          </form>
        </div>
      </div>
    </>
  );
};

export default CreatePasswordPage;
