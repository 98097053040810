import React from "react";
import RenewMotorLayout from "../../../renewalMotorPolicy/renewMotorLayout";
import TitleDescription from "../../../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useTranslation } from "react-i18next";
import PolicyCard from "../../../../../components/policyCard/policyCard";
import { transferPolicyData } from "../../../../dashboard/data";
import AlertComponent from "../../../transferPolicyFlow/alertComponent";
import useLanguageTranslate from "../../../../../hooks/useLanguageTranslate";
import Button from "../../../../../components/common/Button/Button";
import usePolicyCancellationFlow from "../../../../../hooks/usePolicyCancellationFlow";
import PolicyCancellationMotorLayout from "./policyCancellationMotorLayout";
import canclecircle from "../../../../../assets/images/cancelcircle.svg"

import { useNavigate } from "react-router-dom";
import { getPolicyViewHeaderPartyResponse, getPolicyViewHeaderResponse } from "../../../../../redux/features/policyDetailsSlice";
import { useSelector } from "react-redux";
import { selectCrmCaseComplaintLoader } from "../../../../../redux/features/crm/caseComplaintSlice";
import { Loader } from "storybook/internal/components";

const ConfimPolicyCancellationPage = ({handleNavigationToPolicyOverview,confimedCancellation, selectedReasonOut}) => {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const translate = useLanguageTranslate();
  const { handleNextSubstep, handlePrevStep } = usePolicyCancellationFlow();

  // Get policy detail. Call API if empty or check redux
  const policyDetailsInfo = useSelector(getPolicyViewHeaderResponse);
  const policyDetailsPartyInfo = useSelector(getPolicyViewHeaderPartyResponse);
  const policyCardInfo = {
    "ModelYear": "",
    "Make": policyDetailsInfo ? policyDetailsInfo?.Make : '--',
    "Model": policyDetailsInfo?.Model || '--',
    "CPR": "",
    "PolicyNumber": policyDetailsInfo?.PolicyNumber || '--',
    "RegistrationNumber": policyDetailsInfo?.RegistrationNumber || '-',
    "SumInsured": 0.0,
    "PlanName": policyDetailsInfo?.PlanName || '--',
    "ProductCode": "0101",
    "ProductName": "Motor",
    "StartDate": policyDetailsInfo?.FromDateStr?.split(" ")[0]?.split("/")?.reverse()?.join("-") || '--',
    "EndDate": policyDetailsInfo?.ToDateStr?.split(" ")[0]?.split("/")?.reverse()?.join("-") || '--',
    "FromDateStr": policyDetailsInfo?.FromDateStr?.split(" ")[0] || '--',
    "ToDateStr": policyDetailsInfo?.ToDateStr?.split(" ")[0] || '--',
    "Branch": "online.solidarity",
    "MainChannel": "Direct Bahrain",
    "Renewed": policyDetailsInfo?.IsRenewed,
    "PolicyStatus": "Active",
    "IsRenewable": false
  };

  console.log('Selected reason from final page: ', selectedReasonOut);
  const isCaseLoading = useSelector(selectCrmCaseComplaintLoader);
  return (
    <PolicyCancellationMotorLayout
      backClickHandler={handlePrevStep}
      is_bottom_sticky_layout={false}
    >
      <div className="flex flex-col items-center mx-auto max-w-lg max767:p-5 max767:mt-10">
        <div className="flex justify-center items-center w-full">
          <div className=" p-0 w-full confirmCancellation">
            <TitleDescription
              title={t(
                "motorInsurance.policyCancellationFlow.confirmCancellation.title"
              )}
              description={t(
                "motorInsurance.policyCancellationFlow.confirmCancellation.description"
              )}
            />
            <div className="flex flex-col items-center gap-2 self-stretch bg-[#F6F8F9] p-4 rounded-2xl mb-4">
              <p className="text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px] gap-2 flex"><img src={canclecircle} alt=""/> {translate("You are cancelling this policy","أنت تقوم بإلغاء هذه الوثيقة")}</p>
              <PolicyCard
                policy_name="name"
                policy_label={"label"}
                policy_number={"1112"}
                from_date={policyCardInfo.FromDateStr}
                to_date={policyCardInfo.policyDetailsInfo}
                policy_bg_image={policyCardInfo.policy_bg_image}
                expire_days={policyCardInfo.expire_days}
                is_text_dark={policyCardInfo.is_text_dark}
                mapping_key={policyCardInfo.id}
                data={policyCardInfo}
                disableCardClick
              />
            </div>
            <AlertComponent
              title={translate(
                "Please note that once you confirm cancellation, this request will be processed and cannot be reversed. Ensure all details are correct and that you understand the implications of proceeding.",
                "يرجى ملاحظة أنه بمجرد تأكيد الإلغاء، سيتم معالجة هذا الطلب ولا يمكن عكسه. تأكد من صحة جميع التفاصيل وأنك تفهم تداعيات المتابعة.."
              )}
            />
            {
              isCaseLoading ? (<Loader/>) : (
                <div className="flex gap-4 mt-4 max767:flex-col">
                  <Button label={t("motorInsurance.policyCancellationFlow.confirmCancellation.keepPolicyBtn")} onSubmit={handleNavigationToPolicyOverview} className="flex flex-col items-center max-w-[100%] gap-2.5 flex-[1_0_0] border max767:text-sm bg-white text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px] p-4 rounded-[10px] border-solid border-[#DDE2E4] hover:bg-[#F6F8F9] rtl:w-max"/>
                  <Button label={t("motorInsurance.policyCancellationFlow.confirmCancellation.confirmCancellationBtn")} onSubmit={confimedCancellation} className="flex w-[300px] max-w-[100%] max767:w-full max767:text-sm flex-col items-center gap-2.5 bg-[#CC0905] text-white text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px] p-4 rounded-[10px] canclebutton hover:bg-[#CC0905] rtl:w-max"/>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </PolicyCancellationMotorLayout>
  );
};

export default ConfimPolicyCancellationPage;
