import React from 'react'
import './selectInput.css'
import useLanguageTranslate from '../../../hooks/useLanguageTranslate'

const SelectInput = ({ formData, handleChange, options_data, className, name, default_placeholder, label, error }) => {
    const translate = useLanguageTranslate()
    return (
        <div className={`formField form-input flex-1 min-h-[100px] ${className}`}>
            {label && <label className="block mb-1 text-gray-700 not-italic font-normal leading-6 text-base max-350:text-[13px]">{label}</label>}
            <select
                className="border bg-white rounded-[10px] border-solid border-[#B0BABF] p-2.5 w-full"
                name={name}
                value={formData?.[name] || ""}
                onChange={handleChange}
            >
                <option value="">{default_placeholder||translate("Select","يختار")}</option>
                {options_data?.map((each, i) => (
                    <option key={each.id || `${i}`} value={each.value}>
                        {each.label}
                    </option>
                ))}
            </select>

            {error && <p className="field-err text-red-500 text-sm mt-1">{error}</p>}
        </div>

    )
}

export default SelectInput