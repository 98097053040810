export const ar = {
  translation: {
    language: "Arabic",
    header: {},
    validation: {
      required: "هذا الحقل مطلوب.",
      invalidEmail: "البريد إلكتروني غير صالح.",
      invalidMobile: "رقم الهاتف المحمول غير صالح.",
      invalidName: "يجب أن يحتوي الاسم على حروف أبجدية ومسافات فقط.",
      invalidNumber: "الرجاء الكتابة بالأرقام فقط.",
      invalidCPR: "Please enter {{length}} digits only.",
      futureDate: "لا يمكن أن يكون تاريخ الميلاد في المستقبل.",
      passwordDoesNotMatch: "كلمة السر غير متطابقة.",
      selectOption: "يرجى تحديد خيار.",
      tabSelection: "يرجى تحديد قيمة ما.",
      exact_length: "يجب أن يكون هذا الحقل {{length}} حرفًا بالضبط.",
      min_length: "يجب أن يحتوي هذا الحقل على {{min}} أحرف على الأقل.",
      max_length: "يجب ألا يتجاوز هذا الحقل {{max}} أحرف.",
      minValue: "يجب أن تكون القيمة على الأقل {{min}}.",
      maxValue: "يجب ألا تتجاوز القيمة {{max}}.",
      cpr_number: "رقم الهوية يجب أن يتكون من 9 أرقام.",
    },
    siginPage: {
      new_to_label: "أول تجربة لك مع سوليدرتي؟",
      signup_link_label: "تسجيل حساب",
      cpr_input_label: "رقم الهوية أو رقم السجل التجاري",
      password_input_label: "كلمة السر",
      forgot_password_link_label: "لقد نسيت كلمة السر",
      sign_in_btn_label: "تسجيل الدخول",
    },
    signUpFlow: {
      signUpPage: {
        already_have_label: "هل لديك حساب بالفعل؟",
        sign_in_label: "تسجيل الدخول",
        cpr_input_label: "رقم الهوية أو رقم السجل التجاري",
        email_input_label: "البريد الإلكتروني",
        mobile_input_label: "رقم الهاتف المحمول",
        btn_label: "التالي",
      },
      verifyOtpPage: {
        page_description: "أدخل رقم الرمز المكون من 4 أرقام",
        sent_it_label: "لقد قمنا بإرساله إلى",
        change_link_label: "تغيير",
        didnt_recieve_label: "لم أتلقى الرمز",
        btn_label: "استمرار",
      },
      createPassPage: {
        page_description: "اختر كلمة سر آمنة تبلغ 8 حرفًا على الأقل",
        password_input_label: "كلمة السر",
        confirm_password_input_label: "تأكيد كلمة السر",
        your_pass_must_label: "يجب أن تحتوي كلمة السر الخاصة بك على:",
      },
      getVerifiedPage: {
        page_title: "قم بتوثيق حسابك",
        page_description:
          "ادخل الرابط المرسل إلى رقم هاتفك المحمول لتحميل نسخة من:",
        idcard_img: "بطاقة الهوية",
        driver_license_img: "رخصة السائق",
        selfie_label: "صورة شخصية",
        verify_your_account_label: "جاري توثيق حسابك",
        dont_close_label:
          "الرجاء عدم إغلاق أو إعادة تنشيط هذه الصفحة أثناء عملية التوثيق",
        btn_label: "استمرار",
        verification_failed_title: "فشل التحقق",
        verification_failed_description:
          "للأسف، لم نتمكن من التحقق من هويتك باستخدام المعلومات المقدمة. يرجى التأكد من أن الصورة واضحة وجميع تفاصيل المستند مرئية.",
      },
      verifySuccessPage: {
        btn_label: "استمرار",
      },
      cpr_exists_popup: {
        title: "الحساب موجود بالفعل",
        description_1: "يبدو أنك تمتلك حسابًا لدينا بالفعل.",
        description_2:
          "يرجى تسجيل الدخول للمتابعة. إذا كنت قد نسيت كلمة المرور، يمكنك إعادة تعيينها بسهولة.",
        btn_label: "تسجيل الدخول",
      },
    },
    dashboard: {
      quick_actions_label: "الإجراءات السريعة",
      offers_label: "العروض",
      viewAll: "عرض الكل",
    },
    newPolicy: {
      online_services_label: "الخدمات الإلكترونية",
      new_insurance_policies_label: "وثائق التأمين الجديدة",
      general_insurance_label: "التأمين العام",
      life_insurance_label: "التأمين على الحياة",
      buy_online_plans_label: "اشتر البرامج عبر الانترنت",
      contact_advisor_message:
        "يمكنك التواصل مع أحد مستشارينا الماليين لتسجيلك في أحد هذه البرامج",
      speak_to_our_advisor_label: "تواصل مع مستشارنا",
    },
    profileSettings: {
      home: "الصفحة الرئيسية",
      profile_settings: "إعدادات الملف الشخصي",
      last_login: "أخر تسجيل دخول",
      your_profile: "ملفك الشخصي",
      full_name: "الاسم الكامل",
      cpr_cr_number: "رقم الهوية\\السجل التجاري",
      mobile_number: "رقم الهاتف المحمول",
      email_address: "البريد الإلكتروني",
      change_mobile_number: "تغيير رقم الهاتف المحمول",
      password: "كلمة السر",
      current_password: "كلمة السر الحالية",
      new_password: "كلمة السر الجديدة",
      confirm_new_password: "تأكيد كلمة السر الجديدة",
      update_password: "تحديث كلمة السر",
      security: "الأمن",
      two_factor_authentication: "المصادقة الثنائية",
      yes_no_btn:
        "أضف طبقة إضافية من الحماية للوصول إلى حساب الحياة الخاص بك باستخدام التحقق عبر الرسائل النصية القصيرة",
      verify_email: "التحقق من بريدك الإلكتروني",
    },
    commonLabels: {
      home_label: "الصفحة الرئيسية",
      last_login_label: "أخر تسجيل دخول",
      my_policies_label: "وثائقي",
      expired_policies_label: "الوثائق المنتهية",
    },
    motorInsurance: {
      promoCode: {
        inputLabel: "رمز الخصم",
        button: "تطبيق",
      },
      uploadSuccess: {
        upload_successful: "تهانينا على نجاح عملية تحميل الصور الخاصة بك!",
        upload_photos:
          "لقد قمت بنجاح بتحميل صور لسيارتك من جميع الزوايا المطلوبة. هذه الصور ضرورية لتقييم الحالة الحالية لسيارتك كجزء من طلب التأمين الخاص بك.",
      },
      signInModal: {
        title: "احفظ خيارات التأمين الخاصة بك",
        description:
          "قبل أن تقوم بتخصيص الوثيقة الخاصة بك بتغطيات إضافية، دعنا نقوم بحفظ اختياراتك.",
        old_account: "إذا كان لديك حساب بالفعل، يرجى تسجيل الدخول!",
        register_account:
          "هل أنت مستخدم جديد؟ قم بالتسجيل معنا للحصول على تجربة سلسة ومريحة.",
        help: "هل تحتاج إلى مساعدة؟",
        customer_support:
          "فريق دعم العملاء الخاص بنا متاح لمساعدتك في كل خطوة.",
        faq: "الأسئلة الشائعة",
        call_us: "تواصل معنا",
      },
      bottomStickybtnlayout: {
        summary: "ملخص العرض",
      },
      viewExpiredPolicy: {
        view: "عرض الوثائق المنتهية الصلاحية",
      },
      adjustCarvaluePopUp: {
        title: "تعديل قيمة سيارتك",
        description: "اعتبارًا من عام 2024، تبلغ القيمة التقديرية لسيارتك",
        disclaimer:
          "يرجى ملاحظة أن زيادة قيمة سيارتك سيؤثر على إجمالي القسط الذي تدفعه.",
        by_how_much_increase: "بكم تريد زيادة هذه القيمة؟",
        by_how_much_decrease: "بكم تريد تخفيض هذه القيمة؟",
        label: "القيمة المحدثة لسيارتك (المبلغ المؤمن عليه)",
        labelConfirm: "تأكيد",
        labelCancel: "إلغاء",
      },
      covergeAndBenefit: {
        flexi: "برنامج فليكسي",
        benefit: "المزايا المتضمنة",
        optional_coverage: "تغطية اختيارية",
        get_added: "احصل على خيارات تغطية إضافية تبدأ من 8 دينار بحريني فقط",
        change_plan: "تغيير البرنامج",
      },
      policyOverview: {
        back: "عودة",
        save: "حفظ",
        policy_no: "رقم الوثيقة",
      },
      transferPolicyFlow: {
        newOwnerdetails: {
          title: "نقل ملكية الوثيقة",
          description:
            "يرجى إدخال تفاصيل المالك الجديد. بمجرد تقديم التفاصيل، سيتلقى المالك الجديد إشعارًا لإكمال عملية النقل.",
          disclaimer:
            "يرجى ملاحظة أنه بمجرد اكتمال عملية النقل بنجاح، لن تظهر الوثيقة في حسابك بعد الآن.",
          policy_number: "رقم الوثيقة",
          label_cpr: "رقم الهوية للمالك الجديد",
          label_mobile: "رقم الهاتف المحمول",
        },
        transfersuccess: {
          transfer_request: "تم تقديم طلب النقل بنجاح!",
          transfer_policy_ownership:
            "لقد تم تقديم طلبك لنقل ملكية الوثيقة بنجاح، وتم إرسال التعليمات إلى رقم الهاتف المحمول للمالك الجديد +973 37796977.",
          return_home: "العودة إلى الصفحة الرئيسية",
        },
      },
      newMotorInsuranceFlow: {
        basicInfo: {
          title: "تأمين السيارات الجديد",
          subtitle: "مرحبًا بك! لنبدأ ببعض المعلومات الأساسية.",
          fullName: "الاسم الكامل",
          emailAddress: "البريد الإلكتروني",
          mobileNumber: "رقم الهاتف المحمول",
          dateOfBirth: "تاريخ الميلاد",
          day: "اليوم",
          month: "الشهر",
          year: "السنة",
          continueButton: "استمرار",
        },
        paymentSummary: {
          review_confirm: "المراجعة والتأكيد",
          review_proceed: "يرجى مراجعة معلوماتك والانتقال إلى صفحة الدفع.",
          motor_insurance: "وثيقة تأمين السيارات الجديدة",
          confirm_payment: "قم بمراجعة التفاصيل أدناه وتأكيد الدفع.",
          plate_number: "رقم اللوحة",
          plate_type: "نوع اللوحة",
          chassis_number: "رقم الهيكل\\الشاصي",
          car_make: "ماركة السيارة",
          car_model: "طراز السيارة",
          manufacturing_year: "سنة الصنع",
          sum_insured: "المبلغ المؤمن عليه",
          startDate: "تاريخ البدء",
          plan: "البرنامج",
          add_ons: "الإضافات",
          disclaimer: "يرجى ملاحظة أن هذه الوثيقة سيتم إصدارها باسمك.",
          agreement:
            "أقر بموجبه أن المعلومات المذكورة أعلاه صحيحة ودقيقة حسب علمي، وأوافق على التعاون الكامل من خلال تقديم جميع المستندات المطلوبة قبل الدفع. أفهم أن تقديم معلومات غير صحيحة سيؤدي إلى إلغاء وثيقة التأمين الخاصة بي. كما أنني قرأت وأوافق على ",
          policy: "مواصفات الوثيقة",
          insurance: "لمنتج التأمين الماثل.",
          proceed_payment: "الانتقال إلى صفحة الدفع",
          declaration: [
            "أقر بموجبه أن السيارة المؤمن عليها لم تتعرض لأي حادث أو حادثة تسببت في تلف أو خسارة أو حاجة للإصلاح خلال الأشهر الاثني عشر الماضية.",
            "أؤكد أنه حسب معرفتي، لم تكن هناك أحداث أو وقائع تتعلق بهذه السيارة تتطلب تقديم مطالبة تأمين أو تقرير خلال هذه الفترة.",
            "أفهم أن تقديم معلومات كاذبة أو مضللة قد يؤثر على أهليتي للتغطية وقد يكون سببًا لتعديل أو إلغاء السياسة.",
            "أفهم أن الفشل في الالتزام بهذا التصريح قد يؤثر على أهليتي للتغطية وقد يؤدي إلى تعديلات أو إلغاء السياسة.",
            "أنا الموقع أدناه أفهم وأوافق على أنه بالنسبة للمبلغ المؤمن عليه فوق أو تحت القيمة السوقية، إذا حدثت أي مطالبات خلال فترة التأمين المحددة، فسيتم أخذ القيمة السوقية أو المبلغ المؤمن عليه، أيهما أقل، بعين الاعتبار.",
          ],
        },
        documentsReadyInfo: {
          title: "هل أنت مستعد لبدء وثيقتك الجديدة؟",
          description:
            "يرجى التأكد من أن لديك المعلومات والمستندات التالية جاهزة من أجل عملية سلسة.",
          aboutCar: {
            title: "حول سيارتك",
          },
          documents: {
            title: "المستندات",
          },
          continueButton: "استمرار",
        },
        carInfo: {
          title: "حول سيارتك",
          description: "هل سيارتك جديدة؟",
          yesButton: "نعم",
          noButton: "لا",
          continueButton: "استمرار",
          newCarForm: {
            title: "تفاصيل سيارتك الجديدة",
            description: "يرجى تقديم تفاصيل حول سيارتك",
            chasisNumberLabel: "رقم الهيكلالشاصي (VIN)",
            modelMakeLabel: "ماركة السيارة",
            carModelLabel: "طراز السيارة",
            modelYearLabel: "سنة الصنع",
            carBodyTypeLabel: "نوع هيكل السيارة",
            noOfSeatsLabel: "عدد المقاعد",
            engineCapacityLabel: "سعة المحرك",
          },
          no_new_brand: {
            label: "هل لديك ملكية السيارة؟",
            uploadInstruction: "قم بتحميل صورة واضحة لملكية السيارة",
          },
        },
        carInfoConfirm: {
          title: "معلومات سيارتك",
          description: "يرجى التأكد من صحة التفاصيل أدناه لسيارتك.",
        },
        carInfoPlateNumber: {
          title: "معلومات سيارتك",
          description: "ما هو رقم لوحة سيارتك ونوعها؟",
          plateNumber: "رقم اللوحة",
          plateType: "نوع اللوحة",
          discoverPlateTypes: "اكتشف الاختلافات بين أنواع اللوحات",
          showMe: "أرِنِي",
        },
        carInfoFinancing: {
          title: "تمويل السيارات",
          description: "هل يتم تمويل سيارتك حاليًا بالتقسيط؟",
          bankLabel: "من البنك الذي يمول سيارتك؟",
          selectBank: "اختر البنك",
          carValueLabel: "قيمة سيارتك (مبلغ التأمين)",
          bodyTypeLabel: "نوع هيكل السيارة",
          noOfSeatsLabel: "عدد المقاعد",
          engineCapacityLabel: "سعة المحرك",
          buttonText: "تعديل قيمة السيارة",
          label_file_claim: "متى كانت آخر مرة قمت فيها بتقديم مطالبة؟",
          bank_lender: "اسم البنك\\المقرض",
          insurance_policy: "تاريخ بدء وثيقة التأمين",
          car_cost: "ما هي تكلفة سيارتك؟",
        },
        fileUploadInput: {
          choose_file: "اختر ملف",
          remove: "إزالة",
        },
        coverageEnhancement: {
          title: "تعزيز التغطية الخاصة بك",
          description: "هل ترغب بإضافة تغطيات ومزايا إضافية إلى وثيقتك؟",
        },
        planSelector: {
          title: "اختر برنامجك",
          description: "حدد البرنامج الذي يناسب احتياجاتك بشكل أفضل.",
          viewFullList: "عرض القائمة الكاملة للمزايا والتغطيات",
        },
        verifyOtp: {
          title: "التحقق من كلمة السر لمرة واحدة (OTP)",
          description:
            "أدخل الرمز المكون من 4 أرقام المرسل إلى رقم هاتفك المحمول",
          sent_it_label: "لقد قمنا بإرساله إلى",
          didnt_recieve_label: "لم أتلقى الرمز",
        },
        uploadDocsPage: {
          title: "تحميل المستندات المطلوبة",
          description: "يرجى تحميل المستندات اللازمة للمتابعة.",
          noClaimLabel: "تحميل صور من شهادة خصم عدم المطالبة",
          bankLetterLabel: "تحميل صورة من خطاب البنك",
        },
        upload3rdPartyDocsPage: {
          title: "تحميل صور لسيارتك",
          description: "قم بالتقاط صور السيارة وتحميلها بسهولة",
          stepsToUpload: {
            label: "خطوات التحميل",
            description:
              "امسح رمز الاستجابة السريعة أدناه باستخدام هاتفك الذكي للوصول إلى صفحة تحميل الملفات الملائمة للهواتف المحمولة. اتبع التعليمات التي تظهر على الشاشة لالتقاط كل صورة وتحميلها مباشرة من هاتفك.",
          },
          instructions: {
            label: "التعليمات",
            description:
              "يرجى تحميل صور واضحة لجميع جوانب سيارتك الأربعة لتعكس حالتها الحالية بدقة. ابدأ بالتقاط الجزء الأمامي، ثم الجزء الخلفي، ثم كل جانب. سيضمن ذلك عرض جميع الجوانب بوضوح.",
          },
          needAssistance:
            "هل تحتاج إلى مساعدة؟ في حال واجهت أي مشكلات أو احتجت إلى مساعدة في عملية تحميل الملفات، يرجى زيارة صفحة",
          faq: "الأسئلة الشائعة",
          sectionOr: "أو",
          contactCustomerSupport: "التواصل مع خدمة دعم العملاء",
        },
        paymentMethods: {
          title: "طريقة الدفع",
          quotationNumber: "رقم التسعيرة",
          description: "الوصف",
          footerText1:
            "سوليدرتي البحرين هي شركة تابعة لمجموعة سوليدرتي القابضة",
          footerText2:
            "مرخصة ومنظمة من قبل مصرف البحرين المركزي كشركة تكافل محلية - الفئة الرابعة",
          paymentMethodsList: {
            debitCard: "بطاقة الخصم",
            creditCard: "بطاقة الائتمان",
            applePay: "آبل باي",
            flooss: "تطبيق فلوس",
          },
        },
        paymentSuccess: {
          congratulations: "لقد انتهيت الآن، تهانينا!.",
          notification_received:
            "يجب أن تكون قد تلقيت إشعارًا يؤكد أن وثيقة التأمين الخاصة بك تمت الموافقة عليها لدى شركة سوليدرتي.",
          view_download: "عرض وتنزيل مستندات وثيقة التأمين الخاصة بك:",
          for_inquiries:
            "لأي استفسارات يمكنك التواصل مع المتحدث الآلي الخاص بنا عبر",
          whatsapp: "الواتساب",
          or_call: "أو التواصل معنا على",
          view_policy_details: "عرض تفاصيل وثيقتك",
          or: "أو",
        },
      },
      renewalPolicyFlow: {
        cprNoPage: {
          cprNo: "رقم الهوية أو رقم السجل التجاري",
          title: "تجديد الوثيقة",
          description:
            "يرجى إدخال رقم الهوية الخاصة بك لعرض الوثائق التي تتطلب التجديد.",
          plateNo: "رقم اللوحة",
          quickRenewal: "التجديد السريع",
          quickRenewalDesc:
            "يرجى تقديم رقم السجل الشخصي (CPR) ورقم تسجيل المركبة للمتابعة مع تجديد الوثيقة.",
        },
        motorPolicyrenewal: {
          title: "تجديد وثيقة تأمين السيارات",
          description: "قم بمراجعة التفاصيل أدناه وتأكيد الدفع.",
          payment: "الانتقال إلى صفحة الدفع",
          declaration:
            "أنا الموقع أدناه أفهم وأوافق على أنه بالنسبة للمبلغ المؤمن عليه فوق أو تحت القيمة السوقية، إذا حدثت أي مطالبات خلال فترة التأمين المحددة، فسيتم أخذ القيمة السوقية أو المبلغ المؤمن عليه، أيهما أقل، بعين الاعتبار.",
        },
        policyCard: {
          selected: "تم التحديد",
          select: "تحديد",
          expiry_date: "تنتهي صلاحيتها في",
          days: "أيام",
        },
        currentPlanDetails: {
          plan_details: "تفاصيل البرنامج الحالي",
          review_policy: "تغيير برنامجك",
        },
        verifyOtpPage: {
          title: "التحقق من كلمة السر لمرة واحدة (OTP)",
          description: "أدخل رقم الرمز المكون من 4 أرقام",
          sent_it_label: "لقد قمنا بإرساله إلى",
          didnt_recieve_label: "لم أتلقى الرمز",
        },
        selectPoliciesPage: {
          title: "تجديد الوثيقة",
          description:
            "يرجى إدخال رقم الهوية الخاصة بك لعرض الوثائق التي تتطلب التجديد.",
        },
        reviewYourPolicyPage: {
          title: "مراجعة برنامج الوثيقة الخاصة بك",
          description:
            "يمكنك إما الاستمرار في برنامجك الحالي أو تخفيض الترقية.",
        },
        adjustCarValuePage: {
          title: "لقد تغيرت قيمة سيارتك",
          description: "هل ترغب في تعديل القيمة؟",
        },
        enhanceYourCoverage: {
          title: "قم بتعزيز وثيقتك",
          description:
            "ما هي التغطيات والمزايا الإضافية التي ترغب في إضافتها إلى وثيقتك؟",
        },
        reviewAndConfirmPage: {
          title: "المراجعة والتأكيد",
          description: "يرجى مراجعة معلوماتك والانتقال إلى صفحة الدفع.",
        },
        chooseDowngradePlanPage: {
          title: "مراجعة برنامج الوثيقة الخاصة بك",
          description:
            "يمكنك إما الاستمرار في برنامجك الحالي أو تخفيض الترقية.",
        },
      },
      changePlanMotorPolicy: {
        selectNewPlan: {
          title: "تحديث وثيقتك",
          description: "حدد خطة جديدة تناسب احتياجاتك بشكل أفضل.",
        },
        reviewAndConfirmPage: {
          upgrade_title: "المراجعة والتأكيد",
          upgrade_description: "يرجى مراجعة معلوماتك والانتقال إلى صفحة الدفع.",
          downgrade_title: "مراجعة وتأكيد عملية تخفيض الترقية الخاصة بك",
          downgrade_description: "يرجى مراجعة معلوماتك بعناية قبل المتابعة.",
          upgrade_plan: "أنت الآن تقوم بترقية برنامجك",
          third_party: "برنامج الطرف الثالث",
          gold: "البرنامج الذهبي",
          downgrade_plan: "أنت على وشك تخفيض ترقية برنامجك",
          confirm: "تأكيد",
          declaration: [
            "أقر بموجبه أن السيارة المؤمن عليها لم تتعرض لأي حادث أو حادثة تسببت في تلف أو خسارة أو حاجة للإصلاح خلال الأشهر الاثني عشر الماضية.",
            "أؤكد أنه حسب معرفتي، لم تكن هناك أحداث أو وقائع تتعلق بهذه السيارة تتطلب تقديم مطالبة تأمين أو تقرير خلال هذه الفترة.",
            "أفهم أن تقديم معلومات كاذبة أو مضللة قد يؤثر على أهليتي للتغطية وقد يكون سببًا لتعديل أو إلغاء السياسة.",
          ],
        },
      },
      addMoreCovers: {
        additional_covers: "أنت الآن تقوم بإضافة تغطيات إضافية إلى وثيقتك",
        car: "استبدال السيارة",
        small_car: "15 يومًا - سيارة صغيرة",
        roadside_assistance: "خدمة المساعدة على الطريق",
        declaration: [
          "أقر بموجبه أن السيارة المؤمن عليها لم تتعرض لأي حادث أو حادثة تسببت في تلف أو خسارة أو حاجة للإصلاح خلال الأشهر الاثني عشر الماضية.",
          "أؤكد أنه حسب معرفتي، لم تكن هناك أحداث أو وقائع تتعلق بهذه السيارة تتطلب تقديم مطالبة تأمين أو تقرير خلال هذه الفترة.",
          "أفهم أن تقديم معلومات كاذبة أو مضللة قد يؤثر على أهليتي للتغطية وقد يكون سببًا لتعديل أو إلغاء السياسة.",
        ],
      },
      removeBankNameFlow: {
        removeBankPage: {
          title: "إزالة اسم البنك من الملكية",
          description:
            "يرجى تأكيد تفاصيل المالك الحالي وإزالة اسم البنك المرتبط بسيارتك.",
          input_label: "البنك الحالي",
        },
        uploadBankNOC: {
          title: "تحميل خطاب عدم ممانعة البنك",
          description:
            "يرجى تحميل خطاب عدم الممانعة من البنك الخاص بك، والذي يؤكد إزالة اسم البنك من ملكية سيارتك.",
          input_label: "تحميل خطاب عدم ممانعة البنك",
        },
      },
      adjustSumInsuredFlow: {
        adjustSumValue: {
          title: "تعديل قيمة سيارتك (المبلغ المؤمن عليه)",
          description:
            "يرجى تحديد المبلغ الذي ترغب في زيادته أو تحديثه لهذه القيمة.",
          car_value_input_label: "قيمة سيارتك (مبلغ التأمين)",
          request_call_label: "طلب مكالمة",
          increase_over_30: "لزيادة أكثر من 30%",
        },
        reviewAndConfirmPage: {
          title: "المراجعة والتأكيد",
          description: "يرجى مراجعة معلوماتك والانتقال إلى صفحة الدفع.",
          you_have_increased_label: "لقد قمت بزيادة القيمة التأمينية لسيارتك",
          proceed_to_payment: "الانتقال إلى صفحة الدفع",
        },
      },
      changePlateNumberPopup: {
        title: "تحديث مطلوب لدى الإدارة العامة للمرور",
        message1:
          "لا يمكن متابعة محاولة تغيير رقم اللوحة لأن التحديث لم يتم لدى الإدارة العامة للمرور (GDT) أولاً.",
        message2:
          "يرجى تحديث تسجيل مركبتك لدى الإدارة العامة للمرور ثم العودة هنا لتحديث بوليصة التأمين الخاصة بك.",
        buttonLabel: "العودة إلى الصفحة الرئيسية",
      },
      changePlateNumber: {
        newPlateNumber: {
          title: "تغيير رقم لوحة سيارتك",
          description: "الرجاء إدخال رقم اللوحة الجديد لسيارتك.",
          current_plate_number_label: "رقم اللوحة الحالية",
          new_plate_number_label: "رقم اللوحة الجديد",
          plate_type_label: "نوع اللوحة",
        },
        uploadLetterOfDocument: {
          title: "تحميل وثيقة إدارة المرور",
          description:
            "يرجى تحميل خطاب من الإدارة العامة للمرور يؤكد رقم اللوحة الجديدة الخاصة بك.",
          upload_letter_of_document_label: "تحميل مستند خطاب الرقم الجديد",
        },
      },
      trackClaimFlow: {
        basicInfo: {
          title: "تتبع المطالبة",
          description: "للعثور على مطالبتك، يرجى تقديم المعلومات التالية.",
          claim_input_label: "رقم المطالبة",
          mobile_number_label: "رقم الهاتف المحمول",
        },
        verifyOtpPage: {
          title: "التحقق من كلمة السر لمرة واحدة (OTP)",
          description: "أدخل رقم الرمز المكون من 4 أرقام",
        },
        breadcrumb: {
          file_a_claim: "تقديم مطالبة",
          claim_details: "تفاصيل المطالبة",
        },
        garageSelection: {
          title: "تغيير الكراج / الورشة",
          searchPlaceholder: "ابحث عن اسم الكراج",
          submitButton: "إرسال",
          backButton: "عودة",
        },
        claimDetailCard: {
          claimType: "نوع المطالبة",
          policyPlan: "وثيقة التأمين",
          driverCprNumber: "رقم هوية للسائق",
          vehicleOwnerCprNumber: "رقم هوية لمالك المركبة",
          plateNumber: "رقم اللوحة",
          emailAddress: "البريد الإلكتروني",
          mobileNumber: "رقم الهاتف المحمول",
          accidentDescription: "وصف الحادث",
          plannedGarageWorkshop: "الكراج / الورشة المخطط لها",
          repairSurveyDate: "تاريخ فحص الإصلاح",
          claimResolution: "حل المطالبة",
        },
        uploadDocumentPage: {
          uploadLabel: "تحميل المستندات",
          submit: "إرسال",
        },
        confirmPaymentDetails: {
          title: "تأكيد تفاصيل الدفع",
          description:
            "يرجى إدخال رقم رقم الحساب البنكي الخاص بك وتحميل شهادة رقم الحساب البنكي المقابلة لتسهيل عملية الدفع.",
          ibanLabel: "رقم الحساب البنكي",
          ibanUpload: "تحميل شهادة الحساب البنكي",
          submitButton: "الموافقة والإرسال",
          backButton: "عودة",
        },
        successScreen: {
          confirmationMessage:
            "لقد تلقينا موافقتك على العرض. سيتواصل معك فريق المطالبات قريبًا لمناقشة الدفع إلى رقم الحساب البنكي المحدد. شكرًا لتعاونك.",
          backToClaimButton: "العودة إلى المطالبة",
        },
        confirmIdentityModal: {
          confirm_identity: "تأكيد هويتك",
          identity_verification:
            "يرجى تسجيل الدخول أو إنشاء حساب للتحقق من معلومات الهوية الخاصة بك وإتمام الموافقة على العرض بأمان.",
          existing_account: "إذا كان لديك حساب بالفعل، يرجى تسجيل الدخول!",
          register_info: "جديد هنا؟ سجل معنا لتجربة أكثر سلاسة.",
          need_help: "تحتاج إلى مساعدة؟",
          customer_support: "دعم العملاء لدينا هنا لمساعدتك في كل خطوة.",
          call_us: "اتصل بنا",
          faqs: "الأسئلة الشائعة",
        },
        resonForRejection: {
          title: "سبب الرفض",
          description: "يرجى توضيح سبب رفضك للعرض",
          tabLable: "سبب الرفض",
          submit: "إرسال الرد",
          counterOfferLable: "عرض مضاد",
        },
      },
      fileAClaimFlow: {
        nonSolidarityPolicyPlans: {
          title: "تقديم مطالبة",
          description: "اختر خطط الوثيقة",
        },
        customerTypePage: {
          title: "تقديم مطالبة",
          description: "من الذي سيبلغ عن المطالبة اليوم؟",
        },
        policyTypePage: {
          title: "تقديم مطالبة",
          description: "حدد نوع الوثيقة",
        },
        requiredDocsPage: {
          title: "تقديم مطالبة",
          description:
            "يرجى التأكد من أن لديك المعلومات والمستندات التالية جاهزة من أجل عملية سلسة.",
        },
        policyPlansPage: {
          title: "تقديم مطالبة",
          description: "حدد برامج الوثيقة",
          mobile_description: "حدد برنامج الوثيقة الذي يلائم مطالبتك",
        },
        yourInfoPage: {
          title: "تقديم مطالبة",
          description: "معلوماتك",
          mobile_description: "أدخل معلوماتك",
          driver_cpr_number_label: "رقم هوية السائق",
          vehicle_cpr_number_label: "رقم هوية مالك السيارة",
          plate_number_label: "رقم لوحة السيارة",
          email_address_label: "البريد الإلكتروني",
          mobile_number_label: "رقم الهاتف المحمول",
        },
        propertyDamageYourInfo: {
          owner_cpr_number_label: "الرقم الشخصي لمالك الممتلكات",
          name_label: "اسم مالك الممتلكات",
          address_label: "عنوان الممتلكات",
        },
        bodilyInjuryYourInfo: {
          owner_cpr_number_label: "الرقم الشخصي للشخص المتضرر",
        },
        accidentInfoPage: {
          title: "تقديم مطالبة",
          description: "معلومات الحادث",
          mobile_title: "معلومات الحادث",
          mobile_description: "أدخل تفاصيل الحادث والأحداث التي جرت",
          desc_accident_label: "وصف الحادث",
          desc_accident_placeholder: "أخبرنا قليلا عن الحادث",
          preferred_garage_label: "الكراج\\الورشة المرغوبة",
          preferred_garage_placeholder: "اختر الكراج\\الورشة",
          preferred_garage_hint_msg:
            "* ملاحظة: قد تكون هناك رسوم عمل إضافية إذا كانت وثيقة التأمين الخاصة بك لا تغطي تصليح الوكالة",
          preferred_date_survey_label: "التاريخ المرغوب لمعاينة السيارة",
          preferred_date_survey_hint_msg:
            "* ملاحظة: سيتم معاينة السيارة خلال 48 ساعة من وقت التسجيل",
          propertyDamageAccidentInfo: {
            contact_person_label: "الشخص المسؤول عن المسح",
            preferred_date_label: "التاريخ المفضل لإجراء المسح",
          },
          date_error: "يجب إجراء الفحص بعد 3 أيام عمل على الأقل، ولا يمكن اجراء الفحص في العطل الأسبوعية"
        },
        repairOrCashPage: {
          title: "تحميل المستندات المطلوبة",
          mobile_title: "تحميل المستندات المطلوبة",
          description: "يرجى تحميل المستندات اللازمة للمتابعة.",
          mobile_description: "يرجى تحميل المستندات اللازمة للمتابعة",
          driver_licence_label:
            "رخصة القيادة وقت الحادث (الجانب الأمامي والخلفي)",
          vehicle_ownership_label: "ملكية السيارة (الجانب الأمامي والخلفي)",
          photos_of_accidents_label: "صور الحادث",
          report_file_label: "تقرير الحادث من الإدارة العامة للمرور",
        },
        reviewAndConfirmPage: {
          title: "المراجعة والتأكيد",
          description: "يرجى مراجعة معلوماتك قبل تقديم الطلب",
          mobile_title: "مطالبة جديدة",
          mobile_description: "قم بمراجعة التفاصيل أدناه وتأكيد الطلب المقدم",
          claim_type_label: "نوع المطالبة",
          policy_plan_label: "برنامج الوثيقة",
          driver_cpr_label: "رقم هوية السائق",
          vehicle_cpr_label: "رقم هوية مالك السيارة",
          vehicle_plate_num_label: "رقم لوحة السيارة",
          email_label: "البريد الإلكتروني",
          mobile_label: "رقم الهاتف المحمول",
          accident_label: "وصف الحادث",
          preferred_garage_label: "الكراج\\الورشة المرغوبة",
          preferred_repair_label: "تاريخ التصليح المرغوب",
          driver_licence_label: "رخصة السائق",
          vehicle_owner_label: "ملكية السيارة",
          photos_of_accident_label: "صور الحادث",
          incident_gov_label: "تقرير الحادث من الحكومة",
          confirm_label_pre:
            "أقر بموجبه أن المعلومات المذكورة أعلاه حقيقية وصحيحة على حد علمي، وأوافق على التعاون بشكل كامل من خلال تقديم جميع المستندات المطلوبة قبل الدفع. أفهم أن تقديم معلومات غير صحيحة سيؤدي إلى إلغاء وثيقة التأمين الخاصة بي. كما قرأت وأوافق على",
          confirm_label_link: "مواصفات الوثيقة",
          confirm_label_post: "لمنتج التأمين الماثل.",
          declaration: [
            "من خلال تحديد المربع وتقديم هذا النموذج، أقر بأنني قرأت شروط الاستخدام وسياسة الخصوصية الخاصة بشركة سوليدرتي، وأوافق على أن يتم الاتصال بي بخصوص طلب المطالبة الخاص بي.",
            "أقر بموجب هذا أن المعلومات المذكورة أعلاه صحيحة ودقيقة وفقًا لأفضل معرفتي، وأوافق على التعاون الكامل من خلال تقديم جميع المستندات المطلوبة. أفهم أن تقديم معلومات غير صحيحة سيؤدي إلى إبطال مطالبتي.",
          ],
          submitClaimBtnLabel: "إرسال المطالبة",
        },
        soilidarityUserRepairOrCashPage: {
          title: "تقديم مطالبة",
          description: "كيف ترغب في متابعة مطالبتك؟",
        },
      },
      policyCancellationFlow: {
        review_Page: {
          title: "إلغاء وثيقة التأمين",
          description:
            "يرجى مراجعة تفاصيل وثيقة التأمين الخاصة بك بعناية قبل المتابعة في الإلغاء.",
          owner: "المالك",
          policy_number: "رقم وثيقة التأمين",
          startDate: "تاريخ البدء",
          endDate: "تاريخ الانتهاء",
          plate_number: "رقم اللوحة",
          chassis_number: "رقم الهيكل",
          car_make: "ماركة السيارة",
          car_model: "طراز السيارة",
          manufacturing_year: "سنة التصنيع",
          body_type: "نوع الهيكل",
          engine_capacity: "سعة المحرك",
          seating_capacity: "عدد المقاعد",
          vehicle_colour: "لون السيارة",
          premium_paid: "القسط المدفوع",
        },
        cancellationReson: {
          title: "سبب الإلغاء",
          description:
            "ساعدنا على فهم سبب رغبتك في إلغاء وثيقة التأمين. ملاحظاتك قيمة وستُستخدم لتحسين خدماتنا.",
        },
        confirmCancellation: {
          title: "تأكيد الإلغاء",
          description:
            "راجع تفاصيل وثيقة التأمين الخاصة بك أدناه للتأكد من أنك على علم كامل قبل إتمام الإلغاء.",
          keepPolicyBtn: "الاحتفاظ بوثيقة التأمين",
          confirmCancellationBtn: "تأكيد الإلغاء",
        },
        bottomLayout: {
          keepPolicyBtn: "أرغب في الاحتفاظ بوثيقة التأمين",
          viewTermsAndConditionBtn: "عرض شروط وأحكام الإلغاء",
        },
      },
    },
    lifeInsurance: {
      policyOverview: {
        back: "عودة",
      },
      changePaymentCycle: {
        popup_title: "تغيير دورة الدفع",
        selectPaymentLabel: "تحديد دورة الدفع",
        cancel_btn_label: "إلغاء",
        confirm_btn_label: "تأكيد الخيار والتقديم",
      },
      changePaymentMethod: {
        popup_title: "تغيير طريقة الدفع",
        currentPaymentMethod: "طريقة الدفع الحالية",
        masterCardEndLabel: "ماستركارد تنتهي في",
        expiryLabel: "انتهاء الصلاحية",
        newPaymentMethodLabel: "طريقة الدفع الجديدة",
        cancel_btn_label: "إلغاء",
        Change_btn_label: "تغيير",
      },
      updatePaymentMethod: {
        popup_title: "تحديث طريقة الدفع",
        cancel_btn_label: "إلغاء",
        change_btn_label: "تحديث",
      },
      withdrawal: {
        changeExistingFunds: {
          title: "سحب",
          description: "تغيير الصناديق الموجودة",
          currentEnhanceLabel: "القيمة النقدية الحالية",
          currentEnhanceHintLabel:
            "الحد الأدنى لمبلغ السحب هو 500 دينار بحريني ويصل إلى 85% من قيمة الوثيقة",
          withdrawalAmountLabel: "مبلغ السحب",
          withdrawalAmountHintLabel: "رسوم السحب الخاصة بنا هي 5 دينار بحريني",
          ibanNumberLabel: "رقم الآيبان",
          uploadPhotosOfCarLabel: "تحميل صور شهادة الآيبان",
        },
        reviewAndConfirm: {
          title: "المراجعة والتأكيد",
          description: "يرجى مراجعة معلوماتك وتقديم الطلب.",
          withdrawalAmountLabel: "مبلغ السحب",
          totalFeeLabel: "إجمالي الرسوم (متضمنة)",
          sendToLabel: "إرسال إلى",
          receivedLabel: "المبلغ المستلم",
          bhdLabel: "دينار بحريني",
          iAcceptTheLabel: "اقبل",
          andLabel: "و",
          termsOfLinkLabel: "شروط الاستخدام",
          privacyLinkLabel: "سياسة الخصوصية",
          confirmSubmitLabel: "تأكيد وتقديم",
        },
        decIncPremium: {
          popup_title: "تخفيض أو زيادة القسط",
          currentPremiumLabel: "القسط الحالي",
          newPremiumInputLabel: "القسط الجديد",
          newPremiumInputHintMsg: "مدفوع",
          cancelBtnLabel: "إلغاء",
          confirmBtnLabel: "تأكيد المبلغ والتقديم",
        },
      },
      surrenderPolicy: {
        title: "إلغاء وثيقتك",
        description:
          "قم بمراجعة تفاصيل وثيقتك وبتوفير المعلومات اللازمة للمضي قدمًا في عملية الإلغاء.",
        policy_detail_title: "تفاصيل الوثيقة",
        policy_detail_description:
          "لقد قمت باختيار الوثيقة التالية لأجل إلغاءها. يرجى مراجعة التفاصيل أدناه.",
        policy_name_label: "اسم الوثيقة",
        policy_number_label: "رقم الوثيقة",
        plan_label: "البرنامج",
        start_date_label: "تاريخ بدء الوثيقة",
        current_plan_value_label: "قيمة البرنامج الحالي",
        surrender_value_label: "قيمة الإلغاء",
        surrender_value_desc_label: "صافي رسوم الإلغاء",
        reason_input_label: "سبب الإلغاء",
        other_reason_label: "أسباب أخرى",
        submit_button_label: "تقديم طلب الإلغاء",
        iAcceptTheLabel: "اقبل",
        andLabel: "و",
        termsOfLinkLabel: "شروط الاستخدام",
        privacyLinkLabel: "سياسة الخصوصية",
      },
      investmentStrategyFlow: {
        fundsRedirectingFlow: {
          addFundsPage: {
            title: "إعادة توجيه الصناديق",
            description:
              "اختر الصناديق التي ستستثمر فيها أقساطك المستقبلية. ستكون هذه هي استراتيجيتك الاستثمارية الجديدة. ستظل حيازاتك الحالية من الصناديق كما هي.",
          },
          fundsRedirectingPage: {
            title: "إعادة توجيه الصناديق",
            description:
              "اختر الصناديق التي ستستثمر فيها أقساطك المستقبلية. ستكون هذه هي استراتيجيتك الاستثمارية الجديدة. ستظل حيازاتك الحالية من الصناديق كما هي.",
          },
        },
        fundSwitchingFlow: {
          switchFundsPage: {
            title: "تحويل الصناديق",
            description:
              "بيع كل أو بعض حيازات الصناديق الحالية في وثيقتك لشراء صناديق أخرى. كجزء من هذه العملية، يمكنك أيضًا تغيير الصناديق التي ستستثمر فيها أقساطك المستقبلية.",
            label: "اختر الصناديق التي تريد إزالتها",
          },
          addFundsPage: {
            title: "إعادة توجيه الصناديق",
            description:
              "اختر الصناديق التي ستستثمر فيها أقساطك المستقبلية. ستكون هذه هي استراتيجيتك الاستثمارية الجديدة. ستظل حيازاتك الحالية من الصناديق كما هي.",
          },
          fundAllocationPage: {
            title: "تخصيص الصناديق",
            description:
              "بيع كل أو بعض حيازات الصندوق الحالية في وثيقتك لشراء صناديق أخرى.",
          },
        },
      },
    },
    buyOnline: {
      expressShieldFlow: {
        basicInfo: {
          title: "مرحباً بك في إكسبرس شيلد!",
          description: "تفاصيل التأمين الأساسي على الحياة",
          fullName: "الاسم الكامل",
          emailAddress: "البريد الإلكتروني",
          mobileNumber: "رقم الهاتف المحمول",
          dateOfBirth: "تاريخ الميلاد",
          month: "الشهر",
          day: "اليوم",
          year: "السنة",
        },
        otherDetails: {
          title: "مرحباً بك في إكسبرس شيلد!",
          description: "تفاصيل التأمين الأساسي على الحياة",
          gender: "الجنس",
          nationality: "جنسية",
          height: "الطول (سم)",
          weight: "الوزن (كجم)",
        },
        declaration: {
          title: "الاستبيان\\الإقرار",
          description: "أقر وأشهد بموجبه أنه",
          additionalInfo:
            'المعلومات الإضافية إن وجدت (وإذا كانت أي إجابة أعلاه "خاطئة")',
        },
        choosePlan: {
          title: "اختر برنامجك",
          description: "البرنامج السنوي - قابل للتجديد سنويًا",
        },
        beneficiaryInfo: {
          title: "معلومات المستفيد\\الوصي",
          description:
            "الورثة الشرعيون هم المستفيدون الافتراضيون ما لم ينص على خلاف ذلك",
          appointBeneficiary: "هل تريد تعيين مستفيد؟",
          addBeneficiary: "إضافة مستفيد",

          table: {
            name: "الاسم",
            sharePercentage: "نسبة الحصة",
            cpr: "الرقم الشخصي",
            relationship: "العلاقة",
            mobileNumber: "رقم الجوال",
          },
          beneficiarySuccess: "تمت إضافة المستفيد بنجاح",
          beneficiaryFailed: "فشل في إنشاء المستفيد",
          age21: "يجب أن يكون عمر المستفيد 21",
          removeBeneficiary: "إزالة المستفيد",
          somethingWentWrong: "حدث خطأ ما، يرجى المحاولة مرة أخرى.",
        },
        reviewAndConfirm: {
          title: "المراجعة والتأكيد",
          description: "يرجى مراجعة معلوماتك والانتقال إلى صفحة الدفع.",
          participantDetailsTitle: "تفاصيل المشترك",
          participantDetails: {
            name: "الاسم",
            dob: "تاريخ الميلاد",
            age: "العمر",
            idType: {
              label: "نوع الهوية",
              cpr: "CPR",
            },
            idNumber: "رقم الهوية",
            gender: "الجنس",
          },
          shieldTitle: "ملخص الدرع الأساسي السريع",
          shieldSummary: {
            deathAnyCause: "الوفاة لأي سبب",
            repatriationExpense: "تكاليف الإعادة للوطن",
            totalSumInsured: "إجمالي مبلغ التأمين",
            grossContribution: "إجمالي المساهمة",
          },
          planNote: "يرجى ملاحظة أن هذه الخطة ليس لها قيمة خطة أو قيمة استرداد",
          agreement: {
            iDeclare:
              [
                "أصرح بأن الإجابات المقدمة صحيحة وكاملة وفقًا لأفضل معرفتي واعتقادي، وستشكل أساس العقد لطلبي للحصول على تأمين الحياة.",
                "أفهم أن عدم الإفصاح عن أي حقيقة جوهرية قد يؤدي إلى إبطال العقد.",
                "أوافق على قيام الشركة بطلب معلومات من أي ممارس طبي قام بمعالجتي أو من أي شركة تأمين تم تقديم طلب تأمين لها، وأفوض بتقديم هذه المعلومات.",
                "أوافق على إبلاغ الشركة كتابيًا بأي تغيير يطرأ على ظروفي بين تاريخ تقديم هذا الطلب وإصدار عقد الوثيقة.",
                "كما أوافق على إبلاغ الشركة بأي تغيير في الاسم أو تغيير في العنوان، وما إلى ذلك، قد يحدث خلال مدة سريان هذه الوثيقة.",
                "أفهم وأوافق على قيام الشركة بالسعي إلى التحقق المستقل (إذا اعتُبر ذلك ضروريًا) من أي معلومات مقدمة في هذا الاستبيان."
              ],
            declaration: "الإقرار.",
            iCertify:
              "أشهد بموجب هذا بأن المعلومات المذكورة أعلاه صحيحة ودقيقة حسب علمي، وأوافق على التعاون الكامل من خلال تقديم جميع المستندات المطلوبة قبل الدفع. أفهم أن تقديم معلومات غير صحيحة سيؤدي إلى إلغاء وثيقة التأمين الخاصة بي. كما قرأت وأوافق على",
            policySpecification: "مواصفات السياسة",
            product: "لهذا المنتج التأميني.",
            iAccept: "أوافق على",
            generalTandC: "الشروط والأحكام العامة",
          },
        },

        reviewYourApplication: {
          answeredYesTitle: "مراجعة مطلوبة",
          title: "مراجعة طلبك",
          subtitle:
            "نشكرك على تقديم تفاصيلك. بناءً على المعلومات المقدمة، نحتاج إلى إلقاء نظرة فاحصة على طلبك.",
          note: "من أجل ضمان تقديم أفضل خيار تغطية ممكن لك، سيتصل بك أحد وكلائنا المتخصصين قريبًا لمناقشة احتياجاتك المحددة وكيف يمكننا مساعدتك.",
          goHome: "العودة إلى الصفحة الرئيسية",
        },
        labels: {
          name: "الاسم",
          email: "البريد الإلكتروني",
          mobileNumber: "رقم الهاتف المحمول",
          dob: "تاريخ الميلاد",
          nationality: "جنسية",
          gender: "الجنس",
          height: "الطول (سم)",
          weight: "الوزن (كجم)",
        },
      },
    },
    forgot_password_flow: {
      reset_password: {
        title: "نسيت كلمة المرور",
        description: "أدخل التفاصيل الخاصة بك لإعادة تعيين كلمة المرور",
        next: "التالي",
      },
      verify_otp_page: {
        title_desc_mobile: "أدخل الرقم المرسل إلى هاتفك المحمول",
        title_desc_code: "أدخل رمز التحقق المكون من 4 أرقام",
      },
      create_password_page: {
        page_title: "إنشاء كلمة مرور جديدة",
      },
    },
    pageNotFound: {
      title: "٤٠٤ - الصفحة غير موجودة",
      description: "لا يمكننا العثور على الصفحة التي تبحث عنها",
      btnLabel: "الذهاب إلى الصفحة الرئيسية",
    },
    securityPage: {
      security: "الأمان",
      useFaceID: "استخدام بصمة الوجه لتسجيل الدخول",
      autoLockSecurity: "قفل الأمان التلقائي",
      autoLockDescription: "تتطلب بصمة الوجه بعد 10 دقائق من عدم النشاط",
      changePassword: "تغيير كلمة المرور",
      twoFactorAuth: "المصادقة الثنائية",
    },
    essentialCareFlow: {
      basicInfo: {
        title: "التأمين الأساسي الجديد على الرعاية",
        description: "مرحبًا! لنبدأ ببعض المعلومات الأساسية.",
        fullName: "الاسم الكامل",
        emailAddress: "عنوان البريد الإلكتروني",
        mobileNumber: "رقم الهاتف المحمول",
        dateOfBirth: "تاريخ الميلاد",
        month: "الشهر",
        day: "اليوم",
        year: "السنة",
      },

      beneficiaryInfo: {
        title: "تفاصيل بوليصة الرعاية الأساسية",
        description: "اختر لتغطية نفسك أو إضافة أعضاء العائلة",
        addMember: "إضافة عضو عائلة",

        table: {
          name: "الاسم",
          sharePercentage: "نسبة المشاركة",
          cpr: "رقم CPR",
          relationship: "العلاقة",
          mobileNumber: "رقم الهاتف المحمول",
        },
        beneficiarySuccess: "تمت إضافة مستفيد جديد بنجاح",
        beneficiaryFailed: "فشل في إنشاء المستفيد",
        age21: "يجب أن يكون عمر المستفيد 21 عامًا",
        removeBeneficiary: "إزالة",
        somethingWentWrong: "حدث خطأ ما، حاول مرة أخرى",
      },
      reviewAndConfirm: {
        title: "مراجعة وتأكيد",
        description: "يرجى مراجعة معلوماتك والمتابعة إلى الدفع.",
        participantDetailsTitle: "تفاصيل المستفيد",
        participantDetails: {
          name: "الاسم",
          dob: "تاريخ الميلاد",
          cpr: "رقم CPR",
          gender: "الجنس",
          premium: "القسط"

        },

        agreement: {
          iDeclare: [
            "أوافق على أنه لن يتم دفع أي تعويض ولن يتم تقديم أي تغطية بموجب وثيقة التأمين المقترحة لتكاليف طبية ناتجة عن اضطرابات تم الإقرار بها قبل إكمال هذا الطلب ولم يتم الإفصاح عنها للمؤمن عليه في تاريخ تقديم هذا الطلب. إن عدم الإفصاح عن معلومات جوهرية للمؤمن عليه سيؤدي إلى إلغاء وثيقة التأمين المقترحة.",
            "أوافق بموجب هذا، فيما يتعلق بي وبالمعالين (إن وجدوا)، على أنني على دراية بالشروط العامة لهذا التأمين وأقبلها لنفسي ولصالح معالي. أصرح أنا الموقع أدناه بأن جميع المعلومات المذكورة أعلاه بالإضافة إلى جميع التصريحات في الاستبيان الإضافي (المعلومات الشخصية) صحيحة وكاملة.",
            "ستعتبر هذه المعلومات جزءًا لا يتجزأ من وثيقة التأمين."
          ],
        },
        note: "يرجى ملاحظة أن الوثيقة ستصدر باسمك. تخضع هذه الوثيقة لفترة انتظار مدتها 3 أشهر للمطالبات المتعلقة بالحالات الموجودة مسبقًا، والأمراض المزمنة، وطب الأسنان، والحمل.",
      },
      reviewYourApplication: {
        answeredYesTitle: "المراجعة مطلوبة",
        title: "راجع طلبك",
        subtitle:
          "شكرًا لتقديم تفاصيلك. بناءً على المعلومات المقدمة، نحتاج إلى إلقاء نظرة فاحصة على طلبك.",
        note: "لضمان تقديم أفضل خيار تغطية ممكنة، سيتواصل معك أحد وكلائنا المتخصصين قريبًا لمناقشة احتياجاتك المحددة وكيف يمكننا مساعدتك.",
        goHome: "العودة إلى الصفحة الرئيسية",
      },

    },

  },
};
