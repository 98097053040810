import { useEffect, useState } from "react";
import { doc, onSnapshot } from "firebase/firestore";

const useFirestoreDoc = (db, collectionName, docId) => {
    const [data, setData] = useState(true);
    //  useEffect(()=>{
    //     setTimeout(()=>{
    //         setData({
    //             status : "approved",
    //         });
            
    //     },3000)
    //  },[])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        if (!docId) return;

        const docRef = doc(db, collectionName, docId);
        const unsubscribe = onSnapshot(
            docRef,
            (docSnap) => {
                if (docSnap.exists()) {
                    setData(docSnap.data());
                    setLoading(false);
                } else {
                    setError("Document does not exist");
                    setLoading(false);
                }
            },
            (err) => {
                setError(err.message);
                setLoading(false);
            }
        );

        return () => unsubscribe();
    }, [db, collectionName, docId]);

    return { data, loading:loading&&docId, error };
}

export default useFirestoreDoc

