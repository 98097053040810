import React from 'react'
import Back_Btn from "../../../assets/images/back-arrow-left.svg";
import Back_Btndark from "../../../assets/images/greenbackarrow.svg";
import { useNavigate } from 'react-router-dom';
const BackBtnWithLabel = ({label}) => {
  const navigate = useNavigate()
  return (
    <>
      <img src={Back_Btn} alt="Back" className='max767:hidden rtl:rotate-180 '  onClick={() => navigate("/")}/>
      <img src={Back_Btndark} alt="Back" className='max767:visible w-10 h-10 rtl:rotate-180' onClick={() => navigate("/")}/>
      <h5 className="max767:mt-8 max767:mb-2 text-black text-xl not-italic font-medium leading-[120%] ">{label}</h5>
    </>
  );
}

export default BackBtnWithLabel
