import { STEP_NAME_AD_DONS, STEP_NAME_CAR, STEP_NAME_PAY, STEP_NAME_PLAN, STEP_NAME_START, STEP_NAME_UPLOAD } from "../../../hooks/data";

export const headerStepperData = [
    {
        key: STEP_NAME_START,
        title_en: "Start",
        title_ar: "يبدأ",
    },
    {
        key: STEP_NAME_CAR,
        title_en: "Car",
        title_ar: "سيارة",
    },
    {
        key: STEP_NAME_PLAN,
        title_en: "Plan",
        title_ar: "يخطط",
    },
    {
        key: STEP_NAME_AD_DONS,
        title_en: "Add-ons",
        title_ar: "الإضافات",
    },
    {
        key: STEP_NAME_UPLOAD,
        title_en: "Upload",
        title_ar: "رفع",
    },
    {
        key: STEP_NAME_PAY,
        title_en: "Pay",
        title_ar: "يدفع",
    },
];



