import React, { useEffect, useState } from "react";
import {
  CUSTOMER_TYPE,
  POLICY_TYPE,
  REQUIRED_DOCS_INFO,
  POLICY_PLANS,
  YOUR_INFO,
  ACCIDENT_INFO,
  REPAIR_OR_CASH,
  UPLOAD_DOCS,
  REVIEW_AND_CONFIRM,
  CLAIM_CREATED,
  NON_SOLIDARITY_POLICY_PLANS_PAGE,
  PROPERTY_DAMAGE_YOUR_INFO,
  BODILY_INJURY_YOUR_INFO,
} from "./data";
import ReviewAndConfirmPage from "./reviewAndConfirmPage";
import CustomerTypePage from "./CustomerTypePage";
import PolicyTypePage from "./policyTypePage";
import RequiredDocsInfoPage from "./requiredDocsInfoPage";
import PolicyPlansPage from "./policyPlansPage";
import YourInfoPage, { ClaimInfoPage } from "./yourInfoPage";
import RepairOrCashPage from "./repairOrCashPage";
import UploadDocsPage, {
  AccidentDocs,
  DriverLicencesDocs,
  ImpactedPersonCprDocs,
  IncidentInput,
  InsuranceCert,
  JudicialReportDocs,
  OtherDocs,
  OwnershipDocs,
  ProofAddress,
  PropertyAccidentPhotosDocs,
  PropertyOwner,
} from "./uploadDocsPage";
import AccidentInfoPage, { AccidentInfo } from "./accidentInfoPage";
import useFileClaimFlow from "../../../hooks/useFileClaimFlow";
import { BASE_URL } from "../../../api/baseURL";
import { selectNewMotorForm } from "../../../redux/features/newMotorFormSlice";
import { useSelector } from "react-redux";
import CreatedClaimPage from "./createdClaimPage";
import SelectPolicyPlans from "./NonSolidarityUser/selectPolicyPlans";
import PropertyDamageYourInfo from "./NonSolidarityUser/PropertyDamageFlow/propertyDamageYourInfo";
import BodilyInjuryYourInfo from "./NonSolidarityUser/BuildInjuryFlow/bodilyInjuryYourInfo";
import posthog from "posthog-js";

const FileClaimMotorPolicy = () => {
  // For Create Claim API
  const [selectedCustomer, setSelectCustOption] = useState(null);
  const [selectedInsuredType, setSelectInsuredType] = useState(null);
  const [selectedTpType, setSelectTpType] = useState(null);

  const [selectedClaimType, setSelectClaimType] = useState(null);
  const { currentStep } = useFileClaimFlow();
  const [garages, setGarages] = useState([]);
  //const [formData, setFormData] = useState({});
  const claimInfo = useSelector(selectNewMotorForm)?.[ClaimInfoPage];
  const propertyDamageInfo =
    useSelector(selectNewMotorForm)?.[PROPERTY_DAMAGE_YOUR_INFO];

  const AccidentInfoDetails = useSelector(selectNewMotorForm)?.[AccidentInfo];

  const driverDocs = useSelector(selectNewMotorForm)?.[DriverLicencesDocs];
  const accidentDocs = useSelector(selectNewMotorForm)?.[AccidentDocs];
  const ownerShipDocs = useSelector(selectNewMotorForm)?.[OwnershipDocs];
  const incidentInput = useSelector(selectNewMotorForm)?.[IncidentInput];

  const insuranceCert = useSelector(selectNewMotorForm)?.[InsuranceCert];
  const propertyOwner = useSelector(selectNewMotorForm)?.[PropertyOwner];
  const proofAddress = useSelector(selectNewMotorForm)?.[ProofAddress];
  const propertyAccidentPhotosDocs =
    useSelector(selectNewMotorForm)?.[PropertyAccidentPhotosDocs];

  const impactedPersonCprDocs =
    useSelector(selectNewMotorForm)?.[ImpactedPersonCprDocs];
  const judicialReportDocs =
    useSelector(selectNewMotorForm)?.[JudicialReportDocs];
  const otherDocs = useSelector(selectNewMotorForm)?.[OtherDocs];

  const GetGarages = async () => {
    const url = BASE_URL + "/constants/garages";

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set headers for JSON
        }, // Convert data to JSON string
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      //console.log("Success:", result);

      var test = result;
      return result;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const isFeatureEnabled = posthog.isFeatureEnabled("Emergency_services");

  if (isFeatureEnabled) {
    console.log("Feature is enabled!");
    // Render the new feature
  } else {
    console.log("Feature is disabled!");
    // Render the fallback
  }

  useEffect(() => {
    // Call GetGarages and set the state with the resolved result
    const fetchData = async () => {
      const garagesData = await GetGarages();
      setGarages(garagesData); // Set the data once it's resolved
    };

    fetchData(); // Call the async function
  }, []);

  const PageRenderer = () => {
    console.log(selectedInsuredType, "selectedInsuredType");
    switch (currentStep?.name) {
      case CUSTOMER_TYPE:
        return (
          <CustomerTypePage
            selectedOption={selectedCustomer}
            setSelectedOption={setSelectCustOption}
          />
        );
      case POLICY_TYPE:
        return <PolicyTypePage />;
      case REQUIRED_DOCS_INFO:
        return (
          <RequiredDocsInfoPage selectedInsuredType={selectedInsuredType} />
        );
      case POLICY_PLANS:
        return (
          <PolicyPlansPage
            selectedOption={selectedTpType}
            setSelectedOption={setSelectTpType}
          />
        );
      case NON_SOLIDARITY_POLICY_PLANS_PAGE:
        return (
          <SelectPolicyPlans
            selectedOption={selectedInsuredType}
            setSelectedOption={setSelectInsuredType}
          />
        );
      case YOUR_INFO:
        return <YourInfoPage />;
      case PROPERTY_DAMAGE_YOUR_INFO:
        return <PropertyDamageYourInfo />;
      case BODILY_INJURY_YOUR_INFO:
        return <BodilyInjuryYourInfo />;
      case ACCIDENT_INFO:
        return (
          <AccidentInfoPage
            garages={garages}
            selectedInsuredType={selectedInsuredType}
          />
        );
      case REPAIR_OR_CASH:
        return (
          <RepairOrCashPage
            selectedInsuredType={selectedInsuredType}
            selectedOption={selectedClaimType}
            setSelectedOption={setSelectClaimType}
          />
        );
      case UPLOAD_DOCS:
        return <UploadDocsPage selectedInsuredType={selectedInsuredType} />;
      case REVIEW_AND_CONFIRM:
        return (
          <ReviewAndConfirmPage
            selectedCustomer={selectedCustomer}
            selectedInsuredType={selectedInsuredType}
            selectedClaimType={selectedClaimType}
            driverDocs={driverDocs}
            incidentDoc={incidentInput}
            accidentDocs={accidentDocs}
            ownerShipDocs={ownerShipDocs}
            AccidentInfoDetails={AccidentInfoDetails}
            claimInfo={claimInfo}
            insuranceCert={insuranceCert}
            propertyOwner={propertyOwner}
            proofAddress={proofAddress}
            impactedPersonCprDocs={impactedPersonCprDocs}
            judicialReportDocs={judicialReportDocs}
            otherDocs={otherDocs}
            propertyAccidentPhotosDocs={propertyAccidentPhotosDocs}
            selectedTpType={selectedTpType}
            propertyDamageInfo={propertyDamageInfo}
          />
        );
      case CLAIM_CREATED:
        return <CreatedClaimPage />;
      default:
        return null;
    }
  };
  return (
    <div className="mainCarDetailsModal fileAClaim pt-[5%] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-[95vh] ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll ">
      <PageRenderer />
    </div>
  );
};

export default FileClaimMotorPolicy;
