// hooks/useTokenRefresh.js
import { useEffect } from "react";
import {jwtDecode} from "jwt-decode";
import { LOCAL_STORAGE_NAME } from "../redux/axiosInstance";
import { clearLocalStorageForUnauthorized } from "../utils";


export const isTokenExpired = (jwtExp) => {
    const now = Math.floor(Date.now() / 1000); 
    return now > jwtExp;
  };

const useTokenRefresh = () => {
    const checkTokenAndRefresh = async () => {
        const token =JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME))?.access_token || "";
        if(!token) return;
        const docodeDetails = jwtDecode(token);
        let isExpired = isTokenExpired(docodeDetails.exp)
        if(isExpired){
          //  console.log("token expired");
          //  // here we need to call refresh token api
          clearLocalStorageForUnauthorized();
        }
      };
  useEffect(() => {
    checkTokenAndRefresh();
  }, []); 
};

export default useTokenRefresh;
