/* Adjust sum insured */

import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormTabs from "../../../../components/common/tabs/formTabs";
import { getIncreaseTabOptions } from "../../newMotorPolicy/carDetails/carInfo/data";
import Input from "../../../../components/common/Input/Input";
import useValidation from "../../../../hooks/useValidation";
import AdjustSumInsuredMotorLayout from "../adjustSumInsuredMotorLayout";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import useAdjustSumInsuredFlow from "../../../../hooks/useAdjustSumInsured";
import useMotorForm from "../../../../hooks/useMotorFormData";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import { useDispatch, useSelector } from "react-redux";
import {
  getPolicyViewLoading,
  getPolicyViewResponse,
  policyView,
} from "../../../../redux/features/policyDetailsSlice";
import { useParams } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getQuoteWithRefund,
  getQuoteWithRefundLoading,
  getQuoteWithRefundResponse,
} from "../../../../redux/features/endorsements/cancellationSlice";
import { toast } from "react-toastify";
import useScreenResolution from "../../../../hooks/useScreenResolution";
const ADJUST_SUM_INSURED_CAR_VALUE = "ADJUST_SUM_INSURED_CAR_VALUE";
const AdjustSumValue = () => {
  const { mobile } = useScreenResolution();
  /* Setting up dispatch to call redux */
  const dispatch = useDispatch();

  /* Setting up variables with appropriate requests to send and response to receive, reading from redux/functions */
  const { policy_id } = useParams();
  const [PolicyNumber, setPolicyNumber] = useState(policy_id);
  const [Channel, setChannel] = useState("online.solidarity");

  const polReq = {
    PolicyNumber,
    Channel,
  };

  // Reading param from url, incase refresh to call the policy view again
  const policyViewResponseDetails = useSelector(getPolicyViewResponse);
  const isPolicyViewLoading = useSelector(getPolicyViewLoading);
  const quoteWithRefundResponse = useSelector(getQuoteWithRefundResponse);
  const quoteWithRefundLoading = useSelector(getQuoteWithRefundLoading);

  console.log("Policy number is: ", PolicyNumber);
  console.log("Policy View Response is: ", policyViewResponseDetails);
  console.log(
    "Policy view response plan name from outside: ",
    policyViewResponseDetails?.PolicyHeader?.PlanName
  );

  //  const increaseTabOptions = getIncreaseTabOptions(null, policyViewResponseDetails?.PolicyHeader?.SumInsured);
  const [increaseTabOptions, setIncreaseTabOptions] = useState(() => {
    // Check if policyViewResponseDetails is available at the start
    return policyViewResponseDetails?.PolicyHeader?.SumInsured
      ? getIncreaseTabOptions(
          null,
          policyViewResponseDetails.PolicyHeader.SumInsured
        )
      : []; // Return an empty array if SumInsured is not available
  });
  console.log("increase tab options is: ", increaseTabOptions);

  /* Functions and Variables that are updated with functions/state */
  const { handleNextSubstep } = useAdjustSumInsuredFlow();

  const [selectedTab, setSelectedTab] = useState({});

  const translate = useLanguageTranslate();
  const { errors, validateField } = useValidation();

  const [formData, setFormData] = useState({
    amount: policyViewResponseDetails?.PolicyHeader?.SumInsured,
    increase_tab_type: null,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onTabClick = (value, name, tab) => {
    setFormData({ ...formData, [name]: value });

    try {
      console.log(
        "Going to next, sum insured should be: ",
        selectedTab,
        selectedTab.amount
      );

      // Prepare the request payload
      const getQuoteWithRefundRequest = {
        PolicyNumber: policyViewResponseDetails?.PolicyHeader?.PolicyNumber,
        Reason:
          "Sum insured to be changed by cancelling previous policy and issuing new quote.",
        NewPlan: "",
        NewSumInsured: tab.amount,
      };

      // Dispatching the getQuoteWithRefund action and awaiting the result
      const quoteWithRefundAction = dispatch(
        getQuoteWithRefund(getQuoteWithRefundRequest)
      );
      const result = unwrapResult(quoteWithRefundAction); // Waits until dispatch is completed
    } catch (error) {
      console.error("Error during quote refund process: ", error);
      toast.error("Error while getting quote with refund: " + error.message);
    }
  };
  const { t } = useTranslation();
  const { saveMotorDataInRedux } = useMotorForm(
    ADJUST_SUM_INSURED_CAR_VALUE,
    setFormData
  );

  useEffect(() => {
    if (PolicyNumber) {
      // Calling API /Policy/View
      if (policyViewResponseDetails == null) {
        // Calling API /policy/view
        dispatch(policyView(polReq));
      } else if (
        policyViewResponseDetails?.PolicyHeader?.PolicyNumber != PolicyNumber
      ) {
        // Calling API /policy/view
        dispatch(policyView(polReq));
      }
    }
  }, [policy_id]);

  useEffect(() => {
    // Set current plan
    if (policyViewResponseDetails?.PolicyHeader?.SumInsured) {
      setFormData((prevData) => ({
        ...prevData,
        amount: policyViewResponseDetails.PolicyHeader.SumInsured,
      }));

      const updatedIncreaseTabOptions = getIncreaseTabOptions(
        null,
        policyViewResponseDetails.PolicyHeader.SumInsured
      );
      setIncreaseTabOptions(updatedIncreaseTabOptions);
    }
    // console.log('Cancellation status: ', cancellationResponse);
  }, [policyViewResponseDetails]);

  /* Next */
  const continueClickHanlder = async () => {
    saveMotorDataInRedux(formData);
    handleNextSubstep();
  };

  return (
    <AdjustSumInsuredMotorLayout
      show_summary
      is_hide_back_btn
      btn_onClick={continueClickHanlder}
      show_close_btn={mobile}
    >
      <div className="adjustsumvalue p-4 w-[632px] max-w-full mx-auto">
        <TitleDescription
          title={t("motorInsurance.adjustSumInsuredFlow.adjustSumValue.title")}
          description={t(
            "motorInsurance.adjustSumInsuredFlow.adjustSumValue.description"
          )}
        />
        <div className="amountInput max-w-full w-[500px] mx-auto">
          <Input
            label={t(
              "motorInsurance.adjustSumInsuredFlow.adjustSumValue.car_value_input_label"
            )}
            className="w-full flex justify-center border border-solid border-[#B0BABF] items-center gap-2.5 self-stretch pl-12 px-4 py-3 bg-[#E5E9EB] text-[#6E7C87] text-lg not-italic font-medium leading-[26px] tracking-[-0.18px] rounded-[10px] border-solid border-[#B0BABF] max767:mb-6"
            type="number"
            name="amount"
            error={errors.amount}
            setFormData={setFormData}
            value={formData.amount}
            handleChange={handleChange}
            startAdornment={
              <p className="text-[#6E7C87] text-lg not-italic font-medium leading-[26px] tracking-[-0.18px]">
                {translate("BHD", "د.ب")}
              </p>
            }
          />
        </div>
        <div className="increse-box-tab flex px-4 py-6 rounded-[0px_0px_10px_10px] adjustsumvalue max767:px-0 max767:py-0">
          <FormTabs
            tabs={increaseTabOptions}
            onTabClick={
              quoteWithRefundLoading
                ? () => {}
                : (type, tab) => {
                    setSelectedTab(tab);
                    onTabClick(type, "increase_tab_type", tab);
                  }
            }
            activeTab={formData.increase_tab_type}
          />
        </div>
        {/* <p className="text-center">
          <span
            className="call text-[#00384D] text-center text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline"
            onClick={() => alert("Call")}
          >
            {t(
              "motorInsurance.adjustSumInsuredFlow.adjustSumValue.request_call_label"
            )}
          </span>{" "}
          {t(
            "motorInsurance.adjustSumInsuredFlow.adjustSumValue.increase_over_30"
          )}
        </p> */}
      </div>
    </AdjustSumInsuredMotorLayout>
  );
};

export default AdjustSumValue;
