import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { EXPRESS_SHEILD_BASIC_INFO } from '../../pages/buyOnline/expressShield/details/basicInfo';
import { EXPRESS_SHEILD_OTHER_DETAILS } from '../../pages/buyOnline/expressShield/details/otherDetails';
import { EXPRESS_SHEILD_DECLERATION } from '../../pages/buyOnline/expressShield/plan/declaration';
import { BUY_ONLINE_PLAN_SELECTED } from '../../pages/buyOnline/expressShield/plan/choosePlan';
import { selectNewLifeForm } from '../../redux/features/newLifeFormSlice';
import { selectProfileData } from '../../redux/features/profileSlice';
import { expressSheildSave, selectExpressShieldLoader } from '../../redux/features/expressSheild/expressSheildSlice';
import { EXPRESS_SHEILD_BENEFICIARY_DETAILS } from '../../pages/buyOnline/expressShield/plan/beneficiaryDetails';

const useExpressSheildSave = () => {
    const dispatch = useDispatch()
    const isLoading = useSelector(selectExpressShieldLoader)

    const basicDetails = useSelector(selectNewLifeForm)?.[EXPRESS_SHEILD_BASIC_INFO];
    const otherDetails = useSelector(selectNewLifeForm)?.[EXPRESS_SHEILD_OTHER_DETAILS];
    const declerationDetails = useSelector(selectNewLifeForm)?.[EXPRESS_SHEILD_DECLERATION];
    const selectedPlan = useSelector(selectNewLifeForm)?.[BUY_ONLINE_PLAN_SELECTED]
    const beneficiaries = useSelector(selectNewLifeForm)?.[EXPRESS_SHEILD_BENEFICIARY_DETAILS]

    const profileData = useSelector(selectProfileData)
    const setBeneficiaryDetails = () => {
        if (!beneficiaries?.length) {
            return [{
                BenificiaryName: "BE00000027", //default
                RelationshipToInsured: "LEGALHEIR",
                TypeOfBenificiary: "",
                Percentage: "100"
            }]
        }
        else {
            let formatedBenif = beneficiaries?.map(each => {
                let data = each?.original_data;
                return {
                    BenificiaryName: data?.response?.PartyCode, //default
                    RelationshipToInsured: data?.relationship,
                    TypeOfBenificiary: "P",
                    Percentage: data?.share_percentage
                }
            })
            return formatedBenif;
        }
    }
    const expressSheildSaveHandler = async (params) => {
        const payload = {
            PartyCode: profileData?.Code,
            LifeAndPolicyHolSame: true,
            CPR: profileData?.CPR,
            ConsumeAlcohol: false,
            CovergeAttachedTo: "",
            DateOfBirth: `${basicDetails?.day}/${basicDetails?.month}/${basicDetails?.year}`,
            Gender: otherDetails?.gender,
            Height: otherDetails?.height,
            Plan: selectedPlan?.mapping_key,
            OccupationNature: "NH",
            Pregnant: false,
            Smoker: false,
            SmokesPerDay: "",
            Weight: otherDetails?.weight,
            Covers: [
                {
                    CoverCode: "DAC",
                    BenificiaryDetails: setBeneficiaryDetails()
                },
                {
                    CoverCode: "REPATBEN"
                }
            ],
            ...params

        };
        Object.keys(declerationDetails?.answers)?.map(key => {
            let value = declerationDetails?.answers[key];
            payload[key] = (value =="Yes");
        })
        let response = await dispatch(expressSheildSave(payload))
        return response;
    }
    return (
        { expressSheildSaveHandler, isLoading }
    )
}

export default useExpressSheildSave