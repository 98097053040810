/* :Page:MotorRenewal: Current Plan page */

import React from 'react'
import { useTranslation } from 'react-i18next'
import useLanguageTranslate from '../../../../hooks/useLanguageTranslate'

const CurrentPlanDetailCard = ({ plan }) => {
    const {t}=useTranslation()
    const translate=useLanguageTranslate()
    return (
        <div className='bg-[#F6F8F9] rounded-2xl p-5 mt-6 mb-6'>
            <p className='text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px] mb-4'>{t("motorInsurance.renewalPolicyFlow.currentPlanDetails.plan_details")}</p>
            <div className="flex gap-3 align-middle items-center">
                <img src={plan?.imgSrc} alt="" />
                <p className="plan-name text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px]">
                    {translate(plan.name,plan.name_ar)}
                </p>
            </div>
        </div>
    )
}

export default CurrentPlanDetailCard