import slideImg from "../../assets/images/slide-1.svg";
import slide1EnImg from "../../assets/images/En1.png";
import slide1ArImg from "../../assets/images/Ar1.png";
import slide2EnImg from "../../assets/images/En2.png";
import slide2ArImg from "../../assets/images/Ar2.png";
import slide3EnImg from "../../assets/images/En3.png";
import slide3ArImg from "../../assets/images/Ar3.png";
import slide4EnImg from "../../assets/images/En4.png";
import slide4ArImg from "../../assets/images/Ar4.png";

export const slide1Image = {
  srcEn: slide1EnImg,
  srcAr:slide1ArImg,
  altEn: "File Claims",
  altAr:""
};

export const slide2Image = {
    srcEn: slide2EnImg,
    srcAr:slide2ArImg,
   altEn: "File Claims",
  altAr:""

  };

  export const slide3Image = {
    srcEn: slide3EnImg,
    srcAr:slide3ArImg,
   altEn: "File Claims",
  altAr:""

  };

  export const slide4Image = {
    srcEn: slide4EnImg,
    srcAr:slide4ArImg,
   altEn: "File Claims",
  altAr:""
  };