import { useState } from "react";
import TelInput from "../../../components/common/telInput/telInput";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import Input from "../../../components/common/Input/Input";
import Button from "../../../components/common/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  setForgotPasswordDetails,
  forgotPassword,
  selectIsLoading,
} from "../../../redux/features/forgotPasswordFlow/forgotPasswordSlice";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { sendOtp } from "../../../redux/features/sendOtpSlice";
import Loader from "../../../components/common/Loader/Loader";
import useChangeMobileNumberFlow from "../../../hooks/useChangeMobileNumberFlow";
import RenewMotorLayout from "../../motorInsurancePages/renewalMotorPolicy/renewMotorLayout";
import useMotorForm from "../../../hooks/useMotorFormData";
import useValidation from "../../../hooks/useValidation";
import useScreenResolution from "../../../hooks/useScreenResolution";
export const CHANGE_MOBILE_FLOW_ACCOUNT_PASSWORD =
  "CHANGE_MOBILE_FLOW_ACCOUNT_PASSWORD";
function AccountPasswordPage({}) {
  const { handleNextSubstep } = useChangeMobileNumberFlow();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const isLoading = useSelector(selectIsLoading);
  const { errors, validateFields } = useValidation();
  const screenResolution = useScreenResolution();
  const [formData, setFormData] = useState({
    password: "",
  });

  const { saveMotorDataInRedux } = useMotorForm(
    CHANGE_MOBILE_FLOW_ACCOUNT_PASSWORD,
    setFormData
  );
  const handleChange = (e) => {
    const { name, value } = e.target;
    let update = { ...formData, [name]: value };
    setFormData(update);
    validateForm(update);

  };

  const validateForm = (data) => {

    let isValid = validateFields(
      [
        {
          name: "password",
          validations: ["password"],
        },
      ],
      data ? data :formData
    );
    return isValid;
  };

  const NextHandler = async () => {
    if (validateForm()) {
      saveMotorDataInRedux({
        accountPassword: formData.password,
      });
      handleNextSubstep();
    }
  };

  return (
    <RenewMotorLayout
      is_bottom_sticky_layout={screenResolution.mobile}
      is_hide_back_btn
      btn_onClick={NextHandler}
      btn_disabled={!formData.password || errors.password}
      show_close_btn
    >
      {isLoading && <Loader className="" />}
      <div className="mx-auto bg-white max767:p-5 w-[500px] max-w-[100%] mt-20 max767:mt-0">
        <TitleDescription className="max767:mt-0"
          title={translate("Account Password","كلمة مرور الحساب")}
          description={translate("Enter your account password","أدخل كلمة المرور ")}
          hide_description={screenResolution.mobile}
        />

        <Input
          label={translate("Password","كلمة المرور")}
          onChange={handleChange}
          type="password"
          handleChange={handleChange}
          name="password"
          value={formData.password}
          data-testid="password"
          error={errors.password}
        />

        {!screenResolution.mobile && (
          <Button
            type="submit"
            className="primary-button"
            onSubmit={NextHandler}
            label={translate("Next","التالي")}
            disabled={!formData.password || errors.password}
          />
        )}
      </div>
    </RenewMotorLayout>
  );
}

export default AccountPasswordPage;
