import React from "react";
import Button from "../../../../components/common/Button/Button";
import { useTranslation } from "react-i18next";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";

const UploadSuccess = ({ nexthandler }) => {
  const {t}=useTranslation()
  const translate=useLanguageTranslate()
  return (
    <div className="max-w-lg mx-auto bg-white rounded-md mt-10 mb-8 text-center  max767:p-4 max767:mt-32 ipad:mt-44">
      <div className="bg-gray-100 p-6 rounded-lg mb-4">
        <div className="flex justify-around mb-4">
          <div className="bg-green-500 text-white p-3 rounded-full">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        </div>
        <h2 className="text-[#1A2024] text-center text-2xl not-italic font-normal leading-[34px] tracking-[-0.24px] mb-2">
          {/* {translate("Congratulations on Successfully Uploading Your Photos!","أهنئك على تحميل صورك بنجاح!")} */}
          {t("motorInsurance.uploadSuccess.upload_successful")}

        </h2>
        <p className="text-gray-700 mb-6 text-left">
          {/* {translate("You have successfully uploaded photos of your car from all required angles. These images are crucial for assessing the current state of your vehicle as part of your insurance application.","لقد قمت بنجاح بتحميل صور لسيارتك من جميع الزوايا المطلوبة. هذه الصور ضرورية لتقييم الحالة الحالية لسيارتك كجزء من طلب التأمين الخاص بك.")} */}
          {t("motorInsurance.uploadSuccess.upload_photos")}

        </p>
      </div>
    </div>
  );
};

export default UploadSuccess;
