import React, { useState } from "react";
import ExpressShieldPlanLayout from "../expressShieldPlanLayout";
import PlanCard from "../../../motorInsurancePages/newMotorPolicy/planPages/planCard";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import { selectPlansPageCms } from "../../../../redux/features/buyOnlinePlans/expressShieldFlowStrapiCmsSlice";
import { useSelector } from "react-redux";
import { STRAPI_BASE_URL } from "../../../../api/baseURL";
import SignInModal from "../../../motorInsurancePages/newMotorPolicy/planPages/signInModal";
import { selectLoginTokenInfo } from "../../../../redux/features/loginRegisterSlice";
import { toast } from "react-toastify";
import useUpdateLead from "../../../../hooks/BuyOnlineExpressShield/useUpdateLead";
import { LEAD_INFO } from "../details/data";
import useExpressSheildSave from "../../../../hooks/BuyOnlineExpressShield/useExpressSheildSave";
import useLifeFormData from "../../../../hooks/useLifeFormData";
import { selectExpressShieldData, selectExpressShieldWholeData } from "../../../../redux/features/expressSheild/expressSheildSlice";
export const BUY_ONLINE_PLAN_SELECTED = "BUY_ONLINE_PLAN_SELECTED";
const ChoosePlan = ({ onSuccess }) => {
  const translate = useLanguageTranslate();
  const [selectedPlan, setSelectedPlan] = useState("");
  const [showSignIn, setShowSignIn] = useState(false);
  const { updateLeadHandler, leadLoader } = useUpdateLead()
  const { expressSheildSaveHandler, isLoading } = useExpressSheildSave()


  const { saveLifeDataInRedux } = useLifeFormData(
    BUY_ONLINE_PLAN_SELECTED,
    setSelectedPlan
  );
  const planOnClickHandler = (plan) => {
    let payload = { Plan: plan?.mapping_key }
    getQuote(payload)
    setSelectedPlan(plan);
    saveLifeDataInRedux(plan);
  };

  const cmsData = useSelector(selectPlansPageCms);
  const loginTokenInfo = useSelector(selectLoginTokenInfo);
  const togglePopupClickHandler = () => {
    setShowSignIn((state) => !state);
  };

  const getQuote = async (payload) => {
    if (!loginTokenInfo) {
      togglePopupClickHandler();
      return;
    } else {


      updateLeadHandler({
        Info: LEAD_INFO.choosePlan,
      })
      let response = await expressSheildSaveHandler(payload)
      if (response?.type =="expressSheildSave/fulfilled") {
        
      }
      else {
        toast.error("something went wrong try again")
      }

    }
  };
  const continueClickHandler = () => {
    onSuccess();
  }

  const expressSheildData = useSelector(selectExpressShieldData);
  const showSummaryHandler = () =>{

  }

  const wholeExpressShieldWhole = useSelector(selectExpressShieldWholeData);

  return (
    <ExpressShieldPlanLayout
      is_bottom_sticky_layout
      btn_onClick={continueClickHandler}
      btn_disabled={!selectedPlan||wholeExpressShieldWhole?.error}
      isLoading={isLoading}
      show_summary={expressSheildData && selectedPlan}
      totalAmount={expressSheildData?.TotalPremium}
      showSummaryHandler={showSummaryHandler}
    >
      <TitleDescription
        title={translate(
          cmsData?.headings_content?.title_en,
          cmsData?.headings_content?.title_ar
        )}
        description={translate(
          cmsData?.headings_content?.description_en,
          cmsData?.headings_content?.description_ar
        )}
      />
      <div className="max767:w-[100%] max767:overflow-x-auto">
        <div className={`${isLoading && " opacity-70 "} transition-all flex w-[812px] justify-center items-start content-start gap-4 flex-wrap mx-auto mb-4 max767:w-[1620px] max767:pl-0 max767:pr-0 max767:py-0 ipad:w-[536px] ipad:justify-end ipad:items-center ipad:content-center ipad:flex-wrap max767:flex-nowrap `}>
          {cmsData?.plans?.map((plan, i) => (
            <PlanCard
              key={i}
              planOnClickHandler={isLoading ? () => { } : planOnClickHandler}
              plan={{
                imgSrc:
                  `${STRAPI_BASE_URL}${plan?.image?.data?.attributes?.url}` || "",
                name: plan?.name_en,
                name_ar: plan?.name_ar,
                price: plan?.price_en || 0,
                price_ar: plan?.price_ar || 0,
                amount: null,
                features: plan?.benefits?.map((each) => {
                  return {
                    en: each.title_en || "",
                    ar: each.title_ar || "",
                  };
                }),
                mapping_key: plan?.mapping_key,
                isRecommended: false,
                death_any_cause: plan?.death_any_cause,
                repatriation_expense: plan?.repatriation_expense,
                gross_contribution: plan?.gross_contribution
              }}
              selectedPlan={selectedPlan}
            />
          ))}
        </div>
      </div>
      <SignInModal
        show={showSignIn}
        setShow={setShowSignIn}
        cbSuccess={getQuote}
      />
    </ExpressShieldPlanLayout>
  );
};
export default ChoosePlan;
