import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Axios from "../axiosInstance";
import { policyApiEndpoints } from '../../api';

export const policyView = createAsyncThunk(
    "PolicyView",
    async (payload, {rejectWithValue}) => {
      try {
        console.log("Calling policy with payload: ", payload)
              const response = await Axios.post(`${policyApiEndpoints.policyView}`, payload);
              // console.log("Policy View response is: ", response.data)
              return response.data;
      } catch (err) {
        return rejectWithValue(err.response.data);
      }
    }
  );

  const initialState = {
    PolicyDetails: null,
    status: 'idle', // idle | loading | succeeded | failed
    isLoading: false,
    error: null
  }

  
  const policyDetailsSlice = createSlice({
    name: 'PolicyDetails',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(policyView.pending, (state) =>{
            state.status = 'loading';
            state.isLoading = true;
        })
        .addCase(policyView.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.isLoading = false;
            state.PolicyDetails = action.payload;
        })
        .addCase(policyView.rejected, (state, action) =>{
            state.status = 'failed';
            state.isLoading = false;
            state.error = action.error.message;
        })
    }
  });

  export const getPolicyViewResponse = (store) => store.policyDetails?.PolicyDetails;
  export const getPolicyViewHeaderResponse = (store) => store.policyDetails?.PolicyDetails?.PolicyHeader;
  export const getPolicyViewHeaderPartyResponse = (store) => store.policyDetails?.PolicyDetails?.PolicyHeader?.Party;
  export const getPolicyViewLoading = (store) => store.policyDetails?.isLoading;

  export default policyDetailsSlice.reducer;