import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import {  motorApiEndpoints } from "../../../api";
import { toast } from "react-toastify";

const initialState = {
  QuoteDetails: [],
  CoverDetails: [],
  PolicyView: {},
  isLoading: false,
  isCoversLoading: false,
  success: null,
  error: null,
};


export const getQuote = createAsyncThunk(
    "getQuote",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling get quote premium...")
            const response = await Axios.post(`${motorApiEndpoints.getQuote}`, payload);
            console.log("Get quote response is: ", response.data)

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const getCoverPrices = createAsyncThunk(
    "getCoverPrices",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling cover prices with payload: ", payload)
            const response = await Axios.post(`${motorApiEndpoints.coverPrices}`, payload);
            console.log("Cover prices response is: ", response.data)

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const policyView = createAsyncThunk(
  "PolicyView",
  async (payload, {rejectWithValue}) => {
    try {
      console.log("Calling policy with payload: ", payload)
            const response = await Axios.post(`${motorApiEndpoints.policyView}`, payload);
            console.log("Policy View response is: ", response.data)
            return response.data;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getQuoteSlice = createSlice({
  name: "Get Quote Slice",
  initialState,
  reducers: {
    clearGetQuoteError: (state) => {
      state.error = null;
    },
    clearGetQuoteState: () => initialState  // This resets the state to its initial value
  },
  extraReducers: (builder) => {
    // Get Quote API
    builder.addCase(getQuote.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(getQuote.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
      state.QuoteDetails = action?.payload;
    });
    builder.addCase(getQuote.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.ErrorMessageEn;
      state.success = false;
      console.log("Get quote error: ", action.payload.ErrorMessageEn )
      toast.error("Get quote errors: " + action.payload.ErrorMessageEn);
    });
    // Get Cover Prices
    builder.addCase(getCoverPrices.pending, (state, action) => {
      console.log('cover prices is pending....');
      if (!state.data) {
        state.isCoversLoading = true;
      }
    });
    builder.addCase(getCoverPrices.fulfilled, (state, action) => {
      state.isCoversLoading = false;
      state.success = true;
      state.error = null;
      state.CoverDetails = action?.payload;
    });
    builder.addCase(getCoverPrices.rejected, (state, action) => {
      state.isCoversLoading = false;
      state.success = false;
      state.error = action.error?.message;
    });

    // Policy View
    builder.addCase(policyView.pending, (state, action) => {
      console.log('cover prices is pending....');
      if (!state.data) {
        state.isCoversLoading = true;
      }
    });
    builder.addCase(policyView.fulfilled, (state, action) => {
      state.isCoversLoading = false;
      state.success = true;
      state.error = null;
      state.PolicyView = action?.payload;
    });
    builder.addCase(policyView.rejected, (state, action) => {
      state.isCoversLoading = false;
      state.success = false;
      state.error = action.error?.message;
    });
  },
});

// SELECTORS
// Get quote
export const getQuoteResponse = (store) => store?.getQuote.QuoteDetails;
export const getQuoteCoversResponse = (store) => store?.getQuote.CoverDetails;
export const getQuoteLoading = (store) => store?.getQuote.isLoading;
export const getQuoteError = (store) => store?.getQuote.error;
export const getQuoteSuccess = (store) => store?.getQuote.success;

// Get cover prices

export const getCoverPricesLoading = (store) => store?.getQuote.isCoversLoading;

// Policy View
export const PolicyViewResponse = (store) => store?.getQuote.PolicyView;
export const PolicyViewLoading = (store) => store?.getQuote.isLoading;


// export const getSumInsuredLoader = (store) => store?.getSumInsured.isLoading;


// ACTIONS TO DISPATCH
export const { clearGetQuoteError, clearGetQuoteState } = getQuoteSlice.actions;

export default getQuoteSlice.reducer;
