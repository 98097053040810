import React from "react";
import Button from "../../components/common/Button/Button";

const MainCard = ({
  title,
  subtitle,
  primary_label,
  secondary_label,
  handlePrimaryClick,
  handleSecondaryClick,
}) => {
  return (
    <div className="w-full p-4 absolute text-center bg-[linear-gradient(180deg,rgba(255,255,255,0.00)_0%,#FFF_13.22%)] pt-[60px] bottom-[50px]">
      <h1 className="text-black text-center text-base not-italic font-medium leading-[120%] mb-2">{title}</h1>
      <p className="text-black text-center text-2xl not-italic font-extrabold leading-[120%] tracking-[-0.24px] mb-10">{subtitle}</p>

      <div className="flex flex-col space-y-4 w-full max-w-sm">
        <Button onSubmit={handlePrimaryClick} label={primary_label} className="flex  max-w-[100%] h-14 flex-col items-center gap-2.5 bg-[#55C6EF] p-4 rounded-[10px] text-lg not-italic font-semibold" />

        <Button onSubmit={handleSecondaryClick} label={secondary_label} className="text-lg not-italic font-semibold flex flex-col items-center gap-2.5 self-stretch border bg-white p-4 rounded-[10px] border-solid border-[#DDE2E4]"/>
      </div>
    </div>
  );
};

export default MainCard;
