import React, { useEffect, useState } from "react";
import Input from "../../components/common/Input/Input";
import Button from "../../components/common/Button/Button";
import { useTranslation } from "react-i18next";
import Axios from "../../redux/axiosInstance";
import { changePasswordEndpoints } from "../../api";
import { toast } from "react-toastify";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import Loader from "../../components/common/Loader/Loader";
const ChangePassword = () => {
  const [formData, setFormData] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    // setErrors({})

    if (name == "newPassword") {
      const minLength = 8;
      const hasUppercase = /[A-Z]/.test(value);
      const hasLowercase = /[a-z]/.test(value);
      const hasNumber = /[0-9]/.test(value);
      const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value);

      if (
        value.length < minLength ||
        !hasUppercase ||
        !hasLowercase ||
        !hasNumber ||
        !hasSymbol
      ) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          newPassword: translate(
            "Password should be at least 8 characters long and should contain one uppercase letter, one lowercase letter, one symbol, and one number.",
            "يجب أن تكون كلمة المرور مكونة من 8 أحرف على الأقل وتحتوي على حرف كبير واحد، وحرف صغير واحد، ورمز واحد، ورقم واحد."
          ),
        }));
      }
    }

    if (name == "confirmNewPassword") {
      if (value != formData.newPassword) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmNewPassword: translate(
            "Passwords do not match",
            "كلمتا المرور غير متطابقتين."
          ),
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          confirmNewPassword: "",
        }));
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};

    const minLength = 8;
    const hasUppercase = /[A-Z]/.test(formData?.newPassword);
    const hasLowercase = /[a-z]/.test(formData?.newPassword);
    const hasNumber = /[0-9]/.test(formData?.newPassword);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(formData?.newPassword);

    if (!formData?.currentPassword) {
      newErrors.currentPassword = t("validation.required");
    }
    if (!formData?.newPassword) {
      newErrors.newPassword = t("validation.required");
    } else if (
      formData?.newPassword.length < minLength ||
      !hasUppercase ||
      !hasLowercase ||
      !hasNumber ||
      !hasSymbol
    ) {
      newErrors.newPassword = translate(
        "Password should be at least 8 characters long and should contain one uppercase letter, one lowercase letter, one symbol, and one number.",
        "يجب أن تكون كلمة المرور مكونة من 8 أحرف على الأقل وتحتوي على حرف كبير واحد، وحرف صغير واحد، ورمز واحد، ورقم واحد."
      );
    }
    if (!formData?.confirmNewPassword) {
      newErrors.confirmNewPassword = t("validation.required");
    }
    if (
      formData?.newPassword &&
      formData?.newPassword != formData?.confirmNewPassword
    ) {
      newErrors.confirmNewPassword = translate(
        "Passwords do not match",
        "كلمتا المرور غير متطابقتين."
      );
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length == 0;
  };

  const updatePassApiHandler = async () => {
    const { currentPassword, newPassword } = formData;
    const payload = {
      Password: currentPassword,
      NewPassword: newPassword,
      Channel: "channel",
    };
    try {
      setIsLoading(true);
      const res = await Axios.post(
        changePasswordEndpoints?.updatepassword,
        payload
      );
      setIsLoading(false);
      if (res?.status == 200) {
        toast.success(
          translate(
            "Password updated successfully",
            "تم تحديث كلمة المرور بنجاح"
          )
        );
      } else {
        toast.error(translate(res?.ErrorMessageEn, res?.ErrorMessageAr));
      }
    } catch (err) {
      setIsLoading(false);
      let response = err?.response?.data;
      toast.error(
        translate(
          response?.ErrorMessageEn || "Something went wrong try again",
          response?.ErrorMessageAr
        )
      );
    }
  };

  const changePassword = async () => {
    const { currentPassword } = formData;
    if (validateForm()) {
      try {
        setIsLoading(true);
        const response = await Axios.post(
          changePasswordEndpoints?.confirmpassword,
          { Password: currentPassword }
        );
        setIsLoading(false);
        if (response?.status == 200) {
          updatePassApiHandler();
        } else {
          toast.error(
            translate(response?.ErrorMessageEn, response?.ErrorMessageAr)
          );
        }
      } catch (err) {
        setIsLoading(false);
        let response = err?.response?.data;
        toast.error(
          translate(
            response?.ErrorMessageEn || "Something went wrong try again",
            response?.ErrorMessageAr
          )
        );
      }
    }
  };

  return (
    <div>
      {isLoading && <Loader className="w-10 h-10 fixed left-[50%] top-[50%]" />}
      <div className="yourProfile mb-4">
        <div className="inputGrid px-0 py-6 flex flex-col md:flex-row md:space-x-4">
          <Input
            label={t("profileSettings.current_password")}
            type="password"
            name="currentPassword"
            value={formData?.currentPassword}
            setFormData={setFormData}
            handleChange={handleChange}
            error={errors?.currentPassword}
          />
        </div>
        <div className="inputGrid px-0 flex flex-col md:flex-row md:space-x-4 rtl:gap-3 rtl:mb-4">
          <Input
            label={t("profileSettings.new_password")}
            type="password"
            name="newPassword"
            value={formData?.newPassword}
            setFormData={setFormData}
            handleChange={handleChange}
            error={errors?.newPassword}
          />
          <Input
            label={t("profileSettings.confirm_new_password")}
            type="password"
            name="confirmNewPassword"
            value={formData?.confirmNewPassword}
            setFormData={setFormData}
            handleChange={handleChange}
            hint_msg={translate(
              "Choose a secure password that is at least 8 characters long",
              "اختر كلمة مرور آمنة لا تقل عن 8 أحرف"
            )}
            error={errors?.confirmNewPassword}
          />
        </div>
        <div className="flex justify-end ipad:justify-start">
          <Button
            label={t("profileSettings.update_password")}
            className="flex flex-col items-center gap-2.5 px-12 py-2 rounded-[10px]"
            onSubmit={changePassword}
          />
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
