/* :Page:NewMotor: User Info page */
import React, { useEffect } from "react";
import "./startPage.css";
import BasicInfo from "./basicInfo/basicInfo";
import DocumentsReadyInfo from "./documentsReadyInfo/documentsReadyInfo";
import useMotorFlow from "../../../../hooks/useMotorFlow";
import { useSelector } from "react-redux";
import { selectLoginTokenInfo } from "../../../../redux/features/loginRegisterSlice";
import { useLocation } from "react-router-dom";

const StartPage = () => {
  const { currentSubstepIndex, handleNextSubstep } = useMotorFlow();

  const loginTokenInfo = useSelector(selectLoginTokenInfo);
  let isLoggedin = loginTokenInfo;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const w = queryParams.get("w") === "true"; // Convert to boolean
  const e = queryParams.get("email") || ""; // Get email, default to empty string if not present

  // If logged in or redirected from website, skip user info step
  useEffect(() => {
    if (isLoggedin && currentSubstepIndex != 1) {
      handleNextSubstep();
    }
    if ((w || e) && currentSubstepIndex != 1) {
      handleNextSubstep();
    }
  }, [isLoggedin, currentSubstepIndex, handleNextSubstep]);

  return (
    <div className="flex flex-col items-center gap-6 bg-white max767:p-5">
      {isLoggedin ? (
        currentSubstepIndex == 1 && (
          <DocumentsReadyInfo
            continueClickHandler={handleNextSubstep}
            hide_back_btn={true}
            show_close_btn={true}
          />
        )
      ) : (
        <>
          {currentSubstepIndex == 0 && (
            <BasicInfo continueClickHandler={handleNextSubstep} />
          )}
          {currentSubstepIndex == 1 && (
            <DocumentsReadyInfo
              continueClickHandler={handleNextSubstep}
              hide_back_btn={false}
              show_close_btn={false}
            />
          )}
        </>
      )}
    </div>
  );
};

export default StartPage;
