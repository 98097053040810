import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import {
  format,
  subMonths,
  subYears,
  startOfYear,
  parse,
  formatISO,
} from "date-fns";
import learnaboutfund from "../../../../assets/images/assessment.svg";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";
import useScreenResolution from "../../../../hooks/useScreenResolution";
import FundsPreviewPopup from "./investementStrategyFlow/selectFundsTable/fundsPreviewPopup/fundsPreviewPopup";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import DownloadBtn from "../../../../components/common/downloadBtn/downloadBtn";
import { STRAPI_BASE_URL } from "../../../../api/baseURL";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale
);

// const data = {
//   labels: ["2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023"],
//   datasets: [
//     {
//       label: "Fund Value",
//       data: [1, 4, 2, 5, 3, 6, 4, 7, 5, 8, 6, 9],
//       fill: false,
//       backgroundColor: "rgba(75,192,192,0.2)",
//       borderColor: "rgba(75,192,192,1)",
//     },
//   ],
// };

// const options = {
//   responsive: true,
//   plugins: {
//     legend: {
//       display: false
//     },
//     tooltip: {
//       mode: "index",
//       intersect: false,
//     },
//   },
//   hover: {
//     mode: "nearest",
//     intersect: true,
//   },

// };

const FundManagementChart = ({
  item,
  chartResponse,
  investmentStartegyType,
  fundDoc
}) => {
  const [startDate, setStartDate] = useState(new Date("2004-10-01"));
  const [endDate, setEndDate] = useState(new Date("2024-05-30"));
  const screenResolution = useScreenResolution();
  const formattedDate = formatISO(startDate, { representation: "date" });
  const [showFundPreview, setShowFundPreview] = useState(null);
  const translate = useLanguageTranslate();
  const dataSet = {
    labels: ["2012", "2013", "2014", "2016", "2018", "2020", "2022", "2024"],
    data: [2, 1.55, 3, 5, 2, 7, 6, 9],
  };
  // const [filteredData, setFilteredData] = useState(dataSet);
  const [filteredData, setFilteredData] = useState({
    labels: [],
    data: [],
  });

  useEffect(() => {
    if (
      chartResponse?.quarterly_returns &&
      Object.keys(chartResponse.quarterly_returns).length > 0
    ) {
      const quarterlyData = Object.entries(chartResponse.quarterly_returns);
      const sortOrder = quarterlyData.reverse();
      const last8Quarters = sortOrder.slice(0, 8);
      const latestQuarters = last8Quarters.map(([quarter]) => quarter).reverse();
      const latestReturns = last8Quarters.map(([, value]) => value).reverse();
      setFilteredData({
        labels: latestQuarters,
        data: latestReturns,
      });
    } else {
      console.error("No valid quarterly_returns data found.");
    }
  }, [chartResponse]);

  const data = {
    labels: filteredData.labels,
    datasets: [
      {
        label: "Fund Value",
        data: filteredData.data,
        fill: false,
        borderColor: "#0C4A0D",
        pointBackgroundColor: "#0C4A0D",
        borderWidth: 2,
        tension: 0.1,
        pointRadius: (ctx) => (ctx.raw && ctx.raw.hover ? 5 : 0),
        pointHoverRadius: 7,
        pointHoverBorderWidth: 2,
      },
    ],
  };

  const options = {
    animation: false,
    responsive: true,
    scales: {
      x: {
        grid: {
          display: false, // Hide the vertical lines
        },
        border: {
          display: false, // Hide the X-axis line
        },
        ticks: {
          display: true, // Display the X-axis labels
          padding: 20, // Add space between X-axis labels and chart lines
        },
      },
      y: {
        grid: {
          display: true, // Hide the vertical lines
        },
        border: {
          display: false, // Hide the X-axis line
        },
        ticks: {
          display: true, // Display the X-axis labels
          padding: 20, // Add space between X-axis labels and chart lines
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: function (context) {
            return context[0].label;
          },
          label: function (context) {
            return `${context.dataset.label}: ${context.raw}`;
          },
        },
        backgroundColor: "rgba(234, 241, 235, 0.9)",
        titleFont: {
          size: 16,
          weight: "bold",
        },
        bodyFont: {
          size: 14,
        },
        titleColor: "black", // Tooltip title text color
        bodyColor: "black",
        padding: 10,
        displayColors: false,
        borderColor: "green",
        borderWidth: 1,
        position: "nearest",
        caretPadding: 5,
        caretSize: 10,
        xAlign: "center",
        yAlign: "bottom",
      },
      borderDrawing: {
        id: "borderDrawing",
        beforeDraw: (chart) => {
          const {
            ctx,
            chartArea: { left, top, width, height },
          } = chart;
          ctx.save();
          ctx.strokeStyle = "black"; // Border color
          ctx.lineWidth = 1; // Border width
          ctx.strokeRect(left, top, width, height); // Draw the border
          ctx.restore();
        },
      },
    },
    hover: {
      mode: "nearest",
      intersect: false,
      onHover: (event, chartElement, chart) => {
        const points = chart.getDatasetMeta(0).data;
        points.forEach((point) => (point.custom = point.custom || {}));
        points.forEach((point) => (point.custom.radius = 0));
        if (chartElement.length) {
          const index = chartElement[0].index;
          points[index].custom.radius = 5;
          chart.update();
        }
      },
    },
  };

  const plugins = [
    {
      id: "customBorder",
      beforeDraw: (chart) => {
        const {
          ctx,
          chartArea: { left, top, width, height },
        } = chart;
        ctx.save();
        ctx.fillStyle = "white"; // Background color
        ctx.fillRect(left, top, width, height); // Fill the background

        ctx.strokeStyle = "rey"; // Border color
        ctx.lineWidth = 0.2; // Border width
        ctx.strokeRect(left, top, width, height); // Draw the border
        ctx.restore();
      },
    },
  ];

  const handleDateChange = (type, newValue) => {
    const parsedDate = parse(newValue, "dd-MM-yyyy", new Date());
    if (type =="start") setStartDate(parsedDate);
    if (type =="end") setEndDate(parsedDate);
    // Implement filtering logic based on dates
  };

  const handlePeriodFilter = (period) => {
    const now = new Date();
    let newStartDate;
    switch (period) {
      case "1m":
        newStartDate = subMonths(now, 1);
        break;
      case "3m":
        newStartDate = subMonths(now, 3);
        break;
      case "6m":
        newStartDate = subMonths(now, 6);
        break;
      case "YTD":
        newStartDate = startOfYear(now);
        break;
      case "1y":
        newStartDate = subYears(now, 1);
        break;
      case "all":
        newStartDate = new Date("2004-10-01");
        break;
      default:
        return;
    }
    setStartDate(newStartDate);
    setEndDate(now);
    // Implement filtering logic based on period
  };
  const fundPreviewClickHandler = () => {
    setShowFundPreview({
      isin: item.fundISIN,
      original: {
        attributes: {
          fundName: item.fundName,
          ISIN: item.fundISIN,
          assetClass: "",
          category: "",
          description: "",
          pdfLink: "",
        },
      },
    });
  };
  return (
    <>
      {(screenResolution.mobile || screenResolution.tablet) && (
        <div className="isMobile max767:p-0 max767:pt-2">
          <div className="flex justify-between items-center">
            <p className="text-black text-base not-italic font-semibold leading-[normal] max767:mt-0">
              {translate("Fund Code", "رمز الصندوق")}
            </p>

            {!investmentStartegyType ?
              <button
                onClick={fundPreviewClickHandler}
                className="flex text-[#252C32] text-xs not-italic font-semibold leading-4 items-center gap-2 border bg-white px-3 py-1 rounded-md border-solid border-[#DDE2E4] hover:bg-gray-300"
              >
                {translate("Learn about the Fund", "تعرف على الصندوق")}{" "}
                <img src={learnaboutfund} />
              </button> : <DownloadBtn
                filesData={[{
                  "File": STRAPI_BASE_URL+fundDoc,
                  "ID": "pdf",
                  "Name": "Download PDF"
                }]}
                className={"flex bg-transparent"}
              />}
          </div>
          <p className="text-[#5B6871] text-xs not-italic font-normal leading-[normal] mx-0 my-2">
            {item?.fundISIN}
          </p>
          {/* <p className="text-[#5B6871] text-xs not-italic font-normal leading-[normal]">
            {translate("*as of market close 15 May 2024","*اعتبارًا من إغلاق السوق في 15 مايو 2024")}
          </p> */}
        </div>
      )}

      {showFundPreview && (
        <FundsPreviewPopup
          show={showFundPreview}
          setShow={setShowFundPreview}
        />
      )}
      <div className="flex justify-between w-full">
        {!(screenResolution.mobile || screenResolution.tablet) && (
          <div className=" my-6">
            <h2 className="text-black text-2xl not-italic font-medium leading-[120%] mb-2">
              {item?.fundName}
            </h2>

            {/* <p className="text-[#5B6871] text-xs not-italic font-normal leading-[normal]"> */}
            {/* *as of market close "15 May 2024" Date is not comming from api */}
            {/* {translate("*as of market close 15 May 2024","*اعتبارًا من إغلاق السوق في 15 مايو 2024")} */}

            {/* </p> */}
            {!investmentStartegyType ? (
              <>
                <p className="text-black text-base not-italic font-semibold leading-[normal] mt-8 max767:mt-0">
                  {translate("Fund Code", "رمز الصندوق")}
                </p>
                <p className="text-[#5B6871] text-xs not-italic font-normal leading-[normal] mx-0 my-2">
                  {item?.fundISIN}
                </p>
                <button
                  onClick={fundPreviewClickHandler}
                  className="flex text-[#252C32] text-xs not-italic font-semibold leading-4 items-center gap-2 border bg-white px-3 py-1 rounded-md border-solid border-[#DDE2E4] hover:bg-gray-300"
                >
                  {translate("Learn about the Fund", "تعرف على الصندوق")}{" "}
                  <img src={learnaboutfund} />
                </button>
              </>
            ) : (
              <DownloadBtn
                filesData={[{
                  "File": STRAPI_BASE_URL+fundDoc,
                  "ID": "pdf",
                  "Name": "Download PDF"
                }]}
                className={"flex bg-transparent"}
              />
            )}
          </div>
        )}

        {investmentStartegyType =="" && <div className="w-full ">
          {/* <div className="flex-col justify-between items-center mt-6">
          <div className="flex space-x-2">
            <span className="px-3 py-1 text-[#5B6871] text-xs not-italic font-normal leading-[normal] max767:hidden">
              Zoom
            </span>
            <button
              className="flex text-[#252C32] text-center text-sm not-italic font-normal leading-6 items-center gap-2.5 rounded border bg-white px-3 py-0 border-solid border-[#DDE2E4] hover:bg-gray-300"
              onClick={() => handlePeriodFilter("1m")}
            >
              1m
            </button>
            <button
              className="flex text-[#252C32] text-center text-sm not-italic font-normal leading-6 items-center gap-2.5 rounded border bg-white px-3 py-0 border-solid border-[#DDE2E4] hover:bg-gray-300 hover:bg-gray-300"
              onClick={() => handlePeriodFilter("3m")}
            >
              3m
            </button>
            <button
              className="flex text-[#252C32] text-center text-sm not-italic font-normal leading-6 items-center gap-2.5 rounded border bg-white px-3 py-0 border-solid border-[#DDE2E4] hover:bg-gray-300 hover:bg-gray-300"
              onClick={() => handlePeriodFilter("6m")}
            >
              6m
            </button>
            <button
              className="flex text-[#252C32] text-center text-sm not-italic font-normal leading-6 items-center gap-2.5 rounded border bg-white px-3 py-0 border-solid border-[#DDE2E4] hover:bg-gray-300 hover:bg-gray-300"
              onClick={() => handlePeriodFilter("YTD")}
            >
              YTD
            </button>
            <button
              className="flex text-[#252C32] text-center text-sm not-italic font-normal leading-6 items-center gap-2.5 rounded border bg-white px-3 py-0 border-solid border-[#DDE2E4] hover:bg-gray-300 hover:bg-gray-300"
              onClick={() => handlePeriodFilter("1y")}
            >
              1y
            </button>
            <button
              className="flex text-[#252C32] text-center text-sm not-italic font-normal leading-6 items-center gap-2.5 rounded border bg-white px-3 py-0 border-solid border-[#DDE2E4] hover:bg-gray-300 hover:bg-gray-300"
              onClick={() => handlePeriodFilter("all")}
            >
              All
            </button>
          </div>
          <div className="flex space-x-2 max767:hidden">
            <span className="text-[#5B6871] text-xs not-italic font-normal leading-[3]">
              From{" "}
            </span>
            <input
              type="date"
              value={format(startDate, "yyyy-MM-dd")}
              onChange={(e) =>
                handleDateChange("start", new Date(e.target.value))
              }
              className="flex items-center gap-2.5 rounded border bg-white px-2 py-0 border-solid border-[#DDE2E4]"
            />

            <span className="text-[#5B6871] text-xs not-italic font-normal leading-[3]">
              To{" "}
            </span>
            <input
              type="date"
              value={format(endDate, "yyyy-MM-dd")}
              onChange={(e) =>
                handleDateChange("end", new Date(e.target.value))
              }
              className="flex items-center gap-2.5 rounded border bg-white px-2 py-0 border-solid border-[#DDE2E4]"
            />
          </div>
        </div> */}
          <Line data={data} options={options} plugins={plugins} />
        </div>}
      </div>
    </>
  );
};

export default FundManagementChart;
