export const fullListData = [
  {
    benefit: {
      label_en: "Repair Your Vehicle at your Original Dealership",
      label_ar: "إصلاح سيارتك لدى الوكالة الأصلية",
      sublabel:
        "(from the first date of registration in the General Directorate of Traffic)",
      sublabel_ar: "(من تاريخ التسجيل الأول في الإدارة العامة للمرور)",
    },
    third_party: {
      label_en: false,
      label_ar: false,
    },
    third_party_plus: {
      label_en: false,
      label_ar: false,
    },
    flexi: {
      label_en: "3 Years",
      label_ar: "3 سنوات",
    },
    platinum: {
      label_en: "Lifetime",
      label_ar: "مدى الحياة",
    },
  },
  {
    benefit: {
      label_en: "Coverage for Accidents with Other Vehicles",
      label_ar: "التغطية للحوادث مع المركبات الأخرى",
    },
    third_party: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
    third_party_plus: {
      label_en: "Covered for Uninsured Vehicle at Fault Only",
      label_ar: "تغطية فقط للمركبات غير المؤمنة المتسببة بالحادث",
    },
    flexi: {
      label_en: true,
      label_ar: true,
    },
    platinum: {
      label_en: true,
      label_ar: true,
    },
  },
  {
    benefit: {
      label_en: "Third Party Property Damage",
      label_ar: "أضرار الممتلكات التي يلحقها الطرف الثالث",
    },
    third_party: {
      label_en: "BHD 500,000",
      label_ar: "500,000 دينار بحريني",
    },
    third_party_plus: {
      label_en: "BHD 500,000",
      label_ar: "500,000 دينار بحريني",
    },
    flexi: {
      label_en: "BHD 500,000",
      label_ar: "500,000 دينار بحريني",
    },
    platinum: {
      label_en: "BHD 500,000",
      label_ar: "500,000 دينار بحريني",
    },
  },
  {
    benefit: {
      label_en: "Legal Liability Protection for Injuries to Others",
      label_ar: "حماية المسؤولية القانونية عن إصابات الآخرين",
    },
    third_party: {
      label_en: true,
      label_ar: true,
    },
    third_party_plus: {
      label_en: true,
      label_ar: true,
    },
    flexi: {
      label_en: true,
      label_ar: true,
    },
    platinum: {
      label_en: true,
      label_ar: true,
    },
  },
  {
    benefit: {
      label_en: "Emergency Medical Expense",
      label_ar: "نفقات العلاج الطبي الطارئ",
    },
    third_party: {
      label_en: false,
      label_ar: false,
    },
    third_party_plus: {
      label_en: false,
      label_ar: false,
    },
    flexi: {
      label_en: "BHD 500",
      label_ar: "500 دينار بحريني",
    },
    platinum: {
      label_en: "BHD 500",
      label_ar: "500 دينار بحريني",
    },
  },
  {
    benefit: {
      label_en: "Vehicle Towing Services",
      label_ar: "خدمات قطر المركبات",
    },
    third_party: {
      label_en: false,
      label_ar: false,
    },
    third_party_plus: {
      label_en: false,
      label_ar: false,
    },
    flexi: {
      label_en: true,
      label_ar: true,
    },
    platinum: {
      label_en: true,
      label_ar: true,
    },
  },
  {
    benefit: {
      label_en: "New for old replacement of damaged Parts",
      label_ar: "تبديل السيارة المؤقت",
    },
    third_party: {
      label_en: false,
      label_ar: false,
    },
    third_party_plus: {
      label_en: false,
      label_ar: false,
    },
    flexi: {
      label_en: "3 years",
      label_ar: "3 years",
    },
    platinum: {
      label_en: "5 years",
      label_ar: "5 أيام",
    },
  },
  {
    benefit: {
      label_en: "Driver Personal Injury Protection",
      label_ar: "تبديل السيارة المؤقت",
    },
    third_party: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
    third_party_plus: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
    flexi: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
    platinum: {
      label_en: true,
      label_ar: true,
    },
  },
  {
    benefit: {
      label_en: "Personal Accident Benefits to Passengers",
      label_ar: "تبديل السيارة المؤقت",
    },
    third_party: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
    third_party_plus: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
    flexi: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
    platinum: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
  },
  {
    benefit: {
      label_en: "Temporary Car Replacement",
      label_ar: "تبديل السيارة المؤقت",
    },
    third_party: {
      label_en: "Optional",
      label_ar: "اختياري",
      sublabel: "",
      sublabel_ar: "",
    },
    third_party_plus: {
      label_en: "8 Days",
      label_ar: "8 أيام سيارة صغيرة",
      sublabel: "Small Car",
      sublabel_ar: " سيارة صغيرة",
    },
    flexi: {
      label_en: "8 Days",
      label_ar: "8 أيام سيارة صغيرة",
      sublabel: "Small Car",
      sublabel_ar: " سيارة صغيرة",
    },
    platinum: {
      label_en: "15 Days",
      label_ar: "15 أيام سيارة صغيرة",
      sublabel: "Small Car",
      sublabel_ar: " سيارة صغيرة",
    },
  },
  {
    benefit: {
      label_en:
        "Coverage for Your Vehicle's Damage Overseas/Cross-Border Protection",
      label_ar: "تغطية أضرار مركبتك خارج البحرين",
    },
    third_party: {
      label_en: false,
      label_ar: false,
    },
    third_party_plus: {
      label_en: false,
      label_ar: false,
    },
    flexi: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
    platinum: {
      label_en: "GCC 30 Days",
      label_ar: "30 يوماً دول الخليج",
    },
  },
  {
    benefit: {
      label_en: "Around-the-Clock Breakdown Help",
      label_ar: "تغطية أضرار مركبتك خارج البحرين",
    },
    third_party: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
    third_party_plus: {
      label_en: "Bahrain Only",
      label_ar: "تغطية أضرار",
    },
    flexi: {
      label_en: true,
      label_ar: true,
    },
    platinum: {
      label_en: true,
      label_ar: true,
    },
  },
  {
    benefit: {
      label_en: "Act of God",
      label_ar: "تغطية أضرار مركبتك خارج البحرين",
    },
    third_party: {
      label_en: false,
      label_ar: false,
    },
    third_party_plus: {
      label_en: false,
      label_ar: false,
    },
    flexi: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
    platinum: {
      label_en: true,
      label_ar: true,
    },
  },
  {
    benefit: {
      label_en: "Exclusive VIP Services & Benefits",
      label_ar: "تغطية أضرار مركبتك خارج البحرين",
    },
    third_party: {
      label_en: false,
      label_ar: false,
    },
    third_party_plus: {
      label_en: false,
      label_ar: false,
    },
    flexi: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
    platinum: {
      label_en: true,
      label_ar: true,
    },
  },
  {
    benefit: {
      label_en: "Windshield Replacement Coverage",
      label_ar: "تغطية أضرار مركبتك خارج البحرين",
    },
    third_party: {
      label_en: false,
      label_ar: false,
    },
    third_party_plus: {
      label_en: false,
      label_ar: false,
    },
    flexi: {
      label_en: true,
      label_ar: true,
    },
    platinum: {
      label_en: true,
      label_ar: true,
    },
  },
  {
    benefit: {
      label_en: "Protection Against Civil Disturbances",
      label_ar: "تغطية أضرار مركبتك خارج البحرين",
    },
    third_party: {
      label_en: false,
      label_ar: false,
    },
    third_party_plus: {
      label_en: false,
      label_ar: false,
    },
    flexi: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
    platinum: {
      label_en: true,
      label_ar: true,
    },
  },
  {
    benefit: {
      label_en: "Permission to Handle Your Own Repairs",
      label_ar: "تغطية أضرار مركبتك خارج البحرين",
    },
    third_party: {
      label_en: false,
      label_ar: false,
    },
    third_party_plus: {
      label_en: false,
      label_ar: false,
    },
    flexi: {
      label_en: false,
      label_ar: false,
    },
    platinum: {
      label_en: true,
      label_ar: true,
    },
  },
  {
    benefit: {
      label_en: "Life Insurance",
      label_ar: "تغطية أضرار مركبتك خارج البحرين",
    },
    third_party: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
    third_party_plus: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
    flexi: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
    platinum: {
      label_en: "Optional",
      label_ar: "اختياري",
    },
  },
];
