import React from "react";
import "./Loader.css";

const CustomLoader = (type) => {
  if (type.type == "endo") {
    return (
      <div className="policiesGrid flex w-full items-start content-start gap-4 flex-wrap">
        <div className="policy_card_root cursor-pointer bg-white rounded-2xl w-full h-[207px]"></div>
      </div>
    );
  }
};

export default CustomLoader;
