/* :Data: Cover options for new / Also used in add covers */

export const setCoverDataOptions = (coverPrices, planCode, finalCovers, policyCovers) => {
  // Function to adjust the cover prices API and make a list of cover codes and their prices
  //console.log("Should be getting covers for plan: ", planCode); // Plan code
  //console.log('Cover prices passed:', coverPrices); // Covers received from cover prices API 
  const coverPremiums = extractFinalPremiums(coverPrices); // Get prices 

  const coverCode = "CMOT28";
  // Change this to the code you're looking for
  const cover = policyCovers?.covers?.some(cover => cover.coverCode == 'CMOT28');

  console.log("TTTTTTTTTTTTTTTTTTTTTTTTT : " + cover);

  //console.log("Cover premiums: ", coverPremiums);  // Log prices
  //console.log('Final covers passed: ', finalCovers); // Current/Final covers

  return [
    {
      code: 'CMOT28',
      name: "Car Replacement",
      name_ar: "استبدال السيارة",
      included: planCode == 'PL' || planCode == 'GO' ? true : finalCovers ? finalCovers.coverOptionsState[0].included : true,
      price: coverPremiums['CMOT28'] || 0,
      // noOfDays: [
      //   {
      //     label_en: "8 Days",
      //     label_ar: "8 عاةلى السياسة",
      //     type: "8",
      //   },
      //   {
      //     label_en: "10 Days",
      //     label_ar: "10 شهادة المركبة",
      //     type: "10",
      //   },
      //   {
      //     label_en: "15 Days",
      //     label_ar: "15 التغطيات والمزايا",
      //     type: "15",
      //   },
      //   {
      //     label_en: "30 Days",
      //     label_ar: "30 إدارة سياستي",
      //     type: "30",
      //   },
      // ],
      // Conditional noOfDays based on selectedCarType and planCode
      noOfDays: (() => {
        if (planCode == 'PL') {
          var selectedType = finalCovers ? finalCovers.coverOptionsState[0].selectedCarType : "";
          switch (selectedType.toUpperCase()) {
            case 'SMALL':
              return [
                {
                  label_en: "30 Days",
                  label_ar: "30 إدارة سياستي",
                  type: "30",
                },
              ];
            case 'MEDIUM':
              return [
                {
                  label_en: "15 Days",
                  label_ar: "15 التغطيات والمزايا",
                  type: "15",
                },
                {
                  label_en: "30 Days",
                  label_ar: "30 إدارة سياستي",
                  type: "30",
                },
              ];
            case 'LUXURY':
              return [
                {
                  label_en: "8 Days",
                  label_ar: "8 عاةلى السياسة",
                  type: "8",
                },
                {
                  label_en: "10 Days",
                  label_ar: "10 شهادة المركبة",
                  type: "10",
                },
                {
                  label_en: "15 Days",
                  label_ar: "15 التغطيات والمزايا",
                  type: "15",
                },
                {
                  label_en: "30 Days",
                  label_ar: "30 إدارة سياستي",
                  type: "30",
                },
              ];
            default:
              return [
                {
                  label_en: "30 Days",
                  label_ar: "30 إدارة سياستي",
                  type: "30",
                }
              ];
          }
        } else {
          return [
            {
              label_en: "8 Days",
              label_ar: "8 عاةلى السياسة",
              type: "8",
            },
            {
              label_en: "10 Days",
              label_ar: "10 شهادة المركبة",
              type: "10",
            },
            {
              label_en: "15 Days",
              label_ar: "15 التغطيات والمزايا",
              type: "15",
            },
            {
              label_en: "30 Days",
              label_ar: "30 إدارة سياستي",
              type: "30",
            },
          ];
        }
      })(),
      carType: [
        {
          label_en: "Small Car",
          label_ar: " إدارة سياستي",
          type: "SMALL",
        },
        {
          label_en: "Medium Car",
          label_ar: "إدارة سياستي",
          type: "MEDIUM",
        },
        {
          label_en: "Luxury Car",
          label_ar: " إدارة سياستي",
          type: "LUXURY",
        },
      ],
      selectedDays: finalCovers ? finalCovers.coverOptionsState[0].selectedDays : (planCode == 'PL' ? "30" : "8"),
      selectedCarType: finalCovers ? finalCovers.coverOptionsState[0].selectedCarType : "SMALL",
      defaultIncludedInPlan: (() => {
        const coverExists = policyCovers?.covers?.some(cover => cover.coverCode == 'CMOT28');
        const premiumDefined = coverPremiums['CMOT28'] != undefined;
        //console.log(`Cover Exists for CMOT28: ${coverExists}, Premium Defined: ${premiumDefined}`);
        return coverExists && premiumDefined;
      })(),
    },
    {
      code: "CMOT16",
      name: "Roadside Assistance",
      name_ar: "المساعدة على الطريق",
      included: planCode == 'PL' ? true : false,
      price: coverPremiums['CMOT16'] || 0,
      defaultIncludedInPlan: (() => {
        const coverExists = policyCovers?.covers?.some(cover => cover.coverCode == 'CMOT16');
        const premiumDefined = coverPremiums['CMOT16'] != undefined;
        const cmot28 = policyCovers?.covers?.some(cover => cover.coverCode == 'CMOT28');
        //console.log(`Cover Exists for CMOT16: ${coverExists}, Premium Defined: ${premiumDefined}`);
        if (cmot28) {
          return cmot28;
        }
        else {
          return coverExists && premiumDefined;
        }

      })(),
    },
    {
      code: "CMOT34",
      name: "Personal Accident Benefits to Passengers",
      name_ar: "فوائد الحوادث الشخصية للركاب",
      included: false,
      price: coverPremiums['CMOT34'] || 0,
      defaultIncludedInPlan: (() => {
        const coverExists = policyCovers?.covers?.some(cover => cover.coverCode == 'CMOT34');
        const premiumDefined = coverPremiums['CMOT34'] != undefined;
        //console.log(`Cover Exists for CMOT34: ${coverExists}, Premium Defined: ${premiumDefined}`);
        return coverExists && premiumDefined;
      })(),
    },
    {
      code: "CMOT24",
      name: "Geographical Extension",
      name_ar: "الامتداد الجغرافي",
      included: planCode == 'PL' || planCode == 'GO' ? true : false,
      price: coverPremiums['CMOT24'] || 0,
      defaultIncludedInPlan: (() => {
        const coverExists = policyCovers?.covers?.some(cover => cover.coverCode == 'CMOT24');
        const premiumDefined = coverPremiums['CMOT24'] != undefined;
        //console.log(`Cover Exists for CMOT24: ${coverExists}, Premium Defined: ${premiumDefined}`);
        return coverExists && premiumDefined;
      })(),
    },
    {
      code: "CMOT9",
      name: "Act of God",
      name_ar: "فعل الله",
      included: planCode == 'PL' || planCode == 'GO' ? true : false,
      price: coverPremiums['CMOT9'] || 0,
      defaultIncludedInPlan: (() => {
        const coverExists = policyCovers?.covers?.some(cover => cover.coverCode == 'CMOT9');
        const premiumDefined = coverPremiums['CMOT9'] != undefined;
        //console.log(`Cover Exists for CMOT9: ${coverExists}, Premium Defined: ${premiumDefined}`);
        return coverExists && premiumDefined;
      })(),
    },
    {
      code: "CMOT11",
      name: "Windshield",
      name_ar: "الزجاج الأمامي",
      included: false,
      price: coverPremiums['CMOT11'] || 0,
      defaultIncludedInPlan: (() => {
        const coverExists = policyCovers?.covers?.some(cover => cover.coverCode == 'CMOT11');
        const premiumDefined = coverPremiums['CMOT11'] != undefined;
        //console.log(`Cover Exists for CMOT24: ${coverExists}, Premium Defined: ${premiumDefined}`);
        return coverExists && premiumDefined;
      })(),
    },
    {
      code: "CMOT13",
      name: "VIP",
      name_ar: "كبار الشخصيات",
      included: planCode == 'PL' ? true : false,
      price: coverPremiums['CMOT13'] || 0,
      defaultIncludedInPlan: (() => {
        const coverExists = policyCovers?.covers?.some(cover => cover.coverCode == 'CMOT13');
        const premiumDefined = coverPremiums['CMOT13'] != undefined;
        //console.log(`Cover Exists for CMOT24: ${coverExists}, Premium Defined: ${premiumDefined}`);
        return coverExists && premiumDefined;
      })(),
    },
    {
      code: "CMOT40",
      name: "Personal Accident Benefits to Driver",
      name_ar: "فوائد الحوادث الشخصية للسائق",
      included: false,
      price: coverPremiums['CMOT40'] || 0,
      defaultIncludedInPlan: (() => {
        const coverExists = policyCovers?.covers?.some(cover => cover.coverCode == 'CMOT40');
        const premiumDefined = coverPremiums['CMOT40'] != undefined;
        //console.log(`Cover Exists for CMOT24: ${coverExists}, Premium Defined: ${premiumDefined}`);
        return coverExists && premiumDefined;
      })(),
    },
    {
      code: "CMOT61",
      name: "Strike, Riot & Civil Commotion",
      name_ar: "الإضراب والشغب والاضطرابات المدنية",
      included: planCode == 'PL' ? true : false,
      price: coverPremiums['CMOT61'] || 0,
      defaultIncludedInPlan: (() => {
        const coverExists = policyCovers?.covers?.some(cover => cover.coverCode == 'CMOT61');
        const premiumDefined = coverPremiums['CMOT61'] != undefined;
        //console.log(`Cover Exists for CMOT24: ${coverExists}, Premium Defined: ${premiumDefined}`);
        return coverExists && premiumDefined;
      })(),
    },
    {
      code: "CMOT99",
      name: "Depreciation on Parts",
      name_ar: "استهلاك الأجزاء",
      included: planCode == 'PL' ? true : false,
      price: coverPremiums['CMOT99'] || 0,
      defaultIncludedInPlan: (() => {
        const coverExists = policyCovers?.covers?.some(cover => cover.coverCode == 'CMOT99');
        const premiumDefined = coverPremiums['CMOT99'] != undefined;
        //console.log(`Cover Exists for CMOT24: ${coverExists}, Premium Defined: ${premiumDefined}`);
        return coverExists && premiumDefined;
      })(),
    },
    {
      code: "CMOT21",
      name: "Life Insurance",
      name_ar: "تأمين على الحياة",
      included: planCode == 'PL' ? true : false,
      price: coverPremiums['CMOT21'] || 0,
      defaultIncludedInPlan: (() => {
        const coverExists = policyCovers?.covers?.some(cover => cover.coverCode == 'CMOT21');
        const premiumDefined = coverPremiums['CMOT21'] != undefined;
        //console.log(`Cover Exists for CMOT24: ${coverExists}, Premium Defined: ${premiumDefined}`);
        return coverExists && premiumDefined;
      })(),
    },
    // {
    //   code: "CMOT18",
    //   name: "Working Risk",
    //   name_ar: "مخاطر العمل",
    //   included: true,
    //   price: coverPremiums['CMOT21'] || 0,
    //   defaultIncludedInPlan: isDefaultForPlan('CMOT18', planCode),
    // },
    // {
    //   code: "CMOT32",
    //   name: "Content Cover",
    //   name_ar: "محتوى الغلاف",
    //   included: true,
    //   price: coverPremiums['CMOT32'] || 0,
    //   defaultIncludedInPlan: isDefaultForPlan('CMOT32', planCode),
    // },
    {
      code: "CMOT17",
      name: "Additional Accessories",
      name_ar: "ملحقات إضافية",
      included: planCode == 'PL' ? true : false,
      price: coverPremiums['CMOT17'] || 0,
      defaultIncludedInPlan: (() => {
        const coverExists = policyCovers?.covers?.some(cover => cover.coverCode == 'CMOT17');
        const premiumDefined = coverPremiums['CMOT17'] != undefined;
        //console.log(`Cover Exists for CMOT24: ${coverExists}, Premium Defined: ${premiumDefined}`);
        return coverExists && premiumDefined;
      })(),
    },
    {
      code: "CMOT2",
      name: "Agency Repair",
      name_ar: "إصلاح الوكالة",
      included: planCode == 'PL' || planCode == 'GO' ? true : false,
      price: coverPremiums['CMOT2'] || 0,
      defaultIncludedInPlan: (() => {
        const coverExists = policyCovers?.covers?.some(cover => cover.coverCode == 'CMOT2');
        const premiumDefined = coverPremiums['CMOT2'] != undefined;
        //console.log(`Cover Exists for CMOT24: ${coverExists}, Premium Defined: ${premiumDefined}`);
        return coverExists && premiumDefined;
      })(),
    },
    {
      code: "CMOT74",
      name: "Own Damage",
      name_ar: "إصلاح الوكالة",
      included: false,
      price: coverPremiums['CMOT74'] || 0,
      sumInsuredType: [
        {
          label_en: "10000",
          type: "10000"

        },
        {
          label_en: "15000",
          type: "15000"

        }
      ],
      selectedSumInsuredType: finalCovers ? finalCovers.coverOptionsState[0].selectedSumInsuredType : "15000",
      defaultIncludedInPlan: (() => {
        const coverExists = policyCovers?.covers?.some(cover => cover.coverCode == 'CMOT74');
        const premiumDefined = coverPremiums['CMOT74'] != undefined;
        //console.log(`Cover Exists for CMOT24: ${coverExists}, Premium Defined: ${premiumDefined}`);
        return coverExists && premiumDefined;
      })(),
      showCover: planCode == 'TPPLUS'

    },
  ];
}

const extractFinalPremiums = (data) => {
  if (!data || !data.Covers) {
    console.error("Invalid data structure or Covers property missing");
    return {}; // Return an empty object or handle the error as needed
  }

  const coverPremiums = {};

  data.Covers.forEach((cover) => {
    const coverCode = cover.CoverCode;
    const finalPremium = cover.CoverProperties.find(
      (prop) => prop.strParamName == "Final Premium For Cover"
    )?.strParamValue;

    if (finalPremium) {
      coverPremiums[coverCode] = parseFloat(finalPremium); // Convert to number if needed
    }
  });

  return coverPremiums;
};

export const carReplacementPrice = async (days, size) => {
  if (!days || !size) {
    console.error("Invalid data structure or Covers property missing");
    return {}; // Return an empty object or handle the error as needed
  }

  let price = "";

  switch (size) {
    case "SMALL":
      switch (days) {
        case "8":
          price = 20;
          return price;
        case "10":
          price = 25;
          return price;
        case "15":
          price = 30;
          return price;
        case "30":
          price = 50;
          return price;
        default:
          price = 20;
          return price;
      }
    case "MEDIUM":
      switch (days) {
        case "8":
          price = 35;
          return price;
        case "10":
          price = 40;
          return price;
        case "15":
          price = 50;
          return price;
        case "30":
          price = 80;
          return price;
        default:
          price = 35;
          return price;
      }
    case "LUXURY":
      switch (days) {
        case "8":
          price = 60;
          return price;
        case "10":
          price = 70;
          return price;
        case "15":
          price = 100;
          return price;
        case "30":
          price = 165;
          return price;
        default:
          price = 60;
          return price;
      }
  }
};

const isDefaultForPlan = (coverCode, planCode) => {
  switch (coverCode) {
    // case 'CMOT28': // Car replacement
    //   if (planCode =='PL') {
    //     return true;
    //   } else {
    //     return false;
    //   }

    case 'CMOT16': // Roadside assistance
      if (planCode == 'PL') {
        return true;
      } else {
        return false;
      }

    case 'CMOT34': // PA benefits for passenger
      return false;

    case 'CMOT24': // Geographical extension
      if (planCode == 'PL' || planCode == 'GO') {
        return true;
      } else {
        return false;
      }

    case 'CMOT9': // Act of God
      if (planCode == 'PL' || planCode == 'GO') {
        return true;
      } else {
        return false;
      }

    case 'CMOT13': // VIP
      if (planCode == 'PL') {
        return true;
      } else {
        return false;
      }

    case 'CMOT40': // PA benefits to driver
      return false;

    case 'CMOT61': // SRCC
      if (planCode == 'PL') {
        return true;
      } else {
        return false;
      }
    case 'CMOT99': // Dep. on parts
      if (planCode == 'PL') {
        return true;
      } else {
        return false;
      }

    case 'CMOT21': // Life insurance
      if (planCode == 'PL') {
        return true;
      } else {
        return false;
      }
    // case 'CMOT32':
    //   if (planCode =='PL') {
    //     return true;
    //   } else {
    //     return false;
    //   }

    //   case 'CMOT18':
    // if (planCode =='PL') {
    //   return true;
    // } else {
    //   return false;
    // }

    case 'CMOT17': // Additional accessories
      if (planCode == 'PL') {
        return true;
      } else {
        return false;
      }

    case 'CMOT2': // Agency repair
      if (planCode == 'PL' || planCode == 'GO') {
        return true;
      } else {
        return false;
      }

    case 'CMOT74': // Own damage
      return false;

    default:
      return false;
  }
};