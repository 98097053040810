import React from 'react';
import useSummary from '../../../hooks/useSummary';
import { formatToThreeDigits } from '../../../utils';
import useLanguageTranslate from '../../../hooks/useLanguageTranslate';


const LifeInsuranceSummary = ({
  premiumText,
  quotationNumber,
  premium ,
  tax,
  totalPremium,

}) => {
  


  const {
    discount_amount,
    coverage_enhance_data,
    promocode,
  } = useSummary();

  const translate = useLanguageTranslate();
  const premiumTitle = premiumText
    ? translate(premiumText.en, premiumText.ar)
    : translate("Premium", "غالي");



  return (
    <div className="rounded-lg summary-conntent">
      <div className=" flex items-center justify-between bg-[#E8F4D8] px-4 py-2.5 rounded-md mb-2 mt-4 px-0 py-2.5">
        <span className="text-[#5B6871] text-xs not-italic font-medium leading-6">
          {translate("Quotation Number", "رقم الاقتباس")}
        </span>
        <span className="text-[#5B6871] text-xs not-italic font-medium leading-6">
          {quotationNumber}
        </span>
      </div>{" "}
      <div className="flex justify-between mb-2 px-0 py-2.5">
        <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
          {premiumTitle}
        </span>
        <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
          {translate("BHD","دينار بحريني")} {premium}
        </span>
      </div>
      {coverage_enhance_data?.coverOptionsState
        ?.filter((option) => option.included)
        ?.map((value) => (
          <div className="flex justify-between mb-2 px-0 py-2.5">
            <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
              {value.name}
            </span>
            <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
            {translate("BHD","دينار بحريني")} {value.price}
            </span>
          </div>
        ))}

      {promocode && (
        <div className="flex justify-between mb-2 px-0 py-2.5 border-t-[#DDE2E4] border-t border-solid">
          <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
          {translate("Discount","خصم")}
          </span>
          <span className="text-[#F2271C] text-sm not-italic font-normal leading-6">
            - {translate("BHD","دينار بحريني")} {discount_amount}
          </span>
        </div>
      )}
      <div className="flex justify-between mb-2 px-0 py-2.5 border-t-[#DDE2E4] border-t border-solid">
        <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
          {translate("VAT", "ضريبة القيمة المضافة ")} 10%
        </span>
        <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
        {translate("BHD","دينار بحريني")} {tax || 0}
        </span>
      </div>
      <div className="flex justify-between px-0 py-2.5 border-t-[#DDE2E4] border-t border-solid">
        <span className="text-[#1A2024] text-sm not-italic font-extrabold leading-6">
          {translate("Total", "المجموع")}
        </span>
        <span className="text-[#1A2024] text-sm not-italic font-extrabold leading-6">
        {translate("BHD","دينار بحريني")} {formatToThreeDigits(totalPremium)}
        </span>
      </div>
    </div>
  );
};

export default LifeInsuranceSummary;