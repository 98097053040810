import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    selectCurrentStepIndex,
     selectCurrentSubstepIndex, 
     selectRenewalFlowType, 
     setCurrentStepIndex, 
     setCurrentSubstepIndex, 
     setRenewalFlowType } from '../redux/features/motorInsuranceRenewFlowSlice';
import { RENEW_PAGE_ADJUST_CAR_VALUE, RENEW_PAGE_CHOOSE_DOWNGRADE_PLAN, RENEW_PAGE_CPR_NUMBER, RENEW_PAGE_CPR_PLATE_NUMBER, RENEW_PAGE_ENHANCE_YOUR_COVERAGE, RENEW_PAGE_REVIEW_AND_CONFIRM, RENEW_PAGE_REVIEW_YOUR_POLICY, RENEW_PAGE_SELECT_POLICY, RENEW_PAGE_VERIFY_OTP } from '../pages/motorInsurancePages/renewalMotorPolicy/data';
import { selectLoginTokenInfo } from '../redux/features/loginRegisterSlice';



const useRenewalFlow = () => {
    const dispatch = useDispatch();
    const flowType = useSelector(selectRenewalFlowType);
    const currentStepIndex = useSelector((state) => selectCurrentStepIndex(state));
    const currentSubstepIndex = useSelector((state) => selectCurrentSubstepIndex(state));

    // useEffect(() => {
    //     if (isLoggedin) {
    //         console.log("LOGGED IN!!");
    //     } else {
    //         console.log("NOT LOGGED IN!!");
    //     }
    // }, []); 
    // setRenewalFlowType();
    // const steps = new_motor_insurance_renewal_flow[flowType] || [];
    const steps = new_motor_insurance_renewal_flow[flowType] || [];

    const currentStep = steps[currentStepIndex] || {};
    const currentSubstep = currentStep.substeps ? currentStep.substeps[currentSubstepIndex] : '';

    const handleFlowChange = (type, currentStepIndex, currentSubstepIndex) => {
        dispatch(setRenewalFlowType({ type, currentStepIndex, currentSubstepIndex }));
    };

    const handleNextSubstep = () => {
        if (currentStep.substeps && currentSubstepIndex < currentStep.substeps.length - 1) {
            dispatch(setCurrentSubstepIndex(currentSubstepIndex + 1));
        }
        else if (currentSubstepIndex ==currentStep.substeps.length - 1) {
            handleNextStep()
        }
    };

    const handlePrevSubstep = () => {
        if (currentSubstepIndex > 0) {
            dispatch(setCurrentSubstepIndex(currentSubstepIndex - 1));
        }
        else {
            handlePrevStep()
        }
    };

    const handleNextStep = () => {
        if (currentStepIndex < steps.length - 1) {
            dispatch(setCurrentStepIndex(currentStepIndex + 1));
        }
    };

    const handlePrevStep = () => {
        if (currentStepIndex > 0) {
            const currentStep = steps[currentStepIndex - 1] || {};
            const substepIndex = currentStep?.substeps?.length - 1;
            dispatch(setCurrentStepIndex(currentStepIndex - 1));
            dispatch(setCurrentSubstepIndex(substepIndex));
        }
    };

    return (
        {
            handleFlowChange,
            handleNextSubstep,
            handlePrevSubstep,
            handleNextStep,
            handlePrevStep,
            flowType,
            steps,
            currentStep,
            currentStepIndex,
            currentSubstep,
            currentSubstepIndex,
        }
    );
};

export default useRenewalFlow;



export const RENEW_POLICY_SAME_PLAN = "RENEW_POLICY_SAME_PLAN";
export const RENEW_POLICY_DOWNGRADE_PLAN = "RENEW_POLICY_DOWNGRADE_PLAN";
export const RENEW_POLICY_LOGGED_IN = "RENEW_POLICY_LOGGED_IN";
export const RENEW_POLICY_LOGGED_OUT = "RENEW_POLICY_LOGGED_OUT";


const commonFlow = [
    {
        name: RENEW_PAGE_CPR_PLATE_NUMBER,
        substeps: [
            RENEW_PAGE_CPR_PLATE_NUMBER,
        ]
    },
    // {
    //     name: RENEW_PAGE_VERIFY_OTP,
    //     substeps: [
    //         RENEW_PAGE_VERIFY_OTP,
    //     ]
    // },
    // {
    //     name: RENEW_PAGE_SELECT_POLICY,
    //     substeps: [
    //         RENEW_PAGE_SELECT_POLICY,
    //     ]
    // },
    {
        name: RENEW_PAGE_REVIEW_YOUR_POLICY,
        substeps: [
            RENEW_PAGE_REVIEW_YOUR_POLICY,
        ]
    },
]

const commonFlowLoggedIn = [
    {
        name: RENEW_PAGE_SELECT_POLICY,
        substeps: [
            RENEW_PAGE_SELECT_POLICY,
        ]
    },
    {
        name: RENEW_PAGE_REVIEW_YOUR_POLICY,
        substeps: [
            RENEW_PAGE_REVIEW_YOUR_POLICY,
        ]
    },
]

const commonFlowLoggedOut = [
    {
        name: RENEW_PAGE_CPR_PLATE_NUMBER,
        substeps: [
            RENEW_PAGE_CPR_PLATE_NUMBER,
        ]
    },
    {
        name: RENEW_PAGE_REVIEW_AND_CONFIRM,
        substeps: [
            RENEW_PAGE_REVIEW_AND_CONFIRM,
        ]
    },
]

export const new_motor_insurance_renewal_flow = {
    [RENEW_POLICY_LOGGED_OUT]:
    [
        ...commonFlowLoggedOut
    ],
    [RENEW_POLICY_LOGGED_IN]:
    [
        ...commonFlowLoggedIn,
        {
            name: RENEW_PAGE_ADJUST_CAR_VALUE,
            substeps: [
                RENEW_PAGE_ADJUST_CAR_VALUE,
            ]
        },
        {
            name: RENEW_PAGE_ENHANCE_YOUR_COVERAGE,
            substeps: [
                RENEW_PAGE_ENHANCE_YOUR_COVERAGE,
            ]
        },
        {
            name: RENEW_PAGE_REVIEW_AND_CONFIRM,
            substeps: [
                RENEW_PAGE_REVIEW_AND_CONFIRM,
            ]
        },

    ]
    ,
    [RENEW_POLICY_SAME_PLAN]: [
         ...commonFlow,
        {
            name: RENEW_PAGE_ADJUST_CAR_VALUE,
            substeps: [
                RENEW_PAGE_ADJUST_CAR_VALUE,
            ]
        },
        {
            name: RENEW_PAGE_ENHANCE_YOUR_COVERAGE,
            substeps: [
                RENEW_PAGE_ENHANCE_YOUR_COVERAGE,
            ]
        },
        {
            name: RENEW_PAGE_REVIEW_AND_CONFIRM,
            substeps: [
                RENEW_PAGE_REVIEW_AND_CONFIRM,
            ]
        },
    ],
    [RENEW_POLICY_DOWNGRADE_PLAN]: [
        ...commonFlow,
        {
            name: RENEW_PAGE_CHOOSE_DOWNGRADE_PLAN,
            substeps: [
                RENEW_PAGE_CHOOSE_DOWNGRADE_PLAN
            ]
        },
        {
            name: RENEW_PAGE_ENHANCE_YOUR_COVERAGE,
            substeps: [
                RENEW_PAGE_ENHANCE_YOUR_COVERAGE,
            ]
        },
        {
            name: RENEW_PAGE_REVIEW_AND_CONFIRM,
            substeps: [
                RENEW_PAGE_REVIEW_AND_CONFIRM,
            ]
        },
    ],
};
