

export const VEHICLE_DAMAGE = "VEHICLE_DAMAGE";
export const PROPERTY_DAMAGE = "PROPERTY_DAMAGE";
export const BODILY_INJURY = "BODILY_INJURY";

export const POLICY_PLANS_TYPES = [
    {
        type : VEHICLE_DAMAGE,
        title : "Vehicle Damages",
        ar_title : "أضرار المركبة",
        description : "Damage to your vehicle from an accident",
        ar_description : "أضرار لمركبتك من الحادث",
    },
    {
        type : PROPERTY_DAMAGE,
        title : "Property Damages",
        ar_title : "أضرار الممتلكات",
        description : "Damage caused to your property",
        ar_description : "أضرار لحقت بممتلكاتك",
    },
    {
        type : BODILY_INJURY,
        title : "Bodily Injury",
        ar_title : "الإصابة الجسدية",
        description : "Injuries you experienced in accident",
        ar_description : "إصابات تعرضت لها في الحادث",
    },
   
]