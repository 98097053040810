import React from "react";
import Awaiting from "../../../assets/images/rederror.svg";
import Approved from "../../../assets/images/check_circledark.svg";
import Rejected from "../../../assets/images/remove_circle.svg";

import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { STATUS_APPROVED, STATUS_REJECTED, STATUS_WAITING_FOR_APPROVAL } from "../../../pages/lifeInsurancePages/PolicyOverview/manageMyPolicy/data";
import { calculateDateDifference, calculateYearsMonthsRemaining, currencyFormatterWithCountryCode, dateFormatter } from "../../../utils";

const PerformanceCard = ({ info,historyData,cardData, className, onClick = () => { }, status  , btnOnClick=()=>{}}) => {
  const translate = useLanguageTranslate();
  const getCardDataValue = (type, info) => {
  
    const { PolicyDetails, LifeDetails } = info || {};
    switch (type) {
      case "startDate":
        return dateFormatter(PolicyDetails?.StartDate,"d MMM yyyy",translate("en","ar"));
      case "endDate":
        return dateFormatter(PolicyDetails?.EndDate,"d MMM yyyy",translate("en","ar"));
      case "paymentCycle":
      case "paymentHistoryContributionCycle":
        return LifeDetails?.policyList?.[0]?.paymentCycle;
      case "termRemaining":
        return calculateYearsMonthsRemaining(
          new Date(),
          PolicyDetails?.EndDate,
          translate("years","سنوات"),
          translate("months","أشهر")
        );

      case "policyTerm":
        return calculateDateDifference(
          PolicyDetails?.StartDate,
          PolicyDetails?.EndDate,
          "years",
          translate("years","سنوات"),
        );
      case "fundperformanceCurrentValue":
        return currencyFormatterWithCountryCode(
          LifeDetails?.policyList?.[0]?.currentValue
        );
      case "fundperformanceCurrentEncashment":
        return currencyFormatterWithCountryCode(LifeDetails?.policyList?.[0]?.currentEncashmentValue);
      case "fundperformanceTotalPremiumInvested":
        return currencyFormatterWithCountryCode(
          LifeDetails?.policyList?.[0]?.totalPremiumInvested
        );
      case "fundperformanceTotalPremiumPaid":
        return currencyFormatterWithCountryCode(
          LifeDetails?.policyList?.[0]?.totalPremiumPaid
        );
      case "fundperformanceTotalWithdrawals":
        return currencyFormatterWithCountryCode(LifeDetails?.policyList?.[0]?.currentEncashmentValue);
      case "paymentHistoryLastPayment":
        return currencyFormatterWithCountryCode(`${historyData?.[1]?.currencyCode} ${historyData[1]?.amountReceived}`);
      default:
        return translate(cardData.value, cardData?.value_ar) ;
    }
  };

  return (
    <div onClick={() => onClick(cardData)} className={`${className} relative ${cardData.isHide ? "hidden" : ""}`}>
      <div className="flex-col ipad:flex ipad:self-start max767:flex-col managemypolicycard ipad:flex-row flex-row items-center max767:gap-2">
        {cardData?.icon && (
          <img
            src={cardData?.icon}
            alt=""
            className={`w-9 h-9 ${translate("mr-4", "ml-4")}  ipad:mb-2`}
          />
        )}
        <div className="flex flex-col mt-4 max767:mt-2 max767:gap-0 ipad:mt-2 ipad:gap-0 max767:mb-0 ftext">
          <div className="text-[#252C32] text-xs  not-italic font-semibold ipad:text-[#252C32] ipad:text-xs ipad:not-italic ipad:font-normal max767:text-xs cardvalue max767:text-[#252C32]">
            {translate(cardData?.label_en, cardData?.label_ar)}
          </div>
          <div className="ipad:leading-none bhdvalue">
            {/* <span className="text-[#252C32] text-xs not-italic font-semibold leading-[normal] ipad:text-xs  ipad:text-[#1A2024] ipad:leading-[120%] max767:text-[#252C32] max767:leading-[120%] max767:text-lg">
              {translate(cardData?.countryCode, cardData?.countryCode_ar)}
            </span> */}
            <span className="text-xl ftext font-extrabold ipad:text-xs ipad:font-black  ipad:text-[#1A2024] ipad:leading-[120%] max767:text-[#252C32] max767:leading-[120%] max767:text-lg max767:font-normal">
              {getCardDataValue(cardData?.type, info)}
            </span>
          </div>
        </div>
      </div>
      {cardData?.btn && (
        <button
          onClick={() => btnOnClick(cardData)}
          className={`absolute ${translate(
            "right-[17px]",
            "left-[17px]"
          )} items-center justify-center mx-auto my-0 px-3 py-1 top-[30px] ipad:right-0 bottom-9 flex gap-2 border ml-[25px] bg-white h-[28px] text-[#252C32] text-xs not-italic font-semibold leading-4 m-[10px] rounded-md border-solid border-[#DDE2E4] changemethodbtn ipad:top-[10px] max767:relative  max767:w-full max767:justify-center max767:ml-0 max767:mt-0 max767:left-0 max767:top-0`}
        >
          {translate(cardData?.btn?.btn_label_en, cardData?.btn?.btn_label_ar)}
          {cardData?.btn?.btn_icon && (
            <img src={cardData?.btn?.btn_icon} alt="icon" />
          )}
        </button>
      )}
      {status && (
        <>
          {status == STATUS_WAITING_FOR_APPROVAL && (
            <div className="flex items-center gap-1 p-1 bg-white rounded-md">
              <img src={Awaiting} alt="icon" />
              <p className="text-[#A56213] text-xs not-italic font-semibold leading-4">
                {translate("Awaiting approval", "في انتظار الموافقة")}
              </p>
            </div>
          )}
          {status == STATUS_APPROVED && (
            <div className="flex items-center gap-1 p-1 bg-white rounded-md">
              <img src={Approved} alt="icon" />
              <p className="text-[#119C2B] text-xs not-italic font-semibold leading-4">
                {translate("Approved", "موافقة")}
              </p>
            </div>
          )}
          {status == STATUS_REJECTED && (
            <div className="flex items-center gap-1 p-1 bg-white rounded-md">
              <img src={Rejected} alt="icon" />
              <p className="text-[#F76659] text-xs not-italic font-semibold leading-4">
                {translate("Rejected", "مرفوض")}
              </p>
            </div>
          )}
        </>
      )}
      {cardData?.call_option && (
        <div className="flex">
          {cardData?.call_option?.icon && (
            <img
              src={cardData?.call_option?.icon}
              alt=""
              className="h-6 w-6 mb-2 mr-2 ipad:hidden max767:hidden rtl:mr-0 rtl:ml-2"
            />
          )}
          <span className="text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] ipad:text-[#1A2024] ipad:text-xs ipad:font-medium">
            {translate(cardData?.call_option?.lable_en, cardData?.call_option?.label_ar)}
          </span>
        </div>
      )}
    </div>
  );
};

export default PerformanceCard;
