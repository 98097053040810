import React, { useEffect, useMemo, useState } from "react";
import PolicyHeroCard from "./PolicyHeroCard";
import Breadcrumb from "./Breadcrumb";
import Tabs from "../../../components/common/tabs/tabs.jsx";
import {
  tabs,
  policyInfo,
  fundPerformanceData,
  overviewTabData,
  paymentsHistoryData,
  BREADCRUMP_ITEMS,
  findPlanType,
  BUY_ONLINE_PLANS,
  PROTECTION_PLANS,
  SAVING_PROTECTION,
} from "./data.js";
import FundPerformance from "./fundPerformance/fundPerformance.jsx";
import OverviewTab from "./OverviewTab/OwerviewTab.jsx";
import PaymentHistory from "./paymentHistory/paymentHistory.jsx";
import FundManagement from "./fundManagementTab/fundManagement.jsx";
import ManagePolicyTab from "./manageMyPolicy/managePolicyTab.jsx";
import useScreenResolution from "../../../hooks/useScreenResolution.js";
import MobileFooter from "../../../components/common/sideNavbar/mobileFooter";
import useLifePolicyDetails from "../../../hooks/life/useLifePolicyDetails.js";

import { decodeQueryString } from "../../../utils/index.js";
import { Loader } from "storybook/internal/components";
import { useSearchParams } from "react-router-dom";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate.js";
import { useTranslation } from "react-i18next";

const PolicyOverview = () => {
  const decodedQueryString = decodeQueryString();
  const { data, isLoading } = useLifePolicyDetails(decodedQueryString?.policy);
  const [searchParams] = useSearchParams();
  const policyStatus = searchParams.get("status");
  const translate  = useLanguageTranslate();

  const [breadCrumbData, setBreadCrumbData] = useState(BREADCRUMP_ITEMS);
  const [activeTab, setActiveTab] = useState("PolicyOverview");
  const [downloadMenuClicked, setDownloadMenuClicked] = useState(false);
  const screenResolution = useScreenResolution();
  const {i18n} = useTranslation()
  const toggleDownloadMenuClicked = () => {
    setDownloadMenuClicked(!downloadMenuClicked);
  };
  const renderActiveTabContent = () => {
    switch (activeTab) {
      case "PolicyOverview":
        return (
          <OverviewTab
            cards={overviewTabData.cards}
            info={data}
            chngBeneficiaryCardData={overviewTabData.chngBeneficiaryCard}
            downloadCardData={data?.Reports}
            tableData={overviewTabData.table}
            handleDownloadMenuClicked={toggleDownloadMenuClicked}
            showDownloadPopup={downloadMenuClicked}
          />
        );
      case "FundPerformance":
        return (
          <FundPerformance
            cardsData={fundPerformanceData.cards}
            info={data}
            chartsData={fundPerformanceData.charts}
          />
        );
      case "PaymentsHistory":
        return <PaymentHistory info={data} />;
      case "FundManagement":
        return <FundManagement />;
      case "ManagePolicy":
        return <ManagePolicyTab info={data} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    setBreadCrumbData([
      {
        label: translate("My Policy","وثيقتي"),
        link: "/my-policy",
        clickable: true,
        name: 'my_policy'
      },
      {
        label: data?.PolicyDetails?.ProductName,
        clickable: false,
        name: "current_plan",
        isActive: true,
      },
    ]);
  }, [i18n?.resolvedLanguage,data]);

  const params = decodeQueryString();

  useEffect(() => {
    if (params.tabType) {
      setActiveTab(params.tabType);
    }
  }, [params?.tabType]);

  const disabledTabs = useMemo(()=>{
    let disableData = []
     if(policyStatus =="EXPIRED") {
      disableData.push("ManagePolicy")
     }
     let planType = findPlanType(data?.PolicyDetails?.ProductCode) || SAVING_PROTECTION
     if(planType===BUY_ONLINE_PLANS){
      disableData.push("FundManagement")
      disableData.push("PaymentsHistory")
      disableData.push("FundPerformance")
      //disableData.push("ManagePolicy")
     }
     else if (planType===PROTECTION_PLANS){
        disableData.push("FundPerformance")
        disableData.push("FundManagement")
     }
     return disableData
  },[data])

  return (
    <div className="p-6 max767:p-0 max767:m-0">
      {isLoading && <Loader />}
      <div className="flex justify-between items-center">
        {!screenResolution.mobile && <Breadcrumb items={breadCrumbData} />}
        {/* {!screenResolution.mobile && (
          <span className="hidden last-login text-xs not-italic font-normal leading-4 text-gray-700">
            Last Login: 03 November 2024 at 05:20{" "}
          </span>
        )} */}
      </div>

      <PolicyHeroCard
        info={decodedQueryString}
        content={data}
        handleDownloadMenuClicked={toggleDownloadMenuClicked}
      />
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onTabClick={setActiveTab}
        disabledTabs={disabledTabs || []}
      />
      {renderActiveTabContent()}
      {screenResolution.mobile && <MobileFooter />}
    </div>
  );
};

export default PolicyOverview;
