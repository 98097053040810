import React, { useEffect } from "react";
import useChangeMobileNumberFlow from "../../../hooks/useChangeMobileNumberFlow";
import { ACCOUNT_PASSWORD_PAGE, CHANGE_EMAIL_FLOW, REGISTER_SUCCESS_PAGE, UPDATED_MOBILE_NUMBER_PAGE, VERIFY_OTP_PAGE } from "./data";
import AccountPasswordPage from "./accountPasswordPage";
import ChangeMobileNumberPage from "./changeMobileNumberPage";
import VerifyOtp from "./verifyOtpPage";
import UpdateSucccessPage from "./updateSuccessPage";
import { useDispatch } from "react-redux";
import { resetChangeMobileNumberFlow } from "../../../redux/features/changeMobileNumberSlice";

const ChangeMobileNumberFlow = () => {

  const { currentStep } = useChangeMobileNumberFlow();
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(resetChangeMobileNumberFlow());
  }, [dispatch]);

  const PageRenderer = () => {
    switch (currentStep?.name) {
      case ACCOUNT_PASSWORD_PAGE:
        return <AccountPasswordPage/>;
      case UPDATED_MOBILE_NUMBER_PAGE:
        return <ChangeMobileNumberPage/>;
      case VERIFY_OTP_PAGE:
        return <VerifyOtp/>;
      case REGISTER_SUCCESS_PAGE:
        return <UpdateSucccessPage/>;
      default:
        return null;
    }
  };
  return (
    <div className="changeMobileNumberModal pt-[0%] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pt-[7%] max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll ">
      <PageRenderer />
    </div>
  );
};

export default ChangeMobileNumberFlow;
