/* :Page:MotorRenewal: Renewable policies list page */

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import useRenewalFlow, { RENEW_POLICY_LOGGED_IN, RENEW_POLICY_SAME_PLAN } from '../../../../hooks/useRenewalFlow'
import RenewMotorLayout from '../renewMotorLayout';
import TitleDescription from '../../../../components/newMotorPolicy/titleDescription/titleDescription';
import { USER_POLICIES } from './data';
import PolicyCard from '../../../../components/policyCard/policyCard';
import { toast } from 'react-toastify';
import { screenResolution } from '../../../../utils/screenResolution';
import useMotorForm from '../../../../hooks/useMotorFormData';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserPolicies, selectUserPoliciesData, selectUserPoliciesLoader } from '../../../../redux/features/myPolicySlice';
import { selectLoginTokenInfo } from '../../../../redux/features/loginRegisterSlice';
import Loader from '../../../../components/common/Loader/Loader';
import { unwrapResult } from '@reduxjs/toolkit';
import { policyPremium } from '../../../../redux/features/motor/renewalPremiumSlice';
import useLanguageTranslate from '../../../../hooks/useLanguageTranslate';
import { getCoverPrices, getCoverPricesLoading } from '../../../../redux/features/motor/getQuoteSlice';
import { coverPriceRequest } from '../../newMotorPolicy/planPages/data';
import { clearApplyPromoState } from '../../../../redux/features/promoCode/promoCodeSlice';
import { PROMO_CODE } from '../../../../components/newMotorPolicy/veiwSummaryPopup/viewSummaryPopup';

export const RENEW_POLICY_SELECTED_POLICY = "RENEW_POLICY_SELECTED_POLICY";
const SelectPoliciesPage = () => {
  /* Setting up dispatch to call redux */
  const dispatch = useDispatch();
  const translate = useLanguageTranslate()
  const [formState, setFormState] = useState(null);

  /* Setting up variables with appropriate requests to send and response to receive, reading from redux/functions */
  const [selected, setSelected] = useState()
  const loginTokenInfo = useSelector(selectLoginTokenInfo);
  let isLoggedin = loginTokenInfo;
  const fetchPoliciesLoading = useSelector(selectUserPoliciesLoader);
  const coverPricesLoading = useSelector(getCoverPricesLoading);
  /* Functions and Variables that are updated with functions/state */

  const { t } = useTranslation();
  const { handleNextSubstep, handleFlowChange } = useRenewalFlow();
 const { saveMotorDataInRedux } = useMotorForm(
   RENEW_POLICY_SELECTED_POLICY,
   setSelected
 );
 const { removeMotorFormPromoCodeData } = useMotorForm(
  PROMO_CODE,
  setFormState
);

  // later can take polices from below selector
  var userPolicies = useSelector(selectUserPoliciesData)
  // const userPolicies = USER_POLICIES;
  const renewablePolicies = userPolicies?.filter(
    (policy) => policy.IsRenewable && policy.PolicyStatus =="ACTIVE POLICY"
  );

  /* Use Effects, monitor changes to call on [var] change or one time call */
  if (isLoggedin) {
    console.log("LOGGED IN!!"); 
    // Pages index for RENEW_POLICY_LOGGED_IN: 1 Policies, 2 Sum Insured, 3 Covers
  } else {
    // Pages index for default RENEW_POLICY_SAME_PLAN: 0 CPR, 1 OTP, 2 Policies, 3 Review Plan (Flow can change, below added), 4 Sum Insured, 5 Covers, 6 Payment Summary
    // Pages index for default RENEW_POLICY_SAME_PLAN with addition of Change Plan RENEW_POLICY_DOWNGRADE_PLAN: 4 Change plan 5 Covers ...
    console.log("NOT LOGGED IN!!");
    // handleFlowChange(RENEW_POLICY_SAME_PLAN, ); 
  }

  useEffect(() => {
    // Fetch policies if they are not already available
    if (!userPolicies || userPolicies.length ==0) {
      let payload =
      {
          "Cpr": isLoggedin?.userName,
          "Channel": "online.solidarity"
      }

      dispatch(fetchUserPolicies(payload));
      
    }
  }, [dispatch, userPolicies]);

  const selectClickHandler = async (data) => {
    setSelected(data)
  }

  /* Next */
  const continueClickHanlder = async () => {
    if (!selected) {
      toast.error("Select at least one policy")
      return;
    }
    console.log('Selected Plan is: ', selected);
    try {
      // Add default covers for platinum
      const policyPremiumPayload =  {
        PolicyNumber: selected.PolicyNumber,
        Channel: "online.solidarity",
        Covers: [],
        DefaultCovers: false,
        NewPlan: selected.PlanName
      }

      // Clear promo code 
      await dispatch(clearApplyPromoState());
      await removeMotorFormPromoCodeData();
      // Calling API /policy/premium
      const policyPremiumAction = await dispatch(policyPremium(policyPremiumPayload));
      const policyPremiumResult = unwrapResult(policyPremiumAction);

      console.log("Premium result is: ", policyPremiumResult)
      console.log("Premium new quote is: ", policyPremiumResult.PolicyNumber)
      console.log("Premium total is: ", policyPremiumResult.TotalPremium)

        
      if (policyPremiumResult) {
        // Call covers prices 
         // Cover prices
         const coverPricesPayload = coverPriceRequest(
          selected.PolicyNumber,
          selected.PolicyNumber.PlanName,
          "",
          ""
        );
        await dispatch(getCoverPrices(coverPricesPayload));

        // handleNextSubstep();
        // Add the string originalPlanName to the result
        const updatedPolicyPremiumResult = {
          ...policyPremiumResult, // Spread the original policy premium result
          OriginalPlanName: policyPremiumResult.PlanName, // Add the OriginalPlanName field
          OriginalPolicyNo: policyPremiumResult.QuoteDetails.PreviousPolicyNumber, // Add the OriginalPolicyNo field
        };

        saveMotorDataInRedux(updatedPolicyPremiumResult);
        handleNextSubstep();
      }

    }
    catch {
      // alert("Could not get policy premium.")
      toast.error("Could not get policy premium.");
    }
  }

  return (
    <RenewMotorLayout is_hide_back_btn show_close_btn btn_onClick={continueClickHanlder} btn_disabled={!renewablePolicies || renewablePolicies.length ==0}>
      <div className="selectpolicy p-4 w-[33%] max-350:w-[100%] max767:w-[100%] ipad:w-[55%] mx-auto max767:p-5 max767:pt-0">
        <TitleDescription
          title={t("motorInsurance.renewalPolicyFlow.selectPoliciesPage.title")}
          description={
            screenResolution.mobile
              ? "Select Your Policy to renew"
              : t(
                  "motorInsurance.renewalPolicyFlow.selectPoliciesPage.description"
                )
          }
          className="mb-6"
        />
        <div className=" ">
          <div className="bg-gray-100 p-4 rounded-[26px] max767:bg-white max767:p-0">
            {!screenResolution.mobile && (
              <p className="mb-4 text-center text-[#1A2024] text-base not-italic font-medium leading-[26px] tracking-[-0.16px] max767:text-left">
                {translate("Select Your Policy to renew","اختر وثيقتك للتجديد")}
              </p>
            )}
            <div className="grid justify-center gap-4 policycardbox">
              {(() => {

                if (!renewablePolicies || renewablePolicies.length ==0) {
                  if (fetchPoliciesLoading || coverPricesLoading) {
                    return <Loader />
                  }
                  else {
                    return (
                      <p className="text-center">
                        {translate("You have no policies due for renewal","ليس لديك وثائق مستحقة للتجديد")}
                      </p>
                    );
                  }

                }



                return [[[[renewablePolicies.map((each, index) => (
                  <PolicyCard
                  
                    key={index}
                    policy_name={each.policy_name}
                    policy_label={each.policy_label}
                    policy_number={each.policy_number}
                    from_date={each.from_date}
                    to_date={each.to_date}
                    policy_bg_image={each.policy_bg_image}
                    expire_days={each.expire_days}
                    is_text_dark={each.is_text_dark}
                    mapping_key={each.id}
                    data={each}
                    showSelectButton
                    isSelected={selected?.PolicyNumber ==each?.PolicyNumber}
                    selectClickHandler={() => selectClickHandler(each)}
                  />
                ))]]]];
              })()}
            </div>
          </div>
        </div>
      </div>
    </RenewMotorLayout>
  );
}

export default SelectPoliciesPage