export const FILE_CLAIM_FLOW = "FILE_CLAIM_FLOW";
// FLOW PAGES
export const CUSTOMER_TYPE = "CUSTOMER_TYPE";
export const POLICY_TYPE = "POLICY_TYPE";
export const REQUIRED_DOCS_INFO = "REQUIRED_DOCS_INFO";
export const POLICY_PLANS = "POLICY_PLANS";
export const YOUR_INFO = "YOUR_INFO";
export const ACCIDENT_INFO = "ACCIDENT_INFO";
export const REPAIR_OR_CASH = "REPAIR_OR_CASH";
export const UPLOAD_DOCS = "UPLOAD_DOCS";
export const REVIEW_AND_CONFIRM = "REVIEW_AND_CONFIRM";
export const CLAIM_CREATED = "CLAIM_CREATED";

export const NON_SOLIDARITY_POLICY_PLANS_PAGE = "NON_SOLIDARITY_POLICY_PLANS_PAGE"

// PROPERTY DAMAGE
export const PROPERTY_DAMAGE_YOUR_INFO = "PROPERTY_DAMAGE_YOUR_INFO";

// BODILY INJURY
export const BODILY_INJURY_YOUR_INFO = "BODILY_INJURY_YOUR_INFO";


// NON SOLIDARITY FLOW TYPES
export const FILE_CLAIM_VEHICLE_DAMAGE_FLOW = "FILE_CLAIM_VEHICLE_DAMAGE_FLOW"
export const FILE_CLAIM_PROPERTY_DAMAGE_FLOW = "FILE_CLAIM_PROPERTY_DAMAGE_FLOW"
export const FILE_CLAIM_BODILY_INJURY_FLOW = "FILE_CLAIM_BODILY_INJURY_FLOW"
export const FILE_CLAIM_NON_MOTOR_POLICY_HOLDER = "FILE_CLAIM_NON_MOTOR_POLICY_HOLDER"


export const SOLIDARITY_CUSTOMER = "SOLIDARITY_CUSTOMER"
export const THIRD_PARTY_CUSTOMER = "THIRD_PARTY_CUSTOMER"
export const REPORTING_TYPES_DATA = [
  {
    type: SOLIDARITY_CUSTOMER,
    title: "I’m a Solidarity customer",
    ar_title: "أنا عميل سوليدرتي",
    description: "I have an account & insurance policies with Solidarity",
    ar_description: "لدي حساب ووثائق تأمين مع شركة سوليدرتي",
  },
  {
    type: THIRD_PARTY_CUSTOMER,
    title: "I’m not a Solidarity customer (Third Party)",
    ar_title: "أنا لست عميلاً لسوليدرتي (طرف ثالث)",
    description: "I don’t have an account or insurance policies with Solidarity",
    ar_description: "ليس لدي حساب أو وثيقة تأمين مع سوليدرتي",
  },
];




// DOCUMENTS INFO DATA
const alertMessage = {
  label_ar: "يرجى ملاحظة أنه إذا كنت المتسبب في الحادث أو كان عمر سيارتك أكثر من 3 سنوات (أو حسب ما هو مذكور في وثيقتك)، قد تكون مسؤولاً عن دفع رسوم الاقتطاع أو الاستهلاك كجزء من مطالبة التأمين.",
  label_en: "Kindly note that if you caused the accident or your vehicle is older than 3 years (or as stated in your policy), you may be responsible for paying a deductible or depreciation amount as part of your insurance claim."
}

const incidentReport = {
  input_label_ar: "تقرير الحادث من إدارة المرور",
  input_label_en: "Incident report from Traffic Directorate",
  isMultiple: false,
  name: "incident_report"
}

const incidentReportShort = {
  label_ar: "تقرير الحادث من إدارة المرور",
  label_en: "Incident report from Traffic Directorate",
  isMultiple: false,
  name: "incident_report"
}

export const UPLOAD_DOCS_INPUT = {
  solidarity_user: {
    docs: [
      {
        input_label_ar: "رخصة القيادة وقت الحادث (أمامية وخلفية)",
        input_label_en: "Driver license at the time of accident (Front & Back)",
        isMultiple: true,
        name: "driver_license",
      },
      {
        input_label_ar: "شهادة ملكية المركبة (أمامية وخلفية)",
        input_label_en: "Vehicle ownership certificate (Front & Back)",
        isMultiple: true,
        name: "vehicle_ownership_certificate",
      },
      {
        input_label_ar: "صور الحادث",
        input_label_en: "Photos of the accident",
        isMultiple: true,
        name: "photos_of_accident",
      },
      incidentReport
    ],
  },
  non_solidarity_vehicle_damage: {
    docs: [
      {
        input_label_ar: "رخصة القيادة وقت الحادث (أمامية وخلفية)",
        input_label_en: "Driver license at the time of accident (Front & Back)",
        isMultiple: true,
        name: "driver_license",
      },
      {
        input_label_ar: "شهادة التأمين",
        input_label_en: "Insurance Certificate",
        isMultiple: true,
        name: "insurance_certificate",
      },
      {
        input_label_ar: "شهادة ملكية المركبة (أمامية وخلفية)",
        input_label_en: "Vehicle ownership certificate (Front & Back)",
        isMultiple: true,
        name: "vehicle_ownership_certificate",
      },
      {
        input_label_ar: "صور الحادث",
        input_label_en: "Photos of the accident",
        isMultiple: true,
        name: "vd_clear_photos_of_the_accident",
      },
      incidentReport
    ],
  },
  non_solidarity_property_damage: {
    docs: [
      {
        input_label_ar: "رقم CPR لمالك العقار (أمامية وخلفية)",
        input_label_en: "Property Owner CPR Number (Front & Back)",
        isMultiple: true,
        name: "property_owner_cpr_number",
      },
      {
        input_label_ar:
          "إثبات ملكية العقار (مثل فاتورة الكهرباء، بطاقة العنوان، إلخ)",
        input_label_en:
          "Proof of Property Ownership (e.g electric bill, address card, etc.)",
        isMultiple: true,
        name: "proof_of_property_ownership",
      },
      {
        input_label_ar: "صور الحادث",
        input_label_en: "Photos of the accident",
        isMultiple: true,
        name: "clear_photos_of_the_accident",
      },
      incidentReport
    ],
  },
  non_solidarity_bodily_injury: {
    docs: [
      {
        input_label_ar: "رقم CPR للشخص المتضرر (أمامية وخلفية)",
        input_label_en: "CPR Number of the Impacted Person (Front & Back)",
        isMultiple: true,
        name: "cpr_number_of_the_impacted_person",
      },
      {
        input_label_ar: "التقرير القضائي",
        input_label_en: "Judicial Report",
        isMultiple: true,
        name: "judicial_report",
      },
      {
        input_label_ar:
          "مستندات أخرى (وصف: التقرير الطبي الصادر عن اللجان الطبية)",
        input_label_en:
          "Other Documents (Description: Medical Report issued by Medical Committees)",
        isMultiple: true,
        name: "other_docs"
      },
      incidentReport
    ],
  },
  solidarity_user_non_motor: {
    docs: [
      {
        input_label_ar: "شهادة التأمين",
        input_label_en: "Insurance Certificate",
        isMultiple: true,
        name: "insurance_certificate",
      },
      {
        input_label_ar: "شهادة ملكية المركبة (أمامية وخلفية)",
        input_label_en: "Ownership Card (Front & Back)",
        isMultiple: true,
        name: "ownership_card",
      },
      {
        input_label_ar: "صور الأضرار",
        input_label_en: "Photos of the Damages",
        isMultiple: true,
        name: "photos_of_damages",
      },
      {
        input_label_ar: "تقرير الشرطة (اختياري)",
        input_label_en: "Police Report (Optional)",
        isMultiple: true,
        isOptional: true,
        name: "police_report",
      },
      {
        input_label_ar: "خطاب IBAN من المالك (اختياري)",
        input_label_en: "IBAN Letter of Owner (Optional)",
        isMultiple: false,
        isOptional: true,
        name: "iban_letter_of_owner",
      }
    ],
  },
};


export const REQUIRED_DOCS_INFO_DATA = {

  solidarity_user: {
    docs: [
      {
        label_ar: "صور واضحة من الحادث",
        label_en: "Clear Photos of the Accident",
      },
      {
        label_ar: "شهادة ملكية السيارة",
        label_en: "Vehicle Ownership Certificate",
      },
      {
        label_ar: "رخصة القيادة",
        label_en: "Driving License",
      },
      incidentReportShort
    ],
    alertMessage,
  },
  non_solidarity_vehicle_damage: {
    docs: [
      {
        label_ar: "صور واضحة من الحادث",
        label_en: "Clear Photos of the Accident",
      },
      {
        label_ar: "شهادة ملكية السيارة",
        label_en: "Vehicle Ownership Certificate",
      },
      {
        label_ar: "رخصة القيادة",
        label_en: "Driving License",
      },
      {
        label_ar: "شهادة التأمين",
        label_en: "Insurance Certificate",
      },
      incidentReportShort
    ],
    alertMessage,
  },
  non_solidarity_property_damage: {
    docs: [
      {
        label_ar: "صور واضحة من الحادث",
        label_en: "Clear Photos of the Accident",
      },
      {
        label_ar: "رقم CPR لمالك العقار",
        label_en: "Property Owner CPR Number",
      },
      {
        label_ar: "إثبات ملكية العقار",
        label_en: "Proof of Property Ownership",
      },
      {
        label_ar: "صور أضرار العقار",
        label_en: "Property Damage Photos",
      },
      incidentReportShort
    ],
    alertMessage,
  },
  non_solidarity_bodily_injury: {
    docs: [
      {
        label_ar: "صور واضحة من الحادث",
        label_en: "Clear Photos of the Accident",
        input_label_ar: "",
        input_label_en: "",
        isMultiple: true,
        name: "clear_photos_of_the_accident",
      },
      {
        label_ar: "رقم CPR للشخص المتضرر",
        label_en: "CPR Number of the Impacted Person",
      },
      {
        label_ar: "التقرير القضائي",
        label_en: "Judicial Report",
      },
      incidentReportShort
    ],
    alertMessage,
  },
};
