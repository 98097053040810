/* :Page:MotorRenewal: Current plan review page */

import React, { useEffect, useState } from "react";
import useRenewalFlow, {
  RENEW_POLICY_DOWNGRADE_PLAN,
  RENEW_POLICY_LOGGED_IN,
} from "../../../../hooks/useRenewalFlow";
import RenewMotorLayout from "../renewMotorLayout";
import { useTranslation } from "react-i18next";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import CurrentPlanDetailCard from "./currentPlanDetailCard";
import { CURRENT_PLAN } from "../chooseDowngradePlanPage/data";
import { useDispatch, useSelector } from "react-redux";
import { selectNewMotorForm } from "../../../../redux/features/newMotorFormSlice";
import { RENEW_POLICY_SELECTED_POLICY } from "../selectPoliciesPage/selectPoliciesPage";
import {
  getPolicyPremiumResponse,
  getPolicyPremiumResponseQuoteDetails,
  policyPremium,
} from "../../../../redux/features/motor/renewalPremiumSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { selectLoginTokenInfo } from "../../../../redux/features/loginRegisterSlice";
import useMotorForm from "../../../../hooks/useMotorFormData";
import { RENEW_POLICY_SELECTED_PLAN } from "../chooseDowngradePlanPage/chooseDowngradePlanPage";
import { getPolicyPayLoading } from "../../../../redux/features/payment/policyPaySlice";
import { coverPriceRequest } from "../../newMotorPolicy/planPages/data";
import { getCoverPrices } from "../../../../redux/features/motor/getQuoteSlice";
import { clearApplyPromoState } from "../../../../redux/features/promoCode/promoCodeSlice";

const ReviewYourPolicyPage = () => {
  /* Setting up dispatch to call redux */
  const dispatch = useDispatch();
  const [selected, setSelected] = useState();

  /* Setting up variables with appropriate requests to send and response to receive, reading from redux/functions */
  const { t } = useTranslation();
  const { handleNextSubstep, handleFlowChange } = useRenewalFlow();
  const { saveMotorDataInRedux } = useMotorForm(
    RENEW_POLICY_SELECTED_POLICY,
    setSelected
  );

  const { removeMotorFormData } = useMotorForm(
    RENEW_POLICY_SELECTED_PLAN,
    setSelected
  );

  const selectedPolicyRenewalInfo =
    useSelector(selectNewMotorForm)?.[RENEW_POLICY_SELECTED_POLICY];
  const selectedChangedPlan =
    useSelector(selectNewMotorForm)?.[RENEW_POLICY_SELECTED_PLAN];

  const policyPremiumResponseInfo = useSelector(
    getPolicyPremiumResponseQuoteDetails
  );
  const isPolicyPremiumLoading = useSelector(getPolicyPayLoading);
  const loginTokenInfo = useSelector(selectLoginTokenInfo);
  let isLoggedin = loginTokenInfo;

  // Below is because when we open change plan, the flow changes. So this is to get back to original flow if we go back from change plan
  if (isLoggedin) {
    console.log("LOGGED IN!!");
    // Pages index for RENEW_POLICY_LOGGED_IN: 1 CPR, 2 Sum Insured, 3 Covers
    handleFlowChange(RENEW_POLICY_LOGGED_IN, 1);
  } else {
    // Pages index for default RENEW_POLICY_SAME_PLAN: 0 CPR, 1 OTP, 2 Policies, 3 Review Plan (Flow can change, below added), 4 Sum Insured, 5 Covers, 6 Payment Summary
    // Pages index for default RENEW_POLICY_SAME_PLAN with addition of Change Plan RENEW_POLICY_DOWNGRADE_PLAN: 4 Change plan 5 Covers ...
    console.log("NOT LOGGED IN!!");
  }

  useEffect(() => {
    const abortController = new AbortController();
    // Call original premium if plan is different

    console.log("Plan was changed, call original premium with");
    if (
      selectedChangedPlan != undefined && // Check if it exists in Redux
      selectedChangedPlan != "" && // Check if it is not an empty string
      selectedChangedPlan != selectedPolicyRenewalInfo.OriginalPlanName // Check if plans are different
    ) {
      console.log("Plans are different, calling original premium");
      callPremium();
    }

    // Cleanup function to cancel the API call
    return () => {
      console.log("Aborting API call due to page change or unmount");
      abortController.abort();
    };
  }, []);

  const callPremium = async () => {
    try {
      // Add default covers for platinum
      const policyPremiumPayload = {
        PolicyNumber: selectedPolicyRenewalInfo.OriginalPolicyNo,
        Channel: "online.solidarity",
        Covers: [],
        DefaultCovers: false,
        NewPlan: selectedPolicyRenewalInfo.OriginalPlanName,
      };

      // Clear promo code
      await dispatch(clearApplyPromoState());

      // Calling API /policy/premium
      const policyPremiumAction = await dispatch(
        policyPremium(policyPremiumPayload)
      );
      const policyPremiumResult = unwrapResult(policyPremiumAction);

      console.log("Premium result is: ", policyPremiumResult);
      console.log("Premium new quote is: ", policyPremiumResult.PolicyNumber);
      console.log("Premium total is: ", policyPremiumResult.TotalPremium);

      if (policyPremiumResult) {
        // Call cover prices
        // Cover prices
        const coverPricesPayload = coverPriceRequest(
          selectedPolicyRenewalInfo.OriginalPolicyNo,
          selectedPolicyRenewalInfo.OriginalPlanName,
          "",
          ""
        );
        await dispatch(getCoverPrices(coverPricesPayload));

        // handleNextSubstep();
        const updatedPolicyPremiumResult = {
          ...policyPremiumResult, // Spread the original policy premium result
          OriginalPlanName: policyPremiumResult.PlanName, // Add the OriginalPlanName field
          OriginalPolicyNo:
            policyPremiumResult.QuoteDetails.PreviousPolicyNumber, // Add the OriginalPolicyNo field
        };

        saveMotorDataInRedux(updatedPolicyPremiumResult);
        // saveMotorDataInRedux(policyPremiumResult);
        removeMotorFormData({});
      }
    } catch {
      // alert("Could not get policy premium.")
      toast.error("Could not get policy premium.");
    }
  };

  /* Next */
  const continueClickHanlder = async () => {
    console.log(
      "Calculating premium for policy: ",
      selectedPolicyRenewalInfo.OriginalPolicyNo
    );
    handleNextSubstep();
  };

  const changePlanClickHnadler = () => {
    handleFlowChange(RENEW_POLICY_DOWNGRADE_PLAN, 2);
  };

  return (
    <RenewMotorLayout show_summary btn_onClick={continueClickHanlder}>
      <div className="selectpolicy p-4 w-[33%] max-350:w-[100%] max767:w-[100%] ipad:w-[55%] mx-auto">
        <TitleDescription
          title={t(
            "motorInsurance.renewalPolicyFlow.reviewYourPolicyPage.title"
          )}
          description={t(
            "motorInsurance.renewalPolicyFlow.reviewYourPolicyPage.description"
          )}
        />
        <CurrentPlanDetailCard
          plan={CURRENT_PLAN(
            policyPremiumResponseInfo.PlanName ==
              selectedPolicyRenewalInfo.OriginalPlanName
              ? policyPremiumResponseInfo
              : selectedPolicyRenewalInfo
          )}
        />
        {!isPolicyPremiumLoading && (
          <p
            className="text-[#00384D] text-center text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline cursor-pointer"
            onClick={changePlanClickHnadler}
          >
            {t(
              "motorInsurance.renewalPolicyFlow.currentPlanDetails.review_policy"
            )}
          </p>
        )}
      </div>
    </RenewMotorLayout>
  );
};

export default ReviewYourPolicyPage;
