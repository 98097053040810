import React, { useState } from "react";
import greendonecheck from "../../../../assets/images/check_circle.svg";
import redcross from "../../../../assets/images/cancel.svg";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import { fullListData } from "./viewFullListData";




const MobilePlanListTable = () => {
  const [activeMainTab, setActiveMainTab] = useState("comprehensive");
  const [activeSubTab, setActiveSubTab] = useState("flexi");

  const renderValue = (value) => {
    if (value == true) {
      return (
        <img src={greendonecheck} className="mx-auto my-0" alt="green check" />
      );
    } else if (value == false) {
      return <img src={redcross} className="mx-auto my-0" alt="red cross" />;
    } else {
      return value;
    }
  };

  const translate = useLanguageTranslate();

  const renderTable = () => {
    return (
      <div
        className="max-w-full overflow-x-auto overflow-y-auto"
        style={{ maxHeight: "70vh" }}
      >
        <table className="min-w-full bg-white border-collapse">
          <thead>
            <tr>
              <th className="py-4 w-[50%] px-5 bg-[#F6F8F9] text-left text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.14px]">
                {translate("Benefits", "فوائد")}
              </th>
              <th className="py-2 w-[50% px-4 bg-[#F6F8F9] text-center text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.14px]">
                {/* {activeSubTab.charAt(0).toUpperCase() + activeSubTab.slice(1)} */}
                {/* {activeSubTab == "flexi" && translate("Flexi", "مرن")}
                {activeSubTab == "platinum" && translate("Platinum", "بلاتيني")}
                {activeSubTab == "third_party" && translate("Third Party"," طرف ثالث")}
                {activeSubTab == "third_party_plus" && translate("Third Party Plus"," طرف ثالث بلس")} */}
               { translate("Covers"," التغطيات")}
              </th>
            </tr>
          </thead>
          <tbody>
            {fullListData.map((row, index) => (
              <tr key={index}>
                <td className="py-4 px-5 border-b border-gray-200 text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.14px]">
                  {translate(row.benefit.label_en, row.benefit.label_ar)}
                  {row.benefit.sublabel && (
                    <span className="text-[#84919A] text-base not-italic font-normal leading-6 tracking-[-0.16px] flex">
                      {translate(row.benefit.sublabel, row.benefit.sublabel_ar)}
                    </span>
                  )}
                </td>
                <td className="py-2 px-4 border-b img-td text-center border-gray-200 text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.14px]">
                  {renderValue(
                    translate(row[activeSubTab]?.label_en, row[activeSubTab]?.label_ar)
                  )}
                  <span className=" text-[#84919A] text-base not-italic font-normal leading-6 tracking-[-0.16px] flex">
                    {renderValue(
                      translate(row[activeSubTab]?.sublabel ,
                        row[activeSubTab]?.sublabel_ar)
                    )}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="p-0">
      {/* Title */}
      <h2 className="text-black text-[1.2rem] not-italic font-semibold leading-[120%] text-left mb-6 mt-[-44px]">
        {translate(
          "Plans full list of benefits & covers",
          "قائمة كاملة بالفوائد والتغطيات للخطط"
        )}
      </h2>

      <div className="flex text-left border-y-[#DDE2E4] border-t border-solid border-b px-0">
        <button
          onClick={() => {
            setActiveMainTab("comprehensive");
            setActiveSubTab("flexi");
          }}
          className={`px-2 text-[#252C32] text-sm not-italic font-normal leading-6 w-[50%] py-4 border-r border-solid ${
            activeMainTab == "comprehensive"
              ? "text-[#252C32]"
              : "text-[#252C32]"
          }`}
        >
          {translate("Comprehensive Plans", "خطط شاملة")}
        </button>
        <button
          onClick={() => {
            setActiveMainTab("thirdparty");
            setActiveSubTab("third_party");
          }}
          className={`px-4 text-[#252C32] text-sm not-italic font-normal leading-6 w-[50%] py-2.5 ${
            activeMainTab == "thirdparty" ? "text-[#252C32]" : "text-[#252C32]"
          }`}
        >
          {translate("Third Party Plans", "خطط الطرف الثالث")}
        </button>
      </div>

      <div className="flex justify-center">
        <button
          onClick={() => setActiveSubTab("flexi")}
          className={`px-0 py-2 text-sm not-italic leading-6 flex w-[98.25px] flex-col items-center gap-2 pt-2 border-r-[#DDE2E4] border-r border-solid ${
            activeSubTab =="flexi"
              ? "text-[#00719A] border-b-2 border-b-[#00719A] font-semibold bg-[#F6F8F9]"
              : "text-[#252C32] font-normal"
          }`}
        >
          <span className="bg-[#B2D97E] w-[18px] h-[18px] rounded-[10px] mx-0 my-[5px]"></span>
          <span>{translate("Flexi", "ذهب")}</span>
        </button>
        <button
          onClick={() => setActiveSubTab("platinum")}
          className={`px-1 py-2 text-sm not-italic leading-6 flex w-[98.25px] flex-col items-center gap-2 pt-2 border-r-[#DDE2E4] border-r border-solid ${
            activeSubTab =="platinum"
              ? "text-[#00719A] border-b-2 border-b-[#00719A] font-semibold bg-[#F6F8F9]"
              : "text-[#252C32] font-normal"
          }`}
        >
          <span className="bg-[#55C6EF] w-[18px] h-[18px] rounded-[10px] mx-0 my-[5px]"></span>
          <span>{translate("Platinum", "البلاتين")}</span>
        </button>
        <button
          onClick={() => setActiveSubTab("third_party")}
          className={`px-0 py-2 text-sm not-italic leading-6 flex w-[98.25px] flex-col items-center gap-2 pt-2 border-r-[#DDE2E4] border-r border-solid ${
            activeSubTab =="third_party"
              ? "text-[#00719A] border-b-2 border-b-[#00719A] font-semibold bg-[#F6F8F9]"
              : "text-[#252C32] font-normal"
          }`}
        >
          <span className="bg-[#B0BABF] w-[18px] h-[18px] rounded-[10px] mx-0 my-[5px]"></span>
          <span>{translate("Third Party", "ذهب")}</span>
        </button>
        <button
          onClick={() => setActiveSubTab("third_party_plus")}
          className={`px-1 py-2 text-sm not-italic leading-6 flex w-[98.25px] flex-col items-center gap-2 pt-2 border-r-[#DDE2E4] border-r border-solid ${
            activeSubTab =="third_party_plus"
              ? "text-[#00719A] border-b-2 border-b-[#00719A] font-semibold bg-[#F6F8F9]"
              : "text-[#252C32] font-normal"
          }`}
        >
          <span className="bg-[#B0BABF] w-[18px] h-[18px] rounded-[10px] mx-0 my-[5px]"></span>
          <span>{translate("Third Party +", "البلاتين")}</span>
        </button>
      </div>

      {renderTable()}
    </div>
  );
};


export default MobilePlanListTable;
