import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import CarInfo from "./carDetails/carInfo/carInfo";
import Header from "../../../components/common/Header/Header";
import Modal from "../../../components/common/modal/modal";
import StartPage from "./start/startPage";
import CoverageEnhancement from "./addons/coverageEnhancement";
import PaymentSummury from "./pay/paymentSummury";
import { STEP_NAME_AD_DONS, STEP_NAME_CAR, STEP_NAME_PAY, STEP_NAME_PLAN, STEP_NAME_START, STEP_NAME_UPLOAD } from "../../../hooks/data";
import useMotorFlow from "../../../hooks/useMotorFlow";
import PlanSelector from "./planPages/planSelectorPage";
import UploadsMainPage from "./uploads/uploadsMainPage";
import { headerStepperData } from "./data";



const NewMotorPolicy = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);
  const { currentStep } = useMotorFlow()


  const PageRenderer = () => {
    switch (currentStep?.name) {
      case STEP_NAME_START:
        return (
          <StartPage />
        );
      case STEP_NAME_CAR:
        return (
          <CarInfo />
        );
      case STEP_NAME_PLAN:
        return (
          <PlanSelector />
        );
      case STEP_NAME_AD_DONS:
        return (
          <CoverageEnhancement />
        );
      case STEP_NAME_UPLOAD:
        return (
          <UploadsMainPage />
        );
      case STEP_NAME_PAY:
        return <PaymentSummury />;
      default:
        return null
    }
  };

  const activeStepData = useMemo(() => {
    let activeStep = headerStepperData.find((each) => each.key ==currentStep.name)
    return activeStep;
  }, [currentStep])

  return (
    <Modal  show={showModal}>
      <Header
        isPopup
        onCloseModal={() => {
          setShowModal(false);
          navigate("/");
        }}
        showStepper={true}
        steps={headerStepperData}
        activeStep={activeStepData}
      />
      <div className="mainCarDetailsModal pt-[5%] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll max767:pt-0">
        <PageRenderer />
      </div>
    </Modal>
  );
};

export default NewMotorPolicy;
