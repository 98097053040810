import React from 'react'
import Input from '../../../../../components/common/Input/Input'
import useValidation from '../../../../../hooks/useValidation';
import { PAYMENT_FORM_VALIDATIONS } from './data';
import csmda from "../../../../../assets/images/csmda.svg"
import visa from "../../../../../assets/images/visa.svg"
import mastercard from "../../../../../assets/images/mastercard.svg"
import americanexpress from "../../../../../assets/images/americanexpress.svg"
import { isAlphabetsAndSpaces, isNumeric, MMDDformatter } from '../../../../../utils';

const PaymentCardDetailForm = ({ formData, setFormData, handleChange, errors }) => {

    return (
        <div className='flex items-start gap-6 self-stretch bg-[#F6F8F9] p-4 rounded-[10px]'>
            <div className='bg-[#FFF] flex p-4 rounded-[10px] w-full'>
                <div className="images flex flex-col">
                    <img src={csmda} alt="card_1" className='h-14' />
                    <img src={visa} alt="card_2" className='h-14' />
                    <img src={mastercard} alt="card_3" className='h-14' />
                    <img src={americanexpress} alt="card_4" className='h-16' />
                </div>
                <div className="changemehodinputs pl-5 pt-2">
                    <Input
                        placeHolder='Card Number'
                        type="number"
                        name="card_number"
                        error={errors.card_number}
                        setFormData={setFormData}
                        value={formData.card_number}
                        handleChange={handleChange}
                    />
                    <div className="flex">
                        <Input
                            placeHolder='MM/YY'
                            type="text"
                            name="month_year"
                            error={errors.month_year}
                            setFormData={setFormData}
                            value={formData.month_year || ""}
                            handleChange={(e) => {
                                handleChange({
                                    target: {
                                        value: MMDDformatter(e.target.value || ""),
                                        name: "month_year"
                                    }
                                })
                            }}
                        />
                        <Input
                            placeHolder='CVV'
                            type='password'
                            name="cvv"
                            error={errors.cvv}
                            setFormData={setFormData}
                            value={formData.cvv || ""}
                            handleChange={(e) => {
                                if (isNumeric(e.target.value))
                                    handleChange(e)
                            }}
                        />
                    </div>
                    <Input
                        placeHolder='Card Holder Name'
                        type='text'
                        name="holder_name"
                        error={errors.holder_name}
                        setFormData={setFormData}
                        value={formData.holder_name||""}
                        handleChange={(e) => {
                            if (isAlphabetsAndSpaces(e.target.value))
                                handleChange(e)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default PaymentCardDetailForm