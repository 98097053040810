import React, { useMemo, useState } from 'react'
import TitleDescription from '../../../../../components/newMotorPolicy/titleDescription/titleDescription'
import AlertComponent from '../../../../motorInsurancePages/transferPolicyFlow/alertComponent'
import RenewMotorLayout from '../../../../motorInsurancePages/renewalMotorPolicy/renewMotorLayout'
import { decodeQueryString, formatToThreeDigits } from '../../../../../utils'
import SelectInput from '../../../../../components/common/selectInput/selectInput'
import Input from '../../../../../components/common/Input/Input'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import useValidation from '../../../../../hooks/useValidation'
import { SURRENDER_FORM_VALIDATIONS, SURRENDER_FORM_VALIDATIONS_OTHER_REASON } from './data'
import { useTranslation } from 'react-i18next'
import useLanguageTranslate from '../../../../../hooks/useLanguageTranslate'
import useLifePolicyDetails from '../../../../../hooks/life/useLifePolicyDetails'
import useCreateLifeRequests, { LIFE_REQUEST_TYPE } from '../../../../../hooks/life/useCreateLifeRequests'
import Loader from '../../../../../components/common/Loader/Loader'
import CustomCheckbox from '../../../../../components/common/customCheckbox/customCheckbox'

const ReviewAndConfirm = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({})
  const { errors, validateFields } = useValidation()
  const { t } = useTranslation();
  const translate = useLanguageTranslate()
  const params = decodeQueryString()
  const { data, isLoading } = useLifePolicyDetails(params?.policy);
  const { isLoading: requestLoading, createLifeRequest } = useCreateLifeRequests()

  console.log(data, 'some data')


  const validateForm = (data) => {
    let form_data = data || formData
    let isOtherReason = form_data?.reason =="Other reasons"
    let isValid = validateFields(
      !isOtherReason ? SURRENDER_FORM_VALIDATIONS : SURRENDER_FORM_VALIDATIONS_OTHER_REASON,
      form_data)
    return isValid
  };

  const navigate = useNavigate()

  const handleChange = (e) => {
    const { name, value } = e.target;
    let update = { ...formData, [name]: value }
    setFormData(update);
    if (errors?.[name]) {
      validateForm(update)
    }
  };

  const formattedData = useMemo(() => {
    return {
      policyName: data?.PolicyDetails?.ProductName,
      policyNumber: data?.PolicyDetails?.PolicyNumber,
      planName: data?.PolicyDetails?.PolicyName || "",
      policyStartDate: data?.PolicyDetails?.StartDate,
      currentPlanValue: data?.LifeDetails?.policyList?.[0]?.currentValue,
      surrenderValue: data?.PolicyDetails?.surrenderValue || 0
    }
  }, [data])




  const confirmClickHandler = async () => {
    if (!validateForm()) return;
    let payload = {
      PolicyNumber: formattedData?.policyNumber,
      RequestType: LIFE_REQUEST_TYPE.surrenderPolicy,
      Reason: formData?.reason =="Other reasons" ? formData?.other_reason : formData?.reason
    }
    const response = await createLifeRequest(payload)
    navigate(-1)
    // toast.success("Your request successfully sent")
  }

  const loadingState = isLoading || requestLoading
  return (
    <RenewMotorLayout is_hide_back_btn is_bottom_sticky_layout={false}>
      {loadingState && (
        <Loader className="w-10 h-10 fixed left-[50%] top-[50%]" />
      )}

      <div className="changeplatenumber w-[500px] max-w-[100%] mx-auto bg-white max767:p-5">
        <TitleDescription
          title={t("lifeInsurance.surrenderPolicy.title")}
          description={t("lifeInsurance.surrenderPolicy.description")}
        />
        <AlertComponent
          title={translate(
            "Please note, this request will be submitted for approval before it takes effects",
            "يرجى ملاحظة أن هذا الطلب سيتم تقديمه للموافقة عليه قبل أن يصبح ساري المفعول"
          )}
        />
        <div className="mt-5 bg-[#F6F8F9] rounded-[26px] p-4 mb-4 w-full summary-box">
          <h1 className="font-extrabold">
            {t("lifeInsurance.surrenderPolicy.policy_detail_title")}
          </h1>
          <p className="mb-3">
            {t("lifeInsurance.surrenderPolicy.policy_detail_description")}
          </p>
          <table className="w-full table-auto border-separate border border-solid border-[#DDE2E4] border-spacing-0 bg-white rounded-[10px]">
            <tbody>
              {/* Table rows for each piece of information */}
              <tr className="border-b">
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t("lifeInsurance.surrenderPolicy.policy_name_label")}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {formattedData?.policyName}
                </td>
              </tr>

              <tr className="border-b">
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t("lifeInsurance.surrenderPolicy.policy_number_label")}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {formattedData?.policyNumber}
                </td>
              </tr>

              {/* <tr className="border-b">
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t("lifeInsurance.surrenderPolicy.plan_label")}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">{formattedData?.planName}</td>
              </tr> */}

              <tr className="border-b">
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t("lifeInsurance.surrenderPolicy.start_date_label")}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {formattedData?.policyStartDate}
                </td>
              </tr>

              <tr className="border-b">
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t("lifeInsurance.surrenderPolicy.current_plan_value_label")}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {" "}
                  {formattedData?.currentPlanValue}
                </td>
              </tr>
            </tbody>
          </table>
          {/* <div className="flex justify-between px-0 py-2.5 border-[#c2c8ca] mt-3 border-solid">
            <div className="flex flex-col">
              <span className="text-[#1A2024] text-sm not-italic font-extrabold leading-6">
                {t("lifeInsurance.surrenderPolicy.surrender_value_label")}
              </span>
              <small>{t("lifeInsurance.surrenderPolicy.surrender_value_desc_label")}</small>
            </div>

            <span className="text-[#1A2024] text-sm not-italic font-extrabold leading-6">
              BHD {formattedData.surrenderValue}
            </span>
          </div> */}
        </div>
        <SelectInput
          name="reason"
          default_placeholder=""
          formData={formData}
          value={formData?.reason}
          options_data={[
            { value: "Financial Reasons", label: "Financial Reasons" },
            {
              value: "Medical and Personal Reasons",
              label: "Medical and Personal Reasons",
            },
            {
              value: "Found a more attractive offer",
              label: "Found a more attractive offer",
            },
            { value: "Leaving the country", label: "Leaving the country" },
            { value: "Loss of job", label: "Loss of job" },
            {
              value: "Dissatisfied with the product performance",
              label: "Dissatisfied with the product performance",
            },
            {
              value: "Dissatisfied with the customer service",
              label: "Dissatisfied with the customer service",
            },
            { value: "Other reasons", label: "Other reasons" },
          ]}
          label={t("lifeInsurance.surrenderPolicy.reason_input_label")}
          handleChange={handleChange}
          error={errors?.reason}
        />
        {formData?.reason =="Other reasons" && (
          <Input
            label={t("lifeInsurance.surrenderPolicy.other_reason_label")}
            type="text"
            name="other_reason"
            setFormData={setFormData}
            value={formData.other_reason}
            handleChange={handleChange}
            error={errors.other_reason}
          />
        )}

        <div className="flex">
          <CustomCheckbox
            Label={
                              <div className="flex">

              <label
                htmlFor="agreement"
                className="text-[#252C32] text-sm not-italic font-normal leading-6"
              >
                {t("lifeInsurance.surrenderPolicy.iAcceptTheLabel")}{" "}
                <a
                  href=" https://www.solidarity.com.bh/termsofuse"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#00384D] text-sm not-italic font-semibold leading-6 underline"
                >
                  {t("lifeInsurance.surrenderPolicy.termsOfLinkLabel")}
                </a>{" "}
                {t("lifeInsurance.surrenderPolicy.andLabel")}{" "}
                <a
                  href="https://www.solidarity.com.bh/privacyandsecurity"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#00384D] text-sm not-italic font-semibold leading-6 underline"
                >
                  {t("lifeInsurance.surrenderPolicy.privacyLinkLabel")}
                </a>
              </label>
                </div>
            }
            isChecked={isChecked}
            setIsChecked={setIsChecked}
          />
        </div>
        <button
          className={`w-full text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] flex justify-center p-3 rounded-[10px] ${
            isChecked
              ? "bg-[#55C6EF] rounded-[10px] cursor-pointer"
              : "bg-gray-300 text-gray-500 cursor-not-allowed"
          }`}
          disabled={!isChecked || loadingState}
          onClick={confirmClickHandler}
        >
          {t("lifeInsurance.surrenderPolicy.submit_button_label")}
        </button>
      </div>
    </RenewMotorLayout>
  );
}

export default ReviewAndConfirm