import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentStepIndex,
  selectCurrentSubstepIndex,
  setCurrentStepIndex,
  setCurrentSubstepIndex,
  setFileClaimFlowType,
  selectFileClaimFlowType,
} from "../redux/features/motorInsuranceFileClaimFlowSlice";
import {
  FILE_CLAIM_FLOW,
  CUSTOMER_TYPE,
  POLICY_TYPE,
  REQUIRED_DOCS_INFO,
  POLICY_PLANS,
  YOUR_INFO,
  ACCIDENT_INFO,
  REPAIR_OR_CASH,
  UPLOAD_DOCS,
  REVIEW_AND_CONFIRM,
  NON_SOLIDARITY_POLICY_PLANS_PAGE,
  CLAIM_CREATED,
  FILE_CLAIM_VEHICLE_DAMAGE_FLOW,
  FILE_CLAIM_PROPERTY_DAMAGE_FLOW,
  PROPERTY_DAMAGE_YOUR_INFO,
  FILE_CLAIM_BODILY_INJURY_FLOW,
  BODILY_INJURY_YOUR_INFO,
  FILE_CLAIM_NON_MOTOR_POLICY_HOLDER
} from "../pages/motorInsurancePages/fileAClaimFlow/data";

const useFileClaimFlow = () => {
  const dispatch = useDispatch();
  const flowType = useSelector(selectFileClaimFlowType);
  const currentStepIndex = useSelector((state) =>
    selectCurrentStepIndex(state)
  );
  const currentSubstepIndex = useSelector((state) =>
    selectCurrentSubstepIndex(state)
  );

  const steps = motor_insurance_file_claim_flow[flowType] || [];
  const currentStep = steps[currentStepIndex] || {};
  const currentSubstep = currentStep.substeps
    ? currentStep.substeps[currentSubstepIndex]
    : "";

  const handleFlowChange = (type, currentStepIndex, currentSubstepIndex) => {
    dispatch(
      setFileClaimFlowType({
        type,
        currentStepIndex,
        currentSubstepIndex,
      })
    );
  };

  const handleNextSubstep = () => {
    if (
      currentStep.substeps &&
      currentSubstepIndex < currentStep.substeps.length - 1
    ) {
      dispatch(setCurrentSubstepIndex(currentSubstepIndex + 1));
    } else if (currentSubstepIndex ==currentStep.substeps.length - 1) {
      handleNextStep();
    }
  };

  const handlePrevSubstep = () => {
    if (currentSubstepIndex > 0) {
      dispatch(setCurrentSubstepIndex(currentSubstepIndex - 1));
    } else {
      handlePrevStep();
    }
  };

  const handleNextStep = () => {
    if (currentStepIndex < steps.length - 1) {
      dispatch(setCurrentStepIndex(currentStepIndex + 1));
    }
  };

  const handlePrevStep = () => {
    if (currentStepIndex > 0) {
      const currentStep = steps[currentStepIndex - 1] || {};
      const substepIndex = currentStep?.substeps?.length - 1;
      dispatch(setCurrentStepIndex(currentStepIndex - 1));
      dispatch(setCurrentSubstepIndex(substepIndex));
    }
  };

  return {
    handleFlowChange,
    handleNextSubstep,
    handlePrevSubstep,
    handleNextStep,
    handlePrevStep,
    flowType,
    steps,
    currentStep,
    currentStepIndex,
    currentSubstep,
    currentSubstepIndex,
  };
};

export default useFileClaimFlow;

export const motor_insurance_file_claim_flow = {
  [FILE_CLAIM_FLOW]: [
    {
      name: CUSTOMER_TYPE,
      substeps: [CUSTOMER_TYPE],
    },
    {
      name: POLICY_TYPE,
      substeps: [POLICY_TYPE],
    },
    {
      name: REQUIRED_DOCS_INFO,
      substeps: [REQUIRED_DOCS_INFO],
    },
    {
      name: POLICY_PLANS,
      substeps: [POLICY_PLANS],
    },
    {
      name: YOUR_INFO,
      substeps: [YOUR_INFO],
    },
    {
      name: ACCIDENT_INFO,
      substeps: [ACCIDENT_INFO],
    },
    {
      name: REPAIR_OR_CASH,
      substeps: [REPAIR_OR_CASH],
    },
    {
      name: UPLOAD_DOCS,
      substeps: [UPLOAD_DOCS],
    },
    {
      name: REVIEW_AND_CONFIRM,
      substeps: [REVIEW_AND_CONFIRM],
    },
    {
      name: CLAIM_CREATED,
      substeps: [CLAIM_CREATED],
    },
  ],
  [FILE_CLAIM_VEHICLE_DAMAGE_FLOW]: [
    {
      name: CUSTOMER_TYPE,
      substeps: [CUSTOMER_TYPE],
    },
    {
      name: NON_SOLIDARITY_POLICY_PLANS_PAGE,
      substeps: [NON_SOLIDARITY_POLICY_PLANS_PAGE],
    },
    // {
    //   name: POLICY_TYPE,
    //   substeps: [POLICY_TYPE],
    // },
    {
      name: REQUIRED_DOCS_INFO,
      substeps: [REQUIRED_DOCS_INFO],
    },

    {
      name: YOUR_INFO,
      substeps: [YOUR_INFO],
    },
    {
      name: ACCIDENT_INFO,
      substeps: [ACCIDENT_INFO],
    },
    {
      name: REPAIR_OR_CASH,
      substeps: [REPAIR_OR_CASH],
    },
    {
      name: UPLOAD_DOCS,
      substeps: [UPLOAD_DOCS],
    },
    {
      name: REVIEW_AND_CONFIRM,
      substeps: [REVIEW_AND_CONFIRM],
    },
    {
      name: CLAIM_CREATED,
      substeps: [CLAIM_CREATED],
    },
  ],
  [FILE_CLAIM_PROPERTY_DAMAGE_FLOW]: [
    {
      name: CUSTOMER_TYPE,
      substeps: [CUSTOMER_TYPE],
    },
    {
      name: NON_SOLIDARITY_POLICY_PLANS_PAGE,
      substeps: [NON_SOLIDARITY_POLICY_PLANS_PAGE],
    },
    {
      name: REQUIRED_DOCS_INFO,
      substeps: [REQUIRED_DOCS_INFO],
    },

    {
      name: PROPERTY_DAMAGE_YOUR_INFO,
      substeps: [PROPERTY_DAMAGE_YOUR_INFO],
    },
    {
      name: ACCIDENT_INFO,
      substeps: [ACCIDENT_INFO],
    },
    {
      name: REPAIR_OR_CASH,
      substeps: [REPAIR_OR_CASH],
    },
    {
      name: UPLOAD_DOCS,
      substeps: [UPLOAD_DOCS],
    },
    {
      name: REVIEW_AND_CONFIRM,
      substeps: [REVIEW_AND_CONFIRM],
    },
    {
      name: CLAIM_CREATED,
      substeps: [CLAIM_CREATED],
    },
  ],
  [FILE_CLAIM_BODILY_INJURY_FLOW]: [
    {
      name: CUSTOMER_TYPE,
      substeps: [CUSTOMER_TYPE],
    },
    {
      name: NON_SOLIDARITY_POLICY_PLANS_PAGE,
      substeps: [NON_SOLIDARITY_POLICY_PLANS_PAGE],
    },
    {
      name: REQUIRED_DOCS_INFO,
      substeps: [REQUIRED_DOCS_INFO],
    },

    {
      name: BODILY_INJURY_YOUR_INFO,
      substeps: [BODILY_INJURY_YOUR_INFO],
    },
    {
      name: UPLOAD_DOCS,
      substeps: [UPLOAD_DOCS],
    },
    {
      name: REVIEW_AND_CONFIRM,
      substeps: [REVIEW_AND_CONFIRM],
    },
    {
      name: CLAIM_CREATED,
      substeps: [CLAIM_CREATED],
    },
  ],
  [FILE_CLAIM_NON_MOTOR_POLICY_HOLDER] : [
    {
      name: CUSTOMER_TYPE,
      substeps: [CUSTOMER_TYPE],
    },
    {
      name: POLICY_TYPE,
      substeps: [POLICY_TYPE],
    },
    {
      name: UPLOAD_DOCS,
      substeps: [UPLOAD_DOCS],
    },
    {
      name: CLAIM_CREATED,
      substeps: [CLAIM_CREATED],
    },
  ]
};


