import React from "react";
import { useNavigate } from "react-router-dom";

const NewPolicyCard = ({ imageSrc, title, link, is_internal, isNew }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (is_internal) {
      navigate(link);
    }
    else {
      window.location.href = link;
    }
  };
  return (
    <div
      onClick={handleClick}
      className="transition-all md:min-w-[180px] md:min-h-[180px] cursor-pointer max-w-40 max-h-40 shrink-0 border bg-[#F6F8F9] rounded-md border-[#F6F8F9] text-center justify-center hover:border-[1px] hover:border-[solid] hover:border-[#008DC0] policy-card max767:w-56 max767:max-w-[48%] max767:bg-[#F6F8F9] max767:rounded-[10px] max767:border-0 ipad:bg-[#F6F8F9] ipad:rounded-[10px] ipad:border-0 ipad:min-w-[170px] ipad:min-h-[170px]"
    >
      <div className={`flex-col justify-center items-center gap-2 shrink-0 flex max767:pt-3 relative ${isNew && "new"}`}>
        <span className="absolute bg-[#F76659] flex items-center gap-1 text-[#FFEFEB] text-sm not-italic font-semibold px-2 py-0 rounded-md left-3 -top-3 rtl:right-3 rtl:left-[unset]">{ isNew && "New"}</span>
      <img
        src={imageSrc}
        alt={title}
        className="justify-center items-center shrink-0 pt-[38px] pb-2.5 px-[0px] max767:pt-0  max767:px-[0px] max767:w-[36px] max767:h-[36px] max767:justify-center max767:pb-0"
      />
      <h2 className="text-black text-center text-sm not-italic font-normal leading-6 max767:mb-3 hover:text-[#00719A] hover:text-sm hover:font-semibold hover:leading-6 max767:font-medium">
        {title}
      </h2>
      </div>
    </div>
  );
};

export default NewPolicyCard;
