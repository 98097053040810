import faHouse from "../../../assets/images/home.svg";
import faShieldAlt from "../../../assets/images/shield_outline.svg";
import faSyncAlt from "../../../assets/images/refresh.svg";
import faFileAlt from "../../../assets/images/upload_file.svg";
import faExclamationTriangle from "../../../assets/images/error_outline.svg";
import faQuestionCircle from "../../../assets/images/list.svg";
import faVideo from "../../../assets/images/videocam.svg";
import faWhatsapp from "../../../assets/images/whatsapp.svg";
import faPhone from "../../../assets/images/phone_in_talk.svg";
import mypolicy from "../../../assets/images/analytics.svg";
import workoutline from "../../../assets/images/exportwork_outline.svg";
import SignIn from "../../../assets/images/account_circleb.svg";
import Notifications from "../../../assets/images/notificationsb.svg";
import Reportabug from "../../../assets/images/report_problemb.svg";
import FinancialAdvisor from "../../../assets/images/headset_mic.svg";
import faWhatsappb from "../../../assets/images/whatsappb.svg";
import FAQb from "../../../assets/images/Vectorb.svg";
import conditions from "../../../assets/images/assignmentb.svg";
import Security from "../../../assets/images/privacy_tipb.svg";
import Governance from "../../../assets/images/corporate_fareb.svg";
import Charges from "../../../assets/images/account_balance.svg";
import logout from "../../../assets/images/logoutblack.svg";
import faGlobe from "../../../assets/images/language.svg";

const HOME_MENU_ITEM = {
  id: "1.1",
  icon: faHouse,
  text_ar: "الرئيسية",
  text_mobile_ar: "وثائقي",
  text_en: "Home",
  link: "/",
  disabled: false,
  hide: false,
};
export const POLICY_MENU_ITEM = {
  id: "1.2",
  icon: mypolicy,
  text_en: "My Policies",
  text_mobile_en: "My Policies",
  text_mobile_ar: "وثائقي",
  text_ar: "وثائقي",
  link: "/my-policy",
  disabled: false,
  hide: false,
  type: "policy",
  showIfIsLoggedIn: true,
};
export const CLAIM_MENU_ITEM = {
  id: "2.3",
  icon: faFileAlt,
  text_ar: "تقديم مطالبة",
  text_en: "Claim",
  text_mobile_en: "Claim",
  text_mobile_ar: "تقديم مطالبة",
  link: "/file-a-claim",
  disabled: false,
  hide: false,
};
export const menu = [
  {
    id: "1",
    title_en: "",
    title_ar: "",
    className: "",
    links: [HOME_MENU_ITEM, POLICY_MENU_ITEM],
  },
  {
    id: "2",
    title_en: "MAIN SERVICES",
    title_ar: "الخدمات الرئيسية",
    links: [
      {
        id: "2.1",
        icon: faShieldAlt,
        text_ar: "احصل على خطة جديدة",
        text_en: "Get a New Plan",
        link: "/new-policy",
        disabled: false,
        hide: false,
      },
      {
        id: "2.2",
        icon: faSyncAlt,
        text_ar: "التجديد السريع",
        text_en: "Quick Renewal",
        link: "/motor-insurance/quick-renew-motor-policy",
        disabled: false,
        hide: false,
      },
      CLAIM_MENU_ITEM,
      {
        id: "2.4",
        icon: faExclamationTriangle,
        text_ar: "خدمات الطوارئ",
        text_en: "Emergency Services",
        link: "/emergency-services",
        disabled: false,
        hide: false,
      },
    ],
  },
  {
    id: "3",
    title_en: "HELP & SUPPORT",
    title_ar: "المساعدة والدعم",
    links: [
      {
        id: "3.1",
        icon: faQuestionCircle,
        text_ar: "الأسئلة الشائعة",
        text_en: "FAQ",
        link: "/faq",
        disabled: false,
        hide: false,
      },
      {
        id: "3.2",
        icon: faVideo,
        text_ar: "تأمين عبر مكالمات الفيديو",
        text_en: "Video Call Insurance",
        link: "#",
        disabled: false,
        hide: false,
        type: "action",
        actionName: "VideoCall",
      },
      {
        id: "3.3",
        icon: faWhatsapp,
        text_ar: "واتس اب",
        text_en: "Whatsapp",
        is_internal: false,
        link: "https://api.whatsapp.com/send/?phone=97317130000&text&type=phone_number&app_absent=0",
        disabled: false,
        hide: false,
      },
      {
        id: "3.4",
        icon: faPhone,
        text_ar: "اتصل بنا",
        text_en: "Call Us",
        is_internal: false,
        link: "tel:+973 17130000",
        disabled: false,
        hide: false,
      },
    ],
  },
];

export const mobileMenuData = [
  HOME_MENU_ITEM,
  POLICY_MENU_ITEM,
  CLAIM_MENU_ITEM,
  {
    id: "3",
    icon: workoutline,
    text_ar: "البة",
    text_en: "More",
    link: "/more",
  },
];

export const moreMenuData = [
  {
    id: "1",
    title_en: "Account",
    title_ar: "الرئيسية",
    links: [
      {
        id: "1.0",
        icon: faGlobe,
        text_ar: "Switch to English",
        text_en: "Switch to Arabic (العربية)",
        link: "",
        type: "handleLangChng",
      },
      {
        id: "1.1",
        icon: SignIn,
        text_ar: "وثيقة جديدة",
        text_en: "Sign In",
        link: "/sign-in",
        type: "signin",
      },
      {
        id: "1.2",
        icon: Notifications,
        text_ar: "وثيقة جديدة",
        text_en: "Notifications",
        link: "/notifications",
      },
      {
        id: "1.3",
        text_en: "Security",
        text_ar: "الأمان",
        icon: Security,
        showIfIsLoggedIn: true,
        link: "/security",
      },
      {
        id: "1.4",
        icon: Reportabug,
        text_ar: "وثيقة جديدة",
        text_en: "Report a bug",
        is_internal: false,
        link: "https://forms.clickup.com/9007063400/p/f/8cdtrb8-18238/FS7Y77PAP59R7KDLBD/help-us-improve",
      },
      {
        id: "1.5",
        text_en: "Logout",
        text_ar: "تسجيل الخروج",
        icon: logout,
        showIfIsLoggedIn: true,
        type: "logout",
      },
    ],
  },
  {
    id: "2",
    title_en: "Help & Support",
    title_ar: "الرئيسية",
    links: [
      {
        id: "2.1",
        icon: faVideo,
        text_ar: "تأمين عبر مكالمات الفيديو",
        text_en: "Video Call Insurance",
        link: "#",
        type: "VideoCall",
      },
      {
        id: "2.2",
        icon: faWhatsappb,
        text_ar: "وثيقة جديدة",
        text_en: "Whatsapp",
        is_internal: false,
        link: "https://api.whatsapp.com/send/?phone=97317130000&text&type=phone_number&app_absent=0",
      },
      {
        id: "2.3",
        icon: FAQb,
        text_ar: "وثيقة جديدة",
        text_en: "Support & FAQ",
        link: "/faq",
      },
    ],
  },
  {
    id: "3",
    title_en: "Legal",
    title_ar: "الرئيسية",
    links: [
      {
        id: "3.1",
        icon: conditions,
        text_ar: "وثيقة جديدة",
        text_en: "Terms and conditions",
        is_internal: false,
        link: "https://www.solidarity.com.bh/termsofuse",
      },
      {
        id: "3.2",
        icon: Security,
        text_ar: "وثيقة جديدة",
        text_en: "Privacy and Security",
        is_internal: false,
        link: "https://www.solidarity.com.bh/privacyandsecurity",
      },
      {
        id: "3.3",
        icon: Governance,
        text_ar: "وثيقة جديدة",
        text_en: "Corporate Governance",
        link: "/",
        hide: true,
      },
      {
        id: "3.4",
        icon: Charges,
        text_ar: "وثيقة جديدة",
        text_en: "Fees & Charges",
        is_internal: false,
        link: "https://content.solidarity.bh/uploads/solidarity_fees_and_charges_493d5a6b2e.pdf",
      },
    ],
  },
];
