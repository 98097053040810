/* :Page:MotorRenewal: Renewal OPT page */ 
import React,{useState, useEffect} from "react";
import useRenewalFlow, { RENEW_POLICY_LOGGED_IN, RENEW_POLICY_LOGGED_OUT, RENEW_POLICY_SAME_PLAN } from "../../../../hooks/useRenewalFlow";
import RenewMotorLayout from "../renewMotorLayout";
import useValidation from "../../../../hooks/useValidation";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useTranslation } from "react-i18next";
import useMotorForm from "../../../../hooks/useMotorFormData";
import Input from "../../../../components/common/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { sendToken } from "../../../../redux/features/account/accountOtpSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { selectLoginTokenInfo } from "../../../../redux/features/loginRegisterSlice";


export const RENEW_POLICY_CPR_NUMBER = "RENEW_POLICY_CPR_NUMBER";
const CprNumberPage = () => {
  /* Setting up dispatch to call redux */
  const dispatch = useDispatch();

  /* Setting up variables with appropriate requests to send and response to receive, reading from redux/functions */
  const loginTokenInfo = useSelector(selectLoginTokenInfo);
  let isLoggedin = loginTokenInfo;

  /* Functions and Variables that are updated with functions/state */
  
  const { handleNextSubstep, handleFlowChange } = useRenewalFlow();
  const { errors, validateField } = useValidation();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ cprNumber: "" });
  const [isDisabled, setIsDisabled] = useState(false);
  const { saveMotorDataInRedux } = useMotorForm(
    RENEW_POLICY_CPR_NUMBER,
    setFormData
  );

  const { removeMotorFormData } = useMotorForm(
    RENEW_POLICY_CPR_NUMBER,
    setFormData
  );

  let isFormValid = true;
  const validateForm = () => {
    let isCprValid = validateField(
      formData?.cprNumber,
      "cprNumber",
      "required"
    );
    return (isFormValid = isCprValid);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Remove pinId and masked phonenumber from state if CPR is changed
    removeMotorFormData();
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    switch (name) {
      case "cprNumber":
        validateField(value, name, "required");
        break;
      default:
        break;
    }
  };

  /* Use Effects, monitor changes to call on [var] change or one time call */
  // If logged in, skip CPR and OTP pages
//   useEffect(() => {
//     console.log('isLoggedIn', isLoggedin);
//     if (isLoggedin) {
//         console.log("LOGGED IN!!"); 
//         // Pages index for RENEW_POLICY_LOGGED_IN: 1 CPR, 2 Sum Insured, 3 Covers
//         handleFlowChange(RENEW_POLICY_LOGGED_IN, 0); 
//     } else {
//         // Pages index for default RENEW_POLICY_SAME_PLAN: 0 CPR, 1 OTP, 2 Policies, 3 Review Plan (Flow can change, below added), 4 Sum Insured, 5 Covers, 6 Payment Summary
//         // Pages index for default RENEW_POLICY_SAME_PLAN with addition of Change Plan RENEW_POLICY_DOWNGRADE_PLAN: 4 Change plan 5 Covers ...
//         handleFlowChange(RENEW_POLICY_SAME_PLAN, 0); 
//         console.log("NOT LOGGED IN!!");
//     }
// }, []); 

  if (isLoggedin) {
    console.log("LOGGED IN!!"); 
    // Pages index for RENEW_POLICY_LOGGED_IN: 1 CPR, 2 Sum Insured, 3 Covers
    handleFlowChange(RENEW_POLICY_LOGGED_IN, 0, 0); 
  } else {
    // Pages index for default RENEW_POLICY_SAME_PLAN: 0 CPR, 1 OTP, 2 Policies, 3 Review Plan (Flow can change, below added), 4 Sum Insured, 5 Covers, 6 Payment Summary
    // Pages index for default RENEW_POLICY_SAME_PLAN with addition of Change Plan RENEW_POLICY_DOWNGRADE_PLAN: 4 Change plan 5 Covers ...
    console.log("NOT LOGGED IN!!");
  }

  useEffect(() => {
    const isFormValid = validateForm();
    setIsDisabled(!isFormValid);
  }, [formData]);

  /* Next */
  const continueClickHanlder = async () => {
    handleNextSubstep();

    // if (validateForm()) {
    //   try {
       
    //       // Move to the next step
    //       handleNextSubstep();
    //     }
    //   } catch (error) {
    //     // alert("Failed to send token:", error);
    //     toast.error("Failed to send token:", error);
    //   }
    // }
  };

  return (
    <RenewMotorLayout
      show_close_btn
      is_hide_back_btn={true}
      btn_disabled={isDisabled}
      btn_onClick={continueClickHanlder}
    >
      <div className="max-w-[100%] w-[500px] mx-auto bg-white max767:p-5">
        <TitleDescription
          title={t("motorInsurance.renewalPolicyFlow.cprNoPage.title")}
          description={t(
            "motorInsurance.renewalPolicyFlow.cprNoPage.description"
          )}
        />
        <Input
          name="cprNumber"
          type="text"
          label={t("motorInsurance.renewalPolicyFlow.cprNoPage.cprNo")}
          value={formData?.cprNumber}
          error={errors?.cprNumber}
          handleChange={handleChange}
        />
      </div>
    </RenewMotorLayout>
  );
};

export default CprNumberPage;
