import React from 'react'
import './ValidationItem.css'
import greentick from '../../../assets/images/done.svg';
import redtick from '../../../assets/images/clear.svg';
import useLanguageTranslate from '../../../hooks/useLanguageTranslate';
const ValidationItem = ({ className, is_validated, msg }) => {
    const translate = useLanguageTranslate();
    return (
        <div className='flex'>
            {is_validated && <img src={greentick} alt='tick img' className='mr-2'></img>}
            {!is_validated && <img src={redtick} alt='wrong tick img' className='mr-2'></img>}
            <p className="validation_msg">{translate(msg.en, msg.ar)}</p>
        </div>
    )
}

export default ValidationItem