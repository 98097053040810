import React, { useState } from 'react'
import { RENEW_PAGE_ADJUST_CAR_VALUE, RENEW_PAGE_CHOOSE_DOWNGRADE_PLAN, RENEW_PAGE_CPR_NUMBER, RENEW_PAGE_CPR_PLATE_NUMBER, RENEW_PAGE_ENHANCE_YOUR_COVERAGE, RENEW_PAGE_REVIEW_AND_CONFIRM, RENEW_PAGE_REVIEW_YOUR_POLICY, RENEW_PAGE_SELECT_POLICY, RENEW_PAGE_VERIFY_OTP } from './data';
import AdjustCarValuePage from './adjustCarValuePage/adjustCarValuePage';
import ChooseDowngradePlanPage from './chooseDowngradePlanPage/chooseDowngradePlanPage';
import CprNumberPage from './cprNumberPage/cprNumberPage';
import EnhanceYourCoveragePage from './enhanceYourCoveragePage/enhanceYourCoveragePage';
import ReviewAndConfirmPage from './reviewAndConfirmPage/reviewAndConfirmPage';
import ReviewYourPolicyPage from './reviewYourPolicyPage/reviewYourPolicyPage';
import SelectPoliciesPage from './selectPoliciesPage/selectPoliciesPage';
import VerifyOtpPage from './verifyOtpPage/verifyOtpPage';
import Modal from '../../../components/common/modal/modal';
import Header from '../../../components/common/Header/Header';
import { useNavigate } from 'react-router-dom';
import useQuickRenewalFlow from '../../../hooks/useQuickRenewalFlow';
import CprPlateNumberPage from './cprNumberPage/cprPlateNumberPage';
import QuickRenewalCprPlateNumberPage from './quickRenewalMotorPolicy/quickRenewalCprPlateNumber';

const QuickRenewalMotorPolicy = () => {
    const {currentStep} = useQuickRenewalFlow()

    console.log(currentStep,'some current step')
    const PageRenderer = () => {
        switch (currentStep?.name) {
            // case RENEW_PAGE_CPR_NUMBER:
            //     return <CprNumberPage />
            case RENEW_PAGE_CPR_PLATE_NUMBER:
                return <QuickRenewalCprPlateNumberPage />
            // case RENEW_PAGE_ADJUST_CAR_VALUE:
            //     return <AdjustCarValuePage />
            // case RENEW_PAGE_CHOOSE_DOWNGRADE_PLAN:
            //     return <ChooseDowngradePlanPage />
            // case RENEW_PAGE_ENHANCE_YOUR_COVERAGE:
            //     return <EnhanceYourCoveragePage />
            case RENEW_PAGE_REVIEW_AND_CONFIRM:
                return <ReviewAndConfirmPage />
            // case RENEW_PAGE_REVIEW_YOUR_POLICY:
            //     return <ReviewYourPolicyPage />;
            // case RENEW_PAGE_SELECT_POLICY:
            //     return <SelectPoliciesPage />;
            // case RENEW_PAGE_VERIFY_OTP:
            //     return <VerifyOtpPage />;
            default:
                return null
        }
    };
    return (
        <div className="mainCarDetailsModal pt-[5%] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll ">
            <PageRenderer />
        </div>
    )
}

export default QuickRenewalMotorPolicy