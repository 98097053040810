const is_production = true;
// take is production value from env or window.location.baseUrl kind of logic

export const STRAPI_DEV_BASE_URL = "https://contentstaginglife.solidarity.bh";
export const STRAPI_PRODUCTION_BASE_URL = STRAPI_DEV_BASE_URL;

export const STRAPI_DEV_API_TOKEN =
  "1aa536d2b4b8f7f6b7d5d8ed236efeb07d6b51e07bc14a4747b6c1360fbd4c70b88e9ac5868ef75e2c077159afcb703314892c98ca13acda61818b25ac079c369e0fde4a784ef647132c1ad2cd6a66ce41ece9b9b7d1d21aae38b3aa8f7ee98513b702c1be2cce53e2bba66f98b7409e92cd571edb32f35a0edc94f61f5dfc15";
export const STRAPI_PRODUCTION_API_TOKEN = STRAPI_DEV_API_TOKEN;

export const DEV_BASE_URL = "https://api.solidarity.bh/v1.0";
export const STAGING_BASE_URL = "https://api.solidarity.bh/v1.0";
export const ANGULAR_URL = "https://app.solidarity.bh";
export const PRODUCTION_BASE_URL = DEV_BASE_URL;
export const BASE_URL = is_production ? PRODUCTION_BASE_URL : DEV_BASE_URL;

export const FILES_DEV_BASE_URL = "https://files-api.solidarity.bh";
export const FILES_PRODUCTION_BASE_URL = FILES_DEV_BASE_URL;
export const FILES_BASE_URL = FILES_PRODUCTION_BASE_URL;

export const STRAPI_BASE_URL = is_production
  ? STRAPI_PRODUCTION_BASE_URL
  : STRAPI_DEV_BASE_URL;
export const STRAPI_API_TOKEN = is_production
  ? STRAPI_PRODUCTION_API_TOKEN
  : STRAPI_DEV_API_TOKEN;

// export const DEV_BASE_URL = 'http://192.168.40.120:1337';
// export const DEV_API_TOKEN =
// export const CRM_BASE_URL = "https://api.staging.solidarity.bh/v1.0"
// export const API_TOKEN = DEV_API_TOKEN;
// export const BASE_URL = DEV_BASE_URL;
