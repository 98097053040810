import React, { useState } from "react";
import greendonecheck from "../../../../assets/images/check_circle.svg";
import redcross from "../../../../assets/images/cancel.svg";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import { fullListData } from "./viewFullListData";

const TabPlanListTable = () => {
  const [activeTab, setActiveTab] = useState("comprehensive");
  const translate = useLanguageTranslate();

  const renderValue = (value) => {
    if (value == true) {
      return <img src={greendonecheck} className="mx-auto my-0" alt="green check" />;
    } else if (value == false) {
      return <img src={redcross} className="mx-auto my-0" alt="red cross" />;
    } else {
      return value;
    }
  };

  const renderTable = () => (
    <div
      className="max-w-full overflow-x-auto overflow-y-auto"
      style={{ maxHeight: "70vh" }}
    >
      <table className="min-w-full bg-white border-collapse border border-solid border-[#DDE2E4]">
        <thead>
          <tr>
            <th className="py-4 w-[50%] px-5 bg-[#F6F8F9] text-left text-[#252C32] text-sm font-semibold">
              {translate("Benefits", "الفوائد")}
            </th>
            {activeTab =="comprehensive" ? (
              <>
                <th className="py-4 bg-[#F6F8F9] text-center text-sm font-semibold">
                  <span className="bg-[#F8C51B] w-[18px] h-[18px] rounded-[10px] inline-block mr-2"></span>
                  {translate("Flexi", "مرن")}
                </th>
                <th className="py-4 bg-[#F6F8F9] text-center text-sm font-semibold">
                  <span className="bg-[#55C6EF] w-[18px] h-[18px] rounded-[10px] inline-block mr-2"></span>
                  {translate("Platinum", "بلاتينيوم")}
                </th>
              </>
            ) : (
              <>
                <th className="py-4 bg-[#F6F8F9] text-center text-sm font-semibold">
                  <span className="bg-[#B0BABF] w-[18px] h-[18px] rounded-[10px] inline-block mr-2"></span>
                  {translate("Third Party", "طرف ثالث")}
                </th>
                <th className="py-4 bg-[#F6F8F9] text-center text-sm font-semibold">
                  <span className="bg-[#B2D97E] w-[18px] h-[18px] rounded-[10px] inline-block mr-2"></span>
                  {translate("Third Party +", "طرف ثالث +")}
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {fullListData.map((row, index) => (
            <tr key={index}>
              <td className="py-5 px-4 border-b text-[#252C32] text-lg font-semibold">
                {translate(row.benefit.label_en, row.benefit.label_ar)}
                {row.benefit.sublabel && (
                  <span className="block text-[#84919A] text-base font-normal">
                    {translate(row.benefit.sublabel, row.benefit.sublabel_ar)}
                  </span>
                )}
              </td>
              {activeTab =="comprehensive" ? (
                <>
                  <td className="py-2 px-4 border-b text-center text-base font-normal">
                    {renderValue(translate(row.flexi.label_en, row.flexi.label_ar))}
                    <span className="block text-[#84919A] text-base font-normal">
                      {translate(row.flexi.sublabel, row.flexi.sublabel_ar)}
                    </span>
                  </td>
                  <td className="py-2 px-4 border-b text-center text-base font-normal">
                    {renderValue(translate(row.platinum.label_en, row.platinum.label_ar))}
                    <span className="block text-[#84919A] text-base font-normal">
                      {translate(
                        row.platinum.sublabel,
                        row.platinum.sublabel_ar
                      )}
                    </span>
                  </td>
                </>
              ) : (
                <>
                  <td className="py-2 px-4 border-b text-center text-base font-normal">
                    {renderValue(
                      translate(row.third_party.label_en,
                      row.third_party.label_ar)
                    )}
                    <span className="block text-[#84919A] text-sm font-normal">
                      {translate(
                        row.third_party.sublabel,
                        row.third_party.sublabel_ar
                      )}
                    </span>
                  </td>
                  <td className="py-2 px-4 border-b text-center text-base font-normal">
                    {renderValue(
                      translate(row.third_party_plus.label_en,
                      row.third_party_plus.label_ar)
                    )}
                    <span className="block text-[#84919A] text-base font-normal">
                      {translate(
                        row.third_party_plus.sublabel,
                        row.third_party_plus.sublabel_ar
                      )}
                    </span>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  return (
    <div>
      <h2 className="text-black text-[1.2rem] font-semibold mb-6">
        {translate("Plans full list of benefits & covers", "قائمة كاملة بالفوائد والتغطيات للخطط")}
      </h2>
      <div className="flex border-y-[#DDE2E4] border-t border-solid">
        <button
          onClick={() => setActiveTab("comprehensive")}
          className={`px-2 w-[50%] py-4 border-r ${
            activeTab == "comprehensive"
              ? "text-[#00719A] border-b-2 border-[#00719A] font-semibold bg-[#F6F8F9]"
              : "text-[#252C32]"
          }`}
        >
          {translate("Comprehensive Plans", "خطط شاملة")}
        </button>
        <button
          onClick={() => setActiveTab("thirdparty")}
          className={`px-2 w-[50%] py-4 border-r ${
            activeTab == "thirdparty"
              ? "text-[#00719A] border-b-2 border-[#00719A] font-semibold bg-[#F6F8F9]"
              : "text-[#252C32]"
          }`}
        >
          {translate("Third Party Plans", "خطط الطرف الثالث")}
        </button>
      </div>
      {renderTable()}
    </div>
  );
};
export default TabPlanListTable;