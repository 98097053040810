import React, { useState } from "react";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useTranslation } from "react-i18next";
import Summary from "../../../../components/newMotorPolicy/veiwSummaryPopup/summary";
import PromoCode from "../../../../components/newMotorPolicy/veiwSummaryPopup/promoCode";
import useMotorForm from "../../../../hooks/useMotorFormData";
import { PROMO_CODE } from "../../../../components/newMotorPolicy/veiwSummaryPopup/viewSummaryPopup";
import { motorData } from "../../newMotorPolicy/pay/data";
import forwardarrow from "../../../../assets/images/arrow_forwardb.svg";
import { useNavigate } from "react-router-dom";
import AddMoreCoversMotorLayout from "../addMoreCoversMotorLayout";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { getPolicyPayLoading, policyPay } from "../../../../redux/features/payment/policyPaySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { Loader } from "storybook/internal/components";
import { selectNewMotorForm } from "../../../../redux/features/newMotorFormSlice";
import { getPolicyViewHeaderResponse } from "../../../../redux/features/policyDetailsSlice";
import { currencyFormatter } from "../../../../utils";
import Button from "../../../../components/common/Button/Button";
import CustomCheckbox from "../../../../components/common/customCheckbox/customCheckbox";
export const ADD_COVERS_ENHANCEMENT =
  "ADD_COVERS_ENHANCEMENT";

const ReviewAndConfirmPage = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { t } = useTranslation();
  const translate=useLanguageTranslate()
  const [formState, setFormState] = useState({});
  const [isChecked, setIsChecked] = useState(false);

  const policyPayLoading = useSelector(getPolicyPayLoading);
  const finalCovers = useSelector(selectNewMotorForm)?.[ADD_COVERS_ENHANCEMENT] || [];
  // Filter to include only items where `included` is true
  const includedCovers = finalCovers?.coverOptionsState?.filter(cover => cover.included ==true);
  const policyInfo = useSelector(getPolicyViewHeaderResponse);

  // Calculate total price by summing the `price` of each included cover
  const totalPrice = includedCovers?.reduce((sum, cover) => sum + cover.price, 0);

  // Calculate VAT as 10% of the total price
  const VAT = totalPrice * 0.1

  console.log('on add covers payment page, final covers are: ', finalCovers);
  console.log('on add covers payment page, policy info: ', policyInfo);

  
  const declaration = t(
    "motorInsurance.addMoreCovers.declaration",
    { returnObjects: true }
  );
  
  const { saveMotorDataInRedux, removeMotorFormData } = useMotorForm(
    PROMO_CODE,
    setFormState
  );

  const applyPromoCode = () => {
    saveMotorDataInRedux(formState);
  };

  const handlePayment = async () => {
    const payload = {
      PolicyNumber: "",
      Premium: "",
      Channel: "online.solidarity",
    };

    try {
      // Calling API policy/pay
      const linkAction = await dispatch(policyPay(payload));
      const link = unwrapResult(linkAction)
      // console.log("Link is: ", link)
      if (link) {
        window.open(link, "_self");
      }

    }
    catch (error) {
      // alert("Error in getting payment link: ", error)
      toast.error("Error in getting payment link: ", error);
    }
  };



  return (
    <AddMoreCoversMotorLayout is_bottom_sticky_layout={false}>
      <div className="flex flex-col items-center mx-auto max-w-lg max767:p-4">
        <div className="flex justify-center items-center w-full">
          <div className=" p-0 w-full ">
            <TitleDescription
              title={t(
                "motorInsurance.renewalPolicyFlow.reviewAndConfirmPage.title"
              )}
              description={t(
                "motorInsurance.renewalPolicyFlow.reviewAndConfirmPage.description"
              )}
            />
            <div className="bg-[#F6F8F9] rounded-[26px] p-4 mb-4">
              <h3 className="text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px]">
                {t("motorInsurance.addMoreCovers.additional_covers")}
              </h3>

              {/* <div className="additional_covers mt-4 mb-4">
                <div className="cover flex justify-between items-start gap-4 self-stretch p-4 mb-2 rounded-[10px] border-[1px] border-[solid] border-[#DDE2E4] bg-[#FFF]">
                  <p className="text-[#252C32] text-center text-base not-italic font-normal leading-6">
                    {t("motorInsurance.addMoreCovers.car")}
                  </p>
                  <p className="text-[#252C32] text-sm not-italic font-normal leading-6 opacity-50">
                    {t("motorInsurance.addMoreCovers.small_car")}
                  </p>
                </div>
                <div className="cover flex justify-between items-start gap-4 self-stretch p-4 mb-2 rounded-[10px] border-[1px] border-[solid] border-[#DDE2E4] bg-[#FFF]">
                  <p className="text-[#252C32] text-center text-base not-italic font-normal leading-6">
                    {t("motorInsurance.addMoreCovers.roadside_assistance")}
                  </p>
                </div>
              </div> */}
              <div className="additional_covers mt-4 mb-4">
                {includedCovers?.map((cover, i) => (
                  <div
                    key={i}
                    className="cover flex justify-between items-start gap-4 self-stretch p-4 mb-2 rounded-[10px] border-[1px] border-[solid] border-[#DDE2E4] bg-[#FFF]"
                  >
                    {/* Display the cover name */}
                    <p className="text-[#252C32] text-center text-base not-italic font-normal leading-6">
                      {translate(cover.name, cover.name_ar)}
                    </p>

                    {/* Conditionally render additional details for "CMOT28" */}
                    {cover.code =="CMOT28" ? (
                      <p className="text-[#252C32] text-sm not-italic font-normal leading-6 opacity-50">
                        {cover.selectedDays} {translate("Days", "أيام")}{" "}
                        {cover.selectedCarType}
                      </p>
                    ) : (
                      <p className="text-[#252C32] text-sm not-italic font-normal leading-6 opacity-50">
                        {t("motorInsurance.addMoreCovers.small_car")}
                      </p>
                    )}
                  </div>
                ))}
              </div>

              <div className="max767:overflow-x-scroll">
                <table className="min-w-full border-separate bg-white border border-solid border-[#DDE2E4] border-spacing-0 rounded-[10px]">
                  <tbody>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6 rtl:border-l-[#DDE2E4] rtl:border-l rtl:border-solid">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.plate_number"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6 ">
                        {policyInfo?.RegistrationNumber}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.plate_type"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
                        {translate(motorData.plateType, motorData.plateType_ar)}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.chassis_number"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
                        {policyInfo?.ChasissNumber}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.car_make"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b  text-[#1A2024] text-sm not-italic font-medium leading-6">
                        {translate(policyInfo?.Make, policyInfo?.Make)}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.car_model"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
                        {translate(policyInfo?.Model, policyInfo?.Model)}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.manufacturing_year"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
                        {policyInfo?.ModelYear}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.sum_insured"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
                        {currencyFormatter(policyInfo?.SumInsured)}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.startDate"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
                        {policyInfo?.FromDateStr}
                      </td>
                    </tr>
                    <tr className="border-b">
                      <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.plan"
                        )}
                      </td>
                      <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6 rtl:border-r">
                        {translate(policyInfo?.PlanName, policyInfo?.PlanName)}
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6 align-top">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.paymentSummary.add_ons"
                        )}
                      </td>
                      <td className=" text-[#1A2024] text-sm not-italic font-medium leading-6 rtl:border-r">
                        {policyInfo?.Covers
                          // ?.filter((option) => option.included)
                          ?.map((addOn, index) => (
                            <div
                              key={index}
                              className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6"
                            >
                              {addOn.coverName}
                            </div>
                          ))}
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#F6F8F9] rounded-[26px] p-4 mb-4 w-full summary-box">
          <PromoCode
            promoCode={formState.promoCode}
            setPromoCode={(code) =>
              setFormState((prevState) => ({ ...prevState, promoCode: code }))
            }
            applyPromoCode={applyPromoCode}
            clearPromoCode={() => {
              setFormState({});
              removeMotorFormData({});
            }}
          />

          <Summary passedFrom={"AddCovers"} price={totalPrice} vat={VAT} />

          <div className="flex">
            <CustomCheckbox
              Label={
                <div className="flex">
                  <label
                    htmlFor="agreement"
                    className="text-[#252C32] text-sm not-italic font-normal leading-6"
                  >
                    {declaration?.map((paragraph, index) => (
                      <p className="mb-2" key={index}>
                        {paragraph}
                      </p>
                    ))}
                  </label>
                </div>
              }
              isChecked={isChecked}
              setIsChecked={setIsChecked}
            />
          </div>

          {/* Proceed Button */}
          <Button
            isLoading={policyPayLoading}
            className={`bg-[#55C6EF] mt-4 w-full text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] flex justify-center p-3 rounded-[10px]
                ${
                  isChecked
                    ? "bg-[#55C6EF] rounded-[10px] cursor-pointer"
                    : "bg-gray-300 text-gray-500 cursor-not-allowed"
                }`}
            disabled={!isChecked}
            label={t(
              "motorInsurance.renewalPolicyFlow.motorPolicyrenewal.payment"
            )}
            onSubmit={handlePayment}
            post_icon={forwardarrow}
            post_icon_class={"w-6 h-6 ml-[5px] rtl:rotate-180 rtl:mr-2"}
          />
        </div>
      </div>
    </AddMoreCoversMotorLayout>
  );
};

export default ReviewAndConfirmPage;
