import { createSlice } from "@reduxjs/toolkit";
import { FLOW_TYPE_USED_CAR_WITHOUT_OC } from "../../hooks/data";
const initialState = {
    flowType: FLOW_TYPE_USED_CAR_WITHOUT_OC,
    currentStepIndex: 0,
    currentSubstepIndex: 0,
};

const motorInsuranceFlowSlice = createSlice({
    name: "motorInsuranceFlow",
    initialState,
    reducers: {
        setFlowType: (state, action) => {
            const data = action.payload
            state.flowType = data.type;
            state.currentStepIndex = data.currentStepIndex || 0;
            state.currentSubstepIndex = data.currentSubstepIndex || 0;
        },
        setCurrentStepIndex: (state, action) => {
            state.currentStepIndex = action.payload;
            state.currentSubstepIndex = 0;
        },
        setCurrentSubstepIndex: (state, action) => {
            state.currentSubstepIndex = action.payload;
        },
        clearMotorInsuranceFlow: () => initialState
    }
});

export const selectMotorInsuranceFlow = (store) => store.motorInsuranceFlow;
export const selectFlowType = (store) => store?.motorInsuranceFlow?.flowType;
export const selectCurrentStepIndex = (store) => store?.motorInsuranceFlow?.currentStepIndex;
export const selectCurrentSubstepIndex = (store) => store.motorInsuranceFlow?.currentSubstepIndex;


export const { setFlowType, setCurrentStepIndex, setCurrentSubstepIndex, clearMotorInsuranceFlow } = motorInsuranceFlowSlice.actions;
export default motorInsuranceFlowSlice.reducer;
