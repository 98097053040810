import { createSlice } from "@reduxjs/toolkit";
import { RENEW_POLICY_SAME_PLAN } from "../../hooks/useRenewalFlow";

const initialState = {
    flowType: RENEW_POLICY_SAME_PLAN,
    currentStepIndex: 0,
    currentSubstepIndex: 0,
};

const motorInsuranceRenewFlowSlice = createSlice({
    name: "motorInsuranceRenewFlow",
    initialState,
    reducers: {
        setRenewalFlowType: (state, action) => {
            const data = action.payload
            state.flowType = data.type;
            state.currentStepIndex = data.currentStepIndex || 0;
            state.currentSubstepIndex = data.currentSubstepIndex || 0;
        },
        setCurrentStepIndex: (state, action) => {
            state.currentStepIndex = action.payload;
            state.currentSubstepIndex = 0;
        },
        setCurrentSubstepIndex: (state, action) => {
            state.currentSubstepIndex = action.payload;
        },
        clearRenewalFlow: () => initialState
    }
});

export const selectMotorInsuranceRenewalFlow = (store) => store.motorInsuranceRenewFlow;
export const selectRenewalFlowType = (store) => store?.motorInsuranceRenewFlow?.flowType;
export const selectCurrentStepIndex = (store) => store?.motorInsuranceRenewFlow?.currentStepIndex;
export const selectCurrentSubstepIndex = (store) => store.motorInsuranceRenewFlow?.currentSubstepIndex;


export const { setRenewalFlowType, setCurrentStepIndex, setCurrentSubstepIndex, clearRenewalFlow } = motorInsuranceRenewFlowSlice.actions;
export default motorInsuranceRenewFlowSlice.reducer;
