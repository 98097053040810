import React, { useEffect, useState } from "react";
import FundSwitchingTable from "../../../../../../components/common/investments/fundsSwitchingTable";
import TitleDescription from "../../../../../../components/newMotorPolicy/titleDescription/titleDescription";
import useFundsSwitchingFlow from "../../../../../../hooks/fundsSwitchingFlow";
import { useTranslation } from "react-i18next";
import RenewMotorLayout from "../../../../../motorInsurancePages/renewalMotorPolicy/renewMotorLayout";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const SwitchFundsPage = ({
  fundIdsToRemove,
  setFundIdsToRemove,
  fundsToRemove,
  setFundsToRemove,
  userFunds
}) => {
  const { handleNextSubstep } = useFundsSwitchingFlow();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [isContinueClicked, setIsContinueClicked] = useState(false);
  useEffect(() => {
    if (
      isContinueClicked &&
      fundIdsToRemove &&
      Object.keys(fundIdsToRemove).length > 0
    ) {
      handleNextSubstep();
    }
  }, [isContinueClicked]);
  return (
    <RenewMotorLayout
      backClickHandler={()=>navigate(`/life-insurance/fund-management${search}`)}
      btn_onClick={setIsContinueClicked}
    >
    <div className="w-[500px] max-w-[100%] mx-auto max767:p-5">

      <TitleDescription
        title={t(
          "lifeInsurance.investmentStrategyFlow.fundSwitchingFlow.switchFundsPage.title"
        )}
        description={t(
          "lifeInsurance.investmentStrategyFlow.fundSwitchingFlow.switchFundsPage.description"
        )}
      />
    
     
      </div>
      <div className="w-[1200px] max-w-[100%] mx-auto overflow-x-auto max767:p-5 max767:pt-0">
      <h3 className="text-[#1A2024] text-left text-sm not-italic font-semibold leading-6 uppercase ipad:pl-4 ipad:mb-4 mb-2">
        {t(
          "lifeInsurance.investmentStrategyFlow.fundSwitchingFlow.switchFundsPage.label"
        )}
      </h3>
      <FundSwitchingTable
        rowSelection={fundIdsToRemove}
        setRowSelection={setFundIdsToRemove}
        fundsToRemove={fundsToRemove}
        setFundsToRemove={setFundsToRemove}
        isContinueClicked={isContinueClicked}
        data={userFunds||[]}
      />
    </div>
    </RenewMotorLayout>
  );
};

export default SwitchFundsPage;
