/* :Page:MotorRenewal: Change Plan page */
import React, { useState } from "react";
import useRenewalFlow, {
  RENEW_POLICY_SAME_PLAN,
} from "../../../../hooks/useRenewalFlow";
import RenewMotorLayout from "../renewMotorLayout";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useTranslation } from "react-i18next";
import { CURRENT_PLAN, plans } from "./data";
import PlanCard from "../../newMotorPolicy/planPages/planCard";
import CurrentPlanDetailCard from "../reviewYourPolicyPage/currentPlanDetailCard";
import ViewFullListBenefitsModal from "../../newMotorPolicy/planPages/viewFullListBenefitsModal";
import listblackicon from "../../../../assets/images/list-black.svg";
import { RENEW_POLICY_SELECTED_POLICY } from "../selectPoliciesPage/selectPoliciesPage";
import { toast } from "react-toastify";

import useMotorForm from "../../../../hooks/useMotorFormData";
import { useDispatch, useSelector } from "react-redux";
import { selectNewMotorForm } from "../../../../redux/features/newMotorFormSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getPolicyPremiumLoading,
  policyPremium,
} from "../../../../redux/features/motor/renewalPremiumSlice";
import { getCoverPrices } from "../../../../redux/features/motor/getQuoteSlice";
import { coverPriceRequest } from "../../newMotorPolicy/planPages/data";
import { RENEW_POLICY_COVERAGE_ENHANCEMENT } from "../enhanceYourCoveragePage/enhanceYourCoveragePage";
import { clearApplyPromoState } from "../../../../redux/features/promoCode/promoCodeSlice";
import { PROMO_CODE } from "../../../../components/newMotorPolicy/veiwSummaryPopup/viewSummaryPopup";

export const RENEW_POLICY_SELECTED_PLAN = "RENEW_POLICY_SELECTED_PLAN";

const ChooseDowngradePlanPage = () => {
  /* Setting up dispatch to call redux */
  const dispatch = useDispatch();
  const [formState, setFormState] = useState({});

  const { removeMotorFormData } = useMotorForm(
    RENEW_POLICY_COVERAGE_ENHANCEMENT,
    setFormState
  );

  const { removeMotorFormPromoCodeData } = useMotorForm(
    PROMO_CODE,
    setFormState
  );

  /* Setting up variables with appropriate requests to send and response to receive, reading from redux/functions */
  const selectedPolicyRenewalInfo =
    useSelector(selectNewMotorForm)?.[RENEW_POLICY_SELECTED_POLICY];
  const selectedPolicyChangedPlan =
    useSelector(selectNewMotorForm)?.[RENEW_POLICY_SELECTED_PLAN];

  const selectedPolicyRenewalInfoQuoteDetails = selectedPolicyRenewalInfo;
  const isPolicyPremiumLoading = useSelector(getPolicyPremiumLoading);

  /* Functions and Variables that are updated with functions/state */
  const { handleNextSubstep, handleFlowChange } = useRenewalFlow();
  const { t } = useTranslation();
  const currentPlan = CURRENT_PLAN;
  const [selectedPlan, setSelectedPlan] = useState({
    code: selectedPolicyChangedPlan || null,
  });
  const [viewPlanList, setViewPlanList] = useState(false);

  const continueClickHanlder = async () => {
    if (
      selectedPlan &&
      selectedPlan.code != selectedPolicyRenewalInfo.PlanName
    ) {
      console.log(
        "New plan selected:",
        selectedPolicyRenewalInfo.PlanName,
        "To ",
        selectedPlan.code
      );

      try {
        // Add default covers for platinum
        const policyPremiumPayload = {
          PolicyNumber: selectedPolicyRenewalInfo.OriginalPolicyNo,
          Channel: "online.solidarity",
          Covers: [],
          // selectedPlan.code =="TPPlus"
          //   ? [
          //       {
          //         coverCode: "CMOT74",
          //         coverName: "Own Damage",
          //         coverProperty: {
          //           simpleProperty: [
          //             {
          //               Name: "SumInsuredLevel",
          //               Value: "10000",
          //             },
          //             {
          //               Name: "PremiumLevel",
          //               Value: "15",
          //             },
          //           ],
          //         },
          //       },
          //     ]
          //   : [],
          DefaultCovers: false,
          // NewPlan: selectedPlan.code =="TPPlus" ? "TPL" : selectedPlan.code,
          NewPlan: selectedPlan.code,
        };

        // Clear promo code
        await dispatch(clearApplyPromoState());
        await removeMotorFormPromoCodeData();

        // Calling API /policy/premium
        const policyPremiumAction = await dispatch(
          policyPremium(policyPremiumPayload)
        );
        const policyPremiumResult = unwrapResult(policyPremiumAction);

        console.log("Premium result is: ", policyPremiumResult);
        console.log("Premium new quote is: ", policyPremiumResult.PolicyNumber);
        console.log("Premium total is: ", policyPremiumResult.TotalPremium);

        if (policyPremiumResult) {
          // Call cover prices
          // Cover prices
          const coverPricesPayload = coverPriceRequest(
            selectedPolicyRenewalInfo.OriginalPolicyNo,
            selectedPolicyRenewalInfo.code,
            "",
            ""
          );
          await dispatch(getCoverPrices(coverPricesPayload));
          handleNextSubstep();
        }
      } catch {
        // alert("Could not get policy premium.")
        toast.error("Could not get policy premium.");
      }
    } else {
      console.log("Plan is not changed!!");
      handleFlowChange(RENEW_POLICY_SAME_PLAN, 3);
      // handleNextSubstep();
    }
  };
  const planOnClickHandler = async (plan) => {
    console.log("selectedPlan", plan.code);
    setSelectedPlan(plan);
    saveMotorDataInRedux(plan.code);
  };
  const { saveMotorDataInRedux } = useMotorForm(
    RENEW_POLICY_SELECTED_PLAN,
    setSelectedPlan
  );

  const filteredPlans = plans.filter((plan) => {
    if (selectedPolicyRenewalInfo.PlanName == "TPL") {
      // Only show Third Party plan
      return plan.code == "TPPlus";
    }
    if (selectedPolicyRenewalInfo.PlanName == "OFL") {
      // Only show Third Party plan
      return plan.code != "OFL";
    }
    if (selectedPolicyRenewalInfo.PlanName == "OPL") {
      // Only show Third Party plan
      return plan.code != "OPL";
    }
    // If no specific filtering is needed, return all plans
    return true;
  });

  // Clear final covers list from redux
  useState(() => {
    dispatch(removeMotorFormData);
  }, []);

  return (
    <RenewMotorLayout show_summary btn_onClick={continueClickHanlder}>
      <div className="changeyourplan p-4 w-[33%] max-350:w-[100%] max767:w-[100%] ipad:w-[55%] mx-auto">
        <TitleDescription
          title={t(
            "motorInsurance.renewalPolicyFlow.chooseDowngradePlanPage.title"
          )}
          description={t(
            "motorInsurance.renewalPolicyFlow.chooseDowngradePlanPage.description"
          )}
        />
        <CurrentPlanDetailCard plan={CURRENT_PLAN(selectedPolicyRenewalInfo)} />
      </div>
      <div className="selectyourcard">
        <div className="max767:w-full max767:overflow-x-scroll max767:pt-5 ipad:overflow-x-hidden ipad:w-[70%] mx-auto">
          <div className="flex gap-4 mb-4 max767:w-[1024px] max767:pr-0 max767:py-0 ipad:w-[536px] ipad:justify-end ipad:items-center ipad:content-center ipad:max-auto ipad:my-0 ipad:flex-wrap justify-center max767:pl-5">
            {filteredPlans.map((plan) => (
              <PlanCard
                planOnClickHandler={
                  isPolicyPremiumLoading ? () => {} : planOnClickHandler
                }
                selectedPlan={selectedPlan}
                plan={plan}
                isCurrentPlan={plan.name == currentPlan.name}
                showSelectButton
              />
            ))}
          </div>
        </div>
        <button
          className="flex items-center mx-auto gap-2 border  bg-white px-4 py-2 rounded-[10px] border-solid border-[#DDE2E4] text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
          onClick={() => setViewPlanList(!viewPlanList)}
        >
          <img src={listblackicon} alt="altText" />
          {t("motorInsurance.newMotorInsuranceFlow.planSelector.viewFullList")}
        </button>

        <ViewFullListBenefitsModal
          viewPlanList={viewPlanList}
          setViewPlanList={setViewPlanList}
        />
      </div>
    </RenewMotorLayout>
  );
};

export default ChooseDowngradePlanPage;
