import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, {  AxiosStrapi } from "../axiosInstance";
import { apiEndpoints, strapiApiEndPoints } from "../../api";

const initialState = {
  policies_bg: null,
  isLoading: false,
  error: null,

  userPolicies: null,
  isLoadingUserPolicies: false,
  errorUserPolicies: null,
};

export const fetchPoliciesBg = createAsyncThunk(
  "fetchPoliciesBg",
  (queryString, { rejectWithValue }) => {
    return AxiosStrapi.get(`${strapiApiEndPoints.policiesBg}`).then((res) => res.data);
  }
);
export const fetchUserPolicies = createAsyncThunk(
  "fetchUserPolicies",
  (paylaod, { rejectWithValue }) => {
    return Axios.post(`${apiEndpoints.getPolicies}`, paylaod).then((res) => res.data);
  }
);

export const myPolicySlice = createSlice({
  name: "offers slice",
  initialState,
  reducers: {
    clearOffersError: (state) => {
      state.error = null;
    },
    clearUserPoliciesError: (state) => {
      state.errorUserPolicies = null;
    },
  },
  extraReducers: (builder) => {
    // FETCH  UTILS API
    builder.addCase(fetchPoliciesBg.pending, (state, action) => {
      if (!state.policies_bg) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchPoliciesBg.fulfilled, (state, action) => {
      state.isLoading = false;
      state.policies_bg = action.payload?.data;
    });
    builder.addCase(fetchPoliciesBg.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });
    // FETCH  USER POLICIES API
    builder.addCase(fetchUserPolicies.pending, (state, action) => {
      state.isLoadingUserPolicies = true;
      state.userPolicies = null;
    });
    builder.addCase(fetchUserPolicies.fulfilled, (state, action) => {
      state.isLoadingUserPolicies = false;
      state.userPolicies = action.payload;
    });
    builder.addCase(fetchUserPolicies.rejected, (state, action) => {
      state.isLoadingUserPolicies = false;
      // state.error = action.error?.message;
    });
  },
});

// SELECTORS
export const selectPoliciesBgData = (store) => store?.myPolicies?.policies_bg;
export const selectMyPoliciesLoader = (store) => store?.myPolicies?.isLoading;

export const selectUserPoliciesData = (store) => store?.myPolicies?.userPolicies;
export const selectUserPoliciesLoader = (store) => store?.myPolicies?.isLoadingUserPolicies;

export const myPoliciesWholeLoader = (store) => store?.myPolicies?.isLoading || store?.myPolicies?.isLoadingUserPolicies

// ACTIONS TO DISPATCH
export const { clearOffersError, clearUserPoliciesError } = myPolicySlice.actions;

export default myPolicySlice.reducer;
