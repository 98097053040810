import React, { useEffect, useState } from "react";
import RenewMotorLayout from "../../renewalMotorPolicy/renewMotorLayout";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import ReviewMotorTable from "../../renewalMotorPolicy/reviewAndConfirmPage/reviewMotorTable";
import Input from "../../../../components/common/Input/Input";
import PromoCode from "../../../../components/newMotorPolicy/veiwSummaryPopup/promoCode";
import useMotorForm from "../../../../hooks/useMotorFormData";
import { PROMO_CODE } from "../../../../components/newMotorPolicy/veiwSummaryPopup/viewSummaryPopup";
import { useNavigate, useSearchParams } from "react-router-dom";
import Summary from "../../../../components/newMotorPolicy/veiwSummaryPopup/summary";
import { useTranslation } from "react-i18next";
import forwardarrow from "../../../../assets/images/arrow_forwardb.svg";
import Exclamation from "../../../../assets/images/Exclamation.svg";
import { useDispatch, useSelector } from "react-redux";
import { selectProfileData } from "../../../../redux/features/profileSlice";
import { getPolicyTransferSuccess, getStartPolicyTransferResponse } from "../../../../redux/features/endorsements/transferPolicySlice";
import { toast } from "react-toastify";
import { policyView } from "../../../../redux/features/policyDetailsSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { policyPay, policyPayTransferFees } from "../../../../redux/features/payment/policyPaySlice";
import CustomCheckbox from "../../../../components/common/customCheckbox/customCheckbox";

const ConfirmTransferDetails = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const [formState, setFormState] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();

  const oldName = searchParams.get('oldName');
  const decodedOldName = decodeURIComponent(oldName);

  const policyNumber = searchParams.get('policy');
  const decodedPolicyNumber = decodeURIComponent(policyNumber);

  const userInfo = useSelector(selectProfileData);
  const [policyInfo, setPolicyInfo] = useState("");

  const transferFeesResponse = useSelector(getStartPolicyTransferResponse);

  const response = {
    old_owner: decodedOldName,
    new_owner: `${userInfo?.FirstName || '-'} ${userInfo?.LastName || '-'}`,
  };


  const { saveMotorDataInRedux, removeMotorFormData } = useMotorForm(
    PROMO_CODE,
    setFormState
  );

  const applyPromoCode = () => {
    saveMotorDataInRedux(formState);
  };
  const navigate = useNavigate();

  useEffect(() => {
    // Define an async function inside the useEffect
    const fetchPolicyDetails = async () => {
      const payload = {
        PolicyNumber: decodedPolicyNumber,
        Channel: "online.solidarity"
      };
      
      try {
        // Calling API /policy/view
        const policyViewAction = await dispatch(policyView(payload));
        const policyViewResult = unwrapResult(policyViewAction);

        setPolicyInfo(policyViewResult);
      } catch (error) {
        // Handle errors in the catch block
        toast.error("Error in getting policy details: " + error.message);
        navigate("/");
      }
    };
  
    // Call the async function
    fetchPolicyDetails();
  }, [dispatch, decodedPolicyNumber, navigate]);

  /* Next/Payment */
  const handlePayment = async () => {

    const payload = {
      PolicyNumber: policyNumber,
      Premium: (transferFeesResponse.Fees + transferFeesResponse.PremiumToPay) * (1+0.10),
      Type: "transfer-policy",
      Channel: "online.solidarity",
    };

    try {
      // Calling API policy/pay
      const linkAction = await dispatch(policyPayTransferFees(payload));
      const link = unwrapResult(linkAction)
      // console.log("Link is: ", link)
      if (link) {
        window.location.href = link;
      }

    }
    catch (error) {
      // alert("Error in getting payment link: ", error)
      toast.error("Error in getting payment link: ", error);
      navigate("/");
    }
  };


  return (
    <RenewMotorLayout
      backClickHandler={() => navigate("/")}
      is_bottom_sticky_layout={false}
    >
      <div className="flex flex-col items-center mx-auto max-w-lg mt-20 max767:mt-20 max767:pb-16">
        <div className="flex justify-center items-center w-full">
          <div className="p-0 w-full max767:p-4 max767:pb">
            <TitleDescription
              title={"Confirm Transfer Details"}
              description={
                "Please review the policy information carefully before proceeding."
              }
            />

            <div className="mt-10">
              <div className="bg-[#F6F8F9] mb-6 p-4 rounded-2xl">
                <h4 className="text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px] mb-4">
                  New policy ownership
                </h4>

                <div className="plan flex align-middle items-center w-full confirmtransfer">
                  <Input value={response.old_owner} disabled />
                </div>
                <div className="line h-6 border-l-2 ml-8"></div>
                <div className="plan flex align-middle gap-5 mb-5 items-center confirmtransfer">
                  <Input value={response.new_owner} disabled />
                </div>
                <div className="reviwetable">
                  <ReviewMotorTable passedFrom={"transferPolicy"} />
                </div>
              </div>{" "}
              <div className="bg-[#F6F8F9] rounded-[26px] p-4 mb-4 w-full summary-box">
                <PromoCode
                  promoCode={formState.promoCode}
                  setPromoCode={(code) =>
                    setFormState((prevState) => ({
                      ...prevState,
                      promoCode: code,
                    }))
                  }
                  applyPromoCode={applyPromoCode}
                  clearPromoCode={() => {
                    setFormState({});
                    removeMotorFormData({});
                  }}
                />

                <Summary
                  premiumText={{ en: "Transfer Fees", ar: "ar Transfer Fees" }}
                  passedFrom={"transferPolicy"}
                />
              </div>
              <div className="w-full max767:px-4 max767:py-0">
                {/* Warning Message */}
                <div className="alertBox yellow flex items-start gap-4 self-stretch border bg-[#FDE9D2] p-4 rounded-[10px] border-solid border-[#FBC98E]">
                  <img src={Exclamation} alt="danger img" />
                  <span className="text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
                    {t(
                      "motorInsurance.newMotorInsuranceFlow.paymentSummary.disclaimer"
                    )}
                  </span>
                </div>

                <div className="flex">
                  <CustomCheckbox
                    Label={
                      <div className="flex">
                        <label
                          htmlFor="agreement"
                          className="text-[#252C32] text-sm not-italic font-normal leading-6"
                        >
                          {t(
                            "motorInsurance.newMotorInsuranceFlow.paymentSummary.agreement"
                          )}
                          <a
                            href="#"
                            className="text-[#00384D] text-sm not-italic font-semibold leading-6 underline"
                          >
                            {t(
                              "motorInsurance.newMotorInsuranceFlow.paymentSummary.policy"
                            )}
                          </a>{" "}
                          {t(
                            "motorInsurance.newMotorInsuranceFlow.paymentSummary.insurance"
                          )}
                        </label>
                      </div>
                    }
                    isChecked={isChecked}
                    setIsChecked={setIsChecked}
                  />
                </div>

                <button
                  className={`w-full text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] flex justify-center p-3 rounded-[10px] ${
                    isChecked
                      ? "bg-[#55C6EF] rounded-[10px] cursor-pointer"
                      : "bg-gray-300 text-gray-500 cursor-not-allowed"
                  }`}
                  disabled={!isChecked}
                  onClick={handlePayment}
                >
                  {t(
                    "motorInsurance.newMotorInsuranceFlow.paymentSummary.proceed_payment"
                  )}{" "}
                  <img
                    src={forwardarrow}
                    className=" w-6 h-6 ml-[5px] rtl:rotate-180 rtl:mr-2"
                    alt="forward arrow"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </RenewMotorLayout>
  );
};

export default ConfirmTransferDetails;
