import React, { startTransition } from "react";
import "./RegisterSuccessPage.css";
import Button from "../../../components/common/Button/Button";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { useSelector } from "react-redux";
import { selectLoginRegister } from "../../../redux/features/loginRegisterSlice";
import {  STRAPI_BASE_URL } from "../../../api/baseURL";
import { useNavigate } from "react-router-dom";
// import { updateLoggedInUserDataInLocalStorage } from "../../../config/config";
import { useTranslation } from "react-i18next";

const RegisterSuccessPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const translate = useLanguageTranslate();
  const loginRegisterData = useSelector(selectLoginRegister);
  const NextHandler = () => {
    // updateLoggedInUserDataInLocalStorage({ isLoggedIn: true });
    startTransition(() => {
      navigate("/sign-in");
    });
  };
  return (
    <div className="bkimag h-screen max767:absolute max767:z-[9999] max767:w-full">
    <div className="register_success_page flex justify-center items-center h-screen max767:bg-[#183945cf]">
      
      <div className="register-content-box w-[690px] text-center max767:w-[90%] max767:mt-24">
        <img
          src={`${STRAPI_BASE_URL}${loginRegisterData?.tick_mark_img?.img?.data?.attributes?.url}`}
          alt="tick"
          className="tick_mark text-center inline-flex mb-12"
        />
        <h1 className="text-[#48535B] uppercase text-center text-[64px] not-italic font-extrabold leading-[100%] tracking-[-0.64px] mb-2 max767:text-[#00A9E7] max767:text-center max767:text-[32px] max767:not-italic max767:font-black max767:leading-[120%]">
          {translate(
            loginRegisterData?.register_success_content?.title_en,
            loginRegisterData?.register_success_content?.title_ar
          )}
        </h1>
        <h3 className="text-[#1A2024] text-2xl not-italic font-medium leading-8 tracking-[-0.24px] mb-4 max767:text-base max767:not-italic max767:font-normal max767:text-white max767:mt-4">
          {translate(
            loginRegisterData?.register_success_content?.description_en,
            loginRegisterData?.register_success_content?.description_ar
          )}
        </h3>
        <p className="text-center flex justify-center max767:mt-16"><Button
          label={t("signUpFlow.verifySuccessPage.btn_label")}
          className="sign-in-btn w-[500px] flex-col items-center gap-2.5 p-4 bg-[#55C6EF] rounded-[10px] text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px]"
          onSubmit={NextHandler}
        /></p>
        
      </div>
      </div>
    </div>
  );
};

export default RegisterSuccessPage;
