import {
  format,
  parse,
  parseISO,
  differenceInYears,
  differenceInMonths,
  differenceInDays,
} from "date-fns";
import { clearLoggedInUserDataInLocalStorage } from "../config/config";
import { ar, enUS } from "date-fns/locale";

export const expireSoonCalculator = (endDate, days) => {
  const end = new Date(endDate);
  const today = new Date();
  const timeDiff = end - today;
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  return daysDiff < days && daysDiff > 0 ? daysDiff : null;
};
export const daysCalculator = (startDate, endDate) => {
  const end = new Date(endDate);
  const today = new Date(startDate);
  const timeDiff = end - today;
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
  return daysDiff;
};

export const formatDate = (dateString) => {
  try {
    const dateObj = parseISO(dateString);
    return format(dateObj, "d MMMM yyyy");
  } catch (error) {
    return "";
  }
};

export function formatToThreeDigits(value) {
  if (typeof value != "number" || isNaN(value)) {
    return 0;
  }
  return Number(value.toFixed(3));
}

export const isNumeric = (value) => {
  return /^\d*$/.test(value);
};
export const isAlphabetsAndSpaces = (value) => {
  return /^[a-zA-Z\s]*$/.test(value);
};

export const MMDDformatter = (value) => {
  const digits = value.replace(/\D/g, "");
  if (digits.length <= 2) {
    return digits;
  } else if (digits.length <= 4) {
    return `${digits.slice(0, 2)}/${digits.slice(2)}`;
  } else {
    return `${digits.slice(0, 2)}/${digits.slice(2, 4)}`;
  }
};

export const mockApiCall = (response) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(response);
    }, 2000);
  });
};

export const percentageWith2Decimals = (value) => {
  return Number(value || 0).toFixed(2) + "%";
};
export const With2Decimals = (value) => {
  return Number(value || 0).toFixed(2);
};
export const perCalcMinus100 = (value) => {
  if (!value && value != 0) return "NA";
  else {
    return Number((value || 0) - 100).toFixed(2) + "%";
  }
};
export const generateQueryString = (data) => {
  const queryString = new URLSearchParams(data).toString();
  return queryString;
};
export const decodeQueryString = () => {
  const queryString = window.location.search;
  console.log(queryString, "some query string");
  const params = new URLSearchParams(queryString);
  const data = {};
  params.forEach((value, key) => {
    data[key] = value;
  });
  return data;
};
export const dateFormatter = (date, dateFormatType = "d MMM yyyy", locale) => {
  // Format date in '1 Dec 2020' format use  dateFormatType = "d MMM yyyy"
  // Format date in '1 December 2020' format use  dateFormatType = "d MMMM yyyy"
  // Format date in 'Dec 2020' format use  dateFormatType = "MMM yyyy"
  if (!date) return null;
  let parsedDate;
  // Attempt parsing with "dd/MM/yyyy HH:mm:ss" format first
  try {
    parsedDate =
      date instanceof Date
        ? date
        : parse(date, "dd/MM/yyyy HH:mm:ss", new Date());
  } catch (error) {
    console.log("Failed to parse with time, trying without time...");
  }

  // If parsing with time failed or date is invalid, try "dd/MM/yyyy" format
  if (!parsedDate || isNaN(parsedDate.getTime())) {
    parsedDate =
      date instanceof Date ? date : parse(date, "dd/MM/yyyy", new Date());
  }

  return format(parsedDate, dateFormatType, { locale: locale =="ar" ? ar : enUS });
};

// Helper function to calculate date differences in years or days
export const calculateDateDifference = (start, end, format, yearLabel) => {
  if (!start || !end) return null;

  const startDate = parse(start, "dd/MM/yyyy HH:mm:ss", new Date());
  const endDate = parse(end, "dd/MM/yyyy HH:mm:ss", new Date());

  if (format =="years") {
    return `${differenceInYears(endDate, startDate)} ${yearLabel}`;
  } else if (format =="days") {
    return `${differenceInDays(endDate, startDate)} days`;
  }

  return null;
};

// Calculate remaining term in 'X years Y months' format
export const calculateYearsMonthsRemaining = (start, end, yearTrasnlation = "years", monthTranslation = "months") => {
  if (!start || !end) return null;

  // Check if start and end are Date objects or strings
  const startDate =
    start instanceof Date
      ? start
      : parse(start, "dd/MM/yyyy HH:mm:ss", new Date());
  const endDate =
    end instanceof Date ? end : parse(end, "dd/MM/yyyy HH:mm:ss", new Date());

  const years = differenceInYears(endDate, startDate);
  const months = differenceInMonths(endDate, startDate) - years * 12; // Calculate the remaining months

  return `${years} ${yearTrasnlation} ${months} ${monthTranslation}`;
};
export const getTodayAndMonthsBack = (monthsBack = 12) => {
  const today = new Date();

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const pastDate = new Date();
  pastDate.setMonth(today.getMonth() - monthsBack);

  return {
    today: formatDate(today),
    pastDate: formatDate(pastDate),
  };
};

export const formatDate2 = (value) => {
  const formattedPastDate = format(value, "MMM yyyy");
  return formattedPastDate;
};

export const intFromBhdCurrency = (currentValue) => {
  return currentValue?.length > 4
    ? currentValue?.slice(4, currentValue?.length)
    : currentValue;
};

export const getCurrencyAndValue = (currentValue) => {
  // Validate input
  if (!currentValue || typeof currentValue != "string") {
    return { value: "0", country_code: "" };
  }

  const parts = currentValue.split(" ");

  if (parts.length ==2) {
    // Case: "BHD 12345"
    return {
      country_code: parts[0],
      value: parts[1],
    };
  } else if (parts.length ==1) {
    // Case: "12345" (no country code)
    return {
      country_code: "", // No country code present
      value: parts[0],
    };
  }

  // Fallback for unexpected cases
  return { value: "0", country_code: "" };
};

export const currencyFormatter = (amount, locale = "en-US") => {
  try {
    const numAmount = Number(amount);

    if (isNaN(numAmount)) {
      return amount;
    }

    const isWholeNumber = numAmount ==Math.floor(numAmount);
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: isWholeNumber ? 0 : 2, // Omit decimals for whole numbers
      maximumFractionDigits: 2, // Show up to 2 decimals for fractional numbers
    }).format(numAmount);
  } catch (error) {
    console.error("Error formatting number:", error);
    return amount;
  }
};

export const currencyFormatterWithCountryCode = (x, locale = "en-US") => {
  let { value, country_code } = getCurrencyAndValue(x);
  let formattedValue = currencyFormatter(value, locale);
  return `${country_code} ${formattedValue}`;
};

export const clearLocalStorageForUnauthorized = () => {
  clearLoggedInUserDataInLocalStorage();
  window.location.href = "/";
};

export const removeKeysFromObject = (obj, keysToRemove) => {
  return Object.keys(obj)
    .filter(key => !keysToRemove.includes(key))
    .reduce((result, key) => {
      result[key] = obj[key];
      return result;
    }, {});
}