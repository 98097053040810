
/* :Data: New motor plans and setting up covers request */
import ThirdParty from "../../../../assets/images/thirdparty.svg";
import ThirdPartyplus from "../../../../assets/images/thirdparty+.svg";
import Flexi from "../../../../assets/images/Flexi.svg";
import Gold from "../../../../assets/images/Gold.svg";
import OnlineFlexi from "../../../../assets/images/OnlineFlexi.svg";
import Platinium from "../../../../assets/images/Platinium.svg";
export const getMotorPlans = (tpl, fl, go, pl, carAge) => [
  {
    imgSrc: ThirdParty,
    name: "Third Party",
    name_ar: "طرف ثالث",
    code: "TPL",
    price: tpl[0] ? `BHD ${tpl[0].PremiumIncludingLoadingDiscountWithVAT}` : "-",
    // price_ar:"50.4 دينار بحريني",
    amount: tpl[0] ? tpl[0].PremiumIncludingLoadingDiscountWithVAT : null,
    features: [
      {
        en: "Third party Bodley Injury unlimited",
        ar: "إصابة طرف ثالث Bodley غير محدودة"
      },
      {
        en: "Third party property damage up to BD 500,000",
        ar: "أضرار الممتلكات الخاصة بالطرف الثالث تصل إلى 500,000 دينار بحريني"
      },
    ],
    selected: false,
    isRecommended: false,
    weightage: 1,
  },
  {
    imgSrc: ThirdPartyplus,
    name: "Third Party +",
    name_ar: "الطرف الثالث +",
    // price_ar:"50.4 دينار بحريني",
    code: "TPPLUS",
    price: fl[0] ? `BHD ${fl[0].PremiumIncludingLoadingDiscountWithVAT}` : "-",
    amount: fl[0] ? fl[0].PremiumIncludingLoadingDiscountWithVAT : null,
    features: [
      {
        en: "Car Replacement up to 8 Days",
        ar: "استبدال السيارة لمدة تصل إلى 8 أيام"
      },
      {
        en: "24/7 Roadside Assistance in Bahrain",
        ar: "خدمة المساعدة على الطريق على مدار الساعة طوال أيام الأسبوع في البحرين"
      },
      {
        en: "Up to BD 10,000 Coverage for Uninsured Vehicle Incidents",
        ar: "تغطية تصل إلى 10,000 دينار بحريني لحوادث المركبات غير المؤمنة"
      },
      {
        en: "Third-Party Liability Coverage",
        ar: "تغطية المسؤولية تجاه الطرف الثالث"
      },
    ],
    selected: false,
    isRecommended: false,
    weightage: 2,
  },
  // {
  //   imgSrc: Flexi,
  //   name: "Flexi",
  //   price: "BHD 147.6",
  //   features: [
  //     {
  //       en: "Third party Bodley Injury unlimited",
  //       ar: "ar Third party Bodley Injury unlimited"
  //     },
  //     {
  //       en: "Third party property damage up to BD 500,000",
  //       ar: "ar Third party Bodley Injury unlimited"
  //     },
  //   ],
  //   selected: false,
  //   isRecommended: false,
  // },
  {
    imgSrc: OnlineFlexi,
    name: "Online Flexi",
    name_ar: "أونلاين فلكسي",
    code: "OFL",
    price: go[0] ? `BHD ${go[0].PremiumIncludingLoadingDiscountWithVAT}` : "-",
    amount: go[0] ? go[0].PremiumIncludingLoadingDiscountWithVAT : null,
    // name_ar:"ذهب",
    // price_ar:"147.6 دينار بحريني",
    features: [
      {
        en: "5 Years Agency Repair",
        ar: "5 سنوات من إصلاح الوكالة"
      },
      {
        en: "BD 500,000 Third-Party Property Damage",
        ar: "500,000 دينار بحريني تعويض عن أضرار الممتلكات التي يلحقها طرف ثالث"
      },
      {
        en: "BD 500 Personal Accident Coverage",
        ar: "تغطية الحوادث الشخصية بمبلغ 500 دينار بحريني"
      },
      {
        en: "Optional Roadside Assistance",
        ar: "المساعدة على الطريق الاختيارية"
      },
    ],
    selected: false,
    isRecommended: carAge,
    weightage: 3,

  },
  {
    imgSrc: Platinium,
    name: "Online Platinum",
    name_ar: "البلاتين",
    code: "OPL",
    price: pl[0] ? `BHD ${pl[0].PremiumIncludingLoadingDiscountWithVAT}` : "-",
    // price_ar:"317.9 دينار بحريني",
    amount: pl[0] ? pl[0].PremiumIncludingLoadingDiscountWithVAT : null,
    features: [
      {
        en: "Lifetime Agency Repair",
        ar: "إصلاح الوكالة مدى الحياة"
      },
      {
        en: "Unlimited Third-Party Bodily Injury Coverage",
        ar: "تغطية غير محدودة لإصابات الطرف الثالث الجسدية"
      },
      {
        en: "BD 5000 Personal Accident Coverage",
        ar: "تغطية الحوادث الشخصية بمبلغ 5000 دينار بحريني"
      },
      {
        en: "30-Day GCC Coverage",
        ar: "تغطية دول مجلس التعاون الخليجي لمدة 30 يومًا"
      }
    ],
    selected: true,
    isRecommended: !carAge,
    weightage: 4,

  },
];

const currentYear = new Date().getFullYear();

export const calculatePremiumRequest = (brand_new, car_details, car_financing, car_from_gdt, car_from_os) => {

  // console.log("On premium request: ");
  // console.log(car_details);
  // console.log(car_from_gdt);
  // console.log(car_from_os);
  // console.log(car_from_os == "");
  // console.log("If conditions: "); 
  // console.log(car_from_gdt == "");

  let carAge, modelYear, bodyTypeCode, engineSize, makeCode, modelCode, partyCode, seats;
  if (car_details) {
    console.log("Car details found");

    carAge = Math.max((currentYear - car_details.modelYear) + 1, 0);

    modelYear = car_details.modelYear;
    bodyTypeCode = car_details.carBodyType;
    engineSize = car_details.engineCapacity;
    makeCode = car_details.modelMake;
    modelCode = car_details.carModel;
    seats = car_details.seats;
  }
  else if (car_from_gdt != "") {
    console.log("Car details not found. Taking from GDT.");

    // carAge = (currentYear - car_from_gdt.YearOfManufacture) + 1; 
    carAge = Math.max((currentYear - car_from_gdt.YearOfManufacture) + 1, 0);


    modelYear = car_from_gdt.YearOfManufacture;
  }
  // else if (car_from_os != "") {
  //   console.log("Car details not found. Taking from Ownership.");

  //   carAge = (currentYear - car_from_os.ModelYear) + 1;

  //   bodyTypeCode = "SEDAN";
  //   engineSize = car_from_os.EngineCap;
  //   makeCode = car_from_os.MakeCode;
  //   modelCode = car_from_os.ModelCode;
  //   seats = "4";
  // }
  else {
    modelYear = "";
  }

  return {
    Age: carAge, //
    PolicyHolderAge: "35", //
    strProductCode: "0101",
    BodyTypeCode: bodyTypeCode ? bodyTypeCode : "SEDAN",//
    Channel: "online.solidarity",
    EngineSize: engineSize ? engineSize : "0000", //
    IsBrandNew: brand_new.is_brand_new == 'Yes' ? true : false,//
    // IsNCDApplicable: false,//
    MakeCode: makeCode ? makeCode : "00",//
    ModelCode: modelCode ? modelCode : "000",//
    ModelYear: modelYear ? modelYear : "",//
    ModeOfBusiness: "NEW",//
    NCDFactor: brand_new.is_brand_new == 'Yes' ? 'BRANDNEW' : '',//
    PartyCode: "FALCON",//
    PlanName: "TPL,TPP,OFL,OPL",
    PlateCategory: "PVT",
    Seats: seats ? seats : "", //
    Specification: "NORMAL",
    SumInsured: car_financing.amount, //
    TypeCode: "P",
    PolicySubType: "PRIVATE", //
    VehicleCategory: "FMVHCL" //
  }
}

export const getQuoteRequest = (brand_new, car_details, car_financing, plan_code, addedCovers,
  car_from_gdt, car_from_os, plateNumber, partyCode, newEndDate, newFromDate, plateType) => {
  let fromDate, toDate, coversRequest, carAge, modelYear, bodyTypeCode, engineSize, makeCode, modelCode, seats, chasissNo;
  // Model year 
  if (car_details) {
    // carAge = currentYear - car_details.modelYear;
    carAge = Math.max((currentYear - car_details.modelYear + 1), 0);
    modelYear = car_details.modelYear;
    bodyTypeCode = car_details.carBodyType;
    engineSize = car_details.engineCapacity;
    makeCode = car_details.modelMake;
    modelCode = car_details.carModel;
    seats = car_details.seats;
    chasissNo = car_details.chasisNumber
  }
  // If car is brand new, take user set from date and add one year from the last day of current month of start date
  if (brand_new.is_brand_new == 'Yes') {
    // From date to unix
    const date = new Date(car_financing.start_policy_date);
    fromDate = Math.floor(date.getTime() / 1000);

    toDate = getToDate(fromDate);

  }
  // If car is used, pro-rata with GDT. Below conditions
  /*
    Start date: If GDT expiry is more than 90 days ahead, user should not issue a new. Display message
                If GDT expiry is backdated, ??
                If GDT expiry date is less than 90 days ahead, start date is GDT expiry date + 1 day
  */
  else {
    console.log('Setting pro-rata with GDT End date calc: ', newEndDate);

    // fromDate = Math.floor(Date.now() / 1000);
    const newFromDateDate = new Date(newFromDate)
    fromDate = Math.floor(newFromDateDate.getTime() / 1000);

    // To date condition
    const newEndDateDate = new Date(newEndDate);
    toDate = Math.floor(newEndDateDate.getTime() / 1000);

    // Model year from GDT
    if (car_from_gdt != "") {
      console.log("Car details not found. Taking from GDT.");

      // carAge = (currentYear - car_from_gdt.YearOfManufacture) + 1; 
      carAge = Math.max((currentYear - car_from_gdt.YearOfManufacture) + 1, 0);

      modelYear = car_from_gdt.YearOfManufacture;
    }
    // else if (car_from_os != "") {
    //   console.log("Car details not found. Taking from Ownership.");

    //   carAge = (currentYear - car_from_os.ModelYear) + 1;

    //   modelYear = car_from_os.ModelYear
    //   bodyTypeCode = "SEDAN";
    //   engineSize = car_from_os.EngineCap;
    //   makeCode = car_from_os.MakeCode;
    //   modelCode = car_from_os.ModelCode;
    //   seats = "4";
    //   plateNumber = car_from_os.VehicleNo;

    // }
    else {
      modelYear = ""
    }
  }


  if (addedCovers != null) {
    console.log("Added covers are: ", addedCovers)
    coversRequest = extractCoverOptions(addedCovers)
    console.log("Got covers request: ", coversRequest)
  }

  return {
    Age: carAge, //
    BodyTypeCode: bodyTypeCode ? bodyTypeCode : "SEDAN",//
    Channel: "online.solidarity",
    ChasissNumber: chasissNo,
    Color: "",
    Covers: addedCovers != null ? coversRequest : [],
    // Covers: addedCovers != null ? coversRequest : plan_code == 'TPPLUS' ? [
    //   {
    //     "coverCode": "CMOT74",
    //     "coverName": "Own Damage",
    //     "coverProperty": {
    //         "simpleProperty": [
    //             {
    //                 "Name": "SumInsuredLevel",
    //                 "Value": "10000"
    //             },
    //             {
    //                 "Name": "PremiumLevel",
    //                 "Value": "15"
    //             }
    //         ]
    //     }
    // }
    // ]: [],
    EngineNumber: null,
    EngineSize: engineSize ? engineSize : "0000", //
    FirstRegistrationDate: "", //
    FromDate: fromDate, //
    IsBrandNew: brand_new.is_brand_new == 'Yes' ? true : false,//
    IsBreakInsurance: false,
    IsCoverNotePresent: false,
    IsCurrentlyInsured: false,
    IsDeletedRisk: false,
    IsExported: false,
    IsModified: false,
    IsMortgaged: car_financing?.is_finance == "Yes" ? true : false,
    IsNCDApplicable: brand_new.is_brand_new == 'Yes' ? false : true,
    IsTPBreakInsurance: false,
    LenderCode: "",
    MakeCode: makeCode ? makeCode : "00",//
    ModelCode: modelCode ? modelCode : "000",//
    ModelYear: modelYear,//
    ModeOfBusiness: brand_new.is_brand_new == 'Yes' ? "NEW" : "ROLL",//
    NCDFactor: car_financing.last_time_file_claim == 'more_than_a_year' && car_financing?.is_finance == "No" ? '1' : '0',//
    PartyCode: partyCode,//
    PlanName: plan_code,
    PlateCategory: plateType != "" ? getPlateTypeName(plateType) : "PVT",
    PreviousPolicyNumber: "",
    PreviousPolicyToDate: "",
    QuoteNumber: "",
    RegistrationNumber: plateNumber ? plateNumber : "", //
    Seats: seats ? seats : "", //
    Specification: "NORMAL",
    SumInsured: car_financing.amount, //
    ToDate: toDate, //
    TypeCode: "P",
    PolicySubType: "PRIVATE", //
    VehicleCategory: "FMVHCL" //
  }
}


export const coverPriceRequest = (quoteNumber, planName, carDays, carSize) => {

  let carReplacementDays;
  let carReplacementSize;

  if (carDays == "" || carSize == "") {
    // If carDays or carSize is empty, get the values from getVehicleReplacementDays
    ({ days: carReplacementDays, size: carReplacementSize } = getVehicleReplacementDays(planName));
  } else {
    // If carDays and carSize are provided, use them directly
    carReplacementDays = carDays;
    carReplacementSize = carSize;
  }

  // Ensure days is int 
  carReplacementDays = parseInt(carReplacementDays, 10); // Base 10 integer conversion
  // Ensure size is uppercase
  // carReplacementSize = carReplacementSize.toUpperCase();
  carReplacementSize = carReplacementSize && typeof carReplacementSize == 'string' ? carReplacementSize.toUpperCase() : '';;


  const geoExtension = getGeoExtension(planName);

  console.log('Setting car days and size: ', carDays, carSize, planName);

  return {
    PolicyNumber: quoteNumber,
    CarReplacementDays: carReplacementDays ? carReplacementDays : "",
    CarReplacementSize: carReplacementSize ? carReplacementSize : "",
    GeoCoverKSA: geoExtension ? geoExtension : "",
  };
};

// Helpers
const getVehicleReplacementDays = (planName) => {
  switch (planName) {
    case "TPL":
      return { days: 0, size: 0 };
    // case "FL":
    //   return { days: 8, size: "SMALL" };
    case "GO":
      return { days: 8, size: "SMALL" };
    case "PL":
      return { days: 30, size: "SMALL" };
    default:
      return { days: 0, size: 0 }; // Optional: Handle unrecognized plan names
  }
};

const getGeoExtension = (planName) => {
  switch (planName) {
    case "GO":
      return true;
    case "PL":
      return false;
    case "OFL":
      return true;
    default:
      return "";
      break;
  }
}

// Set ToDate for brand new cars
const getToDate = (fromDate) => {
  // Create a new Date object based on the provided fromDate (expects Unix timestamp in seconds)
  const endDate = new Date(fromDate * 1000);

  // Set the year to next year
  endDate.setFullYear(endDate.getFullYear() + 1);

  // Set the month to the next month and set the day to 0 to get the last day of the current month
  endDate.setMonth(endDate.getMonth() + 1, 0);

  // Convert to Unix timestamp (in seconds) and return
  console.log("Calculated date is :", endDate);

  return Math.floor(endDate.getTime() / 1000);
};



// Set ToDate for used cars (Pro-rat with GDT)


// Set covers request from included covers 
export const extractCoverOptions = (data) => {
  if (data) {
    return data.coverOptionsState
      .filter(option => option.included == true && option.defaultIncludedInPlan == false)
      .map(option => {
        const cover = {
          coverCode: option.code,
          coverName: option.name,
        };

        if (option.code == 'CMOT28') {
          cover.coverProperty = {
            simpleProperty: [
              {
                Name: "Days",
                Value: option.selectedDays
              },
              {
                Name: "CarSize",
                Value: option.selectedCarType
              }
            ]
          };
        }

        if (option.code == 'CMOT74') {
          cover.coverProperty = {
            simpleProperty: [
              {
                Name: "SumInsuredLevel",
                Value: option.selectedSumInsuredType
              },
              {
                Name: "PremiumLevel",
                Value: "15"
              }
            ]
          };
        }

        if (option.code == 'CMOT24') {
          cover.coverProperty = {
            simpleProperty: [
              {
                Name: "Area",
                Value: option.selectedGeoOption
              }
            ]
          };
        }

        return cover;
      });
  }
};

export const getPlateTypeName = (plateType) => {
  switch (plateType) {
    case "01":
      return "PVT";
    case "16":
      return "PVTPCKUP";
    case "17":
      return "PVTGDSVEH";
    case "26":
      return "CLSC";
    case "09":
      return "TAXI";
    case "02":
      return "FORHIRE";
    case "10":
      return "HIREMOTOR";
    case "15":
      return "PVTTRPPSG";
    case "06":
      return "PUBTRPPSG";
    case "14":
      return "SPCLUSE";
    case "13":
      return "ROYLCORT";
    case "05":
      return "PUBSCURTY";
    case "03":
      return "TXIONC";
    case "12":
      return "CNTRCTRS";
    case "22":
      return "SEMITRAIL";
    case "04":
      return "DIPLOMATIC";
    case "11":
      return "TOURSTBUS";
    case "07":
      return "PUBPCKUP";
    case "08":
      return "PUBTRANS";
    default:
      return "PVT"; // Default to "PVT" if no match
  }
};
