export const screenResolution = {
  mobile: window.innerWidth < 768,
  tablet: window.innerWidth >= 768 && window.innerWidth <= 1024,
  desktop: window.innerWidth > 1024,
};

// export const screenResolution = {
//   mobile: true,
//   tablet: false,
//   desktop: false,
// };