import React from 'react';
import Button from '../../common/Button/Button';
import Input from '../../common/Input/Input';
import closepromo from "../../../assets/images/close.svg";
import useSummary from '../../../hooks/useSummary';
import useLanguageTranslate from '../../../hooks/useLanguageTranslate';
import { useSelector } from 'react-redux';
import { getPromoCodeLoading } from '../../../redux/features/promoCode/promoCodeSlice';
import { Loader } from 'storybook/internal/components';
import { PROMO_CODE } from './viewSummaryPopup';
import { selectNewMotorForm } from '../../../redux/features/newMotorFormSlice';

const PromoCode = ({ promoCode, setPromoCode, applyPromoCode,clearPromoCode, isDisabled= false  }) => {
  const { promocode,discount_percent, total_amount , premium,discount,discount_percentage,vat_additional_amount} = useSummary()
  console.log(promocode + 'promocode');
  console.log(promoCode + "promoCode");  
  const translate=useLanguageTranslate();
  const isPromoCodeLoading = useSelector(getPromoCodeLoading);
  const promoCodeDetails = useSelector(selectNewMotorForm)?.[PROMO_CODE];

  return (
    <div className="bg-[#FDE9D2] rounded-[10px] items-center gap-2.5 self-stretch p-4 mb-4">
      <div className="flex apply-input gap-1 min-h-[75px] justify-between">
        <Input
          label={translate("Promo code", "كود الخصم")}
          type="text"
          name="promoCode"
          className="text-[#48535B] placeholder:text-gray-300 text-base not-italic font-normal leading-[26px] tracking-[-0.16px]"
          value={promoCode}
          placeHolder={translate("PROMO CODE", "كود الخصم")}
          data-testid="promoCode"
          handleChange={(e) => setPromoCode(e.target.value.toUpperCase())} // Always convert input to uppercase
        />

        <Button
          isLoading={isPromoCodeLoading ? isPromoCodeLoading : false}
          label={translate("Apply", "يتقدم")}
          onSubmit={applyPromoCode} // Pass the function reference, not the invocation
          className="bg-blue-500 text-white rounded px-4 py-2 w-full"
          disabled={!promoCode}
        />
      </div>
      {promoCodeDetails?.promoCodeApplied == true ? (
        <div className="text-[#7C4A0F] text-xs not-italic font-normal leading-4 flex">
          <span className="font-semibold">
            Promo code applied. ({promocode})
          </span>
          {/* <button
            className="ml-1 right-0 text-gray-500 hover:text-gray-700"
            onClick={clearPromoCode}
          >
            <img src={closepromo} className="w-4" alt="" />
          </button> */}
        </div>
      ) : null}
    </div>
  );
};

export default PromoCode;