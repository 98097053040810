export const PERSONAL_DETAIL_QUESTIONS = [
    {
        "id": 12,
        "content_en": "Marital Status",
        "content_ar": "الحالة الاجتماعية",
        "mapping_key": "Martialstatus"
    },
    {
        "id": 13,
        "content_en": "Gender",
        "content_ar": "الجنس",
        "mapping_key": "Gender"
    }
]