import React, { useState } from "react";

import "./selectFundsTable.css";
import FundsPreviewPopup from "./fundsPreviewPopup/fundsPreviewPopup";
import useLanguageTranslate from "../../../../../../hooks/useLanguageTranslate";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BackdropModal from "../../../../../../components/common/modal/backdropModal";
import Button from "../../../../../../components/common/Button/Button";
import fafilter from "../../../../../../assets/images/filterbutton.svg";
import searchimg from "../../../../../../assets/images/graySearch.svg";
import {
  assetClassesOptions,
  categoriesOptions,
  currenciesOptions,
  geographicFocusOptions,
} from "./data";
import FormTabs from "../../../../../../components/common/tabs/formTabs";
import CustomCheckbox from "../../../../../../components/common/customCheckbox/customCheckbox";

const SelectFundsTableMobile = ({
  data,
  switchToggle,
  backClick,
  nextClick,
  setUserFunds,
  userFunds,
  setSelectedNewFunds,
}) => {
  const translate = useLanguageTranslate();
  const [columnFilters, setColumnFilters] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [showFundPreview, setShowFundPreview] = useState(false);
  const [rowSelection, setRowSelection] = useState([]);

  const showFundPreviewPopup = (fund) => {
    setShowFundPreview({
      isin: fund.isin,
      original: {
        attributes: {
          fundName: fund.fund_name,
          ISIN: fund.isin,
          assetClass: "",
          category: "",
          description: "",
          pdfLink: "",
        },
      },
    });
  };
  const fundClickHandler = () => {
    let selectedFundsWithData = data?.filter((each) =>
      rowSelection?.includes(each?.isin)
    );
    selectedFundsWithData = selectedFundsWithData.map((each) => ({
      ...each,
      isNew: true,
    }));
    setSelectedNewFunds && setSelectedNewFunds(selectedFundsWithData);
    // Filter `userFunds` to exclude already selected `isNew` funds (avoid duplicates)
    let filteredUserFunds = userFunds?.filter((each) => !each.isNew);

    // Combine the filtered `userFunds` and the newly selected funds
    let updatedUserFunds = [...filteredUserFunds, ...selectedFundsWithData];

    setUserFunds(updatedUserFunds);

    switchToggle ? switchToggle() : nextClick();
  };

  // Filter the data based on selected filters
  const filteredData = data
    .filter((fund) => {
      const categoryMatch = columnFilters.find(
        (filter) => filter.id == "category"
      )
        ? fund.category ===
          columnFilters.find((filter) => filter.id == "category").value
        : true;

      const currencyMatch = columnFilters.find(
        (filter) => filter.id == "currency"
      )
        ? fund.currency ===
          columnFilters.find((filter) => filter.id == "currency").value
        : true;

      const geographicFocusMatch = columnFilters.find(
        (filter) => filter.id == "geographicFocus"
      )
        ? fund.geographicFocus ===
          columnFilters.find((filter) => filter.id == "geographicFocus").value
        : true;

      const assetClassMatch = columnFilters.find(
        (filter) => filter.id == "assetClass"
      )
        ? fund.assetClass ===
          columnFilters.find((filter) => filter.id == "assetClass").value
        : true;

      const fundNameMatch = columnFilters.find(
        (filter) => filter.id == "fund_name"
      )
        ? fund.fund_name
            .toLowerCase()
            .includes(
              columnFilters
                .find((filter) => filter.id == "fund_name")
                .value.toLowerCase()
            )
        : true;

      return (
        categoryMatch &&
        currencyMatch &&
        geographicFocusMatch &&
        assetClassMatch &&
        fundNameMatch
      );
    })
    .sort((a, b) => {
      const fundNameSort = columnFilters.find(
        (filter) => filter.id == "fundNameSort"
      );

      if (fundNameSort && fundNameSort.value == "asc") {
        return a.fund_name.localeCompare(b.fund_name); // Ascending order
      } else if (fundNameSort && fundNameSort.value == "desc") {
        return b.fund_name.localeCompare(a.fund_name); // Descending order
      } else {
        return 0; // No sorting applied
      }
    });

  const handleCheckboxChange = (e, fundId) => {
    e.stopPropagation();
    setRowSelection((prevSelected) => {
      const newSelection = new Set(prevSelected);
      if (newSelection.has(fundId)) {
        newSelection.delete(fundId); // Unselect if already selected
      } else {
        newSelection.add(fundId); // Select if not already selected
      }
      return Array.from(newSelection); // Convert back to an array
    });
  };

  return (
    <div className="p-4">
      {showFundPreview && (
        <FundsPreviewPopup
          show={showFundPreview}
          setShow={setShowFundPreview}
        />
      )}

      {/* Mobile Filter Button */}
      <div className="mobile-header flex justify-between items-center bg-white mb-4">
        <div className="relative w-full">
          <input
            type="text"
            placeholder={translate(
              "Search for fund name",
              "ابحث عن اسم الصندوق"
            )}
            value={searchValue}
            onChange={(e) => {
              const value = e.target.value;
              setSearchValue(e.target.value);
              setColumnFilters((prevFilters) => {
                const existingFilter = prevFilters.find(
                  (filter) => filter.id == "fund_name"
                );
                if (existingFilter) {
                  // Update existing filter
                  return prevFilters.map((filter) =>
                    filter.id == "fund_name" ? { ...filter, value } : filter
                  );
                } else {
                  // Add new filter
                  return [...prevFilters, { id: "fund_name", value }];
                }
              });
            }}
            className="w-full px-3 py-1 rounded-md border border-solid border-gray-300 text-sm text-gray-700 pl-10 h-[40px] placeholder:text-[#9AA6AC]"
          />
          <img
            src={searchimg}
            alt="search icon"
            className="absolute left-2 top-2"
          />
        </div>
        <button
          onClick={() => setShowFilters(true)}
          className={`${
            columnFilters.length != 0 ? "filterApplied" : ""
          } ml-2 text-sm text-blue-600 relative`}
        >
          <img src={fafilter} alt="" className="mr-2" />
          <span className="activedot "></span>
        </button>
      </div>

      {filteredData?.map((fund) => (
        <div key={fund.isin} className="border-b mb-4">
          {/* Main fund row (collapsed view) */}
          <div className="flex justify-between items-center cursor-pointer py-2 gap-2">
            <div className="flex gap-3 items-center ">
              <CustomCheckbox
                className="mb-[0] mt-[0] px-[0] py-[0]"
                isChecked={rowSelection.includes(fund.isin)}
                onClick={(e) => e.stopPropagation()}
                onChange={(e) => handleCheckboxChange(e, fund.isin)}
              />
              <div
                className="text-[#1A2024] text-sm not-italic font-normal leading-6"
                onClick={() => showFundPreviewPopup(fund)}
              >
                {fund.fund_name}
              </div>
            </div>

            <div>
              {/* <FontAwesomeIcon
                icon={expandedRow ==fund.id ? faChevronUp : faChevronDown}
                className="flex text-[#252C32] text-xs mt-[-5px] mr-4 ipad:mr-0 transform rotate-[-88deg]"
              /> */}
              <FontAwesomeIcon
                icon={faChevronDown}
                className={`flex text-[#252C32] text-xs mt-[-5px] mr-4 ipad:mr-0 transform rotate-[-88deg]`}
              />
            </div>
          </div>
        </div>
      ))}
      <div className="fixed bottom-0 left-0 w-full p-4 bg-white border-t">
        <button
          className="w-full p-3 bg-blue-500 text-white rounded-md disabled:bg-gray-400 disabled:cursor-not-allowed"
          onClick={fundClickHandler}
          disabled={rowSelection.length == 0}
        >
          {translate("Add", "إضافة")}
        </button>
      </div>
      <div>
        <BackdropModal
          className="filterfund"
          show={showFilters}
          onClose={() => setShowFilters(false)}
          title={translate("Filter Funds", "تصفية الصناديق")}
        >
          <div className="mb-4 seleectfund">
            <div className="flex-col space-x-2 mb-3">
              <label className="block mb-1 text-[#1A2024] text-sm not-italic font-semibold leading-6 uppercase">
                {translate("Sort Fund Name by", "ترتيب اسم الصندوق حسب")}
              </label>
              <FormTabs
                className="ml-0"
                tabs={[
                  { label_en: "Ascending", label_ar: "نعم", type: "asc" },
                  { label_en: "Descending", label_ar: "لا", type: "desc" },
                ]}
                onTabClick={(type) => {
                  setColumnFilters((prevFilters) => {
                    const existingFilter = prevFilters.find(
                      (filter) => filter.id == "fundNameSort"
                    );

                    if (existingFilter) {
                      // Update existing filter
                      return prevFilters.map((filter) =>
                        filter.id == "fundNameSort"
                          ? { ...filter, value: type }
                          : filter
                      );
                    } else {
                      // Add new filter
                      return [
                        ...prevFilters,
                        { id: "fundNameSort", value: type },
                      ];
                    }
                  });
                }}
                activeTab={
                  columnFilters.filter((each) => each.id == "fundNameSort")?.[0]
                    ?.value
                }
              />
            </div>
            <div className="flex-col mb-5">
              <label className="block mb-1 text-[#1A2024] text-sm not-italic font-semibold leading-6 uppercase">
                {translate("Categories", "الفئات")}
              </label>
              <div className="flex items-start content-start gap-1 self-stretch flex-wrap">
                {categoriesOptions.map((category) => (
                  <Button
                    className={`flex items-center gap-4 border bg-white p-2.5 rounded-[10px] border-solid border-[#DDE2E4] mb-1 ml-0 ${
                      columnFilters.filter((each) => each.id == "category")?.[0]
                        ?.value == category?.type
                        ? "isActive"
                        : ""
                    } `}
                    key={category?.type}
                    label={translate(category?.text_en, category?.text_ar)}
                    onSubmit={() => {
                      setColumnFilters((prevFilters) => {
                        const existingFilter = prevFilters.find(
                          (filter) => filter.id == "category"
                        );

                        if (existingFilter) {
                          // Update existing filter
                          return prevFilters.map((filter) =>
                            filter.id == "category"
                              ? { ...filter, value: category?.type }
                              : filter
                          );
                        } else {
                          // Add new filter
                          return [
                            ...prevFilters,
                            { id: "category", value: category?.type },
                          ];
                        }
                      });
                    }}
                  />
                ))}
              </div>
            </div>

            <div className="flex-col mb-5">
              <label className="block mb-1 text-[#1A2024] text-sm not-italic font-semibold leading-6 uppercase">
                {translate("Currency", "العملة")}
              </label>
              <div className="flex items-start content-start gap-1 self-stretch flex-wrap">
                {currenciesOptions.map((currency) => (
                  <Button
                    className={`flex items-center gap-4 border bg-white p-2.5 rounded-[10px] border-solid border-[#DDE2E4] mb-1 ml-0 ${
                      columnFilters.filter((each) => each.id == "currency")?.[0]
                        ?.value == currency?.type
                        ? "isActive"
                        : ""
                    }`}
                    key={currency?.type}
                    label={translate(currency?.text_en, currency?.text_ar)}
                    onSubmit={() => {
                      setColumnFilters((prevFilters) => {
                        const existingFilter = prevFilters.find(
                          (filter) => filter.id == "currency"
                        );

                        if (existingFilter) {
                          // Update existing filter
                          return prevFilters.map((filter) =>
                            filter.id == "currency"
                              ? { ...filter, value: currency?.type }
                              : filter
                          );
                        } else {
                          // Add new filter
                          return [
                            ...prevFilters,
                            { id: "currency", value: currency?.type },
                          ];
                        }
                      });
                    }}
                  />
                ))}
              </div>
            </div>

            <div className="flex-col mb-5">
              <label className="block mb-1 text-[#1A2024] text-sm not-italic font-semibold leading-6 uppercase">
                {translate("Geographic Focus", "التركيز الجغرافي")}
              </label>
              <div className="flex items-start content-start gap-1 self-stretch flex-wrap">
                {geographicFocusOptions.map((geographicFocus) => (
                  <Button
                    className={`flex items-center gap-4 border bg-white p-2.5 rounded-[10px] border-solid border-[#DDE2E4] mb-1 ml-0 ${
                      columnFilters.filter(
                        (each) => each.id == "geographicFocus"
                      )?.[0]?.value == geographicFocus?.type
                        ? "isActive"
                        : ""
                    }`}
                    key={geographicFocus?.type}
                    label={translate(
                      geographicFocus?.text_en,
                      geographicFocus?.text_ar
                    )}
                    onSubmit={() => {
                      setColumnFilters((prevFilters) => {
                        const existingFilter = prevFilters.find(
                          (filter) => filter.id == "geographicFocus"
                        );

                        if (existingFilter) {
                          // Update existing filter
                          return prevFilters.map((filter) =>
                            filter.id == "geographicFocus"
                              ? { ...filter, value: geographicFocus?.type }
                              : filter
                          );
                        } else {
                          // Add new filter
                          return [
                            ...prevFilters,
                            {
                              id: "geographicFocus",
                              value: geographicFocus?.type,
                            },
                          ];
                        }
                      });
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="flex-col mb-5">
              <label className="block mb-1 text-[#1A2024] text-sm not-italic font-semibold leading-6 uppercase">
                {translate("Asset Class", "فئة الأصول")}
              </label>
              <div className="flex items-start content-start gap-1 self-stretch flex-wrap">
                {assetClassesOptions.map((assetClass) => (
                  <Button
                    className={`flex items-center gap-4 bg-white p-2.5 rounded - [10px] border - solid border - [#DDE2E4] mb - 1 ml - 0 ${
                      columnFilters.filter(
                        (each) => each.id == "assetClass"
                      )?.[0]?.value == assetClass?.type
                        ? "isActive"
                        : ""
                    }`}
                    key={assetClass?.type}
                    label={translate(assetClass?.text_en, assetClass?.text_ar)}
                    onSubmit={() => {
                      setColumnFilters((prevFilters) => {
                        const existingFilter = prevFilters.find(
                          (filter) => filter.id == "assetClass"
                        );

                        if (existingFilter) {
                          // Update existing filter
                          return prevFilters.map((filter) =>
                            filter.id == "assetClass"
                              ? { ...filter, value: assetClass?.type }
                              : filter
                          );
                        } else {
                          // Add new filter
                          return [
                            ...prevFilters,
                            { id: "assetClass", value: assetClass?.type },
                          ];
                        }
                      });
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="flex items-start gap-2 self-stretch mb-4">
            <Button
              className="flex items-center gap-2.5 flex-[1_0_0] px-4 py-2 text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] border bg-white rounded-[10px] border-solid border-[#DDE2E4] text-center justify-center"
              onSubmit={() => {
                setShowFilters(false);
                setColumnFilters((prevFilters) => {
                  // Preserve the fund_name filter and reset all other filters
                  return prevFilters
                    .filter((filter) => filter.id == "fund_name")
                    .map((filter) => ({ ...filter, value: filter.value })); // Ensure we keep the fund_name filter
                });
              }}
              label={translate("Clear All", "مسح الكل")}
            />
            <Button
              className="flex items-center gap-2.5 flex-[1_0_0] bg-[#55C6EF] px-4 py-2 rounded-[10px] text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] justify-center"
              onSubmit={() => setShowFilters(false)}
              label={translate("Apply", "تطبيق")}
            />
          </div>
        </BackdropModal>
      </div>
    </div>
  );
};

export default SelectFundsTableMobile;
