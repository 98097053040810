import { useTranslation } from "react-i18next";
import Button from "../../../components/common/Button/Button";
import BackdropModal from "../../../components/common/modal/backdropModal";
import { useNavigate } from "react-router-dom";
import home from "../../../assets/images/home-black.svg"

const ChangePlateNumberPopup = ({ show ="true", onClose, btn_label, onSuccess, title, message1, message2 }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSuccess = () => {
    // Navigate to the home page
    navigate("/");
    // Optional: Call onSuccess if additional logic is needed
    if (onSuccess) {
      onSuccess();
    }
  };

  const handleClose = () => {
    show = "false";
  };
  
  return (
    <div className="changeplatepopup">
      <BackdropModal 
        show={show}
        onClose={onClose}
        title={title || t("motorInsurance.changePlateNumberPopup.title")}
      >
        <div className="w-[500px] max-w-[100%]">
        <div className="cpr-exists-popup">
          <p className="text-[#1A2024] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] mb-4">
          {message1 || t("motorInsurance.changePlateNumberPopup.message1")}
          </p>
          <p className="text-[#1A2024] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] mb-4">
          {message2 || t("motorInsurance.changePlateNumberPopup.message2")}
          </p>
          <button
            type="submit"
            onClick={handleSuccess}
            className="flex mb-0 stickybutton w-full justify-center items-center gap-2.5 shrink-0 p-4 bg-[#55C6EF] rounded-[10px] text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px] max767:w-full"
          >
            <img src={home} alt="" />
           {btn_label || t("motorInsurance.changePlateNumberPopup.buttonLabel")}
          </button>
        </div>
        </div>
      </BackdropModal>
    </div>
  );
};

export default ChangePlateNumberPopup;
