import React from "react";
import PerformanceCard from "../../../../components/common/card/performanceCard";
import {
  ADD_MORE_COVERS,
  ADJUST_SUM_INSURED,
  CHANGE_PLAN,
  TRANSFER_POLICY,
  REMOVE_BANK_NAME,
  CHANGE_PLATE_NUMBER,
  CANCEL_POLICY,
} from "../motorPolicyDataFile";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const ManagePolicyTab = ({ cards, policyDetails }) => {
  const [searchParams] = useSearchParams();

  const policyData = {
    PolicyNumber: policyDetails?.PolicyNumber ?? "",
    RegNo: policyDetails?.RegistrationNumber ?? "",
    status: policyDetails?.PolicyStatus ?? "",
    make: policyDetails?.Make ?? "",
    model: policyDetails?.Model ?? "",
    Plan: policyDetails?.PlanName ?? "",
    startDate: policyDetails?.FromDateStr ?? "",
    endDate: policyDetails?.ToDateStr ?? "",
    ChasissNumber: policyDetails?.ChasissNumber,
  };

  const id = policyData.PolicyNumber;
  const navigate = useNavigate();
  const encodePolicy = encodeURIComponent(id);

  const cardClickHandler = (card) => {
    if (card?.type == CHANGE_PLAN) {
      navigate(`/motor-insurance/change-plan/${encodePolicy}`);
    } else if (card.type == ADD_MORE_COVERS) {
      navigate(`/motor-insurance/add-more-covers/${encodePolicy}`);
    } else if (card.type == ADJUST_SUM_INSURED) {
      navigate(`/motor-insurance/adjust-sum-insured/${encodePolicy}`);
    } else if (card.type == REMOVE_BANK_NAME) {
      navigate(`/motor-insurance/remove-bank-name/${encodePolicy}`);
    } else if (card.type == TRANSFER_POLICY) {
      navigate(`/motor-insurance/transfer-ownership?policy=${encodePolicy}`);
    } else if (card.type == CHANGE_PLATE_NUMBER) {
      navigate(
        `/motor-insurance/change-plate-number?policy=${encodePolicy}&regNo=${policyData?.RegNo}&chassis=${policyData?.ChasissNumber}`
      );
    } else if (card.type == CANCEL_POLICY) {
      navigate(`/motor-insurance/cancel-policy/${encodePolicy}`, {
        state: { policyData },
      });
    }
  };
  return (
    <div className="max767:p-5">
      <div className="grid  grid-cols-3 items-start content-start gap-4 self-stretch flex-wrap ipad:w-full managepolicy max767:grid-cols-1 max767:gap-[8px]">
        {cards
          .filter(
            (card) =>
              !(
                card.type == "CANCEL_POLICY" &&
                policyData.status != "ACTIVE POLICY"
              )
          )
          .map((card, index) => (
            <PerformanceCard
              onClick={cardClickHandler}
              key={index}
              cardData={card}
              className="bg-[#F6F8F9] rounded-[10px] border-2 border-solid border-transparent manage-policy-box flex items-center gap-4 p-6 hover:rounded-[10px] hover:border-2 hover:border-[#00719A] hover:bg-[#F6F8F9] hover:[box-shadow:0px_0px_1px_0px_rgba(26,_32,_36,_0.32),_0px_8px_16px_0px_rgba(91,_104,_113,_0.24)] ipad:w-full max767:flex max767:w-full max767:p-2 max767:border max767:border-solid max767:border-[#DDE2E4] "
            />
          ))}
      </div>
    </div>
  );
};

export default ManagePolicyTab;
