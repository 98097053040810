/* :Page:MotorRenewal: Review & Confirm */

import React, { useState } from 'react'
import useRenewalFlow from '../../../../hooks/useRenewalFlow'
import RenewMotorLayout from '../renewMotorLayout';
import TitleDescription from '../../../../components/newMotorPolicy/titleDescription/titleDescription';
import { useTranslation } from 'react-i18next';
import Summary from '../../../../components/newMotorPolicy/veiwSummaryPopup/summary';
import PromoCode from '../../../../components/newMotorPolicy/veiwSummaryPopup/promoCode';
import useMotorForm from '../../../../hooks/useMotorFormData';
import { PROMO_CODE } from '../../../../components/newMotorPolicy/veiwSummaryPopup/viewSummaryPopup';
import { motorData } from '../../newMotorPolicy/pay/data';
import forwardarrow from "../../../../assets/images/arrow_forwardb.svg";
import { useNavigate } from 'react-router-dom';
import ReviewMotorTable from './reviewMotorTable';
import { useDispatch, useSelector } from 'react-redux';
import { getPolicyPremiumResponse, getPolicyPremiumLoading } from "../../../../redux/features/motor/renewalPremiumSlice";
import { getPolicyPayLoading, policyPay } from "../../../../redux/features/payment/policyPaySlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { Loader } from 'storybook/internal/components';
import { toast } from 'react-toastify';
import Button from '../../../../components/common/Button/Button';
import CustomCheckbox from '../../../../components/common/customCheckbox/customCheckbox';


const ReviewAndConfirmPage = () => {
  const dispatch = useDispatch();
  // const selectedPolicyRenewalInfo = useSelector(selectNewMotorForm)?.[RENEW_POLICY_SELECTED_POLICY];
  const policyPremiumResponse = useSelector(getPolicyPremiumResponse);
  console.log('policyPRemium Respons', policyPremiumResponse.QuoteDetails);

  // Get policy info
  const policyData = useSelector(getPolicyPremiumResponse);

  // Setting up policy pay
  const quoteNumber = policyData.PolicyNumber
  const totalPremium = policyData.TotalPremium;

  const policyPayLoading = useSelector(getPolicyPayLoading);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formState, setFormState] = useState({});
  const [isChecked, setIsChecked] = useState(false);

  const { saveMotorDataInRedux, removeMotorFormData } = useMotorForm(
    PROMO_CODE,
    setFormState
  );

  const applyPromoCode = () => {
    saveMotorDataInRedux(formState);
  };
  const handlePayment = async () => {
    // navigate("/motor-insurance/payment");
    if (quoteNumber && totalPremium) {
      const payload = {
        PolicyNumber: quoteNumber,
        Premium: totalPremium,
        Channel: "online.solidarity",
      };

      try {
        // Calling API policy/pay
        const linkAction = await dispatch(policyPay(payload));
        const link = unwrapResult(linkAction)
        // console.log("Link is: ", link)
        if (link) {
          window.location.href = link;
        }

      }
      catch (error) {
        // alert("Error in getting payment link: ", error)
        toast.error("Error in getting payment link: ", error);
      }
      

    }
  };

  return (
    <RenewMotorLayout
      is_bottom_sticky_layout={false}
      backClickHandler={"loggedOut"}
    >
      <div className="flex flex-col items-center mx-auto max-w-lg max767:p-4">
        <div className="flex justify-center items-center w-full">
          <div className=" p-0 w-full ">
            <TitleDescription
              title={t(
                "motorInsurance.renewalPolicyFlow.reviewAndConfirmPage.title"
              )}
              description={t(
                "motorInsurance.renewalPolicyFlow.reviewAndConfirmPage.description"
              )}
            />
            <div className="bg-[#F6F8F9] rounded-[26px] p-4 mb-4">
              <h3 className="text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px]">
                {t("motorInsurance.renewalPolicyFlow.motorPolicyrenewal.title")}
              </h3>
              <p className="text-[#1A2024] text-sm not-italic font-normal leading-5 tracking-[-0.14px] mb-3">
                {t(
                  "motorInsurance.renewalPolicyFlow.motorPolicyrenewal.description"
                )}
              </p>
              <div className="max767:overflow-x-scroll">
                <ReviewMotorTable passedFrom={"MotorRenewal"} />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[#F6F8F9] rounded-[26px] p-4 mb-4 w-full summary-box">
          {/* <PromoCode
            promoCode={formState.promoCode}
            setPromoCode={(code) =>
              setFormState((prevState) => ({ ...prevState, promoCode: code }))
            }
            applyPromoCode={applyPromoCode}
            clearPromoCode={() => {
              setFormState({});
              removeMotorFormData({});
            }}
          /> */}

          <Summary passedFrom={"MotorRenewalReviewAndConfirm"} />

          <div className="flex">
            <CustomCheckbox
              Label={
                <div className='flex'>
                  {" "}
                  <label
                    htmlFor="agreement"
                    className="text-[#252C32] text-sm not-italic font-normal leading-6"
                  >
                    {t(
                      "motorInsurance.renewalPolicyFlow.motorPolicyrenewal.declaration"
                    )}
                  </label>
                </div>
              }
              isChecked={isChecked}
              setIsChecked={setIsChecked}
            />
          </div>

          {/* Proceed Button */}
          <Button
            isLoading={policyPayLoading}
            className={`bg-[#55C6EF] mt-4 w-full text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] flex justify-center p-3 rounded-[10px]`}
            onSubmit={handlePayment}
            label={t(
              "motorInsurance.adjustSumInsuredFlow.reviewAndConfirmPage.proceed_to_payment"
            )}
            disabled={!isChecked}
            post_icon={forwardarrow}
            post_icon_class={"w-6 h-6 ml-[5px] rtl:rotate-180 rtl:mr-2"}
          />
        </div>
      </div>
    </RenewMotorLayout>
  );
}

export default ReviewAndConfirmPage