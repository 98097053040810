import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    current_header_step: null,
};

export const newLifeFormSlice = createSlice({
    name: "new_life_form_slice",
    initialState,
    reducers: {
        setLifeFormData: (state, action) => {
            let { form_name, data } = action.payload || {};
            state[form_name] = data;
        },
        clearLifeFormData: (state, action) => {
            const { form_name } = action.payload || {};
            if (form_name) {
                state[form_name] = null;
            }
        },

        // header step
        setLifeFormHeaderStep: (state, action) => {
            let stepType = action.payload;
            state.current_header_step = stepType;
        },
    }
});

// SELECTORS
export const selectNewLifeForm = (store) => store?.newLifeFormData;
export const selectNewLifeFormHeaderStep = (store) => store?.newLifeFormData?.current_header_step;

// ACTIONS 
export const { setLifeFormData, clearLifeFormData, setLifeFormHeaderStep } = newLifeFormSlice.actions;

export default newLifeFormSlice.reducer;
