import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import {  motorApiEndpoints, policyApiEndpoints } from "../../../api";
import { toast } from "react-toastify";

const initialState = {
  PromoCodeQuote: [],
  isLoading: false,
  success: null,
  error: null,
};


export const applyPromoCode = createAsyncThunk(
    "applyPromoCode",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling applyPromoCode...")
            const response = await Axios.post(`${policyApiEndpoints.applyPromo}`, payload);
            console.log("applyPromoCode is: ", response.data)

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);



export const promoCodeSlice = createSlice({
  name: "Apply Promo Slice",
  initialState,
  reducers: {
    clearApplyPromoError: (state) => {
      state.error = null;
    },
    clearApplyPromoState: () => initialState  // This resets the state to its initial value
  },
  extraReducers: (builder) => {
    // Get Quote API
    builder.addCase(applyPromoCode.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(applyPromoCode.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
      state.PromoCodeQuote = action?.payload;
    });
    builder.addCase(applyPromoCode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.ErrorMessageEn;
      state.success = false;
      console.log("Get quote error: ", action.payload.ErrorMessageEn )
      toast.error("Get quote errors: " + action.payload.ErrorMessageEn);
    });
  },
});

// SELECTORS
export const getPromoCodeResponse = (store) => store?.promoCode.QuoteDetails;
export const getPromoCodeLoading = (store) => store?.promoCode.isLoading;
export const getPromoCodeError = (store) => store?.promoCode.error;
export const getPromoCodeSuccess = (store) => store?.promoCode.success;


// ACTIONS TO DISPATCH
export const { clearApplyPromoError, clearApplyPromoState } = promoCodeSlice.actions;

export default promoCodeSlice.reducer;
