import React, { useState } from 'react';
import TitleDescription from '../../../../../components/newMotorPolicy/titleDescription/titleDescription';
import useLanguageTranslate from '../../../../../hooks/useLanguageTranslate';
import { INVESTMENT_STRATEGIES } from './data';
import StrategyCard from './strategyCard';
import AlertComponent from '../../../../motorInsurancePages/transferPolicyFlow/alertComponent';
import RenewMotorLayout from '../../../../motorInsurancePages/renewalMotorPolicy/renewMotorLayout';
import { useTranslation } from 'react-i18next';
import useLifePolicyDetails from '../../../../../hooks/life/useLifePolicyDetails';
import useCreateLifeRequests from '../../../../../hooks/life/useCreateLifeRequests';
import { decodeQueryString } from '../../../../../utils';
import { Loader } from 'storybook/internal/components';
import { useNavigate } from 'react-router-dom';
import CustomCheckbox from '../../../../../components/common/customCheckbox/customCheckbox';

const InvestmentStrategyChangeFlow = () => {
    const translate = useLanguageTranslate();
    const [selectedStrategy, setSelectedStrategy] = useState(null);
    const [isChecked, setIsChecked] = useState(false);

    const params = decodeQueryString()
    const { data, isLoading } = useLifePolicyDetails(params?.policy);
    const { isLoading: requestLoading, createLifeRequest } = useCreateLifeRequests()

    const currentStrategy = data?.LifeDetails?.policyList?.[0]?.investmentStrategy;
    const { t } = useTranslation()

    const handleStrategySelect = (strategy) => {
        setSelectedStrategy(strategy);
    };

    const navigate = useNavigate()
    const confirmClickHandler = async () => {
        if (!selectedStrategy) return;
        let payload = {
            PolicyNumber: params?.policy,
            RequestType: selectedStrategy?.id
        }
        const response = await createLifeRequest(payload)
        navigate(-1)
        // alert('confirm click handler')
    }

    const loadingState = isLoading || requestLoading


    return (
      <div className="mainCarDetailsModal pt-[80px] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:pt-2 max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll ">
        <RenewMotorLayout
          show_close_btn
          btn_disabled={loadingState || !selectedStrategy}
          is_hide_back_btn
          is_bottom_sticky_layout={false}
        >
          {loadingState && (
            <Loader className="w-10 h-10 fixed left-[50%] top-[50%]" />
          )}

          <div className="changeplatenumber w-[500px] max-w-[100%] mx-auto bg-white max767:p-5 max767:pt-0">
            <TitleDescription
              title={translate("Investment strategy", "استراتيجية الاستثمار")}
              description={translate(
                "Move your investments into risk rated, diversified and actively managed portfolio of funds for your investments.",
                "نقل استثماراتك إلى محفظة استثمارات متنوعة ومدارة بنشاط ذات تصنيف مخاطر."
              )}
            />
          </div>
          <div className="grid grid-cols-3 gap-4 mb-6 w-[800px] max-w-[100%] mx-auto bg-white max767:p-5 max767:grid-cols-1 ipad:w-[700px] Investmentstrategy">
            {INVESTMENT_STRATEGIES?.map((strategy) => (
              <StrategyCard
                key={strategy.id}
                strategyName={translate(
                  strategy.strategyName,
                  strategy.ar_strategyName
                )}
                description={translate(
                  strategy.description,
                  strategy.ar_description
                )}
                selected={selectedStrategy?.id == strategy.id}
                isCurrentStrategy={currentStrategy == strategy.type}
                onClick={() => handleStrategySelect(strategy)}
                strategy={strategy}
              />
            ))}
          </div>
          <div className="changeplatenumber w-[500px] max-w-[100%] mx-auto bg-white max767:p-5">
            <AlertComponent
              title={translate(
                "Please note, this request will be submitted for approval before it takes effects",
                "يرجى العلم أن هذا الطلب سيقدم للفحص والموافقة قبل أن يصبح ساريًا"
              )}
            />
            <div className="flex">
              <CustomCheckbox
                Label={
                  <div className="flex">
                    <label
                      htmlFor="agreement"
                      className="text-[#252C32] text-sm not-italic font-normal leading-6"
                    >
                      {t("lifeInsurance.surrenderPolicy.iAcceptTheLabel")}{" "}
                      <a
                        href="https://www.solidarity.com.bh/termsofuse"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#00384D] text-sm not-italic font-semibold leading-6 underline"
                      >
                        {t("lifeInsurance.surrenderPolicy.termsOfLinkLabel")}
                      </a>{" "}
                      {t("lifeInsurance.surrenderPolicy.andLabel")}{" "}
                      <a
                        href="https://www.solidarity.com.bh/privacyandsecurity"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#00384D] text-sm not-italic font-semibold leading-6 underline"
                      >
                        {t("lifeInsurance.surrenderPolicy.privacyLinkLabel")}
                      </a>
                    </label>
                  </div>
                }
                isChecked={isChecked}
                setIsChecked={setIsChecked}
              />
            </div>
            <button
              className={`w-full text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] flex justify-center p-3 rounded-[10px] ${
                isChecked
                  ? "bg-[#55C6EF] rounded-[10px] cursor-pointer"
                  : "bg-gray-300 text-gray-500 cursor-not-allowed"
              }`}
              disabled={!isChecked}
              onClick={confirmClickHandler}
            >
              {translate("Confirm", "يتأكد")}
            </button>
          </div>
        </RenewMotorLayout>
      </div>
    );
};

export default InvestmentStrategyChangeFlow;


