import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { selectNewLifeForm } from '../../redux/features/newLifeFormSlice';
import { selectProfileData } from '../../redux/features/profileSlice';
import { ESSENTIAL_CARE_BASIC_INFO } from '../../pages/essentialCare/details/basicInfo';
import { getEssentiaCareQuote, selectEssentialCareLoader } from '../../redux/features/essentialCare/essentialCareQuoteSlice';
import { toast } from 'react-toastify';
import useLanguageTranslate from '../useLanguageTranslate';

const useEssentialCareQuote = () => {
    const dispatch = useDispatch()
    const isLoading = useSelector(selectEssentialCareLoader)
    const translate = useLanguageTranslate()

    let dataFormatter = (data) => {
        
        let [FirstName, LastName] = data?.fullName?.split(" ")
        return {
            "Age": 25,
            "Height": "",
            "Weight": "",
            "MedicalCardNumber": "",
            "Relationship": data?.relationship||data?.Relationship,
            "DOB": `${data?.day}/${data?.month}/${data?.year}`,
            "FirstName": FirstName,
            "LastName": LastName,
            "CPR": data?.cpr,
            "Nationality": data?.nationality||"",
            "Martialstatus": data?.Martialstatus||"",
            "Gender": data?.gender || ""
        }
    }


    const profileData = useSelector(selectProfileData)
    const setBeneficiaryDetails = (self) => {
        let formatedBenif = self?.map(each => {
            return dataFormatter(each?.original_data)
        })
        return formatedBenif;

    }
    console.log(profileData,'some profile data')
    const essentialCareQuoteHandler = async (self) => {
        const payload = {
            PartyCode:profileData?.Code|| "FALCON" ,
            "ContactDetails": "",
            "Plan": "EC",
            "PlanType": "I",
            "Members":  setBeneficiaryDetails(self)
        };

        let response = await dispatch(getEssentiaCareQuote(payload));
        console.log(response,'some response')
        if(!response?.type?.includes("fulfilled")){
            toast.error(translate(response?.payload?.ErrorMessageEn||"Something went wrong Try again Later...",response?.payload?.ErrorMessageAr))
        }
        return response;
    }
    return (
        { essentialCareQuoteHandler, isLoading }
    )
}

export default useEssentialCareQuote