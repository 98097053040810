import { useState } from "react";
import TelInput from "../../../components/common/telInput/telInput";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import Input from "../../../components/common/Input/Input";
import Button from "../../../components/common/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { sendOtp } from "../../../redux/features/sendOtpSlice";
import Loader from "../../../components/common/Loader/Loader";
import useChangeMobileNumberFlow from "../../../hooks/useChangeMobileNumberFlow";
import RenewMotorLayout from "../../motorInsurancePages/renewalMotorPolicy/renewMotorLayout";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  getTokenOtpLoading,
  sendToken,
} from "../../../redux/features/account/accountOtpSlice";
import useMotorForm from "../../../hooks/useMotorFormData";
import useValidation from "../../../hooks/useValidation";
import useScreenResolution from "../../../hooks/useScreenResolution";
import { selectProfileData } from "../../../redux/features/profileSlice";
export const CHANGE_MOBILE_FLOW_UPDATED_MOBILE_NUMBER =
  "CHANGE_MOBILE_FLOW_UPDATED_MOBILE_NUMBER";
function ChangeMobileNumberPage({ onSuccess }) {
  const { handleNextSubstep, handlePrevSubstep } = useChangeMobileNumberFlow();
  const screenResolution = useScreenResolution();
  const profileData = useSelector(selectProfileData);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const isLoading = useSelector(getTokenOtpLoading);
  const { errors, validateFields } = useValidation();

  const [formData, setFormData] = useState({
    mobile: "",
  });

  const { saveMotorDataInRedux } = useMotorForm(
    CHANGE_MOBILE_FLOW_UPDATED_MOBILE_NUMBER,
    setFormData
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    if (name == "mobile") {
      newValue = value.replace(/\D/g, "");
      if (newValue.length > 8) {
        newValue = newValue.substring(0, 8);
      }
    }
    let update = { ...formData, [name]: newValue };
    setFormData(update);
    validateForm(update);
  };

  const validateForm = (data) => {
    let isValid = validateFields(
      [
        {
          name: "mobile",
          validations: ["length"],
          exact_length: 8,
        },
      ],
      data ? data : formData
    );
    return isValid;
  };
  const NextHandler = async () => {
    if (validateForm()) {
      if (profileData?.PhoneNumber != formData.mobile) {
        try {
          const tokenOtpPayload = {
            mobileNumber: formData.mobile,
          };

          // Calling API /account/sendtoken with mobile
          const tokenOtpAction = await dispatch(sendToken(tokenOtpPayload));
          const tokenOtpResult = unwrapResult(tokenOtpAction);

          // If the response is successful, process the phone number
          if (tokenOtpResult && tokenOtpResult.smsStatus == "MESSAGE_SENT") {
            // Mask the phonenumber e.g XXXXX908
            const maskedPhone = tokenOtpResult.to.replace(
              /(\d{3})(\d{5})(\d{3})/,
              "$1 XXXXX$3"
            );

            const updatedFormData = {
              ...formData,
              phone: formData.mobile,
              maskedPhone: maskedPhone,
              pinId: tokenOtpResult ? tokenOtpResult.pinId : "",
            };

            saveMotorDataInRedux(updatedFormData);
            handleNextSubstep();
          } else if (
            tokenOtpResult &&
            tokenOtpResult.smsStatus == "MESSAGE_NOT_SENT"
          ) {
            toast.error(
              "Failed to send token to mobile number " + tokenOtpResult.to
            );
          } else if (
            tokenOtpResult.requestError.serviceException.messageId ===
            "THROTTLE_EXCEPTION"
          ) {
            toast.error(tokenOtpResult.requestError.serviceException.text);
          }
        } catch (error) {
          toast.error(
            translate("Failed to send token:", "فشل في إرسال الرمز:"),
            error
          );
        }
      } else {
        toast.error(
          translate(
            "The entered number matches your current one. Please provide a different mobile number.",
            "الرقم المدخل يطابق رقمك الحالي. يرجى تقديم رقم هاتف  مختلف."
          )
        );
      }
    }
  };
  return (
    <RenewMotorLayout
      is_bottom_sticky_layout={screenResolution.mobile}
      btn_onClick={NextHandler}
      backClickHandler={() => handlePrevSubstep()}
      btn_disabled={!formData.mobile || errors.mobile}
    >
      {isLoading && <Loader className="" />}
      <div className="mx-auto bg-white max767:p-5 w-[500px] max-w-[100%] mt-20 max767:mt-0">
        <TitleDescription
          title={translate(
            "Enter the new mobile number",
            "أدخل رقم الهاتف الجديد"
          )}
          description={translate(
            "enter the new mobile number",
            "أدخل رقم الهاتف الجديد"
          )}
          hide_description={screenResolution.mobile}
        />

        <div className="input-columns w-full ">
          <TelInput
            name="mobile"
            value={formData.mobile}
            error={errors.mobile}
            onChange={handleChange}
            label={t("signUpFlow.signUpPage.mobile_input_label")}
            className="mobilenumber px-4 py-3 max767:w-[50%] w-[97%] border-r-0 border-0 rounded-[9px] h-[46px] focus:outline-none"
            data-testid="mobile"
          />
        </div>

        {!screenResolution.mobile && (
          <Button
            type="submit"
            className="primary-button"
            onSubmit={NextHandler}
            label={t("forgot_password_flow.reset_password.next")}
            disabled={!formData.mobile || errors.mobile}
          />
        )}
      </div>
    </RenewMotorLayout>
  );
}

export default ChangeMobileNumberPage;
