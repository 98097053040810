export const coverOptions = [
    {
      name: "Car Replacement",
      included: false,
      price: 10,
      noOfDays: [
        {
          label_en: "8 Days",
          label_ar: "8 عاةلى السياسة",
          type: "8 Days",
        },
        {
          label_en: "10 Days",
          label_ar: "10 شهادة المركبة",
          type: "10 Days",
        },
        {
          label_en: "15 Days",
          label_ar: "15 التغطيات والمزايا",
          type: "15 Days",
        },
        {
          label_en: "20 Days",
          label_ar: "20 إدارة سياستي",
          type: "20 Days",
        },
      ],
      carType: [
        {
          label_en: "Small Car",
          label_ar: " إدارة سياستي",
          type: "Small Car",
        },
        {
          label_en: "Medium Car",
          label_ar: "إدارة سياستي",
          type: "Medium Car",
        },
        {
          label_en: "Luxury Car",
          label_ar: " إدارة سياستي",
          type: "Luxury Car",
        },
      ],
      selectedDays: "8 Days",
      selectedCarType: "Small Car",
      defaultIncludedInPlan: false,
    },
    {
      name: "Roadside Assitance",
      name_ar:"المساعدة على الطريق",
      included: true,
      price: 10,
      defaultIncludedInPlan: true,
    },
    {
      name: "Personal Accident Benefits to Passengers",
      name_ar:"فوائد الحوادث الشخصية للركاب",
      included: false,
      price: 10,
      defaultIncludedInPlan: false,
    },
    {
      name: "Geographical Extension",
      name_ar:"الامتداد الجغرافي",
      included: false,
      price: 10,
      defaultIncludedInPlan: false,
    },
    {
      name: "Act of God",
      name_ar:"فعل الله",
      included: true,
      price: 10,
      defaultIncludedInPlan: false,
    },
    {
      name: "Windshield",
      name_ar:"الزجاج الأمامي",
      included: false,
      price: 10,
      defaultIncludedInPlan: false,
    },
    {
      name: "VIP",
      name_ar: "كبار الشخصيات",
      included: true,
      price: 10,
      defaultIncludedInPlan: false,
    },
    {
      name: "Personal Accident Benefits to Driver",
      name_ar: "فوائد الحوادث الشخصية للسائق",
      included: false,
      price: 8,
      defaultIncludedInPlan: false,
    },
    {
      name: "Strike, Riot & Civil Commotion",
      name_ar: "الإضراب والشغب والاضطرابات المدنية",
      included: false,
      price: 10,
      defaultIncludedInPlan: false,
    },
    {
      name: "Depreciation on Parts",
      name_ar: "استهلاك الأجزاء",
      included: false,
      price: 10,
      defaultIncludedInPlan: false,
    },
    {
      name: "Life Insurance",
      name_ar: "تأمين على الحياة",
      included: false,
      price: 10,
      defaultIncludedInPlan: false,
    },
    {
      name: "Working Risk",
      name_ar: "مخاطر العمل",
      included: true,
      price: 10,
      defaultIncludedInPlan: true,
    },
    {
      name: "Content Cover",
      name_ar: "محتوى الغلاف",
      included: true,
      price: 10,
      defaultIncludedInPlan: true,
    },
    {
      name: "Additional Accessories",
      name_ar: "ملحقات إضافية",
      included: true,
      price: 10,
      defaultIncludedInPlan: false,
    },
    {
      name: "Agency Repair",
      name_ar: "إصلاح الوكالة",
      included: true,
      price: 10,
      defaultIncludedInPlan: true,
    },
  ];
  
  export const tableData = [
    { id: 1, column1: "Plate Number", column2: "516714" },
    { id: 2, column1: "Plate Type", column2: "Private" },
    { id: 3, column1: "Chassis Number", column2: "XXXXXXXXXXXXXXXXX" },
    { id: 4, column1: "Car Make", column2: "Hyundai" },
    { id: 5, column1: "Car Model", column2: "Hyundai ACCENT" },
    { id: 6, column1: "Manufacturing Year", column2: "2018" },
    { id: 7, column1: "Sum Insured", column2: "BHD 4,000" },
    { id: 8, column1: "Start Date", column2: "12/05/2024" },
    { id: 9, column1: "Plan", column2: "Flexi" },
    {
      id: 10,
      column1: "Add-ons", // This row label will appear in the first column
      column2: "", // Leave this empty for the add-ons list
      addOns: ["Car Replacement", "Geographical Extension", "Act of God", "VIP"], // Array of add-ons
    },
  ];

  export const motorData = {
    plateNumber: "516714",
    plateType: "Private",
    plateType_ar:"خاص",
    chassisNumber: "XXXXXXXXXXXXXXXXX",
    carMake: "Hyundai",
    carMake_ar:"هيونداي",
    carModel: "Hyundai ACCENT",
    carModel_ar:"هيونداي اكسنت",
    manufacturingYear: "2018",
    sumInsured: "BHD 4,000",
    startDate: "12/05/2024",
    plan: "Flexi",
    plan_ar:"مرن",
    addOns: ["Car Replacement", "Geographical Extension", "Act of God", "VIP"],
    addOns_ar:["استبدال السيارة","الامتداد الجغرافي","فعل الله","كبار الشخصيات"]
  };
  