import React, { useState } from "react";
import BackdropModal from "../../common/modal/backdropModal";
import PromoCode from "./promoCode";
import Summary from "./summary";
import useMotorForm from "../../../hooks/useMotorFormData";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";

export const PROMO_CODE = "PROMO_CODE";

const ViewSummaryPopup = ({ show, setShow }) => {
  const onClose = () => setShow((state) => !state);
  const [formState, setFormState] = useState({});
  const { saveMotorDataInRedux, removeMotorFormData } = useMotorForm(
    PROMO_CODE,
    setFormState
  );

  const applyPromoCode = () => {
    saveMotorDataInRedux(formState);
  };
  const translate=useLanguageTranslate()

  return (
    <div className="summarymodal">
      <BackdropModal onClose={onClose} show={show}>
        <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px]">
         { translate("Summary","ملخص")}
        </h1>
        <div className=" pr-4 w-full mt-4 mb-0 bg-white rounded-[10px] self-start summary-content-div max767:pr-0 max767:mt-0">
          {/* <PromoCode
            promoCode={formState?.promoCode}
            setPromoCode={(code) =>
              setFormState((prevState) => ({ ...prevState, promoCode: code }))
            }
            applyPromoCode={applyPromoCode}
            clearPromoCode={() => {
              setFormState({});
              removeMotorFormData();
            }}
          /> */}
          <Summary passedFrom={"MotorNew"}/>
        </div>
      </BackdropModal>
    </div>
  );
};

export default ViewSummaryPopup;
