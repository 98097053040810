import React, { useMemo, useState } from "react";
import Button from "../../../../../components/common/Button/Button";
import BackdropModal from "../../../../../components/common/modal/backdropModal";
import editshare from "../../../../../assets/images/editsharebkgray.svg";
import SharePercentageTable from "./sharePercentageTable";
import { SHARE_PERCENTAGE_TABLE_DATA } from "./data";
import { toast } from "react-toastify";
import useLifePolicyDetails from "../../../../../hooks/life/useLifePolicyDetails";
import { decodeQueryString } from "../../../../../utils";
import useCreateLifeRequests, {
  LIFE_REQUEST_TYPE,
} from "../../../../../hooks/life/useCreateLifeRequests";
import { Loader } from "storybook/internal/components";
import useScreenResolution from "../../../../../hooks/useScreenResolution";
import backarrow from "../../../../../assets/images/back-arrow-left.svg";
import BottomStickyBtnLayout from "../../../../motorInsurancePages/newMotorPolicy/Layouts/BottomStickyBtnLayout";
import useLanguageTranslate from "../../../../../hooks/useLanguageTranslate";

const EditSharePercentagePopup = ({ show, setShow }) => {
  const [values, setValues] = useState({});
  const { mobile } = useScreenResolution();
  const translate = useLanguageTranslate();

  const onClose = () => {
    setShow((state) => !state);
    setValues({});
  };

  const percentageCalculator = () => {
    let percentage = 0;
    Object.keys(values)?.forEach((key) => {
      percentage += parseInt(values[key] || 0);
    });
    return percentage;
  };

  const total_percentage = useMemo(() => {
    return percentageCalculator();
  }, [values]);

  const decodedQueryString = decodeQueryString();
  const { data, isLoading } = useLifePolicyDetails(decodedQueryString?.policy);

  const { isLoading: requestLoading, createLifeRequest } =
    useCreateLifeRequests();

  const sharePercentageData = useMemo(() => {
    let beneficiaries = data?.LifeDetails?.policyList?.[0]?.beneficiaries;
    let format = beneficiaries?.map((each) => ({
      beneficiary_name: each?.name,
      relationship: each?.relationship,
      share_percentage: each?.percentage,
    }));
    return format || [];
  }, [data]);

  const confirmHandler = async () => {
    if (total_percentage != 100) {
      toast.error("Cumulative share percentage should be 100");
      return;
    }

    let payload = {
      RequestType: LIFE_REQUEST_TYPE.editSharePercentage,
      PolicyNumber: decodedQueryString?.policy,
      Shares: sharePercentageData?.map((each, i) => ({
        FullName: each?.beneficiary_name,
        Relationship: each?.relationship,
        SharePercentage: values?.[i] || 0,
      })),
    };
    const response = await createLifeRequest(payload);
    onClose();
  };

  const backClickHandler = () => {
    onClose();
  };

  return (
    <div className="EditSharePercentage mt-0 max767:mt-0">
      <BackdropModal
        onClose={onClose}
        show={show}
        className="TrusteesDetails"
        hideClosebtn={mobile}
      >
        {requestLoading && (
          <Loader className="w-10 h-10 fixed left-[50%] top-[50%]" />
        )}
        {mobile && (
          <p onClick={backClickHandler} className="flex gap-2">
            {" "}
            <img
              src={backarrow}
              alt="back"
              className="bg-[#F6F8F9] p-2 rounded-3xl rtl:rotate-180"
            />
          </p>
        )}
        {!mobile && (
          <p className="flex mb-5 w-[600px] max-w-[100%] max767:mt-[-13px]">
            <img
              src={editshare}
              alt="edit share"
              className="exit_to_app  mt-[-40px] "
            />
          </p>
        )}
        <div className="content mb-4  ">
          <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mb-3 max767:text-xl max767:mt-8 max767:mb-0">
            {translate("Edit Share Percentage", "تعديل نسبة الحصة")}
          </h1>
          {mobile && (
            <p className="text-[#1A2024] text-base not-italic font-normal leading-6 tracking-[-0.16px] max767:mb-8">
              {translate(
                "Please review your information and submit request.",
                "يرجى مراجعة معلوماتك وتقديم الطلب."
              )}
            </p>
          )}

          <div className="SharePercentageTable">
            <SharePercentageTable
              values={values}
              setValues={setValues}
              data={sharePercentageData}
              total={total_percentage}
            />
          </div>

          {!mobile && (
            <div className="mt-8 flex items-start gap-3 self-stretch">
              <Button
                onSubmit={onClose}
                label={translate("Cancel", "إلغاء")}
                className="flex flex-col items-center gap-2.5 flex-[1_0_0] px-4 py-2 border rounded-[10px] border-solid border-[#DDE2E4] bg-white text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
              />
              <Button
                onSubmit={confirmHandler}
                label={translate("Confirm", "تأكيد")}
                className="flex flex-col items-center gap-2.5 flex-[1_0_0] px-4 py-2 border rounded-[10px] border-solid border-[#DDE2E4] bg-[#55C6EF] text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
              />
            </div>
          )}
          {mobile && (
            <BottomStickyBtnLayout
              btn_label={translate("Confirm", "تأكيد")}
              btn_onClick={confirmHandler}
            ></BottomStickyBtnLayout>
          )}
        </div>
      </BackdropModal>
    </div>
  );
};

export default EditSharePercentagePopup;
