import React from "react";
import { mobileMenuData } from "./sidebarLinks";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectLoginTokenInfo } from "../../../redux/features/loginRegisterSlice";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";

const MobileFooter = () => {
  const loginTokenInfo = useSelector(selectLoginTokenInfo);
  const translate = useLanguageTranslate();
  const { pathname } = useLocation();
  return (
    <div className="fixed bottom_bar left-0 right-0 z-10 gap-1 bg-white backdrop-blur-[20px] list-none flex w-[393px] max-w-[100%]: justify-between items-start px-7 py-2 border-t mt-[-74px] top-[100dvh] pb-6 w-full max767:z-[9]">
      {mobileMenuData.map((link, linkIndex) => (
        <li
          key={linkIndex}
          className={`list-none flex w-12 flex-col items-center gap-1.5 shrink-0 ${
            link.showIfIsLoggedIn == true && !loginTokenInfo ? "" : ""
          } ${pathname == link.link ? "active" : "inActive"}`}
        >
          <Link
            to={link.link}
            state={{ navigatedFromLink: link.text }}
            className={`text-center relative contents flex-row items-center  ${
              link.disabled
                ? "pointer-events-none opacity-50 text-[#005574]"
                : ""
            }`}
          >
            <span className="inline-flex justify-center items-center">
              <img
                src={link.icon}
                alt={translate(link.text_en, link.text_ar)}
              />
            </span>
            <span className="text-[10px] not-italic font-semibold truncate text-[#84919A] text-center leading-[normal] tracking-[-0.06px]">
              {translate(link.text_en, link.text_ar)}
            </span>
          </Link>
        </li>
      ))}
    </div>
  );
};

export default MobileFooter;
