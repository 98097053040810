import React, { useMemo, useState } from "react";
import ExpressShieldPlanLayout from "../expressShieldPlanLayout";
import useExpressShieldFlow from "../../../../hooks/BuyOnlineExpressShield/useExpressShieldFlow";
import { useNavigate } from "react-router-dom";
import forwardarrow from "../../../../assets/images/arrow_forwardb.svg";
import Exclamation from "../../../../assets/images/Exclamation.svg";
import PromoCode from "../../../../components/newMotorPolicy/veiwSummaryPopup/promoCode";
import { useTranslation } from "react-i18next";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useSelector } from "react-redux";
import { selectNewLifeForm } from "../../../../redux/features/newLifeFormSlice";
import { EXPRESS_SHEILD_BASIC_INFO } from "../details/basicInfo";
import { EXPRESS_SHEILD_OTHER_DETAILS } from "../details/otherDetails";
import { ageCalculator } from "../../../lifeInsurancePages/PolicyOverview/manageMyPolicy/manageBeneficiaries/data";
import { selectExpressShieldData } from "../../../../redux/features/expressSheild/expressSheildSlice";
import LifeInsuranceSummary from "../../../../components/newMotorPolicy/veiwSummaryPopup/lifeInsuranceSummary";
import { selectProfileData } from "../../../../redux/features/profileSlice";
import { BUY_ONLINE_PLAN_SELECTED } from "../plan/choosePlan";
import useLifeFormData from "../../../../hooks/useLifeFormData";
import usePaymentApi from "../../../../hooks/BuyOnlineExpressShield/usePaymentApi";
import Loader from "../../../../components/common/Loader/Loader";
import { genderLabels } from "../data";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import AlertComponent from "../../../motorInsurancePages/transferPolicyFlow/alertComponent";
import CustomCheckbox from "../../../../components/common/customCheckbox/customCheckbox";
const PROMO_CODE = "PROMO_CODE";

const ReviewAndConfirm = () => {
  const { handleNextSubstep } = useExpressShieldFlow();
  const { generatePaymentLink, isLoading } = usePaymentApi()
  const translate = useLanguageTranslate();
  const onSuccess = () => {
    handleNextSubstep();
  };

  const [formState, setFormState] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();
  const { saveLifeDataInRedux, removeLifeFormData } = useLifeFormData(
    PROMO_CODE,
    setFormState
  );

  const applyPromoCode = () => {
    saveLifeDataInRedux(formState);
  };


  const basicDetails = useSelector(selectNewLifeForm)?.[EXPRESS_SHEILD_BASIC_INFO];
  const otherDetails = useSelector(selectNewLifeForm)?.[EXPRESS_SHEILD_OTHER_DETAILS];
  const selectedPlan = useSelector(selectNewLifeForm)?.[BUY_ONLINE_PLAN_SELECTED];
  const profileData = useSelector(selectProfileData);

  const expressSheildData = useSelector(selectExpressShieldData);

  const declaration = t(
    "buyOnline.expressShieldFlow.reviewAndConfirm.agreement.iDeclare",
    { returnObjects: true }
  );

  const navigate = useNavigate();
  const handlePayment = async () => {
    //navigate("/motor-insurance/payment");
    let payload = {
      PolicyNumber: expressSheildData?.QuoteDetails?.QuoteNumber || "",
      Premium: expressSheildData?.TotalPremium || 0
    }
    let response = await generatePaymentLink(payload)
    console.log(response)

    if (response?.length) {
      window.open(response[0], '_self');
    }
  };


  const participantDetails = useMemo(() => {
    let gender = genderLabels?.[otherDetails?.gender || ""]
    return [
      {
        label: t(
          "buyOnline.expressShieldFlow.reviewAndConfirm.participantDetails.name"
        ),
        value: basicDetails?.fullName || "",
      },
      {
        label: t(
          "buyOnline.expressShieldFlow.reviewAndConfirm.participantDetails.dob"
        ),
        value:
          `${basicDetails?.day}-${basicDetails?.month}-${basicDetails?.year}` ||
          "",
      },
      {
        label: t(
          "buyOnline.expressShieldFlow.reviewAndConfirm.participantDetails.age"
        ),
        value: ageCalculator(basicDetails),
      },
      {
        label: t(
          "buyOnline.expressShieldFlow.reviewAndConfirm.participantDetails.idType.label"
        ),
        value: t(
          "buyOnline.expressShieldFlow.reviewAndConfirm.participantDetails.idType.cpr"
        ),
      },
      {
        label: t(
          "buyOnline.expressShieldFlow.reviewAndConfirm.participantDetails.idNumber"
        ),
        value: profileData?.CPR || "",
      },
      {
        label: t(
          "buyOnline.expressShieldFlow.reviewAndConfirm.participantDetails.gender"
        ),
        value: translate(gender?.label_en, gender?.label_ar),
      },
    ];
  })

  const shieldSummary = useMemo(() => {
    console.log("Shield summary-----", selectedPlan, expressSheildData);
    return [
      {
        label: t(
          "buyOnline.expressShieldFlow.reviewAndConfirm.shieldSummary.deathAnyCause"
        ),
        value: selectedPlan?.death_any_cause || 0,
      },
      {
        label: t(
          "buyOnline.expressShieldFlow.reviewAndConfirm.shieldSummary.repatriationExpense"
        ),
        value: selectedPlan?.repatriation_expense || 0,
      },
      {
        label: t(
          "buyOnline.expressShieldFlow.reviewAndConfirm.shieldSummary.totalSumInsured"
        ),
        value: expressSheildData?.QuoteDetails?.SumInsured || 0,
      },
      {
        label: t(
          "buyOnline.expressShieldFlow.reviewAndConfirm.shieldSummary.grossContribution"
        ),
        value: selectedPlan?.gross_contribution || 0,
      },
    ];
  })




  return (
    <ExpressShieldPlanLayout
      is_bottom_sticky_layout={false}
      btn_onClick={onSuccess}
    >
      <div className="w-[500px] max-w-[100%] mx-auto">
        <TitleDescription
          title={t("buyOnline.expressShieldFlow.reviewAndConfirm.title")}
          description={t(
            "buyOnline.expressShieldFlow.reviewAndConfirm.description"
          )}
        />
        <div className="w-full">
          {/* Participant Details Section */}
          <div className="bg-[#F6F8F9] rounded-[26px] mb-6 p-5 max767:bg-transparent max767:p-0">
            <h2 className="text-left text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px] mb-4 rtl:text-right">
              {t(
                "buyOnline.expressShieldFlow.reviewAndConfirm.participantDetailsTitle"
              )}
            </h2>
            <table className="w-full text-left border-separate border-spacing-0 rounded-[10px] bg-[#fff] border border-solid border-[#DDE2E4]">
              <tbody>
                {participantDetails.map((detail, index) => (
                  <tr key={index} className="border-b">
                    <td className="items-center gap-2.5 flex-[1_0_0] self-stretch px-6 py-2.5 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
                      {detail.label}
                    </td>
                    <td className="text-left flex items-center gap-2.5 flex-[1_0_0] self-stretch px-6 py-2.5 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
                      {detail.value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Express Shield Basic Summary Section */}
          <div className="bg-[#F6F8F9] rounded-[26px] mb-6 p-5 max767:bg-transparent max767:p-0">
            <h2 className="text-left text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px] mb-4 rtl:text-right">
              {t("buyOnline.expressShieldFlow.reviewAndConfirm.shieldTitle")}
            </h2>
            <table className="w-full text-left border-separate border-spacing-0 rounded-[10px] bg-[#fff] border border-solid border-[#DDE2E4]">
              <tbody>
                {shieldSummary.map((item, index) => (
                  <tr key={index} className="border-b">
                    <td className="w-[50%] items-center gap-2.5 flex-[1_0_0] self-stretch px-6 py-2.5 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
                      {item.label}
                    </td>
                    <td className="text-left flex items-center gap-2.5 flex-[1_0_0] self-stretch px-6 py-2.5 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
                    {translate("BHD","دينار بحريني")} {item.value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <AlertComponent
              title={t("buyOnline.expressShieldFlow.reviewAndConfirm.planNote")}
            />
          </div>
        </div>
        <div className="bg-[#F6F8F9] rounded-[26px] p-4 mb-4 w-full summary-box max767:bg-transparent max767:p-0 max767:mb-6">
          <PromoCode
            promoCode={formState.promoCode}
            setPromoCode={(code) =>
              setFormState((prevState) => ({
                ...prevState,
                promoCode: code,
              }))
            }
            applyPromoCode={applyPromoCode}
            clearPromoCode={() => {
              setFormState({});
              removeLifeFormData({});
            }}
          />

          <LifeInsuranceSummary
            premiumText={{ 
              "en": "Total Contribution", 
              "ar": "إجمالي المساهمة" 
            }}
            totalPremium={expressSheildData?.TotalPremium || 0}
            tax={expressSheildData?.Tax}
            premium={expressSheildData?.Premium || 0}
            quotationNumber={expressSheildData?.QuoteDetails?.QuoteNumber || ""}
          />
        </div>
        <div className="w-full max767:py-0 max767:p-0">
          {/* Warning Message */}
          <div className="alertBox yellow flex items-start gap-4 self-stretch border bg-[#FDE9D2] p-4 rounded-[10px] border-solid border-[#FBC98E]">
            <img src={Exclamation} alt="danger img" />
            <span className="text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
              {t(
                "motorInsurance.newMotorInsuranceFlow.paymentSummary.disclaimer"
              )}
            </span>
          </div>

          <div className="flex">
            <CustomCheckbox
              Label={
                <div className="flex flex-col">
                  <label
                    htmlFor="agreement"
                    className="text-[#252C32] text-sm not-italic font-normal leading-6 max767:break-all"
                  >
                    {declaration?.map((paragraph, index) => (
                      <p className="mb-2" key={index}>
                        {paragraph}
                      </p>
                    ))}
                    {/* <a
                  href="#"
                  className="text-[#00384D] text-sm not-italic font-semibold leading-6 underline"
                >
                  {t(
                    "buyOnline.expressShieldFlow.reviewAndConfirm.agreement.declaration"
                  )}
                </a>{" "} */}
                  </label>
                  {/* <label
                htmlFor="agreement"
                className="text-[#252C32] text-sm not-italic font-normal leading-6 mt-3"
              >
                {t(
                  "buyOnline.expressShieldFlow.reviewAndConfirm.agreement.iCertify"
                )}
                <a
                  href="#"
                  className="text-[#00384D] text-sm not-italic font-semibold leading-6 underline"
                >
                  {t(
                    "buyOnline.expressShieldFlow.reviewAndConfirm.agreement.policySpecification"
                  )}
                </a>{" "}
                {t(
                  "buyOnline.expressShieldFlow.reviewAndConfirm.agreement.product"
                )}
              </label>
              <label
                htmlFor="agreement"
                className="text-[#252C32] text-sm not-italic font-normal leading-6 mt-3"
              >
                {t(
                  "buyOnline.expressShieldFlow.reviewAndConfirm.agreement.iAccept"
                )}{" "}
                <a
                  href="#"
                  className="text-[#00384D] text-sm not-italic font-semibold leading-6 underline"
                >
                  {t(
                    "buyOnline.expressShieldFlow.reviewAndConfirm.agreement.generalTandC"
                  )}
                </a>{" "}
              </label> */}
                </div>
              }
              isChecked={isChecked}
              setIsChecked={setIsChecked}
            />
          </div>

          <button
            className={`w-full text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] flex justify-center p-3 rounded-[10px] ${isChecked && !isLoading
                ? "bg-[#55C6EF] rounded-[10px] cursor-pointer"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
              }`}
            disabled={!isChecked || isLoading}
            onClick={handlePayment}
          >
            {t(
              "motorInsurance.newMotorInsuranceFlow.paymentSummary.proceed_payment"
            )}{" "}
            {isLoading ? (
              <Loader className="w-6 h-6 ml-[5px] rtl:mr-[5px] relative" />
            ) : (
              <img
                src={forwardarrow}
                className=" w-6 h-6 ml-[5px]  rtl:rotate-180 rtl:mr-2"
                alt="forward arrow"
              />
            )}
          </button>
        </div>{" "}
      </div>
    </ExpressShieldPlanLayout>
  );
};
export default ReviewAndConfirm;
