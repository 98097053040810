import React from "react";
import greendonecheck from "../../../../assets/images/check_circle.svg";
import redcross from "../../../../assets/images/cancel.svg";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import { fullListData } from "./viewFullListData";

const PlanListTable = () => {
  const renderValue = (value, value_ar) => {
    const currentLanguage = translate.currentLanguage; // Assuming your translate hook provides this
  
    // Determine which value to display based on the current language
    const displayValue = currentLanguage == 'ar' ? value_ar : value;
  
    if (displayValue == true) {
      return <img src={greendonecheck} className="mr-2" alt="green check" />;
    } else if (displayValue == false) {
      return <img src={redcross} className="mr-2" alt="red cross" />;
    } else {
      return displayValue;
    }
  };
  
  const translate=useLanguageTranslate()
  return (
    <div className="p-0">
      {/* Title */}
      <h2 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] text-left mb-6 -mt-10 rtl:text-right">
        {translate(
          "Plans full list of benefits & covers",
          "قائمة كاملة بالفوائد والتغطيات للخطط"
        )}
      </h2>

      {/* Table with Two Header Rows */}
      <div
        className="max-w-full overflow-x-auto overflow-y-auto "
        style={{ maxHeight: "70vh" }}
      >
        <table className="min-w-full bg-white border-separate border-spacing-0 border-solid border rounded-[10px]">
          <thead>
            {/* First Header Row */}
            <tr>
              <th className="py-2 px-4 bg-[#FFF] rounded-tl-[10px]"></th>
              <th
                colSpan={2}
                className="py-2 px-4 bg-[#FFF] text-[#252C32] text-sm not-italic font-normal leading-6 border-x-[#DDE2E4] border-r border-solid border-l rtl:border-r-0"
              >
                {translate("Third Party Plans", "خطط الطرف الثالث")}
              </th>
              <th
                colSpan={3}
                className="py-2 px-4 bg-[#FFF] text-[#252C32] text-sm not-italic font-normal leading-6"
              >
                {translate("Comprehensive Plans", "خطط شاملة")}
              </th>
            </tr>

            {/* Second Header Row */}
            <tr className=" items-start self-stretch bg-[#F6F8F9] px-4 py-6 border-b-[#DDE2E4] ">
              <th className="w-[40%] px-4 py-6 bg-[#F6F8F9] text-left text-[#252C32] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] border-b border-solid rtl:text-right">
                {translate("Benefits", "فوائد")}
              </th>
              <th className="w-[15%] px-4 py-6 items-center bg-[#F6F8F9] text-left text-[#252C32] text-lg not-italic font-semibold leading-6 tracking-[-0.18px]  border-b-[#DDE2E4] border-b border-solid">
                <div className="flex items-center">
                  <span className="bg-[#B0BABF] w-[18px] h-[18px] mr-2 rounded-[10px] rtl:ml-2"></span>
                  {translate("Third Party", "طرف ثالث")}
                </div>
              </th>
              <th className="w-[15%] px-4 py-6 items-center bg-[#F6F8F9] text-left text-[#252C32] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] border-b-[#DDE2E4] border-b border-solid">
                <div className="flex items-center">
                  <span className="bg-[#B0BABF] w-[18px] h-[18px] mr-2 rounded-[10px] rtl:ml-2"></span>
                  {translate("Third Party +", "الطرف الثالث +")}
                </div>
              </th>
              <th className="w-[15%] px-4 py-6 items-center bg-[#F6F8F9] text-left text-[#252C32] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] border-b-[#DDE2E4] border-b border-solid">
                <div className="flex items-center">
                  <span className="bg-[#B2D97E] w-[18px] h-[18px] mr-2 rounded-[10px] rtl:ml-2"></span>
                  {translate("Flexi", "ذهب")}
                </div>
              </th>
              <th className="w-[15%] px-4 py-6 items-center bg-[#F6F8F9] text-left text-[#252C32] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] border-b-[#DDE2E4] border-b border-solid">
                <div className="flex items-center">
                  <span className="bg-[#55C6EF] w-[18px] h-[18px] mr-2 rounded-[10px] rtl:ml-2"></span>
                  {translate("Platinum", "البلاتين")}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {fullListData.map((row, index) => (
              <tr key={index}>
                <td className="py-6 px-4 border-b border-gray-200 text-[#252C32)] text-lg not-italic font-semibold leading-6 tracking-[-0.18px]">
                  {translate(row.benefit.label_en, row.benefit.label_ar)}
                  <span className=" text-[#84919A] text-base not-italic font-normal leading-6 tracking-[-0.16px] flex">
                    {translate(row.benefit.sublabel, row.benefit.sublabel_ar)}
                  </span>
                </td>
                <td className="py-6 px-4 border-b border-gray-200 text-left text-[#252C32] text-base not-italic font-normal leading-6 tracking-[-0.16px] rtl:text-right">
                  {renderValue(
                    translate(row.third_party.label_en,
                    row.third_party.label_ar)
                  )}
                  <span className=" text-[#84919A] text-base not-italic font-normal leading-6 tracking-[-0.16px] flex">
                    {translate(
                      row.third_party.sublabel,
                      row.third_party.sublabel_ar
                    )}
                  </span>
                </td>
                <td className="py-6 px-4 border-b border-gray-200 text-left text-[#252C32] text-base not-italic font-normal leading-6 tracking-[-0.16px] rtl:text-right">
                  {renderValue(translate(
                    row.third_party_plus.label_en,
                    row.third_party_plus.label_ar)
                  )}
                  <span className=" text-[#84919A] text-base not-italic font-normal leading-6 tracking-[-0.16px] flex">
                    {translate(
                      row.third_party_plus.sublabel,
                      row.third_party_plus.sublabel_ar
                    )}
                  </span>
                </td>
                <td className="py-6 px-4 border-b border-gray-200 text-left text-[#252C32] text-base not-italic font-normal leading-6 tracking-[-0.16px] rtl:text-right">
                  {renderValue(translate(row.flexi.label_en, row.flexi.label_ar))}
                  <span className=" text-[#84919A] text-base not-italic font-normal leading-6 tracking-[-0.16px] flex">
                    {translate(row.flexi.sublabel, row.flexi.sublabel_ar)}
                  </span>
                </td>
                <td className="py-6 px-4 border-b border-gray-200 text-left text-[#252C32] text-base not-italic font-normal leading-6 tracking-[-0.16px] rtl:text-right">
                  {renderValue(translate(row.platinum.label_en, row.platinum.label_ar))}
                  <span className=" text-[#84919A] text-base not-italic font-normal leading-6 tracking-[-0.16px] flex">
                    {translate(row.platinum.sublabel, row.platinum.sublabel_ar)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlanListTable;
