
import  FundsSwitching  from '../../../../../assets/images/FundsSwitching.svg';
import FundsRedirecting from '../../../../../assets/images/FundsRedirecting.svg';
export const FUND_SWITCHING_TYPE = "FUND_SWITCHING_TYPE";
export const FUND_REDIRECTING_TYPE = "FUND_REDIRECTING_TYPE";
export const FUND_MANAGEMENT_CARD_DATA = [
    {
        title_en: "Funds Switching",
        title_ar: "تبديل الأموال",
        description: "Sell all or some of your policy's current fund holdings to buy other funds. As part of this process, you can also change the funds where your future premiums will be invested.",
        description_ar: "قم ببيع جميع أو بعض الحصص من صندوق استثماراتك الحالية للاستثمار في  صناديق أخرى. كجزء من هذه العملية، يمكنك أيضًا تغيير الصناديق التي سيتم استثمار اشتراكاتك المستقبلية فيها.",
        id: "1",
        type: FUND_SWITCHING_TYPE,
        icon : FundsSwitching
    },
    {
        title_en: "Funds Redirecting",
        title_ar: "إعادة توجيه الأموال",
        description: "Select the funds where your future premiums will be invested. This will be your new investment strategy. Your existing fund holdings will remain the same.",
        description_ar: "حدد الصناديق التي سيتم استثمار اشتراكاتك المستقبلية فيها. ستكون هذه هي استراتيجيتك الاستثمارية الجديدة. ستظل ممتلكات صندوقك الحالية كما هي.",
        id: "2",
        type: FUND_REDIRECTING_TYPE,
        icon : FundsRedirecting
    },

];
