


export const LEAD_INFO = {
    basicDetails: "Basic Details",
    docsInfo: "Docs Info Page",
    questionnaire: "Questionnaire/Decleration",
    addFamilyMember: "Add Family Member",
    appointBeneficiary: "Beneficiary / Trustee Information",
    reviewAndConfirm: 'Review and Confirm'
}
