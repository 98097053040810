/* :Page:NewMotor: Car Financing page */

import React, { useEffect, useMemo, useState } from "react";
import TitleDescription from "../../../../../components/newMotorPolicy/titleDescription/titleDescription";
import NewMotorJourneyLayout from "../../Layouts/NewMotorJourneyLayout";
import {
  BANK_VALIDATION,
  CAR_INFO_BRAND_NEW,
  CAR_INFO_FINANCING,
  INCREASE_VALUE,
  lastTimeClaimTabs,
  NEW_CAR_INFO_FIN_VALIDATIONS,
  U_CAR_WITH_OC_VALIDATIONS,
  U_CAR_WITHOUT_OC_VALIDA,
  yesNoTabs,
} from "./data";
import { useTranslation } from "react-i18next";
import InputWithButton from "../../../../../components/common/Input/inputWithButton";
import FormTabs from "../../../../../components/common/tabs/formTabs";
import SelectInput from "../../../../../components/common/selectInput/selectInput";
import AdjustCarValuePopup from "./adjustCarValuePopup";
import useMotorForm from "../../../../../hooks/useMotorFormData";
import useValidation from "../../../../../hooks/useValidation";
import BottomStickyBtnLayout from "../../Layouts/BottomStickyBtnLayout";
import { useSelector, useDispatch } from "react-redux";
import { selectNewMotorForm } from "../../../../../redux/features/newMotorFormSlice";
import { fetchBanksList } from "../../../../../redux/features/constants/banksListSlice";
import { getSumInsuredDetails, getSumInsuredLoader } from "../../../../../redux/features/motor/getSumInsuredSlice";
import Input from "../../../../../components/common/Input/Input";
import useLanguageTranslate from "../../../../../hooks/useLanguageTranslate";
import { toast } from "react-toastify";
import { crmApiEndPoints } from "../../../../../api";
import { selectProfileData } from "../../../../../redux/features/profileSlice";
import axios from "axios";
import { BASE_URL } from "../../../../../api/baseURL";
import { currencyFormatter } from "../../../../../utils";

export const START_PAGE_BASIC_INFO = "START_PAGE_BASIC_INFO"
const Axios = axios.create({
  baseURL: `${BASE_URL}`,
});

const CarInfoFinancing = ({ onSuccess }) => {
  // Define dispatch to use redux reducers
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { errors, validateFields, isValid } = useValidation();
  const translate = useLanguageTranslate();
  const carInfoDetailsForm = useSelector(
    (state) => state.newMotorFormData.CAR_INFO_NEW_CAR_DETAILS
  );

  const startPageCustomerInfo = useSelector(selectNewMotorForm)?.[START_PAGE_BASIC_INFO]
  const profileInfoLoggedIn = useSelector(selectProfileData);

  // Variables for the cars list fetched, from redux
  const banksList = useSelector((state) => state.banksList);
  const sumInsuredDetails = useSelector(getSumInsuredDetails);
  const sumInsuredLoading = useSelector(getSumInsuredLoader);

  // Variables to set the selector makes and model options from the cars list fetched
  const [bankOptions, setBankOptions] = useState([]);

  // Setting the variable of bankOptions to the fetched bank list. banksList as dependency means function will be called if banksList is updated
  useEffect(() => {
    if (banksList.banks) {
      const options = banksList.banks.map((bank) => ({
        value: bank,
        label: bank,
      }));
      setBankOptions(options);
    }

  }, []);

    // Setting the variable of carOptions to the fetched car list with make code as the value. carsList as dependency means function will be called if carsList is updated
    useEffect(() => {
      if (sumInsuredDetails && sumInsuredDetails.BaseSumInsured) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          amount: sumInsuredDetails.BaseSumInsured,
        }));
      }
  }, [sumInsuredDetails]);

  const [formData, setFormData] = useState({
    is_finance: null,
    last_time_file_claim: null,
    bodyType: "SEDAN",
    no_of_seats: "4 SEATER",
    engine_capacity: "1200 CC",
    amount: sumInsuredDetails?.BaseSumInsured || 0,  // Default to 0 if sumInsuredDetails is not ready
    // popup data
    tab_type: INCREASE_VALUE,
    increase_tab_type: null,
    decrease_tab_type: null,
  });
  const [selectedTab, setSelectedTab] = useState({
    amount: sumInsuredDetails?.BaseSumInsured || 0,  // Default to 0 if sumInsuredDetails is not ready
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const { saveMotorDataInRedux } = useMotorForm(
    CAR_INFO_FINANCING,
    setFormData
  );

  const brandNewData = useSelector(selectNewMotorForm)?.[CAR_INFO_BRAND_NEW];
  const hide_dropdowns = true;
    // (brandNewData?.is_have_oc =="Yes" && ) || brandNewData?.is_brand_new =="Yes";

  const isBrandNew = brandNewData?.is_brand_new =="Yes";

  const validateForm = (data) => {
    let validationConfig = NEW_CAR_INFO_FIN_VALIDATIONS;
    if (!isBrandNew && brandNewData?.is_have_oc != "Yes") {
      validationConfig = U_CAR_WITHOUT_OC_VALIDA
    }
    else if (!isBrandNew && brandNewData?.is_have_oc =="Yes") {
      // validationConfig = U_CAR_WITH_OC_VALIDATIONS
      validationConfig = U_CAR_WITH_OC_VALIDATIONS

    }
    console.log("Validate form: ", validationConfig);

    if (formData.is_finance =="Yes") {
      validationConfig = [...validationConfig, BANK_VALIDATION]
    }
    let isValid = validateFields(validationConfig, data ? data : formData)

    return isValid
  };

  /* Next */ 
  const NextHandler = async () => {
    
      // Set amount to 0 if it's undefined or falsy
    const validatedFormData = {
      ...formData,
      amount: formData?.amount ? formData.amount : 0,
    };  

    // Calling API: Create lead if sum insured > 50,000
    if (validatedFormData.amount > 50000) {
      try {
        const payload = {
          FirstName: profileInfoLoggedIn ? profileInfoLoggedIn?.FirstName : startPageCustomerInfo?.fullName,
          LastName: profileInfoLoggedIn ? profileInfoLoggedIn?.LastName : " ",
          CPR: profileInfoLoggedIn ? profileInfoLoggedIn?.CPR : " ",
          PhoneNumber: profileInfoLoggedIn ? profileInfoLoggedIn?.PhoneNumber : startPageCustomerInfo?.mobile,
          subject: 'High-value New Motor',
          Product: 'AUTO',
          Email: profileInfoLoggedIn ? profileInfoLoggedIn?.Email : startPageCustomerInfo?.email,
          customerType: 1,
          status: 1,
          productType: '',
          LOBProduct: 'Motor',
          stage: '',
          lead_id: '',
          Quote: '',
          channel: 'online.solidarity',
        };

        const response = await Axios.post(crmApiEndPoints.crmCreateLead,payload);
  
        if (response) {
          toast.success("Lead Captured");
        }
        console.log('API Response:', response.data);
      } catch (error) {
        console.error('Error calling API:', error);
      }
    }
    console.log('Is finance form valid? ', validateForm(), formData);
    
    if (validateForm()) {
      saveMotorDataInRedux(validatedFormData);
      onSuccess();
    }
  };

  const onTabClick = (value, name) => {
    let update = { ...formData, [name]: value }
    setFormData(update);
    if (errors?.[name]) {
      validateForm(update)
    }
  };
  const [showAdjustPopup, setShowAdjustPopup] = useState(false);
  const popupToggle = () => {
    setShowAdjustPopup((state) => !state);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let update = { ...formData, [name]: value }
    setFormData(update);
    if (errors?.[name]) {
      validateForm(update)
    }
  };

  const confirmClickHandler = () => {
    setFormData({ ...formData, amount: selectedTab.amount });
  };


  const { minDate, maxDate } = useMemo(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const dd = String(today.getDate()).padStart(2, '0');

    const minDate = `${yyyy}-${mm}-${dd}`;

    // Calculate one month ahead
    const nextMonth = new Date(today);
    nextMonth.setMonth(today.getMonth() + 1);
    const yyyyNext = nextMonth.getFullYear();
    const mmNext = String(nextMonth.getMonth() + 1).padStart(2, '0');
    const ddNext = String(nextMonth.getDate()).padStart(2, '0');

    const maxDate = `${yyyyNext}-${mmNext}-${ddNext}`;

    return { minDate, maxDate };
  }, []);




  return (
    <NewMotorJourneyLayout>
      <div className="AdjustCarValuePopup">
        <AdjustCarValuePopup
          show={showAdjustPopup}
          onClose={popupToggle}
          formData={formData}
          setFormData={setFormData}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          confirmClickHandler={confirmClickHandler}
        />
      </div>
      <BottomStickyBtnLayout
        // btn_disabled={isDisabled}
        btn_onClick={NextHandler}
      >
        <div className="carfinancepage max-w-lg mx-auto bg-white rounded-md  max767:p-5 max767:mt-[0px] max767:pt-0">
          <TitleDescription
            title={t(
              "motorInsurance.newMotorInsuranceFlow.carInfoFinancing.title"
            )}
            description={t(
              "motorInsurance.newMotorInsuranceFlow.carInfoFinancing.description"
            )}
          />
          <FormTabs className="mb-4"
            tabs={yesNoTabs}
            onTabClick={(type) => onTabClick(type, "is_finance")}
            activeTab={formData.is_finance}
            error={errors?.is_finance}
          />
          {formData.is_finance =="Yes" && (
            <SelectInput
              name="bank_name"
              formData={formData}
              default_placeholder={t(
                "motorInsurance.newMotorInsuranceFlow.carInfoFinancing.selectBank"
              )}
              className="banklist"
              options_data={bankOptions}
              label={
                isBrandNew
                  ? t("motorInsurance.newMotorInsuranceFlow.carInfoFinancing.bank_lender")
                  : t(
                    "motorInsurance.newMotorInsuranceFlow.carInfoFinancing.bankLabel"
                  )
              }
              error={errors?.bank_name}
              handleChange={handleChange}
            />
          )}

          {!isBrandNew && (
            <>
              <FormTabs
                tabs={lastTimeClaimTabs}
                onTabClick={(type) => onTabClick(type, "last_time_file_claim")}
                activeTab={formData.last_time_file_claim}
                label={t("motorInsurance.newMotorInsuranceFlow.carInfoFinancing.label_file_claim")}
                error={errors?.last_time_file_claim}
              />
              <InputWithButton
                label={t(
                  "motorInsurance.newMotorInsuranceFlow.carInfoFinancing.carValueLabel"
                )}
                inputValue={`${translate("BHD", "دينار بحريني")} ${currencyFormatter(selectedTab?.amount) || 0}`}
                onButtonClick={popupToggle}
                buttonText={t(
                  "motorInsurance.newMotorInsuranceFlow.carInfoFinancing.buttonText"
                )}
              />
              {/* {hide_dropdowns && (
                <div className="flex max767:gap-1 gap-6 mb-6">
                  <SelectInput
                    name="bodyType"
                    default_placeholder=""
                    formData={formData}
                    options_data={[
                      { value: "SEDAN", label: "SEDAN" },
                      { value: "BODY TYPE 2", label: "BODY TYPE 2" },
                    ]}
                    label={t(
                      "motorInsurance.newMotorInsuranceFlow.carInfoFinancing.bodyTypeLabel"
                    )}
                    error={errors?.bodyType}
                    handleChange={handleChange}
                  />
                  <SelectInput
                    name="no_of_seats"
                    default_placeholder=""
                    formData={formData}
                    options_data={[
                      { value: "4 SEATER", label: "4 SEATER" },
                      { value: "6 SEATER", label: "6 SEATER" },
                    ]}
                    label={t(
                      "motorInsurance.newMotorInsuranceFlow.carInfoFinancing.noOfSeatsLabel"
                    )}
                    error={errors?.no_of_seats}
                    handleChange={handleChange}
                  />
                  <SelectInput
                    name="engine_capacity"
                    default_placeholder=""
                    formData={formData}
                    options_data={[
                      { value: "1200 CC", label: "1200 CC" },
                      { value: "1400 CC", label: "1400 CC" },
                    ]}
                    label={t(
                      "motorInsurance.newMotorInsuranceFlow.carInfoFinancing.engineCapacityLabel"
                    )}
                    error={errors?.engine_capacity}
                    handleChange={handleChange}
                  />
                </div>
              )} */}
            </>
          )}
          {isBrandNew && (
            <>
              <div className="dateselect">
                <Input
                  label={t("motorInsurance.newMotorInsuranceFlow.carInfoFinancing.insurance_policy")}
                  type="date"
                  name="start_policy_date"
                  error={errors.start_policy_date}
                  setFormData={setFormData}
                  value={formData.start_policy_date}
                  handleChange={handleChange}
                  min = {minDate}
                  max = {maxDate}

                />
              
              </div>
              <div className="Howinput">
                <Input
                  label={t("motorInsurance.newMotorInsuranceFlow.carInfoFinancing.car_cost")}
                  type="number"
                  name="amount"
                  error={errors.amount}
                  setFormData={setFormData}
                  value={formData.amount}
                  handleChange={handleChange}
                  startAdornment={<p>{translate("BHD","دينار بحريني")}</p>}
                />
              </div>
            </>
          )}
        </div>
      </BottomStickyBtnLayout>
    </NewMotorJourneyLayout>
  );
};

export default CarInfoFinancing;
