export const stepperData = [
  {
    key: "STEP_NAME_INTIMATION",
    title_en: "Intimation",
    title_ar: "إخطار",
  },
  {
    key: "STEP_NAME_REGISTRATION",
    title_en: "Registration",
    title_ar: "تسجيل",
  },
  {
    key: "STEP_NAME_SURVEY",
    title_en: "Survey",
    title_ar: "استطلاع",
  },
  {
    key: "STEP_NAME_SETTLEMENT",
    title_en: "Settlement",
    title_ar: "تسوية",
  },
  {
    key: "STEP_NAME_CLOSE",
    title_en: "Close",
    title_ar: "إغلاق",
  },
];

export const ACTIONS = {
  UPLOAD: "Upload",
  PROCEED_TO_PAYMENT: "Proceed to payment",
  VIEW_DETAILS: "Details",
  TOTAL_LOSS: "Total Loss",
  CASH_LOSS: "Cash Loss",
  REJECT: "Reject",
  APPROVE: "Approve",
};

export const ACTION_STATUS = {
  SUBMITTED: "Documents submitted",
  YET_TO_SUBMIT: "Yet to Submit",
  PAID: "Paid",
  REJECTED: "Rejected",
  REPAIRING: "Repairing",
  COMPLETED: "Completed",
  OFFER_REJECTED: "Offer Rejected",
  OFFER_APPROVED: "Offer approved",
};

export const mockData = {
  claimID: "CRM-CLAIM-0199009", // String: Claim name or ID
  date: "2024-06-23",
  time: "11:43 am", // String: Date in "YYYY-MM-DD" format
  claimStatus: "Survey", // String: Can be "Initimation", "Registration", "Survey", "Settlement", "Close"
  claimProgress: [
    {
      date: "2024-07-22",
      time: "2:46 pm",
      pillStatus: null,
      messages: ["Update vehicle amount to be paid is BD4,400"],
      actionStatus: ACTION_STATUS.OFFER_APPROVED,
      actions: null,
    },
    {
      date: "2024-07-22",
      time: "2:46 pm",
      pillStatus: null,
      messages: [
        "Kindly note that the your vehicle Reg. No 660231 classified as Total Loss. Amount is BD4,000",
      ],
      actionStatus: ACTION_STATUS.OFFER_REJECTED,
      actions: null,
    },
    {
      date: "2024-07-22",
      time: "2:46 pm",
      pillStatus: null,
      messages: [
        "Kindly note that the your vehicle Reg. No 660231 classified as Total Loss. Amount is BD4,000",
      ],
      actionStatus: ACTION_STATUS.YET_TO_SUBMIT,
      actions: {
        action_en: ACTIONS.TOTAL_LOSS,
        action_ar: "فقدان تام",
      },
    },
    {
      date: "2024-07-23",
      time: "11:43 am",
      pillStatus: ACTION_STATUS.COMPLETED,
      messages: ["Repairs are complete, and your car is ready for pickup."],
      actionStatus: null,
      actions: null,
    },
    {
      date: "2024-07-23",
      time: "11:43 am",
      pillStatus: ACTION_STATUS.REPAIRING,
      messages: [
        "Kindly note that the repair work of your vehicle Reg. No 660231 is started.",
      ],
      actionStatus: null,
      actions: null,
    },
    {
      date: "2024-07-22",
      time: "2:46 pm",
      pillStatus: null,
      messages: [
        "Kindly note that the your vehicle Reg. No 660231 classified as Cash Loss.",
      ],
      actionStatus: null,
      actions: {
        action_en: ACTIONS.VIEW_DETAILS,
        action_ar: "تفاصيل",
      },
    },
    {
      date: "2024-07-23",
      time: "11:43 am",
      pillStatus: ACTION_STATUS.REJECTED,
      messages: ["24 Hour has passed without paying, your claim is rejected"],
      actionStatus: null,
      actions: null,
    },
    {
      date: "2024-07-23",
      time: "11:43 am",
      pillStatus: ACTION_STATUS.PAID,
      messages: ["Collection of Deductible"],
      actionStatus: "Deductible Collected",
      actions: null,
    },
    {
      date: "2024-07-23",
      time: "11:43 am",
      pillStatus: null,
      messages: ["Collection of Deductible, BD50"],
      actionStatus: "Deductible Collected",
      actions: {
        action_en: "Proceed to payment",
        action_ar: "تابع إلى الدفع",
      },
    },
    {
      date: "2024-07-22",
      time: "2:46 pm",
      pillStatus: null,
      messages: [
        "Your claim cannot be processed as the required supporting documents are missing or insufficient. Please submit the Accident Photos.",
      ],
      actionStatus: ACTION_STATUS.SUBMITTED,
      actions: null,
    },
    {
      date: "2024-07-22",
      time: "2:46 pm",
      pillStatus: null,
      messages: [
        "Your claim cannot be processed as the required supporting documents are missing or insufficient. Please submit the Accident Photos.",
      ],
      actionStatus: ACTION_STATUS.YET_TO_SUBMIT,
      actions: {
        action_en: ACTIONS.UPLOAD,
        action_ar: "تحميل",
      },
    },
    {
      date: "2024-07-21",
      time: "10:26 am",
      pillStatus: null,
      messages: [
        "We have successfully received your claim details. Your claim is under review, and we will inform you if further information is required.",
        "Case Number: CRM-CLAIM-01009.",
        "You can use this number to track your claim.",
      ],
      actionStatus: "Claim Under Review",
      actions: null,
    },
  ],
};

export const garages = [
  { name: "SHAMIYA GARAGE WLL" },
  { name: "BOSS AUTO GARAGE W.L.L" },
  { name: "KARAWAN GARAGE" },
  { name: "EIGHTY THREE GARAGE" },
  { name: "ALHAMRIYA GARAGE" },
  { name: "ALI SALEM ALNOSAIMI GARAGE" },
  { name: "AL BADER GARAGE" },
  { name: "AL MARFA GARAGE COMPANY" },
  { name: "JASCO GARAGE CO WLL" },
  { name: "ALMOHSIN GARAGE" },
  { name: "AL MAWATEEN GARAGE AND SPARE PARTS" },
  { name: "Adison World Garage" }
];
