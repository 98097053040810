import React from 'react'
import Exclamation from "../../../assets/images/Exclamation.svg";

const AlertComponent = ({ title, label }) => {
    return (
      <div className="alertBox yellow flex items-start gap-4 self-stretch border bg-[#FDE9D2] p-4 rounded-[10px] border-solid border-[#FBC98E]">
        <img src={Exclamation} alt="alert icon" />
        <p className="text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
          {title}
        </p>
        {label &&<p className="text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
          {label}
        </p>}
      </div>
    );
}

export default AlertComponent