import React, { useState } from "react";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import RenewMotorLayout from "../renewalMotorPolicy/renewMotorLayout";
import SelectInput from "../../../components/common/selectInput/selectInput";
import { useTranslation } from "react-i18next";
import useValidation from "../../../hooks/useValidation";
import redflag from "../../../assets/images/redflag.svg";
import platenumber from "../../../assets/images/123456.svg";
import BAHRAINicon from "../../../assets/images/BAHRAIN.svg";
import downlongarrow from "../../../assets/images/south.svg";
import loadingicon from "../../../assets/images/Spinner.svg";
import ChangePlateNumberPopup from "./changePlateNumberPopup";
import { navigate } from "@storybook/addon-links";
const NewPlateNumber = ({
  switchToggle,
  Policy,
  PlateNumber,
  checkGDT,
  Chassis,
  GDTPlateNo,
}) => {
  const [formData, setFormData] = useState({});
  const [plateNo, setPlateNoValue] = useState("");
  const [plateType, setPlateTypeValue] = useState("");

  const [ModelTitle, setModelTitleValue] = useState("");
  const [ModelDesc, setModelDescValue] = useState("");

  const { errors, validateFields } = useValidation();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  //var plateNo = "";
  //var plateType = "";
  const gdtPayload = {
    PlateOrChassisNo: plateNo,
    PlateType: plateType,
  };

  const validateForm = () => {
    let validationConfig = [
      {
        name: "plateType",
        validations: ["required"],
      },
    ];

    let isValid = validateFields(validationConfig, formData);
    console.log(isValid, formData, "valid");
    return isValid;
  };
  const continueClickHanlder = async () => {
    if (validateForm()) {
      //switchToggle();

      var res = await checkGDT(gdtPayload);
      if (res == "Error") {
        setIsPopupVisible(true);
      } else {
        if (
          res.VehicleDetails.ChassisNumber == Chassis &&
          PlateNumber != res.VehicleDetails.PlateNumber
        ) {
          switchToggle(plateNo, plateType);
        } else {
          setIsPopupVisible(true);

          // setModelTitleValue("Something went wrong!");
          // setModelDescValue("Something went wrong while validating your details");
          // openModal();
        }
      }
      console.log(res);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    //plateType = value
    setPlateTypeValue(e.target.value);
    console.log(e.target);
  };

  const handleChangeNumber = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    //plateNo = value
    //checkGDT(gdtPayload);
    console.log(GDTPlateNo);
    setPlateNoValue(e.target.value);
    console.log(e.target.value);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    // navigate(-1);
  };
  const inputStyle = {
    fontFamily: "FE-Font",
  };
  return (
    <RenewMotorLayout is_hide_back_btn btn_onClick={continueClickHanlder}>
      <div className="changeplatenumber w-[500px] max-w-[100%] mx-auto bg-white max767:p-5">
        {/* {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
            <h2 className="text-lg font-semibold mb-2">{ModelTitle}</h2>
            <p className="mb-4">{ModelDesc}</p>
            <button 
             onClick={closeModal}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              Close
            </button>
          </div>
        </div>
      )} */}
        {isPopupVisible && (
          <ChangePlateNumberPopup
            show={isPopupVisible}
            onClose={() => setIsPopupVisible(false)}
            btn_label={t("motorInsurance.changePlateNumberPopup.buttonLabel")}
            onSuccess={navigate("/")}
          />
        )}
        {
          <>
            <TitleDescription
              title={t("motorInsurance.changePlateNumber.newPlateNumber.title")}
              description={t(
                "motorInsurance.changePlateNumber.newPlateNumber.description"
              )}
            />
            <div className="flex flex-col items-center gap-4 self-stretch p-4 bg-[#F6F8F9] rounded-[26px]">
              <p className="text-[#1A2024] text-center text-base not-italic font-medium leading-[26px] tracking-[-0.16px]">
                {t(
                  "motorInsurance.changePlateNumber.newPlateNumber.current_plate_number_label"
                )}
              </p>
              <div className="flex w-[370.939px] justify-between items-center px-[11.429px] py-0 bg-[#EEF0F2] rounded-[5.714px] border-[2.286px] border-solid border-[#001068] max-w-[100%]">
                <img src={redflag} alt="Image 1" className="" />
                <input
                  type="text"
                  pattern="\d{6}"
                  maxlength="6"
                  title="Please enter exactly 6 digits"
                  placeholder="000000"
                  className="custominput w-24 h-10 max-w-[176px] min-w-[59%] bg-[#EEF0F2] text-blue-900 text-center text-[45.714px] not-italic font-normal leading-[normal] max767:text-[38.571px]"
                  style={inputStyle}
                  value={PlateNumber}
                  disabled
                />
                <img src={BAHRAINicon} alt="Image 3" className="" />
              </div>
            </div>
            <p className="flex flex-col items-center gap-4 self-stretch mb-4 mt-4">
              <img src={downlongarrow} alt="Image 3" className="" />
            </p>
            <div className="flex flex-col items-center gap-4 self-stretch bg-[#F6F8F9] p-4 rounded-[26px]">
              <p className="text-[#1A2024] text-center text-base not-italic font-medium leading-[26px] tracking-[-0.16px]">
                {t(
                  "motorInsurance.changePlateNumber.newPlateNumber.new_plate_number_label"
                )}
              </p>
              <div className="flex w-[370.939px] justify-between items-center px-[11.429px] py-0 bg-[#EEF0F2] rounded-[5.714px] border-[2.286px] border-solid border-[#001068] max-w-[100%]">
                <img src={redflag} alt="Image 1" className="" />
                <input
                  type="number"
                  pattern="\d{6}"
                  maxlength="6"
                  title="Please enter exactly 6 digits"
                  placeholder="000000"
                  className="custominput w-24 h-10 max-w-[176px] min-w-[59%] bg-[#EEF0F2] text-[rgba(0,19,127,0.30)] text-center text-[45.714px] not-italic font-normal leading-[normal] max767:text-[38.571px]"
                  style={inputStyle}
                  onChange={handleChangeNumber}
                />
                <img src={BAHRAINicon} alt="Image 3" />
              </div>
            </div>
            <div className="w-full mt-6">
              <SelectInput
                className="plattypestyle"
                name="plateType"
                formData={formData}
                value={formData?.plateType}
                label={t(
                  "motorInsurance.changePlateNumber.newPlateNumber.plate_type_label"
                )}
                options_data={[
                  { value: "01", label: "Private" },
                  { value: "02", label: "For Hire" },
                  { value: "03", label: "TAXI ON CALL" },
                  { value: "04", label: "DIPLOMATIC" },
                  { value: "05", label: "PUBLIC SECURITY" },
                  { value: "06", label: "PUB TRNS-PSG" },
                  { value: "07", label: "PUB D/C PICKUP" },
                  { value: "08", label: "PUBLIC TRANS" },
                  { value: "09", label: "Taxi" },
                  { value: "10", label: "MOTORCYCLE" },
                  { value: "11", label: "TOURIST BUSSES" },
                  { value: "12", label: "CONTRACTORS" },
                  { value: "13", label: "ROYAL COURT" },
                  { value: "14", label: "SPECIAL USE" },
                  { value: "15", label: "PVT TRNS-PSGR" },
                  { value: "16", label: "PVT D/C PICKUP" },
                  { value: "17", label: "PVT GOODS VEH" },
                  { value: "22", label: "SEMI TRAILER" },
                  { value: "24", label: "TRAILER" },
                  { value: "26", label: "CLASSIC" },
                ]}
                error={errors?.plateType}
                handleChange={handleChange}
              />
            </div>
            {/* <div className="loadingbox flex flex-col items-center gap-2.5 self-stretch px-4 py-6 bg-[#F6F8F9] rounded-[26px]">
              <img src={loadingicon} alt="Image 3" />
            </div> */}
          </>
        }
      </div>
    </RenewMotorLayout>
  );
};

export default NewPlateNumber;
