import React, { useRef, useState, useEffect, useMemo } from "react";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import Tabs from "../../components/common/tabs/tabs";
import FaqBox from "../../components/common/faq/faq";
import useScreenResolution from "../../hooks/useScreenResolution";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { faqData } from "./faqPageData";
import { FAQS_TYPES } from "./data";
import { useSelector } from "react-redux";
import { selectFaqsData } from "../../redux/features/faqsSlice";

const FAQPage = () => {
  const translate = useLanguageTranslate();
  const screenResolution = useScreenResolution();

  const faqScrollRef = useRef(null);

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const [activeTab, setActiveTab] = useState();

  const scroll = (direction) => {
    const scrollAmount = direction == "right" ? 300 : -300;
    faqScrollRef.current.scrollBy({
      top: 0,
      left: scrollAmount,
      behavior: "smooth",
    });
  };
  const checkScrollPosition = () => {
    const { scrollLeft, scrollWidth, clientWidth } = faqScrollRef.current;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft + clientWidth < scrollWidth);
  };

  useEffect(() => {
    const scrollableDiv = faqScrollRef.current;
    if (scrollableDiv) {
      scrollableDiv.addEventListener("scroll", checkScrollPosition);
      checkScrollPosition();
      return () => {
        scrollableDiv.removeEventListener("scroll", checkScrollPosition);
      };
    }
  }, []);

  const faqs = useSelector(selectFaqsData);
  const tabsData = useMemo(() => {
    let data = faqs?.data?.map((each) => ({
      label_en: each?.attributes?.title?.title_en,
      label_ar: each?.attributes?.title?.title_ar,
      type: each?.attributes?.type,
    }));
    let filterHilights = data?.filter(
      (each) => each?.type != FAQS_TYPES.highlights
    );
    if (filterHilights?.length) setActiveTab(filterHilights[0]?.type);
    return filterHilights || [];
  }, [faqs]);
  const tabFaqs = useMemo(() => {
    let data = faqs?.data?.find((each) => each?.attributes?.type == activeTab);

    let format = data?.attributes?.c_p_faqs?.data?.map((each) => ({
      question_en: each?.attributes?.question?.title_en,
      question_ar: each?.attributes?.question?.title_ar,
      answer_en: each?.attributes?.answer?.content_en,
      answer_ar: each?.attributes?.answer?.content_ar,
    }));
    return format || [];
  }, [activeTab]);

  const hightLightFaqs = useMemo(() => {
    let data = faqs?.data?.find(
      (each) => each?.attributes?.type == FAQS_TYPES.highlights
    );

    let format = data?.attributes?.c_p_faqs?.data?.map((each) => ({
      question_en: each?.attributes?.question?.title_en,
      question_ar: each?.attributes?.question?.title_ar,
      answer_en: each?.attributes?.answer?.content_en,
      answer_ar: each?.attributes?.answer?.content_ar,
    }));
    return format || [];
  }, [faqs]);

  return (
    <>
      <section className="ipad:p-4 mt-0 max767:flex-col max767:mt-[30%]">
        {!screenResolution.mobile ? (
          <div className="p-6">
            <div className="flex justify-between mt-0 w-full">
              <h2 className="text-2xl font-semibold mb-0 w-full flex max767:pl-4">
                {screenResolution.mobile
                  ? translate("Support & FAQ", "الأسئلة الشائعة")
                  : translate("FAQs", "الأسئلة الشائعة")}
              </h2>
              <div className="arrowbutn flex gap-2">
                {/* Left Chevron */}
                <button
                  onClick={() => scroll("left")}
                  disabled={!canScrollLeft}
                  className={`p-2 rounded-full h-[36px] w-[36px]  ${
                    canScrollLeft
                      ? "bg-[#55C6EF] text-[black]" // Active state
                      : "bg-[#E5E9EB] text-gray-400" // Disabled state
                  }`}
                >
                  {" "}
                  <span className="flex justify-center">
                    {" "}
                    <FontAwesomeIcon
                      icon={faChevronLeft}
                      size=""
                      className="text-[12px] rtl:rotate-180"
                    />
                  </span>
                </button>

                {/* Right Chevron */}
                <button
                  onClick={() => scroll("right")}
                  disabled={!canScrollRight}
                  className={`p-2 rounded-full h-[36px] w-[36px] ${
                    canScrollRight
                      ? "bg-[#55C6EF] text-[black]" // Active state
                      : "bg-[#E5E9EB] text-gray-400" // Disabled state
                  }`}
                >
                  <span className="flex justify-center">
                    {" "}
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="text-[12px] rtl:rotate-180"
                    />{" "}
                  </span>
                </button>
              </div>
            </div>
          </div>
        ) : (
          <h2 className="text-2xl font-semibold mb-6 w-full flex max767:pl-5">
            {translate("Support & FAQ", "الأسئلة الشائعة")}
          </h2>
        )}

        <div
          className="flex overflow-x-auto space-x-0 pl-5 pr-0 py-0 scroll-smooth max767:mt-[5%] max767:pl-5 rtl:pr-5 rtl:pl-0"
          ref={faqScrollRef}
          style={{
            scrollbarWidth: "none", // Hide scrollbar for Firefox
            display: "flex",
            whiteSpace: "nowrap",
          }}
        >
          {hightLightFaqs?.map((faq, index) => (
            <div
              key={index}
              className="w-[310px] p-6 bg-[#F6F8F9] rounded-[16px] flex-shrink-0 "
              style={{
                display: "inline-block",
                marginRight: "5px",
              }}
            >
              <h3
                className="text-black text-base not-italic font-medium leading-[140%] mb-2"
                style={{ textWrap: "wrap" }}
              >
                {translate(faq.question_en, faq.question_ar)}
              </h3>
              <p
                className="text-black text-sm not-italic font-normal leading-[150%]"
                style={{ textWrap: "wrap" }}
              >
                {translate(faq.answer_en, faq.answer_ar)}
              </p>
            </div>
          ))}
        </div>
      </section>
      <div className="p-6 pt-0 pb-0 max767:p-0">
        <section className="mt-8 ipad:p-4">
          <Tabs
            tabs={tabsData}
            activeTab={activeTab}
            onTabClick={setActiveTab}
          />
          <div className="faq-list mt-6 w-[700px] max-w-[100%] max767:p-5 max767:mt-1">
            {tabFaqs.map((each, index) => (
              <FaqBox
                key={index}
                question={translate(each.question_en, each.question_ar)}
                answer={translate(each.answer_en, each.answer_ar)}
              />
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default FAQPage;
