import React from "react";
import DynamicTable from "../../../../components/common/table/table";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import DownloadBtn from "../../../../components/common/downloadBtn/downloadBtn";

const VehicleCertificateTab = ({ tableData, downloadCard, carDetails }) => {
  const translate = useLanguageTranslate();
  console.log('vehicle certificate tab')
  console.log(tableData)
  return (
    <div className="flex gap-4 ipad:block max-600:block ipad:grid">
      <div className="w-3/5 ipad:w-full mb-4 max-600:w-full ipad:order-2 vheical-table">
        <DynamicTable data={tableData} />
      </div>
      {/* {downloadCard && (
        <div className="w-2/5 ipad:w-full max-600:w-full ipad:order-1 max767:hidden">
          <div className=" rounded overflow-hidden p-6 items-center bg-[#F6F8F9] rounded-[10px] vehical-download">
            <div className="flex flex-col">
              <div className="text-[#1A2024] text-sm not-italic font-medium leading-[normal] mb-3 ">
                {translate(downloadCard?.label_en, downloadCard?.label_ar)}
              </div>
            </div>
            {downloadCard?.download_btn && (
              <DownloadBtn
                filesData={downloadCard.filesData}
                className={"inline-block"}
              />
            )}
          </div>
        </div>
      )} */}
    </div>
  );
};

export default VehicleCertificateTab;
