export const SURRENDER_FORM_VALIDATIONS = [
    {
        name: "reason",
        validations: ['select']
    },
]

export  const SURRENDER_FORM_VALIDATIONS_OTHER_REASON = [ 
     ...SURRENDER_FORM_VALIDATIONS,  
    {
        name: "other_reason",
        validations: ['required']
    },
 ]