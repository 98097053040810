import React from 'react'
import NewMotorJourneyLayout from '../../Layouts/NewMotorJourneyLayout'
import BottomStickyBtnLayout from '../../Layouts/BottomStickyBtnLayout'
import MobileUploadCarPhotos from './mobileUploadCarPhotos'
import useMotorFlow from '../../../../../hooks/useMotorFlow'

const MobileUploadCarPhotoStepperLayout = () => {
      const { handleNextSubstep } = useMotorFlow();
// TODO: add  continue BTN disable when no files uploaded, once file data is stored in redux
    return (
        <div>
            <NewMotorJourneyLayout>
                <BottomStickyBtnLayout show_summary={true} btn_onClick={handleNextSubstep}>
                    <div className="mt-10 max767:mt-0">
                        <MobileUploadCarPhotos />
                    </div>
                </BottomStickyBtnLayout>
            </NewMotorJourneyLayout>
        </div>
    )
}

export default MobileUploadCarPhotoStepperLayout