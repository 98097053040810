import React from 'react'
import FundPerformanceCharts from './fundPerformanceCharts'
import PerformanceCard from '../../../../components/common/card/performanceCard'
import useLanguageTranslate from '../../../../hooks/useLanguageTranslate'
import useScreenResolution from '../../../../hooks/useScreenResolution'

const FundPerformance = ({ info,cardsData, chartsData }) => {
  const translate = useLanguageTranslate();
  const screenResolution = useScreenResolution();
  return (
    <div>
      <div className="flex flex-wrap gap-4 ipad:gap-2 max767:grid max767:grid-cols-2 max767:p-5 PerformanceCardfund paymenthistory">
        {cardsData.map((card, index) => (
          <PerformanceCard
            info={info}
            key={index}
            cardData={card}
            className="flex-col max-w-lg overflow-hidden bg-[#FBF7F0] rounded-2xl flex h-[163px] justify-between items-start flex-[1_0_0] p-6 ma  max767:flex-col fundcard max767:max-h-[135px]"
          />
        ))}
      </div>
      <div className="fundperformencecard max767:p-4">
        {!screenResolution.desktop &&<h2 className='mt-4 text-xl not-italic font-semibold'>{translate("Investment Summary", "ملخص الاستثمار")}</h2>}
        <FundPerformanceCharts charts={chartsData} info={info} />
      </div>
    </div>
  );
}

export default FundPerformance
