import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosStrapi } from "../../axiosInstance";
import { strapiApiEndPoints } from "../../../api";
import { toast } from "react-toastify";
import { MockEssentialCare } from "../../../mocks/essentialCareFlowMock";
import { mockApiCall } from "../../../utils";

const initialState = {
  questionnaire: null,
  plans: null,
  isLoading: false,
  error: null,
};

export const fetchEssentialCareCmsData = createAsyncThunk(
  "fetchEssentialCareCmsData",
  (queryString, { rejectWithValue }) => {
    return AxiosStrapi.get(`${strapiApiEndPoints.essentialCare}`).then(
      (res) => res.data
    );
    // return mockApiCall(MockEssentialCare.data.attributes);
  }
);

export const essentialCareFlowStrapiCmsSlice = createSlice({
  name: "essentialCareFlowStrapiCmsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // FETCH  fetchEssentialCareCmsData API
    builder.addCase(fetchEssentialCareCmsData.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchEssentialCareCmsData.fulfilled, (state, action) => {
      console.log('api fetching====',state, action)
      state.isLoading = false;
      state.data = action.payload?.data?.attributes;
    });
    builder.addCase(fetchEssentialCareCmsData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
      toast.error("Failed to load Data");
    });
  },
});

export const selectEssentialCareAllPagesDataCms = (store) =>
  store?.essentialCareFlowStrapiCms.data;
export const selectEssentialCareCmsLoadingState = (store) =>
  store?.essentialCareFlowStrapiCms?.isLoading;

export default essentialCareFlowStrapiCmsSlice.reducer;
