import React, { useRef, useState } from "react";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import "./Input.css";
import datePickerImage from '../../../assets/images/calender.svg'

const Input = ({
  label,
  name,
  value,
  handleChange,
  error,
  type = "text",
  placeHolder = "",
  disabled,
  setFormData,
  startAdornment,
  startAdornmentClassName,
  endAdornment,
  isTextarea = false, // New prop to determine if it's a textarea
  hint_msg = null,
  className="",
  ...rest
}) => {
  const translate = useLanguageTranslate();
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef(null)

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  const datePickerClick = () =>{
    ref?.current?.showPicker()
  }

  return (
    <div className="formField form-input min-h-[105px]">
      {label && (
        <label className="text-[#48535B] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] mb-1 block">
          {label}
        </label>
      )}
      <div className="w-full relative">
        {isTextarea ? (
          <textarea
            name={name}
            placeholder={placeHolder}
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={!!disabled}
            className={`${endAdornment && "endAdornmentInput"} ${
              startAdornment && "startAdornmentInput"
            } w-full flex justify-center items-center gap-2.5 self-stretch px-4 py-3 border rounded-[10px] border-solid border-[#B0BABF] text-black text-lg not-italic font-normal leading-[26px] tracking-[-0.18px] h-[200px] resize-none mb-6 ${
              isFocused
                ? "border-active border-2 rounded border-[#000000]"
                : "border-default border text-[#B0BABF] text-lg not-italic font-medium leading-[26px] tracking-[-0.18px] rounded-[10px]"
            } ${className}`}
            rows="4" // Set the number of rows for textarea
            {...rest}
          />
        ) : (
          <input
            ref={ref}
            type={type}
            name={name}
            value={value}
            placeholder={placeHolder}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={!!disabled}
            className={`${endAdornment && "endAdornmentInput"} ${startAdornment && "startAdornmentInput"
              } w-full h-[50px] justify-center min-h-[50px] items-center gap-2.5 self-stretch px-4 py-3 text-lg not-italic font-normal border rounded-[10px] border-solid border-[#B0BABF] ${isFocused
                ? "border-active h-[50px] text-lg"
                : "border-default border text-lg not-italic font-medium leading-[26px] rounded-[10px] h-[50px]"
              } ${className}`}
            {...rest}
          />
        )}
        {startAdornment && (
                   <span className={`startAdornment ${startAdornmentClassName} `}>{startAdornment}</span>
        )}
        {endAdornment && <span className="endAdornment">{endAdornment}</span>}
        {type=="date" && <span onClick={datePickerClick} className="endAdornment"><img src={datePickerImage} alt=""/></span>}

      </div>
      {hint_msg && <small>{hint_msg}</small>}
      {error && (
        <p className="field-err text-red-500 text-sm mt-1 text-left rtl:text-right">{error}</p>
      )}
    </div>
  );
};

export default Input;
