/* :Page:MotorRenewal: Covers page */ 
import React, { useEffect, useState } from 'react'
import useRenewalFlow from '../../../../hooks/useRenewalFlow'
import RenewMotorLayout from '../renewMotorLayout';
import TitleDescription from '../../../../components/newMotorPolicy/titleDescription/titleDescription';
import { useTranslation } from 'react-i18next';
import CoverOption from '../../newMotorPolicy/addons/CoverOption';
import useMotorForm from '../../../../hooks/useMotorFormData';
import { carReplacementPrice, coverOptions, geoExtensionPrice, setCoverOptions } from '../../newMotorPolicy/addons/data';
import { useDispatch, useSelector } from 'react-redux';
import { getPolicyPremiumResponse, policyPremium } from '../../../../redux/features/motor/renewalPremiumSlice';
import { getCoverPrices, getQuoteCoversResponse } from '../../../../redux/features/motor/getQuoteSlice';
import { coverPriceRequest, extractCoverOptions } from '../../newMotorPolicy/planPages/data';
import { selectNewMotorForm } from '../../../../redux/features/newMotorFormSlice';
import { RENEW_POLICY_SELECTED_POLICY } from '../selectPoliciesPage/selectPoliciesPage';
import { COVERAGE_ENHANCEMENT } from '../../newMotorPolicy/addons/coverageEnhancement';
import { unwrapResult } from '@reduxjs/toolkit';
import { PLAN_SELECTOR_TYPE } from '../../newMotorPolicy/planPages/planSelectorPage';
import { RENEW_POLICY_SELECTED_PLAN } from '../chooseDowngradePlanPage/chooseDowngradePlanPage';
export const RENEW_POLICY_COVERAGE_ENHANCEMENT =
  "RENEW_POLICY_COVERAGE_ENHANCEMENT";

const EnhanceYourCoveragePage = () => {

  /* Setting up dispatch to call redux */
  const dispatch = useDispatch();

  /* Setting up variables with appropriate requests to send and response to receive, reading from redux/functions */
  const policyPremiumResponse = useSelector(getPolicyPremiumResponse);

  const coverPricesPayload = coverPriceRequest(policyPremiumResponse.QuoteDetails.PreviousPolicyNumber, policyPremiumResponse.PlanName, "", "");
  const coverPricesResponse = useSelector(getQuoteCoversResponse);
  const selectedPolicyRenewalInfo = useSelector(selectNewMotorForm)?.[RENEW_POLICY_SELECTED_POLICY];
  const finalCovers = useSelector(selectNewMotorForm)?.[RENEW_POLICY_COVERAGE_ENHANCEMENT];
  const carPlan = useSelector(selectNewMotorForm)?.[RENEW_POLICY_SELECTED_PLAN];

  const actualPlan = selectedPolicyRenewalInfo.PlanName ==carPlan ? carPlan : selectedPolicyRenewalInfo.PlanName

  const currentSelectedCarDays = finalCovers ? finalCovers?.coverOptionsState?.find(car => car.code =="CMOT28")?.selectedDays : "8";
  const currentSelectedCarSize = finalCovers? finalCovers?.coverOptionsState?.find(car => car.code =="CMOT28")?.selectedCarType : "SMALL";
  const currenetSelectedGeoOption = finalCovers? finalCovers?.coverOptionsState?.find(car => car.code =="CMOT24")?.selectedGeoOption : "KSA";

  const selectedPlanFromRedux = useSelector(selectNewMotorForm)?.[RENEW_POLICY_SELECTED_PLAN]; 

  /* Functions and Variables that are updated with functions/state */
  const [coverOptions, setCoverOptionsState] = useState(setCoverOptions(coverPricesResponse, actualPlan)); 

  const { handleNextSubstep } = useRenewalFlow();
  const { t } = useTranslation();
  const [formState, setFormState] = useState({
    coverOptionsState: finalCovers ==null ? coverOptions : finalCovers
  });

  const { saveMotorDataInRedux } = useMotorForm(
    RENEW_POLICY_COVERAGE_ENHANCEMENT,
    setFormState
  );


  // Temp price to add the cover price to the total in the bottom layout
  const [tempPrice, setTempPrice] = useState(0);
  const [tempIncluded, setTempIncluded] = useState("");
  const [tempCarReplacement, setTempCarReplacement] = useState("");

  const updateOptionValues = async (name, updates, price) => {
    
    // setTempCarReplacement(false);
    console.log("updating cover.", name, updates);
    // If Platinum plan, setting default based on selected. Setting minimum for SMALL30 and MEDIUM15
    if (policyPremiumResponse.PlanName =="OPL") {
      if (updates.selectedCarType =="SMALL") {
        updates.selectedDays = "30";
        updates.noOfDays = [
          {
            label_en: "30 Days",
            label_ar: "30 إدارة سياستي",
            type: "30",
          },
        ] 
      } else if (updates.selectedCarType =="MEDIUM") {
        updates.selectedDays = "15";
        updates.noOfDays = [
          {
            label_en: "15 Days",
            label_ar: "15 التغطيات والمزايا",
            type: "15",
          },
          {
            label_en: "30 Days",
            label_ar: "30 إدارة سياستي",
            type: "30",
          },
        ] 
      }
      else if (updates.selectedCarType =="LUXURY"){
        updates.selectedDays = "8";
        updates.noOfDays = [
          {
            label_en: "8 Days",
            label_ar: "8 عاةلى السياسة",
            type: "8",
          },
          {
            label_en: "10 Days",
            label_ar: "10 شهادة المركبة",
            type: "10",
          },
          {
            label_en: "15 Days",
            label_ar: "15 التغطيات والمزايا",
            type: "15",
          },
          {
            label_en: "30 Days",
            label_ar: "30 إدارة سياستي",
            type: "30",
          },
        ]
      }
    }

    // Setting car replacement options prices in card view 
    if (name =="CMOT28") {     
      // Setting if cover is included by default for OFL. To display is inculded if the default included option is selected. And removing the default is included and adding price if not.
        if (policyPremiumResponse.PlanName =="OFL" && (updates.selectedDays =="8" && currentSelectedCarSize =="SMALL") || (currentSelectedCarDays =="8" && updates.selectedCarType =="SMALL")) {
          updates.defaultIncludedInPlan = true;
        }
        else if (policyPremiumResponse.PlanName =="OPL" && (updates.selectedDays =="8" && currentSelectedCarSize =="SMALL") || (currentSelectedCarDays =="8" && updates.selectedCarType =="SMALL")) {
          updates.defaultIncludedInPlan = true;
        }    
        else {
          updates.defaultIncludedInPlan = false;
        }
        console.log('Price is: ', price);
        // Await the carReplacementPrice function.
        price = await carReplacementPrice(
            updates.selectedDays || currentSelectedCarDays,
            updates.selectedCarType || currentSelectedCarSize
        );
        console.log('Price after awaiting: ', price);
        
        updates.price = price; // Set the updated price.
        setTempCarReplacement(true);
        // updates.included = "CMOT28"; // Uncomment if needed
    }

    // Setting geo options options prices in card view 
    if (name =="CMOT24") {
      if (policyPremiumResponse.PlanName =="OFL" && (updates.selectedGeoOption =="KSA")) {
          updates.defaultIncludedInPlan = true;
      }
      else if (policyPremiumResponse.PlanName =="GO" && (updates.selectedGeoOption =="KSA")) {
        updates.defaultIncludedInPlan = true;
      }
      else {
        updates.defaultIncludedInPlan = false;
      }
      // if (updates.selectedGeoOption =="GCC" || currenetSelectedGeoOption =="GCC") {
        const tempCoverPriceRequest = {
          PolicyNumber: policyPremiumResponse.PolicyNumber,
          GeoCoverKSA: updates.selectedGeoOption =="GCC" ? false : true
        }
        const coverPriceAction = await dispatch(getCoverPrices(tempCoverPriceRequest));
        const coverPriceResult = unwrapResult(coverPriceAction);
        
        price = await geoExtensionPrice(updates.selectedOption =="GCC", coverPriceResult);
        updates.price = price; // Set the updated price.
      // }
    }

  
    let update = {
      ...formState,

      coverOptionsState: formState.coverOptionsState.map((option) =>
        option.code ==name ? { ...option, ...updates } : option
      ),
    };

    // Set form state, which is basically the updated covers and their properties
    setFormState(update);
    // Saves the updated covers in redux COVERAGE_ENHANCEMENT to use globally
    await saveMotorDataInRedux(update);

  };

  /* Use Effects, monitor changes to call on [var] change or one time call */
  useEffect( () => {
    console.log("policyPremiumResponse details: ", policyPremiumResponse)
    console.log("policyPremiumResponse no. is: ", policyPremiumResponse.PolicyNumber)
    console.log("policyPremiumResponse plan is: ", policyPremiumResponse.PlanName)

    // Calling API /Meej/CoverPrices
    if (finalCovers ==null) {
      dispatch(getCoverPrices(coverPricesPayload))
    }

    // Get the quote resposne from the redux state
  }, []) 

  // useEffect( () => {
  //   const updatedCoverOptions = setCoverOptions(coverPricesResponse, policyPremiumResponse.PlanName);
  //   setCoverOptionsState(updatedCoverOptions, policyPremiumResponse.PlanName);

  //   // Update formState with new coverOptions
  //   setFormState((prevState) => ({
  //     ...prevState,
  //     coverOptionsState: updatedCoverOptions,
  //   }));
  // }, [coverPricesResponse])





  /* Next page */
  const continueClickHanlder = async () => {
debugger
    console.log("Calling last get premium, with covers: ", finalCovers);
    const extractFinalCovers = extractCoverOptions(finalCovers);

    
    const policyPremiumFinalPayload =  {
      PolicyNumber: selectedPolicyRenewalInfo.OriginalPolicyNo,
      Channel: "online.solidarity",
      Covers: extractFinalCovers,
      DefaultCovers: false,
      NewPlan: selectedPlanFromRedux.toUpperCase() =="TPPLUS" ? "TPPLUS" : ""
    }
    console.log("Payload to be sent with covers: ", policyPremiumFinalPayload)

    // Calling API /policy/premium
    const policyFinalPremiumAction = await dispatch(policyPremium(policyPremiumFinalPayload));
    const policyFinalPremiumResult = unwrapResult(policyFinalPremiumAction);

    console.log("Premium result is: ", policyFinalPremiumResult)
    console.log("Premium new quote is: ", policyFinalPremiumResult.PolicyNumber)
    console.log("Premium total is: ", policyFinalPremiumResult.TotalPremium)

    if (policyFinalPremiumResult) {
      handleNextSubstep();
    }

  }
  return (
    <RenewMotorLayout show_summary btn_onClick={continueClickHanlder} tempPrice={tempPrice} tempIncluded={tempIncluded} tempCarReplacement={tempCarReplacement}>
      <div className="coverageEnhancement flex flex-col items-center p-6 mx-auto ">
        <TitleDescription
          title={t(
            "motorInsurance.renewalPolicyFlow.enhanceYourCoverage.title"
          )}
          description={t(
            "motorInsurance.renewalPolicyFlow.enhanceYourCoverage.description"
          )}
        />
        <div className=" w-[33%] max-350:w-[100%] max767:w-[100%] ipad:w-[55%]">
        {Array.isArray(formState.coverOptionsState) && formState.coverOptionsState.map((option) => (
            <CoverOption
              key={option.code}
              option={option}
              updateOptionValues={updateOptionValues}
            />
          ))}
        </div>
      </div>
    </RenewMotorLayout>
  );
}

export default EnhanceYourCoveragePage