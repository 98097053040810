import React, { useState } from "react";
import "./basicInfo.css";
import Input from "../../../../../components/common/Input/Input";
import TelInput from "../../../../../components/common/telInput/telInput";
import SelectInput from "../../../../../components/common/selectInput/selectInput";
import { daysOptions, monthsOptions, yearsOptions } from "../../../../../utils/calenderOptions";
import useValidation from "../../../../../hooks/useValidation";
import { useTranslation } from "react-i18next";
import BottomStickyBtnLayout from "../../Layouts/BottomStickyBtnLayout";
import NewMotorJourneyLayout from "../../Layouts/NewMotorJourneyLayout";
import { START_PAGE_BASIC_INFO } from "../../carDetails/carInfo/data";
import useMotorForm from "../../../../../hooks/useMotorFormData";
import { validationConfig } from "./data";

const BasicInfo = ({ continueClickHandler }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({});
  const { errors, validateField, validateFields } = useValidation();

  const { saveMotorDataInRedux } = useMotorForm(
    START_PAGE_BASIC_INFO,
    setValues
  );


  const validateForm = (data) => {
    let isValid = validateFields(validationConfig, data ? data : values);
    return isValid;
  };


  const formSubmitHandler = (e) => {
    e.preventDefault();
    if (
      validateForm()
    ) {
      saveMotorDataInRedux(values)
      continueClickHandler();
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    let update = { ...values, [name]: value };
    setValues(update);
    if (errors?.[name]) {
      validateForm(update);
    }
  };
  return (
    <div className="basicInfoPage w-full">
      <NewMotorJourneyLayout show_close_btn={true} is_hide_back_btn={true}>
        <div className="content min-w-[32%] max767:w-full">
          <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] text-center max767:text-left">
            {" "}
            {t("motorInsurance.newMotorInsuranceFlow.basicInfo.title")}
          </h1>
          <p className="text-[#1A2024] text-base not-italic font-normal leading-6 tracking-[-0.16px] text-center mt-1 mb-6 max767:text-left">
            {" "}
            {t("motorInsurance.newMotorInsuranceFlow.basicInfo.subtitle")}
          </p>
        </div>
        <form onSubmit={formSubmitHandler} className="basicInfoForm w-full">
          <BottomStickyBtnLayout show_summary={false}>
            <div className="w-[32%] max767:w-full mx-auto ipad:w-[60%]">
              <Input
                label={t(
                  "motorInsurance.newMotorInsuranceFlow.basicInfo.fullName"
                )}
                type="text"
                name="fullName"
                value={values.fullName}
                error={errors.fullName}
                handleChange={handleChange}
              />
              <Input
                label={t(
                  "motorInsurance.newMotorInsuranceFlow.basicInfo.emailAddress"
                )}
                type="email"
                name="email"
                value={values.email}
                error={errors.email}
                handleChange={handleChange}
              />
              <TelInput
                name="mobile"
                value={values.mobile}
                error={errors.mobile}
                onChange={handleChange}
                label={t(
                  "motorInsurance.newMotorInsuranceFlow.basicInfo.mobileNumber"
                )}
                className="mobilenumber px-4 py-3 max767:w-[50%] w-[97%] border-r-0 border-0 rounded-[9px] h-[46px] focus:outline-none"
              />
              <div className="date_of_birth">
                <label className="block mb-[5px] text-gray-700 not-italic font-normal leading-6 text-base max-350:text-[13px]">
                  {t(
                    "motorInsurance.newMotorInsuranceFlow.basicInfo.dateOfBirth"
                  )}
                </label>
                <div className="flex gap-6 self-stretch">
                  <SelectInput
                    name="day"
                    options_data={daysOptions}
                    formData={values}
                    default_placeholder={t(
                      "motorInsurance.newMotorInsuranceFlow.basicInfo.day"
                    )}
                    error={errors?.day}
                    handleChange={handleChange}
                  />

                  <SelectInput
                    name="month"
                    formData={values}
                    default_placeholder={t(
                      "motorInsurance.newMotorInsuranceFlow.basicInfo.month"
                    )}
                    options_data={monthsOptions}
                    error={errors?.month}
                    handleChange={handleChange}
                  />

                  <SelectInput
                    name="year"
                    formData={values}
                    default_placeholder={t(
                      "motorInsurance.newMotorInsuranceFlow.basicInfo.year"
                    )}
                    options_data={yearsOptions}
                    error={errors?.year}
                    handleChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </BottomStickyBtnLayout>
        </form>
      </NewMotorJourneyLayout>
    </div>
  );
};

export default BasicInfo;
