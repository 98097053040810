import React, { useEffect, useState } from 'react'
import NewMotorJourneyLayout from '../../Layouts/NewMotorJourneyLayout'
import {  car_info_from_os_table_data } from './data'
import TitleDescription from '../../../../../components/newMotorPolicy/titleDescription/titleDescription'
import CarInfoTable from './components/carInfoTable'
import { useTranslation } from 'react-i18next'
import BottomStickyBtnLayout from '../../Layouts/BottomStickyBtnLayout'
import useMotorFlow from '../../../../../hooks/useMotorFlow'
import { useDispatch, useSelector } from 'react-redux'
import { getScanOwnershipResponse } from '../../../../../redux/features/ai/scannerSlice'
import { fetchSumInsuredDetails, getSumInsuredDetails, getSumInsuredLoader } from '../../../../../redux/features/motor/getSumInsuredSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';

const CarInfoConfirm = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { handleNextSubstep } = useMotorFlow()

  const carFromOwnership = useSelector(getScanOwnershipResponse);
  const sumInsuredLoading = useSelector(getSumInsuredLoader);  
  const sumInsuredDetails = useSelector(getSumInsuredDetails);

  const NextHandler = async (e) => {
    //
    // Calling API /Meej/calculateSumInsured
    if (sumInsuredDetails.length <= 1) {
      let sumInsuredPayload = {
        CarMakeCode: carFromOwnership.MakeCode,
        CarModelCode: carFromOwnership.ModelCode,
        CarModelYear: carFromOwnership.ModelYear,
        CarEngineSize: carFromOwnership.EngineCap,
      };
      // Calling API calculate sum insured
      try {
        const sumInsuredAction = await dispatch(
          fetchSumInsuredDetails(sumInsuredPayload)
        );
        const sumInsuredResult = unwrapResult(sumInsuredAction);

        if (sumInsuredResult) {
          handleNextSubstep();
        }
      } catch (error) {
        // alert("Error in calculating sum insured: ", error);
        toast.error("Error in calculating sum insured: ", error);
        
      }
    }
  };

  return (
    <>
      <NewMotorJourneyLayout >
        <BottomStickyBtnLayout btn_onClick={NextHandler}>
          <div className="flex flex-col items-center gap-6 max767:p-6 max767:pt-0">
            <div className="basicInfoPage min-w-[32%] max767:w-full">
              <TitleDescription
                title={t(
                  "motorInsurance.newMotorInsuranceFlow.carInfoConfirm.title"
                )}
                description={t(
                  "motorInsurance.newMotorInsuranceFlow.carInfoConfirm.description"
                )}
              />
              <div className="carinfotable flex flex-col items-start gap-2.5 self-stretch bg-[#F6F8F9] p-4 rounded-2xl mb-5 ">
                <div className=" w-full max-390:w-[100%] max-390:overflow-x-scroll">
                  <CarInfoTable table_data={car_info_from_os_table_data(carFromOwnership)} />
                </div>
              </div>
            </div>
          </div>
        </BottomStickyBtnLayout>
      </NewMotorJourneyLayout>
    </>
  );
}

export default CarInfoConfirm