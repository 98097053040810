import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentStepIndex,
  selectCurrentSubstepIndex,
  selectAddMoreCoversFlowType,
  setCurrentStepIndex,
  setCurrentSubstepIndex,
  setAddMoreCoversFlowType,
} from "../redux/features/motorInsuranceAddMoreCoversFlowSlice";
import {
  ADD_MORE_COVERS_FLOW,
  ADD_MORE_COVERS_FLOW_ENHANCE_YOUR_COVERAGE,
  ADD_MORE_COVERS_FLOW_REVIEW_AND_CONFIRM,
} from "../pages/motorInsurancePages/addMOreCoversFlow/data";

const useAddMoreCoversFlow = () => {
  const dispatch = useDispatch();
  const flowType = useSelector(selectAddMoreCoversFlowType);
  const currentStepIndex = useSelector((state) =>
    selectCurrentStepIndex(state)
  );
  const currentSubstepIndex = useSelector((state) =>
    selectCurrentSubstepIndex(state)
  );

  console.log(flowType, currentStepIndex, currentSubstepIndex,"sssssssssssssssss");
  const steps = motor_insurance_add_more_covers_flow[flowType] || [];
  const currentStep = steps[currentStepIndex] || {};
  const currentSubstep = currentStep.substeps
    ? currentStep.substeps[currentSubstepIndex]
    : "";

  const handleFlowChange = (type, currentStepIndex, currentSubstepIndex) => {
    dispatch(
      setAddMoreCoversFlowType({
        type,
        currentStepIndex,
        currentSubstepIndex,
      })
    );
  };

  const handleNextSubstep = () => {
    if (
      currentStep.substeps &&
      currentSubstepIndex < currentStep.substeps.length - 1
    ) {
      dispatch(setCurrentSubstepIndex(currentSubstepIndex + 1));
    } else if (currentSubstepIndex == currentStep.substeps.length - 1) {
      handleNextStep();
    }
  };

  const handlePrevSubstep = () => {
    if (currentSubstepIndex > 0) {
      dispatch(setCurrentSubstepIndex(currentSubstepIndex - 1));
    } else {
      handlePrevStep();
    }
  };

  const handleNextStep = () => {
    if (currentStepIndex < steps.length - 1) {
      dispatch(setCurrentStepIndex(currentStepIndex + 1));
    }
  };

  const handlePrevStep = () => {
    if (currentStepIndex > 0) {
      const currentStep = steps[currentStepIndex - 1] || {};
      const substepIndex = currentStep?.substeps?.length - 1;
      dispatch(setCurrentStepIndex(currentStepIndex - 1));
      dispatch(setCurrentSubstepIndex(substepIndex));
    }
  };

  return {
    handleFlowChange,
    handleNextSubstep,
    handlePrevSubstep,
    handleNextStep,
    handlePrevStep,
    flowType,
    steps,
    currentStep,
    currentStepIndex,
    currentSubstep,
    currentSubstepIndex,
  };
};
export default useAddMoreCoversFlow;

export const motor_insurance_add_more_covers_flow = {
  [ADD_MORE_COVERS_FLOW]: [
    {
      name: ADD_MORE_COVERS_FLOW_ENHANCE_YOUR_COVERAGE,
      substeps: [ADD_MORE_COVERS_FLOW_ENHANCE_YOUR_COVERAGE],
    },
    {
      name: ADD_MORE_COVERS_FLOW_REVIEW_AND_CONFIRM,
      substeps: [ADD_MORE_COVERS_FLOW_REVIEW_AND_CONFIRM],
    },
  ],
};
