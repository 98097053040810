export const policyOverviewPaths = [
  "/change-plan",
  "/adjust-sum-insured",
  "/add-more-covers",
  "/remove-bank-name",
  "/transfer-ownership",
  "/change-plate-number",
  "/cancel-policy",
];
export const lifeInsuranceOverviewPaths = [
  "/life-insurance/withdraw",
  "/life-insurance/surrender-policy",
  "/life-insurance/investement-strategy-change",
  "/life-insurance/fund-management",
  "/life-insurance/funds-redirecting-flow",
  "/life-insurance/funds-switching-flow",
];
export const motorInsurancePaths = [
  "/motor-insurance/upload-documents",
  "motor-insurance/change-garage",
  "motor-insurance/payment-details/",
  "motor-insurance/success",
  "/motor-insurance/claim-details/claim-info",
  "/motor-insurance/reason-for-rejection",
];

export const emailOrMobileChangePaths = ["/change-mobile-number", "/change-email"];

export const navigateToHomePaths = [
  "/confirm-transfer-details",
  "/renew-motor-policy",
];

export const claimPaths = ["/track-claim", "/file-new-claim"];