import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectNewMotorForm } from "../redux/features/newMotorFormSlice";
import { PLAN_SELECTOR_TYPE } from "../pages/motorInsurancePages/newMotorPolicy/planPages/planSelectorPage";
import { COVERAGE_ENHANCEMENT } from "../pages/motorInsurancePages/newMotorPolicy/addons/coverageEnhancement";
import { PROMO_CODE } from "../components/newMotorPolicy/veiwSummaryPopup/viewSummaryPopup";
import { useLocation } from "react-router-dom";
import { RENEW_POLICY_COVERAGE_ENHANCEMENT } from "../pages/motorInsurancePages/renewalMotorPolicy/enhanceYourCoveragePage/enhanceYourCoveragePage";
import { RENEW_POLICY_SELECTED_PLAN } from "../pages/motorInsurancePages/renewalMotorPolicy/chooseDowngradePlanPage/chooseDowngradePlanPage";
import { CHANGE_PLAN_SELECT_PLAN } from "../pages/motorInsurancePages/changePlanMotorPolicy/selectNewPlan/selectNewPlan";
import { ADD_MORE_COVERS_POLICY_COVERAGE_ENHANCEMENT } from "../pages/motorInsurancePages/addMOreCoversFlow/enhanceYourCoveragePage/enhanceYourCoveragePage";

const useSummary = () => {
  const { pathname } = useLocation();
  const isRenewalFlow = pathname?.includes("/renew-motor-policy");
  const isChangePlan = pathname?.includes("/change-plan");
  const isAddMoreCovers = pathname?.includes("/add-more-covers");
  const isAdjustSumInsured = pathname?.includes("/adjust-sum-insured");
  // new motor policy data
  let planSelectedData = useSelector(selectNewMotorForm)?.[PLAN_SELECTOR_TYPE];
  let converageEnhanceData =
    useSelector(selectNewMotorForm)?.[COVERAGE_ENHANCEMENT];
  let promoCodeData = useSelector(selectNewMotorForm)?.[PROMO_CODE];

  // renewal data
  const renewal_planSelectedData =
    useSelector(selectNewMotorForm)?.[RENEW_POLICY_SELECTED_PLAN];
  const renewal_converageEnhanceData =
    useSelector(selectNewMotorForm)?.[RENEW_POLICY_COVERAGE_ENHANCEMENT];
  //promoCodeData = useSelector(selectNewMotorForm)?.[RENEWAL_PROMO_CODE]

  const changePlan_planSelectedData =
    useSelector(selectNewMotorForm)?.[CHANGE_PLAN_SELECT_PLAN];

  const addMoreCovers_converageEnhanceData =
    useSelector(selectNewMotorForm)?.[
      ADD_MORE_COVERS_POLICY_COVERAGE_ENHANCEMENT
    ];

  if (isRenewalFlow) {
    planSelectedData = renewal_planSelectedData;
    converageEnhanceData = renewal_converageEnhanceData;
  } else if (isChangePlan) {
    planSelectedData = changePlan_planSelectedData;
  } else if (isAddMoreCovers) {
    converageEnhanceData = addMoreCovers_converageEnhanceData;
  } else if (isAdjustSumInsured) {
  }

  const total_amount = useMemo(() => {
    let amount = 0;
    amount = planSelectedData?.amount || 0;
    if (converageEnhanceData?.coverOptionsState) {
      amount = converageEnhanceData?.coverOptionsState?.reduce(
        (acc, option) => {
          return option.included && !option?.defaultIncludedInPlan
            ? acc + option.price
            : acc;
        },
        amount
      );
    }
    if (amount && typeof amount =="number") {
      amount = amount?.toFixed(3);
    }
    return amount;
  }, [planSelectedData?.amount, converageEnhanceData]);

  const discount_coupon = useMemo(() => {
    let discount = discount_coupons.find(
      (each) => each.code ==promoCodeData?.promoCode
    );
    return discount;
  }, [promoCodeData]);

  const vat_additional_amount = useMemo(() => {
    let vat = total_amount * 0.1;
    return vat?.toFixed(3);
  }, [total_amount]);

  const discount_amount = useMemo(() => {
    if (!discount_coupon) return 0;
    let amount = (total_amount * discount_coupon?.discount_percent) / 100;
    return amount || 0;
  }, [discount_coupon]);

  return {
    total_amount: total_amount - (discount_coupon?.amount || 0),
    premium: planSelectedData?.amount,
    coverage_enhance_data: converageEnhanceData,
    discount: discount_coupon?.code || 0,
    discount_percent: discount_coupon?.discount_percent || 0,
    vat_additional_amount: vat_additional_amount,
    promocode: promoCodeData?.promoCode,
    discount_amount: discount_amount,
  };
};

export default useSummary;

let discount_coupons = [
  {
    discount_percent: 10,
    code: "DISCOUNT20",
  },
  {
    discount_percent: 10,
    code: "DISCOUNT30",
  },
  {
    discount_percent: 10,
    code: "DISCOUNT40",
  },
  {
    discount_percent: 10,
    code: "DISCOUNT50",
  },
];
