import React, { lazy, Suspense, useEffect, useRef } from "react";
import ServiceGrid from "../../components/common/serviceCards/serviceCards";
import faPlusCircleimg from "../../../src/assets/images/newpolicyicon.svg";
import faSyncAltimg from "../../../src/assets/images/Renewal.svg";
import faFileAltimg from "../../../src/assets/images/FileaClaim.svg";
import EmergencyServicesimg from "../../../src/assets/images/EmergencyServices.svg";
import { Link, useNavigate } from "react-router-dom";
import righticon from "../../assets/images/Chevronright.svg";
import Button from "../../components/common/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOffers,
  selectOffersData,
} from "../../redux/features/offersSlice";

import { useTranslation } from "react-i18next";
import Loader from "../../components/common/Loader/Loader";
import MyPolicies from "../dashboard/myPolicies";
import ExpiredPolicies from "../dashboard/expiredPolicies";
import useScreenResolution from "../../hooks/useScreenResolution";
import closeicon from "../../assets/images/close.svg";




const ExpiredPoliciesPage = () => {
  const dispatch = useDispatch();
  const {mobile} = useScreenResolution();
  const navigate = useNavigate()

  const { i18n, t } = useTranslation();

  useEffect(() => {
    dispatch(fetchOffers());
  }, []);


  return (
    <Suspense fallback={<Loader />}>
      <div>
        <div className="min-h-scree">
          <div className="myPoliciesContainer p-6">
            {!mobile && <div className="newPolicy flex align-middle justify-between max767:pl-4 max767:pr-4">
              <div className="breadcrump flex align-middle gap-10">
                <ul className="inline-flex gap-2">
                  <li className="inline-flex">
                    {" "}
                    <Link
                      to="/"
                      className="text-xs not-italic font-normal leading-4 text-[#6E7C87]"
                    >
                      {t("commonLabels.home_label")}
                    </Link>{" "}
                    <span className="ml-2 rtl:ml-0 mr-2">
                      <img src={righticon} alt="right icon" className="rtl:rotate-180" />
                    </span>
                  </li>
                  <li  className="bread-active text-xs not-italic font-normal leading-4 inline-flex text-black">
                  <Link
                      to="/my-policy"
                      className="text-xs not-italic font-normal leading-4 text-black"
                    >
                      {t("commonLabels.my_policies_label")}
                    </Link>{" "}
                    <span className="ml-2 rtl:ml-0 rtl:mr-2">
                      <img src={righticon} alt="right icon" className="rtl:rotate-180"/>
                    </span>
                  </li>
                  <li className="bread-active text-xs not-italic font-normal leading-4 inline-flex text-black">
                    {" "}
                    {t("commonLabels.expired_policies_label")}
                  </li>
                </ul>
              </div>
              <p className="hidden last-login text-xs not-italic font-normal leading-4 text-gray-700">
                {t("commonLabels.last_login_label")}: 03 November 2024 at 05:20
              </p>
            </div>}
            {mobile && <button
                    className="bpy-2 rounded mt-4 flex absolute top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:ml-0"
                    onClick={() => navigate("/my-policy")}
                >
                    <img src={closeicon} alt="Close Icon" />
                </button>}

            <div className="flex justify-between alpbaseign-center mt-7 ipad:mb-2 max767:p-0">
              <h1 className="text-black text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mb-6">{t("commonLabels.expired_policies_label")}</h1>
            </div>
            <div className="policiesGrid flex w-full items-start content-start gap-4 flex-wrap ipad:grid-cols-2 ipad:grid  max767:p-0">
              <ExpiredPolicies />
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default ExpiredPoliciesPage;
