import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/common/Header/Header";
import Modal from "../../../components/common/modal/modal";
import useExpressShieldFlow from "../../../hooks/BuyOnlineExpressShield/useExpressShieldFlow";
import { DETAILS, PLAN, PAYMENT, headerStepperData, FLOW_TYPE_EXPRESS_SHIELD } from "./data";
import Details from "./details/details";
import Plan from "./plan/plan";
import ReviewAndConfirm from "./payment/reviewAndConfirm";
import { useDispatch } from "react-redux";
import { fetchCmsExpressOnlinePlans, fetchCmsQuestionniare } from "../../../redux/features/buyOnlinePlans/expressShieldFlowStrapiCmsSlice";

const ExpressShieldPlan = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);
  const { currentStep,handleFlowChange } = useExpressShieldFlow();

  const PageRenderer = () => {
    switch (currentStep?.name) {
      case DETAILS:
        return <Details />;
      case PLAN:
        return <Plan />;
      case PAYMENT:
        return <ReviewAndConfirm />;
      default:
        return null;
    }
  };

  const activeStepData = useMemo(() => {
    let activeStep = headerStepperData.find(
      (each) => each.key ==currentStep.name
    );
    return activeStep;
  }, [currentStep]);

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchCmsExpressOnlinePlans());
    dispatch(fetchCmsQuestionniare())
    handleFlowChange(FLOW_TYPE_EXPRESS_SHIELD,0)
  }, [])

  return (
    <Modal show={showModal}>
      <Header
        isPopup
        onCloseModal={() => {
          setShowModal(false);
          navigate("/new-policy");
        }}
        showStepper={true}
        steps={headerStepperData}
        activeStep={activeStepData}
      />
      <div className="expressShieldPlan pt-[5%] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll max767:p-5 max767:z-[99999] max767:relative max767:pt-[0%] max767:bg-white" >
        <PageRenderer />
      </div>
    </Modal>
  );
};

export default ExpressShieldPlan;
