import React from "react";
import BackdropModal from "../../components/common/modal/backdropModal";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";

const ViewEssentialCareBenefitsDetailsPopup = ({
  viewPlanList,
  closePopup,
}) => {
  const translate = useLanguageTranslate();
  // Mocked Data based on the image
  const benefitsData = [
    {
      description:`Limited to the Services available at Al Hilal Hospitals & Medical Centre`,
      description_ar:"تقتصر الفائدة على الخدمات المقدمة في مستشفيات ومراكز الهلال الطبية",
      benefits: "OUT-PATIENT",
      benefits_ar: "العيادات الخارجية",
    },
    {
      description: "Annual Limit per person",
      description_ar: `الحد السنوي للشخص الواحد`,
      benefits: "BHD 2,000",
      benefits_ar: `2000 دينار بحريني`,
    },
    {
      description: "Deductible",
      description_ar: `قيمة الاقتطاع`,
      benefits:
        "BHD 3 on Consultation & 20% coinsurance on all other services including, but not limited to, Investigations, medications, physiotherapy, Injections, IV Fluids & all minor surgeries",
      benefits_ar:`3 دينار بحريني للاستشارة وتأمين جزئي بما يعادل %20 على 
كل الخدمات اخرى بما في ذلك على سبيل المثال لا الحصر 
الفحوصات وادوية والعلاج الطبيعي والحقن والمحاليل 
الوريدية وكل العمليات الجراحية البسيطة`,
    },
    {
      description:
        "Consultation: General Physician Specialist (if required by GP recommendation), incl. General Medicine, Paediatrics, Dermatology, ENT, Orthopaedics, Radiology, Ophthalmology",
      description_ar:
       `الاستشارة: طبيب عام
اخصائي: إذا اقتضى امر عند احالة (الطب الباطني، وطب
اطفال، وأمراض النساء والولادة، وانف واذن والحنجرة، وطب
العيون، وجراحة العظام، واشعة، وامراض الجلدية، وعلم
امراض)`,
      benefits: "Covered",
      benefits_ar: "شامل",
    },
    {
      description:
        "Basic Standard Lab Investigations, X-Rays, Basic Ultrasound Scanning and Other Basic Investigations (As Prescribed by the treating Doctor)",
      description_ar:`الفحوصات المختبرية اساسية الروتينية، واشعة السينية،
والتصوير بالموجات فوق الصوتية والفحوصات اساسية
اخرى (وفقR لرأي الطبيب المعالج)`,
      benefits: "Covered",
      benefits_ar: "شامل",
    },
    {
      description:
        "Medicines (generic), Injections and IV Fluids (As Prescribed by the treating Doctor)",
      description_ar:`الأدوية (العامة)، والحقن والمحاليل الوريدية (وفقاً لرأي الطبيب المعالج)`,
      benefits: "Covered",
      benefits_ar: "شامل",
    },
    {
      description: "Physiotherapy up to 6 sessions per person per year",
      description_ar: `العلاج الطبيعي بحد أقصى يصل إلى 6 جلسات للشخص الواحد في السنة`,
      benefits: "Covered",
      benefits_ar: "شامل",
    },
    {
      description:
        "Pre-existing & Chronic Conditions: subject to 3 months waiting period",
      description_ar:
        `امراض السابقة والمزمنة – فترة انتظار مدتها 3 أشهر`,
      benefits: "Covered up to BHD 300",
      benefits_ar: `حتى 300 دينار بحريني`,
    },
    {
      description:
        "Outpatient: Minor surgeries: I&D, Drainage of Abscess, Nail Removal, POP/Cast Application, Foreign Body Removal",
      description_ar:
        `العيادات الخارجية: العمليات الجراحية البسيطة وتشمل:
تصريف الخراج، وإزالة الظفر، واستخدام جبيرة جبسية، وإزالة
الجسم الغريب`,
      benefits: "Covered",
      benefits_ar: "مشمول",
    },
  ];

  const benefitsDataSecondLevel = [
    {
      description:
        "Limited to the Services mentioned below and as per the Physician's request",
      description_ar: `تقتصر المنفعة على الخدمات المذكورة أدناه ووفقاً لأمر الطبيب`,
      benefits:
        "MATERNITY - Outpatient Only for married females spouses 16-45 years",
      benefits_ar:
        `رعاية الأمومة - العيادات الخارجية مخصصة فقط للأمهات المتزوجات التي تتراوح أعمارهم بين 16 - 45 عاماً`,
    },
    {
      description: "Check-up as per Standards ANC Care:",
      description_ar: "الفحص الدوري وفقا لمعايير رعاية ما قبل الولادة:",
      benefits: "Covered",
      benefits_ar: `شامل`,
      listItems: [
        {
          text: "10 times Gynecologist Consultations",
          text_ar: ` 10 استشارات لطبيب أمراض النساء والولادة`,
        },
        {
          text: "3 times Basic Ultrasound Scanning",
          text_ar: `3 مرات تصوير بالموجات فوق الصوتية اساسية`,
        },
        {
          text: "Required Basic Standard Lab Tests",
          text_ar: `الفحوصات المختبرية اساسية المطلوبة`,
        },
        {
          text: "Iron and Calcium Tablets",
          text_ar: `أقراص الحديد والكالسيوم`,
        },
        {
          text: "One Post Delivery Consultation",
          text_ar: `ستشارة واحدة بعد الولادة`,
        },
      ],
    },
    {
      description: "Deductible",
      description_ar: "قيمة الاقتطاع",
      benefits:
        "BHD 3 on Consultation & 20% coinsurance on all other services (Ultrasound, Lab Tests, Iron and Calcium Tablets & any other related treatments)",
      benefits_ar:
        `٣ دينار بحريني للاستشارة وتأمين جزئي بنسبة ٢٠٪ على جميع الخدمات الأخرى (الموجات فوق الصوتية، الفحوصات المخبرية، أقراص الحديد والكالسيوم، وأي علاجات أخرى ذات صلة).`   },
    {
      infoMsg: "This benefit is subject to 3 months waiting period",
      infoMsg_ar: `قد تستغرق هذه الخدمة فترة انتظار مدتها 3 أشهر`,
    },
  ];

  const benefitsDataThirdLevel = [
    {
      description:
        "Limited to Al Hilal Hospitals Only and Limited to the Services Provided by the Network Provider",
      description_ar:`تقتصر المنفعة على الخدمات المقدمة في مستشفيات
الهلال فقط والخدمات المقدمة من مزود الخدمات`,
      benefits: "Dental",
      benefits_ar: "الأسنان",
    },
    {
      description: "Consultation, Medicines, X-ray, Fillings and Extractions",
      description_ar: `الاستشارة وادوية واشعة السينية والحشو والخلع`,
      benefits: "Covered",
      benefits_ar: `شامل`,
    },
    {
      description: "Deductible",
      description_ar: `قيمة الاقتطاع`,
      benefits:
        "BHD 3 on Consultation & 20% coinsurance on all other services (Medicines, X-ray, Fillings and Extractions)",
        benefits_ar:`٣ دينار بحريني للاستشارات وتأمين جزئي بما يعادل ٢٠٪ على كل الخدمات الأخرى (الأدوية والأشعة السينية والحشو والخلع).`   },
    {
      infoMsg: "This benefit is subject to 3 months waiting period",
      infoMsg_ar: `قد تستغرق هذه الخدمة فترة انتظار مدتها 3 أشهر`,
    },
  ];

  return (
    <BackdropModal show={viewPlanList} onClose={closePopup}>
      <div className="">
        <h2 className="text-xl font-bold mb-4 text-left rtl:text-right mt-[-45px]">
          {translate(
            "Essential Care benefits & covers",
            "مزايا ورعاية الرعاية الأساسية"
          )}
        </h2>
        <div className=" overflow-y-scroll h-[74vh] pb-2">
          <table className="min-w-full table-auto border-separate border-spacing-0 border border-[#DDE2E4] rounded-[10px]">
            <thead>
              <tr className="bg-[#F6F8F9]">
                <th className="w-[40%]  p-2 text-left text-lg not-italic font-semibold px-4 py-6 rounded-tl-[10px] rtl:rounded-tr-[10px] rtl:text-right">
                  {translate("Description", "الوصف")}
                </th>
                <th className="w-[60%] p-2 text-left text-lg not-italic font-semibold px-4 py-6 rounded-tr-[10px] rtl:rounded-tl-[10px] rtl:text-right">
                  {translate("Benefits & Covers", "المزايا والتغطيات")}
                </th>
              </tr>
            </thead>
            <tbody>
              {benefitsData?.map((item, index) => (
                <tr key={index} className="hover:bg-[#F6F8F9]">
                  <td className=" px-4 py-6 border-t border-solid border-[#DDE2E4] text-lg not-italic font-semibold">
                    {translate(item?.description, item?.description_ar)}
                    {item?.listItems && (
                      <ul className="mt-2 list-disc pl-6">
                        {item?.listItems?.map((listItem, idx) => (
                          <li key={idx}>
                            {translate(listItem?.text, listItem?.text_ar)}
                          </li>
                        ))}
                      </ul>
                    )}
                  </td>
                  <td className=" px-4 py-6 border-t border-solid border-[#DDE2E4]">
                    {translate(item?.benefits, item?.benefits_ar)}
                  </td>
                </tr>
              ))}

              {benefitsDataSecondLevel?.map((item, index) => (
                <>
                  {item?.description && (
                    <tr key={index} className="hover:bg-[#F6F8F9]">
                      <td className=" px-4 py-6 border-t border-solid border-[#DDE2E4] text-lg not-italic font-semibold">
                        {translate(item?.description, item?.description_ar)}
                      </td>
                      <td className=" px-4 py-6 border-t border-solid border-[#DDE2E4]">
                        {translate(item?.benefits, item?.benefits_ar)}
                      </td>
                    </tr>
                  )}
                  {item?.infoMsg && (
                    <tr>
                      <td
                        colSpan="2"
                        className="px-4 py-6 border-t border-solid border-[#DDE2E4] text-[#005574] text-lg not-italic font-normal leading-6 tracking-[-0.18px]"
                      >
                        {translate(item?.infoMsg, item?.infoMsg_ar)}
                      </td>
                    </tr>
                  )}
                </>
              ))}

              {benefitsDataThirdLevel?.map((item, index) => (
                <>
                  {item?.description && (
                    <tr key={index} className="hover:bg-[#F6F8F9]">
                      <td className=" px-4 py-6 border-t border-solid border-[#DDE2E4] text-lg not-italic font-semibold">
                        {translate(item?.description, item?.description_ar)}
                      </td>
                      <td className=" px-4 py-6 border-t border-solid border-[#DDE2E4]">
                        {translate(item?.benefits, item?.benefits_ar)}
                      </td>
                    </tr>
                  )}
                  {item?.infoMsg && (
                    <tr>
                      <td
                        colSpan="2"
                        className="px-4 py-6 border-t border-solid border-[#DDE2E4] text-[#005574] text-lg not-italic font-normal leading-6 tracking-[-0.18px]"
                      >
                        {translate(item?.infoMsg, item?.infoMsg_ar)}
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </BackdropModal>
  );
};

export default ViewEssentialCareBenefitsDetailsPopup;
