import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import { policyApiEndpoints } from "../../../api";

const initialState = {
  paymentLink: "",
  isLoading: false,
  success: null,
  error: null,
};


export const policyPay = createAsyncThunk(
    "policyPay",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling policyPay...")
            const response = await Axios.post(`${policyApiEndpoints.pay}`, payload);
            console.log("policyPay response is: ", response.data)

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const policyPayTransferFees = createAsyncThunk(
  "policyPayTransferFees",
  async (payload, { rejectWithValue }) => {
      try {
          console.log("Calling policyPayTransferFees...")
          const response = await Axios.post(`${policyApiEndpoints.payTransferFees}`, payload);
          console.log("policyPayTransferFees response is: ", response.data)

          return response.data;
      } catch (err) {
          return rejectWithValue(err.response.data);
      }
  }
);

export const policyPaySlice = createSlice({
  name: "policyPaySlice",
  initialState,
  reducers: {
    clearPolicyPayError: (state) => {
      state.error = null;
    },
    clearPolicyPayState: () => initialState
  },
  extraReducers: (builder) => {
    // Policy Pay API
    builder.addCase(policyPay.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(policyPay.fulfilled, (state, action) => {
      state.isLoading = false;
      state.paymentLink = action?.payload;
    });
    builder.addCase(policyPay.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });  

    // Policy Pay Transfer API
    builder.addCase(policyPayTransferFees.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(policyPayTransferFees.fulfilled, (state, action) => {
      state.isLoading = false;
      state.paymentLink = action?.payload;
    });
    builder.addCase(policyPayTransferFees.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });  
  },
});

// SELECTORS
export const getPaymentLink = (store) => store?.policyPay.paymentLink;
export const getPolicyPayLoading = (store) => store?.policyPay.isLoading;
export const getPolicyPaySuccess = (store) => store?.policyPay.success;


// export const getSumInsuredLoader = (store) => store?.getSumInsured.isLoading;


// ACTIONS TO DISPATCH
export const { clearPolicyPayError, clearPolicyPayState} = policyPaySlice.actions;

export default policyPaySlice.reducer;
