import NewOwnerDetails from "../../pages/motorInsurancePages/transferPolicyFlow/newOwnerDetails";

export const en = {
  translation: {
    language: "English",
    header: {},
    validation: {
      required: "This field is required.",
      invalidEmail: "Invalid email address.",
      invalidMobile: "Invalid mobile number.",
      invalidName: "Name can only contain alphabetic characters and spaces",
      invalidNumber: "Please enter digits only.",
      invalidCPR: "Please enter {{length}} digits only.",
      futureDate: "Date of birth cannot be in the future",
      passwordDoesNotMatch: "Password does not match",
      selectOption: "Please select an option.",
      tabSelection: "Please select some value",
      exact_length: "This field must be exactly {{length}} characters long.",
      min_length: "This field must be at least {{min}} characters long.",
      max_length: "This field must not exceed {{max}} characters.",
      minValue: "The value must be at least {{min}}.",
      maxValue: "The value must not exceed {{max}}.",
      cpr_number: "CPR number must be 9 digits.",
    },
    siginPage: {
      new_to_label: "New to Solidarity ?",
      signup_link_label: "Sign Up",
      cpr_input_label: "CPR or CR Number",
      password_input_label: "Password",
      forgot_password_link_label: "I forgot my password",
      sign_in_btn_label: "Sign In",
    },
    signUpFlow: {
      signUpPage: {
        already_have_label: "Already have an account ?",
        sign_in_label: "Sign in",
        cpr_input_label: "CPR or CR Number",
        email_input_label: "Email Address",
        mobile_input_label: "Mobile Number",
        btn_label: "Next",
      },
      verifyOtpPage: {
        page_description: "Enter the 4-digit code number",
        sent_it_label: "we sent it to ",
        change_link_label: "Change",
        didnt_recieve_label: "I didn't recieve a code",
        btn_label: "Continue",
      },
      createPassPage: {
        page_description:
          "Choose a secure password that is at least 8 characters long",
        password_input_label: "Password",
        confirm_password_input_label: "Confirm Password",
        your_pass_must_label: "Your password must have:",
      },
      getVerifiedPage: {
        page_title: "Complete Your Verification",
        page_description:
          "Please follow the instructions to complete your verification process. Upload a clear photo of your ID as directed. Do not close or refresh this page until the verification process is complete.",
        idcard_img: "ID Card",
        driver_license_img: "Driver's License",
        selfie_label: "Selfie",
        verify_your_account_label: "Verifying Your account",
        dont_close_label:
          "Don't close or refresh this window as we try to verify you",
        btn_label: "Try again",
        verification_failed_title: "Verification failed",
        verification_failed_description:
          "Unfortunately, we couldn't verify your identity with the information provided. Please ensure your photo is clear and all document details are visible.",
      },
      verifySuccessPage: {
        btn_label: "Continue",
      },
      cpr_exists_popup: {
        title: "Account Already Exists",
        description_1: "Looks like you already have an account with us.",
        description_2:
          "Please sign in to continue. If you've forgotten your password, you can easily reset it.",
        btn_label: "Sign In Now",
      },
    },
    dashboard: {
      quick_actions_label: "Quick Actions",
      offers_label: "Offers",
      viewAll: "View All",
    },
    newPolicy: {
      online_services_label: "Online Services",
      new_insurance_policies_label: "New Insurance Plans",
      general_insurance_label: "General Insurance",
      life_insurance_label: "Life Insurance",
      buy_online_plans_label: "Get Plan online ",
      contact_advisor_message:
        "You can contact one of our financial advisors to enroll you in one of these plans",
      speak_to_our_advisor_label: "Speak to our advisor",
    },
    profileSettings: {
      home: "Home",
      profile_settings: "Profile Settings",
      last_login: "Last Login",
      your_profile: "Your Profile",
      full_name: "Full Name",
      cpr_cr_number: "CPR/CR Number",
      mobile_number: "Mobile Number",
      email_address: "Email Address",
      change_mobile_number: "Change Mobile Number",
      password: "Password",
      current_password: "Current Password",
      new_password: "New Password",
      confirm_new_password: "Confirm New Password",
      update_password: "Update Password",
      security: "Security",
      two_factor_authentication: "Two-Factor Authentication",
      yes_no_btn:
        "Add an extra layer of protection for accessing your Life account with SMS verification",
      verify_email: "Change Email Address",
    },
    commonLabels: {
      home_label: "Home",
      last_login_label: "Last Login",
      my_policies_label: "My Plans",
      expired_policies_label: "Expired Plans",
    },
    motorInsurance: {
      uploadSuccess: {
        upload_successful:
          "Congratulations on Successfully Uploading Your Photos!",
        upload_photos:
          "You have successfully uploaded photos of your car from all required angles. These images are crucial for assessing the current state of your vehicle as part of your insurance application.",
      },
      signInModal: {
        title: "Secure Your Insurance Choices",
        description:
          "Before you customize your plan with additional covers, let's secure your selections.",
        old_account: "If you already have an account, please Sign in!",
        register_account:
          "New here? Register with us for a streamlined experience.",
        help: "Need Help ?",
        customer_support:
          "Our customer support is here to assist you every step of the way.",
        faq: "FAQs",
        call_us: "Call Us",
      },
      bottomStickybtnlayout: {
        summary: "Show Summary",
      },
      viewExpiredPolicy: {
        view: "View Expired Plans",
      },
      adjustCarvaluePopUp: {
        title: "Adjust Your Car's Value",
        description: "As of 2024, your car's estimated value is",
        disclaimer:
          "Please note, increasing your car's value will affect the total contribution you pay.",
        by_how_much_increase:
          "By how much would you like to increase this value?",
        by_how_much_decrease:
          "By how much would you like to decrease this value?",
        label: "Your updated car value (Sum Insured)",
        labelConfirm: "Confirm",
        labelCancel: "Cancel",
      },
      covergeAndBenefit: {
        flexi: "Flexi Plan",
        benefit: "Included Benefits",
        optional_coverage: "Optional Coverage",
        get_added: "Get added Coverage Options As Low As BD8",
        change_plan: "Change Plan",
      },
      policyOverview: {
        back: "Back",
        save: "Save",
        policy_no: "Plan Number",
      },
      transferPolicyFlow: {
        newOwnerdetails: {
          title: "Transfer Ownership of Your Plan",
          description:
            "Please enter the details of the new owner. Once submitted, the new owner will receive a notification to complete the transfer process.",
          disclaimer:
            "Note that once the transfer is completed successfully, the plan will no longer appear in your account.",
          policy_number: "Plan Number",
          label_cpr: "New Owner CPR Number",
          label_mobile: "Mobile Number",
        },
        transfersuccess: {
          transfer_request: "Transfer Request Submitted Successfully!",
          transfer_policy_ownership:
            "Your request to transfer the plan ownership has been successfully submitted. Instructions have been sent to the new owner's mobile number",
          return_home: "Return to Home",
        },
      },
      newMotorInsuranceFlow: {
        basicInfo: {
          title: "New Motor Insurance",
          subtitle: "Welcome! Let's start with some basic information.",
          fullName: "Full Name",
          emailAddress: "Email Address",
          mobileNumber: "Mobile Number",
          dateOfBirth: "Date of Birth",
          day: "Day",
          month: "Month",
          year: "Year",
          continueButton: "Continue",
        },
        paymentSummary: {
          review_confirm: "Review and Confirm",
          review_proceed:
            "Please review your information and proceed to payment.",
          motor_insurance: "New Motor Insurance Plan",
          confirm_payment: "Review the details below and confirm the payment.",
          plate_number: "Plate Number",
          plate_type: "Plate Type",
          chassis_number: "Chassis Number",
          car_make: "Car Make",
          car_model: "Car Model",
          manufacturing_year: "Manufacturing Year",
          sum_insured: "Sum Insured",
          startDate: "Start Date",
          plan: "Plan",
          add_ons: "Add-ons",
          disclaimer:
            "Kindly note that the plan will be issued under your name.",
          agreement:
            "I hereby certify that the above given information is true and\n              correct to the best of my knowledge, and I agree to cooperate\n              fully by providing all requested documents before payment. I\n              understand that providing invalid information will result in my\n              insurance plan to be void. I also read and agree the ",
          policy: "Policy Specification",
          insurance: "of this Insurance product.",
          proceed_payment: "Proceed to Payment",
          declaration: [
            "I hereby declare that the vehicle being insured has not been involved in any accident or incident resulting in damage, loss, or the need for repair within the past 12 months.",
            "I confirm that to the best of my knowledge, there have been no events or occurrences related to this vehicle that would have required filing an insurance claim or report within this period.",
            "I understand that providing false or misleading information may affect my eligibility for coverage and may be grounds for policy adjustment or cancellation.",
            "I understand that failure to adhere to this declaration may affect my eligibility for coverage and may result in adjustments or cancellation of the policy.",
            "I, the undersigned, hereby understand and agree that for sums insured above or below market value, if any claim occurs during the specified insurance period, the vehicle value considered will be the market value or sum insured, whichever is less.",
          ],
        },
        documentsReadyInfo: {
          title: "Ready to Start Your New Plan?",
          description:
            "Please ensure you have the following information and documents ready for smooth process.",
          aboutCar: {
            title: "About your car",
          },
          documents: {
            title: "Documents",
          },
          continueButton: "Continue",
        },
        carInfo: {
          title: "About your car",
          description: "Is your car brand new?",
          yesButton: "Yes",
          noButton: "No",
          continueButton: "Continue",
          newCarForm: {
            title: "Your New Car's Details",
            description: "Please provide details about your car",
            chasisNumberLabel: "Chasis Number(VIN)",
            modelMakeLabel: "Model Make",
            carModelLabel: "Car Model",
            modelYearLabel: "Model Year",
            carBodyTypeLabel: "Car Body Type",
            noOfSeatsLabel: "No. of Seats",
            engineCapacityLabel: "Engine Capacity",
          },
          no_new_brand: {
            label: "Do you have Vehicle Ownership Certificate?",
            uploadInstruction:
              "Upload a clear photo of the vehicle ownership certificate",
          },
        },
        carInfoConfirm: {
          title: "Your Car's Information",
          description:
            "Please confirm the details below are correct for your vehicle.",
        },
        carInfoPlateNumber: {
          title: "Your Car's Information",
          description: "What is your car's plate number and type?",
          plateNumber: "Plate Number",
          plateType: "Plate Type",
          discoverPlateTypes: "Discover the differences between Plate Types",
          showMe: "Show Me",
        },
        carInfoFinancing: {
          title: "Car Financing",
          description: "Is your car currently financed through installments?",
          bankLabel: "Which bank is financing your car?",
          selectBank: "Select Bank",
          carValueLabel: "Your car value (Sum Insured)",
          bodyTypeLabel: "Car Body Type",
          noOfSeatsLabel: "No. of Seats",
          engineCapacityLabel: "Engine Capacity",
          buttonText: "Adjust Car Value",
          label_file_claim: "When was the last time you filed a claim?",
          bank_lender: "Bank/Lender Name",
          insurance_policy: "Insurance Plan Start Date",
          car_cost: "How much did your car cost?",
        },
        fileUploadInput: {
          choose_file: "Choose File",
          remove: "Remove",
        },
        coverageEnhancement: {
          title: "Enhance Your Coverage",
          description:
            "Would you like to add extra covers and benefits to your plan?",
        },
        planSelector: {
          title: "Choose Your Plan",
          description: "Select the plan that best suits your needs.",
          viewFullList: "View full list of benefits & covers",
        },
        verifyOtp: {
          title: "Verify OTP",
          description:
            "Enter the 4-digit code number that has been sent to your mobile number",
          sent_it_label: "We sent it to ",
          didnt_recieve_label: "I didn't receive a code",
        },
        uploadDocsPage: {
          title: "Upload Required Documents",
          description: "Please upload the necessary documents to proceed.",
          noClaimLabel: "Upload photos of the No Claim Discount certificate",
          bankLetterLabel: "Upload photo of the bank letter",
        },
        upload3rdPartyDocsPage: {
          title: "Upload Photos of Your Car",
          description: "Easily Capture and Upload Car Photos",
          stepsToUpload: {
            label: "Steps to Upload",
            description:
              "Scan the QR code below with your smartphone to access our mobile-friendly upload page. Follow the on-screen instructions to capture and upload each photo directly from your phone.",
          },
          instructions: {
            label: "Instructions",
            description:
              "Please upload clear photos of all four sides of your car to reflect its current condition accurately. Start by capturing the front, then the rear, followed by each side. This ensures all aspects are clearly presented.",
          },
          needAssistance:
            "Need assistance? If you encounter any issues or need help with the upload process, please visit our",
          faq: "FAQs",
          sectionOr: "section or",
          contactCustomerSupport: "contact customer support",
        },
        paymentMethods: {
          title: "Payment Method",
          quotationNumber: "Quotation Number",
          description: "Description",
          footerText1:
            "Solidarity Bahrain is a Subsidiary of Solidarity Group Holding",
          footerText2:
            "Licensed and regulated by the Central Bank of Bahrain as a locally incorporated Takaful Firm - Category four",
          paymentMethodsList: {
            debitCard: "Debit Card",
            creditCard: "Credit Card",
            applePay: "Apple Pay",
            flooss: "Flooss",
          },
        },
        paymentSuccess: {
          congratulations: "Congratulations! You are all done.",
          notification_received:
            "You should have received a notification confirming that your insurance plan has been approved with Solidarity.",
          view_download: "View & download your insurance plan documents:",
          for_inquiries: "For any inquiries you may contact our chatbot via",
          whatsapp: "WhatsApp",
          or_call: "or call us at",
          view_policy_details: "View Your Plan Details",
          or: "OR",
        },
      },
      renewalPolicyFlow: {
        cprNoPage: {
          cprNo: "CPR or CR Number",
          title: "Plan Renewal",
          description:
            "Please enter your CPR number to find the plans that require renewal.",
          plateNo: "Plate Number",
          quickRenewal: "Quick Renewal",
          quickRenewalDesc:
            "Kindly provide your CPR number and vehicle registration number to proceed with the plan renewal.",
        },
        motorPolicyrenewal: {
          title: "Motor Plan Renewal",
          description: "Review the details below and confirm the payment.",
          payment: "Proceed to Payment",
          declaration:
            "I, the undersigned hereby understand and agree that for sum insured above or below market value, if any claim/s occur during the specified insurance period, the vehicle value that will be taken into consideration is the market value or sum insured, whichever is less.",
        },
        policyCard: {
          selected: "Selected",
          select: "Select",
          expiry_date: "Expires in",
          days: "days",
        },
        currentPlanDetails: {
          plan_details: "Current Plan Details",
          review_policy: "Change Your Plan",
        },
        verifyOtpPage: {
          title: "Verify OTP",
          description: "Enter the 4-digit code number",
          sent_it_label: "We sent it to ",
          didnt_recieve_label: "I didn't receive a code",
        },
        selectPoliciesPage: {
          title: "Plan Renewal",
          description:
            "Please enter your CPR number to find the plans that require renewal.",
        },
        reviewYourPolicyPage: {
          title: "Review Your Plan Type",
          description:
            "You can either continue with your current plan or choose to downgrade.",
        },
        adjustCarValuePage: {
          title: "Your Car's Value Has Changed",
          description: "Do you wish to adjust the value?",
        },
        enhanceYourCoverage: {
          title: "Enhance Your Plan",
          description:
            "Which additional covers and benefits would you like to add to your plan?",
        },
        reviewAndConfirmPage: {
          title: "Review and Confirm",
          description: "Please review your information and proceed to payment.",
        },
        chooseDowngradePlanPage: {
          title: "Review Your Plan Type",
          description:
            "You can either continue with your current plan or choose to downgrade.",
        },
      },
      changePlanMotorPolicy: {
        selectNewPlan: {
          title: "Update Your Plan",
          description: "Select a new Plan that better suits your needs.",
        },
        reviewAndConfirmPage: {
          upgrade_title: "Review and Confirm",
          upgrade_description:
            "Please review your information and proceed to payment.",
          downgrade_title: "Review and Confirm Your Downgrade",
          downgrade_description:
            "Please review your information carefully before proceeding.",
          upgrade_plan: "You are upgrading your plan",
          third_party: "Third Party Plan",
          gold: "Gold Plan",
          downgrade_plan: "You are about to downgrading your plan",
          confirm: "Confirm",
          declaration: [
            "I hereby declare that the vehicle being insured has not been involved in any accident or incident resulting in damage, loss, or the need for repair within the past 12 months.",
            "I confirm that to the best of my knowledge, there have been no events or occurrences related to this vehicle that would have required filing an insurance claim or report within this period.",
            "I understand that providing false or misleading information may affect my eligibility for coverage and may be grounds for policy adjustment or cancellation.",
          ],
        },
      },
      addMoreCovers: {
        additional_covers: "You are adding additional covers to your plan",
        car: "Car Replacement",
        small_car: "15 Days - Small Car",
        roadside_assistance: "Roadside Assistance",
        declaration: [
          "I hereby declare that the vehicle being insured has not been involved in any accident or incident resulting in damage, loss, or the need for repair within the past 12 months.",
          "I confirm that to the best of my knowledge, there have been no events or occurrences related to this vehicle that would have required filing an insurance claim or report within this period.",
          "I understand that providing false or misleading information may affect my eligibility for coverage and may be grounds for policy adjustment or cancellation.",
        ],
      },
      removeBankNameFlow: {
        removeBankPage: {
          title: "Remove Bank Name from Ownership",
          description:
            "Please confirm the current owner details and remove the bank name associated with your vechile.",
          input_label: "Current Bank",
        },
        uploadBankNOC: {
          title: "Upload Bank No Objection Letter",
          description:
            "Please upload the no objection letter from your bank, confirming removal of their name from your car ownership.",
          input_label: "Upload Bank No Objection Letter",
        },
      },
      adjustSumInsuredFlow: {
        adjustSumValue: {
          title: "Adjust your car value (Sum Insured)",
          description:
            "Please select how much you would like to increase or update this value.",
          car_value_input_label: "Your car value (Sum Insured)",
          request_call_label: "Request a call",
          increase_over_30: " to increase over 30%",
        },
        reviewAndConfirmPage: {
          title: "Review and Confirm",
          description: "Please review your information and proceed to payment.",
          you_have_increased_label:
            "You have increased the insured value of your car",
          proceed_to_payment: "Proceed to Payment ",
        },
      },
      changePlateNumber: {
        newPlateNumber: {
          title: "Change Your Car's Plate Number",
          description: "Please enter the new plate number for your car.",
          current_plate_number_label: "Current plate number",
          new_plate_number_label: "New plate number",
          plate_type_label: "Plate Type",
        },
        uploadLetterOfDocument: {
          title: "Upload Traffic Directorate Document",
          description:
            "Please upload the letter from the General Directorate of Traffic confirming your new plate number.",
          upload_letter_of_document_label: "Upload new number letter document",
        },
      },
      changePlateNumberPopup: {
        title: "Update Needed at GDT",
        message1:
          "Your attempt to change the plate number cannot proceed because it has not been updated with the General Directorate of Traffic (GDT) first.",
        message2:
          "Please update your vehicle's registration at the GDT and then return here to update your insurance plan.",
        buttonLabel: "Return to Home",
      },
      trackClaimFlow: {
        breadcrumb: {
          file_a_claim: "File a Claim",
          claim_details: "Claim Details",
        },
        basicInfo: {
          title: "Track Claim",
          description:
            "To locate your claim, please provide the following information.",
          claim_input_label: "Case Number",
          mobile_number_label: "Mobile Number",
        },
        garageSelection: {
          title: "Change Garage/Workshop",
          searchPlaceholder: "Search for garage name",
          submitButton: "Submit",
          backButton: "Back",
        },
        claimDetailCard: {
          claimType: "Claim Type",
          policyPlan: "Plan",
          driverCprNumber: "Driver CPR Number",
          vehicleOwnerCprNumber: "Vehicle Owner CPR Number",
          plateNumber: "Plate Number",
          emailAddress: "Email Address",
          mobileNumber: "Mobile Number",
          accidentDescription: "Accident Description",
          plannedGarageWorkshop: "Planned Garage/Workshop",
          repairSurveyDate: "Repair Survey Date",
          claimResolution: "Claim Resolution",
        },
        uploadDocumentPage: {
          uploadLabel: "Upload Documents",
          submit: "Submit",
        },
        confirmPaymentDetails: {
          title: "Confirm Payment Details",
          description:
            "Please enter your IBAN number and upload the corresponding IBAN certificate to facilitate the payment process.",
          ibanLabel: "IBAN Number",
          ibanUpload: "Upload IBAN Certificate",
          submitButton: "Approve and Submit",
          backButton: "Back",
        },
        successScreen: {
          confirmationMessage:
            "We've received your offer approval. Our claims team will contact you soon to discuss payment to your designated IBAN. Thank you for your cooperation.",
          backToClaimButton: "Back to Claim",
        },
        confirmIdentityModal: {
          confirm_identity: "Confirm Your Identity",
          identity_verification:
            "Please sign in or create an account to verify your identity information and securely complete your offer approval.",
          existing_account: "If you already have an account, please Sign in!",
          register_info:
            "New here? Register with us for a streamlined experience.",
          need_help: "Need Help?",
          customer_support:
            "Our customer support is here to assist you every step of the way.",
          call_us: "Call Us",
          faqs: "FAQs",
        },
        resonForRejection: {
          title: "Reason of Rejection",
          description: "Please specify why you are rejecting the offer",
          tabLable: "Reason for Rejection",
          submit: "Submit Response",
          counterOfferLable: "Counter Offer",
        },
      },
      fileAClaimFlow: {
        nonSolidarityPolicyPlans: {
          title: "File a Claim",
          description: "Select the policy plans",
        },
        customerTypePage: {
          title: "File a Claim",
          description: "Who is reporting the claim today?",
        },
        policyTypePage: {
          title: "File a Claim",
          description: "select plan type",
        },
        requiredDocsPage: {
          title: "File a Claim",
          description:
            "Please ensure you have the following information and documents ready for smooth process.",
        },
        policyPlansPage: {
          title: "File a Claim",
          description: "Select the plan type",
          mobile_description: "Select the plan type that fits your claim",
        },
        yourInfoPage: {
          title: "File a Claim",
          description: "Your information",
          mobile_description: "Enter Your information",
          driver_cpr_number_label: "Driver CPR Number",
          vehicle_cpr_number_label: "Vehicle Owner CPR Number",
          plate_number_label: "Vehicle Plate Number",
          email_address_label: "Email Address",
          mobile_number_label: "Mobile Number",
        },
        propertyDamageYourInfo: {
          owner_cpr_number_label: "Property Owner CPR Number",
          name_label: "Property Owner Name",
          address_label: "Property Address",
        },
        bodilyInjuryYourInfo: {
          owner_cpr_number_label: "CPR Number of the Impacted Person",
        },
        accidentInfoPage: {
          title: "File a Claim",
          description: "Accident information",
          mobile_title: "Accident Information",
          mobile_description: "Enter the accident details and what happened",
          desc_accident_label: "Describe the accident",
          desc_accident_placeholder: "Tell us a bit about the accident",
          preferred_garage_label: "Preferred Garage/Workshop",
          preferred_garage_placeholder: "Select Garage/Workshop",
          preferred_garage_hint_msg:
            "* Note: There might be additional labor charges if your insurance plan does not cover agency repair",
          preferred_date_survey_label: "Preferred date to survey the car",
          preferred_date_survey_hint_msg:
            "* Note: vehicle will be surveyed within 48 hours from the time of registration",
          propertyDamageAccidentInfo: {
            contact_person_label: "Contact Person for Survey",
            preferred_date_label: "Preferred Date to Survey",
          },
          date_error: "The inspection must take place at least 3 working days later, and it cannot be on weekends."
        },
        repairOrCashPage: {
          title: "Upload Required Documents",
          mobile_title: "Upload Required Documents",
          description: "Please upload the necessary documents to proceed.",
          mobile_description:
            "Please upload the necessary documents to proceed",
          driver_licence_label:
            "Driver license at the time of accident (Front & Back)",
          vehicle_ownership_label:
            "Vehicle ownership certificate (Front & Back)",
          photos_of_accidents_label: "Photos of the accident",
          report_file_label:
            "Incident Report from General Directorate of Traffic",
        },
        reviewAndConfirmPage: {
          title: "Review and Confirm",
          description: "Please review your information before submitting",
          mobile_title: "New Claim",
          mobile_description:
            "Review the details below and confirm the submission",
          claim_type_label: "Claim Type",
          policy_plan_label: "Plan Type",
          driver_cpr_label: "Driver CPR Number",
          vehicle_cpr_label: "Vehicle Owner CPR Number",
          vehicle_plate_num_label: "Vehicle Plate Number",
          email_label: "Email Address",
          mobile_label: "Mobile Number",
          accident_label: "Describe the Accident",
          preferred_garage_label: "Preferred Garage/Workshop",
          preferred_repair_label: "Preferred repair date",
          driver_licence_label: "Driver's License",
          vehicle_owner_label: "Vehicle ownership certificate",
          photos_of_accident_label: "Photos of the accident",
          incident_gov_label: "Incident Report from Government",
          confirm_label_pre: 
            "I hereby certify that the above given information is true and correct to the best of my knowledge, and I agree to cooperate fully by providing all requested documents before payment. I understand that providing invalid information will result in my insurance plan to be void. I also read and agree the",
          confirm_label_link: "Plan Specification",
          confirm_label_post: "of this Insurance product.",
          declaration: [
            "By checking the box and submitting this form, I acknowledge that I have read Solidarity's Terms of Use and Privacy Policy, and I agree to be contacted regarding my claims request.",
            "I hereby certify that the above given information is true and correct to the best of my knowledge, and I agree to cooperate fully by providing all requested documents. I understand that providing invalid information will result in my claim to be void.",
          ],
          submitClaimBtnLabel: "Submit Claim",
        },
        soilidarityUserRepairOrCashPage: {
          title: "File a Claim",
          description: "How would you like to proceed with your claim?",
        },
      },
      policyCancellationFlow: {
        review_Page: {
          title: "Plan Cancellation",
          description:
            "Please review your plan details carefully before proceeding with cancellation.",
          owner: "Owner",
          policy_number: "Plan Number",
          startDate: "Start Date",
          endDate: "End Date",
          plate_number: "Plate Number",
          chassis_number: "Chassis Number",
          car_make: "Make",
          car_model: "Car Model",
          manufacturing_year: "Manufacturing Year",
          body_type: "Body Type",
          engine_capacity: "Engine Capacity",
          seating_capacity: "Seating Capacity",
          vehicle_colour: "Vehicle Colour",
          premium_paid: "Contribution Paid",
        },
        cancellationReson: {
          title: "Reason for Cancellation",
          description:
            "Help us understand why you want to cancel your plan. Your feedback is valuable and will be used to improve our services.",
        },
        confirmCancellation: {
          title: "Confirm Cancellation",
          description:
            "Review your plan details below to ensure you are fully informed before finalizing the cancellation of your plan.",
          keepPolicyBtn: "Keep Plan",
          confirmCancellationBtn: "Confirm Cancellation",
        },
        bottomLayout: {
          keepPolicyBtn: "I want to keep my plan",
          viewTermsAndConditionBtn: "View cancellation terms & conditions",
        },
      },
    },
    lifeInsurance: {
      policyOverview: {
        back: "Back",
      },
      changePaymentCycle: {
        popup_title: "Change payment cycle",
        selectPaymentLabel: "Select payment cycle",
        cancel_btn_label: "Cancel",
        confirm_btn_label: "Confirm Selection and Submit",
      },
      changePaymentMethod: {
        popup_title: "Change payment method",
        currentPaymentMethod: "Current Payment Method",
        masterCardEndLabel: "Mastercard ending in",
        expiryLabel: "Expiry",
        newPaymentMethodLabel: "New Payment Method",
        cancel_btn_label: "Cancel",
        Change_btn_label: "Change",
      },
      updatePaymentMethod: {
        popup_title: "Update payment method",
        cancel_btn_label: "Cancel",
        change_btn_label: "Update",
      },
      withdrawal: {
        changeExistingFunds: {
          title: "Withdrawal",
          description: "Change existing funds",
          currentEnhanceLabel: "Current encashment value",
          currentEnhanceHintLabel:
            "Minimum withdrawal amount is BHD 500 and is up to 85% of the plan value",
          withdrawalAmountLabel: "Withdrawal Amount",
          withdrawalAmountHintLabel: "Our withdrawal fees is BHD 5",
          ibanNumberLabel: "IBAN Number",
          uploadPhotosOfCarLabel: "Upload photos of the IBAN Certificate",
        },
        reviewAndConfirm: {
          title: "Review and Confirm",
          description: "Please review your information and submit request.",
          withdrawalAmountLabel: "Withdrawal Amount",
          totalFeeLabel: "Total Fees (Inculded)",
          sendToLabel: "Send to",
          receivedLabel: "Received Amount",
          bhdLabel: "BHD",
          iAcceptTheLabel: "I accept the",
          andLabel: "and",
          termsOfLinkLabel: "Terms of Use",
          privacyLinkLabel: "Privacy policy",
          confirmSubmitLabel: "Confirm and Submit",
        },
        decIncPremium: {
          popup_title: "Decrease or Increase contribution",
          currentPremiumLabel: "Current contribution",
          newPremiumInputLabel: "New contribution",
          newPremiumInputHintMsg: "Paid",
          cancelBtnLabel: "Cancel",
          confirmBtnLabel: "Confirm Amount and Submit",
        },
      },
      surrenderPolicy: {
        title: "Surrender Your Plan",
        description:
          "Review the details of your plan and provide the necessary information to proceed with the surrender.",
        policy_detail_title: "Plan Details",
        policy_detail_description:
          "You have selected the following plan for surrender. Please review the details below.",
        policy_name_label: "Plan Name",
        policy_number_label: "Plan Number",
        plan_label: "Plan",
        start_date_label: "Plan start date",
        current_plan_value_label: "Current Plan value",
        surrender_value_label: "Surrender Value",
        surrender_value_desc_label: "Net of surrender charge",
        reason_input_label: "Reason for Surrender",
        other_reason_label: "Other reasons",
        submit_button_label: "Submit Surrender Request",
        iAcceptTheLabel: "I accept the",
        andLabel: "and",
        termsOfLinkLabel: "Terms of Use",
        privacyLinkLabel: "Privacy policy",
      },
      investmentStrategyFlow: {
        fundsRedirectingFlow: {
          addFundsPage: {
            title: "Add Funds",
            description: "Select funds to add",
          },
          fundsRedirectingPage: {
            title: "Funds Redirecting",
            description:
              "Select the funds where your future contributions will be invested. This will be your new investment strategy. Your existing fund holdings will remain the same.",
          },
        },
        fundSwitchingFlow: {
          switchFundsPage: {
            title: "Funds Switching",
            description:
              "Sell all or some of your plan's current fund holdings to buy other funds. As part of this process, you can also change the funds where your future contributions will be invested.",
            label: "Select the funds you want to remove",
          },
          addFundsPage: {
            title: "Add Funds",
            description: "Select funds to add",
          },
          fundAllocationPage: {
            title: "Funds Allocation",
            description:
              "Sell all or some of your plan's current fund holdings to buy other funds. ",
          },
        },
      },
    },
    buyOnline: {
      expressShieldFlow: {
        basicInfo: {
          title: "Welcome to Express Shield!",
          description: "Primary Life Assured Details",
          fullName: "Full Name",
          emailAddress: "Email Address",
          mobileNumber: "Mobile Number",
          dateOfBirth: "Date of Birth",
          month: "Month",
          day: "Day",
          year: "Year",
        },
        otherDetails: {
          title: "Welcome to Express Shield!",
          description: "Primary Life Assured Details",
          gender: "Gender",
          nationality: "Nationality",
          height: "Height (cm)",
          weight: "Weight (kg)",
        },
        declaration: {
          title: "Questionnaire/Declaration",
          description: "I hereby declare and certify that",
          additionalInfo:
            'Additional information if any (and if any response above is "False")',
        },
        choosePlan: {
          title: "Choose Your Plan",
          description: "Annual Plan - Renewable every year",
        },
        beneficiaryInfo: {
          title: "Beneficiary/Trustee information",
          description:
            "Legal heirs are default beneficiaries unless otherwise stated",
          appointBeneficiary: "Do you want to appoint Beneficiary?",
          addBeneficiary: "Add Beneficiary",
          table: {
            name: "Name",
            sharePercentage: "Share Percentage",
            cpr: "CPR",
            relationship: "Relationship",
            mobileNumber: "Mobile Number",
          },
          beneficiarySuccess: "New beneficiary added successfully",
          beneficiaryFailed: "Failed to create Beneficiary",
          age21: "Beneficiary Age should be 21",
          removeBeneficiary: "Remove beneficiary",
          somethingWentWrong: "something went wrong try again",
        },
        reviewAndConfirm: {
          title: "Review and Confirm",
          description: "Please review your information and proceed to payment.",
          participantDetailsTitle: "Participant Details",
          participantDetails: {
            name: "Name",
            dob: "Date of Birth",
            age: "Age",

            idType: {
              label: "I.D. Type",
              cpr: "CPR",
            },
            idNumber: "I.D. Number",
            gender: "Gender",
          },
          shieldTitle: "Express Shield Basic Summary",
          shieldSummary: {
            deathAnyCause: "Death Any Cause",
            repatriationExpense: "Repatriation Expense",
            totalSumInsured: "Total Sum Insured",
            grossContribution: "Gross Contribution",
          },
          planNote:
            "Please note that this plan has no Plan Value or Surrender Value",
          agreement: {
            iDeclare: [
              "I declare that the answers given are true and complete to the best of my knowledge and belief, and will form the basis of the contract for my application for life insurance.",
              "I understand that failure to disclose any material fact may invalidate the contract.",
              "I consent to The Company seeking information from any medical practitioner who has attended me or from any insurer to which an application has been made for insurance and I authorize the giving of such information.",
              "I agree to inform The Company in writing of any change in my circumstances between the date of this application and issue of the policy contract.",
              "I also agree to inform The Company of any change of name, change of address etc. that may occur during the life of this policy.",
              "I understand and consent to The Company seeking independent verification (if considered necessary) of any of the information given in this questionnaire.",
            ],
            declaration: "Declaration.",
            iCertify:
              "I hereby certify that the above given information is true and correct to the best of my knowledge, and I agree to cooperate fully by providing all requested documents before payment. I understand that providing invalid information will result in my insurance policy to be void. I also read and agree the",
            policySpecification: "Policy Specification",
            product: "of this Insurance product. ",
            iAccept: "I accept the",
            generalTandC: "General Terms & Conditions",
          },
        },
        reviewYourApplication: {
          answeredYesTitle: "Review Required",
          title: "Review Your Application",
          subtitle:
            "Thank you for providing your details. Based on the information submitted, we need to take a closer look at your application.",
          note: "To ensure we offer you the best possible coverage option, one of our specialized agents will contact you shortly to discuss your specific needs and how we can assist you.",
          goHome: "Go Home",
        },
        labels: {
          name: "Name",
          email: "Email Address",
          mobileNumber: "Mobile Number",
          dob: "Date of Birth",
          nationality: "Nationality",
          gender: "Gender",
          height: "Height (cm)",
          weight: "Weight (kg)",
        },
      },
    },
    forgot_password_flow: {
      reset_password: {
        title: "Forgot Password",
        description: "Enter your details to reset your password",
        next: "Next",
      },
      verify_otp_page: {
        title_desc_mobile: "Enter the number sent to your mobile phone",
        title_desc_code: "Enter the 4-digit code number",
      },
      create_password_page: {
        page_title: "Set New Password",
      },
    },
    pageNotFound: {
      title: "404 - Page Not Found",
      description: "We can't seem to find the page you're looking for",
      btnLabel: "Go to Home",
    },
    securityPage: {
      security: "Security",
      useFaceID: "Use Face ID to log in",
      autoLockSecurity: "Auto-Lock security",
      autoLockDescription: "Require Face ID after 10 minutes of inactivity",
      changePassword: "Change password",
      twoFactorAuth: "Two-factor Authentication",
    },
    essentialCareFlow: {
      basicInfo: {
        title: "New Essential Care Insurance",
        description: "Welcome! Let's start with some basic information.",
        fullName: "Full Name",
        emailAddress: "Email Address",
        mobileNumber: "Mobile Number",
        dateOfBirth: "Date of Birth",
        month: "Month",
        day: "Day",
        year: "Year",
      },

      beneficiaryInfo: {
        title: "Essential Care Policy Details",
        description: "Choose to cover yourself or add family members",
        addMember: "Add family member",
        table: {
          name: "Name",
          sharePercentage: "Share Percentage",
          cpr: "CPR",
          relationship: "Relationship",
          mobileNumber: "Mobile Number",
        },
        beneficiarySuccess: "New beneficiary added successfully",
        beneficiaryFailed: "Failed to create Beneficiary",
        age21: "Beneficiary Age should be 21",
        removeBeneficiary: "Remove",
        somethingWentWrong: "something went wrong try again",
      },
      reviewAndConfirm: {
        title: "Review and Confirm",
        description: "Please review your information and proceed to payment.",
        participantDetailsTitle: "Primary (You)",
        participantDetails: {
          name: "Name",
          dob: "Date of Birth",
          cpr: "CPR No.",
          gender: "Gender",
          premium: "Premium",
        },
        agreement: {
          iDeclare: [
            "I agree that no indemnity will be paid and no coverage will be provided under the proposed insurance policy for medical expenses arising from disorders which were declared prior to completion of this Application and which were not disclosed to the insurer at the date of this application. Failure to disclose material information to the insurer will invalidate the proposed insurance policy.",
            "I hereby agree, with this in respect to both, myself and my Dependants (If any) that I am aware of the general terms of this insurance and I accept them for myself and on behalf of my dependants. I the undersigned declare that all of the above information as well as all declarations on the additional questionnaire (personal information) are true and complete.",
            "This information shall be considered as an integral part of the insurance policy.",
          ],
        },
        note: "Kindly note that the policy will be issued under your name. This policy is subject to 3 months waiting period on pre-existing, chronic, dental and maternity claims",
      },
      reviewYourApplication: {
        answeredYesTitle: "Review Required",
        title: "Review Your Application",
        subtitle:
          "Thank you for providing your details. Based on the information submitted, we need to take a closer look at your application.",
        note: "To ensure we offer you the best possible coverage option, one of our specialized agents will contact you shortly to discuss your specific needs and how we can assist you.",
        goHome: "Go Home",
      },
    },
  },
};
