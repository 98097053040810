import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import BackdropModal from "../../../../components/common/modal/backdropModal";
import homeblackicon from "../../../../assets/images/home-black.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectNewLifeForm } from "../../../../redux/features/newLifeFormSlice";
import { EXPRESS_SHEILD_BASIC_INFO } from "./basicInfo";
import { EXPRESS_SHEILD_OTHER_DETAILS } from "./otherDetails";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import { genderLabels } from "../data";

const ReviewYourApplication = ({ type, show, onClose }) => {
  const { t } = useTranslation();
  const translate = useLanguageTranslate()
  const navigate = useNavigate();
  const basicDetails = useSelector(selectNewLifeForm)?.[EXPRESS_SHEILD_BASIC_INFO];
  const otherDetails = useSelector(selectNewLifeForm)?.[EXPRESS_SHEILD_OTHER_DETAILS];

  const applicationDetails = () => {
    let gender = genderLabels?.[otherDetails?.gender || ""];
    console.log(gender,'some gender')
    return [
      { labelKey: "name", value: basicDetails?.fullName || "" },
      { labelKey: "email", value: basicDetails?.email || "" },
      { labelKey: "mobileNumber", value: basicDetails?.mobile || "" },
      { labelKey: "dob", value: `${basicDetails?.day}-${basicDetails?.month}-${basicDetails?.year}` },
      { labelKey: "nationality", value: otherDetails?.nationality || "" },
      { labelKey: "gender", value: translate(gender?.label_en, gender?.label_ar) },
      { labelKey: "height", value: otherDetails?.height || "" },
      { labelKey: "weight", value: otherDetails?.weight || "" },
    ];
  }



  return (
    <BackdropModal show={show} onClose={onClose} hideClosebtn>
      <div className="w-[548px] max-w-[100%] max767:">
        <div className="w-full p-4 max767:p-0">
          <h2 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] text-left mb-4">
            {type =="answeredYes"
              ? t(
                "buyOnline.expressShieldFlow.reviewYourApplication.answeredYesTitle"
              )
              : t("buyOnline.expressShieldFlow.reviewYourApplication.title")}
          </h2>
          <p className="text-left mb-6 text-[#1A2024] text-base not-italic font-normal leading-[26px] tracking-[-0.16px]">
            {t("buyOnline.expressShieldFlow.reviewYourApplication.subtitle")}
          </p>

          <div className="rounded-lg mb-6">
            <table className="w-full text-left text-sm border-separate border-spacing-0 border-1 border border-solid border-[#DDE2E4] rounded-lg">
              <tbody>
                {applicationDetails()?.map((detail, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-6 py-2.5 text-[#5B6871] text-sm not-italic font-medium leading-6 w-[50%] border-b-[#DDE2E4] border-b border-solid max767:px-4 max767:py-2">
                      {t(
                        `buyOnline.expressShieldFlow.labels.${detail.labelKey}`
                      )}
                    </td>
                    <td className="px-6 py-2.5 text-left text-[#1A2024] text-sm not-italic font-medium leading-6 w-[50%] border-b-[#DDE2E4] border-b border-solid border-l-[#DDE2E4] border-l max767:px-4 max767:py-2 rtl:border-r-[#DDE2E4] border-r rtl:border-l-0">
                      {detail.value}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <p className="text-[#1A2024] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] text-left">
            {t("buyOnline.expressShieldFlow.reviewYourApplication.note")}
          </p>

          <div className="mt-6 text-center">
            <button
              onClick={() => navigate("/")}
              className="flex w-full px-[9px] bg-[#55C6EF] mr-2 rounded-[10px] py-2 items-center gap-2 mb-0 text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:w-full ipad:w-[100%]"
            >
              <p className="items-center text-sm justify-center w-full flex gap-2">
                {t("buyOnline.expressShieldFlow.reviewYourApplication.goHome")}
                <img src={homeblackicon} alt="home Icon" className="h-6 flex" />
              </p>
            </button>
          </div>
        </div>
      </div>
    </BackdropModal>
  );
};

export default ReviewYourApplication;
