// src/components/PaginatedTable.js
import React, { useEffect, useState } from "react";
import PerformanceCard from "../../../../components/common/card/performanceCard";
import useScreenResolution from "../../../../hooks/useScreenResolution";
import { Link } from "react-router-dom";
import { paymentsHistoryData } from "../data";
import { currencyFormatterWithCountryCode, dateFormatter } from "../../../../utils";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";

const PaymentHistory = ({ info }) => {
  const translate = useLanguageTranslate();
  const apiMappings = {
    contributionDue: "Coverage From",
    amountDue: "Collection Amount",
    dateContributionReceived: "Collection Date",
    amountReceived: "Collection Amount",
    currencyCode: "Collection Currency",
    duePaymentDate: "duePaymentDate",
    regularContribution: "regularContribution",
  };
  const duePaymentDetailsRow = {
    contributionDue: dateFormatter(
      info?.LifeDetails?.policyList?.[0]?.duePaymentDate,
      "MMM yyy",
      translate("en", "ar")
    ),
    amountDue: info?.LifeDetails?.policyList?.[0]?.regularContribution,
    dateContributionReceived: "-",
    amountReceived: "-",
    status: translate("Due", "المستحق"),
    currencyCode: "",
  };
  const historyRowData = info?.LifeDetails?.policyList?.[0]?.paymentHistory?.map(
    (item) => {
      const paramList = item.record.paramList;

      const contributionDue = dateFormatter(
        paramList.find(
          (param) => param.paramName ==apiMappings.contributionDue
        )?.paramValue,
        "MMM yyy",
        translate("en", "ar")
      );
      const amountDue = paramList.find(
        (param) => param.paramName ==apiMappings.amountDue
      )?.paramValue;
      const dateContributionReceived = dateFormatter(
        paramList.find(
          (param) => param.paramName ==apiMappings.dateContributionReceived
        )?.paramValue,
        "d MMM yyyy",
        translate("en", "ar")
      );
      const amountReceived = paramList.find(
        (param) => param.paramName ==apiMappings.amountReceived
      )?.paramValue;
      const currencyCode = paramList.find(
        (param) => param.paramName ==apiMappings.currencyCode
      )?.paramValue;
      const status = !amountReceived
        ? translate("Due", "المستحق")
        : translate("Paid", "المدفوع");

      return {
        contributionDue,
        amountDue,
        dateContributionReceived,
        amountReceived,
        status,
        currencyCode,
      };
    }
  ) || [];
    const historyData = [ duePaymentDetailsRow,...historyRowData];
    let cardsData = paymentsHistoryData.cards;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedYear, setSelectedYear] = useState("");
  const itemsPerPage = 5;
  const screenResolution = useScreenResolution();
  const totalPages = Math.ceil(historyData?.length / itemsPerPage);

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) return;
    setCurrentPage(page);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setCurrentPage(1);
  };

  const filteredData = selectedYear
    ? historyData.filter((item) => item.contributionDue?.includes(selectedYear))
    : historyData;

  const currentTableData = filteredData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const getPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) pageNumbers.push(i);
    } else if (currentPage <= 4) {
      pageNumbers.push(...[1, 2, 3, 4, 5, "...", totalPages]);
    } else if (currentPage >= totalPages - 3) {
      pageNumbers.push(
        ...[
          1,
          "...",
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        ]
      );
    } else {
      pageNumbers.push(
        ...[
          1,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPages,
        ]
      );
    }
    return pageNumbers;
  };

  const pages = getPageNumbers();

return (
  <div className="container mt-4 max767:p-5 max767:pt-0 max767:mt-5">
    <div className="flex items-start gap-4 self-stretch ipad:gap-2 max767:grid max767:grid-cols-2 paymenthistory relative">
      {cardsData?.map((card, index) => (
        <PerformanceCard
          historyData={historyData}
          info={info}
          key={index}
          cardData={card}
          className="overflow-hidden bg-[#FBF7F0] rounded-2xl flex-col flex h-[163px] justify-between items-start flex-[1_0_0] p-6 max767:p-4 max767:h-[130px] paymenthistroybox"
        />
      ))}
    </div>

    <div className="flex justify-between items-center  max767:w-full max767:items-start max767:px-0 max767:py-4 max767:border-b-[#D5DADD] max767:border-b max767:border-solid max767:items-center">
      <h2 className="text-[#252C32] text-[26px] not-italic font-medium leading-[120%] mb-2.5 pt-[10px] max767:text-xl max767:not-italic max767:font-semibold">
        {translate("Payment History", "سجل المدفوعات")}
      </h2>
      {!screenResolution.mobile ? (
        <div className="flex justify-center items-center gap-1 mt-4 pl-4 pr-2 py-1 border bg-white rounded-md mb-2.5">
          <span className="text-[#9AA6AC] text-sm not-italic font-normal leading-6">
            {translate("Jump to", "الانتقال إلى")}
          </span>
          <select
            onChange={handleYearChange}
            value={selectedYear}
            className="px-3 py-1 bg-white  border-0 focus:outline-none text-[#252C32] text-sm not-italic font-normal h-6 slectyear flex items-center justify-center leading-[1.0] bg-[100%_54%]"
          >
            <option value="">{translate("Year", "السنة")}</option>
            {[
              ...new Set(
                historyData?.map((item) => item?.contributionDue?.slice(-4))
              ),
            ]
              .sort()
              .map((year) => (
                <option key={year} value={year}>
                  {translate("Year", "السنة")} {year}
                </option>
              ))}
          </select>
        </div>
      ) : (
        <Link className="text-[#00384D] text-sm not-italic font-medium leading-[120%] underline">
          {translate("See all", "عرض الكل")}
        </Link>
      )}
    </div>

    {!screenResolution.mobile ? (
      <>
        <table className="min-w-full bg-white border border-gray-200  text-left rtl:text-right border-separate border-spacing-0 rounded-[10px] phtable">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-r border-gray-200 text-[#6E7C87] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
                {translate("Contribution due", "المساهمة المستحقة")}
              </th>
              <th className="py-2 px-4 border-b border-r border-gray-200 text-[#6E7C87] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
                {translate("Amount due", "المبلغ المستحق")}
              </th>
              <th className="py-2 px-4 border-b border-r border-gray-200 text-[#6E7C87] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
                {translate(
                  "Date contribution received",
                  "تاريخ استلام المساهمة"
                )}
              </th>
              <th className="py-2 px-4 border-b border-r border-gray-200 text-[#6E7C87] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
                {translate("Amount received", "المبلغ المستلم")}
              </th>
              <th className="py-2 px-4 border-b border-r border-gray-200 text-[#6E7C87] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
                {translate("Status", "الحالة")}
              </th>
            </tr>
          </thead>
          <tbody>
            {currentTableData?.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b border-gray-200 border-r text-[#1A2024] text-sm not-italic font-normal leading-6">
                  {item.contributionDue}
                </td>
                <td className="py-2 px-4 border-b border-gray-200 border-r text-[#1A2024] text-sm not-italic font-normal leading-6">
                  {currencyFormatterWithCountryCode(
                    `${item.currencyCode} ${item.amountDue}`
                  )}
                </td>
                <td className="py-2 px-4 border-b border-gray-200 border-r text-[#1A2024] text-sm not-italic font-normal leading-6">
                  {item.dateContributionReceived ?? "-"}
                </td>
                <td className="py-2 px-4 border-b border-gray-200 border-r text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
                  {currencyFormatterWithCountryCode(
                    `${item.currencyCode} ${item.amountReceived}`
                  )}
                </td>
                <td className="py-2 px-4 border-b border-gray-200 border-r text-[#1A2024] text-sm not-italic font-normal leading-6">
                  <span
                    className={`inline-block px-2 rounded-full text-xs font-semibold rtl:w-max ${
                      item.status =="Paid"
                        ? "border rounded-md border-solid border-[#B7D9B7] text-[#168617] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] flex items-center px-2 py-0  w-[2.7rem] text-center"
                        : "border bg-[#EEF0F2] rounded-md border-solid border-[#E5E9EB] text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] flex items-center px-2 py-0  w-10 text-center"
                    }`}
                  >
                    {item.status}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="flex justify-between items-center mt-4">
          <ul className="flex items-center -space-x-px text-base rounded-lg w-fit border bg-white rounded-md px-0 py-2 rtl:pl-[2px]">
            <li>
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={currentPage ==1}
                className="flex items-center justify-center px-4 ms-0 leading-tight text-gray-500 bg-white hover:text-gray-700 cursor-pointer"
              >
                <span className="sr-only">
                  {translate("Previous", "السابق")}
                </span>
                <svg
                  className="w-3 h-3 rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 1 1 5l4 4"
                  />
                </svg>
              </button>
            </li>
            {pages?.map((page, index) => (
              <li key={index}>
                {page =="..." ? (
                  <span className="text-xs px-1.5 py-1 leading-tight  text-gray-500 bg-white">
                    ...
                  </span>
                ) : (
                  <button
                    onClick={() => handlePageChange(page)}
                    className={`text-xs px-1.5 py-1 leading-tight ${
                      currentPage ==page
                        ? "text-[#252C32] text-xs not-italic font-normal leading-4 rounded bg-[#E5E9EB]"
                        : "text-[#252C32] text-xs not-italic font-normal leading-4 bg-white"
                    }`}
                  >
                    {page}
                  </button>
                )}
              </li>
            ))}
            <li>
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={currentPage ==totalPages}
                className="flex items-center justify-center px-4 h-10 leading-tight text-[#252C32] text-xs not-italic font-normal leading-4 bg-white hover:text-gray-700 cursor-pointer"
              >
                <span className="sr-only">{translate("Next", "التالي")}</span>
                <svg
                  className="w-3 h-3 rtl:rotate-180"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 6 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 9 4-4-4-4"
                  />
                </svg>
              </button>
            </li>
          </ul>
        </div>
      </>
    ) : (
      <div className="space-y-4">
        {historyData?.map((each, i) => (
          <div
            className="flex justify-between items-center  max767:w-full max767:items-start max767:px-0 max767:py-4 max767:border-b-[#D5DADD] max767:border-b max767:border-solid"
            key={i}
          >
            <div>
              <p className="font-semibold">
                {translate("My Savings -", "مدخراتي -")}
                <span className="font-normal">
                  {info?.PolicyDetails?.ProductName}
                </span>{" "}
              </p>
              <p className="text-[#5B6871] text-xs not-italic font-normal leading-4 mt-2">
                {each.dateContributionReceived}
              </p>
            </div>
            <div className="items-end text-right flex-col justify-end">
              <span
                className={` px-2 rounded-full text-xs font-semibold inline-block w-max ${
                  each.status =="Paid"
                    ? "border inline-table mb-2 rounded-md border-solid border-[#B7D9B7] text-[#168617] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] flex items-center px-2 py-0  w-[2.7rem] text-center"
                    : "border bg-[#EEF0F2] rounded-md border-solid border-[#E5E9EB] text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] flex items-center px-2 py-0  w-10 text-center"
                }`}
              >
                {each.status}
              </span>
              <p className="font-semibold">{currencyFormatterWithCountryCode(`${each.currencyCode} ${each.amountReceived}`)}</p>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
);

};


export default PaymentHistory;
