import React, { act } from "react";
import ClaimsStepper from "./claimsStepper"; // Assuming this is your stepper
import { 
  // ACTION_STATUS,
  //  ACTIONS, 
   stepperData } from "../data";
// import Button from "../../../../components/common/Button/Button";
// import CheckCircle from "../../../../assets/images/check_circle-claim.svg";
// import CancelIcon from "../../../../assets/images/cancelred.svg";
// import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";

const ClaimProgressBar = ({ steps, data, handleActions,activeStep }) => {
  // const translate = useLanguageTranslate();
  return (
    <div className="pl-0 bg-white p-6 rounded-lg mb-6 w-full max767:p-0 claimdetailsbox">
      <ClaimsStepper
        className="justify-start "
        activeStep={activeStep}
        steps={stepperData}
      />

      {/* {steps?.slice().map((step, index) => (
        <div key={index} className="relative flex items-start">
          <div className="pb-6">
            <div className="flex gap-3 items-center ipad">
              <span className="h-[4px] w-[4px] bg-[#B0BABF] rounded-lg"></span>
              <p className="text-[#5B6871] text-left text-xs not-italic font-normal leading-5">{`${step.date} ${step.time}`}</p>
            </div>
            <div className="pl-4 border-l-[#DDE2E4] border-l-2 border-solid">
              {step?.pillStatus && (
                <span
                  className={`pill-status justify-center rounded-xl text-white text-center text-xs not-italic font-semibold leading-4 tracking-[0.12px] uppercase mb-1 mt-1 ${step?.pillStatus}`}
                >
                  {step.pillStatus}
                </span>
              )}
              {step?.messages.map((message, idx) => (
                <p
                  key={idx}
                  className="text-black text-base not-italic font-normal leading-6"
                >
                  {message}
                </p>
              ))}

              {step?.pillStatus ==ACTION_STATUS.PAID && (
                <a
                  href=""
                  className="text-[#00384D] not-italic font-semibold leading-6 tracking-[-0.16px] underline"
                >
                  {translate("view receipt", "عرض الإيصال")}
                </a>
              )}
              {step?.actions &&
              (step?.actions?.action_en ==ACTIONS.TOTAL_LOSS ||
                step?.actions?.action_en ==ACTIONS.CASH_LOSS) ? (
                <div className="flex gap-2 max767:flex-col ipad:flex-col ">
                  <Button
                    label={translate("Reject", "رفض")}
                    className="mt-2 w-[146px] flex items-center justify-center gap-2.5 bg-[#fff] px-4 py-2 rounded-[10px] border border-solid border-[#DDE2E4] hover:bg-[#fff]"
                    onSubmit={() => handleActions("Reject")}
                  />
                  <Button
                    label={translate("Approve", "الموافقة")}
                    className="mt-2 w-[146px] flex items-center justify-center gap-2.5 bg-[#55C6EF] px-4 py-2 rounded-[10px]"
                    onSubmit={() => handleActions("Approve")}
                  />
                </div>
              ) : (
                step?.actions && (
                  <Button
                    label={translate(
                      step?.actions?.action_en,
                      step?.actions?.action_ar
                    )}
                    className="mt-2 w-[300px] flex items-center justify-center gap-2.5 bg-[#55C6EF] px-4 py-2 rounded-[10px]"
                    onSubmit={() => handleActions(step?.actions?.action_en)}
                  />
                )
              )}

              {(step?.actionStatus ==ACTION_STATUS.SUBMITTED ||
                step?.actionStatus ==ACTION_STATUS.OFFER_APPROVED) && (
                <div className="flex gap-2 mt-2">
                  <img src={CheckCircle} alt="" />
                  <span className="text-[#119C2B] text-sm not-italic font-semibold leading-5">
                    {step?.actionStatus}
                  </span>
                </div>
              )}
              {step?.actionStatus ==ACTION_STATUS.OFFER_REJECTED && (
                <div className="flex gap-2 mt-2">
                  <img src={CancelIcon} alt="" />
                  <span className=" text-sm not-italic font-semibold leading-5 text-[#CC0905]">
                    {step?.actionStatus}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      ))} */}
    </div>
  );
};

export default ClaimProgressBar;
