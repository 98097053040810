import React from 'react'
import closemodal from "../../../../src/assets/images/close.svg";
import './modal.css'
import useLanguageTranslate from '../../../hooks/useLanguageTranslate';

const BackdropModal = ({
  children,
  className,
  onClose,
  show,
  title,
  emergencyServiceData,
  hideClosebtn= false
}) => {
    const translate = useLanguageTranslate();
  if (!show) return null;
  return (
    <div className={`backdropModal  ${className}`}>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-40"></div>
      <div className="fixed inset-0 flex items-center justify-around z-50 p-4 max767:p-0 overflow-y-auto max767:z-[99999]">
        <div
          onClick={onClose}
          className="backdrop absolute z-9 w-full h-full"
        ></div>
        <div className="z-10 bg-white rounded-lg pt-5 pb-6 px-6 w-auto relative new-policy-modal max767:w-full max767:overflow-y-scroll max767:h-[90vh] max767:top-[8%] ipad:w-[85%] ipad:mt-[1%]">
          <div className="flex close-modal-icon justify-end pt-[7px] pb-4 px-0 w-full ipad:justify-end rtl:justify-end">
            {emergencyServiceData && emergencyServiceData.length > 0 && (
              <div className="flex items-center space-x-4 ml-2 max767:w-full max767:items-center max767:ml-0">
                <img
                  src={emergencyServiceData?.[0]?.data?.icon}
                  alt={translate(
                    emergencyServiceData?.[0]?.data?.altText_en,
                    emergencyServiceData?.[0]?.data?.altText_ar
                  )}
                  className="w-8 h-8 mr-0"
                />
                <h3 className="text-lg font-semibold">
                  {translate(
                    emergencyServiceData?.[0]?.data?.title_en,
                    emergencyServiceData?.[0]?.data?.title_ar
                  )}
                </h3>
              </div>
            )}
            {title && (
              <h2 className="title text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] rtl:text-right rtl:w-full ">
                {title || ""}
              </h2>
            )}
            {!hideClosebtn && (<button
              className="sticky top-0 right-0 text-gray-500 hover:text-gray-700 max767:bg-[#E5E9EB] max767:w-9 max767:h-9 max767:text-center max767:p-[5px] max767:rounded-[20px]"
              onClick={onClose}
            >
              <img src={closemodal} alt="Close icon" />
            </button>)}
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};

export default BackdropModal