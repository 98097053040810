import React from 'react';
import TableRow from './tableRow';
import useLanguageTranslate from '../../../../../../hooks/useLanguageTranslate';

const Table = ({
  data,
  headers,
  actionTitle = "Action",
  showActions = false,
  actionMenuClickHandler 
}) => {

  const translate = useLanguageTranslate()
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border-separate border rounded-[10px] border-spacing-0">
        <thead>
          <tr className="">
            {headers.map((header, index) => (
              <th key={index} className="border-b px-6 py-2.5 text-left text-[#6E7C87] text-xs not-italic font-semibold leading-6 tracking-[-0.072px] rtl:text-right">
                {translate(header.label,header.label_ar)}
              </th>
            ))}
            {showActions && <th className='border-b text-sm p-2 text-left text-gray-600 font-medium rtl:text-right'>{actionTitle}</th>}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <TableRow
              headers={headers}
              row={row}
              key={rowIndex}
              rowIndex={rowIndex}
              showActions={showActions}
              actionMenuClickHandler={actionMenuClickHandler}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
