import React from "react";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import filterbkimg from "../../assets/images/Rectangleblurbk.svg"
const OnboardingSlider = ({ title, description, image }) => {
  const translate = useLanguageTranslate();

  return (
    <div className="flex flex-col items-center justify-center">
      {image && (
        <div className="relative w-full max-w-md h-[450px] ">
         
          <img src={translate(image.srcEn,image.srcAr)} alt={translate(image.altEn,image.altAr)} className="w-full" />
          <img src={filterbkimg} alt="" className="absolute top-[213px] inset-x-0 mx-auto my-0" />
        </div>
      )}

      <div className="slide-content px-5 py-0 z-10">
        <p className=" text-black text-base not-italic font-medium mb-6">{description}</p>

        <h1 className="text-black text-[28px] not-italic font-semibold leading-[120%] mb-[64px]">
          {translate(title.title_en, title.title_ar)}
        </h1>
      </div>
    </div>
  );
};

export default OnboardingSlider;
