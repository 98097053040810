import React, { useEffect, useMemo, useState } from "react";
import FormTabs from "../../../components/common/tabs/formTabs";
import { useTranslation } from "react-i18next";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import useValidation from "../../../hooks/useValidation";
import { useSelector } from "react-redux";
import ReviewYourApplication from "../details/reviewApplication";
import useLifeFormData from "../../../hooks/useLifeFormData";
import useUpdateLead from "../../../hooks/BuyOnlineExpressShield/useUpdateLead";
import { LEAD_INFO } from "../details/data";
import EssentialCareLayout from "../essentialCareLayout";
import { selectEssentialCareAllPagesDataCms } from "../../../redux/features/essentialCare/essentialCareStrapiCmsSlice";
import Button from "../../../components/common/Button/Button";
import ViewEssentialCareBenefitsDetailsPopup from "../viewEssentialCareBenefitsDetailsPopup";
import listblackicon from "../../../assets/images/list-black.svg";
import { removeKeysFromObject } from "../../../utils";

export const yesNoTabs = [
  { label_en: "Yes", label_ar: "نعم", type: "Yes" },
  { label_en: "No", label_ar: "لا", type: "No" },
];
export const ESSENTIAL_CARE_DECLERATION = "ESSENTIAL_CARE_DECLERATION";
const Declaration = ({ onSuccess }) => {
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const {updateLeadHandler,leadLoader} = useUpdateLead()


  const [formData, setFormData] = useState({
    answers: {},
  });
      const [viewPlanList, setViewPlanList] = useState(false);

  const { saveLifeDataInRedux } = useLifeFormData(
    ESSENTIAL_CARE_DECLERATION,
    setFormData
  );
  const cmsData = useSelector(selectEssentialCareAllPagesDataCms);
  const [invalidDataPopup, setInvalidDataPopup] = useState(false);

  const { errors, validateFields } = useValidation();
  const validationSchemaGenerate = useMemo(() => {
    let schema = cmsData?.questionnaire_questions?.map((question) => ({
      name: `${question.mapping_key}`,
      validations: ["required"],
    }));
    return [...schema];
  }, [cmsData?.questionnaire_questions]);

  const validateForm = (data) => {
    let form_data = data || formData;

    let isValid = validateFields(validationSchemaGenerate, form_data);
    return isValid;
  };


  const onTabClick = (value, questionId) => {
    let update = { ...formData.answers, [questionId]: value };
    setFormData((prev) => ({
      ...prev,
      answers: update,
    }));
    if (errors[questionId]) {
      validateForm(update);
    }
  };

  const declarationSuccess = () => {
        saveLifeDataInRedux(formData)
        updateLeadHandler({
          Info: LEAD_INFO.questionnaire,
        })
        onSuccess();
      
  }
  console.log(formData.answers)
  const nextClickHandler = async () => {
    if (validateForm(formData.answers)) {
      let removeOptionals = removeKeysFromObject(formData.answers,["Martialstatus"])
      const hasYesAnswer = Object.values(removeOptionals).some(
        (answer) => answer === "Yes"
      );

      if (hasYesAnswer) {
        setInvalidDataPopup(true);
      } else {
          declarationSuccess();
        
      }
    }
  };


  const toggleInvalidDataPopup = () => {
    setInvalidDataPopup(!invalidDataPopup);
  };

  return (
    <EssentialCareLayout is_bottom_sticky_layout btn_onClick={nextClickHandler}>
      <div className="w-[500px] max-w-[100%] mx-auto">
        <TitleDescription
          title={translate(
            cmsData?.questionnaire_headings?.title_en,
            cmsData?.questionnaire_headings?.title_ar
          )}
          description={translate(
            cmsData?.questionnaire_headings?.description_en,
            cmsData?.questionnaire_headings?.description_ar
          )}
        />

        {cmsData?.questionnaire_questions?.map((question) => (
          <div key={question.id} className="mb-6">
            <FormTabs
              label={translate(question.content_en, question.content_ar)}
              tabs={yesNoTabs}
              onTabClick={(type) => onTabClick(type, question.mapping_key)}
              activeTab={formData.answers[question.mapping_key]}
              error={errors?.[question?.mapping_key]}
            />
          </div>
        ))}
        <div className="mx-auto flex justify-center EssentialCare">
        <Button
          className="flex items-center gap-2 border bg-white px-4 py-2 rounded-[10px] border-solid border-[#DDE2E4] text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] ipad:mt-4"
          onSubmit={() => setViewPlanList(true)}
          pre_icon={listblackicon}
          label={translate("Essential Care benefits & covers","مزايا وتغطيات الرعاية الأساسية")}
        />
        </div>
        <div className="ViewEssentialCareBenefitsDetailsPopup">
        <ViewEssentialCareBenefitsDetailsPopup
          viewPlanList={viewPlanList}
          closePopup={() => setViewPlanList(false)}
        />
        </div>
        <div className="EssentialReviewYourApplication">
        <ReviewYourApplication
          type="answeredYes"
          show={invalidDataPopup}
          onClose={toggleInvalidDataPopup}
        />
        </div>
       
      </div>
    </EssentialCareLayout>
  );
};

export default Declaration;
