import React, { useState } from "react";
import "./profileSettings.css";
import { Link } from "react-router-dom";
import Chevron from "../../components/common/chevron/chevron";
import ToggleSwitch from "../../components/common/toggleSwitch/toggleSwitch";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import { useTranslation } from "react-i18next";
import righticon from "../../assets/images/Chevronright.svg";
import yourprofileicon from "../../assets/images/person_outline.svg";
import passwordicon from "../../assets/images/lock_outline.svg";
import Securityicon from "../../assets/images/verified_user.svg";
import { useSelector } from "react-redux";
import {
  selectProfileDataLoader,
} from "../../redux/features/profileSlice";
import Loader from "../../components/common/Loader/Loader";
import ProfileDetails from "./profileDetails";
import ChangePassword from "./changePassword";

const ProfileSettings = () => {
  const { t } = useTranslation();
  const [formState, setFormState] = useState({
    twoFactorAuth: false,
  });
  const translate = useLanguageTranslate();
  const isLoading = useSelector(selectProfileDataLoader);

    const updateToggle = (field) => {
      setFormState((prev) => ({
        ...prev,
        [field]: !prev[field],
      }));
    };

  return (
    <div className="profile-setting-container p-5 ipad:p-4 max767:p-5 max767:mt-20">
      {isLoading && <Loader />}
      <div className="profileSettings flex justify-between">
        <div className="breadcrump flex align-middle gap-10">
          <ul className="inline-flex gap-2">
            <li className="inline-flex">
              <Link
                to="/"
                className="text-xs not-italic font-normal leading-4 text-[#6E7C87]"
              >
                {t("commonLabels.home_label")}
              </Link>{" "}
              <span className="ml-2 rtl:ml-0 rtl:mr-2">
                <img src={righticon} alt="right icon" className="rtl:rotate-180"/>
              </span>
            </li>
            <li className="bread-active text-xs not-italic font-normal leading-4 inline-flex text-black">
              {t("profileSettings.profile_settings")}
            </li>
          </ul>
        </div>
        <p className="hidden last-login text-xs not-italic font-normal leading-4 text-gray-700">
          {t("commonLabels.last_login_label")} 03 November 2024 at 05:20
        </p>
      </div>
      <div className="content">
        <h1 className="text-2xl not-italic font-semibold leading-8 mx-0 my-[35px]">
          {translate("Profile Settings", "إعدادات الملف الشخصي")}
        </h1>
        <div className="yourProfileSection p-4 border-b-1 flex flex-col gap-4 self-stretch px-0 py-6 w-[66%] border-b-[color:var(--Light-Gray-Light-Gray-2,#DDE2E4)] border-b border-solid ipad:w-full max767:w-full">
          <Chevron
            className=""
            isDefaultOpen={false}
            Header={
              <div className="flex align-middle items-start gap-2 w-full">
                <img src={yourprofileicon} alt="profile icon" />
                <h1 className="text-[#5B6871] text-base not-italic font-semibold leading-6">
                  {t("profileSettings.your_profile")}
                </h1>
              </div>
            }
          >
            <ProfileDetails />
          </Chevron>
        </div>
        <div className="passwordSection p-4 mt-6 mb-6 border-b-1 flex flex-col gap-4 self-stretch px-0 py-6 w-[66%] border-b-[color:var(--Light-Gray-Light-Gray-2,#DDE2E4)] border-b border-solid ipad:w-full max767:w-full">
          <Chevron
            className=""
            isDefaultOpen={false}
            Header={
              <div className="flex align-middle flex items-start gap-2 w-full rtl:pr-0">
                <img src={passwordicon} alt="lock icon" />
                <h1 className="text-[#5B6871] text-base not-italic font-semibold leading-6">
                  {t("profileSettings.password")}
                </h1>
              </div>
            }
          >
            <ChangePassword />
          </Chevron>
        </div>
        <div className="securitySection securitySection p-4 border-b-1 flex flex-col gap-4 self-stretch px-0 py-6 w-[66%] border-b-[color:var(--Light-Gray-Light-Gray-2,#DDE2E4)] border-b border-solid ipad:w-full max767:w-full">
          <Chevron
            className=""
            isDefaultOpen={false}
            Header={
              <div className="flex align-middle flex items-start gap-2 w-full">
                <img src={Securityicon} alt="lock icon" />
                <h1 className="text-[#5B6871] text-base not-italic font-semibold leading-6">
                  {t("profileSettings.security")}
                </h1>
              </div>
            }
          >
            <div className="yourProfile py-6">
              <div className="flex  justify-between">
                <div className="leftCont ">
                  <p className="text-[#000)] text-base not-italic font-semibold  mb-2 leading-[120%]">
                    {t("profileSettings.two_factor_authentication")}
                  </p>
                  <p className="text-[#000)] text-base not-italic font-normal  leading-[120%]">
                    {t("profileSettings.yes_no_btn")}
                  </p>
                </div>
                <ToggleSwitch
                  isOn={formState.twoFactorAuth}
                  onChange={() => updateToggle("twoFactorAuth")}
                />{" "}
              </div>
            </div>
          </Chevron>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
