import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentStepIndex,
  selectCurrentSubstepIndex,
  selectFlowType,
  setCurrentStepIndex,
  setCurrentSubstepIndex,
  setFlowType,
} from "../../redux/features/essentialCare/essentialCareFlowSlice";
import { essential_care_flow } from "../../pages/essentialCare/data";

const useEssentialCareFlow = () => {
  const dispatch = useDispatch();
  const flowType = useSelector(selectFlowType);
  const currentStepIndex = useSelector((state) =>
    selectCurrentStepIndex(state)
  );
  const currentSubstepIndex = useSelector((state) =>
    selectCurrentSubstepIndex(state)
  );

  const steps = essential_care_flow[flowType] || [];
  const currentStep = steps[currentStepIndex] || {};
  const currentSubstep = currentStep.substeps
    ? currentStep.substeps[currentSubstepIndex]
    : "";

  const handleFlowChange = (type, currentStepIndex, currentSubstepIndex) => {
    dispatch(setFlowType({ type, currentStepIndex, currentSubstepIndex }));
  };

  const handleNextSubstep = () => {
    if (
      currentStep.substeps &&
      currentSubstepIndex < currentStep.substeps.length - 1
    ) {
      dispatch(setCurrentSubstepIndex(currentSubstepIndex + 1));
    } else if (currentSubstepIndex ==currentStep.substeps.length - 1) {
      handleNextStep();
    }
  };

  const handlePrevSubstep = () => {
    if (currentSubstepIndex > 0) {
      dispatch(setCurrentSubstepIndex(currentSubstepIndex - 1));
    } else {
      handlePrevStep();
    }
  };

  const handleNextStep = () => {
    if (currentStepIndex < steps.length - 1) {
      dispatch(setCurrentStepIndex(currentStepIndex + 1));
    }
  };

  const handlePrevStep = () => {
    if (currentStepIndex > 0) {
      const currentStep = steps[currentStepIndex - 1] || {};
      const substepIndex = currentStep?.substeps?.length - 1;
      dispatch(setCurrentStepIndex(currentStepIndex - 1));
      dispatch(setCurrentSubstepIndex(substepIndex));
    }
  };

  return {
    handleFlowChange,
    handleNextSubstep,
    handlePrevSubstep,
    handleNextStep,
    handlePrevStep,
    flowType,
    steps,
    currentStep,
    currentStepIndex,
    currentSubstep,
    currentSubstepIndex,
  };
};

export default useEssentialCareFlow;
