import React from 'react'
import ReviewAndConfirmPage from './reviewAndConfirmPage/reviewAndConfirmPage';
import useAdjustSumInsuredFlow from '../../../hooks/useAdjustSumInsured';
import AdjustSumValue from './adjustSumValue/adjustSumValue';
import { ADJUST_SUM_INSURED_FLOW_ADJUST_VALUE, ADJUST_SUM_INSURED_FLOW_REVIEW_AND_CONFIRM } from './data';

const AdjustSumInsuredMotorPolicy = () => {
    const {currentStep} = useAdjustSumInsuredFlow()

    const PageRenderer = () => {
        switch (currentStep?.name) {
            case ADJUST_SUM_INSURED_FLOW_ADJUST_VALUE:
                return <AdjustSumValue />
            case ADJUST_SUM_INSURED_FLOW_REVIEW_AND_CONFIRM:
                return <ReviewAndConfirmPage />
            default:
                return null
        }
    };
    return (
        <div className="mainCarDetailsModal pt-[5%] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll ">
            <PageRenderer />
        </div>
    )
}

export default AdjustSumInsuredMotorPolicy;