import { useTranslation } from "react-i18next";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import EssentialCareLayout from "../essentialCareLayout";
import { useDispatch, useSelector } from "react-redux";
import { selectEssentialCareAllPagesDataCms } from "../../../redux/features/essentialCare/essentialCareStrapiCmsSlice";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import description from "../../../../src/assets/images/description.svg";
import Exclamation from "../../../assets/images/warning_amber.svg";
import { selectProfileData } from "../../../redux/features/profileSlice";
import { LEAD_INFO } from "./data";
import useUpdateLead from "../../../hooks/BuyOnlineExpressShield/useUpdateLead";
import { createLead } from '../../../redux/features/expressSheild/leadSlice';

const DocsReqd = ({ onSuccess }) => {
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const cmsData = useSelector(selectEssentialCareAllPagesDataCms);
  const profileData = useSelector(selectProfileData);
  const { updateLeadHandler, leadLoader, } = useUpdateLead()
  const dispatch = useDispatch()

  console.log(profileData, 'some profile data')
  let values = {}
  const btnClickHandler = async () => {
    if (!profileData) {
      let payload = {
        Info: `${LEAD_INFO.docsInfo}`,
      }
      updateLeadHandler(payload)
    }
    else {
      let payload = {
        FirstName : profileData?.FirstName,
        LastName : profileData?.LastName,
        CPR: profileData?.CPR,
        PhoneNumber: profileData?.PhoneNumber || "",
        subject: LEAD_INFO.docsInfo,
        Product: "LIFE",
        Email: "",
        customerType: 1,
        status: 1,
        productType: "Life",
        stage: "",
        lead_id: "",
        Quote: "",
        channel: "online.solidarity",
        LOBProduct: "Essential Health Care"
      };
      const response = await dispatch(createLead(payload))
    }
    onSuccess()
  }

  return (
    <EssentialCareLayout
      is_bottom_sticky_layout
      btn_onClick={btnClickHandler}
      show_close_btn={!!profileData}
      is_hide_back_btn={!!profileData}
      isLoading={leadLoader}
    >
      <div className="w-[500px] max-w-[100%] max767:w-full mx-auto ipad:w-[60%]">
        <TitleDescription
          className="mb-6"
          title={translate(
            cmsData?.doc_ready_headings?.title_en,
            cmsData?.doc_ready_headings?.title_ar
          )}
          description={translate(
            cmsData?.doc_ready_headings?.description_en,
            cmsData?.doc_ready_headings?.description_ar
          )}
        />

        <div className="flex flex-col items-start gap-1 bg-[#EDFAFF] px-10 py-6 rounded-[20px] mt-3">
          <h2 className="text-base not-italic font-semibold">
            {translate(
              cmsData?.doc_ready_alert_msg?.title_en,
              cmsData?.doc_ready_alert_msg?.title_ar
            )}
          </h2>
          <span className="text-sm not-italic font-normal">
            {translate(
              cmsData?.doc_ready_alert_msg?.description_en,
              cmsData?.doc_ready_alert_msg?.description_ar
            )}
          </span>
          <span className="text-sm not-italic font-semibold">
            {translate(
              cmsData?.doc_ready_alert_msg?.subtitle_en,
              cmsData?.doc_ready_alert_msg?.subtitle_ar
            )}
          </span>
        </div>
        <div className="infoCard documents-section bg-gray flex flex-col items-start gap-2 self-stretch bg-[#F6F8F9] p-4 rounded-2xl mt-[24px]">
          <div className="flex align-middle gap-2">
            <h3 className="documents-title text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px] flex gap-2">
              <img src={description} alt="docs" />
              {translate("Documents", "المستندات")}
            </h3>
          </div>
          <ul className="documents-list pl-12 rtl:pr-12 max767:pl-[24px]">
            {cmsData?.doc_ready_docs_list?.map((doc, index) => (
              <li
                className=" gap-2 mb-2 inline-flexflex align-middle text-[#48535B] text-sm not-italic font-normal leading-5 tracking-[-0.14px]"
                key={index}
              >
                <span>{translate(doc.title_en, doc.title_ar)}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="imp-section infoCard  bg-gray flex flex-col items-start gap-2 self-stretch bg-[#F6F8F9] p-4 rounded-2xl mt-[16px]">
          <div className="flex align-middle gap-2 w-full mb-2.5">
            <h3 className="documents-title text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px] flex gap-2">
              <img src={Exclamation} alt="alert" />
              {translate("Important Notes", "المستندات")}
            </h3>
          </div>
          <ul className="documents-list pl-12 rtl:pr-12 max767:pl-[24px]">
            {cmsData?.doc_ready_imp_notes?.map((doc, index) => (
              <li
                className=" gap-2 mb-1 inline-flexflex align-middle text-[#48535B] text-sm not-italic font-normal leading-5 tracking-[-0.14px]"
                key={index}
              >
                <span>{translate(doc.content_en, doc.content_ar)}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </EssentialCareLayout>
  );
};

export default DocsReqd;
