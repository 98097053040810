import React, { useState } from 'react'
import PopoverComponent from '../../../../../../components/common/popover/PopoverComponent'
import iconmenu from '../../../../../../assets/images/Iconbutton.svg'
import editicon from '../../../../../../assets/images/edit.svg'
import deleteicon from '../../../../../../assets/images/delete.svg'
import useLanguageTranslate from '../../../../../../hooks/useLanguageTranslate'
import { DELETE_ACTION, EDIT_ACTION } from './tableRow'

const MobileBox = ({each , actionMenuClickHandler}) => {
    const [showPopover, setShowPopover] = useState(false)
    const hidePopover = () => {
        setShowPopover(false)
    }
    const openPopover = () => {
        setShowPopover(state => !state)
    }
    const translate = useLanguageTranslate()
    return (
        <div className="beneficiaryCard flex-col justify-center items-start gap-2 self-stretch border bg-white p-4 rounded-[10px] border-solid border-[#B0BABF] max767:mb-2">
            <div className='editpopover'>
                <div className='  w-full'>
                <PopoverComponent
                    headerMenu={
                     <div className='flex w-full justify-between'><p className="text-[#1A2024] text-base not-italic font-medium leading-[120%] flex">{each.fullName}</p> <div onClick={openPopover}><img src={iconmenu} alt="icon menu" className='flex' /></div></div>  
                    }
                    positions={["bottom"]}
                    isPopoverOpen={showPopover}
                    closer={hidePopover}
                    popupContent={
                        <div className='inline-flex flex-col w-64 items-start gap-2 bg-white shadow-[0px_0px_1px_0px_rgba(26,32,36,0.32),0px_12px_24px_0px_rgba(91,104,113,0.24)] p-2 rounded-[10px] -left-40 top-2.5'>
                            {<button className='flex gap-2 py-2 w-full hover:bg-[#F6F8F9] rounded-md' onClick={() => actionMenuClickHandler(each, EDIT_ACTION)}>
                                <img src={editicon} alt="" />
                                <span className='text-[#48535B] text-sm not-italic font-normal leading-6'> {translate("Edit Details","تعديل التفاصيل")}</span>
                            </button>}
                            {<button className='flex gap-2 py-2 w-full hover:bg-[#F6F8F9] rounded-md' onClick={() => actionMenuClickHandler(each, DELETE_ACTION)}>
                                <img src={deleteicon} alt="" />
                                <span className='text-[#CC0905] text-sm not-italic font-normal leading-6'>  {translate("Remove beneficiary","حذف المستفيد")}</span>
                            </button>}
                        </div>
                      
                    }
                />
                  </div>
            </div>
            
            <div className="flex justify-between w-[100%] mt-4">
                <p className='text-[#5B6871] w-[40%] text-left text-sm not-italic font-normal leading-[120%] rtl:text-right'>{translate("Share Percentage","نسبة الحصة")}</p>
                <p className='text-[#1A2024] w-[60%] text-sm text-left not-italic font-normal leading-[120%] rtl:text-right'>{each.share_percentage}</p>
            </div>
            <div className="flex justify-between w-[100%] mt-2">
                <p className='text-[#5B6871] w-[40%] text-left text-sm not-italic font-normal leading-[120%] rtl:text-right'>{translate("Relationship","العلاقة")}</p>
                <p className='text-[#1A2024] w-[60%] text-sm  text-left not-italic font-normal leading-[120%] rtl:text-right'>{each.relation_ship}</p>
            </div>
            <div className="flex justify-between w-[100%] mt-2">
                <p className='text-[#5B6871] w-[40%] text-left text-sm not-italic font-normal leading-[120%] rtl:text-right'>{translate("Mobile Number","رقم الهاتف المحمول")}</p>
                <p className='text-[#1A2024] w-[60%] text-sm  text-left not-italic font-normal leading-[120%] rtl:text-right'>{each.mobile_number}</p>
            </div>
        </div>
    )
}

export default MobileBox