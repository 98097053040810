import React, { useMemo, useEffect } from "react";
import ServiceGrid from "../../components/common/serviceCards/serviceCards";
import {
  FILE_A_CLAIM,
  fileClaimFaqsData,
  quickActions,
  TRACK_A_CLAIM,
  allClaims,
} from "./data";
import { useNavigate } from "react-router-dom";
import ActiveClaimCard from "./activeClaimCard";
import { useDispatch, useSelector } from "react-redux";
import FaqBox from "../../components/common/faq/faq";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import { selectLoginTokenInfo } from "../../redux/features/loginRegisterSlice";
import {
  selectCrmClaimsLoading,
  getCrmClaims,
  selectCrmClaims,
} from "../../redux/features/crm/crmClaimSlice";
import { FAQS_TYPES } from "../dashboard/data";
import { selectFaqsData } from "../../redux/features/faqsSlice";
import SkeletonLoader from "../../components/common/Loader/SkeletonLoader";

const FileClaim = () => {
  const navigate = useNavigate();
  const translate = useLanguageTranslate();
  const serviceClickHandler = (action_type) => {
    if (action_type ==FILE_A_CLAIM) {
      navigate("/motor-insurance/file-new-claim");
    } else if (action_type ==TRACK_A_CLAIM) {
      navigate("/motor-insurance/track-claim");
    }
  };
  const loginTokenInfo = useSelector(selectLoginTokenInfo);
  const crmClaims = useSelector(selectCrmClaims);
  const dispatch = useDispatch();
  const IsLoading = useSelector(selectCrmClaimsLoading);

  useEffect(() => {
    if (loginTokenInfo) {
      const payload = {
        Cpr: loginTokenInfo?.userName,
        Channel: "online.solidarity",
      };
      dispatch(getCrmClaims(payload)); // Use the correct action creator
    }
  }, [loginTokenInfo, dispatch]);

  const faqs = useSelector(selectFaqsData);
  const fileClaimFaqs = useMemo(() => {
    let data = faqs?.data?.find(
      (each) => each?.attributes?.type ==FAQS_TYPES.claim
    );

    let format = data?.attributes?.c_p_faqs?.data?.map((each) => ({
      question_en: each?.attributes?.question?.title_en,
      question_ar: each?.attributes?.question?.title_ar,
      answer_en: each?.attributes?.answer?.content_en,
      answer_ar: each?.attributes?.answer?.content_ar,
    }));
    return format || [];
  }, [faqs]);
  return (
    <div className="max767:pb-32 ipad:p-4">
      <div className="flex flex-col p-5">
        <h1 className="text-black text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] pl-0 mt-0 max767:mt-24">
          {translate("Quick Actions", "الإجراءات السريعة")}
        </h1>
        <div className="fileclaim-box">
          <ServiceGrid
            services={quickActions}
            serviceClickHandler={serviceClickHandler}
          />
        </div>
      </div>
      {loginTokenInfo && (
        <div className="max767:p-4 p-5">
          <div className="justify-between flex pt-0 mt-0 max767:mt-0 max767:pt-2">
            <h1 className="text-black text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] pl-0 max767:pl-0">
              {translate("Claims", "المطالبات")}
            </h1>
            {/* <span className="flex flex-col items-center gap-2.5 border bg-white px-3 py-1 rounded-md text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:border-0">
              {translate("View Closed Claims", "عرض المطالبات المغلقة")}
            </span> */}
          </div>
          <div className="grid items-start gap-4 pl-0 pr-0 pb-0 pt-3 max767:flow-root max767:pl-0 grid-cols-3 w-[1200px] max-w-[100%] ipad:grid-cols-2">
            {IsLoading && !crmClaims?.length && <SkeletonLoader />}
            {crmClaims?.length > 0
              ? crmClaims?.map((each, index) => (
                  <ActiveClaimCard
                    key={each.CaseNumber || index}
                    claim={each}
                  />
                ))
              : !IsLoading && (
                  <p>{translate("No claims available.", "لا توجد مطالبات.")}</p>
                )}
          </div>
        </div>
      )}
      <div className="flex flex-col p-4 w-[700px] max-w-full p-5">
        <h1 className="text-black text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mt-4 mb-4">
          {translate("FAQs","الأسئلة الشائعة")}
        </h1>
        {fileClaimFaqs?.map((each) => (
          <FaqBox
            question={translate(each.question_en, each.question_ar)}
            answer={translate(each.answer_en, each.answer_ar)}
          />
        ))}
      </div>
    </div>
  );
};

export default FileClaim;
