import React from "react";
import { Line, Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js";
import { dateFormatter } from "../../../../utils";
import useScreenResolution from "../../../../hooks/useScreenResolution";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";

// Register the necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
  Title
);

const FundPerformanceCharts = ({ info }) => {
  const { mobile } = useScreenResolution();
  const translate = useLanguageTranslate();
  const line = {
    labels: [
      dateFormatter(
        info?.PolicyDetails?.StartDate,
        "MMM yyy",
        translate("en", "ar")
      ),
      dateFormatter(new Date(), "MMM yyy", translate("en", "ar")),
    ],
    datasets: [
      {
        label: translate("Policy Value", "قيمة وثيقة التأمين"),
        data: [], // Replace with actual data
        borderColor: "#F7931D",
        backgroundColor: "#F7931D",
      },
      {
        label: translate("Premium Invested", "القسط المستثمر"),
        data: [
          parseFloat(
            info?.LifeDetails?.policyList?.[0]?.totalPremiumInvested?.split(
              " "
            )[1]
          ) || null,
          parseFloat(
            info?.LifeDetails?.policyList?.[0]?.currentValue?.split(" ")[1]
          ) || null,
        ],
        borderColor: "#0C4A0D",
        backgroundColor: "#0C4A0D",
      },
    ],
  };
  const lineData = {
    labels: line.labels,
    datasets: line?.datasets?.map((dataset) => ({
      label: dataset.label,
      data: dataset.data,
      borderColor: dataset.borderColor,
      backgroundColor: dataset.backgroundColor,
      fill: false,
      pointStyle: "circle",
      pointRadius: 5,
      pointHoverRadius: 7,
    })),
  };

  const lineOptions = {
    animation: false,
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          usePointStyle: true,
        },
        align: "left",
      },
    },

    scales: {
      x: {
        grid: {
          display: false, // Hide the vertical lines
        },
        border: {
          display: false, // Hide the X-axis line
        },
        ticks: {
          display: true, // Display the X-axis labels
          padding: 20, // Add space between X-axis labels and chart lines
        },
      },
      y: {
        grid: {
          display: true, // Show the horizontal lines
          drawBorder: false, // Hide the border
        },
        title: {
          display: true,
          text: translate("BHD", "دينار بحريني"),
        },
        ticks: {
          callback: function (value) {
            if (value == 0) return "0";
            if (value < 1000 && value > -1000) return value;
            return `${value / 1000}k`;
          },
          padding: 20,
        },
        border: {
          display: false, // Hide the Y-axis line
        },
      },
    },
  };

  const pieChartData = info?.LifeDetails?.policyList?.[0].funds
    ?.filter((each) => each.allocationPercentage != "0")
    ?.map((each) => ({
      label: `${each.fundDetails} (${each.allocationPercentage}%)`,
      data: each.allocationPercentage,
    }));

  const pieChartLabels = pieChartData?.map((each) => each.label);
  const pieChartValues = pieChartData?.map((each) => each.data);

  const doughnutData = {
    labels: pieChartLabels,
    datasets: [
      {
        label: "Current Fund Holdings",
        data: pieChartValues,
        backgroundColor: [
          "#09380A",
          "#311D06",
          "#105F10",
          "#7C4A0F",
          "#168617",
          "#A56213",
          "#63AE64",
          "#F7931D",
          "#94C794",
          "#FBC98E",
        ],
        cutout: "50%",
      },
    ],
  };

  const doughnutOptions = {
    animation: false,
    plugins: {
      legend: {
        position: mobile ? "bottom" : "right",
      },
      tooltip: {
        enabled: true, // Enable tooltips if needed
      },
    },
  };

  return (
    <div className="flex gap-4 w-full mt-4 max767:flex-col fundperformencechart">
      <div className="flex w-1/2 bg-[#FBF7F0] rounded-2xl p-5 max767:w-full ipad:w-full ipad:h-auto max767:rounded-[10px]">
        <div className="flex flex-col items-center  w-full">
          <h2 className="text-[#1A2024] text-xs not-italic font-semibold leading-[normal] mb-4">
            {translate("Current Value History", "سجل القيمة الحالية")}{" "}
            <span className="text-[#1A2024] text-xs not-italic font-normal leading-[normal]">
              ({translate("since start date", "منذ تاريخ البدء")})
            </span>
          </h2>
          <div className="flex flex-col items-center w-full">
            <div className="w-full">
              <Line data={lineData} options={lineOptions} />
            </div>
          </div>
        </div>
      </div>
      {pieChartData?.length > 0 && (
        <div className="flex w-1/2 bg-[#FBF7F0] rounded-2xl p-5 max767:w-full  ipad:w-full max767:rounded-[10px]">
          <div className="flex flex-col items-center w-full ">
            <h2 className="text-[#1A2024] text-xs not-italic font-semibold leading-[normal] mb-4">
              {translate("Current Fund Holdings", "حيازات الصندوق الحالية")}{" "}
              <span className="text-[#1A2024] text-xs not-italic font-normal leading-[normal]">
                ({translate("since start date", "منذ تاريخ البدء")})
              </span>
            </h2>
            <div className="flex items-center w-full circlechart ipad:h-auto ">
              <Doughnut data={doughnutData} options={doughnutOptions} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FundPerformanceCharts;
