import React from "react";
import Declaration from "./declaration";
// import BeneficiaryDetails from "./beneficiaryDetails";
import useEssentialCareFlow from "../../../hooks/essentialCare/useEssentialCare";
import FamilyMembersDetails from "./familyMmeberDetails";
import PersonalDetails from "./personalDetails";

const Plan = () => {
  const { currentSubstep, handleNextSubstep } = useEssentialCareFlow();
  const onSuccess = () => {
    handleNextSubstep();
  };
  return (
    <>
      {currentSubstep =="personal_details" && <PersonalDetails onSuccess={onSuccess} />}
      {currentSubstep =="declaration" && <Declaration onSuccess={onSuccess} />}
      {currentSubstep =="beeficiary_details" && (
        <FamilyMembersDetails onSuccess={onSuccess} />
      )}
    </>
  );
};

export default Plan;
