export const TYPE_CONSERVATIVE = "Conservative";
export const TYPE_BALANCED = "Balanced";
export const TYPE_GROWTH = "Growth";

export const INVESTMENT_STRATEGIES = [
    {
        strategyName: "Conservative",
        ar_strategyName: "محافظ",
        description: "Low-risk strategy focusing on capital preservation and steady returns via high-quality bonds.",
        ar_description: "استراتيجية منخفضة المخاطر تركز على الحفاظ على رأس المال وعوائد مستقرة من خلال السندات عالية الجودة.",
        id: "Investment strategy to Conservative",
        type: TYPE_CONSERVATIVE
    },
    {
        strategyName: "Balanced",
        ar_strategyName: "متوازن",
        description: "Balanced strategy seeking growth and income through a diversified mix of stocks and bonds.",
        ar_description: "استراتيجية متوازنة تسعى إلى النمو والدخل من خلال مزيج متنوع من الأسهم والسندات.",
        id: "Investment strategy to Balanced",
        type: TYPE_BALANCED
    },
    {
        strategyName: "Growth",
        ar_strategyName: "نمو",
        description: "Aggressive strategy aiming for significant capital appreciation by investing in high-growth equities.",
        ar_description: "استراتيجية هجومية تهدف إلى زيادة رأس المال بشكل كبير من خلال الاستثمار في الأسهم عالية النمو.",
        id: "Investment strategy to Growth",
        type: TYPE_GROWTH

    }
];
