import React from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./serviceCards.css";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import useScreenResolution from "../../../hooks/useScreenResolution";

const ServiceCard = ({ icon, micon, text, handleAction, type, hideInMobile }) => (
  <div
    className={`service-cards w-full flex h-[120px] flex-col justify-between items-start flex-[1_0_0] border bg-[#F6F8F9] p-6 rounded-[10px] border-solid border-[#DDE2E4] max767:w-[110px]  max767:flex-col  max767:items-center  max767:gap-0  max767:p-4 max767:fill-current max767:text-blue-500 max767:border-0 hover:cursor-pointer max767:h-[93px] ${hideInMobile ? "hidden": ""}`}
    onClick={() => handleAction(type)}
  >
    <div className=" rounded-full mb-4 max767:mb-0 ipad:mb-0">
      {/* <FontAwesomeIcon icon={icon} size="lg" /> */}
      <img src={icon} alt="" className="max767:hidden"/>
      <img src={micon} alt="" className="hidden max767:block h-9 w-9" />
    </div>
    <div className="max767:mt-2 service-title text-[#1A2024] text-xl not-italic font-medium leading-[120%] ipad:text-base max767:text-sm max767:text-[#1A2024]">
      {text}
    </div>
  </div>
);

const ServiceGrid = ({ services, className, serviceClickHandler }) => {
  const translate = useLanguageTranslate();
  const {mobile} = useScreenResolution()

  return (
    <div
      className={`flex justify-around policy-cards gap-4 pl-0 max767:pl-0 rtl:pr-0 pt-[8px] pb-0 px-0 max767:gap-2 max767:grid-cols-3 ${className}`}
    >
      {services.map((service) => (
        <ServiceCard
          type={service.type}
          key={service.type}
          icon={service.icon}
          micon={service.micon}
          text={translate(service.text, service.text_ar)}
          handleAction={serviceClickHandler}
          hideInMobile={service.hideInMobile&&mobile}
        />
      ))}
    </div>
  );
};

export default ServiceGrid;
