export const FLOW_TYPE_ESSENTIAL_CARE = "FLOW_TYPE_ESSENTIAL_CARE";
export const FLOW_TYPE_ESSENTIAL_CARE_NON_LOGGED_IN =
  "FLOW_TYPE_ESSENTIAL_CARE_NON_LOGGED_IN";


export const DETAILS = "details";
export const PLAN = "plan";
export const PAYMENT = "payment";

const commonFlow = [{
  name: PLAN,
  substeps: ["declaration","personal_details", "beeficiary_details"],
},
{
  name: PAYMENT,
  substeps: [
    "review_and_confirm",
    "payment_method",
    "payment_congratulations",
  ],
},]
export const essential_care_flow = {
  [FLOW_TYPE_ESSENTIAL_CARE]: [
    {
      name: DETAILS,
      substeps: ["docs_reqd"],
    },
    ...commonFlow,
  ],
  [FLOW_TYPE_ESSENTIAL_CARE_NON_LOGGED_IN]: [
    {
      name: DETAILS,
      substeps: ["basic_details", "docs_reqd"],
    },
    ...commonFlow,
  ],
};

export const headerStepperData = [
  {
    key: DETAILS,
    title_en: "Details",
    title_ar: "يبدأ",
  },
  {
    key: PLAN,
    title_en: "Plan",
    title_ar: "يخطط",
  },
  {
    key: PAYMENT,
    title_en: "Payment",
    title_ar: "يدفع",
  },
];

export const genderLabels = {
  M: {
    label_en: "Male",
    label_ar: "ذكر",
  },
  F: {
    label_en: "Female",
    label_ar: "أنثى",
  },
};


export const ADD_BENEFICIARY_VALIDATIONS = [
  {
    name: "fullName",
    validations: ["required"],
  },
  {
    name: "cpr",
    validations: ["length"],
    exact_length: 9,
  },
  {
    name: "gender",
    validations: ["select"],
  },
  {
    name: "day",
    validations: ["select"],
  },
  {
    name: "month",
    validations: ["select"],
  },
  {
    name: "year",
    validations: ["select"],
  },
  {
    name: "relationship",
    validations: ["select"],
  },
  {
    name: "nationality",
    validations: ["select"],
  },
  // {
  //   name: "cprNumPhotos",
  //   validations: ["required"],
  // },
];


export const RELATIONSHIP_OPTIONS = [
  { value: "14", label: "Child", label_ar: "طفل" },
  { value: "13", label: "Spouse", label_ar: "زوج" },
];

export const getRelationShipOptionFromValue = (value) => {
  let option = RELATIONSHIP_OPTIONS?.find(each => each.value === value);
  return option
}