import React, { useState } from "react";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useTranslation } from "react-i18next";
import CoverOption from "../../newMotorPolicy/addons/CoverOption";
import useMotorForm from "../../../../hooks/useMotorFormData";
import { coverOptions, setCoverOptions } from "../../newMotorPolicy/addons/data";
import useAddMoreCoversFlow from "../../../../hooks/useAddMoreCoversFlow";
import AddMoreCoversMotorLayout from "../addMoreCoversMotorLayout";
import { useSelector } from "react-redux";
export const ADD_MORE_COVERS_POLICY_COVERAGE_ENHANCEMENT =
  "ADD_MORE_COVERS_POLICY_COVERAGE_ENHANCEMENT";

const EnhanceYourCoveragePage = () => {
  /* Setting up dispatch to call redux */

  /* Setting up variables with appropriate requests to send and response to receive, reading from redux/functions */
  // const policyPremiumResponse = useSelector(getPolicyPremiumResponse);


  /* Functions and Variables that are updated with functions/state */
  const { handleNextSubstep } = useAddMoreCoversFlow();

  // Adjust below request of cover options. 
  const [coverOptions, setCoversState] = setCoverOptions(null, 'GO', null); 

  const { t } = useTranslation();
  const [formState, setFormState] = useState({
    coverOptionsState: coverOptions,
  });

  const { saveMotorDataInRedux } = useMotorForm(
    ADD_MORE_COVERS_POLICY_COVERAGE_ENHANCEMENT,
    setFormState
  );

  const updateOptionValues = (name, updates) => {
    let updatedOptions = formState?.coverOptionsState?.map((option) => {
      if (option.code ==name) {
        return { ...option, ...updates, disabled: false };
      }
      return option;
    });
    if (name =="CMOT28" && Object.keys(updates)?.includes("included")) {
      updatedOptions = updatedOptions?.map(each => {
        if (each.name =="CMOT16") {
          return ({ ...each, included: updates.included, disabled: updates.included })
        }
        return each
      })
    }

    let update = {
      ...formState,
      coverOptionsState: updatedOptions,
    };

    setFormState(update);
    saveMotorDataInRedux(update);
  };

  /* Next */
  const continueClickHanlder = () => {
    handleNextSubstep();
  };

  console.log("form state", formState);
  console.log("coverOptionsState", formState.coverOptionsState);


  return (
    <AddMoreCoversMotorLayout
      is_hide_back_btn
      show_summary
      btn_onClick={continueClickHanlder}
    >
      <div className="coverageEnhancement flex flex-col items-center p-6 mx-auto ">
        <TitleDescription
          title={t(
            "motorInsurance.renewalPolicyFlow.enhanceYourCoverage.title"
          )}
          description={t(
            "motorInsurance.renewalPolicyFlow.enhanceYourCoverage.description"
          )}
        />
        <div className=" w-[33%] max-350:w-[100%] max767:w-[100%] ipad:w-[55%]">
          {/* Error handling. Making sure formState.coverOptionsState is an array */}
          {Array.isArray(formState.coverOptionsState) &&
            formState.coverOptionsState.map((option) => (
              <CoverOption
                key={option.code}
                option={option}
                updateOptionValues={updateOptionValues}
                disabled={option.disabled}
              />
            ))}
        </div>
      </div>
    </AddMoreCoversMotorLayout>
  );
};

export default EnhanceYourCoveragePage;
