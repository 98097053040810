import React from 'react'

const SectorDetailCard = ({ data, title }) => {
    return (( Array.isArray(data)&&data?.length)  ? <div className='bg-white rounded-[10px] border-solid border-[#DDE2E4] border p-3 h-auto mb-4 pb-0 flex w-[357px] flex-col items-start gap-4 shrink-0'>
            <h2 className="text-[#252C32] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] mb-4">{title}</h2>
            {data?.map((item, index) => (
                <div key={index} className="flex justify-between border-b px-0 py-2.5">
                    <span className='text-[#1A2024] text-sm not-italic font-normal leading-6'>{item.name}</span>
                    <span className='text-[#1A2024] text-sm not-italic font-normal leading-6'>{item.value}</span>
                </div>
            ))}
        </div> : null
    )
}

export default SectorDetailCard