import React, { useState } from "react";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import RenewMotorLayout from "../renewalMotorPolicy/renewMotorLayout";
import { useNavigate } from "react-router-dom";
import FileUpload from "../../../components/common/fileUploadInput/fileUploadInput";
import spinerblack from "../../../assets/images/Spinnerblack.svg";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ChangePlateNo } from "../../../redux/features/motor/ChangePlateNumberSlice";
import { unwrapResult } from "@reduxjs/toolkit";
const UploadLetterOfDocument = ({
  switchToggle,
  NewPlateNo,
  PlateType,
  Policy,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const continueClickHanlder = async () => {
    const ChangePlateNoPayload = {
      PolicyNumber: Policy,
      Value: NewPlateNo,
    };
    const res = await dispatch(ChangePlateNo(ChangePlateNoPayload));
    if (res.error?.message != "Rejected") {
      const changePlateRes = await unwrapResult(res);
      if (changePlateRes.RegistrationNumber == NewPlateNo) {
        const encodePolicy = encodeURIComponent(Policy);
        navigate(`/insurance-policies/policy-documents?policy=${encodePolicy}`);
      } else {
        // Display error
      }
    } else {
      // Display Error message
    }
  };
  const [formData, setFormData] = useState({
    change_plate_number: false,
  });
  const onFileUploaded = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  return (
    <RenewMotorLayout
      btn_disabled={formData.change_plate_number != true}
      btn_onClick={continueClickHanlder}
      backClickHandler={switchToggle}
    >
      <div className="changeplatenumber w-[500px] max-w-[100%] mx-auto bg-white max767:p-5">
        <TitleDescription
          title={t(
            "motorInsurance.changePlateNumber.uploadLetterOfDocument.title"
          )}
          description={t(
            "motorInsurance.changePlateNumber.uploadLetterOfDocument.description"
          )}
        />
        <p className="text-[#48535B] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] mt-6">
          {t(
            "motorInsurance.changePlateNumber.uploadLetterOfDocument.upload_letter_of_document_label"
          )}
        </p>
        <FileUpload
          className="platetypedocuinput"
          name="change_plate_number"
          toggleFileUpload={onFileUploaded}
        />
        {/* <button
          className={`bg-[#55C6EF] mt-4 h-14 items-center w-full text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] flex justify-center p-3 rounded-[10px]`}
        >
          <img src={spinerblack} alt="spiner" />
        </button> */}
      </div>
    </RenewMotorLayout>
  );
};

export default UploadLetterOfDocument;
