import { Popover } from 'react-tiny-popover'


const PopoverComponent = ({
    positions = ['bottom','top', 'left', 'right'],
    popupContent,
    headerMenu,
    closer,
    isPopoverOpen
}) => {
    return (
        <Popover
            isOpen={isPopoverOpen}
            positions={positions}
            content={popupContent}
            onClickOutside={closer}
        >
            <div >
                {headerMenu}
            </div>
        </Popover>
    )
}

export default PopoverComponent