import React from "react";
import Button from "../../../../components/common/Button/Button";
import useScreenResolution from "../../../../hooks/useScreenResolution";
import ChevronRight from "../../../../assets/images/Chevronright.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ClaimHeader = ({
  navigateToChangeGarage,
  claimCaseNumber,
  claimDate,
}) => {
  const navigate = useNavigate();
  const { mobile, tablet } = useScreenResolution();
  const { t } = useTranslation();
  const navigateToClaimDetailModal = () => {
    navigate("/motor-insurance/claim-details/claim-info/"+claimCaseNumber);
  };

  return (
    <>
      {mobile || tablet ? (
        <div className="flex items-center gap-4 shrink-0 border bg-[#F6F8F9] p-4 rounded-2xl border-solid border-[#DDE2E4] max767:w-full max767:mb-4 max767:justify-between ipad:justify-between max767:mt-20">
          <div className="mt-4">
            <h1 className="text-[#1A2024] text-xl font-semibold leading-normal max767:text-2xl">
              {claimCaseNumber}
            </h1>
            <div className="flex items-center gap-1">
              <p className="text-[#1A2024] text-xs font-normal leading-normal">
                {claimDate}
              </p>
              <p className="text-[#1A2024] text-xs font-normal leading-normal"></p>
            </div>
          </div>
          {/* <img
            src={ChevronRight}
            alt="Chevron Right"
            onClick={navigateToClaimDetailModal}
          /> */}
        </div>
      ) : (
        <div className="flex justify-between items-center">
          <div className="mt-4">
            <h1 className="text-[#1A2024] text-xl font-semibold leading-normal max767:text-2xl">
              {claimCaseNumber}
            </h1>
            <div className="flex items-center gap-1">
              <p className="text-[#1A2024] text-xs font-normal leading-normal">
                {claimDate}
              </p>
              <p className="text-[#1A2024] text-xs font-normal leading-normal"></p>
            </div>
          </div>
          {/* <Button
            label={t("motorInsurance.trackClaimFlow.garageSelection.title")}
            className="changegaragebtn flex flex-col items-center gap-2.5 border bg-white px-3 py-1 rounded-md border-solid border-[#DDE2E4]"
            onSubmit={navigateToChangeGarage}
          /> */}
        </div>
      )}
    </>
  );
};

export default ClaimHeader;
