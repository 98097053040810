import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Layout from "../layout/Layout";
import Dashboard from "../pages/dashboard/dashboard";
import ProfileSettings from "../pages/profileSettings/profileSettings";
import NewPolicy from "../pages/newPolicy/newPolicy";
import PolicyDetailsPage from "../pages/motorInsurancePages/policyOverview/policyDetailsPage";
import NewMotorPolicy from "../pages/motorInsurancePages/newMotorPolicy/NewMotorPolicy";
import UserRefreshLogin from "../hooks/useRefreshLogin";
import PaymentMethods from "../pages/motorInsurancePages/newMotorPolicy/pay/paymentMethods";
import MyPoliciesPage from "../pages/myPoliciesPage/myPoliciesPage";
import MainSignupPage from "../pages/signInRegisterPages/mainSignupPage";
import SignInPage from "../pages/signInRegisterPages/SignInPage/SignInPage";
import MobileUploadCarPhotos from "../pages/motorInsurancePages/newMotorPolicy/uploads/mobileUploadPages/mobileUploadCarPhotos";
import RenewalMotorPolicy from "../pages/motorInsurancePages/renewalMotorPolicy/renewalMotorPolicy";
import ChangePlanPolicy from "../pages/motorInsurancePages/changePlanMotorPolicy/changePlanPolicy";
import AddMoreCoversMotorPolicy from "../pages/motorInsurancePages/addMOreCoversFlow/addMoreCoversMotorPolicy";
import MorePage from "../components/common/sideNavbar/morePage";
import PaymentSuccess from "../pages/motorInsurancePages/newMotorPolicy/pay/paymentSuccess";
import AdjustSumInsuredMotorPolicy from "../pages/motorInsurancePages/adjustSumInsuredFlow/adjustSumInsuredMotorPolicy";
import FileClaim from "../pages/fileClaim/fileClaim";
import TrackClaimFlow from "../pages/motorInsurancePages/trackClaimFlow/trackClaimFlow";
import ChangePlateNumberFlow from "../pages/motorInsurancePages/changePlateNumberFlow/changePlateNumberFlow";
import TransferPolicyFlow from "../pages/motorInsurancePages/transferPolicyFlow/transferPolicyFlow";
import ConfirmTransferDetails from "../pages/motorInsurancePages/transferPolicyFlow/newOwner/confirmTransferDetails";
import RemoveBankNameFlow from "../pages/motorInsurancePages/removeBankNameFlow/removeBankNameFlow";
import ForgotPassword from "../pages/signInRegisterPages/ForgotPasswordPage/ForgotPassword";
import FileClaimMotorPolicy from "../pages/motorInsurancePages/fileAClaimFlow/fileClaimMotorPolicy";
import PolicyOverview from "../pages/lifeInsurancePages/PolicyOverview/PolicyOverview";
import TransferSigninPage from "../pages/motorInsurancePages/transferPolicyFlow/transferSigninPage";
import MobilePolicyTransferRequest from "../pages/dashboard/mobilePolicyTransferRequest";
import useScreenResolution from "../hooks/useScreenResolution";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfileInfo } from "../redux/features/profileSlice";
import { selectLoginTokenInfo } from "../redux/features/loginRegisterSlice";
import WithdrawalFlow from "../pages/lifeInsurancePages/PolicyOverview/manageMyPolicy/withdrawalFlow/withdrawalFlow";
import SurrenderPolicyFlow from "../pages/lifeInsurancePages/PolicyOverview/manageMyPolicy/surrenderPolicyFlow/surrenderPolicyFlow";
import ManageBeneficiaiaries from "../pages/lifeInsurancePages/PolicyOverview/manageMyPolicy/manageBeneficiaries/manageBeneficiaiaries";
import FaqPage from "../pages/dashboard/faqPage";
import MobileNotificationScreen from "../pages/mobileMorePages/mobileNotificationScreen";
import MobileProfilePage from "../pages/mobileMorePages/mobileProfilePage";
import MobileChangePasswordPage from "../pages/mobileMorePages/mobileChangePassword";
import MobileSecurityPage from "../pages/mobileMorePages/mobileSecurityPage";
import MobileFaqPage from "../pages/mobileMorePages/mobileFaqPage";
import ExpressShieldPlan from "../pages/buyOnline/expressShield/expressShieldPlan";
import OnboardingScreen from "../pages/onboardingPages/onboardingScreen";
import InvestmentStrategyChangeFlow from "../pages/lifeInsurancePages/PolicyOverview/fundManagementTab/investmentStrategyChangeFlow/investmentStrategyChangeFlow";
import ActionScreens from "../pages/actions/actionScreens";
import FundManagementPage from "../pages/lifeInsurancePages/PolicyOverview/fundManagementTab/investementStrategyFlow/fundManagementPage";
import FundsRedirectingFlow from "../pages/lifeInsurancePages/PolicyOverview/fundManagementTab/investementStrategyFlow/fundsRedirectingFlow/fundsRedirectingFlow";
import PolicyDocs from "../pages/motorInsurancePages/newMotorPolicy/pay/policyDocs";
import FundsSwitchingFlow from "../pages/lifeInsurancePages/PolicyOverview/fundManagementTab/investementStrategyFlow/fundSwitchingFlow/fundSwitchingFlow";
import PolicyCancellationMotorInsurance from "../pages/motorInsurancePages/policyOverview/managePolicy/policyCancellationFlow/policyCancellationMotorInsurance";
import ClaimProgressPage from "../pages/motorInsurancePages/trackClaimFlow/claimProgressPage";
import ChangeGaragePage from "../pages/motorInsurancePages/trackClaimFlow/claimComponents/changeGaragePage";
import UploadDocuments from "../pages/motorInsurancePages/trackClaimFlow/claimComponents/uploadDocuments";
import ConfirmPaymentDetails from "../pages/motorInsurancePages/trackClaimFlow/claimComponents/confirmPaymentDetails";
import SuccessScreen from "../pages/motorInsurancePages/trackClaimFlow/claimComponents/successScreen";
import ChangeMobileNumberFlow from "../pages/signInRegisterPages/changeMobileNumberFlow/changeMobileNumberFlow";
import ClaimDetailModal from "../pages/motorInsurancePages/trackClaimFlow/claimComponents/claimDetailModal";
import ExpiredPoliciesPage from "../pages/myPoliciesPage/expiredPolicyPage";
import ChangeEmailFlow from "../pages/signInRegisterPages/changeEmailFlow/changeEmailFlow";
import ReasonforReection from "../pages/motorInsurancePages/trackClaimFlow/claimComponents/reasonforReection";
import EmergencyServices from "../pages/emergencyServices/EmergencyServices";
import ChangePlateNumberPopup from "../pages/motorInsurancePages/changePlateNumberFlow/changePlateNumberPopup";
import ReportRedirect from "../pages/policyReport/ReportRedirect";
import QuickRenewalMotorPolicy from "../pages/motorInsurancePages/renewalMotorPolicy/quickRenewalMotorPolicy";
import NotFoundPage from "./notFoundPage";
import EssentialCare from "../pages/essentialCare/essentialCare";
import PolicyOverviewMainTab from "../pages/essentialCare/policyOverview/policyOverviewMainTab";
import useOnboarding from "../hooks/useOnboarding";

const AllRoutes = () => {
  UserRefreshLogin();
  useOnboarding()
  const screenResolution = useScreenResolution();

  let routes = [
    {
      path: "/sign-in",
      element: <SignInPage />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    {
      path: "/forgot-password",
      element: <ForgotPassword />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    {
      path: "/profile-details",
      element: <MobileProfilePage />,
      isPopup: true,
      hide_header: true,
      isAuthorized: true,
    },
    {
      path: "/notifications",
      element: <MobileNotificationScreen />,
      isPopup: true,
      hide_header: true,
      isAuthorized: true,
    },
    {
      path: "/change-password",
      element: <MobileChangePasswordPage />,
      isPopup: true,
      hide_header: true,
      isAuthorized: true,
    },
    {
      path: "/security",
      element: <MobileSecurityPage />,
      isPopup: true,
      hide_header: true,
      isAuthorized: true,
    },
    {
      path: "/sign-up",
      element: <MainSignupPage />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    {
      path: "/",
      element: <Dashboard />,
      hide_header: false,
      isPopup: false,
      isAuthorized: false,
    },
    {
      path: "/policyTransfer/:id",
      element: screenResolution.mobile ? (
        <MobilePolicyTransferRequest />
      ) : (
        <Dashboard />
      ),
      hide_header: false,
      isPopup: false,
      isAuthorized: true,
    },
    {
      path: "/policyTransfer/signin/:id",
      element: <TransferSigninPage />,
      hide_header: true,
      isPopup: false,
      isAuthorized: true,
    },
    {
      path: "/more",
      element: <MorePage />,
      hide_header: false,
      isPopup: false,
      isAuthorized: false,
    },
    {
      path: "/faq",
      element: screenResolution.mobile ? <MobileFaqPage /> : <FaqPage />,
      hide_header: screenResolution.mobile ? true : false,
      isPopup: screenResolution.mobile ? true : false,
      isAuthorized: false,
    },
    {
      path: "/my-policy",
      element: <MyPoliciesPage />,
      hide_header: false,
      isPopup: false,
      isAuthorized: true,
    },
    {
      path: "/life-insurance/policy-overview",
      element: <PolicyOverview />,
      hide_header: screenResolution.mobile,
      isPopup: false,
      isAuthorized: true,
    },
    {
      path: "/profile-settings",
      element: <ProfileSettings />,
      isAuthorized: true,
    },
    {
      path: "/new-policy",
      element: <NewPolicy />,
      hide_header: screenResolution.mobile,
      isAuthorized: false,
    },
    {
      path: "/emergency-services",
      element: <EmergencyServices />,
      isAuthorized: false,
    },
    {
      path: "/file-a-claim",
      element: <FileClaim />,
      isAuthorized: false,
    },
    {
      path: "/onboarding-flow",
      element: <OnboardingScreen />,
      hide_header: true,
      isPopup: true,
      isAuthorized: false,
    },
    {
      path: "/actions",
      element: <ActionScreens />,
      hide_header: true,
      isPopup: false,
      isAuthorized: false,
    },
    {
      path: "/change-mobile-number",
      element: <ChangeMobileNumberFlow />,
      isPopup: true,
      hide_header: false,
      isAuthorized: true,
    },
    {
      path: "/change-email",
      element: <ChangeEmailFlow />,
      isPopup: true,
      hide_header: false,
      isAuthorized: true,
    },
    {
      path: "/expired-policies",
      element: <ExpiredPoliciesPage />,
      hide_header: screenResolution.mobile,
      isPopup: false,
      isAuthorized: true,
    },
    {
      path: "/essential-care",
      element: <EssentialCare />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    {
      path: "/essential-care/policy-overview",
      element: <PolicyOverviewMainTab />,
      hide_header: screenResolution.mobile ? true : false,
      isPopup: false,
      isAuthorized: true,
    },
  ];

  let motorRoutes = [
    // new Motor Policy routes
    {
      path: "/new-motor-policy",
      element: <NewMotorPolicy />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    {
      path: "/motor-insurance/policy-overview/:policy_id",
      element: <PolicyDetailsPage />,
      hide_header: screenResolution.mobile ? true : false,
      isPopup: false,
      isAuthorized: true,
    },
    {
      path: "/motor-insurance/payment",
      element: <PaymentMethods />,
      hide_header: true,
      isPopup: true,
      isAuthorized: true,
    },
    {
      path: "new-motor-policy/upload-photos-of-car/:id",
      element: <MobileUploadCarPhotos />,
      hide_header: true,
      isPopup: true,
      isAuthorized: true,
    },
    // renewal Motor plicy routes
    {
      path: "/motor-insurance/renew-motor-policy",
      element: <RenewalMotorPolicy />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    {
      path: "/motor-insurance/quick-renew-motor-policy",
      element: <QuickRenewalMotorPolicy />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    // change plan Motor plicy routes
    {
      path: "/motor-insurance/change-plan/:policy_id",
      element: <ChangePlanPolicy />,
      isPopup: true,
      hide_header: false,
      isAuthorized: true,
    },
    // add more covers Motor plicy routes
    {
      path: "/motor-insurance/add-more-covers/:policy_id",
      element: <AddMoreCoversMotorPolicy />,
      isPopup: true,
      hide_header: false,
      isAuthorized: true,
    },
    // track a Claim routes
    {
      path: "/motor-insurance/track-claim",
      element: <TrackClaimFlow />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    // file new claim routes
    {
      path: "/motor-insurance/file-new-claim",
      element: <FileClaimMotorPolicy />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    // transfer Ownership
    {
      path: "/motor-insurance/transfer-ownership",
      element: <TransferPolicyFlow />,
      isPopup: true,
      hide_header: false,
      isAuthorized: true,
    },
    {
      path: "/motor-insurance/confirm-transfer-details",
      element: <ConfirmTransferDetails />,
      isPopup: true,
      hide_header: false,
      isAuthorized: true,
    },
    {
      path: "/motor-insurance/remove-bank-name/:policy_id",
      element: <RemoveBankNameFlow />,
      isPopup: true,
      hide_header: false,
      isAuthorized: true,
    },
    {
      path: "/motor-insurance/change-plate-number",
      element: <ChangePlateNumberFlow />,
      isPopup: true,
      hide_header: false,
      isAuthorized: true,
    },
    {
      path: "/motor-insurance/payment-success",
      element: <PaymentSuccess />,
      isPopup: true,
      hide_header: true,
      isAuthorized: true,
    },
    {
      path: "/life-insurance/payment-success",
      element: <PaymentSuccess />,
      isPopup: true,
      hide_header: false,
      isAuthorized: true,
    },
    {
      path: "/motor-insurance/adjust-sum-insured/:policy_id",
      element: <AdjustSumInsuredMotorPolicy />,
      isPopup: true,
      hide_header: false,
      isAuthorized: true,
    },
    {
      path: "/insurance-policies/policy-documents",
      element: <PolicyDocs />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    {
      path: "/policy/report",
      element: <ReportRedirect />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    {
      path: "/motor-insurance/cancel-policy/:policy_id",
      element: <PolicyCancellationMotorInsurance />,
      isPopup: true,
      hide_header: false,
      isAuthorized: true,
    },
    {
      path: "/motor-insurance/claim-details/:claim_id",
      element: <ClaimProgressPage />,
      isPopup: false,
      hide_header: screenResolution.mobile,
      isAuthorized: false,
    },
    {
      path: "/motor-insurance/change-garage/:claim_id",
      element: <ChangeGaragePage />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    {
      path: "/motor-insurance/upload-documents/:claim_id",
      element: <UploadDocuments />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    {
      path: "/motor-insurance/payment-details/:claim_id",
      element: <ConfirmPaymentDetails />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    {
      path: "/motor-insurance/success",
      element: <SuccessScreen />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    {
      path: "/motor-insurance/claim-details/claim-info/:claim_id",
      element: <ClaimDetailModal />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    {
      path: "/motor-insurance/reason-for-rejection/:claim_id",
      element: <ReasonforReection />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
    {
      path: "/motor-insurance/change-plate-number-modal",
      element: <ChangePlateNumberPopup />,
      isPopup: true,
      hide_header: false,
      isAuthorized: true,
    },
  ];

  let lifeInsuranceRoutes = [
    {
      path: "/life-insurance/withdraw",
      element: <WithdrawalFlow />,
      hide_header: false,
      isPopup: true,
      isAuthorized: true,
    },
    {
      path: "/life-insurance/surrender-policy",
      element: <SurrenderPolicyFlow />,
      hide_header: false,
      isPopup: true,
      isAuthorized: true,
    },
    {
      path: "/life-insurance/manage-beneficiaries",
      element: <ManageBeneficiaiaries />,
      hide_header: screenResolution.mobile,
      isPopup: false,
      isAuthorized: true,
    },
    {
      path: "/life-insurance/investement-strategy-change",
      element: <InvestmentStrategyChangeFlow />,
      isPopup: true,
      hide_header: false,
      isAuthorized: true,
    },
    {
      path: "/life-insurance/fund-management",
      element: <FundManagementPage />,
      isPopup: true,
      hide_header: false,
      isAuthorized: true,
    },
    {
      path: "/life-insurance/funds-redirecting-flow",
      element: <FundsRedirectingFlow />,
      isPopup: true,
      hide_header: false,
      isAuthorized: true,
    },
    {
      path: "/life-insurance/funds-switching-flow",
      element: <FundsSwitchingFlow />,
      isPopup: true,
      hide_header: false,
      isAuthorized: true,
    },
  ];

  let buyOnlineRoutes = [
    {
      path: "/buy-online/express-shield",
      element: <ExpressShieldPlan />,
      isPopup: true,
      hide_header: false,
      isAuthorized: false,
    },
  ];
  const loginTokenInfo = useSelector(selectLoginTokenInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (loginTokenInfo) {
      dispatch(fetchProfileInfo());
    }
  }, [loginTokenInfo]);

  // Wrapper for authorization checks
  const AuthorizedRoute = ({ children }) => {
    if (!loginTokenInfo) {
      // Redirect to home page if not authorized
      return <Navigate to="/" replace />;
    }

    return children;
  };
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={
            route.isAuthorized ? (
              <AuthorizedRoute>
                <Layout
                  isPopup={route.isPopup}
                  isHideHeader={route.hide_header}
                >
                  {route.element}
                </Layout>
              </AuthorizedRoute>
            ) : (
              <Layout isPopup={route.isPopup} isHideHeader={route.hide_header}>
                {route.element}
              </Layout>
            )
          }
        />
      ))}
      {[...motorRoutes, ...lifeInsuranceRoutes, ...buyOnlineRoutes].map(
        (route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              route.isAuthorized ? (
                <AuthorizedRoute>
                  <Layout
                    isPopup={route.isPopup}
                    isHideHeader={route.hide_header}
                  >
                    {route.element}
                  </Layout>
                </AuthorizedRoute>
              ) : (
                <Layout
                  isPopup={route.isPopup}
                  isHideHeader={route.hide_header}
                >
                  {route.element}
                </Layout>
              )
            }
          />
        )
      )}
      <Route
        path="*"
        element={
          <Layout isPopup={true} isHideHeader={false} invalidPage={true}>
            <NotFoundPage />
          </Layout>
        }
      />
    </Routes>
  );
};

export default AllRoutes;
