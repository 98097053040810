import { createAsyncThunk, createSlice, original } from "@reduxjs/toolkit";
import Axios, { AxiosStrapi } from "../../axiosInstance";
import { lifeApiEndPoints, strapiApiEndPoints } from "../../../api";

const initialState = {
    allfundsOperational:null,
    allFundsOperationalLoader : null,
    allfundsBreakdown: null,
    newAllFundsBreakdown : null,
    allFundsBreakdownLoader : null,
    allfundsPerformance : null,
    closePraiseDaily : null,
    allfundsPerformanceLoader : null,
    tcsCatalogFunds: null,
    tcsCatalogLoader: null,
    rollingPerformance: null,
    rollingPerformanceIsLoading: null,
    isLoading: false,
    error: null,
    strapiFunds: null,
    strapiFundsLoading: null,
};




// Thunk for fetching all funds
export const fetchAllFundsBreakDown = createAsyncThunk(
    "fetchAllFunds",
    async (isin, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`${lifeApiEndPoints.investmentEndPoints.allfunds}`, {
                params: {
                    type: "breakdownslimited",
                    isin: isin
                }
            });
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data || err.message);
        }
    }
);
export const fetchNewAllFundsBreakDown = createAsyncThunk(
    "fetchNewAllFundsBreakDown",
    async (isin, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`${lifeApiEndPoints.investmentEndPoints.allfunds}`, {
                params: {
                    type: "breakdowns",
                    isin: isin
                }
            });
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data || err.message);
        }
    }
);
// close praise Daily
export const fetchClosePraiseDaily = createAsyncThunk(
    "fetchClosePraiseDaily",
    async (isin, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`${lifeApiEndPoints.investmentEndPoints.allfunds}`, {
                params: {
                    type: "closepricesdaily",
                    isin: isin
                }
            });
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data || err.message);
        }
    }
);
// Thunk for fetching all funds
export const fetchAllFundsOperations = createAsyncThunk(
    "fetchAllFundsOperations",
    async (isin, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`${lifeApiEndPoints.investmentEndPoints.allfunds}`, {
                params: {
                    type: "operational",
                    isin: isin
                }
            });
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data || err.message);
        }
    }
);

// Thunk for fetching TCS Catalog Funds
export const fetchTcsCatalogFunds = createAsyncThunk(
    "fetchTcsCatalogFunds",
    async (_, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`${lifeApiEndPoints.investmentEndPoints.tcsCatalog}`);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data || err.message);
        }
    }
);
// Thunk for fetching funds from strapi
export const fetchStrapiFunds = createAsyncThunk(
    "fetchStrapiFunds",
    async (_, { rejectWithValue }) => {
        try {
            const response = await AxiosStrapi.get(`${strapiApiEndPoints.lifeFunds}`);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data || err.message);
        }
    }
);
export const fetchAllFundsPerformance = createAsyncThunk(
    "fetchAllFundsPerformance",
    async (isin, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`${lifeApiEndPoints.investmentEndPoints.allfunds}`, {
                params: {
                    type: "performance",
                    isin: isin
                }
            });
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response?.data || err.message);
        }
    }
);

export const fetchRollingPerformance = createAsyncThunk(
    "fetchRollingPerformance",
    async (params, { rejectWithValue }) => {
        try {
            const response = await Axios.get(`${lifeApiEndPoints.rollingPerformance}`, {
                params: params
            });
            return response.data?.rolling_performance?.[params.isin];
        } catch (err) {
            return rejectWithValue(err.response?.data || err.message);
        }
    }
);



export const allFundsSlice = createSlice({
    name: "All Funds Slice",
    initialState,
    reducers: {
        clearAllFundsError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        // FETCH ALL FUNDS API
        builder.addCase(fetchAllFundsOperations.pending, (state) => {
            state.allFundsOperationalLoader = true;
        });
        builder.addCase(fetchAllFundsOperations.fulfilled, (state, action) => {
            state.allFundsOperationalLoader = false;
            state.allfundsOperational = action.payload;
        });
        builder.addCase(fetchAllFundsOperations.rejected, (state, action) => {
            state.allFundsOperationalLoader = false;
            state.error = action.payload || action.error.message;
        });
        // FETCH ALL FUNDS API
        builder.addCase(fetchClosePraiseDaily.pending, (state) => {
            state.allFundsOperationalLoader = true;
        });
        builder.addCase(fetchClosePraiseDaily.fulfilled, (state, action) => {
            state.allFundsOperationalLoader = false;
            state.closePraiseDaily = action.payload;
        });
        builder.addCase(fetchClosePraiseDaily.rejected, (state, action) => {
            state.allFundsOperationalLoader = false;
            state.error = action.payload || action.error.message;
        });
        // FETCH ALL FUNDS API
        builder.addCase(fetchAllFundsBreakDown.pending, (state) => {
            state.allFundsBreakdownLoader = true;
        });
        builder.addCase(fetchAllFundsBreakDown.fulfilled, (state, action) => {
            state.allFundsBreakdownLoader = false;
            state.allfundsBreakdown = action.payload;
        });
        builder.addCase(fetchAllFundsBreakDown.rejected, (state, action) => {
            state.allFundsBreakdownLoader = false;
            state.error = action.payload || action.error.message;
        });

        // FETCH ALL NEW FUNDS BREAKDOWN
        builder.addCase(fetchNewAllFundsBreakDown.pending, (state) => {
            // state.allFundsBreakdownLoader = true;
        });
        builder.addCase(fetchNewAllFundsBreakDown.fulfilled, (state, action) => {
            // state.allFundsBreakdownLoader = false;
            state.newAllFundsBreakdown = action.payload;
        });
        builder.addCase(fetchNewAllFundsBreakDown.rejected, (state, action) => {
            // state.newAllFundsBreakdown = false;
            state.error = action.payload || action.error.message;
        });

        // FETCH ALL FUNDS PERFORMANCE
        builder.addCase(fetchAllFundsPerformance.pending, (state) => {
            state.allfundsPerformanceLoader = true;
        });
        builder.addCase(fetchAllFundsPerformance.fulfilled, (state, action) => {
            state.allfundsPerformanceLoader = false;
            state.allfundsPerformance = action.payload?.performance;
        });
        builder.addCase(fetchAllFundsPerformance.rejected, (state, action) => {
            state.allfundsPerformanceLoader = false;
            state.error = action.payload || action.error.message;
        });

        // FETCH TCS CATALOG FUNDS API
        builder.addCase(fetchTcsCatalogFunds.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(fetchTcsCatalogFunds.fulfilled, (state, action) => {
            state.isLoading = false;
            state.tcsCatalogFunds = action.payload;
        });
        builder.addCase(fetchTcsCatalogFunds.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload || action.error.message;
        });

        // FETCH ROLLING PERFORMANCE API
        builder.addCase(fetchRollingPerformance.pending, (state) => {
            state.rollingPerformanceIsLoading = true;
        });
        builder.addCase(fetchRollingPerformance.fulfilled, (state, action) => {
            state.rollingPerformanceIsLoading = false;
            state.rollingPerformance = action.payload;
        });
        builder.addCase(fetchRollingPerformance.rejected, (state, action) => {
            state.rollingPerformanceIsLoading = false;
            state.error = action.payload || action.error.message;
        });
        // FETCH ROLLING FUNDS STRAPI API
        builder.addCase(fetchStrapiFunds.pending, (state) => {
            state.strapiFundsLoading = true;
        });
        builder.addCase(fetchStrapiFunds.fulfilled, (state, action) => {
            state.strapiFundsLoading = false;
            state.strapiFunds = action.payload?.data;
        });
        builder.addCase(fetchStrapiFunds.rejected, (state, action) => {
            state.strapiFundsLoading = false;
            state.error = action.payload || action.error.message;
        });
    },
});

// SELECTORS
export const selectAllFundsWholeData = (store) => store?.allFunds;
export const selectAllFundsLoader = (store) => store?.allFunds.isLoading;

export const selectAllFundsBreakdown = (store) => store?.allFunds.allfundsBreakdown;
export const selectAllNewFundsBreakdown = (store) => store?.allFunds.newAllFundsBreakdown;
export const selectClosePraiseDaily = (store) => {
    let data =  store?.allFunds.closePraiseDaily?.close_prices;
    if(data?.length) {
        return data[data?.length-1];
    }
    else return null
    

}

export const selectAllFundsBreakdownLoader = (store) => store?.allFunds.allFundsBreakdownLoader;

export const selectAllFundsPerformanceLoader = (store) => store?.allFunds.allfundsPerformanceLoader;
export const selectAllFundsPerformance = (store) => store?.allFunds.allfundsPerformance;

export const selectTcsCatalogFundsData = (store) => store?.allFunds.tcsCatalogFunds;
export const selectTcsCatalogFundsLoader = (store) => store?.allFunds.tcsCatalogLoader;

export const selectRollingPerformanceData = (store) => store?.allFunds.rollingPerformance;
export const selectRollingPerformanceLoader = (store) => store?.allFunds.rollingPerformanceIsLoading;

export const selectStrapiFunds = (store) => store?.allFunds.strapiFunds;
export const selectStrapiFundsLoader = (store) => store?.allFunds?.strapiFundsLoading

export const selectMappedLoader = (store) => store?.allFunds?.strapiFundsLoading || store?.allFunds.tcsCatalogLoader

export const selectPreviewPopupLoader = (store) =>store?.allFunds.allFundsOperationalLoader || store?.allFunds.rollingPerformanceIsLoading || store?.allFunds.allfundsPerformanceLoader || store?.allFunds.allFundsBreakdownLoader



export const selectMappedFunds = (store) => {
    let mappedIds = store?.allFunds?.tcsCatalogFunds?.Funds?.map(each => each?.fundISIN)
    let mappedFunds = store?.allFunds.strapiFunds?.filter(each => mappedIds?.includes(each?.attributes?.ISIN));
    let formattedData = mappedFunds?.map(each => {
        let update = {
            fund_name: each?.attributes?.fundName,
            isin: each?.attributes?.ISIN,
            category: each?.attributes?.category,
            currency: each?.attributes?.currency,
            managementApproach: each?.attributes?.managementApproach,
            geographicFocus: each?.attributes?.graphicFocus,
            assetClass: each?.attributes?.assetClass,
            original : each,
            tcsOriginal : store?.allFunds?.tcsCatalogFunds?.Funds?.find(x=>x?.fundISIN ===each?.attributes?.ISIN )
        }
        return update;
    })
    return formattedData;
}

export const selectTcsCatalogFormattedFunds = (store) => {
    let funds = store?.allFunds.tcsCatalogFunds?.Funds;
    let formattedData = funds?.map(each => {
        let update = {
            fund_name: each?.fundName,
            isin: each?.fundISIN,
            category: "NA",
            currency: each?.fundCurrency,
            managementApproach: each?.fundStatus,
            geographicFocus: "NA",
            assetClass: "NA",
            original : each,

        }
        return update
    })
    return formattedData;

}

// ACTIONS TO DISPATCH
export const { clearAllFundsError } = allFundsSlice.actions;

export default allFundsSlice.reducer;
