import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


const initialState = {
  data: {
    sidenav_open : true,
  },
};



export const toolsSlice = createSlice({
  name: "utils slice",
  initialState,
  reducers: {
    toggleSidenav: (state,action) => {
      if(action.payload){
        state.data.sidenav_open = action.payload.open;
        return
      }
      state.data.sidenav_open = !state.data.sidenav_open;
    },
  },
  extraReducers: (builder) => {

  },
});

export const selectUtilsWhole = (store) => store?.tools?.data;

export const { toggleSidenav  } = toolsSlice.actions;

export default toolsSlice.reducer;
