import React from "react";
import { useNavigate } from "react-router-dom";

const Breadcrumb = ({ items }) => {
  const navigate = useNavigate();
  const itemClickHandler = (item) => {
    if (item?.clickable) {
      navigate(item.link)
    }
  }
  return (
    <div className="flex items-center" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 rtl:space-x-reverse">
        {items?.map((item, index) => (
          <li key={index} className="inline-flex items-center">
            <button
              onClick={() => { itemClickHandler(item) }}
              className={`text-xs not-italic leading-4 ${item.isActive ? 'text-black font-normal' : 'text-[#6E7C87]'
                }`}
            >
              {item.label}
            </button>
            {index < items.length - 1 && (
              <svg
                className="w-4 h-4 mx-2 mr-0 rtl:mr-2 rtl:mx-0 text-gray-400 rtl:rotate-180"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M8.293 15.707a1 1 0 010-1.414L12.586 10 8.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z" />
              </svg>
            )}
          </li>
        ))}
      </ol>
    </div>
  );
};

export default Breadcrumb;
