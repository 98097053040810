import React, { useEffect, useState } from "react";
import RenewMotorLayout from "../renewalMotorPolicy/renewMotorLayout";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useTranslation } from "react-i18next";
import TelInput from "../../../components/common/telInput/telInput";
import Input from "../../../components/common/Input/Input";
import AlertComponent from "./alertComponent";
import { useSearchParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPolicyTransferSuccess,
  getRequestPolicyTransferResponse,
  getRequestPolicyTransferStatusCode,
  getStartPolicyTransferResponse,
  transferRequest,
} from "../../../redux/features/endorsements/transferPolicySlice";
import { selectProfileData } from "../../../redux/features/profileSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { getPolicyPaySuccess } from "../../../redux/features/payment/policyPaySlice";
import { toast } from "react-toastify";
import { TRANSFER_OWNER_VALIDATIONS } from "./data";
import useValidation from "../../../hooks/useValidation";

const NewOwnerDetails = ({ switchToggle, updateOwnerNumber }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const dispatch = useDispatch();

  // Extract the query parameters
  const queryParams = new URLSearchParams(location.search);
  const encodedPolicy = queryParams.get("policy"); // Get the 'policy' parameter

  const staticData = {
    cpr: "",
    mobile: "",
    policy_no: encodedPolicy,
  };

  const [formData, setFormData] = useState({
    cpr: staticData.cpr,
    mobile: staticData.mobile,
    policy_no: staticData.policy_no,
  });

  const requestPolicyTransferResponse = useSelector(
    getRequestPolicyTransferResponse
  );
  const requestPolicyTransferSuccess = useSelector(getPolicyTransferSuccess);
  const requestPolicyTransferStatusCode = useSelector(
    getRequestPolicyTransferStatusCode
  );

  const userInfo = useSelector(selectProfileData);
  // const userPhoneNo = useSelector();

  const { errors, validateFields } = useValidation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    let update = { ...formData, [name]: value };
    if (errors[name]) {
      validateForm(update);
    }
    setFormData(update);
  };

  const validateForm = (data) => {
    let isValid = validateFields(
      TRANSFER_OWNER_VALIDATIONS,
      data ? data : formData
    );
    return isValid;
  };

  const continueClickHandler = async () => {
    if (!validateForm()) {
      return;
    }
    // Calling API Meej/policyTransferRequest
    console.log("Should submit with form new user", formData);
    console.log("old user", userInfo.CPR, userInfo.PhoneNumber);

    const payload = {
      oldCustomerCPR: userInfo.CPR,
      oldCustomerPhone: userInfo.PhoneNumber,
      newCustomerCPR: formData.cpr,
      newCustomerPhone: formData.mobile,
      policyNumber: formData.policy_no,
    };

    try {
      // Calling API /Meej/policyTransferRequest
      const startEndorsementAction = await dispatch(transferRequest(payload));
      const startEndorsementResult = unwrapResult(startEndorsementAction);

      console.log("requestPolicyTransferResponse", startEndorsementResult);

      // Check if the API call was successful
      if (startEndorsementResult && startEndorsementResult.StatusCode == 0) {
        toast.success(
          "An SMS was sent to the new owner to get his confirmation."
        );
        updateOwnerNumber(formData.mobile);
        switchToggle();
      } else if (
        startEndorsementResult &&
        startEndorsementResult.StatusCode != 0
      ) {
        toast.error(startEndorsementResult.Message);
      }
    } catch (error) {
      toast.error(
        "Error during policy transfer request:",
        requestPolicyTransferStatusCode
      );
      // Handle any errors, if necessary
    }
  };

  return (
    <RenewMotorLayout
      show_close_btn
      is_hide_back_btn
      btn_onClick={continueClickHandler}
    >
      <div className="max-w-[100%] w-[500px] mx-auto bg-white max767:p-5 max767:pt-0">
        <TitleDescription
          title={t("motorInsurance.transferPolicyFlow.newOwnerdetails.title")}
          description={t(
            "motorInsurance.transferPolicyFlow.newOwnerdetails.description"
          )}
        />
        <div className="flex flex-col items-center gap-2.5 self-stretch p-4 bg-[#F6F8F9] rounded-[26px] mb-6">
          <AlertComponent
            title={t(
              "motorInsurance.transferPolicyFlow.newOwnerdetails.disclaimer"
            )}
          />
          <div className="flex items-center self-stretch bg-white rounded-[10px]">
            <p className="text-[#5B6871] text-sm not-italic font-medium leading-6 justify-between flex px-6 py-2.5 w-full max767:flex-col">
              <span className="text-[#5B6871] text-sm not-italic font-medium leading-6">
                {t(
                  "motorInsurance.transferPolicyFlow.newOwnerdetails.policy_number"
                )}
                {"   "}
              </span>
              <span className="text-[#1A2024] text-sm not-italic font-medium leading-6">
                {staticData.policy_no}
              </span>
            </p>
          </div>
        </div>
        <Input
          label={t(
            "motorInsurance.transferPolicyFlow.newOwnerdetails.label_cpr"
          )}
          type="text"
          className="placeholder-black placeholder:font-normal "
          handleChange={handleChange}
          name="cpr"
          value={formData.cpr}
          error={errors?.cpr}
        />
        <div className="input-column w-full transferpolicyinput">
          <TelInput
            name="mobile"
            value={formData.mobile}
            onChange={handleChange}
            label={t(
              "motorInsurance.transferPolicyFlow.newOwnerdetails.label_mobile"
            )}
            className="mobilenumber h-[47px]"
          />
        </div>
      </div>
    </RenewMotorLayout>
  );
};

export default NewOwnerDetails;
