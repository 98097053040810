export const FLOW_TYPE_USED_CAR_WITHOUT_OC = "FLOW_TYPE_USED_CAR_WITHOUT_OC";
export const FLOW_TYPE_USED_CAR_WITH_OC = "FLOW_TYPE_USED_CAR_WITH_OC";
export const FLOW_TYPE_USED_CAR_THIRD_PARTY_PLAN = "FLOW_TYPE_USED_CAR_THIRD_PARTY_PLAN";
export const FLOW_TYPE_NEW_CAR = "FLOW_TYPE_NEW_CAR";

export const STEP_NAME_START = "start";
export const STEP_NAME_CAR = "car";
export const STEP_NAME_PLAN = "plan";
export const STEP_NAME_AD_DONS = "ad_dons";
export const STEP_NAME_UPLOAD = "upload";
export const STEP_NAME_PAY = "pay";

const insurance_start_step = {
    name: STEP_NAME_START,
    substeps: [
        'basic_info',
        'documents_ready',
    ]
};

export const new_motor_insurance_flow = {
    [FLOW_TYPE_USED_CAR_WITHOUT_OC]: [
        insurance_start_step,
        {
            name: STEP_NAME_CAR,
            substeps: [
                'about_car', // is brand new? ownership exists?
                'car_info_number_plate', // plate number with gdt
                'new_car_detail', // car info page
                'car_financing', // sum insured and banks
            ]
        },
        {
            name: STEP_NAME_PLAN,
            substeps: [
                'choose_plan',
            ]
        },
        {
            name: STEP_NAME_AD_DONS,
            substeps: [
                'enhance_coverage',
            ]
        },
        {
            name: STEP_NAME_UPLOAD,
            substeps: [
                'upload_req_docs',
            ]
        },
        {
            name: STEP_NAME_PAY,
            substeps: [
                'review_and_confirm',
                'payment_method',
                'payment_congratulations'
            ]
        },
    ],
    [FLOW_TYPE_USED_CAR_WITH_OC]: [
        insurance_start_step,
        {
            name: STEP_NAME_CAR,
            substeps: [
                'about_car',
                'car_info_confirm',
                // 'new_car_detail', // car info page
                'car_financing',
            ]
        },
        {
            name: STEP_NAME_PLAN,
            substeps: [
                'choose_plan',
            ]
        },
        {
            name: STEP_NAME_AD_DONS,
            substeps: [
                'enhance_coverage',
            ]
        },
        {
            name: STEP_NAME_UPLOAD,
            substeps: [
                'upload_req_docs',
            ]
        },
        {
            name: STEP_NAME_PAY,
            substeps: [
                'review_and_confirm',
                'payment_method',
                'payment_congratulations'
            ]
        },
    ],
    [FLOW_TYPE_USED_CAR_THIRD_PARTY_PLAN]: [
        insurance_start_step,
        {
            name: STEP_NAME_CAR,
            substeps: [
                'about_car',
                'car_info_confirm',
                'car_financing',
            ]
        },
        {
            name: STEP_NAME_PLAN,
            substeps: [
                'choose_plan',
            ]
        },
        {
            name: STEP_NAME_AD_DONS,
            substeps: [
                'enhance_coverage',
            ]
        },
        {
            name: STEP_NAME_UPLOAD,
            substeps: [
                'upload_photos_of_car',
                'upload_req_docs',
            ]
        },
        {
            name: STEP_NAME_PAY,
            substeps: [
                'review_and_confirm',
                'payment_method',
                'payment_congratulations'
            ]
        },
    ],
    [FLOW_TYPE_NEW_CAR]: [
        insurance_start_step,
        {
            name: STEP_NAME_CAR,
            substeps: [
                'about_car',
                'new_car_detail',
                'car_financing',
            ]
        },
        {
            name: STEP_NAME_PLAN,
            substeps: [
                'choose_plan',
            ]
        },
        {
            name: STEP_NAME_AD_DONS,
            substeps: [
                'enhance_coverage',
            ]
        },
        {
            name: STEP_NAME_UPLOAD,
            substeps: [
                // 'upload_photos_of_car',
                'upload_req_docs',
            ]
        },
        {
            name: STEP_NAME_PAY,
            substeps: [
                'review_and_confirm',
                'payment_method',
                'payment_congratulations'
            ]
        },
    ],
};
