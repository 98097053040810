import React, { useState } from 'react'
import ChangeExistingFunds from './changeExistingFunds';
import ReviewAndConfirm from './reviewAndConfirm';

const WithdrawalFlow = () => {
    const [toggle, setToggle] = useState(false)
    const [formData, setFormData] = useState({});

    const switchToggle = () => {
        setToggle(state => !state);
    }
    return (
        <div className="mainCarDetailsModal pt-[80px] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll max767:pt-[0]">
            {
                !toggle ?
                    <ChangeExistingFunds
                        formData={formData}
                        setFormData={setFormData}
                        switchToggle={switchToggle}
                    /> :
                    <ReviewAndConfirm
                        formData={formData}
                        setFormData={setFormData}
                        switchToggle={switchToggle}
                    />
            }
        </div>
    )
}

export default WithdrawalFlow