
import RoadAssistance from "../../../src/assets/images/handyman.svg";
import gulfassistence from "../../../src/assets/images/gulfassistence.svg";
import afroassian from "../../../src/assets/images/afroassian.svg";
import aaaWarrantyServices from "../../../src/assets/images/aaaWarrantyServices.png";
import homeassistence from "../../../src/assets/images/home_outline.svg";
import localairport from "../../../src/assets/images/local_airport.svg";
import local_convenience_store from "../../../src/assets/images/local_convenience_store.svg";
import luminext from "../../../src/assets/images/luminext.svg";
import health360 from "../../../src/assets/images/health360.svg";

export const userPolicyCards = [
  {
    policy_name: "Motor Policy - Flexi Plan",
    policy_label: "Dodge Durango - 516914",
    policy_number: "P/003/14/20/8003/0004341-05",
    from_date: "31 January 2025",
    to_date: "31 January 2025",
    id: "1",
  },
  {
    policy_name: "Home Policy",
    policy_label: "Saar House",
    policy_number: "P/003/14/20/8003/0004341-05",
    from_date: "31 January 2025",
    to_date: "31 January 2025",
    expire_days: "31",
    is_text_dark: true,
    id: "2",
  },
];

export const transferPolicyData = {
    "ModelYear": "2003",
    "Make": "PORSCHE",
    "Model": "CAYENNE",
    "CPR": "960102361",
    "PolicyNumber": "P/003/07/20/8004/0-03",
    "RegistrationNumber": "19499",
    "SumInsured": 0.0,
    "PlanName": "TPL",
    "ProductCode": "0101",
    "ProductName": "Motor",
    "StartDate": "2023-04-02",
    "EndDate": "2024-05-31",
    "FromDateStr": "02/04/2023",
    "ToDateStr": "31/05/2024",
    "Branch": "Seef Mall",
    "MainChannel": "Direct Bahrain",
    "Renewed": "Yes",
    "PolicyStatus": "EXPIRED",
    "IsRenewable": false
}

export const FUNDS_PREMIUM_TABLE_DATA = [
  {
    id: 1,
    fund_name: "ADCB Islamic GCC Equity 'R' (USD) ACC",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
  },
  {
    id: 2,
    fund_name: "OASIS Crescent Global Income 'A' (USD) ACC",
    units_held: 459.247,
    current_price: 9.4789,
    total_value: "BHD 473.89",
    current_percent: "10%",
    new_percent: 10,
  },
  {
    id: 3,
    fund_name: "SNB Capital GCC Growth and Income Fund",
    units_held: 459.247,
    current_price: 9.4789,
    total_value: "BHD 473.89",
    current_percent: "10%",
    new_percent: 10,
  },
  {
    id: 4,
    fund_name: "TATA Indian Sharia Equity 'B' (USD)",
    units_held: 12.47,
    current_price: 18.6584,
    total_value: "BHD 1,884.354",
    current_percent: "10%",
    new_percent: 10,
  },
  {
    id: 5,
    fund_name: "AZ Islamic MAMG Global Sukuk 'A-ME' (USD) ACC",
    units_held: 12.47,
    current_price: 18.6584,
    total_value: "BHD 1,884.354",
    current_percent: "10%",
    new_percent: 10,
  },
  {
    id: 6,
    fund_name: "COMGEST Growth Europe 'S' (USD) ACC",
    units_held: 178.67,
    current_price: 2.3488,
    total_value: "BHD 364.218",
    current_percent: "10%",
    new_percent: 10,
  },
  {
    id: 7,
    fund_name: "Templeton Shariah Global Equity Fund 'A' (USD)",
    units_held: 178.67,
    current_price: 2.3488,
    total_value: "BHD 364.218",
    current_percent: "10%",
    new_percent: 10,
  },
  {
    id: 8,
    fund_name: "AZ Islamic MAMG Global Sukuk 'A-ME' (USD) ACC",
    units_held: 178.67,
    current_price: 2.3488,
    total_value: "BHD 364.218",
    current_percent: "10%",
    new_percent: 10,
  },
  {
    id: 9,
    fund_name: "COMGEST Growth Europe 'S' (USD) ACC",
    units_held: 178.67,
    current_price: 2.3488,
    total_value: "BHD 364.218",
    current_percent: "10%",
    new_percent: 10,
  },
  {
    id: 10,
    fund_name: "CIMB Islamic ASEAN Equity Fund 'A' (USD) ACC",
    units_held: 584.17,
    current_price: 47.6489,
    total_value: "BHD 1,483.368",
    current_percent: "10%",
    new_percent: 10,
  },
];

export const emergencyServicesData = [
  {
    type: "road",
    data: {
      title_en: "Road Assistance",
      title_ar: "المساعدة على الطريق",
      icon: RoadAssistance,
      altText_en: "Road",
      altText_ar: "طريق",
    },

    items: [
      {
        label_en: "MAPFRE (24hr Gulf Assist)",
        label_ar: "MAPFRE (مساعدة الخليج على مدار 24 ساعة)",
        phone: "+973 17221633",
        icon: gulfassistence,
      },
      {
        label_en: "AAA Warranty Services",
        label_ar: "خدمات المساعدة من AAA",
        phone: "+973 17223351",
        icon: aaaWarrantyServices,
      },
    ],
  },
  {
    type: "home",
    data: {
      title_en: "Home Assistance",
      title_ar: "المساعدة المنزلية",
      icon: homeassistence,
      altText_en: "Home",
      altText_ar: "طريق",
    },
    items: [
      { 
        label_en: "Home Assistance",
        label_ar:"المساعدة المنزلية",
         phone: "+973 17576642" },
      { 
        label_en: "Airport Delivery Services", 
        label_ar: "توصيل المطار", 
        phone: "+973 17218925" },
    ],
  },
  {
    type: "travel",
    data: {
      title_en: "Travel Assistance",
      title_ar: "مساعدة سفر",
      icon: localairport,
      altText_en: "Travel",
      altText_ar: "طريق",
    },
    items: [
      { 
        label_en: "Travel Assistance",
        label_ar: "مساعدة سفر",
         phone: "+962 6500 8119" },
      {
        label_en: "Travel Assistance",
        label_ar: "مساعدة سفر",
        link: "https://whatsapp.com",
        linkTextEn: "Whatsapp",
        linkTextAr: "واتساب",
      },
    ],
  },
  {
    type: "medical",
    data: {
      title_en: "Medical Assistance",
      title_ar: "مساعدة طبية",

      icon: local_convenience_store,
      altText_en: "Medical",
      altText_ar: "طبي",
    },
    items: [
      {
        label_en: "Lumi by Next Care",
        label_ar: "Lumi من Next Care",
        gPayLink:
          "https://play.google.com/store/apps/details?id=com.nextcare.app&hl=en&gl=US",
        iosLink: "https://apps.apple.com/ae/app/lumi-by-nextcare/id1039800748",
        icon: luminext,
      },
      {
        label_en: "Health 360",
        label_ar: "هلث 360",
        gPayLink:
          "https://play.google.com/store/apps/details?id=com.health360.app&hl=en&gl=US",
        iosLink:
          "https://apps.apple.com/bh/app/health-360-ancillary-services/id1273593493",
        icon: health360,
      },
    ],
  },
];


export const FAQS_TYPES = {
  claim : "claims",
  highlights : "highlights",
}