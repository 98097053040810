import React, { useEffect, useMemo, useState } from "react";
import RenewMotorLayout from "../../../../../motorInsurancePages/renewalMotorPolicy/renewMotorLayout";
import TitleDescription from "../../../../../../components/newMotorPolicy/titleDescription/titleDescription";
import useLanguageTranslate from "../../../../../../hooks/useLanguageTranslate";
import FundsPremiumTable from "../../../../../../components/common/investments/fundsPremiumTable";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import MobileFundsPremiumTableAlert from "../../../../../../components/common/investments/mobileFundsPremiumTableAlert";
import useScreenResolution from "../../../../../../hooks/useScreenResolution";
import useCreateLifeRequests, {
  LIFE_REQUEST_TYPE,
} from "../../../../../../hooks/life/useCreateLifeRequests";
import { decodeQueryString } from "../../../../../../utils";

const FundsRedirectingPage = ({
  switchToggle,
  userFunds,
  setUserFunds,
  isUserChangedFunds,
  setIsUserChangedFunds,
  originalFunds,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { search } = useLocation();
  const [total, setTotal] = useState(100);
  const [errorMsg, setErrorMsg] = useState("");
  const [allocationTotal, setAllocationTotal] = useState(0);
  const screenResolution = useScreenResolution();
  const { isLoading: requestLoading, createLifeRequest } =
    useCreateLifeRequests();

  const params = decodeQueryString();
  const continueClickHandler = async () => {
    let currentFundIsinIds = userFunds?.map((each) => each?.isin);
    let removedFunds = originalFunds?.filter(
      (each) => !currentFundIsinIds?.includes(each?.isin)
    );
    if (isUserChangedFunds) {
      let payload = {
        PolicyNumber: params?.policy,
        RequestType: LIFE_REQUEST_TYPE.fundRedirectionAdd,
        Funds: userFunds?.map((each) => ({
          ISIN: each?.isin,
          NumberOfUnits: each?.units_held || 0,
          FundValue: each?.total_value || 0,
          FundName: each?.fund_name || "",
        })),
        RemovedFunds: removedFunds?.map((each) => ({
          ISIN: each?.isin,
          NumberOfUnits: each?.units_held || 0,
          FundValue: each?.total_value || 0,
          FundName: each?.fund_name || "",
        })),
      };
      console.log(payload, "some payload");
      const response = await createLifeRequest(payload);
      console.log(response, "some response");
      navigate(`/life-insurance/policy-overview${search}`);
    }
  };
  const addFundHandler = () => {
    switchToggle();
  };

  const backClickHandler = () => {
    navigate(`/life-insurance/fund-management${search}`);
  };

  console.log("error msg===", errorMsg);

  return (
    <RenewMotorLayout
      showTableData
      backClickHandler={backClickHandler}
      btn_label={isUserChangedFunds ? "Confirm" : "Continue"}
      btn_onClick={continueClickHandler}
      AdditionalComponent={
        screenResolution.mobile && (
          <MobileFundsPremiumTableAlert
            total={total}
            errorMsg={errorMsg}
            allocationTotal={allocationTotal}
            fundsData={userFunds}
            tableType="redirecting"
          />
        )
      }
      btn_disabled={requestLoading || total != 100 || errorMsg}
    >
      <div className="changeplatenumber w-[500px] max-w-[100%] mx-auto bg-white max767:p-5 max767:pb-0">
        <TitleDescription
          title={t(
            "lifeInsurance.investmentStrategyFlow.fundsRedirectingFlow.fundsRedirectingPage.title"
          )}
          description={t(
            "lifeInsurance.investmentStrategyFlow.fundsRedirectingFlow.fundsRedirectingPage.description"
          )}
        />
      </div>
      <div className="w-[1200px] max-w-[100%] mx-auto max767:h-auto max767:mb-[90px] FundsPremiumTable">
        <FundsPremiumTable
          fundsData={userFunds || []}
          setFundsData={setUserFunds}
          tableType="redirecting"
          addFundHandler={addFundHandler}
          setIsUserChangedFunds={setIsUserChangedFunds}
          total={total}
          setTotal={setTotal}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          allocationTotal={allocationTotal}
          setAllocationTotal={setAllocationTotal}
        />
      </div>
    </RenewMotorLayout>
  );
};

export default FundsRedirectingPage;
