import { useDispatch, useSelector } from 'react-redux';
import { selectLead, selectLeadLoader, updateLead } from '../../redux/features/expressSheild/leadSlice';

const useUpdateLead = () => {
    const dispatch = useDispatch();
    const leadData = useSelector(selectLead);
    const leadLoader = useSelector(selectLeadLoader);

    const updateLeadHandler = (payload = {}) => {
        let data = {
            lead_id: leadData.lead_id,
            status: 0,
            ...payload
        }
        dispatch(updateLead(data))
    }
    return {updateLeadHandler, leadLoader}
}

export default useUpdateLead