import React from 'react';
import useSummary from '../../../hooks/useSummary';
import { formatToThreeDigits } from '../../../utils';
import useLanguageTranslate from '../../../hooks/useLanguageTranslate';
import visalogo from "../../../assets/images/visaLogoblue.svg";

const RefundSummary = ({
  quotationNumber = "Q241501010106997-01"
}) => {
  const { discount_amount,coverage_enhance_data,promocode,discount_percent, total_amount , premium,discount,discount_percentage,vat_additional_amount} = useSummary();
  let total = parseInt(vat_additional_amount||0) + total_amount - (discount_amount || 0);
  const translate=useLanguageTranslate()
  return (
    <div className="rounded-lg summary-conntent">
      <div className=" flex items-center justify-between bg-[#E8F4D8] px-4 py-2.5 rounded-md mb-2 mt-4 px-0 py-2.5">
        <span className='text-[#5B6871] text-xs not-italic font-medium leading-6'>{translate("Quotation Number","رقم الاقتباس")}</span>
        <span className='text-[#5B6871] text-xs not-italic font-medium leading-6'>{quotationNumber}</span>
      </div>{" "}
      <div className="flex justify-between mb-2 px-0 py-2.5">
        <span className='text-[#1A2024] text-sm not-italic font-normal leading-6'>{translate("Refund Amount","غالي")}</span>
        <span className='text-[#1A2024] text-sm not-italic font-normal leading-6'>BHD {premium}</span>
      </div>

      <div className="flex justify-between mb-2 px-0 py-2.5 border-t-[#DDE2E4] border-t border-solid">
        <span className='text-[#1A2024] text-sm not-italic font-normal leading-6'>{translate("Refund Method","ضريبة القيمة المضافة ")} (10%)</span>
        <span className='text-[#1A2024] text-sm not-italic font-normal leading-6 flex align-middle gap-1 items-center'>
            <img src={visalogo} alt="visa" />
            ***4578
        </span>
      </div>
      <div className="flex justify-between px-0 py-2.5 border-t-[#DDE2E4] border-t border-solid">
        <span className='text-[#1A2024] text-sm not-italic  leading-6'>{translate("Refund Expect Date","المجموع")}</span>
        <span className='text-[#1A2024] text-sm not-italic  leading-6'>26 Jan 2026</span>
      </div>
    </div>
  );
};

export default RefundSummary;