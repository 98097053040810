import { useState } from "react";
import { expressShieldEndPoints } from "../../api";
import Axios from "../../redux/axiosInstance";
import { toast } from "react-toastify";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";


const useExpressShieldParty = () => {
    const [checkPartyLoading, setCheckPartyLoading] = useState(false);
    const [createPartyLoading, setCreatePartyLoading] = useState(false);
    const translate = useLanguageTranslate();

    const checkPartyExistApi = async (payload) => {
        setCheckPartyLoading(true);
        try {
            let response = await Axios.post(expressShieldEndPoints.checkParty, payload);
            return response;
        } catch (error) {
            console.error("Error checking party existence:", error);
            toast.error(translate(
                error.response.data.ErrorMessageEn,
                error.response.data.ErrorMessageAr
            ))
            return error;
        } finally {
            setCheckPartyLoading(false);
        }
    };

    const createPartyApi = async (payload) => {
        setCreatePartyLoading(true);
        try {
            let response = await Axios.post(expressShieldEndPoints.partyCreate, payload);
            return response;
        } catch (error) {
            console.error("Error creating party:", error);
            toast.error(translate(
                error.response.data.ErrorMessageEn,
                error.response.data.ErrorMessageAr
            ))
            return error;
        } finally {
            setCreatePartyLoading(false);
        }
    };

    return {
        checkPartyLoading,
        createPartyLoading,
        checkPartyExistApi,
        createPartyApi,
    };
};

export default useExpressShieldParty;
