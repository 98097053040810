import React from "react";
import { Link } from "react-router-dom";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";

const LinkSelector = ({ item, handleChange, ClassName }) => {
  const translate = useLanguageTranslate();
  return (
    <>
      {item?.is_internal ? (
        <Link to={item.link} className={`${ClassName}`} onClick={handleChange}>
          {item?.icon && (
            <span>
              <img
                src={item?.icon?.data}
                alt={translate(item?.icon?.img_alt_en, item?.icon?.img_alt_ar)}
                className="mr-2 rtl:ml-2"
              />
            </span>
          )}
          {translate(item?.label_en, item?.label_ar)}
        </Link>
      ) : (
        <a href={item?.link||"#"} className={`${ClassName}`} onClick={handleChange}>
          {item?.icon && (
            <span>
              <img
                src={item?.icon?.data}
                alt={translate(item?.icon?.img_alt_en, item?.icon?.img_alt_ar)}
                className="mr-2 rtl:ml-2 "
              />
            </span>
          )}
          {translate(item?.label_en, item?.label_ar)}
        </a>
      )}
    </>
  );
};

export default LinkSelector;
