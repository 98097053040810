import { useEffect, useState } from "react";
import TelInput from "../../../components/common/telInput/telInput";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import Input from "../../../components/common/Input/Input";
import Button from "../../../components/common/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  setForgotPasswordDetails,
  forgotPassword,
  selectIsLoading,
} from "../../../redux/features/forgotPasswordFlow/forgotPasswordSlice";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { sendOtp } from "../../../redux/features/sendOtpSlice";
import Loader from "../../../components/common/Loader/Loader";
import useChangeMobileNumberFlow from "../../../hooks/useChangeMobileNumberFlow";
import RenewMotorLayout from "../../motorInsurancePages/renewalMotorPolicy/renewMotorLayout";
import { unwrapResult } from "@reduxjs/toolkit";
import { sendToken } from "../../../redux/features/account/accountOtpSlice";
import useMotorForm from "../../../hooks/useMotorFormData";
import useValidation from "../../../hooks/useValidation";
import { CHANGE_EMAIL_FLOW } from "../changeMobileNumberFlow/data";
import useScreenResolution from "../../../hooks/useScreenResolution";
export const CHANGE_MOBILE_OR_EMAIL_FLOW_UPDATED_EMAIL =
  "CHANGE_MOBILE_OR_EMAIL_FLOW_UPDATED_EMAIL";
function ChangeEmailPage({ onSuccess }) {
  const { handlePrevSubstep, handleNextSubstep, handleFlowChange } =
    useChangeMobileNumberFlow();

  // useEffect(() => {
  //   handleFlowChange(CHANGE_EMAIL_FLOW,1);
  // }, []);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const isLoading = useSelector(selectIsLoading);
  const { errors, validateFields } = useValidation();
  const screenResolution = useScreenResolution();
  const [formData, setFormData] = useState({
    email: "",
  });

  const { saveMotorDataInRedux } = useMotorForm(
    CHANGE_MOBILE_OR_EMAIL_FLOW_UPDATED_EMAIL,
    setFormData
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    let update = { ...formData, [name]: value };
    setFormData(update);
    validateForm(update);
    
  };

  const validateForm = (data) => {
    let isValid = validateFields(
      [
        {
          name: "email",
          validations: ["required", "email"],
        },
      ],
      data ? data : formData
    );
    return isValid;
  };
  const NextHandler = async () => {
    if (validateForm()) {
      // try {
      //   const tokenOtpPayload = {
      //     email: formData.email,
      //   };
      //   // Calling API /account/sendtoken with mobile
      //   const tokenOtpAction = await dispatch(sendToken(tokenOtpPayload));
      //   const tokenOtpResult = unwrapResult(tokenOtpAction);
      //   // If the response is successful, process the phone number
      //   if (tokenOtpResult && tokenOtpResult.smsStatus =="MESSAGE_SENT") {
      //     // Mask the phonenumber e.g XXXXX908
      //     const maskedPhone = tokenOtpResult.to.replace(
      //       /(\d{3})(\d{5})(\d{3})/,
      //       "$1 XXXXX$3"
      //     );
      //     const updatedFormData = {
      //       ...formData,
      //       phone: formData.mobile,
      //       maskedPhone: maskedPhone,
      //       pinId: tokenOtpResult ? tokenOtpResult.pinId : "",
      //     };
      //     saveMotorDataInRedux(updatedFormData);
      //     handleNextSubstep();
      //   } else if (
      //     tokenOtpResult.requestError.serviceException.messageId ===
      //     "THROTTLE_EXCEPTION"
      //   ) {
      //     toast.error(tokenOtpResult.requestError.serviceException.text);
      //   }
      // } catch (error) {
      //   toast.error("Failed to send token:", error);
      // }
    }
    handleNextSubstep();
  };

  return (
    <RenewMotorLayout
      is_bottom_sticky_layout={screenResolution.mobile}
      btn_onClick={NextHandler}
      backClickHandler={() => handlePrevSubstep()}
      btn_disabled={!formData.email || errors.email}
      btn_label={"Continue"}
    >
      {isLoading && <Loader className="" />}
      <div className="mx-auto bg-white max767:p-5 w-[500px] max-w-[100%] mt-20 max767:mt-20">
        <TitleDescription
          title={"Enter the new email address"}
          description={"enter the new email address"}
          hide_description={screenResolution.mobile}
        />

        <div className="input-columns w-full ">
          <Input
            label={"Email Address"}
            type="text"
            handleChange={handleChange}
            name="email"
            value={formData.email}
            error={errors.email}
            data-testid="email"
          />
        </div>

        {!screenResolution.mobile && (
          <Button
            type="submit"
            className="primary-button"
            onSubmit={NextHandler}
            label={"Continue"}
            disabled={!formData.email || errors.email}
          />
        )}
      </div>
    </RenewMotorLayout>
  );
}

export default ChangeEmailPage;
