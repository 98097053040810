import React from 'react'
import useLanguageTranslate from '../../../hooks/useLanguageTranslate';

const TitleDescription = ({
    title,
    description,
    hide_description = false,
    className
}) => {
    const translate = useLanguageTranslate();
    return (
        <div className={`titleDescription max767:mt-10  ${className}`}>
            <h1 className='text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mb-2 text-center max767:text-left max767:text-[px] max767:font-semibold  max767:rtl:text-right'>{title}</h1>
            {!hide_description && <p className='text-[#1A2024] text-base not-italic font-normal leading-6 tracking-[-0.16px] text-center mb-4 max767:text-left max767:rtl:text-right'>{description}</p>}
        </div>
    )
}

export default TitleDescription