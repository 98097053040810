import React, { useState } from "react";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import useFileClaimFlow from "../../../hooks/useFileClaimFlow";
import FileClaimMotorLayout from "./fileClaimMotorLayout";
import MotorPolicyOptionsCard from "../../../components/motorPolicyOptionsCard/motorPolicyOptionsCard";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { useTranslation } from "react-i18next";

const planOptions = [
  {
    key: "individualComprehensive",
    title_en: "Individual Comprehensive",
    title_ar: "فردي شامل",
    description_en: "For Flexi, Gold and Platinum plans policy holder",
    description_ar: "لحاملي وثائق خطط Flexi وGold وPlatinum",
  },
  {
    key: "individualThirdParty",
    title_en: "Individual Third-Party",
    title_ar: "طرف ثالث فردي",
    description_en: "For Third-Party and Third-Party + plans policy holder",
    description_ar: "لحاملي وثائق التأمين الخاصة بالطرف الثالث والطرف الثالث +",
  },
  {
    key: "corporateClient",
    title_en: "Corporate Client",
    title_ar: "العميل المؤسسي",
    description_en: "For Corporate policy holders",
    description_ar: "لحاملي وثائق التأمين للشركات",
  },
];
function PolicyPlansPage({selectedOption, setSelectedOption}) {
  
  const translate = useLanguageTranslate();
  const {t} = useTranslation()
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };
  const { handleNextSubstep } = useFileClaimFlow();
  const continueClickHanlder = () => {
    handleNextSubstep();
  };
  return (
    <FileClaimMotorLayout
      btn_disabled={!selectedOption}
      btn_onClick={continueClickHanlder}
    >
      <div className="mx-auto bg-white max767:p-5 w-[500px] max-w-[100%]">
        <div className="max767:hidden">
          <TitleDescription
            title={t("motorInsurance.fileAClaimFlow.policyPlansPage.title")}
            description={t("motorInsurance.fileAClaimFlow.policyPlansPage.description")}
          />
        </div>
        <div className="max767:block hidden max767:mb-6 max767:mt-20">
          <TitleDescription
            title={t("motorInsurance.fileAClaimFlow.policyPlansPage.title")}
            description={t("motorInsurance.fileAClaimFlow.policyPlansPage.mobile_description")}
          />
        </div>
        <div>
          {planOptions.map((plan) => (
            <MotorPolicyOptionsCard
              key={plan.key}
              title={translate(plan.title_en, plan.title_ar)}
              description={translate(plan.description_en, plan.description_ar)}
              isSelected={selectedOption ==plan.key}
              onSelect={() => handleOptionSelect(plan.key)}
            />
          ))}
        </div>
      </div>
    </FileClaimMotorLayout>
  );
}

export default PolicyPlansPage;
