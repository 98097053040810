import React from 'react'
import useLanguageTranslate from '../../../../hooks/useLanguageTranslate';

const ClaimsStepper = ({ activeStep, steps }) => {
    const translate = useLanguageTranslate();
    const stepIndex = steps.findIndex((step) => step?.key ==activeStep?.key);
  
    return (
      <div className="flex  justify-center items-center mx-auto my-0 inset-x-0 track-claim-stepper max767:ml-0 max767:mb-6">
        {steps.map((step, index) => (
          <div
            key={index}
            className="flex flex-col items-center relative w-[140px] max-w-[100%]: steper-title max767:w-max"
          >
            <div className="flex items-center w-full relative">
              {/* Connector line before the dot */}
              {index < steps.length - 1 && (
                <div
                  className={`h-[0.1rem] ${
                    index < stepIndex
                      ? "bg-[#55C6EF] flex-grow"
                      : "bg-[#DDE2E4] flex-grow"
                  }`}
                  style={{ transition: "background-color 0.5s ease-in-out" }}
                ></div>
              )}
              {/* Dot */}
              <div
                className={`w-1.5 h-1.5 ${
                  index ==stepIndex && "bg-[#55C6EF]"
                } rounded-full absolute left-0 transform -translate-x-1/2 rtl:right-0`}
                style={{ transition: "background-color 0.5s ease-in-out" }}
              ></div>
              {/* Connector line after the dot */}
              {index < steps.length - 1 && (
                <div
                  className={`h-[0.1rem] ${
                    index < stepIndex ? "bg-[#55C6EF]" : ""
                  }`}
                  style={{ transition: "background-color 0.5s ease-in-out" }}
                ></div>
              )}
            </div>
            {/* Label */}
            <div
              className={`mt-2 text-sm not-italic font-normal leading-6 w-full -ml-2.5 max767:ml-0 max767:mr-3 ${
                index ==stepIndex ? "text-[#1A2024] font-semibold " : "text-[#9AA6AC]"
              }`}
              style={{ alignSelf: "flex-start" }}
            >
              {translate(step.title_en ,step.title_ar)}
            </div>
          </div>
        ))}
      </div>
    );
}

export default ClaimsStepper
