import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import ServiceGrid from "../../components/common/serviceCards/serviceCards";
import faPlusCircleimg from "../../../src/assets/images/newpolicyicon.svg";
import faSyncAltimg from "../../../src/assets/images/Renewal.svg";
import faFileAltimg from "../../../src/assets/images/FileaClaim.svg";
import EmergencyServicesimg from "../../../src/assets/images/EmergencyServices.svg";

import faPlusCircleimgdark from "../../../src/assets/images/newpolicydark.svg";
import faFileAltimgdark from "../../../src/assets/images/FileaClaimdark.svg";
import faSyncAltimgdark from "../../../src/assets/images/Renewaldark.svg";
import EmergencyServicesimgdardk from "../../../src/assets/images/EmergencyServicesdark.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOffers,
  selectOffersData,
} from "../../redux/features/offersSlice";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import { emergencyServicesData, FUNDS_PREMIUM_TABLE_DATA } from "./data";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../components/common/Loader/Loader";
import MyPolicies from "./myPolicies";
import { selectLoginTokenInfo } from "../../redux/features/loginRegisterSlice";
import PolicyTransferRequestModal from "./policyTransferRequestModal";
import useScreenResolution from "../../hooks/useScreenResolution";

import faPlusCircleimgqa from "../../../src/assets/images/Roaddark.svg";
import faSyncAltimgdarkqa from "../../../src/assets/images/homegraybk.svg";
import faFileAltimgdarkqa from "../../../src/assets/images/Traveldark.svg";
import EmergencyServicesimgqa from "../../../src/assets/images/medicalicon.svg";

import { clearPolicyPremium } from "../../redux/features/motor/renewalPremiumSlice";
import { clearGetQuoteState } from "../../redux/features/motor/getQuoteSlice";
import { clearCancellationState } from "../../redux/features/endorsements/cancellationSlice";
import { clearPolicyPayState } from "../../redux/features/payment/policyPaySlice";
import BackdropModal from "../../components/common/modal/backdropModal";
import EmergencyServices from "../emergencyServices/EmergencyServices";
import { selectProfilePolicyTransferStatus } from "../../redux/features/profileSlice";
import { clearCalculatePremiumState } from "../../redux/features/motor/calculatePremiumSlice";
import { clearRenewalFlow } from "../../redux/features/motorInsuranceRenewFlowSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { clearMotorInsuranceFlow } from "../../redux/features/motorInsuranceFlowSlice";
import {
  clearMotorFormData,
  clearNewMotorFormData,
} from "../../redux/features/newMotorFormSlice";
const HeroSliderContent = lazy(() =>
  import("../../components/common/heroSlider/heroSliderContent")
);
const services = [
  {
    icon: faPlusCircleimg,
    micon: faPlusCircleimgdark,
    text: "New Policy",
    text_ar: " خطة جديدة",
    type: "new_policy",
  },
  {
    icon: faSyncAltimg,
    micon: faSyncAltimgdark,
    text: "Renewal",
    text_ar: "تجديد",
    type: "Renewal",
  },
  {
    icon: faFileAltimg,
    micon: faFileAltimgdark,
    text: "File a Claim",
    text_ar: "تقديم مطالبة",
    type: "Claim",
  },
  {
    icon: EmergencyServicesimg,
    micon: EmergencyServicesimgdardk,
    text: "Emergency Services",
    text_ar: "خدمات الطوارئ",
    type: "Emergency",
    hideInMobile: true,
  },
];
const assistanceServices = [
  {
    icon: faPlusCircleimg,
    micon: faPlusCircleimgqa,
    text: "Road",
    text_ar: "سياسة جديدة",
    type: "road",
  },
  {
    icon: faSyncAltimg,
    micon: faSyncAltimgdarkqa,
    text: "Home",
    text_ar: "تجديد",
    type: "home",
  },
  {
    icon: faFileAltimg,
    micon: faFileAltimgdarkqa,
    text: "Travel",
    text_ar: "تقديم مطالبة",
    type: "travel",
  },
  {
    icon: EmergencyServicesimg,
    micon: EmergencyServicesimgqa,
    text: "Medical",
    text_ar: "خدمات الطوارئ",
    type: "medical",
  },
];

const Dashboard = () => {
  const [showPolicyTransfer, setShowPolicyTransfer] = useState(false);
  const [showAssistanceService, setShowAssistanceService] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();
  const { state, pathname } = location;
  const translate = useLanguageTranslate();
  const { i18n, t } = useTranslation();
  const sliderRef1 = useRef(null);
  const screenResolution = useScreenResolution();

  const getPolicyTransferDetails = useSelector(
    selectProfilePolicyTransferStatus
  );
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const policyCardRef = useRef(null);

  useEffect(() => {
    console.log("Home Sweet Home");

    dispatch(fetchOffers());

    // Clear redux state on dashboard page
    dispatch(clearGetQuoteState());
    dispatch(clearPolicyPremium());
    dispatch(clearCancellationState());
    dispatch(clearPolicyPayState());
    dispatch(clearCalculatePremiumState());
    dispatch(clearRenewalFlow());
    dispatch(clearMotorInsuranceFlow());
    dispatch(clearMotorFormData());
    dispatch(clearNewMotorFormData());

    // Check if profile has pending policy transfer
    if (
      getPolicyTransferDetails &&
      getPolicyTransferDetails.length > 0 &&
      getPolicyTransferDetails[0].Status == "PENDING"
    ) {
      setShowPolicyTransfer(true);
    }
  }, [getPolicyTransferDetails, dispatch]);

  const offersReducerData = useSelector(selectOffersData) || [];
  const loginTokenInfo = useSelector(selectLoginTokenInfo);

  const navigate = useNavigate();
  const serviceClickHandler = (service) => {
    if (service == "new_policy") {
      navigate("/new-policy");
    } else if (service == "Renewal") {
      navigate("/motor-insurance/renew-motor-policy");
    } else if (service == "Claim") {
      navigate("/file-a-claim");
    } else if (service == "Emergency") {
      navigate("/emergency-services");
    }
  };
  const assistanceServiceClickHandler = (service) => {
    setShowAssistanceService(service);
  };
  useEffect(() => {
    if (pathname?.includes("policyTransfer")) {
      setShowPolicyTransfer(true);
    }
  }, [pathname]);

  const scroll = (direction) => {
    const container = policyCardRef.current;
    const scrollAmount = 300;
    if (!container) return;

    if (direction == "left") {
      container.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    } else {
      container.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }

    setTimeout(() => {
      updateScrollButtons();
    }, 300);
  };

  const updateScrollButtons = () => {
    const container = policyCardRef.current;
    if (!container) return;

    setCanScrollLeft(container.scrollLeft > 0);
    setCanScrollRight(
      container.scrollWidth > container.clientWidth + container.scrollLeft
    );
  };

  useState(() => {
    updateScrollButtons();
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <div>
        <PolicyTransferRequestModal
          show={showPolicyTransfer}
          setShow={setShowPolicyTransfer}
        />
        <div className="min-h-scree">
          {loginTokenInfo && (
            <div className="myPoliciesContainer max767:mt-[70px] p-6 pr-0 rtl:pl-0 rtl:pr-6 max767:p-5">
              <div className="flex justify-between alpbaseign-center pr-6 max767:pr-0 rtl:pl-6 rtl:pr-0">
                <h1 className="text-black text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mb-[8px] max767:mb-3">
                  {t("commonLabels.my_policies_label")}
                </h1>
                {screenResolution.mobile && (
                  <button
                    className="flex flex-col text-sm items-center gap-2.5 border font-semibold bg-white rounded-[10px] leading-normal px-3 py-1 max767:text-[#00384D] max767:text-sm max767:not-italic max767:font-medium max767:leading-[120%] max767:underline max767:border-0 max767:pr-0"
                    onClick={() => navigate("/my-policy")}
                  >
                    {t("dashboard.viewAll")}
                  </button>
                )}
                {!screenResolution.mobile && (
                  <div className="arrowbutn flex gap-2">
                    {/* Left Chevron */}
                    <button
                      onClick={() => scroll("left")}
                      disabled={!canScrollLeft}
                      className={`p-2 rounded-full h-[36px] w-[36px]  ${
                        canScrollLeft
                          ? "bg-[#55C6EF] text-[black]" // Active state
                          : "bg-[#E5E9EB] text-gray-400" // Disabled state
                      }`}
                    >
                      {" "}
                      <span className="flex justify-center">
                        {" "}
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          size=""
                          className="text-[12px] rtl:rotate-180"
                        />
                      </span>
                    </button>

                    {/* Right Chevron */}
                    <button
                      onClick={() => scroll("right")}
                      disabled={!canScrollRight}
                      className={`p-2 rounded-full h-[36px] w-[36px] ${
                        canScrollRight
                          ? "bg-[#55C6EF] text-[black]" // Active state
                          : "bg-[#E5E9EB] text-gray-400" // Disabled state
                      }`}
                    >
                      <span className="flex justify-center">
                        {" "}
                        <FontAwesomeIcon
                          icon={faChevronRight}
                          className="text-[12px] rtl:rotate-180"
                        />{" "}
                      </span>
                    </button>
                  </div>
                )}
              </div>
              <div
                className="  overflow-y-hidden overflow-x-auto md:overflow-x-hidden "
                ref={policyCardRef}
              >
                <div className="flex gap-4 policycard-slider">
                  <MyPolicies />
                </div>
              </div>
            </div>
          )}

          {state?.navigatedFromLink != "My Policy" &&
            offersReducerData &&
            screenResolution.mobile &&
            !loginTokenInfo && (
              <div className=" flex flex-col offersSection px-[18px] py-0  max767:mb-4 max767:mt-[70px] max767:p-5 max767:pb-0">
                <HeroSliderContent
                  sliders={offersReducerData}
                  sliderRef={sliderRef1}
                />
              </div>
            )}

          <div className="flex flex-col lifeinsurencepolicycard p-6 max767:pt-0 max767:p-5">
            <h1 className="text-black text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] pl-0 max767:pl-0 max767:mt-3">
              {t("dashboard.quick_actions_label")}
            </h1>
            <ServiceGrid
              services={services}
              serviceClickHandler={serviceClickHandler}
            />
          </div>
          {screenResolution.mobile && (
            <div className="flex flex-col Assistance max767:px-5 max767:py-0">
              <h1 className="text-black text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] pl-6 mt-4 max767:pl-0">
                {translate("Assistance Services", "خدمات المساعدة")}
              </h1>
              <ServiceGrid
                services={assistanceServices}
                serviceClickHandler={assistanceServiceClickHandler}
              />
            </div>
          )}
          {state?.navigatedFromLink != "My Policy" &&
            offersReducerData &&
            !(screenResolution.mobile && !loginTokenInfo) && (
              <div className=" flex flex-col offersSection  max767:mt-0 mt-[0px] p-6 max767:p-5">
                {
                  <h1 className="text-black text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] pl-0 mb-[8px] max767:hidden">
                    {t("dashboard.offers_label")}
                  </h1>
                }
                <HeroSliderContent
                  sliders={offersReducerData}
                  sliderRef={sliderRef1}
                />
              </div>
            )}
        </div>
        <div className="emregencymodal">
          <BackdropModal
            show={showAssistanceService}
            onClose={() => setShowAssistanceService("")}
            title={""}
            emergencyServiceData={emergencyServicesData?.filter(
              (each) => each.type == showAssistanceService
            )}
          >
            <EmergencyServices
              data={emergencyServicesData?.filter(
                (each) => each.type == showAssistanceService
              )}
              type={showAssistanceService}
            />
          </BackdropModal>
        </div>
      </div>
    </Suspense>
  );
};

export default Dashboard;
