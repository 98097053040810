import ResetPassword from "./ResetPassword";
import VerifyOtpPage from "../../signInRegisterPages/VerifyOtpPage/VerifyOtpPage";
import CreatePasswordPage from "../CreatePasswordPage/CreatePasswordPage";
import { startTransition, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/common/Header/Header";
import { toast } from "react-toastify";
import useScreenResolution from "../../../hooks/useScreenResolution";
import headerbackarrow from "../../../assets/images/back-arrow-left.svg";
import { useTranslation } from "react-i18next";

const PageState = {
  OTP_VERIFIED: "OTP_VERIFIED",
  PASSWORD_CREATED: "PASSWORD_CREATED",
  RESET_PASSWORD: "RESET_PASSWORD",
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mobile } = useScreenResolution();
  const [currentPage, setCurrentPage] = useState(PageState.RESET_PASSWORD);

  const handleSuccess = (nextPage) => () => {
    startTransition(() => {
      setCurrentPage(nextPage);
    });
  };

  const handleBackClicked = () => {
    startTransition(() => {
      switch (currentPage) {
        case PageState.RESET_PASSWORD:
          navigate("/");
          break;
        case PageState.PASSWORD_CREATED:
          setCurrentPage(PageState.OTP_VERIFIED);
          break;
        case PageState.OTP_VERIFIED:
          setCurrentPage(PageState.RESET_PASSWORD);
          break;
        default:
          break;
      }
    });
  };
  const redirectToSignIn = () => {
    toast.success("Password updated successfully");
    navigate("/sign-in");
  };

  const PageRenderer = () => {
    switch (currentPage) {
      case PageState.RESET_PASSWORD:
        return (
          <ResetPassword onSuccess={handleSuccess(PageState.OTP_VERIFIED)} />
        );
      case PageState.OTP_VERIFIED:
        return (
          <VerifyOtpPage
            onSuccess={handleSuccess(PageState.PASSWORD_CREATED)}
            onBackClick={handleBackClicked}
            titleDesc={
              mobile
              ? t("forgot_password_flow.verify_otp_page.title_desc_mobile")
              : t("forgot_password_flow.verify_otp_page.title_desc_code")
            }
          />
        );
      case PageState.PASSWORD_CREATED:
        return (
          <CreatePasswordPage
            onBackClick={handleBackClicked}
            pageTitle={t("forgot_password_flow.create_password_page.page_title")}
            onSuccess={redirectToSignIn}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Header
        showBackBtn={mobile}
        isPopup
        onCloseModal={() => {
          startTransition(() => {
            navigate("/");
          });
        }}
      />

      <div className="mainSignupModal relative">
        {mobile && <button
          className="bg-navy-blue py-2 rounded  left-6 rtl:right-4 absolute flex "
          onClick={handleBackClicked}
        >
          <img
            src={headerbackarrow}
            alt="back arrow"
            className="mr-2.5 1 rtl:ml-[5px] rtl:rotate-[181deg] "
          />
          <span className="back-text inline max767:hidden">Back</span>
        </button>}
        <PageRenderer />
      </div>
    </>
  );
};

export default ForgotPassword;
