import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import { expressShieldEndPoints } from "../../../api";


const initialState = {
    data: null,
    isLoading: false,
    success: null,
    error: null,
};


export const createLead = createAsyncThunk(
    "createLead",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await Axios.post(`${expressShieldEndPoints.createLead}`, payload);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);
export const updateLead = createAsyncThunk(
    "updateLead",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await Axios.post(`${expressShieldEndPoints.updateLead}`, payload);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const leadSlice = createSlice({
    name: "Create Lead Slice",
    initialState,
    reducers: {
        clearLeadError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        // FETCH  CREATE LEAD API
        builder.addCase(createLead.pending, (state, action) => {
                state.isLoading = true;
        });
        builder.addCase(createLead.fulfilled, (state, action) => {
            state.isLoading = false;
            state.success = true;
            state.data = action?.payload;
        });
        builder.addCase(createLead.rejected, (state, action) => {
            state.isLoading = false;
            state.success = false;
            state.error = action.error?.message;
        });
        // FETCH  UPDATE LEAD API
        builder.addCase(updateLead.pending, (state, action) => {
            if (!state.data) {
                state.isLoading = true;
            }
        });
        builder.addCase(updateLead.fulfilled, (state, action) => {
            state.isLoading = false;
            // state.success = true;
            // state.data = action?.payload;
        });
        builder.addCase(updateLead.rejected, (state, action) => {
            state.isLoading = false;
            state.success = false;
            state.error = action.error?.message;
        });
    },
});

// SELECTORS
export const selectLeadWholeData = (store) => store?.expressLead;
export const selectLeadLoader = (store) => store?.expressLead.isLoading;
export const selectLead = (store) => store?.expressLead.data;


// ACTIONS TO DISPATCH
export const { clearLeadError } = leadSlice.actions;

export default leadSlice.reducer;
