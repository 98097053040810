import React, { useEffect, useState } from "react";
import StepIndicator from "../../../../assets/images/Step_indicator.svg";
import Button from "../../../../components/common/Button/Button";
import searchimg from "../../../../assets/images/graySearch.svg";
import { garages } from "../data";
import Back_Btndark from "../../../../assets/images/greenbackarrow.svg";
import backarrow from "../../../../assets/images/back-arrow-left.svg";

import { useNavigate } from "react-router-dom";
import useScreenResolution from "../../../../hooks/useScreenResolution";
import { useTranslation } from "react-i18next";

const GarageSelection = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [selectedGarage, setSelectedGarage] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const { mobile } = useScreenResolution();
  const [garageList, setGarageList] = useState(null);
  const [error, setError] = useState("");

  const handleGarageChange = (garage) => {
    setSelectedGarage(garage);
    setError(""); 
  };

  const handleSuccess = () => {
    if (!selectedGarage) {
      setError(t("This filed is required"));
      return;
    }
    navigate("/motor-insurance/claim-details/123");
  };

  useEffect(() => {
    if (searchQuery.trim() =="") {
      setGarageList(garages);
    } else {
      const filteredList = garages.filter(garage =>
        garage.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setGarageList(filteredList);
    }
  }, [searchQuery]);

  return (
    <div className="mx-auto bg-white max767:p-5 w-[500px] max-w-[100%] mt-[5%] ipad:mt-[8%]">
      {!mobile ? (
        <button
          className="backButton bg-navy-blue py-2 rounded mt-20 flex absolute left-[26px] top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:bg-[#F6F8F9] max767:w-10 max767:h-10 max767:text-center max767:justify-center max767:rounded-3xl max767:mt-12 max767:ml-[-11px] rtl:right-[26px]"
          onClick={() => navigate("/motor-insurance/claim-details/123")}
        >
          <img src={backarrow} alt="Back arrow" />
          <span className="back-text inline max767:hidden">{t("motorInsurance.trackClaimFlow.garageSelection.backButton")}</span>
        </button>
      ) : (
        <img
          src={Back_Btndark}
          alt="Back"
          className="max767:visible w-10 h-10 relative z-20"
          onClick={() => navigate("/motor-insurance/claim-details/123")}
        />
      )}
      <div className="mx-auto p-4">
        <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] text-center p-4">
          {t("motorInsurance.trackClaimFlow.garageSelection.title")}
        </h1>
        <div className="flex relative">
          <img src={searchimg} alt="search icon" className="absolute left-2 top-6" />

          <input
            type="text"
            placeholder={t("motorInsurance.trackClaimFlow.garageSelection.searchPlaceholder")}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="flex h-10 items-start gap-2 flex-[1_0_0] border bg-white pr-4 py-2 rounded-md border-solid border-[#DDE2E4] mb-4 mt-4 pl-10 placeholder:text-sm placeholder:text-[#9AA6AC]"
          />
        </div>
        <ul className="space-y-2">
          {garageList?.map((garage, index) => (
            <li key={index} className="flex items-center justify-between self-stretch px-0 py-3 border-b-[#DDE2E4] border-b border-solid">
              <label className="flex items-center w-full cursor-pointer">
                <span className="flex-grow text-base not-italic font-normal text-[#252C32]">{garage.name}</span>
                {selectedGarage ==garage.name ? (
                  <img src={StepIndicator} alt="Selected" className="w-5 h-5" />
                ) : (
                  <input
                    type="radio"
                    name="garage"
                    value={garage.name}
                    onChange={() => handleGarageChange(garage.name)}
                    className="w-5 h-5"
                  />
                )}
              </label>
            </li>
          ))}
        </ul>
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>} {/* Show error message */}
        <Button
          label={t("motorInsurance.trackClaimFlow.garageSelection.submitButton")}
          className="button flex bg-[#55C6EF] hover:bg-[#55C6EF] disabled:bg-gray-400 disabled:cursor-not-allowed flex flex-col items-center gap-2.5 self-stretch p-4 rounded-[10px] w-full mt-4 text-lg"
          onSubmit={handleSuccess}
        />
      </div>
    </div>
  );
};

export default GarageSelection;
