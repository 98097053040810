import { createSlice } from "@reduxjs/toolkit";
import { ADJUST_SUM_INSURED_FLOW } from "../../pages/motorInsurancePages/adjustSumInsuredFlow/data";

const initialState = {
  flowType: ADJUST_SUM_INSURED_FLOW,
  currentStepIndex: 0,
  currentSubstepIndex: 0,
};

const motorInsuranceAdjustSumInsuredFlowSlice = createSlice({
  name: "motorInsuranceAdjustSumInsuredFlow",
  initialState,
  reducers: {
    setAdjustSumInsuredFlowType: (state, action) => {
      const data = action.payload;
      state.flowType = data.type;
      state.currentStepIndex = data.currentStepIndex || 0;
      state.currentSubstepIndex = data.currentSubstepIndex || 0;
    },
    setCurrentStepIndex: (state, action) => {
      state.currentStepIndex = action.payload;
      state.currentSubstepIndex = 0;
    },
    setCurrentSubstepIndex: (state, action) => {
      state.currentSubstepIndex = action.payload;
    },
  },
});

export const selectMotorInsuranceAdjustSumInsuredFlow = (store) =>
  store.motorInsuranceAdjustSumInsuredFlow;
export const selectAdjustSumInsuredFlowType = (store) =>
  store?.motorInsuranceAdjustSumInsuredFlow?.flowType;
export const selectCurrentStepIndex = (store) =>
  store?.motorInsuranceAdjustSumInsuredFlow?.currentStepIndex;
export const selectCurrentSubstepIndex = (store) =>
  store.motorInsuranceAdjustSumInsuredFlow?.currentSubstepIndex;

export const {
  setAdjustSumInsuredFlowType,
  setCurrentStepIndex,
  setCurrentSubstepIndex,
} = motorInsuranceAdjustSumInsuredFlowSlice.actions;
export default motorInsuranceAdjustSumInsuredFlowSlice.reducer;
