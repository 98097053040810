import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import { constantsApiEndpoints } from "../../../api";
import { toast } from "react-toastify";

// State that the response will be put into
const initialState = {
  cars: null,
  isLoading: false,
  success: false,
  error: null,
};

// Async API call
// export const fetchCarsList = createAsyncThunk(
//   "fetchCarsList",
//   (payload, { rejectWithValue }) => {
//     console.log("Post cars");
//     return Axios.post(`${constantsApiEndpoints.cars}`).then((res) => res.data);
//   }
// );

// Async API call with Axios timeout
export const fetchCarsList = createAsyncThunk(
  "fetchCarsList",
  async (payload, { rejectWithValue }) => {
    try {
      console.log("Post cars");
      const response = await Axios.post(`${constantsApiEndpoints.cars}`, null, {
        timeout: 30000, // Set timeout to 30 seconds (5000 ms)
      });
      return response.data;
    } catch (error) {
      if (error.code =='ECONNABORTED') {
        // Handle timeout specific error
        return toast.error('Request timed out');
      }
      return toast.error(error.response?.data || 'An error occurred');
    }
  }
);


// Redux slice that contains the reducers/action calls
export const carsListSlice = createSlice({
  name: "Get Car List",
  initialState,
  reducers: {
    clearCarsListError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // FETCH  UTILS API
    builder.addCase(fetchCarsList.pending, (state, action) => {
      console.log("Pending", state.success)
      if (!state.data) {
        state.isLoading = true;
        state.success = false;
      }
    });
    builder.addCase(fetchCarsList.fulfilled, (state, action) => {
      state.success = true;
      console.log("Fulfilled", state.success)
      state.isLoading = false;
      state.error = null;
      state.cars = action.payload;
    });
    builder.addCase(fetchCarsList.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.error?.message;
    });
  },
});

// SELECTORS which can be easily called to get the corresponding value
export const selectCars = (store) => store.carsList?.cars
export const selectCarsLoader = (store) => store?.carsList?.isLoading
export const selectCarsSuccess = (store) => store?.carsList?.success


// ACTIONS TO DISPATCH
export const { clearCarsListError } = carsListSlice.actions;

export default carsListSlice.reducer;
