import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../../components/common/Button/Button';
import Input from '../../../components/common/Input/Input';
import { useDispatch, useSelector } from "react-redux";
import { clearLoginUserError, loginUser, selectLoginErrorInfo, selectLoginLoader } from "../../../redux/features/loginRegisterSlice";
import Loader from "../../../components/common/Loader/Loader";
import { toast } from "react-toastify";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { SIGN_IN_PAGE } from "../../motorInsurancePages/newMotorPolicy/carDetails/carInfo/data";
import { selectverifyKycLoader, verifyKyc } from "../../../redux/features/signUp/kycVerificationStatusSlice";
import { usePostHog } from 'posthog-js/react'
import EyeIcon from "../../../assets/images/remove_red_eye.svg"
import { BASE_URL } from "../../../api/baseURL";

const SignInForm = ({ cbSuccess = () => { }, cbVerifyStatus = () => { } }) => {
    const posthog = usePostHog()
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        cprNumber: "",
        password: "",
    });
    const [errors, setErrors] = useState({});
    const translate = useLanguageTranslate()
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const { t } = useTranslation();


    const CheckSolidarityUser = async (cpr) => {
        const url = BASE_URL+"/Meej/CheckSolidarityUser";
    
        const data = {
          // Your data to send in the request body
          CPR: cpr,      
        };
       
        try {
          
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json", // Set headers for JSON
            },
            body: JSON.stringify(data), // Convert data to JSON string
          });
      
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
      
          const result = await response.json();
          console.log("Success:", result);
          return result
        } catch (error) {
          console.error("Error:", error);
        }
      };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let newValue = value;

        setFormData((prev) => ({ ...prev, [name]: newValue }));
        if (errors[name]){
            let error =  validateField(name, newValue); 
            setErrors({...errors, [name]: error})
        }
         
    };

    const togglePasswordVisibility = () => {
        setIsPasswordVisible((prevState) => !prevState);
      };

    const formSubmitHandler = (e) => {
        e?.preventDefault();
        if (validateForm()) {
            handleSignIn();
        }
    };
    const validateField = (fieldName, value) => { 
        let newError = "";

        switch (fieldName) {
            case "cprNumber":
                if (!value) {
                    newError = t("validation.required");
                }
                else if (value?.length != 9) {
                    newError = t("validation.exact_length", { length: 9 });
                }
                break;
            case "password":
                if (!value) {
                    newError = t("validation.required");
                }
                break;
            default:
                break;
        }
        return newError

        // setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: newError }));
    };
    const validateForm = () => {
        const newErrors = {};
        newErrors.cprNumber = validateField("cprNumber", formData?.cprNumber);
        newErrors.password = validateField("password", formData?.password);
        setErrors(newErrors);
        return !newErrors.cprNumber&&!newErrors.password;
    };
    const dispatch = useDispatch()
    const handleSignIn = async () => {

        let payload = {
            username: formData.cprNumber,
            password: formData.password,
        }
        let loginResp = await dispatch(loginUser({ payload: payload, cb: cbSuccess }))
        if (loginResp?.payload?.access_token) {
            // posthog.reset(true);
            posthog.reset();
            console.log("Logged In User: " + loginResp?.payload?.partyCode);
             posthog.identify(
                 loginResp?.payload?.partyCode,  // Replace 'distinct_id' with your user's unique identifier
                 { partycode: loginResp?.payload?.partyCode, cpr: loginResp?.payload?.cpr, email: loginResp?.payload.email, name: loginResp?.payload?.name, phoneNumber: loginResp?.payload?.phoneNumber } // optional: set additional person properties
             );
             posthog.reloadFeatureFlags(); 
             let response = await dispatch(verifyKyc(formData.cprNumber));
             console.log(response, 'verification response')
             if (response?.payload?.Verified ==false) {
                 cbVerifyStatus(response?.payload)
             }
             else {
                 cbVerifyStatus(response?.payload)
             }
         }
        return;
    };

    const isLoginLoader = useSelector(selectLoginLoader)
    const verifyKycLoader = useSelector(selectverifyKycLoader)
    const loginErrorInfo = useSelector(selectLoginErrorInfo);
    useEffect(() => {
        if (loginErrorInfo) {
            toast.error(translate(loginErrorInfo.ErrorMessageEn, loginErrorInfo.ErrorMessageAr))
            dispatch(clearLoginUserError())
        }
    }, [loginErrorInfo])

    console.log(errors,'some errors')
    return (
        <form onSubmit={formSubmitHandler}>
            {(isLoginLoader || verifyKycLoader) && <Loader />}
            <Input
                label={t("siginPage.cpr_input_label")}
                type="number"
                handleChange={handleChange}
                name="cprNumber"
                value={formData.cprNumber}
                error={errors.cprNumber}
                data-testid="cprNumber"
            />
            <Input
                label={t("siginPage.password_input_label")}
                type={isPasswordVisible ? "text" : "password"}
                handleChange={handleChange}
                name="password"
                value={formData.password}
                error={errors.password}
                data-testid="password"
                endAdornment={
                    <img
                      src={EyeIcon}
                      alt="password"
                      onClick={togglePasswordVisibility}
                    />
                  }
            />
            <Link to="/forgot-password" className="forgot-lable flex mx-0 mb-5 text-[#00384D] text-center text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline">
                {t("siginPage.forgot_password_link_label")}
            </Link>

            <Button
                disabled={errors.password || errors.cprNumber}
                type="submit"
                className="primary-button mb-10"
                label={t("siginPage.sign_in_btn_label")}
            />
        </form>
    )
}

export default SignInForm