import React, { useEffect, useState } from "react";
import DownloadBtn from "../../../../components/common/downloadBtn/downloadBtn";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import PerformanceCard from "../../../../components/common/card/performanceCard";
import { policyOverview } from "../motorPolicyDataFile";
import useScreenResolution from "../../../../hooks/useScreenResolution";
import BackdropModal from "../../../../components/common/modal/backdropModal";
import SkeletonLoader from "../../../../components/common/Loader/SkeletonLoader";
import useAppleWalletApi from "../../../../hooks/useAppleWallet";
import { decodeQueryString } from "../../../../utils";
import { useNavigate, useParams } from "react-router-dom";
import { selectUserPoliciesData } from "../../../../redux/features/myPolicySlice";
import Button from "../../../../components/common/Button/Button";
import Loader from "../../../../components/common/Loader/Loader";
import { useSelector } from "react-redux";
import PolicyCard from "../../../../components/policyCard/policyCard";
import appleWallet from "../../../../assets/images/Applewallet.svg";

const PolicyOverviewTab = ({
  handleDownloadMenuClicked,
  showDownloadPopup,
  doc,
  status,
  policyDetails,
}) => {
  const [docLabel, setDoc] = useState([]);
  const { id } = useParams();
  const params = decodeQueryString();
  const screenResolution = useScreenResolution();
  const userPolicies = useSelector(selectUserPoliciesData); //filter required policy

  const filteredPolicy = userPolicies?.filter(
    (policy) => policy.PolicyNumber == params.policy
  );
  const { AppleWalletApiTrigger, isLoading, isAppleDevice } = useAppleWalletApi(
    params?.policy
  );

  useEffect(() => {
    // Ensure doc is defined and is an array before setting
    if (Array.isArray(doc)) {
      setDoc(doc);
    } else {
      setDoc([]); // Set to empty array if doc is not an array
    }
  }, [doc]);

  const translate = useLanguageTranslate();

  // Safely iterate over docLabel only if it's an array
  if (Array.isArray(docLabel)) {
    for (const i of docLabel) {
      console.log(i.ID); // Process each item
    }
  } else {
    console.error("docLabel is not an array");
  }

  const downloadCard = {
    label_en: "Your insurance policy documents",
    label_ar: "مستندات وثيقة التأمين الخاصة بك:",
    download_btn: true,
    filesData: [],
  };

  for (var document of docLabel) {
    downloadCard.filesData.push({
      fileName: document.ID,
      fileName_ar: document.ID,
      filesData: {
        url: document.File,
        size: "165.7 KB",
      },
    });
  }

  return (
    <>
      {isLoading && <Loader className="w-10 h-10 fixed left-[50%] top-[50%]" />}
      {screenResolution.mobile && (
        <div className="max767:p-5 max767:pb-[0px]">
          {filteredPolicy?.map((each, index) => (
            <PolicyCard
              key={index}
              policy_name={each.policy_name}
              policy_label={each.policy_label}
              policy_number={each.policy_number}
              from_date={each.from_date}
              to_date={each.to_date}
              policy_bg_image={each.policy_bg_image}
              expire_days={each.expire_days}
              is_text_dark={each.is_text_dark}
              mapping_key={each.id}
              data={each}
            />
          ))}
          {isAppleDevice && (
            <button
              onClick={AppleWalletApiTrigger}
              disabled={isLoading}
              className="AppleWallet flex justify-center items-center gap-2 self-stretch bg-black px-2 py-4 rounded-[10px] text-white text-base not-italic font-[510] leading-[120%] max767:w-full max767:mt-2 hover:bg-black"
            >
              <img src={appleWallet} alt="Apple Wallet Icon" />
              Add to Apple Wallet
            </button>
          )}
        </div>
      )}
      <div className="policy-over max767:px-5 max767:py-8 ">
        <div className="flex flex-wrap gap-4 ipad:flex-nowrap ipad:gap-2 max767:col-span-2 policyoverview">
          {policyOverview.cards
            .filter((card) => {
              // Example logic: Exclude cards with empty `icon` only if it's required
              if (card.icon == "" && !card.call_option) return false;

              // Exclude specific card by value
              if (
                card.value == "Home Assistance" &&
                policyDetails?.PolicyHeader?.ProductCode == "0101" &&
                policyDetails?.PolicyHeader?.Covers?.some(
                  (cover) => cover.coverCode == "CMOT28"
                )
              ) {
                card.call_option = card.call_option || {}; // Ensure `call_option` exists
                card.call_option.lable_en = "Call +973 17576642";
              } else if (card.value == "Home Assistance") {
                return false; // Exclude "Home Assistance" if the conditions are not met
              }

              if (
                card.value == "Road Assistance" &&
                policyDetails?.PolicyHeader?.ProductCode == "0101"
              ) {
                card.call_option.lable_en =
                  "Call +973 " +
                  policyDetails?.PolicyHeader?.AssistCompany?.Contact;
                if (
                  policyDetails?.PolicyHeader?.AssistCompany?.Contact ===
                  undefined
                ) {
                  return false;
                }
              }

              // if(card.value =="Airport Delivery Services" && policyDetails?.PolicyHeader?.ProductCode =="0101" && policyDetails?.PolicyHeader?.Covers?.find((cover) => cover.coverCode =="CMOT28")){
              //   //const selectedCover = policyDetails?.PolicyHeader?.Covers?.find((cover) => cover.coverCode =="CMOT28");
              //   card.call_option.lable_en = "Call +973 " + "17218925";
              // }

              if (
                card.value == "Airport Delivery Services" &&
                policyDetails?.PolicyHeader?.ProductCode == "0101" &&
                policyDetails?.PolicyHeader?.Covers?.some(
                  (cover) => cover.coverCode == "CMOT28"
                )
              ) {
                card.call_option = card.call_option || {}; // Ensure `call_option` exists
                card.call_option.lable_en = "Call +973 17218925";
              } else if (card.value == "Airport Delivery Services") {
                return false; // Exclude "Home Assistance" if the conditions are not met
              }

              // Include all other cards
              return true;
            })
            .map((card, index) => (
              <PerformanceCard
                key={index}
                cardData={card}
                className="flex h-[120px] min-w-[217px] max-w-[240px] flex-col justify-between items-start flex-[1_0_0] border bg-[#F6F8F9] p-4 rounded-2xl border-solid border-[#DDE2E4] ipad:min-w-[120px] ipad:flex ipad:h-[100px] ipad:flex-col ipad:justify-between ipad:items-start ipad:flex-[1_0_0] ipad:px-4 ipad:py-6 ipad:gap-0  max767:border-0 max767:focus:border-solid overviewcard max767:max-w-[48%] max767:h-[120px] max767:flex-col max767:justify-between max767:items-start max767:p-4"
              />
            ))}
        </div>
        {status == "loading" ? (
          <SkeletonLoader />
        ) : (
          !screenResolution.mobile &&
          downloadCard && (
            <div className="justify-center items-start gap-2 self-stretch bg-[#F6F8F9] mt-4 p-6 rounded-[10px]">
              <div className="flex">
                <h3 className="text-[#1A2024] text-sm not-italic font-medium leading-[normal] mb-4">
                  {translate(downloadCard?.label_en, downloadCard?.label_ar)}
                </h3>
              </div>
              {downloadCard?.download_btn && (
                <div className=" gap-2 download-wrapper flex flex-wrap w-4/5 ipad:flex ipad:w-[95%]">
                  <DownloadBtn
                    doc={docLabel}
                    filesData={downloadCard.filesData}
                    className={"gap-2 shrink w-[32%]  ipad:w-2/5"}
                  />
                </div>
              )}
            </div>
          )
        )}
        <div className="downloaddocumentsmodal">
          <BackdropModal
            show={showDownloadPopup}
            onClose={handleDownloadMenuClicked}
            title={"Download Documents"}
          >
            <div className="flex-row">
              <DownloadBtn
                filesData={downloadCard.filesData}
                className={"mb-2"}
              />
            </div>
          </BackdropModal>
        </div>
      </div>
    </>
  );
};

export default PolicyOverviewTab;
