import React, { useState } from "react";
import BackdropModal from "../../../../../components/common/modal/backdropModal";
import Button from "../../../../../components/common/Button/Button";
import changcycle from "../../../../../assets/images/changecycleicon.svg"
import useValidation from "../../../../../hooks/useValidation";
import AlertComponent from "../../../../motorInsurancePages/transferPolicyFlow/alertComponent";
import { DEC_INC_VALIDATIONS } from "../decOrIncPremium/data";
import { PAYMENT_CYCLES } from "./data";
import CycleCard from "./cycleCard";
import useLanguageTranslate from "../../../../../hooks/useLanguageTranslate";
import { useTranslation } from "react-i18next";
import useCreateLifeRequests, { LIFE_REQUEST_TYPE } from "../../../../../hooks/life/useCreateLifeRequests";
import { useSelector } from "react-redux";
import { selectLifePolicyData } from "../../../../../redux/features/life/lifePolicyDetailSlice";
import useScreenResolution from "../../../../../hooks/useScreenResolution";
import BottomStickyBtnLayout from "../../../../motorInsurancePages/newMotorPolicy/Layouts/BottomStickyBtnLayout"
import closeicon from "../../../../../assets/images/close.svg";


const ChangePaymentCycle = ({ show, setShow }) => {
    const [selectedPlan, setSelectedPlan] = useState();
    const translate = useLanguageTranslate()
    const { t } = useTranslation()
    const { isLoading, createLifeRequest } = useCreateLifeRequests()
    const {mobile} = useScreenResolution()


    const policyDetails = useSelector(selectLifePolicyData);

    const onClose = () => {
        setShow((state) => !state);
        setSelectedPlan(null)
    }
    const changeHandler = async () => {
        let payload = {
            PolicyNumber: policyDetails?.PolicyDetails?.PolicyNumber||"",
            RequestType: selectedPlan.requestType,
        }
        const response = await createLifeRequest(payload)
        onClose()
    }
    const planOnClickHandler = (plan) => {
        setSelectedPlan(plan)
    }
    return (
        <div className="changepymentcylcmodal">
            <BackdropModal onClose={onClose} show={show} hideClosebtn={mobile}>
                {!mobile && <p className="flex mb-5 max767:mt-[-13px]"><img src={changcycle} alt="exit_to_app" className="exit_to_app  mt-[-40px] max767:hidden" /></p>}
                {mobile && <button
                    className="bpy-2 rounded mt-4 flex absolute top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:ml-[16px] max767:mb-[10px]"
                    onClick={onClose}
                >
                    <img src={closeicon} alt="Close Icon" />
                </button>}
                <div className="content mb-4 max767:mt-[0px]">
                    <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mb-3 max767:text-xl">
                        {t("lifeInsurance.changePaymentCycle.popup_title")}
                    </h1>
                    {mobile && (
                            <p className="text-[#1A2024] text-base not-italic font-normal leading-6 tracking-[-0.16px] max767:mb-8">
                            {translate("Please select how much you would like to increase or update this value.","يرجى تحديد المقدار الذي ترغب في زيادته أو تحديث هذه القيمة.")}
                            </p>
                        )}
                    <AlertComponent title={
                        translate(
                            "Please note, this request will be submitted for approval before it takes effects",
                            "يرجى ملاحظة أن هذا الطلب سيتم تقديمه للموافقة عليه قبل أن يصبح ساري المفعول"
                        )

                    } />
                    <div>
                        <p className="text-[#1A2024] text-base not-italic font-medium leading-[26px] tracking-[-0.16px] mt-6 mb-2">{t("lifeInsurance.changePaymentCycle.selectPaymentLabel")}</p>
                        <div className="items-start gap-4 self-stretch grid grid-cols-2 max767:grid-cols-1">
                            {
                                PAYMENT_CYCLES?.map(plan => <CycleCard
                                    onClick={planOnClickHandler}
                                    isCurrentPlan={plan.id ==policyDetails?.LifeDetails?.policyList?.[0]?.paymentCycle }
                                    plan={plan}
                                    selected={selectedPlan?.id ==plan.id}
                                    label={translate(plan.label, plan.ar_label)}
                                />)
                            }
                        </div>
                    </div>
                    {!mobile && <div className="mt-8 flex items-start gap-3 self-stretch">
                         <Button onSubmit={onClose} label={t("lifeInsurance.changePaymentCycle.cancel_btn_label")} className="flex flex-col items-center gap-2.5 flex-[1_0_0] px-4 py-2 border rounded-[10px] border-solid border-[#DDE2E4] bg-white text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]" />
                        <Button disabled={!selectedPlan || isLoading} onSubmit={changeHandler} label={t("lifeInsurance.changePaymentCycle.confirm_btn_label")} className="flex flex-col items-center gap-2.5 flex-[1_0_0] px-4 py-2 border rounded-[10px] border-solid border-[#DDE2E4] bg-[#55C6EF] text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]" />
                    </div>}
                    {mobile && (
                        <BottomStickyBtnLayout
                        btn_label={t("lifeInsurance.changePaymentCycle.confirm_btn_label")}
                        btn_onClick={changeHandler}
                        btn_disabled= {!selectedPlan || isLoading}
                        ></BottomStickyBtnLayout>
                     )}
                </div>

            </BackdropModal>
        </div>
    )
}

export default ChangePaymentCycle