export const tabs = [
  {
    label_en: "General Insurance",
    label_ar: "نظرة عامة على السياسة",
    type: "GeneralInsurance",
  },
  {
    label_en: "Life Insurance",
    label_ar: "أداء الصندوق",
    type: "LifeInsurance",
  },
  {
    label_en: "Motor Insurance",
    label_ar: "تاريخ الدفعات",
    type: "MotorInsurance",
  },
  {
    label_en: "Claims",
    label_ar: "إدارة الصندوق",
    type: "Claims",
  },
  {
    label_en: "Emergency Services",
    label_ar: "إدارة سياستي",
    type: "EmergencyServices",
  },
];
export const faqData = [
  {
    GeneralInsurance: [
      {
        question_en:
          "How can I file a motor accident claim with Solidarity Bahrain?",
        question_ar:
          "ar How can I file a motor accident claim with Solidarity Bahrain?",
        answer_en:
          "Filing a motor accident claim with Solidarity Bahrain involves several steps to ensure that your claim is processed efficiently. Follow these instructions to file your claim",
        answer_ar:
          "ar Filing a motor accident claim with Solidarity Bahrain involves several steps to ensure that your claim is processed efficiently. Follow these instructions to file your claim",
      },
      {
        question_en: "What documents do I need to submit when filing a claim?",
        question_ar:
          "ar What documents do I need to submit when filing a claim?",
        answer_en: (
          <>
            <p>You need to submit or attach the following documents:</p>
            <ul>
              <li>Traffic Report</li>
              <li>Valid Driving License</li>
              <li>Vehicle Ownership Certificate</li>
              <li>Insurance Certificate</li>
              <li>
                Clear photos of the vehicles/properties involved in the accident
                (vehicle photos must include registration number)
              </li>
            </ul>
          </>
        ),
        answer_ar: (
          <>
            <p>You need to submit or attach the following documents:</p>
            <ul>
              <li>Traffic Report</li>
              <li>Valid Driving License</li>
              <li>Vehicle Ownership Certificate</li>
              <li>Insurance Certificate</li>
              <li>
                Clear photos of the vehicles/properties involved in the accident
                (vehicle photos must include registration number)
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  {
    LifeInsurance: [
      {
        question_en:
          "How can I file a motor accident claim with Solidarity Bahrain?",
        question_ar:
          "ar How can I file a motor accident claim with Solidarity Bahrain?",
        answer_en:
          "Filing a motor accident claim with Solidarity Bahrain involves several steps to ensure that your claim is processed efficiently. Follow these instructions to file your claim",
        answer_ar:
          "ar Filing a motor accident claim with Solidarity Bahrain involves several steps to ensure that your claim is processed efficiently. Follow these instructions to file your claim",
      },
      {
        question_en: "What documents do I need to submit when filing a claim?",
        question_ar:
          "ar What documents do I need to submit when filing a claim?",
        answer_en: (
          <>
            <p>You need to submit or attach the following documents:</p>
            <ul>
              <li>Traffic Report</li>
              <li>Valid Driving License</li>
              <li>Vehicle Ownership Certificate</li>
              <li>Insurance Certificate</li>
              <li>
                Clear photos of the vehicles/properties involved in the accident
                (vehicle photos must include registration number)
              </li>
            </ul>
          </>
        ),
        answer_ar: (
          <>
            <p>You need to submit or attach the following documents:</p>
            <ul>
              <li>Traffic Report</li>
              <li>Valid Driving License</li>
              <li>Vehicle Ownership Certificate</li>
              <li>Insurance Certificate</li>
              <li>
                Clear photos of the vehicles/properties involved in the accident
                (vehicle photos must include registration number)
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  {
    MotorInsurance: [
      {
        question_en:
          "How can I file a motor accident claim with Solidarity Bahrain?",
        question_ar:
          "ar How can I file a motor accident claim with Solidarity Bahrain?",
        answer_en:
          "Filing a motor accident claim with Solidarity Bahrain involves several steps to ensure that your claim is processed efficiently. Follow these instructions to file your claim",
        answer_ar:
          "ar Filing a motor accident claim with Solidarity Bahrain involves several steps to ensure that your claim is processed efficiently. Follow these instructions to file your claim",
      },
      {
        question_en: "What documents do I need to submit when filing a claim?",
        question_ar:
          "ar What documents do I need to submit when filing a claim?",
        answer_en: (
          <>
            <p>You need to submit or attach the following documents:</p>
            <ul>
              <li>Traffic Report</li>
              <li>Valid Driving License</li>
              <li>Vehicle Ownership Certificate</li>
              <li>Insurance Certificate</li>
              <li>
                Clear photos of the vehicles/properties involved in the accident
                (vehicle photos must include registration number)
              </li>
            </ul>
          </>
        ),
        answer_ar: (
          <>
            <p>You need to submit or attach the following documents:</p>
            <ul>
              <li>Traffic Report</li>
              <li>Valid Driving License</li>
              <li>Vehicle Ownership Certificate</li>
              <li>Insurance Certificate</li>
              <li>
                Clear photos of the vehicles/properties involved in the accident
                (vehicle photos must include registration number)
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  {
    Claims: [
      {
        question_en:
          "How can I file a motor accident claim with Solidarity Bahrain?",
        question_ar:
          "ar How can I file a motor accident claim with Solidarity Bahrain?",
        answer_en:
          "Filing a motor accident claim with Solidarity Bahrain involves several steps to ensure that your claim is processed efficiently. Follow these instructions to file your claim",
        answer_ar:
          "ar Filing a motor accident claim with Solidarity Bahrain involves several steps to ensure that your claim is processed efficiently. Follow these instructions to file your claim",
      },
      {
        question_en: "What documents do I need to submit when filing a claim?",
        question_ar:
          "ar What documents do I need to submit when filing a claim?",
        answer_en: (
          <>
            <p>You need to submit or attach the following documents:</p>
            <ul>
              <li>Traffic Report</li>
              <li>Valid Driving License</li>
              <li>Vehicle Ownership Certificate</li>
              <li>Insurance Certificate</li>
              <li>
                Clear photos of the vehicles/properties involved in the accident
                (vehicle photos must include registration number)
              </li>
            </ul>
          </>
        ),
        answer_ar: (
          <>
            <p>You need to submit or attach the following documents:</p>
            <ul>
              <li>Traffic Report</li>
              <li>Valid Driving License</li>
              <li>Vehicle Ownership Certificate</li>
              <li>Insurance Certificate</li>
              <li>
                Clear photos of the vehicles/properties involved in the accident
                (vehicle photos must include registration number)
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  {
    EmergencyServices: [
      {
        question_en:
          "How can I file a motor accident claim with Solidarity Bahrain?",
        question_ar:
          "ar How can I file a motor accident claim with Solidarity Bahrain?",
        answer_en:
          "Filing a motor accident claim with Solidarity Bahrain involves several steps to ensure that your claim is processed efficiently. Follow these instructions to file your claim",
        answer_ar:
          "ar Filing a motor accident claim with Solidarity Bahrain involves several steps to ensure that your claim is processed efficiently. Follow these instructions to file your claim",
      },
      {
        question_en: "What documents do I need to submit when filing a claim?",
        question_ar:
          "ar What documents do I need to submit when filing a claim?",
        answer_en: (
          <>
            <p>You need to submit or attach the following documents:</p>
            <ul>
              <li>Traffic Report</li>
              <li>Valid Driving License</li>
              <li>Vehicle Ownership Certificate</li>
              <li>Insurance Certificate</li>
              <li>
                Clear photos of the vehicles/properties involved in the accident
                (vehicle photos must include registration number)
              </li>
            </ul>
          </>
        ),
        answer_ar: (
          <>
            <p>You need to submit or attach the following documents:</p>
            <ul>
              <li>Traffic Report</li>
              <li>Valid Driving License</li>
              <li>Vehicle Ownership Certificate</li>
              <li>Insurance Certificate</li>
              <li>
                Clear photos of the vehicles/properties involved in the accident
                (vehicle photos must include registration number)
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
];
