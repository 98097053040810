/* :Page:NewMotor: Car Brand New page */

import React, { useState } from "react";
import FormTabs from "../../../../../components/common/tabs/formTabs";
import { CAR_INFO_BRAND_NEW, CAR_INFO_PLATE_NUMBER, yesNoTabs } from "./data";
import FileUpload from "../../../../../components/common/fileUploadInput/fileUploadInput";
import TitleDescription from "../../../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useTranslation } from "react-i18next";
import NewMotorJourneyLayout from "../../Layouts/NewMotorJourneyLayout";
import useMotorForm from "../../../../../hooks/useMotorFormData";
import BottomStickyBtnLayout from "../../Layouts/BottomStickyBtnLayout";
import useMotorFlow from "../../../../../hooks/useMotorFlow";
import {
  FLOW_TYPE_NEW_CAR,
  FLOW_TYPE_USED_CAR_WITH_OC,
  FLOW_TYPE_USED_CAR_WITHOUT_OC,
} from "../../../../../hooks/data";
import {
  fetchCarsList,
  selectCars,
  selectCarsLoader,
  selectCarsSuccess,
} from "../../../../../redux/features/constants/carsListSlice";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  gdtRetrievePlateTypesListResponse,
  retrievePlateTypesFromGDT,
} from "../../../../../redux/features/motor/gdtSlice";

const CarInfoBranNew = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { handleFlowChange } = useMotorFlow();

  const [formData, setFormData] = useState({
    is_brand_new: null,
    is_have_oc: false, // If ownership flow is set, make it null
  });

  const { saveMotorDataInRedux } = useMotorForm(
    CAR_INFO_BRAND_NEW,
    setFormData
  );

  const { removeMotorFormData } = useMotorForm(
    CAR_INFO_PLATE_NUMBER,
    setFormData
  );

  const carsList = useSelector(selectCars);
  const plateTypeList = useSelector(gdtRetrievePlateTypesListResponse);

  const carsListLoading = useSelector(selectCarsLoader);
  const isCarSuccess = useSelector(selectCarsSuccess);

  const NextHandler = async (e) => {
    if (!carsList) {
      // Calling API /constant/cars
      const fetchCarListAction = await dispatch(fetchCarsList());
      const fetchCarResult = unwrapResult(fetchCarListAction);
    }

    if (plateTypeList.length ==0) {
      // Calling API /GDT/RetrievePlateTypes
      const fetchPlateTypeAction = await dispatch(retrievePlateTypesFromGDT());
      const fetchPlateTypeResult = unwrapResult(fetchPlateTypeAction);
    }

    if (carsList && plateTypeList) {
      if (formData.is_brand_new =="Yes") {
        handleFlowChange(FLOW_TYPE_NEW_CAR, 1, 1);
      } else if (formData.is_have_oc =="Yes") {
        handleFlowChange(FLOW_TYPE_USED_CAR_WITH_OC, 1, 1);
      } else {
        handleFlowChange(FLOW_TYPE_USED_CAR_WITHOUT_OC, 1, 1);
      }
      saveMotorDataInRedux(formData);
    } else {
      if (formData.is_brand_new =="Yes") {
        // Clear CAR_INFO_PLATE_NUMBER because it is in used car
        removeMotorFormData();
        handleFlowChange(FLOW_TYPE_NEW_CAR, 1, 1);
      } else if (formData.is_have_oc =="Yes") {
        handleFlowChange(FLOW_TYPE_USED_CAR_WITH_OC, 1, 1);
      } else {
        handleFlowChange(FLOW_TYPE_USED_CAR_WITHOUT_OC, 1, 1);
      }
      saveMotorDataInRedux(formData);
    }
  };
  const onTabClick = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  const onFileUploaded = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  const isDisabled = () => {
    let disabled = false;
    // if (!formData.is_brand_new) {
    //   disabled = true;
    // }
    if (formData.is_brand_new ==null) {
      disabled = true;
    }
    // if (formData.is_brand_new =="No" && !formData.is_have_oc) {
    //   disabled = true;
    // }
    if (formData.is_have_oc =="Yes" && !formData.oc_file) {
      disabled = true;
    }
    return disabled;
  };

  return (
    <NewMotorJourneyLayout>
      <div className="carInfoPage max767:mt-[0px]">
        <BottomStickyBtnLayout
          btn_disabled={isDisabled()}
          btn_onClick={NextHandler}
        >
          <div className="max-w-lg mx-auto bg-white rounded-md max767:p-5 max767:pt-0 max767:mt-[0px]">
            <>
              <TitleDescription
                title={t("motorInsurance.newMotorInsuranceFlow.carInfo.title")}
                description={t(
                  "motorInsurance.newMotorInsuranceFlow.carInfo.description"
                )}
              />

              <div className="yesNoButons">
                <FormTabs
                  tabs={yesNoTabs}
                  onTabClick={(type) => onTabClick(type, "is_brand_new")}
                  activeTab={formData.is_brand_new}
                />
              </div>
              {/* {formData.is_brand_new =="No" && (
                <div className="ownershipSection">
                  <FormTabs
                    label={t(
                      "motorInsurance.newMotorInsuranceFlow.carInfo.no_new_brand.label"
                    )}
                    tabs={yesNoTabs}
                    onTabClick={(type) => onTabClick(type, "is_have_oc")}
                    activeTab={formData.is_have_oc}
                  />
                  {formData.is_have_oc =="Yes" && (
                    <div>
                      <p className="mb-4">
                        {t(
                          "motorInsurance.newMotorInsuranceFlow.carInfo.no_new_brand.uploadInstruction"
                        )}
                      </p>
                      <FileUpload
                        name="oc_file"
                        toggleFileUpload={onFileUploaded}
                      />
                    </div>
                  )}
                </div>
              )} */}
            </>
          </div>
        </BottomStickyBtnLayout>
      </div>
    </NewMotorJourneyLayout>
  );
};

export default CarInfoBranNew;
