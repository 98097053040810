import React from "react";
import useSummary from "../../../hooks/useSummary";
import {
  currencyFormatter,
  currencyFormatterWithCountryCode,
  formatToThreeDigits,
} from "../../../utils";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { useSelector } from "react-redux";
import {
  getQuoteResponse,
  PolicyViewResponse,
} from "../../../redux/features/motor/getQuoteSlice";
import { getPolicyPremiumResponse } from "../../../redux/features/motor/renewalPremiumSlice";
import { getQuoteWithRefundResponse } from "../../../redux/features/endorsements/cancellationSlice";
import { getStartPolicyTransferResponse } from "../../../redux/features/endorsements/transferPolicySlice";
import { getPolicyViewResponse } from "../../../redux/features/policyDetailsSlice";
import { selectNewMotorForm } from "../../../redux/features/newMotorFormSlice";
import { PROMO_CODE } from "./viewSummaryPopup";

const Summary = ({
  premiumText,
  // quotationNumber = quoteResponse.PolicyNumber
  passedFrom,
  price,
  vat,
}) => {
  const {
    discount_amount,
    coverage_enhance_data,
    promocode,
    discount_percent,
    total_amount,
    discount,
    discount_percentage,
    vat_additional_amount,
  } = useSummary();

  // Getting the variable from API responses saved in redux for each case. Motor new, Motor renewal, Motor change active plan, etc...
  const quoteResponse = useSelector(getQuoteResponse);
  const policyPremiumResponse = useSelector(getPolicyPremiumResponse);
  const quoteWithRefundResponse = useSelector(getQuoteWithRefundResponse);
  const transferFeesResponse = useSelector(getStartPolicyTransferResponse);
  const policyViewResponse = useSelector(getPolicyViewResponse);

  // Promo code
  const promoCodeDetails = useSelector(selectNewMotorForm)?.[PROMO_CODE];

  let quotationNumber = "";
  let premium = "";
  let tax = "";
  let totalPremium = "";
  if (passedFrom == "MotorNew") {
    quotationNumber =
      quoteResponse != ""
        ? quoteResponse.PolicyNumber
        : policyPremiumResponse.PolicyNumber;
    premium =
      quoteResponse != ""
        ? quoteResponse.Premium
        : policyPremiumResponse.Premium;
    tax = quoteResponse != "" ? quoteResponse.Tax : policyPremiumResponse.Tax;
    totalPremium =
      quoteResponse != ""
        ? quoteResponse.TotalPremium
        : policyPremiumResponse.TotalPremium;

    // Add prices from coverage_enhance_data?.coverOptionsState if not empty
    if (coverage_enhance_data?.coverOptionsState?.length > 0) {
      const additionalPremium = coverage_enhance_data.coverOptionsState
        .filter(
          (option) =>
            option.included &&
            Number(option.price) > 0 &&
            !option.defaultIncludedInPlan
        ) // Ensure price is a number
        .reduce((sum, option) => sum + Number(option.price), 0); // Convert price to number before summing

      totalPremium += additionalPremium + additionalPremium * 0.1;
      tax = (premium + additionalPremium) * 0.1;
    }
  } else if (passedFrom == "MotorNewReviewAndConfirm") {
    quotationNumber =
      quoteResponse != ""
        ? quoteResponse.PolicyNumber
        : policyPremiumResponse.PolicyNumber;
    premium =
      quoteResponse != ""
        ? quoteResponse.Premium
        : policyPremiumResponse.Premium;
    tax = quoteResponse != "" ? quoteResponse.Tax : policyPremiumResponse.Tax;
    totalPremium =
      quoteResponse != ""
        ? quoteResponse.TotalPremium
        : policyPremiumResponse.TotalPremium;
  } else if (passedFrom == "MotorRenewal") {
    quotationNumber =
      policyPremiumResponse != "" ? policyPremiumResponse.PolicyNumber : "--";
    premium = policyPremiumResponse != "" ? policyPremiumResponse.Premium : "-";
    tax = policyPremiumResponse != "" ? policyPremiumResponse.Tax : "-";
    totalPremium =
      policyPremiumResponse != "" ? policyPremiumResponse.TotalPremium : "-";

    // Add prices from coverage_enhance_data?.coverOptionsState if not empty
    if (coverage_enhance_data?.coverOptionsState?.length > 0) {
      const additionalPremium = coverage_enhance_data.coverOptionsState
        .filter(
          (option) =>
            option.included &&
            Number(option.price) > 0 &&
            !option.defaultIncludedInPlan
        ) // Ensure price is a number
        .reduce((sum, option) => sum + Number(option.price), 0); // Convert price to number before summing

      totalPremium += additionalPremium + additionalPremium * 0.1;
      tax = (premium + additionalPremium) * 0.1;
    }
  } else if (passedFrom == "MotorRenewalReviewAndConfirm") {
    quotationNumber =
      policyPremiumResponse != "" ? policyPremiumResponse.PolicyNumber : "--";
    premium = policyPremiumResponse != "" ? policyPremiumResponse.Premium : "-";
    tax = policyPremiumResponse != "" ? policyPremiumResponse.Tax : "-";
    totalPremium =
      policyPremiumResponse != "" ? policyPremiumResponse.TotalPremium : "-";
  } else if (passedFrom == "MotorChangeActivePlan") {
    quotationNumber =
      quoteWithRefundResponse != ""
        ? quoteWithRefundResponse.PolicyNumber
        : "-";
    premium =
      quoteWithRefundResponse != "" ? quoteWithRefundResponse.Premium : "-";
    tax = quoteWithRefundResponse != "" ? quoteWithRefundResponse.Tax : "-";
    totalPremium =
      quoteWithRefundResponse != ""
        ? quoteWithRefundResponse.TotalPremium
        : "-";
  } else if (passedFrom == "transferPolicy") {
    quotationNumber = policyViewResponse
      ? policyViewResponse.PolicyHeader?.QuoteNumber
      : "Q";
    const vatRate = 0.1;
    premium = transferFeesResponse.Fees + transferFeesResponse.PremiumToPay;
    tax = parseFloat(
      (
        (transferFeesResponse.Fees + transferFeesResponse.PremiumToPay) *
        0.1
      ).toFixed(2)
    );

    totalPremium =
      (transferFeesResponse.Fees + transferFeesResponse.PremiumToPay) *
      (1 + vatRate);
  } else if (passedFrom == "AddCovers") {
    quotationNumber = "";
    premium = price != "" ? price : "";
    tax = vat != "" ? vat : "";
    totalPremium = premium + tax;
  } else {
    quotationNumber =
      quoteResponse != ""
        ? quoteResponse.PolicyNumber
        : policyPremiumResponse.PolicyNumber;
    premium =
      quoteResponse != ""
        ? quoteResponse.Premium
        : policyPremiumResponse.Premium;
    tax = quoteResponse != "" ? quoteResponse.Tax : policyPremiumResponse.Tax;
    totalPremium =
      quoteResponse != ""
        ? quoteResponse.TotalPremium
        : policyPremiumResponse.TotalPremium;
  }

  // const quotationNumber = quoteResponse != "" ? quoteResponse.PolicyNumber : policyPremiumResponse.PolicyNumber;
  // const premium = quoteResponse != "" ? quoteResponse.Premium : policyPremiumResponse.Premium;
  // const tax = quoteResponse != "" ? quoteResponse.Tax : policyPremiumResponse.Tax;
  // const totalPremium = quoteResponse != "" ? quoteResponse.TotalPremium : policyPremiumResponse.TotalPremium;

  // let total = parseInt(vat_additional_amount||0) + total_amount - (discount_amount || 0);
  const translate = useLanguageTranslate();
  const premiumTitle = premiumText
    ? translate(premiumText.en, premiumText.ar)
    : translate("Premium", "غالي");
  console.log(premiumTitle, premiumText);
  return (
    <div className="rounded-lg summary-conntent">
      <div className=" flex items-center justify-between bg-[#E8F4D8] px-4 py-2.5 rounded-md mb-2 mt-4 px-0 py-2.5 max767:mt-0">
        <span className="text-[#5B6871] text-xs not-italic font-medium leading-6">
          {translate("Quotation Number", "رقم الاقتباس")}
        </span>
        <span className="text-[#5B6871] text-xs not-italic font-medium leading-6">
          {quotationNumber}
        </span>
      </div>{" "}
      <div className="flex justify-between mb-2 px-0 py-2.5">
        <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
          {premiumTitle}
        </span>
        <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
          {translate("BHD", "د.ب")} {currencyFormatter(premium)}
        </span>
      </div>
      {coverage_enhance_data?.coverOptionsState
        ?.filter(
          (option) =>
            option.included && option.price > 0 && !option.defaultIncludedInPlan
        )
        ?.map((value) => (
          <div className="flex justify-between mb-2 px-0 py-2.5">
            <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
              {value.name}
            </span>
            <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
              {translate("BHD", "د.ب")} {currencyFormatter(value.price)}
            </span>
          </div>
        ))}
      {/* <div className="flex justify-between mb-2 px-0 py-2.5">
        <span className='text-[#1A2024] text-sm not-italic font-normal leading-6'>Total before VAT & Discount</span>
        <span className='text-[#1A2024] text-sm not-italic font-normal leading-6'>BHD {total?.toFixed(3)}</span>
      </div> */}
      {promoCodeDetails?.promoCodeApplied == true && (
        <div className="flex justify-between mb-2 px-0 py-2.5 border-t-[#DDE2E4] border-t border-solid">
          <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
            {translate("Discount", "خصم")}
          </span>
          <span className="text-[#F2271C] text-sm not-italic font-normal leading-6">
            - BHD {currencyFormatter(promoCodeDetails.promoCodeDifference)}
          </span>
        </div>
      )}
      <div className="flex justify-between mb-2 px-0 py-2.5 border-t-[#DDE2E4] border-t border-solid">
        <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
          {translate("VAT", "ضريبة القيمة المضافة ")} 10%
        </span>
        {promoCodeDetails?.promoCodeApplied == true ? (
          <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
            {translate("BHD", "د.ب")}{" "}
            {currencyFormatter(promoCodeDetails?.promoCodeTax) || 0}
          </span>
        ) : (
          <span className="text-[#1A2024] text-sm not-italic font-normal leading-6">
            {translate("BHD", "د.ب")} {currencyFormatter(tax) || 0}
          </span>
        )}
      </div>
      <div className="flex justify-between px-0 py-2.5 border-t-[#DDE2E4] border-t border-solid">
        <span className="text-[#1A2024] text-sm not-italic font-extrabold leading-6">
          {translate("Total", "المجموع")}
        </span>
        {promoCodeDetails?.promoCodeApplied == true ? (
          <span className="text-[#1A2024] text-sm not-italic font-extrabold leading-6">
            {translate("BHD", "د.ب")}{" "}
            {currencyFormatter(promoCodeDetails?.promoCodeTotalPremium)}
          </span>
        ) : (
          <span className="text-[#1A2024] text-sm not-italic font-extrabold leading-6">
            {translate("BHD", "د.ب")} {currencyFormatter(totalPremium)}
          </span>
        )}
      </div>
    </div>
  );
};

export default Summary;
