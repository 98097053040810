import React, { useEffect, useMemo, useRef, useState } from "react";
import BackdropModal from "../../../../../../../components/common/modal/backdropModal";
import SectorDetailCard from "./sectorDetailCard";
import { sampleFundData, rollingChartData, countryFullData } from "./data";
import FundInfoCard from "./fundsInfoCard";
import RollingPerformanceChart from "./rollingPerformanceChart";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllFundsBreakDown,
  fetchAllFundsOperations,
  fetchAllFundsPerformance,
  fetchClosePraiseDaily,
  fetchNewAllFundsBreakDown,
  fetchRollingPerformance,
  selectAllFundsBreakdown,
  selectAllFundsWholeData,
  selectAllNewFundsBreakdown,
  selectClosePraiseDaily,
} from "../../../../../../../redux/features/allfunds/allFundsSlice";
import {
  getTodayAndMonthsBack,
  perCalcMinus100,
  percentageWith2Decimals,
  With2Decimals,
} from "../../../../../../../utils";
import { toPng } from "html-to-image";
import { jsPDF } from "jspdf";
import { toast } from "react-toastify";
import { Loader } from "storybook/internal/components";
import useLanguageTranslate from "../../../../../../../hooks/useLanguageTranslate";
import useFundQuarterlyReturns from "../../../../../../../hooks/useFundQuarterlyReturns";

const FundsPreviewPopup = ({ show, setShow }) => {
  const translate = useLanguageTranslate();
  const {
    data: chartResponse,
    fetchChartData,
    loading,
  } = useFundQuarterlyReturns();

  const onClose = () => {
    setShow(null);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllFundsPerformance(show?.isin));
    dispatch(fetchAllFundsBreakDown(show?.isin));
    dispatch(fetchNewAllFundsBreakDown(show?.isin));
    dispatch(fetchAllFundsOperations(show?.isin));
    dispatch(fetchClosePraiseDaily(show?.isin));
    // dispatch(fetchAllFundsPerformance("LU1060358063"||show?.isin))
    // dispatch(fetchAllFundsBreakDown("LU1060358063"||show?.isin))
    // dispatch(fetchNewAllFundsBreakDown("LU1060358063"||show?.isin))
    // dispatch(fetchAllFundsOperations("LU1060358063"||show?.isin))
    // dispatch(fetchClosePraiseDaily("LU1060358063"||show?.isin))
    // let { today, pastDate } = getTodayAndMonthsBack(12);
    // let params = {
    //     isin:  show?.isin,
    //     start_date: pastDate,
    //     end_date: today
    // }
    fetchChartData(show?.isin);
  }, []);

  const {
    // net_asset_allocation,
    net_country_exposure: old_country_exposure,
    net_currency_exposure,
    net_rating_distribution,
    net_sector_distribution,
    portfolio_date,
    top_10_underlying_assets,
  } = useSelector(selectAllFundsBreakdown) || {};

  const { net_country_exposure, net_asset_allocation } =
    useSelector(selectAllNewFundsBreakdown) || {};

  console.log(net_country_exposure, old_country_exposure, "#########");

  const closePraiseDaily = useSelector(selectClosePraiseDaily);
  console.log(closePraiseDaily, "closePraiseDaily some data");

  const { allfundsPerformance, allfundsOperational } = useSelector(
    selectAllFundsWholeData
  );

  const otherPercentage = useMemo(() => {
    let percentage = 0;
    Object.keys(net_country_exposure || {})?.forEach((key) => {
      if (key == "ISR") return;
      percentage += parseInt(net_country_exposure[key] || 0);
    });
    return 100 - (percentage || 0);
  }, [net_country_exposure]);

  const fundDetails = useMemo(() => {
    let fundoverview = show?.original?.attributes;
    return {
      fundName: fundoverview?.fundName,
      ISIN: fundoverview?.ISIN,
      assetClass: fundoverview?.assetClass,
      category: fundoverview?.category,
      description: fundoverview?.objective,
      pdfLink: "/download.pdf",
    };
  }, []);

  const ref = useRef(null);
  const [pdfLoading, setPdfLoading] = useState(false);
  const handleDownloadPdf = async () => {
    if (ref.current == null) {
      return;
    }
    try {
      setPdfLoading(true);
      const imgData = await toPng(ref.current);
      const pdf = new jsPDF();
      const img = new Image();
      img.src = imgData;
      await new Promise((resolve) => (img.onload = resolve));
      const imgWidth = img.width;
      const imgHeight = img.height;
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const scaleFactor = Math.min(
        pageWidth / imgWidth,
        pageHeight / imgHeight
      );
      const scaledWidth = imgWidth * scaleFactor;
      const scaledHeight = imgHeight * scaleFactor;
      const xOffset = (pageWidth - scaledWidth) / 2;
      const yOffset = (pageHeight - scaledHeight) / 2;
      pdf.addImage(imgData, "PNG", xOffset, yOffset, scaledWidth, scaledHeight);
      let name = fundDetails?.fundName || "fund";
      pdf.save(`${name}.pdf`);
      setPdfLoading(false);
    } catch (error) {
      console.error("Failed to generate PDF:", error);
      toast.error("Failed to generate PDF");
      setPdfLoading(false);
    }
  };

  const filteremptyData = (data) =>
    data?.filter((each) => each.value != "0.00%" || !each);

  const sectorDistributionData = useMemo(() => {
    let data = [
      {
        name: translate("Cash", "نقد"),
        value: percentageWith2Decimals(net_sector_distribution?.cash),
      },
      {
        name: translate("Energy", "الطاقة"),
        value: percentageWith2Decimals(net_sector_distribution?.energy),
      },
      {
        name: translate("Financials", "القطاع المالي"),
        value: percentageWith2Decimals(net_sector_distribution?.financials),
      },
      {
        name: translate("Government", "الحكومة"),
        value: percentageWith2Decimals(net_sector_distribution?.government),
      },
      {
        name: translate("Health Care", "الرعاية الصحية"),
        value: percentageWith2Decimals(net_sector_distribution?.health_care),
      },
      {
        name: translate("Industrials", "الصناعات"),
        value: percentageWith2Decimals(net_sector_distribution?.industrials),
      },
      {
        name: translate("Materials", "المواد"),
        value: percentageWith2Decimals(net_sector_distribution?.materials),
      },
      {
        name: translate("Real Estate", "العقارات"),
        value: percentageWith2Decimals(net_sector_distribution?.real_estate),
      },
      {
        name: translate("Utilities", "المرافق "),
        value: percentageWith2Decimals(net_sector_distribution?.real_estate),
      },
      {
        name: translate("Others", "أخرى "),
        value: percentageWith2Decimals(net_sector_distribution?.other),
      },

      {
        name: translate("Consumer Discretionary", "السلع الاستهلاكية الكمالية"),
        value: percentageWith2Decimals(
          net_sector_distribution?.consumer_discretionary
        ),
      },
      {
        name: translate("Communication Services", "خدمات الاتصالات"),
        value: percentageWith2Decimals(
          net_sector_distribution?.communication_services
        ),
      },
      {
        name: translate("Consumer Staples", "السلع الاستهلاكية الأساسية"),
        value: percentageWith2Decimals(
          net_sector_distribution?.consumer_staples
        ),
      },
      {
        name: translate("Information Technology", "تكنولوجيا المعلومات"),
        value: percentageWith2Decimals(
          net_sector_distribution?.information_technology
        ),
      },
    ];
    data = filteremptyData(data);
    return data;
  }, [net_sector_distribution]);

  const assetAllocationData = useMemo(() => {
    let data = [
      {
        name: translate("Fixed Income", "الدخل الثابت"),
        value: percentageWith2Decimals(net_asset_allocation?.fixed_income),
      },
      {
        name: translate("Equity", "حقوق الملكية"),
        value: percentageWith2Decimals(net_asset_allocation?.equity),
      },
      {
        name: translate("Fixed Income", "الدخل الثابت"),
        value: percentageWith2Decimals(net_asset_allocation?.fixed_income),
      },
      {
        name: translate("Fund", "صندوق"),
        value: percentageWith2Decimals(net_asset_allocation?.fund),
      },
      {
        name: translate("Other", "أخرى"),
        value: percentageWith2Decimals(net_asset_allocation?.other),
      },
    ];
    data = filteremptyData(data);
    return data;
  }, [net_asset_allocation]);

  const ratingDistributionData = useMemo(() => {
    let data = Object.keys(net_rating_distribution || {})?.map((key) => ({
      name: key,
      value: percentageWith2Decimals(net_rating_distribution?.[key]),
    }));
    data = filteremptyData(data);
    return data;
  }, [net_rating_distribution]);

  const topHoldingsData = useMemo(() => {
    let data = top_10_underlying_assets?.map((each) => ({
      name: each?.name,
      value: percentageWith2Decimals(each?.weight),
    }));
    data = filteremptyData(data);
    return data;
  }, [top_10_underlying_assets]);

  const countryExposureData = useMemo(() => {
    let data = [
      ...Object.keys(net_country_exposure || {})?.map((key) => ({
        name: countryFullData[key],
        value: percentageWith2Decimals(net_country_exposure?.[key]),
      })),
      {
        name: translate("Others", "أخرى"),
        value: `${otherPercentage}%`,
      },
    ];

    data = filteremptyData(data);
    if (data?.length == 1) {
      return [];
    }
    return data;
  }, [net_country_exposure]);

  const priceAndPerformanceData = useMemo(() => {
    let data = [
      {
        name: translate("Total NAV", "إجمالي صافي قيمة الأصول"),
        value: `${With2Decimals(closePraiseDaily?.value || 0)} USD`,
      },
      {
        name: translate("Day Change", "التغير اليومي"),
        value: perCalcMinus100(allfundsPerformance?.one_day),
      },
      {
        name: translate("One Month", "شهر واحد"),
        value: perCalcMinus100(allfundsPerformance?.one_month),
      },
      {
        name: translate("Three Months", "ثلاثة أشهر"),
        value: perCalcMinus100(allfundsPerformance?.three_months),
      },
      {
        name: translate("Six Months", "ستة أشهر"),
        value: perCalcMinus100(allfundsPerformance?.six_months),
      },
      {
        name: translate("YTD", "منذ بداية العام وحتى اليوم"),
        value: perCalcMinus100(allfundsPerformance?.year_to_date),
      },
      {
        name: translate("One Year", "سنة واحدة"),
        value: perCalcMinus100(allfundsPerformance?.one_year),
      },
      {
        name: translate("Three Years", "ثلاث سنوات"),
        value: perCalcMinus100(allfundsPerformance?.three_years),
      },
      {
        name: translate("Five Years", "خمس سنوات"),
        value: perCalcMinus100(allfundsPerformance?.five_years),
      },
      {
        name: translate("Ten Years", "عشر سنوات"),
        value: perCalcMinus100(allfundsPerformance?.ten_years),
      },
    ];
    data = filteremptyData(data);
    return data;
  }, [allfundsPerformance, closePraiseDaily]);

  const productFeeData = useMemo(() => {
    let data = [
      {
        name: translate("Fund fees", "رسوم الصندوق"),
        value: percentageWith2Decimals(allfundsOperational?.refund_fee),
      },
      {
        name: translate("Ongoing charges", "الرسوم المستمرة"),
        value: percentageWith2Decimals(allfundsOperational?.ongoing_charges),
      },
      {
        name: translate("Management fee", "رسوم الإدارة"),
        value: percentageWith2Decimals(allfundsOperational?.management_fee),
      },
      {
        name: translate("Performance fee", "رسوم الأداء"),
        value: percentageWith2Decimals(allfundsOperational?.performance_fee),
      },
      {
        name: translate("Subscription fee up too", "رسوم الاشتراك تصل إلى"),
        value: percentageWith2Decimals(allfundsOperational?.subscription_fee),
      },
      {
        name: translate("Distribution fee", "رسوم التوزيع"),
        value: percentageWith2Decimals(allfundsOperational?.distribution_fee),
      },
      {
        name: translate("Deposit fee", "رسوم الإيداع"),
        value: percentageWith2Decimals(allfundsOperational?.deposit_fee),
      },
      // { name: translate("Redemption fee up to","رسوم الاسترداد تصل إلى"), value: percentageWith2Decimals(allfundsOperational?.redemption_by_amount) },
    ];
    data = filteremptyData(data);
    return data;
  }, [allfundsOperational]);

  const currencyExposureData = useMemo(() => {
    let data = Object.keys(net_currency_exposure || {})?.map((key) => ({
      name: key,
      value: percentageWith2Decimals(net_currency_exposure?.[key]),
    }));
    data = filteremptyData(data);
    return data;
  }, [net_currency_exposure]);

  return (
    <BackdropModal onClose={onClose} show={show} className="fundnamemodal">
      {pdfLoading && (
        <Loader className=" w-10 h-10 fixed left-[50%] top-[50%]" />
      )}

      <div ref={ref}>
        <FundInfoCard
          {...fundDetails}
          pdfLoading={pdfLoading}
          htmlToImageConvert={handleDownloadPdf}
        />
        <RollingPerformanceChart
          title={translate("Rolling Performance", "الأداء المتتابع")}
          // chartData={rollingChartData?.data}
          // labels={rollingChartData?.labels}
          fundLabel={fundDetails?.fundName}
          // benchmarkLabel="Barclays US High Yield 2% Issr Cap Total Return Index"
          fundColor="#FF6600"
          benchmarkColor="#228B22"
          chartData={chartResponse}
        />
        {/* <PerformanceChart /> */}
        <div className=" grid-cols-3 p-0 mb-12 rounded-lg mt-5 ipad:grid-cols-2 max767:grid-cols-1 flex w-[1152px] max-w-[100%] items-start content-start gap-4 flex-wrap">
          <SectorDetailCard
            title={translate("Sector Distribution", "توزيع القطاع")}
            data={sectorDistributionData}
          />
          <SectorDetailCard
            title={translate("Rating Distribution", "توزيع التصنيف")}
            data={ratingDistributionData}
          />

          <SectorDetailCard
            title={translate("Top Holdings", "أهم الحيازات")}
            data={topHoldingsData}
          />
          <SectorDetailCard
            title={translate("Country Exposure", "المخاطر المرتبطة بالدولة")}
            data={countryExposureData}
          />

          <SectorDetailCard
            title={translate("Price & Performance", "السعر والأداء")}
            data={priceAndPerformanceData}
          />
          <SectorDetailCard
            title={translate("Product Fees", "رسوم المنتج")}
            // data={sampleFundData.productFees}
            data={productFeeData}
          />

          <SectorDetailCard
            title={translate("Currency Exposure", "المخاطر المرتبطة بالعملة")}
            data={currencyExposureData}
          />
          <SectorDetailCard
            title={translate("Asset Allocation", "تخصيص الأصول")}
            data={assetAllocationData}
          />
        </div>
      </div>
    </BackdropModal>
  );
};

export default FundsPreviewPopup;
