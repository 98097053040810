import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../axiosInstance";
import { accountApiEndpoints } from "../../api";

// Async thunk to handle the API call
export const sendOtp = createAsyncThunk(
  "otp/sendOtp",
  async (mobileNumber, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`${accountApiEndpoints.sendToken}`, { mobileNumber });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || 'Error occurred');
    }
  }

  // (payload, { rejectWithValue }) => {
  //   return Axios.post(`${accountApiEndpoints.sendToken}`, payload)
  //     .then((res) => {
  //       return res.data; 
  //     })
  //     .catch((err) => {
  //       return rejectWithValue(err?.response?.data || 'Error occurred while sending OTP');
  //     });
  // }
);

const otpSlice = createSlice({
  name: "otp",
  initialState: {
    isLoading: false,
    error: null,
    data: null,
  },
  reducers: {
    resetOtpState: (state) => {
      state.loading = false;
      state.error = null;
      state.data = false;
    },
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendOtp.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to send OTP";
      });
  },
});

export const selectOtpLoading = (state) => state?.otpData?.isLoading;
export const selectOtpError = (state) => state?.otpData?.error;
export const selectOtpData = (state) => state?.otpData?.data;

export const { resetOtpState } = otpSlice.actions;

export default otpSlice.reducer;
