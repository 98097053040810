import React, { useState } from "react";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import useFileClaimFlow from "../../../hooks/useFileClaimFlow";
import FileClaimMotorLayout from "./fileClaimMotorLayout";
import forwardarrow from "../../../assets/images/arrow_forwardb.svg";
import Input from "../../../components/common/Input/Input";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../../../api/baseURL";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import useMotorForm from "../../../hooks/useMotorFormData";
import { selectNewMotorForm } from "../../../redux/features/newMotorFormSlice";
import { useSelector } from "react-redux";
import Loader from "../../../components/common/Loader/Loader";
import CustomCheckbox from "../../../components/common/customCheckbox/customCheckbox";
import { format } from "date-fns";
import { InsuranceCert } from "./uploadDocsPage";

export const CaseNo = "CaseNumber";

function ReviewAndConfirmPage({
  selectedCustomer,
  selectedInsuredType,
  selectedClaimType,
  driverDocs,
  incidentDoc,
  accidentDocs,
  insuranceCert,
  propertyOwner,
  proofAddress,
  impactedPersonCprDocs,
  judicialReportDocs,
  otherDocs,
  AccidentInfoDetails,
  claimInfo,
  propertyAccidentPhotosDocs,
  selectedTpType,
  propertyDamageInfo,
  ownerShipDocs,
}) {
  const [caseNo, setCaseNo] = useState({});
  const { saveMotorDataInRedux } = useMotorForm(CaseNo, setCaseNo);

  //   if (judicialReportDocs && judicialReportDocs.length > 0 && judicialReportDocs[0].preview) {
  //   console.log("I am the owner " + judicialReportDocs?.[0].preview);
  // } else {
  //   var checking = judicialReportDocs?.[0]?.preview ?? '';
  //   console.log("propertyOwner is undefined, empty, or missing 'preview'");
  // }
  const [isChecked, setIsChecked] = useState(false);
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const [isLoading, setIsLoading] = useState(false);
  const { handleNextSubstep } = useFileClaimFlow();
  const continueClickHanlder = () => {
    handleNextSubstep();
  };
  console.log(selectedInsuredType, "selectedInsuredType");
  console.log(selectedClaimType, "selectedClaimType");

  const handleCaimSubmit = async () => {
    var claim = await CreateClaimAPI();
    setCaseNo(claim?.CaseNumber);
    saveMotorDataInRedux(claim?.CaseNumber);
    continueClickHanlder();
  };

  const CreateClaimAPI = async () => {
    const url = BASE_URL + "/claimcrm/createclaim";
    const data = {
      // Your data to send in the request body
      isCustomer: selectedCustomer == "SOLIDARITY_CUSTOMER" ? true : false,
      CPR:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? ""
          : claimInfo?.driverCprNumber ?? "",
      ownerCPR:
        selectedInsuredType == "PROPERTY_DAMAGE" ||
        selectedInsuredType == "BODILY_INJURY"
          ? propertyDamageInfo?.ownerCpr
          : claimInfo?.ownerCprNumber ?? "",
      cprImpacted: "",
      ownerName:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? propertyDamageInfo?.ownerName
          : "",
      ownerAddress:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? propertyDamageInfo?.address
          : "",
      personForSurvey:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? AccidentInfoDetails?.mobile
          : "",
      Email:
        selectedInsuredType == "PROPERTY_DAMAGE" ||
        selectedInsuredType == "BODILY_INJURY"
          ? propertyDamageInfo?.email
          : claimInfo?.email ?? "",
      PhoneNumber:
        selectedInsuredType == "PROPERTY_DAMAGE" ||
        selectedInsuredType == "BODILY_INJURY"
          ? propertyDamageInfo?.mobile
          : claimInfo?.mobile ?? "",
      RegNumber:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? ""
          : claimInfo?.plateNumber ?? "",
      desc:
        selectedInsuredType == "BODILY_INJURY"
          ? propertyDamageInfo?.accidentDesc
          : AccidentInfoDetails?.accidentDesc ?? "",
      SurveyDate:
        AccidentInfoDetails?.surveyDate != null
          ? new Date(AccidentInfoDetails?.surveyDate).toISOString()
          : "",
      IntimateDate: new Date().toISOString(),
      GarageCode: AccidentInfoDetails?.workshop || "Others",
      Source: "online",
      ClaimCompensation: selectedClaimType == "cash" ? 1 : 0,
      InsuredType:
        selectedTpType == "individualComprehensive"
          ? "IND"
          : selectedTpType == "individualThirdParty"
          ? "IND"
          : selectedTpType == "corporateClient"
          ? "CORP"
          : "",
      TPType: selectedCustomer == "THIRD_PARTY_CUSTOMER" ? "DirectTP" : "",
      ClaimOptions:
        selectedInsuredType == "VEHICLE_DAMAGE"
          ? "Vehicle"
          : selectedInsuredType == "PROPERTY_DAMAGE"
          ? "Property"
          : selectedInsuredType == "BODILY_INJURY"
          ? "Bodily"
          : "",
      InsuredCoverage:
        selectedTpType == "individualComprehensive"
          ? "COMP"
          : selectedTpType == "individualThirdParty"
          ? "TP"
          : "",
    };

    try {
      if (!isLoading) {
        setIsLoading(true); // Start loading
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Set headers for JSON
          },
          body: JSON.stringify(data), // Convert data to JSON string
        });
        setIsLoading(false); // Stop loading
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        var guid = result?.id;
        await UploadDocs(guid);
        console.log("Success:", result);
        return result;
      }
    } catch (error) {
      setIsLoading(false); // Stop loading in case of an error
      console.error("Error:", error);
    }
  };

  const UploadDocs = async (guid) => {
    const url = BASE_URL + "/claimcrm/updateclaim";
    const data = {
      // Your data to send in the request body
      GuidId: guid,
      Cpr1:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? propertyOwner?.[0]?.preview ?? ""
          : "",
      Cpr2:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? propertyOwner?.[1]?.preview ?? ""
          : "",
      propertyProof: "PROPERTY_DAMAGE" ? ownerShipDocs?.[0]?.preview ?? "" : "",
      OwnerShip1:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? proofAddress?.[0]?.preview ?? ""
          : ownerShipDocs?.[0]?.preview ?? "",
      OwnerShip2:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? proofAddress?.[1]?.preview ?? ""
          : ownerShipDocs?.[1]?.preview ?? "",
      AccidentPhoto:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? propertyAccidentPhotosDocs?.[0]?.preview ?? ""
          : accidentDocs?.[0]?.preview ?? "",
      AccidentPhoto2:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? propertyAccidentPhotosDocs?.[1]?.preview ?? ""
          : accidentDocs?.[1]?.preview ?? "",
      AccidentPhoto3:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? propertyAccidentPhotosDocs?.[2]?.preview ?? ""
          : accidentDocs?.[2]?.preview ?? "",
      AccidentPhoto4:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? propertyAccidentPhotosDocs?.[3]?.preview ?? ""
          : accidentDocs?.[3]?.preview ?? "",
      AccidentPhoto5:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? propertyAccidentPhotosDocs?.[4]?.preview ?? ""
          : accidentDocs?.[4]?.preview ?? "",
      AccidentPhoto6:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? propertyAccidentPhotosDocs?.[5]?.preview ?? ""
          : accidentDocs?.[5]?.preview ?? "",
      AccidentPhoto7:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? propertyAccidentPhotosDocs?.[6]?.preview ?? ""
          : accidentDocs?.[6]?.preview ?? "",
      AccidentPhoto8:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? propertyAccidentPhotosDocs?.[7]?.preview ?? ""
          : accidentDocs?.[7]?.preview ?? "",
      AccidentPhoto9:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? propertyAccidentPhotosDocs?.[8]?.preview ?? ""
          : accidentDocs?.[8]?.preview ?? "",
      AccidentPhoto10:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? propertyAccidentPhotosDocs?.[9]?.preview ?? ""
          : accidentDocs?.[9]?.preview ?? "",
      Driving1:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? propertyOwner?.[0]?.preview ?? ""
          : selectedInsuredType == "PROPERTY_DAMAGE"
          ? impactedPersonCprDocs?.[0].preview
          : driverDocs?.[0]?.preview ?? "",
      Driving2:
        selectedInsuredType == "PROPERTY_DAMAGE"
          ? propertyOwner?.[1]?.preview ?? ""
          : selectedInsuredType == "PROPERTY_DAMAGE"
          ? impactedPersonCprDocs?.[1].preview
          : driverDocs?.[1]?.preview ?? "",
      JudicialRuling: judicialReportDocs?.[0]?.preview ?? "",
      JudicialRuling2: judicialReportDocs?.[1]?.preview ?? "",
      JudicialRuling3: judicialReportDocs?.[2]?.preview ?? "",
      JudicialRuling4: judicialReportDocs?.[3]?.preview ?? "",
      JudicialRuling5: judicialReportDocs?.[4]?.preview ?? "",
      OtherDocs:
        selectedInsuredType == "BODILY_INJURY"
          ? otherDocs?.[0]?.preview ?? ""
          : insuranceCert?.[0]?.preview ?? "",
      OtherDocs2:
        selectedInsuredType == "BODILY_INJURY"
          ? otherDocs?.[1]?.preview ?? ""
          : insuranceCert?.[1]?.preview ?? "",
      OtherDocs3:
        selectedInsuredType == "BODILY_INJURY"
          ? otherDocs?.[2]?.preview ?? ""
          : insuranceCert?.[2]?.preview ?? "",
      OtherDocs4:
        selectedInsuredType == "BODILY_INJURY"
          ? otherDocs?.[3]?.preview ?? ""
          : insuranceCert?.[3]?.preview ?? "",
      OtherDocs5:
        selectedInsuredType == "BODILY_INJURY"
          ? otherDocs?.[4]?.preview ?? ""
          : insuranceCert?.[4]?.preview ?? "",
      IncidentReport: incidentDoc?.[0]?.preview ?? "",
      IncidentReport2: incidentDoc?.[1]?.preview ?? "",
      IncidentReport3: incidentDoc?.[2]?.preview ?? "",
      IncidentReport4: incidentDoc?.[3]?.preview ?? "",
      IncidentReport5: incidentDoc?.[4]?.preview ?? "",
      PolicyInformation: insuranceCert?.[0]?.preview ?? "",
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Set headers for JSON
        },
        body: JSON.stringify(data), // Convert data to JSON string
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Success:", result);
      return result;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const declaration = t(
    "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.declaration",
    { returnObjects: true }
  );

  console.log("AccidentInfoDetails&&&&&&&", AccidentInfoDetails);
  return (
    <FileClaimMotorLayout
      is_bottom_sticky_layout={false}
      btn_onClick={continueClickHanlder}
    >
      <div className="mx-auto  bg-white max767:mt-8 max767:p-4 w-[500px] max-w-[100%]">
        <TitleDescription
          title={t("motorInsurance.fileAClaimFlow.reviewAndConfirmPage.title")}
          description={t(
            "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.description"
          )}
        />
        <div className="max767:block hidden max767:mb-6 max767:mt-0">
          <TitleDescription
            title={t(
              "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.mobile_title"
            )}
            description={t(
              "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.mobile_description"
            )}
          />
        </div>
        <div className="flex flex-col items-start gap-6 self-stretch bg-[#F6F8F9] p-4 rounded-[26px]">
          <table className="w-full table-auto border-separate border border-solid border-[#DDE2E4] border-spacing-0 bg-white rounded-[10px]">
            <tbody>
              {/* Table rows for each piece of information */}
              <tr className="border-b">
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t(
                    "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.claim_type_label"
                  )}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {translate("Motor Policy", "وثيقة تأمين المركبة")}
                </td>
              </tr>

              <tr
                className={`border-b ${
                  selectedCustomer == "THIRD_PARTY_CUSTOMER" ? "hidden" : ""
                }`}
              >
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t(
                    "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.policy_plan_label"
                  )}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {selectedTpType == "individualComprehensive"
                    ? "Individual Comprehensive"
                    : selectedTpType == "individualThirdParty"
                    ? "Individual Third Party"
                    : selectedTpType == "corporateClient"
                    ? "Corporate Client"
                    : ""}
                </td>
              </tr>

              <tr
                className={`border-b ${
                  selectedInsuredType == "PROPERTY_DAMAGE" ||
                  selectedClaimType == "BODILY_INJURY"
                    ? "hidden"
                    : ""
                }`}
              >
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t(
                    "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.driver_cpr_label"
                  )}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {claimInfo?.driverCprNumber}
                </td>
              </tr>

              <tr className="border-b">
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t(
                    "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.vehicle_cpr_label"
                  )}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {selectedInsuredType == "PROPERTY_DAMAGE" ||
                  selectedInsuredType == "BODILY_INJURY"
                    ? propertyDamageInfo?.ownerCpr
                    : claimInfo?.ownerCprNumber}
                </td>
              </tr>

              <tr
                className={`border-b ${
                  selectedInsuredType == "PROPERTY_DAMAGE" ||
                  selectedInsuredType == "BODILY_INJURY"
                    ? "hidden"
                    : ""
                }`}
              >
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t(
                    "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.vehicle_plate_num_label"
                  )}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {claimInfo?.plateNumber}
                </td>
              </tr>

              <tr className="border-b">
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t(
                    "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.email_label"
                  )}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {selectedInsuredType == "PROPERTY_DAMAGE" ||
                  selectedInsuredType == "BODILY_INJURY"
                    ? propertyDamageInfo?.email
                    : claimInfo?.email}
                </td>
              </tr>

              <tr className="border-b">
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t(
                    "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.mobile_label"
                  )}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {selectedInsuredType == "PROPERTY_DAMAGE" ||
                  selectedInsuredType == "BODILY_INJURY"
                    ? propertyDamageInfo?.mobile
                    : claimInfo?.mobile}
                </td>
              </tr>

              <tr className="border-b">
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t(
                    "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.accident_label"
                  )}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {selectedInsuredType == "BODILY_INJURY"
                    ? propertyDamageInfo?.accidentDesc
                    : AccidentInfoDetails?.accidentDesc}
                </td>
              </tr>

              <tr
                className={`border-b ${
                  selectedInsuredType == "PROPERTY_DAMAGE" ||
                  selectedInsuredType == "BODILY_INJURY"
                    ? "hidden"
                    : ""
                }`}
              >
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t(
                    "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.preferred_garage_label"
                  )}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {AccidentInfoDetails?.Garage}
                </td>
              </tr>

              <tr
                className={`border-b ${
                  selectedInsuredType == "BODILY_INJURY" ? "hidden" : ""
                }`}
              >
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t(
                    "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.preferred_repair_label"
                  )}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {selectedInsuredType == "BODILY_INJURY"
                    ? "hidden"
                    : format(
                        new Date(AccidentInfoDetails?.surveyDate ?? ""),
                        "dd/MM/yyyy"
                      )}
                </td>
              </tr>

              <tr
                className={`border-b ${
                  selectedInsuredType == "PROPERTY_DAMAGE" ||
                  selectedInsuredType == "BODILY_INJURY"
                    ? "hidden"
                    : ""
                }`}
              >
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t(
                    "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.driver_licence_label"
                  )}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {driverDocs?.[0]?.path ? driverDocs?.[0].path : ""}{" "}
                  {driverDocs?.[1]?.path ? driverDocs?.[1].path : ""}
                </td>
              </tr>

              <tr
                className={`border-b ${
                  selectedInsuredType == "PROPERTY_DAMAGE" ||
                  selectedInsuredType == "BODILY_INJURY"
                    ? "hidden"
                    : ""
                }`}
              >
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t(
                    "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.vehicle_owner_label"
                  )}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {ownerShipDocs?.[0]?.path ? ownerShipDocs?.[0].path : ""}{" "}
                  {ownerShipDocs?.[1]?.path ? ownerShipDocs?.[1].path : ""}
                </td>
              </tr>

              <tr className="border-b">
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t(
                    "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.photos_of_accident_label"
                  )}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {accidentDocs?.[0]?.path ? accidentDocs?.[0].path : ""}{" "}
                  {accidentDocs?.[1]?.path ? accidentDocs?.[1].path : ""}{" "}
                  {propertyAccidentPhotosDocs?.[0]?.path
                    ? propertyAccidentPhotosDocs?.[0].path
                    : ""}{" "}
                  {propertyAccidentPhotosDocs?.[1]?.path
                    ? propertyAccidentPhotosDocs?.[1].path
                    : ""}
                </td>
              </tr>

              <tr className="border-b">
                <td className="border-b border-r py-2 px-4 text-[#5B6871] text-[13px] not-italic font-medium leading-6">
                  {t(
                    "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.incident_gov_label"
                  )}
                </td>
                <td className="border-b py-2 px-4 text-[#1A2024] text-[13px] not-italic font-medium leading-6">
                  {incidentDoc?.[0].path ?? ""}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* Terms and conditions note */}
        <div className="text-sm mt-6 flex mb-6 justify-start chekcboxdiv">
          <CustomCheckbox
            Label={
              <div className="flex">
                <label
                  htmlFor="agreement"
                  className="text-[#252C32] text-sm not-italic font-normal leading-6 pl-3"
                >
                  {declaration?.map((paragraph, index) => (
                    <p className="mb-2" key={index}>
                      {paragraph}
                    </p>
                  ))}
                </label>
              </div>
            }
            isChecked={isChecked}
            setIsChecked={setIsChecked}
          />
        </div>
        <button
          className={`w-full text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] flex justify-center p-3 rounded-[10px] ${
            isChecked
              ? "bg-[#55C6EF] rounded-[10px] cursor-pointer"
              : "bg-gray-300 text-gray-500 cursor-not-allowed"
          }`}
          onClick={handleCaimSubmit}
          disabled={!isChecked}
        >
          <span>
            {t(
              "motorInsurance.fileAClaimFlow.reviewAndConfirmPage.submitClaimBtnLabel"
            )}
          </span>
          {isLoading ? (
            <div className="w-6 h-6 ml-[5px] rtl:rotate-180 rtl:mr-2">
              <Loader />
            </div>
          ) : (
            <img
              src={forwardarrow}
              className=" w-6 h-6 ml-[5px] rtl:rotate-180 rtl:mr-2"
              alt="forward arrow"
            />
          )}
        </button>
      </div>
    </FileClaimMotorLayout>
  );
}

export default ReviewAndConfirmPage;
