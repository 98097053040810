import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import { constantsApiEndpoints } from "../../../api";
import { toast } from "react-toastify";

// State that the response will be put into
const initialState = {
  banks: null,
  isLoading: false,
  success: null,
  error: null,
};

// Async API call
// export const fetchBanksList = createAsyncThunk(
//   "fetchBanksList",
//   (payload, { rejectWithValue }) => {
//     return Axios.post(`${constantsApiEndpoints.banks}`).then((res) => res.data);
//   }
// );

// Async API call with Axios timeout
export const fetchBanksList = createAsyncThunk(
  "fetchBanksList",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`${constantsApiEndpoints.banks}`, null, {
        timeout: 30000, // Set timeout to 5 seconds (5000 ms)
      });
      return response.data;
    } catch (error) {
      if (error.code =='ECONNABORTED') {
        // Handle timeout specific error
        toast('Request timed out');
        return rejectWithValue('Request timed out');
      }
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

// Redux slice that contains the reducers/action calls
export const banksListSlice = createSlice({
  name: "Get Banks List",
  initialState,
  reducers: {
    clearBanksListError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // FETCH  UTILS API
    builder.addCase(fetchBanksList.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchBanksList.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.banks = action.payload;
    });
    builder.addCase(fetchBanksList.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.error?.message;
    });
  },
});

// SELECTORS which can be easily called to get the corresponding value
export const selectBanks = (store) => store
export const selectBanksLoader = (store) => store?.carsList?.isLoading
export const selectBanksSuccess = (store) => store?.carsList?.success


// ACTIONS TO DISPATCH
export const { clearBanksListError } = banksListSlice.actions;

export default banksListSlice.reducer;
