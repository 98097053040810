/* :Data:NewMotor:Renewal Cover options New and Renewal */

import { useDispatch } from "react-redux";
import { getCoverPrices } from "../../../../redux/features/motor/getQuoteSlice";
import { coverPriceRequest } from "../planPages/data";
import { currencyFormatter } from "../../../../utils";

export const setCoverOptions = (coverPrices, planCode, finalCovers) => {

  // Function to adjust the cover prices API and make a list of cover codes and their prices
  console.log("Should be getting covers for plan: ", planCode); // Plan code
  console.log('Cover prices passed:', coverPrices); // Covers received from cover prices API 
  const coverPremiums = extractFinalPremiums(coverPrices); // Get prices 
  console.log("Cover premiums: ", coverPremiums);  // Log prices
  console.log('Final covers passed: ', finalCovers); // Current/Final covers
  console.log('');
  return [
    {
      code: 'CMOT28',
      name: "Car Replacement & Bahrain Roadside assistance",
      name_ar: "استبدال السيارة",
      included: planCode =='OPL' || planCode =='TPPLUS' || planCode =='OFL' || planCode =='GO' ? true : finalCovers ? finalCovers.coverOptionsState[0].included : false,
      price: coverPremiums['CMOT28'] || 0,
      // noOfDays: [
      //   {
      //     label_en: "8 Days",
      //     label_ar: "8 عاةلى السياسة",
      //     type: "8",
      //   },
      //   {
      //     label_en: "10 Days",
      //     label_ar: "10 شهادة المركبة",
      //     type: "10",
      //   },
      //   {
      //     label_en: "15 Days",
      //     label_ar: "15 التغطيات والمزايا",
      //     type: "15",
      //   },
      //   {
      //     label_en: "30 Days",
      //     label_ar: "30 إدارة سياستي",
      //     type: "30",
      //   },
      // ],
      // Conditional noOfDays based on selectedCarType and planCode
      noOfDays: (() => {
        if (planCode =='OPL') {
          var selectedType = finalCovers ? finalCovers.coverOptionsState[0].selectedCarType : "";
          switch (selectedType.toUpperCase()) {
            case 'SMALL':
              return [
                {
                  label_en: "30 Days",
                  label_ar: "30 إدارة سياستي",
                  type: "30",
                },
              ];
            case 'MEDIUM':
              return [
                {
                  label_en: "15 Days",
                  label_ar: "15 التغطيات والمزايا",
                  type: "15",
                },
                {
                  label_en: "30 Days",
                  label_ar: "30 إدارة سياستي",
                  type: "30",
                },
              ];
            case 'LUXURY':
              return [
                {
                  label_en: "8 Days",
                  label_ar: "8 عاةلى السياسة",
                  type: "8",
                },
                {
                  label_en: "10 Days",
                  label_ar: "10 شهادة المركبة",
                  type: "10",
                },
                {
                  label_en: "15 Days",
                  label_ar: "15 التغطيات والمزايا",
                  type: "15",
                },
                {
                  label_en: "30 Days",
                  label_ar: "30 إدارة سياستي",
                  type: "30",
                },
              ];
            default:
              return [
                {
                  label_en: "30 Days",
                  label_ar: "30 إدارة سياستي",
                  type: "30",
                }
              ];
          }
        } else if (planCode =='TPPLUS' || planCode =='TPL') {
          return [
            {
              label_en: "8 Days",
              label_ar: "8 عاةلى السياسة",
              type: "8",
            }
          ]
        }
        else {
          return [
            {
              label_en: "8 Days",
              label_ar: "8 عاةلى السياسة",
              type: "8",
            },
            {
              label_en: "10 Days",
              label_ar: "10 شهادة المركبة",
              type: "10",
            },
            {
              label_en: "15 Days",
              label_ar: "15 التغطيات والمزايا",
              type: "15",
            },
            {
              label_en: "30 Days",
              label_ar: "30 إدارة سياستي",
              type: "30",
            },
          ];
        }
      })(),
      carType: (() => {
        if (planCode =='TPPLUS' || planCode =='TPL') {
          return [
            {
              label_en: "Small Car",
              label_ar: " إدارة سياستي",
              type: "SMALL",
            }
          ]
        }
        else {
          return [
            {
              label_en: "Small Car",
              label_ar: " إدارة سياستي",
              type: "SMALL",
            },
            {
              label_en: "Medium Car",
              label_ar: "إدارة سياستي",
              type: "MEDIUM",
            },
            {
              label_en: "Luxury Car",
              label_ar: " إدارة سياستي",
              type: "LUXURY",
            },
          ]
        }
      })(),
      selectedDays: finalCovers ? finalCovers.coverOptionsState[0].selectedDays : (planCode =='OPL' ? "30" : "8"),
      selectedCarType: finalCovers ? finalCovers.coverOptionsState[0].selectedCarType : "SMALL",
      defaultIncludedInPlan: isDefaultForPlan('CMOT28', planCode),
      showCover: true,
      disabled: false
    },
    {
      code: "CMOT16",
      name: "GCC Roadside Assistance (including Bahrain)",
      name_ar: "المساعدة على الطريق",
      // included: planCode == 'OPL' || planCode == 'TPPLUS' || planCode == 'OFL' ? true : finalCovers ? finalCovers.coverOptionsState[1].included : false,
      included: false,
      price: coverPremiums['CMOT16'] || 0,
      defaultIncludedInPlan: isDefaultForPlan('CMOT16', planCode),
      showCover: planCode == 'TPL' || planCode == 'TPPLUS'
    },
    {
      code: "CMOT34",
      name: "Personal Accident Benefits to Passengers",
      name_ar: "فوائد الحوادث الشخصية للركاب",
      included: finalCovers ? finalCovers.coverOptionsState[2].included : false,
      price: coverPremiums['CMOT34'] || 0,
      defaultIncludedInPlan: isDefaultForPlan('CMOT34', planCode),
      showCover: planCode == 'OPL' || planCode == 'OFL' || planCode == 'GO' || planCode == 'FL' ? true : false
    },
    {
      code: "CMOT24",
      name: "Geographical Extension",
      name_ar: "الامتداد الجغرافي",
      included: planCode == 'OPL' || planCode == 'GO' ? true : finalCovers ? finalCovers.coverOptionsState[3].included : false,
      price: currencyFormatter(coverPremiums['CMOT24']) || 0,
      options: (() => {
        if (planCode == 'OPL') {
          return [
            {
              label_en: "GCC",
              type: "GCC"
            }
          ]
        }
        else {
          return [
            {
              label_en: "KSA",
              type: "KSA"
            },
            {
              label_en: "GCC",
              type: "GCC"
            }
          ]
        }
      })(),
      selectedGeoOption: planCode == 'OPL' ? 'GCC' : 'KSA',
      defaultIncludedInPlan: isDefaultForPlan('CMOT24', planCode),
      showCover: planCode == 'OPL' || planCode == 'OFL' || planCode == 'GO' || planCode == 'FL' ? true : false,
      disabled: planCode == 'OPL' || planCode == 'GO' ? true : false
    },
    {
      code: "CMOT9",
      name: "Act of God",
      name_ar: "فعل الله",
      included: planCode == 'OPL' || planCode == 'GO' ? true : finalCovers ? finalCovers.coverOptionsState[4].included : false,
      price: currencyFormatter(coverPremiums['CMOT9']) || 0,
      defaultIncludedInPlan: isDefaultForPlan('CMOT9', planCode),
      // showCover: planCode == 'OPL' || planCode =='OFL' ? true : false
      showCover: planCode == 'OPL' || planCode == 'OFL' || planCode == 'GO' || planCode == 'FL' ? true : false

    },
    {
      code: "CMOT11",
      name: "Windshield",
      name_ar: "الزجاج الأمامي",
      included: false,
      price: coverPremiums['CMOT11'] || 0,
      defaultIncludedInPlan: true,
      showCover: false
    },
    {
      code: "CMOT13",
      name: "VIP",
      name_ar: "كبار الشخصيات",
      included: planCode == 'OPL' ? true : finalCovers ? finalCovers.coverOptionsState[6].included : false,
      price: coverPremiums['CMOT13'] || 0,
      defaultIncludedInPlan: isDefaultForPlan('CMOT13', planCode),
      showCover: planCode == 'OPL' || planCode == 'OFL' || planCode == 'GO' || planCode == 'FL' ? true : false
    },
    {
      code: "CMOT40",
      name: "Personal Accident Benefits to Driver",
      name_ar: "فوائد الحوادث الشخصية للسائق",
      included: planCode == 'OPL' || planCode == 'GO' ? true : finalCovers ? finalCovers.coverOptionsState[7].included : false,
      price: coverPremiums['CMOT40'] || 0,
      defaultIncludedInPlan: isDefaultForPlan('CMOT40', planCode),
      showCover: true
    },
    {
      code: "CMOT61",
      name: "Strike, Riot & Civil Commotion",
      name_ar: "الإضراب والشغب والاضطرابات المدنية",
      included: planCode == 'OPL' ? true : finalCovers ? finalCovers.coverOptionsState[8].included : false,
      price: currencyFormatter(coverPremiums['CMOT61']) || 0,
      defaultIncludedInPlan: isDefaultForPlan('CMOT61', planCode),
      showCover: planCode == 'OPL' || planCode == 'OFL' || planCode == 'GO' || planCode == 'FL' ? true : false
    },
    {
      code: "CMOT99",
      name: "Nil Depreciation on Parts",
      name_ar: "استهلاك الأجزاء",
      included: planCode == 'OPL' || planCode == 'GO' ? true : finalCovers ? finalCovers.coverOptionsState[9].included : false,
      price: coverPremiums['CMOT99'] || 0,
      defaultIncludedInPlan: isDefaultForPlan('CMOT99', planCode),
      showCover: planCode == 'OPL' || planCode == 'OFL' || planCode == 'GO' || planCode == 'FL' ? true : false
    },
    {
      code: "CMOT21",
      name: "Life Insurance",
      name_ar: "تأمين على الحياة",
      included: planCode == 'OPL' ? true : finalCovers ? finalCovers.coverOptionsState[10].included : false,
      price: coverPremiums['CMOT21'] || 0,
      defaultIncludedInPlan: isDefaultForPlan('CMOT21', planCode),
      showCover: true
    },
    // {
    //   code: "CMOT18",
    //   name: "Working Risk",
    //   name_ar: "مخاطر العمل",
    //   included: true,
    //   price: coverPremiums['CMOT21'] || 0,
    //   defaultIncludedInPlan: isDefaultForPlan('CMOT18', planCode),
    // },
    // {
    //   code: "CMOT32",
    //   name: "Content Cover",
    //   name_ar: "محتوى الغلاف",
    //   included: true,
    //   price: coverPremiums['CMOT32'] || 0,
    //   defaultIncludedInPlan: isDefaultForPlan('CMOT32', planCode),
    // },
    // {
    //   code: "CMOT17",
    //   name: "Additional Accessories",
    //   name_ar: "ملحقات إضافية",
    //   included: planCode == 'OPL' ? true : finalCovers ? finalCovers.coverOptionsState[11].included : false,
    //   price: coverPremiums['CMOT17'] || 0,
    //   defaultIncludedInPlan: isDefaultForPlan('CMOT17', planCode),
    //   showCover: planCode == 'OPL' || planCode == 'OFL' ? true : false
    // },
    {
      code: "CMOT2",
      name: "Agency Repair & Nil Depreciation on parts",
      name_ar: "إصلاح الوكالة",
      included: planCode == 'OPL' || planCode == 'GO' ? true : finalCovers ? finalCovers.coverOptionsState[12].included : false,
      price: currencyFormatter(coverPremiums['CMOT2']) || 0,
      defaultIncludedInPlan: isDefaultForPlan('CMOT2', planCode),
      showCover: planCode == 'OPL' || planCode == 'OFL' || planCode == 'GO' || planCode == 'FL' ? true : false
    },
    {
      code: "CMOT74",
      name: "Own Damage",
      name_ar: "إصلاح الوكالة",
      included: planCode == 'TPPLUS' ? true : false,
      price: coverPremiums['CMOT74'] || 0,
      sumInsuredType: [
        {
          label_en: "5,000 BHD",
          type: "5000"

        },
        {
          label_en: "10,000 BHD",
          type: "10000"

        }
      ],
      selectedSumInsuredType: finalCovers ? finalCovers.coverOptionsState[0].selectedSumInsuredType : "5000",
      defaultIncludedInPlan: isDefaultForPlan('CMOT74', planCode),
      showCover: planCode?.toUpperCase() == 'TPPLUS'
    },
  ];
}

const extractFinalPremiums = (data) => {
  if (!data || !data.Covers) {
    console.error("Invalid data structure or Covers property missing");
    return {}; // Return an empty object or handle the error as needed
  }

  const coverPremiums = {};

  data.Covers.forEach((cover) => {
    const coverCode = cover.CoverCode;
    const finalPremium = cover.CoverProperties.find(
      (prop) => prop.strParamName == "Final Premium For Cover"
    )?.strParamValue;

    if (finalPremium) {
      coverPremiums[coverCode] = parseFloat(finalPremium); // Convert to number if needed
    }
  });

  return coverPremiums;
};

export const carReplacementPrice = async (days, size) => {
  if (!days || !size) {
    console.error("Invalid data structure or Covers property missing");
    return {}; // Return an empty object or handle the error as needed
  }

  let price = "";

  switch (size) {
    case "SMALL":
      switch (days) {
        case "8":
          price = 20;
          return price;
        case "10":
          price = 25;
          return price;
        case "15":
          price = 30;
          return price;
        case "30":
          price = 50;
          return price;
        default:
          price = 20;
          return price;
      }
    case "MEDIUM":
      switch (days) {
        case "8":
          price = 35;
          return price;
        case "10":
          price = 40;
          return price;
        case "15":
          price = 50;
          return price;
        case "30":
          price = 80;
          return price;
        default:
          price = 35;
          return price;
      }
    case "LUXURY":
      switch (days) {
        case "8":
          price = 60;
          return price;
        case "10":
          price = 70;
          return price;
        case "15":
          price = 100;
          return price;
        case "30":
          price = 165;
          return price;
        default:
          price = 60;
          return price;
      }
  }
};

export const geoExtensionPrice = async (region, coverPricesResult) => {
  if (!coverPricesResult) {
    console.error("Invalid data structure or Covers property missing");
    return {}; // Return an empty object or handle the error as needed
  }

  let price = "";
  let isCoverKSA = true;
  // Find the cover with CoverCode 'CMOT24'
  const cmot24Cover = coverPricesResult.Covers.find(cover => cover.CoverCode =="CMOT24");

  if (!cmot24Cover) {
    console.error("CMOT24 cover not found in the result");
    return;
  }

  // Extract the 'Final Premium For Cover' value
  const finalPremiumProperty = cmot24Cover.CoverProperties.find(
    prop => prop.strParamName =="Final Premium For Cover"
  );

  if (!finalPremiumProperty) {
    console.error("'Final Premium For Cover' not found in CMOT24 cover properties");
    return;
  }

  // Set the price to the extracted value
  price = parseFloat(finalPremiumProperty.strParamValue);
  console.log("Extracted price for GCC: ", price);


  return price;

};

const isDefaultForPlan = (coverCode, planCode) => {
  switch (coverCode) {
    case 'CMOT28': // Car replacement
      if (planCode == 'TPPLUS' || planCode == 'OFL' || planCode == 'OPL' || planCode == 'GO') {
        return true;
      }
      else {
        return false;
      }

    case 'CMOT16': // Roadside assistance
      if (planCode == 'OPL' || planCode == 'OFL') {
        return true;
      } else {
        return false;
      }

    case 'CMOT34': // PA benefits for passenger
      return false;

    case 'CMOT24': // Geographical extension
      if (planCode == 'OPL' || planCode == 'GO') {
        return true;
      } else {
        return false;
      }

    case 'CMOT9': // Act of God
      if (planCode == 'OPL' || planCode == 'GO') {
        return true;
      } else {
        return false;
      }

    case 'CMOT13': // VIP
      if (planCode == 'OPL') {
        return true;
      } else {
        return false;
      }

    case 'CMOT40': // PA benefits to driver
      if (planCode == 'OPL' || planCode == 'GO') {
        return true;
      } else {
        return false;
      }
    case 'CMOT61': // SRCC
      if (planCode == 'OPL') {
        return true;
      } else {
        return false;
      }
    case 'CMOT99': // Dep. on parts
      if (planCode == 'OPL' || planCode == 'GO') {
        return true;
      } else {
        return false;
      }

    case 'CMOT21': // Life insurance
      if (planCode == 'OPL') {
        return true;
      } else {
        return false;
      }
    // case 'CMOT32':
    //   if (planCode == 'PL') {
    //     return true;
    //   } else {
    //     return false;
    //   }

    //   case 'CMOT18':
    // if (planCode == 'PL') {
    //   return true;
    // } else {
    //   return false;
    // }

    case 'CMOT17': // Additional accessories
      if (planCode == 'OPL') {
        return true;
      } else {
        return false;
      }

    case 'CMOT2': // Agency repair
      if (planCode == 'OPL' || planCode == 'GO') {
        return true;
      } else {
        return false;
      }

    case 'CMOT74': // Own damage
      if (planCode == 'TPPLUS') {
        return true;
      }
      else {
        return false;
      }

    default:
      return false;
  }

};