import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/common/Header/Header";
import Modal from "../../components/common/modal/modal";
import {
  DETAILS,
  PLAN,
  PAYMENT,
  headerStepperData,
  FLOW_TYPE_EXPRESS_SHIELD,
  FLOW_TYPE_ESSENTIAL_CARE_NON_LOGGED_IN,
} from "./data";
import Details from "./details/details";
import Plan from "./plan/plan";
import ReviewAndConfirm from "./payment/reviewAndConfirm";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchEssentialCareCmsData,
} from "../../redux/features/essentialCare/essentialCareStrapiCmsSlice";
import useEssentialCareFlow from "../../hooks/essentialCare/useEssentialCare";
import { FLOW_TYPE_ESSENTIAL_CARE } from "./data";
import { selectProfileData } from "../../redux/features/profileSlice";
import useLifeFormData from "../../hooks/useLifeFormData";
import { ESSENTIAL_CARE_FAMILY_MEMBER_DETAILS } from "./plan/familyMmeberDetails";

const EssentialCare = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);
  const { currentStep, handleFlowChange } = useEssentialCareFlow();
  const {  removeLifeFormData } = useLifeFormData(ESSENTIAL_CARE_FAMILY_MEMBER_DETAILS, () => {})


  const PageRenderer = () => {
    switch (currentStep?.name) {
      case DETAILS:
        return <Details />;
      case PLAN:
        return <Plan />;
      case PAYMENT:
        return <ReviewAndConfirm />;
      default:
        return null;
    }
  };

  const activeStepData = useMemo(() => {
    let activeStep = headerStepperData.find(
      (each) => each.key ==currentStep.name
    );
    return activeStep;
  }, [currentStep]);

  const dispatch = useDispatch();
    const profileData = useSelector(selectProfileData);
  useEffect(() => {
    dispatch(fetchEssentialCareCmsData());
    if (profileData) {
      
      handleFlowChange(FLOW_TYPE_ESSENTIAL_CARE, 0);
    }
    else {
      handleFlowChange(FLOW_TYPE_ESSENTIAL_CARE_NON_LOGGED_IN, 0, 0);

    }
  }, []);

  return (
    <Modal show={showModal}>
      <Header
        isPopup
        onCloseModal={() => {
          setShowModal(false);
          navigate("/new-policy");
          removeLifeFormData()
        }}
        showStepper={false}
        steps={headerStepperData}
        activeStep={activeStepData}
      />
      <div className="EssentialCaremain pt-[5%] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll max767:p-5 max767:z-[99999] max767:relative max767:pt-[0%] max767:bg-white">
        <PageRenderer />
      </div>
    </Modal>
  );
};

export default EssentialCare;
