import React, { useEffect, useMemo, useState } from "react";
import AddNewBeneficiaryPopup from "./addNewBenfeficiaryPopup";
import Breadcrumb from "../../Breadcrumb";
import {
  BREADCRUMP_ITEMS,
  MANAGE_BENEFICIARY_DATA,
  MANAGE_BENEFICIARY_HEADER_DATA,
} from "./data";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { policyInfo } from "../../data";
import useScreenResolution from "../../../../../hooks/useScreenResolution";
import Button from "../../../../../components/common/Button/Button";
import Table from "./table/table";
import { DELETE_ACTION, EDIT_ACTION } from "./table/tableRow";
import TrusteeDetailPopup from "./trusteeDetailPopup";
import EditSharePercentagePopup from "./editSharePercentagePopup";

import backarrow from "../../../../../assets/images/back-arrow-left.svg";
import Managebeneficiariesgraybk from "../../../../../assets/images/Managebeneficiariesgraybk.svg";
import addicon from "../../../../../assets/images/add.svg";
import supervised_user_circle from "../../../../../assets/images/supervised_user_circle.svg";

import EditSharePercentage from "../../../../../assets/images/bar_chart.svg";
import MobileBeneficiaryTable from "./table/mobileBeneficiaryTable";
import { toast } from "react-toastify";
import { toBeInTheDocument } from "@testing-library/jest-dom/matchers";
import useLanguageTranslate from "../../../../../hooks/useLanguageTranslate";
import { decodeQueryString } from "../../../../../utils";
import useLifePolicyDetails from "../../../../../hooks/life/useLifePolicyDetails";
import { Loader } from "storybook/internal/components";
import { selectProfileData } from "../../../../../redux/features/profileSlice";
import useCreateLifeRequests, {
  LIFE_REQUEST_TYPE,
} from "../../../../../hooks/life/useCreateLifeRequests";
import TitleDescription from "../../../../../components/newMotorPolicy/titleDescription/titleDescription";
import closeicon from "../../../../../assets/images/close.svg";
import { useTranslation } from "react-i18next";


const ManageBeneficiaiaries = () => {
  // const [tableData, setTableData] = useState([])
  const [showAddNewBenefi, setShowAddNewBenefi] = useState(false);
  const [isEditBenefi, setIsEditBenefi] = useState(false);
  const [showTrustieDetails, setShowTrustieDetails] = useState(false);
  const [showEditSharePercentage, setShowEditSharePercentage] = useState(false);
  const [breadCrumbData, setBreadCrumbData] = useState(BREADCRUMP_ITEMS);
  const screenResolution = useScreenResolution();
  const translate = useLanguageTranslate();
  const [minorBeneficiaryData, setMinorBeneficairyData] = useState({});
  const { mobile } = useScreenResolution();
  const {i18n} = useTranslation()
  const navigate = useNavigate()


  const decodedQueryString = decodeQueryString();
  const { data, isLoading } = useLifePolicyDetails(decodedQueryString?.policy);

  const tableData = useMemo(() => {
    let beneficiaries = data?.LifeDetails?.policyList?.[0]?.beneficiaries;
    let format = beneficiaries?.map((each) => ({
      cpr: "",
      day: "",
      email: "",
      fullName: each?.name,
      mobile: each?.mobile || "NA",
      month: "",
      nationality: "",
      relationship: each?.relationship,
      share_percentage: each?.percentage,
      year: "",
      gender: "",
    }));
    return format;
  }, [data]);

  const toggleAddNewBeneficiary = () => {
    if (tableData?.length >= 10) {
      toast.error(
        translate(
          "Can't add more than 10 Beneficiaries",
          "Ar Can't add more than 10 Beneficiaries"
        )
      );
      return;
    }
    setShowAddNewBenefi((state) => !state);
  };
  const toggleTrustieDetails = (data) => {
    setMinorBeneficairyData(data);
    console.log(data, "some data");
    setShowAddNewBenefi(false);
    setShowTrustieDetails((state) => !state);
  };

  const { isLoading: requestLoading, createLifeRequest } =
    useCreateLifeRequests();

  const formatFromValues = (data) => {
    return {
      FullName: data?.fullName,
      Email: data?.email,
      PhoneNumber: data?.mobile,
      Gender: data?.gender || "",
      DOB: `${data?.day}/${data?.month}/${data?.year}`,
      Relationship: data?.relationship,
      Nationality: data?.nationality,
      SharePercentage: data?.share_percentage || 0,
    };
  };

  const createPaylaodForBenefi = (data) => {
    const payload = {
      PolicyNumber: decodedQueryString?.policy,
      Beneficiaries: formatFromValues(data),
    };
    return payload;
  };

  const AddBenficiary = async (data, isTrustee, trusteeData) => {
    let payload = createPaylaodForBenefi(data);
    payload.RequestType = LIFE_REQUEST_TYPE.addBeneficiary;
    if (isTrustee) {
      payload.Trustees = formatFromValues(trusteeData);
    }
    console.log(payload, "some paylaod");
    const response = await createLifeRequest(payload);
    isTrustee ? setShowTrustieDetails(false) : toggleAddNewBeneficiary();
  };
  const UpdateBenficiary = async (data, isTrustee, trusteeData) => {
    let payload = createPaylaodForBenefi(data);
    payload.RequestType = LIFE_REQUEST_TYPE.editBeneficiary;
    if (isTrustee) {
      payload.Trustees = formatFromValues(trusteeData);
    }
    const response = await createLifeRequest(payload);

    isTrustee ? setShowTrustieDetails(false) : toggleAddNewBeneficiary();

    // toast.success("Beneficiary updated successfully");
  };

  const deleteBenef = async (data) => {
    let payload = createPaylaodForBenefi(data);
    payload.RequestType = LIFE_REQUEST_TYPE.removeBeneficiary;
    const response = await createLifeRequest(payload);
    setShowAddNewBenefi(false);
  };
  const toggleEditSharePercentage = () => {
    setShowEditSharePercentage((state) => !state);
  };
  const { search } = useLocation();

  useEffect(() => {
    setBreadCrumbData([
      {
        label: translate("My Polices","وثائقي"),
        link: "/my-policy",
        clickable: true,
        name: 'my_policy'
      },
      {
        label:  data?.PolicyDetails?.ProductName,
        link: `/life-insurance/policy-overview${search}`,
        clickable: true,
        name: "current_plan",
      },
      {
        label: translate("Manage beneficiaries","إدارة المستفيدين"),
        clickable: false,
        name: "current_plan",
        isActive: true,
      },
    ]);
  }, [i18n?.resolvedLanguage]);

  const [editData, setEditData] = useState(null);

  const actionMenuClickHandler = (row, type) => {
    if (type == EDIT_ACTION) {
      setEditData(row);
      setIsEditBenefi(true);
      toggleAddNewBeneficiary();
    }
    if (type == DELETE_ACTION) {
      deleteBenef(row);
    }
  };

  console.log(editData, "soem edit data");

  return (
    <div className="ipad:p-4 p-6 max767:p-5">
      {(isLoading || requestLoading) && (
        <Loader className="w-10 h-10 fixed left-[50%] top-[50%]" />
      )}

      <div className="flex justify-between items-center">
        {!screenResolution.mobile && <Breadcrumb items={breadCrumbData} />}
        {/* {!screenResolution.mobile && (
          <span className="hidden last-login text-xs not-italic font-normal leading-4 text-gray-700">
            Last Login: 03 November 2024 at 05:20{" "}
          </span>
        )} */}
      </div>
      <div className="flex justify-between mt-5 mb-5 ipad:mt-8 max767:mt-0 max767:mb-0">
        <div className="flex align-middle gap-2 max767:hidden">
          <div className="back flex items-center gap-1">
            <img src={backarrow} alt="arrow-left" className="rtl:rotate-180" />
            <p className="text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] ml-2 rtl:mr-2 rtl:ml-0" onClick={() => navigate(`/life-insurance/policy-overview${search}&tabType=ManagePolicy`)}>
              {translate("Back","رجوع")}
            </p>
          </div>
          <div className="flex items-center gap-1 ml-2">
            <img src={Managebeneficiariesgraybk} alt="users-icon" />
            <p className="text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] ml-2 rtl:mr-2 rtl:ml-0">
            {translate("Manage beneficiaries","إدارة المستفيدين")}
            </p>
          </div>
        </div>
        <div className="flex gap-2 max767:hidden EditSharePercentagebtn">
          {!!tableData?.length && (
            <Button
              label={translate("Edit Share Percentage","تعديل نسبة الحصة")}
              className="flex leading-none items-center gap-2 bg-[#fff] px-4 py-2 rounded-md text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] border border-solid border-[#DDE2E4]"
              pre_icon={EditSharePercentage} // add user icon here
              onSubmit={toggleEditSharePercentage}
            />
          )}
          <Button
            label={
              tableData?.length ? translate("Add beneficiaries","إضافة مستفيد") : translate("Appoint beneficiaries","تعيين مستفيدين")
            }
            className="flex leading-none items-center gap-2 bg-[#55C6EF] px-4 py-2 rounded-md text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
            pre_icon={tableData?.length ? addicon : supervised_user_circle} // add user icon here
            onSubmit={toggleAddNewBeneficiary}
          />
        </div>
      </div>

      {mobile && (
        <div className="flex flex-col">
          <p  className="max767:p-0 max767:pt-0 max767:pb-0">
          <img src={closeicon} alt="Close Icon" className="bg-[#F6F8F9] p-2 rounded-3xl" onClick={() => navigate(`/life-insurance/policy-overview${search}&tabType=ManagePolicy`)}/>
        </p>
        <div className="max767:p-0 max767:pt-0">
          <TitleDescription
            title={translate("Manage beneficiaries","إدارة المستفيدين")}
            description={
              translate("Please select how much you would like to increase or update this value.","يرجى تحديد المقدار الذي ترغب في زيادته أو تحديث هذه القيمة.")
            }
          />
          </div>
        </div>
      )}
      <AddNewBeneficiaryPopup
        show={showAddNewBenefi}
        setShow={setShowAddNewBenefi}
        toggleTrustieDetails={toggleTrustieDetails}
        AddBenficiary={AddBenficiary}
        UpdateBenficiary={UpdateBenficiary}
        editData={editData}
        setIsEditBenefi={setIsEditBenefi}
        isEditBenefi={isEditBenefi}
        deleteBenef={deleteBenef}
      />
      <TrusteeDetailPopup
        show={showTrustieDetails}
        setShow={setShowTrustieDetails}
        AddBenficiary={(data) =>
          isEditBenefi
            ? UpdateBenficiary(minorBeneficiaryData, true, data)
            : AddBenficiary(minorBeneficiaryData, true, data)
        }
        toggleAddNewBeneficiary={toggleAddNewBeneficiary}
      />
      
      <EditSharePercentagePopup
        show={showEditSharePercentage}
        setShow={setShowEditSharePercentage}
      />
      

      {!tableData?.length && (
        <div className="content max767:p-4">
          <div className="flex w-[568px] flex-col justify-center items-start gap-2 p-6 max-w-[100%] bg-[#FBF7F0] rounded-[10px] ipad:w-full">
            <p className="text-[#1A2024] text-sm not-italic font-medium leading-[normal]">
              {translate("Roles attached to the policy","الأدوار المرتبطة بوثيقة التأمين")}
            </p>
            <h1 className="text-[#1A2024] text-2xl not-italic font-medium leading-[120%]">
              {translate("As Per Islamic Shariah Law","وفقاً لقانون الشريعة الإسلامية")}
            </h1>
          </div>
        </div>
      )}

      {!screenResolution.mobile && !!tableData?.length && (
        <div className="mt-0 EditSharePercentagetbl">
          <Table
            data={tableData}
            headers={MANAGE_BENEFICIARY_HEADER_DATA}
            actionTitle={translate("Action","إجراء")}
            showActions
            actionMenuClickHandler={actionMenuClickHandler}
          />
        </div>
      )}
      {screenResolution.mobile && (
        <div className="mt-10 max767:p-0 max767:mt-0 max767:pt-0">
          <div className="flex gap-2 max767:visible mb-6  max767:justify-around max767:w-full editsharebuttonbox">
            {!!tableData?.length && (
              <Button
                label={translate("Edit Share","تحرير المشاركة")}
                className="h-[32px] w-[48%] flex items-center justify-center gap-2 bg-[#fff] px-4 py-2 rounded-md text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] border border-solid border-[#DDE2E4] max767:h-[40px] max767:grow max767:px-2.5 max767:py-1"
                pre_icon={EditSharePercentage} // add user icon here
                onSubmit={toggleEditSharePercentage}
              />
            )}
            <Button
              label={
                tableData?.length
                  ? translate("Add beneficiaries","إضافة مستفيد") : translate("Appoint beneficiaries","تعيين مستفيدين")
              }
              className="h-[32px] w-auto flex items-center justify-center gap-2 bg-[#55C6EF] px-4 py-2 rounded-md text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]  max767:h-[40px] max767:grow max767:px-2.5 max767:py-1"
              pre_icon={tableData?.length ? addicon : supervised_user_circle} // add user icon here
              onSubmit={toggleAddNewBeneficiary}
            />
          </div>
          <MobileBeneficiaryTable
            data={tableData}
            actionMenuClickHandler={actionMenuClickHandler}
          />
        </div>
      )}
    </div>
  );
};

export default ManageBeneficiaiaries;
