import React from "react";
import PolicyCard from "../../components/policyCard/policyCard";
import { transferPolicyData } from "./data";
import Button from "../../components/common/Button/Button";
import { useNavigate } from "react-router-dom";
import BackdropModal from "../../components/common/modal/backdropModal";
import {
  getStartPolicyTransferLoading,
  startPolicyTransfer,
} from "../../redux/features/endorsements/transferPolicySlice";
import { toast } from "react-toastify";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  fetchProfileInfo,
  selectProfileData,
  selectProfilePolicyTransferStatus,
} from "../../redux/features/profileSlice";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "storybook/internal/components";

const PolicyTransferRequestModal = ({ show, setShow }) => {
  const dispatch = useDispatch();

  const onClose = () => setShow((state) => !state);
  const navigate = useNavigate();

  const getPolicyTransferDetails = useSelector(
    selectProfilePolicyTransferStatus
  );
  const getPolicyInfoData = useSelector(selectProfileData);
  const isStartPolicyTransferLoading = useSelector(
    getStartPolicyTransferLoading
  );

  const transferInfo = useSelector(selectProfilePolicyTransferStatus);

  const policyCardInfo = {
    ModelYear: "",
    Make: transferInfo ? transferInfo?.[0]?.Make : "--",
    Model: transferInfo?.[0]?.Model || "--",
    CPR: "",
    PolicyNumber: transferInfo?.[0]?.PolicyNumber || "--",
    RegistrationNumber: transferInfo?.[0]?.PlateNumber || "-",
    SumInsured: 0.0,
    PlanName: transferInfo?.[0]?.Plan || "--",
    ProductCode: "0101",
    ProductName: "Motor",
    StartDate:
      transferInfo?.[0]?.StartDate?.split(" ")[0]
        ?.split("/")
        ?.reverse()
        ?.join("-") || "--",
    EndDate:
      transferInfo?.[0]?.EndDate?.split(" ")[0]
        ?.split("/")
        ?.reverse()
        ?.join("-") || "--",
    FromDateStr: transferInfo?.[0]?.StartDate?.split(" ")[0] || "--",
    ToDateStr: transferInfo?.[0]?.EndDate?.split(" ")[0] || "--",
    Branch: "online.solidarity",
    MainChannel: "Direct Bahrain",
    Renewed: transferInfo?.[0]?.IsRenewed || "--",
    PolicyStatus: "Active",
    IsRenewable: false,
  };

  const onSubmit = async () => {
    try {
      const payload = {
        PolicyNumber: getPolicyTransferDetails[0].PolicyNumber,
        PartyCode: getPolicyInfoData.Code,
      };
      // Calling API /Meej/startPolicyTransfer
      const startTransferAction = await dispatch(startPolicyTransfer(payload));
      const startTransferResult = unwrapResult(startTransferAction);

      if (
        (startTransferResult && startTransferResult.StatusCode == 0) ||
        startTransferResult.StatusCode == 2
      ) {
        navigate(
          `/motor-insurance/confirm-transfer-details?policy=${getPolicyTransferDetails[0].PolicyNumber}&oldName=${transferInfo[0].FullName}`
        );
      } else if (startTransferResult && startTransferResult.StatusCode != 0) {
        toast.error(startTransferResult.Message);
      }
    } catch (error) {
      toast.error("Error: could not start the policy transfer");
    }
  };

  return (
    <div className="Policy-Transfer-Request">
      <BackdropModal onClose={onClose} show={show} className="">
        <div className="content mb-4 mt-[-43px] max767:mt-[-43px]">
          <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mb-2 max767:text-xl">
            Policy Transfer Request
          </h1>
          <p className="text-[#48535B] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] text-left">
            <span className="font-semibold">
              {" "}
              {transferInfo ? transferInfo[0]?.FullName : "--"}{" "}
            </span>{" "}
            with mobile number{" "}
            <span className="font-semibold">
              {transferInfo ? transferInfo[0]?.MobileNumber : "973 XXX"}
            </span>{" "}
            wants to transfer this policy to you.
          </p>
        </div>
        <div className="flex justify-center mb-4">
          <PolicyCard
            policy_name={`${transferInfo ? transferInfo[0]?.Make : "-"} ${
              transferInfo ? transferInfo[0]?.Model : "-"
            } - ${transferInfo ? transferInfo[0]?.PlateNumber : "-"}`}
            policy_label={transferInfo ? transferInfo[0]?.Plan : "-"}
            policy_number={transferInfo ? transferInfo[0]?.PolicyNumber : "-"}
            from_date={policyCardInfo ? policyCardInfo?.FromDateStr : "-"}
            to_date={policyCardInfo ? policyCardInfo?.ToDateStr : "-"}
            policy_bg_image={
              policyCardInfo ? policyCardInfo?.policy_bg_image : "-"
            }
            // expire_days={transferPolicyData.expire_days}
            is_text_dark={policyCardInfo ? policyCardInfo?.is_text_dark : "-"}
            mapping_key={policyCardInfo ? policyCardInfo?.id : "-"}
            data={policyCardInfo ? policyCardInfo : "-"}
            disableCardClick
          />
        </div>
        <div className="flex justify-center">
          <Button
            isLoading={isStartPolicyTransferLoading}
            onSubmit={onSubmit}
            type="submit"
            label={"Start Transfer"}
            className="mb-0 stickybutton flex w-full flex-col items-center gap-2.5 shrink-0 p-3 bg-[#55C6EF] rounded-[10px] text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px] max767:w-full"
          />
        </div>
      </BackdropModal>
    </div>
  );
};

export default PolicyTransferRequestModal;
