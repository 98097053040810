import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import {  motorApiEndpoints } from "../../../api";
import { toast } from "react-toastify";

const initialState = {
  RequestPolicyTransferResponse: [],
  StartPolicyTransferResponse: [],
  StatusCode:  0,
  isRequestPolicyTransferLoading: false,
  isStartPolicyTransferLoading: false,
  success: null,
  error: null,
};


export const transferRequest = createAsyncThunk(
    "transferRequest",
    async (payload, { rejectWithValue }) => {
      try {
        console.log("Calling transferRequest...");
        const response = await Axios.post(`${motorApiEndpoints.policyTransferRequest}`, payload, {
          timeout: 30000, // Set timeout to 5 seconds (5000 ms)
        });
        console.log("transferRequest response is: ", response.data);
  
        return response.data;
      } catch (err) {
        if (err.code =='ECONNABORTED') {
          toast.error("Request timed out.");
          return rejectWithValue({ error: 'Request timed out' });
        } else {
          toast.error("Error in requesting transfer policy: " + err.response?.data );
          return rejectWithValue(err.response?.data || 'An error occurred');
        }
      }
    }
);

export const startPolicyTransfer = createAsyncThunk(
    "startPolicyTransfer",
    async (payload, { rejectWithValue }) => {
      try {
        console.log("Calling startPolicyTransfer policy...");
        const response = await Axios.post(`${motorApiEndpoints.startPolicyTransfer}`, payload, {
          timeout: 30000, // Set timeout to 5 seconds (5000 ms)
        });
        console.log("startPolicyTransfer response is: ", response.data);
  
        return response.data;
      } catch (err) {
        if (err.code =='ECONNABORTED') {
          toast.error("Request timed out.");
          return rejectWithValue({ error: 'Request timed out' });
        } else {
            toast.error("Error in starting transfer policy: " + err.response?.data);
            return rejectWithValue(err.response?.data || 'An error occurred');
        }
      }
    }
  );


export const transferPolicySlice = createSlice({
  name: "transferPolicySlice",
  initialState,
  reducers: {
    clearTransferPolicySliceError: (state) => {
      state.error = null;
    },
    clearTransferPolicyState: (state) => initialState
  },
  extraReducers: (builder) => {
    // transferRequest
    builder.addCase(transferRequest.pending, (state, action) => {
      if (!state.data) {
        state.isRequestPolicyTransferLoading = true;
      }
    });
    builder.addCase(transferRequest.fulfilled, (state, action) => {
      state.isRequestPolicyTransferLoading = false;
      state.success = true;
      state.error = null;
      state.StatusCode = action?.payload.StatusCode;
      state.RequestPolicyTransferResponse = action?.payload;
    });
    builder.addCase(transferRequest.rejected, (state, action) => {
      state.isRequestPolicyTransferLoading = false;
      state.error = action.payload?.Message;
      state.StatusCode = 1;
      state.success = false;
    });

    // startPolicyTransfer
    builder.addCase(startPolicyTransfer.pending, (state, action) => {
      if (!state.data) {
        state.isStartPolicyTransferLoading = true;
      }
    });
    builder.addCase(startPolicyTransfer.fulfilled, (state, action) => {
      state.isStartPolicyTransferLoading = false;
      state.success = true;
      state.error = null;
      state.StatusCode = action?.payload.StatusCode;
      state.StartPolicyTransferResponse = action?.payload;
    });
    builder.addCase(startPolicyTransfer.rejected, (state, action) => {
      state.isStartPolicyTransferLoading = false;
      state.success = false;
      state.StatusCode = 1;
      state.error = action.error?.Message;
    });
  },
});

// SELECTORS
export const getRequestPolicyTransferResponse = (store) => store?.transferPolicy.RequestPolicyTransferResponse;
export const getStartPolicyTransferResponse = (store) => store?.transferPolicy.StartPolicyTransferResponse;
export const getRequestPolicyTransferStatusCode = (store) => store?.transferPolicy.StatusCode;


export const getRequestPolicyTransferLoading = (store) => store?.transferPolicy.isRequestPolicyTransferLoading;
export const getStartPolicyTransferLoading = (store) => store?.transferPolicy.isStartPolicyTransferLoading;

export const getPolicyTransferSuccess = (store) => store?.transferPolicy.success;
export const getPolicyTransferError = (store) => store?.transferPolicy.error;


// ACTIONS TO DISPATCH
export const { clearTransferPolicySliceError, clearTransferPolicyState } = transferPolicySlice.actions;

export default transferPolicySlice.reducer;
