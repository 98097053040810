import React, { useState } from "react";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useTranslation } from "react-i18next";
import RenewMotorLayout from "../renewalMotorPolicy/renewMotorLayout";
import FileUpload from "../../../components/common/fileUploadInput/fileUploadInput";
import { useNavigate } from "react-router-dom";
import useMotorForm from "../../../hooks/useMotorFormData";
import { useDispatch, useSelector } from "react-redux";
import { RemoveBank } from "../../../redux/features/motor/RemoveBankSlice";
export const REMOVEBANKNAME_UPLOAD_BANK_NOC = "REMOVEBANKNAME_UPLOAD_BANK_NOC";

const UploadBankNOC = ({ switchToggle, PolicyNo, Bank }) => {
  const [formData, setFormData] = useState({ bankNOC: null });
  const [PolicyNumber, setPolicyNumber] = useState(PolicyNo);
  const [Value, setValue] = useState(Bank);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const BankLetterDetails = useSelector(
    (state) => state.ScanBankLetter.ScannedBank
  );
  const dispatch = useDispatch();
  const removeBankDetails = useSelector(
    (state) => state.RemoveBankName.removeBank
  );
  const removeBankStatus = useSelector((state) => state.RemoveBankName.status);
  const removeBankError = useSelector((state) => state.RemoveBankName.error);

  const { saveMotorDataInRedux } = useMotorForm(
    REMOVEBANKNAME_UPLOAD_BANK_NOC,
    setFormData
  );
  const onFileUploaded = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };
  const continueClickHanlder = async () => {
    saveMotorDataInRedux(formData);
    const req = {
      PolicyNumber,
      Value,
    };
    await dispatch(RemoveBank(req));
    navigate("/motor-insurance/payment-success");
  };
  return (
    <RenewMotorLayout
      btn_disabled={
        formData.bankNOC != true || BankLetterDetails?.bankLetterValid != true
      }
      backClickHandler={switchToggle}
      btn_onClick={continueClickHanlder}
    >
      <div className="max-w-[100%] w-[500px] mx-auto bg-white max767:p-5">
        <TitleDescription
          title={t("motorInsurance.removeBankNameFlow.uploadBankNOC.title")}
          description={t(
            "motorInsurance.removeBankNameFlow.uploadBankNOC.description"
          )}
        />
        <div>
          <p className="mb-3 text-[#48535B] text-base not-italic font-normal leading-[26px] tracking-[-0.16px]">
            {t("motorInsurance.removeBankNameFlow.uploadBankNOC.input_label")}
          </p>
          <FileUpload name="bankNOC" toggleFileUpload={onFileUploaded} />
        </div>
      </div>
    </RenewMotorLayout>
  );
};

export default UploadBankNOC;
