


export const LEAD_INFO = {
    primaryDetails: "Primary Details",
    otherDetails: "Primary Details Page 2",
    questionnaire : "Questionnaire/Decleration",
    choosePlan : "Choose Your Plan",
    appointBeneficiary : "Beneficiary / Trustee Information",
    reviewAndConfirm : 'Review and Confirm'
}

export const COUNTRY_SELECT_DATA  = [
    { value: 'AFGHANI', label: 'AFGHANI', code: 1 },
    { value: 'ALBANIAN', label: 'ALBANIAN', code: 2 },
    { value: 'ALGERIAN', label: 'ALGERIAN', code: 3 },
    { value: 'AMERICAN', label: 'AMERICAN', code: 4 },
    { value: 'ANDORRAN', label: 'ANDORRAN', code: 105 },
    { value: 'ANGOLAN', label: 'ANGOLAN', code: 6 },
    { value: 'ARGENTINEAN', label: 'ARGENTINEAN', code: 8 },
    { value: 'ARMENIAN', label: 'ARMENIAN', code: 9 },
    { value: 'AUSTRALIAN', label: 'AUSTRALIAN', code: 10 },
    { value: 'AUSTRIAN', label: 'AUSTRIAN', code: 11 },
    { value: 'AZERBAIJANI', label: 'AZERBAIJANI', code: 12 },
    { value: 'BAHAMAIN', label: 'BAHAMAIN', code: 116 },
    { value: 'BAHRAINI', label: 'BAHRAINI', code: 14 },
    { value: 'BANGLADESHI', label: 'BANGLADESHI', code: 15 },
    { value: 'BARBADIAN', label: 'BARBADIAN', code: 16 },
    { value: 'BARBUDANS', label: 'BARBUDANS', code: 17 },
    { value: 'BELARUSIAN', label: 'BELARUSIAN', code: 19 },
    { value: 'BELGIAN', label: 'BELGIAN', code: 20 },
    { value: 'BELIZEAN', label: 'BELIZEAN', code: 21 },
    { value: 'BENINESE', label: 'BENINESE', code: 22 },
    { value: 'BERMUDIAN', label: 'BERMUDIAN', code: 124 },
    { value: 'BHUTANESE', label: 'BHUTANESE', code: 23 },
    { value: 'BOLIVIAN', label: 'BOLIVIAN', code: 24 },
    { value: 'BOSNIAN', label: 'BOSNIAN', code: 25 },
    { value: 'BRAZILIAN', label: 'BRAZILIAN', code: 26 },
    { value: 'BRITISH', label: 'BRITISH', code: 27 },
    { value: 'BRUNEIAN', label: 'BRUNEIAN', code: 28 },
    { value: 'BULGARIAN', label: 'BULGARIAN', code: 29 },
    { value: 'BURKINESE', label: 'BURKINESE', code: 30 },
    { value: 'BURMESE', label: 'BURMESE', code: 31 },
    { value: 'BURUNDIAN', label: 'BURUNDIAN', code: 32 },
    { value: 'CAMBODIAN', label: 'CAMBODIAN', code: 33 },
    { value: 'CAMEROONIAN', label: 'CAMEROONIAN', code: 34 },
    { value: 'CANADIAN', label: 'CANADIAN', code: 35 },
    { value: 'CAPE VERDEAN', label: 'CAPE VERDEAN', code: 36 },
    { value: 'CAYMANIAN', label: 'CAYMANIAN', code: 140 },
    { value: 'CENTRAL AFRICAN', label: 'CENTRAL AFRICAN', code: 37 },
    { value: 'CHADIAN', label: 'CHADIAN', code: 38 },
    { value: 'CHILEAN', label: 'CHILEAN', code: 39 },
    { value: 'CHINESE', label: 'CHINESE', code: 40 },
    { value: 'COLOMBIAN', label: 'COLOMBIAN', code: 41 },
    { value: 'COMORAN', label: 'COMORAN', code: 42 },
    { value: 'CONGOLESE', label: 'CONGOLESE', code: 43 },
    { value: 'COSTARICAN', label: 'COSTARICAN', code: 44 },
    { value: 'COTECNA', label: 'COTECNA', code: 343 },
    { value: 'CROATIAN', label: 'CROATIAN', code: 45 },
    { value: 'CUBAN', label: 'CUBAN', code: 46 },
    { value: 'CYPRIOT', label: 'CYPRIOT', code: 47 },
    { value: 'CZECH', label: 'CZECH', code: 48 },
    { value: 'DANISH', label: 'DANISH', code: 49 },
    { value: 'DJIBOUTIAN', label: 'DJIBOUTIAN', code: 158 },
    { value: 'DOMINICAN', label: 'DOMINICAN', code: 51 },
    { value: 'DUTCH', label: 'DUTCH', code: 52 },
    { value: 'ECUADOREAN', label: 'ECUADOREAN', code: 54 },
    { value: 'EGYPTIAN', label: 'EGYPTIAN', code: 55 },
    { value: 'EMIRIAN', label: 'EMIRIAN', code: 56 },
    { value: 'ERITREAN', label: 'ERITREAN', code: 58 },
    { value: 'ESTONIAN', label: 'ESTONIAN', code: 59 },
    { value: 'ETHIOPIAN', label: 'ETHIOPIAN', code: 60 },
    { value: 'FIJIAN', label: 'FIJIAN', code: 61 },
    { value: 'FILIPINO', label: 'FILIPINO', code: 62 },
    { value: 'FINNISH', label: 'FINNISH', code: 63 },
    { value: 'FRENCH', label: 'FRENCH', code: 64 },
    { value: 'GABONESE', label: 'GABONESE', code: 65 },
    { value: 'GAMBIAN', label: 'GAMBIAN', code: 66 },
    { value: 'GEORGIAN', label: 'GEORGIAN', code: 67 },
    { value: 'GERMAN', label: 'GERMAN', code: 68 },
    { value: 'GHANAIAN', label: 'GHANAIAN', code: 69 },
    { value: 'GREEK', label: 'GREEK', code: 70 },
    { value: 'GRENADIAN', label: 'GRENADIAN', code: 71 },
    { value: 'GUATEMALAN', label: 'GUATEMALAN', code: 72 },
    { value: 'GUINEAN', label: 'GUINEAN', code: 74 },
    { value: 'GUYANESE', label: 'GUYANESE', code: 75 },
    { value: 'HAITIAN', label: 'HAITIAN', code: 76 },
    { value: 'HERZEGOVINIAN', label: 'HERZEGOVINIAN', code: 77 },
    { value: 'HONDURAN', label: 'HONDURAN', code: 78 },
    { value: 'HONGKONGERS', label: 'HONGKONGERS', code: 27 },
    { value: 'HUNGARIAN', label: 'HUNGARIAN', code: 79 },
    { value: 'ICELANDER', label: 'ICELANDER', code: 80 },
    { value: 'INDIAN', label: 'INDIAN', code: 81 },
    { value: 'INDONESIAN', label: 'INDONESIAN', code: 82 },
    { value: 'IRANIAN', label: 'IRANIAN', code: 83 },
    { value: 'IRAQI', label: 'IRAQI', code: 84 },
    { value: 'IRISH', label: 'IRISH', code: 85 },
    { value: 'ITALIAN', label: 'ITALIAN', code: 87 },
    { value: 'IVORIAN', label: 'IVORIAN', code: 88 },
    { value: 'JAMAICAN', label: 'JAMAICAN', code: 89 },
    { value: 'JAPANESE', label: 'JAPANESE', code: 90 },
    { value: 'JORDANIAN', label: 'JORDANIAN', code: 91 },
    { value: 'KAZAKHSTANI', label: 'KAZAKHSTANI', code: 92 },
    { value: 'KENYAN', label: 'KENYAN', code: 93 },
    { value: 'KOREAN', label: 'KOREAN', code: 34 },
    { value: 'KUWAITI', label: 'KUWAITI', code: 95 },
    { value: 'KYRGYZSTAN', label: 'KYRGYZSTAN', code: 215 },
    { value: 'LAOTIAN', label: 'LAOTIAN', code: 97 },
    { value: 'LATVIAN', label: 'LATVIAN', code: 98 },
    { value: 'LEBANESE', label: 'LEBANESE', code: 99 },
    { value: 'LIBERIAN', label: 'LIBERIAN', code: 100 },
    { value: 'LIBYAN', label: 'LIBYAN', code: 101 },
    { value: 'LIECHTENSTEINER', label: 'LIECHTENSTEINER', code: 102 },
    { value: 'LITHUANIAN', label: 'LITHUANIAN', code: 103 },
    { value: 'LUXEMBOURGER', label: 'LUXEMBOURGER', code: 104 },
    { value: 'MACEDONIAN', label: 'MACEDONIAN', code: 105 }
  ];
  