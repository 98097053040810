import React, { useEffect, useState } from 'react'
import FundsRedirectingPage from './fundsRedirectingPage'
import AddFundsPage from './addFundsPage'
import { FUNDS_PREMIUM_TABLE_DATA } from '../../../../../dashboard/data'
import useLifePolicyDetails from '../../../../../../hooks/life/useLifePolicyDetails'
import Loader from '../../../../../../components/common/Loader/Loader'
import { decodeQueryString } from '../../../../../../utils'

const FundsRedirectingFlow = () => {
    const params = decodeQueryString();
  const { data, isLoading } = useLifePolicyDetails(params?.policy)

  const [toggle, setToggle] = useState(false)
  // const [userFunds, setUserFunds] = useState(FUNDS_PREMIUM_TABLE_DATA);
  const [userFunds, setUserFunds] = useState(null);
  const [originalFunds,setOriginalFunds] = useState(null);
  const [newSelectedIds, setNewSelectedIds] = useState({})
  const [isUserChangedFunds, setIsUserChangedFunds] = useState(false)


  const switchToggle = () => {
    setToggle(state => !state);
  }

  useEffect(() => {
    if (data && !userFunds) {
      let funds = data?.LifeDetails?.policyList?.[0]?.funds;
      let formattedData = funds?.map((fund) => ({
        id: fund?.fundId,
        isin: fund?.fundISIN,
        fund_name: fund?.fundDetails,
        units_held: fund?.numberOfUnits,
        current_price: fund?.unitPrice,
        total_value: fund?.fundValueInPolicyCurrency,
        current_percent: fund?.allocationPercentage,
        new_percent: fund?.allocationPercentage || 0,
      }))
      setUserFunds(formattedData)
      setOriginalFunds(formattedData)
    }

  }, [data])


  console.log(userFunds,'some user funds')

  return (
    <div className="mainCarDetailsModal pt-[80px] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll ">
      {isLoading && <Loader className="w-10 h-10 fixed left-[50%] top-[50%]" />}

      {
        !toggle ?
          <FundsRedirectingPage
            switchToggle={switchToggle}
            userFunds={userFunds}
            setUserFunds={setUserFunds}
            isUserChangedFunds={isUserChangedFunds}
            setIsUserChangedFunds={setIsUserChangedFunds}
            originalFunds={originalFunds}
          /> :
          <AddFundsPage
            switchToggle={switchToggle}
            setUserFunds={setUserFunds}
            userFunds={userFunds}
            newSelectedIds={newSelectedIds}
            setNewSelectedIds={setNewSelectedIds}
            isUserChangedFunds={isUserChangedFunds}
            setIsUserChangedFunds={setIsUserChangedFunds}
          />
      }
    </div>
  )
}

export default FundsRedirectingFlow