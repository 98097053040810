export const TRANSFER_OWNER_VALIDATIONS = [
    {
        name: "cpr",
        validations: ['required']
    },
    {
        name: "mobile",
        validations: ["length"],
        exact_length: 8,
    },
]