import React, { useState, useRef } from "react";
import Button from "../Button/Button";
import multiplefile from "../../../assets/images/add_to_photos.svg";
import removefile from "../../../assets/images/Xclose.svg";
import { useTranslation } from "react-i18next";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { useDispatch, useSelector } from "react-redux";
import {
  fileUploadResponse,
  requestToken,
  tokenResponse,
  uploadFile,
} from "../../../redux/features/upload/fileUploadSlice";
import { scanOwnership } from "../../../redux/features/ai/scannerSlice";
import { ScanBankLetter } from "../../../redux/features/motor/scanBankLetterSlice";
import { unwrapResult } from "@reduxjs/toolkit";

const FileUpload = ({ name, toggleFileUpload }) => {
  const dispatch = useDispatch();

  const token = useSelector(tokenResponse);
  const fileId = useSelector(fileUploadResponse);

  const { t } = useTranslation();
  const [formState, setFormState] = useState({
    fileName: "",
    fileSize: "",
    file: null,
    base64file: "",
    ownershipData: "",
    error: "",
  });

  const fileInputRef = useRef(null); // Using useRef to reference the file input
  const ScanBankLetterDetails = useSelector(
    (state) => state.ScanBankLetter.ScannedBank
  );
  const ScanBankLetterStatus = useSelector(
    (state) => state.ScanBankLetter.status
  );
  const ScanBankLetterError = useSelector(
    (state) => state.ScanBankLetter.error
  );
  const reason = "";
  const convertImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = function (event) {
        const img = new Image();

        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set the canvas size to the image size
          canvas.width = img.width;
          canvas.height = img.height;

          // Draw the image onto the canvas
          ctx.drawImage(img, 0, 0);

          // Convert the canvas content to Blob (JPEG or PNG)
          canvas.toBlob((blob) => {
            if (blob) {
              // Create a new File object from the Blob
              const convertedFile = new File([blob], file.name, {
                type: "image/jpeg",
              }); // Use 'image/png' if needed

              // Resolve the Promise with the new File object (same structure as event.target.files[0])
              resolve(convertedFile);
            } else {
              reject(new Error("Canvas conversion to Blob failed"));
            }
          }, "image/jpeg"); // Or 'image/png'
        };

        img.src = event.target.result; // Set the image source from FileReader
      };

      reader.onerror = () => reject(new Error("File reading failed"));
      reader.readAsDataURL(file); // Read file as data URL
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    console.log("File uploaded is of: ", name, "File:", file);
    if (file) {
      const validTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (validTypes.includes(file.type)) {
        // Convert file to Base64
        const reader = new FileReader();
        reader.onloadend = async () => {
          const base64String = reader.result.split(",")[1]; // Extract Base64 string from the Data URL
          // Ownership OCR
          if (name == "oc_file") {
            try {
              const scanPayload = {
                Base64Image: base64String,
              };
              // Calling API /openAi/scanOwnership
              const responseAction = await dispatch(scanOwnership(scanPayload));
              const response = unwrapResult(responseAction);

              // Parse the response to extract the JSON object
              let ownershipData;
              if (typeof response.payload == "string") {
                // Remove the "```json" prefix and the trailing "```"
                console.log("Trimming the json.");
                const jsonString = response.payload
                  .replace(/^```json\s*/, "")
                  .replace(/\s*```$/, "");
                console.log("Json trimmed: ", jsonString);

                ownershipData = JSON.parse(jsonString);
              } else {
                throw new Error("Unexpected response format");
              }

              console.log("Got json from OCR: ", ownershipData);

              if (response) {
                // Update state with the ownership data
                setFormState({
                  fileName: file.name,
                  fileSize: (file.size / 1024).toFixed(2) + " KB",
                  file: file,
                  base64File: base64String,
                  ownershipData: ownershipData,
                  error: "",
                });
                toggleFileUpload(true, name);
              }
            } catch (error) {
              console.error("Error during ownership scan:", error);
              setFormState({
                fileName: "",
                fileSize: "",
                file: null,
                base64File: "",
                ownershipData: null,
                error: "Failed to scan ownership. Please try again.",
              });
            }
          } else if (name == "bankNOC") {
            try {
              var reason = "";
              const scanPayload = {
                Base64Image: base64String,
              };
              // Calling API /openAi/scanOwnership
              const response = await dispatch(ScanBankLetter(scanPayload));

              if (response.error?.message != "Rejected") {
                reason = await unwrapResult(response);
              }
              // Parse the response to extract the JSON object
              let ownershipData;

              console.log("Got json from OCR: ", ownershipData);

              // Update state with the ownership data

              if (reason?.bankLetterValid == false) {
                setFormState({
                  fileName: file.name,
                  fileSize: (file.size / 1024).toFixed(2) + " KB",
                  file: file,
                  base64File: base64String,
                  ownershipData: ownershipData,
                  error: reason?.reason,
                });
              } else {
                const uploadPayload = {
                  token: token.payload,
                  source: "online.solidarity",
                  file_name:
                    name + "_" + file.name.split(".").slice(0, -1).join("."), // Remove extension,
                  file_base64: base64String,
                };
                await dispatch(uploadFile(uploadPayload));
                setFormState({
                  fileName: file.name,
                  fileSize: (file.size / 1024).toFixed(2) + " KB",
                  file: file,
                  base64File: base64String,
                  ownershipData: ownershipData,
                  error: reason?.reason,
                });
              }

              toggleFileUpload(true, name);
            } catch (error) {
              console.error("Error during ownership scan:", error);
              console.log("Reason: " + reason?.reason);
              setFormState({
                fileName: "",
                fileSize: "",
                file: null,
                base64File: "",
                ownershipData: null,
                error: reason?.reason,
              });
            }
          } else {
            // Normal upload
            try {
              // Request Token via Redux action
              const tokenPayload = {
                secret: "hello123",
                agent: "online.solidarity",
                item_type: name,
                // item_id: name + "_" + file.name.split(".").slice(0, -1).join("."), // Remove extension
                item_id: name + "_" + file.name, // with extension
                source: "online.solidarity",
                description: "Files token request from customer portal.",
              };

              // Calling API tokens/request
              const token = await dispatch(requestToken(tokenPayload));

              // Upload File via Redux action
              const uploadPayload = {
                token: token.payload,
                source: "online.solidarity",
                // file_name: name + "_" + file.name.split(".").slice(0, -1).join("."), // Remove extension,
                file_name: name + "_" + file.name, // with extension
                file_base64: base64String,
              };
              const uploadAction = await dispatch(uploadFile(uploadPayload));
              const uploadResult = await unwrapResult(uploadAction);

              if (uploadResult) {
                // Update state
                setFormState({
                  fileName: file.name,
                  fileSize: (file.size / 1024).toFixed(2) + " KB",
                  file: file,
                  base64File: base64String, // Add Base64 string to state
                  error: "",
                });
                toggleFileUpload(true, name);
              }
            } catch (error) {
              console.error(
                "Error during token request or file upload:",
                error
              );
              setFormState({
                fileName: "",
                fileSize: "",
                file: null,
                base64File: "",
                error: "Failed to upload file. Please try again.",
              });
            }
          }
        };
        reader.readAsDataURL(file);
      } else {
        setFormState({
          fileName: "",
          fileSize: "",
          file: null,
          base64File: "",
          error: "Please upload a valid file format (JPG, PNG, or PDF).",
        });
      }
    }
  };

  const handleRemoveFile = () => {
    setFormState({
      fileName: "",
      fileSize: "",
      file: null,
      error: "",
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the file input using the ref
    }
    toggleFileUpload(false, name);
  };
  const translate = useLanguageTranslate();

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex items-center w-[100%] self-stretch fileupload max767:hidden">
        <input
          type="text"
          value={
            formState.file != null
              ? `${formState.fileName}(${formState.fileSize})`
              : translate("No file chosen", "لم يتم اختيار أي ملف")
          }
          readOnly
          className="text-[#9AA6AC] text-sm not-italic font-normal leading-6 flex h-[50px] justify-end items-center self-stretch pl-4 border bg-[#E5E9EB] w-[75%] rounded-[10px_0px_0px_10px] rtl:rounded-[0px_10px_10px_0px] rtl:pr-2 max-350:w-[63%] max767:w-[70%] ipad:w-[78%]"
          placeholder={translate("No file chosen", "لم يتم اختيار أي ملف")}
        />
        {formState.file == null ? (
          <>
            <label
              htmlFor="file-upload"
              className="rounded-[0px_10px_10px_0px] border-l-0 text-[#252C32] text-center text-sm not-italic font-normal leading-6 flex h-[50px] flex-col justify-center items-center gap-2.5 border bg-white px-4 py-2 border-solid border-[#DDE2E4] rtl:border-r-0 rtl:border-l rtl:rounded-[10px_0px_0px_10px]"
            >
              {t(
                "motorInsurance.newMotorInsuranceFlow.fileUploadInput.choose_file"
              )}
            </label>
            <input
              id="file-upload"
              ref={fileInputRef} // Assigning ref to the input element
              type="file"
              onChange={handleFileChange}
              className="hidden"
            />
          </>
        ) : (
          <Button
            label={t(
              "motorInsurance.newMotorInsuranceFlow.fileUploadInput.remove"
            )}
            onSubmit={handleRemoveFile}
            className="remove border border-l-0 bg-white p-2 rounded-[0px_10px_10px_0px] w-[22%] cursor-pointer flex h-[50px] flex-col justify-center items-center gap-2.5 px-4 py-2 rtl:rounded-[10px_0px_0px_10px] rtl:border-l rtl:border-r-0"
          />
        )}
      </div>

      <div className="max767:block hidden relative   ">
        {/* <div className={` fileInput w-full h-[164px] ${formState.fileName ? "hidden" : ""}`}> */}
        <div className={`flex fileInput w-full h-[164px] mb-4 bg-[#F6F8F9]`}>
          <input
            type="file"
            className="opacity-0 flex h-[164px] justify-center items-center shrink-0 self-stretch w-full rounded-md z-[99] absolute"
            multiple
            onChange={handleFileChange}
            ref={fileInputRef}
          />
          <div className="flex flex-col items-center gap-1 relative z-0 mx-auto my-0 top-[60px] inset-x-0">
            <p>
              <img src={multiplefile} alt="Select multiple files" />{" "}
            </p>
            <p>
              <label>Add file or photo</label>
            </p>{" "}
          </div>
        </div>

        {formState.fileName && (
          <div className="mt-4 flex">
            <li className="flex justify-between flex-1 gap-2 bg-[#F6F8F9] pl-4 pr-2 py-2 rounded-md text-[#252C32] text-sm not-italic font-normal leading-6 mb-2 items-center">
              <span>{formState.fileName}</span>
              <button
                onClick={() => handleRemoveFile()}
                style={{ marginLeft: "10px" }}
              >
                <img src={removefile} alt="Remove file" />
              </button>
            </li>
          </div>
        )}
      </div>

      {formState.error && (
        <p className="text-red-500 text-sm">{formState.error}</p>
      )}
    </div>
  );
};

export default FileUpload;
