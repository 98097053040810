import React from 'react'
import BackdropModal from '../../../../components/common/modal/backdropModal'
import MobilePlanListTable from './mobilePlanListTable';
import TabPlanListTable from "./tabPlanListTable";
import PlanListTable from './planListTable';
import useScreenResolution from '../../../../hooks/useScreenResolution';

const ViewFullListBenefitsModal = ({ viewPlanList, setViewPlanList }) => {
    const {mobile, tablet } = useScreenResolution();
    const listTable = () => {
        if (mobile) {
            return <MobilePlanListTable />;
        } else if (tablet) {
            return <TabPlanListTable />;
        } else {
            return <PlanListTable />;
        }
    };
    return (
        <div className="planselectormodalmain">
            <BackdropModal show={viewPlanList} onClose={() => setViewPlanList(false)}>
                <div className="planselectormodal">
                    {listTable()}
                </div>

            </BackdropModal>
        </div>
    )
}

export default ViewFullListBenefitsModal