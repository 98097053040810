import React, { useRef, useState } from "react";
import Slider from "react-slick";
import SplashScreen from "./splashScreen";
import OnboardingSlider from "./onboardingSlider";
import { useNavigate } from "react-router-dom";
import Button from "../../components/common/Button/Button";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import { useTranslation } from "react-i18next";
import { slide1Image,slide2Image,slide3Image,slide4Image } from "./data";
import filterbkimg from "../../assets/images/Rectangleblurbk.svg"


const OnboardingScreen = () => {
  const navigate = useNavigate();
  const translate = useLanguageTranslate();
  const [isOnbaodrdingComplete, setIsOnbaodrdingComplete] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);
  const { i18n } = useTranslation();

  const isRTL = i18n.language == "ar";

  const slides = [
    {
      title_en: "Create personalized insurance coverage in minutes",
      title_ar: "أنشئ خطة تأمينية مخصصة في دقائق",
      description: "Get insured in minutes",
      description_ar: "احصل على خطة في دقائق",
      image: slide1Image,
    },
    {
      title_en: "Easily customize your policy with easy control",
      title_ar: "خصص وثيقتك التأمينية بسهولة وتحكم",
      description: "Take control of your coverage",
      description_ar: "تحكم في تغطيتك التأمينية",
      image: slide2Image,
    },
    {
      title_en: "File claims instantly, track their status in real-time",
      title_ar: "قدم المطالبات فوراً وتتبع حالتها في الوقت الفعلي",
      description: "Hassle-free claims process",
      description_ar: "عملية مطالبات خالية من المتاعب",
      image: slide3Image,
    },
  ];

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    rtl: isRTL,
    afterChange: (current) => setCurrentSlide(current),
  };

  const handleGetStarted = () => {
    setIsOnbaodrdingComplete(true);

    // if (currentSlide ==slides.length - 1) {
    //   setIsOnbaodrdingComplete(true);
    // } else {
    //   // setCurrentSlide(currentSlide + 1);
    //   sliderRef.current.slickGoTo(currentSlide + 1);
    // }
  };

  const handleSignIn = () => {
    navigate("/sign-in");
  };

  const handleRegister = () => {
    navigate("/sign-up");
  };
  const handleContinue = () => {
    navigate("/");
  };
  return (
    <div className="">
      {!isOnbaodrdingComplete ? (
        <div className=" onboardingslider">
          <Slider {...settings}>
            {slides.map((slide, index) => (
              <div key={index}>
                <OnboardingSlider
                  title={{ title_en: slide.title_en, title_ar: slide.title_ar }}
                  description={translate(
                    slide.description,
                    slide?.description_ar
                  )}
                  image={slide.image}
                />
              </div>
            ))}
          </Slider>
          <div className="flex px-5 py-0">
            <Button
              onSubmit={handleGetStarted}
              label={translate("Get Started", "البدء")}
              className="mt-8 primary-button"
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center  bg-white">
          <div className="relative w-full max-w-md h-[450px] ">
            <img src={translate(slide4Image.srcEn,slide4Image.srcAr)} alt={translate(slide4Image.altEn,slide4Image.altAr)} className="w-full" />
            <img src={filterbkimg} alt="" className="absolute top-[213px] inset-x-0 mx-auto my-0" />
          </div>
          <div className=" mb-6 z-10  px-5 py-0">
            <p className=" text-black text-base not-italic font-medium mb-6">
              {" "}
              {translate(
                "Your complete insurance companion",
                "رفيقك التأميني المتكامل"
              )}
            </p>
            <h1 className="text-[28px] font-semibold text-black mb-[64px] leading-[normal]">
              {translate(
                "Access policies, renewals, emergency services, and 24/7 support",
                "الوصول للخطط والتجديد والخدمات الطارئة والدعم على مدار الساعة"
              )}
            </h1>
          </div>
         

          <div className="flex flex-col  w-full z-10  px-5 py-0">
            <div className="flex gap-4 mb-4">
              <Button
                onSubmit={handleSignIn}
                label={translate("Sign in", "تسجيل الدخول")}
                className="flex flex-col items-center gap-2.5 flex-[1_0_0] bg-[#55C6EF] p-4 rounded-[10px] w-[168px] max767:max-w-[100%] text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px]"
              />
              <Button
                onSubmit={handleRegister}
                label={translate("Register", "إنشاء حساب")}
                className="flex flex-col items-center gap-2.5 flex-[1_0_0] bg-[#55C6EF] p-4 rounded-[10px] w-[168px] text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px]"
              />
            </div>
            <Button
              onSubmit={handleContinue}
              label={translate("Continue as Guest", "المتابعة كضيف")}
              className="flex flex-col items-center gap-2.5 self-stretch border bg-white p-4 rounded-[10px] border-solid border-[#DDE2E4]"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default OnboardingScreen;
