import { useState } from "react";
import { useTranslation } from "react-i18next";

const useValidation = () => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});

  const validateRequired = (value, fieldName) => {
    if (!value || value?.trim() == "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: t("validation.required"),
      }));
      return false;
    }
    setErrors((prevErrors) => {
      const { [fieldName]: removed, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };

  // lengthInfo = { min, max, exact_length }
  const validateLength = (value, fieldName, lengthInfo) => {
    const { min, max, exact_length } = lengthInfo;
    let errorMessage = "";

    if (exact_length > 0 && value?.length != exact_length) {
      errorMessage = t("validation.exact_length", { length: exact_length });
    } else if (min > 0 && value?.length < min) {
      errorMessage = t("validation.min_length", { min });
    } else if (max > 0 && value?.length > max) {
      errorMessage = t("validation.max_length", { max });
    }

    if (errorMessage) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: errorMessage,
      }));
      return false;
    }

    setErrors((prevErrors) => {
      const { [fieldName]: removed, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };

  const validateEmail = (value, fieldName) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: t("validation.invalidEmail"),
      }));
      return false;
    }
    setErrors((prevErrors) => {
      const { [fieldName]: removed, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };

  const validateName = (value, fieldName) => {
    const nameRegex = /^[a-zA-Z\s]+$/;
    if (!nameRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: t("validation.invalidName"),
      }));
      return false;
    }
    setErrors((prevErrors) => {
      const { [fieldName]: removed, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };

  const validateNumber = (value, fieldName) => {
    const numberRegex = /^[0-9]+$/;
    if (!numberRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: t("validation.invalidNumber"),
      }));
      return false;
    }
    setErrors((prevErrors) => {
      const { [fieldName]: removed, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };

  const validateDate = (value, fieldName) => {
    const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
    if (!dateRegex.test(value)) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: "Please enter a valid date in the format DD-MM-YYYY.",
      }));
      return false;
    }
    setErrors((prevErrors) => {
      const { [fieldName]: removed, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };
  const validateSelect = (value, fieldName) => {
    if (value == "" || value == undefined || value == null) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: t("validation.selectOption"),
      }));
      return false;
    }
    setErrors((prevErrors) => {
      const { [fieldName]: removed, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };
  const validateTabSelect = (value, fieldName) => {
    if (value == "" || value == undefined || value == null) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: t("validation.tabSelection"),
      }));
      return false;
    }
    setErrors((prevErrors) => {
      const { [fieldName]: removed, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };
  const validatePassword = (value, fieldName) => {
    const minLength = 12;
    const hasUppercase = /[A-Z]/.test(value);
    const hasLowercase = /[a-z]/.test(value);
    const hasNumber = /[0-9]/.test(value);
    const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    if (
      value &&
      (value?.length <= minLength ||
        !hasUppercase ||
        !hasLowercase ||
        !hasNumber ||
        !hasSymbol)
    ) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]:
          "Password should be at least 8 characters long and should contain one upperCase, oneLowercase, one Symbol and one number",
      }));
      return false;
    }
    setErrors((prevErrors) => {
      const { [fieldName]: removed, ...rest } = prevErrors;
      return rest;
    });
    return true;
  };
  const validateField = (value, fieldName, type, validationConfig) => {
    switch (type) {
      case "required":
        return validateRequired(value, fieldName);
      case "email":
        return validateEmail(value, fieldName);
      case "name":
        return validateName(value, fieldName);
      case "number":
        return validateNumber(value, fieldName);
      case "date":
        return validateDate(value, fieldName);
      case "select":
        return validateSelect(value, fieldName);
      case "tab":
        return validateTabSelect(value, fieldName);
      case "length":
        return validateLength(value, fieldName, validationConfig);
      case "password":
        return validatePassword(value, fieldName);
      default:
        return true;
    }
  };

  const validateFields = (validationConfig, values) => {
    // debugger
    const newErrors = {};
    validationConfig.forEach(({ name, validations, min_value, max_value, min, max, exact_length }) => {
      validations.forEach((validation) => {
        const value = values?.[name];
        let errorMessage = null;
        // debugger
        switch (validation) {
          case "file":
            if (!value || Array.isArray(value) && !value?.length) {
              console.log(value, 'some file value')
              errorMessage = t("validation.required");
            }
            break;
          case "required":
            if (!value || (typeof value == "string" && value.trim() == "")) {
              errorMessage = t("validation.required");
            }
            break;

          case "email":
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
              errorMessage = t("validation.invalidEmail");
            }
            break;

          case "name":
            const nameRegex = /^[a-zA-Z\s]+$/;
            if (!nameRegex.test(value)) {
              errorMessage = t("validation.invalidName");
            }
            break;

          case "number":
            const numberRegex = /^[0-9]+$/;
            if (!numberRegex.test(value)) {
              errorMessage = t("validation.invalidNumber");
            }
            break;

          case "date":
            const dateRegex = /^\d{2}-\d{2}-\d{4}$/;
            if (!dateRegex.test(value)) {
              errorMessage = t("validation.invalidDate");
            }
            break;

          case "select":
            if (value == "" || value == undefined || value == null) {
              errorMessage = t("validation.selectOption");
            }
            break;

          case "tab":
            if (value == "" || value == undefined || value == null) {
              errorMessage = t("validation.tabSelection");
            }
            break;
          case "min":
            if (value < min_value) {
              errorMessage = t(`validation.minValue`, { min: min_value });
            }
            break;
          case "max":
            if (value > max_value) {
              errorMessage = t(`validation.maxValue`, { max: max_value });
            }
            break;
          case "length":
            if (exact_length > 0 && value?.length != exact_length) {
              errorMessage = t("validation.exact_length", {
                length: exact_length,
              });
            }
            break;

          case "length":
            if (exact_length > 0 && value?.length != exact_length) {
              errorMessage = t("validation.exact_length", { length: exact_length });
            } else if (min > 0 && value?.length < min) {
              errorMessage = t("validation.min_length", { min });
            } else if (max > 0 && value?.length > max) {
              errorMessage = t("validation.max_length", { max });
            }
            console.log(errorMessage, 'erro')
            break;


          case "password":
            const minLength = 8;
            const hasUppercase = /[A-Z]/.test(value);
            const hasLowercase = /[a-z]/.test(value);
            const hasNumber = /[0-9]/.test(value);
            const hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(value);
            if (
              value &&
              (value?.length <= minLength ||
                !hasUppercase ||
                !hasLowercase ||
                !hasNumber ||
                !hasSymbol)
            ) {
              errorMessage =
                "Password should be at least 8 characters long and should contain one upperCase, oneLowercase, one Symbol and one number";
            }
            break;
          default:
            break;
        }

        if (errorMessage) {
          newErrors[name] = errorMessage;
        }
      });
    }
    );

    setErrors(newErrors);

    return Object.keys(newErrors).length == 0;
  };

  const isValid = Object.keys(errors).length == 0;

  return { errors, validateField, validateFields, isValid };
};

export default useValidation;
