import React from "react";
import MainCard from "./mainCard";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import FaceID_BG from "../../assets/images/Notification.svg";
import Notication_BG from "../../assets/images/Header 2.svg";

const FaceIDScreen = ({ setCurrentScreen }) => {
  const translate = useLanguageTranslate();

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white relative">
      <div className="w-full max-w-md mb-8">
        <img
          src={Notication_BG}
          alt="Notification"
          className="rounded-lg w-full  object-cover mb-4"
        />
        <img
          src={FaceID_BG}
          alt="Face ID"
          className="rounded-lg object-cover mb-4 absolute w-[134px] h-[134px] mx-auto my-0 top-[27%] inset-x-0"
        />
      </div>

      <MainCard
        title={translate("Welcome to Solidarity", "مرحبًا بك في سوليدرتي")}
        subtitle={translate(
          "Use Face or Touch ID to log in",
          "استخدام بصمة الوجه أو الإصبع لتسجيل الدخول"
        )}
        primary_label={translate("Turn on Face ID", "تشغيل بصمة الوجه")}
        secondary_label={translate("Maybe later", "ربما لاحقًا")}
        handlePrimaryClick={() => setCurrentScreen("success")}
        handleSecondaryClick={() => setCurrentScreen("success")}
      />
    </div>
  );
};

export default FaceIDScreen;
