import React, { useEffect, useState } from "react";
import TitleDescription from "../../../../../../components/newMotorPolicy/titleDescription/titleDescription";
import SelectFundsTable from "../selectFundsTable/selectFundsTable";
import { SELECT_FUNDS_TABLE_DATA } from "../selectFundsTable/data";
import FundsSwitchingLayout from "./fundsSwitchingLayout";
import useFundsSwitchingFlow from "../../../../../../hooks/fundsSwitchingFlow";
import FundsToRemoveData from "./fundsToRemoveData";
import { useTranslation } from "react-i18next";
import SelectFundsTableMobile from "../selectFundsTable/selectFundsTableMobile";
import useScreenResolution from "../../../../../../hooks/useScreenResolution";
import { useDispatch, useSelector } from "react-redux";
import { fetchStrapiFunds, fetchTcsCatalogFunds, selectMappedFunds, selectMappedLoader } from "../../../../../../redux/features/allfunds/allFundsSlice";
import { Loader } from "storybook/internal/components";

const AddFundsPage = ({
  setUserFunds,
  userFunds,
  newSelectedIds,
  setNewSelectedIds,
  fundsToRemove,
  setSelectedNewFunds
}) => {
  const { t } = useTranslation();
  const screenResolution = useScreenResolution();
  const { handleNextSubstep, handlePrevSubstep } = useFundsSwitchingFlow();
  const tableData = useSelector(selectMappedFunds)
  console.log(
    "^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^",
    userFunds,
    fundsToRemove,
    tableData
  );
  const isLoading = useSelector(selectMappedLoader)
  const addFundsClickHandler = () => {
    let selectedFundsWithData = tableData?.filter(
      (_, i) => newSelectedIds[i]
    );
    selectedFundsWithData = selectedFundsWithData.map((each, i) => ({
      ...each,
      isNew: true,
    }));
    console.log(selectedFundsWithData,'selectedFundsWithData')
    setSelectedNewFunds(selectedFundsWithData)
    //filterUserFundsWithoutDuplicateSelectedFunds
    let filterUserFunds = userFunds?.filter((each) => !each.isNew);
    let update = [...filterUserFunds, ...selectedFundsWithData];
    setUserFunds(update);
    handleNextSubstep();
  };

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchTcsCatalogFunds());
    dispatch(fetchStrapiFunds())
  }, [])


console.log("newSelectedIds", newSelectedIds);
  return (
    <FundsSwitchingLayout
      btn_onClick={addFundsClickHandler}
      btn_disabled={newSelectedIds && Object.keys(newSelectedIds).length ==0}
      backClickHandler={handlePrevSubstep}
      is_bottom_sticky_layout={!screenResolution.mobile}
    >
      {isLoading && <Loader className="w-10 h-10 fixed left-[50%] top-[50%]" />}

      <div className="changeplatenumber w-[500px] max-w-[100%] mx-auto bg-white max767:p-5">
        <TitleDescription
          title={t(
            "lifeInsurance.investmentStrategyFlow.fundSwitchingFlow.addFundsPage.title"
          )}
          description={t(
            "lifeInsurance.investmentStrategyFlow.fundSwitchingFlow.addFundsPage.description"
          )}
        />
      </div>
      <div className="w-[1200px] max-w-[100%] mx-auto overflow-x-auto">
        <FundsToRemoveData fundsToRemove={fundsToRemove} />
        {screenResolution.mobile ? (
          <SelectFundsTableMobile
            data={tableData || []}
            backClick={handlePrevSubstep}
            nextClick={handleNextSubstep}
            setUserFunds={setUserFunds}
            userFunds={userFunds}
            setSelectedNewFunds={setSelectedNewFunds}
          />
        ) : (
          <SelectFundsTable
            rowSelection={newSelectedIds}
            setRowSelection={setNewSelectedIds}
            data={tableData || []}
          />
        )}
      </div>
    </FundsSwitchingLayout>
  );
};

export default AddFundsPage;
