import React from 'react'
import './Button.css'
import Loader from '../Loader/Loader';
import loader from '../../../assets/images/Spinner2.svg';

const Button = ({ onSubmit = () => { }, label, type, className = "", disabled, pre_icon, post_icon, post_icon_class, id, isLoading  }) => {
  return (
    <button
      id={id}
      disabled={!!disabled}
      type={type}
      onClick={onSubmit}
      className={`button flex items-center justify-center bg-[#55C6EF] hover:bg-[#55C6EF] disabled:bg-gray-400 disabled:cursor-not-allowed   ${className}`}
    >
      <div className='flex gap-2 items-center'>
        {pre_icon && (
          <span>
            <img src={pre_icon} alt="" />
          </span>
        )}
        {
          isLoading ? <span className='h-[24px] loader'><img className='h-full' src={loader} alt="loader" /></span> : <span className='text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px] content-start rtl:text-right'>
            {label}
          </span>
        }

      </div>
      {post_icon &&!isLoading && (
        <span>
          <img src={post_icon} alt="" className={post_icon_class} />
        </span>
      )}
    </button>
  );
}

export default Button