import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentStepIndex,
  selectCurrentSubstepIndex,
  setCurrentStepIndex,
  setCurrentSubstepIndex,
  setFundsSwitchingFlowType,
  selectFundsSwitchingFlowType,
} from "../redux/features/fundsSwitchingFlowSlice";
import { funds_switching_flow } from "../pages/lifeInsurancePages/PolicyOverview/fundManagementTab/investementStrategyFlow/fundSwitchingFlow/data";


const useFundsSwitchingFlow = () => {
  const dispatch = useDispatch();
  const flowType = useSelector(selectFundsSwitchingFlowType);
  const currentStepIndex = useSelector((state) =>
    selectCurrentStepIndex(state)
  );
  const currentSubstepIndex = useSelector((state) =>
    selectCurrentSubstepIndex(state)
  );

  const steps = funds_switching_flow[flowType] || [];
  const currentStep = steps[currentStepIndex] || {};
  const currentSubstep = currentStep.substeps
    ? currentStep.substeps[currentSubstepIndex]
    : "";

  const handleFlowChange = (type, currentStepIndex, currentSubstepIndex) => {
    dispatch(
      setFundsSwitchingFlowType({
        type,
        currentStepIndex,
        currentSubstepIndex,
      })
    );
  };

  const handleNextSubstep = () => {
    if (
      currentStep.substeps &&
      currentSubstepIndex < currentStep.substeps.length - 1
    ) {
      dispatch(setCurrentSubstepIndex(currentSubstepIndex + 1));
    } else if (currentSubstepIndex == currentStep.substeps.length - 1) {
      handleNextStep();
    }
  };

  const handlePrevSubstep = () => {
    if (currentSubstepIndex > 0) {
      dispatch(setCurrentSubstepIndex(currentSubstepIndex - 1));
    } else {
      handlePrevStep();
    }
  };

  const handleNextStep = () => {
    if (currentStepIndex < steps.length - 1) {
      dispatch(setCurrentStepIndex(currentStepIndex + 1));
    }
  };

  const handlePrevStep = () => {
    if (currentStepIndex > 0) {
      const currentStep = steps[currentStepIndex - 1] || {};
      const substepIndex = currentStep?.substeps?.length - 1;
      dispatch(setCurrentStepIndex(currentStepIndex - 1));
      dispatch(setCurrentSubstepIndex(substepIndex));
    }
  };

  return {
    handleFlowChange,
    handleNextSubstep,
    handlePrevSubstep,
    handleNextStep,
    handlePrevStep,
    flowType,
    steps,
    currentStep,
    currentStepIndex,
    currentSubstep,
    currentSubstepIndex,
  };
};
export default useFundsSwitchingFlow;

