import React from "react";
import BasicInfo from "./basicInfo";
import useEssentialCareFlow from "../../../hooks/essentialCare/useEssentialCare";
import DocsReqd from "./docsReqd";

const Details = () => {
  const { currentSubstep, handleNextSubstep } = useEssentialCareFlow();
  const onSuccess = () => {
    handleNextSubstep();
  };
  return (
    <>
      {currentSubstep =="basic_details" && <BasicInfo onSuccess={onSuccess} />}
      {currentSubstep =="docs_reqd" && (
        <DocsReqd onSuccess={onSuccess} />
      )}
    </>
  );
};

export default Details;
