import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosStrapi } from "../axiosInstance";
import { strapiApiEndPoints } from "../../api";

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

export const fetchCommnApiContent = createAsyncThunk(
  "fetchCommonApi",
  (queryString, { rejectWithValue }) => {
    return AxiosStrapi.get(`${strapiApiEndPoints.commonApiData}`).then((res) => res.data);
  }
);

export const commonApiContentSlice = createSlice({
  name: "commonApi slice",
  initialState,
  reducers: {
    clearCommonApiError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // FETCH  UTILS API
    builder.addCase(fetchCommnApiContent.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchCommnApiContent.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload?.data;
    });
    builder.addCase(fetchCommnApiContent.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });
  },
});

// SELECTORS
export const selectCommonApiContentWhole = (store) => store?.common_api_content;
export const selectCommonApiContent = (store) => store?.common_api_content?.data;
export const selectCommonApiContentLoader = (store) =>
  store?.common_api_content?.isLoading;

// ACTIONS TO DISPATCH
export const { clearCommonApiError } = commonApiContentSlice.actions;

export default commonApiContentSlice.reducer;
