import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import FileClaimMotorLayout from "../fileClaimMotorLayout";
import useFileClaimFlow from "../../../../hooks/useFileClaimFlow";
import MotorPolicyOptionsCard from "../../../../components/motorPolicyOptionsCard/motorPolicyOptionsCard";
import {
  FILE_CLAIM_BODILY_INJURY_FLOW,
  FILE_CLAIM_FLOW,
  FILE_CLAIM_PROPERTY_DAMAGE_FLOW,
  FILE_CLAIM_VEHICLE_DAMAGE_FLOW,
  REPORTING_TYPES_DATA,
} from "../data";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import {
  BODILY_INJURY,
  POLICY_PLANS_TYPES,
  PROPERTY_DAMAGE,
  VEHICLE_DAMAGE,
} from "./data";

function SelectPolicyPlans({ selectedOption, setSelectedOption }) {
  const { handleNextSubstep, handleFlowChange } = useFileClaimFlow();
  const translate = useLanguageTranslate();
  const { t } = useTranslation();
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const continueClickHanlder = () => {
    //

    console.log("Cust Type = " + selectedOption);
    if (selectedOption == VEHICLE_DAMAGE) {
      handleFlowChange(FILE_CLAIM_VEHICLE_DAMAGE_FLOW, 2);
    } else if (selectedOption == PROPERTY_DAMAGE) {
      handleFlowChange(FILE_CLAIM_PROPERTY_DAMAGE_FLOW, 2);
    } else if (selectedOption == BODILY_INJURY) {
      handleFlowChange(FILE_CLAIM_BODILY_INJURY_FLOW, 2);
    } else {
      handleNextSubstep();
    }
  };
  return (
    <FileClaimMotorLayout
      btn_disabled={!selectedOption}
      btn_onClick={continueClickHanlder}
    >
      <div className="max-w-[100%] w-[500px] mx-auto bg-white max767:p-5">
        <TitleDescription
          title={t(
            "motorInsurance.fileAClaimFlow.nonSolidarityPolicyPlans.title"
          )}
          description={t(
            "motorInsurance.fileAClaimFlow.nonSolidarityPolicyPlans.description"
          )}
        />
        {POLICY_PLANS_TYPES?.map((each) => (
          <MotorPolicyOptionsCard
            title={translate(each?.title, each.ar_title)}
            description={translate(each?.description, each.ar_description)}
            isSelected={selectedOption == each?.type}
            onSelect={() => handleOptionSelect(each?.type)}
          />
        ))}
      </div>
    </FileClaimMotorLayout>
  );
}

export default SelectPolicyPlans;
