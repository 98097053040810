import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import { policyApiEndpoints } from "../../../api";


export const ViewIssuedQuoteDocs = createAsyncThunk(
    "ViewIssuedQuoteDocs",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling policy/ViewIssuedQuoteDocs...")
            const response = await Axios.post(`${policyApiEndpoints.ViewDocs}`, payload);
            console.log("policy/ViewIssuedQuoteDocs response is: ", response.data)

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const PolicyVerify = createAsyncThunk(
    "PolicyVerify",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling policy/verify...")
            const response = await Axios.post(`${policyApiEndpoints.verify}`, payload);
            console.log("policy/verify response is: ", response.data)

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const initialState = {
    PolicyInfo: [],
    PolicyDocs: null,
    status: 'idle', // idle | loading | succeeded | failed
    isLoading: false,
    error: null,
    success: null
  }

  const PolicyDocsSlice = createSlice({
    name: 'PolicyDocs',
    initialState,
    reducers: {
        clearPolicyDocsState: () => initialState
    },
    extraReducers: (builder) => {
        builder
        .addCase(ViewIssuedQuoteDocs.pending, (state) =>{
            state.status = 'loading';
        })
        .addCase(ViewIssuedQuoteDocs.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.PolicyDocs = action.payload;
        })
        .addCase(ViewIssuedQuoteDocs.rejected, (state, action) =>{
            state.status = 'failed';
            state.error = action.error.message;
        })
        // Policy verify 
        builder.addCase(PolicyVerify.pending, (state, action) => {
            if (!state.data) {
              state.isLoading = true;
            }
          });
          builder.addCase(PolicyVerify.fulfilled, (state, action) => {
            state.isLoading = false;
            state.success = true;
            state.error = null;
            state.PolicyInfo = action?.payload.PolicyHeader;
            state.PolicyDocs = action?.payload.PolicyHeader.Reports;

          });
          builder.addCase(PolicyVerify.rejected, (state, action) => {
            state.isLoading = false;
            state.success = false;
            state.error = action.error?.message;
          });
    }
  });


export const getPolicyVerifyResponse = (store) => store?.PolicyDocs;
export const getPolicyVerifyLoading = (store) => store?.isLoading;

// ACTIONS TO DISPATCH
export const { clearPolicyDocsState } = PolicyDocsSlice.actions;


export default PolicyDocsSlice.reducer;
