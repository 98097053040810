import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Otp from "../../../components/common/Otp/Otp";
import Button from "../../../components/common/Button/Button";
import "./VerifyOtpPage.css";
import { useDispatch, useSelector } from "react-redux";
import { selectLoginRegister } from "../../../redux/features/loginRegisterSlice";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { useTranslation } from "react-i18next";
import { selectOtpData } from "../../../redux/features/sendOtpSlice";
import {
  verifyOtp,
  selectVerifyOtpLoading,
  setOtpData,
} from "../../../redux/features/verifyOtpSlice";
import Loader from "../../../components/common/Loader/Loader";
import { toast } from "react-toastify";
import useScreenResolution from "../../../hooks/useScreenResolution";
import BackBtnWithLabel from "../../../components/common/backBtnWithLabel/backBtnWithLabel";
// hard refresh need to redirect or need to check ;

const VerifyOtpPage = ({ onBackClick, onSuccess, titleDesc }) => {
  const { mobile } = useScreenResolution();

  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const otpData = useSelector(selectOtpData);
  const isLoading = useSelector(selectVerifyOtpLoading);
  const location = useLocation();

  const { signupDetails } = useSelector((state) => state.signup);
  //Forgot Password Detais
  const forgotPasswordDetails = useSelector(
    (state) => state.forgotPassword.forgotPasswordDetails
  );
  const forgotPasswordData = useSelector((state) => state.forgotPassword.data);

  const isForgotPassword = location.pathname.includes("forgot-password");

  const notRecivedCodeHandler = () => {
    alert("clicked on i didn't recieved code");
  };
  const { t } = useTranslation();

  const translate = useLanguageTranslate();
  const navigate = useNavigate();
  const prevHandler = () => {
    onBackClick();
  };

  const NextHandler = async () => {
    const response = await dispatch(
      verifyOtp({
        pinId: isForgotPassword ? forgotPasswordData?.PinId : otpData?.pinId,
        pin: otp,
      })
    );
    //onSuccess();
    if (response?.payload?.verified) {
      dispatch(setOtpData(otp));
      onSuccess();
    } else {
      toast.error(
        translate(
          response?.payload?.ErrorMessageEn,
          response?.payload?.ErrorMessageAr
        )
      );
    }
  };
  const loginRegisterData = useSelector(selectLoginRegister);

  return (
    <>
      {isLoading && <Loader className=" w-10 h-10 fixed left-[50%] top-[50%]" />}
      <div className="verify_otp_page mt-20  flex justify-center items-center  max767:mt-20 max767:p-4 ipad:mt-20 ">
        <div className="verify-content max767:mt-10 w-[500px] max-w-[100%] mx-auto justify-center ">
          {mobile ? (
            <div className="content">
              {/* <BackBtnWithLabel label={translate("Sign up", "التسجيل")} /> */}

              <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 mb-3 tracking-[-0.24px] text-center max767:text-left max767:text-[28px] max767:font-semibold max767:rtl:text-right">
                {translate(
                  loginRegisterData?.verify_otp_label?.title_en,
                  loginRegisterData?.verify_otp_label?.title_ar
                )}
              </h1>
              <p className="text-center max767:text-left max767:text-sm max767:not-italic max767:font-normal max767:mb-6 max767:mt-1 max767:rtl:text-right">
                {titleDesc ? titleDesc : translate("Enter the number sent to your phone","أدخل الرقم المرسل إلى هاتفك")}
              </p>
            </div>
          ) : (
            <div className="content">
              <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 mb-2 tracking-[-0.24px] text-center max767:text-left">
                {translate(
                  loginRegisterData?.verify_otp_label?.title_en,
                  loginRegisterData?.verify_otp_label?.title_ar
                )}
              </h1>
              <p className="text-[#1A2024] text-base not-italic font-normal leading-6 tracking-[-0.16px] mb-5 text-center max767:text-left">
                {titleDesc ? titleDesc : translate("Enter the 4-digit code number","أدخل الرمز المكون من 4 أرقام")}
              </p>
            </div>
          )}
          <div className="flex align-middle">
            <p className="mb-2 w-full align-middle text-[#48535B] text-center text-base not-italic font-normal leading-[26px] tracking-[-0.16px] max767:text-left max767:mb-5 max767:rtl:text-right">
              {isForgotPassword ? (
                translate(
                  "We sent it to your account mobile number",
                  "تم ارساله إلى رقم هاتفك المسجل في الحساب"
                )
              ) : (
                <>
                  {t(
                    "motorInsurance.renewalPolicyFlow.verifyOtpPage.sent_it_label"
                  )}{" "}
                  {signupDetails?.mobile}{" "}
                  <Link
                    className=" mx-0 my-6 text-[#00384D] text-center text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline"
                    to="/sign-up"
                    onClick={onBackClick}
                  >
                      {translate("Change", "تغيير")}
                  </Link>
                </>
              )}
            </p>
          </div>
          <div className="otpinput justify-center flex">
            <Otp value={otp} setValue={setOtp} />
          </div>
          <p
            onClick={notRecivedCodeHandler}
            className="w-full align-middle link-text mx-0 my-6 text-[#00384D] text-center text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline cursor-pointer"
          >
            {t("signUpFlow.verifyOtpPage.didnt_recieve_label")}
          </p>
          <Button
            disabled={!(otp?.length ==4)}
            onSubmit={NextHandler}
            className="primary-button"
            type="submit"
            label={t("signUpFlow.verifyOtpPage.btn_label")}
          />
        </div>
      </div>
    </>
  );
};

export default VerifyOtpPage;
