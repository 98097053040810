import React, { useMemo, useState } from "react";
import PerformanceCard from "../../../../components/common/card/performanceCard";
import FundManagementChart from "./expandedComponent";
import useScreenResolution from "../../../../hooks/useScreenResolution";
import {
  CHANGE_TO_FUND_MANAGEMENT,
  CHANGE_TO_INVESTMENT,
  FUND_MANAGEMENT,
  fundMangementCardsData,
  fundMangementCardsData2,
  INVESTMENT_STRATEGY_CHANGE,
} from "./data";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import TableRow from "./tableRow";
import { useSelector } from "react-redux";
import { selectFundManagementDoc, selectLifePolicyDataWhole } from "../../../../redux/features/life/lifePolicyDetailSlice";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";

const FundManagement = () => {
  const screenResolution = useScreenResolution();
  const { data, isLoading } = useSelector(selectLifePolicyDataWhole);
  const translate = useLanguageTranslate();

  const funds = data?.LifeDetails?.policyList?.[0]?.funds;
  const currentInvestmentStrategy =
    data?.LifeDetails?.policyList?.[0]?.investmentStrategy;

  const fundData = useMemo(() => {
    return (
      funds?.map((fund) => ({
        fundName: fund?.fundDetails || "",
        allocationPercent: fund?.allocationPercentage || "",
        numberOfUnit: fund?.numberOfUnits || "",
        unitPrice: fund?.unitPrice || "",
        fundValue: fund?.fundValueInPolicyCurrency || "",
        fundISIN: fund?.fundISIN || "",
      })) || []
    );
  }, [data]);

  const navigate = useNavigate();
  const { id } = useParams();
  const { search } = useLocation();
  const changeClickHandler = (data) => {
    if (data.type ==INVESTMENT_STRATEGY_CHANGE) {
      navigate(`/life-insurance/investement-strategy-change${search}`);
    } else if (data.type ==CHANGE_TO_FUND_MANAGEMENT) {
      navigate(`/life-insurance/fund-management${search}`);
    } else if (data.type ==FUND_MANAGEMENT) {
      navigate(`/life-insurance/fund-management${search}`);
    } else if (data.type ==CHANGE_TO_INVESTMENT) {
      navigate(`/life-insurance/investement-strategy-change${search}`);
    }
  };

  const cardClickHandler = (data) => {
    changeClickHandler(data);
  };

  const investmentStartegyType =
    data?.LifeDetails?.policyList?.[0]?.investmentStrategy;
  let doc = useSelector((store) => selectFundManagementDoc(store, investmentStartegyType))
  console.log(doc,'doc')

  return (
    <div className="container mt-4 max767:m-0 w-full">
      {/* Cards */}
      {!currentInvestmentStrategy && (
        <div className="flex flex-wrap gap-4 PerformanceCard max767:grid max767:grid-cols-2 fundmanagementbox max767:p-5">
          {fundMangementCardsData.map((card, index) => (
            <PerformanceCard
              key={index}
              onClick={cardClickHandler}
              cardData={card}
              btnOnClick={changeClickHandler}
              className=" overflow-hidden bg-[#FBF7F0] cursor-pointer rounded-2xl flex-col flex h-[163px] justify-between items-start flex-[1_0_0] p-6 fund-managment ipad:p-3 max767:border-0"
            />
          ))}
        </div>
      )}
      {currentInvestmentStrategy && (
        <div className="flex  flex-wrap gap-4 mt-2 PerformanceCard max767:grid max767:grid-cols-2 max767:p-4 fundmanagementbox">
          {fundMangementCardsData2(currentInvestmentStrategy).map(
            (card, index) => (
              <PerformanceCard
                key={index}
                cardData={card}
                onClick={cardClickHandler}
                btnOnClick={changeClickHandler}
                className=" overflow-hidden bg-[#FBF7F0] rounded-2xl flex-col flex h-[163px] justify-between items-start flex-[1_0_0] p-6 fund-managment ipad:p-3 min-w[50%]"
              />
            )
          )}
        </div>
      )}

      {fundData?.length > 0 &&
        (!(screenResolution.mobile || screenResolution.tablet) ? (
          <table className="min-w-full bg-white border border-gray-200 text-left rtl:text-right mt-6">
            <thead>
              <tr>
                <th className="px-6 py-2.5 border-b border-r text-[#6E7C87] text-xs not-italic font-semibold leading-6 tracking-[-0.072px] border-gray-200">
                  {translate("Fund Name", "اسم الصندوق")}
                </th>
                <th className="px-6 py-2.5 border-b border-r text-[#6E7C87] text-xs not-italic font-semibold leading-6 tracking-[-0.072px] border-gray-200">
                  {translate("Allocation %", "التخصيص %")}
                </th>
                <th className="px-6 py-2.5 border-b border-r text-[#6E7C87] text-xs not-italic font-semibold leading-6 tracking-[-0.072px] border-gray-200">
                  {translate("Number of Unit", "عدد الوحدات")}
                </th>
                <th className="px-6 py-2.5 border-b border-r text-[#6E7C87] text-xs not-italic font-semibold leading-6 tracking-[-0.072px] border-gray-200">
                  {translate("Unit Price", "سعر الوحدة")}
                </th>
                <th className="px-6 py-2.5 border-b border-r text-[#6E7C87] text-xs not-italic font-semibold leading-6 tracking-[-0.072px] border-gray-200">
                  {translate("Fund Value", "قيمة الصندوق")}
                </th>
                <th className="px-6 py-2.5 border-b border-r text-[#6E7C87] text-xs not-italic font-semibold leading-6 tracking-[-0.072px] border-gray-200">
                  {translate("Action", "إجراء")}
                </th>
              </tr>
            </thead>
            <tbody>
              {fundData?.map((item, index) => (
                <TableRow
                  key={index}
                  item={item}
                  investmentStartegyType={investmentStartegyType}
                  fundDoc={doc}
                />
              ))}
            </tbody>
          </table>
        ) : (
          <div className="">
            {fundData?.map((item, index) => (
              <TableRow
                key={index}
                item={item}
                investmentStartegyType={investmentStartegyType}
                fundDoc={doc}
              />
            ))}
          </div>
        ))}
    </div>
  );
};

export default FundManagement;
