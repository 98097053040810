import React from "react";
import SideNavbar from "../components/common/sideNavbar/sideNavbar";
import "./layout.css";

const Layout = ({ children, isPopup = false, isHideHeader, invalidPage=false }) => {
  return (
    <div className="">
      {!isHideHeader && (
        <SideNavbar isPopup={isPopup} invalidPage={invalidPage} />
      )}
      {!isPopup ? (
        <main className="pt-12 sm:pl-64  max767:pb-[100px] ipad:pl-20 rtl:sm:pl-0 rtl:pr-64 max767:rtl:pr-0 max767:pt-0">
          <div className=" policyOverviewDiv ipad:p-0 max767:p-0 max767:mt-[0px]">
            <div className="">{children}</div>
          </div>
        </main>
      ) : (
        <main className="">{children}</main>
      )}
    </div>
  );
};

export default Layout;
