import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import  { AxiosStrapi } from "../axiosInstance";
import {  strapiApiEndPoints } from "../../api";

const initialState = {
  new_policy: null,
  isLoading: false,
  error: null,
};

export const fetchNewPoliciesStaticData = createAsyncThunk(
  "fetchNewPoliciesStaticData",
  (queryString, { rejectWithValue }) => {
    return AxiosStrapi.get(`${strapiApiEndPoints.newPoliciesStaticData}`).then((res) => res.data);
  }
);

export const newPolicySlice = createSlice({
  name: "newPolicy slice",
  initialState,
  reducers: {
    clearNewPolicyError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // FETCH  UTILS API
    builder.addCase(fetchNewPoliciesStaticData.pending, (state, action) => {
      if (!state.new_policy) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchNewPoliciesStaticData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.new_policy = action.payload;
    });
    builder.addCase(fetchNewPoliciesStaticData.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error?.message;
    });
  },
});

// SELECTORS
export const selectNewPoliciesStaticData = (store) =>
  store?.newPoliciesStaticData?.new_policy?.data?.attributes;
export const selectNewPoliciesLoader = (store) =>
  store?.newPoliciesStaticData?.isLoading;

// ACTIONS TO DISPATCH
export const { clearNewPolicyError } = newPolicySlice.actions;

export default newPolicySlice.reducer;
