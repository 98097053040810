import React from "react";
import homeblackicon from "../../../assets/images/home-black.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
const TransferSuccess = ({ number }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
    <div className="flex justify-center items-center w-full h-[70vh] max767:h-auto">
    <div className="flex justify-center items-center w-full">
    <div className="flex flex-col items-start gap-8 self-stretch bg-[#F6F8F9] p-12 rounded-[26px] w-[596px] max-w-full max767:bg-white max767:p-6">
      <h2 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px]">
        {t(
          "motorInsurance.transferPolicyFlow.transfersuccess.transfer_request"
        )}
      </h2>
      <p className="text-[#1A2024] text-base not-italic font-normal leading-[150%]">
        {t(
          "motorInsurance.transferPolicyFlow.transfersuccess.transfer_policy_ownership"
        )}
        {` ${number}`} {/* Ensure space before number */}
      </p>
      <button onClick={()=>navigate("/")} className="flex w-[162px] rtl:w-fit px-[9px] bg-[#55C6EF] mr-2 rounded-[10px] py-2 items-center gap-2 mb-0 text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:w-full ipad:w-[35%] max767:hidden">
        <p className="items-center text-sm justify-around w-full flex">
          {t("motorInsurance.transferPolicyFlow.transfersuccess.return_home")}
          <img src={homeblackicon} alt="home Icon" className="h-6 flex" />
        </p>
      </button>
    </div>
    </div>
  
    </div>
    <p className="max767:block hidden bg-[white] fixed w-full p-6 bottom-0 border-t-[#DDE2E4] border-t border-solid">
      <button onClick={()=>navigate("/")} className="flex w-[162px] rtl:w-[200px] px-[9px] bg-[#55C6EF] mr-2 rounded-[10px] py-2 items-center gap-2 mb-0 text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:w-full ipad:w-[35%] ">
      <p className="items-center text-sm justify-center w-full flex">
        {t("motorInsurance.transferPolicyFlow.transfersuccess.return_home")}
        <img src={homeblackicon} alt="home Icon" className="h-6 flex ml-2" />
      </p>
    </button>
    </p>
    </>
  );
};

export default TransferSuccess;
