import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import {  motorApiEndpoints } from "../../../api";

const initialState = {
  VehicleDetails: [],
  PlateTypeList: [],
  isLoading: false,
  success: null,
  error: null,
};


export const retrieveVehicleFromGDT = createAsyncThunk(
    "retrieveVehicleFromGDT",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling GDT/RetrieveVehicleDetails...")
            const response = await Axios.post(`${motorApiEndpoints.retrieveVehicleDetails}`, payload, {
              timeout: 30000, // Set timeout to 5 seconds (5000 ms)
            });
            console.log("GDT/RetrieveVehicleDetails response is: ", response.data)

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const retrievePlateTypesFromGDT = createAsyncThunk(
  "retrievePlateTypesFromGDT",
  async (payload, { rejectWithValue }) => {
      try {
          console.log("Calling GDT/retrievePlateTypesFromGDT...")
          const response = await Axios.get(`${motorApiEndpoints.retrievePlateTypes}`, {
            timeout: 30000, // Set timeout to 5 seconds (5000 ms)
          });
          console.log("GDT/retrievePlateTypesFromGDT response is: ", response.data)

          return response.data;
      } catch (err) {
          return rejectWithValue(err.response.data);
      }
  }
);

export const gdtSlice = createSlice({
  name: "gdtSlice",
  initialState,
  reducers: {
    clearGDTError: (state) => {
      state.error = null;
    },
    clearGDTState: () => initialState,  // This resets the state to its initial value
  },
  extraReducers: (builder) => {
    // GDT: retrieve vehicle details API
    builder.addCase(retrieveVehicleFromGDT.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(retrieveVehicleFromGDT.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
      state.VehicleDetails = action?.payload.VehicleDetails;
    });
    builder.addCase(retrieveVehicleFromGDT.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      console.log("GDT error: ", action.payload)
    });

     // GDT: retrieve plate types API
     builder.addCase(retrievePlateTypesFromGDT.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(retrievePlateTypesFromGDT.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
      state.PlateTypeList = action?.payload;
    });
    builder.addCase(retrievePlateTypesFromGDT.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      console.log("GDT error: ", action.payload)
    });
  },
});

// SELECTORS
export const gdtRetrieveVehicleResponse = (store) => store?.gdtApi.VehicleDetails;
export const gdtRetrievePlateTypesListResponse = (store) => store?.gdtApi.PlateTypeList;
export const gdtRetrieveVehicleLoading = (store) => store?.gdtApi.isLoading;
export const gdtRetrieveVehicleError = (store) => store?.gdtApi.error;
export const gdtRetrieveVehicleSuccess = (store) => store?.gdtApi.success;


// ACTIONS TO DISPATCH
export const { clearGDTError, clearGDTState } = gdtSlice.actions;

export default gdtSlice.reducer;
