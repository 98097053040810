import React, { useEffect, useState } from "react";
import RemoveBankPage from "./removeBankPage";
import UploadBankNOC from "./uploadBankNOC";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { policyView } from "../../../redux/features/policyDetailsSlice";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { decodeQueryString } from "../../../utils";

const RemoveBankNameFlow = () => {
  const [toggle, setToggle] = useState(false);
  const { policy_id } = useParams();
  const [PolicyNumber, setPolicyNumber] = useState(policy_id);
  const [Channel, setChannel] = useState("online.solidarity");
  const dispatch = useDispatch();
  const policyDetails = useSelector(
    (state) => state.policyDetails.PolicyDetails
  );
  const policyDetailsStatus = useSelector(
    (state) => state.policyDetails.status
  );
  const policyDetailsError = useSelector((state) => state.policyDetails.error);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const translate = useLanguageTranslate();

  const navigate = useNavigate();
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    navigate(-1);
  };
  const polReq = {
    PolicyNumber,
    Channel,
  };

  const switchToggle = () => {
    setToggle((state) => !state);
  };

  const callApi = async () => {
    await dispatch(policyView(polReq));
  };

  useEffect(() => {
    // Ensure the status is "succeeded" (or any equivalent status in your app) before checking for LenderCode
    if (
      policyDetailsStatus == "succeeded" &&
      (policyDetails?.PolicyHeader?.LenderCode == "" ||
        policyDetails?.PolicyHeader?.LenderCode == null ||
        policyDetails?.PolicyHeader?.LenderCode == undefined)
    ) {
      openModal(); // Open modal if LenderCode is missing
    }
  }, [policyDetails, policyDetailsStatus]);

  useEffect(() => {
    if (
      policyDetails?.PolicyHeader?.LenderCode == "" ||
      policyDetails?.PolicyHeader?.LenderCode == null ||
      policyDetails?.PolicyHeader?.LenderCode == undefined
    ) {
      callApi();
    }
  }, []);

  return (
    <div className="mainCarDetailsModal pt-[80px] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll max767:pt-[0]">
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
            <h2 className="text-lg font-semibold mb-2">
              {translate("Car not on loan", "السيارة غير مرهونة")}
            </h2>
            <p className="mb-4">
              {translate("This car is not on loan", "هذه السيارة ليست مرهونة")}
            </p>
            <button
              onClick={closeModal}
              className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
            >
              {translate("Close", "إغلاق")}
            </button>
          </div>
        </div>
      )}

      {!toggle ? (
        <RemoveBankPage
          switchToggle={switchToggle}
          lender={policyDetails?.PolicyHeader?.LenderCode}
          status={policyDetailsStatus}
        />
      ) : (
        <UploadBankNOC
          switchToggle={switchToggle}
          PolicyNo={policy_id}
          Bank={policyDetails?.PolicyHeader?.LenderCode}
        />
      )}
    </div>
  );
};

export default RemoveBankNameFlow;
