import React, { useContext } from "react";
import CarReplacement from "./carReplacement";
import ToggleSwitch from "../../../../components/common/toggleSwitch/toggleSwitch";
import useLanguageTranslate from '../../../../hooks/useLanguageTranslate';
import Loader from "../../../../components/common/Loader/Loader";
import { useSelector } from "react-redux";
import { getCoverPricesLoading, getQuoteLoading } from "../../../../redux/features/motor/getQuoteSlice";
import { PriceContext } from "../Layouts/PriceContext";

const CoverOptionsAddons = ({
  option,
  updateOptionValues,
  onClassName = "border-[#00719A] ",
  offClassName = "border-[#DDE2E4] text-[#252C32] opacity-50 bg-[#FFF]",
  onActivelable = "bg-[#00719A] text-[white]",
  onDisablelable = "bg-[#EEF0F2] text-[#252C32]",
}) => {
const translate=useLanguageTranslate()

// Get cover prices API loader 
const isCoverPriceLoading = useSelector(getCoverPricesLoading);

// const handleToggleChange = () => {
//      // First, toggle the option's "included" state
//      const newIncludedState = !option.included;
//      updateOptionValues(option.name, { included: newIncludedState }, option.price);
     
// };

  return (
    <div
      className={`flex flex-col mb-2 justify-center items-start self-stretch p-4 rounded-[10px] border-2 border-solid border-[#00719A] disabled:bg-gray-400 disabled:cursor-not-allowed ${
        option.included ? onClassName : offClassName
      } ${
        option.defaultIncludedInPlan &&
        "disabled border-gray-400 cursor-not-allowed opacity-0.7"
      }`}
    >
      <div className="flex w-full justify-between mb-2">
        <div className="leftCont">
          <p className="text-[#252C32] text-center text-base not-italic font-normal leading-6 tracking-[-0.16px] max767:text-left">
            {translate(option.name, option.name_ar)}
          </p>
        </div>
        {option.defaultIncludedInPlan ? (
          <h3>{translate("Included in the plan", "متضمن في الخطة")}</h3>
        ) : (
          <>
            <ToggleSwitch
              isOn={option.included}
              onChange={() =>
                updateOptionValues(
                  option.code,
                  { included: !option.included },
                  option.price
                )
              }
              disabled={option.disabled}
            />
          </>
        )}
      </div>
      <div className="enhancetabs w-full">
        {option.code =="CMOT28" && option.included && (
          <CarReplacement
            key={option.code}
            option={option}
            updateOptionValues={updateOptionValues}
          />
        )}
      </div>

      <div className="flex w-full mt-5 justify-end">
        {isCoverPriceLoading ? (
          <Loader />
        ) : (
          !option.defaultIncludedInPlan && (
            <div
              className={`price flex rounded-[10px] -auto text-right h-6 justify-end items-center gap-4 px-2 py-0 ${
                option.included ? onActivelable : onDisablelable
              }`}
            >
              {translate(`+BHD ${option.price}`, `+د.ب ${option.price}`)}
            </div>
          )
        )}
      </div>
    </div>
  );
};
export default CoverOptionsAddons;