import { createSlice } from "@reduxjs/toolkit";
import { CHANGE_PLAN_UPGRADE } from "../../hooks/useChangePlanFlow";

const initialState = {
  flowType: CHANGE_PLAN_UPGRADE,
  currentStepIndex: 0,
  currentSubstepIndex: 0,
};

const motorInsuranceChangePlanFowSlice = createSlice({
  name: "motorInsuranceChangePlanFlow",
  initialState,
  reducers: {
    setChangePlanFlowType: (state, action) => {
      const data = action.payload;
      state.flowType = data.type;
      state.currentStepIndex = data.currentStepIndex || 0;
      state.currentSubstepIndex = data.currentSubstepIndex || 0;
    },
    setCurrentStepIndex: (state, action) => {
      state.currentStepIndex = action.payload;
      state.currentSubstepIndex = 0;
    },
    setCurrentSubstepIndex: (state, action) => {
      state.currentSubstepIndex = action.payload;
    },
  },
});

export const selectMotorInsuranceChangePlanFlow = (store) =>
  store.motorInsuranceChangePlanFlow;
export const selectChangePlanFlowType = (store) =>
  store?.motorInsuranceChangePlanFlow?.flowType;
export const selectCurrentStepIndex = (store) =>
  store?.motorInsuranceChangePlanFlow?.currentStepIndex;
export const selectCurrentSubstepIndex = (store) =>
  store.motorInsuranceChangePlanFlow?.currentSubstepIndex;

export const {
  setChangePlanFlowType,
  setCurrentStepIndex,
  setCurrentSubstepIndex,
} = motorInsuranceChangePlanFowSlice.actions;
export default motorInsuranceChangePlanFowSlice.reducer;
