import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import {  motorApiEndpoints } from "../../../api";
import { toast } from "react-toastify";

const initialState = {
  SumInsuredDetails: [],
  isLoading: false,
  success: null,
  error: null,
};

export const fetchSumInsuredDetails = createAsyncThunk(
  "sumInsuredDetails",
  (payload, { rejectWithValue }) => {
    return Axios.post(
      `${motorApiEndpoints.calculateSumInsured}`, payload
    ).then((res) => {
      console.log("Getting sum insured details: ", res.data)

      return res.data
    }).catch((err) => {
      console.log(err.response.data)
      toast.error("Error in calculating sum insured: ", err.response.data);


      return rejectWithValue(err.response.data);
    })
  }
);

export const getSumInsuredSlice = createSlice({
  name: "Get SumInsured Slice",
  initialState,
  reducers: {
    clearGetSumInsuredError: (state) => {
      state.error = null;
    },
    resetSumInsuredDetails: () => initialState
  },
  extraReducers: (builder) => {
    // FETCH  UTILS API
    builder.addCase(fetchSumInsuredDetails.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchSumInsuredDetails.fulfilled, (state, action) => {
      console.log("Reducer, fetching sum insured deets: ", action.payload.SumInsuredDetails)
      state.isLoading = false;
      state.success = true;
      state.SumInsuredDetails = action?.payload?.SumInsuredDetails;
    });
    builder.addCase(fetchSumInsuredDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.payload?.ErrorMessageEn;
    });
  },
});

// SELECTORS
export const getSumInsuredDetails = (store) => store?.getSumInsured.SumInsuredDetails;
export const getSumInsuredLoader = (store) => store?.getSumInsured.isLoading;
export const getSumInsuredSuccess = (store) => store?.getSumInsured.success
export const getSumInsuredError = (store) => store?.getSumInsured.error



// ACTIONS TO DISPATCH
export const { clearGetSumInsuredError, resetSumInsuredDetails } = getSumInsuredSlice.actions;


export default getSumInsuredSlice.reducer;
