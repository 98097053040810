import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../axiosInstance";
import { accountApiEndpoints } from "../../api";

const initialState = {
  isLoading: false,
  error: null,
  data: null,
  otp: null,
}

// Async thunk to handle the API call for verifying OTP
export const verifyOtp = createAsyncThunk(
  "otp/verifyOtp",
  async ({ pinId, pin }, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`${accountApiEndpoints.verifyToken}`, {
        pinId,
        pin,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Error occurred during OTP verification");
    }
  }
);

const verifyOtpSlice = createSlice({
  name: "verifyOtp",
  initialState,
  reducers: {
    resetVerifyOtpState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.data = null;
      state.otp = null;
    },
    setOtpData: (state, action) => {
      state.otp = action.payload ;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyOtp.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to verify OTP";
      });
  },
});

export const selectVerifyOtpLoading = (state) => state?.verifyOtpData?.isLoading;
export const selectVerifyOtpError = (state) => state?.verifyOtpData?.error;
export const selectVerifyOtpData = (state) => state?.verifyOtpData?.data;
export const getOtpData = (state) => state?.verifyOtpData?.otp;

export const { resetVerifyOtpState, setOtpData } = verifyOtpSlice.actions;

export default verifyOtpSlice.reducer;
