import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { headerStepperData } from "../../pages/motorInsurancePages/newMotorPolicy/data";


const initialState = {
    current_header_step: headerStepperData.START,
};

export const newMotorFormSlice = createSlice({
    name: "new_motor_form_slice",
    initialState,
    reducers: {
        setMotorFormData: (state, action) => {
            let { form_name, data } = action.payload || {};
            state[form_name] = data;
        },
        clearMotorFormData: (state, action) => {
            const { form_name } = action.payload || {};
            if (form_name) {
                state[form_name] = null;
            }
        },

        // header step
        setMotorFormHeaderStep: (state, action) => {
            let stepType = action.payload;
            state.current_header_step = stepType;
        },

        clearNewMotorFormData: () => initialState

    }
});

// SELECTORS
export const selectNewMotorForm = (store) => store?.newMotorFormData;
export const selectNewMotorFormHeaderStep = (store) => store?.newMotorFormData?.current_header_step;

// ACTIONS 
export const { setMotorFormData, clearMotorFormData, setMotorFormHeaderStep, clearNewMotorFormData } = newMotorFormSlice.actions;


export default newMotorFormSlice.reducer;
