import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import { expressShieldEndPoints } from "../../../api";

const initialState = {
    data: null,
    isLoading: false,
    error: null,
};

export const expressSheildSave = createAsyncThunk(
    "expressSheildSave",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await Axios.post(`${expressShieldEndPoints.expressSheildSave}`, payload);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const expressSheildSlice = createSlice({
    name: "Express Shield Slice",
    initialState,
    reducers: {
        clearExpressShieldError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        //  EXPRESS SHIELD SAVE API
        builder.addCase(expressSheildSave.pending, (state) => {
                state.isLoading = true;
        });
        builder.addCase(expressSheildSave.fulfilled, (state, action) => {
            state.isLoading = false;
            state.data = action?.payload;
            state.error = null;
        });
        builder.addCase(expressSheildSave.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error?.message;
        });
    },
});

// SELECTORS
export const selectExpressShieldWholeData = (store) => store?.expressSheild;
export const selectExpressShieldLoader = (store) => store?.expressSheild.isLoading;
export const selectExpressShieldData = (store) => store?.expressSheild.data;

// ACTIONS TO DISPATCH
export const { clearExpressShieldError } = expressSheildSlice.actions;

export default expressSheildSlice.reducer;
