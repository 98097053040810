import southWest from '../../../../../assets/images/south_west.svg'
import northWest from '../../../../../assets/images/north_east.svg'
import carplate from '../../../../../assets/images/CarPlates.svg'
import carplate1 from '../../../../../assets/images/CarPlates1.svg'
import carplate2 from '../../../../../assets/images/CarPlates2.svg'
import carplate3 from '../../../../../assets/images/CarPlates3.svg'
import carplate4 from '../../../../../assets/images/CarPlates4.svg'
import carplate5 from '../../../../../assets/images/CarPlates5.svg'
import carplate6 from '../../../../../assets/images/CarPlates6.svg'
import carplate7 from '../../../../../assets/images/CarPlates7.svg'

export const yesNoTabs = [
    { label_en: "Yes", label_ar: "نعم", type: "Yes" },
    { label_en: "No", label_ar: "لا", type: "No" }
]

export const lastTimeClaimTabs = [
    { label_en: "Less than a year ago", label_ar: "نعم", type: "less_than_a_year" },
    { label_en: "More than a year ago or Never", label_ar: "لا", type: "more_than_a_year" }
]

export const START_PAGE_BASIC_INFO = "START_PAGE_BASIC_INFO"

export const CAR_INFO_BRAND_NEW = "CAR_INFO_BRAND_NEW";
export const CAR_INFO_NEW_CAR_DETAILS = "CAR_INFO_NEW_CAR_DETAILS";
export const CAR_INFO_PLATE_NUMBER = "CAR_INFO_PLATE_NUMBER";
export const CAR_INFO_CONFIRM = "CAR_INFO_CONFIRM";
export const CAR_INFO_FINANCING = "CAR_INFO_FINANCING";

export const carInfoSteps = [
    CAR_INFO_BRAND_NEW,
    CAR_INFO_PLATE_NUMBER,
    CAR_INFO_CONFIRM,
    CAR_INFO_FINANCING,
];

// We found your car!
export const car_info_table_data = (carInfoFromGdt) => {

    if (!carInfoFromGdt) {
        return [];
    }
    else {
        // console.log("Cond: ", !carInfoFromGdt);
        // console.log("Car info from GDT is: ", carInfoFromGdt);
        // console.log("Car info from GDT is: ", carInfoFromGdt.PlateNumber);
        const maskChassisNumber = (chassisNumber) => {
            if (!chassisNumber || chassisNumber.length < 5) {
                return chassisNumber || ''; // Return as is if too short to mask
            }
            const firstTwo = chassisNumber.slice(0, 2);
            const lastThree = chassisNumber.slice(-3);
            const maskedMiddle = 'X'.repeat(chassisNumber.length - 5);
            return `${firstTwo}${maskedMiddle}${lastThree}`;
        };

        return [
            { column1: 'Plate Number', column1_ar: 'رقم اللوحة', column2: carInfoFromGdt.PlateNumber || '' },
            { column1: 'Plate Type', column1_ar: 'نوع اللوحة', column2: carInfoFromGdt.PlateTypeDescriptionEnglish || '' },
            { column1: 'Chassis Number', column1_ar: 'رقم الشاسيه', column2: maskChassisNumber(carInfoFromGdt.ChassisNumber) },
            //   { column1: 'Car Make', column2: carInfoFromGdt.VehicleMakeDescriptionEnglish || '' },
            //   { column1: 'Car Model', column2: carInfoFromGdt.VehicleModelDescriptionEnglish || '' },
            ...(carInfoFromGdt.TCSVehicleMakeCode != "" || carInfoFromGdt.TCSVehicleModelCode != ""
                ? [
                    { column1: 'Car Make', column1_ar: "ماركة السيارة", column2: carInfoFromGdt.VehicleMakeDescriptionEnglish || '' },
                    { column1: 'Car Model', column1_ar: "طراز السيارة", column2: carInfoFromGdt.VehicleModelDescriptionEnglish || '' },
                ]
                : []),
            { column1: 'Vehicle Colour', column1_ar: 'لون المركبة', column2: carInfoFromGdt.VehicleColor1DescriptionEnglish || '' },
            { column1: 'Manufacturing Year', column1_ar: 'سنة الصنع', column2: carInfoFromGdt.YearOfManufacture || '' },
            { column1: 'Registration Expiry Date', column1_ar: 'تاريخ انتهاء التسجيل', column2: carInfoFromGdt.RegistrationExpiryDate || '' },
        ];
    }
}

// Your car's information
export const car_info_from_os_table_data = (carInfoFromOs) => {

    if (!carInfoFromOs) {
        return [];
    }
    else {
        // console.log("Cond: ", !carInfoFromOs);
        // console.log("Car info from GDT is: ", carInfoFromOs);
        // console.log("Car info from GDT is: ", carInfoFromOs.VehicleNo);

        return [
            { column1: 'Plate Number', column2: carInfoFromOs.VehicleNo || '' },
            { column1: 'Plate Type', column2: carInfoFromOs.PlateType || '' },
            { column1: 'Owner', column2: carInfoFromOs.EnName || '' },
            { column1: 'CPR', column2: carInfoFromOs.CPR || '' },
            { column1: 'Car Make', column2: carInfoFromOs.Make + " " + carInfoFromOs.MakeCode || '' },
            { column1: 'Car Model', column2: carInfoFromOs.Model + " " + carInfoFromOs.ModelCode || '' },
            { column1: 'Vehicle Colour', column2: carInfoFromOs.Color || '' },
            { column1: 'Chassis Number', column2: carInfoFromOs.ChassisNo || '' },
            { column1: 'Manufacturing Year', column2: carInfoFromOs.ModelYear || '' },
            { column1: 'Engine Capacity', column2: carInfoFromOs.EngineCap || '' },
            { column1: 'Registration Expiry Date', column2: carInfoFromOs.RegMonth || '' }
        ];
    }
}

export const numberPlatesData = [
    { id: 1, titleEn: "Private and For Hire", image: carplate, titleAr: "خاص و للإيجار" },
    { id: 2, titleEn: "Taxi and Taxi on Call", image: carplate1, titleAr: "تاكسي و تاكسي عند الطلب" },
    { id: 3, titleEn: "Public Transportation, Public Transportation Passenger, Public D/C Pickup, Tourist Bus, Contractors, PVT Transportation Passenger, PVT D/C Pickup, PVT Goods VEH, Semi Trailer and Special Use", image: carplate2, titleAr: "النقل العام، نقل الركاب بالنقل العام، النقل العام بالسيارات، الحافلات السياحية، المقاولون، نقل الركاب بالنقل الخاص، النقل الخاص بالنقل بالنقل الخاص، المركبات ذات المقطورات شبه المقطورة والمركبات ذات الاستخدام الخاص" },
    { id: 4, titleEn: "Motorcycle", image: carplate3, titleAr: "دراجة نارية" },
    { id: 5, titleEn: "Public Security", image: carplate4, titleAr: "الأمن العام" },
    { id: 6, titleEn: "Royal court", image: carplate5, titleAr: "الديوان الملكي" },
    { id: 7, titleEn: "Diplomatic", image: carplate6, titleAr: "دبلوماسي" },
    { id: 8, titleEn: "Classic", image: carplate7, titleAr: "كلاسيكي" },
]

export const INCREASE_VALUE = "Increase Value";
export const DECREASE_VALUE = "Decrease Value"
export const increaseDecreaseTabOptions = [
    {
        label_en: INCREASE_VALUE,
        label_ar: "زيادة القيمة",
        type: INCREASE_VALUE,
        icon: northWest
    },
    {
        label_en: DECREASE_VALUE,
        label_ar: "تقليل القيمة",
        type: DECREASE_VALUE,
        icon: southWest
    }
]

let SumInsuredIncreaseLevel1 = 0;
let SumInsuredIncreaseLevel2 = 0;
let SumInsuredIncreaseLevel3 = 0;
// Sum insured increase
export const getIncreaseTabOptions = (sumInsuredDetails, renewalSumInsured) => {
    console.log("Sum insured condit ", sumInsuredDetails)
    console.log("Amount 10%", SumInsuredIncreaseLevel1);
    console.log("Amount 20%", SumInsuredIncreaseLevel2);
    console.log("Amount 30%", SumInsuredIncreaseLevel3);


    if (sumInsuredDetails == "" || sumInsuredDetails == null) {
        // If sumInsuredDetails is null or undefined, use renewalSumInsured to calculate amounts
        if (SumInsuredIncreaseLevel1 == 0 || SumInsuredIncreaseLevel1 == NaN &&
            SumInsuredIncreaseLevel2 == 0 || SumInsuredIncreaseLevel2 == NaN &&
            SumInsuredIncreaseLevel3 == 0 || SumInsuredIncreaseLevel3 == NaN) {
            console.log("Sum insured is null")
            SumInsuredIncreaseLevel1 = renewalSumInsured * 1.05;
            SumInsuredIncreaseLevel2 = renewalSumInsured * 1.10;
            SumInsuredIncreaseLevel3 = renewalSumInsured * 1.15;
        }
    } else {
        console.log("Sum insured is not null", sumInsuredDetails)

        // If sumInsuredDetails exists, use its values
        SumInsuredIncreaseLevel1 = sumInsuredDetails.SumInsuredIncreaseLevel1 || 0;
        SumInsuredIncreaseLevel2 = sumInsuredDetails.SumInsuredIncreaseLevel2 || 0;
        SumInsuredIncreaseLevel3 = sumInsuredDetails.SumInsuredIncreaseLevel3 || 0;
    }

    return [
        {
            label_en: "5% Increase",
            label_ar: "نعم",
            subtitle_en: `BHD ${Math.round(SumInsuredIncreaseLevel1)}`,
            type: 1,
            amount: Math.round(SumInsuredIncreaseLevel1)
        },
        // {
        //     label_en: "10% Increase",
        //     label_ar: "لنعما",
        //     subtitle_en: `BHD ${Math.round(SumInsuredIncreaseLevel2)}`,
        //     type: 2,
        //     amount: Math.round(SumInsuredIncreaseLevel2)
        // },
        // {
        //     label_en: "15% Increase",
        //     label_ar: "لنعما",
        //     subtitle_en: `BHD ${Math.round(SumInsuredIncreaseLevel3)}`,
        //     type: 3,
        //     amount: Math.round(SumInsuredIncreaseLevel3)
        // }
    ];
};

let SumInsuredDecreaseLevel1 = 0;
let SumInsuredDecreaseLevel2 = 0;
let SumInsuredDecreaseLevel3 = 0;

// Sum insured decrease
export const getDecreaseTabOptions = (sumInsuredDetails, renewalSumInsured) => {


    if (sumInsuredDetails == "" || sumInsuredDetails == null) {
        // If sumInsuredDetails is null or undefined, use renewalSumInsured to calculate amounts
        if (SumInsuredDecreaseLevel1 == 0 || SumInsuredDecreaseLevel1 == NaN &&
            SumInsuredDecreaseLevel2 == 0 || SumInsuredDecreaseLevel2 == NaN &&
            SumInsuredDecreaseLevel3 == 0 || SumInsuredDecreaseLevel3 == NaN) {

            SumInsuredDecreaseLevel1 = renewalSumInsured * 0.95; // 5% decrease
            SumInsuredDecreaseLevel2 = renewalSumInsured * 0.90; // 10% decrease
            SumInsuredDecreaseLevel3 = renewalSumInsured * 0.85; // 15% decrease
        }
    } else {
        // If sumInsuredDetails exists, use its values
        SumInsuredDecreaseLevel1 = sumInsuredDetails.SumInsuredDecreaseLevel1 || 0;
        SumInsuredDecreaseLevel2 = sumInsuredDetails.SumInsuredDecreaseLevel2 || 0;
        SumInsuredDecreaseLevel3 = sumInsuredDetails.SumInsuredDecreaseLevel3 || 0;
    }

    return [
        {
            label_en: "5% Decrease",
            label_ar: "نعم",
            subtitle_en: `BHD ${Math.round(SumInsuredDecreaseLevel1)}`,
            type: 1,
            amount: Math.round(SumInsuredDecreaseLevel1)
        },
        {
            label_en: "10% Decrease",
            label_ar: "لنعما",
            subtitle_en: `BHD ${Math.round(SumInsuredDecreaseLevel2)}`,
            type: 2,
            amount: Math.round(SumInsuredDecreaseLevel2)
        },
        {
            label_en: "15% Decrease",
            label_ar: "لنعما",
            subtitle_en: `BHD ${Math.round(SumInsuredDecreaseLevel3)}`,
            type: 3,
            amount: Math.round(SumInsuredDecreaseLevel3)
        }
    ];
};



export const SIGN_IN_PAGE = "SIGN_IN_PAGE";


// Car inputs list
export const modelYears = [
    { value: "2025", label: "2025" },
    { value: "2024", label: "2024" },
    { value: "2023", label: "2023" },
    { value: "2022", label: "2022" },
    { value: "2021", label: "2021" },
    { value: "2020", label: "2020" },
    { value: "2019", label: "2019" },
    { value: "2018", label: "2018" },
]

export const bodyType = [
    { value: "SEDAN", label: "SEDAN" },
    { value: "SUV", label: "SUV" },
]


export const noOfSeats = [
    { value: "4", label: "4 Seater" },
    { value: "5", label: "5 Seater" },
    { value: "7", label: "7 Seater" },
]

export const engineSizes = [
    { value: "1100", label: "1100 CC" },
    { value: "1200", label: "1200 CC" },
    { value: "1300", label: "1300 CC" },
    { value: "1400", label: "1400 CC" },
    { value: "1500", label: "1500 CC" },
    { value: "1600", label: "1600 CC" },
    { value: "1700", label: "1700 CC" },
    { value: "1800", label: "1800 CC" },
    { value: "1900", label: "1900 CC" },
    { value: "2000", label: "2000 CC" },
    { value: "2100", label: "2100 CC" },
    { value: "2200", label: "2200 CC" },
    { value: "2300", label: "2300 CC" },
    { value: "2400", label: "2400 CC" },
    { value: "2500", label: "2500 CC" },
    { value: "2600", label: "2600 CC" },
    { value: "2700", label: "2700 CC" },
    { value: "2800", label: "2800 CC" },
    { value: "2900", label: "2900 CC" },
    { value: "3000", label: "3000 CC" },
    { value: "3100", label: "3100 CC" },
    { value: "3200", label: "3200 CC" },
    { value: "3300", label: "3300 CC" },
    { value: "3400", label: "3400 CC" },
    { value: "3500", label: "3500 CC" },
    { value: "3600", label: "3600 CC" },
    { value: "3700", label: "3700 CC" },
    { value: "3800", label: "3800 CC" },
    { value: "3900", label: "3900 CC" },
    { value: "4000", label: "4000 CC" },
    { value: "4100", label: "4100 CC" },
    { value: "4200", label: "4200 CC" },
    { value: "4300", label: "4300 CC" },
    { value: "4400", label: "4400 CC" },
    { value: "4500", label: "4500 CC" },
    { value: "4600", label: "4600 CC" },
    { value: "4700", label: "4700 CC" },
    { value: "4800", label: "4800 CC" },
    { value: "4900", label: "4900 CC" },
    { value: "5000", label: "5000 CC" },
    { value: "5100", label: "5100 CC" },
    { value: "5200", label: "5200 CC" },
    { value: "5300", label: "5300 CC" },
    { value: "5400", label: "5400 CC" },
    { value: "5500", label: "5500 CC" },
    { value: "5600", label: "5600 CC" },
    { value: "5700", label: "5700 CC" },
    { value: "5800", label: "5800 CC" },
    { value: "5900", label: "5900 CC" },
    { value: "6000", label: "6000 CC" }
]



export const BANK_VALIDATION = {
    name: "bank_name",
    validations: ['select']
}
export const U_CAR_WITH_OC_VALIDATIONS = [
    {
        name: "is_finance",
        validations: ['tab']
    },
    {
        name: "last_time_file_claim",
        validations: ['tab']
    },
    {
        name: "amount",
        validations: ['min', 'max'],
        min_value: 3000,
        max_value: 50000
    },
]
export const U_CAR_WITHOUT_OC_VALIDA = [
    ...U_CAR_WITH_OC_VALIDATIONS,
    // {
    //     name: "bodyType",
    //     validations: ['select']
    // },
    // {
    //     name: "no_of_seats",
    //     validations: ['select']
    // },
    // {
    //     name: "engine_capacity",
    //     validations: ['select']
    // },

]



export const NEW_CAR_INFO_FIN_VALIDATIONS = [
    {
        name: "is_finance",
        validations: ['tab']
    },
    {
        name: "start_policy_date",
        validations: ['required']
    },
    {
        name: "amount",
        validations: ['min', 'max'],
        min_value: 3000,
        max_value: 50000
    },
]
