import React from "react";
import Button from "../../../../components/common/Button/Button";
import CheckCircle from "../../../../assets/images/check_circle-claim.svg";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SuccessScreen = () => {
  const navigate = useNavigate();
  const handleSuccess = () => {
    navigate("/motor-insurance/claim-details/123");
  };
  const { t } = useTranslation();
  return (
    <div className="mainCarDetailsModal pt-[2%] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll max767:p-4">
      <div className="flex w-[500px] flex-col items-center gap-4 px-6 py-12 mx-auto max-w-[100%] bg-[#F6F8F9] rounded-2xl max767:mt-[5%]">
        <img src={CheckCircle} className="w-[64px] h-[64px]" alt="" />

        <p className="text-[#1A2024] text-center text-2xl not-italic font-normal leading-[34px] tracking-[-0.24px]">
          {t("motorInsurance.trackClaimFlow.successScreen.confirmationMessage")}
        </p>
      </div>
      <div className="flex w-[500px] mx-auto max-w-[100%]">
        <Button
          label={t(
            "motorInsurance.trackClaimFlow.successScreen.backToClaimButton"
          )}
          className=" bg-[#55C6EF] hover:bg-[#55C6EF] disabled:bg-gray-400 disabled:cursor-not-allowed flex flex-col items-center gap-2.5 self-stretch p-4 rounded-[10px] w-full mt-4 text-lg"
          onSubmit={handleSuccess}
        />
      </div>
    </div>
  );
};

export default SuccessScreen;
