import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import { crmApiEndPoints } from "../../../api";

const initialState = {
    data: null,
    isLoading: false,
    success: null,
    error: null,
};


export const createCaseComplaint = createAsyncThunk(
    "createCaseComplaint",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await Axios.post(`${crmApiEndPoints.caseComplaint}`, payload, {
                timeout: 30000, // Set timeout to 5 seconds (5000 ms)
            });
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);


export const caseComplaintSlice = createSlice({
    name: "caseComplaintSlice",
    initialState,
    reducers: {
        clearCaseComplaintError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        // FETCH  CREATE LEAD API
        builder.addCase(createCaseComplaint.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(createCaseComplaint.fulfilled, (state, action) => {
            state.isLoading = false;
            state.success = true;
            state.data = action?.payload;
        });
        builder.addCase(createCaseComplaint.rejected, (state, action) => {
            state.isLoading = false;
            state.success = false;
            state.error = action.error?.message;
        });
    },
});

// SELECTORS
export const selectCrmCaseComplaintWholeData = (store) => store?.crmCaseComplaint;
export const selectCrmCaseComplaintLoader = (store) => store?.crmCaseComplaint.isLoading;
export const selectCrmCaseComplaint = (store) => store?.crmCaseComplaint.data;


// ACTIONS TO DISPATCH
export const { clearCaseComplaintError } = caseComplaintSlice.actions;

export default caseComplaintSlice.reducer;
