import React, { useEffect, useState } from "react";
import Input from "../Input/Input";
import AlertComponent from "../../../pages/motorInsurancePages/transferPolicyFlow/alertComponent";
import FundsToRemoveData from "../../../pages/lifeInsurancePages/PolicyOverview/fundManagementTab/investementStrategyFlow/fundSwitchingFlow/fundsToRemoveData";
import { useTranslation } from "react-i18next";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import deleteicon from "../../../../src/assets/images/delete_outline.svg";
import Button from "../Button/Button";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { currencyFormatterWithCountryCode } from "../../../utils";

const FundsPremiumTable = ({
  fundsData = [],
  setFundsData,
  tableType,
  addFundHandler,
  setIsUserChangedFunds,
  fundsToRemove,
  total,
  setTotal,
  errorMsg,
  setErrorMsg,
  allocationTotal,
  setAllocationTotal,
}) => {
  const [values, setValues] = useState({});
  // const [total, setTotal] = useState(100);
  // const [price, setPrice] = useState(0);
  // const [errorMsg, setErrorMsg] = useState("");
  // const [allocationTotal, setAllocationTotal] = useState(0);
  const [expandedRows, setExpandedRows] = useState([]);

  const translate = useLanguageTranslate();
  const { i18n } = useTranslation();

  const headers = {
    en: {
      redirecting: [
        "",
        "Fund Name",
        "Number of Unit",
        "Unit Price",
        "Fund Value",
        "Current %",
        "New %",
      ],
      allocation: ["", "Fund Name", "Unit Price", "Allocation", "Fund Value"],
    },
    ar: {
      redirecting: [
        "",
        "اسم الصندوق",
        "الوحدات المحتفظ بها",
        "السعر الحالي",
        "القيمة الإجمالية",
        "النسبة الحالية",
        "النسبة الجديدة",
      ],
      allocation: ["", "اسم الصندوق", "سعر الوحدة", "التخصيص", "قيمة الصندوق"],
    },
  };

  useEffect(() => {
    if (fundsToRemove) {
      const total = fundsToRemove?.reduce(
        (acc, each) => acc + (parseInt(each.original.allocation, 10) || 0),
        0
      );
      setAllocationTotal(total);
    }
  }, [fundsToRemove]);

  const getHeaders = () => {
    return (
      headers[i18n.resolvedLanguage][tableType] ||
      headers[i18n.resolvedLanguage].redirecting
    );
  };

  const getRowValues = (each) => {
    const inputStyle =
      values[each?.isin] < 10
        ? "border-red-500 focus:outline-none focus:ring-red-500"
        : "border-gray-300";

    switch (tableType) {
      case "redirecting":
        return [
          each.fund_name,
          each.units_held,
          each.current_price || each?.tcsOriginal?.fundLastNAVprice,
          currencyFormatterWithCountryCode(each.total_value),
          (each?.current_percent || 0) + "%",
          <Input
            type="number"
            value={values[each?.isin] ?? 0}
            handleChange={inputChangeHandler}
            name={each?.isin + ""}
            className={inputStyle}
            endAdornment={<div className="bg-gray-100">%</div>}
          />,
        ];
      case "allocation":
        return [
          each.fund_name,
          each.current_price,
          <Input
            type="number"
            value={values[each?.isin] ?? 0}
            handleChange={inputChangeHandler}
            name={each?.isin + ""}
            className={inputStyle}
            endAdornment={<div className="bg-gray-100">%</div>}
          />,
          each.total_value,
        ];
      default:
        return [];
    }
  };

  useEffect(() => {
    let update = {};
    fundsData.forEach((each) => {
      update[each.isin] = each.current_percent;
    });
    setValues(update);

    const newTotal = Object.values(fundsData).reduce(
      (acc, cur) => acc + (parseInt(cur.current_percent, 10) || 0),
      0
    );
    setTotal(newTotal);
    const hasError = Object.values(fundsData).some(
      (v) => v.current_percent < 10
    );
    if (hasError) {
      setErrorMsg("Minimum allocation per fund is 10%.");
    } else {
      setErrorMsg("");
    }
    // const newPrice = Object.values(fundsData).reduce((acc, cur) => {
    //   const numericValue =
    //     parseFloat(cur?.total_value?.replace(/[^0-9.-]+/g, "")) || 0;
    //   return acc + numericValue;
    // }, 0);
    // setPrice(newPrice);
  }, [fundsData]);

  const inputChangeHandler = (e) => {
    let name = e.target.name;
    let value = parseInt(e.target.value);
    if (value > 100) return;
    const updatedFundsData = fundsData.map((each) => {
      if (each.isin == name) {
        return {
          ...each,
          current_percent: e.target.value,
        };
      } else {
        return each;
      }
    });
    setFundsData(updatedFundsData);
    setValues((prevValues) => {
      const updatedValues = { ...prevValues, [name]: value };
      const newTotal = Object.values(updatedValues).reduce(
        (acc, cur) => acc + (parseInt(cur, 10) || 0),
        0
      );
      setTotal(newTotal);

      const hasError = Object.values(updatedValues).some((v) => v < 10);
      if (hasError) {
        setErrorMsg("Minimum allocation per fund is 10%.");
      } else {
        setErrorMsg("");
      }

      return updatedValues;
    });
    setIsUserChangedFunds(true);
  };

  const deleteRow = (id) => {
    const updatedFunds = fundsData.filter((fund) => fund.isin != id);
    setFundsData(updatedFunds);
    const updatedValues = { ...values };
    delete updatedValues[id];
    const newTotal = Object.values(updatedValues).reduce(
      (acc, cur) => acc + (cur || 0),
      0
    );
    setTotal(newTotal);
    setValues(updatedValues);
    setIsUserChangedFunds(true);
  };

  const toggleRow = (id) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(id)
        ? prevExpandedRows.filter((rowId) => rowId != id)
        : [...prevExpandedRows, id]
    );
  };

  const isRowExpanded = (id) => expandedRows.includes(id);

  return (
    <>
      {fundsToRemove && tableType == "allocation" && (
        <FundsToRemoveData fundsToRemove={fundsToRemove} />
      )}

      <div className="table-container max767:mt-4">
        {/* Desktop View */}
        <table className="w-full border-spacing-0 border-separate border rounded-lg hidden md:table fund-redirect-tbl table-auto">
          <thead>
            <tr>
              {getHeaders().map((header, index) => (
                <th
                  key={index}
                  className="border-b p-2 text-left text-[#6E7C87] text-xs font-semibold leading-6  bg-[#F6F8F9] w-[50px]"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {fundsData?.map((each) => (
              <tr
                key={each.isin}
                className={`${each?.isNew ? "bg-blue-100" : ""}`}
              >
                <td className="p-2 border-b w-[5%]">
                  <button
                    onClick={() => deleteRow(each.isin)}
                    className="flex w-6 h-6 justify-center items-center shrink-0 rounded bg-[#E5E9EB] ipad:bg-[#fff]"
                  >
                    <img src={deleteicon} alt="delete" />
                  </button>
                </td>
                {getRowValues(each).map((value, valueIndex) => (
                  <td
                    key={valueIndex}
                    className="p-2 text-sm font-normal leading-6 border-b flex justify-between md:table-cell"
                  >
                    {value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot className="justify-end  w-full bg-[#F6F8F9] border-separate border rounded-[10px] border-b-1 border-solid">
            {fundsData.length > 0 && (
              <tr className="self-stretch justify-center items-center  bg-[#F6F8F9] border-b">
                <td
                  colSpan="6"
                  className="px-6 py-2.5 w-full text-[#252C32] text-base not-italic font-semibold leading-6 tracking-[-0.096px] text-right pr-16"
                >
                  <span className="">{translate("Total ", "المجمو")}</span>
                </td>
                <td className="py-2.5 w-full text-[#252C32] text-base not-italic font-semibold leading-6 tracking-[-0.096px] text-left pl-2">
                  <span className={`${total != 100 ? "invalid" : "valid"}`}>
                    {translate(`${total || 0}% `, `${total || 0}%`)}
                  </span>
                </td>
              </tr>
            )}
          </tfoot>
        </table>

        {/* Mobile View */}
        <div className="md:hidden">
          {tableType == "redirecting" && (
            <Button
              label={translate("+ Add Fund", "+ إضافة صندوق")}
              // pre_icon={deleteicon}
              onSubmit={addFundHandler}
              className="flex flex-col items-center gap-2 bg-[#55C6EF] px-4 py-2 rounded-[10px] text-sm not-italic font-semibold leading-6 max767:mb-3 max767:ml-4"
            />
          )}
          {fundsData?.map((each) => (
            <React.Fragment key={each.isin}>
              <div
                onClick={() => toggleRow(each.isin)}
                className={`cursor-pointer ${
                  isRowExpanded(each.isin) ? "bg-[#EDFAFF]" : "bg-[#F6F8F9]"
                }   mb-2 p-3 rounded-lg flex justify-between   max767:flex max767:rounded-none max767:mb-0 removefundbox   max767:border-b-[#DDE2E4] max767:border-b max767:border-solid   max767:px-5 max767:py-4`}
              >
                <div>
                  <span className="text-sm font-semibold">
                    {each.fund_name}
                  </span>
                  {!isRowExpanded(each.isin) && (
                    <span
                      className={`${
                        each?.isNew ? "bg-blue-100" : ""
                      } text-sm font-semibold`}
                    >
                      {each.current_percent} {each.total_value}
                    </span>
                  )}
                </div>
                <FontAwesomeIcon
                  icon={isRowExpanded(each.isin) ? faChevronUp : faChevronDown}
                  className="flex text-[#252C32] text-xs mt-[-5px] mr-4 ipad:mr-0 max767:mt-2 max767:mr-1"
                />
              </div>

              {isRowExpanded(each.isin) && (
                <div className="mb-4 bg-[#EDFAFF] max767:mb-0">
                  {getRowValues(each)
                    .slice(1) // Skip the first value
                    .map((value, valueIndex) => (
                      <div
                        key={valueIndex}
                        className="p-3 text-sm flex justify-between allocationbox max767:px-5 max767:py-4 max767:pb-0"
                      >
                        <span className="text-[#6E7C87] text-sm not-italic font-medium leading-[normal]">
                          {getHeaders()[valueIndex + 2]}:
                        </span>
                        <span className="text-black text-right text-base not-italic font-normal leading-[normal]">
                          {value}
                        </span>
                      </div>
                    ))}
                  <div className="p-3 flex justify-center">
                    <Button
                      label={translate(
                        "Remove Fund Allocation",
                        "حذف تخصيص الصندوق"
                      )}
                      pre_icon={deleteicon}
                      onSubmit={() => deleteRow(each.isin)}
                      className="RemoveFundAllocation flex items-center gap-2 self-stretch border bg-white px-4 py-2 rounded-md border-solid border-[#DDE2E4] w-full justify-center text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
                    />
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
        {/* Remaining part of your component (alerts, buttons, etc.) */}
        {tableType == "redirecting" && (
          <div className="flex ipad:pl-4 ipad:pr-4  items-center mt-4 max767:hidden justify-between redirectingfund">
            <Button
              label={translate("+ Add Fund", "+ إضافة صندوق")}
              // pre_icon={deleteicon}
              onSubmit={addFundHandler}
              className="AddFund flex items-center gap-2 bg-[#55C6EF] px-4 py-2 rounded-[10px] text-sm not-italic font-semibold leading-6 mb-4"
            />

            {fundsData?.length > 10 && (
              <AlertComponent
                className="allocationalert"
                title={`${translate(
                  "Maximum Funds is 10 per policy, please remove",
                  "الحد الأقصى للصناديق هو 10 لكل بوليصة، يرجى إزالة"
                )} ${fundsData.length - 10} ${translate("funds", "صناديق")}`}
              />
            )}
            {errorMsg && (
              <AlertComponent className="allocationalert" title={errorMsg} />
            )}
            {total > 100 && (
              <AlertComponent
                title={translate(
                  "Total allocation is more than 100%",
                  "إجمالي التخصيص يزيد عن 100٪"
                )}
              />
            )}
            {fundsData?.length > 0 && total < 100 && (
              <AlertComponent
                title={translate(
                  "Total allocation is less than 100% ",
                  "إجمالي التخصيص أقل من 100٪"
                )}
              />
            )}
          </div>
        )}
        {tableType == "allocation" && errorMsg && (
          <div className="mt-4 text-red-500 text-sm hidden md:block">
            <AlertComponent
              title={errorMsg}
              label={translate(
                "You can remove a fund if you want to adjust your allocation.",
                "يمكنك حذف هذا الصندوق إذا كنت ترغب في تعديل تخصيصك."
              )}
            />
          </div>
        )}
        {tableType == "allocation" && total != allocationTotal && !errorMsg && (
          <div className="mt-4 text-red-500 text-sm ipad:pl-4 ipad:pr-4 hidden md:block">
            <AlertComponent
              title={`${translate(
                "Total allocation should be",
                "يجب أن يكون إجمالي التخصيص"
              )} ${allocationTotal}%`}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default FundsPremiumTable;
