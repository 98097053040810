import { createSlice } from '@reduxjs/toolkit';

const resetSlice = createSlice({
  name: 'reset',
  initialState: {},
  reducers: {
    resetRedux: () => ({}),
  },
});

export const { resetRedux } = resetSlice.actions;
export default resetSlice.reducer;
