import React, { useState } from "react";
import TitleDescription from "../../../../../components/newMotorPolicy/titleDescription/titleDescription";
import useLanguageTranslate from "../../../../../hooks/useLanguageTranslate";
import { INVESTMENT_STRATEGIES } from "../investmentStrategyChangeFlow/data";
import AlertComponent from "../../../../motorInsurancePages/transferPolicyFlow/alertComponent";
import RenewMotorLayout from "../../../../motorInsurancePages/renewalMotorPolicy/renewMotorLayout";
import { useTranslation } from "react-i18next";
import StrategyCard from "../investmentStrategyChangeFlow/strategyCard";
import {
  FUND_MANAGEMENT_CARD_DATA,
  FUND_REDIRECTING_TYPE,
  FUND_SWITCHING_TYPE,
} from "./data";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useScreenResolution from "../../../../../hooks/useScreenResolution";

const FundManagementPage = () => {
  const translate = useLanguageTranslate();
  const screenResolution = useScreenResolution();
  const [selectedFundManagementType, setSelectedFundManagementType] =
    useState(null);

  const currentFundManagementType = INVESTMENT_STRATEGIES[0];
  const { t } = useTranslation();

  const handleFundManagementTypeSelect = (fundManagementType) => {
    setSelectedFundManagementType(fundManagementType);
  };

  const navigate = useNavigate();
  const { search } = useLocation();

  const confirmClickHandler = () => {
    if (selectedFundManagementType?.type ==FUND_SWITCHING_TYPE) {
      navigate(`/life-insurance/funds-switching-flow${search}`);
    } else if (selectedFundManagementType?.type ==FUND_REDIRECTING_TYPE) {
      navigate(`/life-insurance/funds-redirecting-flow${search}`);
    }
  };

  return (
    <RenewMotorLayout
      show_close_btn
      is_hide_back_btn
      btn_disabled={!selectedFundManagementType}
      btn_onClick={confirmClickHandler}
      hideContinueBtn={!screenResolution.mobile}
    >
      <div className="mainCarDetailsModal pt-[80px] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll max767:pt-[0]">
        <div className="changeplatenumber w-[500px] max-w-[100%] mx-auto bg-white max767:p-5 fundmanage max767:pt-0">
          <TitleDescription
            title={translate("Funds Management ", "إدارة الصناديق")}
            description={translate(
              "Move your investments into risk rated, diversified and actively managed portfolio of funds for your investments.",
              "انقل استثماراتك إلى محفظة صناديق استثمارية مُصنفة حسب المخاطر، ومتنوعة، وتدار بنشاط لتحقيق أهداف استثماراتك."
            )}
          />
          <div className="max767:hidden">
            <AlertComponent
              title={translate(
                "Please note, this request will be submitted for approval before it takes effect",
                "يرجى العلم أن هذا الطلب سيقدم للفحص والموافقة قبل أن يصبح ساريًا"
              )}
            />
          </div>
          <div className="grid grid-cols-2 gap-4 mt-5 max767:grid-cols-1">
            {FUND_MANAGEMENT_CARD_DATA?.map((fundManagementType) => (
              <StrategyCard
                key={fundManagementType.id}
                strategyName={translate(
                  fundManagementType.title_en,
                  fundManagementType.title_ar
                )}
                description={translate(
                  fundManagementType.description,
                  fundManagementType.description_ar
                )}
                selected={
                  selectedFundManagementType?.id ==fundManagementType.id
                }
                isCurrentFundManagementType={
                  currentFundManagementType?.id ==fundManagementType.id
                }
                onClick={() =>
                  handleFundManagementTypeSelect(fundManagementType)
                }
                fundManagementType={fundManagementType}
                icon={fundManagementType.icon}
              />
            ))}
          </div>
          <div className="max767:block mb-4 hidden">
            <AlertComponent
              title={translate(
                "Please note, this request will be submitted for approval before it takes effect",
                "يرجى العلم أن هذا الطلب سيقدم للفحص والموافقة قبل أن يصبح ساريًا"
              )}
            />
          </div>
          <button
            className={`hidden md:block w-full text-[#1A2024] text-lg not-italic font-semibold leading-6 tracking-[-0.18px] flex justify-center p-3 mt-8 rounded-[10px] ${
              selectedFundManagementType
                ? "bg-[#55C6EF] rounded-[10px] cursor-pointer"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }`}
            onClick={confirmClickHandler}
          >
            {translate("Continue","متابعة")}
          </button>
        </div>
      </div>
    </RenewMotorLayout>
  );
};

export default FundManagementPage;
