import React from 'react'
import RenewMotorLayout from '../../renewalMotorPolicy/renewMotorLayout';
import MobileUploadCarPhotos from '../../newMotorPolicy/uploads/mobileUploadPages/mobileUploadCarPhotos';
import useChangePlanFlow from '../../../../hooks/useChangePlanFlow';



const MobileUploadCarPhotoStepperLayout = () => {
    const { handleNextSubstep, handlePrevSubstep } = useChangePlanFlow();
    const continueClickHanlder = () =>{
        handleNextSubstep()
    }
    return (
        <div>
            <RenewMotorLayout backClickHandler={handlePrevSubstep} show_summary={true} btn_onClick={continueClickHanlder}>
                <div className="mt-10">
                    <MobileUploadCarPhotos />
                </div>
            </RenewMotorLayout>
        </div>
    )
}

export default MobileUploadCarPhotoStepperLayout