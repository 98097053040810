/* :Page::Page:MotorRenewal: CPR Page */
import React,{useState, useEffect} from "react";
import useRenewalFlow, { RENEW_POLICY_LOGGED_IN, RENEW_POLICY_SAME_PLAN } from "../../../../hooks/useRenewalFlow";
import RenewMotorLayout from "../renewMotorLayout";
import useValidation from "../../../../hooks/useValidation";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useTranslation } from "react-i18next";
import useMotorForm from "../../../../hooks/useMotorFormData";
import Input from "../../../../components/common/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { sendToken } from "../../../../redux/features/account/accountOtpSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { toast } from 'react-toastify';
import { selectLoginTokenInfo } from "../../../../redux/features/loginRegisterSlice";
import { fetchUserPolicies, selectUserPoliciesData } from "../../../../redux/features/myPolicySlice";
import { calculatePremium } from "../../../../redux/features/motor/calculatePremiumSlice";
import { policyPremium } from "../../../../redux/features/motor/renewalPremiumSlice";
import { clearApplyPromoState } from "../../../../redux/features/promoCode/promoCodeSlice";
import { PROMO_CODE } from "../../../../components/newMotorPolicy/veiwSummaryPopup/viewSummaryPopup";


export const RENEW_POLICY_CPR_PLATE_NUMBER = "RENEW_POLICY_CPR_PLATE_NUMBER";
const RENEW_POLICY_LOGGED_OUT = "RENEW_POLICY_LOGGED_OUT";


const CprPlateNumberPage = () => {
  /* Setting up dispatch to call redux */
  const dispatch = useDispatch();

  /* Setting up variables with appropriate requests to send and response to receive, reading from redux/functions */
  const loginTokenInfo = useSelector(selectLoginTokenInfo);
  let isLoggedin = loginTokenInfo;

  /* Functions and Variables that are updated with functions/state */
  
  const { handleNextSubstep, handleFlowChange } = useRenewalFlow();
  const { errors, validateField } = useValidation();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({ cprNumber: "", plateNumber:"" });
  const [isDisabled, setIsDisabled] = useState(false);

  const { saveMotorDataInRedux } = useMotorForm(
    RENEW_POLICY_CPR_PLATE_NUMBER,
    setFormData
  );

  const { removeMotorFormData } = useMotorForm(
    RENEW_POLICY_CPR_PLATE_NUMBER,
    setFormData
  );

  const { removeMotorFormPromoCodeData } = useMotorForm(
    PROMO_CODE,
    setFormData
  );


  let isFormValid = true;
  const validateForm = () => {
    let isCprValid = validateField(
      formData?.cprNumber,
      "cprNumber",
      "required"
    );
    let isPlateValid = validateField(
      formData?.plateNumber,
      "plateNumber",
      "required"
    );
    return (isFormValid = isCprValid, isFormValid = isPlateValid);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Remove pinId and masked phonenumber from state if CPR is changed
    removeMotorFormData();
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    switch (name) {
      case "cprNumber":
        validateField(value, name, "required");
      case "plateNumber":
        validateField(value, name, "required");
        break;
      default:
        break;
    }
  };

  /* Use Effects, monitor changes to call on [var] change or one time call */

  if (isLoggedin) {
    console.log("LOGGED IN!!"); 
    // Pages index for RENEW_POLICY_LOGGED_IN: 1 CPR, 2 Sum Insured, 3 Covers
    handleFlowChange(RENEW_POLICY_LOGGED_IN, 0, 0); 
  } else {
    // Pages index for default RENEW_POLICY_SAME_PLAN: 0 CPR, 1 OTP, 2 Policies, 3 Review Plan (Flow can change, below added), 4 Sum Insured, 5 Covers, 6 Payment Summary
    // Pages index for default RENEW_POLICY_SAME_PLAN with addition of Change Plan RENEW_POLICY_DOWNGRADE_PLAN: 4 Change plan 5 Covers ...
    console.log("NOT LOGGED IN!!");
  }

  useEffect(() => {
    const isFormValid = validateForm();
    setIsDisabled(!isFormValid);
  }, [formData]);

  /* Next */
  const continueClickHanlder = async () => {
    if (validateForm()) {
      try {
        const getPoliciesPayload = {
          Cpr: formData.cprNumber,
          Channel: "online.solidarity"
        }

        // Calling API /policy/GetPolicies
        const getPoliciesAction = await dispatch(fetchUserPolicies(getPoliciesPayload));
        const getPoliciesResult = unwrapResult(getPoliciesAction);
        console.log('get policies result: ', getPoliciesResult);
        if (getPoliciesResult) {
          console.log('policies result: ', getPoliciesResult);
          const matchingPolicy = getPoliciesResult.find(
            (policy) => policy.RegistrationNumber ==formData.plateNumber && policy.IsRenewable ==true
          );
  
          let policyNumber;

          if (matchingPolicy) {
            // If a matching policy is found, means it's due for renewal
            policyNumber = matchingPolicy.PolicyNumber;

            console.log("Policy Number:", policyNumber);
            
            // Clear promo code 
            await removeMotorFormPromoCodeData();
            await dispatch(clearApplyPromoState());


            // Calling API /policy/premium
            try { 
              const premiumPayload = {
                PolicyNumber: policyNumber,
                Channel: "online.solidarity"
              }

              const premiumAction = await dispatch(policyPremium(premiumPayload));
              const premiumResult = unwrapResult(premiumAction);

              if (premiumResult){
                handleFlowChange(RENEW_POLICY_LOGGED_OUT, 1);
              }
            }
            catch (error) {
              toast.error("Error in calculating renewal premium");
            }

          } else {
            // If no renewable policy is found, find the first policy with the specified registration number
            const firstPolicyWithRegNumber = getPoliciesResult.find(
              (policy) => policy.RegistrationNumber ==formData.plateNumber && policy.PolicyStatus =='ACTIVE POLICY'
            );

            if (firstPolicyWithRegNumber) {
              // Display an error message with the EndDate of the first matching policy
              console.error(`No renewable policy found. Policy ${firstPolicyWithRegNumber.PolicyNumber} for ${firstPolicyWithRegNumber.RegistrationNumber} ends on ${firstPolicyWithRegNumber.EndDate}.`);
              toast.error(`No renewable policy found. Policy ${firstPolicyWithRegNumber.PolicyNumber} for plate number ${firstPolicyWithRegNumber.RegistrationNumber} ends on ${firstPolicyWithRegNumber.EndDate}.`);
  
            } else {
              // If no policies are found with the specified registration number
              console.error(`No policy found with Registration Number ${formData.plateNumber}.`);
              toast.error(`The Registration number ${formData.plateNumber} is not due for renewal yet.`);
            }
          }       
        }
      } catch (error) {
        // alert("Failed to send token:", error);
        toast.error("Failed to get renewal policies:", error);
      }
    }
  };

  return (
    <RenewMotorLayout
      show_close_btn
      is_hide_back_btn={true}
      btn_disabled={isDisabled}
      btn_onClick={continueClickHanlder}
    >
      <div className="max-w-[100%] w-[500px] mx-auto bg-white max767:p-5 ">
        <TitleDescription
          title={t("motorInsurance.renewalPolicyFlow.cprNoPage.quickRenewal")}
          description={t(
            "motorInsurance.renewalPolicyFlow.cprNoPage.quickRenewalDesc"
          )}
        />
        <Input
          name="cprNumber"
          type="text"
          label={t("motorInsurance.renewalPolicyFlow.cprNoPage.cprNo")}
          value={formData?.cprNumber}
          error={errors?.cprNumber}
          handleChange={handleChange}
        />
         <Input
          name="plateNumber"
          type="number"
          label={t("motorInsurance.renewalPolicyFlow.cprNoPage.plateNo")}
          value={formData?.plateNumber}
          error={errors?.plateNumber}
          handleChange={handleChange}
        />
      </div>
    </RenewMotorLayout>
  );
};

export default CprPlateNumberPage;
