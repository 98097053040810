import React from 'react';
import FormTabs from '../../../../components/common/tabs/formTabs';

const CarReplacement = ({ option, updateOptionValues }) => {
  return (
    <div className="mb-4">
      <div className="flex space-x-2 mb-2">
        <FormTabs
          tabs={option.noOfDays}
          activeTab={option.selectedDays}
          onTabClick={(tabType) =>
            updateOptionValues(option.code, { selectedDays: tabType }, true)
          }
        />
      </div>
      <div className="flex space-x-2">
        <FormTabs
          tabs={option.carType}
          activeTab={option.selectedCarType}
          onTabClick={(tabType) =>
            updateOptionValues(option.code, { selectedCarType: tabType }, true)
          }
        />
      </div>
    </div>
  );
};
export default CarReplacement;