import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import {  motorApiEndpoints } from "../../../api";
import { toast } from "react-toastify";

const initialState = {
  CurrentPolicyNumber: "",
  NewQuoteNumber: "",
  CancellationResponse: [],
  QuoteWithRefundDetails:  [],
  isCancellationLoading: false,
  isQuoteWithRefundLoading: false,
  success: null,
  error: null,
};

// Helper to create a timeout promise
const timeoutPromise = (ms) => {
    return new Promise((_, reject) =>
      setTimeout(() => reject(new Error('Request timed out')), ms)
    );
  };
  

export const cancelPolicy = createAsyncThunk(
    "cancelPolicy",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling cancel policy...")
            const response = await Promise.race([
                Axios.post(`${motorApiEndpoints.cancelPolicy}`, payload), timeoutPromise(1000), // 5 seconds timeout
            ]);
            console.log("Cancel policy response is: ", response.data)

            return response.data;
        } catch (err) {
          if (err.message =='Request timed out') {
            toast.error("Request time out.");
            return rejectWithValue({ error: 'Request timed out' });
          }
          else {
            toast.error("Error in cancelling policy", err);
          }
            return rejectWithValue(err.response.data);
        }
    }
);

export const rejectCancelPolicy = createAsyncThunk(
    "rejectCancelPolicy",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling cancel policy...")
            const response = await Axios.post(`${motorApiEndpoints.rejectCancelPolicy}`, payload);
            console.log("Cancel policy response is: ", response.data)

            return response.data;
        } catch (err) {
            toast.error("Error in rejecting cancel policy:", err);
            return rejectWithValue(err.response.data);
        }
    }
);
export const getQuoteWithRefund = createAsyncThunk(
    "getQuoteWithRefund",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling getQuoteWithRefund with payload: ", payload)
            const response = await Axios.post(`${motorApiEndpoints.getQuoteWithRefund}`, payload);
            console.log("getQuoteWithRefund response is: ", response.data)

            return response.data;
        } catch (err) {
            toast.error("Error in getting quote to change plan:", err);
            return rejectWithValue(err.response.data);
        }
    }
);


export const cancellationSlice = createSlice({
  name: "cancellationSlice",
  initialState,
  reducers: {
    clearCancellationError: (state) => {
      state.error = null;
    },
    clearCancellationState: (state) => initialState
  },
  extraReducers: (builder) => {
    // Cancel Active Policy (Make it cancellation in progress) API
    builder.addCase(cancelPolicy.pending, (state, action) => {
      if (!state.data) {
        state.isCancellationLoading = true;
      }
    });
    builder.addCase(cancelPolicy.fulfilled, (state, action) => {
      state.isCancellationLoading = false;
      state.success = true;
      state.error = null;
      state.CancellationResponse = action?.payload;
    });
    builder.addCase(cancelPolicy.rejected, (state, action) => {
      state.isCancellationLoading = false;
      state.error = action.payload?.ErrorMessageEn;
      state.success = false;
      console.log("Get quote error: ", action.payload.ErrorMessageEn )
      toast.error("Get quote errors: " + action.payload.ErrorMessageEn);
    });
    // Get quote with refund API
    builder.addCase(getQuoteWithRefund.pending, (state, action) => {
      console.log('cover prices is pending....');
      if (!state.data) {
        state.isQuoteWithRefundLoading = true;
      }
    });
    builder.addCase(getQuoteWithRefund.fulfilled, (state, action) => {
      state.isQuoteWithRefundLoading = false;
      state.success = true;
      state.error = null;
      state.QuoteWithRefundDetails = action?.payload;
      state.NewQuoteNumber = action?.payload.QuoteNumber;
    });
    builder.addCase(getQuoteWithRefund.rejected, (state, action) => {
      state.isQuoteWithRefundLoading = false;
      state.success = false;
      state.error = action.error?.message;
    });
     // Reject Cancellation API
     builder.addCase(rejectCancelPolicy.pending, (state, action) => {
        if (!state.data) {
          state.isCancellationLoading = true;
        }
      });
      builder.addCase(rejectCancelPolicy.fulfilled, (state, action) => {
        state.isCancellationLoading = false;
        state.success = true;
        state.error = null;
        state.CancellationResponse = action?.payload;
      });
      builder.addCase(rejectCancelPolicy.rejected, (state, action) => {
        state.isCancellationLoading = false;
        state.error = action.payload?.ErrorMessageEn;
        state.success = false;
        console.log("Get quote error: ", action.payload.ErrorMessageEn )
        toast.error("Get quote errors: " + action.payload.ErrorMessageEn);
      });
  },
});

// SELECTORS
export const getQuoteWithRefundResponse = (store) => store?.cancellationApi.QuoteWithRefundDetails;
export const getCancellationResponse = (store) => store?.cancellationApi.CancellationResponse;

export const CurrentPolicyNumberResponse = (store) => store?.cancellationApi.CurrentPolicyNumber;
export const NewQuoteNumberResponse = (store) => store?.cancellationApi.NewQuoteNumber;

export const getCancelLoading = (store) => store?.cancellationApi.isCancellationLoading;
export const getQuoteWithRefundLoading = (store) => store?.cancellationApi.isQuoteWithRefundLoading;

export const getQuoteWithRefundError = (store) => store?.cancellationApi.error;
export const getCancellationError = (store) => store?.cancellationApi.error;
export const getCancellationSuccess = (store) => store?.cancellationApi.success;


// ACTIONS TO DISPATCH
export const { clearCancellationError, clearCancellationState } = cancellationSlice.actions;

export default cancellationSlice.reducer;
