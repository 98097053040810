import React, { useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CHANGE_PAYMENT_CYCLE, CHANGE_PAYMENT_METHOD, CHANGE_TO_INVESTMENT_STRATEGY, DECREASE_INCRESE_PREMIUM, FUND_MANAGEMENT, MANAGE_BENEFICIARY, manageMyPolicy, STATUS_APPROVED, STATUS_REJECTED, STATUS_WAITING_FOR_APPROVAL, SURRENDER_POLICY, UPDATE_CARD_DETAILS, WITHDRAWL } from "./data";
import PerformanceCard from "../../../../components/common/card/performanceCard";
import ChangePaymentMethodModal from "./changePaymentMethod/changePaymentMethodModal";
import UpdatePaymentMethodModal from "./updatePaymentMethod/updatePaymentMethod";
import DecOrIncPremium from "./decOrIncPremium/decOrIncPremium";
import ChangePaymentCycle from "./changePaymentCycle/changePaymentCycle";
import { decodeQueryString } from "../../../../utils";
import { findPlanType, SAVING_PROTECTION } from "../data";

const ManagePolicyTab = ({ info}) => {
  let status = {
    // [CHANGE_PAYMENT_CYCLE]: STATUS_WAITING_FOR_APPROVAL,
    // [DECREASE_INCRESE_PREMIUM]: STATUS_APPROVED,
    // [CHANGE_PAYMENT_METHOD]: STATUS_REJECTED
  }

  const { id  } = useParams();
  const navigate = useNavigate();
  const params = decodeQueryString();
  const {search} = useLocation()
  console.log(search)
  
  const cardClickHandler = (card) => {
    if (card?.type == CHANGE_PAYMENT_METHOD) {
      setShowChangePayment(true)
    }
    else if (card.type == UPDATE_CARD_DETAILS) {
      setShowUpdateCard(true)
    }
    else if (card.type == DECREASE_INCRESE_PREMIUM) {
      setShowDecIncPremiumPopup(true)
    }
    else if (card.type == CHANGE_PAYMENT_CYCLE) {
      setShowChangePaymentCycle(true)
    }
    else if (card.type == WITHDRAWL) {
      navigate(`/life-insurance/withdraw${search}`)
    }
    else if (card.type == SURRENDER_POLICY) {
      navigate(`/life-insurance/surrender-policy${search}`)
    }
    else if (card.type == MANAGE_BENEFICIARY) {
      navigate(`/life-insurance/manage-beneficiaries${search}`)
    } else if (card.type == FUND_MANAGEMENT) {
      navigate(`/life-insurance/fund-management${search}`);
    } else if (card.type == CHANGE_TO_INVESTMENT_STRATEGY) {
      navigate(`/life-insurance/investement-strategy-change${search}`);

    }
  };
  const [showChangePayment, setShowChangePayment] = useState(false);
  const [showUpdateCard, setShowUpdateCard] = useState(false);
  const [showDecIncPremiumPopup,setShowDecIncPremiumPopup] = useState(false);
  const [showChangePaymentCycle,setShowChangePaymentCycle] = useState(false);
   const planType = useMemo(()=>{
     let productId = info?.PolicyDetails?.ProductCode
     const type = findPlanType(productId)
     return type || SAVING_PROTECTION
   },[info])
  return (
    <div className="max767:p-5">
      <ChangePaymentCycle show={showChangePaymentCycle} setShow={setShowChangePaymentCycle} />
      <ChangePaymentMethodModal show={showChangePayment} setShow={setShowChangePayment} />
      <UpdatePaymentMethodModal show={showUpdateCard} setShow={setShowUpdateCard} />
      <DecOrIncPremium show={showDecIncPremiumPopup} setShow={setShowDecIncPremiumPopup} />
      <div className="lifeinsurenrce-cards managemypolicy flex items-start content-start gap-4 self-stretch flex-wrap justify-start">
        {manageMyPolicy.cards.map((card, index) => {
          
        if(!card.showPlanType?.includes(planType)) return null

         return <PerformanceCard
            status={status[card.type]}
            onClick={cardClickHandler}
            key={index}
            cardData={card}
            className="bg-[#F6F8F9] rounded-[10px] border-2 border-solid border-transparent manage-policy-box flex items-center gap-4 p-6 hover:rounded-[10px] hover:border-2 hover:border-[#00719A] hover:bg-[#F6F8F9] hover:[box-shadow:0px_0px_1px_0px_rgba(26,_32,_36,_0.32),_0px_8px_16px_0px_rgba(91,_104,_113,_0.24)] justify-between w-[560px] max-w-[100%] max767:p-2 "
          />
})}
      </div>
    </div>
  );
};

export default ManagePolicyTab;
