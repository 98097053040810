import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import {  accountApiEndpoints } from "../../../api";

const initialState = {
//   pinId: [],
//   verified: [],
  isLoading: false,
  success: null,
  error: null,
};


export const sendToken = createAsyncThunk(
    "sendToken",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling sendToken...")
            const response = await Axios.post(`${accountApiEndpoints.sendToken}`, payload);
            console.log("sendToken response is: ", response.data)

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const verifyToken = createAsyncThunk(
    "verifyToken",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling verifyToken with payload: ", payload)
            const response = await Axios.post(`${accountApiEndpoints.verifyToken}`, payload);
            console.log("verifyToken response is: ", response.data)

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const accountOtpSlice = createSlice({
  name: "accountOtpSlice",
  initialState,
  reducers: {
    clearAccountOtpError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Send Token API
    builder.addCase(sendToken.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(sendToken.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = true;
      state.error = null;
    });
    builder.addCase(sendToken.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.ErrorMessageEn;
      state.success = false;
      console.log("Get quote error: ", action.payload.ErrorMessageEn )
    });
    // Verify Token API
    builder.addCase(verifyToken.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(verifyToken.fulfilled, (state, action) => {
      state.isLoading = false;
      state.success = true;
    });
    builder.addCase(verifyToken.rejected, (state, action) => {
      state.isLoading = false;
      state.success = false;
      state.error = action.error?.message;
    });
  },
});

// SELECTORS
export const getTokenOtpLoading = (store) => store?.accountOtp.isLoading;

// ACTIONS TO DISPATCH
export const { clearAccountOtpError } = accountOtpSlice.actions;

export default accountOtpSlice.reducer;
