import React, { useMemo, useState } from 'react'
import RenewMotorLayout from '../../../../motorInsurancePages/renewalMotorPolicy/renewMotorLayout'
import TitleDescription from '../../../../../components/newMotorPolicy/titleDescription/titleDescription';
import AlertComponent from '../../../../motorInsurancePages/transferPolicyFlow/alertComponent';
import Input from '../../../../../components/common/Input/Input';
import FileUpload from '../../../../../components/common/fileUploadInput/fileUploadInput';
import redflag from "../../../../../assets/images/bahrainsmlogo.svg"
import useValidation from '../../../../../hooks/useValidation';
import { WITHDRAWAL_VALIDATION_CONFIG } from './data';
import useLanguageTranslate from '../../../../../hooks/useLanguageTranslate';
import { useTranslation } from 'react-i18next';
import useLifePolicyDetails from '../../../../../hooks/life/useLifePolicyDetails';
import { currencyFormatterWithCountryCode, decodeQueryString } from '../../../../../utils';
import useCreateLifeRequests  from '../../../../../hooks/life/useCreateLifeRequests';
import useScreenResolution from '../../../../../hooks/useScreenResolution';


const ChangeExistingFunds = ({ switchToggle , formData,setFormData }) => {

    const { errors, validateFields } = useValidation();
    const translate = useLanguageTranslate()
    const { t } = useTranslation()
    const params = decodeQueryString()
    const { data, isLoading } = useLifePolicyDetails(params?.policy);
    const {mobile} = useScreenResolution()
 
    const validationCheck = (values) => {
        let isValid = validateFields(WITHDRAWAL_VALIDATION_CONFIG, values || formData);
        return isValid
    }
    const continueClickHanlder = async () => {
        let isValid = validationCheck();
        if (isValid) {          
            switchToggle();
        }
    };
    // const onFileUploaded = (value, name) => {
    //     let update = { ...formData, [name]: value };
    //     if (errors?.[name]) {
    //         validationCheck(update);
    //     }
    //     setFormData(update);
    // };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let update = { ...formData, [name]: value }
        if (errors?.[name]) {
            validationCheck(update);
        }
        setFormData(update);
    };
    const currentEncashment = useMemo(() => {
        let currentValue = data?.LifeDetails?.policyList?.[0]?.currentEncashmentValue;
        return currentValue?.length > 4 ? currentValue?.slice(4, currentValue?.length) : currentValue;
    }, [data])


    console.log(data,'some data')
    return (
      <RenewMotorLayout is_hide_back_btn btn_onClick={continueClickHanlder} show_close_btn={mobile}>
        <div className="changeplatenumber w-[500px] max-w-[100%] mx-auto bg-white max767:p-5">
          <TitleDescription
            title={t("lifeInsurance.withdrawal.changeExistingFunds.title")}
            description={t(
              "lifeInsurance.withdrawal.changeExistingFunds.description"
            )}
          />
          <AlertComponent
            title={translate(
              "Please note, this request will be submitted for approval before it takes effects",
              "يرجى ملاحظة أن هذا الطلب سيتم تقديمه للموافقة عليه قبل أن يصبح ساري المفعول"
            )}
          />
          <div className="currentEncashment mt-4">
            <p className="text-[#1A2024] text-base not-italic font-medium leading-[26px] tracking-[-0.16px]">
              {t(
                "lifeInsurance.withdrawal.changeExistingFunds.currentEnhanceLabel"
              )}
            </p>
            <div className="bg-[#F6F8F9] rounded-[10px] flex justify-between p-3 max767:h-[77px] max767:items-center h-[77px] items-center">
              <p className="font-extrabold">
                {currencyFormatterWithCountryCode(currentEncashment)}
              </p>
              <div className="flex  align-middle">
                <img src={redflag} alt="flag" />
                <p className="ml-2 text-black text-lg not-italic font-normal leading-[26px] tracking-[-0.18px]">
                  {translate("BHD", "دينار بحريني")}
                </p>
              </div>
            </div>
            <small className="text-[#48535B] text-xs not-italic font-normal leading-[26px] tracking-[-0.12px] mt-2 mb-4 flex">
              {t(
                "lifeInsurance.withdrawal.changeExistingFunds.currentEnhanceHintLabel"
              )}
            </small>
          </div>
          <div className="newprimium">
            <Input
              label={t(
                "lifeInsurance.withdrawal.changeExistingFunds.withdrawalAmountLabel"
              )}
              endAdornment={
                <div className="flex align-middle mt-1">
                  <img src={redflag} alt="flag" />
                  <p className="ml-2 text-black text-lg not-italic font-normal leading-[26px] tracking-[-0.18px]">
                    {translate("BHD", "دينار بحريني")}
                  </p>
                </div>
              }
              hint_msg={t(
                "lifeInsurance.withdrawal.changeExistingFunds.withdrawalAmountHintLabel"
              )}
              type="number"
              name="amount"
              setFormData={setFormData}
              value={formData.amount}
              handleChange={handleChange}
              error={errors.amount}
            />
          </div>
          <div className="IBAN mt-4 w-full">
            <Input
              label={t(
                "lifeInsurance.withdrawal.changeExistingFunds.ibanNumberLabel"
              )}
              name="iban_number"
              setFormData={setFormData}
              value={formData.iban_number}
              handleChange={handleChange}
              error={errors.iban_number}
            />
          </div>
          {/* <div className="flex flex-col ">
                    <p className='text-[#48535B] text-base not-italic font-normal leading-[26px] tracking-[-0.16px]'>
                    {t("lifeInsurance.withdrawal.changeExistingFunds.uploadPhotosOfCarLabel")}
                    </p>
                    <FileUpload
                        name="iban_certificate"
                        toggleFileUpload={onFileUploaded}
                    />
                    {errors?.iban_certificate && (
                        <p className="field-err text-red-500 text-sm mt-1 text-left">{errors?.iban_certificate}</p>
                    )}
                </div> */}
        </div>
      </RenewMotorLayout>
    );
}

export default ChangeExistingFunds