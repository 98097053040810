import React, { useMemo } from "react";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import useFileClaimFlow from "../../../hooks/useFileClaimFlow";
import FileClaimMotorLayout from "./fileClaimMotorLayout";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import description from "../../../../src/assets/images/description.svg";
import Exclamation from "../../../assets/images/Exclamation.svg";
import { useTranslation } from "react-i18next";
import { FILE_CLAIM_FLOW, REQUIRED_DOCS_INFO_DATA } from "./data";
import {
  BODILY_INJURY,
  PROPERTY_DAMAGE,
  VEHICLE_DAMAGE,
} from "./NonSolidarityUser/data";
function RequiredDocsInfoPage({ selectedInsuredType }) {
  const translate = useLanguageTranslate();
  const { t } = useTranslation();
  const { handleNextSubstep, currentStep, flowType } = useFileClaimFlow();

  const continueClickHanlder = () => {
    handleNextSubstep();
  };

  const { docs, alertMessage } = useMemo(() => {
    if (flowType == FILE_CLAIM_FLOW) {
      return REQUIRED_DOCS_INFO_DATA.solidarity_user;
    } else if (selectedInsuredType == VEHICLE_DAMAGE) {
      return REQUIRED_DOCS_INFO_DATA.non_solidarity_vehicle_damage;
    } else if (selectedInsuredType == PROPERTY_DAMAGE) {
      return REQUIRED_DOCS_INFO_DATA.non_solidarity_property_damage;
    } else if (selectedInsuredType == BODILY_INJURY) {
      return REQUIRED_DOCS_INFO_DATA.non_solidarity_bodily_injury;
    } else return {};
  }, [flowType]);

  console.log(selectedInsuredType);

  return (
    <FileClaimMotorLayout btn_onClick={continueClickHanlder}>
      <div className="flex flex-col items-center mx-auto w-[500px] max-w-[100%] max767:mt-8 PolicyTypePage max767:p-4">
        <TitleDescription
          title={t("motorInsurance.fileAClaimFlow.requiredDocsPage.title")}
          description={t(
            "motorInsurance.fileAClaimFlow.requiredDocsPage.description"
          )}
        />
        <div className="infoCard documents-section flex flex-col items-start gap-2 self-stretch bg-[#F6F8F9] p-4 rounded-2xl">
          <div className="flex align-middle gap-2">
            <h3 className="documents-title text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px] flex gap-2">
              <img src={description} alt="docs" />
              {translate("Documents", "المستندات")}
            </h3>
          </div>
          <ul className="documents-list pl-12 rtl:pr-12 max767:pl-[24px]">
            {docs?.map((doc, index) => (
              <li
                className=" gap-2 mb-2 inline-flexflex align-middle text-[#48535B] text-sm not-italic font-normal leading-5 tracking-[-0.14px]"
                key={index}
              >
                <span>{translate(doc.label_en, doc.label_ar)}</span>
              </li>
            ))}
          </ul>
        </div>
        {selectedInsuredType != PROPERTY_DAMAGE &&
          selectedInsuredType != BODILY_INJURY && (
            <div className="warning-section flex items-start gap-4 self-stretch border bg-[#FDE9D2] p-4 rounded-[10px] border-solid border-[#FBC98E] mt-4">
              <img
                className="warning-icon"
                src={Exclamation}
                alt="exclamation"
              />
              <p className="warning-text text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
                {translate(alertMessage.label_en, alertMessage.label_ar)}
              </p>
            </div>
          )}
      </div>
    </FileClaimMotorLayout>
  );
}

export default RequiredDocsInfoPage;
