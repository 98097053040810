import { LIFE_REQUEST_TYPE } from "../../../../../hooks/life/useCreateLifeRequests";


export const PAYMENT_CYCLES = [
    {
        label: "Monthly",
        ar_label: "شهريا",
        id: "Monthly",
        requestType: LIFE_REQUEST_TYPE.changePaymentCycleToMonthly
    },
    {
        label: "Quarterly",
        ar_label: "ربع سنوي ",
        id: "Quarterly",
        requestType: LIFE_REQUEST_TYPE.changePaymentCycleToQuarterly

    },
    {
        label: "Semi-Annual",
        ar_label: "نصف سنوي",
        id: "Semi-Annually",
        requestType: LIFE_REQUEST_TYPE.changePaymentCycleToSemiAnnual

    },
    {
        label: "Annual",
        ar_label: "سنوي",
        id: "Annually",
        requestType: LIFE_REQUEST_TYPE.changePaymentCycleToAnnual

    },
]

 export const PAYMENT_CYCLES_LABELS = [
    { label: "Monthly", label_ar: "شهريا" },
    { label: "Quarterly", label_ar: "ربع سنوي" },
    { label: "Semi-Annually", label_ar: "نصف سنوي" },
    { label: "Annually", label_ar: "سنوي" },
  ];