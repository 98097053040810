import React from "react";
import Button from "./Button/Button";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import BackdropModal from "./modal/backdropModal";

const IAgreePopup = ({ show, onAgree }) => {
  const translate = useLanguageTranslate();
  if (!show) return null;

  return (
    <div className="cpr-exists-modal">
      <BackdropModal
        show={show}
        title={translate("Client Consent", "ﺗﻔﻮﻳﺾ اﻟﻌﻤﻴﻞ")}
        hideClosebtn
      >
        <div className="cpr-exists-popup">
          {/* Scrollable Text Section */}
          <div
            className="scrollable-text bg-[#F8F9FA] p-4 rounded-lg mb-4"
            style={{ maxHeight: "200px", overflowY: "auto", direction: "ltr" }}
          >
            <p
              className="text-[#1A2024] text-sm not-italic font-normal leading-[22px] tracking-[-0.16px]"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {translate(
                `I, the undersigned, hereby understand that “Wathiq” is an Electronic Know-Your-Client (eKYC) platform developed by The BENEFIT Company (BENEFIT) designed to digitally authenticate, receive, and verify my identity and information from the Information & eGovernment Authority (IGA) and others (CBB licensees) periodically to Solidarity. 
I agree that “Wathiq” will collect the following information as per CBB requirements when I request an insurance service from Solidarity: 
1. Personal and ID Information 
2. Contact Information 
3. Account Details 
4. Employment Details 
5. Residency Information 
I am aware that my information will be retained by Solidarity for 10 years and might be shared with third parties within and outside Bahrain for the purposes of regulatory requirement, cloud storage, statistics, and other reasons in line with applicable laws and regulations. 
I have the right at any time to withdraw the consent provided. I understand that withdrawal of consent will be applicable to future use of the personal data and will not in any way impact legitimate use of the personal information prior to the withdrawal of the consent. 
For more details, please refer to the privacy policy available on 
`,
                `أنا، الموقع أدناه، على علم بأن "واثق" هو نظام اعرف عميلك الإلكتروني (eKYC) والذي تم تطويره من قبل شركة بنفت المصمم لمصادقة واستلام والتحقق من هويتي ومعلوماتي (قادمًا من هيئة المعلومات والحكومة الإلكترونية وجهات أخرى مرخصة من قبل مصرف البحرين المركزي) بشكل دوري لصالح سوليدرتي. 
أوافق أن "واثق" سيقوم بتجميع المعلومات التالية كما هو مطلوبات مصرف البحرين المركزي، وذلك عند طلبي لخدمة تأمين من سوليدرتي:
1. المعلومات الشخصية ومعلومات الهوية.
2. معلومات الاتصال.
3. معلومات الحساب.
4. بيانات العمل.
5. معلومات الإقامة.
أنا على علم بأن المعلومات المذكورة أعلاه سيتم الاحتفاظ بها لمدة 10 سنوات لدى سوليدرتي، وقد تتم مشاركتها مع أطراف أخرى داخل وخارج البحرين للأغراض التنظيمية، التخزين السحابي، والإحصائيات وغيرها من الأسباب المتوافقة مع القوانين والتشريعات. 
لدي الحق في أي وقت بسحب الموافقة المقدمة. أفهم أن سحب الموافقة سيكون له تأثير على الاستعمال المستقبلي لمعلوماتي الشخصية، ولن يؤثر بأي شكل من الأشكال على الاستعمال المشروع للمعلومات الشخصية قبل سحب الموافقة. 
للمزيد من التفاصيل، يرجى مراجعة سياسة الخصوصية المتاحة على 
`
              )}
              <a
                href="https://www.benefit.bh/privacypolicy"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 underline"
              >
                {translate(
                  `https://www.benefit.bh/privacypolicy`,
                  `https://www.benefit.bh/privacypolicy`
                )}
              </a>
            </p>
            <p
              className="text-[#1A2024] text-sm not-italic font-normal leading-[22px] tracking-[-0.16px]"
              style={{ whiteSpace: "pre-wrap" }}
            >
              {translate(
                `I confirm with my full legal capacity that I have read the above and understood the purposes in which Solidarity collects and retains my information and provide consent to “Wathiq” to process and transfer this information for eKYC purposes.`,
                `أؤكد بكامل أهليتي القانونية بأنني قرأت النص أعلاه وفهمت تمامًا الأسباب التي يتم من أجلها جمع ومعالجة بياناتي.`
              )}
            </p>
          </div>

          {/* Existing Text */}
          <p className="text-[#1A2024] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] mb-4">
            {translate(
              `By signing up, I acknowledge that I have read, understood, and agree to the Terms and Conditions of The BENEFIT Company.`,
              `بالتسجيل، أقر بأنني قد قرأت وفهمت وأوافق على الشروط والأحكام الخاصة بشركة BENEFIT.`
            )}
          </p>

          <Button
            className="mb-0 stickybutton flex w-full flex-col items-center gap-2.5 shrink-0 p-4 bg-[#55C6EF] rounded-[10px] text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px] max767:w-full"
            onSubmit={onAgree}
            label={translate("I Agree", "أوافق")}
          />
        </div>
      </BackdropModal>
    </div>
  );
};

export default IAgreePopup;
