// src/DynamicTable.js
import React from "react";
import Button from "../Button/Button";
import checktablekicon from "../../../assets/images/done_outline.svg";
import useScreenResolution from "../../../hooks/useScreenResolution";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";

// Define initial data
const initialData = [
  { id: 1, column1: "Row 1 Col 1", column2: "Row 1 Col 2" },
  { id: 2, column1: "Row 2 Col 1", column2: "Row 2 Col 2" },
  { id: 3, column1: "Row 3 Col 1", column2: "Row 3 Col 2" },
  { id: 4, column1: "Row 4 Col 1", column2: "Row 4 Col 2" },
  { id: 5, column1: "Row 5 Col 1", column2: "Row 5 Col 2" },
];

const DynamicTable = ({ data }) => {
  const screenResolution = useScreenResolution();

  const translate=useLanguageTranslate()
  return (
    <div className="max767:p-5">
      <table className="min-w-full bg-white border border-gray-200 border-separate border-spacing-0 rounded-[10px] owner-table border-l-[#DDE2E4] border-l border-solid max767:border-0">
        {/* <thead>
          <tr className="w-full bg-gray-100 text-gray-700">
            <th className="py-2 px-4 border-b">Column 1</th>
            <th className="py-2 px-4 border-b">Column 2</th>
          </tr>
        </thead> */}
        <tbody>
          {data?.map((row) => (
            <tr key={row.id} className="">
              <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6 max-600:block max767:border-r-0 max767:border-0 max767:px-0 max767:text-xs max767:not-italic max767:font-medium max767:leading-6 rtl:border-r-0 rtl:border-l">
                {translate(row?.column1,row?.column1_ar)}
              </td>
              <td className="flex py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6 justify-between items-center  max767:border-r-0 max767:px-0 ">
                <span className="flex">{row.icon && !screenResolution.mobile && <img src={row.icon} alt={row.column2} className="mr-2"/>}{row?.column2}</span>
                {/* {row?.btn && (
                  <Button
                    label={translate(row?.btn?.btn_lable_en,row?.btn?.btn_lable_ar)}
                    className={
                      "text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] flex flex-col items-center gap-2.5 rounded border bg-[#F6F8F9] px-2 py-0 border-solid border-[#DDE2E4] "
                    }
                  />
                )} */}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DynamicTable;
