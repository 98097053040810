export const daysOptions = Array.from({ length: 31 }, (_, i) => ({
    value: i + 1,
    label: (i + 1).toString().padStart(2, '0'),
}));

export const monthsOptions = [
    { value: '01', label: 'January' },
    { value: '02', label: 'February' },
    { value: '03', label: 'March' },
    { value: '04', label: 'April' },
    { value: '05', label: 'May' },
    { value: '06', label: 'June' },
    { value: '07', label: 'July' },
    { value: '08', label: 'August' },
    { value: '09', label: 'September' },
    { value: '10', label: 'October' },
    { value: '11', label: 'November' },
    { value: '12', label: 'December' },
];

const currentYear = new Date().getFullYear();
export const yearsOptions = Array.from({ length: currentYear - 1900 + 1 }, (_, i) => ({
    value: currentYear - i,
    label: (currentYear - i).toString(),
}));


export const validationConfig = [
    {
      name: "fullName",
      validations: ["required"]
    },
    {
      name: "email",
      validations: ["required", "email"],
    },
    {
      name: "mobile",
      validations: ["length"],
      exact_length: 8,
    },
    {
      name: "day",
      validations: ["required"]
    },
    {
      name: "month",
      validations: ["required"]
    },
    {
      name: "year",
      validations: ["required"]
    },
  ]