import React from "react";
import { useTranslation } from "react-i18next";

const claimDetails = {
  claimType: "Motor Policy",
  policyPlan: "Individual Comprehensive",
  driverCprNumber: "832007943",
  vehicleOwnerCprNumber: "832007943",
  plateNumber: "S161014",
  emailAddress: "jawaed.sadeq@solidarity.com.bh",
  mobileNumber: "+97337796077",
  accidentDescription:
    "While I was driving through the intersection, the other driver carelessly ran a red light and collided with my vehicle. It was a frightening experience that could have been avoided if they had followed the traffic signals.",
  plannedGarageWorkshop: "Toyota Service Center",
  repairSurveyDate: "29/05/2024",
  claimResolution: "Repair my vehicle",
};

const ClaimDetailCard = () => {
  const { t } = useTranslation();

  return (
    <div className="flex-col items-start flex border px-6 py-4 rounded-[20px] border-solid border-[#EEF0F2] mb-6 ">
      <div className="space-y-4">
        <div className="">
          <h3 className="text-[#5B6871] text-xs not-italic font-medium leading-6 mb-1">{t("motorInsurance.trackClaimFlow.claimDetailCard.claimType")}</h3>
          <p className="text-[#1A2024] text-sm not-italic font-medium leading-6 mb-1 border-b-[#DDE2E4] border-b border-solid pb-2">{claimDetails?.claimType}</p>
        </div>
        <div>
          <h3 className="text-[#5B6871] text-xs not-italic font-medium leading-6 mb-1">{t("motorInsurance.trackClaimFlow.claimDetailCard.policyPlan")}</h3>
          <p className="text-[#1A2024] text-sm not-italic font-medium leading-6 mb-1 border-b-[#DDE2E4] border-b border-solid pb-2">{claimDetails?.policyPlan}</p>
        </div>
        <div>
          <h3 className="text-[#5B6871] text-xs not-italic font-medium leading-6 mb-1">{t("motorInsurance.trackClaimFlow.claimDetailCard.driverCprNumber")}</h3>
          <p className="text-[#1A2024] text-sm not-italic font-medium leading-6 mb-1 border-b-[#DDE2E4] border-b border-solid pb-2">{claimDetails?.driverCprNumber}</p>
        </div>
        <div>
          <h3 className="text-[#5B6871] text-xs not-italic font-medium leading-6 mb-1">{t("motorInsurance.trackClaimFlow.claimDetailCard.vehicleOwnerCprNumber")}</h3>
          <p className="text-[#1A2024] text-sm not-italic font-medium leading-6 mb-1 border-b-[#DDE2E4] border-b border-solid pb-2">{claimDetails?.vehicleOwnerCprNumber}</p>
        </div>
        <div>
          <h3 className="text-[#5B6871] text-xs not-italic font-medium leading-6 mb-1">{t("motorInsurance.trackClaimFlow.claimDetailCard.plateNumber")}</h3>
          <p className="text-[#1A2024] text-sm not-italic font-medium leading-6 mb-1 border-b-[#DDE2E4] border-b border-solid pb-2">{claimDetails?.plateNumber}</p>
        </div>
        <div>
          <h3 className="text-[#5B6871] text-xs not-italic font-medium leading-6 mb-1">{t("motorInsurance.trackClaimFlow.claimDetailCard.emailAddress")}</h3>
          <p className="text-[#1A2024] text-sm not-italic font-medium leading-6 mb-1 border-b-[#DDE2E4] border-b border-solid pb-2">{claimDetails?.emailAddress}</p>
        </div>
        <div>
          <h3 className="text-[#5B6871] text-xs not-italic font-medium leading-6 mb-1">{t("motorInsurance.trackClaimFlow.claimDetailCard.mobileNumber")}</h3>
          <p className="text-[#1A2024] text-sm not-italic font-medium leading-6 mb-1 border-b-[#DDE2E4] border-b border-solid pb-2">{claimDetails?.mobileNumber}</p>
        </div>
        <div>
          <h3 className="text-[#5B6871] text-xs not-italic font-medium leading-6 mb-1">{t("motorInsurance.trackClaimFlow.claimDetailCard.accidentDescription")}</h3>
          <p className="text-[#1A2024] text-sm not-italic font-medium leading-6 mb-1 border-b-[#DDE2E4] border-b border-solid pb-2">{claimDetails?.accidentDescription}</p>
        </div>
        <div>
          <h3 className="text-[#5B6871] text-xs not-italic font-medium leading-6 mb-1">{t("motorInsurance.trackClaimFlow.claimDetailCard.plannedGarageWorkshop")}</h3>
          <p className="text-[#1A2024] text-sm not-italic font-medium leading-6 mb-1 border-b-[#DDE2E4] border-b border-solid pb-2">{claimDetails?.plannedGarageWorkshop}</p>
        </div>
        <div>
          <h3 className="text-[#5B6871] text-xs not-italic font-medium leading-6 mb-1">{t("motorInsurance.trackClaimFlow.claimDetailCard.repairSurveyDate")}</h3>
          <p className="text-[#1A2024] text-sm not-italic font-medium leading-6 mb-1 border-b-[#DDE2E4] border-b border-solid pb-2">{claimDetails?.repairSurveyDate}</p>
        </div>
        <div>
          <h3 className="text-[#5B6871] text-xs not-italic font-medium leading-6 mb-1">{t("motorInsurance.trackClaimFlow.claimDetailCard.claimResolution")}</h3>
          <p className="text-[#1A2024] text-sm not-italic font-medium leading-6 mb-1 border-b-[#DDE2E4] border-b border-solid pb-2">{claimDetails?.claimResolution}</p>
        </div>
      </div>
    </div>
  );
};

export default ClaimDetailCard;
