import React from 'react'
import './Loader.css'

const SkeletonLoader = () => {
    return (
        <div className='policiesGrid flex w-full items-start content-start gap-4 flex-wrap'>
            <div className='policy_card_root cursor-pointer bg-[#F6F8F9] rounded-2xl w-[345px] h-[207px]'>
            </div>
            <div className='policy_card_root cursor-pointer bg-[#F6F8F9] rounded-2xl w-[345px] h-[207px]'>
            </div>
            <div className='policy_card_root cursor-pointer bg-[#F6F8F9] rounded-2xl w-[345px] h-[207px]'>
            </div>
        </div>
    )
}

export default SkeletonLoader