import React, { useEffect, useState } from "react";
import "./chevron.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const Chevron = ({
  children,
  className,
  Header,
  isDefaultOpen = false,
  alwaysKeepOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen || alwaysKeepOpen);

  const toggleState = () => {
    setIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    if (isDefaultOpen || alwaysKeepOpen) {
      setIsOpen(true);
    }
  }, [isDefaultOpen, alwaysKeepOpen]);

  return (
    <div className={`chevron relative ${className}`}>
      <div
        className="flex items-center pl-0 pr-4 pt-2 pb-[7px] ipad:pr-0 rtl:pr-0"
        onClick={() => (alwaysKeepOpen ? setIsOpen(true) : toggleState())}
      >
        {Header}
        <div className="toggleIcon">
          <FontAwesomeIcon
            icon={isOpen ? faChevronUp : faChevronDown}
            className="flex text-[#9AA6AC] text-xs mt-[-5px] mr-4 ipad:mr-0"
          />
        </div>
      </div>

      {isOpen && (
        <div className={`content ${isOpen ? "open" : ""}`}>{children}</div>
      )}
    </div>
  );
};

export default Chevron;
