import React from 'react'
import { Link } from 'react-router-dom'
import Otp from '../../../../components/common/Otp/Otp'
import { useTranslation } from 'react-i18next';
import useLanguageTranslate from '../../../../hooks/useLanguageTranslate';

const VerifyOtpComponent = ({otp,setOtp , notRecivedCodeHandler , mobile="+973 377967777", navigationPath, onChangeClick}) => {
    const { t } = useTranslation();
    const translate = useLanguageTranslate()

    return (
        <>
            <div className="flex align-middle">
                <p className="mb-2 w-full align-middle text-[#48535B] text-center text-base not-italic font-normal leading-[26px] tracking-[-0.16px] max767:text-left max767:mb-5">
                    {t("motorInsurance.renewalPolicyFlow.verifyOtpPage.sent_it_label")}
                    {mobile}{" "}
                    <Link
                        className=" mx-0 my-6 text-[#00384D] text-center text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline"
                        to={navigationPath}
                        onClick={onChangeClick}
                    >
                      {translate("Change","تغيير")}  
                    </Link>
                </p>
            </div>
            <div className="otpinput flex align-middle justify-center">
                <Otp value={otp} setValue={setOtp} />
            </div>
            <p
                onClick={notRecivedCodeHandler}
                className="w-full align-middle link-text mx-0 my-6 text-[#00384D] text-center text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline cursor-pointer"
            >
                {t(
                    "motorInsurance.renewalPolicyFlow.verifyOtpPage.didnt_recieve_label"
                )}
            </p>
        </>
    )
}

export default VerifyOtpComponent