import React from "react";
import { currencyFormatterWithCountryCode } from "../../../../../../utils";

const FundsToRemoveData = ({ fundsToRemove }) => {
  return (
    <>
      {fundsToRemove.map((each, i) => {
        let data = each?.original;
        return (
          <div className="ipad:p-4 max767:p-4 max767:pb-0 ipad:pb-0">
            <div
              key={i}
              className="selectfund flex items-start gap-6 self-stretch bg-[#F6F8F9] px-6 py-4 mx-0 my-6 rounded-[10px] mb-4 justify-between max767:flex-col max767:mb-0"
            >
              <div className="text-black text-sm not-italic font-normal leading-[150%]">
                {data?.fund_name}
              </div>
              <div className="flex gap-2">
                <div className="text-[#1A2024] text-sm not-italic font-semibold">
                  {data?.allocation} %
                </div>
                <div className="text-[#1A2024] text-sm not-italic font-semibold">
                  {data?.no_of_unit} Units
                </div>
                <div className="text-[#1A2024] text-sm not-italic font-semibold">
                  {currencyFormatterWithCountryCode(data?.fund_value)}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default FundsToRemoveData;
