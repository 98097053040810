import React, { useState } from 'react'
import Axios from '../../redux/axiosInstance'
import { expressShieldEndPoints } from '../../api'
import { toast } from 'react-toastify'
import useLanguageTranslate from '../useLanguageTranslate'

const usePaymentApi = () => {
    const [isLoading, setIsLoading] = useState(false)
    const translate = useLanguageTranslate()
    const generatePaymentLink = async (payload = {}) => {
        setIsLoading(true)
        let apiPayload = {
            Channel: "online.solidarity",
            ...payload
        }
        try {
            setIsLoading(true)
            let response = await Axios.post(expressShieldEndPoints.payment, apiPayload);
            return response?.data
        }
        catch(e) {
            let response = e.response.data
            console.log(response,'some response')
            toast.error(translate(response?.ErrorMessageEn||"Failed to generate payment link", response?.ErrorMessageAR||"فشل في إنشاء رابط الدفع"))
        }
        finally { 
            setIsLoading(false)
        }
        
    }

return {
    isLoading,
    generatePaymentLink
}
}

export default usePaymentApi