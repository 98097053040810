import React, { useState } from "react";
import ToggleSwitch from "../../components/common/toggleSwitch/toggleSwitch";
import MorePageLayout from "./morePageLayout";
import Notificationicon from "../../assets/images/notificationsb.svg"
import email from "../../assets/images/mark_email_unread.svg"
import sms from "../../assets/images/sms.svg"
import phone from "../../assets/images/phone_iphone.svg"
import useLanguageTranslate from "../../hooks/useLanguageTranslate";

const MobileNotificationScreen = () => {
  const [formState, setFormState] = useState({
    options: [
      {
        name: "Allow Notifications",
        name_ar: "السماح بالإشعارات",
        key: "allowNotifications",
        included: true,
        disabled: false,
      },
      {
        name: "Email",
        name_ar: "البريد الإلكتروني",
        key: "email",
        included: true,
        disabled: false,
        icon: email,
      },
      {
        name: "SMS",
        name_ar: "رسائل نصية",
        key: "sms",
        included: true,
        disabled: false,
        icon: sms,
      },
      {
        name: "Phone",
        name_ar: "هاتف",
        key: "phone",
        included: true,
        disabled: false,
        icon: phone,
      },
    ],
  });
  const translate = useLanguageTranslate();
  // Function to update the option values
  const updateOptionValues = (key, updates) => {
    const updatedOptions = formState.options.map((option) => {
      if (option.key ==key) {
        return { ...option, ...updates };
      }
      return option;
    });

    setFormState({ options: updatedOptions });
  };

  return (
    <MorePageLayout>
      <div className="mt-20 p-5 w-full h-screen bg-white">
        <div className="mb-6">
          <h2 className="text-[#1A2024] text-left text-lg not-italic font-semibold leading-6 tracking-[-0.18px] mt-4 mb-2">
            {translate("Notifications", "الإشعارات")}
          </h2>
          <div className="flex items-center justify-between py-2 mb-6">
            <div className="flex items-center pr-[10px] rtl:pr-[0px] rtl:pl-[10px]">
              <span className="flex items-start bg-[#E5E9EB] p-3 rounded-3xl mr-4 rtl:ml-4 rtl:mr-0">
                <img src={Notificationicon} alt="" />{" "}
              </span>
              <span className="text-black text-base not-italic font-medium leading-6 tracking-[-0.16px]">
                {translate(
                  formState.options[0].name,
                  formState.options[0].name_ar
                )}
              </span>
            </div>
            <ToggleSwitch
              isOn={formState.options[0].included}
              onChange={() =>
                updateOptionValues("allowNotifications", {
                  included: !formState.options[0].included,
                })
              }
              disabled={formState.options[0].disabled}
            />
          </div>
        </div>

        {/* <div className="flex-col mt-5">
          <h2 className="text-[#1A2024] text-left text-lg not-italic font-semibold leading-6 tracking-[-0.18px] mb-2">Offers and updates</h2>
          <p className="text-sm not-italic font-normal text-[#5B6871] mb-4">
            Stay up to date on the latest news from Solidarity
          </p>

          {formState.options.slice(1).map((option, idx) => (
            <div
              key={idx}
              className="flex items-center justify-between py-2 "
            >
              <div className="flex items-center">
              <span className="flex items-start bg-[#E5E9EB] p-3 rounded-3xl mr-4"><img src={option.icon} alt=""/> </span>
              <span>{option.name}</span>
              </div>
              <ToggleSwitch
                isOn={option.included}
                onChange={() =>
                  updateOptionValues(option.key, {
                    included: !option.included,
                  })
                }
                disabled={option.disabled}
              />
            </div>
          ))}
        </div> */}
      </div>
    </MorePageLayout>
  );
};

export default MobileNotificationScreen;
