import React from 'react';
import './toggleSwitch.css'

const ToggleSwitch = ({ className,isOn,onChange,disabled, onClassName = "bg-[#00A9E7]", offClassName = "bg-[#B0BABF]" }) => {

    return (
      <div
        className={`relative inline-flex items-center cursor-pointer mr-[17px] ${className} disabled:bg-[#B0BABF] disabled:cursor-not-allowed`}
        onClick={disabled?()=>{}:onChange}
      >
        <span
          className={`absolute rounded-full transition-colors w-8 h-5 ${
            isOn ? onClassName : offClassName
          }`}
        ></span>
        <span
          className={`block w-3.5 h-3.5 -ml-px -mt-px shrink-0 rounded-full bg-white shadow-md transform transition-transform duration-300 ease-in-out rtl:mr-[17px] ${
            isOn ? "translate-x-4" : "translate-x-0 ml-[3px]"
          }`}
        ></span>
      </div>
    );
};

export default ToggleSwitch;
