import React from "react";
import Button from "../../../../components/common/Button/Button";
import DownloadBtn from "../../../../components/common/downloadBtn/downloadBtn";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import flexiicon from "../../../../assets/images/Flexi.svg";
import greendonecheck from "../../../../assets/images/greendone.svg";
import redcross from "../../../../assets/images/redclose.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CoversAndBenefitTab = ({ data }) => {
  const translate = useLanguageTranslate();
  const navigate = useNavigate()
  const {id} = useParams();
  const navigateToChangePlan = () =>{
    navigate(`/motor-insurance/change-plan/${id}`)
  }
  const {t}=useTranslation()
  return (
    <div className="bg-white rounded-lg p-6 w-5/6 border bg-white rounded-[10px] border-solid border-[#DDE2E4] ipad:w-full max-600:w-full max767:border-0 max767:p-5">
      {/* Flexi Plan Title */}
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center space-x-2">
          <div className="">
            <img src={flexiicon} className="mr-2" alt="Flexi plan" />
          </div>
          <h2 className="text-[#48535B] text-[32px] not-italic font-semibold leading-[120%] max767:text-[24px]">
          {t("motorInsurance.covergeAndBenefit.flexi")}
          </h2>
        </div>
        <Button
          onSubmit={navigateToChangePlan}
          label={t("motorInsurance.covergeAndBenefit.change_plan")}
          className="change_plan flex flex-col items-center gap-2.5 border bg-white text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] px-4 py-2 rounded-md border-solid border-[#DDE2E4]"
        />
      </div>

      <div className="flex gap-4 max-600:block">
        <div className="includeSection w-3/5 max-600:w-full ipad:w-[50%]">
          <h3 className="text-[#1A2024] text-lg not-italic font-medium leading-[normal] mb-4">
          {t("motorInsurance.covergeAndBenefit.benefit")}
          </h3>
          <ul className="space-y-2 mb-6">
            {data?.includeBenefits?.map((benefit) => (
              <li className="flex items-center text-black text-sm not-italic font-normal leading-[150%]">
                <img
                  src={greendonecheck}
                  className="mr-2"
                  alt="green check mark"
                />
                {translate(benefit.description,benefit.description_ar)}
              </li>
            ))}

            {/* Add more benefits here */}
          </ul>
        </div>

        {data?.downloadCard && (
          <div className="w-2/5 p-4 items-center max-600:w-full max-600:p-0 max-600:mb-4 ipad:p-0 ipad:w-[50%] max767:hidden pr-0">
            <div className="flex flex-col justify-center items-start gap-2 self-stretch bg-[#F6F8F9] p-6 rounded-[10px]">
              <div className="flex flex-col">
                <div className="text-[#1A2024] text-sm not-italic font-medium leading-[normal] mb-1">
                  {translate(
                    data?.downloadCard?.label_en,
                    data?.downloadCard?.label_ar
                  )}
                </div>
              </div>
              {data?.downloadCard?.download_btn && (
                <DownloadBtn
                  filesData={data?.downloadCard.filesData}
                  className={"w-full"}
                />
              )}
            </div>
          </div>
        )}
      </div>

      <div className="optionalCoverages flex justify-between items-start gap-4 bg-[#F6F8F9] p-6 rounded-[10px]">
        <div className="coverages w-full">
        <div className="flex justify-between max767:flex-col">
          <div className="">
          <h3 className="text-[#1A2024] text-lg not-italic font-medium leading-[normal]">
          {t("motorInsurance.covergeAndBenefit.optional_coverage")}
          </h3>
          <p className=" text-[#5B6871] text-sm not-italic font-normal leading-[normal] mb-4 mt-2">
          {t("motorInsurance.covergeAndBenefit.get_added")}
          </p>
          </div>
          <div className="max767:mb-3">
          <button type="button" className=" text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] flex items-center gap-2.5 border bg-white px-4 py-2 rounded-md border-solid border-[#DDE2E4]">{translate("Add Coverage Options","إضافة تغطيات اختيارية")}</button>
          </div>
          </div>
         
         
          <ul className="space-y-2">
            {data?.optionalCoverage?.map((coverage) => (
              <li className="flex items-center text-black text-sm not-italic font-normal leading-[150%]">
                <img src={redcross} className="mr-2" alt="red close" />
                {translate(coverage.description,coverage.description_ar)}
              </li>
            ))}

            {/* Add more optional coverages here */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default CoversAndBenefitTab;
