import React, {  } from "react";

import ProfileDetails from "../../pages/profileSettings/profileDetails";
import MorePageLayout from "./morePageLayout";

const MobileProfilePage = () => {
  return (
    <MorePageLayout is_hide_back_btn show_close_btn>
      <ProfileDetails />
    </MorePageLayout>
  );
};

export default MobileProfilePage;
