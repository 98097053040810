import React from "react";
import backarrow from "../../../assets/images/back-arrow-left.svg";
import closeicon from "../../../assets/images/close.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { screenResolution } from "../../../utils/screenResolution";
import BottomStickyBtnLayout from "../newMotorPolicy/Layouts/BottomStickyBtnLayout";
import useRenewalFlow from "../../../hooks/useRenewalFlow";
import useFundsSwitchingFlow from "../../../hooks/fundsSwitchingFlow";
import useScreenResolution from "../../../hooks/useScreenResolution";
import { claimPaths, emailOrMobileChangePaths, lifeInsuranceOverviewPaths, motorInsurancePaths, navigateToHomePaths, policyOverviewPaths } from "../../../components/common/Header/data";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";

const RENEW_POLICY_LOGGED_OUT = "RENEW_POLICY_LOGGED_OUT"

const RenewMotorLayout = ({
  children,
  is_hide_back_btn,
  show_close_btn,
  btn_label,
  btn_onClick,
  btn_disabled,
  show_summary,
  is_bottom_sticky_layout = true,
  backClickHandler,
  tempPrice,
  tempIncluded,
  tempCarReplacement,
  AdditionalComponent,
  hideContinueBtn,
}) => {
  const navigate = useNavigate();
  const { mobile } = useScreenResolution();

  const { handlePrevSubstep, handlePrevStep } = useRenewalFlow();
  const translate = useLanguageTranslate();
  const { pathname, search } = useLocation();
  const optionalcloseClickHanlder = () => {
    if (pathname?.includes("/new-motor-policy")) {
      navigate("/new-policy");
    } else if (navigateToHomePaths.some((path) => pathname?.includes(path))) {
      navigate("/");
    } else if (
      emailOrMobileChangePaths.some((path) => pathname?.includes(path))
    ) {
      if (mobile) {
        navigate("/profile-details");
      } else {
        navigate("/profile-settings");
      }
    } else if (policyOverviewPaths.some((path) => pathname?.includes(path))) {
      //navigate(`/motor-insurance/policy-overview/${policy_id}`);
      navigate(-1);
    } else if (claimPaths.some((path) => pathname?.includes(path))) {
      navigate("/file-a-claim");
    }
    else if (lifeInsuranceOverviewPaths.some((path) => pathname?.includes(path))) {
      navigate(`/life-insurance/policy-overview${search}&tabType=ManagePolicy`);
    }
    else  if (motorInsurancePaths.some((path) => pathname?.includes(path))) {
      // navigate("/motor-insurance/file-a-claim");
      navigate(-1);
    }
    else {
      navigate(-1)
    }
  };

  return (
    <div className="">
      {!is_hide_back_btn && (
        <button
          className="backButton bg-navy-blue py-2 rounded mt-20 flex absolute left-[26px] top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:bg-[#F6F8F9] max767:w-10 max767:h-10 max767:text-center max767:justify-center max767:rounded-3xl max767:mt-12 max767:ml-[-11px] rtl:right-[26px]"
          onClick={() => {
            console.log("backclickhandler===", typeof backClickHandler);
            if (typeof backClickHandler =="function") {
              backClickHandler();
            } else if (backClickHandler =="loggedOut") {
              // window.location.reload();
              handlePrevStep();
            } else {
              handlePrevSubstep();
            }
          }}
        >
          <img src={backarrow} alt="Back arrow" className="1 rtl:rotate-180" />
          <span className="back-text inline max767:hidden">
            {translate("Back", "عودة")}
          </span>
        </button>
      )}
      {show_close_btn && mobile && (
        <button
          className="bpy-2 rounded mt-4 flex absolute top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] "
          onClick={optionalcloseClickHanlder}
        >
          <img src={closeicon} alt="Close" />
        </button>
      )}
      {is_bottom_sticky_layout ? (
        <BottomStickyBtnLayout
          show_summary={show_summary}
          btn_onClick={btn_onClick}
          btn_disabled={btn_disabled}
          btn_label={btn_label}
          price={tempPrice}
          included={tempIncluded}
          carReplacement={tempCarReplacement}
          AdditionalComponent={AdditionalComponent}
          hideContinueBtn={hideContinueBtn}
          passedFrom={"MotorRenewal"}
        >
          {children}
        </BottomStickyBtnLayout>
      ) : (
        children
      )}
    </div>
  );
};

export default RenewMotorLayout;
