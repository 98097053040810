import React, { useEffect, useState } from "react";
import backarrow from "../../../src/assets/images/back-arrow-left.svg";
import daterange from "../../../src/assets/images/date_range.svg";
import forwadarrow from "../../../src/assets/images/arrow_forward.svg";
import { useNavigate } from "react-router-dom";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import { useTranslation } from "react-i18next";
import useScreenResolution from "../../hooks/useScreenResolution";
import BackdropModal from "../common/modal/backdropModal";
import EditableField from "./editableField";
import PopoverComponent from "../../components/common/popover/PopoverComponent";
import ellipsis from "../../../src/assets/images/eclips.svg";
import editicon from "../../../src/assets/images/editicon.svg";
import downloadicon from "../../../src/assets/images/downloadblack.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  policyView,
  PolicyViewResponse,
} from "../../redux/features/motor/getQuoteSlice";
import {
  getPolicyViewHeaderResponse,
  getPolicyViewLoading,
} from "../../redux/features/policyDetailsSlice";
import { Loader } from "storybook/internal/components";
const menuData = [
  {
    icon: editicon,
    text_en: "Edit plan Name",
    text_ar: "تحرير اسم الوثيقة",
    type: "edit",
  },
  {
    icon: downloadicon,
    text_en: "Download Documents",
    text_ar: "تنزيل المستندات",
    type: "download",
  },
];

const MotorPolicyCard = ({
  content,
  handleDownloadMenuClicked,
  car,
  getPolicyDetails,
}) => {
  const translate = useLanguageTranslate();

  console.log("#####################", content, car, getPolicyDetails);

  // const dispatch = useDispatch();
  // const PolicyViewReq = {
  //   PolicyNumber: "P/003/14/20/8003/0020575-02",
  //   Channel: "online.solidarity"
  // }
  // const PolicyViewresDetails = useSelector(PolicyViewResponse);
  // console.log("CHeck: " + PolicyViewresDetails["PolicyHeader"]["Make"]);
  const [isEditing, setIsEditing] = useState(false);
  const [isMobileEditing, setIsMobileEditing] = useState(false);
  const [showEllipsisMenu, setShowEllipsisMenu] = useState(false);
  const screenResolution = useScreenResolution();
  const policyDetails = useSelector(getPolicyViewHeaderResponse);
  const policyViewLoading = useSelector(getPolicyViewLoading);
  const isRenewable = policyDetails?.IsRenewable;
  const policyInfo = getPolicyDetails
    ? `${policyDetails?.Make || ""} ${getPolicyDetails.Model || ""} - ${
        policyDetails?.RegistrationNumber
      }`
    : translate("No Vehicle Details Available", "لا توجد تفاصيل عن المركبة");
  const [policyLabel, setPolicyLabel] = useState(policyInfo);

  //   useEffect(() => {
  //     dispatch(policyView(PolicyViewReq));
  //   }, [dispatch]);

  //  useEffect(() => {
  //   if (PolicyViewresDetails && PolicyViewresDetails.PolicyHeader) {
  //     console.log("CHeck: " + PolicyViewresDetails["PolicyHeader"]["Make"]);
  //     setPolicyLabel(PolicyViewresDetails["PolicyHeader"]["Make"]);
  //   }
  // }, [PolicyViewresDetails]);

  useEffect(() => {
    if (policyInfo || policyInfo) {
      const translatedLabel = translate(policyInfo, policyInfo);
      setPolicyLabel(translatedLabel);
    }
  }, [content]);

  useEffect(() => {
    const updatedLabel = car
      ? `${policyDetails?.Make || ""} ${policyDetails?.Model || ""} - ${
          policyDetails?.RegistrationNumber
        }`
      : "No Vehicle Details Available";
    setPolicyLabel(updatedLabel);
  }, [car]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
  };
  const handleMobileSaveClick = () => {
    toggleMobileEditing();
  };

  const navigate = useNavigate();
  const backClickHandler = () => {
    navigate(-1);
  };

  const { t } = useTranslation();

  const toggleEllipsisMenu = () => {
    setShowEllipsisMenu(!showEllipsisMenu);
  };
  const toggleMobileEditing = () => {
    setIsMobileEditing(!isMobileEditing);
  };

  const handleMenuClick = (type) => {
    if (type == "edit") {
      setIsMobileEditing(true);
    } else if (type == "download") {
      handleDownloadMenuClicked(true);
    }
    setShowEllipsisMenu(false);
  };

  const mobileEditableField = (
    <>
      <div className="editmodal">
        <EditableField
          value={
            policyViewLoading
              ? translate(
                  "Getting vehicle details ...",
                  "جاري استرجاع تفاصيل المركبة ..."
                )
              : policyLabel
          }
          isEditing={false}
          isMobile={screenResolution.mobile} // Pass isMobile prop
        />
        <div className="editpolicymodal">
          <BackdropModal
            show={isMobileEditing}
            onClose={toggleMobileEditing}
            title={translate("Edit plan Name", "تحرير اسم الوثيقة")}
          >
            <EditableField
              value={
                policyViewLoading
                  ? translate(
                      "Getting vehicle details ...",
                      "جاري استرجاع تفاصيل المركبة ..."
                    )
                  : policyLabel
              }
              onChange={(e) => setPolicyLabel(e.target.value)}
              isEditing={isMobileEditing}
              onSave={handleMobileSaveClick}
              isMobile={screenResolution.mobile}
            />
          </BackdropModal>
        </div>
      </div>
    </>
  );

  return (
    <>
      <div className="visible flex   md:hidden   items-start mb-4 max767:flex max767:w-full max767:flex-row ipad:w-[55%] max767:sticky max767:top-0 max767:mt-0 max767:bg-[#EDFAFF] max767:p-4 stickytitle max767:items-center max767:mb-0 max767:z-10">
        <button
          onClick={backClickHandler}
          className="flex text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:bg-[#F6F8F9] max767:p-2 max767:rounded-3xl"
        >
          <img
            src={backarrow}
            alt="back arrow"
            className="rtl:rotate-180 rtl:mr-1"
          />
          <span className="max767:hidden rtl:mr-1">
            {" "}
            {t("motorInsurance.policyOverview.back")}
          </span>
        </button>
        <div className="flex flex-col items-start mb-4 max-600:block max-600:w-full max767:mb-0">
          {screenResolution.mobile ? (
            mobileEditableField
          ) : (
            <EditableField
              value={
                policyViewLoading
                  ? translate(
                      "Getting vehicle details ...",
                      "جاري استرجاع تفاصيل المركبة ..."
                    )
                  : policyLabel
              }
              onChange={(e) => setPolicyLabel(e.target.value)}
              isEditing={isEditing}
              onSave={handleSaveClick}
              onEdit={handleEditClick}
              editIcon={editicon}
              isMobile={screenResolution.mobile}
            />
          )}
        </div>
        {content?.btn && isRenewable && !policyViewLoading && (
          <button
            className="flex items-center gap-2 bg-[#55C6EF] px-3 py-1 rounded-md text-[#1A2024] text-sm not-italic font-normal leading-6  max767:hidden max767:mt-4 max767:w-40"
            onClick={() => navigate("/motor-insurance/renew-motor-policy")} // Add this line
          >
            {content?.btn?.icon && (
              <img src={content?.btn?.icon} alt="" className="h-6 w-6" />
            )}
            <span>
              {translate(
                content?.btn?.btn_lable_en,
                content?.btn?.btn_lable_ar
              )}
            </span>
          </button>
        )}

        {screenResolution.mobile && (
          <PopoverComponent
            headerMenu={
              <button
                onClick={toggleEllipsisMenu}
                className="flex text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
              >
                <img alt="ellipsis" src={ellipsis} />
              </button>
            }
            positions={["bottom"]}
            isPopoverOpen={showEllipsisMenu}
            closer={toggleEllipsisMenu}
            popupContent={
              <div className="flex w-[228px] flex-col items-start bg-white shadow-[0px_0px_32px_0px_rgba(0,0,0,0.20)] rounded-xl">
                {menuData.map((data) => (
                  <button
                    key={data.type}
                    className=" flex h-11 justify-between items-center self-stretch px-4 py-0 border-b-[#DDE2E4] border-b border-solid"
                    onClick={() => handleMenuClick(data.type)}
                  >
                    {translate(data.text_en, data.text_ar)}
                    <img src={data.icon} alt="" />
                  </button>
                ))}
              </div>
            }
          />
        )}
      </div>
      <div className="p-6 bg-[#EDFAFF] rounded-2xl flex justify-between items-start self-stretch mt-6 mb-3 max-600:block max-600:mt-0 max-600:rounded-none max767:pb-[10px] max767:p-5 ">
        <div className="flex flex-col  items-start mb-4 max767:hidden max767:w-full max767:flex-row ipad:w-[55%] max767:sticky max767:top-0 max767:mt-0 max767:bg-[#EDFAFF] max767:p-0 stickytitle max767:items-center max767:mb-3">
          <button
            onClick={backClickHandler}
            className="flex text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:bg-[#F6F8F9] max767:p-2 max767:rounded-3xl"
          >
            <img
              src={backarrow}
              alt="back arrow"
              className="rtl:rotate-180 rtl:mr-1"
            />
            <span className="max767:hidden rtl:mr-1">
              {" "}
              {t("motorInsurance.policyOverview.back")}
            </span>
          </button>
          <div className="flex flex-col items-start mb-4 max-600:block max-600:w-full max767:mb-0">
            {screenResolution.mobile ? (
              mobileEditableField
            ) : (
              <EditableField
                value={
                  policyViewLoading
                    ? translate(
                        "Getting vehicle details ...",
                        "جاري استرجاع تفاصيل المركبة ..."
                      )
                    : policyLabel
                }
                onChange={(e) => setPolicyLabel(e.target.value)}
                isEditing={isEditing}
                onSave={handleSaveClick}
                onEdit={handleEditClick}
                editIcon={editicon}
                isMobile={screenResolution.mobile}
              />
            )}
          </div>
          {content?.btn && isRenewable && !policyViewLoading && (
            <button
              className="flex items-center gap-2 bg-[#55C6EF] px-3 py-1 rounded-md text-[#1A2024] text-sm not-italic font-normal leading-6  max767:hidden max767:mt-4 max767:w-40"
              onClick={() => navigate("/motor-insurance/renew-motor-policy")} // Add this line
            >
              {content?.btn?.icon && (
                <img src={content?.btn?.icon} alt="" className="h-6 w-6" />
              )}
              <span>
                {translate(
                  content?.btn?.btn_lable_en,
                  content?.btn?.btn_lable_ar
                )}
              </span>
            </button>
          )}

          {screenResolution.mobile && (
            <PopoverComponent
              headerMenu={
                <button
                  onClick={toggleEllipsisMenu}
                  className="flex text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
                >
                  <img alt="ellipsis" src={ellipsis} />
                </button>
              }
              positions={["bottom"]}
              isPopoverOpen={showEllipsisMenu}
              closer={toggleEllipsisMenu}
              popupContent={
                <div className="flex w-[228px] flex-col items-start bg-white shadow-[0px_0px_32px_0px_rgba(0,0,0,0.20)] rounded-xl">
                  {menuData.map((data) => (
                    <button
                      key={data.type}
                      className=" flex h-11 justify-between items-center self-stretch px-4 py-0 border-b-[#DDE2E4] border-b border-solid"
                      onClick={() => handleMenuClick(data.type)}
                    >
                      {translate(data.text_en, data.text_ar)}
                      <img src={data.icon} alt="" />
                    </button>
                  ))}
                </div>
              }
            />
          )}
        </div>

        <div className="flex flex-col justify-between items-start mb-4 max-600:block max-600:w-full max767:mt-8">
          <span className="text-sm not-italic font-semibold flex items-center mb-4 gap-1 bg-[#F6F8F9] px-3 py-1 rounded-md text-gray-700 max767:mb-1 max767:w-max max767:text-[#252C32] ">
            {translate(policyDetails?.PolicyStatus, content?.status_ar)}
          </span>
          {screenResolution.mobile && mobileEditableField}
          <div>
            <h2 className="text-[#5B6871] text-sm not-italic font-semibold leading-[normal] mb-2">
              {translate(
                policyDetails?.PlanName == "FL"
                  ? "Motor Insurance Policy - Flexi Plan"
                  : policyDetails?.PlanName == "GO"
                  ? "Motor Insurance Policy - Gold Plan"
                  : policyDetails?.PlanName == "PL"
                  ? "Motor Insurance Policy - Platinum Plan"
                  : policyDetails?.PlanName == "TPL"
                  ? "Motor Insurance Policy - Third Party Plan"
                  : policyDetails?.PlanName == "ST"
                  ? "Motor Insurance Policy - Staff Plan"
                  : policyDetails?.PlanName == "OFL"
                  ? "Motor Insurance Policy - Online Flexi Plan"
                  : policyDetails?.PlanName,
                content?.planName_ar
              )}
            </h2>
            <p className="text-[#5B6871] text-sm not-italic font-normal leading-[normal] mb-2">
              {t("motorInsurance.policyOverview.policy_no")}:{" "}
              {policyDetails?.PolicyNumber}
            </p>
            <span className="flex items-center gap-4 w-[255px] bg-white p-2 rounded-md text-[#48535B] text-sm not-italic font-semibold leading-[120%] max767:w-[265px]">
              <img src={daterange} alt="date select" />{" "}
              {`${policyDetails?.FromDateStr.split("/")[0] ?? ""}/${
                policyDetails?.FromDateStr.split("/")[1] ?? ""
              }/${policyDetails?.FromDateStr.split("/")[2] ?? ""}`}
              <img
                src={forwadarrow ?? ""}
                alt="date select"
                className="rtl:rotate-180"
              />{" "}
              {`${policyDetails?.ToDateStr.split("/")[0] ?? ""}/${
                policyDetails?.ToDateStr.split("/")[1] ?? ""
              }/${policyDetails?.ToDateStr.split("/")[2] ?? ""}`}
            </span>
            {content?.btn && isRenewable && !policyViewLoading && (
              <button className="flex items-center gap-2 bg-[#55C6EF] px-3 py-1 rounded-md text-[#1A2024] text-sm not-italic font-normal leading-6 hidden max767:block max767:mt-4 max767:w-40 max767:inline-flex">
                {content?.btn?.icon && (
                  <img src={content?.btn?.icon} alt="" className="h-6 w-6" />
                )}
                <span>
                  {translate(
                    content?.btn?.btn_lable_en,
                    content?.btn?.btn_lable_ar
                  )}
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MotorPolicyCard;
