import React from 'react'
import BackdropModal from '../../../../../components/common/modal/backdropModal'
import { numberPlatesData } from './data'
import useLanguageTranslate from '../../../../../hooks/useLanguageTranslate'

const NumberPlatesTypesPopup = ({ show, onClose }) => {
    const translate = useLanguageTranslate()
    return (
        <BackdropModal className="Differencesplates" show={show} onClose={onClose} title={translate("Differences between Plate Types","الفرق بين أنواع اللوحات")} >
            <div className='numberPlatesTypesPopup '>
                <div className="grid grid-cols-2 max767:grid-cols-1 border border-solid border-[#DDE2E4] rounded-[10px] max767:border-0">
                    {
                        numberPlatesData?.map(each => <div className="item plate-box w-7/2  bg-white p-6 justify-center items-center max767:border-t-[#dde2e4] max767:border-t max767:border-solid max767:border-0">
                            <p className='w-2/2 text-center mb-2 text-black text-sm not-italic font-normal leading-6'>{translate(each.titleEn, each.titleAr)}</p>
                            <img className='w-2/2 mx-auto my-0' src={each.image} alt={translate(each.titleEn, each.titleAr)} />
                        </div>)
                    }
                </div>
            </div>
        </BackdropModal>
    )
}

export default NumberPlatesTypesPopup