import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SelectInput from '../../../components/common/selectInput/selectInput';
import TelInput from '../../../components/common/telInput/telInput';
import Input from '../../../components/common/Input/Input';
import useValidation from '../../../hooks/useValidation';
import { daysOptions, monthsOptions, yearsOptions } from '../../../utils/calenderOptions';
import TitleDescription from '../../../components/newMotorPolicy/titleDescription/titleDescription';
import { createLead, selectLeadLoader } from '../../../redux/features/expressSheild/leadSlice';
import useLifeFormData from '../../../hooks/useLifeFormData';
import { LEAD_INFO } from './data';
import EssentialCareLayout from '../essentialCareLayout';
import { selectEssentialCareAllPagesDataCms } from '../../../redux/features/essentialCare/essentialCareStrapiCmsSlice';
import useLanguageTranslate from '../../../hooks/useLanguageTranslate';

export const ESSENTIAL_CARE_BASIC_INFO = "ESSENTIAL_CARE_BASIC_INFO"

const BasicInfo = ({ onSuccess }) => {
  const [values, setValues] = useState({});
  const translate = useLanguageTranslate();
  const { errors, validateFields } = useValidation();
    const cmsData = useSelector(selectEssentialCareAllPagesDataCms);

  const { saveLifeDataInRedux } = useLifeFormData(
    ESSENTIAL_CARE_BASIC_INFO,
    setValues
  );
  const { t } = useTranslation();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  }
  const validateForm = (data) => {
    let isValid = validateFields(
      [
        {
          name: "email",
          validations: ["required", "email"],
        },
        {
          name: "fullName",
          validations: ["required"],
        },
        {
          name: "day",
          validations: ["select"],
        },
        {
          name: "month",
          validations: ["select"],
        },
        {
          name: "year",
          validations: ["select"],
        },
        {
          name: "mobile",
          validations: ["length"],
          exact_length: 8,
        },
      ],
      data ? data : values
    );
    return isValid;
  };
  const dispatch = useDispatch();
  const nextClickHandler = async () => {
    if (validateForm()) {
      saveLifeDataInRedux(values);
      let [FirstName, LastName] = values?.fullName?.split(" ")
      let payload = {
        FirstName,
        LastName,
        CPR: "",
        PhoneNumber: `${values?.countryCode||973}${values?.mobile}` || "",
        subject: LEAD_INFO.basicDetails,
        Product: "LIFE",
        Email: "",
        customerType: 1,
        status: 1,
        productType: "Life",
        stage: "",
        lead_id: "",
        Quote: "",
        channel: "online.solidarity",
        LOBProduct: "Essential Health Care"
      };
      const response = await dispatch(createLead(payload))
      if(response?.type =="createLead/fulfilled"){
        onSuccess()
      }
        // onSuccess()
    }
  }
  const isLoading = useSelector(selectLeadLoader);

  return (
    <EssentialCareLayout
      is_bottom_sticky_layout
      is_hide_back_btn
      btn_onClick={nextClickHandler}
      isLoading={isLoading}
      show_close_btn
    >
      <div className="w-[500px] max-w-[100%] max767:w-full mx-auto ipad:w-[60%]">
        <TitleDescription
          title={t("essentialCareFlow.basicInfo.title")}
          description={t("essentialCareFlow.basicInfo.description")}
        />
        <div className="flex flex-col items-start gap-1 bg-[#EDFAFF] px-10 py-6 rounded-[20px] mt-3 mb-6 max767:mt-6">
          <h2 className='text-base not-italic font-semibold'>
            {translate(
              cmsData?.doc_ready_alert_msg?.title_en,
              cmsData?.doc_ready_alert_msg?.title_ar
            )}
          </h2>
          <span className='class="text-sm not-italic font-normal"'>
            {translate(
              cmsData?.doc_ready_alert_msg?.description_en,
              cmsData?.doc_ready_alert_msg?.description_ar
            )}
          </span>
          <span className='text-sm not-italic font-semibold'>
            {translate(
              cmsData?.doc_ready_alert_msg?.subtitle_en,
              cmsData?.doc_ready_alert_msg?.subtitle_ar
            )}
          </span>
        </div>
        <Input
          label={t("essentialCareFlow.basicInfo.fullName")}
          type="text"
          name="fullName"
          value={values.fullName}
          error={errors.fullName}
          handleChange={handleChange}
        />
        <Input
          label={t("essentialCareFlow.basicInfo.emailAddress")}
          type="email"
          name="email"
          value={values.email}
          error={errors.email}
          handleChange={handleChange}
        />
        <div className='essentialmobile'>
        <TelInput
          name="mobile"
          value={values.mobile}
          error={errors.mobile}
          onChange={handleChange}
          label={t("essentialCareFlow.basicInfo.mobileNumber")}
          className="mobilenumber  px-4 py-3 max767:w-[50%] w-[97%] border-r-0 border-0 rounded-[9px] h-[46px] focus:outline-none"
        />
        </div>
        <div className="date_of_birth">
          <label className="block mb-[5px] text-gray-700 not-italic font-normal leading-6 text-base max-350:text-[13px]">
            {t("essentialCareFlow.basicInfo.dateOfBirth")}
          </label>
          <div className="flex gap-6 self-stretch">
            <SelectInput
              name="day"
              options_data={daysOptions}
              formData={values}
              default_placeholder={t(
                "essentialCareFlow.basicInfo.day"
              )}
              error={errors?.day}
              handleChange={handleChange}
            />

            <SelectInput
              name="month"
              formData={values}
              default_placeholder={t(
                "essentialCareFlow.basicInfo.month"
              )}
              options_data={monthsOptions}
              error={errors?.month}
              handleChange={handleChange}
            />

            <SelectInput
              name="year"
              formData={values}
              default_placeholder={t(
                "essentialCareFlow.basicInfo.year"
              )}
              options_data={yearsOptions}
              error={errors?.year}
              handleChange={handleChange}
            />
          </div>
        </div>
      </div>
    </EssentialCareLayout>
  );
}
export default BasicInfo;