export const WITHDRAWAL_VALIDATION_CONFIG = [
    {
      name: "amount",
      validations: ["required"],
    },
    {
      name: "iban_number",
      validations: ["required"],
    },
    // {
    //   name: "iban_certificate",
    //   validations: ["file"],
    // },
  ];