import React, { useState } from 'react'
import { ADD_MORE_COVERS_FLOW_ENHANCE_YOUR_COVERAGE, ADD_MORE_COVERS_FLOW_REVIEW_AND_CONFIRM } from './data';
import ReviewAndConfirmPage from './reviewAndConfirmPage/reviewAndConfirmPage';
import useAddMoreCoversFlow from '../../../hooks/useAddMoreCoversFlow';
import { coverPriceRequest } from '../newMotorPolicy/planPages/data';
import { useDispatch, useSelector } from 'react-redux';
import { getCoverPrices, policyView } from '../../../redux/features/motor/getQuoteSlice';
import CoversLists from '../renewalMotorPolicy/enhanceYourCoveragePage/coversLists';
import { useParams } from 'react-router-dom';
import { PriceProvider } from '../newMotorPolicy/Layouts/PriceContext';

const AddMoreCoversMotorPolicy = () => {
    const dispatch = useDispatch();
    const policyDetails = useSelector((state) => state.policyDetails.PolicyDetails);
    const policyDetailsStatus = useSelector((state) => state.policyDetails.status);
    const policyDetailsError = useSelector((state) => state.policyDetails.error);
    const totalPrice = 3;
    const { policy_id } = useParams();
    const [PolicyNumber, setPolicyNumber] = useState(policy_id);
    const [Channel, setChannel] = useState('online.solidarity');
    const {currentStep} = useAddMoreCoversFlow()
    const coverPricesPayload = coverPriceRequest(
        policy_id,
        'FL',
        "",
        ""
      );
      const polReq = {
        PolicyNumber,
        Channel
      }
      
        
        
        if(policyDetails ==null)
        {
            dispatch(policyView(polReq));
            dispatch(getCoverPrices(coverPricesPayload));
        }
        else if(policyDetails?.PolicyHeader?.PolicyNumber != policy_id){
            dispatch(policyView(polReq));
            dispatch(getCoverPrices(coverPricesPayload));
        }
        dispatch(getCoverPrices(coverPricesPayload));

    const PageRenderer = () => {
        switch (currentStep?.name) {
            case ADD_MORE_COVERS_FLOW_ENHANCE_YOUR_COVERAGE:
                return <CoversLists covers={policyDetails?.PolicyHeader?.Covers} />
            case ADD_MORE_COVERS_FLOW_REVIEW_AND_CONFIRM:
                return <ReviewAndConfirmPage />
            default:
                return null
        }
    };
    return (
        <div className="mainCarDetailsModal pt-[4%] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll ">
            <PageRenderer />
        </div>
    )
}

export default AddMoreCoversMotorPolicy;