import { useState, useEffect } from 'react';

const useScreenResolution = () => {
  const [screenResolution, setScreenResolution] = useState(getScreenResolution());

  useEffect(() => {
    const handleResize = async () => {
      let update = getScreenResolution()
      if(screenResolution.mobile != update.mobile ||
        screenResolution.tablet != update.tablet ||
        screenResolution.desktop != update.desktop) {
        await setScreenResolution(update)
        console.log(update,'update')
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [screenResolution]);
  return screenResolution;
};

const getScreenResolution = () => {
  const width = window.innerWidth;
  return {
    mobile: width < 768,
    tablet: width >= 768 && width <= 1024,
    desktop: width > 1024,
  };
};

export default useScreenResolution;
