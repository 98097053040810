import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import BackdropModal from "../../../../../components/common/modal/backdropModal";
import Button from "../../../../../components/common/Button/Button";
import incresedecrese from "../../../../../assets/images/incresedecrese.svg"
import redflag from "../../../../../assets/images/bahrainsmlogo.svg"
import downgrayarrow from "../../../../../assets/images/downgrayarrow.svg"
import useValidation from "../../../../../hooks/useValidation";
import AlertComponent from "../../../../motorInsurancePages/transferPolicyFlow/alertComponent";
import Input from "../../../../../components/common/Input/Input";
import { DEC_INC_VALIDATIONS } from "./data";
import useLanguageTranslate from "../../../../../hooks/useLanguageTranslate";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectLifePolicyData } from "../../../../../redux/features/life/lifePolicyDetailSlice";
import useCreateLifeRequests, { LIFE_REQUEST_TYPE } from "../../../../../hooks/life/useCreateLifeRequests";
import { currencyFormatterWithCountryCode } from "../../../../../utils";
import { PAYMENT_CYCLES_LABELS } from "../changePaymentCycle/data";
import useScreenResolution from "../../../../../hooks/useScreenResolution";
import closeicon from "../../../../../assets/images/close.svg";
import { useNavigate } from "react-router-dom";
import BottomStickyBtnLayout from "../../../../motorInsurancePages/newMotorPolicy/Layouts/BottomStickyBtnLayout";


const DecOrIncPremium = ({ show, setShow }) => {
    const { errors, validateFields } = useValidation();
    const { t } = useTranslation()
    const translate = useLanguageTranslate()
    const { isLoading, createLifeRequest } = useCreateLifeRequests()
    const {mobile} = useScreenResolution()
    const navigate = useNavigate()
    const validateForm = (data) => {
        let isValid = validateFields(DEC_INC_VALIDATIONS, data ? data : formData)
        return isValid
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let update = { ...formData, [name]: value }
        setFormData(update);
        if (errors?.[name]) {
            validateForm(update)
        }
    };


    const policyDetails = useSelector(selectLifePolicyData);
    const currentPremium = useMemo(() => {
        let currentValue = policyDetails?.LifeDetails?.policyList?.[0]?.currentValue;
        return currentValue?.length > 4 ? currentValue?.slice(4, currentValue?.length) : currentValue;
    }, [])

  const paymentCycle = useMemo(() => {
    const currentPaymentCycleId = policyDetails?.LifeDetails?.policyList?.[0]?.paymentCycle;
    return PAYMENT_CYCLES_LABELS.find((cycle) => cycle.label == currentPaymentCycleId);
  }, [policyDetails?.LifeDetails?.policyList]);


    const [formData, setFormData] = useState({})
    const onClose = () => {
        setShow((state) => !state);
        setFormData({})
    }
    const changeHandler = async () => {
        if (!validateForm()) return;
        let amount = Number(formData.amount)

        let payload = {
            PolicyNumber: policyDetails?.PolicyDetails?.PolicyNumber || "",
            RequestType: LIFE_REQUEST_TYPE.increasePremium,
            Amount: amount
        }
        if (amount < currentPremium) {
            payload.RequestType = LIFE_REQUEST_TYPE.decreasePremium
        }
        const response = await createLifeRequest(payload)
        onClose()
    }

    return (
      <div className="changepymentcylcmodal">
        <BackdropModal onClose={onClose} show={show} hideClosebtn={mobile} className="incresedecrese">
          {!mobile && <p className="flex mb-5 max767:mt-[-13px]">
            <img
              src={incresedecrese}
              alt="exit_to_app"
              className="exit_to_app  mt-[-40px] max767:hidden "
            />
          </p>}
          {mobile && 
              <button
                    className="bpy-2 rounded mt-4 flex absolute top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:ml-[16px] max767:mb-[10px]"
                    onClick={onClose}
                >
                    <img src={closeicon} alt="Close Icon" />
                </button>}
          <div className="content mb-4 max767:mt-[0px]">
            <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mb-6 max767:text-xl">
              {t("lifeInsurance.withdrawal.decIncPremium.popup_title")}
            </h1>
            {mobile && (
            <p className="text-[#1A2024] text-base not-italic font-normal leading-6 tracking-[-0.16px] max767:mb-8">
              {translate("Please select how much you would like to increase or update this value.","يرجى تحديد المقدار الذي ترغب في زيادته أو تحديث هذه القيمة.")}
            </p>
          )}
            <AlertComponent
              title={translate(
                "Please note, this request will be submitted for approval before it takes effects",
                "يرجى ملاحظة أن هذا الطلب سيتم تقديمه للموافقة عليه قبل أن يصبح ساري المفعول"
              )}
            />
            <div className="currentEncashment mt-6">
              <p className="text-[#1A2024] text-base not-italic font-medium leading-[26px] tracking-[-0.16px]">
                {t(
                  "lifeInsurance.withdrawal.decIncPremium.currentPremiumLabel"
                )}
              </p>
              <div className="bg-[#F6F8F9] rounded-[10px] px-4 py-6 flex justify-between p-3 max767:h-[77px] max767:items-center h-[77px] items-center">
                <p className="text-2xl not-italic font-semibold max767:text-2xl max767:not-italic max767:font-semibold">
                  {currencyFormatterWithCountryCode(currentPremium)}
                </p>
                <div className="flex  align-middle mt-0">
                  <img src={redflag} alt="flag" />
                  <p className="ml-2 text-black text-lg not-italic font-normal leading-[26px] tracking-[-0.18px] rtl:mr-2 rtl:ml-0">
                    {translate("BHD", "دينار بحريني")}
                  </p>
                </div>
              </div>
            </div>
            <img src={downgrayarrow} alt="arrow-down" className="my-4" />
            <div className="newprimium">
              <Input
                className="px-4 py-6 max767:items-center max767:w-full text-2xl not-italic font-normal"
                label={t(
                  "lifeInsurance.withdrawal.decIncPremium.newPremiumInputLabel"
                )}
                endAdornment={
                  <div className="flex align-middle mt-1">
                    <img src={redflag} alt="flag" />
                    <p className="ml-2 text-black text-lg not-italic font-normal leading-[26px] tracking-[-0.18px] rtl:mr-2 rtl:ml-0">
                      {translate("BHD", "دينار بحريني")}
                    </p>
                  </div>
                }
                hint_msg={`${t("lifeInsurance.withdrawal.decIncPremium.newPremiumInputHintMsg")} ${translate(paymentCycle?.label, paymentCycle?.label_ar)}`}
                type="number"
                name="amount"
                setFormData={setFormData}
                value={formData.amount}
                handleChange={handleChange}
                error={errors.amount}
              />
            </div>
            {!mobile && <div className="mt-8 flex items-start gap-3 self-stretch max767:flex-col decIncPremium">
               <Button
                onSubmit={onClose}
                label={t(
                  "lifeInsurance.withdrawal.decIncPremium.cancelBtnLabel"
                )}
                className="flex flex-col items-center gap-2.5 flex-[1_0_0] px-4 py-2 border rounded-[10px] border-solid border-[#DDE2E4] bg-white text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:w-full"
              />
              <Button
                disabled={isLoading}
                onSubmit={changeHandler}
                label={t(
                  "lifeInsurance.withdrawal.decIncPremium.confirmBtnLabel"
                )}
                className="flex flex-col items-center gap-2.5 flex-[1_0_0] px-4 py-2 border rounded-[10px] border-solid border-[#DDE2E4] bg-[#55C6EF] text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:w-full"
              />
            </div>}
            {mobile && (
                        <BottomStickyBtnLayout
                        btn_label={t(
                          "lifeInsurance.withdrawal.decIncPremium.confirmBtnLabel"
                        )}
                        btn_onClick={changeHandler}
                        btn_disabled= {isLoading}
                        ></BottomStickyBtnLayout>
                      )}
          </div>
        </BackdropModal>
      </div>
    );
};

export default DecOrIncPremium;
