import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearMotorFormData, selectNewMotorForm, setMotorFormData } from "../redux/features/newMotorFormSlice";

const useMotorForm = (formName, setFormData) => {
    const dispatch = useDispatch();
    const newMotorFormData = useSelector(selectNewMotorForm);
    const redux_form_data = newMotorFormData?.[formName]

    useEffect(() => {
        if (redux_form_data) {
            setFormData(redux_form_data)
        }
    }, []);

    // const saveMotorDataInRedux = (formData) => {
    //     dispatch(setMotorFormData({ form_name: formName, data: formData }));
    // }

    const saveMotorDataInRedux = async (formData) => {
        return new Promise((resolve, reject) => {
            try {
                dispatch(setMotorFormData({ form_name: formName, data: formData }));
                resolve(); // Resolve the promise after the dispatch is complete
            } catch (error) {
                reject(error); // Reject the promise if an error occurs
            }
        });
    };
    const removeMotorFormData = () => {
        dispatch(clearMotorFormData({ form_name: formName }));
    }

    const removeMotorFormPromoCodeData = () => {
        dispatch(clearMotorFormData({ form_name: formName }));
    }

    return { saveMotorDataInRedux, removeMotorFormData, removeMotorFormPromoCodeData, redux_form_data };
};

export default useMotorForm;
