import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserPolicies, myPoliciesWholeLoader, selectUserPoliciesData } from '../../redux/features/myPolicySlice';
import { selectLoginTokenInfo } from '../../redux/features/loginRegisterSlice';
import PolicyCard from '../../components/policyCard/policyCard';
import Loader from '../../components/common/Loader/Loader';
import SkeletonLoader from '../../components/common/Loader/SkeletonLoader';


const ExpiredPolicies = () => {
    const tokenInfo = useSelector(selectLoginTokenInfo);
    const dispatch = useDispatch()
    useEffect(() => {
        let payload =
        {
            "Cpr": tokenInfo?.userName,
            "Channel": "online.solidarity"
        }
        if (tokenInfo)
            dispatch(fetchUserPolicies(payload))
    }, [])
    const userPolicies = useSelector(selectUserPoliciesData)
    const IsLoading = useSelector(myPoliciesWholeLoader);
    return (
        <>  
            {
                IsLoading && !userPolicies?.length && <SkeletonLoader />
            }
            {userPolicies
            ?.filter(each => each.PolicyStatus =="EXPIRED")
            .map((each, index) => (
                <PolicyCard
                    key={index}
                    policy_name={each.policy_name}
                    policy_label={each.policy_label}
                    policy_number={each.policy_number}
                    from_date={each.from_date}
                    to_date={each.to_date}
                    policy_bg_image={each.policy_bg_image}
                    expire_days={each.expire_days}
                    is_text_dark={each.is_text_dark}
                    mapping_key={each.id}
                    data={each}
                />
            ))}
        </>
    )
}

export default ExpiredPolicies