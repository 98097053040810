import React from "react";
import TitleDescription from "../../../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useTranslation } from "react-i18next";
import RenewMotorLayout from "../../../renewalMotorPolicy/renewMotorLayout";
import AlertComponent from "../../../transferPolicyFlow/alertComponent";
import useLanguageTranslate from "../../../../../hooks/useLanguageTranslate";
import { transferPolicyData } from "../../../../dashboard/data";
import PolicyCard from "../../../../../components/policyCard/policyCard";
import { policyCancellationTableData } from "./data";
import Button from "../../../../../components/common/Button/Button";
import usePolicyCancellationFlow from "../../../../../hooks/usePolicyCancellationFlow";
import BottomLayout from "./bottomLayout";
import PolicyCancellationMotorLayout from "./policyCancellationMotorLayout";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPolicyViewHeaderPartyResponse, getPolicyViewHeaderResponse } from "../../../../../redux/features/policyDetailsSlice";

const PolicyCancellationReviewPage = ({handleNavigationToPolicyOverview}) => {
  const translate = useLanguageTranslate();
  const navigate = useNavigate();
  const { handleNextSubstep, handleFlowChange } = usePolicyCancellationFlow();

  const { t } = useTranslation();

  // Get policy detail. Call API if empty or check redux
  const policyDetailsInfo = useSelector(getPolicyViewHeaderResponse);
  const policyDetailsPartyInfo = useSelector(getPolicyViewHeaderPartyResponse);
  const policyCardInfo = {
    "ModelYear": "",
    "Make": policyDetailsInfo ? policyDetailsInfo?.Make : '--',
    "Model": policyDetailsInfo?.Model || '--',
    "CPR": "",
    "PolicyNumber": policyDetailsInfo?.PolicyNumber || '--',
    "RegistrationNumber": policyDetailsInfo?.RegistrationNumber || '-',
    "SumInsured": 0.0,
    "PlanName": policyDetailsInfo?.PlanName || '--',
    "ProductCode": "0101",
    "ProductName": "Motor",
    "StartDate": policyDetailsInfo?.FromDateStr?.split(" ")[0]?.split("/")?.reverse()?.join("-") || '--',
    "EndDate": policyDetailsInfo?.ToDateStr?.split(" ")[0]?.split("/")?.reverse()?.join("-") || '--',
    "FromDateStr": policyDetailsInfo?.FromDateStr?.split(" ")[0] || '--',
    "ToDateStr": policyDetailsInfo?.ToDateStr?.split(" ")[0] || '--',
    "Branch": "online.solidarity",
    "MainChannel": "Direct Bahrain",
    "Renewed": policyDetailsInfo?.IsRenewed,
    "PolicyStatus": "Active",
    "IsRenewable": false
  };

  /* Next */
  const continueClickHanlder = () => {
    handleNextSubstep();
  };

  return (
    <PolicyCancellationMotorLayout
      is_hide_back_btn={true}
      show_close_btn={true}
      handleCloseBtnClick={handleNavigationToPolicyOverview}
      btn_onClick={continueClickHanlder}
      CancellationAdditionalComponent={
        <BottomLayout className="btvk"
          btn_onClick={continueClickHanlder}
          handleNavigationToPolicyOverview={handleNavigationToPolicyOverview}
        />
      }
    >
      <div className="flex flex-col items-center mx-auto max-w-lg max767:p-5 max767:mt-0 max767:pt-0 max767:pb-[50px]">
        <div className="flex justify-center items-center w-full">
          <div className=" p-0 w-full ">
            <TitleDescription
              className="max767:mt-0!important"
              title={t(
                "motorInsurance.policyCancellationFlow.review_Page.title"
              )}
              description={t(
                "motorInsurance.policyCancellationFlow.review_Page.description"
              )}
            />
            <AlertComponent
              className="mb-4"
              title={translate(
                "Note that cancelling your policy is a request and is subject to approval based on our cancellation policy. Ensure you understand the implications of this action.",
                "يرجى ملاحظة أن إلغاء وثيقتك هو طلب ويخضع للموافقة بناءً على سياسة الإلغاء الخاصة بنا. تأكد من فهمك لتداعيات هذا الإجراء."
              )}
            />
            <div className="flex flex-col justify-center items-center mt-5 bg-[#F6F8F9] p-4 rounded-2xl max767:bg-white max767:p-0">
              <PolicyCard
                className="mb-4"
                policy_name="name"
                policy_label={"label"}
                policy_number={"1112"}
                from_date={policyCardInfo.FromDateStr}
                to_date={policyCardInfo.policyDetailsInfo}
                policy_bg_image={policyCardInfo.policy_bg_image}
                expire_days={policyCardInfo.expire_days}
                is_text_dark={policyCardInfo.is_text_dark}
                mapping_key={policyCardInfo.id}
                data={policyCardInfo}
                disableCardClick
              />

              <table className="min-w-full border-separate bg-white border border-solid border-[#DDE2E4] border-spacing-0 rounded-[10px] max767:border-0">
                <tbody>
                  <tr className="block md:table-row md:border-b max767:border-b-[#DDE2E4] max767:border-b max767:border-solid">
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:pl-0">
                      {t(
                        "motorInsurance.policyCancellationFlow.review_Page.owner"
                      )}
                    </td>
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:text-[#1A2024] max767:font-medium max767:pl-0">
                      {policyDetailsPartyInfo?.FirstName}{" "}
                      {policyDetailsPartyInfo?.LastName}
                    </td>
                  </tr>
                  <tr className="block md:table-row md:border-b max767:border-b-[#DDE2E4] max767:border-b max767:border-solid">
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:pl-0">
                      {t(
                        "motorInsurance.policyCancellationFlow.review_Page.policy_number"
                      )}
                    </td>
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:text-[#1A2024] max767:font-medium max767:pl-0">
                      {policyDetailsInfo?.PolicyNumber}
                    </td>
                  </tr>
                  <tr className="block md:table-row md:border-b max767:border-b-[#DDE2E4] max767:border-b max767:border-solid">
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:pl-0">
                      {t(
                        "motorInsurance.policyCancellationFlow.review_Page.startDate"
                      )}
                    </td>
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:text-[#1A2024] max767:font-medium max767:pl-0">
                      {policyDetailsInfo?.FromDateStr}
                    </td>
                  </tr>
                  <tr className="block md:table-row md:border-b max767:border-b-[#DDE2E4] max767:border-b max767:border-solid">
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:pl-0">
                      {t(
                        "motorInsurance.policyCancellationFlow.review_Page.endDate"
                      )}
                    </td>
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:text-[#1A2024] max767:font-medium max767:pl-0">
                      {policyDetailsInfo?.ToDateStr}
                    </td>
                  </tr>
                  <tr className="block md:table-row md:border-b max767:border-b-[#DDE2E4] max767:border-b max767:border-solid">
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:pl-0">
                      {t(
                        "motorInsurance.policyCancellationFlow.review_Page.plate_number"
                      )}
                    </td>
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:text-[#1A2024] max767:font-medium max767:pl-0">
                      {policyDetailsInfo?.RegistrationNumber}
                    </td>
                  </tr>
                  <tr className="block md:table-row md:border-b max767:border-b-[#DDE2E4] max767:border-b max767:border-solid">
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:pl-0">
                      {t(
                        "motorInsurance.policyCancellationFlow.review_Page.chassis_number"
                      )}
                    </td>
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:text-[#1A2024] max767:font-medium max767:pl-0">
                      {policyDetailsInfo?.ChasissNumber}
                    </td>
                  </tr>
                  <tr className="block md:table-row md:border-b max767:border-b-[#DDE2E4] max767:border-b max767:border-solid">
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:pl-0">
                      {t(
                        "motorInsurance.policyCancellationFlow.review_Page.car_make"
                      )}
                    </td>
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:text-[#1A2024] max767:font-medium max767:pl-0">
                      {policyDetailsInfo?.Make}
                    </td>
                  </tr>
                  <tr className="block md:table-row md:border-b max767:border-b-[#DDE2E4] max767:border-b max767:border-solid">
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:pl-0">
                      {t(
                        "motorInsurance.policyCancellationFlow.review_Page.car_model"
                      )}
                    </td>
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:text-[#1A2024] max767:font-medium max767:pl-0">
                      {policyDetailsInfo?.Model}
                    </td>
                  </tr>
                  <tr className="block md:table-row md:border-b max767:border-b-[#DDE2E4] max767:border-b max767:border-solid">
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:pl-0">
                      {t(
                        "motorInsurance.policyCancellationFlow.review_Page.manufacturing_year"
                      )}
                    </td>
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:text-[#1A2024] max767:font-medium max767:pl-0">
                      {policyDetailsInfo?.ModelYear}
                    </td>
                  </tr>
                  <tr className="block md:table-row md:border-b max767:border-b-[#DDE2E4] max767:border-b max767:border-solid">
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:pl-0">
                      {t(
                        "motorInsurance.policyCancellationFlow.review_Page.body_type"
                      )}
                    </td>
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:text-[#1A2024] max767:font-medium max767:pl-0">
                      {policyDetailsInfo?.BodyTypeCode}
                    </td>
                  </tr>
                  <tr className="block md:table-row md:border-b max767:border-b-[#DDE2E4] max767:border-b max767:border-solid">
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:pl-0">
                      {t(
                        "motorInsurance.policyCancellationFlow.review_Page.engine_capacity"
                      )}
                    </td>
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:text-[#1A2024] max767:font-medium max767:pl-0">
                      {policyDetailsInfo?.EngineSize}
                    </td>
                  </tr>
                  <tr className="block md:table-row md:border-b max767:border-b-[#DDE2E4] max767:border-b max767:border-solid">
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:pl-0">
                      {t(
                        "motorInsurance.policyCancellationFlow.review_Page.seating_capacity"
                      )}
                    </td>
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:text-[#1A2024] max767:font-medium max767:pl-0">
                      {policyDetailsInfo?.Seats}
                    </td>
                  </tr>
                  <tr className="block md:table-row md:border-b max767:border-b-[#DDE2E4] max767:border-b max767:border-solid">
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:pl-0">
                      {t(
                        "motorInsurance.policyCancellationFlow.review_Page.vehicle_colour"
                      )}
                    </td>

                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:text-[#1A2024] max767:font-medium max767:pl-0">
                      {policyDetailsInfo?.ColorCode}
                    </td>
                  </tr>
                  <tr className="block md:table-row md:border-b max767:border-b-[#DDE2E4] max767:border-b max767:border-solid">
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:pl-0">
                      {t(
                        "motorInsurance.policyCancellationFlow.review_Page.premium_paid"
                      )}
                    </td>
                    <td className="py-2 px-4 md:border-b md:border-r text-[#5B6871] text-sm font-medium leading-6 block md:table-cell max767:text-[#1A2024] max767:font-medium max767:pl-0">
                      {policyDetailsInfo?.Premium} {translate("BHD","المحدودة")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </PolicyCancellationMotorLayout>
  );
};

export default PolicyCancellationReviewPage;
