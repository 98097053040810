import React from "react";
import { motorData } from "../../newMotorPolicy/pay/data";
import { useTranslation } from "react-i18next";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import { useSelector } from "react-redux";
import { getPolicyPremiumResponse } from "../../../../redux/features/motor/renewalPremiumSlice";
import {
  CurrentPolicyNumberResponse,
  getQuoteWithRefundResponse,
} from "../../../../redux/features/endorsements/cancellationSlice";
import { PolicyViewResponse } from "../../../../redux/features/motor/getQuoteSlice";
import { currencyFormatter } from "../../../../utils";

const ReviewMotorTable = ({ passedFrom }) => {
  const policyPremiumResponse = useSelector(getPolicyPremiumResponse);
  const quoteWithRefundResponse = useSelector(getQuoteWithRefundResponse);
  const policyViewResponse = useSelector(PolicyViewResponse);

  let response = "";

  console.log("Passed from: ", passedFrom);
  if (passedFrom == "MotorNew") {
    response = "";
  } else if (passedFrom == "MotorRenewal") {
    response = policyPremiumResponse.QuoteDetails;
    console.log("policyPRemium Respons", response);
  } else if (passedFrom == "MotorChangeActivePlan") {
    response = quoteWithRefundResponse.QuoteDetails;
    console.log("Get quote with refund response: ", response);
  } else if (passedFrom == "transferPolicy") {
    response = policyViewResponse.PolicyHeader;
    console.log("policy view details are: ", response);
  } else {
    response = "";
  }

  const filteredAddOns = response?.Covers?.filter(
    (addOn) => addOn.coverCode != "CMOT23" && addOn.coverCode != "CMOT26"
  );

  console.log("selected", policyPremiumResponse);
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  return (
    <table className="min-w-full border-separate bg-white border border-solid border-[#DDE2E4] border-spacing-0 rounded-[10px]">
      <tbody>
        <tr className="border-b">
          <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6 rtl:border-l-[#DDE2E4] rtl:border-l rtl:border-solid">
            {t(
              "motorInsurance.newMotorInsuranceFlow.paymentSummary.plate_number"
            )}
          </td>
          <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6 ">
            {response?.RegistrationNumber}
          </td>
        </tr>
        <tr className="border-b">
          <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
            {t(
              "motorInsurance.newMotorInsuranceFlow.paymentSummary.plate_type"
            )}
          </td>
          <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
            {/* {translate(motorData.plateType,motorData.plateType_ar)} */}
            {response?.PlateCategory}
          </td>
        </tr>
        <tr className="border-b">
          <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
            {t(
              "motorInsurance.newMotorInsuranceFlow.paymentSummary.chassis_number"
            )}
          </td>
          <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
            {response?.ChasissNumber
              ? `${response.ChasissNumber.slice(
                  0,
                  2
                )}XXXXXXXXXXXX${response.ChasissNumber.slice(-3)}`
              : ""}
          </td>
        </tr>
        <tr className="border-b">
          <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
            {t("motorInsurance.newMotorInsuranceFlow.paymentSummary.car_make")}
          </td>
          <td className="py-2 px-4 border-b  text-[#1A2024] text-sm not-italic font-medium leading-6">
            {/* {translate(motorData.carMake,motorData.carMake_ar)} */}
            {response?.Make}
          </td>
        </tr>
        <tr className="border-b">
          <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
            {t("motorInsurance.newMotorInsuranceFlow.paymentSummary.car_model")}
          </td>
          <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
            {/* {translate(motorData.carModel,motorData.carModel_ar)} */}
            {response?.Model}
          </td>
        </tr>
        <tr className="border-b">
          <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
            {t(
              "motorInsurance.newMotorInsuranceFlow.paymentSummary.manufacturing_year"
            )}
          </td>
          <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
            {/* {motorData?.manufacturingYear} */}
            {response?.ModelYear}
          </td>
        </tr>
        <tr className="border-b">
          <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
            {t(
              "motorInsurance.newMotorInsuranceFlow.paymentSummary.sum_insured"
            )}
          </td>
          <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
            {translate("BHD", "د.ب")} {currencyFormatter(response?.SumInsured)}
          </td>
        </tr>
        <tr className="border-b">
          <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
            {t("motorInsurance.newMotorInsuranceFlow.paymentSummary.startDate")}
          </td>
          <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6">
            {/* {response?.PreviousPolicyToDateStr} */}
            {response?.FromDateStr}
          </td>
        </tr>
        <tr className="border-b">
          <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6">
            {t("motorInsurance.newMotorInsuranceFlow.paymentSummary.plan")}
          </td>
          <td className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6 rtl:border-r">
            {response?.PlanName == "OFL" && "Online Flexi"}
            {response?.PlanName == "OPL" && "Online Platinum"}
            {response?.PlanName == "TPPLUS" && "Third Party Plus"}
            {response?.PlanName == "TP" && "Third Party"}
          </td>
        </tr>
        <tr>
          <td className="py-2 px-4 border-b border-r text-[#5B6871] text-sm not-italic font-medium leading-6 align-top">
            {t("motorInsurance.newMotorInsuranceFlow.paymentSummary.add_ons")}
          </td>
          <td className=" text-[#1A2024] text-sm not-italic font-medium leading-6 rtl:border-r">
            {/* {translate(
              motorData.addOns.map((addOn, index) => (
                <div
                  key={index}
                  className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6"
                >
                  {addOn}
                </div>
              )),
              motorData.addOns_ar.map((addOn, index) => (
                <div
                  key={index}
                  className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6"
                >
                  {addOn}
                </div>
              ))
            )} */}

            {filteredAddOns?.map((addOn, index) => (
              <div
                key={addOn.coverCode}
                className="py-2 px-4 border-b text-[#1A2024] text-sm not-italic font-medium leading-6"
              >
                <div>{addOn?.coverName}</div>
              </div>
            ))}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default ReviewMotorTable;
