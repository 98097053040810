/* Policy Cancellation */

import React, { useState, useEffect } from "react";
import usePolicyCancellationFlow from "../../../../../hooks/usePolicyCancellationFlow";
import useLanguageTranslate from "../../../../../hooks/useLanguageTranslate";
import PolicyCancellationReviewPage from "./reviewPage";
import {
  POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_CONFIRMATION,
  POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_REASON,
  POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_REVIEW,
  termsAndConditions,
} from "./data";
import PolicyCancellationReasonPage from "./cancellationReasonPage";
import ConfimPolicyCancellationPage from "./confimCancellationPage";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPolicyCancellationFlow } from "../../../../../redux/features/motorInsurancePolicyCancellationFlowSlice";
import BackdropModal from "../../../../../components/common/modal/backdropModal";
import Button from "../../../../../components/common/Button/Button";
import { toast } from "react-toastify";
import {
  getPolicyViewHeaderPartyResponse,
  getPolicyViewHeaderResponse,
} from "../../../../../redux/features/policyDetailsSlice";
import { createCaseComplaint } from "../../../../../redux/features/crm/caseComplaintSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectProfileData } from "../../../../../redux/features/profileSlice";
import CryptoJS from "crypto-js";

const PolicyCancellationMotorInsurance = () => {
  const { currentStep } = usePolicyCancellationFlow();
  const translate = useLanguageTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { policyData } = location.state || {};

  const [selectedReasonOut, setSelectedReasonOut] = useState("");

  useEffect(() => {
    dispatch(resetPolicyCancellationFlow());
  }, [dispatch]);

  const handleNavigationToPolicyOverview = () => {
    const valueToEncrypt = policyData?.PolicyNumber;

    // Encryption logic
    const secretKey = "i-hate-meej";
    const encryptedValue = CryptoJS.AES.encrypt(
      valueToEncrypt,
      secretKey
    ).toString();

    // Convert to URL-safe format
    const urlSafeEncryptedValue = encryptedValue
      .split("+")
      .join("-") // Replace '+' with '-'
      .split("/")
      .join("_") // Replace '/' with '_'
      .split("=")
      .join(""); // Remove '=' padding

    navigate(`/motor-insurance/policy-overview/${urlSafeEncryptedValue}`, {
      state: {
        activeTab: "ManagePolicy",
        // data: policyData
      },
    });
  };

  // Get policy detail. Call API if empty or check redux
  const policyDetailsInfo = useSelector(getPolicyViewHeaderResponse);
  const policyDetailsPartyInfo = useSelector(getPolicyViewHeaderPartyResponse);
  const profileInfo = useSelector(selectProfileData);

  const confimedCancellation = async () => {
    const payload = {
      FirstName: policyDetailsPartyInfo?.FirstName || "",
      LastName: policyDetailsPartyInfo?.LastName || "",
      PhoneNumber: policyDetailsPartyInfo?.PhoneNumber || "",
      Email: profileInfo?.Email || "", // Add appropriate email or extract it from party info if available
      Subject: "Cancel Policy",
      Product: policyDetailsInfo?.ProductName || "Motor",
      Lob: "Auto",
      Descreption: `Policy to be cancelled. \nSelected Reason: ${selectedReasonOut}`,
      ClaimNumber: "",
      PolicyNumber: policyDetailsInfo?.PolicyNumber || "",
      VNumber: policyDetailsInfo?.ChasissNumber || "",
      Channel: "online.solidarity",
    };
    // Calling API case/complaint
    try {
      const caseComplaintAction = await dispatch(createCaseComplaint(payload));
      const caseComplaintResult = unwrapResult(caseComplaintAction);

      if (caseComplaintResult) {
        handleNavigationToPolicyOverview();
        toast.success(
          "Your request for cancellation has been successfully sent"
        );
      }
    } catch (error) {
      toast.error("Error in sending cancel policy request ", error);
    }
  };

  const PageRenderer = () => {
    switch (currentStep?.name) {
      case POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_REVIEW:
        return (
          <PolicyCancellationReviewPage
            handleNavigationToPolicyOverview={handleNavigationToPolicyOverview}
          />
        );
      case POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_REASON:
        return (
          <PolicyCancellationReasonPage
            handleNavigationToPolicyOverview={handleNavigationToPolicyOverview}
            setSelectedReasonOut={setSelectedReasonOut}
          />
        );
      case POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_CONFIRMATION:
        return (
          <ConfimPolicyCancellationPage
            handleNavigationToPolicyOverview={handleNavigationToPolicyOverview}
            confimedCancellation={confimedCancellation}
            selectedReasonOut={selectedReasonOut}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="mainCarDetailsModal pt-[5%] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll">
      <PageRenderer />
    </div>
  );
};

export default PolicyCancellationMotorInsurance;
