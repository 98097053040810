import React, { useMemo } from "react";
import "./policyCard.css";
import darkcardbgimage from "../../assets/images/darkcardBG.svg";
import { useSelector } from "react-redux";
import { selectPoliciesBgData } from "../../redux/features/myPolicySlice";
import { STRAPI_BASE_URL } from "../../api/baseURL";
import { useNavigate } from "react-router-dom";
import {
  daysCalculator,
  expireSoonCalculator,
  formatDate,
  generateQueryString,
} from "../../utils";
import { POLICY_TYPES } from "./data";
import Button from "../common/Button/Button";
import tickicon from "../../assets/images/done.svg";
import blueLogo from "../../assets/images/Logo.svg";
import whiteLogo from "../../assets/images/white_logo.svg";
import { useTranslation } from "react-i18next";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import CryptoJS from "crypto-js";

const PolicyCard = ({
  className,
  data,
  selectClickHandler,
  showSelectButton,
  isSelected,
  disableCardClick,
  policy_bg_image,
}) => {
  const navigate = useNavigate();
  const translate = useLanguageTranslate();
  const valueToEncrypt = data?.PolicyNumber;
  // Encryption logic
  const secretKey = "i-hate-meej";
  const encryptedValue = CryptoJS.AES.encrypt(
    valueToEncrypt,
    secretKey
  ).toString();

  // Convert to URL-safe format
  const urlSafeEncryptedValue = encryptedValue
    .split("+")
    .join("-") // Replace '+' with '-'
    .split("/")
    .join("_") // Replace '/' with '_'
    .split("=")
    .join(""); // Remove '=' padding
  const policiesBgs = useSelector(selectPoliciesBgData);
  const cardBg = useMemo(() => {
    let cardBgImage = policiesBgs?.find((each) =>
      each?.attributes?.crm_mapping_key?.split(",")?.includes(data?.ProductCode)
    );
    return cardBgImage?.attributes;
  }, [policiesBgs, data]);
  const navigateToUrl = () => {
    if (showSelectButton || disableCardClick) return;
    if (data?.ProductCode == POLICY_TYPES.motorInsurance) {
      navigate(`/motor-insurance/policy-overview/` + urlSafeEncryptedValue, {
        state: { data },
      });
    } else if (POLICY_TYPES.lifeInsurance?.includes(data?.ProductCode)) {
      let queryString = generateQueryString({
        policy: data?.PolicyNumber,
        status: data?.PolicyStatus,
        CPR: data?.CPR,
        // Make: data?.Make,
        // Model: data?.Model,
        // Plan: data?.PlanName,
        // startDate: data?.StartDate,
        // endDate: data?.EndDate,
        // regNo: data?.RegistrationNumber,
      });
      navigate(`/life-insurance/policy-overview?${queryString}`);
    }
  };

  const cardData = useMemo(() => {
    let formattedData = {
      policy_name: `${data?.ProductName || ""} Policy - ${
        data?.PlanName == "OFL"
          ? "Online Flexi"
          : data?.PlanName == "OPL"
          ? "Online Platinum"
          : data?.PlanName == "TPPLUS"
          ? "Third Party Plus"
          : data?.PlanName == "TPL"
          ? "Third Party"
          : data?.PlanName == "PL"
          ? "Platinum"
          : data?.PlanName == "GO"
          ? "Gold"
          : data?.PlanName == "FL"
          ? "Flexi"
          : data?.PlanName
      }`,
      policy_number: `${data?.PolicyNumber}`,
      from_date: formatDate(data?.StartDate),
      to_date: formatDate(data?.EndDate),
      expire_days: expireSoonCalculator(data?.EndDate, 30),
      isExpired: data?.PolicyStatus == "EXPIRED",
    };
    if (data?.ProductCode == POLICY_TYPES.motorInsurance) {
      formattedData.policy_label = `${data?.Make} ${data?.Model} - ${data?.RegistrationNumber}`;
    } else if (data?.ProductCode == POLICY_TYPES.tranvelInsurance) {
      formattedData.policy_label = `${daysCalculator(
        data?.StartDate,
        data?.EndDate
      )} ${translate("Days", "أيام")}`;
    } else if (POLICY_TYPES.lifeInsurance?.includes(data?.ProductCode)) {
      formattedData.policy_label = data?.CPR;
    }
    return formattedData;
  }, [data]);
  const { t } = useTranslation();

  return (
    <div
      className={`policy_card_root min-h-[202px] cursor-pointer relative w-[322px] max-w-full min-w-[322px] ipad:min-w-[322px] max767:max-w-[100%] max767:w-full max767:min-w-[332px] ${className}`}
      onClick={navigateToUrl}
    >
      <img
        // src={`${STRAPI_BASE_URL}${cardBg?.card_bg_image?.img?.data?.attributes?.url}`}
        src={
          policy_bg_image
            ? policy_bg_image
            : `${STRAPI_BASE_URL}${cardBg?.card_bg_image?.img?.data?.attributes?.url}`
        }
        alt=""
        className="bgImage relative  rounded-[20px] w-[100%] min-h-[202px] h-[100%] ipad:min-w-[280px] max767:min-w-[100%] max767:max-w-[100%]"
      />
      <img
        className="absolute right-4 top-6 rtl:left-3.5 rtl:right-[unset]"
        src={cardBg?.is_text_dark ? blueLogo : whiteLogo}
        alt=""
      />
      <div
        className={`policy_card_content flex flex-col justify-between absolute w-full h-full p-4 top-0 max767:p-4 min-h-[200px] ${
          cardBg?.is_text_dark ? "dark_text" : ""
        }`}
      >
        <p>
          {cardData?.expire_days && (
            <span className="bgWhite text-[#252C32] text-xs not-italic font-normal leading-4 rounded bg-[#F6F8F9] inline-flex items-center px-1 py-0">
              {t("motorInsurance.renewalPolicyFlow.policyCard.expiry_date")}{" "}
              {cardData?.expire_days}{" "}
              {t("motorInsurance.renewalPolicyFlow.policyCard.days")}
            </span>
          )}
        </p>
        <p>
          {!cardData?.expire_days && (
            <span className="bgYellow text-[#835101] text-xs not-italic font-normal leading-4 rounded bg-[#FFFCC2] px-1 py-0">
              {cardData?.isExpired
                ? translate("Expired", "منتهي الصلاحية")
                : translate("Active", "ساري")}
            </span>
          )}
        </p>
        <h3 className="text-white text-xs not-italic font-semibold mt-[35px]">
          {cardData?.policy_name}
        </h3>
        <p className="card-title text-white text-xl not-italic font-semibold leading-[normal] mb-[20px]">
          {cardData?.policy_label || <>&nbsp;</>}
        </p>
        <p className="policy_number text-white text-xs not-italic font-normal leading-normal">
          {cardData?.policy_number}
        </p>
        <p className="date-info text-white text-sm not-italic font-normal leading-normal">
          <span className="rtl:w-max rtl:inline-flex">
            {cardData?.from_date}
          </span>{" "}
          <span className="rtl:w-max rtl:inline-flex"> - </span>{" "}
          <span className="rtl:w-max rtl:inline-flex">{cardData?.to_date}</span>
        </p>
        {showSelectButton && (
          <>
            {isSelected ? (
              <Button
                pre_icon={tickicon}
                label={t(
                  "motorInsurance.renewalPolicyFlow.policyCard.selected"
                )}
                className="bg-[#55C6EF] text-[#1A2024] rounded-md text-center text-sm not-italic justify-center font-semibold leading-6 tracking-[-0.084px] flex h-8 items-center gap-2.5 shrink-0 self-stretch px-3 py-1"
              />
            ) : (
              <Button
                onSubmit={selectClickHandler}
                label={t("motorInsurance.renewalPolicyFlow.policyCard.select")}
                className="border bg-white rounded-md border-solid border-[#DDE2E4] justify-center text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] flex h-8 items-center gap-2.5 shrink-0 self-stretch px-3 py-1"
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PolicyCard;
