import React, { useEffect, useState } from "react";
import useFundsSwitchingFlow from "../../../../../../hooks/fundsSwitchingFlow";
import { FUNDS_SWITCHING_FLOW, FUNDS_SWITCHING_FLOW_ADD_FUNDS, FUNDS_SWITCHING_FLOW_FUNDS_ALLOCATION, FUNDS_SWITCHING_FLOW_SWITCH_FUNDS } from "./data";
import SwitchFundsPage from "./switchFundsPage";
import AddFundsPage from "./addFundsPage";
import FundsAllocationPage from "./fundsAllocationPage";
import useLifePolicyDetails from "../../../../../../hooks/life/useLifePolicyDetails";
import Loader from "../../../../../../components/common/Loader/Loader";
import { decodeQueryString } from "../../../../../../utils";


const FundsSwitchingFlow = () => {
    const decodedQueryString = decodeQueryString();
  const { currentStep, handleFlowChange } = useFundsSwitchingFlow();
  const [fundsToRemove, setFundsToRemove] = useState([])
  const [fundIdsToRemove, setFundIdsToRemove] = useState({});
  const { data, isLoading } = useLifePolicyDetails(decodedQueryString?.policy);
  const [newSelectedIds, setNewSelectedIds] = useState({});
  const [userFunds, setUserFunds] = useState(null);
  const [selectedNewFunds,setSelectedNewFunds] = useState([])
  const [isUserChangedFunds, setIsUserChangedFunds] = useState(false);

    useEffect(() => {
      handleFlowChange(FUNDS_SWITCHING_FLOW, 0);
    }, []);
 
  useEffect(() => {
    if (data ) {
      let funds = data?.LifeDetails?.policyList?.[0]?.funds;
      let formattedData = funds?.map((fund) => ({
        id: fund?.fundId,
        isin: fund?.fundISIN,
        fund_name: fund?.fundDetails,
        units_held: fund?.numberOfUnits,
        current_price: fund?.unitPrice,
        total_value: fund?.fundValueInPolicyCurrency,
        current_percent: fund?.allocationPercentage,
        new_percent: fund?.allocationPercentage || 0,

        // FUND SWITCHING MISSING
        no_of_unit: fund?.numberOfUnits,
        unit_price: fund?.unitPrice,
        fund_value: fund?.fundValueInPolicyCurrency,
        allocation: fund?.allocationPercentage,
      }));
      setUserFunds(formattedData);
    }
  }, [data]);

  const PageRenderer = () => {
    switch (currentStep?.name) {
      case FUNDS_SWITCHING_FLOW_SWITCH_FUNDS:
        return (
          <SwitchFundsPage
            fundIdsToRemove={fundIdsToRemove}
            setFundIdsToRemove={setFundIdsToRemove}
            fundsToRemove={fundsToRemove}
            setFundsToRemove={setFundsToRemove}
            userFunds={userFunds}
          />
        );
      case FUNDS_SWITCHING_FLOW_ADD_FUNDS:
        return (
          <AddFundsPage
            fundsToRemove={fundsToRemove}
            setUserFunds={setUserFunds}
            userFunds={userFunds}
            newSelectedIds={newSelectedIds}
            setNewSelectedIds={setNewSelectedIds}
            setSelectedNewFunds={setSelectedNewFunds}
          />
        );
      case FUNDS_SWITCHING_FLOW_FUNDS_ALLOCATION:
        return (
          <FundsAllocationPage
            data={selectedNewFunds}
            setSelectedNewFunds={setSelectedNewFunds}
            fundsToRemove={fundsToRemove}
            setUserFunds={setUserFunds}
            isUserChangedFunds={isUserChangedFunds}
            setIsUserChangedFunds={setIsUserChangedFunds}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="fundsSwitchingFlow pt-[80px] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll max767:pt-[98px]">
      {isLoading && <Loader className="w-10 h-10 fixed left-[50%] top-[50%]" />}

      <PageRenderer />
    </div>
  )
};


export default FundsSwitchingFlow;
