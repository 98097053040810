import React, { useEffect, useMemo, useState } from "react";
import FormTabs from "../../../components/common/tabs/formTabs";
import { useTranslation } from "react-i18next";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import useValidation from "../../../hooks/useValidation";
import { useSelector } from "react-redux";
import useLifeFormData from "../../../hooks/useLifeFormData";
import useUpdateLead from "../../../hooks/BuyOnlineExpressShield/useUpdateLead";
import { LEAD_INFO } from "../details/data";
import EssentialCareLayout from "../essentialCareLayout";
import { selectLoginTokenInfo } from "../../../redux/features/loginRegisterSlice";
import SignInModal from "../../motorInsurancePages/newMotorPolicy/planPages/signInModal";
import { selectEssentialCareAllPagesDataCms } from "../../../redux/features/essentialCare/essentialCareStrapiCmsSlice";
import Button from "../../../components/common/Button/Button";
import ViewEssentialCareBenefitsDetailsPopup from "../viewEssentialCareBenefitsDetailsPopup";
import listblackicon from "../../../assets/images/list-black.svg";
import { PERSONAL_DETAIL_QUESTIONS } from "./data";

export const yesNoTabs = [
    { label_en: "Yes", label_ar: "نعم", type: "Yes" },
    { label_en: "No", label_ar: "لا", type: "No" },
];
export const marritialTabs = [
    { label_en: "Married", label_ar: "متزوج", type: "Married" },
    { label_en: "Single", label_ar: "أعزب", type: "Single" },
];
export const genderTabs = [
    { label_en: "Male", label_ar: "ذكر", type: "Male" },
    { label_en: "Female", label_ar: "أنثى", type: "Female" },
];
export const ESSENTIAL_CARE_PERSONAL_DETAILS = "ESSENTIAL_CARE_PERSONAL_DETAILS";
const PersonalDetails = ({ onSuccess }) => {
    const { t } = useTranslation();
    const translate = useLanguageTranslate();
    const { updateLeadHandler, leadLoader } = useUpdateLead()


    const [formData, setFormData] = useState({
        answers: {},
    });
    const [viewPlanList, setViewPlanList] = useState(false);

    const { saveLifeDataInRedux } = useLifeFormData(
        ESSENTIAL_CARE_PERSONAL_DETAILS,
        setFormData
    );
    const [showSignIn, setShowSignIn] = useState(false);

    const { errors, validateFields } = useValidation();
    const validationSchemaGenerate = useMemo(() => {
        let schema = PERSONAL_DETAIL_QUESTIONS?.map((question) => ({
            name: `${question.mapping_key}`,
            validations: ["required"],
        }));
        return [...schema];
    }, []);

    const validateForm = (data) => {
        let form_data = data || formData;

        let isValid = validateFields(validationSchemaGenerate, form_data);
        return isValid;
    };

    const loginTokenInfo = useSelector(selectLoginTokenInfo);


    const togglePopupClickHandler = () => {
        setShowSignIn((state) => !state);
    };
    const onTabClick = (value, questionId) => {
        let update = { ...formData.answers, [questionId]: value };
        setFormData((prev) => ({
            ...prev,
            answers: update,
        }));
        if (errors[questionId]) {
            validateForm(update);
        }
    };

    const declarationSuccess = () => {
        saveLifeDataInRedux(formData)
        updateLeadHandler({
            Info: LEAD_INFO.questionnaire,
        })
        onSuccess();

    }
    const nextClickHandler = async () => {
        if (validateForm(formData.answers)) {
            if (!loginTokenInfo) {
                togglePopupClickHandler();
            } else {
                declarationSuccess();
            }
        }
    };



    return (
        <EssentialCareLayout is_bottom_sticky_layout btn_onClick={nextClickHandler}>
            <div className="w-[500px] max-w-[100%] mx-auto">
                <TitleDescription
                    title={translate(
                        "Your Personal Details",
                        "تفاصيلك الشخصية"
                    )}
                    description={translate(
                        "Please let us know your marital status and gender.",
                        "يرجى إعلامنا بحالتك الاجتماعية وجنسك"
                    )}
                />

                {PERSONAL_DETAIL_QUESTIONS?.map((question) => (
                    <div key={question.id} className="mb-6">
                        <FormTabs
                            label={translate(question.content_en, question.content_ar)}
                            tabs={
                                question.mapping_key ==="Martialstatus" ? marritialTabs : genderTabs
                            }
                            onTabClick={(type) => onTabClick(type, question.mapping_key)}
                            activeTab={formData.answers[question.mapping_key]}
                            error={errors?.[question?.mapping_key]}
                        />
                    </div>
                ))}
                <div className="mx-auto flex justify-center EssentialCare">
                    <Button
                        className="flex items-center gap-2 border bg-white px-4 py-2 rounded-[10px] border-solid border-[#DDE2E4] text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] ipad:mt-4"
                        onSubmit={() => setViewPlanList(true)}
                        pre_icon={listblackicon}
                        label={translate("Essential Care benefits & covers", "مزايا وتغطيات الرعاية الأساسية")}
                    />
                </div>
                <div className="ViewEssentialCareBenefitsDetailsPopup">
                    <ViewEssentialCareBenefitsDetailsPopup
                        viewPlanList={viewPlanList}
                        closePopup={() => setViewPlanList(false)}
                    />
                </div>
                <div className="EssentialReviewYourApplication">

                </div>
                <SignInModal
                    show={showSignIn}
                    setShow={setShowSignIn}
                    cbSuccess={declarationSuccess}
                />
            </div>
        </EssentialCareLayout>
    );
};

export default PersonalDetails;
