import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import { signUpApiEndpoints } from "../../../api";

const initialState = {
  isLoading: false,
  error: null,
  verificationData: null,
  cpr : null
};

// Async thunk for KYC verification
export const verifyKyc = createAsyncThunk(
  "verifyKyc",
  (queryString, { rejectWithValue }) => {
    return Axios.post(`${signUpApiEndpoints?.verifykyc}`, {
      cpr: queryString,
    })
      .then((response) => response.data)
      .catch((error) => {
        return rejectWithValue(error.response?.data || "Something went wrong");
      });
  }
);

const kycVerificationSlice = createSlice({
  name: "kycVerification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(verifyKyc.pending, (state,action) => {
        state.isLoading = true;
        state.cpr = action?.meta?.arg;
        state.error = null;
        state.verificationData = null;
      })
      .addCase(verifyKyc.fulfilled, (state, action) => {
        state.isLoading = false;
        state.verificationData = action.payload;
        state.error = null;
      })
      .addCase(verifyKyc.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.verificationData = null;
      });
  },
});

export const selectCprFromKycVerification = (store) => store?.kycVerification?.cpr
export const selectverifyKycLoader = (store) => store?.kycVerification?.isLoading

export default kycVerificationSlice.reducer;
