import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import {  aiApiEndpoints } from "../../../api";

const initialState = {
  ownershipJson: [],
  isLoading: false,
  success: null,
  error: null,
};


export const scanOwnership = createAsyncThunk(
    "scanOwnership",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling scanOwnership...")
            const response = await Axios.post(`${aiApiEndpoints.scanOwnership}`, payload);
            console.log("scanOwnership response is: ", response.data)

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);


export const scannerSlice = createSlice({
  name: "scannerSlice",
  initialState,
  reducers: {
    clearScannerSliceError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Get Quote API
    builder.addCase(scanOwnership.pending, (state, action) => {
      if (!state.data) {
        state.isLoading = true;
      }
    });
    builder.addCase(scanOwnership.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.error = null;
        const jsonString = action?.payload.replace(/^```json\s*/, "").replace(/\s*```$/, "");
        state.ownershipJson = JSON.parse(jsonString);
    
    //   state.ownershipJson = action?.payload.replace(/^```json\s*/, "")
    //   .replace(/\s*```$/, "");
    });
    builder.addCase(scanOwnership.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.success = false;
      console.log("Scan ownership error: ", action.payload )
    });
  },
});

// SELECTORS
export const getScanOwnershipResponse = (store) => store?.scanOS.ownershipJson;
export const getScanOwnershipLoading = (store) => store?.scanOS.isLoading;
export const getScanOwnershipError = (store) => store?.scanOS.error;
export const getScanOwnershipSuccess = (store) => store?.scanOS.success;


// export const getSumInsuredLoader = (store) => store?.getSumInsured.isLoading;


// ACTIONS TO DISPATCH
export const { clearScannerSliceError } = scannerSlice.actions;

export default scannerSlice.reducer;
