import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { useSelector } from 'react-redux';
import { selectRollingPerformanceData } from '../../../../../../../redux/features/allfunds/allFundsSlice';
import { formatDate, formatDate2 } from '../../../../../../../utils';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const RollingPerformanceChart = ({ title, chartData, labels, fundLabel, benchmarkLabel, fundColor, benchmarkColor }) => {
  // const rollingPerformanceChartData = useSelector(selectRollingPerformanceData);

  const data = useMemo(() => {
    let quarterlyData, sortOrder, last8Quarters, latestQuarters, latestReturns
    if (
      chartData?.quarterly_returns &&
      Object.keys(chartData.quarterly_returns).length > 0
    ) {
      quarterlyData = Object.entries(chartData.quarterly_returns);
      sortOrder = quarterlyData.reverse();
      // last8Quarters = sortOrder.slice(0, 8);
      latestQuarters = sortOrder.map(([quarter]) => quarter).reverse();
      latestReturns = sortOrder.map(([, value]) => value).reverse();
    }

    const update = {
      labels: latestQuarters,
      datasets: [
        {
          label: fundLabel,
          data: latestReturns,
          borderColor: fundColor,
          backgroundColor: 'rgba(255,165,0,0.2)',
          fill: true,
          tension: 0.1,
          pointRadius: 0,
          pointBackgroundColor: fundColor
        },
        // {
        //   label: benchmarkLabel,
        //   data: chartData.benchmarkPerformance,
        //   borderColor: benchmarkColor,
        //   // backgroundColor: `${benchmarkColor}33`, 
        //   fill: false,
        //   tension: 0.1, 
        //   pointRadius: 0,
        //   pointBackgroundColor: benchmarkColor

        // }
      ],
    };
    return update
  }, [chartData])


  const options = {
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          usePointStyle: true,
          padding: 20,
          pointRadius: 2,
        },
      },
      title: {
        display: false,
        text: 'Rolling Performance',
        align: 'start',
        font: {
          size: 18,
          weight: 'bold',
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 2,
        },
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: '',
        },
      },
      x: {
        ticks: {
          autoSkip: true, // Prevent skipping
        },
        title: {
          display: true,
          text: '',
        },
        grid: {
          display: false,
        },
      },
    },
  };



  return (
    <div className="w-full h-[400px] mt-8 p-6 bg-white rounded-[10px] border-solid border-[#DDE2E4] border">
      <h1 className='text-[#1A2024] text-base not-italic font-semibold leading-[normal] tracking-[-0.16px] mb-4'>{title}</h1>
      <Line data={data} options={options} className='pb-4' />
    </div>
  );
};

export default RollingPerformanceChart;
