import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import {  motorApiEndpoints } from "../../../api";


export const ScanBankLetter = createAsyncThunk(
    "ScanBankLetter",
    async (payload, { rejectWithValue }) => {
        try {
            console.log("Calling API...")
            const response = await Axios.post(`${motorApiEndpoints.scanBankLetter}`, payload);
            console.log("response is: ", response.data)

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

const initialState = {
    ScannedBank: null,
    status: 'idle', // idle | loading | succeeded | failed
    error: null
  }

  const ScanBankLetterSlice = createSlice({
    name: 'ScanBankLetter',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(ScanBankLetter.pending, (state) =>{
            state.status = 'loading';
        })
        .addCase(ScanBankLetter.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.ScannedBank = action.payload;
        })
        .addCase(ScanBankLetter.rejected, (state, action) =>{
            state.status = 'failed';
            state.error = action.error.message;
        })
    }
  });

export default ScanBankLetterSlice.reducer;
