import React, { useContext, useEffect, useState } from 'react'
import useRenewalFlow from '../../../../hooks/useRenewalFlow'
import RenewMotorLayout from '../renewMotorLayout';
import TitleDescription from '../../../../components/newMotorPolicy/titleDescription/titleDescription';
import { useTranslation } from 'react-i18next';
import CoverOption from '../../newMotorPolicy/addons/CoverOption';
import useMotorForm from '../../../../hooks/useMotorFormData';
import { useDispatch, useSelector } from 'react-redux';
import { getPolicyPremiumResponse, policyPremium } from '../../../../redux/features/motor/renewalPremiumSlice';
import { getCoverPrices, getQuoteCoversResponse } from '../../../../redux/features/motor/getQuoteSlice';
import { coverPriceRequest, extractCoverOptions } from '../../newMotorPolicy/planPages/data';
import { selectNewMotorForm } from '../../../../redux/features/newMotorFormSlice';
import { RENEW_POLICY_SELECTED_POLICY } from '../selectPoliciesPage/selectPoliciesPage';
import { COVERAGE_ENHANCEMENT } from '../../newMotorPolicy/addons/coverageEnhancement';
import { unwrapResult } from '@reduxjs/toolkit';
import useAddMoreCoversFlow from '../../../../hooks/useAddMoreCoversFlow';
import AddMoreCoversMotorLayout from '../../addMOreCoversFlow/addMoreCoversMotorLayout';
import { useParams } from 'react-router-dom';
import { setCoverDataOptions, carReplacementPrice } from '../../newMotorPolicy/addons/CoverData';
import CoverOptionsAddons from '../../newMotorPolicy/addons/CoverOptionsAddons';
import { PriceContext } from '../../newMotorPolicy/Layouts/PriceContext';
import { getPolicyViewHeaderResponse } from '../../../../redux/features/policyDetailsSlice';
import BottomPriceLayout from '../../newMotorPolicy/Layouts/BottomPriceLayout';
export const ADD_COVERS_ENHANCEMENT =
  "ADD_COVERS_ENHANCEMENT";

const CoversLists = (
  coversInPolicy
) => {

  /* Setting up dispatch to call redux */
  const dispatch = useDispatch();
  const { policy_id } = useParams();
  /* Setting up variables with appropriate requests to send and response to receive, reading from redux/functions */
  // const policyPremiumResponse = useSelector(getPolicyPremiumResponse);
  const policyViewResponse = useSelector(getPolicyViewHeaderResponse);
  

  const coverPricesPayload = coverPriceRequest(policy_id, policyViewResponse?.PlanName, "", "");
  const coverPricesResponse = useSelector(getQuoteCoversResponse);
  const selectedPolicyRenewalInfo = useSelector(selectNewMotorForm)?.[RENEW_POLICY_SELECTED_POLICY];
  const finalCovers = useSelector(selectNewMotorForm)?.[ADD_COVERS_ENHANCEMENT];
  const currentSelectedCarDays = finalCovers ? finalCovers?.coverOptionsState?.find(car => car.code =="CMOT28")?.selectedDays : "8";
  const currentSelectedCarSize = finalCovers? finalCovers?.coverOptionsState?.find(car => car.code =="CMOT28")?.selectedCarType : "SMALL";

  /* Functions and Variables that are updated with functions/state */
  const [coverOptions, setCoverOptionsState] = useState(setCoverDataOptions(coverPricesResponse, policyViewResponse?.PlanName, null, coversInPolicy)); 


  const { handleNextSubstep } = useAddMoreCoversFlow();
  const { t } = useTranslation();
  const [formState, setFormState] = useState({
    coverOptionsState: finalCovers ==null ? coverOptions : finalCovers,
  });
  const policyDetails = useSelector((state) => state.policyDetails.PolicyDetails);
  const { saveMotorDataInRedux } = useMotorForm(
    ADD_COVERS_ENHANCEMENT,
    setFormState
  );
  // const { setTotalPrice } = useContext(PriceContext); // Access setTotalPrice from the context

  // Function to reset total price

  // const updateOptionValues = (name, updates) => {
  //   let update = {
  //     ...formState,
  //     coverOptionsState: formState.coverOptionsState.map((option) =>
  //       option.name ==name ? { ...option, ...updates } : option
  //     ),
  //   };
  //   setFormState(update);
  //   saveMotorDataInRedux(update);
  // };
  console.log('on init, final covers are: ', finalCovers);
  console.log('on init, form state is: ', formState);
  console.log('on init, cover option is: ', coverOptions);

  // Temp price to add the cover price to the total in the bottom layout
  const [tempPrice, setTempPrice] = useState(0);
  const [tempIncluded, setTempIncluded] = useState("");
  const [tempCarReplacement, setTempCarReplacement] = useState("");

  const updateOptionValues = async (name, updates, price) => {
    setTempCarReplacement(false);

    //console.log("updating cover.", name, updates);
    // If Platinum plan, setting default based on selected. Setting minimum for SMALL30 and MEDIUM15
    if (policyViewResponse.PlanName =="PL") {
      if (updates.selectedCarType =="SMALL") {
        updates.selectedDays = "30";
      } else if (updates.selectedCarType =="MEDIUM") {
        updates.selectedDays = "15";
      }
    }

    if (name =="CMOT28") {
      console.log('Price is : ', price);
      price = await carReplacementPrice(updates.selectedDays ? updates.selectedDays : currentSelectedCarDays, updates.selectedCarType ? updates.selectedCarType : currentSelectedCarSize);
      updates.price = price;
      setTempCarReplacement(true);
    }

    setTempPrice(price);
    console.log('Is cover included?', updates.included);
    setTempIncluded(updates.included);

    let update = {
      ...formState,

      coverOptionsState: formState.coverOptionsState.map((option) =>
        option.code ==name ? { ...option, ...updates } : option
      ),
    };

    // Set form state, which is basically the updated covers and their properties
    setFormState(update);
    // Saves the updated covers in redux COVERAGE_ENHANCEMENT to use globally
    await saveMotorDataInRedux(update);

  };


  /* Next page */
  const continueClickHanlder = () => {
    handleNextSubstep();
  };
  
  return (
    <AddMoreCoversMotorLayout
      is_hide_back_btn
      show_close_btn
      show_summary
      btn_onClick={continueClickHanlder}
    >
      <BottomPriceLayout
        show_summary
        btn_onClick={continueClickHanlder}
        price={tempPrice}
        included={tempIncluded}
        carReplacement={tempCarReplacement}
      >
        <div className="coverageEnhancement flex flex-col items-center p-6 mx-auto ">
          <TitleDescription
            title={t(
              "motorInsurance.renewalPolicyFlow.enhanceYourCoverage.title"
            )}
            description={t(
              "motorInsurance.renewalPolicyFlow.enhanceYourCoverage.description"
            )}
          />
          <div className=" w-[33%] max-350:w-[100%] max767:w-[100%] ipad:w-[55%]">
            {/* Error handling. Making sure formState.coverOptionsState is an array */}
            {Array.isArray(formState.coverOptionsState) &&
              formState.coverOptionsState.map((option) => (
                <CoverOptionsAddons
                  key={option.code}
                  option={option}
                  updateOptionValues={updateOptionValues}
                  disabled={option.disabled}
                />
              ))}
          </div>
        </div>
      </BottomPriceLayout>
    </AddMoreCoversMotorLayout>
  );
}

export default CoversLists