
import fundPerfomancePage1 from "../../../../assets/images/fundPerfomancePage1.svg";
import SwicthFundsIcon from "../../../../assets/images/SwicthFundsIcon.svg"
import InvestmentStratergyIcon from "../../../../assets/images/InvestmentStratergyIcon.svg"


export const INVESTMENT_STRATEGY_CHANGE = "INVESTMENT_STRATEGY_CHANGE";
export const CHANGE_TO_FUND_MANAGEMENT = "CHANGE_TO_FUND_MANAGEMENT";

export const FUND_MANAGEMENT = "Change existing or future funds";
export const CHANGE_TO_INVESTMENT = "Change to Investment Strategy";


export const fundMangementCardsData2 = (current_strategy) =>  ([
    {
        icon: InvestmentStratergyIcon,
        label_en: "Investment strategy",
        label_ar : "إدارة الأموال",
        value: current_strategy,
        value_ar : current_strategy,
        type : INVESTMENT_STRATEGY_CHANGE,
        btn: {
            btn_label_en: "Change",
            btn_label_ar: "يتغير",
        },
    },
    {
        icon: SwicthFundsIcon,
        label_en: "Funds Management",
        label_ar : "استراتيجية الاستثمار",
        value: "Change to funds management",
        value_ar : "محافظ",
        type : CHANGE_TO_FUND_MANAGEMENT
       
    }  
])


export const fundMangementCardsData = [
    {
        icon: SwicthFundsIcon,
        label_en: "Funds Management",
        label_ar : "استراتيجية الاستثمار",
        value: FUND_MANAGEMENT,
        type : FUND_MANAGEMENT,
        value_ar : "محافظ",
        btn: {
            btn_label_en: "Change",
            btn_label_ar: "يتغير",
        },
    },

    {
        icon: InvestmentStratergyIcon,
        label_en: "Investment strategy",
        label_ar : "إدارة الأموال",
        value: CHANGE_TO_INVESTMENT,
        type : CHANGE_TO_INVESTMENT,
        value_ar : "التغيير في إدارة الأموال"
    },

]


