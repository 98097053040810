import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";
import { Link, useNavigate } from "react-router-dom";
import Chevron from "../../components/common/chevron/chevron";
import NewPolicyCard from "../../components/common/newPolicyCard/newPolicyCard";
import { useTranslation } from "react-i18next";
import {
  fetchNewPoliciesStaticData,
  selectNewPoliciesStaticData,
} from "../../redux/features/newPolicySlice";
import righticon from "../../assets/images/Chevronright.svg";
import CarInsurance from "../../assets/images/CarInsurance.svg";
import HomeInsurance from "../../assets/images/HomeInsurance.svg";
import TravelInsurance from "../../assets/images/TravelInsurance.svg";
import DomesticHelper from "../../assets/images/DomesticInsurance.svg";
import PersonalAccident from "../../assets/images/PersonalInsurance.svg";
import EssentialCare from "../../assets/images/EssentialHealth.svg"
import MedicalInsurance from "../../assets/images/MedicalInsurance.svg";
import ExpatMedicalVisa from "../../assets/images/HumanSafety.svg";
import PersonalCyber from "../../assets/images/cyber.svg";
import SmartDevice from "../../assets/images/SmartDevice.svg";
import BUPA from "../../assets/images/BUPA.svg";
import iCareCardiacshiled from "../../assets/images/Rectangle76.svg";
import iCarecancershield from "../../assets/images/Rectangle77.svg";
import Hemayashield from "../../assets/images/Rectangle79.svg";
import Royapersonalshield from "../../assets/images/Rectangle78.svg";
import Expressshield from "../../assets/images/Rectangle80.svg";
import { selectProfileData } from "../../redux/features/profileSlice";
import useScreenResolution from "../../hooks/useScreenResolution";
import closeicon from "../../assets/images/close.svg";
import { ANGULAR_URL } from "../../api/baseURL";

// const cardDetails = {
//   generalInsurance: [
//     {
//       imgUrl: CarInsurance,
//       title: "Motor Insurance",
//       title_ar: "تأمين السيارات",
//       is_internal: true,
//       link: "/new-motor-policy",
//     },
//     {
//       imgUrl: HomeInsurance,
//       title: "Home Insurance",
//       title_ar: "تأمين المنزل",
//       is_internal: false,
//       link: `https://dev-online.solidaritynet.com/insurance-policies/home-insurance/${profileData.token}`,
//     },
//     {
//       imgUrl: TravelInsurance,
//       title: "Travel Insurance",
//       title_ar: "تأمين السفر",
//       link: "https://dev-online.solidaritynet.com/insurance-policies/travel-insurance",
//     },
//     {
//       imgUrl: DomesticHelper,
//       title: "Domestic Helper",
//       title_ar: "عاملة منزلية",
//       link: "https://dev-online.solidaritynet.com/insurance-policies/domestic-helper",
//     },
//     {
//       imgUrl: PersonalAccident,
//       title: "Personal Accident",
//       title_ar: "الحوادث الشخصية",
//       link: "https://dev-online.solidaritynet.com/insurance-policies/personal-accident",
//     },
//     {
//       imgUrl: MedicalInsurance,
//       title: "Medical Insurance",
//       title_ar: "التأمين الطبي",
//       link: "https://dev-online.solidaritynet.com/insurance-policies/medical-insurance",
//     },
//     {
//       imgUrl: ExpatMedicalVisa,
//       title: "Expat Medical Visa",
//       title_ar: "تأشيرة طبية للمغتربين",
//       link: "https://dev-online.solidaritynet.com/insurance-policies/expat-medical-visa",
//     },
//     {
//       imgUrl: PersonalCyber,
//       title: "Personal Cyber",
//       title_ar: "السيبرانية الشخصية",
//       link: "https://dev-online.solidaritynet.com/insurance-policies/smart-device",
//     },
//     {
//       imgUrl: SmartDevice,
//       title: "Smart Device",
//       title_ar: "جهاز ذكي",
//       link: "https://dev-online.solidaritynet.com/insurance-policies/smart-device",
//     },
//     {
//       imgUrl: BUPA,
//       title: "BUPA",
//       title_ar: "بوبا",
//     },
//   ],
//   lifeInsurance: [
//     {
//       imgUrl: iCareCardiacshiled,
//       title: "iCare Cardiac shiled",
//       title_ar: "درع القلب من آي كير",
//     },
//     {
//       imgUrl: iCarecancershield,
//       title: "iCare cancer shield",
//       title_ar: "درع آي كير للسرطان",
//     },
//     {
//       imgUrl: Hemayashield,
//       title: "Hemaya shield",
//       title_ar: "حماية لايت",
//     },
//     {
//       imgUrl: Royapersonalshield,
//       title: "Roya personal shield",
//       title_ar: "رايا با",
//     },
//     {
//       imgUrl: Expressshield,
//       title: "Express shield",
//       title_ar: "درع سريع",
//       link: "/buy-online/express-shield",
//     },
//   ],
// };



const NewPolicy = () => {

  const profileData = useSelector(selectProfileData);
  const token = profileData?.Id || null;
  const {mobile} = useScreenResolution()
  const navigate = useNavigate()

  const cardDetails = {
    generalInsurance: [
      {
        imgUrl: CarInsurance,
        title: "Motor Insurance",
        title_ar: "تأمين السيارات",
        is_internal: true,
        link: "/new-motor-policy",
      },
      {
        imgUrl: HomeInsurance,
        title: "Home Insurance",
        title_ar: "تأمين المنزل",
        is_internal: false,
        link: token
          ? `${ANGULAR_URL}/insurance-policies/home-insurance?token=${token}&&react=true`
          : `${ANGULAR_URL}/insurance-policies/home-insurance?react=true`,
      },
      {
        imgUrl: TravelInsurance,
        title: "Travel Insurance",
        title_ar: "تأمين السفر",
        link: token
          ? `${ANGULAR_URL}/insurance-policies/travel-insurance?token=${token}&&react=true`
          : `${ANGULAR_URL}/insurance-policies/travel-insurance?react=true`,
      },
      {
        imgUrl: DomesticHelper,
        title: "Domestic Helper",
        title_ar: "عاملة منزلية",
        link: token
          ? `${ANGULAR_URL}/insurance-policies/domestic-helper?token=${token}&&react=true`
          : `${ANGULAR_URL}/insurance-policies/domestic-helper?react=true`,
      },
      {
        imgUrl: PersonalAccident,
        title: "Personal Accident",
        title_ar: "الحوادث الشخصية",
        link: token
          ? `${ANGULAR_URL}/insurance-policies/personal-accident?token=${token}&&react=true`
          : `${ANGULAR_URL}/insurance-policies/personal-accident?react=true`,
      },
      // {
      //   imgUrl: EssentialCare,
      //   title: "Essential Care",
      //   title_ar: "الرعاية أساسية",
      //   is_internal: true,
      //   link: "/essential-care",
      //   isNew: true,
      // },
      {
        imgUrl: MedicalInsurance,
        title: "Medical Insurance",
        title_ar: "التأمين الطبي",
        link: token
          ? `${ANGULAR_URL}/insurance-policies/medical-insurance?token=${token}&&react=true`
          : `${ANGULAR_URL}/insurance-policies/medical-insurance?react=true`,
      },
      {
        imgUrl: ExpatMedicalVisa,
        title: "Expat Medical Visa",
        title_ar: "تأشيرة طبية للمغتربين",
        link: token
          ? `${ANGULAR_URL}/insurance-policies/expat-medical-visa?token=${token}&&react=true`
          : `${ANGULAR_URL}/insurance-policies/expat-medical-visa?react=true`,
      },
      {
        imgUrl: PersonalCyber,
        title: "Personal Cyber",
        title_ar: "السيبرانية الشخصية",
        link: token
          ? `${ANGULAR_URL}/insurance-policies/personal-cyber?token=${token}&&react=true`
          : `${ANGULAR_URL}/insurance-policies/personal-cyber?react=true`,
      },
      {
        imgUrl: SmartDevice,
        title: "Smart Device",
        title_ar: "جهاز ذكي",
        link: token
          ? `${ANGULAR_URL}/insurance-policies/smart-device?token=${token}&&react=true`
          : `${ANGULAR_URL}/insurance-policies/smart-device?react=true`,
      },
      {
        imgUrl: BUPA,
        title: "BUPA",
        title_ar: "بوبا",
        link: token
          ? `${ANGULAR_URL}/insurance-policies/bupa?token=${token}&&react=true`
          : `${ANGULAR_URL}/insurance-policies/bupa?react=true`,
      },
    ],
    lifeInsurance: [
      // {
      //   imgUrl: iCareCardiacshiled,
      //   title: "iCare Cardiac shiled",
      //   title_ar: "درع القلب من آي كير",
      // },
      // {
      //   imgUrl: iCarecancershield,
      //   title: "iCare cancer shield",
      //   title_ar: "درع آي كير للسرطان",
      // },
      // {
      //   imgUrl: Hemayashield,
      //   title: "Hemaya shield",
      //   title_ar: "حماية لايت",
      // },
      // {
      //   imgUrl: Royapersonalshield,
      //   title: "Roya personal shield",
      //   title_ar: "رايا با",
      // },
      {
        imgUrl: Expressshield,
        title: "Express shield",
        title_ar: "إكسبرس شيلد",
        link: token
          ? `/buy-online/express-shield?token=${token}`
          : "/buy-online/express-shield",
      },
    ],
  };

  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const translate = useLanguageTranslate();

  useEffect(() => {
    dispatch(fetchNewPoliciesStaticData());
  }, []);
  const newPolicyStaticReducerData = useSelector(selectNewPoliciesStaticData);

  return (
    <div className="newPolicy p-6 ipad:p-4 max767:mt-[0px] max767:p-5">
      <div>
        {mobile && <button
                    className="bpy-2 rounded mt-4 flex absolute top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:ml-0"
                    onClick={() => navigate("/")}
                >
                    <img src={closeicon} alt="Close Icon" />
                </button>}
        <div className="newPolicy flex align-middle justify-between max767:hidden ">
          <div className="breadcrump flex align-middle gap-10">
            <ul className="inline-flex gap-2">
              <li className="inline-flex">
                {" "}
                <Link
                  to="/"
                  className="text-xs not-italic font-normal leading-4 text-[#6E7C87]"
                >
                  {t("newPolicy.online_services_label")}
                </Link>{" "}
                <span className="ml-2 rtl:ml-0 rtl:mr-2">
                  <img src={righticon} alt="right icon" className="rtl:rotate-180"/>
                </span>
              </li>
              <li className="bread-active text-xs not-italic font-normal leading-4 inline-flex text-black">
                {" "}
                {t("newPolicy.new_insurance_policies_label")}
              </li>
            </ul>
          </div>
          <p className="hidden last-login text-xs not-italic font-normal leading-4 text-gray-700">
            {t("commonLabels.last_login_label")}: 03 November 2024 at 05:20
          </p>
        </div>
        <div className="content">
          <div className="mb-[26px] max767:p-0 max767:hidden">
            <h3 className="text-black text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mt-10 mb-1.5 mx-0">
              {translate(
                newPolicyStaticReducerData?.headings_content?.title_en,
                newPolicyStaticReducerData?.headings_content?.title_ar
              )}
            </h3>
            <span className="text-black text-sm not-italic font-normal leading-5">
              {translate(
                newPolicyStaticReducerData?.headings_content?.description_en,
                newPolicyStaticReducerData?.headings_content?.description_ar
              )}
            </span>
          </div>
          <div className="generalInsuranceSection max767:p-0">
            <Chevron
              className=""
              isDefaultOpen={true}
              alwaysKeepOpen={true}
              Header={
                <div className="">
                  <h1 className="text-[#252C32] text-xl not-italic font-semibold leading-6 tracking-[-0.084px]">
                    {t("newPolicy.general_insurance_label")}
                  </h1>
                </div>
              }
            >
              <div className="generalInsuranceCards flex items-start content-start gap-3 flex-wrap md:bg-white sm:bg-[#ffffff] rtl:pr-0 ipad:bg-white ipad:p-0 ">
                {cardDetails.generalInsurance.map((card, index) => (
                  <NewPolicyCard
                    key={index}
                    imageSrc={card.imgUrl}
                    title={translate(card.title, card.title_ar)}
                    is_internal={card.is_internal}
                    link={card.link}
                    isNew={ card.isNew}
                  />
                ))}
              </div>
            </Chevron>
          </div>
          <div className="lifeInsuranceSection p-5 pl-0 pr-0 max767:p-0 max767:mt-4">
            <Chevron
              className=""
              isDefaultOpen={true}
              alwaysKeepOpen={true}
              Header={
                <div className="flex align-middle">
                  <h1 className="text-[#252C32] text-xl not-italic font-semibold leading-6 tracking-[-0.084px]">{t("newPolicy.life_insurance_label")}</h1>
                </div>
              }
            >
              <div className="lifeInsuranceCards flex items-start content-start gap-3 flex-wrap sm:p-0 max767:mb-4 ipad:bg-white ipad:pl-0 mb-4">
                <p className="flex w-full hidden">
                  {t("newPolicy.buy_online_plans_label")}
                </p>
                {cardDetails.lifeInsurance.map((card, index) => (
                  <NewPolicyCard
                    key={index}
                    imageSrc={card.imgUrl}
                    title={translate(card.title, card.title_ar)}
                    link={card.link}
                    is_internal
                  />
                ))}
              </div>
              <div className="flex justify-between items-center self-stretch w-full border-t-[#E1D9CB] border-t border-solid gap-2.5 bg-[#FBF7F0] p-6 hidden">
                <p className="text-black text-base not-italic font-normal leading-6 tracking-[-0.16px]">
                  {t("newPolicy.contact_advisor_message")}
                </p>
                <button className="flex flex-col items-center gap-2.5 border bg-white px-4 py-2 rounded-[10px]">
                  {t("newPolicy.speak_to_our_advisor_label")}
                </button>
              </div>
            </Chevron>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPolicy;
