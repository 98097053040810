import React, { useEffect, useState } from "react";
import Tabs from "../../../components/common/tabs/tabs";
import MotorPolicyCard from "../../../components/motorPolicyCard/motorPolicyCard";
import PolicyOverviewTab from "./tabViews/policyOverviewTab";
import {
  coverageAndBenefits,
  manageMyPolicy,
  PolicyDetailsGlobal,
  policyOverview,
  vehicleCertificate,
} from "./motorPolicyDataFile";
import VehicleCertificateTab from "./tabViews/vehicleCertificateTab";
import ManagePolicyTab from "./tabViews/managePolicyTab";
import CoversAndBenefitTab from "./tabViews/coversAndBenefitTab";
import useScreenResolution from "../../../hooks/useScreenResolution";
import MobileFooter from "../../../components/common/sideNavbar/mobileFooter";
import { useDispatch, useSelector } from "react-redux";
import { policyView } from "../../../redux/features/motor/getQuoteSlice";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { decodeQueryString } from "../../../utils";
import Breadcrumb from "../../lifeInsurancePages/PolicyOverview/Breadcrumb";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { useTranslation } from "react-i18next";
import CryptoJS from "crypto-js";
import { useFeatureFlagEnabled } from "posthog-js/react";

const PolicyDetailsPage = () => {
  // posthog
  const AddCoversEnabled = useFeatureFlagEnabled("Add_Covers");
  const ChangePlanEnabled = useFeatureFlagEnabled("Change_plan");
  const CancelPolicyEnabled = useFeatureFlagEnabled("Cancel_policy");
  const ChangePlateEnabled = useFeatureFlagEnabled("change_plate_number");
  const AdjustCarValueEnabled = useFeatureFlagEnabled("adjust_car_value");
  const TransferPolicyEnabled = useFeatureFlagEnabled("transfer_policy");
  const RemoveBankEnabled = useFeatureFlagEnabled("remove_bank");

  const location = useLocation();
  const translate = useLanguageTranslate();
  const policyNumber = location.state?.data?.PolicyNumber;
  const GetPolicyDetails = location.state?.data;
  const { i18n } = useTranslation();
  const { policy_id } = useParams();

  const secretKey = "i-hate-meej";

  // Revert to Base64 format using split and join
  const base64EncryptedValue = policy_id
    .split("-")
    .join("+") // Replace '-' with '+'
    .split("_")
    .join("/"); // Replace '_' with '/'

  // Decrypt the value
  const bytes = CryptoJS.AES.decrypt(base64EncryptedValue, secretKey);
  const originalValue = bytes.toString(CryptoJS.enc.Utf8);
  console.log(originalValue);
  const [searchParams] = useSearchParams();

  const [PolicyNumber, setPolicyNumber] = useState(originalValue);
  const [Channel, setChannel] = useState("online.solidarity");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const policyDetails = useSelector(
    (state) => state.policyDetails.PolicyDetails
  );
  const policyDetailsStatus = useSelector(
    (state) => state.policyDetails.status
  );
  const policyDetailsError = useSelector((state) => state.policyDetails.error);
  const [breadCrumbData, setBreadCrumbData] = useState(null);

  const polReq = {
    PolicyNumber,
    Channel,
  };

  // const PolicyViewReq = {
  //   PolicyNumber: "P/003/14/20/8003/0020575-02",
  //   Channel: "online.solidarity"
  // }
  //const PolicyViewresDetails = useSelector(PolicyViewResponse);

  const [activeTab, setActiveTab] = useState(
    location.state?.activeTab || "PolicyOverview"
  );
  const [downloadMenuClicked, setDownloadMenuClicked] = useState(false);
  const [policyLabel, setPolicyLabel] = useState("");
  const screenResolution = useScreenResolution();
  const toggleDownloadMenuClicked = () => {
    setDownloadMenuClicked(!downloadMenuClicked);
  };

  const handleTabClick = (tabType) => {
    setActiveTab(tabType);

    const Path = `${location.pathname}${location.search}`;

    if (location.state?.activeTab) {
      const { activeTab, ...restState } = location.state;
      navigate(Path, { state: { ...restState, activeTab: "" } });
    }
  };

  useEffect(() => {
    setBreadCrumbData([
      {
        label: translate("My Policy", "وثيقتي"),
        link: "/my-policy",
        clickable: true,
        name: "my_policy",
      },
      {
        label: translate("Motor Policy", "وثيقة التأمين على السيارات"),
        clickable: false,
        name: "current_plan",
        isActive: true,
      },
    ]);
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    if (policyDetails == null) {
      dispatch(policyView(polReq));
    } else if (policyDetails?.PolicyHeader?.PolicyNumber != policyNumber) {
      dispatch(policyView(polReq));
    }
    console.log(policyDetails);
    console.log("Testing: " + policyDetailsStatus);
  }, [dispatch]);

  useEffect(() => {
    if (policyDetailsStatus == "succeeded") {
      console.log("Policy Make: ", policyDetails);
    }
  }, [policyDetails, policyDetailsStatus]);
  const Info = {
    PolicyNumber: originalValue,
    status: "",
    Make: "",
    Model: "",
    PlanName: "",
    StartDate: "",
    EndDate: "",
    RegNo: "",
  };
  const tabs = [
    {
      label_en: "Policy Overview",
      label_ar: "نظرة عامة على الوثيقة",
      type: "PolicyOverview",
    },
    {
      label_en: "Vehicle Certificate",
      label_ar: "شهادة المركبة",
      type: "VehicleCertificate",
    },
    // {
    //   label_en: "Covers & Benefits",
    //   label_ar: "التغطيات والمزايا",
    //   type: "Covers&Benefits",
    // },
    {
      label_en: "Manage Policy",
      label_ar: "إدارة الوثيقة",
      type: "ManagePolicy",
    },
  ];

  vehicleCertificate.tableData = [];
  vehicleCertificate.tableData = [
    {
      id: 1,
      column1: "Owner",
      column1_ar: "مالك",
      column2:
        policyDetails?.PolicyHeader?.Party?.FirstName +
        " " +
        policyDetails?.PolicyHeader?.Party?.LastName, // Correctly assigning the variable without curly braces
      btn: { btn_lable_en: "Transfer", btn_lable_ar: "تحويل" },
    },
    {
      id: 2,
      column1: "Policy Number",
      column1_ar: "رقم الوثيقة",
      column2: policyDetails?.PolicyHeader.PolicyNumber,
    },
    {
      id: 3,
      column1: "Start Date",
      column1_ar: "تاريخ البدء",
      column2: policyDetails?.PolicyHeader.FromDateStr,
    },
    {
      id: 4,
      column1: "End Date",
      column1_ar: "تاريخ النهاية",
      column2: policyDetails?.PolicyHeader.ToDateStr,
    },
    {
      id: 5,
      column1: "Plate Number",
      column1_ar: "رقم اللوحة",
      column2:
        policyDetails?.PolicyHeader.RegistrationNumber == ""
          ? ""
          : policyDetails?.PolicyHeader.RegistrationNumber,
      btn: {
        btn_lable_en:
          policyDetails?.PolicyHeader.RegistrationNumber == ""
            ? "Add"
            : "Change",
        btn_lable_ar: "يتغير",
      },
    },
    {
      id: 6,
      column1: "Chassis Number",
      column1_ar: "رقم الهيكل",
      column2: policyDetails?.PolicyHeader.ChasissNumber,
    },
    {
      id: 7,
      column1: "Make",
      column1_ar: "يصنع",
      column2: policyDetails?.PolicyHeader.Make,
    },
    {
      id: 8,
      column1: "Manufacturing Year",
      column1_ar: "سنة التصنيع",
      column2: policyDetails?.PolicyHeader.ModelYear,
    },
    {
      id: 9,
      column1: "Body Type",
      column1_ar: "نوع الجسم",
      column2: policyDetails?.PolicyHeader.BodyTypeCode,
    },
    {
      id: 10,
      column1: "Engine Capacity",
      column1_ar: "سعة المحرك",
      column2: policyDetails?.PolicyHeader.EngineSize + " CC",
    },
    {
      id: 11,
      column1: "Seating Capacity",
      column1_ar: "سعة المقاعد",
      column2: policyDetails?.PolicyHeader.Seats + " " + "Seater",
    },
    // {
    //   id: 12,
    //   column1: "Vehicle Colour",
    //   column1_ar: "لون السيارة",
    //   column2: policyDetails?.PolicyHeader?.ColorCode ? "" : "NA"
    // },
    {
      id: 13,
      column1: "Type of Cover",
      column1_ar: "نوع الغطاء",
      column2:
        policyDetails?.PolicyHeader.ProductName +
        " - " +
        policyDetails?.PolicyHeader.BodyTypeCode,
    },
    // {
    //   id: 14,
    //   column1: "Car Value",
    //   column1_ar: "قيمة السيارة (مبلغ التأمين)",
    //   column2: "BHD 7,000/-",
    //   btn: { btn_lable_en: "Adjust", btn_lable_ar: "يُعدِّل" }
    // },
    // {
    //   id: 15,
    //   column1: "Excess",
    //   column1_ar: "إفراط",
    //   column2: "BHD 50/-"
    // },
    // {
    //   id: 16,
    //   column1: "Unknown Claim Excess",
    //   column1_ar: "مطالبة غير معروفة",
    //   column2: "BHD 100/-"
    // }
  ];

  //coverageAndBenefits.includeBenefits = [];
  // coverageAndBenefits.optionalCoverage = [];
  const defaultBenefits = [
    {
      id: 1,
      description: "5 Years Agency Repair",
      description_ar: "إصلاح الوكالة لمدة 3 سنوات",
    },
    {
      id: 2,
      description: "BHD 500,000 Loss or Damage of Vehicle",
      description_ar: "500,000 دينار بحريني خسارة أو تلف المركبة",
    },
    {
      id: 3,
      description: "Unlimited Third Party Bodily Injury",
      description_ar: "إصابة جسدية غير محدودة لطرف ثالث",
    },
    {
      id: 4,
      description: "BHD 500 Emergency Treatment Cover",
      description_ar: "تغطية العلاج الطارئ بقيمة 500 دينار بحريني",
    },
    {
      id: 5,
      description: "Towing Cover",
      description_ar: "غطاء القطر",
    },
    {
      id: 6,
      description: "3 Years NIL Depreciation on Parts",
      description_ar: "3 سنوات من عدم الاستهلاك على الأجزاء",
    },
    {
      id: 7,
      description: "BDS0 Windscreen",
      description_ar: "BD50 الزجاج الأمامي",
    },
  ];
  // Update specific fields for certain plan types
  if (GetPolicyDetails && GetPolicyDetails.PlanName) {
    switch (GetPolicyDetails.PlanName) {
      case "FL":
        coverageAndBenefits.includeBenefits = [
          {
            ...defaultBenefits[0],
            description: "3 Years Agency Repair",
            description_ar: "إصلاح الوكالة لمدة 3 سنوات",
          },
          ...defaultBenefits.slice(1),
        ];
        break;

      case "GO":
        coverageAndBenefits.includeBenefits = [
          {
            ...defaultBenefits[0],
            description: "5 Years Agency Repair",
            description_ar: "إصلاح الوكالة لمدة 5 سنوات",
          },
          ...defaultBenefits.slice(1),
        ];
        break;

      case "PL":
      case "ST":
        coverageAndBenefits.includeBenefits = [...defaultBenefits];
        break;

      default:
        coverageAndBenefits.includeBenefits = [...defaultBenefits];
    }
  } else {
    // Handle case when GetPolicyDetails or PlanName is missing
    console.error("GetPolicyDetails or PlanName is undefined");
    coverageAndBenefits.includeBenefits = []; // You can set this to an empty array or a default value
  }

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case "PolicyOverview":
        return (
          <PolicyOverviewTab
            handleDownloadMenuClicked={toggleDownloadMenuClicked}
            showDownloadPopup={downloadMenuClicked}
            doc={policyDetails?.Reports}
            status={policyDetailsStatus}
            policyDetails={policyDetails}
          />
        );
      case "VehicleCertificate":
        return (
          <VehicleCertificateTab
            tableData={vehicleCertificate.tableData}
            downloadCard={vehicleCertificate.downloadCard}
            carDetails={policyDetails?.PolicyHeader}
          />
        );
      case "Covers&Benefits":
        return <CoversAndBenefitTab data={coverageAndBenefits} />;
      case "ManagePolicy":
        if (AddCoversEnabled && ChangePlanEnabled) {
          console.log("ENABLED");
          return (
            <ManagePolicyTab
              cards={manageMyPolicy?.cards || []}
              policyDetails={policyDetails?.PolicyHeader}
            />
          );
        } else {
          // Start with all cards
          let updatedCards = manageMyPolicy?.cards || [];

          // Filter out 'ADD_MORE_COVERS' if AddCoversEnabled is false
          if (!AddCoversEnabled) {
            updatedCards = updatedCards.filter(
              (card) => card.type != "ADD_MORE_COVERS"
            );
          }

          // Filter out 'Change_plan' if ChangePlanEnabled is false
          if (!ChangePlanEnabled) {
            updatedCards = updatedCards.filter(
              (card) => card.type != "CHANGE_PLAN"
            );
          }
          if (!AdjustCarValueEnabled) {
            updatedCards = updatedCards.filter(
              (card) => card.type != "ADJUST_SUM_INSURED"
            );
          }
          if (!ChangePlateEnabled) {
            updatedCards = updatedCards.filter(
              (card) => card.type != "CHANGE_PLATE_NUMBER"
            );
          }
          if (!RemoveBankEnabled) {
            updatedCards = updatedCards.filter(
              (card) => card.type != "REMOVE_BANK_NAME"
            );
          }
          if (!TransferPolicyEnabled) {
            updatedCards = updatedCards.filter(
              (card) => card.type != "TRANSFER_POLICY"
            );
          }

          if (!CancelPolicyEnabled) {
            updatedCards = updatedCards.filter(
              (card) => card.type != "CANCEL_POLICY"
            );
          }
          console.log("DISABLED");
          return (
            <ManagePolicyTab
              cards={updatedCards}
              policyDetails={policyDetails?.PolicyHeader}
            />
          );
        }

      default:
        return null;
    }
  };

  const params = decodeQueryString();

  useEffect(() => {
    if (params.tabType) {
      setActiveTab(params.tabType);
    }
  }, [params?.tabType]);
  return (
    <div className="p-6 max767:p-0">
      <div className="flex justify-between items-center">
        {!screenResolution.mobile && <Breadcrumb items={breadCrumbData} />}
        {/* {!screenResolution.mobile && (
          <span className="hidden last-login text-right text-xs not-italic font-normal leading-4 text-gray-700">
            Last Login: 03 November 2024 at 05:20{" "}
          </span>
        )} */}
      </div>
      <MotorPolicyCard
        getPolicyDetails={Info}
        content={policyOverview?.policyInfo}
        handleDownloadMenuClicked={toggleDownloadMenuClicked}
        car={policyDetails?.PolicyHeader}
      />
      <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />
      {renderActiveTabContent()}
      {screenResolution.mobile && <MobileFooter />}
    </div>
  );
};

export default PolicyDetailsPage;
