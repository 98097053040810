import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import { accountApiEndpoints } from "../../../api";

const initialState = {
  changeMobileDetails: {

  },
  data: null,
  isLoading: false,
  error: null,
};

export const changeMobile = createAsyncThunk(
  "changeMobile",
  (payload, { rejectWithValue }) => {
    console.log("calling changeMobile.....",payload);
    return Axios.post(`${accountApiEndpoints?.changeMobileApi}`, payload)
      .then((res) => res.data)
      .catch((error) => rejectWithValue(error.response.data));
  }
);

const changeMobileSlice = createSlice({
  name: "changeMobile",
  initialState,
  reducers: {
    setchangeMobileDetails: (state, action) => {
      state.changeMobileDetails = {
        ...state.changeMobileDetails,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(changeMobile.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(changeMobile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(changeMobile.rejected, (state, action) => {
        state.isLoading = false;
        state.error =
          action.payload || "Failed to process change mobile number request";
      });
  },
});

export const { setchangeMobileDetails } = changeMobileSlice.actions;

export const selectIsLoading = (store) => store?.changeMobile?.isLoading;
export const selectchangeMobileDetails = (state) =>
  state?.changeMobile?.data;

export default changeMobileSlice.reducer;
