import React from 'react';
import MorePageLayout from './morePageLayout';
import FAQPage from '../dashboard/faqPage';
const MobileFaqPage = () => {
  return (
    <MorePageLayout show_chat_btn={false} chat_btn_on_click={()=>alert("chat clicked")}>
      <FAQPage/>
    </MorePageLayout>
  );
}
export default MobileFaqPage;