import React, { useState } from "react";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import FileClaimMotorLayout from "./fileClaimMotorLayout";
import useFileClaimFlow from "../../../hooks/useFileClaimFlow";
import MotorPolicyOptionsCard from "../../../components/motorPolicyOptionsCard/motorPolicyOptionsCard";
import {
  FILE_CLAIM_FLOW,
  FILE_CLAIM_VEHICLE_DAMAGE_FLOW,
  REPORTING_TYPES_DATA,
  SOLIDARITY_CUSTOMER,
} from "./data";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { useTranslation } from "react-i18next";
import useScreenResolution from "../../../hooks/useScreenResolution";
function CustomerTypePage({ selectedOption, setSelectedOption }) {
  const { handleNextSubstep, handleFlowChange } = useFileClaimFlow();
  const translate = useLanguageTranslate();
  const { t } = useTranslation();
  const { mobile } = useScreenResolution();
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const continueClickHanlder = () => {
    //handleNextSubstep();
    if (selectedOption == SOLIDARITY_CUSTOMER) {
      handleFlowChange(FILE_CLAIM_FLOW, 1);
    } else {
      handleFlowChange(FILE_CLAIM_VEHICLE_DAMAGE_FLOW, 1);
    }
    console.log("Cust Type = " + selectedOption);
  };
  return (
    <FileClaimMotorLayout
      is_hide_back_btn
      btn_disabled={!selectedOption}
      btn_onClick={continueClickHanlder}
      show_close_btn={mobile}
    >
      <div className="max-w-[100%] w-[500px] mx-auto bg-white max767:p-5">
        <TitleDescription
          title={t("motorInsurance.fileAClaimFlow.customerTypePage.title")}
          description={t(
            "motorInsurance.fileAClaimFlow.customerTypePage.description"
          )}
        />
        {REPORTING_TYPES_DATA?.map((each) => (
          <MotorPolicyOptionsCard
            title={translate(each?.title, each.ar_title)}
            description={translate(each?.description, each.ar_description)}
            isSelected={selectedOption == each?.type}
            onSelect={() => handleOptionSelect(each?.type)}
          />
        ))}
      </div>
    </FileClaimMotorLayout>
  );
}

export default CustomerTypePage;
