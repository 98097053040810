import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from "./redux/store";
import { Provider } from "react-redux";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n.js";
import "./fontawesome";


// Analytics from Post Hog
import { PostHogProvider} from 'posthog-js/react'
import posthog from 'posthog-js';

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  enable_session_recording: true,
}
// console.log('PostHog Host:', process.env.REACT_APP_PUBLIC_POSTHOG_HOST);
// console.log('PostHog Key:', process.env.REACT_APP_PUBLIC_POSTHOG_KEY);



posthog.debug();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={options}
        >
           
          <App />

        </PostHogProvider>
      </Provider>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
