import { useState, useCallback } from "react";
import { lifeApiEndPoints } from "../api";
import Axios from "../redux/axiosInstance";

const useFundQuarterlyReturns = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchChartData = useCallback(async (isin) => {
    if (!isin) {
      console.error("Invalid input: Missing ISIN");
      return;
    }
    try {
      setLoading(true);
      const response = await Axios.get(
        `${lifeApiEndPoints.investmentEndPoints.allfunds}?isin=${isin}&type=performance`
      )
      setData(response?.data?.performance)
    } catch (err) {
      console.error("Error fetching chart data:", err);
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    data,
    fetchChartData,
    loading,
  };
};

export default useFundQuarterlyReturns;
