import { useDispatch, useSelector } from "react-redux";
import {
  selectCurrentStepIndex,
  selectCurrentSubstepIndex,
  setCurrentStepIndex,
  setCurrentSubstepIndex,
  setPolicyCancellationFlowType,
  selectPolicyCancellationFlowType,
} from "../redux/features/motorInsurancePolicyCancellationFlowSlice";
import {
  POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_REVIEW,
  POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_REASON,
  POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_CONFIRMATION,
  POLICY_CANCELLATION_FLOW,
} from "../pages/motorInsurancePages/policyOverview/managePolicy/policyCancellationFlow/data";


const usePolicyCancellationFlow = () => {
  const dispatch = useDispatch();
  const flowType = useSelector(selectPolicyCancellationFlowType);
  const currentStepIndex = useSelector((state) =>
    selectCurrentStepIndex(state)
  );
  const currentSubstepIndex = useSelector((state) =>
    selectCurrentSubstepIndex(state)
  );

  const steps = motor_insurance_adjust_sum_insured_flow[flowType] || [];
  const currentStep = steps[currentStepIndex] || {};
  const currentSubstep = currentStep.substeps
    ? currentStep.substeps[currentSubstepIndex]
    : "";

  const handleFlowChange = (type, currentStepIndex, currentSubstepIndex) => {
    dispatch(
      setPolicyCancellationFlowType({
        type,
        currentStepIndex,
        currentSubstepIndex,
      })
    );
  };

  const handleNextSubstep = () => {
    if (
      currentStep.substeps &&
      currentSubstepIndex < currentStep.substeps.length - 1
    ) {
      dispatch(setCurrentSubstepIndex(currentSubstepIndex + 1));
    } else if (currentSubstepIndex ==currentStep.substeps.length - 1) {
      handleNextStep();
    }
  };

  const handlePrevSubstep = () => {
    if (currentSubstepIndex > 0) {
      dispatch(setCurrentSubstepIndex(currentSubstepIndex - 1));
    } else {
      handlePrevStep();
    }
  };

  const handleNextStep = () => {
    if (currentStepIndex < steps.length - 1) {
      dispatch(setCurrentStepIndex(currentStepIndex + 1));
    }
  };

  const handlePrevStep = () => {
    if (currentStepIndex > 0) {
      const currentStep = steps[currentStepIndex - 1] || {};
      const substepIndex = currentStep?.substeps?.length - 1;
      dispatch(setCurrentStepIndex(currentStepIndex - 1));
      dispatch(setCurrentSubstepIndex(substepIndex));
    }
  };

  return {
    handleFlowChange,
    handleNextSubstep,
    handlePrevSubstep,
    handleNextStep,
    handlePrevStep,
    flowType,
    steps,
    currentStep,
    currentStepIndex,
    currentSubstep,
    currentSubstepIndex,
  };
};
export default usePolicyCancellationFlow;

export const motor_insurance_adjust_sum_insured_flow = {
  [POLICY_CANCELLATION_FLOW]: [
    {
      name: POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_REVIEW,
      substeps: [POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_REVIEW],
    },
    {
      name: POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_REASON,
      substeps: [POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_REASON],
    },
    {
        name: POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_CONFIRMATION,
        substeps: [POLICY_CANCELLATION_FLOW_POLICY_CANCELLATION_CONFIRMATION],
      },
  ],
};
