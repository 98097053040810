import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios, { AxiosFiles } from "../../axiosInstance";
import {  filesApiEndpoints } from "../../../api";

const initialState = {
  token: "",
  fileId: 0,
  isLoadingToken: false,
  isLoadingUpload: false,
  tokenSuccess: null,
  uploadSuccess: null,
  tokenError: null,
  uploadError: null,

};


export const requestToken = createAsyncThunk(
  "requestToken",
  async (payload, { rejectWithValue }) => {
      try {
          const response = await AxiosFiles.post(
              `${filesApiEndpoints.token}`,
              payload,
              { timeout: 60000 }  // Set timeout to 1 minute (60000 ms)
          );

          return response.data.data.token;
      } catch (err) {
          return rejectWithValue(err.response?.data || err.message);
      }
  }
);

export const uploadFile = createAsyncThunk(
    "uploadFile",
    async (payload, { rejectWithValue }) => {
        try {
            const response = await AxiosFiles.post(`${filesApiEndpoints.upload}`, payload, { timeout: 60000 });

            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);



export const filesApiSlice = createSlice({
  name: "filesApiSlice",
  initialState,
  reducers: {
    clearGetQuoteError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // Request token API
    builder.addCase(requestToken.pending, (state, action) => {
      if (!state.data) {
        state.isLoadingToken = true;
      }
    });
    builder.addCase(requestToken.fulfilled, (state, action) => {
      state.isLoadingToken = false;
      state.token = action?.payload;
    });
    builder.addCase(requestToken.rejected, (state, action) => {
      state.isLoadingToken = false;
      state.error = action.error?.message;
    });
    // Upload file API
    builder.addCase(uploadFile.pending, (state, action) => {
      if (!state.data) {
        state.isLoadingUpload = true;
      }
    });
    builder.addCase(uploadFile.fulfilled, (state, action) => {
      state.isLoadingUpload = false;
      state.success = true;
      state.fileId = action?.payload;
    });
    builder.addCase(uploadFile.rejected, (state, action) => {
      state.isLoadingUpload = false;
      state.success = false;
      state.error = action.error?.message;
    });
  },
});

// SELECTORS
export const tokenResponse = (store) => store?.filesAPI.token;
export const fileUploadResponse = (store) => store?.filesAPI.fileId;
export const tokenLoading = (store) => store?.filesAPI.isLoadingToken;
export const uploadLoading = (store) => store?.filesAPI.isLoadingUpload;
// export const filesOrTokenSuccess = (store) => store?.filesAPI.success;


// export const getSumInsuredLoader = (store) => store?.getSumInsured.isLoading;


// ACTIONS TO DISPATCH
export const { clearFilesApiSliceError } = filesApiSlice.actions;

export default filesApiSlice.reducer;
