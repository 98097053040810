import React from 'react';
import VerificationFail from "../assets/images/verificationFail.svg";
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../components/common/Button/Button';

const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col w-[500px] max-w-[100%] mx-auto mt-20 max767:p-5">
      <img
        src={VerificationFail}
        alt="Verification Failed"
        className="text-center w-[96px] h-[96px]"
      />
      <p className='mt-5 flex'>
        <b className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] ">
          {t("pageNotFound.title")}
        </b>
      </p>
      <p className="text-[#1A2024] text-base not-italic font-normal leading-6 tracking-[-0.16px] mb-4">
        {t("pageNotFound.description")}
      </p>
      <Button
        label={t("pageNotFound.btnLabel")}
        className="primary-button"
        onSubmit={()=>navigate("/")}
      />
    </div>
  );
}

export default NotFoundPage;