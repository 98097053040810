import { createSlice } from "@reduxjs/toolkit";
import { FILE_CLAIM_FLOW } from "../../pages/motorInsurancePages/fileAClaimFlow/data";

const initialState = {
  flowType: FILE_CLAIM_FLOW,
  currentStepIndex: 0,
  currentSubstepIndex: 0,
};

const motorInsuranceFileClaimFlowSlice = createSlice({
  name: "motorInsuranceFileClaimFlow",
  initialState,
  reducers: {
    setFileClaimFlowType: (state, action) => {
      const data = action.payload;
      state.flowType = data.type;
      state.currentStepIndex = data.currentStepIndex || 0;
      state.currentSubstepIndex = data.currentSubstepIndex || 0;
    },
    setCurrentStepIndex: (state, action) => {
      state.currentStepIndex = action.payload;
      state.currentSubstepIndex = 0;
    },
    setCurrentSubstepIndex: (state, action) => {
      state.currentSubstepIndex = action.payload;
    },
  },
});

export const selectMotorInsuranceFileClaimFlow = (store) =>
  store.motorInsuranceFileClaimFlow;
export const selectFileClaimFlowType = (store) =>
  store?.motorInsuranceFileClaimFlow?.flowType;
export const selectCurrentStepIndex = (store) =>
  store?.motorInsuranceFileClaimFlow?.currentStepIndex;
export const selectCurrentSubstepIndex = (store) =>
  store.motorInsuranceFileClaimFlow?.currentSubstepIndex;

export const {
  setFileClaimFlowType,
  setCurrentStepIndex,
  setCurrentSubstepIndex,
} = motorInsuranceFileClaimFlowSlice.actions;
export default motorInsuranceFileClaimFlowSlice.reducer;
