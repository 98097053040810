import { LOCAL_STORAGE_NAME } from "../redux/axiosInstance";

export const setLoggedInUserDataInLocalStorage = (userInfo) => {
  localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(userInfo));

};
export const clearLoggedInUserDataInLocalStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_NAME);

};

export const getLoggedInUserDataFromLocalStorage = () => {
  const storedUserInfo = localStorage.getItem(LOCAL_STORAGE_NAME);
  return storedUserInfo ? JSON.parse(storedUserInfo) : null;
};

export const updateLoggedInUserDataInLocalStorage = (data) => {
  const currentUserInfo = getLoggedInUserDataFromLocalStorage();
  const updatedUserInfo = { ...currentUserInfo, ...data };
  localStorage.setItem(
    "solidarity_customer_portal_user_info",
    JSON.stringify(updatedUserInfo)
  );
};

export const isLoggedIn = () => {
  return getLoggedInUserDataFromLocalStorage();
};


export default {
  setLoggedInUserDataInLocalStorage,
  getLoggedInUserDataFromLocalStorage,
  updateLoggedInUserDataInLocalStorage,
  isLoggedIn,
  clearLoggedInUserDataInLocalStorage
};


