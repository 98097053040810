import './App.css';
import AllRoutes from './AllRoutes/AllRoutes';
import useLanguageTranslate from './hooks/useLanguageTranslate';
import useLanguagePercistant from './hooks/useLanguagePercistant';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoginRegisterContent, loginUser, selectLoginTokenInfo } from './redux/features/loginRegisterSlice';
import { fetchCommnApiContent } from './redux/features/commonApiDataSlice';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchPoliciesBg } from './redux/features/myPolicySlice';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchNotifications } from './redux/features/notificationsSlice';
import useScreenResolution from './hooks/useScreenResolution';
import { toggleSidenav } from './redux/features/toolsSlice';
import { fetchFaqs } from './redux/features/faqsSlice';
import { useTranslation } from 'react-i18next';
import useTokenRefresh from './hooks/useTokenRefresh';


function App() {
  useLanguagePercistant();
  useTokenRefresh();
  const translate = useLanguageTranslate();
  const { tablet } = useScreenResolution()
  const dispatch = useDispatch()
  const { i18n } = useTranslation();

  useEffect(() => {
    dispatch(fetchLoginRegisterContent())
    dispatch(fetchCommnApiContent());
    // dispatch(fetchNotifications());
    dispatch(fetchFaqs())
    document.body.dir = translate('ltr', 'rtl');
    if (tablet) {
      dispatch(toggleSidenav({ open: false }))
    }
  }, [])
  const loginTokenInfo = useSelector(selectLoginTokenInfo);
  useEffect(()=>{
    dispatch(fetchPoliciesBg());
  },[loginTokenInfo])

  useEffect(() => {
      document.body.dir = translate('ltr', 'rtl');
  }, [i18n.resolvedLanguage])

  return (
    <div className={translate("english", "arabic")}>
      <ToastContainer autoClose={10000} />
      <BrowserRouter>
        <AllRoutes />
      </BrowserRouter>

    </div>
  );
}

export default App;
