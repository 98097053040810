import React from "react";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";

const Stepper = ({ activeStep, steps }) => {
  const translate = useLanguageTranslate();
  const stepIndex = steps.findIndex((step) => step?.key == activeStep?.key);

  return (
    <div className="flex z-[99999] absolute justify-center items-center w-[30%] mx-auto my-0 inset-x-0 max767:hidden ipad:w-[55%] ipad:ml-[25%] ">
      {steps.map((step, index) => (
        <div
          key={index}
          className="flex flex-col items-center relative w-[80px] steper-title"
        >
          <div className="flex items-center w-full relative">
            {/* Connector line before the dot */}
            {index < steps.length - 1 && (
              <div
                className={`h-[0.1rem] ${
                  index < stepIndex
                    ? "bg-[#55C6EF] flex-grow"
                    : "bg-[#DDE2E4] flex-grow"
                }`}
                style={{ transition: "background-color 0.5s ease-in-out" }}
              ></div>
            )}
            {/* Dot */}
            <div
              className={`w-1.5 h-1.5 ${
                index == stepIndex && "bg-[#55C6EF]"
              } rounded-full absolute left-0 transform -translate-x-1/2`}
              style={{ transition: "background-color 0.5s ease-in-out" }}
            ></div>
            {/* Connector line after the dot */}
            {index < steps.length - 1 && (
              <div
                className={`h-[0.1rem] ${
                  index < stepIndex ? "bg-[#55C6EF]" : ""
                }`}
                style={{ transition: "background-color 0.5s ease-in-out" }}
              ></div>
            )}
          </div>
          {/* Label */}
          <div
            className={`mt-2 text-sm not-italic font-normal leading-6 w-full -ml-2.5 ${
              index == stepIndex ? "text-[#1A2024] font-semibold " : ""
            }`}
            style={{ alignSelf: "flex-start" }}
          >
            {translate(step.title_en ,step.title_ar)}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Stepper;
