import MobileBox from "./mobileBox"


const MobileBeneficiaryTable = ({ data , actionMenuClickHandler }) => {
    return (
        <div className='beneficiaryCards'>
            {
                data?.map(each => <MobileBox actionMenuClickHandler={actionMenuClickHandler} each={each} />)
            }

        </div>
    )
}

export default MobileBeneficiaryTable