import React from "react";
import BackdropModal from "../../../../components/common/modal/backdropModal";
import { Link } from "react-router-dom";
import Button from "../../../../components/common/Button/Button";
import SignInForm from "../../../signInRegisterPages/SignInPage/signInForm";
import { useTranslation } from "react-i18next";
const SignInModal = ({ show, setShow, cbSuccess }) => {
  const { t } = useTranslation();
  const onClose = () => setShow((state) => !state);
  return (
    <BackdropModal onClose={onClose} show={show}>
      <div className="content mb-4 mt-[-43px] max767:mt-[-43px]">
        <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mb-2 max767:text-xl">
          {t("motorInsurance.signInModal.title")}
        </h1>
        <p className="text-[#1A2024] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] text-left">
          {t("motorInsurance.signInModal.description")}
        </p>
      </div>
      <div className="sign_inForm items-start gap-4 self-stretch bg-[#F6F8F9] rounded-2xl pt-6 pb-0.5 px-6">
        <p className="text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px] mb-3">
          {t("motorInsurance.signInModal.old_account")}
        </p>
        <SignInForm cbSuccess={cbSuccess} />
      </div>
      <div className="newHere flex flex-col items-center gap-4 self-stretch bg-[#F6F8F9] text-center mt-6 p-6 rounded-2xl">
        <Link
          to="/sign-up"
          className="text-[#00384D] text-center text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline"
        >
          {t("motorInsurance.signInModal.register_account")}
        </Link>
      </div>
      <div className="mt-4">
        <h2 className="text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px]">
          {t("motorInsurance.signInModal.help")}
        </h2>
        <p className="text-[#1A2024] text-sm not-italic font-normal leading-5 tracking-[-0.14px]">
          {t("motorInsurance.signInModal.customer_support")}
        </p>
        <div className="flex gap-2 mt-2">
          <Button
            onSubmit={() => {}}
            label={t("motorInsurance.signInModal.call_us")}
            className="flex flex-col items-center gap-2.5 border bg-white px-3 py-1 rounded-[10px] border-solid border-[#DDE2E4] text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
          />
          <Button
            onSubmit={() => {}}
            label={t("motorInsurance.signInModal.faq")}
            className="flex flex-col items-center gap-2.5 border bg-white px-3 py-1 rounded-[10px] border-solid border-[#DDE2E4] text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
          />
        </div>
      </div>
    </BackdropModal>
  );
};

export default SignInModal;
