import React, { useState } from "react";
import { menu } from "./sidebarLinks";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Header from "../Header/Header";
import "./sideNavbar.css";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { useSelector } from "react-redux";
import { selectUtilsWhole } from "../../../redux/features/toolsSlice";
import useScreenResolution from "../../../hooks/useScreenResolution";
import MobileFooter from "./mobileFooter";
import "./sideNavbar.css";
import { selectProfileData } from "../../../redux/features/profileSlice";
import { selectLoginTokenInfo } from "../../../redux/features/loginRegisterSlice";
import VideoCallModal from "./videoCallModal";
const SideNavbar = ({ isPopup = false, invalidPage }) => {
  const screenResolution = useScreenResolution();
  const translate = useLanguageTranslate();
  const sidenavOpen = useSelector(selectUtilsWhole).sidenav_open;
  const profileData = useSelector(selectProfileData);
  const loginTokenInfo = useSelector(selectLoginTokenInfo);
  const [showVideoPopup, setShowVideoPopup] = useState(false);
  const { pathname } = useLocation();

  let hideFooterRoutes = [
    "/sign-in",
    "/forgot-password",
    "/sign-up",
    "/life-insurance",
    "/motor-insurance/cancel-policy",
    "/motor-insurance/claim-details",
    "/motor-insurance/upload-documents",
    "motor-insurance/change-garage",
    "/motor-insurance/payment-details",
    "/motor-insurance/success",
    "/motor-insurance/claim-details/claim-info",
    "/change-email",
    "/change-mobile-number",
    "/motor-insurance/reason-for-rejection",
    "/motor-insurance/file-new-claim",
    "/motor-insurance/add-more-covers",
  ];

  const linkClickHandler = (data, event) => {
    if (data?.is_internal == false) {
      event.preventDefault();
      window.open(data.link, "_blank");
    }
    if (data.actionName == "VideoCall") {
      setShowVideoPopup(true);
    }
  };

  return (
    <>
      <Header isPopup={isPopup} invalidPage={invalidPage} />
      <Tooltip id="sideNav-tooltip" place="left-start" />
      {!isPopup && !screenResolution.mobile ? (
        <aside
          className={` ${
            sidenavOpen ? " " : " "
          } hidden pt-12 w-52 ipad:w-auto text-black fixed inset-y-0 overflow-x-hidden overflow-y-auto sm:block z-10 rtl:right-0`}
        >
          <div
            className={`transition-all fixed flex flex-col left-0 rtl:right-0 ${
              sidenavOpen ? " w-64" : "w-max"
            } h-full border-r side-bar bg-[#f6f8f9] rtl:border-l rtl:border-r-0`}
          >
            <div className="overflow-y-auto overflow-x-hidden flex-grow">
              <ul className="flex flex-col space-y-1 ipad:p-2 px-4 py-6 sidebar">
                {menu.map((section, index) => (
                  <div key={index} className=" pt-0 pb-4 px-0 border-b li-box">
                    <li className="px-5">
                      <div className="flex flex-row items-center ">
                        <div className="menu-subtitle pt-4 pb-2 mb-2 text-[#84919A] text-xs not-italic font-semibold leading-4 tracking-[0.12px] uppercase rtl:pr-1">
                          {sidenavOpen &&
                            translate(section.title_en, section.title_ar)}
                        </div>
                      </div>
                    </li>
                    {section.links.map(
                      (link, linkIndex) =>
                        !link.hide && (
                          <li
                            key={linkIndex}
                            className={`${
                              link.showIfIsLoggedIn == true && !loginTokenInfo
                                ? "hidden"
                                : ""
                            } ${
                              pathname == link.link ||
                              (link.type == "policy" &&
                                pathname.includes(
                                  "/life-insurance/policy-overview"
                                )) ||
                              (link.type == "policy" &&
                                pathname.includes(
                                  "/motor-insurance/policy-overview"
                                ))
                                ? "active"
                                : "inActive"
                            }`}
                            data-tooltip-id="sideNav-tooltip"
                            data-tooltip-content={
                              !sidenavOpen
                                ? translate(link.text_en, link.text_ar)
                                : ""
                            }
                          >
                            <Link
                              id={link?.actionName}
                              onClick={(event) => linkClickHandler(link, event)}
                              to={link.link}
                              state={{ navigatedFromLink: link.text }}
                              className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-[#eef0f2]  hover:rounded-md border-l-4 border-transparent pr-0 text-[#252c32] text-sm not-italic font-normal leading-6 rtl:pr-5 ${
                                link.disabled
                                  ? "pointer-events-none opacity-50"
                                  : ""
                              }`}
                            >
                              <span className="inline-flex justify-center items-center ml-3">
                                <img
                                  className=""
                                  src={link.icon}
                                  alt={translate(link.text_en, link.text_ar)}
                                />
                              </span>
                              {sidenavOpen && (
                                <span className="ml-2 text-[#252C32] text-sm not-italic font-normal leading-6 tracking-wide rtl:ml-0 ">
                                  {translate(link.text_en, link.text_ar)}
                                </span>
                              )}
                            </Link>
                          </li>
                        )
                    )}
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </aside>
      ) : null}

      {screenResolution.mobile &&
        !hideFooterRoutes?.some((path) => pathname?.includes(path)) && (
          <MobileFooter />
        )}
      {
        <VideoCallModal
          show={showVideoPopup}
          onClose={() => setShowVideoPopup(false)}
        />
      }
    </>
  );
};

export default SideNavbar;
