import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../axiosInstance";
import { forgotPasswordApiEndpoints } from "../../../api";

const initialState = {
  isLoading: false,
  error: null,
  data: null,
};

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Axios.post(`${forgotPasswordApiEndpoints?.resetpassword}`, payload);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || "Something went wrong");
    }
  }
);

const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    resetState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { resetState } = resetPasswordSlice.actions;

export const selectResetIsLoading = (state) => state.resetPassword.isLoading;
export const selectResetPasswordError = (state) => state.resetPassword.error;
export const selectResetPasswordData = (state) => state.resetPassword.data;

export default resetPasswordSlice.reducer;
