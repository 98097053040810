import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useChangeMobileNumberFlow from "../../../hooks/useChangeMobileNumberFlow";
import RenewMotorLayout from "../../motorInsurancePages/renewalMotorPolicy/renewMotorLayout";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import VerifyOtpComponent from "../../motorInsurancePages/renewalMotorPolicy/verifyOtpPage/verifyOtpComponent";
import Button from "../../../components/common/Button/Button";
import { unwrapResult } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { changeMobile } from "../../../redux/features/account/updateMobileApiSlice";
import { selectNewMotorForm } from "../../../redux/features/newMotorFormSlice";
import { CHANGE_MOBILE_FLOW_UPDATED_MOBILE_NUMBER } from "./changeMobileNumberPage";
import { CHANGE_MOBILE_FLOW_ACCOUNT_PASSWORD } from "./accountPasswordPage";
import useScreenResolution from "../../../hooks/useScreenResolution";
import {
  getTokenOtpLoading,
  sendToken,
} from "../../../redux/features/account/accountOtpSlice";
import Loader from "../../../components/common/Loader/Loader";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";

const VerifyOtp = ({}) => {
  const dispatch = useDispatch();
  const translate = useLanguageTranslate();
  const { handleNextSubstep, handlePrevSubstep } = useChangeMobileNumberFlow();
  const screenResolution = useScreenResolution();
  const updatedPhnDetails = useSelector(selectNewMotorForm);
  const isLoading = useSelector(getTokenOtpLoading);
  const mobileNum =
    updatedPhnDetails?.[CHANGE_MOBILE_FLOW_UPDATED_MOBILE_NUMBER]["phone"];

  const [otp, setOtp] = useState();
  const { t } = useTranslation();
  // const notRecivedCodeHandler = () => {
  //   alert("clicked on i didn't recieved code");
  // };
  const notRecivedCodeHandler = async () => {
    try {
      const tokenOtpPayload = {
        mobileNumber: mobileNum,
      };

      const tokenOtpAction = await dispatch(sendToken(tokenOtpPayload));
      const tokenOtpResult = unwrapResult(tokenOtpAction);

      // If the response is successful, process the phone number
      if (tokenOtpResult?.smsStatus =="MESSAGE_SENT") {
        toast.success("OTP sent to mobile number");
      } else if (
        tokenOtpResult &&
        tokenOtpResult.smsStatus =="MESSAGE_NOT_SENT"
      ) {
        toast.error(
          translate(
            "Failed to send token to mobile number ",
            "فشل في إرسال الرمز إلى رقم الهاتف"
          ) + tokenOtpResult.to
        );
      } else if (
        tokenOtpResult.requestError.serviceException.messageId ===
        "THROTTLE_EXCEPTION"
      ) {
        toast.error(tokenOtpResult.requestError.serviceException.text);
      }
    } catch (error) {
      toast.error(
        translate("Failed to send token:", "فشل في إرسال الرمز:"),
        error
      );
    }
  };

  const continueClickHanlder = async () => {
    try {
      const payload = {
        Password:
          updatedPhnDetails?.[CHANGE_MOBILE_FLOW_ACCOUNT_PASSWORD][
            "accountPassword"
          ],
        Phone:
          updatedPhnDetails?.[CHANGE_MOBILE_FLOW_UPDATED_MOBILE_NUMBER][
            "phone"
          ],
        Pin: otp,
        PinId:
          updatedPhnDetails?.[CHANGE_MOBILE_FLOW_UPDATED_MOBILE_NUMBER][
            "pinId"
          ],
      };

      // Calling API /account/updateMobile
      const updateMobileAction = await dispatch(changeMobile(payload));
      const updateMobileResult = unwrapResult(updateMobileAction);
      if (updateMobileResult) {
        toast.success(
          translate(
            "Updated mobile number successfully",
            "تم تحديث رقم الهاتف بنجاح"
          )
        );
        handleNextSubstep();
      }
    } catch (error) {
      toast.error(
        translate(
          "Failed to update mobile number:",
          "فشل في تحديث رقم الهاتف:"
        ),
        error
      );
    }
  };

  return (
    <RenewMotorLayout
      is_bottom_sticky_layout={screenResolution.mobile}
      btn_disabled={!(otp?.length ==4)}
      btn_onClick={continueClickHanlder}
      backClickHandler={() => handlePrevSubstep()}
    >
      {isLoading && <Loader />}

      <div className="mx-auto bg-white max767:p-5 w-[500px] max-w-[100%] max767:mt-0 mt-20">
        <div className="reniewlotp">
          <TitleDescription
            title={translate(
              "Verify OTP on the new mobile number",
              "تحقق من رمز التحقق (OTP) على رقم الهاتف الجديد"
            )}
            description={translate(
              "Enter the 4-digit code number",
              "أدخل الرمز المكون من 4 أرقام"
            )}
          />
          <VerifyOtpComponent
            notRecivedCodeHandler={notRecivedCodeHandler}
            otp={otp}
            setOtp={setOtp}
            mobile={mobileNum}
            onChangeClick={handlePrevSubstep}
          />
        </div>
        {!screenResolution.mobile && (
          <Button
            type="submit"
            className="primary-button"
            onSubmit={continueClickHanlder}
            label={t("forgot_password_flow.reset_password.next")}
            disabled={!(otp?.length ==4)}
          />
        )}
      </div>
    </RenewMotorLayout>
  );
};

export default VerifyOtp;
