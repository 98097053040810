import { configureStore, combineReducers } from "@reduxjs/toolkit";
import toolsReducer from "./features/toolsSlice";
import loginRegisterReducer from "./features/loginRegisterSlice";
import commonApiContentReducer from "./features/commonApiDataSlice";
import offersReducer from "./features/offersSlice";
import myPolicyReducer from "./features/myPolicySlice";
import newPoliciesStaticDataReducer from "./features/newPolicySlice";
import newMotorFormReducer from "./features/newMotorFormSlice";
import profileReducer from "./features/profileSlice";
import motorInsuranceReducer from "./features/motorInsuranceFlowSlice";
import carsListReducer from "./features/constants/carsListSlice";
import motorInsuranceRenewReducer from "./features/motorInsuranceRenewFlowSlice";
import banksListReducer from "./features/constants/banksListSlice";
import getSumInsuredReducer from "./features/motor/getSumInsuredSlice";
import calculatePremiumReducer from './features/motor/calculatePremiumSlice';
import getQuoteReducer from './features/motor/getQuoteSlice';
import motorInsuranceAddMoreCoversFlowReducer from "./features/motorInsuranceAddMoreCoversFlowSlice";
import motorInsuranceAdjustSumInsuredFlowReducer from "./features/motorInsuranceAdjustSumInsuredFlowSlice";
import motorInsuranceChangePlanFlowReducer from "./features/motorInsuranceChangePlanFlowSlice";
import motorInsuranceFileClaimFlowReducer from "./features/motorInsuranceFileClaimFlowSlice";
import policyPremiumReducer from "./features/motor/renewalPremiumSlice";
import getFilesReducer from "./features/upload/fileUploadSlice";
import gdtReducer from "./features/motor/gdtSlice";
import scannerReducer from "./features/ai/scannerSlice";
import accountOtpReducer from "./features/account/accountOtpSlice";
import updateMobileApiReducer from "./features/account/updateMobileApiSlice";
import notificationsReducer from "./features/notificationsSlice";
import otpReducer from "./features/sendOtpSlice";
import verifyOtpReducer from './features/verifyOtpSlice';
import expressShieldReducer from "./features/expressShieldFlowSlice";
import expressShieldFlowStrapiCmsReducer from "./features/buyOnlinePlans/expressShieldFlowStrapiCmsSlice";
import essentialCareFlowStrapiCmsReducer from "./features/essentialCare/essentialCareStrapiCmsSlice";
import { resetRedux } from "./features/resetSlice";
import policyDetailsReducer from "./features/policyDetailsSlice";
import signupReducer from "./features/signUp/signUpSlice";
import createCustomerAccountReducer from "./features/signUp/signUpSlice";
import verifySessionReducer from "./features/signUp/createVeriffSessionSlice";
import viewDocsReducer from "./features/motor/policyDocsViewSlice"
import cancellationReducer from "./features/endorsements/cancellationSlice"
import policyPayReducer from "./features/payment/policyPaySlice"
import ScanBankLetterReducer from "./features/motor/scanBankLetterSlice"
import RemoveBankNameReducer from "./features/motor/RemoveBankSlice"


import fundsSwitchingFlowReducer from "./features/fundsSwitchingFlowSlice";
import ExpressLeadReducer from "./features/expressSheild/leadSlice";
import newLifeFormReducer from "./features/newLifeFormSlice";
import expressSheildReducer from "./features/expressSheild/expressSheildSlice";
import motorInsurancePolicyCancellationFlowReducer from "./features/motorInsurancePolicyCancellationFlowSlice"
import kycVerificationReducer from "./features/signUp/kycVerificationStatusSlice"
import transferPolicyReducer from "./features/endorsements/transferPolicySlice"
import forgotPasswordReducer from "./features/forgotPasswordFlow/forgotPasswordSlice"
import resetPasswordReducer from "./features/forgotPasswordFlow/forgotPasswordSlice"
import allFundsReducer from "./features/allfunds/allFundsSlice";
import lifePolicyDetailReducer from "./features/life/lifePolicyDetailSlice";
import changeMobileNumberFlowReducer from "./features/changeMobileNumberSlice";
import faqsReducer from "./features/faqsSlice";
import crmCaseComplaintReducer from "./features/crm/caseComplaintSlice";
import crmClaimReducer from "./features/crm/crmClaimSlice";
import promoCodeReducer from "./features/promoCode/promoCodeSlice";
  import essentialCareFlowReducer from "./features/essentialCare/essentialCareFlowSlice";
  import essentialCareQuoteReducer from "./features/essentialCare/essentialCareQuoteSlice";
  import ChangePlateNoReducer from "./features/motor/ChangePlateNumberSlice";

const appReducer = combineReducers({
  notifications: notificationsReducer,
  tools: toolsReducer,
  login_register: loginRegisterReducer,
  common_api_content: commonApiContentReducer,
  offers: offersReducer,
  myPolicies: myPolicyReducer,
  newPoliciesStaticData: newPoliciesStaticDataReducer,
  fundsSwitchingFlow: fundsSwitchingFlowReducer,
  newMotorFormData: newMotorFormReducer,
  profile: profileReducer,
  motorInsuranceRenewFlow: motorInsuranceRenewReducer,
  motorInsuranceAddMoreCoversFlow: motorInsuranceAddMoreCoversFlowReducer,
  motorInsuranceAdjustSumInsuredFlow: motorInsuranceAdjustSumInsuredFlowReducer,
  motorInsuranceChangePlanFlow: motorInsuranceChangePlanFlowReducer,
  // Motor Reducers
  motorInsuranceFlow: motorInsuranceReducer,
  carsList: carsListReducer,
  banksList: banksListReducer,
  getSumInsured: getSumInsuredReducer,
  calculatePremium: calculatePremiumReducer,
  getQuote: getQuoteReducer,
  policyPremium: policyPremiumReducer,
  // GDT
  gdtApi: gdtReducer,
  // FilesAPI Reducers
  filesAPI: getFilesReducer,
  // Open AI
  scanOS: scannerReducer,
  motorInsuranceFileClaimFlow: motorInsuranceFileClaimFlowReducer,
  // Account OTP
  accountOtp: accountOtpReducer,
  updateMobileApi: updateMobileApiReducer,
  otpData: otpReducer,
  verifyOtpData: verifyOtpReducer,
  expressShieldFlow: expressShieldReducer,
  expressShieldFlowStrapiCms: expressShieldFlowStrapiCmsReducer,
  essentialCareFlow: essentialCareFlowReducer,
  essentialCareFlowStrapiCms: essentialCareFlowStrapiCmsReducer,
  signup: signupReducer,
  createCustomerAccount: createCustomerAccountReducer,
  verifySession: verifySessionReducer,
  changeMobileNumberFlow: changeMobileNumberFlowReducer,
  // Policy
  policyDetails: policyDetailsReducer,
  policyPay: policyPayReducer,
  // Endorsement
  cancellationApi: cancellationReducer,
  viewDocs: viewDocsReducer,
  ScanBankLetter: ScanBankLetterReducer,
  RemoveBankName: RemoveBankNameReducer,
  // Express Sheild
  expressLead: ExpressLeadReducer,
  expressSheild: expressSheildReducer,
  newLifeFormData: newLifeFormReducer,
  motorInsurancePolicyCancellationFlow:
    motorInsurancePolicyCancellationFlowReducer,
  kycVerification: kycVerificationReducer,
  transferPolicy: transferPolicyReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  allFunds: allFundsReducer,
  // life policy
  lifePolicyDetail: lifePolicyDetailReducer,
  faqs: faqsReducer,
  // CRM
  crmCaseComplaint: crmCaseComplaintReducer,
  crmClaim: crmClaimReducer,
  // Promo Code
  promoCode: promoCodeReducer,
  essentialCare : essentialCareQuoteReducer,
  ChangePlateNo: ChangePlateNoReducer
});

// Root reducer to handle reset action
const rootReducer = (state, action) => {
  if (action.type ==resetRedux.type) {
    // some of cms content should not be reset
    const { login_register, tools, common_api_content } = state;
    state = { login_register, tools, common_api_content };
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

// TODO: refresh also persist the values if needed
