import React, { useEffect, useMemo, useState } from "react";
import Input from "../Input/Input";
import AlertComponent from "../../../pages/motorInsurancePages/transferPolicyFlow/alertComponent";
import FundsToRemoveData from "../../../pages/lifeInsurancePages/PolicyOverview/fundManagementTab/investementStrategyFlow/fundSwitchingFlow/fundsToRemoveData";
import { useTranslation } from "react-i18next";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import deleteicon from "../../../../src/assets/images/delete_outline.svg";
import Button from "../Button/Button";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  currencyFormatterWithCountryCode,
  intFromBhdCurrency,
} from "../../../utils";

const AllocationTable = ({
  fundsData = [],
  setFundsData,
  tableType = "allocation",
  addFundHandler,
  setIsUserChangedFunds,
  fundsToRemove,
  total,
  setTotal,
  errorMsg,
  setErrorMsg,
  allocationTotal,
}) => {
  const [values, setValues] = useState({});
  const [expandedRows, setExpandedRows] = useState([]);

  const translate = useLanguageTranslate();
  const { i18n } = useTranslation();

  const headers = {
    en: {
      allocation: ["", "Fund Name", "Value BHD"],
    },
    ar: {
      allocation: ["", "اسم الصندوق", "قيمة الصندوق"],
    },
  };

  const getHeaders = () => {
    return (
      headers[i18n.resolvedLanguage][tableType] ||
      headers[i18n.resolvedLanguage].redirecting
    );
  };

  const getRowValues = (each) => {
    const inputStyle =
      values[each?.isin] < 10
        ? "border-red-500 focus:outline-none focus:ring-red-500"
        : "border-gray-300";

    switch (tableType) {
      case "allocation":
        return [
          each.fund_name,
          <Input
            type="number"
            value={values[each?.isin] ?? 0}
            handleChange={inputChangeHandler}
            name={each?.isin + ""}
            startAdornmentClassName=""
            className={`${inputStyle} bhdinputvox`}
            startAdornment={
              <div className="bg-gray-100 h-[30px] px-3 py-1 rounded-tl-[9px] rounded-bl-[5px]">
                BHD
              </div>
            }
          />,
        ];
      default:
        return [];
    }
  };

  useEffect(() => {
    let update = {};
    fundsData.forEach((each) => {
      update[each.isin] = each.current_price || 0;
    });
    setValues(update);
    const newTotal = Object.values(fundsData).reduce(
      (acc, cur) => acc + (parseFloat(cur.current_price, 10) || 0),
      0
    );
    setTotal(newTotal);
    const hasError = Object.values(fundsData).some((v) => v.current_price < 10);
    if (hasError) {
      setErrorMsg("Minimum allocation per fund is 10%.");
    } else {
      setErrorMsg("");
    }
  }, [fundsData]);

  const inputChangeHandler = (e) => {
    let name = e.target.name;
    let value = parseFloat(e.target.value);
    if (value > allocationTotal) return;
    // const updatedFundsData = fundsData.map((each) => {
    //   if (each.isin == name) {
    //     return {
    //       ...each,
    //       current_price: e.target.value,
    //     };
    //   } else {
    //     return each;
    //   }
    // });
    // setFundsData(updatedFundsData);
    setValues((prevValues) => {
      const updatedValues = { ...prevValues, [name]: value };
      const newTotal = Object.values(updatedValues).reduce(
        (acc, cur) => acc + (parseFloat(cur, 10) || 0),
        0
      );
      setTotal(newTotal);
      const hasError = Object.values(updatedValues).some((v) => v < 10);
      if (hasError) {
        setErrorMsg("Minimum allocation per fund is 10%.");
      } else {
        setErrorMsg("");
      }

      return updatedValues;
    });
    setIsUserChangedFunds(true);
  };

  const deleteRow = (id) => {
    const updatedFunds = fundsData.filter((fund) => fund.isin != id);
    setFundsData(updatedFunds);
    const updatedValues = { ...values };
    delete updatedValues[id];
    const newTotal = Object.values(updatedValues).reduce(
      (acc, cur) => acc + (cur || 0),
      0
    );
    setTotal(newTotal);
    setValues(updatedValues);
    setIsUserChangedFunds(true);
  };

  const toggleRow = (id) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(id)
        ? prevExpandedRows.filter((rowId) => rowId != id)
        : [...prevExpandedRows, id]
    );
  };

  const isRowExpanded = (id) => expandedRows.includes(id);

  return (
    <>
      {fundsToRemove && tableType == "allocation" && (
        <>
          <FundsToRemoveData fundsToRemove={fundsToRemove} />
        </>
      )}

      <div className=" table-container max767:mt-4">
        {/* Desktop View */}
        <table className="w-full border-spacing-0 border-separate border rounded-lg hidden md:table fund-redirect-tbl">
          <thead>
            <tr>
              {getHeaders().map((header, index) => (
                <th
                  key={index}
                  className="border-b p-2 text-left text-[#6E7C87] text-xs font-semibold leading-6 bg-[#F6F8F9]"
                >
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {fundsData?.map((each) => (
              <tr
                key={each.isin}
                className={`${each?.isNew ? "bg-blue-100" : ""}`}
              >
                <td className="p-2 border-b w-[5%] ">
                  <button
                    onClick={() => deleteRow(each.isin)}
                    className="flex w-6 h-6 justify-center items-center shrink-0 rounded bg-[#E5E9EB] ipad:bg-[#fff]"
                  >
                    <img src={deleteicon} alt="delete" />
                  </button>
                </td>
                {getRowValues(each).map((value, valueIndex) => (
                  <td
                    key={valueIndex}
                    className="p-2 text-sm font-normal leading-6 border-b flex justify-between md:table-cell"
                  >
                    {value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot className="justify-end  w-full bg-[#F6F8F9] border-separate border rounded-[10px] border-b-1 border-solid">
            {fundsData?.length > 0 && (
              <tr className="self-stretch justify-center items-center  bg-[#F6F8F9] border-b">
                <td
                  colSpan="2"
                  className="px-6 py-2.5 w-full text-[#252C32] text-base not-italic font-semibold leading-6 tracking-[-0.096px] text-right"
                >
                  <span className="">{translate("Total", "المجموع ")}</span>
                </td>
                <td
                  colSpan="3"
                  className="px-6 py-2.5 w-full text-[#252C32] text-base not-italic font-semibold leading-6 tracking-[-0.096px] text-left pl-[8px]"
                >
                  <span className="">
                    {translate(
                      currencyFormatterWithCountryCode(
                        `BHD ${total || 0}`,
                        `دينار بحريني ${total || 0}`
                      )
                    )}
                  </span>
                </td>
              </tr>
            )}
          </tfoot>
        </table>

        {/* Mobile View */}
        <div className="md:hidden">
          {tableType == "redirecting" && (
            <Button
              label={translate("+ Add Fund", "+ إضافة صندوق")}
              // pre_icon={deleteicon}
              onSubmit={addFundHandler}
              className="flex flex-col items-center gap-2 bg-[#55C6EF] px-4 py-2 rounded-[10px] text-sm not-italic font-semibold leading-6 max767:mb-3 max767:ml-4"
            />
          )}
          {fundsData?.map((each) => (
            <React.Fragment key={each.isin}>
              <div
                onClick={() => toggleRow(each.isin)}
                className="cursor-pointer bg-[#F6F8F9] mb-2 p-3 rounded-lg flex justify-between max767:flex max767:rounded-none max767:mb-0 removefundbox max767:border-b-[#DDE2E4] max767:border-b max767:border-solid max767:px-5 max767:py-4"
              >
                <div>
                  <span className="text-sm font-semibold">
                    {each.fund_name}
                  </span>
                </div>
                <FontAwesomeIcon
                  icon={isRowExpanded(each.isin) ? faChevronUp : faChevronDown}
                  className="flex text-[#252C32] text-xs mt-[-5px] mr-4 ipad:mr-0 max767:mt-2 max767:mr-1"
                />
              </div>

              {isRowExpanded(each.isin) && (
                <div className="mb-4 bg-[#F6F8F9]">
                  {getRowValues(each)
                    .slice(1, 3)
                    .map((value, valueIndex) => (
                      <div
                        key={valueIndex}
                        className="p-3 text-sm flex justify-between allocationbox max767:px-5 max767:py-4 max767:pb-0"
                      >
                        <span className="text-[#6E7C87] text-sm not-italic font-medium leading-[normal]">
                          {getHeaders()[valueIndex + 2]}:
                        </span>
                        <span className="text-black text-right text-base not-italic font-normal leading-[normal]">
                          {value}
                        </span>
                      </div>
                    ))}
                  <div className="p-3 flex justify-center">
                    <Button
                      label={translate(
                        "Remove Fund Allocation",
                        "إزالة تخصيص الصندوق"
                      )}
                      pre_icon={deleteicon}
                      onSubmit={() => deleteRow(each.isin)}
                      className="flex items-center gap-2 self-stretch border bg-white px-4 py-2 rounded-md border-solid border-[#DDE2E4] w-full justify-center text-[#1A2024] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
                    />
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        {errorMsg && (
          <div className="mt-4 text-red-500 text-sm ipad:pl-4 ipad:pr-4 hidden md:block">
            <AlertComponent title={errorMsg} />
          </div>
        )}
        {total != allocationTotal && (
          <div className="mt-4 text-red-500 text-sm ipad:pl-4 ipad:pr-4 hidden md:block">
            <AlertComponent
              title={`Total allocation should be  ${currencyFormatterWithCountryCode(
                `BHD ${allocationTotal}`
              )}`}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default AllocationTable;
