import React, { useState } from 'react'
import OTPInput from 'react-otp-input';
import './Otp.css'

const Otp = ({ className , value,setValue  }) => {
    return (
        <OTPInput
            value={value}
            onChange={setValue}
            placeholder='0000'
            inputType='number'
            numInputs={4}
            renderInput={(props) => <input {...props} className={`flex w-20 h-20 text-center text-[32px] not-italic font-semibold justify-center items-center gap-2.5 border bg-white px-4 py-3 rounded-[10px] border-solid border-[#B0BABF] max767:w-[80px] max767:h-[80px] max-350:w-[55px] max-350:h-[55px] ${className} otp_input `} />}
        />
    )
}

export default Otp