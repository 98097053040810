import { policyInfo } from "../../data";

export const BREADCRUMP_ITEMS = [
  {
    label: "My Policy",
    link: "/my-policy",
    clickable: true,
    name: 'my_policy'
  },

];


export const MANAGE_BENEFICIARY_HEADER_DATA = [
  { label: "Beneficiary Name",label_ar:"اسم المستفيد", key: "fullName" },
  { label: "Share Percentage",label_ar:"نسبة الحصة", key: "share_percentage" },
  { label: "Relationship",label_ar:"العلاقة", key: "relationship" },
  { label: "Mobile Number", label_ar:"رقم الهاتف ", key: "mobile" },
];

export const MANAGE_BENEFICIARY_DATA = {
  cpr: "677890",
  day: "16",
  email: "testemail@gmail.com",
  fullName: "Sayed Mohammed Sadiq",
  mobile: "+97337487875",
  month: "09",
  nationality: "Bahraini",
  relationship: "BLOODR",
  share_percentage: "100%",
  year: "1965",
  gender: "F",
};



export const trusteeTypeTabTypes = [
  { label_en: "Primary", label_ar: "نعمنعم", type: "Primary" },
  { label_en: "Contigent", label_ar: "نعملا", type: "Contigent" }
]


export const SHARE_PERCENTAGE_TABLE_DATA = [
  {
    id: 1,
    beneficiary_name: 'Sayed Mohammed Sadiq',
    share_percentage: "20"
  },
]


export const ADD_BENEFICIARY_VALIDATIONS = [
  {
    name: "fullName",
    validations: ['required']
  },
  {
    name: "email",
    validations: ['required']
  },
  {
    name: "mobile",
    validations: ['required']
  },
  {
    name: "cpr",
    validations: ['required'],
    exact_length: 9,
  },
  {
    name: "gender",
    validations: ['select']
  },
  {
    name: "day",
    validations: ['select']
  },
  {
    name: "month",
    validations: ['select']
  },
  {
    name: "year",
    validations: ['select']
  },
  {
    name: "relationship",
    validations: ['required']
  },
  {
    name: "nationality",
    validations: ['select']
  },
  {
    name: "share_percentage",
    validations: ['required']
  },
]

export const MOBILE_VALIDATION = {
  name: "mobile",
  validations: ['required']
}
export const FORM_VALIDATION_WITH_MOBILE_VALIDATION = [
  ...ADD_BENEFICIARY_VALIDATIONS,
  MOBILE_VALIDATION
]

export const TRUSTEE_DETAILS_VALIDATION = [
  {
    name: "is_finance",
    validations: ['select']
  },
  {
    name: "fullName",
    validations: ['required']
  },
  {
    name: "day",
    validations: ['select']
  },
  {
    name: "month",
    validations: ['select']
  },
  {
    name: "nationality",
    validations: ['select']
  },
  {
    name: "year",
    validations: ['select']
  },
  {
    name: "relationship",
    validations: ['required']
  },
  {
    name: "mobile",
    validations: ["length"],
        exact_length: 8,
  },
]


export const ageCalculator = (person) => {
  const day = parseInt(person.day, 10);
  const month = parseInt(person.month, 10) - 1;
  const year = parseInt(person.year, 10);

  const birthDate = new Date(year, month, day);
  const today = new Date();

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (monthDiff < 0 || (monthDiff ==0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}


export const RELATIONSHIP_OPTIONS = [
  { value: 'AUNT', label: 'Aunt' },
  { value: 'BLOODR', label: 'Blood Relatives' },
  { value: 'BROTHER', label: 'Brother' },
  { value: 'BROIL', label: 'Brother in Law' },
  { value: 'DATR', label: 'Daughter' },
  { value: 'DTRIL', label: 'Daughter in Law' },
  { value: 'EMPLOYEE', label: 'Employee' },
  { value: 'FATHER', label: 'Father' },
  { value: 'FIL', label: 'Father in Law' },
  { value: 'GDTR', label: 'Grand Daughter' },
  { value: 'GFATHER', label: 'Grand Father' },
  { value: 'GMOTHER', label: 'Grand Mother' },
  { value: 'GSON', label: 'Grand Son' },
  { value: 'LEGALHEIR', label: 'Legal Heir' },
  { value: 'MEMBER', label: 'Member' },
  { value: 'MOTHER', label: 'Mother' },
  { value: 'OTHERS', label: 'Others' },
  { value: 'SISTER', label: 'Sister' },
  { value: 'SISIL', label: 'Sister in Law' },
  { value: 'SON', label: 'Son' },
  { value: 'SONIL', label: 'Son in Law' },
  { value: 'SPOUSE', label: 'Spouse' },
  { value: 'UNCLE', label: 'Uncle' }
];