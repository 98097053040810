import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import SignInForm from "../../signInRegisterPages/SignInPage/signInForm";
import { useTranslation } from "react-i18next";
import logo from "../../../../src/assets/images/Logo.svg"
const TransferSigninPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const onTransferSignInClicked = () => {
    // if (loginTokenInfo) {
    navigate(`/policyTransfer/${id}`);
    // }
  };
  return (
    <>
    <div className="flex flex-col items-start self-stretch bg-[#F6F8F9] p-12 rounded-[26px] w-[673px] max-w-[100%] mx-auto absolute mx-auto my-0 p-10 inset-x-0">
      <div className="content ">
        <p className="mb-6"><img src={logo} alt="logo" className="w-[202px] h-16"/></p>
        <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mb-2 max767:text-xl">
          Sign In{" "}
        </h1>
        <p className="text-[#1A2024] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] text-left mb-6">
          Enter your details to finish policy transfer please sign in or
          register.
        </p>
      </div>
      <div className="sign_inForm items-start gap-4 self-stretch bg-[#F6F8F9] rounded-2xl  pb-0.5">
        <SignInForm cbSuccess={onTransferSignInClicked} />
      </div>
      <div className="newHere flex flex-col items-left gap-4 self-stretch bg-[#F6F8F9] text-left p-1 pl-6 pr-6 rounded-2xl mb-6">
        <p className="text-left">Don't have an account?  <Link
          to="/sign-up"
          className="text-[#00384D] text-center text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline"
        >
          Sign Up
        </Link></p>
       
      </div>
      <p className="pl-6 pr-6 text-[#5B6871] text-xs not-italic font-normal leading-[150%] pb-6">
        Solidarity Bahrain is a Subsidiary of Solidarity Group Holding
        <br />
        Licensed and regulated by the Central Bank of Bahrain as a locally
        incorporated Takaful Firm – Category four
      </p>
      </div>
    </>
  );
};

export default TransferSigninPage;
