export const SELECT_FUNDS_TABLE_DATA = [
  {
    id: 421,
    fund_name: 'ADCB ISLAMIC GCC EQUITY "R" (USD) ACC',
    isin: "4530892",
    category: "Growth",
    currency: "USD",
    managementApproach: "Active",
    geographicFocus: "GCC",
    assetClass: "Equity",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
  {
    id: 237,
    fund_name: "SNB CAPITAL SAUDI RIYAL TRADE FUND",
    isin: "3718105",
    category: "Growth",
    currency: "SAR",
    managementApproach: "Passive",
    geographicFocus: "Global",
    assetClass: "Sukuk (Islamic)",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
  {
    id: 319,
    fund_name: 'CIMB ISLAMIC ASEAN EQUITY FUND "X" (USD) ACC',
    isin: "3718105",
    category: "Growth",
    currency: "SAR",
    managementApproach: "Passive",
    geographicFocus: "Global",
    assetClass: "Sukuk (Islamic)",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
  {
    id: 564,
    fund_name: "SNB Capital GCC Growth and Income Fund",
    isin: "7013406",
    category: "Conservative",
    currency: "USD",
    managementApproach: "Passive",
    geographicFocus: "Europe",
    assetClass: "Sukuk (Islamic)",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
  {
    id: 109,
    fund_name: "SNB Capital Multi-Asset Conservative (USD) ACC",
    isin: "A3ON005",
    category: "Conservative",
    currency: "USD",
    managementApproach: "Passive",
    geographicFocus: "United States of America",
    assetClass: "Sukuk (Islamic)",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
  {
    id: 723,
    fund_name: 'COMGEST GROWTH EUROPE "S USD" (USD) ACC',
    isin: "8427704",
    category: "Growth",
    currency: "USD",
    managementApproach: "Active",
    geographicFocus: "Saudi Arabia",
    assetClass: "Mixed Assets",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
  {
    id: 845,
    fund_name: 'TATA INDIAN SHARIA EQUITY "B" (USD)',
    isin: "8427704",
    category: "Growth",
    currency: "SAR",
    managementApproach: "Passive",
    geographicFocus: "Global",
    assetClass: "Sukuk (Islamic)",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
  {
    id: 649,
    fund_name: 'TEMPLETON SHARIAH GLOBAL EQUITY FUND "X" (USD)',
    isin: "8427704",
    category: "Growth",
    currency: "SAR",
    managementApproach: "Passive",
    geographicFocus: "Global",
    assetClass: "Sukuk (Islamic)",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
  {
    id: 950,
    fund_name: "SNB Capital Multi-Asset Conservative (USD) ACC",
    isin: "A3ON005",
    category: "Moderate-Growth",
    currency: "USD",
    managementApproach: "Passive",
    geographicFocus: "Europe",
    assetClass: "Sukuk (Islamic)",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
  {
    id: 781,
    fund_name: 'TATA INDIAN SHARIA EQUITY "B" (USD)',
    isin: "3718105",
    category: "Growth",
    currency: "SAR",
    managementApproach: "Passive",
    geographicFocus: "Global",
    assetClass: "Sukuk (Islamic)",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
  {
    id: 302,
    fund_name: 'COMGEST GROWTH EUROPE S "USD" (USD) ACC',
    isin: "8093902",
    category: "Growth",
    currency: "USD",
    managementApproach: "Active",
    geographicFocus: "Saudi Arabia",
    assetClass: "Mixed Assets",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
  {
    id: 648,
    fund_name: 'OASIS CRESCENT GLOBAL INCOME "X" (USD) ACC',
    isin: "8093902",
    category: "Growth",
    currency: "USD",
    managementApproach: "Active",
    geographicFocus: "Saudi Arabia",
    assetClass: "Mixed Assets",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
  {
    id: 509,
    fund_name: 'CIMB ISLAMIC ASEAN EQUITY FUND "A" (USD) ACC',
    isin: "3718105",
    category: "Growth",
    currency: "SAR",
    managementApproach: "Passive",
    geographicFocus: "Global",
    assetClass: "Sukuk (Islamic)",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
  {
    id: 742,
    fund_name: "SNB CAPITAL SAUDI RIYAL TRADE FUND",
    isin: "5409286",
    category: "Moderate-Growth",
    currency: "USD",
    managementApproach: "Active",
    geographicFocus: "GCC",
    assetClass: "Mixed Assets",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
  {
    id: 683,
    fund_name: 'MASHREQ AL ISLAMI INCOME FUND "D" (USD)',
    isin: "5409286",
    category: "Moderate-Growth",
    currency: "USD",
    managementApproach: "Active",
    geographicFocus: "GCC",
    assetClass: "Mixed Assets",
    current_price: 2.2345,
  },
  {
    id: 853,
    fund_name: 'OASIS CRESCENT GLOBAL INCOME "X" (USD) ACC',
    isin: "6500010",
    category: "Moderate-Growth",
    currency: "USD",
    managementApproach: "Active",
    geographicFocus: "Saudi Arabia",
    assetClass: "Mixed Assets",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
  {
    id: 537,
    fund_name: 'AZ ISLAMIC NAMG GLOBAL SUKUK "A-ME" (USD) ACC',
    isin: "5409286",
    category: "Growth",
    currency: "USD",
    managementApproach: "Active",
    geographicFocus: "Saudi Arabia",
    assetClass: "Mixed Assets",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
  {
    id: 148,
    fund_name: "SNB CAPITAL SAUDI RIYAL TRADE FUND",
    isin: "7482007",
    category: "Moderate-Growth",
    currency: "USD",
    managementApproach: "Active",
    geographicFocus: "United States of America",
    assetClass: "Money Market",
    units_held: 790.731,
    current_price: 4.6587,
    total_value: "BHD 1,472.10",
    current_percent: "10%",
    new_percent: 10,
    allocation: 10,
  },
];



export const categoriesOptions = [
  { text_en: 'Conservative', text_ar: 'محافظ', type: 'Conservative' },
  { text_en: 'Moderate', text_ar: 'متوسط', type: 'Moderate' },
  { text_en: 'Moderate - Growth', text_ar: 'متوسط - نمو', type: 'Moderate - Growth' },
  { text_en: 'Growth', text_ar: 'نمو', type: 'Growth' }
];

export const currenciesOptions = [
  { text_en: 'SAR - Saudi riyal', text_ar: 'ريال سعودي - SAR', type: 'SAR - Saudi riyal' },
  { text_en: 'USD - United States dollar', text_ar: 'دولار أمريكي - USD', type: 'USD - United States dollar' }
];

export const geographicFocusOptions = [
  { text_en: 'GCC', text_ar: 'دول مجلس التعاون الخليجي', type: 'GCC' },
  { text_en: 'Global', text_ar: 'عالمي', type: 'Global' },
  { text_en: 'Europe', text_ar: 'أوروبا', type: 'Europe' },
  { text_en: 'Saudi Arabia', text_ar: 'المملكة العربية السعودية', type: 'Saudi Arabia' },
  { text_en: 'United States of America', text_ar: 'الولايات المتحدة الأمريكية', type: 'United States of America' },
  { text_en: 'United Arab Emirates', text_ar: 'الإمارات العربية المتحدة', type: 'United Arab Emirates' },
  { text_en: 'MENA', text_ar: 'الشرق الأوسط وشمال أفريقيا', type: 'MENA' },
  { text_en: 'Asia Pacific', text_ar: 'آسيا والمحيط الهادئ', type: 'Asia Pacific' },
  { text_en: 'Global Emerging Markets', text_ar: 'الأسواق الناشئة العالمية', type: 'Global Emerging Markets' },
  { text_en: 'India', text_ar: 'الهند', type: 'India' }
];

export const assetClassesOptions = [
  { text_en: 'Equity', text_ar: 'حقوق الملكية', type: 'Equity' },
  { text_en: 'Sukuk (Islamic Bond)', text_ar: 'صكوك (سند إسلامي)', type: 'Sukuk (Islamic Bond)' },
  { text_en: 'Money Market', text_ar: 'سوق المال', type: 'Money Market' },
  { text_en: 'Mixed Assets', text_ar: 'أصول مختلطة', type: 'Mixed Assets' }
];


export const performanceOptions = ["performance"];
