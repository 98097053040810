import React, { useEffect, useState } from 'react'
import FundManagementChart from './expandedComponent';
import useScreenResolution from '../../../../hooks/useScreenResolution';
import { lifeApiEndPoints } from '../../../../api';
import Axios from '../../../../redux/axiosInstance';
import useLanguageTranslate from '../../../../hooks/useLanguageTranslate';
import { currencyFormatterWithCountryCode } from '../../../../utils';
import useFundQuarterlyReturns from '../../../../hooks/useFundQuarterlyReturns';

const TableRow = ({ item, index, investmentStartegyType , fundDoc }) => {
  const [expand, SetExpand] = useState(false);
  const translate = useLanguageTranslate();
  const screenResolution = useScreenResolution();
  const {data:chartResponse,fetchChartData,loading} = useFundQuarterlyReturns()

  const handleExpand = () => {
    SetExpand((state) => !state);
  };

  useEffect(() => {
    if (expand) {
      fetchChartData(item.fundISIN);
    }
  }, [expand]);

  return !(screenResolution.mobile || screenResolution.tablet) ? (
    <React.Fragment key={index}>
      <tr>
        <td className="px-6 py-2.5 border-b border-r border-gray-200 text-black max767:text-2xl max767:not-italic max767:font-medium max767:leading-[120%]">
          {item.fundName}
        </td>
        <td className="px-6 py-2.5 border-b border-r border-gray-200 text-[#1A2024] text-sm not-italic font-normal leading-6">
          {item.allocationPercent}
        </td>
        <td className="px-6 py-2.5 border-b border-r border-gray-200 text-[#1A2024] text-sm not-italic font-normal leading-6">
          {item.numberOfUnit}
        </td>
        <td className="px-6 py-2.5 border-b border-r border-gray-200 text-[#1A2024] text-sm not-italic font-normal leading-6">
          {item.unitPrice}
        </td>
        <td className="px-6 py-2.5 border-b border-r border-gray-200 text-[#1A2024] text-sm not-italic font-normal leading-6">
          {currencyFormatterWithCountryCode(item.fundValue)}
        </td>
        <td className="px-6 py-2.5 border-b border-r border-gray-200 text-[#1A2024] text-sm not-italic font-normal leading-6">
          <button
            className="flex px-1 py-0 items-center border bg-[#EEF0F2] rounded-md border-solid border-[#E5E9EB] hover:text-gray-900 hover:bg-gray-100 text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] expandbtn"
            onClick={handleExpand}
          >
            <svg
              className={`w-4 h-4 mr-2 transition-transform duration-200 ${
                expand ? "" : "transform rotate-180"
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 15l7-7 7 7"
              />
            </svg>
            {expand
              ? translate("Collapse", "ينهار")
              : translate("Expand", "يوسع")}
          </button>
        </td>
      </tr>
      {expand && (
        <tr>
          <td
            colSpan="6"
            className="justify-between items-start self-stretch px-6 py-8 bg-[#FBF7F0] border-l-[#DDE2E4] border-r border-l border-solid"
          >
            <FundManagementChart
              item={item}
              chartResponse={chartResponse}
              investmentStartegyType={investmentStartegyType}
              fundDoc={fundDoc}
            />
          </td>
        </tr>
      )}
    </React.Fragment>
  ) : (
    <>
      <div
        className="flex-col items-center mb-2 ipad:mt-4 border-b max767:mb-0 max767:p-5 max767:border-y-[#DDE2E4] max767:border-t max767:border-solid"
        key={index}
      >
        <div className="flex justify-between">
          <p className="text-2xl not-italic font-medium mb-1">
            {item.fundName}
          </p>
          <button
            className="w-[86px] h-[24px] inline-flex px-1 py-0 items-center border bg-[#EEF0F2] rounded-md border-solid border-[#E5E9EB] hover:text-gray-900 hover:bg-gray-100 text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px] ml-2 mb-1"
            onClick={() => handleExpand(index)}
          >
            <svg
              className={`w-4 h-4 mr-2 transition-transform duration-200 ${
                expand ? "" : "transform rotate-180"
              }`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 15l7-7 7 7"
              />
            </svg>
            {expand
              ? translate("Collapse", "ينهار")
              : translate("Expand", "يوسع")}
          </button>
        </div>
        <div>
          <div className="flex justify-between">
            <p className="text-base not-italic font-semibold mb-1">
              {translate("Allocation %", "التخصيص %")}
            </p>{" "}
            <p className="text-base not-italic font-normal mb-1">
              {item.allocationPercent}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-base not-italic font-semibold mb-1">
              {translate("Number of Unit", "عدد الوحدات")}
            </p>{" "}
            <p className="text-base not-italic font-normal mb-1">
              {item.numberOfUnit}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-base not-italic font-semibold mb-1">
              {translate("Unit Price", "سعر الوحدة")}
            </p>{" "}
            <p className="text-base not-italic font-normal mb-1">
              {item.unitPrice}
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-base not-italic font-semibold mb-1">
              {translate("Fund Value", "قيمة الصندوق")}
            </p>{" "}
            <p className="text-base not-italic font-normal mb-1">
              {currencyFormatterWithCountryCode(item.fundValue)}
            </p>
          </div>
        </div>
        <div className="text-right"></div>
      </div>
      {expand && (
        <div className="bg-[#FBF7F0] items-start gap-6 self-stretch px-5 py-6 border-b-[#DDE2E4] border-b border-solid max767:py-0 max767:mt-0 max767:border-b-0">
          <FundManagementChart
            item={item}
            chartResponse={chartResponse}
            investmentStartegyType={investmentStartegyType}
            fundDoc={fundDoc}

          />
        </div>
      )}
    </>
  );
};

export default TableRow