import React, { useState } from "react";
import { screenResolution } from "../../../../utils/screenResolution";
import UploadPhotosOfCar from "./uploadPhotosOfCar";
import MobileUploadCarPhotoStepperLayout from "./MobileUploadCarPhotoStepperLayout";

const UploadsMainPage = () => {

    return (
        <>
            {
                screenResolution.mobile ? <MobileUploadCarPhotoStepperLayout /> : <UploadPhotosOfCar />
            }
        </>
    )
};

export default UploadsMainPage;
