import React, { useMemo, useState } from "react";
import ExpressShieldPlanLayout from "../expressShieldPlanLayout";
import FormTabs from "../../../../components/common/tabs/formTabs";
import { useTranslation } from "react-i18next";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import useValidation from "../../../../hooks/useValidation";
import { useSelector } from "react-redux";
import { selectQuestionnairePageCms } from "../../../../redux/features/buyOnlinePlans/expressShieldFlowStrapiCmsSlice";
import ReviewYourApplication from "../details/reviewApplication";
import useLifeFormData from "../../../../hooks/useLifeFormData";
import useUpdateLead from "../../../../hooks/BuyOnlineExpressShield/useUpdateLead";
import { LEAD_INFO } from "../details/data";
import { selectNewLifeForm } from "../../../../redux/features/newLifeFormSlice";
import { EXPRESS_SHEILD_OTHER_DETAILS } from "../details/otherDetails";

export const yesNoTabs = [
  { label_en: "Yes", label_ar: "نعم", type: "Yes" },
  { label_en: "No", label_ar: "لا", type: "No" },
];
export const EXPRESS_SHEILD_DECLERATION = "EXPRESS_SHEILD_DECLERATION";
const Declaration = ({ onSuccess }) => {
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const { updateLeadHandler, leadLoader } = useUpdateLead();
    const otherDetails = useSelector(selectNewLifeForm)?.[EXPRESS_SHEILD_OTHER_DETAILS];

  const [formData, setFormData] = useState({
    answers: {
    
    },
  });

  const cmsData = useSelector(selectQuestionnairePageCms);
  const [invalidDataPopup, setInvalidDataPopup] = useState(false);
  const { errors, validateFields } = useValidation();
  const { saveLifeDataInRedux } = useLifeFormData(
    EXPRESS_SHEILD_DECLERATION,
    setFormData
  );
  const validationSchemaGenerate = useMemo(() => {
    let schema = cmsData?.questions?.map((question) => ({
      name: `${question.mapping_key}`,
      validations: ["required"],
    }));
    return [...schema];
  }, [cmsData?.questions]);

  const validateForm = (data) => {
    let form_data = data || formData;
    let isValid = validateFields(validationSchemaGenerate, form_data);
    return isValid;
  };

  console.log('validation', formData.answers);
  const nextClickHandler = async () => {
    if (validateForm(formData.answers)) {
      const hasYesAnswer = Object.values(formData?.answers).some(
        (answer) => answer =="No"
      );

      if (hasYesAnswer) {
        setInvalidDataPopup(true);
      } else {
        updateLeadHandler({ Info: LEAD_INFO.questionnaire });
        saveLifeDataInRedux(formData);
        onSuccess();
      }
    }
  };

  const toggleInvalidDataPopup = () => {
    setInvalidDataPopup(!invalidDataPopup);
  };

  return (
    <ExpressShieldPlanLayout
      is_bottom_sticky_layout={!invalidDataPopup}
      btn_onClick={nextClickHandler}
    >
      <div className="w-[500px] max-w-[100%] mx-auto">
        <TitleDescription
          title={translate(
            cmsData?.headings_content?.title_en,
            cmsData?.headings_content?.title_ar
          )}
          description={translate(
            cmsData?.headings_content?.description_en,
            cmsData?.headings_content?.description_ar
          )}
        />
        {cmsData?.questions?.map((question) => (
          <div key={question.id} className="mb-6">
            <FormTabs
              label={translate(question.content_en, question.content_ar)}
              tabs={yesNoTabs}
              onTabClick={(type) =>
                setFormData((prev) => ({
                  ...prev,
                  answers: { ...prev?.answers, [question.mapping_key]: type },
                }))
              }
              activeTab={formData?.answers[question.mapping_key]}
              error={errors?.[question?.mapping_key]}
            />
          </div>
        ))}
{/* 
        {otherDetails?.gender==="F" &&<div className="mb-6">
          <FormTabs
            label={translate(
              "Are you currently pregnant?",
              "هل أنت حامل حاليًا؟"
            )}
            tabs={yesNoTabs}
            activeTab={formData?.answers["Pregnant"]}
            onTabClick={(type) =>
              setFormData((prev) => ({
                ...prev,
                answers: { ...prev?.answers, ["Pregnant"]: type },
              }))
            }
          />
        </div>} */}
        {/* <div className="mb-6">
          <FormTabs
            label={translate("Do you smoke?", "هل تدخن؟")}
            tabs={yesNoTabs}
            activeTab={formData?.answers["Smoker"]}
            onTabClick={(type) =>
              setFormData((prev) => ({
                ...prev,
                answers: { ...prev?.answers, ["Smoker"]: type },
              }))
            }
          />
        </div>
        <div className="mb-6">
          <FormTabs
            label={translate("Do you consume alcohol?", "هل تتناول الكحول؟")}
            tabs={yesNoTabs}
            activeTab={formData?.answers["ConsumeAlcohol"]}
            onTabClick={(type) =>
              setFormData((prev) => ({
                ...prev,
                answers: { ...prev?.answers, ["ConsumeAlcohol"]: type },
              }))
            }
          />
        </div> */}
        <ReviewYourApplication
          type="answeredYes"
          show={invalidDataPopup}
          onClose={toggleInvalidDataPopup}
        />
      </div>
    </ExpressShieldPlanLayout>
  );
};

export default Declaration;
