import { useState } from "react";
import TelInput from "../../../components/common/telInput/telInput";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import Input from "../../../components/common/Input/Input";
import Button from "../../../components/common/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  setForgotPasswordDetails,
  forgotPassword,
  selectIsLoading
} from "../../../redux/features/forgotPasswordFlow/forgotPasswordSlice";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import { sendOtp } from "../../../redux/features/sendOtpSlice";
import Loader from "../../../components/common/Loader/Loader"

function ResetPassword({ onSuccess }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const translate = useLanguageTranslate();
  const isLoading = useSelector(selectIsLoading);

  const [formData, setFormData] = useState({
    cprNumber: "",
  });
  const [errors, setErrors] = useState({
    cprNumber: "",
  });

  const validateField = (fieldName, value) => {
    let newError = "";

    switch (fieldName) {
      case "cprNumber":
        if (!value) {
          newError = t("validation.required");
        }
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [fieldName]: newError }));
  };


  const validateForm = () => {
    let newErrors = { };
    let formIsValid = true;

    if (!formData.cprNumber) {
      newErrors.cprNumber = t("validation.required");
      formIsValid = false;
    }
    setErrors(newErrors);
    return formIsValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let newValue = value;
    
    setFormData((prev) => ({ ...prev, [name]: newValue }));
    validateField(name, newValue);
  };

  const NextHandler = async () => {
    if (validateForm()) {
      try {
        dispatch(
          setForgotPasswordDetails({
            cprNumber: formData.cprNumber,
          })
        );

        const response = await dispatch(forgotPassword(formData?.cprNumber));
        if (response?.payload?.Token) {
          onSuccess();
        } else {
          toast.error(
            translate(
              response?.payload?.ErrorMessageEn,
              response?.payload?.ErrorMessageAr
            )
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
    {isLoading && <Loader className=""/>}
      <div className="mx-auto bg-white max767:p-5 w-[500px] max-w-[100%] mt-20 max767:mt-20">
        <TitleDescription
          title={t("forgot_password_flow.reset_password.title")}
          description={t("forgot_password_flow.reset_password.description")}
        />

        <Input
          label={t("signUpFlow.signUpPage.cpr_input_label")}
          onChange={handleChange}
          type="number"
          handleChange={handleChange}
          name="cprNumber"
          value={formData.cprNumber}
          data-testid="cprNumber"
          error={errors.cprNumber}

        />

        <Button
          type="submit"
          className="primary-button"
          onSubmit={NextHandler}
          label={t("forgot_password_flow.reset_password.next")}
        />
       
      </div>
      
    </>
  );
}

export default ResetPassword;
