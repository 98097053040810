import useZoomVideoClient from "../../../hooks/useZoomVideoClient";
import { zoomApiKeys } from "../../../api";
import { useState } from "react";
import BackdropModal from "../modal/backdropModal";
import TelInput from "../telInput/telInput";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";
import useValidation from "../../../hooks/useValidation";

const VideoCallModal = ({ show, onClose }) => {
  const [values, setValues] = useState({});
  const elementId = "VideoCallBtn";
  const { errors, validateFields } = useValidation();

  const translate = useLanguageTranslate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    const update = { ...values, [name]: value };
    setValues(update);
    validateForm(update);
  };
  const validateForm = (data) => {
    let isValid = validateFields(
      [
        {
          name: "mobile",
          validations: ["number", "length"],
          exact_length: 8,
        },
      ],
      data ? data : values
    );
    return isValid;
  };
  const handleCloseClick = () => {
    setValues({ ...values, ["mobile"]: "" });
    onClose();
  };
  useZoomVideoClient(
    elementId,
    zoomApiKeys.entryId,
    `${values?.countryCode || 973}${values.mobile}`,
    handleCloseClick
  );

  return (
    <BackdropModal show={show} onClose={handleCloseClick}>
      <div>
        <TelInput
          name="mobile"
          value={values.mobile}
          error={errors.mobile}
          onChange={handleChange}
          label={translate("Mobile Number", "رقم الجوال")}
          className="mobilenumber px-4 py-3 max767:w-[50%] w-[97%] border-r-0 border-0 rounded-[9px] h-[46px] focus:outline-none"
        />
        <Button
          id="VideoCallBtn"
          label={translate("Start Video", "ابدأ الفيديو")}
          disabled={values?.mobile?.length != 8 || errors.mobile}
          className="mb-0 stickybutton flex w-full flex-col items-center gap-2.5 shrink-0 p-4 bg-[#55C6EF] rounded-[10px] text-[#1A2024] text-center text-lg not-italic font-semibold leading-6 tracking-[-0.18px] max767:w-full"
        />
      </div>
    </BackdropModal>
  );
};

export default VideoCallModal;
