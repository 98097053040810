import React, { useEffect }  from "react";
import ReviewAndConfirmPage from "./reviewAndConfirmPage/reviewAndConfirmPage";
import {
  CHANGE_PLAN_SELECT_NEW_PLAN,
  CHANGE_PLAN_UPLOAD_PHOTOS_OF_CAR,
  CHANGE_PLAN_REVIEW_AND_CONFIRM_PAGE,
} from "./data";
import SelectNewPlan from "./selectNewPlan/selectNewPlan";
import useChangePlanFlow, { CHANGE_PLAN_UPGRADE } from "../../../hooks/useChangePlanFlow";
import UploadsMainPage from "./uploadsMainPage/uploadsMainPage";

const ChangePlanPolicy = () => {
  const { currentStep , handleFlowChange } =  useChangePlanFlow()

  useEffect(()=>{
    handleFlowChange(CHANGE_PLAN_UPGRADE,0)
  },[])

  const PageRenderer = () => {
    switch (currentStep?.name) {
      case CHANGE_PLAN_SELECT_NEW_PLAN:
        return <SelectNewPlan />;
      case CHANGE_PLAN_UPLOAD_PHOTOS_OF_CAR:
        return <UploadsMainPage />;
      case CHANGE_PLAN_REVIEW_AND_CONFIRM_PAGE:
        return <ReviewAndConfirmPage />;

      default:
        return null;
    }
  };
  return (
    <div className="mainCarDetailsModal pt-[5%] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll ">
      <PageRenderer />
    </div>
  );
};

export default ChangePlanPolicy;
