import React from 'react';
import Button from '../../../../../components/common/Button/Button';
import tickicon from "../../../../../assets/images/check.svg";
import useLanguageTranslate from '../../../../../hooks/useLanguageTranslate';

const StrategyCard = ({
    strategyName,
    description,
    selected,
    isCurrentStrategy,
    onClick,
    strategy,
    icon
}) => {
    const translate = useLanguageTranslate();

    return (
        <div onClick={() => onClick(strategy)} className={`${selected ? "bg-[#E3F8FF] border-[#00719A]" : ""} flex flex-col justify-between items-start p-6 rounded-lg border w-[264px] max-w-full max767:w-full`}>
           
           <div className=''>{
                icon && <img src={icon} alt="icon" />
            }
            <p className="text-[#1A2024] text-lg font-semibold leading-6 mb-3 mt-4">
                {strategyName}
            </p>
            
            <p className="text-[#1A2024] text-base not-italic font-normal leading-6 tracking-[-0.16px] mb-6 ">
                {description}
            </p>
            </div> 
            {isCurrentStrategy ? (
                <Button
                    disabled
                    label={translate("Current strategy", "الاستراتيجية الحالية")}
                    className="flex flex-col items-center gap-2.5 self-stretch px-3 py-1 w-full opacity-50 border bg-white rounded-md text-sm font-semibold"
                />
            ) : selected ? (
                <Button
                    pre_icon={tickicon}
                    label={translate("Selected", "تم اختياره")}
                    className="flex flex-col items-center gap-2.5 self-stretch px-3 py-1 w-full bg-[#55C6EF] text-[#1A2024] rounded-md text-sm font-semibold  justify-center "
                />
            ) : (
                <Button
                    label={translate("Select", "اختر")}
                    className="flex flex-col items-center gap-2.5 self-stretch px-3 py-1 w-full border bg-white rounded-md border-solid border-[#DDE2E4] text-sm font-semibold  justify-center"
                />
            )}
        </div>
    );
};

export default StrategyCard;
