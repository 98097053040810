/* :Page:NewMotor: Car GDT Info page */
import React, { useEffect, useState } from "react";
import Input from "../../../../../components/common/Input/Input";
import Button from "../../../../../components/common/Button/Button";
import TitleDescription from "../../../../../components/newMotorPolicy/titleDescription/titleDescription";
import { useTranslation } from "react-i18next";
import NewMotorJourneyLayout from "../../Layouts/NewMotorJourneyLayout";
import {
  CAR_INFO_PLATE_NUMBER,
  car_info_table_data,
} from "./data";
import CarInfoTable from "./components/carInfoTable";
import NumberPlatesTypesPopup from "./numberPlatesTypesPopup";
import infoicon from "../../../../../assets/images/Informationcircle.svg";
import useValidation from "../../../../../hooks/useValidation";
import BottomStickyBtnLayout from "../../Layouts/BottomStickyBtnLayout";
import useMotorFlow from "../../../../../hooks/useMotorFlow";
import SelectInput from "../../../../../components/common/selectInput/selectInput";
import useMotorForm from "../../../../../hooks/useMotorFormData";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";
import { gdtRetrievePlateTypesListResponse, gdtRetrieveVehicleResponse, retrieveVehicleFromGDT } from "../../../../../redux/features/motor/gdtSlice";
import { fetchBanksList } from "../../../../../redux/features/constants/banksListSlice";
import { fetchCarsList, selectCars, selectCarsLoader } from "../../../../../redux/features/constants/carsListSlice";
import { toast } from 'react-toastify';
import useLanguageTranslate from "../../../../../hooks/useLanguageTranslate";

const CarInfoPlateNumber = () => {

  const dispatch = useDispatch();
  const [vehicleInformation, setVehicleInformation] = useState();
  const [isMappedWithGDT, setMappedWithGDT] = useState(false);
  const banksList = useSelector((state) => state.banksList);
  const translate = useLanguageTranslate()

  const [showCarInfo, setShowcarInfo] = useState(false);
  const [formData, setFormData] = useState({});
  const { errors, validateField } = useValidation();
  const { handleNextSubstep, handleFlowChange } = useMotorFlow()
  const { t } = useTranslation();
  const { saveMotorDataInRedux } = useMotorForm(
    CAR_INFO_PLATE_NUMBER,
    setFormData
  );

  
  const carsList = useSelector(selectCars)
  const carsListLoading = useSelector(selectCarsLoader)

  const plateTypeList = useSelector(gdtRetrievePlateTypesListResponse)


  const continueClickHandler = async () => {
    const isPlateNumber = validateField(
      formData.plateNumber,
      "plateNumber",
      "required"
    );
    // Removed plate type validation as we will only have private for now
    // const isPlateType = validateField(formData.plateType, "plateType", "select");
    
  
    // if (isPlateNumber && isPlateType) {
    if (isPlateNumber) {
      try {
        // Prepare the payload for the GDT API call
        const gdtPayload = {
          PlateOrChassisNo: formData.plateNumber,
          PlateType: formData.plateType ? formData.plateType : "01"
        };
  
        if (!showCarInfo) {
          // Calling API GDT
          const resultAction = await dispatch(retrieveVehicleFromGDT(gdtPayload));
          const vehicleData = await unwrapResult(resultAction);
    
          // Handle the successful response
          console.log("Vehicle data retrieved:", vehicleData.VehicleDetails);
          
          if (vehicleData.VehicleDetails.TCSVehicleMakeCode =="" || vehicleData.VehicleDetails.TCSVehicleModelCode =="") {
            setMappedWithGDT(false);
          }
          else {
            setMappedWithGDT(true);
          }
          // Update car info with response
          setVehicleInformation(vehicleData.VehicleDetails)
        
          setShowcarInfo(true);
        }
  
        if (showCarInfo) {
          // Call banks list before moving to next
          console.log('Mapped to GDT ? ', isMappedWithGDT);

          if (!banksList.banks) {
            await dispatch(fetchBanksList());
          }

          // Calling API /constant/cars
          if (!carsList) {
            await dispatch(fetchCarsList());
          }
          if (!carsListLoading) {
            const prorataDates = getProrataDates(
              vehicleInformation.RegistrationExpiryDate
            );

            console.log("Should continue. Pro-rata dates: ");
            console.log(
              "Registration expiry date: ",
              vehicleInformation.RegistrationExpiryDate
            );

            // Process vehicle date
            console.log("Processed vehicle date:", prorataDates);
            // If registration expiry date is more than 90 days ahead, display alert.
            const prorataStartDate = getProrataDateFrom(
              vehicleInformation.RegistrationExpiryDate
            );
            if (prorataStartDate =="MoreThan90") {
              toast.error(
                "GDT Registration expiry date must be less than 90 days ahead"
              );
            } else if (prorataStartDate =="Backdated") {
              toast.error("GDT Registration is expired");
            } else {
              const updatedFormData = {
                ...formData, // Spread the existing formData
                newEndDate: prorataDates, // Add or update the newEndDate field
                newFromDate: prorataStartDate,
                isMappedWithGDT: isMappedWithGDT,
                TCSMakeCode: vehicleInformation.TCSVehicleMakeCode =="" ? "" : vehicleInformation.TCSVehicleMakeCode,
                TCSModelCode: vehicleInformation.TCSVehicleModelCode =="" ? "" : vehicleInformation.TCSVehicleModelCode,
                vehiclePlateType: vehicleInformation.PlateTypeCode
              };

              console.log('Mapped to GDT ? ', isMappedWithGDT);
              saveMotorDataInRedux(updatedFormData);
              handleNextSubstep();
            }
          }
        }
      } catch (error) {
        // Handle any errors from the API call
        // alert("Error retrieving vehicle data:", error);
        toast.error("Error retrieving vehicle data:", error);
      }
    }
  };

  // Pro-rata function for end date
const getProrataDates = (vehicleDate) => {
  if (!vehicleDate) {
    console.error("Vehicle date is missing");
    return null;
  }

  const regExpDate = new Date(vehicleDate);
  const currentDate = new Date();

  // Validate if regExpDate is a valid date
  if (isNaN(regExpDate)) {
    console.error("Invalid vehicle date");
    return null;
  }

  // Calculate the difference in months between two dates
  const getMonthDifference = (startDate, endDate) => {
    return (endDate.getFullYear() - startDate.getFullYear()) * 12 + (endDate.getMonth() - startDate.getMonth());
  };

  let newEndDate;

  // Logic for when reg. exp. date is greater than current date
  if (regExpDate > currentDate) {
    const monthDiff = getMonthDifference(currentDate, regExpDate);
    
    if (monthDiff < 6) {
      // Add 1 year if the difference is less than 6 months
      newEndDate = new Date(regExpDate.setFullYear(regExpDate.getFullYear() + 1));
    } else if (monthDiff >= 6) {
      // Keep the reg. exp. date if the difference is 6 months or more
      newEndDate = regExpDate;
    }
  }
  
  // Logic for when reg. exp. date is less than current date
  else if (regExpDate < currentDate) {
    const monthDiff = getMonthDifference(regExpDate, currentDate);
    
    const regExpDatePlusOneYear = new Date(regExpDate);
    regExpDatePlusOneYear.setFullYear(currentDate.getFullYear() + 1);

    if (monthDiff < 6) {
      // Add 2 years if the difference is less than 6 months
      newEndDate = new Date(regExpDate.setFullYear(currentDate.getFullYear() + 2));
    } else if (monthDiff >= 6) {
      // Add 1 year if the difference is 6 months or more
      newEndDate = new Date(regExpDate.setFullYear(currentDate.getFullYear() + 1));
    }
  }

  // Format the result date to YYYY-MM-DD
  if (newEndDate) {
    return newEndDate.toISOString().split('T')[0];
  }

  return null;  // return null if no valid newEndDate
  };

  // Pro-rata start data 
// Pro-rata start date
const getProrataDateFrom = (vehicleDate) => {
  if (!vehicleDate) {
    console.error("Vehicle date is missing");
    return null;
  }

  const regExpDate = new Date(vehicleDate);
  const today = new Date();

  // Check if the vehicle date is invalid or backdated
  if (isNaN(regExpDate.getTime()) || regExpDate < today) {
    console.error("Vehicle date is invalid or backdated");
    return "Backdated";
  }

  // Calculate the difference in days
  const diffInTime = regExpDate.getTime() - today.getTime();
  const diffInDays = Math.ceil(diffInTime / (1000 * 60 * 60 * 24));

  // If regExpDate is more than 90 days ahead
  if (diffInDays > 90) {
    console.error("Vehicle date is more than 90 days ahead");
    return "MoreThan90";
  }

  
  // Add one day to regExpDate for the startDate
  const startDate = new Date(regExpDate);
  startDate.setDate(startDate.getDate() + 1);

  // Format the result date to YYYY-MM-DD
  if (startDate) {
    return startDate.toISOString().split('T')[0];
  }

  return startDate;
};


  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup((state) => !state);
  };

  const handleChange = (e) => {
    // Remove table with car info on plate number input change
    setShowcarInfo(false);
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    switch (name) {
      case "plateNumber":
        validateField(value, name, "required");
        break;
      case "plateType":
        validateField(value, name, "select");
        break;
      default:
        break;
    }
  };

  console.log(formData,'form data')

  return (
    <NewMotorJourneyLayout>
      <NumberPlatesTypesPopup show={showPopup} onClose={togglePopup} />
      <BottomStickyBtnLayout btn_onClick={continueClickHandler}>
        <div className="max-w-[100%] w-[500px] mx-auto bg-white max767:p-5 max767:pt-0">
          <TitleDescription
            title={t(
              "motorInsurance.newMotorInsuranceFlow.carInfoPlateNumber.title"
            )}
            description={t(
              "motorInsurance.newMotorInsuranceFlow.carInfoPlateNumber.description"
            )}
          />
          <Input
            name="plateNumber"
            type="text"
            label={t(
              "motorInsurance.newMotorInsuranceFlow.carInfoPlateNumber.plateNumber"
            )}
            value={formData?.plateNumber}
            error={errors.plateNumber}
            handleChange={handleChange}
          />
          <SelectInput
            name="plateType"
            default_placeholder="PRIVATE - خصوصي "
            formData={formData}
            value={formData?.plateType}
            options_data={plateTypeList?.map((plate) => ({
              value: plate.PlateTypeCode,
              label: `${plate.EnglishName} - ${plate.ArabicName}`,
            })) || []}
            label={t(
              "motorInsurance.newMotorInsuranceFlow.carInfoPlateNumber.plateType"
            )}
            error={errors?.plateType}
            handleChange={handleChange}
          />
          {/* <Input
            name="plateType"
            default_placeholder="Private"
            placeHolder="Private"
            formData={formData}
            value={formData?.plateType}
            // options_data={[
            //   { value: "Private", label: "Private" },
            // ]}
            label={t(
              "motorInsurance.newMotorInsuranceFlow.carInfoPlateNumber.plateType"
            )}
            error={errors?.plateType}
            handleChange={handleChange}
            disabled={true}

          /> */}
          <div className="alertBox justify-between w-full flex items-start gap-4 px-4 py-3 bg-[#F6F8F9] mb-6 rounded-md items-center">
            <div className="flex gap-1 max767:w-[70%] max767:items-start">
              <img src={infoicon} alt="info" className="infoIcon mr-2" />
              <p className="text-[#252C32] text-sm not-italic font-semibold leading-6 tracking-[-0.084px]">
                {t(
                  "motorInsurance.newMotorInsuranceFlow.carInfoPlateNumber.discoverPlateTypes"
                )}
              </p>
            </div>
            <Button
              label={t(
                "motorInsurance.newMotorInsuranceFlow.carInfoPlateNumber.showMe"
              )}
              onSubmit={togglePopup}
              className="showme flex flex-col items-center gap-2.5 bg-[#55C6EF] text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px] px-3 py-1 rounded-md max767:w-[85px] max767:align-top"
            />
          </div>
          {showCarInfo && (
            <div className="carInfoCard bg-[#F6F8F9] rounded-2xl flex flex-col items-start gap-2 self-stretch p-4 mb-6">
              <h2 className="text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px]">
                {translate("We Found Your Car!","لقد وجدنا سيارتك!")}
              </h2>
              <p className="text-[#1A2024] text-sm not-italic font-normal leading-5 tracking-[-0.14px]">
                {translate("Please confirm the details below are correct for your vehicle.","يرجى التأكد من صحة التفاصيل أدناه لسيارتك.")}
              </p>
              <div className="mobile-scroll max-390:overflow-x-scroll w-full">
                <CarInfoTable table_data={car_info_table_data(vehicleInformation)} />
              </div>
            </div>
          )}
        </div>
      </BottomStickyBtnLayout>
    </NewMotorJourneyLayout>
  );
};

export default CarInfoPlateNumber;
