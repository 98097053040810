import React from "react";
import PerformanceCard from "../../../../components/common/card/performanceCard";
import DynamicTable from "../../../../components/common/table/table";
import DownloadBtn from "../../../../components/common/downloadBtn/downloadBtn";
import useLanguageTranslate from "../../../../hooks/useLanguageTranslate";
import Beneficiaryicon from "../../../../assets/images/account_circle.svg";
import useScreenResolution from "../../../../hooks/useScreenResolution";
import BackdropModal from "../../../../components/common/modal/backdropModal";
import Button from "../../../../components/common/Button/Button";
import { selectUserPoliciesData } from "../../../../redux/features/myPolicySlice";
import { useSelector } from "react-redux";
import PolicyCard from "../../../../components/policyCard/policyCard";
import { useNavigate, useParams } from "react-router-dom";
import appleWallet from "../../../../assets/images/Applewallet.svg"
import selected from "../../../../assets/images/done_outline.svg";
import notselected from "../../../../assets/images/do_not_disturb_alt.svg";
import LifeDynamicTable from "../../../../components/common/table/lifeTable";
import useAppleWalletApi from "../../../../hooks/useAppleWallet";
import { decodeQueryString } from "../../../../utils";
import Loader from "../../../../components/common/Loader/Loader";
const OverviewTab = ({
  info,
  cards,
  chngBeneficiaryCardData,
  downloadCardData,
  // tableData,
  handleDownloadMenuClicked,
  showDownloadPopup,
}) => {
  console.log(downloadCardData,'some downlaod cards data')
  const translate = useLanguageTranslate();
  const navigate = useNavigate();
  const { id } = useParams();
  const params = decodeQueryString()
  const screenResolution = useScreenResolution();
  const userPolicies = useSelector(selectUserPoliciesData); //filter required policy
  const filteredPolicy = userPolicies?.filter(
    (policy) => policy.PolicyNumber ==params.policy
  );

  const tableData = [
    {
      data: [
        {
          id: 1,
          column1: translate("Owner", "المالك"),
          column2: info?.PolicyDetails?.Name || "",
        },
        {
          id: 2,
          column1: translate("Plan", "الخطة"),
          column2: info?.PolicyDetails?.ProductName || "",
        },
      ],
    },
  ];

  const convertedCoversData = info?.Covers?.map((cover, index) => ({
    id: index + 3, // Start id from 3 to avoid duplicates
    column1: cover?.coverName,
    column2: translate("Selected", "محدد"),
    icon: selected,
    key: cover?.coverCode,
  }));

  tableData[0].data = [...tableData[0].data, ...convertedCoversData || []];


  const {AppleWalletApiTrigger , isLoading ,isAppleDevice } = useAppleWalletApi(params?.policy)
  return (
    <>
      {(isLoading) && <Loader className="w-10 h-10 fixed left-[50%] top-[50%]" />}
      {screenResolution.mobile && (
        <div className="max767:p-5 max767:pb-[10px]">
          {filteredPolicy?.map((each, index) => (
            <PolicyCard
              key={index}
              policy_name={each.policy_name}
              policy_label={each.policy_label}
              policy_number={each.policy_number}
              from_date={each.from_date}
              to_date={each.to_date}
              policy_bg_image={each.policy_bg_image}
              expire_days={each.expire_days}
              is_text_dark={each.is_text_dark}
              mapping_key={each.id}
              data={each}
            />
          ))}
       { isAppleDevice &&  <Button
            onSubmit={()=>AppleWalletApiTrigger()}
            label={translate("Add to Apple Wallet","أضف إلى محفظة Apple")}
            pre_icon={appleWallet}
            className="AppleWallet flex justify-center items-center gap-2 self-stretch bg-black px-2 py-4 rounded-[10px] text-white text-base not-italic font-[510] leading-[120%] max767:w-full max767:mt-2 hover:bg-black"
          />}
        </div>
      )}
      <div className="flex flex-wrap gap-4 ipad:gap-2 max767:grid max767:grid-cols-2 max767:p-5 policyoverviewtab ">
        {cards.map((card, index) => (
          <PerformanceCard
            key={index}
            info={info}
            cardData={card}
            className="max-w-lg rounded overflow-hidden bg-[#FBF7F0] rounded-2xl p-6 flex-col flex h-[163px] justify-between items-start flex-[1_0_0]  max767:h-[150px] ipad:p-3 ipad:h-[140px] poverviewtab"
          />
        ))}
      </div>
      <div className="flex gap-4 mt-4 max767:flex-col ipad:flex-col max767:mt-0">
        <div className="table-col w-[59.5%] max767:w-full ipad:w-full">
          <LifeDynamicTable data={tableData} />
        </div>
        <div className="flex flex-col items-end w-2/5 ovrveiw-tab max767:w-full max767:p-4 ipad:grid ipad:grid-cols-2 ipad:w-full ipad:items-start">
          {chngBeneficiaryCardData && (
            <div
              className={`${
                chngBeneficiaryCardData.isHide ? "hidden" : ""
              } max-w-lg overflow-hidden p-6 flex-col items-center bg-[#FBF7F0] rounded-2xl w-full mb-2 ipad:flex ipad:w-[96%] ipad:order-2 ipad:place-items-start`}
            >
              <div className="flex flex-col">
                <div className="text-[#252C32] text-xs not-italic font-semibold leading-[normal]">
                  {translate(
                    chngBeneficiaryCardData?.label_en,
                    chngBeneficiaryCardData?.label_ar
                  )}
                </div>
                <div className="mt-2.5 mb-4 mx-0 text-[#1A2024] text-2xl not-italic font-medium leading-[120%]">
                  <span>
                    {translate(
                      chngBeneficiaryCardData?.value_en,
                      chngBeneficiaryCardData?.value_ar
                    )}{" "}
                  </span>
                </div>
              </div>
              <Button
                onSubmit={() => {
                  navigate(`/life-insurance/manage-beneficiaries/${id}`);
                }}
                className="flex items-center gap-2 border bg-white px-4 py-2 rounded-md border-solid border-[#DDE2E4] max767:font-semibold"
                pre_icon={Beneficiaryicon}
                label={translate(
                  chngBeneficiaryCardData?.btn_label_en,
                  chngBeneficiaryCardData?.btn_label_ar
                )}
              />
            </div>
          )}
          {!screenResolution.mobile && downloadCardData && (
            <div className="max-w-lg flex-col p-6 bg-[#FBF7F0] rounded-2xl flex gap-2 items-start w-full mt-3 ipad:w-[96%] ipad:-order-1 ipad:mt-0 ipad:place-items-start">
              <div className="flex flex-col w-full">
                <div className=" flex text-[#1A2024] text-sm not-italic font-medium justify-between">
                  {translate(
                    "Your insurance policy documents:",
                    "مستندات وثيقة التأمين الخاصة بك:"
                  )}
                  <Button
                    className="bg-transparent text-[#00384D] text-sm not-italic font-semibold leading-4 tracking-[-0.14px] underline hover:bg-transparent viewallbtn"
                    label={translate("View All", "عرض الكل")}
                    onSubmit={handleDownloadMenuClicked}
                  />
                </div>
              </div>
              {downloadCardData && (
                <DownloadBtn
                  filesData={downloadCardData.filter(
                    (doc) =>
                      doc.ID.toLowerCase().includes(
                        "illustration".toLowerCase()
                      ) ||
                      doc.ID.toLowerCase().includes(
                        "schedule".toLowerCase()
                      )
                  )}
                  className={"flex bg-transparent"}
                />
              )}
            </div>
          )}
        </div>
        <div className="downloaddocumentsmodal">
          <BackdropModal
            show={showDownloadPopup}
            onClose={handleDownloadMenuClicked}
            title={translate("Download Documents","تحميل المستندات")}
          >
            <div className="flex-row">
              <DownloadBtn filesData={downloadCardData} className={"mb-2"} />
            </div>
          </BackdropModal>
        </div>
      </div>
    </>
  );
};

export default OverviewTab;