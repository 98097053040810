import fundPerfomancePage1 from "../../../assets/images/fundPerfomancePage1.svg";
import fundPerfomancePage2 from "../../../assets/images/fundPerfomancePage2.svg";
import policydate from "../../../assets/images/policydate.svg";
import termremaining from "../../../assets/images/Termremaining.svg";
import fundPerfomancePage3 from "../../../assets/images/fundPerfomancePage3.svg";
import fundPerfomancePage4 from "../../../assets/images/fundPerfomancePage4.svg";
import fundPerfomancePage5 from "../../../assets/images/fundPerfomancePage5.svg";
import selected from "../../../assets/images/done_outline.svg";
import notselected from "../../../assets/images/do_not_disturb_alt.svg"
import creditcardicon from "../../../assets/images/credit_card.svg";
import Premiumcycle from  "../../../assets/images/fundPerfomancePage2.svg";
export const policyInfo = {
  status: "Active",
  ar_status: "نشيط",
  planName: "Retirement Saving Plan",
  policyNumber: "P/0001/4/20/B003/0004341-05",
  amount: "5,677.93",
  date: "01 November 2023",
};

export const tabs = [
  { label_en: "Policy Overview", label_ar: "نظرة عامة على السياسة", type: "PolicyOverview" },
  { label_en: "Fund Performance", label_ar: "أداء الصندوق", type: "FundPerformance" },
  { label_en: "Payments History", label_ar: "تاريخ الدفعات", type: "PaymentsHistory" },
  { label_en: "Fund Management", label_ar: "إدارة الصندوق", type: "FundManagement" },
  { label_en: "Manage Policy", label_ar: "إدارة سياستي", type: "ManagePolicy" },
];


export const overviewTabData = {
  cards: [
    {
      type: "policyTerm",
      icon: fundPerfomancePage1,
      label_en: "Policy Term",
      value: "20 years",
      label_ar: "مصطلح السياسة",
      value_ar: "20 سنة",
    },
    {
      type: "startDate",
      icon: fundPerfomancePage3,
      label_en: "Policy start date",
      value: "1 Dec 2020",
      label_ar: "تاريخ بدء السياسة",
      value_ar: "1 ديسمبر 2020",
    },
    {
      type: "endDate",
      icon: policydate,
      label_en: "Policy end date",
      value: "30 Nov 2040",
      label_ar: "تاريخ انتهاء السياسة",
      value_ar: "30 نوفمبر 2040",
    },
    {
      type: "termRemaining",
      icon: termremaining,
      label_en: "Term remaining",
      value: "16 years 0 months",
      label_ar: "المدة المتبقية",
      value_ar: "16 سنة 0 شهر",
    },
    {
      type: "paymentCycle",
      icon: termremaining,
      label_en: "Contribution cycle",
      value: "Monthly",
      label_ar: "دورة مميزة",
      value_ar: "شهريا",
    },
  ],
  chngBeneficiaryCard: {
    isHide: true,
    label_en: "Roles attached to the beneficiary",
    label_ar: "الأدوار المرتبطة بالسياسة",
    value_en: "As per Islamic Sharia law",
    value_ar: "وفقاً لقانون الشريعة الإسلامية",
    btn_label_en: "Change Beneficiary",
    btn_label_ar: "تغيير المستفيد",
  },

  downloadCard: {
    label_en: "Your insurance policy documents",
    label_ar: "مستندات وثيقة التأمين الخاصة بك:",
    download_btn: true,
    filesData: [
      {
        fileName: "randomFile1.txt",
        fileData: {},
      },
      {
        fileName: "randomFile2.txt",
        fileData: {},
      },
    ],
  },
  table: [
    {
      data: [
        { id: 1, column1: "Owner", column2: "Name" },
        { id: 2, column1: "Tab", column2: "Retirement savings plan" },
        { id: 3, column1: "Life Cover", column2: "selected", icon: selected },
        {
          id: 4,
          column1: "Lie cover",
          column2: "Not selected",
          icon: notselected,
        },
        { id: 5, column1: "Life cover", column2: "selected", icon: selected },
        { id: 6, column1: "Life cover", column2: "selected", icon: selected },
      ],
    },
  ],
};
export const fundPerformanceData = {
  cards: [
    {
      type: "fundperformanceCurrentValue",
      icon: fundPerfomancePage1,
      label_en: "Current value",
      label_ar: "القيمة الحالية",
      countryCode: "BHD",
      value: "5,677.93",
    },
    {
      type: "fundperformanceCurrentEncashment",
      icon: fundPerfomancePage2,
      label_en: "Current encashment value",
      label_ar: "القيمة النقدية الحالية",
      countryCode: "BHD",
      value: "4,478.01",
    },
    {
      type: "fundperformanceTotalPremiumInvested",
      icon: fundPerfomancePage3,
      label_en: "Total contribution invested",
      label_ar: "إجمالي الأقساط المستثمرة",
      countryCode: "BHD",
      value: "4,189.00",
    },
    {
      type: "fundperformanceTotalPremiumPaid",
      icon: fundPerfomancePage4,
      label_en: "Total contribution paid",
      label_ar: "مجموع الأقساط المدفوعة",
      countryCode: "BHD",
      value: "4,489.01",
    },
    {
      isHide: true,
      type: "fundperformanceTotalWithdrawals",
      icon: fundPerfomancePage5,
      label_en: "Total withdrawals",
      label_ar: "إجمالي السحوبات",
      countryCode: "BHD",
      value: "0.00",
    },
  ],
  charts: {
    line: {
      labels: [
        "Nov 2023", //start DATW
        "Nov 2024", //today date

      ],
      datasets: [
        {
          label: "Policy Value",
          data: [], // Replace with actual data
          borderColor: "#F7931D",
          backgroundColor: "#F7931D",
        },
        {
          label: "Premium Invested",
          data: [3000, 6000], // Replace with actual data  //totalPremiumInvested , currentValue
          borderColor: "#0C4A0D",
          backgroundColor: "#0C4A0D",
        },
      ],
    },
    pie: {
      labels: ["Islamic Funds 1 (80%)", "Islamic Funds 2 (20%)"],
      data: [80, 20],
    },
  },
};

export const paymentsHistoryData = {
  cards: [
    {
      type: "paymentHistoryLastPayment",
      icon: fundPerfomancePage1,
      label_en: "Last Payment",
      label_ar: "آخر دفعة",
      countryCode: "BHD",
      value: "150.29",
    },
    {
      type: "paymentHistoryContributionCycle",
      icon: fundPerfomancePage3,
      label_en: "Contribution cycle",
      label_ar: "دورة المساهمة",

      value: "Quarterly",
    },
    {
      isHide: true,
      icon: Premiumcycle,
      label_en: "Payment Method",
      value: "Credit Card",
      btn: {
        btn_icon: creditcardicon,
        btn_label_en: "Change Payment Method",
        btn_label_ar: "تغيير طريقة الدفع",
      },
    },
  ],
};





export const BREADCRUMP_ITEMS = [
  {
    label: "My Policy",
    link: "/my-policy",
    clickable: true,
    name: 'my_policy'
  }
];


export const typeProductIds =  {
  savings_protection : ["ROEDU","ROFUTURE","SVPRO"],
  protection_plans : ["REAYA","LTAM"],
  buy_online_plans : ["LTA-HDFC",]
}


export const SAVING_PROTECTION = "savings_protection";
export const  PROTECTION_PLANS = "protection_plans";
export const  BUY_ONLINE_PLANS = "buy_online_plans";
export const  OTHER_PLANS = "OTHER_PLANS";
export const findPlanType=(productId)=>{
    if(typeProductIds[SAVING_PROTECTION].includes(productId)){
        return SAVING_PROTECTION;
    }else if(typeProductIds[PROTECTION_PLANS].includes(productId)){
        return PROTECTION_PLANS;
    } else if(typeProductIds[BUY_ONLINE_PLANS].includes(productId)){
        return BUY_ONLINE_PLANS;
    } else {
        return OTHER_PLANS;
    }
}

