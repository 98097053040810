import React, { useState } from "react";
import TitleDescription from "../../../../components/newMotorPolicy/titleDescription/titleDescription";
import Button from "../../../../components/common/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import useScreenResolution from "../../../../hooks/useScreenResolution";
import Back_Btndark from "../../../../assets/images/greenbackarrow.svg";
import backarrow from "../../../../assets/images/back-arrow-left.svg";
import FileUpload from "../../../../components/common/fileUploadInput/fileUploadInput";
import Input from "../../../../components/common/Input/Input";
import useValidation from "../../../../hooks/useValidation";
import { useTranslation } from "react-i18next";

const ConfirmPaymentDetails = () => {
  const navigate = useNavigate();
  const { mobile } = useScreenResolution();
  const { t } = useTranslation();
  const { errors, validateField } = useValidation();
  const [fileUploadError, setFileUploadError] = useState(null);
  const { claim_id } = useParams();

  const [formData, setFormData] = useState({
    ibanNumber: "",
    iban_certificate: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    validateField(value, name, "required");
  };

  const onFileUploaded = (value, name) => {
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (value) {
      setFileUploadError(null);
    } else {
      setFileUploadError(t("validation.required"));
    }
  };

  const handleSubmit = () => {
    const isValidForm =
      validateField(formData.ibanNumber, "ibanNumber", "required") &&
      formData?.iban_certificate;

    if (isValidForm) {
      navigate("/motor-insurance/success");
    } else {
      if (!formData?.iban_certificate) {
        setFileUploadError(t("validation.required"));
      }
    }
  };

  return (
    <div className="mainCarDetailsModal pt-[5%] pb-[5%] max767:w-full max767:overflow-y-scroll max767:pb-[30%] max767:h-screen ipad:pt-[10%] ipad:h-[93vh] ipad:overflow-y-scroll">
      <div className="mx-auto bg-white max767:p-5 w-[500px] max-w-[100%] ipad:mt-[8%]">
        {!mobile ? (
          <button
            className="backButton bg-navy-blue py-2 rounded mt-20 flex absolute left-[26px] top-0 text-black text-sm not-italic font-semibold leading-6 tracking-[-0.084px] max767:bg-[#F6F8F9] max767:w-10 max767:h-10 max767:text-center max767:justify-center max767:rounded-3xl max767:mt-12 max767:ml-[-11px] rtl:right-[26px]"
            onClick={() => navigate("/motor-insurance/claim-details/123")}
          >
            <img src={backarrow} alt="Back arrow" className="1 rtl:rotate-180" />
            <span className="back-text inline max767:hidden rtl:mr-1">
              {t("motorInsurance.trackClaimFlow.garageSelection.backButton")}
            </span>
          </button>
        ) : (
          <img
            src={Back_Btndark}
            alt="Back"
            className="max767:visible w-10 h-10"
            onClick={() =>
              navigate(`/motor-insurance/claim-details/${claim_id}`)
            }
          />
        )}
        <div className="">
          <TitleDescription
            title={t(
              "motorInsurance.trackClaimFlow.confirmPaymentDetails.title"
            )}
            description={t(
              "motorInsurance.trackClaimFlow.confirmPaymentDetails.description"
            )}
          />
          <div className="details">
            <Input
              label={t(
                "motorInsurance.trackClaimFlow.confirmPaymentDetails.ibanLabel"
              )}
              type="text"
              handleChange={handleChange}
              name="ibanNumber"
              value={formData?.ibanNumber}
              error={errors?.ibanNumber}
              data-testid="ibanNumber"
            />
            <div className="upload_file">
              <p className="mb-2 text-[#48535B] text-base not-italic font-normal leading-[26px] tracking-[-0.16px]">
                {t(
                  "motorInsurance.trackClaimFlow.confirmPaymentDetails.ibanUpload"
                )}
              </p>
              <FileUpload
                name="iban_certificate"
                toggleFileUpload={onFileUploaded}
              />
              {fileUploadError && (
                <p className="text-red-500 text-sm">{fileUploadError}</p>
              )}
            </div>
            <Button
              label={t(
                "motorInsurance.trackClaimFlow.confirmPaymentDetails.submitButton"
              )}
              onSubmit={handleSubmit}
              className="button bg-[#55C6EF] hover:bg-[#55C6EF] flex  disabled:bg-gray-400 disabled:cursor-not-allowed  items-center gap-2.5 self-stretch p-4 rounded-[10px] w-full mt-4 text-lg justify-center"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmPaymentDetails;
