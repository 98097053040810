import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ClaimHeader from "./claimComponents/claimHeader";
import { Link, useNavigate, useParams } from "react-router-dom";
import righticon from "../../../assets/images/Chevronright.svg";
import { useTranslation } from "react-i18next";
import ClaimDetailCard from "./claimComponents/claimDetailCard";
import ClaimProgressBar from "./claimComponents/claimProgressBar";
import { ACTIONS, mockData } from "./data";
import useScreenResolution from "../../../hooks/useScreenResolution";
import ClaimDetailModal from "./claimComponents/claimDetailModal";
import BackdropModal from "../../../components/common/modal/backdropModal";
import SignInForm from "../../signInRegisterPages/SignInPage/signInForm";
import Button from "../../../components/common/Button/Button";
import Loader from "../../../components/common/Loader/Loader";
import { useLocation } from "react-router-dom";
import {
  getClaimDetails,
  selectCrmClaimsLoading,
  selectCrmClaimsError,
  clearClaimDetails,
  selectClaimDetails,
} from "../../../redux/features/crm/crmClaimSlice";
import CryptoJS from "crypto-js";
import Back_Btndark from "../../../assets/images/greenbackarrow.svg";

const ClaimProgressPage = () => {
  const { mobile } = useScreenResolution();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const { claim_id } = useParams();

  const secretKey = "i-hate-meej";

  // Revert to Base64 format using split and join
  const base64EncryptedValue = claim_id
    .split("-")
    .join("+") // Replace '-' with '+'
    .split("_")
    .join("/"); // Replace '_' with '/'

  // Decrypt the value
  const bytes = CryptoJS.AES.decrypt(base64EncryptedValue, secretKey);
  const originalValue = bytes.toString(CryptoJS.enc.Utf8);
  const claimDetails = useSelector(selectClaimDetails);
  const IsLoading = useSelector(selectCrmClaimsLoading);
  console.log(originalValue);

  useEffect(() => {
    if (originalValue) {
      let isSubscribed = true;
      let payload = {
        CaseNumber: originalValue,
        Channel: "online.solidarity",
      };

      console.log(payload);
      if (isSubscribed) {
        dispatch(getClaimDetails(payload));
      }

      return () => {
        isSubscribed = false; // Cleanup
      };
    }
  }, [originalValue, dispatch]);

  let claimStatus = "";
  let activeStep = {};
  if (claimDetails != null) {
    switch (claimDetails.Claim.ClaimStatus) {
      case "Claim Intimation":
        claimStatus = "Intimation";
        break;
      case "Intimation Acknowledgement":
        claimStatus = "Intimation";
        break;
      case "Incomplete Intimation":
        claimStatus = "Intimation";
        break;
      case "Rejected Claim":
        claimStatus = "Intimation";
        break;
      case "Claim Registration":
        claimStatus = "Registration";
        break;
      case "Survey Completion":
        claimStatus = "Survey";
        break;
      case "Under Procurement":
        claimStatus = "Settlement";
        break;
      case "Under Repair":
        claimStatus = "Settlement";
        break;
      case "Total Loss":
        claimStatus = "Settlement";
        break;
      case "Cash Loss":
        claimStatus = "Settlement";
        break;
      case "Pending For Loss Adjustor":
        claimStatus = "Settlement";
        break;
      case "Pending for Estimate":
        claimStatus = "Survey";
        break;
      case "Pending for Deductible":
        claimStatus = "Registration";
        break;
      case "Pending for Depreciation":
        claimStatus = "Survey";
        break;
      case "Vehicle Collection":
        claimStatus = "Settlement";
        break;
      case "Close Claim":
        claimStatus = "Closed";
        break;
      default:
        claimStatus = "Unknown status.";
    }

    switch (claimStatus) {
      case "Intimation":
        activeStep = {
          key: "STEP_NAME_INTIMATION",
          title_en: "Intimation",
          title_ar: "إخطار",
        };
        break;
      case "Survey":
        activeStep = {
          key: "STEP_NAME_SURVEY",
          title_en: "Survey",
          title_ar: "استطلاع",
        };
        break;
      case "Settlement":
        activeStep = {
          key: "STEP_NAME_SETTLEMENT",
          title_en: "Settlement",
          title_ar: "تسوية",
        };
        break;
      case "Closed":
        activeStep = {
          key: "STEP_NAME_CLOSE",
          title_en: "Close",
          title_ar: "إغلاق",
        };
        break;
      default:
        activeStep = activeStep = {
          key: "STEP_NAME_REGISTRATION",
          title_en: "Registration",
          title_ar: "تسجيل",
        };
    }
  }
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseSignInModal = () => {
    setShowSignInModal(false);
  };
  const handleActions = (actionType) => {
    switch (actionType) {
      case ACTIONS.UPLOAD:
        navigate(`/motor-insurance/upload-documents/${originalValue}`);
        break;
      case ACTIONS.PROCEED_TO_PAYMENT:
        break;
      case ACTIONS.VIEW_DETAILS:
        break;
      case ACTIONS.APPROVE:
        setShowSignInModal(true);
        break;
      case ACTIONS.REJECT:
        navigate(`/motor-insurance/reason-for-rejection/${originalValue}`);
        break;

      default:
        console.log("Unknown action type");
    }
  };

  const navigateToChangeGarage = () => {
    navigate(`/motor-insurance/change-garage/${originalValue}`);
  };

  const handlePaymentFlow = () => {
    navigate(`/motor-insurance/payment-details/${originalValue}`);
  };

  const { i18n, t } = useTranslation();
  return (
    <div className="ipad:p-6 max767:p-6 max767:mt-[2%] p-6">
      {mobile && (
        <img
          src={Back_Btndark}
          alt="Back"
          className="max767:visible w-10 h-10 rtl:rotate-180"
          onClick={() => navigate("/file-a-claim")}
        />
      )}
      {!mobile && (
        <div className="ipad:p-5 max767:p-5 max767:mt-[15%] ipad:pl-0 max767:pl-0">
          <div className=" flex align-middle justify-between ipad:mb-4 max767:hidden">
            <div className="breadcrump flex align-middle gap-10">
              <ul className="inline-flex gap-2">
                <li className="inline-flex">
                  <Link
                    to="/file-a-claim"
                    className="text-xs not-italic font-normal leading-4 text-[#6E7C87]"
                  >
                    {t("motorInsurance.trackClaimFlow.breadcrumb.file_a_claim")}
                  </Link>{" "}
                  <span className="ml-2 rtl:ml-0 rtl:mr-2">
                    <img
                      src={righticon}
                      alt="right icon"
                      className="rtl:rotate-180"
                    />
                  </span>
                </li>
                <li className="bread-active text-xs not-italic font-normal leading-4 inline-flex text-black">
                  {t("motorInsurance.trackClaimFlow.breadcrumb.claim_details")}
                </li>
              </ul>
            </div>
            {/* <p className="last-login text-xs not-italic font-normal leading-4 text-gray-700">
              {t("commonLabels.last_login_label")}: 03 November 2024 at 05:20
            </p> */}
          </div>
        </div>
      )}

      {IsLoading && <Loader />}
      {claimDetails != null ? (
        <div>
          <ClaimHeader
            setShowModal={setShowModal}
            navigateToChangeGarage={navigateToChangeGarage}
            claimCaseNumber={originalValue}
            claimDate={claimDetails.Claim.IntimationDateStr}
          />
          <div className="flex gap-3">
            <ClaimProgressBar
              steps={mockData.claimProgress}
              data={mockData}
              className="justify-start track-claim-stepper"
              handleActions={handleActions}
              activeStep={activeStep}
            />
            {/* {desktop && <ClaimDetailCard />} */}
          </div>

          <BackdropModal
            show={showSignInModal}
            onClose={handleCloseSignInModal}
          >
            <div className="w-[500px] max-w-[100%] ipad:w-full">
              <h1 className="text-[#1A2024] text-2xl not-italic font-semibold leading-8 tracking-[-0.24px] mt-[-45px]">
                {" "}
                {t(
                  "motorInsurance.trackClaimFlow.confirmIdentityModal.confirm_identity"
                )}
              </h1>
              <p className="text-[#1A2024] text-base not-italic font-normal leading-[26px] tracking-[-0.16px] mt-2 mb-3">
                {t(
                  "motorInsurance.trackClaimFlow.confirmIdentityModal.identity_verification"
                )}
              </p>
              <div className="signInForm bg-[#F6F8F9] p-6 rounded-2xl mb-4">
                <p className="text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px] mb-3">
                  {t(
                    "motorInsurance.trackClaimFlow.confirmIdentityModal.existing_account"
                  )}
                </p>
                <SignInForm cbSuccess={handlePaymentFlow} className="w-full" />
              </div>
              <div className="flex-col items-start gap-4 self-stretch bg-[#F6F8F9] p-6 rounded-2xl mb-3">
                <Button
                  className="w-full bg-transparent text-[#00384D] text-center text-base not-italic font-semibold leading-[26px] tracking-[-0.16px] underline"
                  label={t(
                    "motorInsurance.trackClaimFlow.confirmIdentityModal.register_info"
                  )}
                />
              </div>
              <div className="needHelp">
                <h3 className="text-[#1A2024] text-base not-italic font-semibold leading-6 tracking-[-0.16px]">
                  {t(
                    "motorInsurance.trackClaimFlow.confirmIdentityModal.need_help"
                  )}
                </h3>
                <p className="text-sm not-italic font-normal mb-2">
                  {t(
                    "motorInsurance.trackClaimFlow.confirmIdentityModal.customer_support"
                  )}
                </p>
                <div className="flex help_btns gap-2">
                  <Button
                    label={t(
                      "motorInsurance.trackClaimFlow.confirmIdentityModal.call_us"
                    )}
                    className=" flex flex-col items-center gap-2.5 border bg-white px-3 py-1 rounded-[10px] border-solid border-[#DDE2E4] text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
                  />
                  <Button
                    label={t(
                      "motorInsurance.trackClaimFlow.confirmIdentityModal.faqs"
                    )}
                    className="flex flex-col items-center gap-2.5 border bg-white px-3 py-1 rounded-[10px] border-solid border-[#DDE2E4] text-[#1A2024] text-center text-sm not-italic font-semibold leading-6 tracking-[-0.084px]"
                  />
                </div>
              </div>
            </div>
          </BackdropModal>
        </div>
      ) : (
        !IsLoading && (
          <p>
            {t(
              "Could not find claim details.",
              "لم يتم العثور على معلومات المطالبة"
            )}
          </p>
        )
      )}
    </div>
  );
};

export default ClaimProgressPage;
