import React, { useState } from "react";
import TurnOnNotificationScreen from "./turnOnNotifications";
import FaceIDScreen from "./faceIDScreen";
import RegisterSuccessPage from "../signInRegisterPages/RegisterSuccessPage/RegisterSuccessPage";

const ActionScreens = () => {
  const [currentScreen, setCurrentScreen] = useState("notification");

  return (
    <>
      {currentScreen =="notification" && <TurnOnNotificationScreen setCurrentScreen={setCurrentScreen} />}
      {currentScreen =="faceID" && <FaceIDScreen setCurrentScreen={setCurrentScreen} />}
      {currentScreen =="success" && <RegisterSuccessPage />}
    </>
  );
};

export default ActionScreens;
