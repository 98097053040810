import React, { useState, useEffect } from "react";
import TitleDescription from "../../../components/newMotorPolicy/titleDescription/titleDescription";
import FileClaimMotorLayout from "./fileClaimMotorLayout";
import useFileClaimFlow from "../../../hooks/useFileClaimFlow";
import Input from "../../../components/common/Input/Input";
import useValidation from "../../../hooks/useValidation";
import SelectInput from "../../../components/common/selectInput/selectInput";
import { useTranslation } from "react-i18next";
import useMotorForm from "../../../hooks/useMotorFormData";
import { FILE_CLAIM_FLOW } from "./data";
import {
  BODILY_INJURY,
  PROPERTY_DAMAGE,
  VEHICLE_DAMAGE,
} from "./NonSolidarityUser/data";
import TelInput from "../../../components/common/telInput/telInput";
import { isWeekend, addDays, format } from "date-fns"; // Utility functions for date operations

export const AccidentInfo = "AccidentInfo";

function DatePickerInput({ value, setValue, label }) {
  const { t } = useTranslation();
  const [minDate, setMinDate] = useState("");

  // Set the minimum date to 3 days from today, adjusted if it falls on Friday or Saturday.
  useEffect(() => {
    const dateThreeDaysFromNow = addDays(new Date(), 3);
    let nextValidDate = dateThreeDaysFromNow;

    // Adjust to the next valid weekday if the date is Friday or Saturday.
    while (isWeekendCustom(nextValidDate)) {
      nextValidDate = addDays(nextValidDate, 1);
    }
    setMinDate(format(nextValidDate, "yyyy-MM-dd"));
  }, []);

  // Custom helper function to treat Friday (5) and Saturday (6) as weekend
  const isWeekendCustom = (date) => {
    const day = date.getDay();
    return day === 5 || day === 6;
  };
  const handleChange = (e) => {
    const selectedDate = new Date(e.target.value);
    if (selectedDate && !isWeekendCustom(selectedDate)) {
      setValue(selectedDate);
    } else {
      // Inform the user that the date is invalid.
      alert(t("motorInsurance.fileAClaimFlow.accidentInfoPage.date_error"));
      e.target.value = "";
    }
  };

  return (
    <div className="dateselect max767:w-full">
      <Input
        label={label}
        type="date"
        value={value ? format(value, "yyyy-MM-dd") : ""}
        handleChange={handleChange}
        min={minDate}
        className="rtl:text-right"
      />
    </div>
  );
}

function AccidentInfoPage({
  garages,
  selectedInsuredType,
  propertyDamageInfo,
}) {
  const [formData, setFormData] = useState({});
  const { handleNextSubstep, flowType } = useFileClaimFlow();

  const { errors, validateFields } = useValidation();
  const { t } = useTranslation();
  const { saveMotorDataInRedux } = useMotorForm(AccidentInfo, setFormData);

  let validationConfig = [
    {
      name: "accidentDesc",
      validations: ["required"],
    },
    {
      name: "workshop",
      validations: ["select"],
    },
    {
      name: "surveyDate",
      validations: ["required"],
    },
  ];
  if (selectedInsuredType == PROPERTY_DAMAGE) {
    validationConfig = [
      {
        name: "accidentDesc",
        validations: ["required"],
      },
      {
        name: "mobile",
        validations: ["length"],
        exact_length: 8,
      },
      {
        name: "surveyDate",
        validations: ["required"],
      },
    ];
  }

  const validateForm = () => {
    let isValid = validateFields(validationConfig, formData);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const selectedGarage = garages?.find(
      (garage) => garage.GarageCode == value
    ); // Find the full garage object

    // If the selectedGarage exists, save both GarageGUID and GarageEnName
    if (selectedGarage) {
      const { GarageCode, GarageEnName } = selectedGarage;
      let update = {
        ...formData,
        [name]: GarageCode, // Save only the GarageGUID
        Garage: GarageEnName, // Save the GarageEnName separately
      };
      setFormData(update);
    } else {
      // Fallback for other fields if necessary
      let update = { ...formData, [name]: value };
      setFormData(update);
    }

    // Run validation if necessary
    if (errors?.[name]) {
      validateForm();
    }
  };

  const continueClickHanlder = () => {
    if (validateForm()) {
      saveMotorDataInRedux(formData);
      handleNextSubstep();
    }
  };

  console.log(errors, "some errors");

  return (
    <FileClaimMotorLayout btn_onClick={continueClickHanlder}>
      <div className="mx-auto bg-white max767:p-5 w-[500px] max-w-[100%]">
        <div className="max767:hidden">
          <TitleDescription
            title={t("motorInsurance.fileAClaimFlow.accidentInfoPage.title")}
            description={t(
              "motorInsurance.fileAClaimFlow.accidentInfoPage.description"
            )}
          />
        </div>
        <div className="max767:block hidden max767:mb-6 max767:mt-20">
          <TitleDescription
            title={t(
              "motorInsurance.fileAClaimFlow.accidentInfoPage.mobile_title"
            )}
            description={t(
              "motorInsurance.fileAClaimFlow.accidentInfoPage.mobile_description"
            )}
          />
        </div>
        <div>
          <Input
            isTextarea
            label={t(
              "motorInsurance.fileAClaimFlow.accidentInfoPage.desc_accident_label"
            )}
            placeHolder={t(
              "motorInsurance.fileAClaimFlow.accidentInfoPage.desc_accident_placeholder"
            )}
            type="text"
            handleChange={handleChange}
            name="accidentDesc"
            value={formData.accidentDesc}
            error={errors.accidentDesc}
            data-testid="accidentDesc"
          />
          {(flowType == FILE_CLAIM_FLOW ||
            selectedInsuredType == VEHICLE_DAMAGE) && (
            <>
              <SelectInput
                className="workshop"
                name="workshop"
                formData={formData}
                value={formData?.workshop}
                label={t(
                  "motorInsurance.fileAClaimFlow.accidentInfoPage.preferred_garage_label"
                )}
                default_placeholder={t(
                  "motorInsurance.fileAClaimFlow.accidentInfoPage.preferred_garage_placeholder"
                )}
                options_data={garages?.map((garage) => ({
                  value: garage.GarageCode, // Use GarageGUID as the value for each option
                  label: garage.GarageEnName, // Display name
                  data: garage,
                }))}
                error={errors?.workshop}
                handleChange={handleChange}
              />
              <span className="flex note text-[#005574] text-xs not-italic font-normal leading-5 tracking-[-0.12px] mb-4">
                {t(
                  "motorInsurance.fileAClaimFlow.accidentInfoPage.preferred_garage_hint_msg"
                )}
              </span>
              <DatePickerInput
                label={t(
                  "motorInsurance.fileAClaimFlow.accidentInfoPage.preferred_date_survey_label"
                )}
                value={formData.surveyDate}
                setValue={(date) =>
                  setFormData({ ...formData, surveyDate: date.toISOString() })
                }
              />
            </>
          )}
          {selectedInsuredType == PROPERTY_DAMAGE && (
            <>
              <TelInput
                name="mobile"
                value={formData.mobile}
                error={errors.mobile}
                onChange={handleChange}
                label={t(
                  "motorInsurance.fileAClaimFlow.accidentInfoPage.propertyDamageAccidentInfo.contact_person_label"
                )}
                className="mobilenumber px-4 py-3 max767:w-[50%] w-[97%] border-r-0 border-0 rounded-[9px] h-[46px] focus:outline-none"
                data-testid="mobile"
              />
              <DatePickerInput
                label={t(
                  "motorInsurance.fileAClaimFlow.accidentInfoPage.propertyDamageAccidentInfo.preferred_date_label"
                )}
                value={formData.surveyDate}
                setValue={(date) =>
                  setFormData({ ...formData, surveyDate: date })
                }
              />
            </>
          )}
        </div>
      </div>
    </FileClaimMotorLayout>
  );
}

export default AccidentInfoPage;
