import React from "react";
import radioActive from "../../assets/images/radio_active.svg";
import radioUnactive from "../../assets/images/radio_unactive.svg";

const MotorPolicyOptionsCard = ({ label="",title, description, isSelected, onSelect }) => {
  return (
    <div
      onClick={onSelect}
      className={`transition-all gap-4 p-6 rounded-[10px] border-solid border mb-4 cursor-pointer w-full optioncard  box-border ${
        isSelected ? "bg-[#E3F8FF] border-[#00719A] border-2 box-border " : "bg-white border-transparent border-2 box-border outline-1 outline-[#DDE2E4]"
      }`}
    >
      <div className="items-center gap-4 grid box-border">
        <input
          type="radio"
          checked={isSelected}
          onChange={onSelect}
          className="mr-3 mb-4 hidden"
        />
        <img
            src={isSelected ? radioActive : radioUnactive}
            alt={isSelected ? "Selected" : "Not Selected"}
            className="w-6 h-6"
          />
        <div className="flex-col">
          <h2 className="font-medium text-lg label">{label}</h2>
          <h2 className="font-medium text-lg title">{title}</h2>
          <p className="text-[#1A2024] text-base not-italic font-normal leading-6 tracking-[-0.16px] description">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default MotorPolicyOptionsCard;
