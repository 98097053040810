import React from "react";
import Notication_BG from "../../assets/images/Header 2.svg";
import LOGO from "../../assets/images/sm-logo.svg";
import MainCard from "./mainCard";
import useLanguageTranslate from "../../hooks/useLanguageTranslate";

const TurnOnNotificationScreen = ({ setCurrentScreen }) => {
  const translate = useLanguageTranslate();
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <div className="w-full mb-8">
        <img
          src={Notication_BG}
          alt="Notification"
          className="rounded-lg w-full  object-cover mb-4"
        />
        <div className="flex-col items-center gap-2.5 self-stretch bg-white shadow-[0px_10px_14px_0px_rgba(0,0,0,0.07)] w-[95%] absolute mx-auto my-0 p-2.5 rounded-2xl top-[16%] inset-x-0">
          <div className="flex w-full justify-between">
            <div className="flex gap-3">
              <div className="">
                <img src={LOGO} alt="Solidarity Logo" className=" w-10 h-10" />
              </div>
              <div className="">
                <p className="text-black text-[15px] not-italic font-semibold leading-5 tracking-[-0.5px] flex">
                  {translate("Solidarity Bahrain", "سوليدرتي البحرين ")}
                </p>
                <p className="text-[13px] not-italic font-normal leading-[18px]">
                  {translate(
                    "You've received 3 insurance updates!",
                    "لقد تلقيت 3 تحديثات تأمينية!"
                  )}
                </p>
              </div>
            </div>
            <div>
              <p className="text-[13px] not-italic font-normal leading-[18px] flex">
                {translate("now", "الآن")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <MainCard
        title={translate("Welcome to Solidarity", "مرحبًا بك في سوليدرتي")}
        subtitle={translate("Turn on Notifications", "تشغيل الإشعارات")}
        primary_label={translate(
          "Turn on notifications",
          "تشغيل الإشعارات"
        )}
        secondary_label={translate("Maybe later", "ربما لاحقًا")}
        handlePrimaryClick={() => setCurrentScreen("faceID")}
        handleSecondaryClick={() => setCurrentScreen("faceID")}
      />
    </div>
  );
};

export default TurnOnNotificationScreen;
