// ZOOM KYC DATA
export const zoomApiKeys = {
   src : "https://us01ccistatic.zoom.us/us01cci/web-sdk/video-client.js",
   apiKey : "94NjPSW_RaC-qGF9bmJyQQ",
   entryId : "cLQgcJirSQ2vCtoZFhiyUQ"
}



export const strapiApiEndPoints = {
  signInRegister:
    "/api/singin-register?populate=sign_in_header,download_app_title,apple_store_icon,apple_store_icon.img,apple_store_icon.arabic_img,google_play_icon,google_play_icon.img,google_play_icon.arabic_img,create_account_title,accept_terms_label,terms_conditions_link,privacy_policy_link,verify_otp_label,create_pass_label,tick_mark_img,tick_mark_img.img,tick_mark_img.arabic_img,register_success_content",
  commonApiData: `/api/common-data?populate=settings_t_and_conditions,settings_t_and_conditions.icon,settings_t_and_conditions.icon.img,settings_t_and_conditions.icon.arabic_img,settings_t_and_conditions.link,settings_privacy_and_security,settings_privacy_and_security.icon,settings_privacy_and_security.icon.img,settings_privacy_and_security.icon.arabic_img,settings_privacy_and_security.link,settings_fees_and_charges,settings_fees_and_charges.icon,settings_fees_and_charges.icon.img,settings_fees_and_charges.icon.arabic_img,settings_fees_and_charges,settings_fees_and_charges.icon,settings_fees_and_charges.icon.img,settings_fees_and_charges.icon.arabic_img,settings_fees_and_charges.link`,
  offersData: `/api/offer?populate=banner,banner.img,banner.arabic_img,mobileBanner,mobileBanner.img,mobileBanner.arabic_img`,
  policiesBg: `/api/policy-card-bgs?populate=card_bg_image,card_bg_image.img,card_bg_image.arabic_img`,
  newPoliciesStaticData: `/api/new-policy?populate=*`,
  buyOnlinePlans: "/api/buy-online-plan?populate=plans.image,plans.benefits,headings_content",
  buyOnlineQuestionniare: "/api/buy-online-questionnaire?populate=*",
  lifeFunds : "/api/fund-lists",
  faqs : "/api/c-p-faq-categories?populate=title,c_p_faqs.question,c_p_faqs.answer",
  fundManagement : "/api/fund-management-doc?populate=*",
  essentialCare : "/api/essential-care?populate=*"
};

export const signUpApiEndpoints = {
  userExist: "/account/userexists",
  createAccount: "/account/mobilefirststep",
  verifySession: "/account/create-veriff-session",
  verifykyc: "/account/verify-kyc",
  confirmUsCitizenship : "/account/publishsignedcase"
}
export const apiEndpoints = {
  signIn: "/token",
  getPolicies: "/policy/GetPolicies",
  profile: "/profile",
  notifications: "/profile/notifications",
  apppleWalletGeneratePass : "/links/request",
  apppleWalletGenerateFileId : "/account/generatePolicyPass",
}

export const accountApiEndpoints = {
  // OTP
  sendToken: "/account/sendtoken",
  verifyToken: "/account/verifytoken",

  // eKYC

  // Veriff

  // updatePhone
  changeMobileApi: "/account/updatephone",
};

export const constantsApiEndpoints = {
  cars: "constants/cars",
  banks: "constants/banks"
}

export const motorApiEndpoints = {
  // MEEJ
  calculateSumInsured: "/Meej/CalculateSumInsured",
  calculatePremium: "/Meej/calculatepremium",
  coverPrices: "/Meej/CoversPrices",
  policyTransferRequest: "/Meej/policyTransferRequest",
  startPolicyTransfer: "/Meej/startpolicytransfer",

  // Motor
  getQuote: "/motor/quote",

  // GDT
  retrieveVehicleDetails: '/GDT/RetrieveVehicleDetails',
  retrievePlateTypes: '/GDT/RetrievePlateTypes',
  // Policy View
  policyView: '/policy/view',

  // Endorsements
  cancelPolicy: '/Meej/CancelActivePolicy',
  rejectCancelPolicy: '/Meej/RejectCancelPolicy',
  getQuoteWithRefund: '/Meej/GetQuoteWithRefund',
  ChangePlate: '/Endorsement/updateRegNumber',

  // view docs
  ViewDocs: '/policy/ViewIssuedQuoteDocs',

  // scan bank letter
  scanBankLetter: '/openAi/scanBankLetter',

  // remove bank
  removeBank: '/Endorsement/removeBank'

}

export const aiApiEndpoints = {
  // OpenAI
  scanOwnership: "/openAi/scanOwnership",

}

export const policyApiEndpoints = {
  // MEEJ
  pay: "/policy/pay",
  premium: "/policy/premium",
  policyView: '/policy/view',
  payTransferFees: "/policy/PayTransferFees",
  

  verify: "policy/verify",
  // view docs
  ViewDocs: '/policy/ViewIssuedQuoteDocs',

  // Promo code
  applyPromo: "/policy/applypromo"

}

export const filesApiEndpoints = {
  // Uploading files
  token: "/tokens/request",
  upload: "/files/upload",
  getLink: "/tokens/request",

}

export const expressShieldEndPoints = {
  createLead: "/lead/createlead",
  updateLead: "/lead/updatelead",
  expressSheildSave: "/Life/expresShield",
  checkParty: "/customer/checkparty",
  partyCreate: "/customer/new",
  payment: "/policy/pay"
}

export const essentialCareEndPoints = {
  essentialCareQuote : "/Life/EssentialHealth"
}

export const forgotPasswordApiEndpoints = {
  forgotpassword:"/account/forgotpassword",
  resetpassword: "account/resetpassword"
}

export const lifeApiEndPoints = {
  createRequests : "Life/CreateLifeRequest",
  lifePolicyDetails : "/Life/GetLifePolicyDetails",
  rollingPerformance : "/Life/rolling_performance",
  investmentEndPoints: {
    allfunds: "/Life/allfunds",
    tcsCatalog : "/Life/tcscatalog",
    singleFundDetails : "/Life/GetLifePolicyDetails",
  }
}

export const crmApiEndPoints = {
  caseComplaint: "/case/complaint",
  crmClaims: "/ClaimCrm/getclaims",
  crmClaimDetails: "/ClaimCrm/GetClaimDetails",
  crmCreateLead: "/lead/createlead",
}

export const changePasswordEndpoints = {
  confirmpassword : "/account/confirmpassword",
  updatepassword: "/account/updatepassword"
}
