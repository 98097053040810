// src/Tabs.js
import React, { useRef } from "react";
import useLanguageTranslate from "../../../hooks/useLanguageTranslate";

const Tabs = ({
  tabs,
  activeTab,
  onTabClick,
  className,
  disabledTabs = [],
}) => {
  const translate = useLanguageTranslate();
  const itemsRef = useRef([]);


  const handleClick = (index,tab) => {
    if (itemsRef.current[index]) {
      itemsRef.current[index].scrollIntoView({
        behavior: "smooth",
        block: "center", 
        inline: "center" 
      });
    }
    onTabClick(tab.type)
  };

  return (
    <div
      className={`flex border-b gap-4 border-gray-200 pt-[15px] border-b-[#DDE2E4] mb-4 max767:mb-0 max767:justify-between ipad:overflow-auto  max767:sticky max767:top-[67px] max767:overflow-x-auto max767:ml-0 max767:pl-[5%] max767:bg-white max767:z-10 max767:w-full ${className}`}
    >
      {tabs.map((tab,index) => {
        const isDisabled = disabledTabs?.includes(tab.type);
        return (
          <button
             ref={(el) => (itemsRef.current[index] = el)}
            key={tab.type}
            onClick={() => !isDisabled &&handleClick(index,tab) }
            disabled={isDisabled}
            className={` text-sm not-italic font-normal leading-6 pb-3 ipad:pl-0 max767:text-base max767:not-italic  ${
              isDisabled
                ? "cursor-not-allowed opacity-[0.5] flex-col items-center shrink-0 bg-white box-border max767:font-normal max767:text-[#84919A] pl-0 text-[#84919A]"
                : activeTab == tab.type
                ? " text-[#00719A] text-sm not-italic font-semibold flex items-center shrink-0  rtl:pl-0 box-border pl-0 border-b-[#00719A] border-b border-solid"
                : "flex-col items-center shrink-0 bg-white box-border max767:font-normal max767:text-[#84919A] pl-0 text-[#84919A]"
            }`}
          >
            <span className="flex ">
              {tab?.icon && (
                <img src={tab.icon} alt="" className="mr-2 rtl:ml-2 rtl:mr-0" />
              )}
              {translate(tab.label_en, tab.label_ar)}
            </span>
          </button>
        );
      })}
    </div>
  );
};

export default Tabs;
